import React from "react";
import Header from '@components/PDFHeader';
import moment from "moment";
import { MultiDatePicker } from "@elements/index"
const COTAJobDescription = (props) => {
  return (
    <div id="form-content">
      <Header title="Certified Occupational Therapy 
Assistant - COTA
" />
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="border-box">
                <div>
                  <div className="d-md-flex">
                    <table className="table table-all bordered">
                      <tbody>
                        <tr>
                          <td width="40%" className="head">
                            Job Title:
                          </td>
                          <td width="60%" className="input">
                            <input
                              type="text"
                              disabled
                              value="Certified Occupational Therapy 
Assistant - COTA"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td width="40%" className="head">
                            Location:
                          </td>
                          <td width="60%" className="input">
                            <input type="text" value={"Staffers Holdings LLC"} disabled onChange={(e) => props.handleInputChange('location', e.target.value)} />

                          </td>
                        </tr>
                        <tr>
                          <td width="40%" className="head">
                            Effective Date:
                          </td>
                          <td width="60%" className="input">
                            {/* <input type="date" value={props.inputValues.EffectiveDate} onChange={(e) => props.handleInputChange('EffectiveDate', e.target.value)} /> */}
                            <MultiDatePicker required={false} multiple={false} value={props.inputValues.EffectiveDate} onChange={(e) => props.handleInputChange('EffectiveDate', e)} />

                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="table table-all bordered">
                      <tbody>
                        <tr>
                          <td width="40%" className="head">
                            Department
                          </td>
                          <td width="60%" className="input">
                            <input type="text" value={"Rehab"} disabled onChange={(e) => props.handleInputChange('Department', e.target.value)} />

                          </td>
                        </tr>
                        <tr>
                          <td width="40%" className="head">
                            FLSA Status:
                          </td>
                          <td width="60%" className="input">
                            <input type="text" value={props.inputValues.FLSA} onChange={(e) => props.handleInputChange('FLSA', e.target.value)} />

                          </td>
                        </tr>
                        <tr>
                          <td width="40%" className="head">
                            Revised Date:
                          </td>
                          <td width="60%" className="input">
                            <input type="text" value={props.inputValues.Revised} onChange={(e) => props.handleInputChange('Revised', e.target.value)} />

                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <table className="table table-all bordered">
                    <tbody>
                      <tr>
                        <td className="head width-set-h">Reporting to:</td>
                        <td className="input width-set-i">
                          <input type="text" value="Staffing Manager" disabled />
                        </td>
                      </tr>
                      <tr>
                        <td className="head width-set-h">Date of Hire:</td>
                        <td className="input width-set-i">
                          {/* <input type="date" value={props.inputValues.DOH} onChange={(e) => props.handleInputChange('DOH', e.target.value)} /> */}
                          <MultiDatePicker required={false} multiple={false} value={props.inputValues.DOH} onChange={(e) => props.handleInputChange('DOH', e)} />
                      
                        </td>
                      </tr>
                      <tr>
                        <td className="head width-set-h">Employee Name:</td>
                        <td className="input width-set-i">
                          <input placeholder="Full Name" style={{ borderWidth: "0px 0px 1px 0px" }} required type="text" minLength={2} maxLength={20} name="full name" value={props.inputValues.full_name} onChange={(e) => props.handleInputChange('full_name', e.target.value)} />

                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="back-grad grey">
                  <h2 className="heading-line">JOB SUMMARY:</h2>
                  <p className="bold-para">
                    An Occupational Therapy Assistant, or Occupational Therapist
                    Assistant, supports the Occupational Therapist in conducting
                    therapy treatments to improve patients’ mobility and
                    physical health. Their main duties include helping patients
                    perform exercises or treatment plans, recording a patients’
                    health progress and collecting patients’ medical history or
                    other personal information.
                  </p>
                </div>
                <div className="back-grad">
                  <h3 className="same-head">
                    <span>E</span>ssential job functions
                  </h3>
                  <h4 className="same-small-head">Customer Service</h4>

                  <ul>
                    <li>
                      <p>
                        Provide compassionate resident care services. Establish
                        and maintain positive interactions with internal and
                        external customers, including co-workers, facility
                        employees and direct reports.
                      </p>
                    </li>
                    <li>
                      <p>
                        Take responsibility for customer’s issues and concerns
                        striving to make outstanding experiences for our
                        customers. Customers include residents, families,
                        vendors, and other employees.
                      </p>
                    </li>
                    <li>
                      <p>
                        Promote positive relationships with residents, visitors,
                        and regulators, to include presenting a professional
                        appearance.
                      </p>
                    </li>
                  </ul>

                  <br></br>
                  <h4 className="same-small-head">
                    Specific Duties and Responsibilities
                  </h4>
                  <ul>
                    <li>
                      <p>
                        {" "}
                        Monitoring a patient’s progress to make sure that
                        they’re successfully completing treatment
                      </p>
                    </li>
                    <li>
                      <p>
                        Encouraging the patient during their recovery process
                      </p>
                    </li>
                    <li>
                      <p>
                        Helping patients to improve their quality of life by
                        finding solutions to their problems
                      </p>
                    </li>
                    <li>
                      <p>
                        Helping injured employees improve their motor skills
                        with the goal of re-entering the workforce
                      </p>
                    </li>
                    <li>
                      <p>
                        {" "}
                        Teaching patients with disabilities to become more
                        independent
                      </p>
                    </li>
                    <li>
                      <p>
                        {" "}
                        Updating patient records for the Occupational Therapist
                      </p>
                    </li>
                    <li>
                      <p>
                        Keeping the office, treatment room and equipment in good
                        order
                      </p>
                    </li>
                    <li>
                      <p>
                        Teaching patients, family members or other Caregivers
                        skills and techniques to help with the patients’
                        treatment program
                      </p>
                    </li>
                  </ul>
                  <br></br>
                  <h4 className="same-small-head">Safeguarding and Safety</h4>
                  <ul>
                    <li>
                      <p>
                        To ensure that residents are safeguarded against the
                        risk of abuse. This includes but is not limited to a
                        zero tolerance for harassment and violence; following
                        infection control procedures; following safe patient
                        handling as required with your specific job position.
                      </p>
                    </li>
                    <li>
                      <p>
                        Demonstrate safe practices regarding resident’s comfort
                        and safety by applying knowledge of proper body
                        alignment for self and resident. Practice and promote
                        adherence to institutional safety codes.
                      </p>
                    </li>
                    <li>
                      <p>
                        Comply with all Company safety policies, regulations and
                        requirements regarding fire and life safety.
                      </p>
                    </li>
                    <li>
                      <p>
                        Meet the general health requirements set forth by the
                        policies of the facility.
                      </p>
                    </li>
                  </ul>
                  <br></br>
                  <h4 className="same-small-head">
                    Other Functions and Responsibilities
                  </h4>
                  <ul>
                    <li>
                      <p>Flexibility and ability to work under pressure.</p>
                    </li>
                    <li>
                      <p>The ability to prioritize a busy workload.</p>
                    </li>
                    <li>
                      <p>
                        Must adhere to Code of Conduct and Business Ethics
                        policy, including documentation and reporting
                        responsibilities.
                      </p>
                    </li>
                    <li>
                      <p>
                        This is a position in health care facilities open 24
                        hours a day, 365 days a year. Shifts on holidays and
                        working during inclement weather may be required.
                      </p>
                    </li>
                    <li>
                      <p>
                        To complete and submit all timesheets correctly and in a
                        timely manner to enable authorization and payment.
                      </p>
                    </li>
                  </ul>
                  <br></br>
                  <h4 className="same-small-head">
                    Physical and Sensory Requirements
                  </h4>
                  <p>This position is very active and requires:</p>
                  <ul>
                    <li>
                      <p>
                        Walking, standing, reaching, bending, crouching,
                        grasping, pushing, pulling, and lifting.
                      </p>
                    </li>
                    <li>
                      <p>
                        The ability to work at a rapid pace for an entire shift.
                      </p>
                    </li>
                    <li>
                      <p>
                        The employee must frequently lift or move objects and
                        patients weighing up to 40 pounds. Following patient
                        care plan and using a variety of equipment, the employee
                        may frequently move, reposition, and transfer residents
                        of varying weights up to 300 lbs.
                      </p>
                    </li>
                    <li>
                      <p>
                        Must have a finger and hand dexterity sufficient to
                        type, handle and manipulate instruments and operate
                        departmental equipment.
                      </p>
                    </li>
                    <li>
                      <p>
                        Have a working understand of English: ability to read
                        and write; ability to understand and follow written and
                        oral instructions; ability to verbally communicate with
                        residents and others; ability to hear and respond to
                        pages.
                      </p>
                    </li>
                    <li>
                      <p>
                        Ability to remain calm in emergency situations and when
                        handling multiple tasks.
                      </p>
                    </li>
                  </ul>
                  <br></br>
                  <h4 className="same-small-head">
                    Physical and Sensory Requirements
                  </h4>
                  <ul>
                    <li>
                      <p>
                        Proven working experience as a Certified Occupational
                        Therapy Assistant (COTA)
                      </p>
                    </li>
                    <li>
                      <p>
                        Excellent knowledge of principles and practices of
                        occupational therapy
                      </p>
                    </li>
                    <li>
                      <p>
                        Hands-on experience with computer programs and creative
                        media
                      </p>
                    </li>
                    <li>
                      <p>
                        Familiarity with ADLs evaluation tools ( e.g. Katz ADL
                        scale, Lawton IADL scale)
                      </p>
                    </li>
                    <li>
                      <p>
                        Ability to manage patients with different types of
                        personalities.
                      </p>
                    </li>
                    <br />
                    <br />
                    <li>
                      <p>
                        Current knowledge of treatment practices per discipline
                        and drive to continually learn and grow in the
                        profession
                      </p>
                    </li>
                    <li>
                      <p>Professional behavior</p>
                    </li>
                    <li>
                      <p>Excellent interpersonal communication skills</p>
                    </li>
                    <li>
                      <p>Valid COTA certification</p>
                    </li>
                  </ul>
                  <br></br>

                  <h2 className="heading-line">JOB DESCRIPTION REVIEW:</h2>
                  <p>
                    I understand this job description and its requirements, and
                    that I am expected to complete all duties as assigned. I
                    understand the job functions may be altered from time to
                    time. I further understand that the purpose of this job
                    description is to identify the major duties and
                    responsibilities of the job and this is not intended to
                    contain each and every inherent in this job. I have noted
                    below any accommodations that are required to enable me to
                    perform these duties. I have also noted below any job
                    responsibilities or functions which I am unable to perform,
                    with or without accommodation.
                  </p>
                </div>
                <table className="table table-all bordered">
                  <tbody>
                    <tr>
                      <td width="20%" className="head not-bold">
                        Employee's<br></br> Signature
                      </td>
                      <td className="input">
                        <div>

                          {props?.signature ? <img src={props?.signature} width={180} height={50}  className="w-100 rounded-0" alt="Signature here" /> : <div class="badge badge-primary text-wrap pointer" onClick={props?.onOpenModal}>
                            Click Here To Sign
                          </div>}
                        </div>
                      </td>
                      <td width="20%" className="head not-bold">
                        Date:
                      </td>
                      <td className="input">
                        <div>                        <div>
                          <input type='text' value={moment(new Date()).format("MM-DD-YYYY HH:mm:ss")} disabled /></div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width="20%" className="head not-bold">
                        Supervisor's<br></br> Signature
                      </td>
                      <td className="input" width="20%">
                        <div></div>
                      </td>
                      <td width="20%" className="head not-bold">
                        Date:
                      </td>
                      <td className="input" width="20%">
                        <div>
                          {/* <input className='' /> */}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="border-box mt-3">
                <div className="back-grad">
                  <p className=" para-underline">Accomodation Needed:</p>
                  <p className=" para-underline">
                    Job Responsibilities I am unable to perform:
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default COTAJobDescription;
