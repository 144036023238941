import React, { useState, useEffect } from "react";
import { observer } from "mobx-react"
import HeaderPage from "../layout/HeaderPage";
import DrawerMenuPage from "../layout/DrawerMenuPage";
import { ErrorMessage, FieldArray, Formik } from "formik";
import './Settings.css'
import Spinner from "@components/Spinner";
import { InputField } from "@elements/index"
import AdminStore from "@store/AdminStore";
import { toJS } from "mobx";
import { toast } from "react-toastify";
import { BASE_API_URL_FILE } from "../../../api/config";
import { Helmet } from "react-helmet";
const Settings = observer(() => {

    const [settings, setSettings] = useState([]);

    useEffect(() => {
        AdminStore.getSettings();
    }, []);

    useEffect(() => {
        setSettings(toJS(AdminStore?.data?.settings)?.filter(x => !x.prof_id && !x.document_id))
    }, [AdminStore.data.settings]);

    const saveSetting = (values) => {
        let param = values.settingsList.map(x => {
            return { 'key_name': x.key_name, 'value': x.value || 'false', 'document_id': x.document_id }
        })
        AdminStore.saveSettings({ "setting": param }, navigationCallBack)
    }
    const navigationCallBack = () => {
        AdminStore.getSettings();
    }
    const openFile = (url) => {
        // Append a cache-busting parameter to the URL
        var cacheBuster = new Date().getTime();
        var cacheBustedUrl = url + "?cb=" + cacheBuster;
        // Open the URL in a new window
        window.open(cacheBustedUrl, '_blank');
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>Staffers LLC | Settings</title>
            </Helmet>
            {/* <body> */}
            <div className="container-scroller">
                <HeaderPage />
                <div className="container-fluid page-body-wrapper">
                    <DrawerMenuPage />
                    <div className="main-panel" style={{ right: 0, position: "absolute" }}>
                        <div className="content-wrapper p-3 bg-white ">
                            <h3 className="page-title">
                                <span className="page-title-icon linear text-white me-2">
                                <i className="fas fa-cog"></i>
                                </span> Settings
                            </h3>

                        </div>
                        {/* <!-- partial --> */}
                        <div className=" row justify-content-center mt-4">
                            <div className="col-lg-11">
                                <Formik
                                    initialValues={{ settingsList: settings }}
                                    onSubmit={values => saveSetting(values)}
                                    enableReinitialize
                                    validateOnChange={false}
                                >
                                    {
                                        ({ handleChange, handleBlur, handleSubmit, values, errors, touched, setFieldValue
                                        }) =>
                                        (

                                            <form onSubmit={handleSubmit} className="wow fadeInUp">
                                                <div className="row g-2">
                                                    <FieldArray name="settingsList"
                                                        render={() => (values?.settingsList?.map((field, i) => {
                                                            return (
                                                                <>
                                                                    <div key={i + 'settings'} className="col-sm-5 mb-3">
                                                                        {field?.document_id ?
                                                                            <div>
                                                                                <div className="did-floating-label-content">
                                                                                    <input
                                                                                        className={"did-floating-input"}
                                                                                        type="file"
                                                                                        name={`settingsList[${i}].value`}
                                                                                        label={field.key_name}
                                                                                        // value={field.value}
                                                                                        accept=".pdf"
                                                                                        onChange={(e) => {
                                                                                            if (e.currentTarget.files[0] && e.currentTarget.files[0].size >= 5 * 1024 * 1024) {
                                                                                                // File size is too large, display an error message
                                                                                                toast.warn("Your file is too large, maximum allowed size is 5MB!")
                                                                                            } else {
                                                                                                setFieldValue(`settingsList[${i}].value`, e.currentTarget.files[0])
                                                                                            }
                                                                                        }}
                                                                                        onBlur={handleBlur}
                                                                                    />

                                                                                    <label className="did-floating-label did-floating-label-top">{field.key_name}*</label>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <InputField
                                                                                className={"did-floating-input"}
                                                                                type="text"
                                                                                name={`settingsList[${i}].value`}
                                                                                label={field.key_name}
                                                                                value={field.value}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                            />}
                                                                        <ErrorMessage name={`settingsList.${i}.value`} component="div" className="error-message" />
                                                                    </div>
                                                                    {(field?.document_id && (typeof field?.value === "string" ? !(field?.value?.trim()?.length === 0) : false)) ? <div className="col-sm-1 mb-3 pt-2 w-15">
                                                                        <i className="fa fa-eye text-primary pointer" onClick={() => openFile(BASE_API_URL_FILE + field?.value)} aria-hidden="true"></i>
                                                                    </div> : <div className="col-sm-1 mb-3 pt-2"></div>}
                                                                </>
                                                            )

                                                        }))} />
                                                    <div className="pt-4 d-flex justify-content-center">
                                                        <button className="btn btn-primary w-25 p-2" type="submit">Submit</button>
                                                    </div>
                                                </div>
                                            </form>
                                        )
                                    }
                                </Formik>
                            </div>
                        </div>
                    </div>

                    {/* <!-- main-panel ends --> */}
                </div>
                {/* <!-- page-body-wrapper ends --> */}
            </div>
            {/* </body> */}
            <Spinner isLoading={AdminStore.loading} />
        </React.Fragment>
    );
})

export default Settings;