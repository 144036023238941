import axios from "axios";
import { BASE_API_URL, BASE_API_URL_INT } from "./config"
import secureLocalStorage from "react-secure-storage";

export const axiosInstance = axios.create({
  baseURL: BASE_API_URL,
});
export const axiosInstanceInt = axios.create({
  baseURL: BASE_API_URL_INT,
  headers: { "Content-Type": "multipart/form-data" },
});

export const setAuthToken = () => {
  let token = localStorage.getItem("refresh-token");
  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

export const setAuthTokenInt = () => {
  let token = secureLocalStorage.getItem("token");
  axiosInstanceInt.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}
export const setAuthTokenIntAdmin = () => {
  let token = secureLocalStorage.getItem("admin-token");
  axiosInstanceInt.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}








