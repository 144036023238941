/* eslint-disable */
import React, { useState, useEffect } from "react";
import { ErrorMessage, FieldArray, Formik } from "formik";
import Header from "@components/Header";
import { Helmet } from "react-helmet";
import { useParams, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import Footer from "@components/Footer";
import { InputField } from "@elements/index";
import Select, { components } from "react-select";
import { toast } from "react-toastify";
import Spinner from "@components/Spinner";
import StaffsStore from "@store/StaffsStore";
import HomeStore from "@store/HomeStore";
import OtpInput from "react-otp-input";
import SelectField from "@elements/select/SelectField";
import {
  ClientSignUpSchema,
  StaffSignUpSchema,
  regex,
} from "@utils/schemas/allSchema";
import { toJS } from "mobx";
import WebsiteLayout from "../components/WebsiteLayout";
import UserStore from "../../store/UserStore";
import { Link } from "react-router-dom";
var data = require("@assets/CountryCodes.json");
var citys = require("@assets/city.json");
var states = require("@assets/states.json");
const colourStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderColor: state.isFocused ? "blue" : "#75459b",
    borderRadius: 20,
    boxShadow: "none",
    padding: "2px 10px",

    "&:focus": {
      border: "1px solid blue !important",
    },
    "&:active": {
      border: "1px solid blue !important",
    },
    "&:hover": {
      border: "1px solid blue !important",
    },
  }),
  menu: (base) => ({
    ...base,
    fontSize: 12,
    zIndex: 1999,
  }),
  menuList: (base) => ({
    ...base,
    height: "200px",

    "::-webkit-scrollbar": {
      width: "9px",
    },
    "::-webkit-scrollbar-track": {
      background: "white",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#8282C8",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
};
const colourStylesTitle = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    padding: "2px 10px",
    borderColor: state.isFocused ? "blue" : "#75459b",
    borderRadius: 20,
    boxShadow: "none",
    "&:focus": {
      border: "1px solid blue !important",
    },
    "&:active": {
      border: "1px solid blue !important",
    },
    "&:hover": {
      border: "1px solid #8282C8 !important",
    },
  }),
  menu: (base) => ({
    ...base,
    fontSize: 12,
    zIndex: 9999,
  }),
  menuList: (base) => ({
    ...base,
    height: "auto",

    "::-webkit-scrollbar": {
      width: "9px",
    },
    "::-webkit-scrollbar-track": {
      background: "white",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#8282C8",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
};
const colourStylesState = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderColor: state.isFocused ? "blue" : "#75459b",
    borderRadius: 20,
    boxShadow: "none",
    padding: "2px 10px",
    "&:focus": {
      border: "1px solid blue !important",
    },
    "&:active": {
      border: "1px solid blue !important",
    },
    "&:hover": {
      border: "1px solid blue !important",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    fontSize: 12,
  }),
  menu: (base) => ({
    ...base,
    fontSize: 12,
    zIndex: 200, // set the z-index to a high value
  }),
  menuList: (base) => ({
    ...base,
    height: "200px",
    "::-webkit-scrollbar": {
      width: "9px",
    },
    "::-webkit-scrollbar-track": {
      background: "white",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#8282C8",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
};
const WebsiteRegisterPage = observer(() => {
  window.onscroll = function () {};
  const navigate = useNavigate();
  const { role } = useParams();
  const [redirect, setRedirect] = useState(false);
  const [userType, setUserType] = useState("false"); //false for client
  const [stepOne, setStepOne] = useState(false);
  const [OTP, setOTP] = useState("");
  const [maskEmail, setMaskEmail] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [seconds, setSeconds] = useState(0);
  const [icon, setIcon] = useState(
    "fa fa-fw fa-eye-slash field-icon toggle-password"
  );
  const [passwordType, setPasswordType] = useState("password");
  const [degrees, setDegrees] = useState([]);

  useEffect(() => {
    HomeStore.getDashboardData();
    if (role == "employer") {
      setUserType("false");
    } else {
      setUserType("true");
    }
  }, []);

  useEffect(() => {
    const degreesData = HomeStore.data.dashboardData?.find(
      (x) => x.key_name === "DEGREES"
    );
    if (degreesData) setDegrees(JSON.parse(toJS(degreesData.value)));
  }, [HomeStore.data.dashboardData]);

  const _changeIcon = () => {
    icon === "fa fa-fw fa-eye-slash field-icon toggle-password"
      ? (setIcon("fa fa-fw fa-eye field-icon toggle-password"),
        setPasswordType("text"))
      : (setIcon("fa fa-fw fa-eye-slash field-icon toggle-password"),
        setPasswordType("password"));
  };

  const registerForm = (values) => {
    setName(values.first_name);
    setEmail(values.email);
    if (userType == "true") {
      //for staff
      StaffsStore.registerStaff(values, navigationCallBack);
    } else {
      //for client
      // console.log(values);return
      let formData = new FormData();
      formData.append(
        "additional_contact",
        values.additional_contact?.length > 0
          ? JSON.stringify(values.additional_contact)
          : ""
      );
      formData.append(
        "additional_mail",
        values.additional_email?.length > 0
          ? JSON.stringify(values.additional_email)
          : ""
      );
      formData.append("organization_name", values.organization_name);
      formData.append("organization_type", values.organization_type);
      formData.append("title", values.title);
      formData.append("first_name", values.first_name);
      formData.append("last_name", values.last_name);
      formData.append("email", values.email);
      formData.append("contact", values.contact);
      formData.append("country_code", values.country_code);
      formData.append("state", values.state);
      formData.append("city", values.city);
      formData.append("city_data", JSON.stringify(values?.city_data));
      formData.append("address", values.address);
      formData.append("zipcode", values.zipcode);
      formData.append("description", values.description);
      formData.append("designation", values.designation);
      formData.append("password", values.password);
      UserStore.register(formData, navigationCallBack);
    }
  };

  const navigationCallBack = (email, id) => {
    let maskEmail = email?.replace(
      regex,
      (_, first, middle, last) => `${first}${"x".repeat(middle.length)}${last}`
    );
    setMaskEmail(maskEmail);
    setEmail(email);
    setUsername(id);
    toast.success("Successfully Sent OTP");
    setStepOne(true);
    localStorage.removeItem("jobId");
  };

  const resendOTP = () => {
    setOTP("");
    setSeconds(30);
    if (userType == "true") {
      const formdata = new FormData();
      formdata.append("email", email);
      StaffsStore.reSendOTP(formdata);
    } else {
      const formdata = new FormData();
      formdata.append("id", username);
      UserStore.reSendOTP(formdata);
    }
  };

  useEffect(() => {
    if (OTP.length === 4) {
      verifyOTP();
    }
  }, [OTP]);

  const verifyOTP = () => {
    if (OTP && OTP.length === 4) {
      if (userType == "true") {
        const formdata = new FormData();
        formdata.append("email", email);
        formdata.append("otp", OTP);
        StaffsStore.verifyOTP(formdata, navigationCallBackVerify);
      } else {
        const formdata = new FormData();
        formdata.append("id", username);
        formdata.append("otp", OTP);
        UserStore.verifyOTP(formdata, navigationCallBackVerify);
      }
    } else {
      toast.warn("Please Enter OTP!!");
    }
  };

  const navigationCallBackVerify = () => {
    if (userType == "true") {
      swal(
        "Registered",
        "Your account has been registered. Please login.",
        "success"
      );
      navigate("/login/employee");
    } else {
      swal(
        "Pending!",
        "Your account is under review. You will be notified via mail once activated.",
        "warning"
      );
      navigate("/login/employer");
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  if (redirect) {
    return <Link to="/login" />;
  }
  return (
    <WebsiteLayout>
      <Helmet>
        <title>Staffers LLC | Register</title>
      </Helmet>
      <div className="container-fluid callback py-5">
        <div className="container pt-5">
          <h5 className="display-5 mb-4 animated slideInDown login-head">
            Welcome to the Staffers Community
          </h5>
          <div className="row justify-content-center ">
            <div className="col-xl-7 col-lg-7">
              <div
                className="bg-white shadow border p-4 p-sm-4 wow fadeInUp"
                style={{ borderRadius: 25 }}
                data-wow-delay="0.03s"
              >
                {!stepOne && (
                  <>
                    <div
                      className="text-center mx-auto wow fadeInUp"
                      data-wow-delay="0.03s"
                      style={{ maxWidth: 600 }}
                    >
                      <div className="d-flex justify-content-center align-items-center text-primary fw-semi-bold pb-4">
                        <div className="row">
                          <div className="col">
                            <div
                              className={
                                userType === "false"
                                  ? "login-switch-label-selected"
                                  : ""
                              }
                            >
                              <input
                                className="login-switch"
                                type="radio"
                                name="Employer"
                                id="Employer"
                                checked={userType === "false"}
                                value={false}
                                onChange={(event) => {
                                  setUserType(event.target.value);
                                }}
                                onClick={(event) => {
                                  setUserType(event.target.value);
                                }}
                              />
                              <label
                                className="login-switch-label"
                                style={
                                  userType === "false"
                                    ? { color: "#75459B" }
                                    : {}
                                }
                                htmlFor="Employer"
                                aria-label="Employer"
                              >
                                <span></span>
                                Facility User
                              </label>
                            </div>
                          </div>
                          <div className="col">
                            <div
                              className={
                                userType === "true"
                                  ? "login-switch-label-selected"
                                  : ""
                              }
                            >
                              <input
                                className="login-switch"
                                type="radio"
                                name="Employee"
                                id="Employee"
                                value={true}
                                checked={userType === "true"}
                                onChange={(event) => {
                                  setUserType(event.target.value);
                                }}
                                onClick={(event) => {
                                  setUserType(event.target.value);
                                }}
                              />
                              <label
                                className="login-switch-label"
                                htmlFor="Employee"
                                aria-label="Employee"
                                style={
                                  userType === "true"
                                    ? { color: "#75459B" }
                                    : {}
                                }
                              >
                                <span></span>
                                Caregiver
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Formik
                      initialValues={{
                        organization_name: "",
                        organization_type: "",
                        region_id: "",
                        designation: "",
                        profession: "",
                        title: "Mr.",
                        first_name: "",
                        last_name: "",
                        contact: "",
                        email: "",
                        country_code: "+1",
                        state: "",
                        city: "",
                        city_data: [],
                        address: "",
                        zipcode: "",
                        description: "",
                        password: "",
                        passwordConfirmation: "",
                        additional_contact: [],
                        additional_email: [],
                        job_id: localStorage.getItem("jobId"),
                      }}
                      onSubmit={(values) => registerForm(values)}
                      validationSchema={
                        userType == "true"
                          ? StaffSignUpSchema
                          : ClientSignUpSchema
                      }
                    >
                      {({
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                        values,
                        errors,
                        touched,
                        resetForm,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="row g-3">
                            {/* <div className="col-sm-2">
                                                                <Select
                                                                    onChange={(e) => setFieldValue("title", e.value)}
                                                                    placeholder={'Title'}
                                                                    styles={colourStylesTitle}
                                                                    isSearchable={true}
                                                                    options={global.config.titleList}
                                                                    defaultValue={{ label: "Mr.", value: 'Mr.' }}
                                                                    components={{ Control, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                                />
                                                            </div> */}
                            <div className="col-sm-6">
                              <InputField
                                className={"did-floating-input"}
                                classNameLabel={
                                  "did-floating-label did-floating-labe-astrisk"
                                }
                                type="text"
                                name="first_name"
                                label={"First Name"}
                                value={values.first_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required={true}
                              />
                              {errors.first_name && touched.first_name ? (
                                <div className="error-message">
                                  {errors.first_name}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-sm-6">
                              <InputField
                                className={"did-floating-input"}
                                classNameLabel={
                                  "did-floating-label did-floating-labe-astrisk"
                                }
                                type="text"
                                name="last_name"
                                label={"Last Name"}
                                value={values.last_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required={true}
                              />
                              {errors.last_name && touched.last_name ? (
                                <div className="error-message">
                                  {errors.last_name}
                                </div>
                              ) : null}
                            </div>
                            {userType == "false" && (
                              <>
                                <div className="col-sm-6">
                                  <InputField
                                    className={"did-floating-input"}
                                    classNameLabel={
                                      "did-floating-label did-floating-labe-astrisk"
                                    }
                                    type="text"
                                    name="organization_name"
                                    label={"Organization Name"}
                                    value={values.organization_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {errors.organization_name &&
                                  touched.organization_name ? (
                                    <div className="error-message">
                                      {errors.organization_name}
                                    </div>
                                  ) : null}
                                </div>
                              </>
                            )}
                            {userType == "false" && (
                              <>
                                <div className="col-sm-6">
                                  <SelectField
                                    name="organization_type"
                                    label={"Organization Type"}
                                    value={values.organization_type}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    data={global.config.organizationType}
                                    optionLabel={"label"}
                                    optionValue={"value"}
                                    required
                                  />
                                  {errors.organization_type &&
                                  touched.organization_type ? (
                                    <div className="error-message">
                                      {errors.organization_type}
                                    </div>
                                  ) : null}
                                </div>
                                <div className="col-sm-12">
                                  <InputField
                                    className={"did-floating-input"}
                                    type="text"
                                    name="designation"
                                    label={"Job Title"}
                                    value={values.designation || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {errors.designation && touched.designation ? (
                                    <div className="error-message">
                                      {errors.designation}
                                    </div>
                                  ) : null}
                                </div>
                              </>
                            )}

                            <div className="col-sm-2">
                              <Select
                                onChange={(e) =>
                                  setFieldValue("country_code", e.label)
                                }
                                placeholder={""}
                                label={"Country"}
                                styles={colourStyles}
                                isSearchable={true}
                                options={data}
                                defaultValue={{ label: "+1", value: "CA" }}
                                components={{
                                  Control,
                                  DropdownIndicator: () => null,
                                  IndicatorSeparator: () => null,
                                }}
                              />
                            </div>
                            <div className="col-sm-4">
                              <InputField
                                className={"did-floating-input"}
                                classNameLabel={
                                  "did-floating-label did-floating-labe-astrisk"
                                }
                                type="text"
                                name="contact"
                                label={"Contact Number"}
                                value={
                                  values?.contact
                                    ?.replace(/[^\d]/g, "")
                                    .replace(
                                      /(\d{3})(\d{3})(\d{4})/,
                                      "$1-$2-$3"
                                    ) || ""
                                }
                                maxLength={12}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.contact && touched.contact ? (
                                <div className="error-message">
                                  {errors.contact}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-sm-6">
                              <InputField
                                className={"did-floating-input"}
                                classNameLabel={
                                  "did-floating-label did-floating-labe-astrisk"
                                }
                                type="email"
                                name="email"
                                label={"Email Address"}
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.email && touched.email ? (
                                <div className="error-message">
                                  {errors.email}
                                </div>
                              ) : null}
                            </div>
                            {/* More contacts input */}
                            {userType == "false" && (
                              <div className="col-sm-12 d-flex ">
                                <div className="col-sm-6">
                                  <FieldArray
                                    name="additional_contact"
                                    render={({ push, remove }) => (
                                      <div>
                                        {values?.additional_contact?.map(
                                          (device, index) => (
                                            <div
                                              key={index}
                                              className="row g-2"
                                            >
                                              <div className="col-sm-4">
                                                <Select
                                                  onChange={(e) =>
                                                    setFieldValue(
                                                      `additional_contact.${index}.country_code`,
                                                      e.label
                                                    )
                                                  }
                                                  placeholder={""}
                                                  label={"Country"}
                                                  styles={colourStyles}
                                                  isSearchable={true}
                                                  options={data}
                                                  defaultValue={{
                                                    label: "+1",
                                                    value: "CA",
                                                  }}
                                                  components={{
                                                    Control,
                                                    DropdownIndicator: () =>
                                                      null,
                                                    IndicatorSeparator: () =>
                                                      null,
                                                  }}
                                                />
                                              </div>
                                              <div className="col-sm-7 pb-2">
                                                <InputField
                                                  className={
                                                    "did-floating-input"
                                                  }
                                                  classNameLabel={
                                                    "did-floating-label did-floating-labe-astrisk"
                                                  }
                                                  type="text"
                                                  name="contact"
                                                  label={"Contact Number"}
                                                  value={
                                                    device?.contact
                                                      ?.replace(/[^\d]/g, "")
                                                      .replace(
                                                        /(\d{3})(\d{3})(\d{4})/,
                                                        "$1-$2-$3"
                                                      ) || ""
                                                  }
                                                  maxLength={12}
                                                  onChange={(e) =>
                                                    setFieldValue(
                                                      `additional_contact.${index}.contact`,
                                                      e.target.value
                                                    )
                                                  }
                                                  onBlur={handleBlur}
                                                  required={true}
                                                />
                                                <ErrorMessage
                                                  name={`additional_contact.${index}.contact`}
                                                  component="div"
                                                  className="error-message"
                                                />
                                              </div>
                                              <div className="col-sm-1">
                                                <i
                                                  className="fas fa-times-circle text-danger"
                                                  onClick={() => remove(index)}
                                                ></i>
                                              </div>
                                            </div>
                                          )
                                        )}
                                        {values.additional_contact?.length <
                                          3 && (
                                          <button
                                            type="button"
                                            className="btn btn-sm btn-light"
                                            onClick={() =>
                                              push({
                                                country_code: "+1",
                                                contact: "",
                                              })
                                            }
                                          >
                                            Add Contact
                                          </button>
                                        )}
                                      </div>
                                    )}
                                  />
                                </div>
                                <div className="col-sm-6">
                                  {/* More email input */}
                                  <FieldArray
                                    name="additional_email"
                                    render={({ push, remove }) => (
                                      <div>
                                        {values.additional_email.map(
                                          (device, index) => (
                                            <div
                                              className="row g-2"
                                              key={index}
                                            >
                                              <div className="col-sm-11 pb-2">
                                                <InputField
                                                  className={
                                                    "did-floating-input"
                                                  }
                                                  classNameLabel={
                                                    "did-floating-label did-floating-labe-astrisk"
                                                  }
                                                  type="email"
                                                  name="email"
                                                  label={"Email Address"}
                                                  value={device.email}
                                                  onChange={(e) =>
                                                    setFieldValue(
                                                      `additional_email.${index}.email`,
                                                      e.target.value
                                                    )
                                                  }
                                                  onBlur={handleBlur}
                                                  required={true}
                                                />
                                                <ErrorMessage
                                                  name={`additional_email.${index}.email`}
                                                  component="div"
                                                  className="error-message"
                                                />
                                              </div>
                                              <div className="col-sm-1">
                                                <i
                                                  className="fas fa-times-circle text-danger"
                                                  onClick={() => remove(index)}
                                                ></i>
                                              </div>
                                            </div>
                                          )
                                        )}
                                        {values.additional_email?.length <
                                          3 && (
                                          <button
                                            type="button"
                                            className="btn btn-sm btn-light ms-2"
                                            onClick={() => push({ email: "" })}
                                          >
                                            Add Email
                                          </button>
                                        )}
                                      </div>
                                    )}
                                  />
                                </div>
                              </div>
                            )}

                            <div className="col-sm-6">
                              <InputField
                                className={"did-floating-input"}
                                classNameLabel={
                                  "did-floating-label did-floating-labe-astrisk"
                                }
                                type={passwordType}
                                name="password"
                                label={"Password"}
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <span
                                toggle="#password-field"
                                className={icon}
                                onClick={() => _changeIcon()}
                              ></span>
                              {errors.password && touched.password ? (
                                <div className="error-message">
                                  {errors.password}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-sm-6">
                              <InputField
                                className={"did-floating-input"}
                                classNameLabel={
                                  "did-floating-label did-floating-labe-astrisk"
                                }
                                type="password"
                                name="passwordConfirmation"
                                label={"Confirm Password"}
                                value={values.passwordConfirmation}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.passwordConfirmation &&
                              touched.passwordConfirmation ? (
                                <div className="error-message">
                                  {errors.passwordConfirmation}
                                </div>
                              ) : null}
                            </div>
                            {userType == "true" && (
                              <>
                                <SelectField
                                  name="profession"
                                  label={"Profession"}
                                  required
                                  value={values.profession}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  data={degrees}
                                  optionLabel={"Name"}
                                  optionValue={"Id"}
                                />
                                {errors.profession && touched.profession ? (
                                  <div className="error-message">
                                    {errors.profession}
                                  </div>
                                ) : null}
                              </>
                            )}
                            {userType == "false" && (
                              <>
                                <div className="col-sm-6">
                                  <InputField
                                    className={"did-floating-input"}
                                    classNameLabel={
                                      "did-floating-label did-floating-labe-astrisk"
                                    }
                                    type={"text"}
                                    name="address"
                                    label={"Full Address"}
                                    value={values.address}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </div>
                                {errors.address && touched.address ? (
                                  <div className="error-message">
                                    {errors.address}
                                  </div>
                                ) : null}
                                <div className="col-sm-6">
                                  <Select
                                    placeholder={""}
                                    label={"State"}
                                    styles={colourStylesState}
                                    isRequired={true}
                                    onChange={(e) => {
                                      setFieldValue("state", e.abbreviation),
                                        setFieldValue("city", "");
                                    }}
                                    value={
                                      states.filter(({ abbreviation }) =>
                                        values?.state?.includes(abbreviation)
                                      ) || ""
                                    }
                                    options={states}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) =>
                                      option.abbreviation
                                    }
                                    components={{
                                      Control,
                                      DropdownIndicator: () => null,
                                      IndicatorSeparator: () => null,
                                    }}
                                  />
                                </div>
                                {errors.state && touched.state ? (
                                  <div className="error-message">
                                    {errors.state}
                                  </div>
                                ) : null}
                                <div className="col-sm-6">
                                  <Select
                                    onChange={(e) => {
                                      setFieldValue("city", e.label);
                                      setFieldValue("city_data", [e]);
                                    }}
                                    placeholder={""}
                                    isRequired={true}
                                    label={"City"}
                                    isDisabled={!values?.state}
                                    styles={colourStylesState}
                                    getOptionLabel={(option) => option.label}
                                    getOptionValue={(option) => option.label}
                                    value={
                                      citys.filter(
                                        ({ label }) => values?.city === label
                                      ) || ""
                                    }
                                    options={citys?.filter(
                                      (ct) =>
                                        ct.state ===
                                        states?.find(
                                          (x) => x.abbreviation == values?.state
                                        )?.name
                                    )}
                                    components={{
                                      Control,
                                      DropdownIndicator: () => null,
                                      IndicatorSeparator: () => null,
                                    }}
                                  />
                                  {errors.city && touched.city ? (
                                    <div className="error-message">
                                      {errors.city}
                                    </div>
                                  ) : null}
                                </div>
                                <div className="col-sm-6">
                                  <InputField
                                    name={"zipcode"}
                                    className={"did-floating-input"}
                                    classNameLabel={
                                      "did-floating-label did-floating-labe-astrisk"
                                    }
                                    type="number"
                                    label={"Zip Code"}
                                    value={values.zipcode}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {errors.zipcode && touched.zipcode ? (
                                    <div className="error-message">
                                      {errors.zipcode}
                                    </div>
                                  ) : null}
                                </div>
                              </>
                            )}
                            <div className="col-12">
                              <label className="pb-2 label">
                                Tell us about yourself
                              </label>
                              <div className="">
                                <textarea
                                  className="form-control"
                                  placeholder=""
                                  name={"description"}
                                  value={values.description}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  style={{ height: 100 }}
                                ></textarea>
                              </div>
                            </div>
                            <div className="d-flex pb-2">
                              <span>
                                <label className="label">
                                  {" "}
                                  <input
                                    type="checkbox"
                                    required
                                    style={{ marginTop: "0px" }}
                                    className="form-check-input"
                                    id="remember_me"
                                    name="remember_me"
                                    value="remember_me"
                                  />{" "}
                                  I agree to Staffers{" "}
                                  <Link
                                    to="/terms-and-conditions"
                                    style={{
                                      textDecoration: "underline",
                                      color: "#50B5FF",
                                    }}
                                  >
                                    T&C
                                  </Link>{" "}
                                  and{" "}
                                  <Link
                                    to="/privacy-policy"
                                    style={{
                                      textDecoration: "underline",
                                      color: "#50B5FF",
                                    }}
                                  >
                                    Privacy Policy
                                  </Link>
                                  .
                                </label>
                              </span>
                            </div>
                            <div className="col-4">
                              <button
                                className="btn btn-light w-100 p-2"
                                type="reset"
                                onClick={resetForm}
                              >
                                Reset
                              </button>
                            </div>
                            <div className="col-8">
                              <button
                                className="btn btn-primary w-100 p-2"
                                type="submit"
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </>
                )}
                {/* STEP ONE */}

                {stepOne && (
                  <>
                    <div
                      onClick={() => {
                        setStepOne(false);
                      }}
                    >
                      <i
                        className="fa fa-arrow-left"
                        style={{ cursor: "pointer" }}
                        aria-hidden="true"
                      ></i>
                    </div>
                    <div
                      className="text-center pb-2 mx-auto wow fadeInUp"
                      data-wow-delay="0.03s"
                      style={{ maxWidth: 600 }}
                    >
                      <h4>Hi {name} </h4>Please enter the one time password to
                      verify your account
                      <br></br>A code has been sent to {maskEmail}
                    </div>

                    <div className="otpContainer">
                      <OtpInput
                        className="otpInput"
                        value={OTP}
                        onChange={(value) => {
                          setOTP(value);
                        }}
                        numInputs={4}
                        isInputNum={true}
                        inputStyle={{
                          width: "3rem",
                          textAlign: "center",
                          height: "3rem",
                          fontSize: "2rem",
                          borderRadius: 8,
                          border: "0.1px solid #75459b",
                        }}
                        focusStyle={{
                          outline: "none",
                          border: "2px solid #782db6",
                          borderRadius: "8px",
                        }}
                      />
                    </div>
                    <div>
                      <div className="countdown-text">
                        {seconds > 0 ? (
                          <small>
                            Time Remaining:{" "}
                            {seconds < 10 ? `0${seconds}` : seconds}
                          </small>
                        ) : (
                          <small>Didn't recieve code?</small>
                        )}

                        <button
                          disabled={seconds > 0}
                          style={{
                            color: seconds > 0 ? "#DFE3E8" : "#75449b",
                          }}
                          onClick={resendOTP}
                        >
                          Resend OTP
                        </button>
                      </div>
                    </div>
                    <div className="pt-4 d-flex justify-content-center">
                      <button
                        className="btn btn-primary w-50 p-2 "
                        onClick={() => verifyOTP()}
                      >
                        Submit
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Spinner isLoading={UserStore.loading || StaffsStore.loading} />
    </WebsiteLayout>
  );
});

export const Control = (props) => {
  return (
    <>
      <label
        className={
          props.hasValue || props.isFocused
            ? props?.selectProps?.isRequired
              ? "did-floating-label did-floating-label-top did-floating-labe-astrisk"
              : "did-floating-label did-floating-label-top"
            : props?.selectProps?.isRequired
            ? "did-floating-label did-floating-label did-floating-labe-astrisk"
            : "did-floating-label did-floating-label"
        }
        style={{ zIndex: 100 }}
      >
        {props?.selectProps?.label}
      </label>
      <components.Control {...props} />
    </>
  );
};

export default WebsiteRegisterPage;
