/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react';
import Header from '@components/Header';
import { Helmet } from 'react-helmet';
import swal from '@sweetalert/with-react';
import 'react-spring-bottom-sheet/dist/style.css';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import { CButton, CFormLabel, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CDropdownDivider } from "@coreui/react";
import Spinner from "@components/Spinner";
import StaffsStore from "@store/StaffsStore";
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import ReactDOM from "react-dom";
import TimeRangePicker from '@wojtekmaj/react-timerange-picker';

const NewOpportunity = observer(() => {
    window.onscroll = function () { };
    const [newJobs, setNewJobs] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [signleData, setSingleData] = useState();
    const tableRef = useRef()
    const tableName = "table1"

    useEffect(() => {
        StaffsStore.getNewJobs();
    }, []);

    useEffect(() => {
        setNewJobs(toJS(StaffsStore.data.newJobs));
    }, [StaffsStore.data.newJobs]);


    const showDetails = (item) => {
        setSingleData(item);
        setIsOpen(true);
    }
    const onDismiss = () => {
        setIsOpen(false)
    }
    const onStatus = (item, status) => {
        if (status === 2) {
            swal({
                title: "Are you sure to reject this opportunity?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        let param = {
                            "request_id": item.id,
                            "status_id": status
                        }
                        return StaffsStore.acceptNewJob(param, navigationCallBack)
                    }
                });
            return
        }
        let param = {
            "request_id": item.id,
            "status_id": status
        }
        StaffsStore.acceptNewJob(param, navigationCallBack)
    }
    const navigationCallBack = () => {
        onDismiss()
        StaffsStore.getNewJobs();
    }

    useEffect(() => {
        $.fn.dataTable.ext.errMode = 'none';
        $($.fn.dataTable.tables(true)).DataTable()
            .columns.adjust();
        const table = $(`#${tableName}`).DataTable(
            {
                data: newJobs,
                order: [], // Disable initial sorting
                columns: [
                    { title: "Job Id", data: 'id', width: 60 },
                    {
                        title: "Organization Name", data: "user", "render": function (data, type, row, meta) {
                            return data?.organization_name
                        }
                    },
                    {
                        title: "Organization Type", data: "user", "render": function (data, type, row, meta) {
                            return data?.organization_type
                        }
                    },
                    {
                        title: "Email Address", data: "user", "render": function (data, type, row, meta) {
                            return data?.email
                        }
                    },
                    {
                        title: "Contact", data: "user", "render": function (data, type, row, meta) {
                            return data?.contact
                        }
                    },
                    {
                        title: "Job Type", width: 100, data: 'job_type', "render": function (data, type, row, meta) {
                            return global.config.jobType?.find(job => job.value == data)?.name;
                        }
                    },
                    {
                        title: "Shift Type", width: 100, data: 'shift_type'
                    },
                    {
                        title: "Action"
                    },

                ],

                columnDefs: [
                    {
                        targets: 7,
                        responsivePriority: 1,
                        createdCell: (td, cellData, rowData, row, col) =>
                            ReactDOM.render(
                                <div>
                                    <CDropdown variant="btn-group" direction='dropstart'>
                                        <CDropdownToggle size="sm" color="transparent" caret={false} href="#" className="p-0"><i class="fa fa-ellipsis-v text-primary"></i></CDropdownToggle>
                                        <CDropdownMenu >
                                            <CDropdownItem>
                                                <CButton onClick={() => {
                                                    showDetails(rowData)
                                                }} size="sm" className="w-100" variant='outline' color="primary" >
                                                    View <i className="fas fa-eye"></i>
                                                </CButton>
                                            </CDropdownItem>
                                            {rowData?.staff_response === 0 &&
                                                <>
                                                    <CDropdownItem>
                                                        <CButton onClick={() => {
                                                            onStatus(rowData, 1)
                                                        }} size="sm" className="w-100" variant='outline' color="success" >
                                                            Accept <i className="fas fa-user-check"></i>
                                                        </CButton>
                                                    </CDropdownItem>
                                                    <CDropdownItem>
                                                        <CButton
                                                            onClick={() => {
                                                                onStatus(rowData, 2)
                                                            }}
                                                            size="sm" className="w-100" variant='outline' color="danger">
                                                            Reject <i className="fas fa-user-times"></i>
                                                        </CButton>
                                                    </CDropdownItem>
                                                </>
                                            }
                                        </CDropdownMenu>
                                    </CDropdown>
                                </div>
                                , td),
                    }
                ],
                destroy: true,  // I think some clean up is happening here
                searching: true,
                "language": {
                    search: '',
                    searchPlaceholder: "Search",
                    emptyTable: "No Record Found"
                },
                initComplete: function () {
                    // Apply the search
                    this.api()
                        .columns([0, 1, 2, 3, 4])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><input class="form-control" style="width:100%;margin-top:5px;background-color:white"" type="text" placeholder="Filter" />')
                                .appendTo($(column.header()))

                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $('input', this.header()).on('keyup change clear', function () {
                                if (column.search() !== this.value) {
                                    column.search(this.value).draw();
                                }
                            });
                        });

                    this.api()
                        .columns([6])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><select class="form-control" placeholder="search" style="width:100%;margin-top:5px;background-color:white""><option value="">All</option></select>')
                                .appendTo($(column.header()))
                                .on('change', function () {
                                    var val = $.fn.dataTable.util.escapeRegex($(this).val());
                                    column.search(val ? '^' + val + '$' : '', true, false).draw();
                                });
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            column
                                .data()
                                .unique()
                                .sort()
                                .each(function (d, j) {
                                    select.append('<option value="' + d + '">' + d + '</option>');
                                });
                        });
                    this.api()
                        .columns([5])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><select class="form-control" placeholder="search" style="width:100%;margin-top:5px;background-color:white""><option value="">All</option></select>')
                                .appendTo($(column.header()))
                                .on('change', function () {
                                    var val = $.fn.dataTable.util.escapeRegex($(this).val());
                                    column.search(val ? '^' + val + '$' : '', true, false).draw();
                                });
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            column
                                .data()
                                .unique()
                                .sort()
                                .each(function (d, j) {
                                    if (d) {
                                        let name = global.config.jobType?.find(job => job.value == d)?.name;
                                        select.append('<option value="' + name + '">' + name + '</option>');
                                    }
                                });
                        });

                },

            }

        )
        // Extra step to do extra clean-up.
        return function () {
            table.destroy()
        }
    }, [newJobs])

    return (
        <React.Fragment>
            <Header />
            <Helmet>
                <title>Shift Opportunities</title>
            </Helmet>
            <>
                <div className="container-fluid callback mt-5 py-5">
                    <div className="container pt-5">
                        <h5 className="display-5 mb-4 animated slideInDown login-head">Shift Opportunities</h5>
                    </div>
                    
                    <div className="col-lg-12">
                        <div className="row bg-white" style={{ height: 500, paddingTop: 50 }}>
                            <table className="table table-striped dt-responsive nowrap" style={{ width: "100%" }} id={tableName} ref={tableRef}></table>
                        </div>
                    </div>
                </div>
                <div>

                    <BottomSheet open={isOpen} onDismiss={onDismiss}
                        header={
                            <>
                                <div className="text-end" style={{ cursor: "pointer" }} onClick={onDismiss}>
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </div>
                                <h4 className="flex items-center text-xl justify-center font-bold text-primary-800">
                                    {signleData && signleData?.user?.organization_name}
                                </h4>
                            </>
                        }
                        footer={
                            <div className="text-end">
                                {signleData?.staff_response === 0 && <button onClick={() => onStatus(signleData, 1)} className="btn btn-success">
                                    Accept
                                </button>}
                            </div>
                        }
                    >
                        <div style={{ height: global.config.windowHeight / 2.5, overflowY: 'scroll' }}>
                            <div className="col-xl-12 col-md-12">
                                <div className="card user-card-full">
                                    <div className="row m-l-0 m-r-0">
                                        <div className="col-sm-12">
                                            <div className="card-block"><h6 className="m-b-20 p-b-5 b-b-default f-w-600">Organization Details</h6> <div class="row">
                                                {signleData && signleData?.user?.organization_name && <div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">Organization Name</p>
                                                    <h6 className="text-muted f-w-400">{signleData?.user?.organization_name}</h6>
                                                </div>}
                                                {signleData && signleData?.user?.organization_type && <div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">Organization Type</p>
                                                    <h6 className="text-muted f-w-400">{signleData?.user?.organization_type}</h6>
                                                </div>}
                                                {signleData && signleData?.user?.email && <div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">Email</p>
                                                    <h6 className="text-muted f-w-400">{signleData?.user?.email}</h6>
                                                </div>}
                                                {signleData && signleData?.user?.country_code && <div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">Country Code</p>
                                                    <h6 className="text-muted f-w-400">{signleData?.user?.country_code}</h6>
                                                </div>}
                                                {signleData && signleData?.user?.contact && <div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">Contact</p>
                                                    <h6 className="text-muted f-w-400">{signleData?.user?.contact}</h6>
                                                </div>}
                                                {signleData && signleData?.user?.extention_times && <div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">Extention Times</p>
                                                    <h6 className="text-muted f-w-400">{signleData?.extention_times}</h6>
                                                </div>}
                                            </div>

                                            </div>

                                            <div className="card-block"><h6 className="m-b-20 p-b-5 b-b-default f-w-600">Shift Details</h6> <div class="row">
                                                {signleData && signleData?.shift_type && <div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">Shift Type</p>
                                                    <h6 className="text-muted f-w-400">{signleData?.shift_type}</h6>
                                                </div>}
                                                {signleData && signleData?.category && <div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">Category</p>
                                                    <h6 className="text-muted f-w-400">{signleData?.category}</h6>
                                                </div>}
                                                {signleData && signleData?.city && <div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">City</p>
                                                    <h6 className="text-muted f-w-400">{signleData?.city}</h6>
                                                </div>}
                                                {signleData && signleData?.state && <div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">State</p>
                                                    <h6 className="text-muted f-w-400">{signleData?.state}</h6>
                                                </div>}

                                                {<div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">Status</p>
                                                    <h6 className="text-muted f-w-400">

                                                        {signleData?.staff_response == 0 ?
                                                            <CFormLabel className="w-100 text-warning" >
                                                                Pending
                                                            </CFormLabel>
                                                            : signleData?.staff_response == 1 ? <CFormLabel className="w-100 text-success" >
                                                                Accepted
                                                            </CFormLabel>
                                                                : signleData?.staff_response == 2 ? <CFormLabel className="w-100 text-danger" >
                                                                    Rejected
                                                                </CFormLabel>
                                                                    : null
                                                        }
                                                    </h6>
                                                </div>}
                                            </div>
                                            </div>
                                            <h6 className="m-b-20 p-b-5 b-b-default f-w-600">Shifts</h6>
                                            <div className="row">
                                                {signleData?.tenure && <div className="col-sm-12">
                                                    {/* <p className="m-b-10 f-w-600">Shift Slots</p> */}
                                                    <ul className="list-unstyled">
                                                        <div className="row">
                                                            {JSON?.parse(signleData?.tenure)?.map((slot, index) =>
                                                                <div className="col-sm-6 col-md-6 col-lg-4" key={index + 'slots'}>
                                                                    <li>
                                                                        <input className={slot?.filled == 1 ? "slot green-border" : "slot"} readOnly value={slot?.date} />
                                                                        <TimeRangePicker
                                                                            className={slot?.filled == 1 ? "timer-filled" : "timer"}
                                                                            value={slot?.time}
                                                                            rangeDivider="To"
                                                                            clearIcon={null}
                                                                            disabled
                                                                        />
                                                                    </li>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </ul>
                                                </div>}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </BottomSheet>
                </div>
            </>
            <Spinner isLoading={StaffsStore.loading} />
        </React.Fragment>
    )
});
export default NewOpportunity;