import React, { useState, useEffect } from "react";
import "react-step-progress-bar/styles.css";
import Confetti from 'react-confetti'
const liveStatusMsg = "We regret to inform you that your account is currently inactive. Our system indicates that your account has been temporarily disabled for unknown reasons. To resolve this issue and reactivate your account, we kindly request you to contact the administrator or support team."
const MultiStepProgressBar = props => {
    const [progressOne, setProgressOne] = useState();
    const [progressTwo, setProgressTwo] = useState();
    const [progressThree, setProgressThree] = useState();
    const [progressFour, setProgressFour] = useState();
    const [progressFive, setProgressFive] = useState();
    const [progressSix, setProgressSix] = useState();
    const [confettiVisible, setConfettiVisible] = useState(false);
    const [currentStatus, setCurrentStatus] = useState();

    useEffect(() => {
            if (props.blueSkyStatus == 0) {
                setCurrentStatus(0);
            } else if (props.blueSkyStatus == 1) {
                setCurrentStatus(1)
            }
    }, [props.blueSkyStatus])

    useEffect(() => {
        // PROGRESS ONE 
        let checkProgressOne = {
            "first_name": props?.data?.first_name,
            "last_name": props?.data?.last_name,
            "state_id": props?.data?.state_id,
            "city": props?.data?.city,
            "address": props?.data?.address,
            "preferred_state": props?.data?.preferred_state?.length,
            "preferred_cities": props?.data?.preferred_cities?.length,
            "zipcode": props?.data?.zipcode,
        }
        // Get the total number of keys
        const total_keys = Object.keys(checkProgressOne).length;

        // Get the number of non-empty keys
        const non_empty_keys = Object.values(checkProgressOne).filter(value => {
            return value !== "" && value !== undefined && !Array.isArray(value) && typeof value !== "object";
        }).length;

        // Calculate the percentage of filled key values
        const filled_percentage = (non_empty_keys / total_keys) * 100;
        setProgressOne(`${filled_percentage.toFixed(0)}%`)
        // PROGRESS ONE END

        // PROGRESS Two 
        let checkProgressTwo = {
            "degree_specialities": props?.data?.degree_specialities[0],
            "contact_devices": props?.data?.contact_devices[0]
        }
        const total_keys_two = Object.keys(checkProgressTwo).length;
        // Get the number of non-empty keys
        const non_empty_keys_two = Object.values(checkProgressTwo).filter(value => {
            return value?.type !== "" && value?.type !== undefined && !Array.isArray(value?.type) && typeof value?.type !== "object" &&
                value?.number !== "" && value?.number !== undefined && !Array.isArray(value?.number) && typeof value?.number !== "object"
                || value?.profession !== "" && value?.profession !== undefined && !Array.isArray(value?.profession) && typeof value?.profession !== "object"
                ;
        }).length;
        // Calculate the percentage of filled key values
        const filled_percentage_two = (non_empty_keys_two / total_keys_two) * 100;
        setProgressTwo(`${filled_percentage_two.toFixed(0)}%`)
        // PROGRESS TWO END 

        // PROGRESS THREE
        //Three Availablity check progress
        let checkProgressThree = {
            "interested_in": props?.data?.interested_in,
            "availability": props?.data?.availability
        }

        // Get the total number of keys
        const total_keys_three = Object.keys(checkProgressThree).length;

        // Get the number of non-empty keys
        const non_empty_keys_three = Object.values(checkProgressThree).filter(value => {
            return value ? true : false;
        }).length;

        // Calculate the percentage of filled key values
        const filled_percentage_three = (non_empty_keys_three / total_keys_three) * 100;
        setProgressThree(`${filled_percentage_three.toFixed(0)}%`)
        // PROGRESS THREE END

        // PROGRESS FOUR
        //Four Documents check progress
        const filled_percentage_four = ((props.reqJobDocuments?.length - props?.data.req_doc_list.filter(x => !x.file)?.length) / props.reqJobDocuments?.length) * 100
        setProgressFour(`${filled_percentage_four.toFixed(0)}%`)
        // PROGRESS FOUR END

        // PROGRESS FIVE

        let checkProgressFive = {
            "equal_opportunity": props?.data?.equal_opportunity == 'true' ? props?.data?.equal_opportunity : (props?.data?.hispanic && props?.data?.gender ? "true" : false),
            "disability": props?.data?.disability,
        }

        // Get the total number of keys
        const total_keys_five = Object.keys(checkProgressFive).length;

        // Get the number of non-empty keys
        const non_empty_keys_five = Object.values(checkProgressFive).filter(value => {
            return value !== "" && value !== "false" && value !== false && value !== undefined && !Array.isArray(value) && typeof value !== "object" && value !== 0;
        }).length;

        // Calculate the percentage of filled key values
        const filled_percentage_five = (non_empty_keys_five / total_keys_five) * 100;
        setProgressFive(`${filled_percentage_five.toFixed(0)}%`)
        // PROGRESS FIVE END

        let checkProgressSix = {
            "birth_date": props?.data?.birth_date,
            "ssn": props?.data?.ssn,
            "tnc": props?.data?.tnc,
        }

        const total_keys_six = Object.keys(checkProgressSix).length;
        // Get the number of non-empty keys
        const non_empty_keys_six = Object.values(checkProgressSix).filter(value => {
            return value !== "" && value !== undefined && !Array.isArray(value) && typeof value !== "object" && value !== 0
        }).length;
        // Calculate the percentage of filled key values
        const filled_percentage_six = (non_empty_keys_six / total_keys_six) * 100;

        setProgressSix(`${filled_percentage_six.toFixed(0)}%`)

        if (props.jobProfile?.status === 1 && !props?.exported) {
            setConfettiVisible(true)
        } else {
            setConfettiVisible(false)
        }
    }, [props?.data, props?.reqJobDocuments])
    
    return (
        <div className="bg-white shadow-sm  border p-4 p-sm-4 mb-2 wow bounceInDown" style={{ borderRadius: 25 }}>
            {confettiVisible && <div className="row">
                <Confetti recycle={false} height={global.config.windowHeight} width={global.config.windowWidth} />
            </div>}
            <div className="row d-flex align-items-center">
                <div className="col-lg-3 col-sm-6 progress-width" style={{ width: "21%" }}>
                    <h3 className="fs-title">Application Progress:</h3>
                </div>
                <div className={"col-lg-9 pb-3"} style={{ marginTop: 2 }}>
                    {currentStatus === 1 ? <b className="text-warning">{liveStatusMsg}</b>
                        : (props.jobProfile?.status === 2)
                            ? <div className="d-flex justify-content-start text-danger"><small><b>Rejected&nbsp;</b> </small>
                                {props.jobProfile?.remark && <>
                                    <small><b>Reason</b></small>
                                    : {props.jobProfile?.remark}</>}

                            </div> :
                            props.jobProfile?.status === 3 ? <b className="text-success">Active <i class="far fa-check-circle"></i> </b> :
                                props.jobProfile?.status === 4 ? <b className="text-danger">In-Active</b> :
                                    props.jobProfile?.status === 5 ? <b className="text-danger">In-Active</b> :
                                        props.jobProfile?.status === 6 ? <b className="text-danger">In-Active</b> :
                                            (props.jobProfile?.status === 1 && props?.exported) ? <b className="text-danger">In-Active</b> : props.jobProfile?.status === 1 ? <b className="text-success">Your account has been approved so please <a href="/onboarding-documents"><u>Click Here</u></a> to sign the necessary documents. </b> :
                                                props.jobProfile?.status === 0 ? <b className="text-warning">Pending! We wanted to reach out to you with an exciting update regarding your job profile at Staffers. We appreciate your patience throughout the process, and we are pleased to let you that your job profile is currently under review.</b> :
                                                    (progressOne === '100%' && progressTwo !== '100%') ? "Getting Started" : (progressOne === '100%' && progressTwo === '100%' && progressThree !== '100%') ? "In Progress" : (progressOne === '100%' && progressTwo === '100%' && progressThree === '100%' && progressFour !== '100%') ? "Halfway There" :
                                                        (progressOne === '100%' && progressTwo === '100%' && progressThree === '100%' && progressFour === '100%' && progressFive !== '100%') ? "Making Progress" : (progressOne === '100%' && progressTwo === '100%' && progressThree === '100%' && progressFour === '100%' && progressFive === '100%' && progressSix !== '100%') ? "Almost Done" :
                                                            progressSix === '100%' ? "Well Done!" :
                                                                "Getting Started"}</div>
            </div>
           
            {!props?.exported && <div className="row g-3 d-flex justify-content-center">
                <div className="col-lg-2 text-center pointer" onClick={() => props.onTabClick(0, props.resetForm)}>
                    <div className="progress mb-2">
                        <div className={props?.exported || props?.jobProfile?.status === 1 ? 'progress-bar progress-complete progress-bar-striped progress-bar-animated w-100' : progressOne === '100%' ? `progress-bar progress-complete progress-bar-striped progress-bar-animated` : `progress-bar progress-pending progress-bar-striped progress-bar-animated`} style={{ width: progressOne }} role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <strong>Personal Info</strong>
                </div>
                <div className="col-lg-2 text-center pointer" onClick={() => props.onTabClick(1, props.resetForm)}>
                    <div className="progress mb-2">
                        <div className={props?.exported || props?.jobProfile?.status === 1 ? 'progress-bar progress-complete progress-bar-striped progress-bar-animated w-100' : progressTwo === '100%' ? `progress-bar progress-complete progress-bar-striped progress-bar-animated` : `progress-bar progress-pending progress-bar-striped progress-bar-animated`} style={{ width: progressTwo }} role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <strong>Professional Info</strong>
                </div>
                <div className="col-lg-2 text-center pointer" onClick={() => props.onTabClick(2, props.resetForm)}>
                    <div className="progress mb-2">
                        <div className={props?.exported || props?.jobProfile?.status === 1 ? 'progress-bar progress-complete progress-bar-striped progress-bar-animated w-100' : progressThree === '100%' ? `progress-bar progress-complete progress-bar-striped progress-bar-animated` : `progress-bar progress-pending progress-bar-striped progress-bar-animated`} style={{ width: progressThree }} role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <strong>Availability</strong>
                </div>

                <div className="col-lg-2 text-center pointer" onClick={() => props.onTabClick(3, props.resetForm)}>
                    <div className="progress mb-2">
                        <div className={props?.exported || props?.jobProfile?.status === 1 ? 'progress-bar progress-complete progress-bar-striped progress-bar-animated w-100' : progressFour === '100%' ? `progress-bar progress-complete progress-bar-striped progress-bar-animated` : `progress-bar progress-pending progress-bar-striped progress-bar-animated`} style={{ width: progressFour }} role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <strong>Documents</strong>
                </div>
                <div className="col-lg-2 text-center pointer" onClick={() => props.onTabClick(4, props.resetForm)}>
                    <div className="progress mb-2">
                        <div className={props?.exported || props?.jobProfile?.status === 1 ? 'progress-bar progress-complete progress-bar-striped progress-bar-animated w-100' : progressFive === '100%' ? `progress-bar progress-complete progress-bar-striped progress-bar-animated` : `progress-bar progress-pending progress-bar-striped progress-bar-animated`} style={{ width: progressFive }} role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <strong>Acknowledgments</strong>
                </div>
                <div className="col-lg-2 text-center pointer" onClick={() => props.onTabClick(5, props.resetForm)}>
                    <div className="progress mb-2">
                        <div className={props?.exported || props?.jobProfile?.status === 1 ? 'progress-bar progress-complete progress-bar-striped progress-bar-animated w-100' : progressSix === '100%' ? `progress-bar progress-complete progress-bar-striped progress-bar-animated` : `progress-bar progress-pending progress-bar-striped progress-bar-animated`} style={{ width: progressSix }} role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <strong>Finish</strong>
                </div>
            </div>}
        </div>

    );
};

export default MultiStepProgressBar;
