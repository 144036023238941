/* eslint-disable */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { BASE_API_URL_INT } from "@api/config";
import { Helmet } from "react-helmet";
import Footer from "@components/Footer";
import { InputField } from "@elements/index";
import { Formik } from "formik";
import * as Yup from "yup";
import Select, { components, ValueContainerProps } from "react-select";
import UserStore from "@store/UserStore";
import HomeStore from "@store/HomeStore";
import StaffsStore from "@store/StaffsStore";
import Spinner from "@components/Spinner";
import swal from "@sweetalert/with-react";
import secureLocalStorage from "react-secure-storage";
import SelectField from "@elements/select/SelectField";
import { useNavigate } from "react-router-dom";
import { toJS } from "mobx";
var data = require("@assets/CountryCodes.json");
var citys = require("@assets/city.json");
var states = require("@assets/states.json");
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const colourStylesTitle = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderColor: state.isFocused ? "blue" : "#75459b",
    borderRadius: 20,
    "&:focus": {
      border: "1px solid blue !important",
    },
    "&:hover": {
      border: "1px solid #8282C8 !important",
    },
  }),
  menu: (base) => ({
    ...base,
    fontSize: 12,
  }),
  menuList: (base) => ({
    ...base,
    height: "auto",

    "::-webkit-scrollbar": {
      width: "9px",
    },
    "::-webkit-scrollbar-track": {
      background: "white",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#8282C8",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
};
const colourStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderColor: state.isFocused ? "blue" : "#75459b",
    borderRadius: 20,
    "&:focus": {
      border: "1px solid blue !important",
    },
    "&:hover": {
      border: "1px solid #8282C8 !important",
    },
  }),
  menu: (base) => ({
    ...base,
    fontSize: 12,
  }),
  menuList: (base) => ({
    ...base,
    height: "200px",

    "::-webkit-scrollbar": {
      width: "9px",
    },
    "::-webkit-scrollbar-track": {
      background: "white",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#8282C8",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
};
const ClientUpdateSchema = Yup.object().shape({
  organization_name: Yup.string()
    .required("Organization Name required")
    .nullable(),
  organization_type: Yup.string()
    .required("Organization Type required")
    .nullable(),
  designation: Yup.string().required("Job Title required").nullable(),
  // region_id: Yup.string().required('Region Id required').nullable(),
  first_name: Yup.string()
    .required("First name required")
    .nullable()
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
  last_name: Yup.string()
    .required("Last name required")
    .nullable()
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
  contact: Yup.string()
    .matches(phoneRegExp, "Contact number is not valid")
    .required("Contact number required")
    .min(7)
    .max(15, "Wrong contact number")
    .nullable(),
});
const StaffUpdateSchema = Yup.object().shape({
  profession: Yup.string().required("Profession required").nullable(),
  first_name: Yup.string()
    .required("First name required")
    .nullable()
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
  last_name: Yup.string()
    .required("Last name required")
    .nullable()
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
  contact: Yup.string()
    .matches(phoneRegExp, "Contact number is not valid")
    .required("Contact number required")
    .min(7)
    .max(15, "Wrong contact number")
    .nullable(),
});
const colourStylesState = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderColor: state.isFocused ? "blue" : "#75459b",
    borderRadius: 20,
    boxShadow: "none",
    padding: "2px 10px",
    "&:focus": {
      border: "1px solid blue !important",
    },
    "&:active": {
      border: "1px solid blue !important",
    },
    "&:hover": {
      border: "1px solid blue !important",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    fontSize: 12,
  }),
  menu: (base) => ({
    ...base,
    fontSize: 12,
    zIndex: 200, // set the z-index to a high value
  }),
  menuList: (base) => ({
    ...base,
    height: "200px",
    "::-webkit-scrollbar": {
      width: "9px",
    },
    "::-webkit-scrollbar-track": {
      background: "white",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#8282C8",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
};

const LinkedInAuth = observer(() => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState();
  const [refresh, setRefresh] = useState();
  const [regions, setRegions] = useState([]);
  const [degrees, setDegrees] = useState([]);

  useEffect(() => {
    StaffsStore.getRegionsList();
    setRefresh(localStorage.getItem("refresh-token"));
    try {
      if (secureLocalStorage.getItem("type") == "false") {
        UserStore.loading = true;
        fetch(
          `${BASE_API_URL_INT}user/loginSocial/linkedin/callback${window?.location?.search}`,
          { headers: new Headers({ accept: "application/json" }) }
        )
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
            throw new Error("Something went wrong!");
          })
          .then((data) => {
            if (data?.status === 3) {
              UserStore.loading = false;
              secureLocalStorage.removeItem("token");
              secureLocalStorage.removeItem("type");
              swal(
                "Blocked!",
                "Your account has been temporarily disabled. Please contact the Administrator of Staffers.",
                "error"
              );
              navigate("/");
              return;
            }
            if (data?.status === 2) {
              UserStore.loading = false;
              secureLocalStorage.removeItem("token");
              secureLocalStorage.removeItem("type");
              swal(
                "Rejected!",
                "Your account verification is rejected. Please contact Staffers administrator",
                "error"
              );
              navigate("/");
              return;
            }
            if (data?.status === 1) {
              UserStore.updateAsyncStore(data);
              secureLocalStorage.setItem("type", "false");
              navigate("/portal");
            } else {
              UserStore.loading = false;
              secureLocalStorage.setItem("token", data?.token);
              setUserData(data);
            }
          })
          .catch((error) => {
            UserStore.loading = false;
            secureLocalStorage.removeItem("token");
            secureLocalStorage.removeItem("type");
            navigate("/login/employer");
          });
      } else {
        StaffsStore.loading = true;
        fetch(
          `${BASE_API_URL_INT}staff/loginSocial/linkedin/callback${window?.location?.search}`,
          { headers: new Headers({ accept: "application/json" }) }
        )
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
            throw new Error("Something went wrong!");
          })
          .then((data) => {
            if (data?.status === 3) {
              StaffsStore.loading = false;
              secureLocalStorage.removeItem("token");
              secureLocalStorage.removeItem("type");
              swal(
                "Blocked!",
                "Your account has been temporarily disabled. Please contact the Administrator of Staffers.",
                "error"
              );
              navigate("/");
              return;
            }
            if (data?.status === 2) {
              StaffsStore.loading = false;
              secureLocalStorage.removeItem("token");
              secureLocalStorage.removeItem("type");
              swal(
                "Rejected!",
                "Your account verification is rejected. Please contact Staffers administrator",
                "error"
              );
              navigate("/");
              return;
            }
            if (data?.profession) {
              StaffsStore.updateAsyncStore(data);
              secureLocalStorage.setItem("type", "true");
              navigate("/portal");
            } else {
              StaffsStore.loading = false;
              secureLocalStorage.setItem("token", data?.token);
              setUserData(data);
            }
          })
          .catch((error) => {
            StaffsStore.loading = false;
            secureLocalStorage.removeItem("token");
            secureLocalStorage.removeItem("type");
            navigate("/login/employee");
          });
      }
    } catch (e) {
      secureLocalStorage.removeItem("token");
      secureLocalStorage.removeItem("type");
      navigate("/");
    }
  }, []);

  useEffect(() => {
    setRegions(StaffsStore?.data?.regionsList);
  }, [StaffsStore?.data?.regionsList]);

  useEffect(() => {
    const degreesData = HomeStore.data.dashboardData?.find(
      (x) => x.key_name === "DEGREES"
    );
    if (degreesData) setDegrees(JSON.parse(toJS(degreesData.value)));
  }, [HomeStore.data.dashboardData]);
  const updateForm = (values) => {
    values.contact = userData?.contact ? null : values.contact;
    if (secureLocalStorage.getItem("type") === "false") {
      UserStore.updateUserProfile(values, navigationCallBack);
    } else {
      StaffsStore.updateStaffProfile(values, navigationCallBackStaffs);
    }
  };
  const navigationCallBackStaffs = (values) => {
    secureLocalStorage.setItem("userData", JSON.stringify(userData));
    StaffsStore.loading = false;
    window.location.replace("/portal");
  };

  const navigationCallBack = () => {
    swal(
      "Updated!",
      "Your account is under review. You will be notified via mail once activated.",
      "success"
    );
    UserStore.loading = false;
    secureLocalStorage.removeItem("token");
    secureLocalStorage.removeItem("type");
    navigate("/");
  };
  return (
    <React.Fragment>
      {userData && (
        <>
          {/* <Header /> */}
          <Helmet>
            <title>Update Profile</title>
          </Helmet>
          <div className="container-fluid callback mt-5 py-5">
            <div className="container pt-5">
              <h5 className="display-5 mb-4 animated slideInDown login-head">
                Update Profile
              </h5>
              <div className="row justify-content-center ">
                <div className="col-lg-6">
                  <div
                    className="bg-white shadow border p-4 p-sm-4 wow fadeInUp"
                    style={{ borderRadius: 25 }}
                    data-wow-delay="0.3s"
                  >
                    <>
                      <Formik
                        initialValues={{
                          title: userData?.title || "Mr.",
                          email: userData?.email,
                          first_name: userData?.first_name,
                          last_name: userData?.last_name,
                          organization_name: userData?.organization_name,
                          organization_type: userData?.organization_type,
                          region_id: userData?.region_id,
                          profession: userData?.profession,
                          contact: userData?.contact,
                          country_code: userData?.country_code || "+1",
                          state: userData?.state || "",
                          city: userData?.city || "",
                          address: userData?.address || "",
                          zipcode: userData?.zipcode || "",
                          description: userData?.description || "",
                          designation: userData?.designation,
                          password: "",
                        }}
                        onSubmit={(values) => updateForm(values)}
                        validationSchema={
                          secureLocalStorage.getItem("type") === "false"
                            ? ClientUpdateSchema
                            : StaffUpdateSchema
                        }
                        enableReinitialize={true}
                      >
                        {({
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          setFieldValue,
                          values,
                          errors,
                          touched,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <div className="row g-3">
                              {/* <div className="col-sm-2">
                                                                <Select
                                                                    onChange={(e) => setFieldValue("title", e.value)}
                                                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                                    placeholder={'Title'}
                                                                    styles={colourStylesTitle}
                                                                    isSearchable={true}
                                                                    value={values.title && { label: `${values.title}`, value: `${values.title}` } || ''}
                                                                    options={global.config.titleList}
                                                                />
                                                            </div> */}
                              <div className="col-sm-6">
                                <InputField
                                  className={"did-floating-input"}
                                  type="text"
                                  name="first_name"
                                  label={"First Name"}
                                  value={values.first_name || ""}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {errors.first_name ? (
                                  <div className="error-message">
                                    {errors.first_name}
                                  </div>
                                ) : null}
                              </div>
                              <div className="col-sm-6">
                                <InputField
                                  className={"did-floating-input"}
                                  type="text"
                                  name="last_name"
                                  label={"Last Name*"}
                                  value={values.last_name || ""}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {errors.last_name ? (
                                  <div className="error-message">
                                    {errors.last_name}
                                  </div>
                                ) : null}
                              </div>
                              {secureLocalStorage.getItem("type") ===
                                "false" && (
                                <>
                                  <div className="col-sm-6">
                                    <InputField
                                      className={"did-floating-input"}
                                      type="text"
                                      name="organization_name"
                                      label={"Organization Name"}
                                      value={values.organization_name || ""}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    {errors.organization_name ? (
                                      <div className="error-message">
                                        {errors.organization_name}
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className="col-sm-6">
                                    <SelectField
                                      name="organization_type"
                                      label={"Organization Type*"}
                                      value={values.organization_type}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      data={global.config.organizationType}
                                      optionLabel={"label"}
                                      optionValue={"value"}
                                    />
                                    {errors.organization_type ? (
                                      <div className="error-message">
                                        {errors.organization_type}
                                      </div>
                                    ) : null}
                                  </div>
                                </>
                              )}
                              <div className="col-sm-2">
                                <Select
                                  onChange={(e) =>
                                    setFieldValue("country_code", e.label)
                                  }
                                  components={{
                                    DropdownIndicator: () => null,
                                    IndicatorSeparator: () => null,
                                  }}
                                  placeholder={""}
                                  styles={colourStyles}
                                  value={
                                    data.filter(
                                      ({ label }) =>
                                        label === values.country_code
                                    ) || ""
                                  }
                                  isSearchable={true}
                                  options={data}
                                  defaultValue={{ label: "+1", value: "CA" }}
                                />
                              </div>

                              <div className="col-sm-4">
                                <InputField
                                  className={"did-floating-input"}
                                  type="text"
                                  name="contact"
                                  label={"Contact Number"}
                                  value={
                                    values?.contact
                                      ?.replace(/[^\d]/g, "")
                                      .replace(
                                        /(\d{3})(\d{3})(\d{4})/,
                                        "$1-$2-$3"
                                      ) || ""
                                  }
                                  maxLength={12}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {errors.contact ? (
                                  <div className="error-message">
                                    {errors.contact}
                                  </div>
                                ) : null}
                              </div>
                              <div className="col-sm-6">
                                <InputField
                                  className={"did-floating-input"}
                                  type="email"
                                  name={"email"}
                                  label={"Email Address"}
                                  value={values.email || ""}
                                  disabled={true}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </div>
                              {secureLocalStorage.getItem("type") ===
                                "false" && (
                                <>
                                  {/* <div className="col-sm-6">
                                                                    <SelectField
                                                                        name="region_id"
                                                                        label={"Add Your Region"}
                                                                        value={values.region_id}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        data={regions}
                                                                        optionLabel={"Name"}
                                                                        optionValue={"ID"}
                                                                        required={true}
                                                                    />
                                                                    {errors.region_id ? (
                                                                        <div className="error-message">{errors.region_id}</div>
                                                                    ) : null}
                                                                </div> */}
                                  <div className="col-sm-12">
                                    <InputField
                                      className={"did-floating-input"}
                                      type="text"
                                      name="designation"
                                      label={"Job Title"}
                                      value={values.designation || ""}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    {errors.designation ? (
                                      <div className="error-message">
                                        {errors.designation}
                                      </div>
                                    ) : null}
                                  </div>
                                  <>
                                    <div className="col-sm-6">
                                      <InputField
                                        className={"did-floating-input"}
                                        type={"text"}
                                        name="address"
                                        label={"Full Address"}
                                        value={values.address}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                    </div>
                                    <div className="col-sm-6">
                                      <Select
                                        onChange={(e) => {
                                          setFieldValue(
                                            "state",
                                            e.abbreviation
                                          ),
                                            setFieldValue("city", "");
                                        }}
                                        placeholder={""}
                                        label={"State"}
                                        styles={colourStylesState}
                                        value={
                                          states.filter(({ abbreviation }) =>
                                            values?.state?.includes(
                                              abbreviation
                                            )
                                          ) || ""
                                        }
                                        options={states}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) =>
                                          option.abbreviation
                                        }
                                        components={{
                                          Control,
                                          DropdownIndicator: () => null,
                                          IndicatorSeparator: () => null,
                                        }}
                                      />
                                    </div>
                                    <div className="col-sm-6">
                                      <Select
                                        onChange={(e) => {
                                          setFieldValue("city", e.label);
                                        }}
                                        placeholder={""}
                                        label={"City"}
                                        isDisabled={!values?.state}
                                        styles={colourStylesState}
                                        getOptionLabel={(option) =>
                                          option.label
                                        }
                                        getOptionValue={(option) =>
                                          option.label
                                        }
                                        value={
                                          citys.filter(
                                            ({ label }) =>
                                              values?.city === label
                                          ) || ""
                                        }
                                        options={citys?.filter(
                                          (ct) =>
                                            ct.state ===
                                            states?.find(
                                              (x) =>
                                                x.abbreviation == values?.state
                                            )?.name
                                        )}
                                        components={{
                                          Control,
                                          DropdownIndicator: () => null,
                                          IndicatorSeparator: () => null,
                                        }}
                                      />
                                    </div>
                                    <div className="col-sm-6">
                                      <InputField
                                        name={"zipcode"}
                                        className={"did-floating-input"}
                                        type="number"
                                        label={"Zip Code"}
                                        value={values.zipcode}
                                        onChange={(event) => {
                                          handleChange(event);
                                        }}
                                        onBlur={handleBlur}
                                      />
                                    </div>
                                  </>
                                </>
                              )}
                              {secureLocalStorage.getItem("type") ===
                                "true" && (
                                <>
                                  <SelectField
                                    name="profession"
                                    label={"Profession*"}
                                    value={values.profession}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    data={degrees}
                                    optionLabel={"Name"}
                                    optionValue={"Id"}
                                  />
                                  {errors.profession && touched.profession ? (
                                    <div className="error-message">
                                      {errors.profession}
                                    </div>
                                  ) : null}
                                </>
                              )}
                              <div className="col-12">
                                <label className="pb-2 label">
                                  Tell us about yourself
                                </label>
                                <div className="form-floating">
                                  <textarea
                                    className="form-control"
                                    placeholder=""
                                    name={"description"}
                                    value={values.description || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    style={{ height: 100 }}
                                  ></textarea>
                                </div>
                              </div>
                              <div className="pt-4 d-flex justify-content-center">
                                <button
                                  className="btn btn-primary w-50 p-2"
                                  type="submit"
                                >
                                  Update
                                </button>
                              </div>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </>
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
          <Footer />
        </>
      )}
      <Spinner isLoading={UserStore.loading || StaffsStore.loading} />
    </React.Fragment>
  );
});
export const Control = (props) => {
  return (
    <>
      <label
        className={
          props.hasValue || props.isFocused
            ? "did-floating-label did-floating-label-top "
            : "did-floating-label did-floating-label"
        }
        style={{ zIndex: 999 }}
      >
        {props?.selectProps?.label}
      </label>
      <components.Control {...props} />
    </>
  );
};

export default LinkedInAuth;
