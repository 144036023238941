const LimitString = (inputString, limit) => {
  let words = inputString.split(/\s+/);

  let limitedWords = words.slice(0, limit);

  let resultString = limitedWords.join(" ");

  if (words.length > limit) {
    resultString += "...";
  }

  return resultString;
};

export default LimitString;

export const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  } else {
    return text;
  }
};
