/* eslint-disable */
import React, { useEffect } from "react";
import BaseLayout from "../components/BaseLayout";
import { useLocation } from 'react-router-dom';

const WhyChooseUs = () => {
    const params = useLocation();
    window.onscroll = function () { };

    useEffect(() => {
        if (params?.state?.type === 'client') {
            goToClient()
        } else if (params?.state?.type === 'staff') {
            goToStaff()
        }
    }, [params?.state?.type]);

    const goToClient = () => {
        const element = document.getElementById('client');
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const goToStaff = () => {
        const element = document.getElementById('staff');
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <BaseLayout title={'Staffers LLC | How Does It Work?'}>
            <div className="container-fluid p-0 wow fadeIn" data-wow-delay="0.03s">
                <div id="header-carousel" className="carousel slide carousel-fade" data-bs-ride="carousel">
                    <div className="carousel-inner carousel-radius">
                        <div className="carousel-item active">
                            <img className="w-100" src="assets/img/carousel-1.png" alt="banner" />
                            <div className="carousel-caption">
                                <div className="container">
                                    <div className="row justify-content-start">
                                        <div className="col-lg-8 pt-5">
                                            <p
                                                className="display-6 d-inline-block text-light py-1 px-0 animated slideInDown">
                                                COMING TO THE RESCUE</p>
                                            <h1 className="display-4 mb-4 animated slideInDown text-light">How Does It Work?</h1>
                                            {/* <small className="mb-4 animated slideInDown text-light">We are dedicated to offering high-quality, at-home healthcare support in order to guarantee that all of our customers receive the best possible care. Our team of skilled elder care professionals not only provide first-rate care but also treats them like members of their own family.
                                            </small>  */}
                                            <div className="pt-3">
                                                <button className="bg-primary border-2 border-light rounded-2 text-light py-3 px-5 mb-3 animated slideInDown" onClick={() => goToClient()}>Looking For Caregiver?</button>
                                                {/* gotostaff  */}
                                                <button className="bg-primary border-2 border-light rounded-2 text-light py-3 px-5 mb-3 ms-1 animated slideInDown" onClick={() => goToStaff()}>Looking For Job?</button>

                                                &nbsp;
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <section data-wow-delay="0.1s">
                <div className="container-xxl feature py-5">
                    <div className="container">
                        <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s">
                            <h3 className="d-inline-block text-gray fw-semi-bold py-3 px-3 font-weight-normal ">We’ve worked with some great companies already. We think you should join them in your quest for top talent.</h3>
                        </div>
                        <div class="row g-5 align-items-center">
                            <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                                <h2 class="display-5 mb-4">Why People Choosing Us?</h2>
                                <p class="mb-4">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu diam amet diam et
                                    eos. Clita erat ipsum et lorem et sit, sed stet lorem sit clita duo justo magna dolore erat amet
                                </p>
                                <a class="btn btn-primary py-3 px-5" href="">Explore More</a>
                            </div>
                            <div class="col-lg-6">
                                <div class="row g-4 align-items-center">
                                    <div class="col-md-6">
                                        <div class="row g-4">
                                            <div class="col-12 wow fadeIn" data-wow-delay="0.3s">
                                                <div class="feature-box border rounded p-4">
                                                    <i class="fa fa-check fa-3x text-primary mb-3"></i>
                                                    <h4 class="mb-3">Fast Hiring Process</h4>
                                                    <p class="mb-3">Clita erat ipsum et lorem et sit, sed stet lorem sit clita duo
                                                        justo erat amet</p>
                                                    <a class="fw-semi-bold" href="">Read More <i
                                                class="fa fa-arrow-right ms-1"></i></a>
                                                </div>
                                            </div>
                                            <div class="col-12 wow fadeIn" data-wow-delay="0.5s">
                                                <div class="feature-box border rounded p-4">
                                                    <i class="fa fa-check fa-3x text-primary mb-3"></i>
                                                    <h4 class="mb-3">Low Fees</h4>
                                                    <p class="mb-3">Clita erat ipsum et lorem et sit, sed stet lorem sit clita duo
                                                        justo erat amet</p>
                                                    <a class="fw-semi-bold" href="">Read More <i
                                                class="fa fa-arrow-right ms-1"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 wow fadeIn" data-wow-delay="0.7s">
                                        <div class="feature-box border rounded p-4">
                                            <i class="fa fa-check fa-3x text-primary mb-3"></i>
                                            <h4 class="mb-3">Large Talent Pool</h4>
                                            <p class="mb-3">Clita erat ipsum et lorem et sit, sed stet lorem sit clita duo justo
                                                erat amet</p>
                                            <a class="fw-semi-bold" href="">Read More <i class="fa fa-arrow-right ms-1"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <section>
                <div class="container-fluid facts my-5 py-5">
                    <div class="container py-2">
                        <div class="row g-5">
                            <div class="col-sm-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.1s">
                                <i class="fa fa-briefcase fa-3x text-white mb-3"></i>
                                <h1 class="display-4 text-white" data-toggle="counter-up">8000+</h1>
                                <span class="fs-5 text-white">Jobs</span>
                                <hr class="bg-white w-25 mx-auto mb-0" />
                            </div>
                            <div class="col-sm-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.3s">
                                <i class="fa fa-check fa-3x text-white mb-3"></i>
                                <h1 class="display-4 text-white" data-toggle="counter-up">2000+</h1>
                                <span class="fs-5 text-white">Clients</span>
                                <hr class="bg-white w-25 mx-auto mb-0" />
                            </div>
                            <div class="col-sm-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.5s">
                                <i class="fa fa-users-cog fa-3x text-white mb-3"></i>
                                <h1 class="display-4 text-white" data-toggle="counter-up">1500</h1>
                                <span class="fs-5 text-white">Dedicated Staff</span>
                                <hr class="bg-white w-25 mx-auto mb-0" />
                            </div>
                            <div class="col-sm-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.7s">
                                <i class="fa fa-award fa-3x text-white mb-3"></i>
                                <h1 class="display-4 text-white" data-toggle="counter-up">2123</h1>
                                <span class="fs-5 text-white">Awards Achieved</span>
                                <hr class="bg-white w-25 mx-auto mb-0" />
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <section id="staff">
                <div class="container-xxl service py-5">
                    <div class="container">
                        <div class="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: "600px" }}>
                            <p class="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3">Our Services</p>
                            <h1 class="display-5 mb-5">We have options for you!</h1>
                        </div>
                        <div class="row g-4 wow fadeInUp" data-wow-delay="0.3s">
                            <div class="col-lg-4">
                                <div class="nav nav-pills d-flex justify-content-between w-100 h-100 me-4">
                                    <button class="nav-link w-100 d-flex align-items-center text-start border p-4 mb-4 active"
                                        data-bs-toggle="pill" data-bs-target="#tab-pane-1" type="button">
                                        <h5 class="m-0"><i class="fa fa-bars text-primary me-3"></i>Pay</h5>
                                    </button>
                                    <button class="nav-link w-100 d-flex align-items-center text-start border p-4 mb-4"
                                        data-bs-toggle="pill" data-bs-target="#tab-pane-2" type="button">
                                        <h5 class="m-0"><i class="fa fa-bars text-primary me-3"></i>Medical Plan</h5>
                                    </button>
                                    <button class="nav-link w-100 d-flex align-items-center text-start border p-4 mb-4"
                                        data-bs-toggle="pill" data-bs-target="#tab-pane-3" type="button">
                                        <h5 class="m-0"><i class="fa fa-bars text-primary me-3"></i>Sick Pay</h5>
                                    </button>
                                    <button class="nav-link w-100 d-flex align-items-center text-start border p-4 mb-0"
                                        data-bs-toggle="pill" data-bs-target="#tab-pane-4" type="button">
                                        <h5 class="m-0"><i class="fa fa-bars text-primary me-3"></i>Perks & Discounts</h5>
                                    </button>
                                </div>
                            </div>
                            <div class="col-lg-8">
                                <div class="tab-content w-100">
                                    <div class="tab-pane fade show active" id="tab-pane-1">
                                        <div class="row g-4">
                                            <div class="col-md-6" style={{ minHeight: "350px" }}>
                                                <div class="position-relative h-100">
                                                    <img class="position-absolute rounded w-100 h-100" src="assets/img/PayStub.png"
                                                        style={{ objectFit: "cover" }} alt="" />
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <h3 class="mb-4">Pay</h3>
                                                <p class="mb-4">Many factors affect your paycheck, including location, specialty, and benefits you choose. To receive top pay, its important to be flexible to location and shift. You may also be eligible for housing, transportation, and meal stipends to reimburse you for actual expenses incurred while on a travel assignment. Your Travel Coordinator will review all these details with you during contract placement. </p>
                                                <p><i class="fa fa-check text-primary me-3"></i>Competitive pay</p>
                                                <p><i class="fa fa-check text-primary me-3"></i>Weekly direct deposit</p>
                                                <p><i class="fa fa-check text-primary me-3"></i>Self-scheduling from our mobile app</p>
                                                <a href="" class="btn btn-primary py-3 px-5 mt-3">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="tab-pane-2">
                                        <div class="row g-4">
                                            <div class="col-md-6" style={{ minHeight: "350px" }}>
                                                <div class="position-relative h-100">
                                                    <img class="position-absolute rounded w-100 h-100" src="assets/img/Medical-Benefits.jpeg"
                                                        style={{ objectFit: "cover" }} alt="" />
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <h3 class="mb-4">Medical Plan</h3>
                                                <p class="mb-4">A comprehensive medical, dental and vision plan is available starting the 1st of the month following 30 days of employment. </p>
                                                <p><i class="fa fa-check text-primary me-3"></i>Variety of facilities to choose from</p>
                                                <p><i class="fa fa-check text-primary me-3"></i>Medical, dental, vision and life</p>
                                                <p><i class="fa fa-check text-primary me-3"></i>insurance (only if working a full-time local contract) Ready to get started?</p>
                                                <a href="" class="btn btn-primary py-3 px-5 mt-3">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="tab-pane-3">
                                        <div class="row g-4">
                                            <div class="col-md-6" style={{ minHeight: "350px" }}>
                                                <div class="position-relative h-100">
                                                    <img class="position-absolute rounded w-100 h-100" src="assets/img/SickPay.jpg"
                                                        style={{ objectFit: "cover" }} alt="" />
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <h3 class="mb-4">Sick Pay</h3>
                                                <p class="mb-4">You’ll start accruing sick time on the first day of your assignment and earn 1 hour for every 30 hours worked which can start to use after 90 days. </p>
                                                <p><i class="fa fa-check text-primary me-3"></i>Accrued sick time – 1 hour for every 30 hours worked</p>
                                                <p><i class="fa fa-check text-primary me-3"></i>A dedicated staffing coordinator to assist you if you prefer</p>
                                                <p><i class="fa fa-check text-primary me-3"></i>New grad training available</p>
                                                <a href="" class="btn btn-primary py-3 px-5 mt-3">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="tab-pane-4">
                                        <div class="row g-4">
                                            <div class="col-md-6" style={{ minHeight: "350px" }}>
                                                <div class="position-relative h-100">
                                                    <img class="position-absolute rounded w-100 h-100" src="assets/img/service-4.jpg"
                                                        style={{ objectFit: "cover" }} alt="" />
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <h3 class="mb-4">Perks & Discounts</h3>
                                                <p class="mb-4">We’ve made it easy for you to access amazing discounts, cashback offers and perks on thousands of brands you love in a variety of categories including travel, auto, electronics, apparel, education, entertainment, restaurants, and lots more!</p>
                                                <p><i class="fa fa-check text-primary me-3"></i>Reimbursements for licenses, certifications,</p>
                                                <p><i class="fa fa-check text-primary me-3"></i>uniforms, mileage, and other necessities (only if working a full-time local contract)</p>
                                                <p><i class="fa fa-check text-primary me-3"></i>Medical, dental, vision and life</p>
                                                <a href="" class="btn btn-primary py-3 px-5 mt-3">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section>
                <div className="container-xxl feature py-5">
                    <div id="client">
                        <div className="row g-1 text-center" >
                            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.03s">
                                <img className="img-fluid rounded img-res" src="assets/img/service-3.png" />
                            </div>
                        </div>
                    </div>
                    <div id="staff">
                        <div className="row text-center">
                            <div className="col-lg-12 mt-5 wow fadeInUp" data-wow-delay="0.03s" >
                                <img className="img-fluid rounded img-res" src="assets/img/service-4.png" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </BaseLayout>
    );
};

export default WhyChooseUs;
