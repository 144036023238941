/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react"
import HeaderPage from "../layout/HeaderPage";
import DrawerMenuPage from "../layout/DrawerMenuPage";
import { CButton, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CInputGroup, CFormInput, CFormSwitch } from "@coreui/react";
import { toJS } from "mobx";
import { useNavigate } from "react-router-dom";
import swal from '@sweetalert/with-react';
import { InputField } from "@elements/index"
import { CModal, CModalHeader, CModalTitle } from "@coreui/react";
import { FieldArray, Formik } from "formik";
import Spinner from "@components/Spinner";
import ReactDOM from "react-dom";
import moment from "moment";
import SelectField from "@elements/select/SelectField";
import { Helmet } from "react-helmet";
import StaffsStore from "@store/StaffsStore";
import HomeStore from "@store/HomeStore";
import AdminStore from "@store/AdminStore";
import { FacilityAddSchema } from '@utils/schemas/allSchema';
import Select, { components } from 'react-select';
import { colourStylesState } from '@utils/customStyle/style';
import { Control, generateTooltipHTML, checkAccountValidation, checkAccountValidationOnHover, convertToUserFriendly } from "../commonFunctions";

var cities = require('@assets/city.json');
var states = require('@assets/states.json');
const $ = window.$;
const FacilityList = observer(() => {
    const tableRef = useRef()
    const tableName = "table1"
    const formikRef = useRef(null);
    const navigate = useNavigate(); 
    const [formData, setFormData] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [paginationLength, setPaginationLength] = useState(25);
    const [rateearch, setrateearch] = useState('');
    const [regions, setRegions] = useState([]);
    const [isOpenFacility, setIsOpenFacility] = useState(false);
    const [isOpenAddCost, setIsOpenAddCost] = useState(false);
    const [facilityData, setFacilityData] = useState();
    const [degree, setDegree] = useState([]);

    useEffect(() => {
        StaffsStore.getRegionsList();
        AdminStore.updatePage();
        AdminStore.getFacility();
    }, []);

    useEffect(() => {
        setRegions(toJS(StaffsStore?.data?.regionsList))
    }, [StaffsStore?.data?.regionsList]);
    useEffect(() => {
        setDegree(HomeStore?.data?.degreeList)
    }, [HomeStore?.data?.degreeList]);


    useEffect(() => {
        const renderPagination = () => {
            const table = $('#table1').DataTable();
            const totalPages = AdminStore.data.lastPage;
            const currentPage = AdminStore.data.page;

            const paginationContainer = $('.dataTables_paginate');
            const paginationList = $('<ul>', { class: 'pagination' });
            paginationContainer.empty().append(paginationList);

            let startPage = Math.max(1, currentPage - 2);
            let endPage = Math.min(totalPages, currentPage + 2);

            if (totalPages > 5 && currentPage <= 3) {
                endPage = 5;
            } else if (totalPages > 5 && currentPage >= totalPages - 2) {
                startPage = totalPages - 4;
            }
            const firstButton = $('<a>', {
                text: 'First',
                class: `page-link pointer ${currentPage === 1 ? 'disabled' : ''}`,
                click: function () {
                    if (currentPage > 1) {
                        AdminStore.data.page = 1;
                        AdminStore.getFacility();
                        table.page('first').draw(false);
                    }
                },
            });

            const previousButton = $('<a>', {
                text: 'Previous',
                class: `page-link pointer ${currentPage === 1 ? 'disabled' : ''}`,
                click: function () {
                    if (currentPage > 1) {
                        AdminStore.data.page = AdminStore.data.page - 1;
                        AdminStore.getFacility();
                        table.page('previous').draw(false);
                    }
                },
            });

            const nextButton = $('<a>', {
                text: 'Next',
                class: `page-link pointer ${currentPage === totalPages ? 'disabled' : ''}`,
                click: function () {
                    if (currentPage < totalPages) {
                        AdminStore.data.page = AdminStore.data.page + 1;
                        AdminStore.getFacility();
                        table.page('next').draw(false);
                    }
                },
            });
            const lastButton = $('<a>', {
                text: 'Last',
                class: `page-link pointer ${currentPage === totalPages ? 'disabled' : ''}`,
                click: function () {
                    if (currentPage < totalPages) {
                        AdminStore.data.page = AdminStore.data.lastPage;
                        AdminStore.getFacility();
                        table.page('last').draw(false);
                    }
                },
            });

            const firstListItem = $('<li>', { class: `page-item ${currentPage === 1 ? 'disabled' : ''}` }).append(firstButton);
            const previousListItem = $('<li>', { class: `page-item ${currentPage === 1 ? 'disabled' : ''}` }).append(previousButton);
            const nextListItem = $('<li>', { class: `page-item ${currentPage === totalPages ? 'disabled' : ''}` }).append(nextButton);
            const lastListItem = $('<li>', { class: `page-item ${currentPage === totalPages ? 'disabled' : ''}` }).append(lastButton);
            paginationList.append(firstListItem);
            paginationList.append(previousListItem);

            for (let i = startPage; i <= endPage; i++) {
                const pageLink = $('<a>', {
                    text: i,
                    class: `page-link pointer ${i === currentPage ? 'active' : ''}`,
                    click: function () {
                        AdminStore.data.page = i;
                        AdminStore.getFacility();
                        table.page(i - 1).draw(false);
                    },
                });

                const listItem = $('<li>', { class: `page-item ${i === currentPage ? 'active' : ''}` }).append(pageLink);
                paginationList.append(listItem);
            }

            paginationList.append(nextListItem);
            paginationList.append(lastListItem);
        };
        $.fn.dataTable.ext.errMode = 'none';
        $($.fn.dataTable.tables(true)).DataTable()
            .columns.adjust();
        const table = $(`#${tableName}`).DataTable(
            {
                data: AdminStore?.data?.facilityList,
                order: [], // Disable initial sorting
                dom: 'Blfrtip',
                buttons: [
                    'copy', 'csv', 'excel', 'print',
                ],
                pageLength: paginationLength,
                lengthMenu: [25, 50, 100, 500, 1000],
                columns: [
                    {
                        title: "Sr", data: 'id', width: 50, "render": function (data, type, full, meta) {
                            return ((AdminStore.data.page - 1) * paginationLength + 1) + meta.row
                        }
                    },
                    {
                        title: "Facility", data: 'organization_name', width: 50, "render": function (data, type, full, meta) {
                            return data ? "<span class='text-success'><strong>" + data + "</strong></span>"
                                + (checkAccountValidation(full, "facility") ? `<b id="check-validation" class="text-warning ps-2"><i class="fas fa-exclamation-triangle"></i></b>` : "")
                                : "-"
                        }
                    },
                    {
                        title: "Type", data: 'organization_type', width: 50, "render": function (data, type, full, meta) {
                            return data
                        }
                    },
                    {
                        title: "F. User", width: 100, data: "user_relations", "render": function (data, type, row, meta) {
                            return (data && data?.length > 0)
                                ? '<span class="text-info"><b>' + data[0]?.users?.first_name + " " + data[0]?.users?.last_name + '</span>' + '<sup class="text-info"><b>' + (data?.length > 1 ? " +" + (data.length - 1) : "") + '</b></sup>' : "-";
                        }
                    },
                    {
                        title: "Region", width: 100, data: "region_id", "render": function (data, type, row, meta) {
                            return regions?.find(x => x.ID == data)?.Name || "-";
                        }
                    },
                    {
                        title: "Facility & Unit Code", width: 100, "render": function (data, type, row, meta) {
                            return row?.facility_code ? (row?.facility_code + " & " + row.unit_code) : '-';
                        }
                    },

                    {
                        title: "City", data: "city"
                    },
                    {
                        title: "State", data: "state", "render": function (data, type, row, meta) {
                            return data || "-";
                        }
                    },
                    {
                        title: "Last Updated By", width: 100, data: 'last_updated_by', "render": function (data, type, row, meta) {
                            return data ? data?.first_name + " " + data?.last_name : "-"
                        }
                    },
                    {
                        title: "Last Update", width: 100, data: 'updated_at', "render": function (data, type, row, meta) {
                            return moment(data).fromNow() || "-";
                        }
                    },
                    {
                        title: "Action"
                    },

                ],
                columnDefs: [
                    {
                        targets: 0,
                        visible: false,
                    },
                    {
                        targets: 1,
                        responsivePriority: 2,
                        createdCell: function (td, cellData, rowData, row, col) {
                            if (col !== 1) return; // Exit if not column index 1
                            var tdId = $(td).find('b').attr('id');
                            if (tdId === 'check-validation') {
                                // Update tooltip content with HTML returned by checkAccountValidationOnHover
                                const tooltipContent = checkAccountValidationOnHover(rowData, "facility");
                                $(td).attr('data-toggle', 'tooltip');
                                $(td).attr('data-html', 'true');
                                $(td).attr('data-placement', 'top');
                                $(td).attr('title', generateTooltipHTML(tooltipContent));
                            }
                        }
                    },
                    {
                        targets: 3,
                        responsivePriority: 3,
                        createdCell: function (td, cellData, rowData, row, col) {
                            var listHtml = "";
                            cellData.forEach(function (data) {
                                listHtml += "" + data?.users?.first_name + " " + data?.users?.last_name + "\n";
                            })
                            listHtml += "";
                            $(td).attr('data-toggle', 'tooltip');
                            $(td).attr('data-html', 'true');
                            $(td).attr('data-placement', 'top');
                            $(td).attr('title', listHtml);
                        },
                    },
                    {
                        targets: 10,
                        responsivePriority: 1,
                        createdCell: (td, cellData, rowData, row, col) =>
                            ReactDOM.render(
                                <div>
                                    <CDropdown variant="btn-group" direction='dropstart' alignment="end">
                                        <CDropdownToggle size="sm" color="transparent" caret={false} href="#" className="p-0"><i className="fa fa-ellipsis-v text-primary"></i></CDropdownToggle>
                                        <CDropdownMenu>

                                            {<CDropdownItem className="more-menu">
                                                <CButton onClick={() => {
                                                    onEdit(rowData)
                                                }} size="sm" className="w-100" variant='outline' color="primary" >
                                                    Edit <i className="fas fa-edit"></i>
                                                </CButton>
                                            </CDropdownItem>}
                                            <CDropdownItem>
                                                <CButton
                                                    onClick={() => {
                                                        deleteFacility(rowData.id)
                                                    }}
                                                    size="sm" className="w-100" variant='outline' color="danger">
                                                    Delete <i className="fas fa-trash"></i>
                                                </CButton>
                                            </CDropdownItem>
                                            <CDropdownItem className="more-menu">
                                                <CButton onClick={() => {
                                                    onShiftView(rowData)
                                                }} size="sm" className="w-100" variant='outline' color="primary" >
                                                    View Shifts <i className="fas fa-eye"></i>
                                                </CButton>
                                            </CDropdownItem>
                                            {<CDropdownItem className="more-menu">
                                                <CButton onClick={() => {
                                                    onShiftCalendarView(rowData)
                                                }} size="sm" className="w-100" variant='outline' color="primary" >
                                                    View Calendar <i className="fas fa-calendar"></i>
                                                </CButton>
                                            </CDropdownItem>}
                                            <CDropdownItem className="more-menu">
                                                <CButton onClick={() => {
                                                    onAddCost(rowData)
                                                }} size="sm" className="w-100" variant='outline' color="primary" >
                                                    Shift Rate <i className="fas fa-dollar-sign"></i>
                                                </CButton>
                                            </CDropdownItem>
                                        </CDropdownMenu>
                                    </CDropdown>
                                </div>
                                , td),
                    }
                ],
                destroy: true,  // I think some clean up is happening here
                searching: true,
                "language": {
                    search: '',
                    searchPlaceholder: "Search",
                    emptyTable: "No Record Found",
                    info: "Showing " + AdminStore.data.from + " to " + AdminStore.data.to + " of " + AdminStore.data.total + " entries",
                },
                initComplete: function () {
                    // Apply the search
                    this.api()
                        .columns([0, 1, 2, 3, 4, 5, 6, 7, 8])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><input class="form-control " style="width:100%;margin-top:5px" type="text" placeholder="Filter per page" />')
                                .appendTo($(column.header()))

                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $('input', this.header()).on('keyup change clear', function () {
                                if (column.search() !== this.value) {
                                    column.search(this.value).draw();
                                }
                            });
                        });
                },

                drawCallback: function () {
                    renderPagination();
                }
            });

        renderPagination();
        table.page.len(paginationLength).draw();
        $(`#${tableName}`).on('length.dt', function (e, settings, len) {
            handlePaginationLengthChange(len)
        });
        table.on('click', 'td', function () {
            // Get the column index of the clicked cell
            const columnIndex = table.cell(this).index()?.column;
            const columnID = table.cell(this)?.node()?.querySelector('b')?.id;

            // Check if the clicked cell belongs to the desired column
            if (columnIndex === 1 && columnID) {
                // Get the data for the clicked cell
                const rowData = table.row($(this).closest('tr')).data();
                // Handle the event for the clicked cell
                if (rowData) {
                    onEdit(rowData)
                }
            }
        });


        // Extra step to do extra clean-up.
        return function () {
            $(`#${tableName}`).off('length.dt');
            table.destroy()
        }
    }, [AdminStore?.data?.facilityList])

    const deleteFacility = (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this facility!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    AdminStore.deleteFacility({ id: id }, navigationCallBack)
                }
            });
    }

    const navigationCallBack = () => {
        AdminStore.getFacility();
        onDismiss();
    }

    const onDismiss = () => {
        if (formikRef.current) {
            formikRef.current.resetForm();
        }
        setFacilityData()
        setIsOpenFacility(false)
        setIsOpenAddCost(false)
    }

    const handlePaginationLengthChange = (newPageSize) => {
        setPaginationLength(newPageSize)
        AdminStore.data.pageSize = newPageSize;
        AdminStore.getFacility();
    };
    const handleChange = (event) => {
        setrateearch(event.target.value);
        AdminStore.data.search = event.target.value;
    };
    const onSearch = () => {
        AdminStore.getFacility();
    }
    const onClearSearch = () => {
        setrateearch('');
        AdminStore.data.search = '';
        AdminStore.getFacility();
    }
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            onSearch();
        }
    };

    const onEdit = (data) => {
        setFacilityData(data);
        setIsOpenFacility(true);
    }

    const onAddCost = (data) => {
        setFacilityData(data);
        setIsOpenAddCost(true);
    }


    const submitForm = (values) => {
        if (values?.id) {
            AdminStore.updateFacility(values, navigationCallBack)
        } else {
            values.city_data = JSON.stringify(values?.city_data)
            AdminStore.addFacility(values, navigationCallBack)
        }
    }
    const onShiftView = (facilityData) => {
        const facility = {
            id: facilityData?.id,
            organization_name: facilityData?.organization_name
        }
        const url = `/admin/facility-shift?data=${encodeURIComponent(JSON.stringify(facility))}`;
        window.open(url, '_blank');
    }
    const onShiftCalendarView = (clientData) => {
        const client = {
            id: clientData?.id,
            organization_name: clientData?.organization_name
        }
        const url = `/admin/facility-calendar?data=${encodeURIComponent(JSON.stringify(client))}`;
        window.open(url, '_blank');
    }
    const saveFacilityRate = (values) => {

        if (values?.rate?.some(obj => 'id' in obj)) {
            const formData = new FormData();
            formData.append("facility_id", values?.facility_id)
            formData.append("rate", JSON.stringify(values.rate))
            formData.append("delete_ids[]", values?.delete_ids || [])
            AdminStore.updateFacilityRate(formData, navigationCallBack)
        } else {
            const param = {
                'facility_id': values?.facility_id,
                'rate': JSON.stringify(values.rate),
            }
            AdminStore.saveFacilityRate(param, navigationCallBack)
        }

    }


    return (
        <React.Fragment>
            <Helmet>
                <title>Staffers LLC | Facility List</title>
            </Helmet>
            {/* <body> */}
            <div className="container-scroller">
                <HeaderPage />
                <div className="container-fluid page-body-wrapper">
                    <DrawerMenuPage />
                    <div className="main-panel" style={{ right: 0, position: "absolute" }}>
                        <div className="content-wrapper p-3 bg-white row pe-0">
                            <h3 className="col-6 col-lg-6 page-title">
                                <span className="page-title-icon linear text-white me-2">
                                    <i className="fas fa-user-friends"></i>
                                </span> Facility
                            </h3>
                            <div className='col-6 col-lg-3 text-end'>
                                <a onClick={() => {
                                    setIsOpenFacility(true),
                                        setFacilityData()
                                }}>
                                    <h1 className="page-title signup-link fs-4" >
                                        <span className="page-title-icon linear text-white me-2">
                                            <i className="far fa-building"></i>
                                        </span>Add Facility
                                    </h1>
                                </a>
                            </div>
                            <div className="col-12 col-lg-3 mt-lg-0 mt-3">
                                <CInputGroup>
                                    <CFormInput placeholder="Search" value={rateearch} onChange={handleChange} onKeyPress={handleKeyPress} />
                                    {rateearch && (
                                        <CButton onClick={onClearSearch} type="button" color="primary" variant="outline">
                                            <i className="fa fa-times" aria-hidden="true"></i>
                                        </CButton>
                                    )}
                                    <CButton onClick={onSearch} type="button" color="primary" variant="outline">
                                        <i className="fa fa-search" aria-hidden="true"></i>
                                    </CButton>
                                </CInputGroup>
                            </div>
                        </div>
                        {/* <!-- partial --> */}

                        <div className="col-lg-12">
                            <div className="row bg-white" style={{ height: 500, paddingTop: 20 }}>
                                <table className="table dt-responsive table-hover nowrap" style={{ width: "100%" }} id={tableName} ref={tableRef}></table>
                            </div>
                        </div>
                    </div>

                    {/* <!-- main-panel ends --> */}
                </div>
                {/* <!-- page-body-wrapper ends --> */}
            </div>

            <Spinner isLoading={AdminStore.loading} />

            <CModal visible={isOpenFacility} onClose={() => onDismiss()} backdrop="static">
                <CModalHeader onClose={() => onDismiss()}>
                    <CModalTitle>{facilityData?.id ? "Edit Facility" : "Add Facility"}</CModalTitle>
                </CModalHeader>
                {/* <CModalBody> */}
                <Formik
                    innerRef={formikRef}
                    initialValues={{
                        'id': facilityData?.id || null,
                        'organization_name': facilityData?.organization_name || "",
                        'organization_type': facilityData?.organization_type || "",
                        'state': facilityData?.state || "",
                        'city': facilityData?.city || "",
                        'city_data': facilityData?.city_data || "",
                        'address': facilityData?.address || "",
                        'zipcode': facilityData?.zipcode || "",
                        'region_id': facilityData?.region_id || "",
                        'facility_code': facilityData?.facility_code || "",
                        'unit_code': facilityData?.unit_code || "",
                    }}
                    onSubmit={values => submitForm(values)}
                    validationSchema={FacilityAddSchema}
                    validateOnChange={false}
                >
                    {
                        ({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched
                        }) =>
                        (
                            <form onSubmit={handleSubmit}>
                                <div className="modal-body p-4">
                                    <div className="row g-2">
                                        <div className="col-sm-12 mb-3">
                                            <InputField
                                                className={"did-floating-input"}
                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                type="text"
                                                name="organization_name"
                                                label={"Facility"}
                                                value={values.organization_name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.organization_name && touched.organization_name ? (
                                                <div className="error-message">{errors.organization_name}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-6 mb-3">
                                            <SelectField
                                                name="organization_type"
                                                label={"Organization Type"}
                                                value={values.organization_type}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                data={global.config.organizationType}
                                                optionLabel={"label"}
                                                optionValue={"value"}
                                                required={true}
                                            />
                                            {errors.organization_type && touched.organization_type ? (
                                                <div className="error-message">{errors.organization_type}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-6 mb-3">
                                            <SelectField
                                                name="region_id"
                                                label={"Add Your Region"}
                                                value={values.region_id}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                data={regions}
                                                optionLabel={"Name"}
                                                optionValue={"ID"}
                                                required={true}
                                            />
                                            {errors.region_id ? (
                                                <div className="error-message">{errors.region_id}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-6  mb-3">
                                            <InputField
                                                className={"did-floating-input"}
                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                type="text"
                                                name="facility_code"
                                                label={"Facility Code"}
                                                value={values.facility_code}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={true}
                                            />
                                        </div>
                                        <div className="col-sm-6 mb-3">
                                            <InputField
                                                className={"did-floating-input"}
                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                type="text"
                                                name="unit_code"
                                                label={"Unit Code"}
                                                value={values.unit_code}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={true}
                                            />
                                        </div>
                                        <div className="col-sm-6 mb-3">
                                            <InputField
                                                className={"did-floating-input"}
                                                type={"text"}
                                                name="address"
                                                label={"Full Address"}
                                                value={values.address}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={true}
                                            />
                                        </div>
                                        <div className="col-sm-6 mb-3">
                                            <Select
                                                onChange={(e) => { setFieldValue("state", e.abbreviation), setFieldValue("city", '') }}
                                                placeholder={''}
                                                label={'State'}
                                                styles={colourStylesState}
                                                value={states.filter(({ abbreviation }) => values?.state?.includes(abbreviation)) || ''}
                                                options={states}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.abbreviation}
                                                components={{ Control, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                            />
                                        </div>
                                        <div className="col-sm-6 mb-3">
                                            <Select
                                                onChange={(e) => { setFieldValue("city", e.label); setFieldValue("city_data", [e]) }}
                                                placeholder={''}
                                                label={'City'}
                                                isDisabled={!values?.state}
                                                styles={colourStylesState}
                                                getOptionLabel={(option) => option.label}
                                                getOptionValue={(option) => option.label}
                                                value={cities.filter(({ label }) => values?.city === label) || ''}
                                                options={cities?.filter(ct => ct.state === states?.find(x => x.abbreviation == values?.state)?.name)}
                                                components={{ Control, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                            />
                                            {errors.city && touched.city ? (
                                                <div className="error-message">{errors.city}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-6 mb-3">
                                            <InputField
                                                name={"zipcode"}
                                                className={"did-floating-input"}
                                                type="number"
                                                label={"Zip Code"}
                                                value={values.zipcode}
                                                onChange={(event) => { handleChange(event); }
                                                }
                                                onBlur={handleBlur}
                                                required={true}
                                            />
                                            {errors.zipcode && touched.zipcode ? (
                                                <div className="error-message">{errors.zipcode}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-center">
                                    <button type="submit" className="btn btn-success w-25">Submit</button>
                                </div>
                            </form>
                        )
                    }
                </Formik>
                {/* </CModalBody> */}
            </CModal>

            {/* COST ADD PER PROFESSION */}
            <CModal className="custom-modal-size" visible={isOpenAddCost} onClose={() => onDismiss()} backdrop="static">
                <CModalHeader onClose={() => onDismiss()}>
                    <CModalTitle>{facilityData?.rates?.length > 0 ? "Update Shift Rate" : "Add Shift Rate"} For <b>{facilityData?.organization_name}</b></CModalTitle>
                </CModalHeader>
                {/* <CModalBody> */}
                <Formik
                    innerRef={formikRef}
                    initialValues={{ facility_id: facilityData?.id, rate: facilityData?.rates?.length > 0 ? toJS(facilityData?.rates) : [{ profession_id: '', profession_name: '', cost: '' }], delete_ids: [] }}
                    onSubmit={(values) => {
                        // Handle form submission here
                        saveFacilityRate(values);
                    }}
                    validateOnChange={false}
                >
                    {({ values, handleSubmit, setFieldValue }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="modal-body p-4">
                                <FieldArray
                                    name="rate"
                                    render={(arrayHelpers) => (
                                        <>
                                            {values.rate?.map((input, index) => (
                                                <div key={index} className="row">
                                                    <div className="col-sm-5  mb-3">
                                                        <div className="did-floating-label-content">
                                                            <select className="did-floating-dropdown"
                                                                name={`rate.${index}.profession_id`}
                                                                value={input?.profession_id}
                                                                onChange={(e) => {
                                                                    arrayHelpers.replace(index, {
                                                                        ...values.rate[index],
                                                                        profession_id: e.target.value,
                                                                        profession_name: degree?.find(x => x.Id == e.target.value)?.Name,
                                                                    })
                                                                }}
                                                                required
                                                            >
                                                                <option
                                                                    value=""
                                                                >
                                                                </option>
                                                                {degree && degree?.map((option, index) => (
                                                                    <option
                                                                        key={index}
                                                                        value={option.Id}
                                                                        disabled={values?.rate?.some(obj => obj?.profession_id == option.Id)}>
                                                                        {option.Name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            <label className={values.profession_id == "" ? "did-floating-label did-floating-label-down did-floating-labe-astrisk" : "did-floating-label did-floating-label-top did-floating-labe-astrisk"}>Profession</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-5 mb-3">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            type={"number"}
                                                            min={0}
                                                            label={"Shift Rate (USD/hr)"}
                                                            name={`rate.${index}.cost`}
                                                            value={input?.cost}
                                                            onChange={(e) =>
                                                                arrayHelpers.replace(index, {
                                                                    ...values.rate[index],
                                                                    cost: e.target.value,
                                                                })
                                                            }
                                                            required={true}
                                                        />
                                                    </div>
                                                    <div className="col-sm-2 p-0 m-0">
                                                        {index === values.rate.length - 1 && (
                                                            <button className="btn btn-primary mb-2 me-1" type="button" onClick={() => arrayHelpers.push({ profession_id: '', profession_name: '', cost: '' })}>
                                                                <i className="fas fa-plus"></i>
                                                            </button>
                                                        )}
                                                        {index !== 0 && (
                                                            <button type="button" className="btn btn-danger mb-2" onClick={() => { arrayHelpers.remove(index); setFieldValue("delete_ids", [...values?.delete_ids, input?.id]); }}>
                                                                <i className="fas fa-trash-alt"></i>
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                />
                            </div>
                            <div className="modal-footer justify-content-center">
                                <button type="submit" className="btn btn-success w-25">
                                    Submit
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
                {/* </CModalBody> */}
            </CModal>
            {/* </body> */}
        </React.Fragment >
    );
})

export default FacilityList;