import { observer } from "mobx-react";
import { useEffect } from "react";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link, useLocation } from "react-router-dom";
import WebsiteStore from "../../store/WebsiteStore";
import { toJS } from "mobx";
import WebsiteLayout from "../components/WebsiteLayout";
import { Helmet } from "react-helmet";

const PageSearch = observer(() => {
  const location = useLocation();
  const [globalPageData, setGlobalPageData] = useState([]);

  useEffect(() => {
    WebsiteStore.postGlobalSearch({ search: location?.state?.search });
  }, []);

  useEffect(() => {
    if (WebsiteStore?.data?.globalSearchList?.length > 0) {
      setGlobalPageData(toJS(WebsiteStore?.data?.globalSearchList));
    }
  }, [WebsiteStore.data.globalSearchList]);

  const transformString = (inputString) => {
    // Trim the string
    let trimmedString = inputString.trim();

    // Make the string lowercase
    let transformedString = trimmedString.toLowerCase();

    // Replace spaces with "-"
    transformedString = transformedString.replace(/\s+/g, "-");

    // Remove single quotes
    transformedString = transformedString.replace(/'/g, "");

    return transformedString;
  };

  const limitContent = (content) => {
    // Remove special characters [ ] and "
    const cleanedContent = content.replace(/[\[\]"]+/g, "");

    // Split the content into words
    const words = cleanedContent.split(" ");

    // Join the first 100 words
    const limitedContent = words.slice(0, 100).join(" ");

    // Check if there are more words
    const hasMoreWords = words?.length > 100;

    // Append ellipsis if there are more words
    const finalContent = hasMoreWords ? limitedContent + "..." : limitedContent;

    // Highlight the searched term with a CSS class
    const highlightedContent = finalContent.replace(
      new RegExp(location?.state?.search, "gi"),
      (match) => `<span class="highlighted">${match}</span>`
    );

    return <p dangerouslySetInnerHTML={{ __html: highlightedContent }} />;
  };

  // Filter globalPageData based on whether the keyword is present
  const filteredPageData = globalPageData.filter((pageData) =>
    pageData.sections.some((section) =>
      section.content
        .toLowerCase()
        .includes(location?.state?.search.toLowerCase())
    )
  );

  const metaData = {
    title: "Search Pages - Staffers LLC",
    description:
      "Staffers simplifies licensing across professions and states, assigning a specialist to streamline onboarding and ensure compliance for your assignments.",
    // Add more metadata fields as needed
  };

  const jsonLdData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "Search Pages - Staffers LLC",
    description:
      "Staffers simplifies licensing across professions and states, assigning a specialist to streamline onboarding and ensure compliance for your assignments.",
    // Add more JSON-LD fields as needed
  };

  return (
    <WebsiteLayout>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
        {/* Add more meta tags here as needed */}
      </Helmet>
      <div className="travel-search-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="inner-search-border">
                <h2 className="common-web-head">
                  Search results for {location?.state?.search}
                </h2>
                <ul>
                  {/* Display skeleton loader if loading */}
                  {WebsiteStore.loading ? (
                    Array.from({ length: 5 }).map((_, index) => (
                      <li key={index}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Skeleton height={30} width={200} />
                          <Skeleton height={20} width={300} />
                        </div>
                      </li>
                    ))
                  ) : filteredPageData?.length > 0 ? (
                    filteredPageData.map((pageData, idx) => (
                      <li key={pageData?.id}>
                        <div>
                          <h3 className="web-link-big">
                            {pageData?.page_name}
                          </h3>
                          <Link
                            to={`/${transformString(pageData?.page_name)}`}
                            className="web-link-small"
                          >
                            {`https://www.staffers.com/${transformString(
                              pageData?.page_name
                            )}`}
                          </Link>
                          {pageData?.sections?.map((section, idx) => (
                            <div key={idx}>{limitContent(section.content)}</div>
                          ))}
                        </div>
                      </li>
                    ))
                  ) : (
                    <li>No matching pages found</li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="new-extra-space">.</div>
      </div>
      <script type="application/ld+json">{JSON.stringify(jsonLdData)}</script>
    </WebsiteLayout>
  );
});

export default PageSearch;
