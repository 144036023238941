/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react"
import HeaderPage from "../layout/HeaderPage";
import DrawerMenuPage from "../layout/DrawerMenuPage";
import { CButton, CFormLabel, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CDropdownDivider, CInputGroup, CFormInput } from "@coreui/react";
import './manageNotification.css'
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import AdminStore from "@store/AdminStore";
import { toJS } from "mobx";
import Spinner from "@components/Spinner";
import moment from "moment";
import ReactDOM from "react-dom";
import { Helmet } from "react-helmet";

const $ = window.$;
const ManageNotificationList = observer(() => {
    const tableRef = useRef()
    const tableName = "table1"
    const [notificationList, setNotificationList] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [signleData, setSingleData] = useState();
    const [inputSearch, setInputSearch] = useState('');
    const [paginationLength, setPaginationLength] = useState(25);

    useEffect(() => {
        AdminStore.getManageNotification();
        AdminStore.updatePage();
    }, []);

    useEffect(() => {
        setNotificationList(toJS(AdminStore?.data?.manageNotification))
    }, [AdminStore?.data?.manageNotification]);

    useEffect(() => {
        const renderPagination = () => {
            const table = $('#table1').DataTable();
            const totalPages = AdminStore.data.lastPage;
            const currentPage = AdminStore.data.page;

            const paginationContainer = $('.dataTables_paginate');
            const paginationList = $('<ul>', { class: 'pagination' });
            paginationContainer.empty().append(paginationList);

            let startPage = Math.max(1, currentPage - 2);
            let endPage = Math.min(totalPages, currentPage + 2);

            if (totalPages > 5 && currentPage <= 3) {
                endPage = 5;
            } else if (totalPages > 5 && currentPage >= totalPages - 2) {
                startPage = totalPages - 4;
            }
            const firstButton = $('<a>', {
                text: 'First',
                class: `page-link pointer ${currentPage === 1 ? 'disabled' : ''}`,
                click: function () {
                    if (currentPage > 1) {
                        AdminStore.data.page = 1;
                        AdminStore.getManageNotification();
                        table.page('first').draw(false);
                    }
                },
            });

            const previousButton = $('<a>', {
                text: 'Previous',
                class: `page-link pointer ${currentPage === 1 ? 'disabled' : ''}`,
                click: function () {
                    if (currentPage > 1) {
                        AdminStore.data.page = AdminStore.data.page - 1;
                        AdminStore.getManageNotification();
                        table.page('previous').draw(false);
                    }
                },
            });

            const nextButton = $('<a>', {
                text: 'Next',
                class: `page-link pointer ${currentPage === totalPages ? 'disabled' : ''}`,
                click: function () {
                    if (currentPage < totalPages) {
                        AdminStore.data.page = AdminStore.data.page + 1;
                        AdminStore.getManageNotification();
                        table.page('next').draw(false);
                    }
                },
            });
            const lastButton = $('<a>', {
                text: 'Last',
                class: `page-link pointer ${currentPage === totalPages ? 'disabled' : ''}`,
                click: function () {
                    if (currentPage < totalPages) {
                        AdminStore.data.page = AdminStore.data.lastPage;
                        AdminStore.getManageNotification();
                        table.page('last').draw(false);
                    }
                },
            });

            const firstListItem = $('<li>', { class: `page-item ${currentPage === 1 ? 'disabled' : ''}` }).append(firstButton);
            const previousListItem = $('<li>', { class: `page-item ${currentPage === 1 ? 'disabled' : ''}` }).append(previousButton);
            const nextListItem = $('<li>', { class: `page-item ${currentPage === totalPages ? 'disabled' : ''}` }).append(nextButton);
            const lastListItem = $('<li>', { class: `page-item ${currentPage === totalPages ? 'disabled' : ''}` }).append(lastButton);
            paginationList.append(firstListItem);
            paginationList.append(previousListItem);

            for (let i = startPage; i <= endPage; i++) {
                const pageLink = $('<a>', {
                    text: i,
                    class: `page-link pointer ${i === currentPage ? 'active' : ''}`,
                    click: function () {
                        AdminStore.data.page = i;
                        AdminStore.getManageNotification();
                        table.page(i - 1).draw(false);
                    },
                });

                const listItem = $('<li>', { class: `page-item ${i === currentPage ? 'active' : ''}` }).append(pageLink);
                paginationList.append(listItem);
            }

            paginationList.append(nextListItem);
            paginationList.append(lastListItem);
        };
        $.fn.dataTable.ext.errMode = 'none';
        $($.fn.dataTable.tables(true)).DataTable()
            .columns.adjust();
        const table = $(`#${tableName}`).DataTable(
            {
                data: notificationList,
                order: [], // Disable initial sorting
                pageLength: paginationLength,
                lengthMenu: [25, 50, 100, 500, 1000],
                columns: [
                    {
                        title: "Sr", data: 'id', width: 50, "render": function (data, type, full, meta) {
                            return meta.row + 1;
                        }
                    },
                    {
                        title: "Send To", data: "notifiable_type", width: 60, "render": function (data, type, full, meta) {
                            return data?.split("\\")?.pop() == 'User' ? ((full?.user?.title ? full?.user?.title : "") + full?.user?.first_name + " " + full?.user?.last_name) :
                                data?.split("\\")?.pop() == 'Staff' ? ((full?.staff?.title ? full?.staff?.title : "") + full?.staff?.first_name + " " + full?.staff?.last_name) :
                                    data?.split("\\")?.pop() == 'Admin' ? ((full?.admin?.title ? full?.admin?.title : "") + full?.admin?.first_name + " " + full?.admin?.last_name)
                                        : "-";
                        }
                    },
                    {
                        title: "Name", data: "notifiable_type", width: 60, "render": function (data, type, full, meta) {
                            return data ? data?.split("\\")?.pop() == 'User' ? "Employer" : data?.split("\\")?.pop() == 'Staff' ? "Caregiver" : "Admin" : "-";
                        }
                    },
                    {
                        title: "Heading", data: 'data', width: 'auto', "render": function (data, type, full, meta) {
                            return data ? JSON.parse(data)?.data?.heading?.substring(0, 25) + "..." : "-";
                        }
                    },
                    {
                        title: "Sent Via", data: 'data', width: 'auto', "render": function (data, type, full, meta) {

                            const parsedData = data ? JSON.parse(data)?.data : "";
                            return `
                            <div style="text-align: left; border: 1px solid #ccc; padding: 2px; display: grid; grid-template-columns: max-content auto;">
                                <strong style="border: 1px solid #ccc; padding: 2px;">Mail:</strong> 
                                <span style="border: 1px solid #ccc; padding: 2px;">${parsedData?.mail === 1 ? "Yes" : "No"}</span>
                                
                                <strong style="border: 1px solid #ccc; padding: 2px;">SMS:</strong> 
                                <span style="border: 1px solid #ccc; padding: 2px;">${parsedData?.sms === 1 ? "Yes" : "No"}</span>
                                
                                <strong style="border: 1px solid #ccc; padding: 2px;">Portal:</strong> 
                                <span style="border: 1px solid #ccc; padding: 2px;">${parsedData?.bc === 1 ? "Yes" : "No"}</span>

                                <strong style="border: 1px solid #ccc; padding: 2px;">Pop-up:</strong> 
                                <span style="border: 1px solid #ccc; padding: 2px;">${parsedData?.popup === 1 ? "Yes" : "No"}</span>
                            </div>
                        `;
                        }
                    },
                    // {
                    //     title: "Content", width: 100, data: "data", "render": function (data, type, full, meta) {
                    //         return data ;
                    //     }
                    // },
                    {
                        title: "Portal Read Status", data: "read_at", width: 60, "render": function (data, type, full, meta) {
                            return data ? "<span class='text-success'>" + moment(data).fromNow() + '</span>' : "Unread";
                        }
                    },
                    {
                        title: "Time", data: "created_at", width: 60, "render": function (data, type, full, meta) {
                            return data ? moment(data).fromNow() : "Unread";
                        }
                    },
                    {
                        title: "Action", width: 60
                    },
                ],

                columnDefs: [
                    {
                        targets: 2,
                        responsivePriority: 2,
                    },
                    {
                        targets: 3,
                        responsivePriority: 3,
                    },
                    {
                        targets: 4,
                        responsivePriority: 4,
                    },

                    {
                        targets: 7,
                        responsivePriority: 1,
                        createdCell: (td, cellData, rowData, row, col) =>
                            ReactDOM.render(
                                <div>
                                    <CButton onClick={() => {
                                        showDetails(rowData)
                                    }} size="sm" className="w-100" variant={rowData?.read_at ? 'outline' : ""} color="primary" >
                                        {rowData?.read_at ? <i class="far fa-envelope-open"></i> : <i class="far fa-envelope"></i>} Open
                                    </CButton>
                                </div>
                                , td),
                    }
                ],
                destroy: true,  // I think some clean up is happening here
                searching: true,
                "language": {
                    search: '',
                    searchPlaceholder: "Search",
                    emptyTable: "No Record Found",
                    info: "Showing " + AdminStore.data.from + " to " + AdminStore.data.to + " of " + AdminStore.data.total + " entries",

                },
                initComplete: function () {
                    this.api()
                        .columns([0, 1, 2, 3, 4, 5])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><input class="form-control " style="width:100%;margin-top:5px" type="text" placeholder="Filter" />')
                                .appendTo($(column.header()))

                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $('input', this.header()).on('keyup change clear', function () {
                                if (column.search() !== this.value) {
                                    column.search(this.value).draw();
                                }
                            });
                        });

                },
                drawCallback: function () {
                    renderPagination();
                },
                "rowCallback": function (row, data) {
                    // Add your condition here
                    if (!data.read_at) {
                        $(row).addClass('highlight'); // Apply the CSS class to highlight the row
                    }
                }

            }

        )
        renderPagination();
        table.page.len(paginationLength).draw();
        $(`#${tableName}`).on('length.dt', function (e, settings, len) {
            handlePaginationLengthChange(len)
        });

        // Extra step to do extra clean-up.
        return function () {
            console.log("Table destroyed")
            $(`#${tableName}`).off('length.dt');
            table.destroy()
        }
    }, [notificationList])

    const showDetails = (item) => {
        setSingleData(item);
        setIsOpen(true);
    }
    const onDismiss = () => {
        setIsOpen(false)
    }
    const handlePaginationLengthChange = (newPageSize) => {
        setPaginationLength(newPageSize)
        AdminStore.data.pageSize = newPageSize;
        AdminStore.getManageNotification();
    };
    const handleChange = (event) => {
        setInputSearch(event.target.value);
        AdminStore.data.search = event.target.value;
    };
    const onSearch = () => {
        AdminStore.getManageNotification();
    }
    const onClearSearch = () => {
        setInputSearch('');
        AdminStore.data.search = '';
        AdminStore.getManageNotification();
    }
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            onSearch();
        }
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>Staffers LLC | Manage Notification List</title>
            </Helmet>
            {/* <body> */}
            <div className="container-scroller">
                <HeaderPage />
                <div className="container-fluid page-body-wrapper">
                    <DrawerMenuPage />
                    <div className="main-panel" style={{ right: 0, position: "absolute" }}>
                        <div className="d-flex justify-content-between content-wrapper p-3 bg-white ">
                            <h3 className="page-title">
                                <span className="page-title-icon linear text-white me-2">
                                    <i className="fas fa-bell"></i>
                                </span> Manage Notifications
                            </h3>
                            <a href="/admin/notification-add">
                                <h3 className="page-title signup-link" >
                                    <span className="page-title-icon linear text-white me-2">
                                        <i className="fas fa-paper-plane"></i>
                                    </span>Send Notification
                                </h3>
                            </a>
                        </div>
                        {/* <!-- partial --> */}
                        <div className="d-flex justify-content-end me-1">
                            <CInputGroup className="w-25">
                                <CFormInput placeholder="Search" value={inputSearch} onChange={handleChange} onKeyPress={handleKeyPress} />
                                {inputSearch && (
                                    <CButton onClick={onClearSearch} type="button" color="primary" variant="outline">
                                        <i className="fa fa-times" aria-hidden="true"></i>
                                    </CButton>
                                )}
                                <CButton onClick={onSearch} type="button" color="primary" variant="outline">
                                    <i className="fa fa-search" aria-hidden="true"></i>
                                </CButton>
                            </CInputGroup>
                        </div>
                        <div className="row bg-white pb-3">
                            <div className="row bg-white">
                                <table className="table dt-responsive table-hover nowrap" style={{ width: "100%" }} id={tableName} ref={tableRef}></table>
                            </div>
                        </div>
                    </div>

                    {/* <!-- main-panel ends --> */}
                </div>
                {/* <!-- page-body-wrapper ends --> */}
            </div>
            <BottomSheet open={isOpen} onDismiss={onDismiss} scrollLocking={true}
                header={
                    <>
                        <div className="text-end" style={{ cursor: "pointer" }} onClick={onDismiss}>
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </div>
                        <h4 className="flex items-center text-xl justify-center font-bold text-primary-800">
                            {signleData?.data ? JSON.parse(signleData?.data)?.data?.heading : "NOTIFICATION"}
                        </h4>
                    </>
                }>
                <div style={{ height: global.config.windowHeight / 2.5, overflowY: 'scroll' }}>
                    <div className="col-xl-12 col-md-12">
                        <div className="user-card-full">
                            <div className="row m-l-0 m-r-0">
                                <div className="col-sm-12">
                                    <div className="card-block">
                                        <div className='d-flex justify-content-between align-items-center b-b-default p-b-5'>
                                            <h6 class="f-w-600">Notification Details</h6>
                                            {signleData?.read_at && <h5><span class="badge badge-primary"> {moment(signleData?.read_at).fromNow()}</span></h5>}
                                        </div>
                                        <div className="row pt-3">
                                            {signleData && signleData?.data && <div class="col-sm-3 ">
                                                <p class="m-b-10 f-w-600">Subject</p>
                                                <h6 class="text-muted f-w-400">{JSON.parse(signleData?.data)?.data?.subject || "-"}</h6>
                                            </div>}
                                            {signleData && signleData?.data && JSON.parse(signleData?.data)?.data?.link && <div class="col-sm-3">
                                                <p class="m-b-10 f-w-600">Link</p>
                                                <a href={`${JSON.parse(signleData?.data)?.data?.link}`} target="_blank"><u className="f-w-400">{JSON.parse(signleData?.data)?.data?.link || "-"}</u></a>
                                            </div>}
                                            {signleData && signleData?.last_updated_by && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Last Updated By</p>
                                                <h6 className="text-muted f-w-400">{signleData?.last_updated_by?.title} {signleData?.last_updated_by?.first_name} {signleData?.last_updated_by?.last_name}</h6>
                                            </div>}
                                            {signleData && signleData?.last_updated_by && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Last Update</p>
                                                <h6 className="text-muted f-w-400">{moment(signleData?.updated_at).fromNow()}</h6>
                                            </div>}
                                            {signleData && signleData?.data && JSON.parse(signleData?.data)?.data?.content && Object.keys(JSON.parse(signleData?.data)?.data?.content).length > 0 && <div class="col-sm-12">
                                                <p class="m-b-10 f-w-600">Content</p>
                                                {/* <h6 class="text-muted f-w-400">{signleData?.content}</h6> */}
                                                <div dangerouslySetInnerHTML={{ __html: JSON.parse(signleData?.data)?.data?.content }} />
                                            </div>}

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BottomSheet>
            <Spinner isLoading={AdminStore.loading} />

        </React.Fragment >
    );
})

export default ManageNotificationList;