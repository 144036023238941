import React from 'react';
import swal from '@sweetalert/with-react';

const ProfileView = ({ userData, url,clientData }) => {
    swal({
        title: userData?.username ? 'Facility Details' : 'Caregiver Details',
        icon: 'info',
        content: (
            <div className="profile-card">
                <div className="profile-item">
                    <h3>{userData?.first_name} {userData?.last_name}</h3>
                </div>
                {userData?.bluesky_staff_id && <div className="profile-item">
                    <div className="ms-2">
                        <span className="badge badge-primary fs-6">{userData?.bluesky_staff_id}</span>
                    </div>
                </div>}
                {clientData?.organization_name && <div className="profile-item">
                    <div className="ms-2">
                        <span className="badge badge-primary fs-6">{clientData?.organization_name}</span>
                    </div>
                </div>}
                <div className="profile-item">
                    <i className="fas fa-envelope "></i><div className="ms-2"><a href={`mailto:${userData?.email}`}>{userData?.email}</a>
                    </div>
                </div>
                <div className="profile-item">
                    <i className="fas fa-phone"></i><div className="ms-2"><a href={`tel:${userData?.country_code}${userData?.contact}`}>{userData?.country_code}{userData?.contact}</a>
                    </div>
                </div>
            </div>
        ),
        buttons: {
            view: {
                text: 'View',
                value: 'view',
                className: 'btn-success',
            },
            confirm: {
                text: 'Close',
                value: null,
                visible: true,
                className: 'btn-dark',
                closeModal: true,
            },
        },
    }).then((value) => {
        if (value === 'view') {
            window.open(url, '_blank');
        }
    });
};

export default ProfileView;
