import React from "react";
import Header from '@components/PDFHeader';
import moment from "moment";
import { Images } from "@elements/index"
import { HOME_URL } from "../../../api/config";

export const SkilledNursingCompetencySelfAssessment = (props) => {
    return (
        <div className="container mt-3" id="form-content">
            <div >
                <div>
                    <div className="d-flex justify-content-between align-items-baseline mb-2">
                        <img
                            src={`${HOME_URL}static/media/brand.750d576a52f3ac057b3d.png`}
                            alt="staffers logo"
                            style={{
                                width: "180px",
                                height: "80px",
                            }}
                        />
                        <h5 >Skilled Nursing Competency Self Assessment</h5>
                    </div>
                    <hr style={{height:2, border: 'none', color: '#000'}} />

                    <h5 className="text-end"><b>Clinical Skills Validation Checklist</b></h5>

                    <div className="row">
                        <div className="col-6">
                            <p><b>Directions</b></p>
                            <p>Please circle a value for each question to provide us with
                                an assessment of your clinical experience. These values
                                confirm your strengths within your specialty and assist us
                                at placing you within an assignment that best matches your
                                current skill set.
                            </p>
                        </div>
                        <div className="col-6">
                            <ul className="list-group">
                                <li style={{ background: "#000" }} className="list-group-item p-0 text-center text-white">Experience</li>
                                <li className="list-group-item p-0 ps-1">0 - Not Applicable</li>
                                <li className="list-group-item  p-0 ps-1">1 - No Experience</li>
                                <li className="list-group-item  p-0 ps-1">2 - Some Experience (Require Assistance)</li>
                                <li className="list-group-item  p-0 ps-1">3 - Intermittent Experience (May Require Assistance)</li>
                                <li className="list-group-item  p-0 ps-1">4 - Experienced (Performs without Assistance)</li>
                                <li className="list-group-item  p-0 ps-1">5 - Very Experienced (Able to Teach/Supervise)</li>
                            </ul></div>
                    </div>
                    <div className="">
                        <div className="row  mt-5">
                            <div className="col-4 text-center">
                                <input placeholder="Full Name" style={{ borderWidth: "0px 0px 1px 0px" }} required type="text" minLength={2} maxLength={20} name="full name" value={props.inputValues.full_name} onChange={(e) => props.handleInputChange('full_name', e.target.value)} />
                            </div>
                            <div className="col-4 text-center">
                                <input placeholder="SS#" style={{ borderWidth: "0px 0px 1px 0px" }} type="number" minLength={2} maxLength={20} name="SS#" value={props.inputValues.SSno} onChange={(e) => props.handleInputChange('SSno', e.target.value)} />
                            </div>
                            <div className="col-4 text-center">
                                <input type='text' value={moment(new Date()).format("MM-DD-YYYY HH:mm:ss")} disabled />
                            </div>
                        </div>
                        <div className="row border-top border-2"></div>
                        <div className="row">
                            <small className="col-4 text-center">Print Name</small>
                            <small className="col-4 text-center">Last 4 Digits of SS#</small>
                            <small className="col-4 text-center">Date</small>
                        </div>
                    </div>
                    <div className="container mt-3" >
                        <table className="table table-bordered border-dark">
                            <thead className="thead-dark" style={{ background: "#000" }}>
                                <tr>
                                    <th className=" text-light p-1" colspan="1"  width={100}>General Skills</th>
                                    <th className="text-center text-light p-1" colspan="6">Experience</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" width={400}>Advanced directives</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`generalSkill${value}`}
                                                value={value}
                                                checked={props?.inputValues?.generalSkill == value}
                                                onChange={(e) => { props?.handleInputChange('generalSkill', e.target.value) }}
                                            />
                                            <label htmlFor={`generalSkill${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Awareness of HCAHPS</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`HCAHPS${value}`}
                                                value={value}
                                                checked={props?.inputValues?.HCAHPS == value}
                                                onChange={(e) => { props?.handleInputChange('HCAHPS', e.target.value) }}
                                            />
                                            <label htmlFor={`HCAHPS${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Patient/family teaching</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`pft${value}`}
                                                value={value}
                                                checked={props?.inputValues?.pft == value}
                                                onChange={(e) => { props?.handleInputChange('pft', e.target.value) }}
                                            />
                                            <label htmlFor={`pft${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Discharge planning</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`dischPlan${value}`}
                                                value={value}
                                                checked={props?.inputValues?.dischPlan == value}
                                                onChange={(e) => { props?.handleInputChange('dischPlan', e.target.value) }}
                                            />
                                            <label htmlFor={`dischPlan${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">UR/Medicare review</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`medReview${value}`}
                                                value={value}
                                                checked={props?.inputValues?.medReview == value}
                                                onChange={(e) => { props?.handleInputChange('medReview', e.target.value) }}
                                            />
                                            <label htmlFor={`medReview${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Lift/transfer devices</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`transDev${value}`}
                                                value={value}
                                                checked={props?.inputValues?.transDev == value}
                                                onChange={(e) => { props?.handleInputChange('transDev', e.target.value) }}
                                            />
                                            <label htmlFor={`transDev${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Specialty beds</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`specBeds${value}`}
                                                value={value}
                                                checked={props?.inputValues?.specBeds == value}
                                                onChange={(e) => { props?.handleInputChange('specBeds', e.target.value) }}
                                            />
                                            <label htmlFor={`specBeds${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Restrictive devices (restraints)</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`restrictivedevices${value}`}
                                                value={value}
                                                checked={props?.inputValues?.restrictivedevices == value}
                                                onChange={(e) => { props?.handleInputChange('restrictivedevices', e.target.value) }}
                                            />
                                            <label htmlFor={`restrictivedevices${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">End of life care/palliative care</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`palliativecare${value}`}
                                                value={value}
                                                checked={props?.inputValues?.palliativecare == value}
                                                onChange={(e) => { props?.handleInputChange('palliativecare', e.target.value) }}
                                            />
                                            <label htmlFor={`palliativecare${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Automated Medication Dispensing System, Pyxis, Omnicell</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`automatedMedDispSystem${value}`}
                                                value={value}
                                                checked={props?.inputValues?.automatedMedDispSystem == value}
                                                onChange={(e) => { props?.handleInputChange('automatedMedDispSystem', e.target.value) }}
                                            />
                                            <label htmlFor={`automatedMedDispSystem${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">National Patient Safety Goals</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`natPatientSafetyGoals${value}`}
                                                value={value}
                                                checked={props?.inputValues?.natPatientSafetyGoals == value}
                                                onChange={(e) => { props?.handleInputChange('natPatientSafetyGoals', e.target.value) }}
                                            />
                                            <label htmlFor={`natPatientSafetyGoals${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Accurate patient identification</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`accPatientIdentification${value}`}
                                                value={value}
                                                checked={props?.inputValues?.accPatientIdentification == value}
                                                onChange={(e) => { props?.handleInputChange('accPatientIdentification', e.target.value) }}
                                            />
                                            <label htmlFor={`accPatientIdentification${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Effective communication</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`effCommunication${value}`}
                                                value={value}
                                                checked={props?.inputValues?.effCommunication == value}
                                                onChange={(e) => { props?.handleInputChange('effCommunication', e.target.value) }}
                                            />
                                            <label htmlFor={`effCommunication${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Awareness of patient rights</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`awarePatientRights${value}`}
                                                value={value}
                                                checked={props?.inputValues?.awarePatientRights == value}
                                                onChange={(e) => { props?.handleInputChange('awarePatientRights', e.target.value) }}
                                            />
                                            <label htmlFor={`awarePatientRights${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Interpretation & communication of lab values</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`interpCommLabValues${value}`}
                                                value={value}
                                                checked={props?.inputValues?.interpCommLabValues == value}
                                                onChange={(e) => { props?.handleInputChange('interpCommLabValues', e.target.value) }}
                                            />
                                            <label htmlFor={`interpCommLabValues${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>

                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Labeling (medications & specimens)</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`labelMedSpecimens${value}`}
                                                value={value}
                                                checked={props?.inputValues?.labelMedSpecimens == value}
                                                onChange={(e) => { props?.handleInputChange('labelMedSpecimens', e.target.value) }}
                                            />
                                            <label htmlFor={`labelMedSpecimens${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Medication reconciliation</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`medReconciliation${value}`}
                                                value={value}
                                                checked={props?.inputValues?.medReconciliation == value}
                                                onChange={(e) => { props?.handleInputChange('medReconciliation', e.target.value) }}
                                            />
                                            <label htmlFor={`medReconciliation${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Anticoagulation therapy</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`anticoagTherapy${value}`}
                                                value={value}
                                                checked={props?.inputValues?.anticoagTherapy == value}
                                                onChange={(e) => { props?.handleInputChange('anticoagTherapy', e.target.value) }}
                                            />
                                            <label htmlFor={`anticoagTherapy${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Pain assessment & management</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`painAssessManage${value}`}
                                                value={value}
                                                checked={props?.inputValues?.painAssessManage == value}
                                                onChange={(e) => { props?.handleInputChange('painAssessManage', e.target.value) }}
                                            />
                                            <label htmlFor={`painAssessManage${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Infection control</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`infectControl${value}`}
                                                value={value}
                                                checked={props?.inputValues?.infectControl == value}
                                                onChange={(e) => { props?.handleInputChange('infectControl', e.target.value) }}
                                            />
                                            <label htmlFor={`infectControl${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Universal precautions</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`univPrecautions${value}`}
                                                value={value}
                                                checked={props?.inputValues?.univPrecautions == value}
                                                onChange={(e) => { props?.handleInputChange('univPrecautions', e.target.value) }}
                                            />
                                            <label htmlFor={`univPrecautions${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Isolation</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`isolationExp${value}`}
                                                value={value}
                                                checked={props?.inputValues?.isolationExp == value}
                                                onChange={(e) => { props?.handleInputChange('isolationExp', e.target.value) }}
                                            />
                                            <label htmlFor={`isolationExp${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Minimize risk for falls</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`minRiskFalls${value}`}
                                                value={value}
                                                checked={props?.inputValues?.minRiskFalls == value}
                                                onChange={(e) => { props?.handleInputChange('minRiskFalls', e.target.value) }}
                                            />
                                            <label htmlFor={`minRiskFalls${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Prevention of pressure ulcers</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`prevPressUlcers${value}`}
                                                value={value}
                                                checked={props?.inputValues?.prevPressUlcers == value}
                                                onChange={(e) => { props?.handleInputChange('prevPressUlcers', e.target.value) }}
                                            />
                                            <label htmlFor={`prevPressUlcers${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="container mt-3">
                        <table className="table table-bordered border-dark ">
                            <thead className="thead-dark" style={{ background: "#000" }}>
                                <tr>
                                    <th className=" text-light p-1" colspan="1">Cardiac</th>
                                    <th className="text-center text-light p-1" colspan="6">Experience</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" width={400}>Assessment of heart sounds</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`assessHeartSounds${value}`}
                                                value={value}
                                                checked={props?.inputValues?.assessHeartSounds == value}
                                                onChange={(e) => { props?.handleInputChange('assessHeartSounds', e.target.value) }}
                                            />
                                            <label htmlFor={`assessHeartSounds${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" >Identification of arrhythmias (rate/rhythm)</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`idArrhythmias${value}`}
                                                value={value}
                                                checked={props?.inputValues?.idArrhythmias == value}
                                                onChange={(e) => { props?.handleInputChange('idArrhythmias', e.target.value) }}
                                            />
                                            <label htmlFor={`idArrhythmias${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" >Pacemakers/AID’s</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`pacemakers${value}`}
                                                value={value}
                                                checked={props?.inputValues?.pacemakers == value}
                                                onChange={(e) => { props?.handleInputChange('pacemakers', e.target.value) }}
                                            />
                                            <label htmlFor={`pacemakers${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" >Cardiac arrest/CPR</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`cardiacArrest${value}`}
                                                value={value}
                                                checked={props?.inputValues?.cardiacArrest == value}
                                                onChange={(e) => { props?.handleInputChange('cardiacArrest', e.target.value) }}
                                            />
                                            <label htmlFor={`cardiacArrest${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr style={{ background: "#D9D9D9" }} >
                                    <td className="border-bottom border-dark p-1 document-table-td" colspan="8"><b>Care of Patient with:</b></td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" >Hypertension</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`hypertension${value}`}
                                                value={value}
                                                checked={props?.inputValues?.hypertension == value}
                                                onChange={(e) => { props?.handleInputChange('hypertension', e.target.value) }}
                                            />
                                            <label htmlFor={`hypertension${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" >Pre/post MI</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`postMI${value}`}
                                                value={value}
                                                checked={props?.inputValues?.postMI == value}
                                                onChange={(e) => { props?.handleInputChange('postMI', e.target.value) }}
                                            />
                                            <label htmlFor={`postMI${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" >CHF</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`CHF${value}`}
                                                value={value}
                                                checked={props?.inputValues?.CHF == value}
                                                onChange={(e) => { props?.handleInputChange('CHF', e.target.value) }}
                                            />
                                            <label htmlFor={`CHF${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" >Post cardiac surgery</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`postCardiacSurgery${value}`}
                                                value={value}
                                                checked={props?.inputValues?.postCardiacSurgery == value}
                                                onChange={(e) => { props?.handleInputChange('postCardiacSurgery', e.target.value) }}
                                            />
                                            <label htmlFor={`postCardiacSurgery${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" >Fluid & electrolyte imbalances</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`fluidElecImbal${value}`}
                                                value={value}
                                                checked={props?.inputValues?.fluidElecImbal == value}
                                                onChange={(e) => { props?.handleInputChange('fluidElecImbal', e.target.value) }}
                                            />
                                            <label htmlFor={`fluidElecImbal${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr style={{ background: "#D9D9D9" }} >
                                    <td className="border-bottom border-dark p-1 document-table-td" colspan="8"><b>Medication Administration</b></td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" >Anticoagulants</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`anticoagulants${value}`}
                                                value={value}
                                                checked={props?.inputValues?.anticoagulants == value}
                                                onChange={(e) => { props?.handleInputChange('anticoagulants', e.target.value) }}
                                            />
                                            <label htmlFor={`anticoagulants${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" >Antiarrhythmic</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`antiarrhythmic${value}`}
                                                value={value}
                                                checked={props?.inputValues?.antiarrhythmic == value}
                                                onChange={(e) => { props?.handleInputChange('antiarrhythmic', e.target.value) }}
                                            />
                                            <label htmlFor={`antiarrhythmic${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" >Beta blockers</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`betaBlockers${value}`}
                                                value={value}
                                                checked={props?.inputValues?.betaBlockers == value}
                                                onChange={(e) => { props?.handleInputChange('betaBlockers', e.target.value) }}
                                            />
                                            <label htmlFor={`betaBlockers${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" >Nitroglycerin</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`nitroglycerin${value}`}
                                                value={value}
                                                checked={props?.inputValues?.nitroglycerin == value}
                                                onChange={(e) => { props?.handleInputChange('nitroglycerin', e.target.value) }}
                                            />
                                            <label htmlFor={`nitroglycerin${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" >Diuretics</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`diuretics${value}`}
                                                value={value}
                                                checked={props?.inputValues?.diuretics == value}
                                                onChange={(e) => { props?.handleInputChange('diuretics', e.target.value) }}
                                            />
                                            <label htmlFor={`diuretics${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>

                            </tbody>
                        </table>
                    </div>


                    <div className="container mt-3">
                        <table className="table table-bordered border-dark ">
                            <thead className="thead-dark" style={{ background: "#000" }}>
                                <tr>
                                    <th className=" text-light p-1" colspan="1">Respiratory</th>
                                    <th className="text-center text-light p-1" colspan="6">Experience</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" width={400}>Assessment/auscultation of lung sounds</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`assessAuscultLungSounds${value}`}
                                                value={value}
                                                checked={props?.inputValues?.assessAuscultLungSounds == value}
                                                onChange={(e) => { props?.handleInputChange('assessAuscultLungSounds', e.target.value) }}
                                            />
                                            <label htmlFor={`assessAuscultLungSounds${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Establishing an airway</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`estabAirway${value}`}
                                                value={value}
                                                checked={props?.inputValues?.estabAirway == value}
                                                onChange={(e) => { props?.handleInputChange('estabAirway', e.target.value) }}
                                            />
                                            <label htmlFor={`estabAirway${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Chest PT</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`chestPT${value}`}
                                                value={value}
                                                checked={props?.inputValues?.chestPT == value}
                                                onChange={(e) => { props?.handleInputChange('chestPT', e.target.value) }}
                                            />
                                            <label htmlFor={`chestPT${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Incentive spirometry</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`incentSpriro${value}`}
                                                value={value}
                                                checked={props?.inputValues?.incentSpriro == value}
                                                onChange={(e) => { props?.handleInputChange('incentSpriro', e.target.value) }}
                                            />
                                            <label htmlFor={`incentSpriro${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Supplemental oxygen (cannula, facemask)</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`suppOxygen${value}`}
                                                value={value}
                                                checked={props?.inputValues?.suppOxygen == value}
                                                onChange={(e) => { props?.handleInputChange('suppOxygen', e.target.value) }}
                                            />
                                            <label htmlFor={`suppOxygen${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Tracheostomy care</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`trachCare${value}`}
                                                value={value}
                                                checked={props?.inputValues?.trachCare == value}
                                                onChange={(e) => { props?.handleInputChange('trachCare', e.target.value) }}
                                            />
                                            <label htmlFor={`trachCare${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Suctioning (tracheostomy & nasotracheal)</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`suctTrachNasotrach${value}`}
                                                value={value}
                                                checked={props?.inputValues?.suctTrachNasotrach == value}
                                                onChange={(e) => { props?.handleInputChange('suctTrachNasotrach', e.target.value) }}
                                            />
                                            <label htmlFor={`suctTrachNasotrach${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Nebulizer use</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`nebUse${value}`}
                                                value={value}
                                                checked={props?.inputValues?.nebUse == value}
                                                onChange={(e) => { props?.handleInputChange('nebUse', e.target.value) }}
                                            />
                                            <label htmlFor={`nebUse${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr style={{ background: "#D9D9D9" }} >
                                    <td className="border-bottom border-dark p-1 document-table-td" colspan="8"><b>Care of Patient with:</b></td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Pneumonia</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`pneumoniaExp${value}`}
                                                value={value}
                                                checked={props?.inputValues?.pneumoniaExp == value}
                                                onChange={(e) => { props?.handleInputChange('pneumoniaExp', e.target.value) }}
                                            />
                                            <label htmlFor={`pneumoniaExp${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Asthma</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`asthmaExp${value}`}
                                                value={value}
                                                checked={props?.inputValues?.asthmaExp == value}
                                                onChange={(e) => { props?.handleInputChange('asthmaExp', e.target.value) }}
                                            />
                                            <label htmlFor={`asthmaExp${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">COPD</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`COPD${value}`}
                                                value={value}
                                                checked={props?.inputValues?.COPD == value}
                                                onChange={(e) => { props?.handleInputChange('COPD', e.target.value) }}
                                            />
                                            <label htmlFor={`COPD${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Emphysema</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`emphysema${value}`}
                                                value={value}
                                                checked={props?.inputValues?.emphysema == value}
                                                onChange={(e) => { props?.handleInputChange('emphysema', e.target.value) }}
                                            />
                                            <label htmlFor={`emphysema${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr style={{ background: "#D9D9D9" }} >
                                    <td className="border-bottom border-dark p-1 document-table-td" colspan="8"><b>Use & Administration of:</b></td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Bronchodilators</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`bronchodilators${value}`}
                                                value={value}
                                                checked={props?.inputValues?.bronchodilators == value}
                                                onChange={(e) => { props?.handleInputChange('bronchodilators', e.target.value) }}
                                            />
                                            <label htmlFor={`bronchodilators${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Expectorants</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`expectorants${value}`}
                                                value={value}
                                                checked={props?.inputValues?.expectorants == value}
                                                onChange={(e) => { props?.handleInputChange('expectorants', e.target.value) }}
                                            />
                                            <label htmlFor={`expectorants${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Corticosteroids</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`corticosteroids${value}`}
                                                value={value}
                                                checked={props?.inputValues?.corticosteroids == value}
                                                onChange={(e) => { props?.handleInputChange('corticosteroids', e.target.value) }}
                                            />
                                            <label htmlFor={`corticosteroids${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="container mt-3">
                        <table className="table table-bordered border-dark ">
                            <thead className="thead-dark" style={{ background: "#000" }}>
                                <tr>
                                    <th className=" text-light p-1" colspan="1">Neurology</th>
                                    <th className="text-center text-light p-1" colspan="6">Experience</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" width={400}>Assessment of neuro signs</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`assessNeuroSigns${value}`}
                                                value={value}
                                                checked={props?.inputValues?.assessNeuroSigns == value}
                                                onChange={(e) => { props?.handleInputChange('assessNeuroSigns', e.target.value) }}
                                            />
                                            <label htmlFor={`assessNeuroSigns${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Seizure precautions</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`seizurePrecautions${value}`}
                                                value={value}
                                                checked={props?.inputValues?.seizurePrecautions == value}
                                                onChange={(e) => { props?.handleInputChange('seizurePrecautions', e.target.value) }}
                                            />
                                            <label htmlFor={`seizurePrecautions${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr style={{ background: "#D9D9D9" }} >
                                    <td className="border-bottom border-dark p-1 document-table-td" colspan="8"><b>Care of Patient with:</b></td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">TBI (Traumatic Brain Injury: history of)</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`TBIhist${value}`}
                                                value={value}
                                                checked={props?.inputValues?.TBIhist == value}
                                                onChange={(e) => { props?.handleInputChange('TBIhist', e.target.value) }}
                                            />
                                            <label htmlFor={`TBIhist${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Seizure activity </td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`seizureActivity${value}`}
                                                value={value}
                                                checked={props?.inputValues?.seizureActivity == value}
                                                onChange={(e) => { props?.handleInputChange('seizureActivity', e.target.value) }}
                                            />
                                            <label htmlFor={`seizureActivity${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Spinal cord injury</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`spinalCordInjury${value}`}
                                                value={value}
                                                checked={props?.inputValues?.spinalCordInjury == value}
                                                onChange={(e) => { props?.handleInputChange('spinalCordInjury', e.target.value) }}
                                            />
                                            <label htmlFor={`spinalCordInjury${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Stroke (CVA)</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`strokeCVA${value}`}
                                                value={value}
                                                checked={props?.inputValues?.strokeCVA == value}
                                                onChange={(e) => { props?.handleInputChange('strokeCVA', e.target.value) }}
                                            />
                                            <label htmlFor={`strokeCVA${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Multiple sclerosis</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`multipleSclerosis${value}`}
                                                value={value}
                                                checked={props?.inputValues?.multipleSclerosis == value}
                                                onChange={(e) => { props?.handleInputChange('multipleSclerosis', e.target.value) }}
                                            />
                                            <label htmlFor={`multipleSclerosis${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Alzheimer’s disease</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`alzheimerDisease${value}`}
                                                value={value}
                                                checked={props?.inputValues?.alzheimerDisease == value}
                                                onChange={(e) => { props?.handleInputChange('alzheimerDisease', e.target.value) }}
                                            />
                                            <label htmlFor={`alzheimerDisease${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">ALS (Amyotrophic Lateral Sclerosis)</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`alsAmyotrophicLateralSclerosis${value}`}
                                                value={value}
                                                checked={props?.inputValues?.alsAmyotrophicLateralSclerosis == value}
                                                onChange={(e) => { props?.handleInputChange('alsAmyotrophicLateralSclerosis', e.target.value) }}
                                            />
                                            <label htmlFor={`alsAmyotrophicLateralSclerosis${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr style={{ background: "#D9D9D9" }} >
                                    <td className="border-bottom border-dark p-1 document-table-td" colspan="8"><b>Use & Administration of:</b></td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Antiseizure medications</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`antiSeizureMeds${value}`}
                                                value={value}
                                                checked={props?.inputValues?.antiSeizureMeds == value}
                                                onChange={(e) => { props?.handleInputChange('antiSeizureMeds', e.target.value) }}
                                            />
                                            <label htmlFor={`antiSeizureMeds${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Antiemetics</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`antiemetics${value}`}
                                                value={value}
                                                checked={props?.inputValues?.antiemetics == value}
                                                onChange={(e) => { props?.handleInputChange('antiemetics', e.target.value) }}
                                            />
                                            <label htmlFor={`antiemetics${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Laxatives</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`laxatives${value}`}
                                                value={value}
                                                checked={props?.inputValues?.laxatives == value}
                                                onChange={(e) => { props?.handleInputChange('laxatives', e.target.value) }}
                                            />
                                            <label htmlFor={`laxatives${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Enemas</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`enemas${value}`}
                                                value={value}
                                                checked={props?.inputValues?.enemas == value}
                                                onChange={(e) => { props?.handleInputChange('enemas', e.target.value) }}
                                            />
                                            <label htmlFor={`enemas${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Bowel prep</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`bowelPrep${value}`}
                                                value={value}
                                                checked={props?.inputValues?.bowelPrep == value}
                                                onChange={(e) => { props?.handleInputChange('bowelPrep', e.target.value) }}
                                            />
                                            <label htmlFor={`bowelPrep${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div className="container mt-3">
                        <table className="table table-bordered border-dark ">
                            <thead className="thead-dark" style={{ background: "#000" }}>
                                <tr>
                                    <th className=" text-light p-1" colspan="1">Gastrointestinal</th>
                                    <th className="text-center text-light p-1" colspan="6">Experience</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" width={400}>NG tube (insertion/removal)</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`NGtube${value}`}
                                                value={value}
                                                checked={props?.inputValues?.NGtube == value}
                                                onChange={(e) => { props?.handleInputChange('NGtube', e.target.value) }}
                                            />
                                            <label htmlFor={`NGtube${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Long term feeding tubes (dobhoff/keofeed)</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`sondob${value}`}
                                                value={value}
                                                checked={props?.inputValues?.sondob == value}
                                                onChange={(e) => { props?.handleInputChange('sondob', e.target.value) }}
                                            />
                                            <label htmlFor={`sondob${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Gastrostomy tube</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`gTube${value}`}
                                                value={value}
                                                checked={props?.inputValues?.gTube == value}
                                                onChange={(e) => { props?.handleInputChange('gTube', e.target.value) }}
                                            />
                                            <label htmlFor={`gTube${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Tube feedings</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`tFeeds${value}`}
                                                value={value}
                                                checked={props?.inputValues?.tFeeds == value}
                                                onChange={(e) => { props?.handleInputChange('tFeeds', e.target.value) }}
                                            />
                                            <label htmlFor={`tFeeds${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Monitoring input/output</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`mIOtracking${value}`}
                                                value={value}
                                                checked={props?.inputValues?.mIOtracking == value}
                                                onChange={(e) => { props?.handleInputChange('mIOtracking', e.target.value) }}
                                            />
                                            <label htmlFor={`mIOtracking${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr style={{ background: "#D9D9D9" }} >
                                    <td className="border-bottom border-dark p-1 document-table-td" colspan="8"><b>Care of Patient with:</b></td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Colostomy/ileostomy</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`cOstomy${value}`}
                                                value={value}
                                                checked={props?.inputValues?.cOstomy == value}
                                                onChange={(e) => { props?.handleInputChange('cOstomy', e.target.value) }}
                                            />
                                            <label htmlFor={`cOstomy${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">GI bleed</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`giBleed${value}`}
                                                value={value}
                                                checked={props?.inputValues?.giBleed == value}
                                                onChange={(e) => { props?.handleInputChange('giBleed', e.target.value) }}
                                            />
                                            <label htmlFor={`giBleed${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Feeding devices/adaptive equipment</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`fEquipDevices${value}`}
                                                value={value}
                                                checked={props?.inputValues?.fEquipDevices == value}
                                                onChange={(e) => { props?.handleInputChange('fEquipDevices', e.target.value) }}
                                            />
                                            <label htmlFor={`fEquipDevices${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Dietary restrictions</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`dRestrictions${value}`}
                                                value={value}
                                                checked={props?.inputValues?.dRestrictions == value}
                                                onChange={(e) => { props?.handleInputChange('dRestrictions', e.target.value) }}
                                            />
                                            <label htmlFor={`dRestrictions${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">GT/PEG feedings</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`gtPegFeeds${value}`}
                                                value={value}
                                                checked={props?.inputValues?.gtPegFeeds == value}
                                                onChange={(e) => { props?.handleInputChange('gtPegFeeds', e.target.value) }}
                                            />
                                            <label htmlFor={`gtPegFeeds${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr style={{ background: "#D9D9D9" }} >
                                    <td className="border-bottom border-dark p-1 document-table-td" colspan="8"><b>Use & Administration of:</b></td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Antiemetics</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`aMetics${value}`}
                                                value={value}
                                                checked={props?.inputValues?.aMetics == value}
                                                onChange={(e) => { props?.handleInputChange('aMetics', e.target.value) }}
                                            />
                                            <label htmlFor={`aMetics${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Laxatives</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`lAxatives${value}`}
                                                value={value}
                                                checked={props?.inputValues?.lAxatives == value}
                                                onChange={(e) => { props?.handleInputChange('lAxatives', e.target.value) }}
                                            />
                                            <label htmlFor={`lAxatives${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Enemas</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`eNemas${value}`}
                                                value={value}
                                                checked={props?.inputValues?.eNemas == value}
                                                onChange={(e) => { props?.handleInputChange('eNemas', e.target.value) }}
                                            />
                                            <label htmlFor={`eNemas${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Bowel prep</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`bPrep${value}`}
                                                value={value}
                                                checked={props?.inputValues?.bPrep == value}
                                                onChange={(e) => { props?.handleInputChange('bPrep', e.target.value) }}
                                            />
                                            <label htmlFor={`bPrep${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="container mt-3">
                        <table className="table table-bordered border-dark ">
                            <thead className="thead-dark" style={{ background: "#000" }}>
                                <tr>
                                    <th className=" text-light p-1" colspan="1">Genitourinary</th>
                                    <th className="text-center text-light p-1" colspan="6">Experience</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" width={400}>Foley catheter insertion/removal</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`foleyCathInsertRemoval${value}`}
                                                value={value}
                                                checked={props?.inputValues?.foleyCathInsertRemoval == value}
                                                onChange={(e) => { props?.handleInputChange('foleyCathInsertRemoval', e.target.value) }}
                                            />
                                            <label htmlFor={`foleyCathInsertRemoval${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">GU irrigations</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`GUIrrigations${value}`}
                                                value={value}
                                                checked={props?.inputValues?.GUIrrigations == value}
                                                onChange={(e) => { props?.handleInputChange('GUIrrigations', e.target.value) }}
                                            />
                                            <label htmlFor={`GUIrrigations${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Nephrostomy tube</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`nephroTube${value}`}
                                                value={value}
                                                checked={props?.inputValues?.nephroTube == value}
                                                onChange={(e) => { props?.handleInputChange('nephroTube', e.target.value) }}
                                            />
                                            <label htmlFor={`nephroTube${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Suprapubic catheter</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`supraPubicCath${value}`}
                                                value={value}
                                                checked={props?.inputValues?.supraPubicCath == value}
                                                onChange={(e) => { props?.handleInputChange('supraPubicCath', e.target.value) }}
                                            />
                                            <label htmlFor={`supraPubicCath${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Ileo conduit</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`ileConduit${value}`}
                                                value={value}
                                                checked={props?.inputValues?.ileConduit == value}
                                                onChange={(e) => { props?.handleInputChange('ileConduit', e.target.value) }}
                                            />
                                            <label htmlFor={`ileConduit${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Use of bladder scan equipment</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`bladderScanUse${value}`}
                                                value={value}
                                                checked={props?.inputValues?.bladderScanUse == value}
                                                onChange={(e) => { props?.handleInputChange('bladderScanUse', e.target.value) }}
                                            />
                                            <label htmlFor={`bladderScanUse${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr style={{ background: "#D9D9D9" }} >
                                    <td className="border-bottom border-dark p-1 document-table-td" colspan="8"><b>Care of Patient with:</b></td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Shunts and fistulas</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`shuntsFistulas${value}`}
                                                value={value}
                                                checked={props?.inputValues?.shuntsFistulas == value}
                                                onChange={(e) => { props?.handleInputChange('shuntsFistulas', e.target.value) }}
                                            />
                                            <label htmlFor={`shuntsFistulas${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Straight catheterizations</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`straightCaths${value}`}
                                                value={value}
                                                checked={props?.inputValues?.straightCaths == value}
                                                onChange={(e) => { props?.handleInputChange('straightCaths', e.target.value) }}
                                            />
                                            <label htmlFor={`straightCaths${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Incontinence/bladder training</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`bladderTraining${value}`}
                                                value={value}
                                                checked={props?.inputValues?.bladderTraining == value}
                                                onChange={(e) => { props?.handleInputChange('bladderTraining', e.target.value) }}
                                            />
                                            <label htmlFor={`bladderTraining${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Chronic renal failure/dialysis</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`chronicRenalFailure${value}`}
                                                value={value}
                                                checked={props?.inputValues?.chronicRenalFailure == value}
                                                onChange={(e) => { props?.handleInputChange('chronicRenalFailure', e.target.value) }}
                                            />
                                            <label htmlFor={`chronicRenalFailure${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Peritoneal dialysis</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`peritonealDialysis${value}`}
                                                value={value}
                                                checked={props?.inputValues?.peritonealDialysis == value}
                                                onChange={(e) => { props?.handleInputChange('peritonealDialysis', e.target.value) }}
                                            />
                                            <label htmlFor={`peritonealDialysis${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>

                            </tbody>
                        </table>
                    </div>


                    <div className="container mt-3">
                        <table className="table table-bordered border-dark ">
                            <thead className="thead-dark" style={{ background: "#000" }}>
                                <tr>
                                    <th className=" text-light p-1" colspan="1">Orthopedic</th>
                                    <th className="text-center text-light p-1" colspan="6">Experience</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" width={400}>Total joint replacement</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`totalJointReplacement${value}`}
                                                value={value}
                                                checked={props?.inputValues?.totalJointReplacement == value}
                                                onChange={(e) => { props?.handleInputChange('totalJointReplacement', e.target.value) }}
                                            />
                                            <label htmlFor={`totalJointReplacement${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Arthroscopic surgery</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`arthroscopicSurgery${value}`}
                                                value={value}
                                                checked={props?.inputValues?.arthroscopicSurgery == value}
                                                onChange={(e) => { props?.handleInputChange('arthroscopicSurgery', e.target.value) }}
                                            />
                                            <label htmlFor={`arthroscopicSurgery${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Cast care</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`castCare${value}`}
                                                value={value}
                                                checked={props?.inputValues?.castCare == value}
                                                onChange={(e) => { props?.handleInputChange('castCare', e.target.value) }}
                                            />
                                            <label htmlFor={`castCare${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Pulse/CMS checks</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`CMSchecks${value}`}
                                                value={value}
                                                checked={props?.inputValues?.CMSchecks == value}
                                                onChange={(e) => { props?.handleInputChange('CMSchecks', e.target.value) }}
                                            />
                                            <label htmlFor={`CMSchecks${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Suture/staple removal</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`stapleRemoval${value}`}
                                                value={value}
                                                checked={props?.inputValues?.stapleRemoval == value}
                                                onChange={(e) => { props?.handleInputChange('stapleRemoval', e.target.value) }}
                                            />
                                            <label htmlFor={`stapleRemoval${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Prosthesis application</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`prosthesisApplication${value}`}
                                                value={value}
                                                checked={props?.inputValues?.prosthesisApplication == value}
                                                onChange={(e) => { props?.handleInputChange('prosthesisApplication', e.target.value) }}
                                            />
                                            <label htmlFor={`prosthesisApplication${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Standard extremity braces</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`stdExtremityBraces${value}`}
                                                value={value}
                                                checked={props?.inputValues?.stdExtremityBraces == value}
                                                onChange={(e) => { props?.handleInputChange('stdExtremityBraces', e.target.value) }}
                                            />
                                            <label htmlFor={`stdExtremityBraces${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr style={{ background: "#D9D9D9" }} >
                                    <td className="border-bottom border-dark p-1 document-table-td" colspan="8"><b>Care of Patient with:</b></td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Amputation</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`amputationNew${value}`}
                                                value={value}
                                                checked={props?.inputValues?.amputationNew == value}
                                                onChange={(e) => { props?.handleInputChange('amputationNew', e.target.value) }}
                                            />
                                            <label htmlFor={`amputationNew${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Stump wrapping</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`stumpWrapping${value}`}
                                                value={value}
                                                checked={props?.inputValues?.stumpWrapping == value}
                                                onChange={(e) => { props?.handleInputChange('stumpWrapping', e.target.value) }}
                                            />
                                            <label htmlFor={`stumpWrapping${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Laminectomy</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`laminectomy${value}`}
                                                value={value}
                                                checked={props?.inputValues?.laminectomy == value}
                                                onChange={(e) => { props?.handleInputChange('laminectomy', e.target.value) }}
                                            />
                                            <label htmlFor={`laminectomy${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Assistive devices</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`assistiveDevices${value}`}
                                                value={value}
                                                checked={props?.inputValues?.assistiveDevices == value}
                                                onChange={(e) => { props?.handleInputChange('assistiveDevices', e.target.value) }}
                                            />
                                            <label htmlFor={`assistiveDevices${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">CPM machines</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`CPMmachines${value}`}
                                                value={value}
                                                checked={props?.inputValues?.CPMmachines == value}
                                                onChange={(e) => { props?.handleInputChange('CPMmachines', e.target.value) }}
                                            />
                                            <label htmlFor={`CPMmachines${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>

                            </tbody>
                        </table>
                    </div>

                    <div className="container mt-3">
                        <table className="table table-bordered border-dark ">
                            <thead className="thead-dark" style={{ background: "#000" }}>
                                <tr>
                                    <th className=" text-light p-1" colspan="1">IV Therapy</th>
                                    <th className="text-center text-light p-1" colspan="6">Experience</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" width={400}>Start & maintain IVs</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`startMaintainIVs${value}`}
                                                value={value}
                                                checked={props?.inputValues?.startMaintainIVs == value}
                                                onChange={(e) => { props?.handleInputChange('startMaintainIVs', e.target.value) }}
                                            />
                                            <label htmlFor={`startMaintainIVs${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Blood draw: venous</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`bloodDraw${value}`}
                                                value={value}
                                                checked={props?.inputValues?.bloodDraw == value}
                                                onChange={(e) => { props?.handleInputChange('bloodDraw', e.target.value) }}
                                            />
                                            <label htmlFor={`bloodDraw${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Central line care (PICC)</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`centralLineCare${value}`}
                                                value={value}
                                                checked={props?.inputValues?.centralLineCare == value}
                                                onChange={(e) => { props?.handleInputChange('centralLineCare', e.target.value) }}
                                            />
                                            <label htmlFor={`centralLineCare${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Care & management of ports</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`careManagementofPorts${value}`}
                                                value={value}
                                                checked={props?.inputValues?.careManagementofPorts == value}
                                                onChange={(e) => { props?.handleInputChange('careManagementofPorts', e.target.value) }}
                                            />
                                            <label htmlFor={`careManagementofPorts${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Infusion pumps</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`infusionPumps${value}`}
                                                value={value}
                                                checked={props?.inputValues?.infusionPumps == value}
                                                onChange={(e) => { props?.handleInputChange('infusionPumps', e.target.value) }}
                                            />
                                            <label htmlFor={`infusionPumps${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Administration of blood/blood products</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`adminBloodProducts${value}`}
                                                value={value}
                                                checked={props?.inputValues?.adminBloodProducts == value}
                                                onChange={(e) => { props?.handleInputChange('adminBloodProducts', e.target.value) }}
                                            />
                                            <label htmlFor={`adminBloodProducts${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>

                            </tbody>
                        </table>
                    </div>

                    <div className="container mt-3">
                        <table className="table table-bordered border-dark ">
                            <thead className="thead-dark" style={{ background: "#000" }}>
                                <tr>
                                    <th className=" text-light p-1" colspan="1">Skin</th>
                                    <th className="text-center text-light p-1" colspan="6">Experience</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" width={400}>Wound care/surgical</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`woundSugCare${value}`}
                                                value={value}
                                                checked={props?.inputValues?.woundSugCare == value}
                                                onChange={(e) => { props?.handleInputChange('woundSugCare', e.target.value) }}
                                            />
                                            <label htmlFor={`woundSugCare${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" >Wound care/medical</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`woundMedCare${value}`}
                                                value={value}
                                                checked={props?.inputValues?.woundMedCare == value}
                                                onChange={(e) => { props?.handleInputChange('woundMedCare', e.target.value) }}
                                            />
                                            <label htmlFor={`woundMedCare${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" >Dressing changes</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`dressingChanges${value}`}
                                                value={value}
                                                checked={props?.inputValues?.dressingChanges == value}
                                                onChange={(e) => { props?.handleInputChange('dressingChanges', e.target.value) }}
                                            />
                                            <label htmlFor={`dressingChanges${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" >Skin assessment</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`skinAssessment${value}`}
                                                value={value}
                                                checked={props?.inputValues?.skinAssessment == value}
                                                onChange={(e) => { props?.handleInputChange('skinAssessment', e.target.value) }}
                                            />
                                            <label htmlFor={`skinAssessment${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" >Wound vac</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`woundVac${value}`}
                                                value={value}
                                                checked={props?.inputValues?.woundVac == value}
                                                onChange={(e) => { props?.handleInputChange('woundVac', e.target.value) }}
                                            />
                                            <label htmlFor={`woundVac${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>

                            </tbody>
                        </table>
                    </div>

                    <div className="container mt-3">
                        <table className="table table-bordered border-dark ">
                            <thead className="thead-dark" style={{ background: "#000" }}>
                                <tr>
                                    <th className=" text-light p-1" colspan="1">Age Specific Competencies</th>
                                    <th className="text-center text-light p-1" colspan="6">Experience</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" width={400}>Newborn/neonate (birth - 30 days)</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`newbornneonatebirth30days${value}`}
                                                value={value}
                                                checked={props?.inputValues?.newbornneonatebirth30days == value}
                                                onChange={(e) => { props?.handleInputChange('newbornneonatebirth30days', e.target.value) }}
                                            />
                                            <label htmlFor={`newbornneonatebirth30days${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Infant (31 days – 1 year)</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`infantExp${value}`}
                                                value={value}
                                                checked={props?.inputValues?.infantExp == value}
                                                onChange={(e) => { props?.handleInputChange('infantExp', e.target.value) }}
                                            />
                                            <label htmlFor={`infantExp${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" >Toddler (ages 2-3 years)</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`toddlerages2to3years${value}`}
                                                value={value}
                                                checked={props?.inputValues?.toddlerages2to3years == value}
                                                onChange={(e) => { props?.handleInputChange('toddlerages2to3years', e.target.value) }}
                                            />
                                            <label htmlFor={`toddlerages2to3years${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" >Preschool (ages 4-5 years)</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`preschoolages4to5years${value}`}
                                                value={value}
                                                checked={props?.inputValues?.preschoolages4to5years == value}
                                                onChange={(e) => { props?.handleInputChange('preschoolages4to5years', e.target.value) }}
                                            />
                                            <label htmlFor={`preschoolages4to5years${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" >School age (ages 6-12 years)</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`schoolageages6to12years${value}`}
                                                value={value}
                                                checked={props?.inputValues?.schoolageages6to12years == value}
                                                onChange={(e) => { props?.handleInputChange('schoolageages6to12years', e.target.value) }}
                                            />
                                            <label htmlFor={`schoolageages6to12years${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Adolescent (ages 13-21 years)</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`adolescentages13to21years${value}`}
                                                value={value}
                                                checked={props?.inputValues?.adolescentages13to21years == value}
                                                onChange={(e) => { props?.handleInputChange('adolescentages13to21years', e.target.value) }}
                                            />
                                            <label htmlFor={`adolescentages13to21years${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Young adult (ages 22-39 years)</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`youngadultages22to39years${value}`}
                                                value={value}
                                                checked={props?.inputValues?.youngadultages22to39years == value}
                                                onChange={(e) => { props?.handleInputChange('youngadultages22to39years', e.target.value) }}
                                            />
                                            <label htmlFor={`youngadultages22to39years${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Older adult (ages 40-64 years)</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`olderadultages40to64years${value}`}
                                                value={value}
                                                checked={props?.inputValues?.olderadultages40to64years == value}
                                                onChange={(e) => { props?.handleInputChange('olderadultages40to64years', e.target.value) }}
                                            />
                                            <label htmlFor={`olderadultages40to64years${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Elderly (ages 80+ years)</td>
                                    {[0, 1, 2, 3, 4, 5].map((value) => (
                                        <td key={`general-skill-${value}`} className="border-bottom border-dark p-1 document-table-td">
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                id={`elderlyages80plusyears${value}`}
                                                value={value}
                                                checked={props?.inputValues?.elderlyages80plusyears == value}
                                                onChange={(e) => { props?.handleInputChange('elderlyages80plusyears', e.target.value) }}
                                            />
                                            <label htmlFor={`elderlyages80plusyears${value}`}>&nbsp;{value}</label>
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="container mt-3">
                        <table className="table table-bordered border-dark ">
                            <thead className="thead-dark" style={{ background: "#000" }}>
                                <tr>
                                    <th className=" text-light p-1" colspan="1">Please list any additional skills:</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">1.<input placeholder=" " style={{ width: "90%", border: 0 }} type="text" minLength={2} maxLength={200} name="skil_one" value={props.inputValues.skillD2One} onChange={(e) => props.handleInputChange('skillD2One', e.target.value)} /> </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">2.<input placeholder=" " style={{ width: "90%", border: 0 }} type="text" minLength={2} maxLength={200} name="skil_one" value={props.inputValues.skillD2Two} onChange={(e) => props.handleInputChange('skillD2Two', e.target.value)} /></td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">3.<input placeholder=" " style={{ width: "90%", border: 0 }} type="text" minLength={2} maxLength={200} name="skil_one" value={props.inputValues.skillD2Three} onChange={(e) => props.handleInputChange('skillD2Three', e.target.value)} /></td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">4.<input placeholder=" " style={{ width: "90%", border: 0 }} type="text" minLength={2} maxLength={200} name="skil_one" value={props.inputValues.skillD2Four} onChange={(e) => props.handleInputChange('skillD2Four', e.target.value)} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="container mt-3">
                        <table className="table table-bordered border-dark ">
                            <thead className="thead-dark" style={{ background: "#000" }}>
                                <tr>
                                    <th className=" text-light p-1" colspan="1">Additional training:</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">1.<input placeholder=" " style={{ width: "90%", border: 0 }} type="text" minLength={2} maxLength={200} name="training_one" value={props.inputValues.trainingD2One} onChange={(e) => props.handleInputChange('trainingD2One', e.target.value)} /></td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">2.<input placeholder=" " style={{ width: "90%", border: 0 }} type="text" minLength={2} maxLength={200} name="training_two" value={props.inputValues.trainingD2Two} onChange={(e) => props.handleInputChange('trainingD2Two', e.target.value)} /></td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">3.<input placeholder=" " style={{ width: "90%", border: 0 }} type="text" minLength={2} maxLength={200} name="training_three" value={props.inputValues.trainingD2Three} onChange={(e) => props.handleInputChange('trainingD2Three', e.target.value)} /></td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">4.<input placeholder=" " style={{ width: "90%", border: 0 }} type="text" minLength={2} maxLength={200} name="training_four" value={props.inputValues.trainingD2Four} onChange={(e) => props.handleInputChange('trainingD2Four', e.target.value)} /></td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div className="container mt-3">
                        <table className="table table-bordered border-dark ">
                            <thead className="thead-dark" style={{ background: "#000" }}>
                                <tr>
                                    <th className=" text-light p-1" colspan="1">Additional equipment:</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">1.<input placeholder=" " style={{ width: "90%", border: 0 }} type="text" minLength={2} maxLength={200} name="skil_one" value={props.inputValues.equipmentD2One} onChange={(e) => props.handleInputChange('equipmentD2One', e.target.value)} /></td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">2.<input placeholder=" " style={{ width: "90%", border: 0 }} type="text" minLength={2} maxLength={200} name="skil_one" value={props.inputValues.equipmentD2Two} onChange={(e) => props.handleInputChange('equipmentD2Two', e.target.value)} /></td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">3.<input placeholder=" " style={{ width: "90%", border: 0 }} type="text" minLength={2} maxLength={200} name="skil_one" value={props.inputValues.equipmentD2Three} onChange={(e) => props.handleInputChange('equipmentD2Three', e.target.value)} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="container mt-3 mb-3">
                        <div className="p-3 container border border-1 border-dark ">
                            <p className="text-center"><b>The information on this and all preceding pages is true and correct.</b></p>

                            <div className="row">
                                <div className="col-6  text-center  mt-3">
                                    {props?.signature ? <img src={props?.signature} width={180} height={50} alt="Signature here" /> :
                                        <div class="badge badge-primary text-wrap pointer" onClick={props?.onOpenModal}>
                                            Click Here To Sign
                                        </div>
                                    }
                                </div>

                                <div className="col-6  text-center  mt-3" >
                                    <input className='w-100' type="text" value={moment(new Date()).format("MM-DD-YYYY HH:mm:ss")} disabled />
                                </div>
                            </div>

                            <div className="row">

                                <div className="col-6 text-center border-top">
                                    (Sign Name)
                                </div>
                                <div className="col-6 text-center border-top">
                                    (Date)
                                </div>
                            </div>
                        </div>
                    </div>
                    <p>Skilled Nursing Competency Self-Assessment – Staffers – Updated 7/15/2019 </p>
                </div>
            </div>


        </div>

    );
};

