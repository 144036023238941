/* eslint-disable */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react"
import HeaderPage from "../layout/HeaderPage";
import DrawerMenuPage from "../layout/DrawerMenuPage";
import * as Yup from 'yup';
import { ErrorMessage, Field, FieldArray, Formik, Form } from "formik";
import './UpdatePages.css'
import Spinner from "@components/Spinner";
import { InputField } from "@elements/index"
import AdminStore from "@store/AdminStore";
import Select, { components } from 'react-select';
import { toJS } from "mobx";
import { Helmet } from "react-helmet";
import { colourStylesTitle } from '@utils/customStyle/style';
import ReusableForm from "./component/ReusableForm";
import { CAccordion, CAccordionBody, CAccordionHeader, CAccordionItem, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CModal, CModalBody, CModalHeader, CModalTitle } from "@coreui/react-pro";

const UpdatePages = observer(() => {

    const [selectedTab, setSelectedTab] = useState(0);
    const [isOpenAddPage, setIsOpenAddPage] = useState(false);
    const [isOpenAddSection, setIsOpenAddSection] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);
    const [pages, setPages] = useState([]);

    useEffect(() => {
        onRefresh();
    }, []);

    useEffect(() => {
        setPages(toJS(AdminStore?.data?.pages))
    }, [AdminStore?.data?.pages]);

    const onRefresh = () => {
        setIsOpenAddPage(false)
        setIsOpenAddSection(false)
        AdminStore.getPages();
    }

    const onSelectTab = (tab) => {
        setSelectedTab(tab)
    }

    const openModalAddSection = () => {
        setSelectedImages([])
        setIsOpenAddSection(true)
    }
    const openModalAddPage = () => {
        setIsOpenAddPage(true)
    }

    const onDismissAddSec = () => {
        setIsOpenAddSection(false)
    }
    const onDismissAddPage = () => {
        setIsOpenAddPage(false)
    }


    const addPage = (values) => {
        AdminStore.addPage(values, onRefresh)
    }
    const handleImageClick = (image) => {
        window.open(image, '_blank');
    }

    const onAddSection = (values) => {
        const formData = new FormData();
        // Append data to the form
        formData.append("page_id", values.page_id);
        formData.append("sequence", values.sequence);
        values.section[0]?.images?.forEach((imageObject, index) => {
            formData.append(`images[${index}][file]`, imageObject.file);
            formData.append(`images[${index}][alt_text]`, imageObject.alt_text);
        });

        values.section[0]?.links && formData.append("link", JSON.stringify(values.section[0]?.links));
        values.section[0]?.content && formData.append("content", JSON.stringify(values.section[0]?.content));
        AdminStore.addSection(formData, onRefresh)
    }

    const handleSubmit = (values) => {
        const formData = new FormData();
        // Append data to the form
        formData.append("page_id", values.page_id);
        formData.append("section_id", values.section_id);
        formData.append("sequence", values.sequence);
        values.section[0]?.images?.forEach((imageObject, index) => {
            formData.append(`images[${index}][id]`, imageObject.id);
            formData.append(`images[${index}][file]`, imageObject.file || '');
            formData.append(`images[${index}][alt_text]`, imageObject.alt_text);
        });

        values.section[0]?.links && formData.append("link", JSON.stringify(values.section[0]?.links));
        values.section[0]?.content && formData.append("content", JSON.stringify(values.section[0]?.content));
        AdminStore.updateSection(formData, onRefresh)
    };


    return (
        <React.Fragment>
            {/* <body> */}
            <Helmet>
                <title>Staffers LLC | Pages</title>
            </Helmet>
            <div className="container-scroller">
                <HeaderPage />
                <div className="container-fluid page-body-wrapper">
                    <DrawerMenuPage />
                    <div className="main-panel" style={{ right: 0, position: "absolute" }}>
                        <div className="content-wrapper p-3 bg-white ">
                            <h3 className="page-title">
                                <span className="page-title-icon linear text-white me-2">
                                    <i className="fas fa-laptop-code"></i>
                                </span> Pages
                            </h3>

                        </div>
                        {/* <!-- partial --> */}
                        <div className="">
                            <div className="container-tab">
                                <div className="content">
                                    <div className="list">
                                        {pages?.map((page, index) => (
                                            <div key={index + 'pages'} className={selectedTab === index ? "slider 1" : "pointer border-bottom"} onClick={() => onSelectTab(index)}>
                                                <input
                                                    className="content-input"
                                                    type="radio"
                                                    name="slider"
                                                    checked={selectedTab === index}
                                                    id={page?.page_name?.toLowerCase()}
                                                />
                                                <label
                                                    htmlFor={page?.page_name?.toLowerCase()}
                                                    className="page"
                                                >
                                                    <span>{page?.page_name}</span>
                                                </label>

                                            </div>
                                        ))}
                                    </div>

                                    <div className="text-content text-center p-2">
                                        {
                                            <>
                                                <div className="content-heading">{pages[selectedTab]?.page_name} Content</div>
                                                {
                                                    pages[selectedTab]?.sections?.length > 0 ?
                                                        <CAccordion activeItemKey={1}>
                                                            {pages[selectedTab]?.sections?.map((page, index) =>
                                                                <CAccordionItem itemKey={page?.sequence} className={"pointer"}>
                                                                    <CAccordionHeader>Section #{page?.sequence}</CAccordionHeader>
                                                                    <CAccordionBody>
                                                                        {/* Your ReusableForm or other components go here */}
                                                                        <ReusableForm initialValues={page} onSubmit={handleSubmit} fields={page} />

                                                                    </CAccordionBody>
                                                                </CAccordionItem>

                                                            )}
                                                        </CAccordion> : <span>No Section Found!</span>}
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    {/* <!-- main-panel ends --> */}
                </div>
                {/* <!-- page-body-wrapper ends --> */}

                {<div className="float-btn">
                    <CDropdown>
                        <CDropdownToggle color="primary">
                            <i className="fas fa-plus"></i> Add
                        </CDropdownToggle>
                        <CDropdownMenu>
                            <CDropdownItem onClick={() => openModalAddPage()}>Add Page</CDropdownItem>
                            <CDropdownItem onClick={() => openModalAddSection()}>Add Section</CDropdownItem>
                        </CDropdownMenu>
                    </CDropdown>
                </div>}
            </div>
            {/* Add Page*/}
            <CModal backdrop="static" visible={isOpenAddPage} onClose={onDismissAddPage} >
                <CModalHeader >
                    <CModalTitle>Add New Page</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <Formik
                        initialValues={{
                            page_name: '',
                            ldjson: '',
                            meta_data: '',
                        }}
                        validationSchema={Yup.object({
                            page_name: Yup.string().required('Page name is required'),
                        })}
                        onSubmit={(values, { resetForm }) => {
                            addPage(values);
                            // resetForm(); // Optional: Reset the form after submission
                        }}
                    >
                        {({ values, errors, touched, handleBlur, handleChange }) => (
                            <Form>
                                <div className="row g-2">
                                    <div className="col-sm-12 mb-3">
                                        <InputField
                                            className={"did-floating-input"}
                                            classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                            type="text"
                                            maxLength={25}
                                            name="page_name"
                                            label={"Page Name"}
                                            value={values.page_name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {errors.page_name && touched.page_name ? (
                                            <div className="error-message">{errors.page_name}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-sm-12 mb-3">
                                        <label className="pb-2 label">Meta Data</label>
                                        <div className="">
                                            <textarea className="form-control"
                                                placeholder=""
                                                name={'meta_data'}
                                                value={values.meta_data}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                style={{ height: 100 }}>
                                            </textarea>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 mb-3">
                                        <label className="pb-2 label">SEO JSON</label>
                                        <div className="">
                                            <textarea className="form-control"
                                                placeholder=""
                                                name={'ldjson'}
                                                value={values.ldjson}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                style={{ height: 100 }}>
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="pt-4 d-flex justify-content-center pb-3">
                                    <button className="btn btn-primary w-25 p-2" type="submit">Submit</button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </CModalBody>
            </CModal >
            {/* Add Section */}
            <CModal size="lg" scrollable visible={isOpenAddSection} onClose={() => onDismissAddSec()} backdrop="static">
                <CModalHeader >
                    <CModalTitle>{"Add New Section On Home Page"}</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <Formik
                        initialValues={{ page_id: '', sequence: '', section: [{ images: [{ "file": '', "alt_text": '' }], content: [], links: [] }] }}
                        // validationSchema={validationSchemaAddSection}
                        onSubmit={(values) => {
                            // Handle form submission here
                            onAddSection(values);
                        }}
                    >
                        {({ values, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-lg-3 mb-3">
                                        <Select
                                            onChange={(selectedOption) => {
                                                setFieldValue('page_id', selectedOption?.id || '')
                                                setFieldValue('sequence', selectedOption?.sections?.length + 1)
                                            }}
                                            placeholder={''}
                                            label={'Select Page'}
                                            value={pages?.find((page) => page.id === values.page_id) || ''}
                                            styles={colourStylesTitle}
                                            isSearchable={true}
                                            options={pages}
                                            getOptionLabel={(option) => option.page_name}
                                            getOptionValue={(option) => option.id}
                                            components={{ Control }}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-12 col-lg-3 mb-3">
                                        <InputField
                                            className={"did-floating-input"}
                                            classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                            type="number"
                                            name="sequence"
                                            label={"Sequence"}
                                            min={1}
                                            value={values.sequence}
                                            onChange={handleChange}
                                            required={true}
                                            onBlur={handleBlur}
                                            disabled={true}
                                        />
                                    </div>
                                    <div className="col-sm-12 ">
                                        <FieldArray name="section">
                                            {({ push, remove }) => (
                                                <div>
                                                    {values?.section?.map((entry, index) => (
                                                        <div className=" mb-4" key={index + 'page'}>
                                                            <div className="">
                                                                {/* <h5 className="card-title">Entry {index + 1}</h5> */}

                                                                <div className="mb-3">
                                                                    <label className="form-label">Images:</label>
                                                                    <FieldArray name={`section[${index}].images`}>
                                                                        {({ push: pushImage, remove: removeImage }) => (
                                                                            <div>
                                                                                {entry?.images?.map((img, imageIndex) => (
                                                                                    <div className="input-group mb-2 me-2 pe-3" key={imageIndex + 'img'}>
                                                                                        <div className="input-group-prepend border border-primary">
                                                                                            <span className="input-group-text bg-primary text-primary"><span className="badge badge-primary">#{imageIndex + 1}</span></span>
                                                                                        </div>
                                                                                        <input
                                                                                            className="form-control"
                                                                                            accept="image/*"
                                                                                            style={{ lineHeight: '40px', borderRadius: "0px 8px 8px 0px" }}
                                                                                            type="file"
                                                                                            required
                                                                                            name={`section[${index}].images[${imageIndex}].file`}
                                                                                            onChange={(event) => {
                                                                                                const selectedFile = event.currentTarget.files[0];
                                                                                                if (selectedFile) {
                                                                                                    setFieldValue(`section[${index}].images[${imageIndex}].file`, selectedFile);
                                                                                                } else {
                                                                                                    // If no file is selected, set it to null or an empty string based on your preference
                                                                                                    setFieldValue(`section[${index}].images[${imageIndex}].file`, null);
                                                                                                }
                                                                                            }}
                                                                                            onBlur={(e) => {
                                                                                                const file = e.target.files[0];
                                                                                                if (file) {
                                                                                                    const reader = new FileReader();
                                                                                                    reader.onloadend = () => {
                                                                                                        const newImages = [...selectedImages];
                                                                                                        newImages[imageIndex] = reader.result;
                                                                                                        setSelectedImages(newImages);
                                                                                                    };
                                                                                                    reader.readAsDataURL(file);
                                                                                                } else {
                                                                                                    const newImages = [...selectedImages];
                                                                                                    newImages.splice(imageIndex, 1); // Remove the entry at imageIndex
                                                                                                    setSelectedImages(newImages);
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                        {/* Right side preview */}
                                                                                        {
                                                                                            <div>
                                                                                                {selectedImages?.length >= (imageIndex + 1) && <img onClick={() => handleImageClick(selectedImages[imageIndex])} src={selectedImages[imageIndex]} alt={`Selected Preview ${imageIndex + 1}`} style={{ maxHeight: '76px', maxWidth: '76px' }} />}
                                                                                            </div>
                                                                                        }

                                                                                        <input
                                                                                            className={"form-control"}
                                                                                            type="text"
                                                                                            label={"Image Text"}
                                                                                            placeholder="Type Some Text"
                                                                                            min={1}
                                                                                            value={values.section[0].images[imageIndex]?.alt_text}
                                                                                            name={`section[0].images[${imageIndex}].alt_text`}
                                                                                            onChange={handleChange}
                                                                                            required={true}
                                                                                            onBlur={handleBlur}
                                                                                        />
                                                                                        {imageIndex > -1 && (
                                                                                            <button className="btn btn-outline-danger" type="button" onClick={() => {
                                                                                                const newImages = [...selectedImages];
                                                                                                newImages.splice(imageIndex, 1); // Remove the entry at imageIndex
                                                                                                setSelectedImages(newImages);
                                                                                                removeImage(imageIndex)
                                                                                            }}>
                                                                                                Remove Image
                                                                                            </button>
                                                                                        )}
                                                                                    </div>
                                                                                ))}
                                                                                <button className="btn btn-outline-primary" type="button" onClick={() => pushImage('')}>
                                                                                    Add Image
                                                                                </button>
                                                                            </div>
                                                                        )}
                                                                    </FieldArray>
                                                                    <ErrorMessage name={`section[${index}].images`} component="div" className="text-danger" />
                                                                </div>

                                                                <div className="mb-3">
                                                                    <label className="form-label">Text:</label>
                                                                    <FieldArray name={`section[${index}].content`}>
                                                                        {({ push: pushText, remove: removeText }) => (
                                                                            <div>
                                                                                {entry?.content?.map((link, textIndex) => (
                                                                                    <div className="input-group mb-2" key={textIndex + 'content'}>
                                                                                        <div className="input-group-prepend border border-primary">
                                                                                            <span className="input-group-text bg-primary text-primary"><span className="badge badge-primary">#{textIndex + 1}</span></span>
                                                                                        </div>
                                                                                        <Field required className="form-control" placeholder="Enter content" type="text" name={`section[${index}].content[${textIndex}]`} />
                                                                                        {textIndex > -1 && (
                                                                                            <button className="btn btn-outline-danger" type="button" onClick={() => removeText(textIndex)}>
                                                                                                Remove content
                                                                                            </button>
                                                                                        )}
                                                                                    </div>
                                                                                ))}
                                                                                <button className="btn btn-outline-primary" type="button" onClick={() => pushText('')}>
                                                                                    Add content
                                                                                </button>
                                                                            </div>
                                                                        )}
                                                                    </FieldArray>
                                                                    <ErrorMessage name={`section[${index}].content`} component="div" className="text-danger" />
                                                                </div>

                                                                <div className="mb-3">
                                                                    <label className="form-label">Links:</label>
                                                                    <FieldArray name={`section[${index}].links`}>
                                                                        {({ push: pushLink, remove: removeLink }) => (
                                                                            <div>
                                                                                {entry.links.map((link, linkIndex) => (
                                                                                    <div className="input-group mb-2" key={linkIndex + 'link'}>
                                                                                        <div className="input-group-prepend border border-primary">
                                                                                            <span className="input-group-text bg-primary text-primary"><span className="badge badge-primary">#{linkIndex + 1}</span></span>
                                                                                        </div>
                                                                                        <Field required className="form-control" placeholder="Enter link" type="text" name={`section[${index}].links[${linkIndex}]`} />
                                                                                        {linkIndex > -1 && (
                                                                                            <button className="btn btn-outline-danger" type="button" onClick={() => removeLink(linkIndex)}>
                                                                                                Remove Link
                                                                                            </button>
                                                                                        )}
                                                                                    </div>
                                                                                ))}
                                                                                <button className="btn btn-outline-primary" type="button" onClick={() => pushLink('')}>
                                                                                    Add Link
                                                                                </button>
                                                                            </div>
                                                                        )}
                                                                    </FieldArray>
                                                                    <ErrorMessage name={`section[${index}].links`} component="div" className="text-danger" />
                                                                </div>

                                                                {/* {index > 0 && (
                                          <button className="btn btn-outline-danger" type="button" onClick={() => remove(index)}>
                                            Remove Entry
                                          </button>
                                        )} */}
                                                            </div>
                                                        </div>
                                                    ))}
                                                    {/* <button className="btn btn-outline-primary" type="button" onClick={() => push({ images: [], text: '', links: [''] })}>
                                    Add Entry
                                  </button> */}
                                                </div>
                                            )}
                                        </FieldArray>
                                    </div>
                                </div>
                                <button className="btn btn-primary" type="submit">
                                    Submit
                                </button>
                            </form>

                        )}
                    </Formik>
                </CModalBody>
            </CModal>
            {/* </body> */}
            <Spinner isLoading={AdminStore.loading} />

        </React.Fragment >
    );
})
export const Control = (props) => {
    return (
        <>
            <label className={props.hasValue || props.isFocused ? props?.selectProps?.isRequired ? "did-floating-label did-floating-label-top did-floating-labe-astrisk" : "did-floating-label did-floating-label-top" : props?.selectProps?.isRequired ? "did-floating-label did-floating-label did-floating-labe-astrisk" : "did-floating-label did-floating-label"} style={{ zIndex: 100 }}>{props?.selectProps?.label}</label>
            <components.Control {...props} />
        </>
    );
};
const MoreSelectedBadge = ({ items }) => {
    const style = {
        marginLeft: "auto",
        background: "#d4eefa",
        borderRadius: "4px",
        fontFamily: "Open Sans",
        fontSize: "11px",
        padding: "3px",
        order: 99
    };

    const title = items.join(", ");
    const length = items.length;
    const label = `+ ${length} item${length !== 1 ? "s" : ""}`;

    return (
        <div style={style} title={title}>
            {label}
        </div>
    );
};
const MultiValue = ({ index, getValue, ...props }) => {
    const maxToShow = 2;
    const overflow = getValue()
        .slice(maxToShow)
        .map((x) => x.label);

    return index < maxToShow ? (
        <components.MultiValue {...props} />
    ) : index === maxToShow ? (
        <MoreSelectedBadge items={overflow} />
    ) : null;
};
export default UpdatePages;
