import React from "react";
import Header from '@components/PDFHeader';
import moment from "moment";
import { MultiDatePicker } from "@elements/index"

const WABackgroundCheck = (props) => {
  return (
    <div id="form-content">
      <Header title="Background Check Authorization" />
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="border-box mb-3">
                <div className="">
                  <table className="table-screening input-back">
                    {/* <tbody className="set-line-height"> */}
                    <tbody className="">
                      <tr>
                        <td colSpan={6}>
                          <p>
                            <b>Section 1. Required: Applicant Information </b>
                            (All sections completed by the applicant, the person
                            receiving a background check).
                            <br></br>
                            The requesting entitiy will submit the applicant's
                            information through the online Background Check
                            System (BCS).
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td className="border-replace" colSpan={6}>
                          <p>
                            1. <b>Required: </b> LEGAL NAME AS IT IS LISTED ON
                            YOUR DRIVER'S LICENSE OR GOVERNMENT ISSUED PHOTO
                            IDENTIFICATION (ID)
                          </p>
                        </td>
                      </tr>
                      <tr className="border-replace1">
                        <td colSpan={2}>
                          <div>
                            <label for="">FIRST<span className="text-danger">*</span></label>
                            <input type="text" className="table-screening-input" minLength={2} maxLength={20} name="full name" value={props.inputValues.full_name} onChange={(e) => props.handleInputChange('full_name', e.target.value)} />
                          </div>
                        </td>
                        <td colSpan={2}>
                          <div>
                            <label for="">MIDDLE</label>
                            <input type="text" className="table-screening-input" minLength={2} maxLength={20} name="middle name" value={props.inputValues.middle_name} onChange={(e) => props.handleInputChange('middle_name', e.target.value)} />
                          </div>
                        </td>
                        <td colSpan={2}>
                          <div>
                            <label for="">LAST<span className="text-danger">*</span></label>
                            <input type="text" className="table-screening-input" minLength={2} maxLength={20} name="last name" value={props.inputValues.last_name} onChange={(e) => props.handleInputChange('last_name', e.target.value)} />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="border-replace" colSpan={6}>
                          <p>
                            2. <b>REQUIRED:</b> OTHER ALIAS FIRST, MIDDLE, AND
                            LAST NAMES YOU HAVE USED
                          </p>
                        </td>
                      </tr>
                      <tr className="border-replace1">
                        <td colSpan={2}>
                          <div>
                            <label for="">FIRST</label>
                            <input type="text" className="table-screening-input" value={props.inputValues.aliases_first} onChange={(e) => props.handleInputChange('aliases_first', e.target.value)} />
                          </div>
                        </td>
                        <td colSpan={2}>
                          <div>
                            <label for="">MIDDLE</label>
                            <input type="text" className="table-screening-input" value={props.inputValues.aliases_middle} onChange={(e) => props.handleInputChange('aliases_middle', e.target.value)} />
                          </div>
                        </td>
                        <td colSpan={2}>
                          <div>
                            <label for="">LAST</label>
                            <input type="text" className="table-screening-input" value={props.inputValues.aliases_last} onChange={(e) => props.handleInputChange('aliases_last', e.target.value)} />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <div>
                            <label for="">
                              3. <b>REQUIRED: </b> DATE OF BIRTH<span className="text-danger">*</span>
                              <br></br>(MM/DD/YYYY)
                            </label>
                            <div>
                              <MultiDatePicker required={false} multiple={false} value={props.inputValues.dob} onChange={(e) => props.handleInputChange('dob', e)} />
                            </div>
                          </div>
                        </td>
                        <td colSpan={2}>
                          <div>
                            <label for="">
                              4. <b>REQUIRED: </b> PHONE NUMBER
                              <br></br>(INCLUDE AREA CODE)
                            </label>
                            <input type="text" className="table-screening-input" value={props.inputValues.telephone} onChange={(e) => props.handleInputChange('telephone', e.target.value)} />
                          </div>
                        </td>
                        <td colSpan={2}>
                          <div>
                            <label for="">5. EMAIL ADDRESS</label>
                            <input type="email" className="table-screening-input" value={props.inputValues.email} onChange={(e) => props.handleInputChange('email', e.target.value)} />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <div>
                            <label for="">6. SOCIAL SECURITY NUMBER</label>
                            <input type="text" placeholder="AAA-GG-SSSS" className="table-screening-input" value={props.inputValues.ssn} onChange={(e) => props.handleInputChange('ssn', e.target.value)} />
                          </div>
                        </td>
                        <td colSpan={2}>
                          <div>
                            <label for="">
                              7A. <b>REQUIRED: </b> VALID DRIVER'S LICENSE OR
                              STATE ID <b>(WRITE NONE IF NONE)</b>
                            </label>
                            <input type="text" className="table-screening-input" value={props.inputValues.state_id} onChange={(e) => props.handleInputChange('state_id', e.target.value)} />
                          </div>
                        </td>
                        <td colSpan={2}>
                          <div>
                            <label for="">
                              7B. <b>REQUIRED: </b> ISSUING STATE
                            </label>
                            <input type="text" className="table-screening-input" value={props.inputValues.issue_state} onChange={(e) => props.handleInputChange('issue_state', e.target.value)} />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="border-replace" colSpan={6}>
                          <p>
                            8. <b>REQUIRED:</b> HAVEYOU LIVED IN ANY STATE OR
                            COUNTRY OTHER THAN WASHINGTON STATE WITHIN THE LAST
                            THREE YEARS (36 MONTHS)?
                          </p>
                          <div className="d-flex">
                            <label className="">
                              <input
                                type="radio"
                                name="thirtysixMONTHS"
                                value="thirtysixMONTHSYes"
                                className="radio-btn-size"
                                checked={props?.inputValues?.thirtysixMONTHS === "thirtysixMONTHSYes"} // Use 'checked' attribute
                                onChange={(e) => { props?.handleInputChange('thirtysixMONTHS', e.target.value) }}
                              />
                              <span className="checkmark"></span>&nbsp;
                              YES
                            </label>
                            <label className="">
                              <input
                                type="radio"
                                name="thirtysixMONTHS"
                                value="thirtysixMONTHSNo"
                                className="radio-btn-size"
                                checked={props?.inputValues?.thirtysixMONTHS === "thirtysixMONTHSNo"} // Use 'checked' attribute
                                onChange={(e) => { props?.handleInputChange('thirtysixMONTHS', e.target.value) }}
                              />
                              <span className="checkmark"></span>
                              &nbsp;   NO
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="" colSpan={6}>
                          <p>
                            9. <b>REQUIRED:</b> MAILING ADDRESS WHERE WE CAN
                            SEND YOU CONFIDENTIAL INFORMATION
                          </p>
                          <ul className="list-nineth">
                            <li>
                              <div>
                                <label>STREET</label>
                                <input type="text" className="table-screening-input" value={props.inputValues.conf_street} onChange={(e) => props.handleInputChange('conf_street', e.target.value)} />
                              </div>
                            </li>
                            <li>
                              <div>
                                <label>APT.NO.</label>
                                <input type="text" className="table-screening-input" value={props.inputValues.APT} onChange={(e) => props.handleInputChange('APT', e.target.value)} />
                              </div>
                            </li>
                            <li>
                              <div>
                                <label>CITY</label>
                                <input type="text" className="table-screening-input" value={props.inputValues.conf_city} onChange={(e) => props.handleInputChange('conf_city', e.target.value)} />
                              </div>
                            </li>
                            <li>
                              <div>
                                <label>STATE</label>
                                <input type="text" className="table-screening-input" value={props.inputValues.conf_state} onChange={(e) => props.handleInputChange('conf_state', e.target.value)} />
                              </div>
                            </li>
                            <li>
                              <div>
                                <label>ZIP CODE</label>
                                <input type="number" maxLength={7} className="table-screening-input" value={props.inputValues.conf_zip} onChange={(e) => props.handleInputChange('conf_zip', e.target.value)} />
                              </div>
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td className="border-replace" colSpan={6}>
                          <p>
                            10. <b>REQUIRED:</b> PHYSICAL ADDRESS WHERE YOU LIVE
                            NOW (WRITE "SAME IF ADDRESS IS THE SAME AS YOUR
                            MAILING ADDRESS")
                          </p>
                          <ul className="list-nineth">
                            <li>
                              <div>
                                <label>STREET</label>
                                <input type="text" className="table-screening-input" value={props.inputValues.PHYSICALADDRESS} onChange={(e) => props.handleInputChange('PHYSICALADDRESS', e.target.value)} />
                              </div>
                            </li>
                            <li>
                              <div>
                                <label>APT.NO.</label>
                                <input type="text" className="table-screening-input" value={props.inputValues.PHYSICALAPT} onChange={(e) => props.handleInputChange('PHYSICALAPT', e.target.value)} />
                              </div>
                            </li>
                            <li>
                              <div>
                                <label>CITY</label>
                                <input type="text" className="table-screening-input" value={props.inputValues.PHYSICALCITY} onChange={(e) => props.handleInputChange('PHYSICALCITY', e.target.value)} />
                              </div>
                            </li>
                            <li>
                              <div>
                                <label>STATE</label>
                                <input type="text" className="table-screening-input" value={props.inputValues.PHYSICALSTATE} onChange={(e) => props.handleInputChange('PHYSICALCITY', e.target.value)} />
                              </div>
                            </li>
                            <li>
                              <div>
                                <label>ZIP CODE</label>
                                <input type="text" className="table-screening-input" value={props.inputValues.PHYSICALZIP} onChange={(e) => props.handleInputChange('PHYSICALZIP', e.target.value)} />
                              </div>
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td className="" colSpan={6}>
                          <p className="m-2">
                            <b>
                              Section 2. Required: Self-Disclosure Questions for
                              ALL convictions and pending charges from any state
                              or jurisdiction. You must answer Questions 11A
                              through 14. Attach Page 2 if you have crimes or
                              pending charges. <b>SEE INSTRUCTIONS.</b>
                            </b>{" "}
                            PHYSICAL ADDRESS WHERE YOU LIVE NOW (WRITE "SAME IF
                            ADDRESS IS THE SAME AS YOUR MAILING ADDRESS")
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={6}>
                          <div className="mb-2 mt-2 d-flex justify-content-between">
                            <p>
                              11A. Have you been convicted of any crime? If yes,
                              complete Page 2, Section 3.......................
                            </p>

                            <div className="d-flex w-22 justify-content-end">
                              <label className="">
                                <input
                                  type="radio"
                                  name="crime"
                                  value="crimeYes"
                                  className="radio-btn-size"
                                  checked={props?.inputValues?.crime === "crimeYes"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('crime', e.target.value) }}
                                />
                                <span className="checkmark ms-1"></span>
                                YES
                              </label>
                              <label className="">
                                <input
                                  type="radio"
                                  name="crime"
                                  value="crimeNo"
                                  className="radio-btn-size"
                                  checked={props?.inputValues?.crime === "crimeNo"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('crime', e.target.value) }}
                                />
                                <span className="checkmark ms-1"></span>
                                NO
                              </label>
                            </div>
                          </div>
                          <div className="mb-2 d-flex justify-content-between">
                            <p>
                              11B. Do you have charges (pending) against you for
                              any crime? If yes, complete Page 2, Section
                              4.......................
                            </p>
                            <div className="d-flex w-22 justify-content-end">
                              <label className="">
                                <input
                                  type="radio"
                                  name="charges"
                                  value="chargesYes"
                                  className="radio-btn-size"
                                  checked={props?.inputValues?.charges === "chargesYes"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('charges', e.target.value) }}
                                />
                                <span className="checkmark ms-1"></span>
                                YES
                              </label>
                              <label className="">
                                <input
                                  type="radio"
                                  name="charges"
                                  value="chargesNo"
                                  className="radio-btn-size"
                                  checked={props?.inputValues?.charges === "chargesNo"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('charges', e.target.value) }}
                                />
                                <span className="checkmark"></span>
                                NO
                              </label>
                            </div>
                          </div>
                          <div className="mb-2 d-flex justify-content-between">
                            <p>
                              12. Has a court or state agency ever issued you an
                              order or other final notification stating that you
                              have sexually abused, physically abused,
                              neglected, abandoned, or exploited a child,
                              juvenile, or vulnerable adult?..
                            </p>
                            <div className="d-flex w-22 justify-content-end">
                              <label className="">
                                <input
                                  type="radio"
                                  name="abused"
                                  value="abusedYes"
                                  className="radio-btn-size"
                                  checked={props?.inputValues?.abused === "abusedYes"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('abused', e.target.value) }}
                                />
                                <span className="checkmark"></span>
                                YES
                              </label>
                              <label className="">
                                <input
                                  type="radio"
                                  name="abused"
                                  value="abusedNo"
                                  className="radio-btn-size"
                                  checked={props?.inputValues?.abused === "abusedNo"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('abused', e.target.value) }}
                                />
                                <span className="checkmark"></span>
                                NO
                              </label>
                            </div>
                          </div>
                          <div className="mb-2 d-flex justify-content-between">
                            <p>
                              13. Has a government agency ever denied,
                              terminated, or revoked your contract or license
                              for failing to care for children, juveniles, or
                              vulnerable adults; or have you ever given up your
                              contract or license because a government agency
                              was taking action against you for failing to care
                              for children, juveniles, or vulnerable
                              adults?.......................................
                            </p>
                            <div className="d-flex w-22 justify-content-end">
                              <label className="">
                                <input
                                  type="radio"
                                  name="vulnerable"
                                  value="vulnerableYes"
                                  className="radio-btn-size"
                                  checked={props?.inputValues?.vulnerable === "vulnerableYes"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('vulnerable', e.target.value) }}
                                />
                                <span className="checkmark"></span>
                                YES
                              </label>
                              <label className="">
                                <input
                                  type="radio"
                                  name="vulnerable"
                                  value="vulnerableNo"
                                  className="radio-btn-size"
                                  checked={props?.inputValues?.vulnerable === "vulnerableNo"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('vulnerable', e.target.value) }}
                                />
                                <span className="checkmark"></span>
                                NO
                              </label>
                            </div>
                          </div>
                          <div className="mb-2 d-flex justify-content-between">
                            <p>
                              14. Has a court ever entered any of the following
                              orders against you for abuse, sexual abuse,
                              neglect, abandonment, domestic violence,
                              exploitation, or financial exploitation of a
                              vulnerable adult, juvenile, or child..
                            </p>
                            <div className="d-flex w-22 justify-content-end">
                              <label className="">
                                <input
                                  type="radio"
                                  name="exploitation"
                                  value="exploitationYes"
                                  className="radio-btn-size"
                                  checked={props?.inputValues?.exploitation === "exploitationYes"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('exploitation', e.target.value) }}
                                />
                                <span className="checkmark"></span>
                                YES
                              </label>
                              <label className="">
                                <input
                                  type="radio"
                                  name="exploitation"
                                  value="exploitationNo"
                                  className="radio-btn-size"
                                  checked={props?.inputValues?.exploitation === "exploitationNo"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('exploitation', e.target.value) }}
                                />
                                <span className="checkmark"></span>
                                NO
                              </label>
                            </div>
                          </div>
                          <ul>
                            <li>
                              <p>
                                Permanent vulnerable adult protection order /
                                restraining order, either active or expired,
                                under RCW 74.34.
                              </p>
                            </li>
                            <li>
                              <p>
                                Sexual assault protection order under RCW 7.90.
                              </p>
                            </li>
                            <li>
                              <p>
                                Permanent civil anti-harassment protection
                                order, either active or expired, under RCW
                                10.14.
                              </p>
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={6} className="mb-2 mt-2">
                          <p>
                            I am the person named above. If I do not tell the
                            whole truth on this div, I understand I can be
                            charged with perjury and I may not be allowed to
                            work with vulnerable adults, juveniles, or children.
                            I understand and agree my signature in the box below
                            means:
                          </p>

                          <ul>
                            <li>
                              <p className="mb-2">
                                I give DSHS permission to check my background
                                with any governmental entity and law enforcement
                                agency.
                              </p>
                            </li>
                            <li>
                              <p className="mb-2">
                                My background check result may include prior
                                self-disclosure information and fingerprint
                                results that are contained in the DSHS
                                Background Check System and that this
                                information will be reported as allowed by
                                federal or state law.
                              </p>
                            </li>
                            <li>
                              <p className="mb-2">
                                If a final finding is identified, DSHS will
                                report only my name and that a final finding was
                                identified on the background check result.
                              </p>
                            </li>
                            <li>
                              <p className="mb-2">
                                DSHS will give my background check result to the
                                persons or entities requesting my background
                                check and those persons or entities may release
                                my background check results to other persons or
                                entities when the law authorizes or requires
                                DSHS to do so. Fingerprint rap sheets are
                                provided if allowed by federal or state law.
                              </p>
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr className="border-replace1">
                        <td colSpan={5}>
                          <div>
                            <label for="">
                              15. <b>REQUIRED: </b> SIGNATURE. YOUR PARENT OR
                              GUARDIAN'S SIGNATURE IF YOU ARE UNDER 18.
                            </label>
                            {props?.signatureParent ? <img src={props?.signatureParent} width={180} height={50} alt="Siganture Here" onClick={props?.onOpenModalParent} /> : <div class="badge badge-primary text-wrap w-25 pointer" onClick={props?.onOpenModalParent}>
                              Click Here To Sign
                            </div>}
                          </div>
                        </td>
                        <td colSpan={1}>
                          <div>
                            <label for="">
                              16. <b>REQUIRED: </b> TODAY'S <br></br>
                              DATE (MM/DD/YYYY)
                            </label>
                            <input className="table-screening-input" value={moment(new Date()).format("MM-DD-YYYY HH:mm:ss")} disabled />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <br></br>
            <div className="col-md-12">
              <div>
                <h4 className="heading-head">
                  List of Crimes and Pending Charges
                </h4>
                <p>
                  This page <b>MUST </b> be attached to Page One of the
                  Background Check Authorization div if 11A or 11B are marked
                  "Yes."
                </p>
                <p>
                  <b>
                    Important information about answering self-disclosure
                    questions:{" "}
                  </b>{" "}
                  Your answers to self-disclosure questions become part of your
                  background check history and are stored in the DSHS database.
                  It is recommended that you refer to charging papers, court
                  records, or other official documents and that you list
                  criminal convictions, pending charges, dates, and other
                  information exactly as they are listed in those documents.
                </p>
                <div className="border-box mb-3">
                  <table className="table-screening input-back">
                    <tbody className="">
                      <tr>
                        <td className="border-remove" colSpan={6}>
                          <p>
                            <b>REQUIRED: </b> PRINT YOUR NAME AS IT IS LISTED ON
                            YOUR DRIVER'S LICENSE OR GOVERNMENT ISSUED PHOTO ID
                          </p>
                        </td>
                      </tr>
                      <tr className="border-replace1">
                        <td colSpan={2}>
                          <div>
                            <label for="">FIRST:</label>
                            <input type="text" className="table-screening-input" required value={props.inputValues.full_name} onChange={(e) => props.handleInputChange('full_name', e.target.value)} />
                          </div>
                        </td>
                        <td colSpan={2}>
                          <div>
                            <label for="">MIDDLE:</label>
                            <input type="text" className="table-screening-input" value={props.inputValues.middle_name} onChange={(e) => props.handleInputChange('middle_name', e.target.value)} />
                          </div>
                        </td>
                        <td colSpan={2}>
                          <div>
                            <label for="">LAST:</label>
                            <input type="text" className="table-screening-input" value={props.inputValues.last_name} onChange={(e) => props.handleInputChange('last_name', e.target.value)} />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <p>
                            <b>REQUIRED: </b> DATE OF BIRTH (MM/DD/YYYY)
                            <MultiDatePicker className="table-screening-input" required={false} multiple={false} value={props.inputValues.dob} onChange={(e) => props.handleInputChange('dob', e)} />
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={6}>
                          <p>
                            <b>Section 3. Questions 11A. </b> If you check{" "}
                            <b>YES, </b> you must enter the crime name, degree
                            (if any), state, conviction date, and crime
                            information.
                          </p>
                        </td>
                      </tr>
                      <tr className="">
                        <td colSpan={3}>
                          <div>
                            <label for="">1. CRIME NAME</label>
                            <input type="text" className="table-screening-input" value={props.inputValues.crime_name} onChange={(e) => props.handleInputChange('crime_name', e.target.value)} />
                          </div>
                        </td>
                        <td colSpan={1}>
                          <div>
                            <label for="">DEGREE (IF ANY)</label>
                            <input type="text" className="table-screening-input" value={props.inputValues.DEGREE} onChange={(e) => props.handleInputChange('DEGREE', e.target.value)} />
                          </div>
                        </td>
                        <td colSpan={1}>
                          <div>
                            <label for="">STATE</label>
                            <input type="text" className="table-screening-input" value={props.inputValues.CRIMESTATE} onChange={(e) => props.handleInputChange('CRIMESTATE', e.target.value)} />
                          </div>
                        </td>
                        <td colSpan={1}>
                          <div>
                            <label for="">
                              CONVICTION DATE <br></br>(MM/DD/YYYY)
                            </label>
                            <MultiDatePicker className="table-screening-input" required={false} multiple={false} value={props.inputValues.CONVICTIONDATE} onChange={(e) => props.handleInputChange('CONVICTIONDATE', e)} />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={6}>
                          <div className="d-md-flex align-items-center p-2">
                            Other crime information:
                            <label className="">
                              <input
                                type="checkbox"
                                checked={props.inputValues.Attempted} // Use 'checked' attribute
                                onChange={(e) => { props.handleInputChange('Attempted', e.target.checked) }} // Use 'e.target.checked'
                              />
                              <span className="checkmark"></span>
                              Attempted
                            </label>
                            <label className="">
                              <input
                                type="checkbox"
                                checked={props.inputValues.Conspiracy} // Use 'checked' attribute
                                onChange={(e) => { props.handleInputChange('Conspiracy', e.target.checked) }} // Use 'e.target.checked'
                              />
                              <span className="checkmark"></span>
                              Conspiracy
                            </label>
                            <label className="">
                              <input
                                type="checkbox"
                                checked={props.inputValues.DomesticViolence} // Use 'checked' attribute
                                onChange={(e) => { props.handleInputChange('DomesticViolence', e.target.checked) }} // Use 'e.target.checked'
                              />
                              <span className="checkmark"></span>
                              Domestic Violence
                            </label>
                            <label className="">
                              <input
                                type="checkbox"
                                checked={props.inputValues.Solicitation} // Use 'checked' attribute
                                onChange={(e) => { props.handleInputChange('Solicitation', e.target.checked) }} // Use 'e.target.checked'
                              />
                              <span className="checkmark"></span>
                              Solicitation
                            </label>
                            <label className="">
                              <input
                                type="checkbox"
                                checked={props.inputValues.WithSexualMotivation} // Use 'checked' attribute
                                onChange={(e) => { props.handleInputChange('WithSexualMotivation', e.target.checked) }} // Use 'e.target.checked'
                              />
                              <span className="checkmark"></span>
                              With Sexual Motivation
                            </label>
                            <label className="">
                              <input
                                type="checkbox"
                                checked={props.inputValues.NA} // Use 'checked' attribute
                                onChange={(e) => { props.handleInputChange('NA', e.target.checked) }} // Use 'e.target.checked'
                              />
                              <span className="checkmark"></span>
                              N/A
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={6}>
                          <p>
                            DESCRIPTION OF CRIME (REQUIRED WHEN CRIME IS
                            COMMITTED OUTSIDE OF WASHINGTON STATE)
                          </p>
                          <textarea row="2" type="text" className="table-screening-input" value={props.inputValues.crime_description} onChange={(e) => props.handleInputChange('crime_description', e.target.value)} />
                        </td>
                      </tr>
                      <tr className="">
                        <td colSpan={3}>
                          <div>
                            <label for="">2. CRIME NAME</label>
                            <input type="text" className="table-screening-input" value={props.inputValues.CRIMENAMETWO} onChange={(e) => props.handleInputChange('CRIMENAMETWO', e.target.value)} />
                          </div>
                        </td>
                        <td colSpan={1}>
                          <div>
                            <label for="">DEGREE (IF ANY)</label>
                            <input type="text" className="table-screening-input" value={props.inputValues.DEGREETWO} onChange={(e) => props.handleInputChange('DEGREETWO', e.target.value)} />
                          </div>
                        </td>
                        <td colSpan={1}>
                          <div>
                            <label for="">STATE</label>
                            <input type="text" className="table-screening-input" value={props.inputValues.CRIMESTATETWO} onChange={(e) => props.handleInputChange('CRIMESTATETWO', e.target.value)} />
                          </div>
                        </td>
                        <td colSpan={1}>
                          <div>
                            <label for="">
                              CONVICTION DATE <br></br> (MM/DD/YYYY)
                            </label>
                            <MultiDatePicker className="table-screening-input" required={false} multiple={false} value={props.inputValues.CONVICTIONDATETWO} onChange={(e) => props.handleInputChange('CONVICTIONDATETWO', e)} />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={6}>
                          <div className="d-md-flex align-items-center p-2">
                            Other crime information:
                            <label className="">
                              <input
                                type="checkbox"
                                checked={props.inputValues.AttemptedTWO} // Use 'checked' attribute
                                onChange={(e) => { props.handleInputChange('AttemptedTWO', e.target.checked) }} // Use 'e.target.checked'
                              />
                              <span className="checkmark"></span>
                              Attempted
                            </label>
                            <label className="">
                              <input
                                type="checkbox"
                                checked={props.inputValues.ConspiracyTWO} // Use 'checked' attribute
                                onChange={(e) => { props.handleInputChange('ConspiracyTWO', e.target.checked) }} // Use 'e.target.checked'
                              />
                              <span className="checkmark"></span>
                              Conspiracy
                            </label>
                            <label className="">
                              <input
                                type="checkbox"
                                checked={props.inputValues.DomesticViolenceTWO} // Use 'checked' attribute
                                onChange={(e) => { props.handleInputChange('DomesticViolenceTWO', e.target.checked) }} // Use 'e.target.checked'
                              />
                              <span className="checkmark"></span>
                              Domestic Violence
                            </label>
                            <label className="">
                              <input
                                type="checkbox"
                                checked={props.inputValues.SolicitationTWO} // Use 'checked' attribute
                                onChange={(e) => { props.handleInputChange('SolicitationTWO', e.target.checked) }} // Use 'e.target.checked'
                              />
                              Solicitation
                            </label>
                            <label className="">
                              <input
                                type="checkbox"
                                checked={props.inputValues.WithSexualMotivationTWO} // Use 'checked' attribute
                                onChange={(e) => { props.handleInputChange('WithSexualMotivationTWO', e.target.checked) }} // Use 'e.target.checked'
                              />
                              <span className="checkmark"></span>
                              With Sexual Motivation
                            </label>
                            <label className="">
                              <input
                                type="checkbox"
                                checked={props.inputValues.NATWO} // Use 'checked' attribute
                                onChange={(e) => { props.handleInputChange('NATWO', e.target.checked) }} // Use 'e.target.checked'
                              />
                              <span className="checkmark"></span>
                              N/A
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={6}>
                          <p>
                            DESCRIPTION OF CRIME (REQUIRED WHEN CRIME IS
                            COMMITTED OUTSIDE OF WASHINGTON STATE)
                          </p>
                          <textarea row="2" type="text" className="table-screening-input" value={props.inputValues.crime_description_two} onChange={(e) => props.handleInputChange('crime_description_two', e.target.value)} />
                        </td>
                      </tr>
                      <tr className="">
                        <td colSpan={3}>
                          <div>
                            <label for="">3. CRIME NAME</label>
                            <input type="text" className="table-screening-input" value={props.inputValues.CRIMENAMETHREE} onChange={(e) => props.handleInputChange('CRIMENAMETHREE', e.target.value)} />
                          </div>
                        </td>
                        <td colSpan={1}>
                          <div>
                            <label for="">DEGREE (IF ANY)</label>
                            <input type="text" className="table-screening-input" value={props.inputValues.DEGREETHREE} onChange={(e) => props.handleInputChange('DEGREETHREE', e.target.value)} />
                          </div>
                        </td>
                        <td colSpan={1}>
                          <div>
                            <label for="">STATE</label>
                            <input type="text" className="table-screening-input" value={props.inputValues.CRIMESTATETHREE} onChange={(e) => props.handleInputChange('CRIMESTATETHREE', e.target.value)} />
                          </div>
                        </td>
                        <td colSpan={1}>
                          <div>
                            <label for="">
                              CONVICTION DATE <br></br> (MM/DD/YYYY)
                            </label>
                            <MultiDatePicker className="table-screening-input" required={false} multiple={false} value={props.inputValues.CONVICTIONDATETHREE} onChange={(e) => props.handleInputChange('CONVICTIONDATETHREE', e)} />

                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={6}>
                          <div className="d-md-flex align-items-center p-2">
                            Other crime information:
                            <label className="">
                              <input
                                type="checkbox"
                                checked={props.inputValues.AttemptedTHREE} // Use 'checked' attribute
                                onChange={(e) => { props.handleInputChange('AttemptedTHREE', e.target.checked) }} // Use 'e.target.checked'
                              />
                              <span className="checkmark"></span>
                              Attempted
                            </label>
                            <label className="">
                              <input
                                type="checkbox"
                                checked={props.inputValues.ConspiracyTHREE} // Use 'checked' attribute
                                onChange={(e) => { props.handleInputChange('ConspiracyTHREE', e.target.checked) }} // Use 'e.target.checked'
                              />
                              <span className="checkmark"></span>
                              Conspiracy
                            </label>
                            <label className="">
                              <input
                                type="checkbox"
                                checked={props.inputValues.DomesticViolenceTHREE} // Use 'checked' attribute
                                onChange={(e) => { props.handleInputChange('DomesticViolenceTHREE', e.target.checked) }} // Use 'e.target.checked'
                              />  <span className="checkmark"></span>
                              Domestic Violence
                            </label>
                            <label className="">
                              <input
                                type="checkbox"
                                checked={props.inputValues.SolicitationTHREE} // Use 'checked' attribute
                                onChange={(e) => { props.handleInputChange('SolicitationTHREE', e.target.checked) }} // Use 'e.target.checked'
                              />  <span className="checkmark"></span>
                              Solicitation
                            </label>
                            <label className="">
                              <input
                                type="checkbox"
                                checked={props.inputValues.WithSexualMotivationTHREE} // Use 'checked' attribute
                                onChange={(e) => { props.handleInputChange('WithSexualMotivationTHREE', e.target.checked) }} // Use 'e.target.checked'
                              /> <span className="checkmark"></span>
                              With Sexual Motivation
                            </label>
                            <label className="">
                              <input
                                type="checkbox"
                                checked={props.inputValues.NATHREE} // Use 'checked' attribute
                                onChange={(e) => { props.handleInputChange('NATHREE', e.target.checked) }} // Use 'e.target.checked'
                              /> <span className="checkmark"></span>
                              N/A
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={6}>
                          <p>
                            DESCRIPTION OF CRIME (REQUIRED WHEN CRIME IS
                            COMMITTED OUTSIDE OF WASHINGTON STATE)
                          </p>
                          <textarea row="2" type="text" className="table-screening-input" value={props.inputValues.crime_description_three} onChange={(e) => props.handleInputChange('crime_description_three', e.target.value)} />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={6}>
                          <p>
                            <b>Section 4. Questions 11B. </b> If you check{" "}
                            <b>YES, </b> you must enter the PENDING charge name,
                            degree (if any), state, conviction date, and crime
                            information.
                          </p>
                        </td>
                      </tr>
                      <tr className="">
                        <td colSpan={3}>
                          <div>
                            <label for="">1. CRIME NAME</label>
                            <input type="text" className="table-screening-input" value={props.inputValues.CRIMENAMEFOUR} onChange={(e) => props.handleInputChange('CRIMENAMEFOUR', e.target.value)} />
                          </div>
                        </td>
                        <td colSpan={1}>
                          <div>
                            <label for="">DEGREE (IF ANY)</label>
                            <input type="text" className="table-screening-input" value={props.inputValues.DEGREEFOUR} onChange={(e) => props.handleInputChange('DEGREEFOUR', e.target.value)} />
                          </div>
                        </td>
                        <td colSpan={1}>
                          <div>
                            <label for="">STATE</label>
                            <input type="text" className="table-screening-input" value={props.inputValues.CRIMESTATEFOUR} onChange={(e) => props.handleInputChange('CRIMESTATEFOUR', e.target.value)} />

                          </div>
                        </td>
                        <td colSpan={1}>
                          <div>
                            <label for="">
                              CONVICTION DATE <br></br> (MM/DD/YYYY)
                            </label>
                            <MultiDatePicker className="table-screening-input" required={false} multiple={false} value={props.inputValues.CONVICTIONDATEFOUR} onChange={(e) => props.handleInputChange('CONVICTIONDATEFOUR', e)} />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={6}>
                          <div className="d-md-flex align-items-center p-2">
                            Other crime information:
                            <label className="">
                              <input
                                type="checkbox"
                                checked={props.inputValues.AttemptedFOUR} // Use 'checked' attribute
                                onChange={(e) => { props.handleInputChange('AttemptedFOUR', e.target.checked) }} // Use 'e.target.checked'
                              />
                              <span className="checkmark"></span>
                              Attempted
                            </label>
                            <label className="">
                              <input
                                type="checkbox"
                                checked={props.inputValues.ConspiracyFOUR} // Use 'checked' attribute
                                onChange={(e) => { props.handleInputChange('ConspiracyFOUR', e.target.checked) }} // Use 'e.target.checked'
                              />
                              <span className="checkmark"></span>
                              Conspiracy
                            </label>
                            <label className="">
                              <input
                                type="checkbox"
                                checked={props.inputValues.DomesticViolenceFOUR} // Use 'checked' attribute
                                onChange={(e) => { props.handleInputChange('DomesticViolenceFOUR', e.target.checked) }} // Use 'e.target.checked'
                              /> <span className="checkmark"></span>
                              Domestic Violence
                            </label>
                            <label className="">
                              <input
                                type="checkbox"
                                checked={props.inputValues.SolicitationFOUR} // Use 'checked' attribute
                                onChange={(e) => { props.handleInputChange('SolicitationFOUR', e.target.checked) }} // Use 'e.target.checked'
                              />
                              <span className="checkmark"></span>
                              Solicitation
                            </label>
                            <label className="">
                              <input
                                type="checkbox"
                                checked={props.inputValues.WithSexualMotivationFOUR} // Use 'checked' attribute
                                onChange={(e) => { props.handleInputChange('WithSexualMotivationFOUR', e.target.checked) }} // Use 'e.target.checked'
                              />   <span className="checkmark"></span>
                              With Sexual Motivation
                            </label>
                            <label className="">
                              <input
                                type="checkbox"
                                checked={props.inputValues.NAFOUR} // Use 'checked' attribute
                                onChange={(e) => { props.handleInputChange('NAFOUR', e.target.checked) }} // Use 'e.target.checked'
                              /> <span className="checkmark"></span>
                              N/A
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={6}>
                          <p>
                            DESCRIPTION OF CRIME (REQUIRED WHEN CRIME IS
                            COMMITTED OUTSIDE OF WASHINGTON STATE)
                          </p>
                          <textarea row="2" type="text" className="table-screening-input" value={props.inputValues.crime_description_four} onChange={(e) => props.handleInputChange('crime_description_four', e.target.value)} />

                        </td>
                      </tr>
                      <br />
                      <br />
                      <br />

                      <tr className="">
                        <td colSpan={3}>
                          <div>
                            <label for="">2. CRIME NAME</label>
                            <input type="text" className="table-screening-input" value={props.inputValues.CRIMENAMEFIVE} onChange={(e) => props.handleInputChange('CRIMENAMEFIVE', e.target.value)} />
                          </div>
                        </td>
                        <td colSpan={1}>
                          <div>
                            <label for="">DEGREE (IF ANY)</label>
                            <input type="text" className="table-screening-input" value={props.inputValues.DEGREEFIVE} onChange={(e) => props.handleInputChange('DEGREEFIVE', e.target.value)} />
                          </div>
                        </td>
                        <td colSpan={1}>
                          <div>
                            <label for="">STATE</label>
                            <input type="text" className="table-screening-input" value={props.inputValues.CRIMESTATEFIVE} onChange={(e) => props.handleInputChange('CRIMESTATEFIVE', e.target.value)} />
                          </div>
                        </td>
                        <td colSpan={1}>
                          <div>
                            <label for="">
                              CONVICTION DATE <br></br> (MM/DD/YYYY)
                            </label>
                            <MultiDatePicker className="table-screening-input" required={false} multiple={false} value={props.inputValues.CONVICTIONDATEFIVE} onChange={(e) => props.handleInputChange('CONVICTIONDATEFIVE', e)} />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={6}>
                          <div className="d-md-flex align-items-center p-2">
                            Other crime information:
                            <label className="">
                              <input
                                type="checkbox"
                                checked={props.inputValues.AttemptedFIVE} // Use 'checked' attribute
                                onChange={(e) => { props.handleInputChange('AttemptedFIVE', e.target.checked) }} // Use 'e.target.checked'
                              />   <span className="checkmark"></span>
                              Attempted
                            </label>
                            <label className="">
                              <input
                                type="checkbox"
                                checked={props.inputValues.ConspiracyFIVE} // Use 'checked' attribute
                                onChange={(e) => { props.handleInputChange('ConspiracyFIVE', e.target.checked) }} // Use 'e.target.checked'
                              />  <span className="checkmark"></span>
                              Conspiracy
                            </label>
                            <label className="">
                              <input
                                type="checkbox"
                                checked={props.inputValues.DomesticViolenceFIVE} // Use 'checked' attribute
                                onChange={(e) => { props.handleInputChange('DomesticViolenceFIVE', e.target.checked) }} // Use 'e.target.checked'
                              /> <span className="checkmark"></span>
                              Domestic Violence
                            </label>
                            <label className="">
                              <input
                                type="checkbox"
                                checked={props.inputValues.SolicitationFIVE} // Use 'checked' attribute
                                onChange={(e) => { props.handleInputChange('SolicitationFIVE', e.target.checked) }} // Use 'e.target.checked'
                              />  <span className="checkmark"></span>
                              Solicitation
                            </label>
                            <label className="">
                              <input
                                type="checkbox"
                                checked={props.inputValues.WithSexualMotivationFIVE} // Use 'checked' attribute
                                onChange={(e) => { props.handleInputChange('WithSexualMotivationFIVE', e.target.checked) }} // Use 'e.target.checked'
                              />
                              <span className="checkmark"></span>
                              With Sexual Motivation
                            </label>
                            <label className="">
                              <input
                                type="checkbox"
                                checked={props.inputValues.NAFIVE} // Use 'checked' attribute
                                onChange={(e) => { props.handleInputChange('NAFIVE', e.target.checked) }} // Use 'e.target.checked'
                              /><span className="checkmark"></span>
                              N/A
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={6}>
                          <p>
                            DESCRIPTION OF CRIME (REQUIRED WHEN CRIME IS
                            COMMITTED OUTSIDE OF WASHINGTON STATE)
                          </p>
                          <textarea row="50" type="text" style={{height:100}} value={props.inputValues.crime_description_five} onChange={(e) => props.handleInputChange('crime_description_five', e.target.value)} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WABackgroundCheck;
