import React, { useState, useEffect, useRef } from "react";
import WebsiteLayout from "../components/WebsiteLayout";
import { InputField } from "../../elements";

import { Formik } from "formik";
import { observer } from "mobx-react";
import WebsiteStore from "../../store/WebsiteStore";
import splitTextArray from "../../helpers/splitArray";
import { toJS } from "mobx";
import ReactGA from "react-ga";
import { Typeahead } from "react-bootstrap-typeahead";
import { abbreviatedDays } from "@utils/constant/constant";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import PageHelmet from "../components/PageHelmet";
import HomeStore from "@store/HomeStore";
import { truncateText } from "../../helpers/LimitString";
var cities = require("@assets/city.json");

const NewCaregivers = observer(() => {
  const [isWhereSpanHidden, setIsWhereSpanHidden] = useState(false);
  const [isWhatSpanHidden, setIsWhatSpanHidden] = useState(false);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedJobType, setSelectedJobType] = useState(null);
  const [caregiverList, setCaregiverList] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [degrees, setDegrees] = useState([]);
  const [section1Data, setSection1Data] = useState([]);
  const [pageData, setPageData] = useState(null);
  const jobRef = useRef(null);

  useEffect(() => {
    // Set the page_id for fetching page details
    WebsiteStore.page_id = 17;

    // Fetch page details
    WebsiteStore.getPageDetails();
    ReactGA.pageview(window.location.pathname + window.location.search);

    // Reset search filters and fetch caregivers
    WebsiteStore.resetSearch();
    WebsiteStore.getCaregivers();
  }, []);

  const executeScroll = () => {
    if (jobRef && jobRef.current) {
      jobRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(() => {
    const degreesData = HomeStore.data.dashboardData?.find(
      (x) => x.key_name === "DEGREES"
    );
    if (degreesData) {
      const parsedDegree = JSON.parse(toJS(degreesData.value));

      // const filteredNames = parsedDegree.map((item) => {
      //   const splitName = item?.Name.split(" - ");
      //   const newName = splitName.length > 1 ? splitName[1] : item.Name;
      //   return { ...item, Name: newName };
      // });

      if (WebsiteStore.data.keyword !== "") {
        const filteredDegrees = parsedDegree.filter((degree) => {
          const labelRegex = new RegExp(WebsiteStore.data.keyword, "i");

          return labelRegex.test(degree.Name);
        });

        setDegrees(filteredDegrees);
      } else {
        setDegrees(parsedDegree);
      }
    }
  }, [HomeStore.data.dashboardData, WebsiteStore.data.keyword]);

  useEffect(() => {
    setCaregiverList(WebsiteStore?.data?.caregiversList);
  }, [WebsiteStore?.data?.caregiversList]);

  const handleWhereSpanFocus = (e) => {
    // Hide the span on focus
    setIsWhereSpanHidden(!!e.target.value);
    setIsWhereSpanHidden(true);
  };

  const handleWhatSpanFocus = (e) => {
    // Hide the span on focus
    setIsWhatSpanHidden(!!e.target.value);
    setIsWhatSpanHidden(true);
  };

  const handleIsWhereBlur = (e) => {
    if (!e.target.value) {
      setIsWhereSpanHidden(false);
    }
  };
  const handleIsWhatBlur = (e) => {
    if (!e.target.value) {
      setIsWhatSpanHidden(false);
    }
  };

  const handlePageSizeChange = (value) => {
    WebsiteStore.data.pageSize = value;
    WebsiteStore.getCaregivers();
  };

  const handleInputChange = async (inputValue) => {
    setIsLoading(true);

    // Use debouncedLoadOptions here
    await debouncedLoadOptions(inputValue, setOptions);

    setIsLoading(false);
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const handleDegreeChange = (jobType) => {
    setSelectedJobType(jobType);
    WebsiteStore.data.degree = jobType;
    WebsiteStore.data.page = 1;
    WebsiteStore.getCaregivers();
  };
  const debouncedLoadOptions = debounce(async (inputValue, callback) => {
    if (!inputValue.trim()) {
      callback([]);
      return;
    }

    setIsLoading(true);

    const filteredCities = cities
      .filter((city) => {
        const labelRegex = new RegExp(inputValue, "i");
        return (
          labelRegex.test(city.label) ||
          labelRegex.test(city.state) ||
          labelRegex.test(city.state_id)
        );
      })
      .map((city) => ({
        label: `${city.label}${city.state_id ? `, ${city.state_id}` : ""}`,
        value: city.label,
      }));

    setTimeout(() => {
      setIsLoading(false);

      const slicedOptions = filteredCities.slice(0, 1000);

      callback(slicedOptions);
    }, 1000);
  }, 300);

  useEffect(() => {
    if (WebsiteStore?.data?.pageData !== null) {
      setPageData(toJS(WebsiteStore?.data?.pageData));
    }
    if (WebsiteStore?.data?.pageData?.sections?.length > 0) {
      setSection1Data(
        splitTextArray(
          toJS(WebsiteStore?.data?.pageData?.sections)?.[0]?.content
        )
      );

      setIsPageLoading(false);
    }
  }, [WebsiteStore.data.pageData]);

  const handlePageChange = (newPage) => {
    WebsiteStore.data.page = newPage;
    WebsiteStore.getCaregivers();
  };

  return (
    <>
      <PageHelmet
        name={pageData?.page_name || "Caregivers"}
        metaData={pageData?.meta_data}
        ldjson={pageData?.ldjson}
      />
      <WebsiteLayout>
        <div>
          <div className="banner-web main-sec justify-content-center">
            <div className="search-travel-job position-relative pb-0">
              <div className="container">
                <div className="">
                  <div className="banner-content text-center mb-3">
                    <span>
                      {section1Data?.[0] ||
                        `Travel Nursing, Allied, Per Diem Jobs, and More`}
                    </span>
                    <p>
                      {section1Data?.[1] ||
                        `Unlock Direct Access to Permanent and In-House Agency
                      Roles`}
                    </p>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-lg-10 col-md-12">
                    <div className="search-inner">
                      <Formik
                        initialValues={{
                          where: "",
                          what: "",
                        }}
                        onSubmit={(values) => {
                          WebsiteStore.data.location = values.where || "";
                          WebsiteStore.data.keyword = values.what || "";
                          WebsiteStore.getCaregivers();
                          executeScroll();
                        }}
                        enableReinitialize
                        // validationSchema={

                        // }
                      >
                        {({
                          handleChange,
                          handleSubmit,
                          values,
                          errors,
                          touched,
                          setFieldValue,
                        }) => (
                          <form onSubmit={handleSubmit} autoComplete="off">
                            <div className="row align-items-center">
                              <div className="col-md-6">
                                <div className="border-left grey-box">
                                  <span
                                    className={
                                      isWhereSpanHidden
                                        ? "hidden search-span"
                                        : "search-span"
                                    }
                                  >
                                    Place
                                  </span>

                                  <Typeahead
                                    id="jobLocationTypeahead"
                                    onChange={(selectedOptions) => {
                                      const selectedOption = selectedOptions[0];
                                      setFieldValue(
                                        "where",
                                        selectedOption
                                          ? selectedOption.label
                                          : ""
                                      );
                                    }}
                                    onBlur={handleIsWhereBlur}
                                    onFocus={handleWhereSpanFocus}
                                    onInputChange={handleInputChange}
                                    options={options}
                                    isLoading={isLoading}
                                    labelKey={(option) =>
                                      `${option.label}${
                                        option.state_id
                                          ? `, ${option.state_id}`
                                          : ""
                                      }`
                                    }
                                    placeholder="City, State"
                                    selected={cities.filter(
                                      (city) =>
                                        values?.where ===
                                        `${city.label}${
                                          city.state_id
                                            ? `, ${city.state_id}`
                                            : ""
                                        }`
                                    )}
                                    renderInput={(inputProps) => (
                                      <InputField
                                        {...inputProps}
                                        className="did-floating-input"
                                        type="text"
                                        name="where"
                                        label={"City, State"}
                                      />
                                    )}
                                    filterBy={() => true}
                                    minLength={2}
                                  />
                                  {errors.where ? (
                                    <div className="error-message">
                                      {errors.where}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-md-5 ">
                                <div className="grey-box">
                                  <span
                                    className={
                                      isWhatSpanHidden
                                        ? "hidden search-span"
                                        : "search-span"
                                    }
                                  >
                                    Tags
                                  </span>
                                  <InputField
                                    className={"did-floating-input"}
                                    type="text"
                                    name="what"
                                    label={"Name, Degree, Speciality"}
                                    value={values?.what || ""}
                                    onChange={handleChange}
                                    onBlur={handleIsWhatBlur}
                                    onFocus={handleWhatSpanFocus}
                                  />
                                  {errors.what ? (
                                    <div className="error-message">
                                      {errors.what}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-md-1 ">
                                <div className="text-center purple-box">
                                  <button className="common-web-btn purple-back p-2 ">
                                    <i className="fa fa-search"></i>
                                    <p>Search Caregivers</p>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div ref={jobRef} className="jobs-section">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-3 border-filter-left">
                  <div className="">
                    <h3 className="head-third">Filters</h3>
                    <hr></hr>
                    <div>
                      <div className="industry-filter">
                        <h6 className="head-sixth">Degree</h6>
                        <button
                          className="clear-btn-job-type"
                          onClick={() => handleDegreeChange(null)}
                        >
                          Clear
                        </button>
                      </div>
                      <div className="health-type">
                        {degrees?.map((degree) => {
                          return (
                            <div
                              key={degree?.id}
                              className={`blue-box ${
                                selectedJobType === degree?.Name
                                  ? "selected"
                                  : ""
                              }`}
                              onClick={() => handleDegreeChange(degree?.Name)}
                            >
                              {degree?.Name}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 border-filter-left">
                  <div className="row">
                    <div className="col-md-5 col-sm-6">
                      <div className="mile-drop">
                        <h3 className="head-third mb-4 ">
                          Showing
                          <span style={{ color: "#919191" }}>
                            {" "}
                            {` ${WebsiteStore.data.from || 0} to ${
                              WebsiteStore.data.to || 0
                            } of ${WebsiteStore.data.total || 0}`}
                          </span>{" "}
                          caregivers
                        </h3>
                      </div>
                    </div>
                    <div className="col-md-7 col-sm-6">
                      <div className="mile-drop justify-content-end">
                        <div className="dropdown">
                          <select
                            id="pageSizeDropdown"
                            className="btn btn-secondary"
                            value={WebsiteStore.data.pageSize}
                            onChange={(e) =>
                              handlePageSizeChange(e.target.value)
                            }
                          >
                            <option value={15}>15</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                          </select>
                        </div>

                        {/* ... (rest of the code remains the same) */}
                      </div>
                    </div>
                  </div>
                  {/* <hr></hr> */}
                  <div className="row mt-3 search-caregivers">
                    <div className="padding-job row">
                      {caregiverList?.length > 0 &&
                        caregiverList?.map((caregiver, idx) => {
                          return (
                            <div className="col-md-4">
                              <div className="cards-box mb-3 differ-height">
                                <img
                                  // src={`/assets/img/statepics/${
                                  //   caregiver?.preferred_state
                                  //     ? JSON?.parse(
                                  //         caregiver?.preferred_state
                                  //       )?.[0]?.toLowerCase() || "al"
                                  //     : "al"
                                  // }.png`}
                                  src="/assets/img/website/user-filler.png"
                                  className="img-fluid"
                                  alt="rescue"
                                />
                                <div className="content-in caregiver-content-in">
                                  <div className="d-flex mb-0 pb-0">
                                    <div className="d-flex mb-2 pb-0 align-items-start gap-2">
                                      <div className="d-flex align-items-center">
                                        <span>
                                          <i className="fa fa-map-marker-alt " />{" "}
                                        </span>
                                        <div className="inner-align-flex">
                                          {WebsiteStore.loading ? (
                                            <Skeleton width={100} />
                                          ) : (
                                            <span className="info-text mb-0 gap-1">
                                              {caregiver?.preferred_cities
                                                ? JSON.parse(
                                                    caregiver?.preferred_cities
                                                  )?.[0]?.label + ", " || ""
                                                : ""}
                                              {caregiver?.preferred_state
                                                ? JSON?.parse(
                                                    caregiver?.preferred_state
                                                  )?.[0] || ""
                                                : ""}
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {caregiver?.interested_in && (
                                    <div className="d-flex">
                                      <div className="d-flex mb-0 pb-0">
                                        <div className="d-flex mb-2 pb-0 align-items-start gap-2">
                                          <div className="d-flex align-items-center">
                                            <span>
                                              <i className="fas fa-user-md icon-grey"></i>
                                            </span>
                                            <div
                                              className="inner-align-flex"
                                              data-toggle="tooltip"
                                              data-placement="left"
                                              title={
                                                WebsiteStore.loading
                                                  ? ""
                                                  : caregiver?.interested_in &&
                                                    JSON.parse(
                                                      caregiver?.interested_in
                                                    )
                                              }
                                            >
                                              {WebsiteStore.loading ? (
                                                <Skeleton
                                                  count={1}
                                                  width={100}
                                                />
                                              ) : (
                                                caregiver?.interested_in &&
                                                JSON.parse(
                                                  caregiver?.interested_in || []
                                                )
                                                  .slice(0, 1)
                                                  .map(
                                                    (interest, idx, array) => (
                                                      <React.Fragment key={idx}>
                                                        <span
                                                          className="info-text"
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                        >
                                                          {interest}
                                                        </span>
                                                        {idx <
                                                          array?.length - 1 &&
                                                          ", "}{" "}
                                                        {idx === 1 &&
                                                          array?.length > 1 &&
                                                          ", ..."}
                                                      </React.Fragment>
                                                    )
                                                  )
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  <div className="d-flex">
                                    <div className="d-flex mb-0 pb-0">
                                      <div className="d-flex mb-2 pb-0 align-items-start gap-2">
                                        <div className="d-flex align-items-center">
                                          <span>
                                            <i className="fas fa-calendar-alt icon-grey"></i>
                                          </span>
                                          <div className="inner-align-flex">
                                            {WebsiteStore.loading ? (
                                              <Skeleton count={2} width={100} />
                                            ) : caregiver?.available_day ? (
                                              JSON.parse(
                                                caregiver?.available_day || []
                                              ).map((day, idx, array) => (
                                                <React.Fragment key={idx}>
                                                  <span className="info-text">
                                                    {abbreviatedDays[day]}
                                                  </span>
                                                  {idx < array.length - 1 &&
                                                    ","}{" "}
                                                </React.Fragment>
                                              ))
                                            ) : (
                                              <React.Fragment key={idx}>
                                                <span className="info-text">
                                                  Sun, Mon, Tue, Wed, Thu, Fri,
                                                  Sat
                                                </span>
                                              </React.Fragment>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {WebsiteStore.loading ? (
                                    <Skeleton width={200} height={20} />
                                  ) : (
                                    caregiver?.degree_specialities &&
                                    JSON.parse(caregiver?.degree_specialities)
                                      ?.length > 0 && (
                                      <div className="d-flex">
                                        <div className="d-flex mb-0 pb-0">
                                          <div className="d-flex mb-2 pb-0 align-items-start gap-2">
                                            <div className="d-flex align-items-center">
                                              <span>
                                                <i className="fas fa-briefcase-medical icon-grey"></i>
                                              </span>
                                              <div
                                                className="inner-align-flex"
                                                data-toggle="tooltip"
                                                data-placement="left"
                                                title={
                                                  WebsiteStore.loading
                                                    ? ""
                                                    : caregiver?.degree_specialities &&
                                                      JSON.parse(
                                                        caregiver?.degree_specialities
                                                      )
                                                        .map(
                                                          (el) =>
                                                            el.profession_name
                                                        )
                                                        .join(", ")
                                                }
                                              >
                                                {WebsiteStore.loading ? (
                                                  <Skeleton
                                                    width={200}
                                                    height={20}
                                                  />
                                                ) : (
                                                  caregiver?.degree_specialities &&
                                                  JSON.parse(
                                                    caregiver?.degree_specialities ||
                                                      []
                                                  )
                                                    .slice(0, 1)
                                                    .map((el, idx, array) => (
                                                      <React.Fragment key={idx}>
                                                        <span
                                                          className="info-text .truncate-str"
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                        >
                                                          {truncateText(
                                                            el?.profession_name,
                                                            33
                                                          )}
                                                        </span>
                                                        {idx <
                                                          array?.length - 1 &&
                                                          ", "}
                                                        {idx === 0 &&
                                                          caregiver
                                                            ?.degree_specialities
                                                            ?.length > 1 &&
                                                          ", .."}
                                                      </React.Fragment>
                                                    ))
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                                <Link
                                  to="/register/employer"
                                  className="common-web-btn purple-back w-100"
                                >
                                  Join us for more details
                                </Link>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    {/* Pagination component */}
                    <div className="row justify-content-end align-items-center pt-3">
                      <div className="col-md-12">
                        <div className="new-pagination">
                          <div className="rc-paginate">
                            <span>{`Showing ${WebsiteStore.data.from || 0} to ${
                              WebsiteStore.data.to || 0
                            } of ${
                              WebsiteStore.data.total || 0
                            } caregivers`}</span>
                            <br />
                            <span>{`Page ${WebsiteStore.data.page} of ${
                              WebsiteStore.data.lastPage || "-"
                            }`}</span>

                            <Pagination
                              current={WebsiteStore.data.page}
                              total={WebsiteStore.data.total}
                              pageSize={WebsiteStore.data.pageSize}
                              onChange={(page) => handlePageChange(page)}
                              disabled={WebsiteStore.loading}
                              showTitle={false}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="new-extra-space">.</div>
        </div>
      </WebsiteLayout>
    </>
  );
});

export default NewCaregivers;
