import { configure, makeAutoObservable, runInAction } from "mobx"
import { axiosInstance, axiosInstanceInt, setAuthToken, setAuthTokenInt } from "../api/axiosInstance"
import { toast } from 'react-toastify';
class JobsStore {
  loading = false
  loadingApply = false
  data = {
    jobs: [],
    appliedJobs: [],
    showType: 0,
    jobType: 2,
    pg: 1,
    recs: 15,
    degree: null,
    specialty: null,
    city: null,
    state: null,
    hot: null,
    bookmarkJob: []
  }
  errors = {}
  constructor() {
    makeAutoObservable(this);
    configure({
      useProxies: "never"
    })
  }
  toggleLoading = (toggle) => {
    this.loading = toggle
  }
  toggleLoadingApply = (toggle) => {
    this.loadingApply = toggle
  }

  async getJobsData() {
    setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get(`jobs/feed/json/${this.data.showType}/${this.data.jobType}/${this.data.pg}/${this.data.recs}/${this.data.degree}/${this.data.specialty}/${this.data.city}/${this.data.state}/${this.data.hot}`
      )
      if (response) {
        runInAction(() => {
          this.data.jobs = response?.data.jobs;
        })
        return response?.data.jobs;
      } else {
        // toast.error("Something went to wrong!");
        
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async applyJob(values, navigationCallBack) {
    this.toggleLoadingApply(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.post('/staff/applyJob', values)
      if (response.data.message === 'success') {
        navigationCallBack(values.job_id)
      } else {
        // toast.error("Someting went to wrong!");
      }
    }
    catch (err) {
      if (err?.response?.status !== 500) {
        this.toggleLoadingApply(false);
        toast.error(err?.response?.data?.message);
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async applyForBlueSky(id, guid, navigationCallBackFinal) {
    this.toggleLoadingApply(true);
    try {
      setAuthToken(global.config.token)
      const response = await axiosInstance.post(`/api/contractor/applyonjob/${id}/${guid}`)
      if (response.data === true) {
        navigationCallBackFinal()
      }
    }
    catch (err) {
      console.log("errir")

      toast.error("Failed!");
    }
    finally {
      this.toggleLoadingApply(false);
    }
  }

  async getAppliedJobsData() {
    setAuthTokenInt()
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.get("staff/getJobNeeds")
      if (response) {
        runInAction(() => {
          this.data.appliedJobs = response?.data?.jobs;
        })
      } else {
        // toast.error("Something went to wrong!");
        
      }
    }
    catch (err) {
      console.log(err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async bookmarkJob(data, navigationCallBackBookmark) {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.post('/staff/saveJob', data)
      if (response.data !== "") {
        navigationCallBackBookmark(response.data.message);
      } else {
        console.log("Someting went to wrong!");
      }
    }
    catch (err) {
      navigationCallBackBookmark(err.response.data.message);
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async getBookmarkJob() {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.get('/staff/getSavedJobs')
      if (response.data !== "") {
        runInAction(() => {
          this.data.bookmarkJob = response?.data?.data;
        })
      } else {
        // toast.error("Someting went  wrong!");
        console.log("err");
      }
    }
    catch (err) {
      console.log("err", err);
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async deleteBookmarkJob(param,navigationCallBackBookmarkDelete) {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.post('/staff/deleteSavedJobs',param)
      if (response.data !== "") {
        navigationCallBackBookmarkDelete()
      } else {
        // toast.error("Someting went  wrong!");
        console.log("err");
      }
    }
    catch (err) {
      console.log("err", err);
    }
    finally {
      this.toggleLoading(false);
    }
  }
}

export default new JobsStore()
