import React from "react";
import Header from '@components/PDFHeader';
import moment from "moment";

export const AcknowledgmentAndAuthorizationUniversal = (props) => {
  return (
    <div id="form-content">
      <Header />
      <div className=" agreement-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h6 className="text-center">
                <u> <b>ACKNOWLEDGMENT AND AUTHORIZATION FOR BACKGROUND INVESTIGATION</b></u>
              </h6>
              <p>
                I acknowledge receipt of the separate documents entitled DISCLOSURE REGARDING BACKGROUND
                INVESTIGATION, DISCLOSURE FOR INVESTIGATIVE CONSUMER REPORT (if applicable), A SUMMARY OF
                YOUR RIGHTS UNDER THE FAIR CREDIT REPORTING ACT and OTHER STATE/LOCAL NOTICES and certify that
                I have read and understand those documents. I hereby authorize the obtaining of “consumer reports”
                and/or “investigative consumer reports” by <b> Staffers Holdings LLC</b> (the “Company“) at any time after receipt
                of this authorization and throughout my employment, if applicable. To this end, I hereby authorize, without
                reservation, any law enforcement agency, administrator, state or federal agency, institution, school or
                university (public or private), information service bureau, employer, or insurance company to furnish any
                and all background information requested by<b> Universal Background Screening, P.O. Box 5920, Scottsdale,
                  AZ 85261, 877-263-8033,<a href="www.universalbackground.com" target="_blank"><u> www.universalbackground.com</u></a> </b>
                and/or the Company. I agree that a facsimile
                (“fax”), electronic or photographic copy of this Authorization shall be as valid as the original.
              </p>
              <div className="bonus-sec">
                <div className="row">
                  <div className="col-6">
                    <p>
                      {props?.signature ? <img src={props?.signature} width={180} height={50} alt="Signature here" /> :
                        <div class="badge badge-primary text-wrap pointer" onClick={props?.onOpenModal}>
                          Click Here To Sign
                        </div>
                      }
                      <hr />
                      <label>Employee Signature<small className="text-danger">*</small></label>
                    </p>
                  </div>
                  <div className="col-6">
                    <p>
                      <input className='mb-4' type="text" value={moment(new Date()).format("MM-DD-YYYY HH:mm:ss")} disabled />
                      <hr />
                      <label>Date</label>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
