/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react"
import HeaderPage from "../layout/HeaderPage";
import DrawerMenuPage from "../layout/DrawerMenuPage";
import { CButton, CFormLabel, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CTooltip } from "@coreui/react";
import { CSmartTable } from '@coreui/react-pro'
import AdminStore from "@store/AdminStore";
import HomeStore from "@store/HomeStore";
import SelectField from "@elements/select/SelectField";
import { toJS } from "mobx";
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import './HiringRequestList.css'
import secureLocalStorage from "react-secure-storage";
import Spinner from "@components/Spinner";
import TimeRangePicker from '@wojtekmaj/react-timerange-picker';
import { CModal, CModalHeader, CModalTitle } from "@coreui/react";
import { Formik, Field, FieldArray } from "formik";
import { toast } from "react-toastify";
import DatePicker, { getAllDatesInRange } from "react-multi-date-picker"
import weekends from "react-multi-date-picker/plugins/highlight_weekends"
import swal from '@sweetalert/with-react';

const HiringRequestList = observer(() => {
    const formikRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [hiringData, setHiringData] = useState();
    const [hiringList, setHiringList] = useState([]);
    const [staffData, setStaffData] = useState();
    const [shifts, setShifts] = useState([]); 
    const [shiftsDummy, setShiftsDummy] = useState([]);
    const [degreeData, setDegreeData] = useState();
    const [isAuthenticated, setAuthenticated] = useState(null);
    const [deletedSlot, setDeletedSlot] = useState([]);
    const [selectAllButton, setSelectAllButton] = useState(false);
    const [isOpenForm, setIsOpenForm] = useState(false);
    const [formData, setFormData] = useState();
    const [multi, setValues] = useState([])
    const [dates, setDates] = useState([])
    const [allDates, setAllDates] = useState([])
    const [durationType, setDurationType] = useState(undefined);
    const [disabledSlots, setDisabledSlots] = useState([]);
    const [mergeSlots, setMergeSlots] = useState([]);
    const [copy, setCopy] = useState(false);

    useEffect(() => {
        let token = secureLocalStorage.getItem("admin-token");
        setAuthenticated(token);
        AdminStore.getHireRequestList();
    }, []);

    useEffect(() => {
        setHiringList(toJS(AdminStore?.data?.hiringList))
    }, [AdminStore?.data?.hiringList]);


    const columns = [
        { key: 'sr', _style: { width: '7%' } },
        { key: 'client_name', label: "Name" },
        { key: 'organization_name' },
        { key: 'organization_email' },
        { key: 'staff_name', label: "Caregiver" },
        { key: 'job_type' },
        { key: 'city' },
        { key: 'state' },
        { key: 'status' },
        { key: 'caregiver_status', label: 'Caregiver Response' },

        {
            key: 'Action',
            label: 'Action',
            filter: false,
            sorter: false,
        },
    ];

    const onDismiss = () => {
        setIsOpen(false)
    }
    const onDismissForm = () => {
        if (formikRef.current) {
            formikRef.current.resetForm();
        }
        setIsOpenForm(false)
        setFormData()
        setValues([])
        setDates([])
        setAllDates([])
        setDisabledSlots([])
        setDurationType()
    }
    const onOpenForm = (item) => {
        setFormData(item)
        setMergeSlots(item.tenure && JSON.parse(item.tenure))
        setDisabledSlots(item.tenure && JSON.parse(item.tenure)?.map((item) => item.date))
        setIsOpenForm(true)
    }
    const showDetails = (item) => {
        setHiringData(item);
        if (item?.tenure) {
            setShifts(JSON.parse(item?.tenure))
            setShiftsDummy(JSON.parse(item?.tenure))
        }
        if (isAuthenticated) {
            HomeStore.getCaregiverDegreeData(item?.staff_id).then(data => {
                setDegreeData(data);
            })
            HomeStore.getCaregiverDetails(item?.staff_id).then(data => {
                setStaffData(data);
                setIsOpen(true);
            })
        }
        setIsOpen(true);
    }

    const onStatus = (item, status) => {
        if (status === 4) {
            swal("Remark:", {
                title: "Do you really want to reject?",
                icon: "warning",
                content: { element: "textarea" },
                buttons: ["Cancel", "Reject"],
            })
                .then((value) => {
                    if (value) {
                        value = document.querySelector(".swal-content__textarea").value;
                        let param = {
                            status_id: status,
                            request_id: item.id,
                            send_mail: 1
                        }
                        AdminStore.statusHiringRequest(param, navigationCallBack)
                        return
                    }
                });
            return
        }
        else {
            let param = {
                status_id: status,
                request_id: item.id,
                send_mail: 0
            }
            AdminStore.statusHiringRequest(param, navigationCallBack)
        }
    }

    const onGoingStatus = (item, status) => {
        const hasBlankTimes = shifts.some(obj => {
            const time = obj.time;
            return !time || time.length === 0;
        });
        if (hasBlankTimes) {
            toast.warn("Please select date & time!")
        } else {
            let check = shifts.filter(sl => sl.date !== deletedSlot?.find(x => x == sl.date));
            let param = {
                status_id: status,
                request_id: item,
                send_mail: 0,
                tenure: check?.length !== 0 ? JSON.stringify(check) : []
            }
            AdminStore.statusHiringRequest(param, navigationCallBack)
        }
    }

    const navigationCallBack = () => {
        AdminStore.getHireRequestList();
        onDismiss()
    }

    const addSlots = (date) => {
        let updatedArray = shifts.map(obj => {
            if (obj.date === date) {
                return { ...obj, filled: "1" };
            }
            return obj;
        });
        setShifts(updatedArray);
    }

    const deleteSlots = (date) => {
        // Check if the element already exists in the array
        const elementExists = deletedSlot.includes(date);
        // Remove the existing element if it exists, and add the new element to the array
        const updatedArray = elementExists
            ? deletedSlot.filter(item => item !== date)
            : deletedSlot.concat(date);
        // Update the state variable with the updated array
        setDeletedSlot(updatedArray);
    }

    const updateSlots = (day, value) => {
        const [startTime, endTime] = value;
        const date1 = new Date(`2023-03-14T${startTime}:00`);
        const date2 = new Date(`2023-03-14T${endTime}:00`);
        if (date1.getTime() < date2.getTime() || startTime) {
            let updatedArray = shifts.map(obj => {
                if (obj.date === day) {
                    return { ...obj, time: value };
                }
                return obj;
            });
            setShifts(updatedArray);
        }
        if (date1.getTime() > date2.getTime()) {
            let updatedArray = shifts.map(obj => {
                if (obj.date === day) {
                    return { ...obj, time: [] };
                }
                return obj;
            });
            setShifts(updatedArray);
            toast.warn(`Start is later than end time`);
            return
        }
        if (date1.getTime() === date2.getTime()) {
            let updatedArray = shifts.map(obj => {
                if (obj.date === day) {
                    return { ...obj, time: [] };
                }
                return obj;
            });
            setShifts(updatedArray);
            toast.warn(`Start and end are the same time`);
            return
        }

    }

    const selectAllSlot = () => {
        let updatedArray = shifts.map(obj => {
            return { ...obj, filled: "1" };
        });
        const filteredSlots = updatedArray.filter(item2 => {
            const item2Date = item2.date;
            return !deletedSlot.some(item1 => {
                const item1Date = item1;// Assuming the date is stored in a property called "date"
                return item1Date === item2Date; // Compare the dates as Unix timestamps
            });
        });
        setShifts(filteredSlots);
        setSelectAllButton(true);
    }

    const selectAllSlotReset = () => {
        let updatedArray = shiftsDummy.map(obj => {
            return { ...obj, filled: "0" };
        });
        setShifts(updatedArray);
        setSelectAllButton(false);
    }

    const onExtendStatus = (values) => {
        let mergedSlots = []
        let finalslot = values.tenure.map((x, index) => {
            return { "date": x.format(), "time": values.alltime[index] ? values.alltime[index] : values.alltime[0], "filled": "0" }
        })

        let check = finalslot.some((obj) => !Object.values(obj).every((val) => val !== undefined));
        if (check) {
            toast.warn("Please select date & time!")
        } else {
            if (mergeSlots && mergeSlots?.length != 0) {
                mergedSlots = mergeSlots.concat(finalslot);
            } else {
                mergedSlots = finalslot;
            }
            let param = {
                status_id: 5,
                request_id: values.request_id,
                send_mail: 0,
                tenure: JSON.stringify(mergedSlots)
            }
            AdminStore.statusHiringRequest(param, navigationCallBackExtend)
        }
    }

    const navigationCallBackExtend = () => {
        AdminStore.getHireRequestList();
        onDismissForm();
        setFormData();
        setDisabledSlots();
    }

    const onCloseStatus = (item, status) => {
        let param = {
            status_id: status,
            request_id: item.id,
            send_mail: 0,
            tenure: item.tenure
        }
        AdminStore.statusHiringRequest(param, navigationCallBack)
    }

    const onClipboard = async (staffData, hiringData, url) => {
        try {
            if (!document.hasFocus()) {
                window.focus();
            }
            navigator.clipboard.writeText(`${staffData?.FullName} | ${degreeData?.degrees[0]?.title} | ${hiringData?.user?.organization_name} ${hiringData?.rating ? "\n| Rating :" + hiringData?.rating + "/5" : ''} ${hiringData?.feedback ? "\n| Feedback :" + hiringData?.feedback : ''} \nVisit Us Here - https://staffers.us`).then(() => {
                onDismiss()
                swal({
                    title: "Feedback copied on clipboard!",
                    text: "Please paste this on your post",
                    icon: "success",
                    buttons: true,
                    dangerMode: true,
                    buttons: ["Cancel", "Share"],
                })
                    .then((willDelete) => {
                        if (willDelete) {
                            setIsOpen(true)
                            window.open(url, '_blank');
                        } else {
                            setIsOpen(true)
                        }
                    });

            })
        } catch (error) {
            console.log('Failed to copy to clipboard: ', error);
        }
    }

    const onCopy = async (staffData, hiringData) => {
        try {
            if (!document.hasFocus()) {
                window.focus();
            }
            navigator.clipboard.writeText(`${staffData?.FullName} | ${degreeData?.degrees[0]?.title} | ${hiringData?.user?.organization_name} ${hiringData?.rating ? "\n| Rating :" + hiringData?.rating + "/5" : ''} ${hiringData?.feedback ? "\n| Feedback :" + hiringData?.feedback : ''} \nVisit Us Here - https://staffers.us`).then(() => {
                setCopy(true)
                setTimeout(function () {
                    setCopy(false)
                }.bind(this), 1000);
            })
        } catch (error) {
            console.log('Failed to copy to clipboard: ', error);
        }
    }
    return (
        <React.Fragment>
            {/* <body> */}
            <div className="container-scroller">
                <HeaderPage />
                <div className="container-fluid page-body-wrapper">
                    <DrawerMenuPage />
                    <div className="main-panel" style={{ right: 0, position: "absolute" }}>
                        <div className="content-wrapper p-3 bg-white ">
                            <h3 className="page-title">
                                <span className="page-title-icon linear text-white me-2">
                                    <i className="fas fa-id-card-alt"></i>
                                </span> Hire Requests
                            </h3>
                        </div>
                        {/* <!-- partial --> */}
                        <div className="row bg-white  pb-3">
                            <CSmartTable
                                items={hiringList}
                                columns={columns}
                                columnFilter
                                tableFilter
                                columnSorter
                                itemsPerPageSelect
                                itemsPerPage={10}
                                pagination
                                tableProps={{
                                    hover: true,
                                    responsive: true,
                                }}
                                tableFilterPlaceholder={"Search"}
                                tableFilterLabel={""}
                                scopedColumns={{
                                    sr: (item, index) => {
                                        return (
                                            <td>
                                                {index + 1}
                                            </td>
                                        )
                                    },
                                    organization_name: (item, index) => {
                                        return (
                                            <td>
                                                {item?.user?.organization_name}
                                            </td>
                                        )
                                    },
                                    client_name: (item, index) => {
                                        return (
                                            <td>
                                                {item?.user?.first_name} {item?.user?.last_name}
                                            </td>
                                        )
                                    },
                                    organization_email: (item, index) => {
                                        return (
                                            <td>
                                                {item?.user?.email}
                                            </td>
                                        )
                                    },
                                    job_type: (item, index) => {
                                        return (
                                            <td>
                                                {global.config.jobType.find(x => x.value == item?.job_type)?.name}
                                            </td>
                                        )
                                    },
                                    caregiver_status: (item, index) => {
                                        return (
                                            <td>
                                                <CFormLabel classprofession="m-2 w-100" >
                                                    <span className={item?.staff_response == '0' ? 'text-warning' : item?.staff_response == '1' ? 'text-success' : item?.staff_response == '2' ? 'text-danger' : "text-info"}>
                                                        {item?.staff_response == '0' ? 'Pending' : item?.staff_response == '1' ? 'Accepted' : item?.staff_response == '2' ? 'Rejected' : "No Update"}
                                                    </span>
                                                </CFormLabel>
                                            </td>
                                        )
                                    },
                                    status: (item) => {
                                        return (
                                            <td classprofession="py-2">
                                                <CFormLabel classprofession="m-2 w-100" >
                                                    <span className={item.status == '0' ? 'text-warning' : item.status == '1' ? 'text-success' : item.status == '2' ? 'text-success' : item.status == '3' ? 'text-success' : item.status == '4' ? "text-danger" : "text-info"}>
                                                        {item.status == '0' ? 'Pending' : item.status == '1' ? 'Accepted' : item.status == '2' ? 'Ongoing' : item.status == '3' ? 'Completed' : item.status == '4' ? "Rejected" : item.status == '5' ? "Extended" : "No Update"}
                                                    </span>
                                                </CFormLabel>
                                            </td>
                                        )
                                    },
                                    Action: (item) => {
                                        return (
                                            <td>
                                                <CDropdown variant="btn-group" direction='dropstart' alignment="end">
                                                    <CDropdownToggle size="sm" color="transparent" caret={false} href="#" className="p-0"><i className="fa fa-ellipsis-v text-primary"></i></CDropdownToggle>
                                                    <CDropdownMenu >
                                                        <CDropdownItem className="more-menu">
                                                            <CButton onClick={() => {
                                                                showDetails(item)
                                                            }} size="sm" className="w-100" variant='outline' color="primary" >
                                                                View <i className="fas fa-eye"></i>
                                                            </CButton>
                                                        </CDropdownItem>
                                                        {
                                                            item?.status === 1 && <CDropdownItem>
                                                                <CButton onClick={() => {
                                                                    showDetails(item)
                                                                }} size="sm" className="w-100" variant='outline' color="info" >
                                                                    Ongoing <i className="fas fa-user-check"></i>
                                                                </CButton>
                                                            </CDropdownItem>
                                                        }
                                                        {
                                                            item?.status === 2 && <CDropdownItem>
                                                                <CButton onClick={() => {
                                                                    onCloseStatus(item, 3)
                                                                }} size="sm" className="w-100" variant='outline' color="info" >
                                                                    Close <i className="fas fa-check"></i>
                                                                </CButton>
                                                            </CDropdownItem>
                                                        }

                                                        {item?.status === 0 && <>
                                                            <CDropdownItem>
                                                                <CButton onClick={() => {
                                                                    onStatus(item, 1)
                                                                }} size="sm" className="w-100" variant='outline' color="success" >
                                                                    Approve <i className="fas fa-user-check"></i>
                                                                </CButton>
                                                            </CDropdownItem>
                                                            <CDropdownItem>
                                                                <CButton
                                                                    onClick={() => {
                                                                        onStatus(item, 4)
                                                                    }}
                                                                    size="sm" className="w-100" variant='outline' color="danger">
                                                                    Reject <i className="fas fa-user-times"></i>
                                                                </CButton>
                                                            </CDropdownItem>
                                                        </>
                                                        }
                                                        {
                                                            (item?.status === 2 || item?.status === 3 || item?.status === 5) && <CDropdownItem>
                                                                <CButton onClick={() => {
                                                                    onOpenForm(item)
                                                                }} size="sm" className="w-100" variant='outline' color="warning" >
                                                                    Extended <i className="far fa-calendar-plus"></i>
                                                                </CButton>
                                                            </CDropdownItem>
                                                        }
                                                    </CDropdownMenu>
                                                </CDropdown>
                                            </td>
                                        )
                                    },
                                }}
                            />
                        </div>
                    </div>

                    {/* <!-- main-panel ends --> */}
                </div>
                {/* <!-- page-body-wrapper ends --> */}
            </div>
            <BottomSheet open={isOpen} onDismiss={onDismiss} scrollLocking={true}
                header={
                    <>
                        <div className="text-end" style={{ cursor: "pointer" }} onClick={onDismiss}>
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </div>
                        <h4 className="flex items-center text-xl justify-center font-bold text-primary-800">
                            REQUEST DETAILS
                        </h4>
                    </>
                }

                footer={
                    <div>
                        <div className="d-flex justify-content-between">
                            <div>
                                {(hiringData?.status === 2 || hiringData?.status === 3 || hiringData?.status === 5) && hiringData?.rating && <>
                                    <div className="d-flex">
                                        <label>
                                            Share feedback on:&nbsp;&nbsp;
                                        </label>
                                        <ul className="social-icons">
                                            <li><a onClick={() => onClipboard(staffData, hiringData, "https://www.facebook.com/sharer/sharer.php?u=https://staffers.us")} href="#" ><i class="fab fa-facebook-f"></i></a></li>
                                            <li><a onClick={() => onClipboard(staffData, hiringData, "https://www.linkedin.com/sharing/share-offsite/?url=https://staffers.us")} href="#"><i class="fab fa-linkedin-in"></i></a></li>
                                            <li><a target="_blank" href={`https://twitter.com/intent/tweet?url=staffers.us&text=${staffData?.FullName} , ${degreeData?.degrees[0]?.title} , xyz Organization \n${hiringData?.feedback ? "Feedback :" + hiringData?.feedback : ''} \n${hiringData?.rating ? "Rating :" + hiringData?.rating + "/5" : ''} \nVisit Us Here - https://staffers.us`}><i class="fab fa-twitter"></i></a></li>
                                            <li><a target="_blank" href={`https://wa.me/?text=${staffData?.FullName} | ${degreeData?.degrees[0]?.title} | xyz Organization \n${hiringData?.feedback ? "Feedback :" + hiringData?.feedback : ''} \n${hiringData?.rating ? "Rating :" + hiringData?.rating + "/5" : ''} \nVisit Us Here - https://staffers.us`}><i class="fab fa-whatsapp"></i></a></li>
                                            <li><a target="_blank" href={`https://t.me/share/url?url=staffers.us&text=${staffData?.FullName} | ${degreeData?.degrees[0]?.title} | xyz Organization \n${hiringData?.feedback ? "Feedback :" + hiringData?.feedback : ''} \n${hiringData?.rating ? "Rating :" + hiringData?.rating + "/5" : ''} \nVisit Us Here - https://staffers.us`}><i class="fab fa-telegram-plane"></i></a></li>
                                            <li><a target="_blank" href={`mailto:""?&subject=Feedback&body=${staffData?.FullName} | ${degreeData?.degrees[0]?.title} | xyz Organization \n${hiringData?.feedback ? "Feedback :" + hiringData?.feedback : ''} \n${hiringData?.rating ? "Rating :" + hiringData?.rating + "/5" : ''} \nVisit Us Here - https://staffers.us`}><i class="fas fa-at"></i></a></li>
                                            <li>
                                                <a href="#" onClick={() => onCopy(staffData, hiringData)} className="cpy"><i class="fa fa-clone"></i></a></li>
                                            {copy && <li><div className="callout top-right">Copied</div></li>}
                                        </ul>
                                    </div>


                                </>}
                            </div>
                            {shifts && shifts?.length != 0 && (hiringData?.status === 1 || hiringData?.status === 2 || hiringData?.status === 5) &&
                                <div>
                                    {!selectAllButton && shifts?.find(sl => sl.filled == 0) && <CButton onClick={() => {
                                        selectAllSlot()
                                    }} className="w-15" color="primary" >
                                        Fill All
                                    </CButton>}
                                    {selectAllButton && <CButton onClick={() => {
                                        selectAllSlotReset()
                                    }} className="w-15" color="primary" >
                                        Rest All
                                    </CButton>}
                                    &nbsp;
                                    &nbsp;
                                    <CButton onClick={() => {
                                        onGoingStatus(hiringData.id, 2)
                                    }} className="w-15" color="success" >
                                        Save Slots
                                    </CButton>
                                </div>}
                        </div>
                    </div>

                }
            >
                <div style={{ height: '70vh', overflowY: 'scroll' }}>
                    <div className="col-xl-12 col-md-12">
                        <div className="user-card-full">
                            <div className="row m-l-0 m-r-0">
                                <div className="col-sm-12">
                                    <div className="card-block"><h6 className="m-b-20 p-b-5 b-b-default f-w-600">Organization Details</h6>
                                        <div className="row">
                                            {hiringData && hiringData?.user && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Organization Name</p>
                                                <h6 className="text-muted f-w-400">{hiringData?.user?.organization_name}</h6>
                                            </div>}
                                            {hiringData && hiringData?.user && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Organization Type</p>
                                                <h6 className="text-muted f-w-400">{hiringData?.user?.organization_type}</h6>
                                            </div>}
                                            {hiringData && hiringData?.user && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Email</p>
                                                <h6 className="text-muted f-w-400">{hiringData?.user?.email}</h6>
                                            </div>}
                                            {hiringData && hiringData?.user && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Contact</p>
                                                <h6 className="text-muted f-w-400">{hiringData?.user?.contact}</h6>
                                            </div>}
                                            {hiringData && hiringData?.user && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Country Code</p>
                                                <h6 className="text-muted f-w-400">{hiringData?.user?.country_code}</h6>
                                            </div>}
                                        </div>
                                        <br />
                                        <h6 className="m-b-20 p-b-5 b-b-default f-w-600">Caregiver Details</h6>
                                        <div className="row">
                                            {staffData && staffData?.FullName && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Name</p>
                                                <h6 className="text-muted f-w-400">{staffData?.FName} {staffData?.LName}</h6>
                                            </div>}
                                            {degreeData && degreeData.degrees.length > 0 && <div className="col-sm-3">
                                                <p className="m-b-10 f-w-600">Profession</p>
                                                <h6 className="text-muted f-w-400">{degreeData?.degrees.map(tl => { return <>{tl.title} < br /></> })}</h6>
                                            </div>}
                                            {degreeData && degreeData?.degrees.length > 0 && <div className="col-sm-3">
                                                <p className="m-b-10 f-w-600">Specialty</p>
                                                <h6 className="text-muted f-w-400">{degreeData?.degrees.map(tl => tl.specialties?.map(sp => { return <>{sp.name} < br /></> }))}</h6>
                                            </div>}

                                            {staffData && staffData?.City && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">City</p>
                                                <h6 className="text-muted f-w-400">{staffData?.City}</h6>
                                            </div>}
                                            {staffData && staffData?.StateID && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">State</p>
                                                <h6 className="text-muted f-w-400">{staffData?.StateID}</h6>
                                            </div>}
                                        </div>
                                        <br />
                                        <h6 className="m-b-20 p-b-5 b-b-default f-w-600">Caregiver Request Details</h6>
                                        <div className="row">
                                            {hiringData && hiringData?.state && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">State</p>
                                                <h6 className="text-muted f-w-400">{hiringData?.state}</h6>
                                            </div>}
                                            {hiringData && hiringData?.city && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">City</p>
                                                <h6 className="text-muted f-w-400">{hiringData?.city}</h6>
                                            </div>}
                                            {
                                                <div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">Job Type</p>
                                                    <h6 className="text-muted f-w-400">{global?.config?.jobType?.find(job => job?.value == hiringData?.job_type)?.name}</h6>
                                                </div>
                                            }

                                            {
                                                <div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">Extention Times</p>
                                                    <h6 className="text-muted f-w-400">{hiringData?.extention_times}</h6>
                                                </div>
                                            }

                                            {<div className="col-sm-2">
                                                <p className="f-w-600 mb-0">Rating</p>
                                                <h6 className="text-muted f-w-400">
                                                    <div className="rate-view">
                                                        <input type="radio" id="star5" checked={hiringData?.rating == 5} readOnly name="rate" value="5" />
                                                        <label htmlFor="star5" title="5 Start">5 stars</label>
                                                        <input type="radio" id="star4" checked={hiringData?.rating == 4} readOnly name="rate" value="4" />
                                                        <label htmlFor="star4" title="4 Start">4 stars</label>
                                                        <input type="radio" id="star3" checked={hiringData?.rating == 3} readOnly name="rate" value="3" />
                                                        <label htmlFor="star3" title="3 Start">3 stars</label>
                                                        <input type="radio" id="star2" checked={hiringData?.rating == 2} readOnly name="rate" value="2" />
                                                        <label htmlFor="star2" title="2 Start">2 stars</label>
                                                        <input type="radio" id="star1" checked={hiringData?.rating == 1} readOnly name="rate" value="1" />
                                                        <label htmlFor="star1" title="1 Start">1 star</label>
                                                    </div></h6>
                                            </div>
                                            }
                                            {<div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Feedback</p>
                                                <h6 className="text-muted f-w-400">{hiringData?.feedback}</h6>
                                            </div>
                                            }
                                            {<div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Status</p>
                                                <h6 className="text-muted f-w-400">

                                                    {hiringData?.status == 0 ?
                                                        <CFormLabel className="w-100 text-warning" >
                                                            Pending
                                                        </CFormLabel>
                                                        : hiringData?.status == 1 ? <CFormLabel className="w-100 text-success" >
                                                            Accepted
                                                        </CFormLabel>
                                                            : hiringData?.status == 2 ? <CFormLabel className="w-100 text-success" >
                                                                Ongoing
                                                            </CFormLabel>
                                                                : hiringData?.status == 3 ? <CFormLabel className="w-100 text-success" >
                                                                    Completed
                                                                </CFormLabel>
                                                                    : hiringData?.status == 4 ? <CFormLabel className="w-100 text-danger" >
                                                                        Rejected
                                                                    </CFormLabel>
                                                                        : hiringData?.status == 5 ? <CFormLabel className="w-100 text-info" >
                                                                            Extended
                                                                        </CFormLabel> : <CFormLabel className="w-100 text-info" >
                                                                            No Update
                                                                        </CFormLabel>
                                                    }
                                                </h6>
                                            </div>}
                                            {<div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Caregiver Status</p>
                                                <h6 className="text-muted f-w-400">
                                                    {hiringData?.staff_response == 0 ?
                                                        <CFormLabel className="w-100 text-warning" >
                                                            Pending
                                                        </CFormLabel>
                                                        : hiringData?.staff_response == 1 ? <CFormLabel className="w-100 text-success" >
                                                            Accepted
                                                        </CFormLabel>
                                                            : hiringData?.staff_response == 2 ? <CFormLabel className="w-100 text-ganger" >
                                                                Rejected </CFormLabel> : <CFormLabel className="w-100 text-info" >
                                                                No Update
                                                            </CFormLabel>

                                                    }
                                                </h6>
                                            </div>}

                                            {hiringData && hiringData?.description && <div className="col-sm-4">
                                                <p className="m-b-10 f-w-600">Description</p>
                                                <h6 className="text-muted f-w-400">{hiringData?.description}</h6>
                                            </div>}
                                        </div>
                                    </div>
                                    {hiringData?.tenure && <div className="card-block">
                                        <h6 className="m-b-20 p-b-5 b-b-default f-w-600 row">Shift Details (&nbsp;<div className="green-box"> </div>&nbsp;= Shift Filled)</h6>
                                        <div className="row">
                                            {hiringData?.tenure && <div className="col-sm-12">
                                                <ul className="list-unstyled">
                                                    <div className="row">
                                                        {shifts?.map((slot, index) =>
                                                            <div key={index + "shifts"} className="col-sm-6 col-md-6 col-lg-4">
                                                                <li >
                                                                    <span className={deletedSlot?.includes(slot?.date) ? "deleted" : ""}>
                                                                        <input className={slot?.filled == 1 ? "slot green-border" : "slot"} readOnly value={slot?.date} />
                                                                        <TimeRangePicker
                                                                            value={slot?.time}
                                                                            className={slot?.filled == 1 ? "timer-filled" : "timer"}
                                                                            onChange={(e) => updateSlots(slot?.date, e)}
                                                                            rangeDivider="To"
                                                                            clearIcon={null}
                                                                            required
                                                                            disableClock={true}
                                                                        />
                                                                    </span>
                                                                    {hiringData?.status !== 0 && hiringData?.status !== 4 && hiringData?.status !== 3 && <div className="btn" >
                                                                        {deletedSlot?.includes(slot?.date) ? <i onClick={() => deleteSlots(slot?.date)} className="fa fa-undo" aria-hidden="true"></i> :
                                                                            <CTooltip
                                                                                content="Remove this shift?"
                                                                                placement="right"
                                                                            >
                                                                                <i onClick={() => deleteSlots(slot?.date)} className="fa fa-trash text-danger" aria-hidden="true">
                                                                                </i>
                                                                            </CTooltip>
                                                                        }
                                                                        &nbsp;
                                                                        &nbsp;
                                                                        {slot?.filled == 0 && !deletedSlot?.includes(slot?.date) &&
                                                                            (<CTooltip content="Fill this shift?" placement="right"><i onClick={() => addSlots(slot?.date)} className="fa fa-check text-success" aria-hidden="true"></i></CTooltip>)}
                                                                    </div>}
                                                                </li>
                                                            </div>
                                                        )}
                                                    </div>
                                                </ul>
                                            </div>}
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BottomSheet >
            <Spinner isLoading={AdminStore.loading} />

            {/* EXTEND POP UP */}
            <CModal visible={isOpenForm} onClose={() => onDismissForm()}>
                <CModalHeader onClose={() => onDismiss()}>
                    <CModalTitle>Select Shifts</CModalTitle>
                </CModalHeader>
                {/* <CModalBody> */}
                <Formik
                    innerRef={formikRef}
                    initialValues={{ request_id: formData?.id, tenure: "" }}
                    onSubmit={values => onExtendStatus(values)}
                >
                    {
                        ({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched
                        }) =>
                        (
                            <form onSubmit={handleSubmit}>
                                <div className="modal-body p-4">
                                    <div className="row g-2">
                                        <div className="pb-2 col-sm-6">
                                            <SelectField
                                                name="tenure_type"
                                                label={"Shift Type*"}
                                                value={durationType}
                                                onChange={(e) => {
                                                    setDurationType(e.target.value)
                                                    setValues([])
                                                    setDates([])
                                                    setAllDates([])
                                                    setFieldValue("tenure", "")
                                                    setFieldValue("alldate", "")
                                                    setFieldValue("alltime", "")
                                                }}
                                                onBlur={handleBlur}
                                                data={[{ "label": "PER DIEM", "value": "Multiple" }, { "label": "LONG TERM", "value": "Range" }]}
                                                optionLabel={"label"}
                                                optionValue={"value"}
                                            />
                                            {!durationType ? (
                                                <div className="error-message">Shift type required</div>
                                            ) : null}
                                        </div>
                                        <div className="pb-2 col-sm-6">
                                            {durationType == 'Multiple' ? <DatePicker
                                                disabled={durationType === null}
                                                required
                                                calendarPosition="bottom"
                                                fixMainPosition
                                                placeholder="Select Dates"
                                                format="MM/DD/YYYY"
                                                inputClass="did-floating-dropdown"
                                                multiple
                                                value={multi}
                                                minDate={new Date()}
                                                mapDays={({ date, today }) => {
                                                    if (disabledSlots && disabledSlots?.includes(date?.format())) {
                                                        return { disabled: true };
                                                    }
                                                }}
                                                onChange={(e) => {
                                                    setValues(e)
                                                    setFieldValue('tenure', e)
                                                }}
                                                plugins={[
                                                    weekends()
                                                ]}
                                            /> : durationType === "Range" ?
                                                <DatePicker
                                                    disabled={durationType === null}
                                                    placeholder="Select Start & End Date"
                                                    calendarPosition="bottom"
                                                    fixMainPosition
                                                    required
                                                    format="MM/DD/YYYY"
                                                    inputClass="did-floating-dropdown"
                                                    range
                                                    mapDays={({ date, today }) => {
                                                        if (disabledSlots && disabledSlots?.includes(date.format())) {
                                                            return { disabled: true };
                                                        }
                                                    }}
                                                    rangeHover
                                                    value={dates}
                                                    minDate={new Date()}
                                                    onChange={dateObjects => {
                                                        setDates(dateObjects)
                                                        setAllDates(getAllDatesInRange(dateObjects))
                                                        setFieldValue('tenure', getAllDatesInRange(dateObjects))
                                                    }
                                                    }
                                                    plugins={[
                                                        weekends()
                                                    ]}
                                                /> : ""}
                                        </div>
                                        <div>
                                            {dates?.length > 1 &&
                                                <div>
                                                    <div className="d-flex justify-content-start mt-2 mb-2">Slots:</div>
                                                    {/* <h5 className="d-flex justify-content-start">
                                                        {`All Dates`}
                                                    </h5> */}
                                                    <ul>
                                                        <FieldArray name="tenure">
                                                            {({ push, remove, form }) => (
                                                                values?.tenure?.length > 0 && values?.tenure.map((date, index) =>
                                                                    <li key={index + 'slotss'}>
                                                                        <input className="slot" id={`alldate[${index}]`} name={`alldate[${index}]`} value={date?.format()} />
                                                                        <Field id={`alltime[${index}]`} name={`alltime[${index}]`} render={({ field, form }) => <TimeRangePickerField  {...field} {...form} firstTime={values?.alltime?.length > 0 && values?.alltime[0]} index={index}/>} />
                                                                        <div className="btn" onClick={() => {
                                                                            setAllDates([
                                                                                ...allDates.slice(0, index),
                                                                                ...allDates.slice(index + 1)
                                                                            ]);
                                                                            setDates([
                                                                                ...allDates.slice(0, index),
                                                                                ...allDates.slice(index + 1)
                                                                            ]);
                                                                            remove(index)
                                                                        }}>
                                                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            )}
                                                        </FieldArray>

                                                    </ul>
                                                </div>
                                            }
                                            {multi?.length > 0 && multi &&
                                                <div>
                                                    <div className="d-flex justify-content-start mt-2 mb-2">Slots:</div>
                                                    {/* <h5>
                                                        All Dates :
                                                    </h5> */}
                                                    <ul>
                                                        <FieldArray name="tenure">
                                                            {({ push, remove, form }) => (
                                                                values?.tenure.length > 0 && values?.tenure?.map((date, index) =>
                                                                    <li key={index + 'ext'}>
                                                                        <input className="slot" id={`alldate[${index}]`} name={`alldate[${index}]`} value={date?.format()} />
                                                                        <Field id={`alltime[${index}]`} name={`alltime[${index}]`} render={({ field, form }) => <TimeRangePickerField  {...field} {...form} firstTime={values?.alltime?.length > 0 && values?.alltime[0]} index={index}/>} />
                                                                        <div className="btn" onClick={() => {
                                                                            setValues([
                                                                                ...multi.slice(0, index),
                                                                                ...multi.slice(index + 1)
                                                                            ]);
                                                                            remove(index)
                                                                        }}>
                                                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            )}
                                                        </FieldArray>
                                                    </ul>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-center">
                                    <button type="submit" className="btn btn-primary w-25">Submit</button>
                                </div>
                            </form>
                        )
                    }
                </Formik>
                {/* </CModalBody> */}
            </CModal>
        </React.Fragment >
    );
})

function TimeRangePickerField(props) {
    const handleChange = (value) => {
        const [startTime, endTime] = value;
        const date1 = new Date(`2023-03-14T${startTime}:00`);
        const date2 = new Date(`2023-03-14T${endTime}:00`);
        if (date1.getTime() < date2.getTime() || startTime) {
            props.setFieldValue(props.name, value);
        }
        if (date1.getTime() > date2.getTime()) {
            props.setFieldValue(props.name, [startTime, undefined]);
            toast.warn(`Start is later than end time`);
            return
        }
        if (date1.getTime() === date2.getTime()) {
            props.setFieldValue(props.name, [startTime,]);
            toast.warn(`Start and end are the same time`);
            return
        }
    };

    const handleBlur = () => {
        props.setFieldTouched(props.name, true);
    };
    return (
        <>
            <TimeRangePicker
                className="timer"
                value={props.value || props?.firstTime}
                onChange={handleChange}
                onBlur={handleBlur}
                {...props.rest}
                rangeDivider="To"
                required
                clearIcon={null}
            />
        </>
    );
}

export default HiringRequestList;