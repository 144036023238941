import React from 'react';
import { CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter, CButton } from '@coreui/react';
import { CFormCheck, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react-pro'
import moment from "moment";
import TimeRangePicker from '@wojtekmaj/react-timerange-picker';
import HomeStore from "@store/HomeStore";

const EmployerModal = ({
    showSlotModal,
    setShowSlotModal,
    pendingContractList,
    pendingShiftList,
    handleSelectAllContract,
    contractAdd,
    slotAdd,
    handleSelectAllSlots,
    contractAcceptReject,
    slotAcceptReject,
    selectedContractIds,
    selectedSlotIds

}) => {
    return (
        <CModal
            id="approval"
            size="lg"
            backdrop="static"
            visible={showSlotModal}
            scrollable
            onClose={() => setShowSlotModal(false)}
        >
            <CModalHeader closeButton>
                <CModalTitle>({pendingShiftList?.length + pendingContractList?.length}) Order Request's</CModalTitle>
            </CModalHeader>
            <CModalBody>
                {/* CONTRACT TABLE */}
                {(pendingContractList && pendingContractList?.length !== 0) && <>
                    <h5 className="d-flex justify-content-center pt-2"><b>CONTRACTS</b></h5>
                    <CTable responsive>
                        <CTableHead>
                            <CTableRow>
                                <CTableHeaderCell>
                                    <CFormCheck onClick={handleSelectAllContract} data-toggle="tooltip" data-placement="right" title="Approve All"
                                        inline style={{ borderColor: "green" }} type="radio" value="Approve" label="Approve All"
                                        name="all_contract" id="all_contract_approve"
                                        checked={pendingContractList?.every(({ id }) => selectedContractIds?.some(y => y.id == id && y.code === 5))}
                                    />
                                </CTableHeaderCell>

                                <CTableHeaderCell>
                                    <CFormCheck onClick={handleSelectAllContract} data-toggle="tooltip" data-placement="right" title="Reject All"
                                        inline style={{ borderColor: "red" }} type="radio" name="all_contract" value="Reject" label="Reject All"
                                        id="all_contract_reject"
                                        checked={pendingContractList?.every(({ id }) => selectedContractIds?.some(y => y.id == id && y.code === 3))}
                                    />
                                </CTableHeaderCell>
                                <CTableHeaderCell scope="col">Shift Ref.</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Shift Date/Time</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Request By</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Requested Time</CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>
                        <CTableBody>
                            {/* FOR CONTACT */}
                            {pendingContractList?.map((care, index) => {
                                return (
                                    <CTableRow key={index + "contact"}>
                                        <CTableHeaderCell id="approve-contract">
                                            <CFormCheck inline
                                                data-toggle="tooltip" data-placement="right" title="Approve"
                                                style={{ borderColor: "green" }} type="radio"
                                                id={care.id + "approve-contacts"}
                                                name={care.id}
                                                label=""
                                                value={"Approve"}
                                                onClick={(e) => {
                                                    contractAdd(e.target.value, care.id);
                                                }}
                                                checked={selectedContractIds?.some(x => x.id == care.id && x.code === 5)}
                                            />
                                        </CTableHeaderCell>
                                        <CTableHeaderCell id="reject-contract">
                                            <CFormCheck inline
                                                data-toggle="tooltip" data-placement="right" title="Reject"
                                                style={{ borderColor: "red" }} type="radio"
                                                name={care.id}
                                                id={care.id + "reject-contacts"}
                                                value={"Reject"}
                                                label=""
                                                onClick={(e) => {
                                                    contractAdd(e.target.value, care.id);
                                                }}
                                                checked={selectedContractIds?.some(x => x.id == care.id && x.code === 3)}
                                            />
                                        </CTableHeaderCell>
                                        <CTableHeaderCell>{care.id}</CTableHeaderCell>
                                        <CTableHeaderCell>{
                                            <span>
                                                <input className="slot" readOnly value={moment(care?.start_date, 'YYYY-MM-DD').format('MM/DD/YYYY')} />
                                                <span className='slot-to'>To</span>
                                                <input className="slot-right" readOnly value={moment(care?.end_date, 'YYYY-MM-DD').format('MM/DD/YYYY')} />
                                                <TimeRangePicker
                                                    value={[care?.start_time, care?.end_time]}
                                                    disabled={true}
                                                    className={"timer"}
                                                    rangeDivider="To"
                                                    clearIcon={null}
                                                    required
                                                    disableClock={true}
                                                />
                                            </span>}
                                        </CTableHeaderCell>
                                        <CTableDataCell>{care?.staff?.first_name} {care?.staff?.last_name}
                                            <a href={"tel:" + care?.staff?.contact}>{care?.staff?.contact}&nbsp;&nbsp;{care?.staff?.contact && <i className="fas fa-phone"></i> || "-"}</a>
                                            <a href={"mailto:" + care?.staff?.email}> {care?.staff?.email}&nbsp;&nbsp;<i className="fas fa-envelope"></i></a></CTableDataCell>
                                        <CTableDataCell>{moment(care.created_at).fromNow()}</CTableDataCell>
                                    </CTableRow>);
                            })}

                        </CTableBody>
                    </CTable>
                </>}
                {/* SLOTS TABLE */}
                {(pendingShiftList && pendingShiftList?.length !== 0) && <><h5 className="d-flex justify-content-center pb-0 mb-0"><b>SHIFTS</b></h5>
                    <CTable responsive>
                        <CTableHead>
                            <CTableRow>
                                <CTableHeaderCell>
                                    <CFormCheck className='m-0 text-success' onClick={handleSelectAllSlots} data-toggle="tooltip"
                                        data-placement="right" title="Approve All" inline style={{ borderColor: "green" }} type="radio"
                                        checked={pendingShiftList?.every(({ id }) => selectedSlotIds?.some(y => y.id === id && y.code === 2))}
                                        value="Approve" label="Approve All" name="all_slot" id="all_slot_approve" />
                                    <CFormCheck className='m-0 text-danger' onClick={handleSelectAllSlots} data-toggle="tooltip" name="all_slot" id="all_slot_reject_close"
                                        data-placement="right" title="Reject & Close All" inline style={{ borderColor: "red" }} type="radio"
                                        value="Reject-Close" label="Reject & Close All"
                                        checked={pendingShiftList?.every(({ id }) => selectedSlotIds?.some(y => y.id === id && y.code === 3))}
                                    />
                                    <CFormCheck className='m-0 text-danger' onClick={handleSelectAllSlots} data-toggle="tooltip" name="all_slot" id="all_slot_reject"
                                        data-placement="right" title="Reject & Open All" inline style={{ borderColor: "red" }} type="radio"
                                        value="Reject" label="Reject & Open All"
                                        checked={pendingShiftList?.every(({ id }) => selectedSlotIds?.some(y => y.id === id && y.code === 1))}
                                    />

                                </CTableHeaderCell>
                                <CTableHeaderCell scope="col">Shift Ref.</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Shift Date/Time</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Request By</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Profession-Speciality</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Requested Time</CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>
                        <CTableBody>
                            {pendingShiftList?.map((care, index) => {
                                return (<CTableRow key={index + "slot"}>
                                    <CTableHeaderCell id="approve-slots">
                                        <CFormCheck inline
                                            data-toggle="tooltip"
                                            data-placement="right"
                                            title="Approve"
                                            className='m-0 text-success'
                                            type="radio"
                                            id={care.id + "approve-slot"}
                                            name={care.id}
                                            label="Approve"
                                            value={"Approve"}
                                            onClick={(e) => {
                                                slotAdd(e.target.value, care.id);
                                            }}
                                            checked={selectedSlotIds?.some(x => x.id === care.id && x.code === 2)}
                                        />
                                        <CFormCheck inline
                                            data-toggle="tooltip"
                                            data-placement="right"
                                            title="Reject & Close"
                                            className='m-0 text-danger'
                                            type="radio"
                                            name={care.id}
                                            id={care.id + "reject-slot-close"}
                                            value={"Reject-Close"}
                                            label="Reject & Close"
                                            onClick={(e) => {
                                                slotAdd(e.target.value, care.id);
                                            }}
                                            checked={selectedSlotIds?.some(x => x.id === care.id && x.code === 3)}
                                        />
                                        <CFormCheck inline
                                            data-toggle="tooltip"
                                            data-placement="right"
                                            title="Reject & Open"
                                            className='m-0 text-danger'
                                            type="radio"
                                            name={care.id}
                                            id={care.id + "reject-slot"}
                                            value={"Reject"}
                                            label="Reject & Open"
                                            onClick={(e) => {
                                                slotAdd(e.target.value, care.id);
                                            }}
                                            checked={selectedSlotIds?.some(x => x.id === care.id && x.code === 1)}
                                        />
                                    </CTableHeaderCell>
                                    <CTableHeaderCell>{care.id}</CTableHeaderCell>
                                    <CTableHeaderCell>{
                                        <span>
                                            <input className={"slot"} readOnly value={care?.slot_date} />
                                            <TimeRangePicker
                                                value={[care?.slot_start_time, care?.slot_end_time]}
                                                disabled={true}
                                                className={"timer"}
                                                rangeDivider="To"
                                                clearIcon={null}
                                                required
                                                disableClock={true}
                                            />
                                        </span>}
                                    </CTableHeaderCell>
                                    <CTableDataCell className='h3'>{care?.staff?.first_name} {care?.staff?.last_name}<br />
                                        <a href={"tel:" + care?.staff?.contact}>{care?.staff?.contact}&nbsp;&nbsp;{care?.staff?.contact && <i className="fas fa-phone"></i> || "-"}</a><br />
                                        <a href={"mailto:" + care?.staff?.email}> {care?.staff?.email}&nbsp;&nbsp;<i className="fas fa-envelope"></i></a></CTableDataCell>
                                    <CTableDataCell>{care?.request?.specialty_name ? care?.request?.specialty_name : HomeStore?.data?.degreeList?.find(pro => pro.Id == care?.request?.profession)?.Name}
                                    </CTableDataCell>
                                    <CTableDataCell>{moment(care.created_at).fromNow()}</CTableDataCell>
                                </CTableRow>);
                            })}

                        </CTableBody>

                    </CTable></>}

            </CModalBody>
            <CModalFooter>
                <CButton onClick={() => { contractAcceptReject(); slotAcceptReject(); }} >Submit</CButton>
            </CModalFooter>
        </CModal>
    );
};

export default EmployerModal;
