import { toast } from "react-toastify";
import { HOME_URL } from "../../api/config";
import HomeStore from "@store/HomeStore";
import { toJS } from "mobx";
import moment from "moment";
const documentFields = {
  Department: "",
  FLSA: "Non-exempt/Hourly",
  Revised: "",
  Reportingto: "Staffing Manager",
  DOH: "",
  job_title: "",
  job_title: "",
  location: "Staffers LLC",
  EffectiveDate: "",
  aliases_first: "",
  aliases_middle: "",
  aliases_last: "",
  full_name: "",
  first_name: "",
  middle_name: "",
  last_name: "",
  dob: "",
  ssn: "",
  email: "",
  diff_email: "",
  country: "",
  vaccine_declined_time: null,
  hbv: null,
  Position: "",
  race: "",
  gender: "",
  Height_Feet: "",
  Height_Inches: "",
  Weight: "",
  Citizens: "",
  pob: "",
  Aliases: "",
  PriorCity: "",
  PriorState: "",
  Yearfrom: "",
  Yearto: "",
  genderExp: "",
  eyeColor: "",
  hairColor: "",
  address: "",
  ResidentialHistory: "",
  ResidentialHistory2: "",
  PlaceofEmployment: "",
  EmploymentPosition: "",
  PositionDescription: "",
  PositionRequires: "",
  PositionRequiresDriving: "",
  street: "",
  city: "",
  state: "",
  state_id: "",
  issue_state: "",
  zip: "",
  work: "",
  telephone: "",
  telephoneSecond: "",
  telephone_type: "",
  telephone_type2: "",
  workedList: "",
  claims: "",
  circumstances: "",
  workRestrictions: "",
  chemicalFumes: "",
  heavyLifting: "",
  repetitiveMotion: "",
  ability: "",
  nosebleed: "",
  occupationalExposures: "",
  persistentCough: "",
  wheezing: "",
  asthma: "",
  bronchitis: "",
  pneumonia: "",
  Tuberculosis: "",
  Chickenpox: "",
  Hepatitis: "",
  htype: "",
  Measles: "",
  Mumps: "",
  Rhewmatic: "",
  Polio: "",
  MRSA: "",
  Head: "",
  Neck: "",
  Upper: "",
  Middle: "",
  Low: "",
  Swollen: "",
  Arthritis: "",
  Foot: "",
  Elbow: "",
  Shoulder: "",
  Knee: "",
  Leg: "",
  Heart: "",
  Shortness: "",
  Chest: "",
  Blood: "",
  Nosebleeds: "",
  Dizzy: "",
  Numbness: "",
  Varicose: "",
  Thrombophlebitis: "",
  LSugar: "",
  Diabetes: "",
  Bruise: "",
  Epilepsy: "",
  Swallowing: "",
  Rashes: "",
  Hoarseness: "",
  Amputation: "",
  Circulation: "",
  Breathing: "",
  Blindness: "",
  Hearing: "",
  Muscle: "",
  Problems: "",
  Itching: "",
  Jaundice: "",
  Latex: "",
  diagnosis: "",
  Diphtheria: "",
  ExpDiphtheria: "",
  Rubella: "",
  ExpRubella: "",
  Polio2: "",
  Polio2Date: "",
  HepatitisB: "",
  HepatitisBDate: "",
  Varicella: "",
  VaricellaDate: "",
  ChickenpoxHave: "",
  PPD: "",
  PPDDate: "",
  PPDDateResult: "",
  ChestX: "",
  ChestXDate: "",
  ChestXResult: "",
  Polio3: "",
  Polio3Date: "",
  Polio3Result: "",
  Hepatitis3: "",
  Hepatitis3Result: "",
  Hepatitis3Date: "",
  Varicella3: "",
  Varicella3Date: "",
  Varicella3Result: "",
  haveAllergies: "",
  physicians: "",
  thirtysixMONTHS: "",
  conf_street: "",
  APT: "",
  conf_city: "",
  conf_state: "",
  conf_zip: "",
  PHYSICALADDRESS: "",
  PHYSICALAPT: "",
  PHYSICALCITY: "",
  PHYSICALSTATE: "",
  PHYSICALZIP: "",
  crime: "",
  charges: "",
  abused: "",
  vulnerable: "",
  exploitation: "",
  crime_name: "",
  DEGREE: "",
  CRIMESTATE: "",
  CONVICTIONDATE: "",
  Attempted: "",
  Conspiracy: "",
  Solicitation: "",
  NA: "",
  crime_description: "",
  DEGREETWO: "",
  CRIMESTATETWO: "",
  CONVICTIONDATETWO: "",
  AttemptedTWO: "",
  ConspiracyTWO: "",
  SolicitationTWO: "",
  NATWO: "",
  crime_description_two: "",
  DEGREETHREE: "",
  CRIMESTATETHREE: "",
  CONVICTIONDATETHREE: "",
  AttemptedTHREE: "",
  ConspiracyTHREE: "",
  SolicitationTHREE: "",
  NATHREE: "",
  crime_description_three: "",
  DEGREEFOUR: "",
  CRIMESTATEFOUR: "",
  CONVICTIONDATEFOUR: "",
  AttemptedFOUR: "",
  ConspiracyFOUR: "",
  SolicitationFOUR: "",
  NAFOUR: "",
  crime_description_four: "",
  DEGREEFIVE: "",
  CRIMESTATEFIVE: "",
  CONVICTIONDATEFIVE: "",
  AttemptedFIVE: "",
  ConspiracyFIVE: "",
  SolicitationFIVE: "",
  NAFIVE: "",
  crime_description_five: "",
  Documenttype: "",
  IssuingState: "",
  DocumentNumber: "",
  ExpirationDate: "",
  DocExt: "",
  cane: "",
  walker: "",
  standby: "",
  shampoo: "",
  nailCare: "",
  oralHygiene: "",
  shavingSafety: "",
  dressingAssist: "",
  perinealCare: "",
  bathBedTubShower: "",
  applyingLotion: "",
  backRub: "",
  decubitusCare: "",
  unoccupiedBed: "",
  occupiedBed: "",
  valuableSecurementInventory: "",
  admissionSetUp: "",
  lightHousekeeping: "",
  encourageFluids: "",
  assistFeeding: "",
  feedingTechniques: "",
  mealSnackPrep: "",
  measureRecordIntake: "",
  handWashing: "",
  airbornePrecaution: "",
  dropletPrecaution: "",
  universalPrecautions: "",
  ppe: "",
  bedPanUrinal: "",
  bedsideCommode: "",
  measureRecordOutput: "",
  foleyCatheterCare: "",
  externalCatheterCare: "",
  enemasTabH20: "",
  gaitBelt: "",
  weightBearing: "",
  twoPersonTransfer: "",
  slideBoard: "",
  wheelchair: "",
  hoyerLift: "",
  supinePositioning: "",
  pulse: "",
  sideLying: "",
  drawSheet: "",
  rangeOfMotionExercises: "",
  inChair: "",
  temperature: "",
  respirations: "",
  bloodPressure: "",
  heightWeight: "",
  painScale: "",
  communicationImpaired: "",
  hcahpsAwareness: "",
  urine: "",
  stool: "",
  wound: "",
  sputum: "",
  changeBodyFunctions: "",
  changeBehavior: "",
  changeRoutines: "",
  vestRestraint: "",
  softAnkle: "",
  wristRestraints: "",
  paddedSideRails: "",
  leather4PointRestraints: "",
  verbalPrompts: "",
  medicationTaken: "",
  twoIdentifiers: "",
  flowRate: "",
  waterToHumidifier: "",
  nasalCannula: "",
  simpleMaskNonRebreather: "",
  carePlans: "",
  centralStationEntryRetrieval: "",
  delegation: "",
  medicalInfoSystem: "",
  flowSheets: "",
  narrativeNotes: "",
  soap: "",
  sbar: "",
  newbornNeonate: "",
  infant31days1year: "",
  toddler2to3years: "",
  preschool4to5years: "",
  schoolAge6to12years: "",
  adolescent13to21years: "",
  youngAdult22to39years: "",
  olderAdult40to64years: "",
  elderly80plusyears: "",
  skillOne: "",
  skillTwo: "",
  skillThree: "",
  skillFour: "",
  trainingOne: "",
  trainingTwo: "",
  trainingThree: "",
  trainingFour: "",
  equipmentOne: "",
  equipmentTwo: "",
  equipmentThree: "",
  generalSkill: "",
  HCAHPS: "",
  pft: "",
  dischPlan: "",
  medReview: "",
  transDev: "",
  specBeds: "",
  palliativecare: "",
  automatedMedDispSystem: "",
  natPatientSafetyGoals: "",
  accPatientIdentification: "",
  effCommunication: "",
  awarePatientRights: "",
  interpCommLabValues: "",
  medReconciliation: "",
  labelMedSpecimens: "",
  medReconciliation: "",
  anticoagTherapy: "",
  painAssessManage: "",
  infectControl: "",
  isolation: "",
  minRiskFalls: "",
  prevPressUlcers: "",
  assessHeartSounds: "",
  pacemakers: "",
  cardiacArrest: "",
  postMI: "",
  CHF: "",
  postCardiacSurgery: "",
  fluidElecImbal: "",
  anticoagulants: "",
  antiarrhythmic: "",
  betaBlockers: "",
  nitroglycerin: "",
  diuretics: "",
  assessAuscultLungSounds: "",
  chestPT: "",
  incentSpriro: "",
  suppOxygen: "",
  trachCare: "",
  suctTrachNasotrach: "",
  nebUse: "",
  pneumoniaExp: "",
  asthmaExp: "",
  COPD: "",
  expectorants: "",
  bronchodilators: "",
  emphysema: "",
  assessNeuroSigns: "",
  seizurePrecautions: "",
  seizureActivity: "",
  TBIhist: "",
  spinalCordInjury: "",
  strokeCVA: "",
  multipleSclerosis: "",
  alzheimerDisease: "",
  antiSeizureMeds: "",
  antiemetics: "",
  laxatives: "",
  bowelPrep: "",
  foleyCathInsertRemoval: "",
  GUIrrigations: "",
  supraPubicCath: "",
  ileConduit: "",
  bladderScanUse: "",
  shuntsFistulas: "",
  restrictivedevices: "",
  univPrecautions: "",
  idArrhythmias: "",
  hypertension: "",
  estabAirway: "",
  straightCaths: "",
  chronicRenalFailure: "",
  peritonealDialysis: "",
  totalJointReplacement: "",
  arthroscopicSurgery: "",
  castCare: "",
  CMSchecks: "",
  stapleRemoval: "",
  prosthesisApplication: "",
  stdExtremityBraces: "",
  amputationNew: "",
  stumpWrapping: "",
  laminectomy: "",
  assistiveDevices: "",
  CPMmachines: "",
  startMaintainIVs: "",
  bloodDraw: "",
  centralLineCare: "",
  careManagementofPorts: "",
  infusionPumps: "",
  adminBloodProducts: "",
  woundSugCare: "",
  woundMedCare: "",
  dressingChanges: "",
  skinAssessment: "",
  woundVac: "",
  newbornneonatebirth30days: "",
  infantExp: "",
  toddlerages2to3years: "",
  preschoolages4to5years: "",
  schoolageages6to12years: "",
  adolescentages13to21years: "",
  youngadultages22to39years: "",
  olderadultages40to64years: "",
  elderlyages80plusyears: "",
  alsAmyotrophicLateralSclerosis: "",
  enemas: "",
  bladderTraining: "",
  nephroTube: "",
  skillD2One: "",
  skillD2Two: "",
  skillD2Three: "",
  skillD2Four: "",
  trainingD2One: "",
  trainingD2Two: "",
  trainingD2Three: "",
  trainingD2Four: "",
  equipmentD2One: "",
  equipmentD2Two: "",
  equipmentD2Three: "",
  SSNo: "",
  policy_checkbox: "",
  isolationExp: "",
  corticosteroids: "",
  NGtube: "",
  sondob: "",
  gTube: "",
  tFeeds: "",
  mIOtracking: "",
  cOstomy: "",
  giBleed: "",
  fEquipDevices: "",
  dRestrictions: "",
  gtPegFeeds: "",
  aMetics: "",
  lAxatives: "",
  eNemas: "",
  bPrep: "",
  // Add other input field properties as needed
};
const selectedKeysOne = [
  "first_name",
  "last_name",
  "state_id",
  "city",
  "address",
  "preferred_state",
  "zipcode",
];
const selectedKeysTwo = ["degree_specialities", "contact_devices"];
const selectedKeysThree = ["interested_in", "availability"];
const selectedKeysFour = ["documents"];
const selectedKeysFive = ["equal_opportunity", "disability"];
const selectedKeysSix = ["ssn", "birth_date", "tnc"];

const industriesList = [
  { label: "Hospital" },
  { label: "SNF/LTC" },
  { label: "Assisted Living" },
  { label: "Behavioral Health Hospital" },
  { label: "Home Care" },
];
const shiftTypes = [
  { label: "Days" },
  { label: "Evenings" },
  { label: "Nights" },
  { label: "Weekends" },
];

const contractTypes = [
  { label: "Per Diem" },
  { label: "Local Contract" },
  { label: "Travel Contract" },
];
const specalitiesList = [
  { label: "ER" },
  { label: "Labor and Delivery" },
  { label: "DON" },
];
const contractLengthList = [
  { label: "4 weeks", value: 4 },
  { label: "8 weeks", value: 8 },
  { label: "12 weeks", value: 12 },
  { label: "14 weeks", value: 14 },
  { label: "18 weeks", value: 18 },
  { label: "24 weeks", value: 24 },
  { label: "28 weeks", value: 28 },
  { label: "30+ weeks", value: 30 },
];
const enquiryType = [
  {
    id: 1,
    enquiry: "General",
  },
  {
    id: 2,
    enquiry: "Recruiter/Employer",
  },
  {
    id: 3,
    enquiry: "Professional",
  },
  {
    id: 4,
    enquiry: "Candidate",
  },
  {
    id: 5,
    enquiry: "Refer a Friend",
  },
];
const contactQuery = [
  {
    id: 1,
    enquiry: "General inquiry",
  },
  {
    id: 2,
    enquiry: "I am Employer - Looking for talent.",
  },
  {
    id: 3,
    enquiry: "I am Professional - Looking for assistance.",
  },
  {
    id: 4,
    enquiry: "I am a Candidate - Seeking job opportunities.",
  },
  {
    id: 5,
    enquiry: "I'm from Staffers - Referring a friend.",
  },
];
const jobType = [
  {
    label: "Local",
    value: "Local",
  },
  {
    label: "Travel",
    value: "Travel",
  },
];

const slotStatusList = [
  {
    label: "Open",
    value: 1,
  },
  {
    label: "Confirmed",
    value: 2,
  },
  {
    label: "Rejected",
    value: 3,
  },
  {
    label: "Extention pending",
    value: 4,
  },
  {
    label: "Cancelled by caregiver",
    value: 5,
  },
  {
    label: "Cancelled by facility",
    value: 6,
  },
  {
    label: "Completed",
    value: 7,
  },
];
const abbreviatedDays = {
  Monday: "Mon",
  Tuesday: "Tue",
  Wednesday: "Wed",
  Thursday: "Thu",
  Friday: "Fri",
  Saturday: "Sat",
  Sunday: "Sun",
};
const slotStatusListClient = [
  {
    label: "Open",
    value: 1,
  },
  {
    label: "Confirmed",
    value: 2,
  },
  {
    label: "Rejected",
    value: 3,
  },
  {
    label: "Extention pending",
    value: 4,
  },
  {
    label: "Cancelled by caregiver",
    value: 5,
  },
  {
    label: "Cancelled by you",
    value: 6,
  },
  {
    label: "Completed",
    value: 7,
  },
];
const slotStatusListCaregiver = [
  {
    label: "Confirmed",
    value: 2,
  },
  {
    label: "Rejected",
    value: 3,
  },
  {
    label: "Extention pending",
    value: 4,
  },
  {
    label: "Cancelled by you",
    value: 5,
  },
  {
    label: "Cancelled by facility",
    value: 6,
  },
  {
    label: "Completed",
    value: 7,
  },
];
const statusList = [
  {
    label: "Active",
    value: "0",
  },
  {
    label: "In-active",
    value: "1",
  },
];
const enqStatusList = [
  {
    label: "Pending",
    value: "0",
  },
  {
    label: "Closed",
    value: "1",
  },
];

const massActionList = [
  {
    label: "Assign caregiver",
    value: 1,
  },
  {
    label: "Cancelled by caregiver",
    value: 2,
  },
  {
    label: "Cancelled by facility",
    value: 3,
  },
  {
    label: "Delete",
    value: 4,
  },
];
const massActionListCaregiver = [
  {
    label: "Approve",
    value: 1,
  },
  {
    label: "Reject",
    value: 4,
  },
  {
    label: "Cancelled by caregiver",
    value: 2,
  },
  {
    label: "Cancelled by facility",
    value: 3,
  },
];

const jobsCompany = [
  { label: 'Google Search', value: 'Google Search' },
  { label: 'Facebook', value: 'Facebook' },
  { label: 'Instagram', value: 'Instagram' },
  { label: 'LinkedIn', value: 'LinkedIn' },
  { label: 'Twitter/X', value: 'Twitter/X' },
  { label: 'Friend/Referral', value: 'Friend/Referral' },
  { label: 'Advertisement', value: 'Advertisement' },
  { label: 'Newsletter', value: 'Newsletter' },
  { label: 'Other', value: 'Other' }
];

const onCopySlot = async (slot) => {
  try {
    if (!document.hasFocus()) {
      window.focus();
    }
    navigator?.clipboard
      ?.writeText(HOME_URL + `available-shifts?ref_id=${slot?.id}`)
      .then(() => {
        toast?.success("Shift URL Copied");
      });
  } catch (error) {
    console.log("Failed to copy to clipboard: ", error);
  }
};

function determineDiscipline(speciality_name, profession, degreeList) {
  return speciality_name
    ? speciality_name
    : degreeList?.find((deg) => deg.Id == profession)?.Name;
}
function checkProgress(step) {
  if (step === 1) {
    return <span className="text-info">Just started, keep going!</span>;
  } else if (step >= 2 && step < 70) {
    return <span className="text-warning">Making progress, keep it up!</span>;
  } else if (step >= 71 && step <= 100) {
    return <span className="text-warning">Getting closer, almost there!</span>;
  } else if (step < 105) {
    return (
      <span className="text-success">
        Getting started almost complete, submit now!
      </span>
    );
  } else if (step === 200) {
    return <span className="text-success">Completed</span>;
  } else {
    return <span style={{ color: "red" }}>Invalid progress value!</span>;
  }
}

function formatDuration(totalMinutes) {
  return moment
    ?.utc(totalMinutes * 60000)
    ?.format(
      (moment.duration(totalMinutes * 60000).days() > 0 ? "D [Day] " : "") +
        (moment.duration(totalMinutes * 60000).hours() > 0 ||
        moment.duration(totalMinutes * 60000).days() > 0
          ? "H [Hr] "
          : "") +
        "m [Min]"
    );
}
function getProfessionKeyList(prof) {
  if (prof?.length !== 0) {
    const uniqueProfessions = prof?.reduce((accumulator, item, index) => {
      const professionName = item?.profession_name.split(" - ")[0];
      if (!accumulator?.includes(professionName)) {
        accumulator += (index === 0 ? "" : ", ") + professionName;
      }
      return accumulator;
    }, "");
    return uniqueProfessions;
  } else {
    return true;
  }
}
export {
  selectedKeysOne,
  selectedKeysTwo,
  selectedKeysThree,
  selectedKeysFour,
  selectedKeysFive,
  selectedKeysSix,
  industriesList,
  shiftTypes,
  contractTypes,
  specalitiesList,
  contractLengthList,
  enquiryType,
  statusList,
  enqStatusList,
  slotStatusList,
  slotStatusListClient,
  slotStatusListCaregiver,
  massActionList,
  massActionListCaregiver,
  documentFields,
  onCopySlot,
  determineDiscipline,
  checkProgress,
  contactQuery,
  abbreviatedDays,
  formatDuration,
  getProfessionKeyList,
  jobType,
  jobsCompany
};
