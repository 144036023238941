import { observer } from 'mobx-react-lite';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';

const GuestTabs = [{
    route: "/",
    icon: "fas fa-home",
    label: "Home"
},
{
    route: "/staffs",
    icon: "fas fa-search",
    label: "Staff"
},
{
    route: "/jobs",
    icon: "fas fa-search",
    label: "Job"
},
{
    route: "/login/employee",
    icon: "fas fa-user-circle",
    label: "Login"
}]
const EmployerTabs = [{
    route: "/",
    icon: "fas fa-home",
    label: "Home"
},
{
    route: "/staffs",
    icon: "fas fa-search",
    label: "Caregivers"
},
{
    route: "/shift-request-list",
    icon: "fas fa-search",
    label: "Order Requests"
},
{
    route: "/edit-profile",
    icon: "fas fa-user-circle",
    label: "My Account"
}]
const CaregiverTabs = [{
    route: "/",
    icon: "fas fa-home",
    label: "Home"
},
{
    route: "/available-shifts",
    icon: "fas fa-search",
    label: "Pickup Shift"
},
{
    route: "/applied-shifts",
    icon: "far fa-calendar-alt",
    label: "My Shift"
},
{
    route: "/my-job-profile",
    icon: "fas fa-user-circle",
    label: "Job Profile"
}]

const BottomTab = observer(() => {
    const [isAuthenticated, setAuthenticated] = useState(null);
    const [role, setRole] = useState();
    const [tabs, setTabs] = useState();
    useEffect(() => {
        let token = secureLocalStorage.getItem("token");
        setAuthenticated(token);
        let userType = secureLocalStorage.getItem("type");
        setRole(userType);
    }, []);
    useEffect(() => {
        if (isAuthenticated) {
            if (role == 'false') {
                setTabs(EmployerTabs)
            }
            if (role == 'true') {
                setTabs(CaregiverTabs)
            }
        } else {
            setTabs(GuestTabs)
        }

    }, [role, isAuthenticated]);




    return (
        <div>

            <nav className="navbar-bottom fixed-bottom navbar-light d-block d-lg-none bottom-tab-nav" role="navigation">
                <ul className="nav w-100">
                    <div className="d-flex flex-row justify-content-around w-100">
                        {
                            tabs?.map((tab, index) => (
                                <li key={`tab-${index}`} className="nav-item">
                                    <NavLink to={tab.route} className="nav-link bottom-nav-link">
                                        <div className="row d-flex flex-column justify-content-center align-items-center text-center">
                                            <i className={tab.icon}></i>
                                            <div className="bottom-tab-label">{tab.label}</div>
                                        </div>
                                    </NavLink>
                                </li>
                            ))
                        }
                    </div>
                </ul>
            </nav>
        </div>
    )
});

export default BottomTab;