/* eslint-disable */
import React from "react";
import { observer } from "mobx-react"
import { Images } from "@elements";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { BASE_API_URL_INT } from "@api/config";
import secureLocalStorage from "react-secure-storage";

const SocialLogin = observer(({ userRole }) => {

    const gmailLogin = () => {
        secureLocalStorage.setItem('type', userRole)
        if (userRole === 'false') {
            window.open(`${BASE_API_URL_INT}user/loginSocial/google`, '_self')
        } else {
            window.open(`${BASE_API_URL_INT}staff/loginSocial/google`, '_self')
        }
    }
    const facebookLogin = () => {
        secureLocalStorage.setItem('type', userRole)
        if (userRole === 'false') {
            window.open(`${BASE_API_URL_INT}user/loginSocial/facebook`, '_self')
        } else {
            window.open(`${BASE_API_URL_INT}staff/loginSocial/facebook`, '_self')
        }
    }
    const linkedinLogin = () => {
        secureLocalStorage.setItem('type', userRole)
        if (userRole === 'false') {
            window.open(`${BASE_API_URL_INT}user/loginSocial/linkedin`, '_self')
        } else {
            window.open(`${BASE_API_URL_INT}staff/loginSocial/linkedin`, '_self')
        }
    }
    return (
        <div className="row pt-4 justify-content-center">
            <div className="d-flex justify-content-center">
                <h5 className="text-center" style={{ fontWeight: "bold" }}>Connect with</h5>
            </div>
            <div className="col-sm ps-0" onClick={() => gmailLogin()}>
                <a className="social-button" id="google-connect">
                    <span>
                        <small className="text"><img className="img" src={Images.google} /> Google</small>
                    </span>
                </a>
            </div>
            <div className="col-sm ps-0" onClick={() => facebookLogin()}>
                <a href="#" className="social-button " id="facebook-connect">
                    <span>
                        <small className="text"><img className="img" src={Images.fb} /> Facebook</small>
                    </span>
                </a>
            </div>


            <div className="col-sm ps-0"
            onClick={() => linkedinLogin()}
            >
                <a href="#" className="social-button " id="linkedin-connect">
                    <span>
                        <small className="text">
                            <img className="img" src={Images.linkedin} /> Linkedin
                        </small>
                    </span>
                </a>
            </div>
        </div>
    );
});

export default SocialLogin;
