import React from "react";
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import './staffsheetcomponent.css'
import parse from 'html-react-parser';
import StaffsStore from "@store/StaffsStore";
import moment from "moment";
var states = require('@assets/states.json');

const StaffSheetComponent = ({ isOpen, onDismiss, onOpenForm, data }) => (
    <>
        <BottomSheet open={isOpen} onDismiss={onDismiss} scrollLocking={false}
            header={
                <>
                    <div className="text-end" style={{ cursor: "pointer" }} onClick={onDismiss}>
                        <i className="fa fa-times" aria-hidden="true"></i>
                    </div>
                    <h4 className="flex items-center text-xl justify-center font-bold text-primary-800">
                        {data?.first_name.toUpperCase()}
                    </h4>
                </>
            }

            footer={
                <div className="text-end">
                    <button onClick={onOpenForm} className="btn btn-primary">
                        Submit request to select caregiver
                    </button>
                </div>
            }>
            <div style={{ height: global.config.windowHeight / 2.5, overflowY: 'scroll' }}>
                <div className="col-xl-12 col-md-12">
                    <div className="user-card-full">
                        <div className="row m-l-0 m-r-0">
                            <div className="col-sm-12">
                                <div className="card-block">
                                    <h6 className="m-b-20 p-b-5 f-w-600"> Caregiver ID (Bluesky ID): {data?.staff_profile?.bluesky_staff_id} </h6>
                                    <h6 className="m-b-20 p-b-5 b-b-default f-w-600">Caregiver Details</h6>
                                    <div className="row">
                                        {data && data?.degree_specialities && <div className="col-sm-4">
                                            <p className="m-b-10 f-w-600">Profession - Specialities</p>
                                            <h6 className="text-muted f-w-400">{JSON.parse(data?.degree_specialities)?.map((pro, i) => { return <div key={i + 'prop'}>{pro.profession_name || ''} {pro.specialties_name && <>➤ {pro.specialties_name || '-'}</>} < br /></div> })}</h6>                                        </div>}
                                        {/* {data && data?.degree_specialities && <div className="col-sm-3">
                                            <p className="m-b-10 f-w-600"></p>
                                            <h6 className="text-muted f-w-400">{JSON.parse(data?.degree_specialities)?.map((spec, i) => { return <div key={i + 'special'}>{spec.specialties_name || '-'}< br /></div> })}</h6>
                                        </div>} */}
                                        {data && data?.available_date && <div className="col-sm-3">
                                            <p className="m-b-10 f-w-600">Availability Date</p>
                                            <h6 className="text-muted f-w-400">{moment(data?.available_date).format('MMMM DD, YYYY')}</h6>
                                        </div>}
                                        {data && data?.region_id && <div className="col-sm-2">
                                            <p className="m-b-10 f-w-600">Region</p>
                                            <h6 className="text-muted f-w-400">{StaffsStore?.data?.regionsList?.find(x => x.ID == data?.region_id)?.Name}</h6>
                                        </div>}
                                        {data && data?.category && <div className="col-sm-2">
                                            <p className="m-b-10 f-w-600">Category</p>
                                            <h6 className="text-muted f-w-400">{data?.category}</h6>
                                        </div>}
                                        {data && data.city && <div className="col-sm-3">
                                            <p className="m-b-10 f-w-600">City</p>
                                            <h6 className="text-muted f-w-400">{data.city}</h6>
                                        </div>}
                                        {data && data.state_id && <div className="col-sm-3">
                                            <p className="m-b-10 f-w-600">State</p>
                                            <h6 className="text-muted f-w-400">{data.state_id}</h6>
                                        </div>}
                                        {data && data.preferred_state && <div className="col-sm-3">
                                            <p className="m-b-10 f-w-600">Preferred State</p>
                                            <h6 className="text-muted f-w-400">{JSON?.parse(data?.preferred_state).map((state, index) => {
                                                const stateName = states?.find(stat => stat?.abbreviation === state)?.name;
                                                return stateName + (index === JSON?.parse(data?.preferred_state).length - 1 ? "" : ", ");
                                            })}</h6>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BottomSheet>
    </>
);
export default StaffSheetComponent;