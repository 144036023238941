/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { CButton, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CTable, CTableHead, CTableRow, CTableHeaderCell, CTableBody, CTableDataCell, CModalBody, CFormCheck } from "@coreui/react";
import 'react-spring-bottom-sheet/dist/style.css';
import HomeStore from "@store/HomeStore";
import { observer } from "mobx-react-lite";
import { toJS } from 'mobx';
import Spinner from "@components/Spinner";
import moment from 'moment';
import swal from '@sweetalert/with-react';
import { useMediaQuery } from 'react-responsive';
import AdminStore from "@store/AdminStore";
import HeaderPage from "../layout/HeaderPage";
import DrawerMenuPage from "../layout/DrawerMenuPage";
import ReactDOM from "react-dom";
import CustomModal from '@components/modals/CustomModal';
import { Formik } from 'formik';
import SelectField from "@elements/select/SelectField";
import DatePicker from "react-multi-date-picker"
import { timeValidationSchema } from '@utils/schemas/allSchema';
import { CModal, CModalHeader, CModalTitle } from "@coreui/react";
import Select, { components } from 'react-select';
import { colourStylesTitle } from '@utils/customStyle/style';
import ProfileView from '../../../components/profile-view/ProfileView';
import { onCopySlot, massActionList } from '@utils/constant/constant';
import ToolbarDropdown from "@elements/select/ToolbarDropdown";
import { toast } from 'react-toastify';
import { dropdownStatusStyles } from '@utils/customStyle/style';
import { onChangeSlotStatus, Control } from "../commonFunctions"

var states = require('@assets/states.json');
const ShiftByFacility = observer(() => {
    const [activeTab, setActiveTab] = useState('tab1');
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const tableRef = useRef()
    const tableRefSlot = useRef()
    const formikRef = useRef(null);
    const tableName = "table1"
    const tableNameSlot = "table2"
    const [degree, setDegree] = useState([]);
    const [staffingRequestList, setStaffingRequestList] = useState([]);
    const [staffingRequestContractList, setStaffingRequestContractList] = useState([]);
    const [editSlotEditModal, setShowEditModal] = useState(false);
    const [editSlotData, setEditSlotData] = useState();
    const [facilityData, setClientData] = useState();
    const [staffByProfession, setStaffByProfession] = useState([]);
    const [assignTime, setAssignTime] = useState();
    const [slotData, setSlotData] = useState();
    const [openNewCaregiver, setOpenNewCaregiver] = useState(false);
    const [signleData, setSingleData] = useState([]);
    const [assignToStaff, setAssignToStaff] = useState(null);
    const [paginationLength, setPaginationLength] = useState(100);
    const [selectedAction, setSelectedAction] = useState(null);
    const [dummySlots, setDummySlotList] = useState([]);
    const [selectedSlots, setSelectedSlots] = useState([]);
    const [selectedProfession, setSelectedProfession] = useState();
    const [massAssign, setMassAssign] = useState(false);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };
    useEffect(() => {
        const encodedData = new URLSearchParams(window.location.search);
        try {
            const data = JSON.parse(decodeURIComponent(encodedData.get("data")));
            AdminStore.getShiftsByFacility(data?.id);
            setClientData(data);
        } catch (error) {
            console.error('Error decoding JSON data:', error);
        }
    }, []);

    useEffect(() => {
        setDegree(HomeStore?.data?.degreeList)
    }, [HomeStore?.data?.degreeList]);

    useEffect(() => {
        let assignmentDataWithId = toJS(AdminStore?.data?.shiftByFacility)?.flatMap(item =>
            item.assignments.map(assignment => ({ ...assignment, shift_data: item }))
        );
        const sortedSlots = assignmentDataWithId?.sort((a, b) => Math.abs(a.slot_date - new Date()) - Math.abs(b.slot_date - new Date()));
        setStaffingRequestList(sortedSlots)
        setDummySlotList(sortedSlots)
        setStaffingRequestContractList(toJS(AdminStore?.data?.shiftByFacility)?.filter(x => x.category === 'TC'))
    }, [AdminStore?.data?.shiftByFacility]);

    useEffect(() => {
        setStaffByProfession(toJS(AdminStore?.data?.staffListByProfession)?.filter(x => x.staffer_id && x.external_id && x.status === 3))
    }, [AdminStore?.data?.staffListByProfession]);

    // CONTRACT WISE
    useEffect(() => {
        $.fn.dataTable.ext.errMode = 'none';
        $($.fn.dataTable.tables(true)).DataTable()
            .columns.adjust();
        const table = $(`#${tableName}`).DataTable(
            {
                data: staffingRequestContractList,
                order: [], // Disable initial sorting
                searching: true,
                responsive: true,
                lengthMenu: [100, 500, 1000],
                dom: 'Blfrtip',
                buttons: [
                    'copy', 'csv', 'excel', 'print',
                ],
                columns: [
                    {
                        title: "Ref.", data: 'id',
                        width: 60,
                        render: function (data, type, full, meta) {
                            return data;
                        }
                    },
                    {
                        title: "Shift Date/Time", data: "assignments", "render": function (data, type, full, meta) {
                            const dates = data?.map(item => new Date(item?.slot_date));
                            // // Find the minimum and maximum dates
                            const minDate = new Date(Math?.min.apply(null, dates));
                            const maxDate = new Date(Math?.max.apply(null, dates));
                            // // Function to pad a number with leading zeros
                            const zeroPad = (value, length) => {
                                return value.toString().padStart(length, "0");
                            };
                            // // Format the dates as strings with zero-padded month and day values
                            const minDateString = `${zeroPad(minDate.getMonth() + 1, 2)}/${zeroPad(minDate.getDate(), 2)}/${minDate.getFullYear()}`;
                            const maxDateString = `${zeroPad(maxDate.getMonth() + 1, 2)}/${zeroPad(maxDate.getDate(), 2)}/${maxDate.getFullYear()}`;

                            return data?.length !== 0 ? '<strong><span class="pointer link-info">' + minDateString + " To " + maxDateString + '</span></strong>' :
                                full?.category === 'TC' ? '<strong><span class="pointer link-info">' + moment(full.start_date, 'YYYY-MM-DD').format('MM/DD/YYYY') + " To " + moment(full.end_date, 'YYYY-MM-DD').format('MM/DD/YYYY') + '</span></strong>' :
                                    "No Shift Available";
                        }
                    },
                    {
                        title: "Contract Details", "render": function (data, type, full, meta) {
                            return `
                        <div style="text-align: left; border: 1px solid #ccc; padding: 2px; display: grid; grid-template-columns: max-content auto;">
                            <strong style="border: 1px solid #ccc; padding: 2px;">Shift Type:</strong> 
                            <span style="border: 1px solid #ccc; padding: 2px;">${full?.shift_type}</span>
                            
                            <strong style="border: 1px solid #ccc; padding: 2px;">No Of Weeks:</strong> 
                            <span style="border: 1px solid #ccc; padding: 2px;">${full?.no_of_weeks}</span>
                            
                            <strong style="border: 1px solid #ccc; padding: 2px;">Hours/Week:</strong> 
                            <span style="border: 1px solid #ccc; padding: 2px;">${full?.hours_per_week}</span>
                            
                            <strong style="border: 1px solid #ccc; padding: 2px;">Pay Details:</strong> 
                            <span style="border: 1px solid #ccc; padding: 2px;">${full?.pay_details}</span>
                            
                            <strong style="border: 1px solid #ccc; padding: 2px;">Qualification:</strong> 
                            <span style="border: 1px solid #ccc; padding: 2px;">${full?.qualifications}</span>
                        </div>
                    `;
                        }
                    },
                    {
                        title: "Caregiver Name", data: "staff", "render": function (data, type, full, meta) {
                            return data && ('<span class="text-success">' + (data?.first_name + " " + data?.last_name) + "</span>") || "No Assignment";
                        }
                    },
                    {
                        title: "Profession-Specialty", data: "profession", "render": function (data, type, full, meta) {
                            return full?.specialty_name ? full?.specialty_name : degree?.find(pro => pro.Id == data)?.Name;
                        }
                    },
                    {
                        title: "Category", width: 100, data: 'category', "render": function (data, type, full, meta) {
                            return global.config.shiftCaregory?.find(x => x.value === data)?.label
                        }
                    },
                    {
                        title: "State", width: 100, data: "state"
                    },
                    {
                        title: "City", width: 100, data: "city"
                    },
                    {
                        title: "Extention Times", width: 10, data: "extentions"
                    },
                    {
                        title: "Status", data: "status"
                    },
                    {
                        title: "Manage", data: ""
                    },
                ],
                columnDefs: [
                    {
                        targets: 1,
                        responsivePriority: 1,
                        createdCell: function (td, cellData, rowData, row, col) {
                            var listHtml = "";
                            cellData.forEach(function (data) {
                                listHtml += "" + data?.slot_date + " "
                                    + moment(data?.slot_start_time, "HH:mm").format("hh:mm A") + " To "
                                    + moment(data?.slot_end_time, "HH:mm").format("hh:mm A") + " "
                                    + (data.status === 2 ? "Filled"
                                        : data.status === 3 ? "Rejected"
                                            : data.status === 4 ? "Extention Pending"
                                                : data.status === 5 ? "Cancelled By Caregiver"
                                                    : data.status === 6 ? "Cancelled By Facility"
                                                        : data.status === 7 ? "Completed" : "Unfilled") + "\n";
                            });
                            listHtml += "";
                            $(td).attr('data-toggle', 'tooltip');
                            $(td).attr('data-html', 'true');
                            $(td).attr('data-placement', 'top');
                            $(td).attr('title', listHtml);
                        },
                    },
                    {
                        targets: 9,
                        responsivePriority: 3,
                        createdCell: (td, cellData, rowData, row, col) =>
                            ReactDOM.render(
                                <div>
                                    <span>
                                        {
                                            rowData?.status == 0 ? <span className={'text-warning'}>Pending</span> :
                                                (rowData?.status == 1 && rowData?.staff_id && rowData?.staff_response == 0) || (rowData?.shift_data?.category === 'TC' && rowData?.shift_data?.staff_response === 0 && rowData?.shift_data?.status === 1) ? <span className={'text-warning'}><strong>Pending <br /> By Caregiver</strong></span> :
                                                    rowData?.extention_request
                                                        ? <span className={'text-info'}><b>Requested</b></span>
                                                        : ((rowData?.assignments?.filter(obj => obj.status === 1 && obj.staff_id)?.length > 0)
                                                            || (rowData?.category === 'TC' && rowData?.status === 1 && rowData?.staff_id && rowData?.staff_response === 1)) ? <strong><span className="text-info">Waiting For Approval!</span></strong> : (rowData?.category === 'TC' && rowData?.status === 1 && rowData?.staff_response === 0) ? <span className={'text-warning'}>Pending</span> :
                                                            rowData?.status == 2 ? <span className={'text-danger'}>Rejected</span>
                                                                : rowData?.status == 3 ? <span className={'text-dark'}>Closed</span>
                                                                    : (rowData?.assignments?.length === 0 && rowData?.category !== 'TC') ? <span className={'text-dark'}>No Shift Available</span>
                                                                        : rowData?.assignments?.some(item => item.status === 4) ? <span className={'text-warning'}>Extention Pending</span>
                                                                            : (rowData?.assignments?.length > 0 && rowData?.assignments?.every(item => item.status === 2 && item?.staff_id)) || (rowData?.category === 'TC' && rowData?.status === 5 && rowData?.staff_id && rowData?.staff_response === 1) ? <span className={'text-success'}><b>Filled</b></span>
                                                                                : (rowData?.assignments?.some(item => item.status === 2 && item?.staff_id))
                                                                                    ? <span className={'text-warning'}><b>Partially Filled</b></span>
                                                                                    : rowData?.status == 1 ? <span className={'text-danger'}>Unfilled</span>
                                                                                        : rowData?.status == 4 ? <span className={'text-success'}>Extended</span> : ""}

                                    </span>
                                </div>
                                , td),
                    },
                    {

                        targets: 10,
                        responsivePriority: 2,
                    }


                ],
                destroy: true,  // I think some clean up is happening here
                "language": {
                    search: '',
                    searchPlaceholder: "Search",
                    emptyTable: "No Record Found"
                },

                initComplete: function () {
                    // Apply the search
                    this.api()
                        .columns([0, 2, 3, 4, 7, 8])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><input class="form-control " style="width:100%;margin-top:5px" type="text" placeholder="Filter" />')
                                .appendTo($(column.header()))

                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $('input', this.header()).on('keyup change clear', function () {
                                if (column.search() !== this.value) {
                                    column.search(this.value).draw();
                                }
                            });
                        });
                    this.api()
                        .columns([1])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><div class="input-daterange input-group" style="width:250px;margin-top:5px"></div>')
                                .appendTo($(column.header()));

                            var startDateInput = $('<input type="text" class="form-control" placeholder="Start Date" style="border-top-left-radius:8px;border-bottom-left-radius:8px;"/>');
                            var endDateInput = $('<input type="text" class="form-control" placeholder="End Date"  style="border-top-right-radius:8px;border-bottom-right-radius:8px;"/>');

                            startDateInput.appendTo(select);
                            endDateInput.appendTo(select);

                            $('.input-daterange').datepicker({
                                format: 'mm-dd-yyyy', // Specify your desired date format here
                                // autoclose: true,
                                todayHighlight: true, // Highlight today's date
                                clearBtn: true, // Show the "Clear" button
                                orientation: 'bottom',
                                daysOfWeekHighlighted: '0,6', // Highlight weekends (0: Sunday, 6: Saturday)
                                templates: {
                                    leftArrow: '<i class="fas fa-chevron-left"></i>', // Customize the left arrow icon
                                    rightArrow: '<i class="fas fa-chevron-right"></i>', // Customize the right arrow icon
                                },
                            });

                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $('input', this.header()).on('keyup change clear', function () {
                                if (!this.value) {
                                    $(`#${tableName}`).DataTable().column([1]).search('').draw();
                                }
                                if (column.search() !== this.value) {
                                    column.search(moment(this.value).format('MM/DD/YYYY')).draw();
                                }
                            });
                        });

                    this.api().columns([5]).every(function () {//Category Filter
                        var column = this;
                        var select = $('<br/><select class="form-control" placeholder="search" style="width:100%;margin-top:5px;background-color:white""><option value="">All</option></select>')
                            .appendTo($(column.header()))
                            .on('change', function () {
                                var val = $.fn.dataTable.util.escapeRegex($(this).val());
                                column.search(val ? '^' + val + '$' : '', true, false).draw();
                            });

                        $(select).click(function (e) {
                            e.stopPropagation();
                        });

                        column
                            .data()
                            .unique()
                            .sort()
                            .each(function (d, j) {
                                if (select.find('option[value="' + (global.config.shiftCaregory.find(x => x.value == d))?.label + '"]').length === 0) {
                                    select.append('<option value="' + (global.config.shiftCaregory.find(x => x.value == d)?.label) + '">' + (global.config.shiftCaregory.find(x => x.value == d)?.label) + '</option>');
                                }
                            });
                    });
                    this.api()//STATE FILTER
                        .columns([6])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><select class="form-control" placeholder="search" style="width:100%;margin-top:5px;background-color:white""><option value="" selected>All</option></select>')
                                .appendTo($(column.header()))
                                .on('change', function () {
                                    var val = $.fn.dataTable.util.escapeRegex($(this).val());
                                    column.search(val ? '^' + val + '$' : '', true, false).draw();
                                });
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            column
                                .data()
                                .unique()
                                .sort()
                                .each(function (d, j) {
                                    let name = states?.find(stat => stat?.abbreviation == d);
                                    select.append('<option value="' + name?.abbreviation + '">' + name?.name + '</option>');
                                });
                        });
                    this.api()
                        .columns([9])//STATUS FILTER
                        .every(function () {
                            var column = this;
                            var select = $('<br/><select class="form-control" placeholder="search" style="width:100%;margin-top:5px;background-color:white""><option value="">All</option></select>')
                                .appendTo($(column.header()))
                                .on('change', function () {
                                    var val = $.fn.dataTable.util.escapeRegex($(this).val());
                                    column.search(val ? '^' + val + '$' : '', true, false).draw();
                                });
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            [{ "name": "Pending", "value": 0 }, { "name": "Unfilled", "value": 1 }, { "name": "Rejected", "value": 2 }, { "name": "Extention Pending", "value": 4 }, { "name": "Cancelled By You", "value": 5 }, { "name": "Cancelled By Facility", "value": 6 }, { "name": "Closed", "value": 3 }].forEach(function (option) {
                                var selected = '';
                                select.append('<option value="' + option.value + '" ' + selected + '>' + option?.name + '</option>');
                            });
                        });
                },
            }
        )
        // table.column(1).visible(false);
        table.on('click', 'td', function () {
            // Get the column index of the clicked cell
            const columnIndex = table.cell(this).index()?.column;
            // Check if the clicked cell belongs to the desired column
            if (columnIndex === 1) {
                // Get the data for the clicked cell
                const rowData = table.row($(this).closest('tr')).data();
                // Handle the event for the clicked cell
                if (rowData) {
                    showDetails(rowData)
                }
            }
        });

        table.column(10).visible(false);

        // Extra step to do extra clean-up.
        return function () {
            table.destroy()
        }
    }, [staffingRequestContractList, degree])

    // SLOT WISE
    useEffect(() => {
        $.fn.dataTable.ext.errMode = 'none';
        $($.fn.dataTable.tables(true)).DataTable()
            .columns.adjust();

        const tableSlot = $(`#${tableNameSlot}`).DataTable(
            {
                data: staffingRequestList,
                pageLength: paginationLength,
                lengthMenu: [100, 500, 1000, 2000, 3000, 5000],
                dom: 'Blfrtip',
                buttons: [
                    'copy', 'csv', 'excel', 'print',
                ],
                columns: [
                    {
                        title: "Order Ref.", data: 'shift_id',
                        width: 60,
                        render: function (data, type, full, meta) {
                            return data;
                        }
                    },
                    {
                        data: "id",
                        orderable: false
                    },
                    {
                        title: "Shift Date", data: 'slot_date', "render": function (data, type, row, meta) {
                            return '<strong><a class="link-info pointer">' + data + '</a></strong>'
                        }
                    },
                    {
                        title: "Time", "render": function (data, type, row, meta) {
                            return '<strong><a class="link-info pointer">' + moment(row?.slot_start_time, "HH:mm").format("hh:mm A") + " To " + moment(row?.slot_end_time, "HH:mm").format("hh:mm A") + '</a></strong>'
                        }
                    },
                    {
                        title: "Shift Type", "render": function (data, type, row, meta) {
                            return row?.slot_type + " & " + global.config.shiftCaregory?.find(x => x.value === row?.shift_data?.category)?.label;
                        }
                    },
                    {
                        title: "Facility User", data: "shift_data", "render": function (data, type, full, meta) {
                            return data?.user?.first_name +" "+data?.user?.last_name|| "-";
                        }

                    },
                    {
                        title: "Assigned Caregiver", data: "staff", "render": function (data, type, full, meta) {
                            return data?.first_name ? '<strong><a class="link-info pointer">' + data?.first_name + " " + data?.last_name + '</a></strong>' : "No Assignment";
                        }

                    },
                    {
                        title: "Profession-Specialty", data: "shift_data", "render": function (data, type, full, meta) {
                            return data?.specialty_name ? data?.specialty_name : degree?.find(pro => pro.Id == data?.profession)?.Name;
                        }
                    },
                    {
                        title: "State", data: 'shift_data', "render": function (data, type, row, meta) {
                            return data?.state || "-";
                        }
                    },
                    {
                        title: "City", data: 'shift_data', "render": function (data, type, row, meta) {
                            return data?.city || "-";
                        }
                    },
                    {
                        title: "Shift Status", data: "status"
                    },
                    {
                        title: "Comment For Caregiver", data: "remark"
                    },
                    {
                        title: "Creation Time", data: "created_at", "render": function (data, type, row, meta) {
                            return moment(data).format('MM/DD/yy hh:mm A') || "-";
                        }
                    },
                    {
                        title: "Manage", data: ""
                    },
                    {
                        title: "Exported", data: "is_exported", "render": function (data, type, row, meta) {
                            return data === 1 ? "Yes" : "No";
                        }
                    },
                ],
                columnDefs: [

                    {
                        targets: 1,
                        responsivePriority: 1,
                        title: `
                        <label style="font-size: 0.875rem;"><b>Select All</b></label><br/>
                        <input type="checkbox"id="select-all-checkbox" class="select-all-checkbox">                     
                 
                    `,
                        render: function (data, type, row, meta) {
                            // Render a checkbox for each row
                            return `<input type="checkbox" class="row-select" data-id="${data}"/>`;
                        }
                    },
                    {
                        targets: 2,
                        responsivePriority: 2,
                    },
                    {
                        targets: 4,
                        responsivePriority: 3,
                    },
                    {
                        targets: 13,
                        responsivePriority: 4,
                        createdCell: (td, cellData, rowData, row, col) =>
                            ReactDOM.render(
                                <div>
                                    <CDropdown variant="btn-group" direction='dropstart'>
                                        <CDropdownToggle size="sm" color="transparent" caret={false} href="#" className="p-0"><i className="fa fa-ellipsis-v text-primary"></i></CDropdownToggle>
                                        <CDropdownMenu>
                                            {rowData?.status === 1 && <CDropdownItem>
                                                <CButton onClick={() => {
                                                    onCopySlot(rowData)
                                                }} size="sm" className="w-100" variant='outline' color="info" >
                                                    Copy Shift URL <i className="far fa-copy "></i>
                                                </CButton>
                                            </CDropdownItem>}
                                            {/* {((rowData?.status !== 5 && rowData?.status !== 6 && rowData?.status !== 7) && rowData?.is_exported === 0) && <CDropdownItem>
                                                <CButton onClick={() => {
                                                    assignNewCaregiver(rowData)
                                                }} size="sm" className="w-100" variant='outline' color="primary" >
                                                    Assign Caregiver <i className="fas fa-user-plus"></i>
                                                </CButton>
                                            </CDropdownItem>} */}
                                            {rowData?.status === 1 && <CDropdownItem>
                                                <CButton onClick={() => {
                                                    onEditSlot(rowData)
                                                }} size="sm" className="w-100" variant='outline' color="primary" >
                                                    Edit Shift <i className="far fa-edit"></i>
                                                </CButton>
                                            </CDropdownItem>}
                                            {/* {(rowData?.shift_data?.staff_response === 1 && rowData?.shift_data?.staff_id) || (rowData?.status === 1 && rowData?.staff_id) && <CDropdownItem>
                                                <CButton onClick={() => {
                                                    onChangeSlotStatus(rowData, 'approve', refreshList)
                                                }} size="sm" className="w-100" variant='outline' color="success" >
                                                    Approve Shift <i className="fas fa-check"></i>
                                                </CButton>
                                            </CDropdownItem>
                                            } */}
                                            {/* {(rowData?.shift_data?.staff_response === 1 && rowData?.shift_data?.staff_id) || (rowData?.status === 1 && rowData?.staff_id) && <CDropdownItem>
                                                <CButton onClick={() => {
                                                    onChangeSlotStatus(rowData, 'reject-close', refreshList)
                                                }} size="sm" className="w-100" variant='outline' color="danger" >
                                                    Reject & Close Shift <i className="fas fa-times"></i>
                                                </CButton>
                                            </CDropdownItem>} */}
                                            {/* {(rowData?.shift_data?.staff_response === 1 && rowData?.shift_data?.staff_id) || (rowData?.status === 1 && rowData?.staff_id) && <CDropdownItem>
                                                <CButton onClick={() => {
                                                    onChangeSlotStatus(rowData, 'reject-open', refreshList)
                                                }} size="sm" className="w-100" variant='outline' color="danger" >
                                                    Reject & Open Shift <i className="fas fa-times"></i>
                                                </CButton>
                                            </CDropdownItem>} */}
                                            {/* {rowData?.status === 2 && <CDropdownItem>
                                                <CButton onClick={() => {
                                                    onChangeSlotStatus(rowData, 'cancel-facility', refreshList)
                                                }} size="sm" className="w-100" variant='outline' color="danger" >
                                                    Cancel By Facility <i className="fas fa-times"></i>
                                                </CButton>
                                            </CDropdownItem>} */}
                                            {/* {rowData?.status === 2 && <CDropdownItem>
                                                <CButton onClick={() => {
                                                    onChangeSlotStatus(rowData, 'cancel-caregiver', refreshList)
                                                }} size="sm" className="w-100" variant='outline' color="danger" >
                                                    Cancel By Caregiver <i className="fas fa-times"></i>
                                                </CButton>
                                            </CDropdownItem>} */}
                                            {/* {<CDropdownItem>
                                                <CButton onClick={() => {
                                                    onChangeSlotStatus(rowData, 'delete', refreshList)
                                                }} size="sm" className="w-100" variant='outline' color="danger" >
                                                    Delete Shift <i className="far fa-trash-alt"></i>
                                                </CButton>
                                            </CDropdownItem>} */}
                                        </CDropdownMenu>
                                    </CDropdown>
                                </div>
                                , td),
                    },
                    {
                        targets: 10,
                        responsivePriority: 5,
                        createdCell: (td, cellData, rowData, row, col) =>
                            ReactDOM.render(
                                <div>
                                    <span>
                                        {
                                            (rowData?.status == 1 && rowData?.staff_id && rowData?.shift_data?.staff_response === 0 && rowData?.shift_data?.staff_id) ? <strong> <span className={'text-warning'}>Pending
                                                By Caregiver </span></strong> : (rowData?.shift_data?.staff_response === 1 && rowData?.shift_data?.staff_id) || (rowData?.status === 1 && rowData?.staff_id) ? <strong> <span className={'text-info'}>Waiting For Approval!</span></strong> :
                                                rowData?.status == 1 ? <span className={'text-danger'}>Unfilled</span> :
                                                    rowData?.status == 2 ? <span className={'text-success'}>Confirmed</span> :
                                                        rowData?.status == 3 ? <span className={'text-danger'}>Rejected</span> :
                                                            rowData?.status == 4 ? <span className={'text-warning'}>Extention Pending</span> :
                                                                rowData?.status == 5 ? <span className={'text-danger'}>Cancelled by caregiver</span> :
                                                                    rowData?.status == 6 ? <span className={'text-danger'}>Cancelled by facility</span> :
                                                                        rowData?.status == 7 ? <span className={'text-muted'}>Closed</span> : ""}

                                    </span>
                                </div>
                                , td),
                    }
                ],
                destroy: true,  // I think some clean up is happening here
                searching: true,
                // scrollX: true,
                responsive: isMobile ? false : true,
                "language": {
                    search: '',
                    searchPlaceholder: "Search",
                    emptyTable: "No Record Found"
                },
                initComplete: function () {
                    // Apply the search
                    this.api()
                        .columns([0, 4, 6, 9, 11])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><input class="form-control " style="width:100%;margin-top:5px" type="text" placeholder="Filter" />')
                                .appendTo($(column.header()))

                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $('input', this.header()).on('keyup change clear', function () {
                                if (column.search() !== this.value) {
                                    column.search(this.value).draw();
                                }
                            });
                        });

                    this.api()
                        .columns([8])//State search
                        .every(function () {
                            var column = this;
                            var select = $('<br/><select class="form-control" placeholder="search" style="width:100%;margin-top:5px;background-color:white""><option value="" selected>All</option></select>')
                                .appendTo($(column.header()))
                                .on('change', function () {
                                    var val = $.fn.dataTable.util.escapeRegex($(this).val());
                                    column.search(val ? '^' + val + '$' : '', true, false).draw();
                                });
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });

                            // Create an array to store unique values
                            var uniqueValues = [];

                            column
                                .data()
                                .unique()
                                .sort()
                                .each(function (d, j) {
                                    let name = states?.find(stat => stat?.abbreviation == d?.state);

                                    // Check if the value is already in the uniqueValues array
                                    if (name && uniqueValues.indexOf(name.abbreviation) === -1) {
                                        uniqueValues.push(name.abbreviation);
                                        select.append('<option value="' + name.abbreviation + '">' + name.name + '</option>');
                                    }
                                });
                        });
                    this.api()
                        .columns([2])//Date
                        .every(function () {
                            var column = this;
                            var select = $('<br/><input type="date" class="form-control" style="width:100%;margin-top:5px" type="text" placeholder="" />')
                                .appendTo($(column.header()))
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $('input', this.header()).on('keyup change clear', function () {
                                if (!this.value) {
                                    $(`#${tableNameSlot}`).DataTable().column([1]).search('').draw();
                                }
                                if (column.search() !== this.value) {
                                    column.search(moment(this.value).format('MM/DD/YYYY')).draw();
                                }
                            });
                        });

                    this.api()
                        .columns([3])//Time
                        .every(function () {
                            var column = this;
                            var select = $('<br/><input type="time" class="form-control" style="width:100%;margin-top:5px" type="text" placeholder="" />')
                                .appendTo($(column.header()))

                            $(select).click(function (e) {
                                e.stopPropagation();
                            });

                            $('input', this.header()).on('keyup change clear', function () {
                                if (!moment(this.value, "HH:mm").format("hh:mm A")) {
                                    $(`#${tableNameSlot}`).DataTable().column([3]).search('').draw();
                                }
                                if (column.search() !== moment(this.value, "HH:mm").format("hh:mm A")) {
                                    column.search(moment(this.value, "HH:mm").format("hh:mm A")).draw();
                                }
                            });
                        });
                    //Specilaty + Profession Dropdown
                    this.api().columns([7]).every(function () {
                        $(select).click(function (e) {
                            e.stopPropagation();
                        });
                        var column = this;

                        // Create the dropdown with "All" as the default option
                        var select = $('<br/><select class="form-control" placeholder="filter" style="width:100%;margin-top:5px;background-color:white"><option value="" selected>All</option></select>')
                            .appendTo($(column.header()));

                        // Keep track of unique values
                        var uniqueValues = {};

                        // Populate the dropdown with unique values from column 5
                        column.data().each(function (d, j) {
                            // Get the HTML content of the cell
                            var cellText = column.cell(j, 7).node().innerHTML;
                            // Check if the value is unique before adding it to the dropdown
                            if (!uniqueValues[cellText]) {
                                uniqueValues[cellText] = true;
                                select.append('<option value="' + cellText + '">' + cellText + '</option>');
                            }
                        });

                        // Add change event listener to the dropdown
                        select.on('change', function () {
                            var val = $.fn.dataTable.util.escapeRegex(
                                $(this).val()
                            );

                            // Search and filter the table
                            column.search(val ? '^' + val + '$' : '', true, false).draw();
                        });

                    });
                    this.api()
                        .columns([10])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><select class="form-control" placeholder="search" style="width:100%;margin-top:5px;background-color:white""><option value="">All</option></select>')
                                .appendTo($(column.header()))
                                .on('change', function () {
                                    var val = $.fn.dataTable.util.escapeRegex($(this).val());
                                    column.search(val ? '^' + val + '$' : '', true, false).draw();
                                });
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            [{ "name": "Confirmed", "value": 2 }, { "name": "Unfilled", "value": 1 }, { "name": "Rejected", "value": 3 }, { "name": "Extention", "value": 4 }, { "name": "Cancelled by caregiver", "value": 5 }, { "name": "Cancelled by facility", "value": 6 }, { "name": "Closed", "value": 7 }].forEach(function (option) {
                                var selected = '';
                                select.append('<option value="' + option.value + '" ' + selected + '>' + option?.name + '</option>');
                            });
                        });
                    //Facility Dropdown
                    this.api().columns([5]).every(function () {
                        $(select).click(function (e) {
                            e.stopPropagation();
                        });
                        var column = this;

                        // Create the dropdown with "All" as the default option
                        var select = $('<br/><select class="form-control" placeholder="filter" style="width:100%;margin-top:5px;background-color:white"><option value="" selected>All</option></select>')
                            .appendTo($(column.header()));

                        // Keep track of unique values
                        var uniqueValues = {};

                        // Populate the dropdown with unique values from column 5
                        column.data().each(function (d, j) {
                            // Get the HTML content of the cell
                            var cellText = column.cell(j, 5).node().innerHTML;
                            // Check if the value is unique before adding it to the dropdown
                            if (!uniqueValues[cellText]) {
                                uniqueValues[cellText] = true;
                                select.append('<option value="' + cellText + '">' + cellText + '</option>');
                            }
                        });

                        // Add change event listener to the dropdown
                        select.on('change', function () {
                            var val = $.fn.dataTable.util.escapeRegex(
                                $(this).val()
                            );

                            // Search and filter the table
                            column.search(val ? '^' + val + '$' : '', true, false).draw();
                        });

                    });
                },
            }
        )
        tableSlot.on('page.dt', function () {
            // Clear all checkboxes and reset selection state
            $('.row-select').prop('checked', false);
            setSelectedSlots([]);
            $('.select-all-checkbox').prop('checked', false); // Uncheck 'Select All'
        });
        $(document).on('click', '.select-all-checkbox', function () {
            const isChecked = $(this).prop('checked');
            // Check/uncheck all checkboxes in the visible rows
            tableSlot.rows({ page: 'current' }).nodes().to$().find('.row-select').prop('checked', isChecked);
            // If "Select All" checkbox is checked, add all rows' data to the state constant
            if (isChecked) {
                setSelectedSlots(tableSlot.rows({ page: 'current' }).data().toArray());
            } else {
                setSelectedSlots([]); // Clear the state constant if "Select All" is unchecked
            }
        });

        // Handle individual row checkbox click
        $(document).on('click', '.row-select', function () {
            // If any row is unchecked, uncheck the "Select All" checkbox
            if (!$(this).prop('checked')) {
                $('.select-all-checkbox').prop('checked', false);
            }

            // Update selectedSlots based on the checked checkboxes
            const selectedRows = [];
            tableSlot.rows({ page: 'current' }).nodes().to$().find('.row-select:checked').each(function () {
                const rowData = tableSlot.row($(this).closest('tr')).data();
                selectedRows.push(rowData);
            });
            setSelectedSlots(selectedRows);
            // Check if all row checkboxes are checked
            const allChecked = tableSlot.rows({ page: 'current' }).nodes().to$().find('.row-select:checked').length === tableSlot.rows({ page: 'current' }).count();

            // Update the "Select All" checkbox status
            $('.select-all-checkbox').prop('checked', allChecked);
        });


        // Check the action value and enable/disable checkboxes accordingly
        if (selectedAction) {
            $('.row-select').prop('disabled', false); // Enable checkboxes
            $('.select-all-checkbox').prop('disabled', false);
        } else {
            $('.row-select').prop('disabled', true); // Disable checkboxes
            $('.select-all-checkbox').prop('disabled', true); // Disable checkboxes
        }
        tableSlot.on('click', 'td', function () {
            // Get the column index of the clicked cell
            const columnIndex = tableSlot.cell(this).index()?.column;
            // Check if the clicked cell belongs to the desired column
            if (columnIndex === 6) {
                // Get the data for the clicked cell
                const rowData = tableSlot.row($(this).closest('tr')).data();
                // Handle the event for the clicked cell
                if (rowData) {
                    showProfileDetails(rowData)
                }
            }
        });


        // tableSlot.column(11).visible(false);
        // Extra step to do extra clean-up.
        return function () {
            tableSlot.destroy()
        }
    }, [staffingRequestList, degree])


    const showDetails = (item) => {
        setRequestData(item)
        setIsOpen(true)
    }

    const refreshList = () => {
        setEditSlotData()
        setShowEditModal(false)
        setOpenNewCaregiver(false)
        setAssignTime()
        setSlotData()
        setSelectedProfession(null)
        setSelectedAction(null)
        AdminStore.getShiftsByFacility(facilityData?.id);
    }
    const onEditSlot = (data) => {
        if (formikRef.current) {
            formikRef.current.resetForm();
        }
        setEditSlotData(data)
        setShowEditModal(true)
    }
    const editSlotModalDismiss = () => {
        setShowEditModal(false)
        setEditSlotData()
    }
    //SLOT EDIT API CALL 
    const slotChanges = (slot) => {
        swal({
            title: "Do you really want to update this shift?",
            text: "After an update, You can't roll back an update.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    let newSlot = [{ "id": slot.slot_id, "staff_id": slot?.staff_id, "slot_status": slot?.status, "date": slot?.slot_date, "time": [slot?.slot_start_time, slot?.slot_end_time], "slot_type": slot?.shift_type }];
                    let param = {
                        request_id: slot?.shift_id,
                        slots: JSON.stringify(newSlot)
                    }
                    AdminStore.updateSlotData(param, refreshList)
                }
            });
    }
    const EditForm = () => {
        return <Formik
            innerRef={formikRef}
            validationSchema={timeValidationSchema}
            initialValues={{
                shift_id: editSlotData?.shift_id,
                staff_id: editSlotData?.staff_id,
                slot_id: editSlotData?.id,
                status: editSlotData?.status,
                shift_type: editSlotData?.slot_type || "",
                slot_date: editSlotData?.slot_date || "",
                slot_start_time: editSlotData?.slot_start_time || "",
                slot_end_time: editSlotData?.slot_end_time || "",
            }}
            onSubmit={values => slotChanges(values)}
            enableReinitialize
            validateOnChange={false}
        >
            {
                ({ handleChange, handleBlur, handleSubmit, setFieldValue, resetForm, values, errors, touched
                }) =>
                (
                    <form onSubmit={handleSubmit}>
                        <div className="row g-2">
                            <div className="col-sm-6">
                                <SelectField
                                    name="shift_type"
                                    label={"Shift Type"}
                                    onChange={(event) => {
                                        setFieldValue("shift_type", event.target.value)
                                        setFieldValue(`slot_start_time`, global.config.shiftTimeRange?.find(x => x.label === event.target.value)?.value?.start)
                                        setFieldValue(`slot_end_time`, global.config.shiftTimeRange?.find(x => x.label === event.target.value)?.value?.end)
                                    }}
                                    value={values?.shift_type}
                                    required
                                    data={global.config.shiftType}
                                    optionLabel={"name"}
                                    optionValue={"value"}
                                />
                                {errors.shift_type && touched.shift_type ? (
                                    <div className="error-message">{errors.shift_type}</div>
                                ) : null}
                            </div>
                            <div className="col-sm-6">
                                <DatePicker
                                    placeholder="Select Date"
                                    calendarPosition="top-left"
                                    required
                                    format="MM/DD/YYYY"
                                    inputClass="did-floating-dropdown"
                                    value={values?.slot_date}
                                    minDate={new Date()}
                                    onChange={dateObjects => {
                                        setFieldValue('date', dateObjects)
                                    }}
                                    multiple={false}
                                />
                                {errors.date && touched.date ? (
                                    <div className="error-message">{errors.date}</div>
                                ) : null}
                            </div>
                            <div className="col-sm-6">
                                <input required type="time" id="start" className="did-floating-dropdown" name="slot_start_time"
                                    value={values?.slot_start_time}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {errors.slot_start_time && touched.slot_start_time ? (
                                    <div className="error-message">{errors.slot_start_time}</div>
                                ) : null}
                            </div>
                            <div className="col-sm-6">

                                <input required type="time" id="end" className="did-floating-dropdown" name="slot_end_time"
                                    value={values?.slot_end_time}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {errors.slot_end_time && touched.slot_end_time ? (
                                    <div className="error-message">{errors.slot_end_time}</div>
                                ) : null}
                            </div>
                            <div className="d-flex justify-content-center pt-1">
                                <button className="btn btn-light w-auto pe-5 ps-5 p-2 me-2" type="button" onClick={() => resetForm()}>Reset</button>
                                <button className="btn btn-primary w-auto pe-5 ps-5 p-2" type="submit">Submit</button>
                            </div>
                        </div>
                    </form>
                )}
        </Formik>

    }
    //ASSIGN NEW CAREGIVER MODAL OPEN
    const assignNewCaregiver = (slot) => {
        setOpenNewCaregiver(true)
        const times = [slot?.slot_start_time, slot?.slot_end_time];
        let formattedTime = [];
        times.forEach((time) => {
            formattedTime.push(moment(time, 'HH:mm').format('hh:mm A'));
        });
        setAssignTime(formattedTime.join(" TO "))
        setSlotData(slot)
        AdminStore.getStaffByProfession({ "profession_ids": [slot?.shift_data?.profession] })
    }
    const onDismissNewCaregiverModal = () => {
        setOpenNewCaregiver(false)
        setAssignTime()
        setSlotData()
        setAssignToStaff()
    }
    //Assign new caregiver API call
    const assignToOtherStaff = (slot) => {
        if (assignToStaff) {
            swal({
                title: "Do you really want to assign this caregiver?",
                text: "After an update, You can't roll back an update.",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        let newSlot = [{ "id": slot?.id, "slot_type": slot?.slot_type, "staff_id": assignToStaff, "slot_status": 2, "date": slot?.slot_date, "time": [slot?.slot_start_time, slot?.slot_end_time] }];
                        let param = {
                            request_id: slot?.shift_id,
                            slots: JSON.stringify(newSlot)
                        }
                        AdminStore.updateSlotData(param, refreshList)
                    } else {
                    }
                });
        } else {
            toast.warn("Please select any other caregiver!")
        }
    }
    const showProfileDetails = (user) => {
        if (!user?.staff_id) {
            return
        }
        const caregiver = {
            id: user?.staff?.id,
            first_name: user?.staff?.first_name,
            last_name: user?.staff?.last_name,
            bluesky_staff_id: user?.staff?.bluesky_staff_id,
        }
        const url = `/admin/caregiver-shift?data=${encodeURIComponent(JSON.stringify(caregiver))}`;
        let userData = user?.staff;
        return ProfileView({ userData, url });
    };

    const handleActionChange = (e) => {
        setSelectedSlots([])
        setSelectedAction(e)
        if (e?.value === 1) {
            setStaffingRequestList(dummySlots?.filter(x => (x.status === 1 || x.status === 2) && x?.is_exported === 0))
        }
        if (e?.value === 2 || e?.value === 3) {
            setStaffingRequestList(dummySlots?.filter(x => x.status === 2))
        }
        if (e?.value === 4) {
            setStaffingRequestList(dummySlots?.filter(x => x.status === 1))
        }
    }

    const handleClearAction = () => {
        setSelectedAction(null)
        setSelectedSlots([])
        setStaffingRequestList(dummySlots)
    }
    const handleSelectedProfession = (e) => {
        setSelectedSlots([])
        setSelectedProfession(e)
        let filteredArray = dummySlots?.filter(x => x.status === 1 || x.status === 2)
        if (e) {
            setStaffingRequestList(filteredArray?.filter(request => request?.shift_data?.profession == e.Id))
            AdminStore.getStaffByProfession({ "profession_ids": [e.Id] })
        } else {
            setStaffingRequestList(filteredArray)
        }
    }
    const clearSelectedProfession = () => {
        setSelectedProfession(null)
        setStaffingRequestList(dummySlots?.filter(x => x.status === 1 || x.status === 2))
        setStaffByProfession()
    }

    const onMassAction = async () => {
        switch (selectedAction?.value) {
            case 1:
                // Assign
                setAssignToStaff()
                setMassAssign(true)
                break;
            case 2:// 2 for CBC, 
                swal({
                    content: (
                        <div>
                            <h3>Are you sure to cancel this shift by caregiver?</h3>
                            <p>After this action, you can't roll back!</p>
                            <input
                                type="text"
                                placeholder="Enter the reason for cancellation..."
                                id="cancelReason"
                                className='did-floating-dropdown'
                            />
                        </div>
                    ),
                    buttons: {
                        cancel: {
                            text: 'Cancel',
                            value: null,
                            visible: true,
                            className: '',
                            closeModal: true,
                        },
                        confirm: {
                            text: 'OK',
                            value: true,
                            visible: true,
                            className: '',
                            closeModal: true,
                        },
                    },
                    icon: 'warning', // Add alert icon
                    dangerMode: true,
                })
                    .then((willDelete) => {
                        if (willDelete) {
                            const reason = document.getElementById('cancelReason')?.value;
                            let param = {
                                slot_id_facility: [],
                                slot_id_caregiver: selectedSlots?.map(x => x.id),
                                slot_id_delete: [],
                                reason: reason,
                            };
                            AdminStore.cancelSlot(param, refreshList);
                        }
                    });
                break;
            case 3:
                // 3 for CBF
                swal({
                    content: (
                        <div>
                            <h3>Are you sure to cancel this shift by facility?</h3>
                            <p>After this action, you can't roll back!</p>
                            <input
                                type="text"
                                placeholder="Enter the reason for cancellation..."
                                id="cancelReason"
                                className='did-floating-dropdown'
                            />
                        </div>
                    ),
                    buttons: {
                        cancel: {
                            text: 'Cancel',
                            value: null,
                            visible: true,
                            className: '',
                            closeModal: true,
                        },
                        confirm: {
                            text: 'OK',
                            value: true,
                            visible: true,
                            className: '',
                            closeModal: true,
                        },
                    },
                    icon: 'warning', // Add alert icon
                    dangerMode: true,
                })
                    .then((willDelete) => {
                        if (willDelete) {
                            const reason = document.getElementById('cancelReason')?.value;
                            let param = {
                                slot_id_facility: selectedSlots?.map(x => x.id),
                                slot_id_caregiver: [],
                                slot_id_delete: [],
                                reason: reason,
                            };
                            AdminStore.cancelSlot(param, refreshList);
                        }
                    });
                break;
            case 4:
                // Delete
                swal({
                    title: "Are you sure to delete this shift?",
                    text: "After an delete, You can't roll back an delete.",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                    .then((willDelete) => {
                        if (willDelete) {
                            let param = {
                                slot_id_facility: [],
                                slot_id_caregiver: [],
                                slot_id_delete: selectedSlots?.map(x => x.id),
                            }
                            AdminStore.cancelSlot(param, refreshList)
                        }
                    });
                break;
            default:
                toast.warn("Please select any action!")
        }
    }

    const assignMassCaregiver = () => {
        let newSlot = [];
        if (selectedSlots?.length !== 0) {
            newSlot = selectedSlots?.map(slot => {
                return { "id": slot?.id, "slot_type": slot?.slot_type, "staff_id": assignToStaff, "slot_status": 2, "date": slot?.slot_date, "time": [slot?.slot_start_time, slot?.slot_end_time] };
            });
            let param = {
                slots: JSON.stringify(newSlot)
            }
            AdminStore.updateSlotData(param, callbackNavigation)
        } else {
            toast.warn("Please select caregiver!")
        }
    }
    const callbackNavigation = () => {
        setSelectedSlots()
        setEditSlotData()
        setShowEditModal(false)
        setOpenNewCaregiver(false)
        setAssignTime()
        setSlotData()
        setSelectedProfession(null)
        setSelectedAction(null)
        setMassAssign(false)
        setAssignToStaff()
        AdminStore.getShiftsByFacility(facilityData?.id);
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>Staffers LLC | Facility User Shifts</title>
            </Helmet>
            <div className="container-scroller">
                <HeaderPage />
                <div className="container-fluid page-body-wrapper">
                    <DrawerMenuPage />
                    <div className="main-panel" style={{ right: 0, position: "absolute" }}>
                        <div className="row content-wrapper p-2 bg-white">
                            <div className="col-11 mb-2 mb-lg-0 page-title">
                                <b><i className="far fa-id-card"></i> Facility Information</b>
                                <div className="row mt-2">
                                    <div className="col-12 col-sm-auto mb-2">
                                        <span>Facility Id: {facilityData?.id},</span>
                                    </div>
                                    <div className="col-12 col-sm-auto mb-2">
                                        <span>Facility: {facilityData?.organization_name}</span>
                                    </div>
                                    <div className="col-12 col-sm-auto mb-2">
                                        <div className="nav-item dropdown">
                                            <a href="#" className="nav-link dropdown-toggle  pt-0 mt-0" data-bs-toggle="dropdown">
                                                <i className="fas fa-users"></i>&nbsp;
                                                {toJS(AdminStore?.data?.employersByFacility)?.length > 0 && (toJS(AdminStore?.data?.employersByFacility)[0]?.first_name +" " + toJS(AdminStore?.data?.employersByFacility)[0]?.last_name)}
                                                <sup>+{toJS(AdminStore?.data?.employersByFacility) ? toJS(AdminStore?.data?.employersByFacility)?.length - 1 : 0}</sup>
                                            </a>
                                            <div className="dropdown-menu border-light m-0">
                                                <ul className="list-unstyled">
                                                    {toJS(AdminStore?.data?.employersByFacility) && toJS(AdminStore?.data?.employersByFacility)?.map((item, index) => (
                                                        <li key={index} className="dropdown-item">{item?.first_name}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h2 className="col-1 mb-2 mb-lg-0">
                                <div className='d-flex'>
                                    {/* <i data-toggle="tooltip" data-placement="right" title="Back" className="fas fa-arrow-alt-circle-left fs-1 text-primary pointer" onClick={goBack}></i> */}
                                    <i data-toggle="tooltip" data-placement="right" title="Refresh" className="fas fa-redo fs-1 text-primary pointer" onClick={refreshList}></i>
                                </div>
                            </h2>
                        </div>
                        <div className="col-lg-12">
                            <p className='ms-2'><strong>Please Note: </strong>To view the list of slots click on the slots tab and to view traveler contracts click on the contracts tab.</p>
                            <div className="d-flex">
                                {/* <ToolbarDropdown isMulti={false} placeholder={"Select Action"} selectedOption={selectedAction} slotStatusList={massActionList} handleSelectChange={handleActionChange} handleClearAction={handleClearAction} /> */}
                                {selectedAction?.value === 1 && <div className="d-flex mb-2 ms-1">
                                    <Select
                                        placeholder={"Select Profession"}
                                        options={degree}
                                        onChange={handleSelectedProfession}
                                        value={selectedProfession}
                                        styles={dropdownStatusStyles}
                                        isMulti={false}
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        getOptionLabel={(option) => `${option.Name}`}
                                        getOptionValue={(option) => option.Id}
                                        components={{ Control, MultiValue, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                    />
                                    {<div className=" ms-2">
                                        <button className='btn btn-danger' onClick={clearSelectedProfession}>Clear</button>
                                    </div>}
                                </div>}
                            </div>

                            <div className="row bg-white">
                                <div className=''>
                                    <ul className="nav nav-tabs d-flex justify-content-center">
                                        <li className="nav-item">
                                            <button
                                                className={`nav-link ${activeTab === 'tab1' ? 'active text-primary border-3 border-primary' : 'text-muted border border-muted'}`}
                                                onClick={() => handleTabChange('tab1')}
                                            >
                                                <strong><i className="fas fa-list"></i> Slots</strong>
                                            </button>
                                        </li>
                                        <li className="nav-item">
                                            <button
                                                className={`nav-link ${activeTab === 'tab2' ? 'active text-primary border-3 border-primary' : 'text-muted border border-muted'}`}
                                                onClick={() => handleTabChange('tab2')}
                                            >
                                                <strong><i className="fas fa-file-signature"></i> Contracts</strong>
                                            </button>
                                        </li>
                                    </ul>
                                    <div className={`tab-list-content ${activeTab === 'tab1' ? 'active' : ''}`}>
                                        <div className='table-responsive'>
                                            <table className="table dt-responsive  table-hover nowrap" style={{ width: "100%" }} id={tableNameSlot} ref={tableRefSlot}></table>
                                        </div>
                                    </div>
                                    <div className={`tab-list-content ${activeTab === 'tab2' ? 'active' : ''}`}>
                                        <div className='table-responsive'>
                                            <table className="table dt-responsive table-hover nowrap" style={{ width: "100%" }} id={tableName} ref={tableRef}></table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <CustomModal
                        isOpen={editSlotEditModal}
                        closeModal={editSlotModalDismiss}
                        title={"Edit Shift Ref." + editSlotData?.id}
                        content={<EditForm />}
                    />
                    {/* <!-- main-panel ends --> */}
                </div>
                {/* <!-- page-body-wrapper ends --> */}
            </div >
            {/* ASIGGNED NEW AREGIVERS MODAL */}

            < Spinner isLoading={AdminStore.loading} />
            {selectedSlots?.length !== 0 && <div className="float-btn">
                <button type="submit" onClick={() => onMassAction()}
                    className="btn btn-success w-100 py-3">
                    Selected Shift's({selectedSlots?.length})  Submit</button>
            </div>}

            <CModal size="lg" scrollable visible={openNewCaregiver} onClose={onDismissNewCaregiverModal} backdrop="static" >
                <CModalHeader onClose={onDismissNewCaregiverModal}>
                    <CModalTitle>Assign Caregiver?
                    </CModalTitle>
                </CModalHeader>
                <CModalBody >
                    <CTable responsive>
                        <CTableHead>
                            <CTableRow>
                                <CTableHeaderCell scope="col">Shift Type</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Date</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Time</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Comment</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Select Assign Caregiver</CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>
                        <CTableBody>
                            <CTableRow >
                                <CTableDataCell><span >{slotData?.slot_type}</span></CTableDataCell>
                                <CTableDataCell><i className="far fa-calendar-alt"></i> {slotData?.slot_date}</CTableDataCell>
                                <CTableDataCell> <i className="far fa-clock"></i> {assignTime}</CTableDataCell>
                                <CTableDataCell>{slotData?.remark}</CTableDataCell>
                                <CTableDataCell>
                                    <Select
                                        onChange={(e) => setAssignToStaff(e.staff_id)}
                                        placeholder={'Select Caregiver'}
                                        required={true}
                                        isDisabled={signleData?.status === 3 || signleData?.status === 2 || signleData?.status === 0}
                                        value={staffByProfession?.filter(({ staff_id }) => staff_id === assignToStaff)}
                                        styles={colourStylesTitle}
                                        isSearchable={true}
                                        options={staffByProfession}
                                        getOptionLabel={(option) => `${option.first_name} ${option.last_name} (Bluesky Id: ${option.staffer_id})`}
                                        getOptionValue={(option) => option.staff_id}
                                        components={{ Control }}
                                    />
                                </CTableDataCell>
                            </CTableRow>
                        </CTableBody>

                    </CTable>
                </CModalBody>
                {/* </CModalBody> */}
                <div className="modal-footer justify-content-center">
                    <button type="button" className="btn btn-danger w-15" onClick={onDismissNewCaregiverModal} >&nbsp;Close &nbsp;</button>
                    <button type="button" className="btn btn-success w-15" disabled={assignToStaff === null} onClick={() => assignToOtherStaff(slotData)} >Update?</button>
                </div>
            </CModal>
            {/* Mass Assign caregiver */}
            <CModal visible={massAssign} onClose={() => setMassAssign(false)} backdrop="static" >
                <CModalHeader>
                    <CModalTitle>Assign Caregiver?
                    </CModalTitle>
                </CModalHeader>
                <CModalBody >
                    <CTable responsive>
                        <CTableHead>
                            <CTableRow>
                                <CTableHeaderCell scope="col">Caregiver</CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>
                        <CTableBody>
                            <CTableRow >

                                <CTableDataCell>
                                    <Select
                                        onChange={(e) => setAssignToStaff(e.staff_id)}
                                        placeholder={'Select Caregiver'}
                                        required={true}
                                        value={staffByProfession?.filter(({ staff_id }) => staff_id === assignToStaff)}
                                        styles={colourStylesTitle}
                                        isSearchable={true}
                                        options={staffByProfession}
                                        getOptionLabel={(option) => `${option.first_name} ${option.last_name} (Bluesky Id: ${option.staffer_id})`}
                                        getOptionValue={(option) => option.staff_id}
                                        components={{ Control }}
                                    />
                                </CTableDataCell>
                            </CTableRow>
                        </CTableBody>

                    </CTable>
                </CModalBody>
                {/* </CModalBody> */}
                <div className="modal-footer justify-content-center">
                    <button type="button" className="btn btn-danger w-15" onClick={() => setMassAssign(false)} >&nbsp;Close &nbsp;</button>
                    <button type="button" className="btn btn-success w-15" disabled={assignToStaff === null} onClick={() => assignMassCaregiver()} >Assign?</button>
                </div>
            </CModal>
        </React.Fragment >
    )
})
export default ShiftByFacility;
