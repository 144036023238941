
import { useMediaQuery } from 'react-responsive';

const PDFViewer = ({ pdfUrl }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const googleViewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(pdfUrl)}&embedded=true`
  return (
    <div style={{ width: '100%', height: '500px' }}>
      {isMobile ? <iframe src={googleViewerUrl} title="PDF Viewer" width="100%" height="100%" type="application/pdf"></iframe>
        : <iframe
          src={pdfUrl}
          title="PDF Viewer"
          style={{ width: '100%', height: '100%' }}
          type="application/pdf"
        >
          This browser does not support PDFs. Please download the PDF to view it.
        </iframe>}
    </div>
  );
};

export default PDFViewer;
