/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react"
import HeaderPage from "../layout/HeaderPage";
import DrawerMenuPage from "../layout/DrawerMenuPage";
import { CBadge, CButton, CCollapse, CCardBody } from "@coreui/react";
import moment from 'moment';
import Spinner from "@components/Spinner";
import ReactDOM from "react-dom";
const $ = window.$;
import './NotificationList.css'
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import AdminStore from "@store/AdminStore";
import { toJS } from "mobx";
import { Helmet } from "react-helmet";

const NotificationList = observer(() => {
    const tableRef = useRef()
    const tableName = "table1"
    const [notificationList, setNotificationList] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [signleData, setSingleData] = useState();
    const [paginationLength, setPaginationLength] = useState(100);

    useEffect(() => {
        setNotificationList(toJS(AdminStore?.data?.notifications))
    }, [AdminStore?.data?.notifications]);

    useEffect(() => {
        $.fn.dataTable.ext.errMode = 'none';
        $($.fn.dataTable.tables(true)).DataTable()
            .columns.adjust();
        const table = $(`#${tableName}`).DataTable(
            {
                data: notificationList,
                order: [], // Disable initial sorting
                pageLength: paginationLength,
                lengthMenu: [100, 500, 1000, 2000, 3000, 5000],
                dom: 'Blfrtip',
                buttons: [
                    'copy', 'csv', 'excel', 'print',
                ],
                columns: [
                    {
                        title: "Sr", data: 'id', width: 50, "render": function (data, type, full, meta) {
                            return meta.row + 1;
                        }
                    },
                    {
                        title: "Heading", width: 50, data: "data", width: 60, "render": function (data, type, full, meta) {
                            return data ? JSON.parse(data)?.data?.heading : "-";
                        }
                    },
                    {
                        title: "Content", width: 100, data: "data", "render": function (data, type, full, meta) {
                            return data ? JSON.parse(data)?.data?.content?.length > 0 ? JSON.parse(data)?.data?.content?.length < 25 ? JSON.parse(data)?.data?.content?.substring(0, 10) : JSON.parse(data)?.data?.content?.substring(0, 25) + "..." : '-' : "-";;
                        }
                    },

                    {
                        title: "Link", data: "data", width: 100, "render": function (data, type, full, meta) {
                            return data ? JSON.parse(data)?.data?.link ? `<a href="${JSON.parse(data)?.data?.link}" target="_blank">${JSON.parse(data)?.data?.link}</a>` : "-" : "-";
                        }
                    },
                    {
                        title: "Time", data: "created_at", width: 60, "render": function (data, type, full, meta) {
                            return moment(data).fromNow();
                        }
                    },

                    {
                        title: "Action", width: 60
                    },
                ],

                columnDefs: [
                    {
                        targets: 4,
                        responsivePriority: 2
                    },
                    {
                        targets: 5,
                        responsivePriority: 1,
                        createdCell: (td, cellData, rowData, row, col) =>
                            ReactDOM.render(
                                <div>
                                    <CButton onClick={() => {
                                        showDetails(rowData)
                                    }} size="sm" className="w-100" variant={rowData?.read_at ? 'outline' : ""} color="primary" >
                                        {rowData?.read_at ? <i class="far fa-envelope-open"></i> : <i class="far fa-envelope"></i>} Open
                                    </CButton>
                                </div>
                                , td),
                    },
                ],
                destroy: true,  // I think some clean up is happening here
                searching: true,
                "language": {
                    search: '',
                    searchPlaceholder: "Search",
                    emptyTable: "No Record Found"
                },
                "rowCallback": function (row, data) {
                    // Add your condition here
                    if (!data.read_at) {
                        $(row).addClass('highlight'); // Apply the CSS class to highlight the row
                    }
                },
                initComplete: function () {
                    // Apply the search
                    this.api()
                        .columns([0, 1, 2, 3, 4])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><input class="form-control " style="width:100%;margin-top:5px" type="text" placeholder="Filter" />')
                                .appendTo($(column.header()))

                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $('input', this.header()).on('keyup change clear', function () {
                                if (column.search() !== this.value) {
                                    column.search(this.value).draw();
                                }
                            });
                        });

                },

            }

        )
        // Extra step to do extra clean-up.
        return function () {
            table.destroy()
        }
    }, [notificationList])

    const showDetails = (item) => {
        setSingleData(item);
        setIsOpen(true);
    }
    const onDismiss = () => {
        if (!signleData?.read_at) {
            let param = {
                "notification_id": signleData?.id
            }
            AdminStore.readNotification(param, null, navigationCallBack)
        }
        setIsOpen(false)
    }
    const navigationCallBack = () => {
        AdminStore.getNotificationsData();
        setSingleData()
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>Staffers LLC | Notification List</title>
            </Helmet>
            {/* <body> */}
            <div className="container-scroller">
                <HeaderPage />
                <div className="container-fluid page-body-wrapper">
                    <DrawerMenuPage />
                    <div className="main-panel" style={{ right: 0, position: "absolute" }}>
                        <div className="d-flex justify-content-between content-wrapper p-3 bg-white ">
                            <h3 className="page-title">
                                <span className="page-title-icon linear text-white me-2">
                                    <i className="fas fa-bell"></i>
                                </span> Notifications
                            </h3>
                        </div>
                        <div className="row bg-white table-responsive" style={{ paddingTop: 20 }}>
                            <div className="table-wrapper">
                                <table className="table dt-responsive table-hover nowrap" style={{ width: "100%" }} id={tableName} ref={tableRef}></table>
                            </div>
                        </div>
                    </div>

                    {/* <!-- main-panel ends --> */}
                </div>
                {/* <!-- page-body-wrapper ends --> */}
                <BottomSheet open={isOpen} onDismiss={onDismiss} scrollLocking={false}
                    header={
                        <>
                            <div className="text-end" style={{ cursor: "pointer" }} onClick={onDismiss}>
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <h4 className="flex items-center text-xl justify-center font-bold text-primary-800">
                                {signleData?.data ? JSON.parse(signleData?.data)?.data?.heading : "NOTIFICATION"}
                            </h4>
                        </>
                    }>
                    <div style={{ height: 'auto', overflowY: 'scroll' }}>
                        <div class="col-xl-12 col-md-12">
                            <div class="card user-card-full">
                                <div class="row m-l-0 m-r-0">
                                    <div class="col-sm-12">
                                        <div class="card-block">
                                            <div className='d-flex justify-content-between align-items-center b-b-default p-b-5'>
                                                <h6 class="f-w-600">Notification Details</h6>
                                                {signleData?.read_at && <h5><span class="badge badge-primary"> {moment(signleData?.read_at).fromNow()}</span></h5>}
                                            </div>
                                            <div class="row pt-3">
                                                {signleData && signleData?.data && <div class="col-sm-3">
                                                    <p class="m-b-10 f-w-600">Subject</p>
                                                    <h6 class="text-muted f-w-400">{JSON.parse(signleData?.data)?.data?.subject || "-"}</h6>
                                                </div>}
                                                {signleData && signleData?.data && JSON.parse(signleData?.data)?.data?.link && <div class="col-sm-3">
                                                    <p class="m-b-10 f-w-600">Link</p>
                                                    <a href={`${JSON.parse(signleData?.data)?.data?.link}`} target="_blank"><u className="f-w-400">{JSON.parse(signleData?.data)?.data?.link || "-"}</u></a>
                                                </div>}
                                                {signleData && signleData?.data && JSON.parse(signleData?.data)?.data?.content && <div class="col-sm-12">
                                                    <p class="m-b-10 f-w-600">Content</p>
                                                    {/* <h6 class="text-muted f-w-400">{signleData?.content}</h6> */}
                                                    <div dangerouslySetInnerHTML={{ __html: JSON.parse(signleData?.data)?.data?.content }} />
                                                </div>}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </BottomSheet>
                <Spinner isLoading={AdminStore.loading} />

            </div>

        </React.Fragment>
    );
})

export default NotificationList;