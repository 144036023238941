import React from "react";

const PartnerMarquee = ({ sectionData }) => {
  return (
    <div className="logos-sec">
      <div>
        <h3 className="common-web-head mb-4 text-center">
          {sectionData?.[0] || `Our Partners`}
        </h3>

        <div className="container">
          <div className="row">
            <ul className="partner-list">
              <li>
                <img
                  src="/assets/images/web-images/ProMedica.png"
                  className="img-fluid"
                  alt="rescue"
                />
              </li>
              <li>
                <img
                  src="/assets/images/web-images/ProMedicaHospice.png"
                  className="img-fluid"
                  alt="rescue"
                />
              </li>
              <li>
                <img
                  src="/assets/images/web-images/SalinasValley.png"
                  className="img-fluid"
                  alt="rescue"
                />
              </li>
              <li>
                <img
                  src="/assets/images/web-images/AmamereLiving.png"
                  className="img-fluid"
                  alt="rescue"
                />
              </li>
              <li>
                <img
                  src="/assets/images/web-images/AvalonHealthGroup.png"
                  className="img-fluid"
                  alt="rescue"
                />
              </li>
              <li>
                <img
                  src="/assets/images/web-images/BeehiveHomes.png"
                  className="img-fluid"
                  alt="rescue"
                />
              </li>
              <li>
                <img
                  src="/assets/images/web-images/Encompass.png"
                  className="img-fluid"
                  alt="rescue"
                />
              </li>
              <li>
                <img
                  src="/assets/images/web-images/EnsignGroup.png"
                  className="img-fluid"
                  alt="rescue"
                />
              </li>
              <li>
                <img
                  src="/assets/images/web-images/GenerationsHealthcare.png"
                  className="img-fluid"
                  alt="rescue"
                />
              </li>
              <li>
                <img
                  src="/assets/images/web-images/HCRManorCare.png"
                  className="img-fluid"
                  alt="rescue"
                />
              </li>
              <li>
                <img
                  src="/assets/images/web-images/NorthernNevada.png"
                  className="img-fluid"
                  alt="rescue"
                />
              </li>
              <li>
                <img
                  src="/assets/images/web-images/NorthwestKidney.png"
                  className="img-fluid"
                  alt="rescue"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerMarquee;
