/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import Header from '@components/Header';
import { CModal, CButton, CModalHeader, CModalTitle, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem } from "@coreui/react";
import SelectField from "@elements/select/SelectField";
import { InputField } from "@elements/index";
import { Helmet } from 'react-helmet';
import 'react-spring-bottom-sheet/dist/style.css';
import UserStore from "@store/UserStore";
import HomeStore from "@store/HomeStore";
import StaffsStore from "@store/StaffsStore";
import { observer } from "mobx-react-lite";
import { toJS } from 'mobx';
import secureLocalStorage from 'react-secure-storage';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import Spinner from "@components/Spinner";
import DatePicker, { getAllDatesInRange } from "react-multi-date-picker"
import TimeRangePicker from '@wojtekmaj/react-timerange-picker';
import weekends from "react-multi-date-picker/plugins/highlight_weekends"
import * as Yup from 'yup';
import { Formik, Field, FieldArray } from "formik";
import { toast } from "react-toastify";
import ReactDOM from "react-dom";
import Select, { components } from 'react-select';

const colourStyles = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? 'blue' : '#75459b',
        borderRadius: 20,
        boxShadow: 'none',
        padding: "2px 10px",
        '&:focus': {
            border: '1px solid blue !important'
        },
        '&:active': {
            border: '1px solid blue !important'
        },
        '&:hover': {
            border: '1px solid blue !important'
        }
    }),
    valueContainer: (provided) => ({
        ...provided,
        fontSize: 12,
    }),
    menu: base => ({
        ...base,
        fontSize: 12,
    }),
    menuList: (base) => ({
        ...base,
        height: "200px",

        "::-webkit-scrollbar": {
            width: "9px"
        },
        "::-webkit-scrollbar-track": {
            background: "white"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#8282C8"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        }
    })

}
var citys = require('@assets/city.json');
var states = require('@assets/states.json');
const UpdateSchema = Yup.object().shape({
    state: Yup.string().required('State required'),
    city: Yup.string().required('City required'),
    shift_type: Yup.string().required('Shift type required'),
    profession: Yup.string().required('Profession required'),
});
const FavoriteList = observer(() => {
    const tableRef = useRef()
    const tableName = "table1"
    const [bookmarkList, setBookmarkList] = useState([]);
    const [staffData, setStaffData] = useState();
    const [isAuthenticated, setAuthenticated] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenForm, setIsOpenForm] = useState(false);
    const [userData, setUserData] = useState(null);
    const [degree, setDegree] = useState([]);
    const [facilityCode, setFacilityCode] = useState(null);
    const [multi, setValues] = useState([])
    const [dates, setDates] = useState([])
    const [allDates, setAllDates] = useState([])
    const [durationType, setDurationType] = useState(undefined);
    const [state, setState] = useState(null);
    const [city, setCity] = useState(null);
    const [zipcode, setZipCode] = useState(null);
    const [address, setAddress] = useState(null);
    useEffect(() => {
        UserStore.getBookmark();
        let token = secureLocalStorage.getItem("token");
        setAuthenticated(token);
        StaffsStore.getRegionsList();
        var userData = secureLocalStorage.getItem("userData");
        setUserData(JSON.parse(userData));
        setFacilityCode(JSON.parse(userData)?.facility_code);
        setState(JSON.parse(userData)?.state);
        setCity(JSON.parse(userData)?.city);
        setAddress(JSON.parse(userData)?.address);
        setZipCode(JSON.parse(userData)?.zipcode);
    }, [])

    useEffect(() => {
        setBookmarkList(toJS(UserStore?.data?.bookmarkList))
    }, [UserStore?.data?.bookmarkList]);
    useEffect(() => {
        setDegree(HomeStore.data.degreeList)
    }, [HomeStore.data.degreeList]);

    useEffect(() => {
        $.fn.dataTable.ext.errMode = 'none';
        $($.fn.dataTable.tables(true)).DataTable()
            .columns.adjust();
        const table = $(`#${tableName}`).DataTable(
            {
                data: bookmarkList,
                order: [], // Disable initial sorting
                columns: [
                    {
                        title: "Sr", data: 'id', width: 30, "render": function (data, type, full, meta) {
                            return meta.row + 1;
                        }
                    },
                    {
                        title: "Caregiver Name", width: 100, "data": function (data, type, full, meta) {
                            return data?.staff_first_name+" "+ data?.staff_last_name;
                        }
                    },
                    {
                        title: "Profession", width: 100, data: "staff", "render": function (data, type, full, meta) {
                            return degree?.find(x => x.Id == data?.profession)?.Name;
                        }
                    },

                    {
                        title: "State", data: "state", width: 60,
                    },
                    {
                        title: "City", data: "city", width: 100,
                    },
                    {
                        title: "Action", width: 100,
                    },
                ],

                columnDefs: [
                    {
                        targets: 5,
                        responsivePriority: 1,
                        createdCell: (td, cellData, rowData, row, col) =>
                            ReactDOM.render(
                                <div>
                                    <CDropdown variant="btn-group" direction='dropstart' alignment="end">
                                        <CDropdownToggle size="sm" color="transparent" caret={false} href="#" className="p-0"><i className="fa fa-ellipsis-v text-primary"></i></CDropdownToggle>
                                        <CDropdownMenu>
                                            <CDropdownItem className="more-menu">
                                                <CButton onClick={() => {
                                                    onOpenForm(rowData)
                                                }} size="sm" className="w-100" variant='outline' color="success" >
                                                    <i className="fas fa-user-check"></i> Select Caregiver
                                                </CButton>
                                            </CDropdownItem>

                                            <CDropdownItem className="more-menu">
                                                <CButton onClick={() => {
                                                    showDetails(rowData)
                                                }} size="sm" className="w-100" variant='outline' color="primary" >
                                                    <i className="fa fa-eye" aria-hidden="true"></i> View
                                                </CButton>
                                            </CDropdownItem>

                                            <CDropdownItem className="more-menu">
                                                <CButton onClick={() => deleteBookmark(rowData)} size="sm" className="w-100" variant='outline' color="danger" >
                                                    <i className="fa fa-trash" aria-hidden="true"></i> Remove
                                                </CButton>
                                            </CDropdownItem>

                                        </CDropdownMenu>

                                    </CDropdown>

                                </div>
                                , td),
                    }
                ],
                destroy: true,  // I think some clean up is happening here
                searching: true,
                "language": {
                    search: '',
                    searchPlaceholder: "Search",
                    emptyTable: "No Record Found"
                },
                initComplete: function () {
                    // Apply the search
                    this.api()
                        .columns([0, 1, 2, 4])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><input class="form-control " style="width:100%;margin-top:5px" type="text" placeholder="Filter" />')
                                .appendTo($(column.header()))

                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $('input', this.header()).on('keyup change clear', function () {
                                if (column.search() !== this.value) {
                                    column.search(this.value).draw();
                                }
                            });
                        });
                    this.api()//STATE FILTER
                        .columns([3])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><select class="form-control" placeholder="search" style="width:100%;margin-top:5px;background-color:white""><option value="" selected>All</option></select>')
                                .appendTo($(column.header()))
                                .on('change', function () {
                                    var val = $.fn.dataTable.util.escapeRegex($(this).val());
                                    column.search(val ? '^' + val + '$' : '', true, false).draw();
                                });
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            column
                                .data()
                                .unique()
                                .sort()
                                .each(function (d, j) {
                                    let name = states?.find(stat => stat?.abbreviation == d);
                                    select.append('<option value="' + name?.abbreviation + '">' + name?.name + '</option>');
                                });
                        });
                },

            }

        )
        // Extra step to do extra clean-up.
        return function () {
            console.log("Table destroyed")
            table.destroy()
        }
    }, [bookmarkList])

    const deleteBookmark = (item) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this caregiver details!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    let param = {
                        bluesky_staff_id: item.staff_id,
                        first_name: item.staff_first_name,
                        last_name: item.staff_last_name,
                        city: item.city,
                        state: item.state,
                    }
                    UserStore.bookmark(param, navigationCallBackBookmark)
                }
            });

    }
    const navigationCallBackBookmark = () => {
        UserStore.getBookmark();
        toast.success("Successfully added");
    }

    const showDetails = (item) => {
        if (isAuthenticated) {
            setStaffData(item)
            setIsOpen(true);
        }
    }

    const onDismissForm = () => {
        setIsOpenForm(false)
        setStaffData()
    }

    const onOpenForm = (data) => {
        setDurationType(null)
        setStaffData(data)
        setIsOpen(false)
        setIsOpenForm(true)
    }
    const onDismiss = () => {
        setIsOpen(false)
        setIsOpenForm(false)
        setStaffData()
    }
    const onHiringRequest = (values) => {
        let finalslot = values.tenure.map((x, index) => {
            return { "date": x.format(), "time": values.alltime[index] ? values.alltime[index] : values.alltime[0], "remark": values?.allcomment ? values?.allcomment[index] || '' : '' }
        })

        let check = finalslot.some((obj) => !Object.values(obj).every((val) => val !== undefined));
        if (check) {
            toast.warn("Please select date & time!")
        } else {
            let param = {
                user_id: values.user_id,
                staff_id: values.staff_id,
                staff_name: values.staff_name,
                profession: values.profession,
                category: values.category,
                shift_type: values.shift_type,
                slots: JSON.stringify(finalslot),
                zipcode: values.zipcode,
                job_type: values.job_type,
                description: values.description,
                address: values.address,
                city: values.city,
                state: values.state
            }
            UserStore.setStaffingRequest(param, navigationCallBack, navigationCallBackUnallocated)
        }
    }
    const navigationCallBack = () => {
        var content = document.createElement('div');
        content.innerHTML = 'Thank you for submitting a staff request through Staffers!<br>Your specific request has been sent to your selected caregiver.<br>We will notify you as soon as caregiver accepts your assignment.<br> The notification will be sent to:<br><strong class="text-primary">&#128222; ' + userData?.contact + '</strong><br><strong class="text-primary">&#128231;' + userData?.email + '</strong><br> If they are not available, we will present to you someone who is. Thank you for trusting Staffers with your staffing needs!';
        swal({
            title: "Success",
            content: content,
            icon: "success"
        }).then((value) => {
            onDismissForm()
        });
    }
    const navigationCallBackUnallocated = () => {
        onDismissForm()
    }
    function ClearButton({ setFiled }) {
        return <div>
            <button
                className="btn btn-sm btn-primary"
                type="button"
                onClick={() => {
                    setDates([])
                    setAllDates([])
                    setFiled('tenure', [])
                }}
            >
                Clear
            </button>
        </div>;
    }
    return (
        <>
            <Header />
            <Helmet>
                <title>Saved Caregivers</title>
            </Helmet>
            <>
                <div className="container-fluid callback mt-5 py-5">
                    <div className="container pt-lg-5">
                        <h5 className="display-5 mb-4 animated slideInDown login-head">
                            Saved Caregivers</h5>
                    </div>
                    <div className="col-lg-12">
                        <div className="row bg-white" style={{ height: 500, paddingTop: 50 }}>
                            <table className="table dt-responsive table-hover nowrap" style={{ width: "100%" }} id={tableName} ref={tableRef}></table>
                        </div>
                    </div>
                </div>
                <BottomSheet open={isOpen} onDismiss={onDismiss} scrollLocking={true}
                    header={
                        <>
                            <div className="text-end" style={{ cursor: "pointer" }} onClick={onDismiss}>
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <h4 className="flex items-center text-xl justify-center font-bold text-primary-800 text-uppercase">
                                {staffData?.staff_first_name}
                            </h4>
                        </>
                    }
                    footer={
                        <div className="text-end">
                            <button onClick={onOpenForm} className="btn btn-primary">
                                Request to select caregiver
                            </button>
                        </div>
                    }>
                    <div style={{ height: 'auto', overflowY: 'scroll' }}>
                        <div className="col-xl-12 col-md-12">
                            <div className="card user-card-full">
                                <div className="row m-l-0 m-r-0">
                                    <div className="col-sm-12">
                                        <div className="card-block">
                                            <h6 className="m-b-20 p-b-5 b-b-default f-w-600">Caregiver Details</h6>
                                            <div className="row">
                                                {(staffData && staffData?.staff_first_name) && <div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">Name</p>
                                                    <h6 className="text-muted f-w-400">{staffData?.staff_first_name}</h6>
                                                </div>}
                                                {(staffData && staffData?.staff?.job_profile?.degree_specialities) && <div className="col-sm-3">
                                                    <p className="m-b-10 f-w-600">Profession</p>
                                                    <h6 className="text-muted f-w-400">{JSON.parse(staffData?.staff?.job_profile?.degree_specialities)?.map((tl, index) => { return <div key={index + 'proff'}>{tl.profession_name} < br /></div> })}</h6>
                                                </div>}
                                                {(staffData && staffData?.staff?.job_profile?.degree_specialities) && <div className="col-sm-3">
                                                    <p className="m-b-10 f-w-600">Specialty</p>
                                                    <h6 className="text-muted f-w-400">{JSON.parse(staffData?.staff?.job_profile?.degree_specialities)?.map((sp, index) => { return <div key={index + 'spec'}>{sp.specialties_name} < br /></div> })}</h6>
                                                </div>}
                                                {staffData && staffData?.city && <div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">City</p>
                                                    <h6 className="text-muted f-w-400">{staffData?.city}</h6>
                                                </div>}
                                                {staffData && staffData?.state && <div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">State</p>
                                                    <h6 className="text-muted f-w-400">{staffData?.state}</h6>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </BottomSheet>
            </>

            <Spinner isLoading={HomeStore.loading || UserStore.loading} />
            <CModal visible={isOpenForm} onClose={onDismiss} backdrop="static">
                <CModalHeader>
                    <CModalTitle>Request to select this caregiver</CModalTitle>
                </CModalHeader>
                {/* <CModalBody> */}
                <Formik
                    initialValues={{
                        user_id: userData?.id,
                        staff_id: staffData?.staff?.id,
                        facility_code: facilityCode || '',
                        category: staffData?.category || '',
                        profession: '', specialty: '',
                        staff_name: staffData?.staff_first_name,
                        tenure: "",
                        shift_type: "",
                        zipcode: '',
                        job_type: 2,
                        region: "",
                        description: "",
                        state: state || "",
                        city: city || "",
                        zipcode: zipcode || '',
                        address: address || '',
                        nop: "",
                        hours_per_week: "",
                        no_of_weeks: "",
                        pay_detail: "",
                        start_date: "",
                        end_date: "",
                        start_time: "",
                        end_time: "",
                        qualifications: "",
                    }}
                    onSubmit={values => onHiringRequest(values)}
                    validationSchema={UpdateSchema}
                    validateOnChange={false}
                >
                    {
                        ({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched
                        }) =>
                        (
                            <form onSubmit={handleSubmit}>
                                <div className="modal-body p-4">
                                    <div className="row g-2">
                                        <div className="pb-2 col-sm-6">
                                            <InputField
                                                className={"did-floating-input"}
                                                type="text"
                                                name="staff_name"
                                                label={"Caregiver Name"}
                                                value={values.staff_name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                readOnly={true}
                                            />
                                        </div>
                                        <div className="pb-2 col-sm-6">
                                            <InputField
                                                className={"did-floating-input"}
                                                type="text"
                                                label={"Caregiver Location"}
                                                value={`${staffData?.city}, ${staffData?.state}`}
                                                readOnly={true}
                                            />
                                        </div>
                                        <div className="pb-2 col-sm-6">
                                            <InputField
                                                className={"did-floating-input"}
                                                type="text"
                                                name="facility_code"
                                                label={"Facility Code"}
                                                value={values.facility_code}
                                                disabled={true}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />

                                        </div>
                                        <div className="col-sm-6">
                                            <div className="did-floating-label-content">
                                                <select className="did-floating-dropdown"
                                                    name="profession"
                                                    value={values.profession}
                                                    onChange={handleChange}
                                                    onClick={(e) => {
                                                        setFieldValue('profession', e.target.value)
                                                    }}
                                                >
                                                    <option
                                                        value=""
                                                    >
                                                    </option>
                                                    {(staffData && staffData?.staff?.job_profile?.degree_specialities) && JSON.parse(staffData?.staff?.job_profile?.degree_specialities)?.filter((entry, index, self) => self.findIndex(e => e.profession === entry.profession) === index).map((option, index) => (
                                                        <option
                                                            key={index}
                                                            value={option.profession}
                                                        >
                                                            {option.profession_name}
                                                        </option>
                                                    ))}
                                                </select>
                                                <label className={values.profession == "" ? "did-floating-label did-floating-label-down" : "did-floating-label did-floating-label-top"}>Profession*</label>
                                            </div>
                                            {errors.profession && touched.profession ? (
                                                <div className="error-message">{errors.profession}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="did-floating-label-content">
                                                <select className="did-floating-dropdown"
                                                    name="specialty"
                                                    value={values.specialty}
                                                    onChange={handleChange}
                                                    onClick={(e) => {
                                                        const selectedOption = e.target.options[e.target.selectedIndex];
                                                        const specialtyName = selectedOption.getAttribute('data-name');
                                                        setFieldValue('specialty', e.target.value)
                                                        setFieldValue('specialty_name', specialtyName)
                                                    }}
                                                >
                                                    <option
                                                        value=""
                                                    >
                                                    </option>
                                                    {staffData?.staff?.job_profile?.degree_specialities && JSON.parse(staffData?.staff?.job_profile?.degree_specialities)?.filter(x => x.profession == values.profession)?.map((option, index) => (
                                                        <option
                                                            key={index}
                                                            value={option.specialties}
                                                            data-name={option.specialties_name}
                                                        >
                                                            {option.specialties_name}
                                                        </option>
                                                    ))}
                                                </select>
                                                <label className={values.specialty == "" ? "did-floating-label did-floating-label-down" : "did-floating-label did-floating-label-top"}>Specialty</label>
                                            </div>
                                        </div>
                                        <div className="pb-2 col-sm-6">
                                            <SelectField
                                                name="category"
                                                label={"Category*"}
                                                value={values.category}
                                                onChange={(e) => {
                                                    setFieldValue("category", e.target.value)
                                                    setValues([])
                                                    setDates([])
                                                    setAllDates([])
                                                    setFieldValue("tenure", "")
                                                    setFieldValue("alldate", "")
                                                    setFieldValue("alltime", "")
                                                    setFieldValue("alltype", "")
                                                }}
                                                onBlur={handleBlur}
                                                data={[{ label: "Per Diem", value: "PD" }, { label: "Traveler", value: "TR" }]}
                                                optionLabel={"label"}
                                                optionValue={"value"}
                                            />
                                            {errors.category && touched.category ? (
                                                <div className="error-message">{errors.category}</div>
                                            ) : null}
                                        </div>
                                        {values.category === 'TC' &&
                                            <>
                                                <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                    <SelectField
                                                        name="shift_type"
                                                        label={"Shift Type"}
                                                        value={values.shift_type}
                                                        onChange={(event) => {
                                                            setFieldValue("shift_type", event.target.value)
                                                            setFieldValue(`start_time`, global.config.shiftTimeRange?.find(x => x.label === event.target.value)?.value?.start)
                                                            setFieldValue(`end_time`, global.config.shiftTimeRange?.find(x => x.label === event.target.value)?.value?.end)
                                                        }}
                                                        onBlur={handleBlur}
                                                        required
                                                        data={global.config.shiftType}
                                                        optionLabel={"name"}
                                                        optionValue={"value"}
                                                    />
                                                    {errors.shift_type && touched.shift_type ? (
                                                        <div className="error-message">{errors.shift_type}</div>
                                                    ) : null}
                                                </div>

                                                <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                    <InputField
                                                        className={"did-floating-input"}
                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                        type="text"
                                                        name="pay_detail"
                                                        label={"Pay Detail"}
                                                        value={values.pay_detail}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        required={true}
                                                    />
                                                    {errors.pay_detail && touched.pay_detail ? (
                                                        <div className="error-message">{errors.pay_detail}</div>
                                                    ) : null}
                                                </div>

                                                <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                    <InputField
                                                        className={"did-floating-input"}
                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                        type="date"
                                                        name="start_date"
                                                        label={"Start Date"}
                                                        value={values.start_date}
                                                        min={new Date().toISOString().split('T')[0]}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        required={true}
                                                    />
                                                    {errors.start_date && touched.start_date ? (
                                                        <div className="error-message">{errors.start_date}</div>
                                                    ) : null}
                                                </div>
                                                <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                    <InputField
                                                        className={"did-floating-input"}
                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                        type="date"
                                                        name="end_date"
                                                        label={"End Date"}
                                                        value={values.end_date}
                                                        min={values.start_date ? values.start_date : new Date().toISOString().split('T')[0] || values.start_date}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        required={true}
                                                    />
                                                    {errors.end_date && touched.end_date ? (
                                                        <div className="error-message">{errors.end_date}</div>
                                                    ) : null}
                                                </div>
                                                <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                    <InputField
                                                        className={"did-floating-input"}
                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                        type="time"
                                                        name="start_time"
                                                        label={"Start Time"}
                                                        value={values.start_time}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        required={true}
                                                    />
                                                    {errors.start_time && touched.start_time ? (
                                                        <div className="error-message">{errors.start_time}</div>
                                                    ) : null}
                                                </div>
                                                <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                    <InputField
                                                        className={"did-floating-input"}
                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                        type="time"
                                                        name="end_time"
                                                        label={"End Time"}
                                                        value={values.end_time}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        required={true}
                                                    />
                                                    {errors.end_time && touched.end_time ? (
                                                        <div className="error-message">{errors.end_time}</div>
                                                    ) : null}
                                                </div>
                                                <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                    <InputField
                                                        className={"did-floating-input"}
                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                        type="number"
                                                        name="hours_per_week"
                                                        label={"Guaranteed Hours Per Week"}
                                                        value={values.ghpw}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        required={true}
                                                        min={1}
                                                    />
                                                    {errors.hours_per_week && touched.hours_per_week ? (
                                                        <div className="error-message">{errors.hours_per_week}</div>
                                                    ) : null}
                                                </div>
                                                <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                    <InputField
                                                        className={"did-floating-input"}
                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                        type="number"
                                                        name="no_of_weeks"
                                                        label={"# of Weeks"}
                                                        value={values.no_of_weeks}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        required={true}
                                                        min={1}
                                                    />
                                                    {errors.no_of_weeks && touched.no_of_weeks ? (
                                                        <div className="error-message">{errors.no_of_weeks}</div>
                                                    ) : null}
                                                </div>
                                                <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                    <InputField
                                                        className={"did-floating-input"}
                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                        type="number"
                                                        name="nop"
                                                        label={"# of Openings"}
                                                        value={values.nop}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        required={true}
                                                        min={1}
                                                    />
                                                    {errors.nop && touched.nop ? (
                                                        <div className="error-message">{errors.nop}</div>
                                                    ) : null}
                                                </div>
                                                <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                    <InputField
                                                        className={"did-floating-input"}
                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                        type="text"
                                                        name="qualifications"
                                                        label={"Qualifications"}
                                                        value={values.qualifications}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        required={true}
                                                    />
                                                    {errors.qualifications && touched.qualifications ? (
                                                        <div className="error-message">{errors.qualifications}</div>
                                                    ) : null}
                                                </div>
                                            </>
                                        }
                                        <div className="pb-2 col-sm-6">
                                            {values.category === 'PD' ? <DatePicker
                                                disabled={values.category === null}
                                                required
                                                calendarPosition="top-left"
                                                placeholder="Select Dates"
                                                format="MM/DD/YYYY"
                                                inputClass="did-floating-dropdown"
                                                multiple
                                                value={multi}
                                                minDate={new Date()}
                                                onChange={(e) => {
                                                    setValues(e)
                                                    setFieldValue('tenure', e)
                                                }}
                                                plugins={[
                                                    weekends()
                                                ]}
                                            /> : values.category === "TR" ?
                                                <DatePicker
                                                    disabled={values.category === null}
                                                    placeholder="Select Start & End Date"
                                                    calendarPosition="top-left"
                                                    required
                                                    format="MM/DD/YYYY"
                                                    inputClass="did-floating-dropdown"
                                                    multiple
                                                    range
                                                    rangeHover
                                                    value={dates}
                                                    minDate={new Date()}
                                                    onChange={dateObjects => {
                                                        setDates(dateObjects)
                                                        setAllDates(dateObjects?.map((date) => getAllDatesInRange([date[0], date[1]]))?.flat())
                                                        setFieldValue('tenure', dateObjects?.map((date) => getAllDatesInRange([date[0], date[1]]))?.flat())

                                                    }}
                                                    plugins={[
                                                        weekends(),
                                                        <ClearButton setFiled={setFieldValue} position="bottom" />

                                                    ]}
                                                /> : ""}
                                        </div>

                                        <div>
                                            {allDates?.length > 0 &&
                                                <div>
                                                    <div className="d-flex justify-content-start mt-2 mb-2">Shifts:</div>
                                                    <ul className="list-unstyled">
                                                        <FieldArray name="tenure">
                                                            {({ push, remove, form }) => (
                                                                values?.tenure && values?.tenure?.length != 0 && values?.tenure?.map((date, index) =>
                                                                    <div className="card border p-2 mb-2">
                                                                        <div className="col-2 pt-1 pb-2" >
                                                                            <SelectField
                                                                                id={"slot-type"}
                                                                                label={"Shift Type"}
                                                                                name={`alltype[${index}]`}
                                                                                onChange={(event) => {
                                                                                    setFieldValue(`alltype[${index}]`, event.target.value)
                                                                                    setFieldValue(`alltime[${index}]`, [global.config.shiftTimeRange?.find(x => x.label === event.target.value)?.value?.start, global.config.shiftTimeRange?.find(x => x.label === event.target.value)?.value?.end])
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                                value={values?.alltype && values?.alltype[index]}
                                                                                required
                                                                                data={global.config.shiftType}
                                                                                optionLabel={"name"}
                                                                                optionValue={"value"}
                                                                            />
                                                                        </div>
                                                                        <li key={index}>
                                                                            <input className="slot" id={`alldate[${index}]`} name={`alldate[${index}]`} value={date?.format()} />
                                                                            <Field id={`alltime[${index}]`} name={`alltime[${index}]`} render={({ field, form }) => <TimeRangePickerField  {...field} {...form} firstTime={values?.alltime?.length > 0 && values?.alltime[0]} index={index} />} />
                                                                            <div className="btn position-absolute top-0 end-0" onClick={() => {
                                                                                setAllDates([
                                                                                    ...allDates.slice(0, index),
                                                                                    ...allDates.slice(index + 1)
                                                                                ]);
                                                                                setDates([
                                                                                    ...allDates.slice(0, index),
                                                                                    ...allDates.slice(index + 1)
                                                                                ]);
                                                                                (values?.alltime?.length !== 0 && values?.alltime?.length >= index) && values?.alltime?.splice(index, 1);
                                                                                (values?.alltype?.length !== 0 && values?.alltype?.length >= index) && values?.alltype?.splice(index, 1);

                                                                                remove(index)
                                                                            }}>
                                                                                <i className="fas fa-times-circle text-danger" aria-hidden="true"></i>
                                                                            </div>
                                                                            {values?.tenure?.length > 0 && <div className="btn btn-outline-success ms-2" style={{ padding: " 1px 5px 1px 5px" }}> <i className="far fa-copy pointer" onClick={() => {
                                                                                if (values?.alltime[index]?.length === 2 && !values?.alltime[index]?.some(item => item === null || item === undefined)) {
                                                                                    values?.tenure?.map((item, ind) => {
                                                                                        setFieldValue(`alltime[${ind}]`, values?.alltime[index]);
                                                                                        setFieldValue(`alltype[${ind}]`, values?.alltype[index]);
                                                                                        if (values?.allqty?.length && (values?.allqty[index] !== null || values?.allqty[index] !== undefined)) {
                                                                                            setFieldValue(`allqty[${ind}]`, values?.allqty[index])
                                                                                        }
                                                                                        if (values?.alltype?.length && (values?.alltype[index] !== null || values?.alltype[index] !== undefined)) {
                                                                                            setFieldValue(`alltype[${ind}]`, values?.alltype[index])
                                                                                        }
                                                                                    })
                                                                                } else {
                                                                                    toast.warn("Please select start end time")
                                                                                }
                                                                            }} data-toggle="tooltip" data-placement="right" title="Copy to all"></i></div>}
                                                                            <div className="col-12">
                                                                                <InputField
                                                                                    className={"did-floating-input"}
                                                                                    type="text"
                                                                                    name={`allcomment[${index}]`}
                                                                                    onChange={(event) => {
                                                                                        setFieldValue(`allcomment[${index}]`, event.target.value)
                                                                                    }}
                                                                                    label={"Comments For Caregiver"}
                                                                                    value={values?.allcomment && values?.allcomment[index]}
                                                                                    onBlur={handleBlur}
                                                                                />
                                                                            </div>
                                                                        </li>
                                                                    </div>
                                                                )
                                                            )}
                                                        </FieldArray>

                                                    </ul>
                                                </div>
                                            }
                                            {multi?.length > 0 && multi &&
                                                <div>
                                                    <div className="d-flex justify-content-start mt-2 mb-2">Shifts:</div>
                                                    <ul className="list-unstyled">
                                                        <FieldArray name="tenure">
                                                            {({ push, remove, form }) => (
                                                                values?.tenure && values?.tenure?.length != 0 && values?.tenure.map((date, index) =>
                                                                    <div className="card border p-2 mb-2">
                                                                        <div className="col-2 pt-1 pb-2" >
                                                                            <SelectField
                                                                                id={"slot-type"}
                                                                                label={"Shift Type"}
                                                                                name={`alltype[${index}]`}
                                                                                onChange={(event) => {
                                                                                    setFieldValue(`alltype[${index}]`, event.target.value)
                                                                                    setFieldValue(`alltime[${index}]`, [global.config.shiftTimeRange?.find(x => x.label === event.target.value)?.value?.start, global.config.shiftTimeRange?.find(x => x.label === event.target.value)?.value?.end])
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                                value={values?.alltype && values?.alltype[index]}
                                                                                required
                                                                                data={global.config.shiftType}
                                                                                optionLabel={"name"}
                                                                                optionValue={"value"}
                                                                            />
                                                                        </div>
                                                                        <li key={index}>
                                                                            <input className="slot" id={`alldate[${index}]`} name={`alldate[${index}]`} value={date?.format()} />
                                                                            <Field id={`alltime[${index}]`} name={`alltime[${index}]`} render={({ field, form }) => <TimeRangePickerField  {...field} {...form} firstTime={values?.alltime?.length > 0 && values?.alltime[0]} index={index} />} />
                                                                            <div className="btn position-absolute top-0 end-0" onClick={() => {
                                                                                setValues([
                                                                                    ...multi.slice(0, index),
                                                                                    ...multi.slice(index + 1)
                                                                                ]);
                                                                                (values?.alltime?.length !== 0 && values?.alltime?.length >= index) && values?.alltime?.splice(index, 1);
                                                                                (values?.alltype?.length !== 0 && values?.alltype?.length >= index) && values?.alltype?.splice(index, 1);

                                                                                remove(index)
                                                                            }}>
                                                                                <i className="fas fa-times-circle text-danger" aria-hidden="true"></i>
                                                                            </div>
                                                                            {values?.tenure?.length > 1 && <div className="btn btn-outline-success ms-2" style={{ padding: " 1px 5px 1px 5px" }}>  <i className="far fa-copy pointer" onClick={() => {
                                                                                if (values?.alltime[index]?.length === 2 && !values?.alltime[index]?.some(item => item === null || item === undefined)) {
                                                                                    values?.tenure?.map((item, ind) => {
                                                                                        setFieldValue(`alltime[${ind}]`, values?.alltime[index]);
                                                                                        if (values?.allqty?.length && (values?.allqty[index] !== null || values?.allqty[index] !== undefined)) {
                                                                                            setFieldValue(`allqty[${ind}]`, values?.allqty[index])
                                                                                        }
                                                                                        if (values?.alltype?.length && (values?.alltype[index] !== null || values?.alltype[index] !== undefined)) {
                                                                                            setFieldValue(`alltype[${ind}]`, values?.alltype[index])
                                                                                        }
                                                                                    })
                                                                                } else {
                                                                                    toast.warn("Please select start end time")
                                                                                }
                                                                            }} data-toggle="tooltip" data-placement="right" title="Copy to all"></i></div>}
                                                                            <div className="col-12">
                                                                                <InputField
                                                                                    className={"did-floating-input"}
                                                                                    type="text"
                                                                                    name={`allcomment[${index}]`}
                                                                                    onChange={(event) => {
                                                                                        setFieldValue(`allcomment[${index}]`, event.target.value)
                                                                                    }}
                                                                                    label={"Comments For Caregiver"}
                                                                                    value={values?.allcomment && values?.allcomment[index]}
                                                                                    onBlur={handleBlur}
                                                                                />
                                                                            </div>
                                                                        </li>
                                                                    </div>
                                                                )
                                                            )}
                                                        </FieldArray>
                                                    </ul>
                                                </div>
                                            }
                                        </div>
                                        <div className="pb-2 col-sm-6">
                                            <Select
                                                onChange={(e) => { setFieldValue("state", e.abbreviation), setFieldValue("city", '') }}
                                                placeholder={''}
                                                label={'State*'}
                                                styles={colourStyles}
                                                value={states.filter(({ abbreviation }) => values?.state?.includes(abbreviation)) || ''}
                                                options={states}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.abbreviation}
                                                components={{ Control, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                            />
                                            {errors.state && touched.state ? (
                                                <div className="error-message">{errors.state}</div>
                                            ) : null}
                                        </div>
                                        <div className="pb-2 col-sm-6">
                                            <Select
                                                onChange={(e) => setFieldValue("city", e.label)}
                                                placeholder={''}
                                                label={'City*'}
                                                styles={colourStyles}
                                                getOptionLabel={(option) => option.label}
                                                getOptionValue={(option) => option.label}
                                                value={citys.filter(({ label }) => values?.city === label) || ''}
                                                options={citys?.filter(ct => ct.state === states?.find(x => x.abbreviation == values?.state)?.name)}
                                                components={{ Control, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                            />
                                            {errors.city && touched.city ? (
                                                <div className="error-message">{errors.city}</div>
                                            ) : null}
                                        </div>
                                        <div className="pb-2 col-sm-12">
                                            <SelectField
                                                name="job_type"
                                                label={"Job Type"}
                                                value={values.job_type}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                data={global.config.jobType}
                                                optionLabel={"name"}
                                                optionValue={"value"}
                                            />
                                        </div>
                                        <div className="form-group col-sm-12">
                                            <label className="pb-2 label">Comments for Staffers Management</label>
                                            <textarea className="form-control"
                                                placeholder=""
                                                name={'description'}
                                                value={values.description}
                                                onChange={handleChange('description')}
                                            >
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-center">
                                    <button type="submit" className="btn btn-primary w-25">Submit</button>
                                </div>
                            </form>
                        )
                    }
                </Formik>
                {/* </CModalBody> */}
            </CModal>
        </>
    )
})

function TimeRangePickerField(props) {
    const hnadleStart = (e) => {
        const startTime = e.target.value;
        if (props?.values?.alltime?.length === 0) {
            props.setFieldValue(props.name, [startTime, undefined]);
        } else {
            props.setFieldValue(props.name, [startTime, props?.values?.alltime[props.index]?.length === 2 ? props?.values?.alltime[props.index][1] : undefined]);
        }
    }
    const hnadleEnd = (e) => {
        const endTime = e.target.value;
        if (props?.values?.alltime?.length === 0) {
            props.setFieldValue(props.name, [undefined, endTime]);
        } else {
            if (props?.values?.alltime[props.index][0] !== endTime) {
                props.setFieldValue(props.name, [props?.values?.alltime[props.index][0], endTime]);
            } else {
                toast.error("The start and finish timings must not be the same!")
            }
        }
    }
    return (
        <>
            <input style={{ lineHeight: 2 }} required type="time" id="start" name="start" value={props?.values?.alltime[props?.index] ? props?.values?.alltime[props?.index][0] : ''} onChange={hnadleStart} />
            <span style={{ padding: 5, paddingBottom: 6 }} className="timer-form">To</span>
            <input style={{ lineHeight: 2 }} required type="time" className="timer-border" id="end" name="end" disabled={props?.values?.alltime[props?.index] ? props?.values?.alltime[props?.index][0] ? false : true : true} value={props?.values?.alltime[props?.index]?.length === 2 ? props?.values?.alltime[props.index][1] : ""} onChange={hnadleEnd} />
        </>
    );
}
export const Control = (props) => {
    return (
        <>
            <label className={props.hasValue || props.isFocused ? "did-floating-label did-floating-label-top" : "did-floating-label did-floating-label"} style={{ zIndex: 1 }}>{props?.selectProps?.label}</label>
            <components.Control {...props} />
        </>
    );
};

export default FavoriteList;