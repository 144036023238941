import React from "react";
import StarRating from "./StarRating";
import moment from "moment";
import LimitString from "../../helpers/LimitString";
import Skeleton from "react-loading-skeleton";
import WebsiteStore from "../../store/WebsiteStore";

const FeedbackCard = ({ feedbackData }) => {
  const isLoading = WebsiteStore.loading;

  return (
    <div>
      <div className="slider-grad-box">
        <div className="rating-top">
          {isLoading ? (
            <Skeleton width={100} height={20} />
          ) : (
            <StarRating stars={feedbackData?.stars || 3} />
          )}
        </div>
        <div className="row">
          <div className="col-md-12 content d-flex">
            {isLoading ? (
              <Skeleton count={3} />
            ) : (
              <p>{LimitString(feedbackData?.feedback, 200)}</p>
            )}
          </div>
          <div className="col-md-12 content-right">
            {isLoading ? (
              <Skeleton width={100} />
            ) : (
              <h6>{feedbackData?.name}</h6>
            )}
          </div>
          <div className="col-md-12 content-right">
            {isLoading ? (
              <Skeleton width={150} />
            ) : (
              <h6>
                {moment(feedbackData?.created_at).format("MMMM DD, YYYY")}
              </h6>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackCard;
