

module.exports = global.config = {
    app_key: "AuthHandShake@Santeware",
    app_password: "Staffers321!",
    token: localStorage.getItem('refresh-token') ? localStorage.getItem('refresh-token') : "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJjb21wYW55X2lkIjoiMjA3OSIsImNsaWVudF9pZCI6InN0YWZmZXJzbGxjIiwidG9rZW5faWQiOiI0QzkzM0ZEMTBFNzE0NDU1QTgxMjkwNkNCMUZEMzgwNCIsImFwcGxpY2F0aW9uX2lkIjoiMTA0MyIsInVzZXJuYW1lIjoiU3RhZmZlcnNBUElVc2VyIiwiZ3VpZCI6IjJBQjc2RDk1LTc5Q0MtNDE4Qi1BQ0EwLUY2RDREN0M2OTQwMyIsInVzZXJfaWQiOiIyOTk0IiwidXNlcl9ncm91cF9pZCI6IjIzMiIsInJlZ2lvbl9pZCI6IjEiLCJwb3J0YWxfaWQiOiI4IiwicG9ydGFsX2dyb3VwX2lkIjoiMSIsInNjX2lkIjoiLTEiLCJwYXJlbnRfaWQiOiI1NyIsImVudGl0eV9pZCI6IjU3IiwiaXNTdXAiOiJGYWxzZSIsImlzSW1wIjoiRmFsc2UiLCJnZW5lcmljIjoiMCIsImVtYWlsIjoibWl0aHVuQHNhbnRld2FyZS5jb20iLCJtZmFfdHlwZSI6IjAiLCJpc3MiOiJCU1MiLCJhdWQiOiJCU1NfQVVESUVOQ0UiLCJleHAiOjE2ODQyODMzODAsIm5iZiI6MTY4NDI0NzM4MH0.kNow2vkHVtgxiM6nLEV9EelIY24SiyVlmUPHii5H9VA",
    organizationType: [{ label: "Hospital", value: "Hospital" }, { label: "SNF/LTC", value: "SNF/LTC" }, { label: "Home Health", value: "Home Health" }, { label: "Hospice", value: "Hospice" }, { label: "Assisted Living", value: "Assisted Living" }, { label: "Clinic", value: "Clinic" }, { label: "Behavorial Health", value: "Behavorial Health" }, { label: "Other", value: "Other" }],
    tenureList: [{ label: "Day(s)", value: "Day(s)" }, { label: "Week(s)", value: "Week(s)" }, { label: "Month(s)", value: "Month(s)" }, { label: "Year(s)", value: "Year(s)" }],
    titleList: [{ label: "Mr.", value: "Mr." }, { label: "Mrs.", value: "Mrs." }, { label: "Ms.", value: "Ms." }, { label: "Dr.", value: "Dr." }, { label: "Jr.", value: "Jr" }, { label: "Prof. ", value: "Prof." }, { label: "Sr.", value: "Sr." }, { label: "St.", value: "St." }, { label: "Mx.", value: "Mx." }, { label: "None", value: "None" }],
    jobType: [{ "name": "Shifts", "value": 0, "type": "Job Type" }, { "name": "Permanent", "value": 1, "type": "Job Type" }, { "name": "All", "value": 2, "type": "Job Type" }],
    status: [{ "name": "Pending", "value": 0 }, { "name": "Filling", "value": 1 }, { "name": "Rejected", "value": 2 }, { "name": "Closed", "value": 3 }, { "name": "Extended", "value": 4 }],
    shiftType: [{ "name": "Day 8h", "value": "Day 8h" }, { "name": "Evening 8h", "value": "Evening 8h" }, { "name": "NOC 8h", "value": "NOC 8h" }, { "name": "Day 12h", "value": "Day 12h" }, { "name": "NOC 12h", "value": "NOC 12h" }],
    shiftDay: [{ "label": "Morning", "value": "Morning" }, { "label": "Afternoon", "value": "Afternoon" }, { "label": "Nights", "value": "Nights" }],
    shiftWeeks: [{ "label": "Monday", "value": "Monday" }, { "label": "Tuesday", "value": "Tuesday" }, { "label": "Wednesday", "value": "Wednesday" }, { "label": "Thursday", "value": "Thursday" }, { "label": "Friday", "value": "Friday" }, { "label": "Saturday", "value": "Saturday" }, { "label": "Sunday", "value": "Sunday" }],
    ratingOptions: [{ "label": "4★ & above", "value": "4" }, { "label": "3★ & above", "value": "3" }, { "label": "1★ & above", "value": "1" }],
    interestedIn: [{ "label": "Travel", "value": "Travel" }, { "label": "Local Shifts", "value": "Local Shifts" }, { "label": "Permanent Placement", "value": "Permanent Placement" }],
    reasonoptions: [
        { value: "100143", label: "Onboarding Initiated" },
        { value: "100144", label: "Onboarding In Process" },
        { value: "100145", label: "Onboarding Complete" },
        { value: "100209", label: "Outstanding Compliance Items" },
        { value: "100210", label: "Resignation With Notice" },
        { value: "100211", label: "Hasn't Worked In 90 Days" },
        { value: "100212", label: "Resignation Without Notice" },
        { value: "199", label: "Do Not Hire" },
        { value: "314", label: "Prospect" },
        { value: "315", label: "Dormant" },
        { value: "316", label: "Inactive" },
        { value: "317", label: "Terminated By Agency" },
        { value: "100184", label: "Recruiting Call-LM" },
        { value: "100185", label: "Not Qualified" },
        { value: "100186", label: "Recruiting Call-Message Sent" },
        { value: "100221", label: "Withdrawn" },
    ],
    blueSkyStatusOptions: [
        { value: null, text: 'Profile Incomplete' },
        { value: 0, text: 'Pending' },
        { value: 1, text: 'Incomplete Applicant' },
        { value: 2, text: 'Rejected' },
        { value: 3, text: 'Active' },
        { value: 4, text: 'In-Active' },
        { value: 5, text: 'Applicant' },
        { value: 6, text: 'Pre-Active' }
    ],
    blueSkyStatusOptionsFilter: [
        { value: -1, text: 'Profile Incomplete' },
        { value: -2, text: 'Pending For Approval' },
        { value: 1, text: 'Incomplete Applicant' },
        { value: 2, text: 'Rejected' },
        { value: 3, text: 'Active' },
        { value: 4, text: 'In-Active' },
        { value: 5, text: 'Applicant' },
        { value: 6, text: 'Pre-Active' }
    ],
    shiftCaregory: [
        { value: "PD", label: 'Per Diem' },
        { value: "TR", label: 'Local Contract' },
        { value: "TC", label: 'Traveler' }
    ],
    shiftTimeRange: [
        { value: { start: '06:00', end: '14:00' }, label: 'Day 8h' },
        { value: { start: '14:00', end: '22:00' }, label: 'Evening 8h' },
        { value: { start: '22:00', end: '06:00' }, label: 'NOC 8h' },
        { value: { start: '06:00', end: '18:00' }, label: 'Day 12h' },
        { value: { start: '18:00', end: '06:00' }, label: 'NOC 12h' },
    ],
    radiusMiles: [
        { value: 10, label: '10 miles' },
        { value: 25, label: '25 miles' },
        { value: 40, label: '40 miles' },
        { value: 80, label: '80 miles' },
        { value: 100, label: '100 miles' },
        { value: 250, label: '250 miles' },
        { value: 500, label: '500 miles' },
        { value: 1000, label: '1000 miles' },
        { value: 1500, label: '1500 miles' },
        { value: 2000, label: '2000 miles' },
    ],

    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth
};

