/* eslint-disable */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react"
import HeaderPage from "../layout/HeaderPage";
import DrawerMenuPage from "../layout/DrawerMenuPage";
import { CButton, CListGroup, CListGroupItem, CTable, CTableHead, CTableRow, CTableHeaderCell, CTableBody, CFormCheck, CTableDataCell } from "@coreui/react";
import './StaffingRequestList.css'
import 'react-spring-bottom-sheet/dist/style.css';
import AdminStore from "@store/AdminStore";
import HomeStore from "@store/HomeStore";
import Spinner from "@components/Spinner";
import { dropdownStatusStyles } from '@utils/customStyle/style';
import Select, { components } from 'react-select';
import { toast } from "react-toastify";
import moment from "moment";
import { toJS } from "mobx";
import { Helmet } from "react-helmet";

const PendingStaffingRequestList = observer(() => { // Caregiver Pending Shift
    const [staffShiftsList, setStaffShiftsList] = useState([]);
    const [staffExtShiftsList, setStaffExtShiftsList] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [degree, setDegree] = useState([]);
    const [selectedAllIds, setSelectedAllIds] = useState([]);
    const [selectedAll, setSelectedAll] = useState();
    const [selectedId, setSelectedId] = useState([]);
    //EXTENSION
    const [selectedAllExtIds, setSelectedAllExtIds] = useState([]);
    const [selectedAllExt, setSelectedAllExt] = useState();
    const [selectedExtId, setSelectedExtId] = useState([]);

    useEffect(() => {
        AdminStore.getStaffPendingSlots()
    }, []);

    useEffect(() => {
        setDegree(HomeStore?.data?.degreeList)
    }, [HomeStore?.data?.degreeList]);

    useEffect(() => {
        let pendingShifts = AdminStore.data.staffPendingSlots?.contracts?.concat(AdminStore.data.staffPendingSlots?.data);
        let pendingExtShifts = AdminStore.data.staffPendingSlots?.ex_contracts?.concat(AdminStore.data.staffPendingSlots?.ex_data);
        setStaffShiftsList(pendingShifts);
        setStaffExtShiftsList(pendingExtShifts);
        let mergeShifts = pendingShifts?.concat(pendingExtShifts)
        let clienOpt = mergeShifts?.map(x => ({
            label: x.category === 'TC' ? x.staff?.first_name : x.first_name,
            value: x.category === 'TC' ? x.staff.id : x.id
        }))

        const uniqueClient = clienOpt?.filter((obj, index, self) =>
            index === self.findIndex((o) =>
                o.label === obj.label && o.value === obj.value
            )
        );
        setClientOptions(uniqueClient)
    }, [AdminStore.data.staffPendingSlots]);

    const handleSelectChange = (event) => {
        setSelectedOption(event);
        setStaffShiftsList(AdminStore.data.staffPendingSlots?.contracts?.concat(AdminStore.data.staffPendingSlots?.data)?.filter(x => x?.id === event.value || x?.staff.id === event.value));
        setStaffExtShiftsList(AdminStore.data.staffPendingSlots?.ex_contracts?.concat(AdminStore.data.staffPendingSlots?.ex_data)?.filter(x => x?.id === event.value || x?.staff.id === event.value));
        setSelectedAll()
        setSelectedAllIds([])
        setSelectedId()
        // Perform any additional actions based on the selected value
    };

    const handleClearClick = () => {
        setSelectedOption('');
        setStaffShiftsList(AdminStore.data.staffPendingSlots?.contracts?.concat(AdminStore.data.staffPendingSlots?.data));
        setStaffExtShiftsList(AdminStore.data.staffPendingSlots?.ex_contracts?.concat(AdminStore.data.staffPendingSlots?.ex_data));
        setSelectedAll()
        setSelectedAllIds([])
        setSelectedId()
        // Perform any additional actions needed after clearing the selection
    };
    //EXPORT CSV
    const exportFile = (client) => {
        if (client?.category !== 'TC') {
            const mergedArray = [].concat(...client?.requests?.map(x => x.pending_assignments));
            const headerMapping = {
                id: 'Slot Id',
                slot_date: 'Date',
                slot_start_time: 'Start Time',
                slot_end_time: 'End Time',
                user_id: 'Organization Name',
            };
            const csv = convertArrayToCSV(mergedArray, headerMapping);
            const blob = new Blob([csv], { type: 'text/csv' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${client?.first_name?.replace(/ /g, '_')}.csv`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            toast.success("Successfully downloaded.");
        } else {
            const headerMapping = {
                id: 'Shift Id',
                start_date: 'Start Date',
                end_date: 'End Date',
                start_time: 'Start Time',
                end_time: 'End Time',
                user_id: 'Organization Name',
            };
            const csv = convertArrayToCSV([client], headerMapping);
            const blob = new Blob([csv], { type: 'text/csv' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${client.staff?.first_name?.replace(/ /g, '_')}.csv`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            toast.success("Successfully downloaded.");
        }

    }

    function convertArrayToCSV(array, headerMapping) {
        const headers = Object.values(headerMapping);
        const headerRow = headers.join(',');
        const rows = array.map(obj => {
            return Object.keys(headerMapping).map(key => {
                if (key === 'user_id') {
                    return obj?.user.organization_name;
                } else {
                    return obj[key]
                }
            }).join(',');
        });
        return `${headerRow}\n${rows.join('\n')}`;
    }

    //SELECT CHECKBOX FOR CONTRACT AND SLOT
    const handleSelectAllRows = (e, client) => {//2 for approve 1 for reject
        if (client?.category !== 'TC') {
            setSelectedId([])
            const mergedArray = [].concat(...client?.requests?.map(x => toJS(x.pending_assignments)));
            if (e.target.checked) {
                if (e.target.value === 'Approve') {
                    let selectedAllIds = mergedArray?.map(x => { return { "id": x.id, "code": 2 } })
                    setSelectedAllIds(selectedAllIds)
                    setSelectedAll('Approve' + client?.id)
                    return
                }
                if (e.target.value === 'Reject') {
                    let selectedAllIds = mergedArray?.map(x => { return { "id": x.id, "code": 1 } })
                    setSelectedAllIds(selectedAllIds)
                    setSelectedAll('Reject' + client?.id)
                    return
                }
            }
        } else {
            setSelectedId([])
            if (e.target.checked) {
                if (e.target.value === 'Approve') {
                    let selectedAllIds = { "id": client.id, "code": 5, type: 'TC' }
                    setSelectedAllIds(selectedAllIds)
                    setSelectedAll('Approve' + client?.id)
                    setSelectedAll('Approve' + client?.id)
                    return
                }
                if (e.target.value === 'Reject') {
                    let selectedAllIds = { "id": client.id, "code": 100, type: 'TC' }
                    setSelectedAllIds(selectedAllIds)
                    setSelectedAll('Reject' + client?.id)
                    return
                }
            }
        }
    };
    const slotAdd = (type, slot, client_id) => {
        if (slot?.category !== 'TC') {
            const index = selectedId?.findIndex(item => item.id === slot.id);
            const newId = { id: slot.id, code: type === 'Approve' ? 2 : 1, client_id: client_id };
            let check = selectedId?.every(item => item.client_id === client_id)
            if (check) {
                if (index !== -1) {
                    const updatedSelectedId = [...selectedId];
                    updatedSelectedId[index] = newId;
                    setSelectedId(updatedSelectedId);
                } else {
                    setSelectedId(prevSelectedId => [...prevSelectedId, newId]);
                }
            } else {
                // If client_id is null or undefined, clear the selectedId array and then add the newId.
                setSelectedId([newId]);
            }
        } else {
            const newId = { id: slot.id, code: type === 'Approve' ? 5 : 100, type: 'TC' };
            setSelectedId(newId)
        }

    };

    //APP/REJ API CALL
    const openModal = () => {
        if (selectedAllIds?.type === "TC" || selectedId?.type === "TC") {
            swal({
                title: "Are you sure to update this contract?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        let param = {
                            'request_id': selectedAllIds?.id || selectedId?.id,
                            'status_id': selectedAllIds?.code || selectedId?.code,
                        }
                        AdminStore.updateContract(param, navigationCallBack)
                    }
                });
        } else {
            swal({
                title: "Are you sure to update this slot(s)?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        let param = {
                            'slots': selectedAllIds?.length > 0 ? JSON.stringify(selectedAllIds) : JSON.stringify(selectedId)
                        }
                        AdminStore.updateSlots(param, navigationCallBack)
                    }
                });
        }
    }
    const navigationCallBack = () => {
        swal("Success!", "Shift/Slot Approval/Reject has been submitted successfully.", "success");
        AdminStore.getStaffPendingSlots()
    }


    //FOR  SELECT CHECKBOX FOR EXTENSION CONTRACT AND SLOT
    const handleSelectAllRowsExt = (e, client) => {//2 for approve 1 for reject
        if (client?.category !== 'TC') {
            setSelectedExtId([])
            const mergedArray = [].concat(...client?.requests?.map(x => toJS(x.pending_assignments)));
            if (e.target.checked) {
                if (e.target.value === 'Approve') {
                    let selectedAllExtIds = mergedArray?.map(x => { return { "id": x.id, "code": 2 } })
                    setSelectedAllExtIds(selectedAllExtIds)
                    setSelectedAllExt('Approve' + client?.id)
                    return
                }
                if (e.target.value === 'Reject') {
                    let selectedAllExtIds = mergedArray?.map(x => { return { "id": x.id, "code": 1 } })
                    setSelectedAllExtIds(selectedAllExtIds)
                    setSelectedAllExt('Reject' + client?.id)
                    return
                }
            }
        } else {
            setSelectedExtId([])
            if (e.target.checked) {
                if (e.target.value === 'Approve') {
                    let selectedAllExtIds = { "id": client.id, "code": 5, type: 'TC' }
                    setSelectedAllExtIds(selectedAllExtIds)
                    setSelectedAllExt('Approve' + client?.id)
                    setSelectedAllExt('Approve' + client?.id)
                    return
                }
                if (e.target.value === 'Reject') {
                    let selectedAllIds = { "id": client.id, "code": 100, type: 'TC' }
                    setSelectedAllExtIds(selectedAllIds)
                    setSelectedAllExt('Reject' + client?.id)
                    return
                }
            }
        }
    };
    const slotExtAdd = (type, slot, client_id) => {
        if (slot?.category !== 'TC') {
            const index = selectedExtId?.findIndex(item => item.id === slot.id);
            const newId = { id: slot.id, code: type === 'Approve' ? 2 : 1, client_id: client_id };
            let check = selectedExtId?.every(item => item.client_id === client_id)
            if (check) {
                if (index !== -1) {
                    const updatedSelectedId = [...selectedId];
                    updatedSelectedId[index] = newId;
                    setSelectedExtId(updatedSelectedId);
                } else {
                    setSelectedExtId(prevSelectedId => [...prevSelectedId, newId]);
                }
            } else {
                // If client_id is null or undefined, clear the selectedId array and then add the newId.
                setSelectedExtId([newId]);
            }
        } else {
            const newId = { id: slot.id, code: type === 'Approve' ? 5 : 100, type: 'TC' };
            setSelectedExtId(newId)
        }
    };
    //APP/REJ EXT API CALL
    const openModalExt = () => {
        if (selectedAllExtIds?.type === "TC" || selectedExtId?.type === "TC") {
            swal({
                title: "Are you sure to update this contract?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        let param = {
                            'request_id': selectedAllExtIds?.id || selectedExtId?.id,
                            'status_id': selectedAllExtIds?.code || selectedExtId?.code,
                        }
                        console.log("ext tc payload", param);
                        // AdminStore.updateContractExt(param, navigationCallBack)
                    }
                });
        } else {
            swal({
                title: "Are you sure to update this slot(s)?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        let param = {
                            'slots': selectedAllIds?.length > 0 ? JSON.stringify(selectedAllIds) : JSON.stringify(selectedId)
                        }
                        console.log("ext slot payload", param);

                        // AdminStore.updateSlotsExt(param, navigationCallBack)
                    }
                });
        }
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>Staffers LLC | Caregiver Pending Shift</title>
            </Helmet>
            {/* <body> */}
            <div className="container-scroller">
                <HeaderPage />
                <div className="container-fluid page-body-wrapper">
                    <DrawerMenuPage />
                    <div className="main-panel" style={{ right: 0, position: "absolute" }}>
                        <div className="row content-wrapper p-3 bg-white">
                            <h3 className="col-12 mb-2 mb-lg-0 page-title">
                                <span className="page-title-icon linear text-white me-2">
                                    <i className="fas fa-hourglass-half"></i>
                                </span> Pending Shift/Slot(s) By Caregiver
                            </h3>
                        </div>
                        {/* <!-- partial --> */}
                        <div className="container">
                            <label htmlFor="selectInput">Filter by caregiver</label>
                            <div className="row">
                                <div className="row align-items-center">
                                    <div className="col-md-3">
                                        <Select id="selectInput"
                                            placeholder={selectedOption ? "" : "Select a caregiver"}
                                            options={clientOptions}
                                            onChange={handleSelectChange}
                                            value={selectedOption}
                                            className=""
                                            styles={dropdownStatusStyles} // Apply custom styles
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        {selectedOption && <small><button type="button" onClick={handleClearClick} className="btn-close btn-danger" aria-label="Close"></button></small>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ height: 500, paddingTop: 20 }}>
                            {(staffShiftsList?.length === 0 && staffExtShiftsList.length === 0) && <p className="d-flex justify-content-center text-muted">There are no pending slots available!</p>}
                            {staffShiftsList?.length > 0 && staffShiftsList?.map((slt, index) => {
                                return <div key={index + "sltlst"} className="col-12 ms-2 pe-4" id={"approval" + slt?.id}>
                                    <CListGroup>
                                        {slt?.category === 'TC' && <span style={{ position: "relative" }}>
                                            {<span className='suffix-container-list-update-left'><span className="suffix-lable ">Traveler Contract</span></span>}
                                        </span>}
                                        <CListGroupItem className="mb-3">
                                            <div className="d-flex justify-content-between">
                                                <div className="">
                                                    <ul className="list-unstyled">
                                                        <li><h5>{slt?.category === 'TC' ? slt.staff.first_name : slt?.first_name} (<small>{slt?.category === 'TC' ? slt.staff.bluesky_staff_id : slt?.bluesky_staff_id}</small>)&nbsp;
                                                            {(slt?.category === 'TC' ? slt?.staff?.city : slt?.city) && <small><i className="fas fa-map-marker-alt text-primary"></i> {slt?.category === 'TC' ? slt.staff.city : slt?.city},{slt?.category === 'TC' ? slt.staff?.state : slt?.state}</small>}&nbsp;&nbsp;
                                                            <small><i className="fas fa-phone-alt text-primary"></i><a href={"tel:" + (slt?.category === 'TC' ? slt?.staff?.country_code : slt?.country_code) + (slt?.category === 'TC' ? slt.staff?.contact : slt?.contact)} className="signup-link">&nbsp;
                                                                {slt?.category === 'TC' ? slt.staff?.country_code : slt?.country_code} {slt?.category === 'TC' ? slt.staff?.contact : slt?.contact}
                                                            </a> </small>&nbsp;&nbsp;
                                                            <small><i className="fas fa-envelope text-primary"></i>  <a href={"mailto:" + (slt?.category === 'TC' ? slt.staff?.email : slt?.email)} className="signup-link">&nbsp;{(slt?.category === 'TC' ? slt.staff?.email : slt?.email)}</a> </small>
                                                        </h5></li>
                                                    </ul>
                                                </div>
                                                <span>
                                                    <CButton color="warning" onClick={() => exportFile(slt)}>
                                                        <i className="fas fa-file-export text-success"></i> Export
                                                    </CButton>
                                                </span>
                                            </div>
                                            <CTable responsive id={"data-table" + slt?.id}>
                                                <CTableHead>
                                                    <CTableRow>
                                                        <CTableHeaderCell className="p-0 m-0">
                                                            <CFormCheck inline checked={selectedAll === 'Approve' + slt.id}
                                                                onClick={(e) => handleSelectAllRows(e, slt)} data-toggle="tooltip" data-placement="right" title="Approve All"
                                                                style={{ borderColor: "green" }}
                                                                id={'Approve' + slt.id}
                                                                type="radio"
                                                                value="Approve"
                                                                label="Approve All" />
                                                        </CTableHeaderCell>
                                                        <CTableHeaderCell className="p-0 m-0">
                                                            <CFormCheck
                                                                checked={selectedAll === 'Reject' + slt.id}
                                                                onClick={(e) => handleSelectAllRows(e, slt)} data-toggle="tooltip" data-placement="right" title="Reject All"
                                                                inline style={{ borderColor: "red" }}
                                                                id={'Reject' + slt.id}
                                                                type="radio" value="Reject" label="Reject All" />
                                                        </CTableHeaderCell>
                                                        <CTableHeaderCell className="" scope="col">Shift Ref.</CTableHeaderCell>
                                                        <CTableHeaderCell className="" scope="col">Date/Time</CTableHeaderCell>
                                                        <CTableHeaderCell className="" scope="col">Organization ID</CTableHeaderCell>
                                                        <CTableHeaderCell className="" scope="col">Organization Name</CTableHeaderCell>
                                                        <CTableHeaderCell className="" scope="col">Profession</CTableHeaderCell>
                                                        <CTableHeaderCell className="" scope="col">Creation Time</CTableHeaderCell>
                                                        <CTableHeaderCell className="" scope="col">Staff Comment</CTableHeaderCell>
                                                    </CTableRow>
                                                </CTableHead>
                                                <CTableBody>
                                                    {/* FOR CONTRACT ONLY*/}
                                                    {slt?.category === 'TC' && (
                                                        <CTableRow>
                                                            <CTableHeaderCell id={"approve" + slt?.id} className="p-0 m-0">
                                                                <CFormCheck inline
                                                                    data-toggle="tooltip" data-placement="right" title="Approve"
                                                                    style={{ borderColor: "green" }}
                                                                    name={slt?.id}
                                                                    checked={
                                                                        (selectedAllIds?.id === slt?.id && selectedAllIds?.code === 5)
                                                                        || (selectedId?.id === slt?.id && selectedId?.code === 5)}
                                                                    type="radio"
                                                                    label=""
                                                                    value={"Approve"}
                                                                    onClick={(e) => {
                                                                        setSelectedAllIds()
                                                                        setSelectedAll()
                                                                        slotAdd(e.target.value, slt, null);
                                                                    }}
                                                                />
                                                            </CTableHeaderCell>
                                                            <CTableHeaderCell id={"reject" + slt?.id} className="p-0 m-0">
                                                                <CFormCheck inline
                                                                    data-toggle="tooltip" data-placement="right" title="Reject"
                                                                    style={{ borderColor: "red" }}
                                                                    type="radio"
                                                                    name={slt?.id}
                                                                    checked={
                                                                        (selectedAllIds?.id === slt?.id && selectedAllIds?.code !== 5)
                                                                        || (selectedId?.id === slt?.id && selectedId?.code !== 5)}
                                                                    value={"Reject"}
                                                                    label=""
                                                                    onClick={(e) => {
                                                                        setSelectedAllIds()
                                                                        setSelectedAll()
                                                                        slotAdd(e.target.value, slt, null);
                                                                    }}
                                                                />
                                                            </CTableHeaderCell>

                                                            <CTableHeaderCell><strong>{slt?.id}</strong></CTableHeaderCell>

                                                            <CTableHeaderCell scope="row" className="p-0 m-0">
                                                                <div className="time-range-container">
                                                                    <input type="text" disabled value={slt.start_date} />
                                                                    <span>To</span>
                                                                    <input type="text" disabled value={slt.end_date} />
                                                                    <input type="time" disabled id="start" name="start" value={slt.start_time} />
                                                                    <span>To</span>
                                                                    <input type="time" disabled id="end" name="end" value={slt.end_time} />
                                                                </div></CTableHeaderCell>
                                                            <CTableHeaderCell>{slt?.user?.id}</CTableHeaderCell>
                                                            <CTableHeaderCell>{slt?.user?.organization_name}</CTableHeaderCell>
                                                            <CTableDataCell className="p-0 m-0">{degree?.find(deg => deg.Id == slt?.profession)?.Name}</CTableDataCell>
                                                            <CTableDataCell className="">{moment(slt?.created_at).fromNow() || "-"}</CTableDataCell>
                                                            <CTableDataCell className="p-0 m-0">{slt?.remark || "-"}</CTableDataCell>
                                                        </CTableRow>
                                                    )
                                                    }
                                                    {/* FOR SLOT ONLY */}

                                                    {slt?.category !== 'TC' && slt?.requests?.length !== 0 && slt?.requests?.map((shift, ind) => {
                                                        return (
                                                            shift?.pending_assignments?.map((slot, j) =>
                                                                <CTableRow key={j + "slot"}>
                                                                    <CTableHeaderCell id={"approve" + slt?.id} className="p-0 m-0">
                                                                        <CFormCheck inline
                                                                            data-toggle="tooltip" data-placement="right" title="Approve"
                                                                            style={{ borderColor: "green" }}
                                                                            name={slot?.id}
                                                                            checked={
                                                                                (Array.isArray(selectedAllIds) && selectedAllIds?.length > 0 && selectedAllIds?.some(x => x.id === slot?.id && x.code === 2))
                                                                                || (Array.isArray(selectedId) && selectedId?.length > 0 && selectedId?.some(x => x.id === slot?.id && x.code === 2))
                                                                            }
                                                                            type="radio"
                                                                            label=""
                                                                            value={"Approve"}
                                                                            onClick={(e) => {
                                                                                setSelectedAllIds()
                                                                                setSelectedAll()
                                                                                slotAdd(e.target.value, slot, shift?.id);
                                                                            }}
                                                                        />
                                                                    </CTableHeaderCell>
                                                                    <CTableHeaderCell id={"reject" + slt?.id} className="p-0 m-0">
                                                                        <CFormCheck inline
                                                                            data-toggle="tooltip" data-placement="right" title="Reject"
                                                                            style={{ borderColor: "red" }}
                                                                            type="radio"
                                                                            name={slot?.id}
                                                                            checked={
                                                                                (Array.isArray(selectedAllIds) && selectedAllIds?.some(x => x.id === slot?.id && x.code === 1))
                                                                                || (Array.isArray(selectedId) && selectedId?.some(x => x.id === slot?.id && x.code === 1))}
                                                                            value={"Reject"}
                                                                            label=""
                                                                            onClick={(e) => {
                                                                                setSelectedAllIds()
                                                                                setSelectedAll()
                                                                                slotAdd(e.target.value, slot, shift?.id);
                                                                            }}
                                                                        />
                                                                    </CTableHeaderCell>
                                                                    <CTableHeaderCell><strong>{shift?.id}</strong></CTableHeaderCell>

                                                                    <CTableHeaderCell scope="row" className="p-0 m-0">
                                                                        <div className="time-range-container">
                                                                            <input type="text" disabled value={slot.slot_date} />
                                                                            <input type="time" disabled id="start" name="start" value={slot.slot_start_time} />
                                                                            <span>To</span>
                                                                            <input type="time" disabled id="end" name="end" value={slot.slot_end_time} />
                                                                        </div></CTableHeaderCell>
                                                                    <CTableHeaderCell>{shift?.user?.id}</CTableHeaderCell>
                                                                    <CTableHeaderCell>{shift?.user?.organization_name}</CTableHeaderCell>
                                                                    <CTableDataCell className="p-0 m-0">{degree?.find(deg => deg.Id == shift?.profession)?.Name}</CTableDataCell>

                                                                    <CTableDataCell className="">{moment(slot?.created_at).fromNow() || "-"}</CTableDataCell>
                                                                    <CTableDataCell className="p-0 m-0">{slot?.remark || "-"}</CTableDataCell>
                                                                </CTableRow>
                                                            ))
                                                    })}
                                                </CTableBody>
                                            </CTable>

                                        </CListGroupItem>
                                    </CListGroup>
                                </div>
                            })}
                            {/* EXTENSION REQUEST TABLE */}
                            {staffExtShiftsList?.length > 0 && staffExtShiftsList?.map((slt, index) => {
                                return <div key={index + "sltlst"} className="col-12 ms-2 pe-4" id={"approval" + slt?.id}>
                                    <CListGroup>
                                        {slt?.category === 'TC' ? <span style={{ position: "relative" }}>
                                            {<span className='suffix-container-list-update-left'><span className="suffix-lable ">Extension Traveler Contract</span></span>}
                                        </span> : <span style={{ position: "relative" }}>
                                            {<span className='suffix-container-list-update-left'><span className="suffix-lable ">Extension Slots</span></span>}
                                        </span>}
                                        <CListGroupItem className="mb-3">
                                            <div className="d-flex justify-content-between">
                                                <div className="">
                                                    <ul className="list-unstyled">
                                                        <li><h5>{slt?.category === 'TC' ? slt.staff.first_name : slt?.first_name} (<small>{slt?.category === 'TC' ? slt.staff.bluesky_staff_id : slt?.bluesky_staff_id}</small>)&nbsp;
                                                            {(slt?.category === 'TC' ? slt?.staff?.city : slt?.city) && <small><i className="fas fa-map-marker-alt text-primary"></i> {slt?.category === 'TC' ? slt.staff.city : slt?.city},{slt?.category === 'TC' ? slt.staff?.state : slt?.state}</small>}&nbsp;&nbsp;
                                                            <small><i className="fas fa-phone-alt text-primary"></i><a href={"tel:" + (slt?.category === 'TC' ? slt?.staff?.country_code : slt?.country_code) + (slt?.category === 'TC' ? slt.staff?.contact : slt?.contact)} className="signup-link">&nbsp;
                                                                {slt?.category === 'TC' ? slt.staff?.country_code : slt?.country_code} {slt?.category === 'TC' ? slt.staff?.contact : slt?.contact}
                                                            </a> </small>&nbsp;&nbsp;
                                                            <small><i className="fas fa-envelope text-primary"></i>  <a href={"mailto:" + (slt?.category === 'TC' ? slt.staff?.email : slt?.email)} className="signup-link">&nbsp;{(slt?.category === 'TC' ? slt.staff?.email : slt?.email)}</a> </small>
                                                        </h5></li>
                                                    </ul>
                                                </div>
                                                <span>
                                                    <CButton color="warning" onClick={() => exportFile(slt)}>
                                                        <i className="fas fa-file-export text-success"></i> Export
                                                    </CButton>
                                                </span>
                                            </div>
                                            <CTable responsive id={"data-table" + slt?.id}>
                                                <CTableHead>
                                                    <CTableRow>
                                                        <CTableHeaderCell className="p-0 m-0">
                                                            <CFormCheck inline checked={selectedAllExt === 'Approve' + slt.id}
                                                                onClick={(e) => handleSelectAllRowsExt(e, slt)} data-toggle="tooltip" data-placement="right" title="Approve All"
                                                                style={{ borderColor: "green" }}
                                                                id={'Approve' + slt.id}
                                                                type="radio"
                                                                value="Approve"
                                                                label="Approve All" />
                                                        </CTableHeaderCell>
                                                        <CTableHeaderCell className="p-0 m-0">
                                                            <CFormCheck
                                                                checked={selectedAllExt === 'Reject' + slt.id}
                                                                onClick={(e) => handleSelectAllRowsExt(e, slt)} data-toggle="tooltip" data-placement="right" title="Reject All"
                                                                inline style={{ borderColor: "red" }}
                                                                id={'Reject' + slt.id}
                                                                type="radio" value="Reject" label="Reject All" />
                                                        </CTableHeaderCell>
                                                        <CTableHeaderCell className="" scope="col">Order Ref.</CTableHeaderCell>
                                                        <CTableHeaderCell className="" scope="col">Date/Time</CTableHeaderCell>
                                                        <CTableHeaderCell className="" scope="col">Organization ID</CTableHeaderCell>
                                                        <CTableHeaderCell className="" scope="col">Organization Name</CTableHeaderCell>
                                                        <CTableHeaderCell className="" scope="col">Profession</CTableHeaderCell>
                                                        <CTableHeaderCell className="" scope="col">Creation Time</CTableHeaderCell>
                                                        <CTableHeaderCell className="" scope="col">Staff Comment</CTableHeaderCell>
                                                    </CTableRow>
                                                </CTableHead>
                                                <CTableBody>
                                                    {/* FOR CONTRACT EXTENSION ONLY*/}
                                                    {slt?.category === 'TC' && (
                                                        <CTableRow>
                                                            <CTableHeaderCell id={"approve" + slt?.id} className="p-0 m-0">
                                                                <CFormCheck inline
                                                                    data-toggle="tooltip" data-placement="right" title="Approve"
                                                                    style={{ borderColor: "green" }}
                                                                    name={slt?.id}
                                                                    checked={
                                                                        (selectedAllExtIds?.id === slt?.id && selectedAllExtIds?.code === 5)
                                                                        || (selectedExtId?.id === slt?.id && selectedExtId?.code === 5)}
                                                                    type="radio"
                                                                    label=""
                                                                    value={"Approve"}
                                                                    onClick={(e) => {
                                                                        setSelectedAllExtIds()
                                                                        setSelectedAllExt()
                                                                        slotExtAdd(e.target.value, slt, null);
                                                                    }}
                                                                />
                                                            </CTableHeaderCell>
                                                            <CTableHeaderCell id={"reject" + slt?.id} className="p-0 m-0">
                                                                <CFormCheck inline
                                                                    data-toggle="tooltip" data-placement="right" title="Reject"
                                                                    style={{ borderColor: "red" }}
                                                                    type="radio"
                                                                    name={slt?.id}
                                                                    checked={
                                                                        (selectedAllExtIds?.id === slt?.id && selectedAllExtIds?.code !== 5)
                                                                        || (selectedExtId?.id === slt?.id && selectedExtId?.code !== 5)}
                                                                    value={"Reject"}
                                                                    label=""
                                                                    onClick={(e) => {
                                                                        setSelectedAllExtIds()
                                                                        setSelectedAllExt()
                                                                        slotExtAdd(e.target.value, slt, null);
                                                                    }}
                                                                />
                                                            </CTableHeaderCell>

                                                            <CTableHeaderCell><strong>{slt?.id}</strong></CTableHeaderCell>

                                                            <CTableHeaderCell scope="row" className="p-0 m-0">
                                                                <div className="time-range-container">
                                                                    <input type="text" disabled value={slt.start_date} />
                                                                    <span>To</span>
                                                                    <input type="text" disabled value={slt.end_date} />
                                                                    <input type="time" disabled id="start" name="start" value={slt.start_time} />
                                                                    <span>To</span>
                                                                    <input type="time" disabled id="end" name="end" value={slt.end_time} />
                                                                </div></CTableHeaderCell>
                                                            <CTableHeaderCell>{slt?.user?.id}</CTableHeaderCell>
                                                            <CTableHeaderCell>{slt?.user?.organization_name}</CTableHeaderCell>
                                                            <CTableDataCell className="p-0 m-0">{degree?.find(deg => deg.Id == slt?.profession)?.Name}</CTableDataCell>
                                                            <CTableDataCell className="">{moment(slt?.created_at).fromNow() || "-"}</CTableDataCell>
                                                            <CTableDataCell className="p-0 m-0">{slt?.remark || "-"}</CTableDataCell>
                                                        </CTableRow>
                                                    )
                                                    }
                                                    {/* FOR SLOT EXTENSION ONLY */}

                                                    {slt?.category !== 'TC' && slt?.requests?.length !== 0 && slt?.requests?.map((shift, ind) => {
                                                        return (
                                                            shift?.pending_assignments?.map((slot, j) =>
                                                                <CTableRow key={j + "slot"}>
                                                                    <CTableHeaderCell id={"approve" + slt?.id} className="p-0 m-0">
                                                                        <CFormCheck inline
                                                                            data-toggle="tooltip" data-placement="right" title="Approve"
                                                                            style={{ borderColor: "green" }}
                                                                            name={slot?.id}
                                                                            checked={
                                                                                (Array.isArray(selectedAllExtIds) && selectedAllExtIds?.length > 0 && selectedAllExtIds?.some(x => x.id === slot?.id && x.code === 2))
                                                                                || (Array.isArray(selectedExtId) && selectedExtId?.length > 0 && selectedExtId?.some(x => x.id === slot?.id && x.code === 2))
                                                                            }
                                                                            type="radio"
                                                                            label=""
                                                                            value={"Approve"}
                                                                            onClick={(e) => {
                                                                                setSelectedAllExtIds()
                                                                                setSelectedAllExt()
                                                                                slotExtAdd(e.target.value, slot, shift?.id);
                                                                            }}
                                                                        />
                                                                    </CTableHeaderCell>
                                                                    <CTableHeaderCell id={"reject" + slt?.id} className="p-0 m-0">
                                                                        <CFormCheck inline
                                                                            data-toggle="tooltip" data-placement="right" title="Reject"
                                                                            style={{ borderColor: "red" }}
                                                                            type="radio"
                                                                            name={slot?.id}
                                                                            checked={
                                                                                (Array.isArray(selectedAllExtIds) && selectedAllExtIds?.some(x => x.id === slot?.id && x.code === 1))
                                                                                || (Array.isArray(selectedExtId) && selectedExtId?.some(x => x.id === slot?.id && x.code === 1))}
                                                                            value={"Reject"}
                                                                            label=""
                                                                            onClick={(e) => {
                                                                                setSelectedAllExtIds()
                                                                                setSelectedAllExt()
                                                                                slotExtAdd(e.target.value, slot, shift?.id);
                                                                            }}
                                                                        />
                                                                    </CTableHeaderCell>
                                                                    <CTableHeaderCell><strong>{shift?.id}</strong></CTableHeaderCell>

                                                                    <CTableHeaderCell scope="row" className="p-0 m-0">
                                                                        <div className="time-range-container">
                                                                            <input type="text" disabled value={slot.slot_date} />
                                                                            <input type="time" disabled id="start" name="start" value={slot.slot_start_time} />
                                                                            <span>To</span>
                                                                            <input type="time" disabled id="end" name="end" value={slot.slot_end_time} />
                                                                        </div></CTableHeaderCell>
                                                                    <CTableHeaderCell>{shift?.user?.id}</CTableHeaderCell>
                                                                    <CTableHeaderCell>{shift?.user?.organization_name}</CTableHeaderCell>
                                                                    <CTableDataCell className="p-0 m-0">{degree?.find(deg => deg.Id == shift?.profession)?.Name}</CTableDataCell>

                                                                    <CTableDataCell className="">{moment(slot?.created_at).fromNow() || "-"}</CTableDataCell>
                                                                    <CTableDataCell className="p-0 m-0">{slot?.remark || "-"}</CTableDataCell>
                                                                </CTableRow>
                                                            ))
                                                    })}
                                                </CTableBody>
                                            </CTable>

                                        </CListGroupItem>
                                    </CListGroup>
                                </div>
                            })}
                        </div>
                    </div>
                    {((selectedAllIds?.length > 0 || selectedId?.length > 0) || (selectedAllIds && Object.keys(selectedAllIds)?.length > 0 || selectedId && Object.keys(selectedId)?.length > 0)) && <div className="float-btn">
                        <button type="submit" onClick={() => openModal()} className="btn btn-success w-100 py-3"><i className="far fa-calendar-check"></i>&nbsp; &nbsp;Submit Selected Shift(s)</button>
                    </div>}
                    {/* EXTIONSION BUTTON */}
                    {((selectedAllExtIds?.length > 0 || selectedExtId?.length > 0) || (selectedAllExtIds && Object.keys(selectedAllExtIds)?.length > 0 || selectedExtId && Object.keys(selectedExtId)?.length > 0)) && <div className="float-btn">
                        <button type="submit" onClick={() => openModalExt()} className="btn btn-success w-100 py-3"><i className="far fa-calendar-check"></i>&nbsp; &nbsp;Submit Selected Shift(s)</button>
                    </div>}
                    {/* <!-- main-panel ends --> */}
                </div>
                {/* <!-- page-body-wrapper ends --> */}
            </div>
            <Spinner isLoading={AdminStore.loading} />
        </React.Fragment >
    );
})

export const Control = (props) => {
    return (
        <>
            <label className={props.hasValue || props.isFocused ? "did-floating-label did-floating-label-top" : "did-floating-label did-floating-label"} style={{ zIndex: 999 }}>{props?.selectProps?.label}</label>
            <components.Control {...props} />
        </>
    );
};

export default PendingStaffingRequestList;