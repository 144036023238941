/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react"
import HeaderPage from "../layout/HeaderPage";
import DrawerMenuPage from "../layout/DrawerMenuPage";
import { CButton, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CFormCheck, CModalBody } from "@coreui/react";
import './ReferencesList.css'
import AdminStore from "@store/AdminStore";
import { toJS } from "mobx";
import { CModal, CModalHeader, CModalTitle } from "@coreui/react";
import { FieldArray, Form, Formik } from "formik";
import Spinner from "@components/Spinner";
import ReactDOM from "react-dom";
import { colourStylesState } from '@utils/customStyle/style';
import { referenceSchema } from '@utils/schemas/allSchema';
import { colourStylesCountry } from '@utils/customStyle/style';
import { InputField } from "@elements/index"
import Select, { components } from 'react-select';
import { BottomSheet } from 'react-spring-bottom-sheet';
import html2pdf from 'html2pdf.js';
import { toast } from "react-toastify";
import * as Yup from 'yup';
import moment from "moment";
import { Helmet } from "react-helmet";
import { BASE_API_URL_FILE } from '@api/config';

var countryCodes = require('@assets/CountryCodes.json');

const $ = window.$;
const validationSchema = Yup.object({
    designation: Yup.string().required('Required'),
    workplace: Yup.string().required('Required'),
    timeFrameStart: Yup.string().required('Required'),
    timeFrameEnd: Yup.string().required('Required'),
    strengthsOne: Yup.string().required('Required'),
    strengthsTwo: Yup.string().required('Required'),
    weaknessesOne: Yup.string().required('Required'),
    weaknessesTwo: Yup.string().required('Required'),
    hireAgain: Yup.string().required('Required'),
    healthcareSkills: Yup.number().required('Required').min(1, 'Must be at least 1').max(5, 'Must be at most 5'),
    careForOthers: Yup.number().required('Required').min(1, 'Must be at least 1').max(5, 'Must be at most 5'),
    stressHandling: Yup.number().required('Required').min(1, 'Must be at least 1').max(5, 'Must be at most 5'),
});
const employeeReviewData = {
    designation: 'Position when worked with employee?',
    workplace: 'Where did you work with them?',
    timeFrame: 'During what time frame did you work with them?',
    healthcareSkills: "How would you rate their healthcare worker skills on a scale of 1-5?",
    careForOthers: "How would you rate their ability to care for others on a scale of 1-5?",
    stressHandling: "How would you rate their ability to handle stressful situations on a scale of 1-5?",
    strengths: 'Please provide 2 strengths:',
    weaknesses: 'Please provide 2 weaknesses or areas of improvement:',
    hireAgain: 'Would you work with or hire them again?',
    attendance: "how would you rate their attendance and punctuality on a scale of 1-5"
};
const ReferencesList = observer(() => {

    const tableRef = useRef()
    const formikRef = useRef(null);
    const tableName = "table1"
    const [referencePendingList, setReferencePendingList] = useState([]);
    const [referenceSavedList, setReferenceSavedList] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [paginationLength, setPaginationLength] = useState(100);
    const [staffList, setStaffList] = useState([]);
    const [referenceData, setReferenceData] = useState([]);
    const [onView, setOnView] = useState(false);
    const [deleted, setDeleted] = useState([]);
    const [activeTab, setActiveTab] = useState('tab1');

    const [onViewResponse, setOnViewResponse] = useState(false);
    const [responseData, setResponseData] = useState();
    const [responseDecodeData, setResponseDecodeData] = useState();
    const initialValues = {
        // id:responseData?.id,
        // name:responseData?.name,
        // email:responseData?.email,
        // country_code:responseData?.country_code,
        // contact:responseData?.contact,
        // designation:responseData?.designation,
        // organization_name:responseData?.organization_name,
        designation: responseDecodeData?.find(x => x.question === employeeReviewData?.designation)?.answer || '',
        workplace: responseDecodeData?.find(x => x.question === employeeReviewData?.workplace)?.answer || '',
        timeFrameStart: responseDecodeData && responseDecodeData?.find(x => x.question === employeeReviewData?.timeFrame)?.answer?.split(" To ")[0] || '',
        timeFrameEnd: responseDecodeData && responseDecodeData?.find(x => x.question === employeeReviewData?.timeFrame)?.answer?.split(" To ")[1] || '',
        healthcareSkills: responseDecodeData?.find(x => x.question === employeeReviewData?.healthcareSkills)?.answer || '',
        careForOthers: responseDecodeData?.find(x => x.question === employeeReviewData?.careForOthers)?.answer || '',
        stressHandling: responseDecodeData?.find(x => x.question === employeeReviewData?.stressHandling)?.answer || '',
        strengthsOne: responseDecodeData && responseDecodeData?.find(x => x.question === employeeReviewData?.strengths)?.answer?.split(",")[0] || '',
        strengthsTwo: responseDecodeData && responseDecodeData?.find(x => x.question === employeeReviewData?.strengths)?.answer?.split(",")[1] || '',
        weaknessesOne: responseDecodeData && responseDecodeData?.find(x => x.question === employeeReviewData?.weaknesses)?.answer?.split(",")[0] || '',
        weaknessesTwo: responseDecodeData && responseDecodeData?.find(x => x.question === employeeReviewData?.weaknesses)?.answer?.split(",")[1] || '',
        hireAgain: responseDecodeData?.find(x => x.question === employeeReviewData?.hireAgain)?.answer || '',
        attendance: responseDecodeData?.find(x => x.question === employeeReviewData?.attendance)?.answer || '',

    };
    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };
    useEffect(() => {
        AdminStore.updatePage();
        AdminStore.getReferences();
        if (staffList?.length === 0) {
            AdminStore.getAllStaff();
        }
    }, []);

    useEffect(() => {
        setReferencePendingList(toJS(AdminStore?.data?.referenceList)?.filter(x => x?.job_profile?.documents?.every(doc => doc.document_id !== 77)))
        setReferenceSavedList(toJS(AdminStore?.data?.referenceList)?.filter(x => x?.job_profile?.documents?.some(doc => doc.document_id === 77)))
    }, [AdminStore?.data?.referenceList]);

    useEffect(() => {
        setStaffList(toJS(AdminStore?.data?.allStaffList))
    }, [AdminStore?.data?.allStaffList]);

    //FOR PENDING/Save
    useEffect(() => {
        $.fn.dataTable.ext.errMode = 'none';
        $($.fn.dataTable.tables(true)).DataTable()
            .columns.adjust();
        const table = $(`#${tableName}`).DataTable(
            {
                data: activeTab === 'tab1' ? referencePendingList : referenceSavedList,
                order: [], // Disable initial sorting
                dom: 'Blfrtip',
                buttons: [
                    'copy', 'csv', 'excel', 'print',
                ],
                pageLength: paginationLength,
                lengthMenu: [100, 500, 1000, 2000, 3000, 5000],
                columns: [
                    {
                        title: "Caregiver ID", data: "id", "render": function (data, type, row, meta) {
                            return data || "-";
                        }
                    },
                    {
                        title: "Bluesky ID", data: "bluesky_staff_id", "render": function (data, type, row, meta) {
                            return '<a class="link-info pointer">' + data + '</a>' || "-";
                        }
                    },
                    {
                        title: "Caregiver Name", "render": function (data, type, row, meta) {
                            return '<a class="link-info pointer">' + (row?.first_name + " " + row?.last_name) + '</a>' || "-";
                        }
                    },
                    {
                        title: "Email", data: "email", "render": function (data, type, row, meta) {
                            return data || "-";
                        }
                    },
                    {
                        title: "Contact", data: "contact", "render": function (data, type, row, meta) {
                            return row?.country_code + " " + row?.contact || "-";
                        }
                    },
                    {
                        title: "Status", "render": function (data, type, row, meta) {
                            const filledResponses = row?.references?.filter(item => item.response)?.length;
                            return activeTab === 'tab1' ? "<strong><span class='text-danger me-1'>" + filledResponses + "</span></strong>" + "Out Of" + "<strong><span class='text-danger ms-1'>" + row?.references?.length + "</span></strong>" : "Saved In Bluesky";
                        }
                    },

                    {
                        title: "Last Updated By", width: 100, data: 'last_updated_by', "render": function (data, type, row, meta) {
                            return data ? (data?.first_name + " " + data?.last_name) : "-"
                        }
                    },
                    {
                        title: "Last Update", width: 100, data: 'updated_at', "render": function (data, type, row, meta) {
                            return moment(data).fromNow() || "-";
                        }
                    },
                    {
                        title: "Action"
                    },
                ],
                columnDefs: [
                    {
                        targets: 3,
                        responsivePriority: 2,
                    },
                    {
                        targets: 0,
                        responsivePriority: 3,
                    },
                    {
                        targets: 5,
                        responsivePriority: 4,
                    },

                    {
                        targets: 8,
                        responsivePriority: 1,
                        createdCell: (td, cellData, rowData, row, col) =>
                            ReactDOM.render(
                                <div>
                                    <CDropdown variant="btn-group" direction='dropstart' alignment="end">
                                        <CDropdownToggle size="sm" color="transparent" caret={false} href="#" className="p-0"><i className="fa fa-ellipsis-v text-primary"></i></CDropdownToggle>
                                        <CDropdownMenu >
                                            <CDropdownItem className="more-menu">
                                                <CButton
                                                    onClick={() => {
                                                        viewReference(rowData)
                                                    }}
                                                    size="sm" className="w-100" variant='outline' color="primary">
                                                    View <i className="fas fa-eye"></i>
                                                </CButton>
                                            </CDropdownItem>
                                            {activeTab === 'tab1' && <CDropdownItem className="more-menu">
                                                <CButton
                                                    onClick={() => {
                                                        editReference(rowData)
                                                    }}
                                                    size="sm" className="w-100" variant='outline' color="primary">
                                                    Edit <i className="fas fa-edit"></i>
                                                </CButton>
                                            </CDropdownItem>}
                                        </CDropdownMenu>
                                    </CDropdown>

                                </div>
                                , td),
                    }
                ],
                destroy: true,  // I think some clean up is happening here
                searching: true,
                "language": {
                    search: '',
                    searchPlaceholder: "Search",
                    emptyTable: "No Record Found",
                },
                initComplete: function () {
                    // Apply the search
                    this.api()
                        .columns([0, 1, 2, 3, 4])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><input class="form-control " style="width:100%;margin-top:5px" type="text" placeholder="Filter per page" />')
                                .appendTo($(column.header()))

                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $('input', this.header()).on('keyup change clear', function () {
                                if (column.search() !== this.value) {
                                    column.search(this.value).draw();
                                }
                            });
                        });


                },
            });

        table.on('click', 'td', function () {
            // Get the column index of the clicked cell
            const columnIndex = table.cell(this).index()?.column;
            // Check if the clicked cell belongs to the desired column
            if (columnIndex === 1 || columnIndex === 2) {
                // Get the data for the clicked cell
                const rowData = table.row($(this).closest('tr')).data();
                // Handle the event for the clicked cell
                if (rowData) {
                    viewReference(rowData)
                }
            }
        });
        // Extra step to do extra clean-up.
        return function () {
            $(`#${tableName}`).off('length.dt');
            table.destroy()
        }
    }, [referencePendingList, activeTab])

    const saveReference = (values) => {
        const param = {
            "references": JSON.stringify(values.references),
            "staff_id": values.staff_id ? values.staff_id : referenceData?.id,
            "deleted_ids": deleted
        }
        if (referenceData?.length === 0) {
            AdminStore.addReferences(param, navigationCallBack)
        } else {
            AdminStore.editReferences(param, navigationCallBack)
        }
    }

    const navigationCallBack = () => {
        setIsOpen(false)
        AdminStore.getReferences();
    }
    const onDismiss = () => {
        setReferenceData()
        if (formikRef.current) {
            formikRef.current.resetForm();
        }
        setIsOpen(false)
    }
    const onDismissResponseView = () => {
        setOnViewResponse(false)
        setResponseData()
        setResponseDecodeData()
        setOnView(true)
    }
    const editReference = (data) => {
        setReferenceData(data)
        setIsOpen(true)
    }

    const sendReferenceReminder = (id) => {
        AdminStore.sendReferenceReminder({ "reference_id": id })
    }
    const onDismissView = () => {
        setReferenceData()
        setOnView(false)
    }
    const viewReference = (data) => {
        setReferenceData(data)
        setOnView(true)
    }

    const onDelete = (id) => {
        if (id) {
            setDeleted((prevDeleted) => [...prevDeleted, id]);
        }
    }

    const sendToBluesky = (refData) => {
        if (refData?.references?.some(reference => reference.response)) {
            // JavaScript code to hide elements with data-hide-in-pdf="true"
            const elementsToHide = document.querySelectorAll('[data-hide-in-pdf="true"]');

            elementsToHide?.forEach((element) => {
                element.style.display = 'none'; // Hide the element
            });

            // return

            const element = document.getElementById('reference-content');
            const options = {
                margin: [10, 10, 10, 10],
                filename: 'references.pdf',
                image: { type: 'jpeg', quality: 0.95 },
                jsPDF: { format: 'a4', orientation: 'portrait' },
                pageBreak: { mode: ['avoid-all', 'css', 'legacy'], avoid: ['tr', 'td', 'li'] },
            };
            // Convert HTML to PDF
            html2pdf().from(element).set(options).output('blob').then((pdfBlob) => {
                console.log("convert pdf....");
                // // Create a download link
                // const downloadLink = document.createElement("a");
                // downloadLink.href = URL.createObjectURL(pdfBlob);
                // console.log(downloadLink);
                // // Trigger a click event on the link to start the download
                // // downloadLink.click();
                // return
                let file = { 'doc_id': 77, "file": new File([pdfBlob], `${"reference_form"}.pdf`, { type: 'application/pdf' }, "bin") }
                let param = {
                    doc_list: [file],
                    staff_id: refData?.id
                }
                AdminStore.saveSignedDocuments(param, navigationCallBackFinal)
            });
        } else {
            toast.warn("There was no reference feedback found!")
        }
    }
    const navigationCallBackFinal = (res) => {
        const elementsToHide = document.querySelectorAll('[data-hide-in-pdf="true"]');

        elementsToHide?.forEach((element) => {
            element.style.display = ''; // Hide the element
        });
        let param = {
            request_id: res
        }
        AdminStore.uploadSignedDocuments(param, navigationCallBackAddDoc)
    }
    const navigationCallBackAddDoc = () => {
        console.log("uploaded on the bluesky");
    }

    const onResponse = (item) => {
        setOnView(false)
        setResponseData(item)
        item?.response && setResponseDecodeData(JSON.parse(item?.response))
        setOnViewResponse(true)
    }
    const onSubmit = (values) => {
        const questionAnswerPairs = generateQuestionAnswerPairs(values, employeeReviewData);
        let response = JSON.stringify(questionAnswerPairs);
        const param = {
            "response": response,
            "id": responseData?.id,
        }
        AdminStore.editReponse(param, navigationCallBackResponse)
    };
    const navigationCallBackResponse = () => {
        AdminStore.getReferences();
        setOnViewResponse(false)
        setResponseData()
        setResponseDecodeData()
        setOnView(false)
    }

    function generateQuestionAnswerPairs(data, template) {
        const pairs = [];

        for (const key in template) {
            if (key === "strengths") {
                pairs.push({
                    question: template[key],
                    answer: `${data["strengthsOne"]},${data["strengthsTwo"]}`,
                });
            } else if (key === "weaknesses") {
                pairs.push({
                    question: template[key],
                    answer: `${data["weaknessesOne"]},${data["weaknessesTwo"]}`,
                });
            } else if (key === "timeFrame") {
                pairs.push({
                    question: template[key],
                    answer: `${data["timeFrameStart"]} To ${data["timeFrameEnd"]}`,
                });
            } else {
                pairs.push({
                    question: template[key],
                    answer: data[key],
                });
            }
        }

        return pairs;
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>Staffers LLC | Reference List</title>
            </Helmet>
            {/* <body> */}
            <div className="container-scroller">
                <HeaderPage />
                <div className="container-fluid page-body-wrapper">
                    <DrawerMenuPage />
                    <div className="main-panel" style={{ right: 0, position: "absolute" }}>
                        <div className="content-wrapper p-3 bg-white ">
                            <h3 className="page-title">
                                <span className="page-title-icon linear text-white me-2">
                                    <i className="fas fa-user-friends"></i>
                                </span> References
                            </h3>
                            <div className='col-12 d-flex justify-content-lg-end pt-lg-0 pt-2'>
                                <h1 className="page-title signup-link fs-4" onClick={() => editReference(null)} >
                                    <span className="page-title-icon linear text-white me-2">
                                        <i className="fas fa-plus"></i>
                                    </span>Add New Reference
                                </h1>
                            </div>
                        </div>
                        <ul className="nav nav-tabs d-flex justify-content-center">
                            <li className="nav-item">
                                <button
                                    className={`nav-link ${activeTab === 'tab1' ? 'active text-primary border-3 border-primary' : 'text-muted border border-muted'}`}
                                    onClick={() => handleTabChange('tab1')}
                                >
                                    <strong><i className="fas fa-hourglass-half"></i> Pending</strong>
                                </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    className={`nav-link ${activeTab === 'tab2' ? 'active text-primary border-3 border-primary' : 'text-muted border border-muted'}`}
                                    onClick={() => handleTabChange('tab2')}
                                >
                                    <strong><i className="far fa-save"></i> Archived</strong>
                                </button>
                            </li>
                        </ul>
                        <div className={`tab-list-content active `}>
                            <div className="table-wrapper">
                                <table className="table dt-responsive table-hover nowrap" style={{ width: "100%" }} id={tableName} ref={tableRef}>
                                </table>
                            </div>
                        </div>
                    </div>
                    {/* <!-- main-panel ends --> */}
                </div>
                {/* <!-- page-body-wrapper ends --> */}
            </div>
            <BottomSheet open={onView} onDismiss={onDismissView}
                //   blocking={false}
                header={
                    <div>
                        <div className="text-end" style={{ cursor: "pointer" }} onClick={onDismissView}>
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </div>
                        <h4 className="flex items-center text-xl justify-center font-bold text-primary-800">
                            {referenceData?.first_name?.toUpperCase()} REFERENCE DETAILS
                        </h4>
                    </div>
                }
                footer={
                    <div>

                        {
                            (referenceData?.references?.length >= 3 && referenceData?.references?.filter(x => x.response)?.length >= 2)
                                || (referenceData?.references?.length === 2 && referenceData?.references?.filter(x => x.response)?.length === 2)
                                || (referenceData?.references?.length === 1 && referenceData?.references?.filter(x => x.response)?.length === 1) ?
                                < div className="text-end">
                                    <CButton
                                        onClick={() => {
                                            sendToBluesky(referenceData)
                                        }}
                                        size="sm" variant='outline' color="success" >
                                        Save To Bluesky <i className="fas fa-save"></i>
                                    </CButton>
                                </div> : <div className="text-end"><small className=" text-danger">Note: At least two reference fills are required to save on Bluesky.</small></div>
                        }
                    </div>
                }>
                <div style={{ height: global.config.windowHeight / 1.5, overflowY: 'scroll' }}>
                    <div className="col-xl-12 col-md-12">
                        <div className="user-card-full table-contact" id="reference-content">
                            <div className="row m-l-0 m-r-0">
                                <div className="col-sm-12">
                                    <div className="card-block">
                                        <h6 className="m-b-20 p-b-5 f-w-600"> Caregiver(Bluesky ID): {referenceData?.bluesky_staff_id} </h6>
                                        <h6 className="m-b-20 p-b-5 b-b-default f-w-600">Basic Details <i className="far fa-id-card"></i></h6>
                                        <div className="row">
                                            {referenceData && referenceData?.first_name && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Full Name</p>
                                                <h6 className="text-muted f-w-400">{referenceData?.first_name} {referenceData?.last_name}</h6>
                                            </div>}
                                            {referenceData && referenceData?.contact && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Contact</p>
                                                <h6 className="text-muted f-w-400">{referenceData?.country_code} {referenceData?.contact}</h6>
                                            </div>}
                                            {referenceData && referenceData?.email && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Email Address</p>
                                                <h6 className="text-muted f-w-400">{referenceData?.email}</h6>
                                            </div>}

                                            {referenceData && referenceData?.created_at && <div className="col-sm-2" data-hide-in-pdf="true">
                                                <p className="m-b-10 f-w-600">Creation Time</p>
                                                <h6 className="text-muted f-w-400"><small className="text-muted f-w-400"><span className="badge badge-primary ms-2"> {moment(referenceData?.created_at).fromNow()}</span></small></h6>
                                            </div>}
                                            {referenceData && referenceData?.last_updated_by && <div className="col-sm-2" data-hide-in-pdf="true">
                                                <p className="m-b-10 f-w-600">Last Updated By </p>
                                                <h6 className="text-muted f-w-400"><small className="text-muted f-w-400"> {referenceData?.last_updated_by?.first_name} {referenceData?.last_updated_by?.last_name}</small></h6>
                                            </div>}
                                            {referenceData && referenceData?.updated_at && <div className="col-sm-2" data-hide-in-pdf="true">
                                                <p className="m-b-10 f-w-600">Last Update</p>
                                                <h6 className="text-muted f-w-400"><small className="text-muted f-w-400"><span className="badge badge-primary ms-2"> {moment(referenceData?.updated_at).fromNow()}</span></small></h6>
                                            </div>}
                                            <h6 className="m-b-20 p-b-5 b-b-default f-w-600">References Details <i className="far fa-list-alt"></i></h6>
                                            {
                                                referenceData?.job_profile?.documents?.some(x => x.document_id === 77) ?

                                                    <iframe src={BASE_API_URL_FILE + referenceData?.job_profile?.documents?.find(x => x.document_id === 77)?.document_path} width="100%" height="600px"></iframe>

                                                    : referenceData?.references?.length > 0 && referenceData?.references?.map((item, index) => {
                                                        return <div className="card p-2 mb-2" style={{ borderStyle: 'dashed', borderWidth: 1 }} key={index + "list"} data-hide-in-pdf={item?.response ? "false" : "true"}> <div className="row" >
                                                            <div className="d-flex justify-content-between">
                                                                <span className="mb-1">
                                                                    <small className="badge badge-primary text-uppercase">{index === 0 ? 'First' : index === 1 ? 'Second' : 'Third'} Reference</small>

                                                                </span>
                                                                {
                                                                    <div data-hide-in-pdf="true">
                                                                        <CButton
                                                                            onClick={() => {
                                                                                onResponse(item)
                                                                            }}
                                                                            size="sm" variant='outline' color="success">
                                                                            {(item?.response === '' || !item?.response) ? "Fill Response" : "Edit Response"} <i className="fas fa-edit"></i>
                                                                        </CButton> &nbsp;
                                                                        {(!item?.response || item?.response === '') && <CButton
                                                                            onClick={() => {
                                                                                sendReferenceReminder(item?.id)
                                                                            }}
                                                                            size="sm" variant='outline' color="warning">
                                                                            Send Reminder <i className="fas fa-bell"></i>
                                                                        </CButton>}
                                                                    </div>
                                                                }

                                                            </div>
                                                            <div className="col-sm-2">
                                                                <p className="m-b-10 f-w-600">Name</p>
                                                                <h6 className="text-muted f-w-400">{item?.name}</h6>
                                                            </div>
                                                            <div className="col-sm-2">
                                                                <p className="m-b-10 f-w-600">Organization Name</p>
                                                                <h6 className="text-muted f-w-400">{item?.organization_name}</h6>
                                                            </div>
                                                            <div className="col-sm-2">
                                                                <p className="m-b-10 f-w-600">Email Address</p>
                                                                <h6 className="text-muted f-w-400">{item?.email}</h6>
                                                            </div>
                                                            <div className="col-sm-2">
                                                                <p className="m-b-10 f-w-600">Contact</p>
                                                                <h6 >{item?.country_code} {item?.contact}</h6>
                                                            </div>
                                                            <div className="col-sm-2" data-hide-in-pdf="true">
                                                                <p className="m-b-10 f-w-600">Last Update</p>
                                                                {item?.updated_at && <small data-hide-in-pdf="true" className="text-muted f-w-400"><span className="badge badge-primary ms-2"> {moment(item?.updated_at).fromNow()}</span></small>}

                                                            </div>
                                                        </div>
                                                            <div className="row" data-hide-in-pdf={item?.response ? "false" : "true"}>
                                                                <div className="col-sm-12">
                                                                    <p className="m-b-10 f-w-600 text-uppercase text-success">Reference Feedback <h6 className="text-warning f-w-400">{item?.response ? "" : "Pending"}</h6></p>
                                                                    {item?.response && (
                                                                        <div>
                                                                            {JSON.parse(item?.response)?.map((item, index) => (
                                                                                <div className="list-group" key={index}>
                                                                                    <span className="list-group-item list-group-item-action"><span className="badge badge-primary">Q.</span> {item?.question}</span>
                                                                                    <span className="list-group-item list-group-item-action"><span className="badge badge-success">A.</span> {item?.answer}</span>
                                                                                    <br />

                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            {/* <span className="text-primary" ></span> */}
                                                        </div>
                                                    })
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BottomSheet >
            <CModal scrollable className="custom-modal-size" visible={isOpen} onClose={() => onDismiss()} backdrop="static">
                <CModalHeader onClose={() => onDismiss()}>
                    {!referenceData ? <CModalTitle>Add Reference </CModalTitle> : <CModalTitle>View/Edit Reference </CModalTitle>}
                </CModalHeader>
                <CModalBody>
                    <div className="m-3">
                        <Formik
                            initialValues={{
                                references: (!referenceData || referenceData === undefined) ?
                                    [{ name: '', email: '', designation: '', country_code: '', contact: '', organization_name: '', response: '' }] :
                                    referenceData?.references,
                                staff_id: '',
                            }}
                            validationSchema={referenceSchema}
                            validateOnChange={false}
                            onSubmit={(values, { resetForm }) => {
                                // Handle form submission here
                                saveReference(values);
                            }}
                        >
                            {({ handleChange, handleBlur, setFieldValue, handleSubmit, values, errors }) => (
                                <form onSubmit={handleSubmit}>
                                    <div>
                                        {(!referenceData || referenceData === undefined) && <div className="col-sm-6  mb-2">
                                            <Select
                                                label="Select a Caregiver"
                                                options={staffList}
                                                isLoading={staffList?.length === 0}
                                                onChange={(selectedOption) => {
                                                    setFieldValue("staff_id", selectedOption ? selectedOption.id : null);
                                                }}
                                                value={staffList.find((option) => option.id === values.staff_id) || null}
                                                getOptionLabel={(option) => `${option.first_name} - ${option.last_name} - ${option.bluesky_staff_id}`}
                                                getOptionValue={(option) => option.id}
                                                isRequired={true}
                                                components={{ Control }}
                                                styles={colourStylesState} // Assuming colourStylesState is defined
                                            />
                                            {errors.staff_id && touched.staff_id ? (
                                                <div className="error-message">{errors.staff_id}</div>
                                            ) : null}
                                        </div>}
                                        <FieldArray
                                            name="references"
                                            render={arrayHelpers => (
                                                <div>
                                                    {values?.references?.map((org, index) => (
                                                        <div key={index}>
                                                            <h3 className="text-center">{index === 0 ? "First" : index === 1 ? "Second" : "Third"} Reference</h3>
                                                            <div className="card p-2">
                                                                <div className="row">
                                                                    {index > 0 && (
                                                                        <div className="d-flex justify-content-end me-2 mb-2">
                                                                            <i className="fas fa-times-circle text-danger pointer" onClick={() => { arrayHelpers.remove(index); onDelete(org?.id) }}></i>
                                                                        </div>
                                                                    )}
                                                                    <div className="col-sm-6  mb-2">
                                                                        <InputField
                                                                            className={"did-floating-input"}
                                                                            classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                            type="text"
                                                                            name={`references[${index}].name`}
                                                                            label={"Full Name"}
                                                                            value={org.name}
                                                                            onChange={handleChange}
                                                                            required={true}
                                                                            onBlur={handleBlur}
                                                                        />
                                                                        {errors.name ? (
                                                                            <div className="error-message">{errors.name}</div>
                                                                        ) : null}
                                                                    </div>
                                                                    <div className="col-sm-6 mb-2">
                                                                        <InputField
                                                                            className={"did-floating-input"}
                                                                            classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                            type="text"
                                                                            name={`references[${index}].organization_name`}
                                                                            label={"Organization Name"}
                                                                            value={org.organization_name}
                                                                            onChange={handleChange}
                                                                            required={true}
                                                                            onBlur={handleBlur}
                                                                        />
                                                                        {errors.organization_name ? (
                                                                            <div className="error-message">{errors.organization_name}</div>
                                                                        ) : null}
                                                                    </div>
                                                                    <div className="col-sm-6 mb-2">
                                                                        <InputField
                                                                            className={"did-floating-input"}
                                                                            classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                            type="text"
                                                                            name={`references[${index}].designation`}
                                                                            label={"Designation"}
                                                                            value={org.designation}
                                                                            onChange={handleChange}
                                                                            required={true}
                                                                            onBlur={handleBlur}
                                                                        />
                                                                        {errors?.designation ? (
                                                                            <div className="error-message">{errors.designation}</div>
                                                                        ) : null}
                                                                    </div>
                                                                    <div className="col-sm-6 mb-2">
                                                                        <InputField
                                                                            className={"did-floating-input"}
                                                                            classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                            type="email"
                                                                            name={`references[${index}].email`}
                                                                            label={"Email Address"}
                                                                            value={org.email}
                                                                            onChange={handleChange}
                                                                            required={true}
                                                                            onBlur={handleBlur}
                                                                        />
                                                                        {errors?.email ? (
                                                                            <div className="error-message">{errors.email}</div>
                                                                        ) : null}
                                                                    </div>
                                                                    <div className="col-sm-2 mb-2">
                                                                        <Select
                                                                            onChange={(e) => setFieldValue(`references.${index}.country_code`, e.label)}
                                                                            placeholder={''}
                                                                            label={'Country'}
                                                                            styles={colourStylesCountry}
                                                                            isSearchable={true}
                                                                            value={countryCodes.filter(({ label }) => label === org.country_code) || ''}
                                                                            options={countryCodes}
                                                                            defaultValue={{ label: "+1", value: 'CA' }}
                                                                            components={{ Control, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-4 mb-2">
                                                                        <InputField
                                                                            className={"did-floating-input"}
                                                                            classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                            type="text"
                                                                            name={`references[${index}].contact`}
                                                                            label={"Contact"}
                                                                            value={org.contact}
                                                                            onChange={handleChange}
                                                                            required={true}
                                                                            onBlur={handleBlur}
                                                                        />
                                                                        {errors?.references && errors?.references?.length != 0 && errors?.references[index] ? (
                                                                            <div className="error-message">{errors?.references[index]?.contact}</div>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}

                                                    {values.references?.length < 3 && <div className="row">
                                                        <div className="col-md-12">
                                                            <button
                                                                type="button"
                                                                className="btn btn-outline-primary"
                                                                onClick={() =>
                                                                    arrayHelpers.push({
                                                                        staff_id: '', name: '', email: '', designation: '', country_code: '', contact: '', organization_name: '', response: null
                                                                    })
                                                                }
                                                            >
                                                                + Add Reference
                                                            </button>
                                                        </div>
                                                    </div>}

                                                </div>
                                            )}
                                        />
                                        {
                                            !Array.isArray(errors?.references) && errors?.references ?
                                                <div className="error-message mt-2 mb-2"><i className="fas fa-exclamation-triangle"></i> {errors?.references}</div> : null
                                        }
                                        <div className="d-flex justify-content-center mt-2">
                                            <button className="btn btn-outline-primary" type="submit">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                </CModalBody>
            </CModal>
            {/* THIS IS FOR RESPONSE EDIT */}
            <CModal scrollable className="custom-modal-size" visible={onViewResponse} onClose={() => onDismissResponseView()} backdrop="static">
                <CModalHeader onClose={() => onDismissResponseView()}>
                    {<CModalTitle>Edit Reference Response </CModalTitle>}
                </CModalHeader>
                <CModalBody>
                    <div >
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            validateOnChange={false}
                            onSubmit={onSubmit}
                        >
                            {
                                ({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched, resetForm
                                }) =>
                                (
                                    <Form>
                                        <div >
                                            <div className="container" style={styles.container}>

                                                {<>
                                                    <div className="">
                                                        <div className="container">
                                                            <div className="row">
                                                                <div className="h5 text-primary"><b>Q. {employeeReviewData?.designation}</b></div>
                                                                <div className="col">
                                                                    {/* <InputField
                                                                        className={"did-floating-input"}
                                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                        type="text"
                                                                        name="designation"
                                                                        label={"Job Title"}
                                                                        value={values.designation}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        required={true}
                                                                    /> */}
                                                                    <div className="d-flex">
                                                                        <CFormCheck
                                                                            className={`me-3 text-dark`}
                                                                            autoComplete="off"
                                                                            id="Supervisor"
                                                                            name="designation"
                                                                            value="Supervisor/Manager"
                                                                            label="Supervisor/Manager"
                                                                            checked={values.designation == 'Supervisor/Manager'}
                                                                            onChange={(e) => setFieldValue("designation", e.target.value)}

                                                                        />
                                                                        <CFormCheck
                                                                            className={`ms-2 text-dark`}
                                                                            autoComplete="off"
                                                                            id="Coworker"
                                                                            name="designation"
                                                                            value="Coworker/Peer"
                                                                            label="Coworker/Peer"
                                                                            onChange={(e) => setFieldValue("designation", e.target.value)}
                                                                            checked={values.designation == 'Coworker/Peer'}

                                                                        />
                                                                    </div>
                                                                    {errors.designation && touched.designation ? (
                                                                        <div className="error-message">{errors.designation}</div>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                            <div className="py-3 h5 text-primary "><b>Q. {employeeReviewData?.workplace}</b></div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <InputField
                                                                        className={"did-floating-input"}
                                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                        type="text"
                                                                        name="workplace"
                                                                        label={"Work Place"}
                                                                        value={values.workplace}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        required={true}
                                                                    />
                                                                    {errors.workplace && touched.workplace ? (
                                                                        <div className="error-message">{errors.workplace}</div>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                            <div className="py-3 h5 text-primary"><b>Q. {employeeReviewData?.timeFrame}</b></div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <InputField
                                                                        className={"did-floating-input"}
                                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                        type="month"
                                                                        name="timeFrameStart"
                                                                        label={"Work Start"}
                                                                        value={values.timeFrameStart}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        required={true}
                                                                    />
                                                                    {errors.timeFrameStart && touched.timeFrameStart ? (
                                                                        <div className="error-message">{errors.timeFrameStart}</div>
                                                                    ) : null}
                                                                </div>
                                                                <div className="col">
                                                                    <InputField
                                                                        className={"did-floating-input"}
                                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                        type="month"
                                                                        name="timeFrameEnd"
                                                                        label={"Work End"}
                                                                        value={values.timeFrameEnd}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        required={true}
                                                                        max={getCurrentMonthYear()}
                                                                    />
                                                                    {errors.timeFrameEnd && touched.timeFrameEnd ? (
                                                                        <div className="error-message">{errors.timeFrameEnd}</div>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>}
                                                {
                                                    <div className="question ml-sm-5 pl-sm-5 pt-2 mb-2">
                                                        <div className="py-3 h5 text-primary"><b>Q. {employeeReviewData?.healthcareSkills}</b></div>
                                                        <div className="row">
                                                            <div className="col mb-2">
                                                                <div className="rating-employee">
                                                                    <input type="radio"
                                                                        value={5}
                                                                        checked={values.healthcareSkills == 5}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        name="healthcareSkills"
                                                                        style={{ '--c': '#ff9933' }}
                                                                    />
                                                                    <input type="radio"
                                                                        value={4}
                                                                        checked={values.healthcareSkills == 4}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        name="healthcareSkills"
                                                                        style={{ '--c': '#ff9933' }}
                                                                    />
                                                                    <input type="radio"
                                                                        value={3}
                                                                        checked={values.healthcareSkills == 3}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        name="healthcareSkills"
                                                                        style={{ '--c': '#ff9933' }}
                                                                    />
                                                                    <input type="radio"
                                                                        value={3}
                                                                        checked={values.healthcareSkills == 3}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        name="healthcareSkills"
                                                                        style={{ '--c': '#ff9933' }}
                                                                    />
                                                                    <input type="radio"
                                                                        value={1}
                                                                        checked={values.healthcareSkills == 1}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        name="healthcareSkills"
                                                                        style={{ '--c': '#ff9933' }}
                                                                    />
                                                                </div>
                                                                {errors.healthcareSkills && touched.healthcareSkills ? (
                                                                    <div className="error-message">{errors.healthcareSkills}</div>
                                                                ) : null}
                                                            </div>

                                                        </div>
                                                        <div className="py-3 h5 text-primary"><b>Q. {employeeReviewData?.careForOthers}</b></div>
                                                        <div className="row">
                                                            <div className="col mt-2 mb-2">
                                                                <div className="rating-employee">
                                                                    <input type="radio"
                                                                        value={5}
                                                                        checked={values.careForOthers == 5}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        name="careForOthers"
                                                                        style={{ '--c': '#ff9933' }}
                                                                    />
                                                                    <input type="radio"
                                                                        value={4}
                                                                        checked={values.careForOthers == 4}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        name="careForOthers"
                                                                        style={{ '--c': '#ff9933' }}
                                                                    />
                                                                    <input type="radio"
                                                                        value={3}
                                                                        checked={values.careForOthers == 3}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        name="careForOthers"
                                                                        style={{ '--c': '#ff9933' }}
                                                                    />
                                                                    <input type="radio"
                                                                        value={2}
                                                                        checked={values.careForOthers == 2}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        name="careForOthers"
                                                                        style={{ '--c': '#ff9933' }}
                                                                    />
                                                                    <input type="radio"
                                                                        value={1}
                                                                        checked={values.careForOthers == 1}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        name="careForOthers"
                                                                        style={{ '--c': '#ff9933' }}
                                                                    />
                                                                </div>
                                                                {errors.careForOthers && touched.careForOthers ? (
                                                                    <div className="error-message">{errors.careForOthers}</div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div className="py-3 h5 text-primary"><b>Q. {employeeReviewData?.stressHandling}</b></div>
                                                        <div className="row">
                                                            <div className="col mt-2 mb-2">
                                                                <div className="rating-employee">
                                                                    <input type="radio"
                                                                        value={5}
                                                                        checked={values.stressHandling == 5}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        name="stressHandling"
                                                                        style={{ '--c': '#ff9933' }}
                                                                    />
                                                                    <input type="radio"
                                                                        value={4}
                                                                        checked={values.stressHandling == 4}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        name="stressHandling"
                                                                        style={{ '--c': '#ff9933' }}
                                                                    />
                                                                    <input type="radio"
                                                                        value={3}
                                                                        checked={values.stressHandling == 3}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        name="stressHandling"
                                                                        style={{ '--c': '#ff9933' }}
                                                                    />
                                                                    <input type="radio"
                                                                        value={2}
                                                                        checked={values.stressHandling == 2}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        name="stressHandling"
                                                                        style={{ '--c': '#ff9933' }}
                                                                    />
                                                                    <input type="radio"
                                                                        value={1}
                                                                        checked={values.stressHandling == 1}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        name="stressHandling"
                                                                        style={{ '--c': '#ff9933' }}
                                                                    />
                                                                </div>
                                                                {errors.stressHandling && touched.stressHandling ? (
                                                                    <div className="error-message">{errors.stressHandling}</div>
                                                                ) : null}
                                                            </div>

                                                        </div>

                                                        <div className="py-3 h5 text-primary"><b>Q. {employeeReviewData?.attendance}</b></div>
                                                        <div className="row">
                                                            <div className="col mt-2 mb-2">
                                                                <div className="rating-employee">
                                                                    <input type="radio"
                                                                        value={5}
                                                                        checked={values.attendance == 5}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        name="attendance"
                                                                        style={{ '--c': '#ff9933' }}
                                                                    />
                                                                    <input type="radio"
                                                                        value={4}
                                                                        checked={values.attendance == 4}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        name="attendance"
                                                                        style={{ '--c': '#ff9933' }}
                                                                    />
                                                                    <input type="radio"
                                                                        value={3}
                                                                        checked={values.attendance == 3}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        name="attendance"
                                                                        style={{ '--c': '#ff9933' }}
                                                                    />
                                                                    <input type="radio"
                                                                        value={2}
                                                                        checked={values.attendance == 2}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        name="attendance"
                                                                        style={{ '--c': '#ff9933' }}
                                                                    />
                                                                    <input type="radio"
                                                                        value={1}
                                                                        checked={values.attendance == 1}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        name="attendance"
                                                                        style={{ '--c': '#ff9933' }}
                                                                    />
                                                                </div>
                                                                {errors.attendance && touched.attendance ? (
                                                                    <div className="error-message">{errors.attendance}</div>
                                                                ) : null}
                                                            </div>

                                                        </div>
                                                    </div>

                                                }
                                                {
                                                    <div className="question ml-sm-5 pl-sm-5 pt-2 mb-2">
                                                        <div className="py-3 h5 text-primary"><b>Q. {employeeReviewData?.strengths}</b></div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <InputField
                                                                    className={"did-floating-input"}
                                                                    classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                    type="text"
                                                                    name="strengthsOne"
                                                                    label={"First Strength"}
                                                                    value={values.strengthsOne}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    required={true}
                                                                />
                                                                {errors.strengthsOne && touched.strengthsOne ? (
                                                                    <div className="error-message">{errors.strengthsOne}</div>
                                                                ) : null}
                                                            </div>
                                                            <div className="col">
                                                                <InputField
                                                                    className={"did-floating-input"}
                                                                    classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                    type="text"
                                                                    name="strengthsTwo"
                                                                    label={"Second Strength"}
                                                                    value={values.strengthsTwo}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    required={true}
                                                                />
                                                                {errors.strengthsTwo && touched.strengthsTwo ? (
                                                                    <div className="error-message">{errors.strengthsTwo}</div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div className="py-3 h5 text-primary"><b>Q. {employeeReviewData?.weaknesses}</b></div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <InputField
                                                                    className={"did-floating-input"}
                                                                    classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                    type="text"
                                                                    name="weaknessesOne"
                                                                    label={"First Weakness"}
                                                                    value={values.weaknessesOne}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    required={true}
                                                                />
                                                                {errors.weaknessesOne && touched.weaknessesOne ? (
                                                                    <div className="error-message">{errors.weaknessesOne}</div>
                                                                ) : null}
                                                            </div>
                                                            <div className="col">
                                                                <InputField
                                                                    className={"did-floating-input"}
                                                                    classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                    type="text"
                                                                    name="weaknessesTwo"
                                                                    label={"Second Weakness"}
                                                                    value={values.weaknessesTwo}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    required={true}
                                                                />
                                                                {errors.weaknessesTwo && touched.weaknessesTwo ? (
                                                                    <div className="error-message">{errors.weaknessesTwo}</div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div className="py-3 h5 text-primary"><b>Q. {employeeReviewData?.hireAgain}</b></div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <InputField
                                                                    className={"did-floating-input"}
                                                                    classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                    type="text"
                                                                    name="hireAgain"
                                                                    label={"Hire again?"}
                                                                    value={values.hireAgain}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    required={true}
                                                                />
                                                                {errors.hireAgain && touched.hireAgain ? (
                                                                    <div className="error-message">{errors.hireAgain}</div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {<div className="d-flex justify-content-center pt-3">

                                                    {<div className="ml-auto mr-sm-5" >
                                                        <button className="btn btn-success" type='submit'>Submit</button>
                                                    </div>}
                                                </div>}

                                            </div>
                                        </div>
                                    </Form>
                                )}
                        </Formik>
                    </div>
                </CModalBody>
            </CModal>
            <Spinner isLoading={AdminStore.loading || AdminStore.loadingLong} />
            {/* </body> */}
        </React.Fragment >
    );
})

export default ReferencesList;
const styles = {
    container: {
        backgroundColor: "#fff",
        color: "#ddd",
        borderRadius: "10px",
        padding: "20px",
        fontFamily: "'Montserrat', sans-serif",
        maxWidth: "750px",
    },
};

function getCurrentMonthYear() {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1; // January is 0, so add 1
    const formattedDate = `${year}-${month.toString().padStart(2, '0')}`;
    return formattedDate;
}
export const Control = (props) => {
    return (
        <>
            <label className={props.hasValue || props.isFocused ? props?.selectProps?.isRequired ? "did-floating-label did-floating-label-top did-floating-labe-astrisk" : "did-floating-label did-floating-label-top" : props?.selectProps?.isRequired ? "did-floating-label did-floating-label did-floating-labe-astrisk" : "did-floating-label did-floating-label"} style={{ zIndex: 100 }}>{props?.selectProps?.label}</label>
            <components.Control {...props} />
        </>
    );
};