import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./App.css";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "mobx-react";
import WebsiteStore from "./store/WebsiteStore";
const { ToastProvider } = require("react-toast-notifications");
const stores = {
  WebsiteStore: WebsiteStore,
};

ReactDOM.render(
  <ToastProvider autoDismiss={true} autoDismissTimeout={2000}>
    <Suspense fallback={<div>Loading...</div>}>
      <Provider {...stores}>
        <App />
      </Provider>
    </Suspense>
  </ToastProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
