import React from 'react';
import { CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter, CButton } from '@coreui/react';

const CustomModal = ({ isOpen, closeModal,size, title, content,submit }) => {
  return (
    <CModal visible={isOpen} onClose={closeModal} size={size} backdrop="static">
      <CModalHeader closeButton>
        <CModalTitle>{title}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <>{content}</>
      </CModalBody>
      {/* <CModalFooter>
        <CButton color="primary" onClick={submit}>
          Submit
        </CButton>
      </CModalFooter> */}
    </CModal>
  );
};

export default CustomModal;
