import React, { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
const DashboardCardPage = ({ counts }) => {

    const [userData, setUserData] = useState();
    useEffect(() => {
        let data = secureLocalStorage.getItem("adminData");
        setUserData(JSON.parse(data));
    }, []);


    const customFormatNumber = (number) => {
        return (number)?.toLocaleString('en-US', { useGrouping: true });
    }
    return (

        <div className="row">
            <a href={userData?.is_super === 1 ? "/admin/admin-list" : "#"} className="col stretch-card grid-margin">
                <div className="card bg-gradient-primary card-img-holder text-white">
                    <div className="card-body">
                        <img src="/assets/images/dashboard/circle.svg" className="card-img-absolute" alt="circle-image" />
                        <h4 className="font-weight-normal mb-3">Admins <i className="fas fa-user-shield  float-right"></i>
                        </h4>
                        <h2 className="mb-3">{customFormatNumber(counts?.admin_total_count) || "0"}</h2>
                        <h6 className="card-text"><i className="fas fa-user-shield float-right"></i> Super Admin {customFormatNumber(counts?.admin_super_count) || "0"}</h6>
                        <h6 className="card-text"><i className="fas fa-user-tie float-right"></i> Admin {customFormatNumber(counts?.admin_sub_count) || "0"}</h6>
                    </div>
                </div>
            </a>

            <a href="/admin/clients" className="col stretch-card grid-margin">
                <div className="card bg-gradient-success card-img-holder text-white">
                    <div className="card-body">
                        <img src="/assets/images/dashboard/circle.svg" className="card-img-absolute" alt="circle-image" />
                        <h4 className="font-weight-normal mb-3">Facility User <i className="fas fa-user-friends  float-right"></i>
                        </h4>
                        <h2 className="mb-3">{customFormatNumber(counts?.user_total_count) || "0"}</h2>
                        <h6 className="card-text"><i className="fas fa-building float-right"></i> Facilities {customFormatNumber(counts?.facility_total_count) || "0"}</h6>
                        <h6 className="card-text"><i className="fas fa-user-check float-right"></i> Master User {customFormatNumber(counts?.user_master_count) || "0"}</h6>
                        <h6 className="card-text"><i className="fas fa-user-check float-right"></i> Sub User {customFormatNumber(counts?.user_sub_count) || "0"}</h6>
                        <h6 className="card-text"><i className="fas fa-user-clock float-right"></i> Pending User {customFormatNumber(counts?.user_pending_count) || "0"}</h6>
                    </div>
                </div>
            </a>
            <a href="/admin/job-profile-request" className="col stretch-card grid-margin">
                <div className="card bg-gradient-info card-img-holder text-white">
                    <div className="card-body">
                        <img src="/assets/images/dashboard/circle.svg" className="card-img-absolute" alt="circle-image" />
                        <h4 className="font-weight-normal mb-3">Caregiver <i className="fas fa-user-tie  float-right"></i>
                        </h4>
                        <h2 className="mb-3">{customFormatNumber(counts?.staff_total_count) || "0"}</h2>
                        <h6 className="card-text"><i className="fas fa-user-check float-right"></i> Active In Portal {customFormatNumber(counts?.staff_active_account) || "0"}</h6>
                        <h6 className="card-text"><i className="fas fa-user-check float-right"></i> Active In BlueSky {customFormatNumber(counts?.staff_active_job_profile) || "0"}</h6>
                        <h6 className="card-text"><i className="fas fa-user-clock float-right"></i> Pend. Job Profiles {customFormatNumber(counts?.staff_pending_job_count) || "0"}</h6>
                        <h6 className="card-text"><i className="fas fa-file-signature float-right"></i> Pend. Doc. Sign   {customFormatNumber(counts?.staff_onboarding_pending) || "0"}</h6>
                        <h6 className="card-text"><i className="fas fa-user-times float-right"></i> Un-verified In Portal {customFormatNumber(counts?.staff_unverify_count) || "0"}</h6>
                    </div>
                </div>
            </a>
            <a href="/admin/shift-request" className="col stretch-card grid-margin">
                <div className="card bg-gradient-warning card-img-holder text-white">
                    <div className="card-body">
                        <img src="/assets/images/dashboard/circle.svg" className="card-img-absolute" alt="circle-image" />
                        <h4 className="font-weight-normal mb-3">Shifts <i className="fas fa-clock  float-right"></i>
                        </h4>
                        <h2 className="mb-3">{customFormatNumber(counts?.total_slots) || "0"}</h2>
                        <h6 className="card-text"><i className="fas fa-check-square float-right"></i> Filled Shifts {customFormatNumber(counts?.filled_slots) || "0"}</h6>
                        <h6 className="card-text"><i className="far fa-square float-right"></i> Unfilled Shifts {customFormatNumber(counts?.unfilled_slots) || "0"}</h6>
                        <h6 className="card-text"><i className="far fa-window-close float-right"></i> Cancelled Shifts {customFormatNumber(counts?.cancelled_slots) || "0"}</h6>
                        <h6 className="card-text"><i className="fas fa-calendar-check float-right"></i> Completed Shifts {customFormatNumber(counts?.completed_Shifts) || "0"}</h6>
                        <h6 className="card-text"><i className="fas fa-calendar-day float-right"></i> Expired Shifts {customFormatNumber(counts?.expired_slots) || "0"}</h6>
                    </div>
                </div>
            </a>
            <a href="/admin/shift-request" className="col stretch-card grid-margin">
                <div className="card bg-gradient-success card-img-holder text-white">
                    <div className="card-body">
                        <img src="/assets/images/dashboard/circle.svg" className="card-img-absolute" alt="circle-image" />
                        <h4 className="font-weight-normal mb-3">Orders|Contracts
                        </h4>
                        <h2 className="mb-3">{customFormatNumber(counts?.total_shifts) || "0"}|{customFormatNumber(counts?.total_contracts) || "0"}</h2>
                        <h6 className="card-text"><i className="fas fa-calendar-check float-right"></i> Per Diem {customFormatNumber(counts?.per_diem_shifts) || "0"}</h6>
                        <h6 className="card-text"><i className="fas fa-calendar-check float-right"></i> Local Traveler {customFormatNumber(counts?.local_traveller_shifts) || "0"}</h6>
                        <h6 className="card-text"><i className="fas fa-tasks float-right"></i> Traveler Contracts {customFormatNumber(counts?.active_contracts) || "0"}</h6>
                        <h6 className="card-text"><i className="fas fa-business-time float-right"></i> Pending TR Contracts {customFormatNumber(counts?.open_contracts) || "0"}</h6>
                        <h6 className="card-text"><i className="fas fa-calendar-check float-right"></i> Completed TR Contracts {customFormatNumber(counts?.completed_contracts) || "0"}</h6>

                 
                    </div>
                </div>
            </a>
        </div>
    );
}

export default DashboardCardPage;