import React from "react";
import moment from "moment";

export const AbuseNeglectandDementiaTrainingNewHires = (props) => {

    return (
        <div class="container m-3" id="form-content">
            <div>
                <div>
                    <h1 class="mt-5">Abuse Prevention Training</h1>
                    <p>
                        1. The Abuse Prevention Coordinator is the <b><u>Administrator.</u></b> <br />
                        2. We are all mandated reporters. <br />
                        3. If you become aware of any incident, concern, or allegation of abuse, neglect or misappropriation <br />
                        of resident property, you need to immediately: <br />
                        <div className="container">
                            <div className="row">
                                <div className="col-md-2"></div> {/* Empty column to create space */}
                                <div className="col-md-10">
                                    <div className="">
                                        <ul className="list-styled">
                                            <li><b>STOP IT</b> (ensure resident is safe)</li>
                                            <li><b>CALL IT</b> (to the State Hotline)</li>
                                            <li><b>REPORT IT</b> (to your supervisor AND the Abuse Prevention Coordinator)</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        If you are not sure, talk to your supervisor or call the Administrator or DON anytime! <br /><br />
                        <div class="text-center mb-3">
                            Make sure you fill out a concern form for all resident concerns and complaints. A copy of the concern form needs given to the nurse supervisor in charge so an investigation can be initiated promptly. The original concern form must go to the Administrator for tracking of resolution.
                        </div>
                        <u>
                            <b>By signing this form, I acknowledge that I have had Abuse Prevention Training and understand <br />my
                                responsibilities as a Mandated Reporter: <br /></b></u><br />
                        <div class="row">
                            <div class="col-6 text-center mt-5">
                                {props?.signature ? <img src={props?.signature} width={180} height={50} alt="Signature here" /> :
                                    <div class="badge badge-primary text-wrap pointer" onClick={props?.onOpenModal}>
                                        Click Here To Sign
                                    </div>
                                }
                            </div>
                            <div class="col-6 text-center mt-5">
                                <input type="text" value={moment(new Date()).format("MM-DD-YYYY HH:mm:ss")} disabled />
                            </div>
                        </div>
                        <div class="row d-flex">
                            <div class="col-6 text-center border-top pb-3">
                                (Sign Name)
                            </div>
                            <div class="col-6 text-center border-top pb-3">
                                (Date)
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 text-center mt-5">
                                <input placeholder="Full Name" style={{ border: 0 }} required type="text" minLength={2} maxLength={20} name="full name" value={props.inputValues.full_name} onChange={(e) => props.handleInputChange('full_name', e.target.value)} />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 text-center border-top">
                                (Print Name)
                            </div>
                        </div>
                    </p>
                </div>
            </div>
            <div class="page-break">
                <div class="container">
                    <div class="row">
                        <div>
                            <h2 class="">Abuse Prevention Training Cont.</h2>
                            <ul>
                                <li><b>Definitions:</b>
                                    <ul>
                                        <li>Abuse is defined as the willful infliction of injury, unreasonable confinement, intimidation, or punishment with resulting physical harm, pain or mental anguish. Abuse also includes the deprivation by an individual, including a caretaker, of goods or services that are necessary to attain or maintain physical, mental, and psychosocial well-being. Instances of abuse of all residents, irrespective of any mental or physical condition, cause physical harm, pain or mental anguish. It includes verbal abuse, sexual abuse, physical abuse, and mental abuse including abuse facilitated or enabled through the use of technology.</li>
                                        <li>Neglect means the failure of the facility, its employees or service providers to provide goods and services to a resident that are necessary to avoid physical harm, pain, mental anguish or emotional distress.</li>
                                        <li>Sexual abuse is defined as non-consensual sexual contact of any type with a resident.</li>
                                        <li>Willful means the individual must have acted deliberately, not that the individual must have intended to inflict injury or harm.</li>
                                    </ul>
                                </li>
                            </ul>
                            <ul>
                                <li><b>Abuse includes:</b>
                                    <ul>
                                        <li>Staff to resident</li>
                                        <li>Resident to resident</li>
                                        <li>Visitor to resident</li>
                                    </ul>
                                </li>
                            </ul>
                            <ul>
                                <li>The risk for abuse increases with patients who demonstrate behaviors that may provoke others to react.</li>
                                <li>Abuse may result in psychological, behavioral, or psychosocial.</li>
                                <li>Physical abuse includes, but is not limited to, hitting, slapping, punching, biting, and kicking.</li>
                                <li>Corporal punishment, which is physical punishment, is used as a means to correct or control behavior. Corporal punishment includes, but is not limited to, pinching, spanking, slapping of hands, flicking, or hitting with an object.</li>
                                <li>Possible indicators of physical abuse include an injury that is suspicious because the source of the injury is not observed, the extent or location of the injury is unusual, or because of the number of injuries either at a single point in time or over time.</li>
                                <li>Abuse also includes the deprivation by staff of goods or services that are necessary to attain or maintain physical, mental, and psychosocial well-being.</li>
                                <li>Verbal abuse includes the use of oral, written, or gestured communication, or sounds, to residents within hearing distance, regardless of age, ability to comprehend, or disability.</li>
                                <li>Mental abuse includes abuse that is facilitated or enabled through the use of technology, such as smartphones and other personal electronic devices. This includes keeping and/or distributing demeaning or humiliating photographs and recordings through social media or multimedia messaging regardless of whether the resident provided consent or their cognitive status.</li>
                            </ul>

                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 text-center mt-5">
                        {props?.signature ? <img src={props?.signature} width={180} height={50} alt="Signature here" /> :
                            <div class="badge badge-primary text-wrap pointer" onClick={props?.onOpenModal}>
                                Click Here To Sign
                            </div>
                        }
                    </div>
                    <div class="col-6 text-center mt-5">
                        <input type="text" value={moment(new Date()).format("MM-DD-YYYY HH:mm:ss")} disabled />

                    </div>
                </div>
                <div class="row">
                    <div class="col-6 text-center border-top pb-3 ">
                        (Sign Name)
                    </div>
                    <div class="col-6 text-center border-top pb-3 ">
                        (Date)
                    </div>
                </div>
                <div class="row">
                    <div class="text-center mt-5">
                        <input placeholder="Full Name" style={{ border: 0 }} required type="text" minLength={2} maxLength={20} name="full name" value={props.inputValues.full_name} onChange={(e) => props.handleInputChange('full_name', e.target.value)} />
                    </div>
                </div>
                <div class="row">
                    <div class="text-center border-top">
                        (Print Name)
                    </div>
                </div>

            </div>
            <div class="page-break">
                <div>
                    <p>
                        <h5 className="mt-5 mb-2"><b>WORKING WITH RESIDENTS WITH LOW COGNITIVE FUNCTIONING ABILITIES</b></h5>
                        <div>
                            <ol type="i">
                                <li>Dementia
                                    <ol type="A">
                                        <li>Many types of dementia- the most known is Alzheimer's Disease.</li>
                                        <li>Caused by a buildup of a protein-like substance that attaches to the outside of the brain which causes 'plaques and tangles' that eventually choke off communication lines.</li>
                                        <li>As the disease progresses, the areas that are affected will eventually 'die' and whatever information was 'housed' there will be lost. That area can no longer function.</li>
                                        <li>Dementia is a loss of memory, language skills, and judgment. 'Childhood' in reverse. Also, effects speech, body functions, the ability to analyze and store what you see, hear, feel, taste, and smell. Eventually the ability to walk, talk, eat, think, and breathe.</li>
                                    </ol>
                                </li>
                                <li>Person Centered Care
                                    <ol type="A">
                                        <li>Person-centered care is finding out about the resident: their likes, dislikes, hobbies, past work history, family life, military experience, etc.
                                            <ol>
                                                <li>At intake, talk to family members to find out about the new resident's previous lifestyle and likes and dislikes, their schedules at home.</li>
                                            </ol>
                                        </li>
                                        <li>Using this information to form a plan of care that will help to put the resident at ease, making them less likely to have behaviors, allowing them to be happier in their stay here.</li>

                                        <li>Person-centered care approach has been found to be most effective in dealing with the unique needs of the residents:
                                            <ol>
                                                <li>Begin with talking to the resident, not about him or her.</li>
                                                <li>You need to meet the resident where he or she is, not where you want him or her to be.</li>
                                                <li>Give the resident a chance to do something with you, instead of doing something to them.</li>
                                                <li>Check their chart for medication side effects, their usual level of consciousness.</li>
                                                <li>Get to know the resident's unique non-verbal cues.</li>
                                                <li>Remember that the resident was not always like this.</li>
                                                <li>Forcing a resident to face reality only causes more confusion and embarrassment. It can also cause aggression, sudden verbal outbursts and even wandering. These behaviors are signals. The resident is trying to tell you something but cannot remember how.</li>
                                            </ol>
                                        </li>
                                    </ol>
                                </li>
                                <li>Wandering
                                    <ol type="A">
                                        <li>Can be caused by a physical need like forgetting how to use the bathroom or how to perform a daily task.</li>
                                        <li>Use simple commands, gentle touch, and clear direction with gestures to guide the resident back to his or her room.</li>
                                        <li>Never 'punish' residents with restraints, isolation, etc.</li>
                                    </ol>
                                </li>
                                <li>Communication
                                    <ol type="A">
                                        <li>Avoid placing the resident in a high noise area.</li>
                                        <li>Keep the television turned off, if possible.</li>
                                        <li>Always approach from the front, make eye contact, and identify yourself.</li>
                                        <li>Be aware of your own body language is saying.</li>
                                        <li>Hold resident's hand, smile, help him or her to feel safe.</li>
                                        <li>Give instructions one at a time.</li>
                                        <li>If the resident makes a mistake, do not correct him or her.</li>
                                        <li>Try to speak their language.</li>
                                        <li>Always make sure that upon approach, you are not in a rush, speak in a calm voice, never blow off steam, etc.</li>
                                    </ol>
                                </li>
                            </ol>
                            <p className="text-center"><b>WORKING WITH RESIDENTSWITH LOW COGNITIVE FUNCTIONING ABILITIES POLICY</b></p>
                            <p className="text-center">I have reviewed and received access to an electronic or hard copy of the WORKING WITH RESIDENTSWITH LOW COGNITIVE FUNCTIONING ABILITIES I understand that I can obtain extra copies through the FACILITY Educator representative. I have been given the opportunity to ask any questions and know that I am responsible for abiding by the policy.</p>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <ul class="list-unstyled">
                                    <li class="row">
                                        <div class="col-md-3">
                                            <label for="name">Name (print)<small className="text-danger">*</small>:</label>
                                        </div>
                                        <div class="col-md-9">
                                            <input placeholder="Full Name" style={{ borderWidth: "0px 0px 1px 0px", width: "75%" }} required type="text" minLength={2} maxLength={20} name="full name" value={props.inputValues.full_name} onChange={(e) => props.handleInputChange('full_name', e.target.value)} />
                                        </div>
                                    </li>
                                    <li class="row">
                                        <div class="col-md-3">
                                            <label for="position">Position<small className="text-danger">*</small>:</label>
                                        </div>
                                        <div class="col-md-9">
                                            <input type="text" style={{ borderWidth: "0px 0px 1px 0px", width: "75%" }} required maxLength={50} name="position" value={props.inputValues.EmploymentPosition} onChange={(e) => props.handleInputChange('EmploymentPosition', e.target.value)} />

                                        </div>
                                    </li>
                                    <li class="row">
                                        <div class="col-md-3">
                                            <label for="last_social">Last 4 of Social:</label>
                                        </div>
                                        <div class="col-md-9">
                                            <input type="text" style={{ borderWidth: "0px 0px 1px 0px", width: "95%" }} maxLength={200} name="social" value={props.inputValues.social} onChange={(e) => props.handleInputChange('social', e.target.value)} />
                                        </div>
                                    </li>
                                    <li class="row">
                                        <div class="col-md-3">
                                            <label for="signature">Signature:</label>
                                        </div>
                                        <div class="col-md-9">
                                            {props?.signature ? <img src={props?.signature} width={180} height={50} alt="Signature here" /> :
                                                <div class="badge badge-primary text-wrap pointer" onClick={props?.onOpenModal}>
                                                    Click Here To Sign
                                                </div>
                                            }
                                        </div>
                                    </li>
                                    <li class="row">
                                        <div class="col-md-3">
                                            <label for="date">Date:</label>
                                        </div>
                                        <div class="col-md-9">
                                            <input type="text" value={moment(new Date()).format("MM-DD-YYYY HH:mm:ss")} disabled />
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </p>
                </div>
            </div>
        </div>

    );
};

