import React, { useState, useEffect } from "react";
import HeaderPage from "../layout/HeaderPage";
import DrawerMenuPage from "../layout/DrawerMenuPage";
import * as Yup from 'yup';
import { Formik } from "formik";
import './MyProfilePage.css'
import Spinner from "@components/Spinner";
import { observer } from "mobx-react"
import { useNavigate } from 'react-router-dom';
import AdminStore from "@store/AdminStore";
import { InputField } from "@elements/index"
import Select, { components } from 'react-select';
import { Helmet } from "react-helmet";
var data = require('@assets/CountryCodes.json');
const colourStyles = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? 'blue' : '#75459b',
        borderRadius: 20,
        fontSize: '12px',
        boxShadow: 'none',
        padding: "2px 10px",
        '&:focus': {
            border: '1px solid blue !important'
        },
        '&:active': {
            border: '1px solid blue !important'
        },
        '&:hover': {
            border: '1px solid blue !important'
        }
    }),
    menu: base => ({
        ...base,
        fontSize: 12,
        zIndex: 9999
    }),
    menuList: (base) => ({
        ...base,
        height: "200px",

        "::-webkit-scrollbar": {
            width: "9px"
        },
        "::-webkit-scrollbar-track": {
            background: "white"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#8282C8"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        }
    })

}
const colourStylesTitle = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? 'blue' : '#75459b',
        borderRadius: 20,
        boxShadow: 'none',
        padding: "2px 10px",
        '&:focus': {
            border: '1px solid blue !important'
        },
        '&:active': {
            border: '1px solid blue !important'
        },
        '&:hover': {
            border: '1px solid #8282C8 !important'
        }
    }),
    menu: base => ({
        ...base,
        fontSize: 12,
    }),
    menuList: (base) => ({
        ...base,
        height: "auto",
        "::-webkit-scrollbar": {
            width: "9px"
        },
        "::-webkit-scrollbar-track": {
            background: "white"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#8282C8"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        }
    })
}
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const SignUpSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name Required').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
    last_name: Yup.string().required('Last Name Required').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
    contact: Yup.string().matches(phoneRegExp, 'Contact number is not valid').required('Contact number required').min(10).max(10, "Wrong contact number"),
    email: Yup.string().email('Invalid email').required('Required'),
});
const MyProfilePage = observer(() => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState();

    useEffect(() => {
        AdminStore.getAdminById();
    }, []);

    useEffect(() => {
        setUserData(AdminStore?.data?.profileDetails)
    }, [AdminStore?.data?.profileDetails]);

    const updateProfile = (values) => {
        values.contact = userData?.contact === values.contact ? null : values.contact
        AdminStore.updateAdminById(values, navigationCallBack)
    }
    const navigationCallBack = () => {
        AdminStore.getAdminById();
        navigate('/admin/dashboard');
    }
    return (
        <React.Fragment>
               <Helmet>
                <title>Staffers LLC | My Profile</title>
            </Helmet>
            {/* <body> */}
            <div className="container-scroller">
                <HeaderPage />
                <div className="container-fluid page-body-wrapper">
                    <DrawerMenuPage />
                    <div className="main-panel" style={{ right: 0, position: "absolute" }}>
                        <div className="content-wrapper p-3 bg-white ">
                            <h3 className="page-title">
                                <span className="page-title-icon linear text-white me-2">
                                    <i className="fa fa-id-card"></i>
                                </span>My Profile
                            </h3>

                        </div>
                        {/* <!-- partial --> */}
                        <div className=" row justify-content-center mt-4">
                            <div className="col-lg-11">
                                <Formik
                                    initialValues={{ title: userData?.title, first_name: userData?.first_name, last_name: userData?.last_name, contact: userData?.contact, email: userData?.email, country_code: userData?.country_code }}
                                    onSubmit={values => updateProfile(values)}
                                    validationSchema={SignUpSchema}
                                    enableReinitialize
                                    validateOnChange={false}>
                                    {
                                        ({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched
                                        }) =>
                                        (
                                            <form onSubmit={handleSubmit} className="wow fadeInUp">
                                                <div className="row g-2">
                                                    {/* <div className="col-sm-1">
                                                        <Select
                                                            onChange={(e) => setFieldValue("title", e.value)}
                                                            placeholder={''}
                                                            label={'Title'}
                                                            value={global.config.titleList.filter(({ value }) => values?.title?.includes(value)) || ''}
                                                            styles={colourStylesTitle}
                                                            isSearchable={true}
                                                            options={global.config.titleList}
                                                            defaultValue={{ label: "Mr.", value: 'Mr.' }}
                                                            components={{ Control, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                        />
                                                    </div> */}
                                                    <div className="col-sm-6 mb-3">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                            type="text"
                                                            name="first_name"
                                                            label={"First Name"}
                                                            value={values.first_name}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.first_name && touched.first_name ? (
                                                            <div className="error-message">{errors.first_name}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="col-sm-6 mb-3">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                            type="text"
                                                            name="last_name"
                                                            label={"Last Name"}
                                                            value={values.last_name}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.last_name && touched.last_name ? (
                                                            <div className="error-message">{errors.last_name}</div>
                                                        ) : null}
                                                    </div>

                                                    <div className="col-sm-6 mb-3">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                            type="email"
                                                            name="email"
                                                            label={"Email"}
                                                            value={values.email}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                    <div className="col-sm-2 mb-3">
                                                        <Select
                                                            onChange={(e) => setFieldValue("country_code", e.label)}
                                                            placeholder={''}
                                                            label={'Country Code'}
                                                            value={data.filter(({ label }) => values?.country_code?.includes(label)) || ''}
                                                            styles={colourStyles}
                                                            isSearchable={true}
                                                            options={data}
                                                            defaultValue={{ label: "+1", value: 'CA' }}
                                                            components={{ Control }}
                                                        />
                                                    </div>
                                                    <div className="col-sm-4 mb-3">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                            type="text"
                                                            name="contact"
                                                            label={"Contact"}
                                                            value={values?.contact?.replace(/[^\d]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}
                                                            maxLength={12}
                                                            minLength={12}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.contact && touched.contact ? (
                                                            <div className="error-message">{errors.contact}</div>
                                                        ) : null}
                                                    </div>

                                                    <div className="pt-4 d-flex justify-content-center">
                                                        <button className="btn btn-primary w-25 p-2" type="submit">Update</button>
                                                    </div>
                                                </div>
                                            </form>
                                        )
                                    }
                                </Formik>
                            </div>
                        </div>
                    </div>

                    {/* <!-- main-panel ends --> */}
                </div>
                {/* <!-- page-body-wrapper ends --> */}
            </div>
            {/* </body> */}
            <Spinner isLoading={AdminStore.loading} />

        </React.Fragment>
    );
})
export const Control = (props) => {
    return (
        <>
            <label className={props.hasValue || props.isFocused ? "did-floating-label did-floating-label-top" : "did-floating-label did-floating-label"} style={{ zIndex: 999 }}>{props?.selectProps?.label}</label>
            <components.Control {...props} />
        </>
    );
};

export default MyProfilePage;