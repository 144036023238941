/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import Header from '@components/Header';
import { Helmet } from 'react-helmet';
import { CDatePicker } from '@coreui/react-pro'
import 'react-spring-bottom-sheet/dist/style.css';
import StaffsStore from '../../store/StaffsStore';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { toast } from 'react-toastify';
import { toJS } from 'mobx';
import Spinner from "@components/Spinner";
import secureLocalStorage from 'react-secure-storage';

const ViewShiftsPage = observer(() => {
    const [isDate, setDate] = useState();
    const [shiftList, setShiftList] = useState([]);
    const [careGiverID, setCareGiverID] = useState(null);

    const tableRef = useRef()
    const tableName = "table1"
    useEffect(() => {
        setShiftList(toJS(StaffsStore.data.shiftList));
        let userData = secureLocalStorage.getItem("userData");
        setCareGiverID(userData)
    }, [StaffsStore.data.shiftList]);

    const getShift = () => {
        if (isDate) {
            let caregiverID = JSON?.parse(careGiverID)?.bluesky_staff_id
            StaffsStore.getShiftData(caregiverID, isDate);
        } else {
            toast.warn("Please choose date");
        }
    }
    useEffect(() => {
        const table = $(`#${tableName}`).DataTable(
            {

                data: shiftList,
                order: [], // Disable initial sorting
                columns: [
                    { title: "Shift Id", data: 'ShiftId' },
                    { title: "Shift Type", data: 'ShiftTypeName' },
                    { title: "Specialty", data: 'SpecialtyName' },
                    { title: "Start Date", data: 'StartDate' },
                    { title: "Start Time", data: 'StartTime' },
                    { title: "Unit Name", data: 'UnitName' },
                ],
                destroy: true,  // I think some clean up is happening here
                searching: true,
                "language": {
                    search: '',
                    emptyTable: "No records found"
                },
                initComplete: function () {
                    // Apply the search
                    this.api()
                        .columns([0, 1, 2, 5])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><input class="form-control" style="width:100%;margin-top:5px"s type="text" placeholder="" />')
                                .appendTo($(column.header()))

                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $('input', this.header()).on('keyup change clear', function () {
                                if (column.search() !== this.value) {
                                    column.search(this.value).draw();
                                }
                            });
                        });
                    this.api()
                        .columns([3])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><input type="date" class="form-control" style="width:100%;margin-top:5px"s type="text" placeholder="" />')
                                .appendTo($(column.header()))

                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $('input', this.header()).on('keyup change clear', function () {
                                if (!this.value) {
                                    $(`#${tableName}`).DataTable().column([3]).search('').draw();
                                }

                                if (column.search() !== this.value) {
                                    column.search(moment(this.value).format('MM/DD/YY')).draw();
                                }
                            });
                        });
                    this.api()
                        .columns([4])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><input type="time" class="form-control" style="width:100%;margin-top:5px"s type="text" placeholder="" />')
                                .appendTo($(column.header()))

                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $('input', this.header()).on('keyup change clear', function () {
                                if (!moment(this.value, "HH:mm").format("hh:mm A")) {
                                    $(`#${tableName}`).DataTable().column([4]).search('').draw();
                                }

                                if (column.search() !== moment(this.value, "HH:mm").format("hh:mm A")) {
                                    column.search(moment(this.value, "HH:mm").format("hh:mm A")).draw();
                                }
                            });
                        });

                },
            }
        )
        // Extra step to do extra clean-up.
        return function () {
            table.destroy()
        }
    }, [shiftList])

    return (
        <React.Fragment>
            <Header />
            <Helmet>
                <title>Staffers LLC | Bluesky Assigned Shifts</title>
            </Helmet>
            <>
                <div className="container-fluid callback-small">
                    <div className="container-fluid mt-5 pt-5">
                        <div className="container mt-5 mb-3">
                            <h5 className="display-5 animated slideInDown login-head mb-5">Check Your Assigned Shifts</h5>
                            <div className="row justify-content-center ">
                                <div className="container float-search d-flex justify-content-center top-padding">
                                    <div className="shadow border rounded  wow fadeInUp" style={{ backgroundColor: "#f6e8ff" }}>
                                        <div className="col-lg-12 col-12 pt-2 ps-2">
                                            <b className="">SELECT SHIFT DATE</b>
                                        </div>
                                        <div className="row px-5 d-flex justify-content-center mt-2 mb-2" >
                                            <div className="col-lg-8 pt-1 mb-2">
                                                <CDatePicker footer size="sm" onDateChange={(value) => setDate(moment(value).format("MM-DD-YYYY"))} locale="en-US" />
                                            </div>
                                            <div className="col-lg-4 mb-3" style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                                                <button className="btn btn-primary w-100 py-2" onClick={getShift}>View</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row bg-white" style={{ height: 500, paddingTop: "7%", paddingBottom: "30%"}}>
                        <p><strong>Please Note: </strong><span className='text-danger'><i>You will receive this shift from the allocated bluesky.</i></span></p>
                        <table className="table table-striped dt-responsive nowrap" style={{ width: "100%" }} id={tableName} ref={tableRef}></table>
                    </div>
                </div>
            </>
            <Spinner isLoading={StaffsStore.loading} />
        </React.Fragment>
    )
})

export default ViewShiftsPage;