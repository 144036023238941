import React from "react";
import Header from '@components/PDFHeader';
import moment from "moment";
import { Images } from "@elements/index"
import { HOME_URL } from "../../../api/config";

export const CertifiedNursingAssistantSkillsValidationChecklist = (props) => {
    return (
        <div className="container mt-3" id="form-content">
            <div >
                <div>
                    <div className="d-flex justify-content-between align-items-baseline mb-2">
                        <img
                            src={`${HOME_URL}static/media/brand.750d576a52f3ac057b3d.png`}
                            alt="staffers logo"
                            style={{
                                width: "180px",
                                height: "80px",
                            }}
                        />
                        <h4 >Certified Nursing Assistant</h4>
                    </div>
                    <hr style={{height:2, border: 'none', color: '#000'}} />
                    <h5 className="text-end"><b>Clinical Skills Validation Checklist</b></h5>

                    <div className="row">
                        <div className="col-6">
                            <p><b>Directions</b></p>
                            <p>Please circle a value for each question to provide us with an assessment of your clinical experience. These values confirm your strengths within your specialty and assist us at placing you within an assignment that best matches your current skill set.</p>
                        </div>
                        <div className="col-6">
                            <ul className="list-group">
                                <li style={{ background: "#000" }} className="list-group-item p-0 text-center text-white">Experience</li>
                                <li className="list-group-item p-0 ps-1">0 - Not Applicable</li>
                                <li className="list-group-item  p-0 ps-1">1 - No Experience</li>
                                <li className="list-group-item  p-0 ps-1">2 - Some Experience (Require Assistance)</li>
                                <li className="list-group-item  p-0 ps-1">3 - Intermittent Experience (May Require Assistance)</li>
                                <li className="list-group-item  p-0 ps-1">4 - Experienced (Performs without Assistance)</li>
                                <li className="list-group-item  p-0 ps-1">5 - Very Experienced (Able to Teach/Supervise)</li>
                            </ul></div>
                    </div>
                    <div className="">
                        <div className="row  mt-5">
                            <div className="col-4 text-center">
                                <input placeholder="Full Name" style={{ borderWidth: "0px 0px 1px 0px" }} required type="text" minLength={2} maxLength={20} name="full name" value={props.inputValues.full_name} onChange={(e) => props.handleInputChange('full_name', e.target.value)} />
                            </div>
                            <div className="col-4 text-center">
                                <input placeholder="SS#" style={{ borderWidth: "0px 0px 1px 0px" }} type="number" minLength={2} maxLength={20} name="SS#" value={props.inputValues.SSno} onChange={(e) => props.handleInputChange('SSno', e.target.value)} />
                            </div>
                            <div className="col-4 text-center">
                                <input type='text' value={moment(new Date()).format("MM-DD-YYYY HH:mm:ss")} disabled />
                            </div>
                        </div>
                        <div className="row border-top border-2"></div>
                        <div className="row">
                            <small className="col-4 text-center">Print Name</small>
                            <small className="col-4 text-center">Last 4 Digits of SS#</small>
                            <small className="col-4 text-center">Date</small>
                        </div>
                    </div>
                    <div className="container mt-3">
                        <table className="table table-bordered border-dark ">
                            <thead className="thead-dark" style={{ background: "#000" }}>
                                <tr>
                                    <th className=" text-light p-1" colspan="1">Ambulating</th>
                                    <th className="text-center text-light p-1" colspan="6">Experience</th>
                                </tr>
                            </thead>
                            <tbody className=""> 
                                <tr >
                                    <td className="border-bottom border-dark p-1 document-table-td" width={400} >Cane</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="cane0" name="cane"
                                            value="0"
                                            checked={props?.inputValues?.cane == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('cane', e.target.value) }}
                                        />
                                        <label for="cane0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="cane1" name="cane"
                                            value="1"
                                            checked={props?.inputValues?.cane == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('cane', e.target.value) }}
                                        />
                                        <label for="cane1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="cane2" name="cane"
                                            value="2"
                                            checked={props?.inputValues?.cane == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('cane', e.target.value) }} />
                                        <label for="cane2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="cane3" name="cane"
                                            value="3"
                                            checked={props?.inputValues?.cane == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('cane', e.target.value) }} />
                                        <label for="cane3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="cane4" name="cane"
                                            value="4"
                                            checked={props?.inputValues?.cane == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('cane', e.target.value) }} />
                                        <label for="cane4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="cane5" name="cane"
                                            value="5"
                                            checked={props?.inputValues?.cane == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('cane', e.target.value) }} />
                                        <label for="cane5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Walker</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="walker0" value="0"
                                            checked={props?.inputValues?.walker == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('walker', e.target.value) }} />
                                        <label for="walker0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="walker1" value="1"
                                            checked={props?.inputValues?.walker == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('walker', e.target.value) }} />
                                        <label for="walker1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="walker2" value="2"
                                            checked={props?.inputValues?.walker == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('walker', e.target.value) }} />
                                        <label for="walker2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="walker3" value="3"
                                            checked={props?.inputValues?.walker == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('walker', e.target.value) }} />
                                        <label for="walker3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="walker4" value="4"
                                            checked={props?.inputValues?.walker == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('walker', e.target.value) }} />
                                        <label for="walker4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="walker5" value="5"
                                            checked={props?.inputValues?.walker == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('walker', e.target.value) }} />
                                        <label for="walker5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Standby Assistant</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="standby0" value="0"
                                            checked={props?.inputValues?.standby == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('standby', e.target.value) }} />
                                        <label for="standby0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="standby1" value="1"
                                            checked={props?.inputValues?.standby == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('standby', e.target.value) }} />
                                        <label for="standby1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="standby2" value="2"
                                            checked={props?.inputValues?.standby == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('standby', e.target.value) }} />
                                        <label for="standby2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="standby3" value="3"
                                            checked={props?.inputValues?.standby == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('standby', e.target.value) }} />
                                        <label for="standby3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="standby4" value="4"
                                            checked={props?.inputValues?.standby == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('standby', e.target.value) }} />
                                        <label for="standby4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="standby5" value="5"
                                            checked={props?.inputValues?.standby == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('standby', e.target.value) }} />
                                        <label for="standby5">&nbsp;5</label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="container mt-3">
                        <table className="table table-bordered border-dark ">
                            <thead className="thead-dark" style={{ background: "#000" }}>
                                <tr>
                                    <th className=" text-light p-1" colspan="1">Personal Care</th>
                                    <th className="text-center text-light p-1" colspan="6">Experience</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" width={400} >Shampoo</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="shampoo0" value="0"
                                            checked={props?.inputValues?.shampoo == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('shampoo', e.target.value) }} />
                                        <label for="shampoo0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="shampoo1" value="1"
                                            checked={props?.inputValues?.shampoo == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('shampoo', e.target.value) }} />
                                        <label for="shampoo1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="shampoo2" value="2"
                                            checked={props?.inputValues?.shampoo == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('shampoo', e.target.value) }} />
                                        <label for="shampoo2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="shampoo3" value="3"
                                            checked={props?.inputValues?.shampoo == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('shampoo', e.target.value) }} />
                                        <label for="shampoo3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="shampoo4" value="4"
                                            checked={props?.inputValues?.shampoo == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('shampoo', e.target.value) }} />
                                        <label for="shampoo4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="shampoo5" value="5"
                                            checked={props?.inputValues?.shampoo == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('shampoo', e.target.value) }} />
                                        <label for="shampoo5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Nail care</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="nailCare0" value="0"
                                            checked={props?.inputValues?.nailCare == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('nailCare', e.target.value) }} />
                                        <label for="nailCare0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="nailCare1" value="1"
                                            checked={props?.inputValues?.nailCare == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('nailCare', e.target.value) }} />
                                        <label for="nailCare1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="nailCare2" value="2"
                                            checked={props?.inputValues?.nailCare == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('nailCare', e.target.value) }} />
                                        <label for="nailCare2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="nailCare3" value="3"
                                            checked={props?.inputValues?.nailCare == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('nailCare', e.target.value) }} />
                                        <label for="nailCare3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="nailCare4" value="4"
                                            checked={props?.inputValues?.nailCare == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('nailCare', e.target.value) }} />
                                        <label for="nailCare4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="nailCare5" value="5"
                                            checked={props?.inputValues?.nailCare == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('nailCare', e.target.value) }} />
                                        <label for="nailCare5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Oral hygiene</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="oralHygiene0" value="0"
                                            checked={props?.inputValues?.oralHygiene == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('oralHygiene', e.target.value) }} />
                                        <label for="oralHygiene0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="oralHygiene1" value="1"
                                            checked={props?.inputValues?.oralHygiene == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('oralHygiene', e.target.value) }} />
                                        <label for="oralHygiene1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="oralHygiene2" value="2"
                                            checked={props?.inputValues?.oralHygiene == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('oralHygiene', e.target.value) }} />
                                        <label for="oralHygiene2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="oralHygiene3" value="3"
                                            checked={props?.inputValues?.oralHygiene == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('oralHygiene', e.target.value) }} />
                                        <label for="oralHygiene3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="oralHygiene4" value="4"
                                            checked={props?.inputValues?.oralHygiene == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('oralHygiene', e.target.value) }} />
                                        <label for="oralHygiene4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="oralHygiene5" value="5"
                                            checked={props?.inputValues?.oralHygiene == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('oralHygiene', e.target.value) }} />
                                        <label for="oralHygiene5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Shaving safety/electric razor</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="shavingSafety0" value="0"
                                            checked={props?.inputValues?.shavingSafety == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('shavingSafety', e.target.value) }} />
                                        <label for="shavingSafety0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="shavingSafety1" value="1"
                                            checked={props?.inputValues?.shavingSafety == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('shavingSafety', e.target.value) }} />
                                        <label for="shavingSafety1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="shavingSafety2" value="2"
                                            checked={props?.inputValues?.shavingSafety == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('shavingSafety', e.target.value) }} />
                                        <label for="shavingSafety2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="shavingSafety3" value="3"
                                            checked={props?.inputValues?.shavingSafety == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('shavingSafety', e.target.value) }} />
                                        <label for="shavingSafety3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="shavingSafety4" value="4"
                                            checked={props?.inputValues?.shavingSafety == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('shavingSafety', e.target.value) }} />
                                        <label for="shavingSafety4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="shavingSafety5" value="5"
                                            checked={props?.inputValues?.shavingSafety == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('shavingSafety', e.target.value) }} />
                                        <label for="shavingSafety5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Dressing assist/complete</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="dressingAssist0" value="0"
                                            checked={props?.inputValues?.dressingAssist == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('dressingAssist', e.target.value) }} />
                                        <label for="dressingAssist0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="dressingAssist1" value="1"
                                            checked={props?.inputValues?.dressingAssist == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('dressingAssist', e.target.value) }} />
                                        <label for="dressingAssist1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="dressingAssist2" value="2"
                                            checked={props?.inputValues?.dressingAssist == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('dressingAssist', e.target.value) }} />
                                        <label for="dressingAssist2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="dressingAssist3" value="3"
                                            checked={props?.inputValues?.dressingAssist == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('dressingAssist', e.target.value) }} />
                                        <label for="dressingAssist3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="dressingAssist4" value="4"
                                            checked={props?.inputValues?.dressingAssist == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('dressingAssist', e.target.value) }} />
                                        <label for="dressingAssist4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="dressingAssist5" value="5"
                                            checked={props?.inputValues?.dressingAssist == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('dressingAssist', e.target.value) }} />
                                        <label for="dressingAssist5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Perineal care: (male and female)</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="perinealCare0" value="0"
                                            checked={props?.inputValues?.perinealCare == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('perinealCare', e.target.value) }} />
                                        <label for="perinealCare0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="perinealCare1" value="1"
                                            checked={props?.inputValues?.perinealCare == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('perinealCare', e.target.value) }} />
                                        <label for="perinealCare1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="perinealCare2" value="2"
                                            checked={props?.inputValues?.perinealCare == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('perinealCare', e.target.value) }} />
                                        <label for="perinealCare2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="perinealCare3" value="3"
                                            checked={props?.inputValues?.perinealCare == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('perinealCare', e.target.value) }} />
                                        <label for="perinealCare3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="perinealCare4" value="4"
                                            checked={props?.inputValues?.perinealCare == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('perinealCare', e.target.value) }} />
                                        <label for="perinealCare4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="perinealCare5" value="5"
                                            checked={props?.inputValues?.perinealCare == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('perinealCare', e.target.value) }} />
                                        <label for="perinealCare5">&nbsp;5</label>
                                    </td>
                                </tr>

                                <tr style={{ background: "#D9D9D9" }} >
                                    <td className="border-bottom border-dark p-1 document-table-td" colspan="8"><b>Skin Care</b></td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Bath, bed, tub, and shower</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="bathBedTubShower0" value="0"
                                            checked={props?.inputValues?.bathBedTubShower == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('bathBedTubShower', e.target.value) }} />
                                        <label for="bathBedTubShower0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="bathBedTubShower1" value="1"
                                            checked={props?.inputValues?.bathBedTubShower == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('bathBedTubShower', e.target.value) }} />
                                        <label for="bathBedTubShower1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="bathBedTubShower2" value="2"
                                            checked={props?.inputValues?.bathBedTubShower == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('bathBedTubShower', e.target.value) }} />
                                        <label for="bathBedTubShower2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="bathBedTubShower3" value="3"
                                            checked={props?.inputValues?.bathBedTubShower == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('bathBedTubShower', e.target.value) }} />
                                        <label for="bathBedTubShower3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="bathBedTubShower4" value="4"
                                            checked={props?.inputValues?.bathBedTubShower == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('bathBedTubShower', e.target.value) }} />
                                        <label for="bathBedTubShower4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="bathBedTubShower5" value="5"
                                            checked={props?.inputValues?.bathBedTubShower == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('bathBedTubShower', e.target.value) }} />
                                        <label for="bathBedTubShower5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Applying lotion</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="applyingLotion0" value="0"
                                            checked={props?.inputValues?.applyingLotion == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('applyingLotion', e.target.value) }} />
                                        <label for="applyingLotion0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="applyingLotion1" value="1"
                                            checked={props?.inputValues?.applyingLotion == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('applyingLotion', e.target.value) }} />
                                        <label for="applyingLotion1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="applyingLotion2" value="2"
                                            checked={props?.inputValues?.applyingLotion == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('applyingLotion', e.target.value) }} />
                                        <label for="applyingLotion2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="applyingLotion3" value="3"
                                            checked={props?.inputValues?.applyingLotion == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('applyingLotion', e.target.value) }} />
                                        <label for="applyingLotion3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="applyingLotion4" value="4"
                                            checked={props?.inputValues?.applyingLotion == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('applyingLotion', e.target.value) }} />
                                        <label for="applyingLotion4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="applyingLotion5" value="5"
                                            checked={props?.inputValues?.applyingLotion == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('applyingLotion', e.target.value) }} />
                                        <label for="applyingLotion5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Back Rub</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="backRub0" value="0"
                                            checked={props?.inputValues?.backRub == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('backRub', e.target.value) }} />
                                        <label for="backRub0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="backRub1" value="1"
                                            checked={props?.inputValues?.backRub == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('backRub', e.target.value) }} />
                                        <label for="backRub1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="backRub2" value="2"
                                            checked={props?.inputValues?.backRub == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('backRub', e.target.value) }} />
                                        <label for="backRub2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="backRub3" value="3"
                                            checked={props?.inputValues?.backRub == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('backRub', e.target.value) }} />
                                        <label for="backRub3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="backRub4" value="4"
                                            checked={props?.inputValues?.backRub == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('backRub', e.target.value) }} />
                                        <label for="backRub4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="backRub5" value="5"
                                            checked={props?.inputValues?.backRub == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('backRub', e.target.value) }} />
                                        <label for="backRub5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Decubitus care</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="decubitusCare0" value="0"
                                            checked={props?.inputValues?.decubitusCare == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('decubitusCare', e.target.value) }} />
                                        <label for="decubitusCare0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="decubitusCare1" value="1"
                                            checked={props?.inputValues?.decubitusCare == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('decubitusCare', e.target.value) }} />
                                        <label for="decubitusCare1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="decubitusCare2" value="2"
                                            checked={props?.inputValues?.decubitusCare == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('decubitusCare', e.target.value) }} />
                                        <label for="decubitusCare2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="decubitusCare3" value="3"
                                            checked={props?.inputValues?.decubitusCare == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('decubitusCare', e.target.value) }} />
                                        <label for="decubitusCare3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="decubitusCare4" value="4"
                                            checked={props?.inputValues?.decubitusCare == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('decubitusCare', e.target.value) }} />
                                        <label for="decubitusCare4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="decubitusCare5" value="5"
                                            checked={props?.inputValues?.decubitusCare == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('decubitusCare', e.target.value) }} />
                                        <label for="decubitusCare5">&nbsp;5</label>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div className="container mt-3">
                        <table className="table table-bordered border-dark ">
                            <thead className="thead-dark" style={{ background: "#000" }}>
                                <tr>
                                    <th className=" text-light p-1" colspan="1">Environment</th>
                                    <th className="text-center text-light p-1" colspan="6">Experience</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                <tr style={{ background: "#D9D9D9" }} >
                                    <td className="border-bottom border-dark p-1 document-table-td" colspan="8" width={400}><b>Linen Change</b></td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" width={400} >Unoccupied Bed</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="unoccupiedBed0" value="0"
                                            checked={props?.inputValues?.unoccupiedBed == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('unoccupiedBed', e.target.value) }} />
                                        <label for="unoccupiedBed0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="unoccupiedBed1" value="1"
                                            checked={props?.inputValues?.unoccupiedBed == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('unoccupiedBed', e.target.value) }} />
                                        <label for="unoccupiedBed1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="unoccupiedBed2" value="2"
                                            checked={props?.inputValues?.unoccupiedBed == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('unoccupiedBed', e.target.value) }} />
                                        <label for="unoccupiedBed2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="unoccupiedBed3" value="3"
                                            checked={props?.inputValues?.unoccupiedBed == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('unoccupiedBed', e.target.value) }} />
                                        <label for="unoccupiedBed3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="unoccupiedBed4" value="4"
                                            checked={props?.inputValues?.unoccupiedBed == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('unoccupiedBed', e.target.value) }} />
                                        <label for="unoccupiedBed4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="unoccupiedBed5" value="5"
                                            checked={props?.inputValues?.unoccupiedBed == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('unoccupiedBed', e.target.value) }} />
                                        <label for="unoccupiedBed5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Occupied Bed</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="occupiedBed0" value="0"
                                            checked={props?.inputValues?.occupiedBed == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('occupiedBed', e.target.value) }} />
                                        <label for="occupiedBed0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="occupiedBed1" value="1"
                                            checked={props?.inputValues?.occupiedBed == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('occupiedBed', e.target.value) }} />
                                        <label for="occupiedBed1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="occupiedBed2" value="2"
                                            checked={props?.inputValues?.occupiedBed == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('occupiedBed', e.target.value) }} />
                                        <label for="occupiedBed2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="occupiedBed3" value="3"
                                            checked={props?.inputValues?.occupiedBed == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('occupiedBed', e.target.value) }} />
                                        <label for="occupiedBed3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="occupiedBed4" value="4"
                                            checked={props?.inputValues?.occupiedBed == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('occupiedBed', e.target.value) }} />
                                        <label for="occupiedBed4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="occupiedBed5" value="5"
                                            checked={props?.inputValues?.occupiedBed == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('occupiedBed', e.target.value) }} />
                                        <label for="occupiedBed5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Valuable Securement/Inventory</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="valuableSecurementInventory0" value="0"
                                            checked={props?.inputValues?.valuableSecurementInventory == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('valuableSecurementInventory', e.target.value) }} />
                                        <label for="valuableSecurementInventory0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="valuableSecurementInventory1" value="1"
                                            checked={props?.inputValues?.valuableSecurementInventory == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('valuableSecurementInventory', e.target.value) }} />
                                        <label for="valuableSecurementInventory1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="valuableSecurementInventory2" value="2"
                                            checked={props?.inputValues?.valuableSecurementInventory == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('valuableSecurementInventory', e.target.value) }} />
                                        <label for="valuableSecurementInventory2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="valuableSecurementInventory3" value="3"
                                            checked={props?.inputValues?.valuableSecurementInventory == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('valuableSecurementInventory', e.target.value) }} />
                                        <label for="valuableSecurementInventory3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="valuableSecurementInventory4" value="4"
                                            checked={props?.inputValues?.valuableSecurementInventory == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('valuableSecurementInventory', e.target.value) }} />
                                        <label for="valuableSecurementInventory4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="valuableSecurementInventory5" value="5"
                                            checked={props?.inputValues?.valuableSecurementInventory == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('valuableSecurementInventory', e.target.value) }} />
                                        <label for="valuableSecurementInventory5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Admission bed/equipment set up</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="admissionSetUp0" value="0"
                                            checked={props?.inputValues?.admissionSetUp == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('admissionSetUp', e.target.value) }} />
                                        <label for="admissionSetUp0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="admissionSetUp1" value="1"
                                            checked={props?.inputValues?.admissionSetUp == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('admissionSetUp', e.target.value) }} />
                                        <label for="admissionSetUp1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="admissionSetUp2" value="2"
                                            checked={props?.inputValues?.admissionSetUp == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('admissionSetUp', e.target.value) }} />
                                        <label for="admissionSetUp2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="admissionSetUp3" value="3"
                                            checked={props?.inputValues?.admissionSetUp == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('admissionSetUp', e.target.value) }} />
                                        <label for="admissionSetUp3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="admissionSetUp4" value="4"
                                            checked={props?.inputValues?.admissionSetUp == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('admissionSetUp', e.target.value) }} />
                                        <label for="admissionSetUp4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="admissionSetUp5" value="5"
                                            checked={props?.inputValues?.admissionSetUp == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('admissionSetUp', e.target.value) }} />
                                        <label for="admissionSetUp5">&nbsp;5</label>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div className="container mt-3">
                        <table className="table table-bordered border-dark ">
                            <thead className="thead-dark" style={{ background: "#000" }}>
                                <tr>
                                    <th className=" text-light p-1" colspan="1">Nutrition/Hydration</th>
                                    <th className="text-center text-light p-1" colspan="6" >Experience</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" width={400} >Light Housekeeping</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="lightHousekeeping0" value="0"
                                            checked={props?.inputValues?.lightHousekeeping == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('lightHousekeeping', e.target.value) }} />
                                        <label for="lightHousekeeping0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="lightHousekeeping1" value="1"
                                            checked={props?.inputValues?.lightHousekeeping == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('lightHousekeeping', e.target.value) }} />
                                        <label for="lightHousekeeping1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="lightHousekeeping2" value="2"
                                            checked={props?.inputValues?.lightHousekeeping == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('lightHousekeeping', e.target.value) }} />
                                        <label for="lightHousekeeping2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="lightHousekeeping3" value="3"
                                            checked={props?.inputValues?.lightHousekeeping == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('lightHousekeeping', e.target.value) }} />
                                        <label for="lightHousekeeping3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="lightHousekeeping4" value="4"
                                            checked={props?.inputValues?.lightHousekeeping == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('lightHousekeeping', e.target.value) }} />
                                        <label for="lightHousekeeping4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="lightHousekeeping5" value="5"
                                            checked={props?.inputValues?.lightHousekeeping == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('lightHousekeeping', e.target.value) }} />
                                        <label for="lightHousekeeping5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Encourage Fluids</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="encourageFluids0" value="0"
                                            checked={props?.inputValues?.encourageFluids == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('encourageFluids', e.target.value) }} />
                                        <label for="encourageFluids0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="encourageFluids1" value="1"
                                            checked={props?.inputValues?.encourageFluids == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('encourageFluids', e.target.value) }} />
                                        <label for="encourageFluids1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="encourageFluids2" value="2"
                                            checked={props?.inputValues?.encourageFluids == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('encourageFluids', e.target.value) }} />
                                        <label for="encourageFluids2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="encourageFluids3" value="3"
                                            checked={props?.inputValues?.encourageFluids == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('encourageFluids', e.target.value) }} />
                                        <label for="encourageFluids3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="encourageFluids4" value="4"
                                            checked={props?.inputValues?.encourageFluids == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('encourageFluids', e.target.value) }} />
                                        <label for="encourageFluids4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="encourageFluids5" value="5"
                                            checked={props?.inputValues?.encourageFluids == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('encourageFluids', e.target.value) }} />
                                        <label for="encourageFluids5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Assist in feeding</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="assistFeeding0" value="0"
                                            checked={props?.inputValues?.assistFeeding == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('assistFeeding', e.target.value) }} />
                                        <label for="assistFeeding0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="assistFeeding1" value="1"
                                            checked={props?.inputValues?.assistFeeding == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('assistFeeding', e.target.value) }} />
                                        <label for="assistFeeding1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="assistFeeding2" value="2"
                                            checked={props?.inputValues?.assistFeeding == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('assistFeeding', e.target.value) }} />
                                        <label for="assistFeeding2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="assistFeeding3" value="3"
                                            checked={props?.inputValues?.assistFeeding == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('assistFeeding', e.target.value) }} />
                                        <label for="assistFeeding3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="assistFeeding4" value="4"
                                            checked={props?.inputValues?.assistFeeding == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('assistFeeding', e.target.value) }} />
                                        <label for="assistFeeding4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="assistFeeding5" value="5"
                                            checked={props?.inputValues?.assistFeeding == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('assistFeeding', e.target.value) }} />
                                        <label for="assistFeeding5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Feeding techniques</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="feedingTechniques0" value="0"
                                            checked={props?.inputValues?.feedingTechniques == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('feedingTechniques', e.target.value) }} />
                                        <label for="feedingTechniques0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="feedingTechniques1" value="1"
                                            checked={props?.inputValues?.feedingTechniques == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('feedingTechniques', e.target.value) }} />
                                        <label for="feedingTechniques1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="feedingTechniques2" value="2"
                                            checked={props?.inputValues?.feedingTechniques == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('feedingTechniques', e.target.value) }} />
                                        <label for="feedingTechniques2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="feedingTechniques3" value="3"
                                            checked={props?.inputValues?.feedingTechniques == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('feedingTechniques', e.target.value) }} />
                                        <label for="feedingTechniques3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="feedingTechniques4" value="4"
                                            checked={props?.inputValues?.feedingTechniques == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('feedingTechniques', e.target.value) }} />
                                        <label for="feedingTechniques4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="feedingTechniques5" value="5"
                                            checked={props?.inputValues?.feedingTechniques == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('feedingTechniques', e.target.value) }} />
                                        <label for="feedingTechniques5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Meal/snack prep</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="mealSnackPrep0" value="0"
                                            checked={props?.inputValues?.mealSnackPrep == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('mealSnackPrep', e.target.value) }} />
                                        <label for="mealSnackPrep0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="mealSnackPrep1" value="1"
                                            checked={props?.inputValues?.mealSnackPrep == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('mealSnackPrep', e.target.value) }} />
                                        <label for="mealSnackPrep1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="mealSnackPrep2" value="2"
                                            checked={props?.inputValues?.mealSnackPrep == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('mealSnackPrep', e.target.value) }} />
                                        <label for="mealSnackPrep2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="mealSnackPrep3" value="3"
                                            checked={props?.inputValues?.mealSnackPrep == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('mealSnackPrep', e.target.value) }} />
                                        <label for="mealSnackPrep3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="mealSnackPrep4" value="4"
                                            checked={props?.inputValues?.mealSnackPrep == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('mealSnackPrep', e.target.value) }} />
                                        <label for="mealSnackPrep4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="mealSnackPrep5" value="5"
                                            checked={props?.inputValues?.mealSnackPrep == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('mealSnackPrep', e.target.value) }} />
                                        <label for="mealSnackPrep5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Measure and record intake</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="measureRecordIntake0" value="0"
                                            checked={props?.inputValues?.measureRecordIntake == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('measureRecordIntake', e.target.value) }} />
                                        <label for="measureRecordIntake0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="measureRecordIntake1" value="1"
                                            checked={props?.inputValues?.measureRecordIntake == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('measureRecordIntake', e.target.value) }} />
                                        <label for="measureRecordIntake1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="measureRecordIntake2" value="2"
                                            checked={props?.inputValues?.measureRecordIntake == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('measureRecordIntake', e.target.value) }} />
                                        <label for="measureRecordIntake2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="measureRecordIntake3" value="3"
                                            checked={props?.inputValues?.measureRecordIntake == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('measureRecordIntake', e.target.value) }} />
                                        <label for="measureRecordIntake3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="measureRecordIntake4" value="4"
                                            checked={props?.inputValues?.measureRecordIntake == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('measureRecordIntake', e.target.value) }} />
                                        <label for="measureRecordIntake4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="measureRecordIntake5" value="5"
                                            checked={props?.inputValues?.measureRecordIntake == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('measureRecordIntake', e.target.value) }} />
                                        <label for="measureRecordIntake5">&nbsp;5</label>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div className="container mt-3">
                        <table className="table table-bordered border-dark ">
                            <thead className="thead-dark" style={{ background: "#000" }}>
                                <tr>
                                    <th className=" text-light p-1" colspan="1">Infection Control </th>
                                    <th className="text-center text-light p-1" colspan="6">Experience</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" width={400} >Hand washing</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="handWashing0" value="0"
                                            checked={props?.inputValues?.handWashing == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('handWashing', e.target.value) }} />
                                        <label for="handWashing0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="handWashing1" value="1"
                                            checked={props?.inputValues?.handWashing == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('handWashing', e.target.value) }} />
                                        <label for="handWashing1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="handWashing2" value="2"
                                            checked={props?.inputValues?.handWashing == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('handWashing', e.target.value) }} />
                                        <label for="handWashing2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="handWashing3" value="3"
                                            checked={props?.inputValues?.handWashing == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('handWashing', e.target.value) }} />
                                        <label for="handWashing3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="handWashing4" value="4"
                                            checked={props?.inputValues?.handWashing == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('handWashing', e.target.value) }} />
                                        <label for="handWashing4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="handWashing5" value="5"
                                            checked={props?.inputValues?.handWashing == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('handWashing', e.target.value) }} />
                                        <label for="handWashing5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Airborne Precaution</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="airbornePrecaution0" value="0"
                                            checked={props?.inputValues?.airbornePrecaution == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('airbornePrecaution', e.target.value) }} />
                                        <label for="airbornePrecaution0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="airbornePrecaution1" value="1"
                                            checked={props?.inputValues?.airbornePrecaution == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('airbornePrecaution', e.target.value) }} />
                                        <label for="airbornePrecaution1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="airbornePrecaution2" value="2"
                                            checked={props?.inputValues?.airbornePrecaution == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('airbornePrecaution', e.target.value) }} />
                                        <label for="airbornePrecaution2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="airbornePrecaution3" value="3"
                                            checked={props?.inputValues?.airbornePrecaution == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('airbornePrecaution', e.target.value) }} />
                                        <label for="airbornePrecaution3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="airbornePrecaution4" value="4"
                                            checked={props?.inputValues?.airbornePrecaution == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('airbornePrecaution', e.target.value) }} />
                                        <label for="airbornePrecaution4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="airbornePrecaution5" value="5"
                                            checked={props?.inputValues?.airbornePrecaution == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('airbornePrecaution', e.target.value) }} />
                                        <label for="airbornePrecaution5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Droplet Precaution</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="dropletPrecaution0" value="0"
                                            checked={props?.inputValues?.dropletPrecaution == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('dropletPrecaution', e.target.value) }} />
                                        <label for="dropletPrecaution0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="dropletPrecaution1" value="1"
                                            checked={props?.inputValues?.dropletPrecaution == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('dropletPrecaution', e.target.value) }} />
                                        <label for="dropletPrecaution1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="dropletPrecaution2" value="2"
                                            checked={props?.inputValues?.dropletPrecaution == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('dropletPrecaution', e.target.value) }} />
                                        <label for="dropletPrecaution2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="dropletPrecaution3" value="3"
                                            checked={props?.inputValues?.dropletPrecaution == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('dropletPrecaution', e.target.value) }} />
                                        <label for="dropletPrecaution3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="dropletPrecaution4" value="4"
                                            checked={props?.inputValues?.dropletPrecaution == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('dropletPrecaution', e.target.value) }} />
                                        <label for="dropletPrecaution4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="dropletPrecaution5" value="5"
                                            checked={props?.inputValues?.dropletPrecaution == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('dropletPrecaution', e.target.value) }} />
                                        <label for="dropletPrecaution5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Universal Precautions</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="universalPrecautions0" value="0"
                                            checked={props?.inputValues?.universalPrecautions == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('universalPrecautions', e.target.value) }} />
                                        <label for="universalPrecautions0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="universalPrecautions1" value="1"
                                            checked={props?.inputValues?.universalPrecautions == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('universalPrecautions', e.target.value) }} />
                                        <label for="universalPrecautions1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="universalPrecautions2" value="2"
                                            checked={props?.inputValues?.universalPrecautions == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('universalPrecautions', e.target.value) }} />
                                        <label for="universalPrecautions2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="universalPrecautions3" value="3"
                                            checked={props?.inputValues?.universalPrecautions == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('universalPrecautions', e.target.value) }} />
                                        <label for="universalPrecautions3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="universalPrecautions4" value="4"
                                            checked={props?.inputValues?.universalPrecautions == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('universalPrecautions', e.target.value) }} />
                                        <label for="universalPrecautions4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="universalPrecautions5" value="5"
                                            checked={props?.inputValues?.universalPrecautions == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('universalPrecautions', e.target.value) }} />
                                        <label for="universalPrecautions5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Personal Protective Equipment</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="ppe0" value="0"
                                            checked={props?.inputValues?.ppe == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('ppe', e.target.value) }} />
                                        <label for="ppe0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="ppe1" value="1"
                                            checked={props?.inputValues?.ppe == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('ppe', e.target.value) }} />
                                        <label for="ppe1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="ppe2" value="2"
                                            checked={props?.inputValues?.ppe == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('ppe', e.target.value) }} />
                                        <label for="ppe2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="ppe3" value="3"
                                            checked={props?.inputValues?.ppe == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('ppe', e.target.value) }} />
                                        <label for="ppe3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="ppe4" value="4"
                                            checked={props?.inputValues?.ppe == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('ppe', e.target.value) }} />
                                        <label for="ppe4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="ppe5" value="5"
                                            checked={props?.inputValues?.ppe == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('ppe', e.target.value) }} />
                                        <label for="ppe5">&nbsp;5</label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="container mt-3">
                        <table className="table table-bordered border-dark ">
                            <thead className="thead-dark" style={{ background: "#000" }}>
                                <tr>
                                    <th className=" text-light p-1 document-table-td" colspan="1">Bowel and Bladder (Elimination)</th>
                                    <th className="text-center text-light p-1" colspan="6">Experience</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" width={400} >Bed pan/urinal and fracture pan</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="bedPanUrinal0" value="0"
                                            checked={props?.inputValues?.bedPanUrinal == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('bedPanUrinal', e.target.value) }} />
                                        <label for="bedPanUrinal0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="bedPanUrinal1" value="1"
                                            checked={props?.inputValues?.bedPanUrinal == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('bedPanUrinal', e.target.value) }} />
                                        <label for="bedPanUrinal1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="bedPanUrinal2" value="2"
                                            checked={props?.inputValues?.bedPanUrinal == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('bedPanUrinal', e.target.value) }} />
                                        <label for="bedPanUrinal2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="bedPanUrinal3" value="3"
                                            checked={props?.inputValues?.bedPanUrinal == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('bedPanUrinal', e.target.value) }} />
                                        <label for="bedPanUrinal3">&nbsp;3</label>
                                    </td>

                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="bedPanUrinal4" value="4"
                                            checked={props?.inputValues?.bedPanUrinal == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('bedPanUrinal', e.target.value) }} />
                                        <label for="bedPanUrinal4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="bedPanUrinal5" value="5"
                                            checked={props?.inputValues?.bedPanUrinal == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('bedPanUrinal', e.target.value) }} />
                                        <label for="bedPanUrinal5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Bedside commode</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="bedsideCommode0" value="0"
                                            checked={props?.inputValues?.bedsideCommode == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('bedsideCommode', e.target.value) }} />
                                        <label for="bedsideCommode0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="bedsideCommode1" value="1"
                                            checked={props?.inputValues?.bedsideCommode == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('bedsideCommode', e.target.value) }} />
                                        <label for="bedsideCommode1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="bedsideCommode2" value="2"
                                            checked={props?.inputValues?.bedsideCommode == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('bedsideCommode', e.target.value) }} />
                                        <label for="bedsideCommode2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="bedsideCommode3" value="3"
                                            checked={props?.inputValues?.bedsideCommode == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('bedsideCommode', e.target.value) }} />
                                        <label for="bedsideCommode3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="bedsideCommode4" value="4"
                                            checked={props?.inputValues?.bedsideCommode == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('bedsideCommode', e.target.value) }} />
                                        <label for="bedsideCommode4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="bedsideCommode5" value="5"
                                            checked={props?.inputValues?.bedsideCommode == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('bedsideCommode', e.target.value) }} />
                                        <label for="bedsideCommode5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Measure and record output</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="measureRecordOutput0" value="0"
                                            checked={props?.inputValues?.measureRecordOutput == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('measureRecordOutput', e.target.value) }} />
                                        <label for="measureRecordOutput0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="measureRecordOutput1" value="1"
                                            checked={props?.inputValues?.measureRecordOutput == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('measureRecordOutput', e.target.value) }} />
                                        <label for="measureRecordOutput1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="measureRecordOutput2" value="2"
                                            checked={props?.inputValues?.measureRecordOutput == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('measureRecordOutput', e.target.value) }} />
                                        <label for="measureRecordOutput2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="measureRecordOutput3" value="3"
                                            checked={props?.inputValues?.measureRecordOutput == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('measureRecordOutput', e.target.value) }} />
                                        <label for="measureRecordOutput3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="measureRecordOutput4" value="4"
                                            checked={props?.inputValues?.measureRecordOutput == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('measureRecordOutput', e.target.value) }} />
                                        <label for="measureRecordOutput4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="measureRecordOutput5" value="5"
                                            checked={props?.inputValues?.measureRecordOutput == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('measureRecordOutput', e.target.value) }} />
                                        <label for="measureRecordOutput5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Foley catheter care</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="foleyCatheterCare0" value="0"
                                            checked={props?.inputValues?.foleyCatheterCare == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('foleyCatheterCare', e.target.value) }} />
                                        <label for="foleyCatheterCare0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="foleyCatheterCare1" value="1"
                                            checked={props?.inputValues?.foleyCatheterCare == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('foleyCatheterCare', e.target.value) }} />
                                        <label for="foleyCatheterCare1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="foleyCatheterCare2" value="2"
                                            checked={props?.inputValues?.foleyCatheterCare == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('foleyCatheterCare', e.target.value) }} />
                                        <label for="foleyCatheterCare2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="foleyCatheterCare3" value="3"
                                            checked={props?.inputValues?.foleyCatheterCare == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('foleyCatheterCare', e.target.value) }} />
                                        <label for="foleyCatheterCare3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="foleyCatheterCare4" value="4"
                                            checked={props?.inputValues?.foleyCatheterCare == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('foleyCatheterCare', e.target.value) }} />
                                        <label for="foleyCatheterCare4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="foleyCatheterCare5" value="5"
                                            checked={props?.inputValues?.foleyCatheterCare == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('foleyCatheterCare', e.target.value) }} />
                                        <label for="foleyCatheterCare5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">External catheter care</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="externalCatheterCare0" value="0"
                                            checked={props?.inputValues?.externalCatheterCare == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('externalCatheterCare', e.target.value) }} />
                                        <label for="externalCatheterCare0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="externalCatheterCare1" value="1"
                                            checked={props?.inputValues?.externalCatheterCare == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('externalCatheterCare', e.target.value) }} />
                                        <label for="externalCatheterCare1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="externalCatheterCare2" value="2"
                                            checked={props?.inputValues?.externalCatheterCare == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('externalCatheterCare', e.target.value) }} />
                                        <label for="externalCatheterCare2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="externalCatheterCare3" value="3"
                                            checked={props?.inputValues?.externalCatheterCare == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('externalCatheterCare', e.target.value) }} />
                                        <label for="externalCatheterCare3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="externalCatheterCare4" value="4"
                                            checked={props?.inputValues?.externalCatheterCare == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('externalCatheterCare', e.target.value) }} />
                                        <label for="externalCatheterCare4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="externalCatheterCare5" value="5"
                                            checked={props?.inputValues?.externalCatheterCare == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('externalCatheterCare', e.target.value) }} />
                                        <label for="externalCatheterCare5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Enemas tab H20, fleets, soap suds</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="enemasTabH200" value="0"
                                            checked={props?.inputValues?.enemasTabH20 == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('enemasTabH20', e.target.value) }} />
                                        <label for="enemasTabH200">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="enemasTabH201" value="1"
                                            checked={props?.inputValues?.enemasTabH20 == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('enemasTabH20', e.target.value) }} />
                                        <label for="enemasTabH201">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="enemasTabH202" value="2"
                                            checked={props?.inputValues?.enemasTabH20 == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('enemasTabH20', e.target.value) }} />
                                        <label for="enemasTabH202">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="enemasTabH203" value="3"
                                            checked={props?.inputValues?.enemasTabH20 == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('enemasTabH20', e.target.value) }} />
                                        <label for="enemasTabH203">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="enemasTabH204" value="4"
                                            checked={props?.inputValues?.enemasTabH20 == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('enemasTabH20', e.target.value) }} />
                                        <label for="enemasTabH204">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="enemasTabH205" value="5"
                                            checked={props?.inputValues?.enemasTabH20 == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('enemasTabH20', e.target.value) }} />
                                        <label for="enemasTabH205">&nbsp;5</label>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div className="container mt-3">
                        <table className="table table-bordered border-dark ">
                            <thead className="thead-dark" style={{ background: "#000" }}>
                                <tr>
                                    <th className=" text-light p-1" colspan="1">Transfer Techniques</th>
                                    <th className="text-center text-light p-1" colspan="6">Experience</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" width={400} >Gait belt</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="gaitBelt0" value="0"
                                            checked={props?.inputValues?.gaitBelt == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('gaitBelt', e.target.value) }} />
                                        <label for="gaitBelt0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="gaitBelt1" value="1"
                                            checked={props?.inputValues?.gaitBelt == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('gaitBelt', e.target.value) }} />
                                        <label for="gaitBelt1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="gaitBelt2" value="2"
                                            checked={props?.inputValues?.gaitBelt == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('gaitBelt', e.target.value) }} />
                                        <label for="gaitBelt2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="gaitBelt3" value="3"
                                            checked={props?.inputValues?.gaitBelt == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('gaitBelt', e.target.value) }} />
                                        <label for="gaitBelt3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="gaitBelt4" value="4"
                                            checked={props?.inputValues?.gaitBelt == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('gaitBelt', e.target.value) }} />
                                        <label for="gaitBelt4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="gaitBelt5" value="5"
                                            checked={props?.inputValues?.gaitBelt == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('gaitBelt', e.target.value) }} />
                                        <label for="gaitBelt5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Weight bearing</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="weightBearing0" value="0"
                                            checked={props?.inputValues?.weightBearing == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('weightBearing', e.target.value) }} />
                                        <label for="weightBearing0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="weightBearing1" value="1"
                                            checked={props?.inputValues?.weightBearing == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('weightBearing', e.target.value) }} />
                                        <label for="weightBearing1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="weightBearing2" value="2"
                                            checked={props?.inputValues?.weightBearing == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('weightBearing', e.target.value) }} />
                                        <label for="weightBearing2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="weightBearing3" value="3"
                                            checked={props?.inputValues?.weightBearing == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('weightBearing', e.target.value) }} />
                                        <label for="weightBearing3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="weightBearing4" value="4"
                                            checked={props?.inputValues?.weightBearing == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('weightBearing', e.target.value) }} />
                                        <label for="weightBearing4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="weightBearing5" value="5"
                                            checked={props?.inputValues?.weightBearing == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('weightBearing', e.target.value) }} />
                                        <label for="weightBearing5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">2 person transfer</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="twoPersonTransfer0" value="0"
                                            checked={props?.inputValues?.twoPersonTransfer == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('twoPersonTransfer', e.target.value) }} />
                                        <label for="twoPersonTransfer0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="twoPersonTransfer1" value="1"
                                            checked={props?.inputValues?.twoPersonTransfer == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('twoPersonTransfer', e.target.value) }} />
                                        <label for="twoPersonTransfer1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="twoPersonTransfer2" value="2"
                                            checked={props?.inputValues?.twoPersonTransfer == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('twoPersonTransfer', e.target.value) }} />
                                        <label for="twoPersonTransfer2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="twoPersonTransfer3" value="3"
                                            checked={props?.inputValues?.twoPersonTransfer == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('twoPersonTransfer', e.target.value) }} />
                                        <label for="twoPersonTransfer3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="twoPersonTransfer4" value="4"
                                            checked={props?.inputValues?.twoPersonTransfer == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('twoPersonTransfer', e.target.value) }} />
                                        <label for="twoPersonTransfer4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="twoPersonTransfer5" value="5"
                                            checked={props?.inputValues?.twoPersonTransfer == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('twoPersonTransfer', e.target.value) }} />
                                        <label for="twoPersonTransfer5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Slide board</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="slideBoard0" value="0"
                                            checked={props?.inputValues?.slideBoard == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('slideBoard', e.target.value) }} />
                                        <label for="slideBoard0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="slideBoard1" value="1"
                                            checked={props?.inputValues?.slideBoard == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('slideBoard', e.target.value) }} />
                                        <label for="slideBoard1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="slideBoard2" value="2"
                                            checked={props?.inputValues?.slideBoard == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('slideBoard', e.target.value) }} />
                                        <label for="slideBoard2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="slideBoard3" value="3"
                                            checked={props?.inputValues?.slideBoard == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('slideBoard', e.target.value) }} />
                                        <label for="slideBoard3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="slideBoard4" value="4"
                                            checked={props?.inputValues?.slideBoard == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('slideBoard', e.target.value) }} />
                                        <label for="slideBoard4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="slideBoard5" value="5"
                                            checked={props?.inputValues?.slideBoard == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('slideBoard', e.target.value) }} />
                                        <label for="slideBoard5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Wheelchair</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="wheelchair0" value="0"
                                            checked={props?.inputValues?.wheelchair == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('wheelchair', e.target.value) }} />
                                        <label for="wheelchair0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="wheelchair1" value="1"
                                            checked={props?.inputValues?.wheelchair == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('wheelchair', e.target.value) }} />
                                        <label for="wheelchair1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="wheelchair2" value="2"
                                            checked={props?.inputValues?.wheelchair == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('wheelchair', e.target.value) }} />
                                        <label for="wheelchair2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="wheelchair3" value="3"
                                            checked={props?.inputValues?.wheelchair == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('wheelchair', e.target.value) }} />
                                        <label for="wheelchair3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="wheelchair4" value="4"
                                            checked={props?.inputValues?.wheelchair == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('wheelchair', e.target.value) }} />
                                        <label for="wheelchair4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="wheelchair5" value="5"
                                            checked={props?.inputValues?.wheelchair == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('wheelchair', e.target.value) }} />
                                        <label for="wheelchair5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr style={{ background: "#D9D9D9" }} >
                                    <td className="border-bottom border-dark p-1 document-table-td" colspan="8"><b>Sara Lift – Sit to Sand, Maxi-Lift - Hoyer
                                    </b></td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Hoyer</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="hoyerLift0" value="0"
                                            checked={props?.inputValues?.hoyerLift == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('hoyerLift', e.target.value) }} />
                                        <label for="hoyerLift0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="hoyerLift1" value="1"
                                            checked={props?.inputValues?.hoyerLift == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('hoyerLift', e.target.value) }} />
                                        <label for="hoyerLift1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="hoyerLift2" value="2"
                                            checked={props?.inputValues?.hoyerLift == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('hoyerLift', e.target.value) }} />
                                        <label for="hoyerLift2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="hoyerLift3" value="3"
                                            checked={props?.inputValues?.hoyerLift == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('hoyerLift', e.target.value) }} />
                                        <label for="hoyerLift3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="hoyerLift4" value="4"
                                            checked={props?.inputValues?.hoyerLift == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('hoyerLift', e.target.value) }} />
                                        <label for="hoyerLift4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="hoyerLift5" value="5"
                                            checked={props?.inputValues?.hoyerLift == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('hoyerLift', e.target.value) }} />
                                        <label for="hoyerLift5">&nbsp;5</label>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div className="container mt-3">
                        <table className="table table-bordered border-dark ">
                            <thead className="thead-dark" style={{ background: "#000" }}>
                                <tr>
                                    <th className=" text-light p-1" colspan="1">Positioning/Turning</th>
                                    <th className="text-center text-light p-1" colspan="6">Experience</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" width={400} >Supine</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="supinePositioning0" value="0"
                                            checked={props?.inputValues?.supinePositioning == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('supinePositioning', e.target.value) }} />
                                        <label for="supinePositioning0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="supinePositioning1" value="1"
                                            checked={props?.inputValues?.supinePositioning == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('supinePositioning', e.target.value) }} />
                                        <label for="supinePositioning1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="supinePositioning2" value="2"
                                            checked={props?.inputValues?.supinePositioning == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('supinePositioning', e.target.value) }} />
                                        <label for="supinePositioning2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="supinePositioning3" value="3"
                                            checked={props?.inputValues?.supinePositioning == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('supinePositioning', e.target.value) }} />
                                        <label for="supinePositioning3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="supinePositioning4" value="4"
                                            checked={props?.inputValues?.supinePositioning == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('supinePositioning', e.target.value) }} />
                                        <label for="supinePositioning4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="supinePositioning5" value="5"
                                            checked={props?.inputValues?.supinePositioning == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('supinePositioning', e.target.value) }} />
                                        <label for="supinePositioning5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Side lying</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="sideLying0" value="0"
                                            checked={props?.inputValues?.sideLying == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('sideLying', e.target.value) }} />
                                        <label for="sideLying0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="sideLying1" value="1"
                                            checked={props?.inputValues?.sideLying == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('sideLying', e.target.value) }} />
                                        <label for="sideLying1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="sideLying2" value="2"
                                            checked={props?.inputValues?.sideLying == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('sideLying', e.target.value) }} />
                                        <label for="sideLying2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="sideLying3" value="3"
                                            checked={props?.inputValues?.sideLying == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('sideLying', e.target.value) }} />
                                        <label for="sideLying3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="sideLying4" value="4"
                                            checked={props?.inputValues?.sideLying == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('sideLying', e.target.value) }} />
                                        <label for="sideLying4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="sideLying5" value="5"
                                            checked={props?.inputValues?.sideLying == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('sideLying', e.target.value) }} />
                                        <label for="sideLying5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Use of draw sheet</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="drawSheet0" value="0"
                                            checked={props?.inputValues?.drawSheet == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('drawSheet', e.target.value) }} />
                                        <label for="drawSheet0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="drawSheet1" value="1"
                                            checked={props?.inputValues?.drawSheet == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('drawSheet', e.target.value) }} />
                                        <label for="drawSheet1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="drawSheet2" value="2"
                                            checked={props?.inputValues?.drawSheet == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('drawSheet', e.target.value) }} />
                                        <label for="drawSheet2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="drawSheet3" value="3"
                                            checked={props?.inputValues?.drawSheet == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('drawSheet', e.target.value) }} />
                                        <label for="drawSheet3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="drawSheet4" value="4"
                                            checked={props?.inputValues?.drawSheet == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('drawSheet', e.target.value) }} />
                                        <label for="drawSheet4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="drawSheet5" value="5"
                                            checked={props?.inputValues?.drawSheet == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('drawSheet', e.target.value) }} />
                                        <label for="drawSheet5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Range of motion exercises</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="rangeOfMotionExercises0" value="0"
                                            checked={props?.inputValues?.rangeOfMotionExercises == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('rangeOfMotionExercises', e.target.value) }} />
                                        <label for="rangeOfMotionExercises0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="rangeOfMotionExercises1" value="1"
                                            checked={props?.inputValues?.rangeOfMotionExercises == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('rangeOfMotionExercises', e.target.value) }} />
                                        <label for="rangeOfMotionExercises1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="rangeOfMotionExercises2" value="2"
                                            checked={props?.inputValues?.rangeOfMotionExercises == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('rangeOfMotionExercises', e.target.value) }} />
                                        <label for="rangeOfMotionExercises2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="rangeOfMotionExercises3" value="3"
                                            checked={props?.inputValues?.rangeOfMotionExercises == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('rangeOfMotionExercises', e.target.value) }} />
                                        <label for="rangeOfMotionExercises3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="rangeOfMotionExercises4" value="4"
                                            checked={props?.inputValues?.rangeOfMotionExercises == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('rangeOfMotionExercises', e.target.value) }} />
                                        <label for="rangeOfMotionExercises4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="rangeOfMotionExercises5" value="5"
                                            checked={props?.inputValues?.rangeOfMotionExercises == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('rangeOfMotionExercises', e.target.value) }} />
                                        <label for="rangeOfMotionExercises5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">In chair</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="inChair0" value="0"
                                            checked={props?.inputValues?.inChair == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('inChair', e.target.value) }} />
                                        <label for="inChair0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="inChair1" value="1"
                                            checked={props?.inputValues?.inChair == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('inChair', e.target.value) }} />
                                        <label for="inChair1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="inChair2" value="2"
                                            checked={props?.inputValues?.inChair == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('inChair', e.target.value) }} />
                                        <label for="inChair2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="inChair3" value="3"
                                            checked={props?.inputValues?.inChair == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('inChair', e.target.value) }} />
                                        <label for="inChair3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="inChair4" value="4"
                                            checked={props?.inputValues?.inChair == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('inChair', e.target.value) }} />
                                        <label for="inChair4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="inChair5" value="5"
                                            checked={props?.inputValues?.inChair == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('inChair', e.target.value) }} />
                                        <label for="inChair5">&nbsp;5</label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="container mt-3">
                        <table className="table table-bordered border-dark ">
                            <thead className="thead-dark" style={{ background: "#000" }}>
                                <tr>
                                    <th className=" text-light p-1 document-table-td" colspan="1">Take and Record Vital Signs</th>
                                    <th className="text-center text-light p-1" colspan="6">Experience</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" width={400} >Pulse (apical, radial, carotid)</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="pulse0" value="0"
                                            checked={props?.inputValues?.pulse == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('pulse', e.target.value) }} />
                                        <label for="pulse0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="pulse1" value="1"
                                            checked={props?.inputValues?.pulse == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('pulse', e.target.value) }} />
                                        <label for="pulse1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="pulse2" value="2"
                                            checked={props?.inputValues?.pulse == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('pulse', e.target.value) }} />
                                        <label for="pulse2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="pulse3" value="3"
                                            checked={props?.inputValues?.pulse == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('pulse', e.target.value) }} />
                                        <label for="pulse3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="pulse4" value="4"
                                            checked={props?.inputValues?.pulse == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('pulse', e.target.value) }} />
                                        <label for="pulse4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="pulse5" value="5"
                                            checked={props?.inputValues?.pulse == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('pulse', e.target.value) }} />
                                        <label for="pulse5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Temperature – axillary, oral, rectal</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="temperature0" value="0"
                                            checked={props?.inputValues?.temperature == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('temperature', e.target.value) }} />
                                        <label for="temperature0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="temperature1" value="1"
                                            checked={props?.inputValues?.temperature == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('temperature', e.target.value) }} />
                                        <label for="temperature1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="temperature2" value="2"
                                            checked={props?.inputValues?.temperature == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('temperature', e.target.value) }} />
                                        <label for="temperature2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="temperature3" value="3"
                                            checked={props?.inputValues?.temperature == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('temperature', e.target.value) }} />
                                        <label for="temperature3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="temperature4" value="4"
                                            checked={props?.inputValues?.temperature == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('temperature', e.target.value) }} />
                                        <label for="temperature4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="temperature5" value="5"
                                            checked={props?.inputValues?.temperature == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('temperature', e.target.value) }} />
                                        <label for="temperature5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Respirations</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="respirations0" value="0"
                                            checked={props?.inputValues?.respirations == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('respirations', e.target.value) }} />
                                        <label for="respirations0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="respirations1" value="1"
                                            checked={props?.inputValues?.respirations == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('respirations', e.target.value) }} />
                                        <label for="respirations1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="respirations2" value="2"
                                            checked={props?.inputValues?.respirations == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('respirations', e.target.value) }} />
                                        <label for="respirations2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="respirations3" value="3"
                                            checked={props?.inputValues?.respirations == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('respirations', e.target.value) }} />
                                        <label for="respirations3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="respirations4" value="4"
                                            checked={props?.inputValues?.respirations == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('respirations', e.target.value) }} />
                                        <label for="respirations4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="respirations5" value="5"
                                            checked={props?.inputValues?.respirations == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('respirations', e.target.value) }} />
                                        <label for="respirations5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Blood Pressure</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="bloodPressure0" value="0"
                                            checked={props?.inputValues?.bloodPressure == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('bloodPressure', e.target.value) }} />
                                        <label for="bloodPressure0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="bloodPressure1" value="1"
                                            checked={props?.inputValues?.bloodPressure == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('bloodPressure', e.target.value) }} />
                                        <label for="bloodPressure1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="bloodPressure2" value="2"
                                            checked={props?.inputValues?.bloodPressure == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('bloodPressure', e.target.value) }} />
                                        <label for="bloodPressure2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="bloodPressure3" value="3"
                                            checked={props?.inputValues?.bloodPressure == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('bloodPressure', e.target.value) }} />
                                        <label for="bloodPressure3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="bloodPressure4" value="4"
                                            checked={props?.inputValues?.bloodPressure == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('bloodPressure', e.target.value) }} />
                                        <label for="bloodPressure4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="bloodPressure5" value="5"
                                            checked={props?.inputValues?.bloodPressure == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('bloodPressure', e.target.value) }} />
                                        <label for="bloodPressure5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Height and Weight</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="heightWeight0" value="0"
                                            checked={props?.inputValues?.heightWeight == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('heightWeight', e.target.value) }} />
                                        <label for="heightWeight0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="heightWeight1" value="1"
                                            checked={props?.inputValues?.heightWeight == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('heightWeight', e.target.value) }} />
                                        <label for="heightWeight1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="heightWeight2" value="2"
                                            checked={props?.inputValues?.heightWeight == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('heightWeight', e.target.value) }} />
                                        <label for="heightWeight2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="heightWeight3" value="3"
                                            checked={props?.inputValues?.heightWeight == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('heightWeight', e.target.value) }} />
                                        <label for="heightWeight3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="heightWeight4" value="4"
                                            checked={props?.inputValues?.heightWeight == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('heightWeight', e.target.value) }} />
                                        <label for="heightWeight4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="heightWeight5" value="5"
                                            checked={props?.inputValues?.heightWeight == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('heightWeight', e.target.value) }} />
                                        <label for="heightWeight5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Pain Scale</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="painScale0" value="0"
                                            checked={props?.inputValues?.painScale == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('painScale', e.target.value) }} />
                                        <label for="painScale0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="painScale1" value="1"
                                            checked={props?.inputValues?.painScale == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('painScale', e.target.value) }} />
                                        <label for="painScale1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="painScale2" value="2"
                                            checked={props?.inputValues?.painScale == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('painScale', e.target.value) }} />
                                        <label for="painScale2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="painScale3" value="3"
                                            checked={props?.inputValues?.painScale == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('painScale', e.target.value) }} />
                                        <label for="painScale3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="painScale4" value="4"
                                            checked={props?.inputValues?.painScale == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('painScale', e.target.value) }} />
                                        <label for="painScale4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="painScale5" value="5"
                                            checked={props?.inputValues?.painScale == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('painScale', e.target.value) }} />
                                        <label for="painScale5">&nbsp;5</label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="container mt-3">
                        <table className="table table-bordered border-dark ">
                            <thead className="thead-dark" style={{ background: "#000" }}>
                                <tr>
                                    <th className=" text-light p-1" colspan="1">Communication</th>
                                    <th className="text-center text-light p-1" colspan="6">Experience</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" width={400} >Verbal/nonverbal with impaired patient</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="communicationImpaired0" value="0"
                                            checked={props?.inputValues?.communicationImpaired == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('communicationImpaired', e.target.value) }} />
                                        <label for="communicationImpaired0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="communicationImpaired1" value="1"
                                            checked={props?.inputValues?.communicationImpaired == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('communicationImpaired', e.target.value) }} />
                                        <label for="communicationImpaired1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="communicationImpaired2" value="2"
                                            checked={props?.inputValues?.communicationImpaired == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('communicationImpaired', e.target.value) }} />
                                        <label for="communicationImpaired2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="communicationImpaired3" value="3"
                                            checked={props?.inputValues?.communicationImpaired == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('communicationImpaired', e.target.value) }} />
                                        <label for="communicationImpaired3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="communicationImpaired4" value="4"
                                            checked={props?.inputValues?.communicationImpaired == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('communicationImpaired', e.target.value) }} />
                                        <label for="communicationImpaired4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="communicationImpaired5" value="5"
                                            checked={props?.inputValues?.communicationImpaired == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('communicationImpaired', e.target.value) }} />
                                        <label for="communicationImpaired5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Awareness of HCAHPS</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="hcahpsAwareness0" value="0"
                                            checked={props?.inputValues?.hcahpsAwareness == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('hcahpsAwareness', e.target.value) }} />
                                        <label for="hcahpsAwareness0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="hcahpsAwareness1" value="1"
                                            checked={props?.inputValues?.hcahpsAwareness == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('hcahpsAwareness', e.target.value) }} />
                                        <label for="hcahpsAwareness1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="hcahpsAwareness2" value="2"
                                            checked={props?.inputValues?.hcahpsAwareness == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('hcahpsAwareness', e.target.value) }} />
                                        <label for="hcahpsAwareness2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="hcahpsAwareness3" value="3"
                                            checked={props?.inputValues?.hcahpsAwareness == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('hcahpsAwareness', e.target.value) }} />
                                        <label for="hcahpsAwareness3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="hcahpsAwareness4" value="4"
                                            checked={props?.inputValues?.hcahpsAwareness == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('hcahpsAwareness', e.target.value) }} />
                                        <label for="hcahpsAwareness4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="hcahpsAwareness5" value="5"
                                            checked={props?.inputValues?.hcahpsAwareness == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('hcahpsAwareness', e.target.value) }} />
                                        <label for="hcahpsAwareness5">&nbsp;5</label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="container mt-3">
                        <table className="table table-bordered border-dark ">
                            <thead className="thead-dark" style={{ background: "#000" }}>
                                <tr>
                                    <th className=" text-light p-1" colspan="1">Specimen Collections </th>
                                    <th className="text-center text-light p-1" colspan="6">Experience</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" width={400} >Urine</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="urine0" value="0"
                                            checked={props?.inputValues?.urine == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('urine', e.target.value) }} />
                                        <label for="urine0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="urine1" value="1"
                                            checked={props?.inputValues?.urine == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('urine', e.target.value) }} />
                                        <label for="urine1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="urine2" value="2"
                                            checked={props?.inputValues?.urine == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('urine', e.target.value) }} />
                                        <label for="urine2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="urine3" value="3"
                                            checked={props?.inputValues?.urine == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('urine', e.target.value) }} />
                                        <label for="urine3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="urine4" value="4"
                                            checked={props?.inputValues?.urine == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('urine', e.target.value) }} />
                                        <label for="urine4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="urine5" value="5"
                                            checked={props?.inputValues?.urine == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('urine', e.target.value) }} />
                                        <label for="urine5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Stool</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="stool0" value="0"
                                            checked={props?.inputValues?.stool == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('stool', e.target.value) }} />
                                        <label for="stool0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="stool1" value="1"
                                            checked={props?.inputValues?.stool == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('stool', e.target.value) }} />
                                        <label for="stool1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="stool2" value="2"
                                            checked={props?.inputValues?.stool == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('stool', e.target.value) }} />
                                        <label for="stool2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="stool3" value="3"
                                            checked={props?.inputValues?.stool == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('stool', e.target.value) }} />
                                        <label for="stool3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="stool4" value="4"
                                            checked={props?.inputValues?.stool == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('stool', e.target.value) }} />
                                        <label for="stool4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="stool5" value="5"
                                            checked={props?.inputValues?.stool == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('stool', e.target.value) }} />
                                        <label for="stool5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Wound</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="wound0" value="0"
                                            checked={props?.inputValues?.wound == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('wound', e.target.value) }} />
                                        <label for="wound0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="wound1" value="1"
                                            checked={props?.inputValues?.wound == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('wound', e.target.value) }} />
                                        <label for="wound1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="wound2" value="2"
                                            checked={props?.inputValues?.wound == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('wound', e.target.value) }} />
                                        <label for="wound2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="wound3" value="3"
                                            checked={props?.inputValues?.wound == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('wound', e.target.value) }} />
                                        <label for="wound3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="wound4" value="4"
                                            checked={props?.inputValues?.wound == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('wound', e.target.value) }} />
                                        <label for="wound4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="wound5" value="5"
                                            checked={props?.inputValues?.wound == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('wound', e.target.value) }} />
                                        <label for="wound5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Sputum</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="sputum0" value="0"
                                            checked={props?.inputValues?.sputum == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('sputum', e.target.value) }} />
                                        <label for="sputum0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="sputum1" value="1"
                                            checked={props?.inputValues?.sputum == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('sputum', e.target.value) }} />
                                        <label for="sputum1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="sputum2" value="2"
                                            checked={props?.inputValues?.sputum == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('sputum', e.target.value) }} />
                                        <label for="sputum2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="sputum3" value="3"
                                            checked={props?.inputValues?.sputum == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('sputum', e.target.value) }} />
                                        <label for="sputum3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="sputum4" value="4"
                                            checked={props?.inputValues?.sputum == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('sputum', e.target.value) }} />
                                        <label for="sputum4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="sputum5" value="5"
                                            checked={props?.inputValues?.sputum == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('sputum', e.target.value) }} />
                                        <label for="sputum5">&nbsp;5</label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="container mt-3">
                        <table className="table table-bordered border-dark ">
                            <thead className="thead-dark" style={{ background: "#000" }}>
                                <tr>
                                    <th className="text-light p-1 document-table-td text-break" colspan="1">Observations/Reporting/Documentation</th>
                                    <th className="text-center text-light p-1" colspan="6">Experience</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" width={400} >Change in body functions</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="changeBodyFunctions0" value="0"
                                            checked={props?.inputValues?.changeBodyFunctions == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('changeBodyFunctions', e.target.value) }} />
                                        <label for="changeBodyFunctions0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="changeBodyFunctions1" value="1"
                                            checked={props?.inputValues?.changeBodyFunctions == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('changeBodyFunctions', e.target.value) }} />
                                        <label for="changeBodyFunctions1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="changeBodyFunctions2" value="2"
                                            checked={props?.inputValues?.changeBodyFunctions == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('changeBodyFunctions', e.target.value) }} />
                                        <label for="changeBodyFunctions2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="changeBodyFunctions3" value="3"
                                            checked={props?.inputValues?.changeBodyFunctions == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('changeBodyFunctions', e.target.value) }} />
                                        <label for="changeBodyFunctions3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="changeBodyFunctions4" value="4"
                                            checked={props?.inputValues?.changeBodyFunctions == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('changeBodyFunctions', e.target.value) }} />
                                        <label for="changeBodyFunctions4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="changeBodyFunctions5" value="5"
                                            checked={props?.inputValues?.changeBodyFunctions == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('changeBodyFunctions', e.target.value) }} />
                                        <label for="changeBodyFunctions5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Change in behavior</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="changeBehavior0" value="0"
                                            checked={props?.inputValues?.changeBehavior == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('changeBehavior', e.target.value) }} />
                                        <label for="changeBehavior0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="changeBehavior1" value="1"
                                            checked={props?.inputValues?.changeBehavior == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('changeBehavior', e.target.value) }} />
                                        <label for="changeBehavior1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="changeBehavior2" value="2"
                                            checked={props?.inputValues?.changeBehavior == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('changeBehavior', e.target.value) }} />
                                        <label for="changeBehavior2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="changeBehavior3" value="3"
                                            checked={props?.inputValues?.changeBehavior == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('changeBehavior', e.target.value) }} />
                                        <label for="changeBehavior3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="changeBehavior4" value="4"
                                            checked={props?.inputValues?.changeBehavior == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('changeBehavior', e.target.value) }} />
                                        <label for="changeBehavior4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="changeBehavior5" value="5"
                                            checked={props?.inputValues?.changeBehavior == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('changeBehavior', e.target.value) }} />
                                        <label for="changeBehavior5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Change in routines</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="changeRoutines0" value="0"
                                            checked={props?.inputValues?.changeRoutines == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('changeRoutines', e.target.value) }} />
                                        <label for="changeRoutines0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="changeRoutines1" value="1"
                                            checked={props?.inputValues?.changeRoutines == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('changeRoutines', e.target.value) }} />
                                        <label for="changeRoutines1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="changeRoutines2" value="2"
                                            checked={props?.inputValues?.changeRoutines == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('changeRoutines', e.target.value) }} />
                                        <label for="changeRoutines2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="changeRoutines3" value="3"
                                            checked={props?.inputValues?.changeRoutines == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('changeRoutines', e.target.value) }} />
                                        <label for="changeRoutines3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="changeRoutines4" value="4"
                                            checked={props?.inputValues?.changeRoutines == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('changeRoutines', e.target.value) }} />
                                        <label for="changeRoutines4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="changeRoutines5" value="5"
                                            checked={props?.inputValues?.changeRoutines == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('changeRoutines', e.target.value) }} />
                                        <label for="changeRoutines5">&nbsp;5</label>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div className="container mt-3">
                        <table className="table table-bordered border-dark ">
                            <thead className="thead-dark" style={{ background: "#000" }}>
                                <tr>
                                    <th className=" text-light p-1" colspan="1">Safety Devices </th>
                                    <th className="text-center text-light p-1" colspan="6">Experience</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" width={400} >Vest restraint</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="vestRestraint0" value="0"
                                            checked={props?.inputValues?.vestRestraint == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('vestRestraint', e.target.value) }} />
                                        <label for="vestRestraint0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="vestRestraint1" value="1"
                                            checked={props?.inputValues?.vestRestraint == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('vestRestraint', e.target.value) }} />
                                        <label for="vestRestraint1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="vestRestraint2" value="2"
                                            checked={props?.inputValues?.vestRestraint == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('vestRestraint', e.target.value) }} />
                                        <label for="vestRestraint2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="vestRestraint3" value="3"
                                            checked={props?.inputValues?.vestRestraint == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('vestRestraint', e.target.value) }} />
                                        <label for="vestRestraint3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="vestRestraint4" value="4"
                                            checked={props?.inputValues?.vestRestraint == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('vestRestraint', e.target.value) }} />
                                        <label for="vestRestraint4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="vestRestraint5" value="5"
                                            checked={props?.inputValues?.vestRestraint == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('vestRestraint', e.target.value) }} />
                                        <label for="vestRestraint5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Soft ankle</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="softAnkle0" value="0"
                                            checked={props?.inputValues?.softAnkle == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('softAnkle', e.target.value) }} />
                                        <label for="softAnkle0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="softAnkle1" value="1"
                                            checked={props?.inputValues?.softAnkle == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('softAnkle', e.target.value) }} />
                                        <label for="softAnkle1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="softAnkle2" value="2"
                                            checked={props?.inputValues?.softAnkle == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('softAnkle', e.target.value) }} />
                                        <label for="softAnkle2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="softAnkle3" value="3"
                                            checked={props?.inputValues?.softAnkle == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('softAnkle', e.target.value) }} />
                                        <label for="softAnkle3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="softAnkle4" value="4"
                                            checked={props?.inputValues?.softAnkle == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('softAnkle', e.target.value) }} />
                                        <label for="softAnkle4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="softAnkle5" value="5"
                                            checked={props?.inputValues?.softAnkle == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('softAnkle', e.target.value) }} />
                                        <label for="softAnkle5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Wrist restraints</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="wristRestraints0" value="0"
                                            checked={props?.inputValues?.wristRestraints == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('wristRestraints', e.target.value) }} />
                                        <label for="wristRestraints0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="wristRestraints1" value="1"
                                            checked={props?.inputValues?.wristRestraints == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('wristRestraints', e.target.value) }} />
                                        <label for="wristRestraints1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="wristRestraints2" value="2"
                                            checked={props?.inputValues?.wristRestraints == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('wristRestraints', e.target.value) }} />
                                        <label for="wristRestraints2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="wristRestraints3" value="3"
                                            checked={props?.inputValues?.wristRestraints == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('wristRestraints', e.target.value) }} />
                                        <label for="wristRestraints3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="wristRestraints4" value="4"
                                            checked={props?.inputValues?.wristRestraints == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('wristRestraints', e.target.value) }} />
                                        <label for="wristRestraints4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="wristRestraints5" value="5"
                                            checked={props?.inputValues?.wristRestraints == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('wristRestraints', e.target.value) }} />
                                        <label for="wristRestraints5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Padded side rails</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="paddedSideRails0" value="0"
                                            checked={props?.inputValues?.paddedSideRails == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('paddedSideRails', e.target.value) }} />
                                        <label for="paddedSideRails0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="paddedSideRails1" value="1"
                                            checked={props?.inputValues?.paddedSideRails == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('paddedSideRails', e.target.value) }} />
                                        <label for="paddedSideRails1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="paddedSideRails2" value="2"
                                            checked={props?.inputValues?.paddedSideRails == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('paddedSideRails', e.target.value) }} />
                                        <label for="paddedSideRails2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="paddedSideRails3" value="3"
                                            checked={props?.inputValues?.paddedSideRails == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('paddedSideRails', e.target.value) }} />
                                        <label for="paddedSideRails3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="paddedSideRails4" value="4"
                                            checked={props?.inputValues?.paddedSideRails == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('paddedSideRails', e.target.value) }} />
                                        <label for="paddedSideRails4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="paddedSideRails5" value="5"
                                            checked={props?.inputValues?.paddedSideRails == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('paddedSideRails', e.target.value) }} />
                                        <label for="paddedSideRails5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Leather: 4 Point Restraints</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="leather4PointRestraints0" value="0"
                                            checked={props?.inputValues?.leather4PointRestraints == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('leather4PointRestraints', e.target.value) }} />
                                        <label for="leather4PointRestraints0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="leather4PointRestraints1" value="1"
                                            checked={props?.inputValues?.leather4PointRestraints == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('leather4PointRestraints', e.target.value) }} />
                                        <label for="leather4PointRestraints1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="leather4PointRestraints2" value="2"
                                            checked={props?.inputValues?.leather4PointRestraints == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('leather4PointRestraints', e.target.value) }} />
                                        <label for="leather4PointRestraints2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="leather4PointRestraints3" value="3"
                                            checked={props?.inputValues?.leather4PointRestraints == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('leather4PointRestraints', e.target.value) }} />
                                        <label for="leather4PointRestraints3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="leather4PointRestraints4" value="4"
                                            checked={props?.inputValues?.leather4PointRestraints == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('leather4PointRestraints', e.target.value) }} />
                                        <label for="leather4PointRestraints4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="leather4PointRestraints5" value="5"
                                            checked={props?.inputValues?.leather4PointRestraints == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('leather4PointRestraints', e.target.value) }} />
                                        <label for="leather4PointRestraints5">&nbsp;5</label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="container mt-3">
                        <table className="table table-bordered border-dark ">
                            <thead className="thead-dark" style={{ background: "#000" }}>
                                <tr>
                                    <th className=" text-light p-1" colspan="1">Medication Reminders</th>
                                    <th className="text-center text-light p-1" colspan="6">Experience</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" width={400} >Verbal prompts</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="verbalPrompts0" value="0"
                                            checked={props?.inputValues?.verbalPrompts == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('verbalPrompts', e.target.value) }} />
                                        <label for="verbalPrompts0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="verbalPrompts1" value="1"
                                            checked={props?.inputValues?.verbalPrompts == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('verbalPrompts', e.target.value) }} />
                                        <label for="verbalPrompts1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="verbalPrompts2" value="2"
                                            checked={props?.inputValues?.verbalPrompts == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('verbalPrompts', e.target.value) }} />
                                        <label for="verbalPrompts2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="verbalPrompts3" value="3"
                                            checked={props?.inputValues?.verbalPrompts == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('verbalPrompts', e.target.value) }} />
                                        <label for="verbalPrompts3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="verbalPrompts4" value="4"
                                            checked={props?.inputValues?.verbalPrompts == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('verbalPrompts', e.target.value) }} />
                                        <label for="verbalPrompts4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="verbalPrompts5" value="5"
                                            checked={props?.inputValues?.verbalPrompts == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('verbalPrompts', e.target.value) }} />
                                        <label for="verbalPrompts5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Inquire if medication taken</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="medicationTaken0" value="0"
                                            checked={props?.inputValues?.medicationTaken == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('medicationTaken', e.target.value) }} />
                                        <label for="medicationTaken0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="medicationTaken1" value="1"
                                            checked={props?.inputValues?.medicationTaken == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('medicationTaken', e.target.value) }} />
                                        <label for="medicationTaken1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="medicationTaken2" value="2"
                                            checked={props?.inputValues?.medicationTaken == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('medicationTaken', e.target.value) }} />
                                        <label for="medicationTaken2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="medicationTaken3" value="3"
                                            checked={props?.inputValues?.medicationTaken == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('medicationTaken', e.target.value) }} />
                                        <label for="medicationTaken3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="medicationTaken4" value="4"
                                            checked={props?.inputValues?.medicationTaken == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('medicationTaken', e.target.value) }} />
                                        <label for="medicationTaken4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="medicationTaken5" value="5"
                                            checked={props?.inputValues?.medicationTaken == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('medicationTaken', e.target.value) }} />
                                        <label for="medicationTaken5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Two patients identifiers</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="twoIdentifiers0" value="0"
                                            checked={props?.inputValues?.twoIdentifiers == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('twoIdentifiers', e.target.value) }} />
                                        <label for="twoIdentifiers0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="twoIdentifiers1" value="1"
                                            checked={props?.inputValues?.twoIdentifiers == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('twoIdentifiers', e.target.value) }} />
                                        <label for="twoIdentifiers1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="twoIdentifiers2" value="2"
                                            checked={props?.inputValues?.twoIdentifiers == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('twoIdentifiers', e.target.value) }} />
                                        <label for="twoIdentifiers2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="twoIdentifiers3" value="3"
                                            checked={props?.inputValues?.twoIdentifiers == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('twoIdentifiers', e.target.value) }} />
                                        <label for="twoIdentifiers3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="twoIdentifiers4" value="4"
                                            checked={props?.inputValues?.twoIdentifiers == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('twoIdentifiers', e.target.value) }} />
                                        <label for="twoIdentifiers4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="twoIdentifiers5" value="5"
                                            checked={props?.inputValues?.twoIdentifiers == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('twoIdentifiers', e.target.value) }} />
                                        <label for="twoIdentifiers5">&nbsp;5</label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="container mt-3">
                        <table className="table table-bordered border-dark ">
                            <thead className="thead-dark" style={{ background: "#000" }}>
                                <tr>
                                    <th className=" text-light p-1" colspan="1">Oxygen Therapy</th>
                                    <th className="text-center text-light p-1" colspan="6">Experience</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" width={400} >Flow rate</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="flowRate0" value="0"
                                            checked={props?.inputValues?.flowRate == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('flowRate', e.target.value) }} />
                                        <label for="flowRate0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="flowRate1" value="1"
                                            checked={props?.inputValues?.flowRate == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('flowRate', e.target.value) }} />
                                        <label for="flowRate1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="flowRate2" value="2"
                                            checked={props?.inputValues?.flowRate == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('flowRate', e.target.value) }} />
                                        <label for="flowRate2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="flowRate3" value="3"
                                            checked={props?.inputValues?.flowRate == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('flowRate', e.target.value) }} />
                                        <label for="flowRate3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="flowRate4" value="4"
                                            checked={props?.inputValues?.flowRate == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('flowRate', e.target.value) }} />
                                        <label for="flowRate4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="flowRate5" value="5"
                                            checked={props?.inputValues?.flowRate == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('flowRate', e.target.value) }} />
                                        <label for="flowRate5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Water to humidifier</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="waterToHumidifier0" value="0"
                                            checked={props?.inputValues?.waterToHumidifier == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('waterToHumidifier', e.target.value) }} />
                                        <label for="waterToHumidifier0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="waterToHumidifier1" value="1"
                                            checked={props?.inputValues?.waterToHumidifier == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('waterToHumidifier', e.target.value) }} />
                                        <label for="waterToHumidifier1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="waterToHumidifier2" value="2"
                                            checked={props?.inputValues?.waterToHumidifier == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('waterToHumidifier', e.target.value) }} />
                                        <label for="waterToHumidifier2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="waterToHumidifier3" value="3"
                                            checked={props?.inputValues?.waterToHumidifier == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('waterToHumidifier', e.target.value) }} />
                                        <label for="waterToHumidifier3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="waterToHumidifier4" value="4"
                                            checked={props?.inputValues?.waterToHumidifier == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('waterToHumidifier', e.target.value) }} />
                                        <label for="waterToHumidifier4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="waterToHumidifier5" value="5"
                                            checked={props?.inputValues?.waterToHumidifier == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('waterToHumidifier', e.target.value) }} />
                                        <label for="waterToHumidifier5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Nasal Cannula</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="nasalCannula0" value="0"
                                            checked={props?.inputValues?.nasalCannula == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('nasalCannula', e.target.value) }} />
                                        <label for="nasalCannula0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="nasalCannula1" value="1"
                                            checked={props?.inputValues?.nasalCannula == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('nasalCannula', e.target.value) }} />
                                        <label for="nasalCannula1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="nasalCannula2" value="2"
                                            checked={props?.inputValues?.nasalCannula == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('nasalCannula', e.target.value) }} />
                                        <label for="nasalCannula2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="nasalCannula3" value="3"
                                            checked={props?.inputValues?.nasalCannula == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('nasalCannula', e.target.value) }} />
                                        <label for="nasalCannula3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="nasalCannula4" value="4"
                                            checked={props?.inputValues?.nasalCannula == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('nasalCannula', e.target.value) }} />
                                        <label for="nasalCannula4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="nasalCannula5" value="5"
                                            checked={props?.inputValues?.nasalCannula == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('nasalCannula', e.target.value) }} />
                                        <label for="nasalCannula5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Simple Mask/Non-rebreather</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="simpleMaskNonRebreather0" value="0"
                                            checked={props?.inputValues?.simpleMaskNonRebreather == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('simpleMaskNonRebreather', e.target.value) }} />
                                        <label for="simpleMaskNonRebreather0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="simpleMaskNonRebreather1" value="1"
                                            checked={props?.inputValues?.simpleMaskNonRebreather == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('simpleMaskNonRebreather', e.target.value) }} />
                                        <label for="simpleMaskNonRebreather1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="simpleMaskNonRebreather2" value="2"
                                            checked={props?.inputValues?.simpleMaskNonRebreather == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('simpleMaskNonRebreather', e.target.value) }} />
                                        <label for="simpleMaskNonRebreather2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="simpleMaskNonRebreather3" value="3"
                                            checked={props?.inputValues?.simpleMaskNonRebreather == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('simpleMaskNonRebreather', e.target.value) }} />
                                        <label for="simpleMaskNonRebreather3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="simpleMaskNonRebreather4" value="4"
                                            checked={props?.inputValues?.simpleMaskNonRebreather == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('simpleMaskNonRebreather', e.target.value) }} />
                                        <label for="simpleMaskNonRebreather4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="simpleMaskNonRebreather5" value="5"
                                            checked={props?.inputValues?.simpleMaskNonRebreather == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('simpleMaskNonRebreather', e.target.value) }} />
                                        <label for="simpleMaskNonRebreather5">&nbsp;5</label>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div className="container mt-3">
                        <table className="table table-bordered border-dark ">
                            <thead className="thead-dark" style={{ background: "#000" }}>
                                <tr>
                                    <th className=" text-light p-1" colspan="1">Management</th>
                                    <th className="text-center text-light p-1" colspan="6">Experience</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" width={400} >Care Plans</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="carePlans0" value="0"
                                            checked={props?.inputValues?.carePlans == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('carePlans', e.target.value) }} />
                                        <label for="carePlans0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="carePlans1" value="1"
                                            checked={props?.inputValues?.carePlans == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('carePlans', e.target.value) }} />
                                        <label for="carePlans1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="carePlans2" value="2"
                                            checked={props?.inputValues?.carePlans == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('carePlans', e.target.value) }} />
                                        <label for="carePlans2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="carePlans3" value="3"
                                            checked={props?.inputValues?.carePlans == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('carePlans', e.target.value) }} />
                                        <label for="carePlans3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="carePlans4" value="4"
                                            checked={props?.inputValues?.carePlans == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('carePlans', e.target.value) }} />
                                        <label for="carePlans4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="carePlans5" value="5"
                                            checked={props?.inputValues?.carePlans == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('carePlans', e.target.value) }} />
                                        <label for="carePlans5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Central station entry/retrieval</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="centralStationEntryRetrieval0" value="0"
                                            checked={props?.inputValues?.centralStationEntryRetrieval == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('centralStationEntryRetrieval', e.target.value) }} />
                                        <label for="centralStationEntryRetrieval0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="centralStationEntryRetrieval1" value="1"
                                            checked={props?.inputValues?.centralStationEntryRetrieval == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('centralStationEntryRetrieval', e.target.value) }} />
                                        <label for="centralStationEntryRetrieval1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="centralStationEntryRetrieval2" value="2"
                                            checked={props?.inputValues?.centralStationEntryRetrieval == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('centralStationEntryRetrieval', e.target.value) }} />
                                        <label for="centralStationEntryRetrieval2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="centralStationEntryRetrieval3" value="3"
                                            checked={props?.inputValues?.centralStationEntryRetrieval == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('centralStationEntryRetrieval', e.target.value) }} />
                                        <label for="centralStationEntryRetrieval3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="centralStationEntryRetrieval4" value="4"
                                            checked={props?.inputValues?.centralStationEntryRetrieval == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('centralStationEntryRetrieval', e.target.value) }} />
                                        <label for="centralStationEntryRetrieval4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="centralStationEntryRetrieval5" value="5"
                                            checked={props?.inputValues?.centralStationEntryRetrieval == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('centralStationEntryRetrieval', e.target.value) }} />
                                        <label for="centralStationEntryRetrieval5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Delegation</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="delegation0" value="0"
                                            checked={props?.inputValues?.delegation == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('delegation', e.target.value) }} />
                                        <label for="delegation0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="delegation1" value="1"
                                            checked={props?.inputValues?.delegation == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('delegation', e.target.value) }} />
                                        <label for="delegation1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="delegation2" value="2"
                                            checked={props?.inputValues?.delegation == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('delegation', e.target.value) }} />
                                        <label for="delegation2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="delegation3" value="3"
                                            checked={props?.inputValues?.delegation == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('delegation', e.target.value) }} />
                                        <label for="delegation3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="delegation4" value="4"
                                            checked={props?.inputValues?.delegation == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('delegation', e.target.value) }} />
                                        <label for="delegation4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="delegation5" value="5"
                                            checked={props?.inputValues?.delegation == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('delegation', e.target.value) }} />
                                        <label for="delegation5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Medical Information System</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="medicalInfoSystem0" value="0"
                                            checked={props?.inputValues?.medicalInfoSystem == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('medicalInfoSystem', e.target.value) }} />
                                        <label for="medicalInfoSystem0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="medicalInfoSystem1" value="1"
                                            checked={props?.inputValues?.medicalInfoSystem == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('medicalInfoSystem', e.target.value) }} />
                                        <label for="medicalInfoSystem1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="medicalInfoSystem2" value="2"
                                            checked={props?.inputValues?.medicalInfoSystem == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('medicalInfoSystem', e.target.value) }} />
                                        <label for="medicalInfoSystem2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="medicalInfoSystem3" value="3"
                                            checked={props?.inputValues?.medicalInfoSystem == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('medicalInfoSystem', e.target.value) }} />
                                        <label for="medicalInfoSystem3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="medicalInfoSystem4" value="4"
                                            checked={props?.inputValues?.medicalInfoSystem == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('medicalInfoSystem', e.target.value) }} />
                                        <label for="medicalInfoSystem4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="medicalInfoSystem5" value="5"
                                            checked={props?.inputValues?.medicalInfoSystem == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('medicalInfoSystem', e.target.value) }} />
                                        <label for="medicalInfoSystem5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr style={{ background: "#D9D9D9" }} >
                                    <td className="border-bottom border-dark p-1 document-table-td" colspan="8"><b>Documentation</b></td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Flow Sheets</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="flowSheets0" value="0"
                                            checked={props?.inputValues?.flowSheets == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('flowSheets', e.target.value) }} />
                                        <label for="flowSheets0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="flowSheets1" value="1"
                                            checked={props?.inputValues?.flowSheets == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('flowSheets', e.target.value) }} />
                                        <label for="flowSheets1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="flowSheets2" value="2"
                                            checked={props?.inputValues?.flowSheets == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('flowSheets', e.target.value) }} />
                                        <label for="flowSheets2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="flowSheets3" value="3"
                                            checked={props?.inputValues?.flowSheets == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('flowSheets', e.target.value) }} />
                                        <label for="flowSheets3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="flowSheets4" value="4"
                                            checked={props?.inputValues?.flowSheets == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('flowSheets', e.target.value) }} />
                                        <label for="flowSheets4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="flowSheets5" value="5"
                                            checked={props?.inputValues?.flowSheets == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('flowSheets', e.target.value) }} />
                                        <label for="flowSheets5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Narrative Notes</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="narrativeNotes0" value="0"
                                            checked={props?.inputValues?.narrativeNotes == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('narrativeNotes', e.target.value) }} />
                                        <label for="narrativeNotes0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="narrativeNotes1" value="1"
                                            checked={props?.inputValues?.narrativeNotes == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('narrativeNotes', e.target.value) }} />
                                        <label for="narrativeNotes1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="narrativeNotes2" value="2"
                                            checked={props?.inputValues?.narrativeNotes == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('narrativeNotes', e.target.value) }} />
                                        <label for="narrativeNotes2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="narrativeNotes3" value="3"
                                            checked={props?.inputValues?.narrativeNotes == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('narrativeNotes', e.target.value) }} />
                                        <label for="narrativeNotes3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="narrativeNotes4" value="4"
                                            checked={props?.inputValues?.narrativeNotes == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('narrativeNotes', e.target.value) }} />
                                        <label for="narrativeNotes4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="narrativeNotes5" value="5"
                                            checked={props?.inputValues?.narrativeNotes == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('narrativeNotes', e.target.value) }} />
                                        <label for="narrativeNotes5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">SOAP</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="soap0" value="0"
                                            checked={props?.inputValues?.soap == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('soap', e.target.value) }} />
                                        <label for="soap0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="soap1" value="1"
                                            checked={props?.inputValues?.soap == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('soap', e.target.value) }} />
                                        <label for="soap1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="soap2" value="2"
                                            checked={props?.inputValues?.soap == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('soap', e.target.value) }} />
                                        <label for="soap2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="soap3" value="3"
                                            checked={props?.inputValues?.soap == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('soap', e.target.value) }} />
                                        <label for="soap3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="soap4" value="4"
                                            checked={props?.inputValues?.soap == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('soap', e.target.value) }} />
                                        <label for="soap4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="soap5" value="5"
                                            checked={props?.inputValues?.soap == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('soap', e.target.value) }} />
                                        <label for="soap5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">SBAR</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="sbar0" value="0"
                                            checked={props?.inputValues?.sbar == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('sbar', e.target.value) }} />
                                        <label for="sbar0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="sbar1" value="1"
                                            checked={props?.inputValues?.sbar == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('sbar', e.target.value) }} />
                                        <label for="sbar1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="sbar2" value="2"
                                            checked={props?.inputValues?.sbar == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('sbar', e.target.value) }} />
                                        <label for="sbar2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="sbar3" value="3"
                                            checked={props?.inputValues?.sbar == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('sbar', e.target.value) }} />
                                        <label for="sbar3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="sbar4" value="4"
                                            checked={props?.inputValues?.sbar == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('sbar', e.target.value) }} />
                                        <label for="sbar4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="sbar5" value="5"
                                            checked={props?.inputValues?.sbar == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('sbar', e.target.value) }} />
                                        <label for="sbar5">&nbsp;5</label>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div className="container mt-3">
                        <table className="table table-bordered border-dark ">
                            <thead className="thead-dark" style={{ background: "#000" }}>
                                <tr>
                                    <th className=" text-light p-1 document-table-td" colspan="1">Age Specific Competencies</th>
                                    <th className="text-center text-light p-1" colspan="6">Experience</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td" width={400} >Newborn/Neonate (Birth - 30 days)</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="newbornNeonate0" value="0"
                                            checked={props?.inputValues?.newbornNeonate == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('newbornNeonate', e.target.value) }} />
                                        <label for="newbornNeonate0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="newbornNeonate1" value="1"
                                            checked={props?.inputValues?.newbornNeonate == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('newbornNeonate', e.target.value) }} />
                                        <label for="newbornNeonate1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="newbornNeonate2" value="2"
                                            checked={props?.inputValues?.newbornNeonate == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('newbornNeonate', e.target.value) }} />
                                        <label for="newbornNeonate2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="newbornNeonate3" value="3"
                                            checked={props?.inputValues?.newbornNeonate == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('newbornNeonate', e.target.value) }} />
                                        <label for="newbornNeonate3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="newbornNeonate4" value="4"
                                            checked={props?.inputValues?.newbornNeonate == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('newbornNeonate', e.target.value) }} />
                                        <label for="newbornNeonate4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="newbornNeonate5" value="5"
                                            checked={props?.inputValues?.newbornNeonate == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('newbornNeonate', e.target.value) }} />
                                        <label for="newbornNeonate5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Infant (31 days – 1 year)</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="infant31days1year0" value="0"
                                            checked={props?.inputValues?.infant31days1year == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('infant31days1year', e.target.value) }} />
                                        <label for="infant31days1year0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="infant31days1year1" value="1"
                                            checked={props?.inputValues?.infant31days1year == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('infant31days1year', e.target.value) }} />
                                        <label for="infant31days1year1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="infant31days1year2" value="2"
                                            checked={props?.inputValues?.infant31days1year == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('infant31days1year', e.target.value) }} />
                                        <label for="infant31days1year2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="infant31days1year3" value="3"
                                            checked={props?.inputValues?.infant31days1year == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('infant31days1year', e.target.value) }} />
                                        <label for="infant31days1year3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="infant31days1year4" value="4"
                                            checked={props?.inputValues?.infant31days1year == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('infant31days1year', e.target.value) }} />
                                        <label for="infant31days1year4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="infant31days1year5" value="5"
                                            checked={props?.inputValues?.infant31days1year == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('infant31days1year', e.target.value) }} />
                                        <label for="infant31days1year5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Toddler (ages 2-3 years)</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="toddler2to3years0" value="0"
                                            checked={props?.inputValues?.toddler2to3years == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('toddler2to3years', e.target.value) }} />
                                        <label for="toddler2to3years0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="toddler2to3years1" value="1"
                                            checked={props?.inputValues?.toddler2to3years == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('toddler2to3years', e.target.value) }} />
                                        <label for="toddler2to3years1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="toddler2to3years2" value="2"
                                            checked={props?.inputValues?.toddler2to3years == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('toddler2to3years', e.target.value) }} />
                                        <label for="toddler2to3years2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="toddler2to3years3" value="3"
                                            checked={props?.inputValues?.toddler2to3years == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('toddler2to3years', e.target.value) }} />
                                        <label for="toddler2to3years3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="toddler2to3years4" value="4"
                                            checked={props?.inputValues?.toddler2to3years == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('toddler2to3years', e.target.value) }} />
                                        <label for="toddler2to3years4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="toddler2to3years5" value="5"
                                            checked={props?.inputValues?.toddler2to3years == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('toddler2to3years', e.target.value) }} />
                                        <label for="toddler2to3years5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Preschool (ages 4-5 years)</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="preschool4to5years0" value="0"
                                            checked={props?.inputValues?.preschool4to5years == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('preschool4to5years', e.target.value) }} />
                                        <label for="preschool4to5years0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="preschool4to5years1" value="1"
                                            checked={props?.inputValues?.preschool4to5years == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('preschool4to5years', e.target.value) }} />
                                        <label for="preschool4to5years1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="preschool4to5years2" value="2"
                                            checked={props?.inputValues?.preschool4to5years == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('preschool4to5years', e.target.value) }} />
                                        <label for="preschool4to5years2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="preschool4to5years3" value="3"
                                            checked={props?.inputValues?.preschool4to5years == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('preschool4to5years', e.target.value) }} />
                                        <label for="preschool4to5years3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="preschool4to5years4" value="4"
                                            checked={props?.inputValues?.preschool4to5years == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('preschool4to5years', e.target.value) }} />
                                        <label for="preschool4to5years4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="preschool4to5years5" value="5"
                                            checked={props?.inputValues?.preschool4to5years == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('preschool4to5years', e.target.value) }} />
                                        <label for="preschool4to5years5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">School Age (ages 6-12 years)</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="schoolAge6to12years0" value="0"
                                            checked={props?.inputValues?.schoolAge6to12years == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('schoolAge6to12years', e.target.value) }} />
                                        <label for="schoolAge6to12years0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="schoolAge6to12years1" value="1"
                                            checked={props?.inputValues?.schoolAge6to12years == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('schoolAge6to12years', e.target.value) }} />
                                        <label for="schoolAge6to12years1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="schoolAge6to12years2" value="2"
                                            checked={props?.inputValues?.schoolAge6to12years == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('schoolAge6to12years', e.target.value) }} />
                                        <label for="schoolAge6to12years2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="schoolAge6to12years3" value="3"
                                            checked={props?.inputValues?.schoolAge6to12years == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('schoolAge6to12years', e.target.value) }} />
                                        <label for="schoolAge6to12years3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="schoolAge6to12years4" value="4"
                                            checked={props?.inputValues?.schoolAge6to12years == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('schoolAge6to12years', e.target.value) }} />
                                        <label for="schoolAge6to12years4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="schoolAge6to12years5" value="5"
                                            checked={props?.inputValues?.schoolAge6to12years == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('schoolAge6to12years', e.target.value) }} />
                                        <label for="schoolAge6to12years5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Adolescent (ages 13-21 years)</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="adolescent13to21years0" value="0"
                                            checked={props?.inputValues?.adolescent13to21years == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('adolescent13to21years', e.target.value) }} />
                                        <label for="adolescent13to21years0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="adolescent13to21years1" value="1"
                                            checked={props?.inputValues?.adolescent13to21years == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('adolescent13to21years', e.target.value) }} />
                                        <label for="adolescent13to21years1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="adolescent13to21years2" value="2"
                                            checked={props?.inputValues?.adolescent13to21years == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('adolescent13to21years', e.target.value) }} />
                                        <label for="adolescent13to21years2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="adolescent13to21years3" value="3"
                                            checked={props?.inputValues?.adolescent13to21years == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('adolescent13to21years', e.target.value) }} />
                                        <label for="adolescent13to21years3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="adolescent13to21years4" value="4"
                                            checked={props?.inputValues?.adolescent13to21years == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('adolescent13to21years', e.target.value) }} />
                                        <label for="adolescent13to21years4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="adolescent13to21years5" value="5"
                                            checked={props?.inputValues?.adolescent13to21years == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('adolescent13to21years', e.target.value) }} />
                                        <label for="adolescent13to21years5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Young Adult (ages 22-39 years)</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="youngAdult22to39years0" value="0"
                                            checked={props?.inputValues?.youngAdult22to39years == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('youngAdult22to39years', e.target.value) }} />
                                        <label for="youngAdult22to39years0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="youngAdult22to39years1" value="1"
                                            checked={props?.inputValues?.youngAdult22to39years == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('youngAdult22to39years', e.target.value) }} />
                                        <label for="youngAdult22to39years1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="youngAdult22to39years2" value="2"
                                            checked={props?.inputValues?.youngAdult22to39years == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('youngAdult22to39years', e.target.value) }} />
                                        <label for="youngAdult22to39years2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="youngAdult22to39years3" value="3"
                                            checked={props?.inputValues?.youngAdult22to39years == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('youngAdult22to39years', e.target.value) }} />
                                        <label for="youngAdult22to39years3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="youngAdult22to39years4" value="4"
                                            checked={props?.inputValues?.youngAdult22to39years == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('youngAdult22to39years', e.target.value) }} />
                                        <label for="youngAdult22to39years4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="youngAdult22to39years5" value="5"
                                            checked={props?.inputValues?.youngAdult22to39years == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('youngAdult22to39years', e.target.value) }} />
                                        <label for="youngAdult22to39years5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Older Adult (ages 40-64 years)</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="olderAdult40to64years0" value="0"
                                            checked={props?.inputValues?.olderAdult40to64years == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('olderAdult40to64years', e.target.value) }} />
                                        <label for="olderAdult40to64years0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="olderAdult40to64years1" value="1"
                                            checked={props?.inputValues?.olderAdult40to64years == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('olderAdult40to64years', e.target.value) }} />
                                        <label for="olderAdult40to64years1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="olderAdult40to64years2" value="2"
                                            checked={props?.inputValues?.olderAdult40to64years == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('olderAdult40to64years', e.target.value) }} />
                                        <label for="olderAdult40to64years2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="olderAdult40to64years3" value="3"
                                            checked={props?.inputValues?.olderAdult40to64years == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('olderAdult40to64years', e.target.value) }} />
                                        <label for="olderAdult40to64years3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="olderAdult40to64years4" value="4"
                                            checked={props?.inputValues?.olderAdult40to64years == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('olderAdult40to64years', e.target.value) }} />
                                        <label for="olderAdult40to64years4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="olderAdult40to64years5" value="5"
                                            checked={props?.inputValues?.olderAdult40to64years == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('olderAdult40to64years', e.target.value) }} />
                                        <label for="olderAdult40to64years5">&nbsp;5</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">Elderly (ages 80+ years)</td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="elderly80plusyears0" value="0"
                                            checked={props?.inputValues?.elderly80plusyears == 0} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('elderly80plusyears', e.target.value) }} />
                                        <label for="elderly80plusyears0">&nbsp;0</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="elderly80plusyears1" value="1"
                                            checked={props?.inputValues?.elderly80plusyears == 1} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('elderly80plusyears', e.target.value) }} />
                                        <label for="elderly80plusyears1">&nbsp;1</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="elderly80plusyears2" value="2"
                                            checked={props?.inputValues?.elderly80plusyears == 2} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('elderly80plusyears', e.target.value) }} />
                                        <label for="elderly80plusyears2">&nbsp;2</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="elderly80plusyears3" value="3"
                                            checked={props?.inputValues?.elderly80plusyears == 3} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('elderly80plusyears', e.target.value) }} />
                                        <label for="elderly80plusyears3">&nbsp;3</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="elderly80plusyears4" value="4"
                                            checked={props?.inputValues?.elderly80plusyears == 4} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('elderly80plusyears', e.target.value) }} />
                                        <label for="elderly80plusyears4">&nbsp;4</label>
                                    </td>
                                    <td className="border-bottom border-dark p-1 document-table-td">
                                        <input className="pointer" type="checkbox" id="elderly80plusyears5" value="5"
                                            checked={props?.inputValues?.elderly80plusyears == 5} // Use 'checked' attribute
                                            onChange={(e) => { props?.handleInputChange('elderly80plusyears', e.target.value) }} />
                                        <label for="elderly80plusyears5">&nbsp;5</label>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div className="container mt-3">
                        <table className="table table-bordered border-dark ">
                            <thead className="thead-dark" style={{ background: "#000" }}>
                                <tr>
                                    <th className=" text-light p-1" colspan="1">Please list any additional skills:</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">1.<input placeholder="  " style={{ width: "90%", border: 0 }} type="text" minLength={2} maxLength={200} name="skil_one" value={props.inputValues.skillOne} onChange={(e) => props.handleInputChange('skillOne', e.target.value)} /> </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">2.<input placeholder="  " style={{ width: "90%", border: 0 }} type="text" minLength={2} maxLength={200} name="skil_one" value={props.inputValues.skillTwo} onChange={(e) => props.handleInputChange('skillTwo', e.target.value)} /></td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">3.<input placeholder="  " style={{ width: "90%", border: 0 }} type="text" minLength={2} maxLength={200} name="skil_one" value={props.inputValues.skillThree} onChange={(e) => props.handleInputChange('skillThree', e.target.value)} /></td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">4.<input placeholder="  " style={{ width: "90%", border: 0 }} type="text" minLength={2} maxLength={200} name="skil_one" value={props.inputValues.skillFour} onChange={(e) => props.handleInputChange('skillFour', e.target.value)} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="container mt-3">
                        <table className="table table-bordered border-dark ">
                            <thead className="thead-dark" style={{ background: "#000" }}>
                                <tr>
                                    <th className=" text-light p-1" colspan="1">Additional training:</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">1.<input placeholder="  " style={{ width: "90%", border: 0 }} type="text" minLength={2} maxLength={200} name="training_one" value={props.inputValues.trainingOne} onChange={(e) => props.handleInputChange('trainingOne', e.target.value)} /></td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">2.<input placeholder="  " style={{ width: "90%", border: 0 }} type="text" minLength={2} maxLength={200} name="training_two" value={props.inputValues.trainingTwo} onChange={(e) => props.handleInputChange('trainingTwo', e.target.value)} /></td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">3.<input placeholder="  " style={{ width: "90%", border: 0 }} type="text" minLength={2} maxLength={200} name="training_three" value={props.inputValues.trainingThree} onChange={(e) => props.handleInputChange('trainingThree', e.target.value)} /></td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">4.<input placeholder="  " style={{ width: "90%", border: 0 }} type="text" minLength={2} maxLength={200} name="training_four" value={props.inputValues.trainingFour} onChange={(e) => props.handleInputChange('trainingFour', e.target.value)} /></td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div className="container mt-3">
                        <table className="table table-bordered border-dark ">
                            <thead className="thead-dark" style={{ background: "#000" }}>
                                <tr>
                                    <th className=" text-light p-1" colspan="1">Additional equipment:</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">1.<input placeholder="  " style={{ width: "90%", border: 0 }} type="text" minLength={2} maxLength={200} name="skil_one" value={props.inputValues.equipmentOne} onChange={(e) => props.handleInputChange('equipmentOne', e.target.value)} /></td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">2.<input placeholder="  " style={{ width: "90%", border: 0 }} type="text" minLength={2} maxLength={200} name="skil_one" value={props.inputValues.equipmentTwo} onChange={(e) => props.handleInputChange('equipmentTwo', e.target.value)} /></td>
                                </tr>
                                <tr>
                                    <td className="border-bottom border-dark p-1 document-table-td">3.<input placeholder="  " style={{ width: "90%", border: 0 }} type="text" minLength={2} maxLength={200} name="skil_one" value={props.inputValues.equipmentThree} onChange={(e) => props.handleInputChange('equipmentThree', e.target.value)} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="container  mt-3 mb-3">
                        <div className="p-3 container border border-1 border-dark ">
                            <p className="text-center"><b>The information on this and all preceding pages is true and correct.</b></p>

                            <div className="row  mt-">
                                <div className="col-6 text-center">
                                    {props?.signature ? <img src={props?.signature} width={180} height={50} alt="Signature here" /> :
                                        <div class="badge badge-primary text-wrap pointer" onClick={props?.onOpenModal}>
                                            Click Here To Sign
                                        </div>
                                    }
                                </div>

                                <div className="col-6 text-center">
                                    <input type='text' value={moment(new Date()).format("MM-DD-YYYY HH:mm:ss")} disabled />
                                </div>
                            </div>

                            <div className="row">

                                <div className="col-6 text-center border-top">
                                    (Sign Name)
                                </div>
                                <div className="col-6 text-center border-top ">
                                    (Date)
                                </div>
                            </div>
                        </div>
                    </div>
                    <p>Clinical Skills Validation – Certified Nursing Assistant – Staffers LLC Revised 7/10/2019</p>
                </div>
            </div>


        </div>

    );
};

