import React from "react";
import Header from '@components/PDFHeader';
import { MultiDatePicker } from "@elements/index"

export const InfoSheet = (props) => {
  return (
    <div id="form-content" className="p-2 container">
      <Header title="BACKGROUND SCREENING FORM" />
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="border-box mb-3">
                <div className="">
                  <table className="table-screening input-back">
                    {/* <tbody className="set-line-height"> */}
                    <tbody >
                      <tr>
                        <td colSpan={2}>
                          <div  >
                            <label htmlFor="">First Name<small className="text-danger">*</small>:</label>
                            <input type="text" required className="table-screening-input" name="first name" value={props.inputValues.first_name} onChange={(e) => props.handleInputChange('first_name', e.target.value)} />
                          </div>
                        </td>
                        <td colSpan={2}>
                          <div  >
                            <label htmlFor="">Middle Name:</label>
                            <input type="text" className="table-screening-input" value={props.inputValues.middle_name} name="middle name" onChange={(e) => props.handleInputChange('middle_name', e.target.value)} />
                          </div>
                        </td>
                        <td colSpan={2}>
                          <div  >
                            <label htmlFor="">Last Name<small className="text-danger">*</small>:</label>
                            <input type="text" required className="table-screening-input" value={props.inputValues.last_name} name="last name" onChange={(e) => props.handleInputChange('last_name', e.target.value)} />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={3}>
                          <div>
                            <label htmlFor="dob">Date of Birth (mm/dd/yyyy)</label> 
                            <MultiDatePicker   name="dob"  inputClass={"date-picker-height"}  required={false} multiple={false} value={props.inputValues.dob} onChange={(e) => props.handleInputChange('dob', e)} />
                           </div>
                        </td>
                        <td colSpan={3}>
                          <div>
                            <label htmlFor="">Social Security Number:</label>
                            <input type="text" placeholder="AAA-GG-SSSS" className="table-screening-input" value={props.inputValues.ssn} onChange={(e) => props.handleInputChange('ssn', e.target.value)} />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={3}>
                          <div  >
                            <label htmlFor="">Phone Number</label>
                            <input type="text" className="table-screening-input" value={props.inputValues.phone} onChange={(e) => props.handleInputChange('phone', e.target.value)} />
                          </div>
                        </td>
                        <td colSpan={3}>
                          <div  >
                            <label htmlFor="">Email Address</label>
                            <input type="email" className="table-screening-input" value={props.inputValues.email} onChange={(e) => props.handleInputChange('email', e.target.value)} />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={4}>
                          <div  >
                            <label htmlFor="">Street Address</label>
                            <input type="text" className="table-screening-input" value={props.inputValues.street} onChange={(e) => props.handleInputChange('street', e.target.value)} />
                          </div>
                        </td>
                        <td colSpan={2}>
                          <div  >
                            <label htmlFor="">Country</label>
                            <input type="text" className="table-screening-input" value={props.inputValues.country} onChange={(e) => props.handleInputChange('country', e.target.value)} />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <div  >
                            <label htmlFor="">City</label>
                            <input type="text" className="table-screening-input" value={props.inputValues.city} onChange={(e) => props.handleInputChange('city', e.target.value)} />
                          </div>
                        </td>
                        <td colSpan={2}>
                          <div  >
                            <label htmlFor="">State</label>
                            <input type="text" className="table-screening-input" value={props.inputValues.state} onChange={(e) => props.handleInputChange('state', e.target.value)} />
                          </div>
                        </td>
                        <td colSpan={2}>
                          <div  >
                            <label htmlFor="">Zip</label>
                            <input type="number" placeholder="" className="table-screening-input" value={props.inputValues.zip} onChange={(e) => props.handleInputChange('zip', e.target.value)} />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={6}>
                          <ul className="list-sheet">
                            <li>
                              <h4>RACE</h4>
                            </li>
                            <li>
                              <p>Asian or Pacific Islander  <input
                                type="radio"
                                name="race"
                                value="aisa"
                                className="radio-btn-size"
                                checked={props?.inputValues?.race === "aisa"} // Use 'checked' attribute
                                onChange={(e) => { props?.handleInputChange('race', e.target.value) }}
                              /></p>


                              <p>Native American or Alaskan Native    <input
                                type="radio"
                                name="race"
                                value="Native"
                                className="radio-btn-size"
                                checked={props?.inputValues?.race === "Native"} // Use 'checked' attribute
                                onChange={(e) => { props?.handleInputChange('race', e.target.value) }}
                              /></p>

                            </li>
                            <li>
                              <p>Black  <input
                                type="radio"
                                name="race"
                                value="Black"
                                className="radio-btn-size"
                                checked={props?.inputValues?.race === "Black"} // Use 'checked' attribute
                                onChange={(e) => { props?.handleInputChange('race', e.target.value) }}
                              /></p>

                              <p>White  <input
                                type="radio"
                                name="race"
                                value="white"
                                className="radio-btn-size"
                                checked={props?.inputValues?.race === "white"} // Use 'checked' attribute
                                onChange={(e) => { props?.handleInputChange('race', e.target.value) }}
                              /></p>
                            </li>
                            <li>
                              <p>Hispanic <input
                                type="radio"
                                name="race"
                                value="Hispanic"
                                className="radio-btn-size"
                                checked={props?.inputValues?.race === "Hispanic"} // Use 'checked' attribute
                                onChange={(e) => { props?.handleInputChange('race', e.target.value) }}
                              /></p>
                              <p>Unknown <input
                                type="radio"
                                name="race"
                                value="Unknown"
                                className="radio-btn-size"
                                checked={props?.inputValues?.race === "Unknown"} // Use 'checked' attribute
                                onChange={(e) => { props?.handleInputChange('race', e.target.value) }}
                              /></p>
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={3}>
                          <ul className="list-sheet">
                            <li>
                              <h4>GENDER</h4>
                            </li>
                            <li>
                              <p>Male  <input
                                type="radio"
                                name="gender"
                                value="male"
                                className="radio-btn-size"
                                checked={props?.inputValues?.gender === "male"} // Use 'checked' attribute
                                onChange={(e) => { props?.handleInputChange('gender', e.target.value) }}
                              /></p>
                              <p>Female <input
                                type="radio"
                                name="gender"
                                value="female"
                                className="radio-btn-size"
                                checked={props?.inputValues?.gender === "female"} // Use 'checked' attribute
                                onChange={(e) => { props?.handleInputChange('gender', e.target.value) }}
                              /></p>
                            </li>
                            <li>
                              <p>Other <input
                                type="radio"
                                name="gender"
                                value="other"
                                className="radio-btn-size"
                                checked={props?.inputValues?.gender === "other"} // Use 'checked' attribute
                                onChange={(e) => { props?.handleInputChange('gender', e.target.value) }}
                              /></p>
                              <p>Unknown
                                <input
                                  type="radio"
                                  name="gender"
                                  value="unknown"
                                  className="radio-btn-size"
                                  checked={props?.inputValues?.gender === "unknown"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('gender', e.target.value) }}
                                />
                              </p>
                            </li>
                          </ul>
                        </td>
                        <td colSpan={3}>
                          <div  >
                            <input type="text" className="table-screening-input" value={props.inputValues.genderExp} onChange={(e) => props.handleInputChange('genderExp', e.target.value)} />

                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={6}>
                          <ul className="list-sheet">
                            <li>
                              <h4>EYE COLOR</h4>
                            </li>
                            <li>
                              <p>Black   <input
                                type="radio"
                                name="eyeColor"
                                value="black"
                                className="radio-btn-size"
                                checked={props?.inputValues?.eyeColor === "black"} // Use 'checked' attribute
                                onChange={(e) => { props?.handleInputChange('eyeColor', e.target.value) }}
                              /></p>
                              <p>Blue
                                <input
                                  type="radio"
                                  name="eyeColor"
                                  value="Blue"
                                  className="radio-btn-size"
                                  checked={props?.inputValues?.eyeColor === "Blue"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('eyeColor', e.target.value) }}
                                />
                              </p>
                            </li>
                            <li>
                              <p>Brown
                                <input
                                  type="radio"
                                  name="eyeColor"
                                  value="Brown"
                                  className="radio-btn-size"
                                  checked={props?.inputValues?.eyeColor === "Brown"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('eyeColor', e.target.value) }}
                                />
                              </p>
                              <p>Green
                                <input
                                  type="radio"
                                  name="eyeColor"
                                  value="Green"
                                  className="radio-btn-size"
                                  checked={props?.inputValues?.eyeColor === "Green"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('eyeColor', e.target.value) }}
                                />
                              </p>
                            </li>
                            <li>
                              <p>Hazel
                                <input
                                  type="radio"
                                  name="eyeColor"
                                  value="Hazel"
                                  className="radio-btn-size"
                                  checked={props?.inputValues?.eyeColor === "Hazel"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('eyeColor', e.target.value) }}
                                />
                              </p>
                              <p>Maroon
                                <input
                                  type="radio"
                                  name="eyeColor"
                                  value="Maroon"
                                  className="radio-btn-size"
                                  checked={props?.inputValues?.eyeColor === "Maroon"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('eyeColor', e.target.value) }}
                                />
                              </p>
                            </li>
                            <li>
                              <p>Multicolored
                                <input
                                  type="radio"
                                  name="eyeColor"
                                  value="Multicolored"
                                  className="radio-btn-size"
                                  checked={props?.inputValues?.eyeColor === "Multicolored"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('eyeColor', e.target.value) }}
                                />
                              </p>
                              <p>Pink   <input
                                type="radio"
                                name="eyeColor"
                                value="Pink"
                                className="radio-btn-size"
                                checked={props?.inputValues?.eyeColor === "Pink"} // Use 'checked' attribute
                                onChange={(e) => { props?.handleInputChange('eyeColor', e.target.value) }}
                              /></p>

                            </li>
                            <li>
                              <p>Unknown
                                <input
                                  type="radio"
                                  name="eyeColor"
                                  value="Unknown"
                                  className="radio-btn-size"
                                  checked={props?.inputValues?.eyeColor === "Unknown"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('eyeColor', e.target.value) }}
                                />
                              </p>
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={6}>
                          <ul className="list-sheet">
                            <li>
                              <h4>HAIR COLOR</h4>
                            </li>
                            <li>
                              <p>Bald   <input
                                type="radio"
                                name="hairColor"
                                value="Bald"
                                className="radio-btn-size"
                                checked={props?.inputValues?.hairColor === "Bald"} // Use 'checked' attribute
                                onChange={(e) => { props?.handleInputChange('hairColor', e.target.value) }}
                              />
                              </p>
                              <p>Brown
                                <input
                                  type="radio"
                                  name="hairColor"
                                  value="Brown"
                                  className="radio-btn-size"
                                  checked={props?.inputValues?.hairColor === "Brown"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('hairColor', e.target.value) }}
                                />
                              </p>
                              <p>Black
                                <input
                                  type="radio"
                                  name="hairColor"
                                  value="Black"
                                  className="radio-btn-size"
                                  checked={props?.inputValues?.hairColor === "Black"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('hairColor', e.target.value) }}
                                />
                              </p>
                            </li>
                            <li>
                              <p>Gray
                                <input
                                  type="radio"
                                  name="hairColor"
                                  value="Gray"
                                  className="radio-btn-size"
                                  checked={props?.inputValues?.hairColor === "Gray"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('hairColor', e.target.value) }}
                                />
                              </p>
                              <p>Green
                                <input
                                  type="radio"
                                  name="hairColor"
                                  value="Green"
                                  className="radio-btn-size"
                                  checked={props?.inputValues?.hairColor === "Green"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('hairColor', e.target.value) }}
                                />
                              </p>
                              <p>Orange
                                <input
                                  type="radio"
                                  name="hairColor"
                                  value="Orange"
                                  className="radio-btn-size"
                                  checked={props?.inputValues?.hairColor === "Orange"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('hairColor', e.target.value) }}
                                />
                              </p>
                            </li>
                            <li>
                              <p>Pink
                                <input
                                  type="radio"
                                  name="hairColor"
                                  value="Pink"
                                  className="radio-btn-size"
                                  checked={props?.inputValues?.hairColor === "Pink"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('hairColor', e.target.value) }}
                                />
                              </p>
                              <p>Purple
                                <input
                                  type="radio"
                                  name="hairColor"
                                  value="Purple"
                                  className="radio-btn-size"
                                  checked={props?.inputValues?.hairColor === "Purple"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('hairColor', e.target.value) }}
                                />
                              </p>
                              <p>Unknown
                                <input
                                  type="radio"
                                  name="hairColor"
                                  value="Unknown"
                                  className="radio-btn-size"
                                  checked={props?.inputValues?.hairColor === "Unknown"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('hairColor', e.target.value) }}
                                />
                              </p>
                            </li>
                            <li>
                              <p>Sandy
                                <input
                                  type="radio"
                                  name="hairColor"
                                  value="Sandy"
                                  className="radio-btn-size"
                                  checked={props?.inputValues?.hairColor === "Sandy"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('hairColor', e.target.value) }}
                                />
                              </p>
                              <p>Red/Auburn
                                <input
                                  type="radio"
                                  name="hairColor"
                                  value="Auburn"
                                  className="radio-btn-size"
                                  checked={props?.inputValues?.hairColor === "Auburn"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('hairColor', e.target.value) }}
                                />
                              </p>
                              <p>Blonde/Strawberry
                                <input
                                  type="radio"
                                  name="hairColor"
                                  value="Strawberry"
                                  className="radio-btn-size"
                                  checked={props?.inputValues?.hairColor === "Strawberry"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('hairColor', e.target.value) }}
                                />
                              </p>
                            </li>
                            <li>
                              <p>White
                                <input
                                  type="radio"
                                  name="hairColor"
                                  value="White"
                                  className="radio-btn-size"
                                  checked={props?.inputValues?.hairColor === "White"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('hairColor', e.target.value) }}
                                />
                              </p>
                              <p>Blue
                                <input
                                  type="radio"
                                  name="hairColor"
                                  value="Blue"
                                  className="radio-btn-size"
                                  checked={props?.inputValues?.hairColor === "Blue"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('hairColor', e.target.value) }}
                                />
                              </p>
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={3}>
                          <div>
                            <label htmlFor="">Height</label>
                            <div className="d-flex">
                              <input type="text" placeholder="Feet" className="table-screening-input" value={props.inputValues.Height_Feet} onChange={(e) => props.handleInputChange('Height_Feet', e.target.value)} />
                              <input type="text" placeholder="Inches" className="table-screening-input" value={props.inputValues.Height_Inches} onChange={(e) => props.handleInputChange('Height_Inches', e.target.value)} />
                            </div>
                          </div>
                        </td>
                        <td colSpan={3}>
                          <div  >
                            <label htmlFor="">Weight</label>
                            <input type="text" placeholder="Pounds" className="table-screening-input" value={props.inputValues.Weight} onChange={(e) => props.handleInputChange('Weight', e.target.value)} />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={3}>
                          <div  >
                            <label htmlFor="">U.S. Citizen</label>
                            <input type="text" className="table-screening-input" value={props.inputValues.Citizen} onChange={(e) => props.handleInputChange('Citizen', e.target.value)} />
                          </div>
                        </td>
                        <td colSpan={3}>
                          <div  >
                            <label htmlFor="">Place of Birth</label>
                            <input type="text" className="table-screening-input" value={props.inputValues.pob} onChange={(e) => props.handleInputChange('pob', e.target.value)} />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={6}>
                          <div  >
                            <label htmlFor="">Aliases/Prior Names</label>
                            <input type="text" className="table-screening-input" value={props.inputValues.Aliases} onChange={(e) => props.handleInputChange('Aliases', e.target.value)} />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={6}>
                          <div>
                            <p>
                              Prior Addresses (<b>excluding Utah</b>) within the
                              last seven (7) years: (if more space is needed,
                              use backside)
                            </p>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={1}>
                          <div  >
                            <label htmlFor="">City and State</label>
                            <input type="text" className="table-screening-input" value={props.inputValues.PriorCity} onChange={(e) => props.handleInputChange('PriorCity', e.target.value)} />
                          </div>
                        </td>
                        <td colSpan={1}>
                          <div  >
                            <label htmlFor="">Year from</label>
                            <input type="text" className="table-screening-input" value={props.inputValues.Yearfrom} onChange={(e) => props.handleInputChange('Yearfrom', e.target.value)} />
                          </div>
                        </td>
                        <td colSpan={1}>
                          <div  >
                            <label htmlFor="">Year to</label>
                            <input type="number" className="table-screening-input" value={props.inputValues.Yearto} onChange={(e) => props.handleInputChange('Yearto', e.target.value)} />
                          </div>
                        </td>
                        <td colSpan={1}>
                          <div  >
                            <label htmlFor="">City and State</label>
                            <input type="number" className="table-screening-input" value={props.inputValues.PriorCity2} onChange={(e) => props.handleInputChange('PriorCity2', e.target.value)} />
                          </div>
                        </td>
                        <td colSpan={1}>
                          <div  >
                            <label htmlFor="">Year from</label>
                            <input type="number" className="table-screening-input" value={props.inputValues.Yearfrom2} onChange={(e) => props.handleInputChange('Yearfrom2', e.target.value)} />
                          </div>
                        </td>
                        <td colSpan={1}>
                          <div  >
                            <label htmlFor="">Year to</label>
                            <input type="number" className="table-screening-input" value={props.inputValues.Yearto2} onChange={(e) => props.handleInputChange('Yearto2', e.target.value)} />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
