import React from "react";
import Header from '@components/PDFHeader';
import moment from "moment";
import { MultiDatePicker } from "@elements/index"
const FluVaccinationDeclination = (props) => {
    return (
        <div id="form-content">
            <div className="border border-1 border-dark mt-3 p-3" >
                <h3 className="text-center">Declination of Influenza Vaccination</h3>
                <h3 className="text-center">For Health Care Personnel</h3>
                <br />
                <div className="row">
                    <div className="col">
                        <p className="s1">Employee’s Name:  <input placeholder='Full Name' required style={{ borderWidth: "0px 0px 1px 0px" }} value={props.inputValues.full_name} type="text" minLength={2} maxLength={100} name="full name" onChange={(e) => props.handleInputChange('full_name', e.target.value)} />
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <p className="s1">I have been advised that I should receive the influenza vaccine to protect myself and the
                            patients I serve. I have read the Centers for Disease Control and Prevention’s (CDC)
                            Vaccine Information Statement explaining the vaccine and the disease it prevents. I have
                            had the opportunity to discuss the statement and have my questions answered by a
                            healthcare provider. I am aware of the following facts:
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <ul id="l1">
                            <li><p>Influenza is a serious respiratory disease that kills thousands in the United States each year.</p></li>
                            <li><p>Influenza vaccination is recommended for me and all other healthcare personnel to protect this
                                facility’s patients from influenza, its complications, and death.</p></li>
                            <li><p>If I contract influenza, I can shed the virus for 24 hours before influenza symptoms appear. My shedding the virus can spread influenza to patients in this facility.</p></li>
                            <li><p>If I become infected with influenza, I can spread severe illness to others even when my symptoms are mild or non-existent.</p></li>
                            <li><p>I understand that the strains of virus that cause influenza infection change almost every year and, even if they don’t, my immunity declines over time. This is why vaccination against influenza is recommended each year.</p></li>
                            <li><p>I understand that I cannot get influenza from the influenza vaccine.</p></li>
                            <li><p>The consequences of my refusing to be vaccinated could have life-threatening consequences to my health and the health of those with whom I have contact, including all patients in this healthcare facility, coworkers, my family and my community.</p></li>
                            <li><b>Because I have refused vaccination against influenza, I will be required to wear surgical or procedure masks in areas where patients or residents may be present during the influenza season.</b></li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <p className="s1">I acknowledge that I have read this document in its entirety and fully understand it. Despite these facts, I have decided to decline the influenza vaccine by my signature below. I realize that I may re-address this issue at any time and accept vaccination in the future.</p>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <p className="s3">Signature:  {props?.signature ? <img src={props?.signature} width={180} height={50} alt="Signature here" /> : <div class="badge badge-primary text-wrap pointer" onClick={props?.onOpenModal}>
                            Click Here To Sign
                        </div>}</p>
                    </div>
                    <div className="col">
                        <p className="s3">Date:<input type="text" value={moment(new Date()).format("MM-DD-YYYY HH:mm:ss")} disabled /></p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FluVaccinationDeclination;
