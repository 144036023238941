import React, { useEffect, useState } from "react";
import FeedbackSlider from "../components/FeedbackSlider";
import WebsiteLayout from "../components/WebsiteLayout";
import { Formik } from "formik";
import { InputField } from "../../elements";
import Select, { components } from "react-select";
import WebsiteStore from "../../store/WebsiteStore";
import splitTextArray from "../../helpers/splitArray";
import { colourStylesState } from "@utils/customStyle/style";
import { toJS } from "mobx";
import Spinner from "@components/Spinner";
import { observer } from "mobx-react";
import { ReviewValidationSchema } from "../../utils/schemas/allSchema";
import swal from "@sweetalert/with-react";
import ReactGA from "react-ga";
import PageHelmet from "../components/PageHelmet";

var cities = require("@assets/city.json");
var states = require("@assets/states.json");

const Testimonial = observer(() => {
  const [stars, setStars] = useState(0);
  const [pageData, setPageData] = useState(null);

  const [isPageLoading, setIsPageLoading] = useState(false);
  const [section1Data, setSection1Data] = useState([]);
  const [reviewData, setReviewData] = useState([]);

  const handleStarClick = (value) => {
    setStars(value);
  };

  const getHeadlineText = () => {
    switch (stars) {
      case 1:
        return "Bad";
      case 2:
        return "Not Good";
      case 3:
        return "Good";
      case 4:
        return "Very Good";
      case 5:
        return "Excellent";
      default:
        return "Click on a star to rate.";
    }
  };

  useEffect(() => {
    setIsPageLoading(true);

    WebsiteStore.page_id = 14;
    WebsiteStore.getPageDetails();
    ReactGA.pageview(window.location.pathname + window.location.search);
    WebsiteStore.getFeaturedReviews();
  }, []);

  useEffect(() => {
    setIsPageLoading(true);
    if (WebsiteStore.data.featuredReviews?.length > 0) {
      setReviewData(toJS(WebsiteStore.data.featuredReviews));
    }
    setIsPageLoading(false);
  }, [WebsiteStore.data.featuredReviews]);

  useEffect(() => {
    if (WebsiteStore?.data?.pageData !== null) {
      setPageData(toJS(WebsiteStore?.data?.pageData));
    }

    if (WebsiteStore?.data?.pageData?.sections?.length > 0) {
      setSection1Data(
        splitTextArray(
          toJS(WebsiteStore?.data?.pageData?.sections)?.[0]?.content
        )
      );
      setIsPageLoading(false);
    }
  }, [WebsiteStore.data.pageData]);

  const submitReview = (values, resetForm) => {
    ReactGA.event({
      category: "Review Form Submit",
      action: "Clicked on Submit",
      label: "Testimonial Page",
    });

    values.designation = JSON.stringify(values?.designation);

    WebsiteStore.postReview(values, resetForm, callback);
  };

  const callback = (resetForm) => {
    resetForm();
    setStars(0);
    swal("Success!", "Thank You for Your Feedback!");
  };

  return (
    <>
      <PageHelmet
        name={pageData?.page_name || "Testimonials"}
        metaData={pageData?.meta_data}
        ldjson={pageData?.ldjson}
      />
      <WebsiteLayout>
        <div className="contact-section">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-md-12">
                <div className="contact-us-main">
                  <div>
                    <img
                      className="img-fluid contact-hero"
                      src="/assets/img/website/job_recruitement_14.jpg"
                      alt="Contact Us Background"
                    />
                    <h3 className="common-web-head mb-4 text-center">
                      {section1Data?.[0] || `Write a Review`}
                    </h3>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="contact-form">
                    <p>
                      {section1Data?.[1] ||
                        `We would love to hear about your experience with us`}
                      .
                    </p>
                    <p className="headline-text">{getHeadlineText()}</p>
                    <Formik
                      initialValues={{
                        name: "",
                        sequence: 1,
                        stars: 0,
                        email: "",
                        contact: "",
                        designation: "",
                        feedback: "",
                        company_name: "",
                        city: "",
                        state: "",
                      }}
                      validationSchema={ReviewValidationSchema}
                      onSubmit={(values, { resetForm }) => {
                        submitReview(values, resetForm);
                      }}
                      validateOnChange={false}
                    >
                      {({
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        values,
                        errors,
                        touched,
                        setFieldValue,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          {console.log(errors)}
                          <div className="row">
                            <div className="col-md-12 mb-3">
                              <div className="text-center pb-3">
                                {[1, 2, 3, 4, 5].map((star) => (
                                  <span
                                    key={star}
                                    className="star-icon"
                                    onClick={() => {
                                      setFieldValue("stars", star);
                                      handleStarClick(star);
                                    }}
                                  >
                                    {star <= stars ? (
                                      <img
                                        src="/assets/img/website/star-full-icon.svg"
                                        className="img-fluid"
                                        style={{ height: "30px" }}
                                        alt="filled star"
                                      />
                                    ) : (
                                      <img
                                        src="/assets/img/website/star-empty-icon.svg"
                                        className="img-fluid"
                                        style={{ height: "30px" }}
                                        alt="empty star"
                                      />
                                    )}
                                  </span>
                                ))}
                              </div>
                              <div className="text-center">
                                {errors.stars ? (
                                  <div className="error-message">
                                    {errors.stars}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-6 mb-3">
                              <InputField
                                className={"did-floating-input"}
                                classNameLabel={
                                  "did-floating-label did-floating-labe-astrisk"
                                }
                                type="text"
                                name="name"
                                label={"Your Name"}
                                value={values?.name || ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.name ? (
                                <div className="error-message">
                                  {errors.name}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-6 mb-3">
                              <InputField
                                className={"did-floating-input"}
                                classNameLabel={"did-floating-label"}
                                type="email"
                                name={"email"}
                                label={"Email Address"}
                                value={values.email || ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.email ? (
                                <div className="error-message">
                                  {errors.email}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-6 mb-3">
                              <InputField
                                className={"did-floating-input"}
                                classNameLabel={"did-floating-label "}
                                type="text"
                                name="contact"
                                label={"Contact Number"}
                                value={values?.contact}
                                maxLength={12}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required={false}
                              />
                              {errors.contact ? (
                                <div className="error-message">
                                  {errors.contact}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-6 mb-3">
                              <InputField
                                className={"did-floating-input"}
                                classNameLabel={
                                  "did-floating-label did-floating-labe-astrisk"
                                }
                                type="text"
                                name="company_name"
                                label={"Company Name"}
                                value={values?.company_name || ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.company_name ? (
                                <div className="error-message">
                                  {errors.company_name}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-6 mb-3">
                              <Select
                                placeholder={""}
                                label={"State"}
                                styles={colourStylesState}
                                isRequired={true}
                                onChange={(e) => {
                                  setFieldValue("state", e.abbreviation);
                                  setFieldValue("city", "");
                                }}
                                value={
                                  states.filter(({ abbreviation }) =>
                                    values?.state?.includes(abbreviation)
                                  ) || ""
                                }
                                options={states}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.abbreviation}
                                components={{
                                  Control,
                                  DropdownIndicator: () => null,
                                  IndicatorSeparator: () => null,
                                }}
                              />
                              {errors.state ? (
                                <div className="error-message">
                                  {errors.state}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-6 mb-3">
                              <Select
                                onChange={(e) => {
                                  setFieldValue("city", e.label);
                                  setFieldValue("city_data", [
                                    JSON.stringify(e),
                                  ]);
                                }}
                                placeholder={""}
                                isRequired={true}
                                label={"City"}
                                isDisabled={!values?.state}
                                styles={colourStylesState}
                                getOptionLabel={(option) => option.label}
                                getOptionValue={(option) => option.label}
                                value={
                                  cities.filter(
                                    ({ label }) => values?.city === label
                                  ) || ""
                                }
                                options={cities?.filter(
                                  (ct) =>
                                    ct.state ===
                                    states?.find(
                                      (x) => x.abbreviation == values?.state
                                    )?.name
                                )}
                                components={{
                                  Control,
                                  DropdownIndicator: () => null,
                                  IndicatorSeparator: () => null,
                                }}
                              />
                              {errors.city ? (
                                <div className="error-message">
                                  {errors.city}
                                </div>
                              ) : null}
                            </div>

                            <div className="col-md-12 mb-3">
                              <InputField
                                className={"did-floating-input"}
                                classNameLabel={"did-floating-label"}
                                type="text"
                                name="designation"
                                label={"Job Title"}
                                value={values?.designation || ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.designation ? (
                                <div className="error-message">
                                  {errors.designation}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-12 mb-3">
                              <label className="pb-2 label">
                                Message <span style={{ color: "red" }}>* </span>
                              </label>
                              <div className="form-floating">
                                <textarea
                                  className="form-control"
                                  placeholder=""
                                  name={"feedback"}
                                  value={values.feedback || ""}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  style={{ height: 100 }}
                                  required
                                ></textarea>
                              </div>
                            </div>
                          </div>

                          <div className="text-center mt-3">
                            <button
                              className="common-web-btn purple-back p-2"
                              type="submit"
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <FeedbackSlider reviewData={reviewData} />

        <div className="new-extra-space">.</div>
        <Spinner isLoading={WebsiteStore.loading || isPageLoading} />
      </WebsiteLayout>
    </>
  );
});

export default Testimonial;
export const Control = (props) => {
  return (
    <>
      <label
        className={
          props.hasValue || props.isFocused
            ? props?.selectProps?.isRequired
              ? "did-floating-label did-floating-label-top did-floating-labe-astrisk"
              : "did-floating-label did-floating-label-top"
            : props?.selectProps?.isRequired
            ? "did-floating-label did-floating-label did-floating-labe-astrisk"
            : "did-floating-label did-floating-label"
        }
        style={{ zIndex: 100 }}
      >
        {props?.selectProps?.label}
      </label>
      <components.Control {...props} />
    </>
  );
};
