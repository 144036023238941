/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react"
import HeaderPage from "../layout/HeaderPage";
import DrawerMenuPage from "../layout/DrawerMenuPage";
import * as Yup from 'yup';
import { Formik } from "formik";
import './NotificationList.css'
import Spinner from "@components/Spinner";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { InputField } from "@elements/index"
import AdminStore from "@store/AdminStore";
import { useNavigate, useLocation } from 'react-router-dom';
import Select, { components } from 'react-select';
import HomeStore from "@store/HomeStore";
import StaffsStore from "@store/StaffsStore";
import { toJS } from "mobx";
import { Helmet } from "react-helmet";

const optionsGroup = [{ label: "All", value: "0" }, { label: "Employer", value: "1" }, { label: "Caregiver", value: "2" }, { label: "Admin", value: "3" }]
const options = [{ label: 'Yes', value: '1' }, { label: 'No', value: '0' }]
const statusList = [{ "label": "Select All", "value": 'all' }, { "label": "Active", "value": 3 }, { "label": "In-Active", "value": 4 }, { "label": "Pending", "value": 0 }, { "label": "Incomplete Applicant", "value": 1 }, { "label": "Rejected", "value": 2 }, { "label": "Applicant", "value": 5 }, { "label": "Pre-active", "value": 6 }, { "label": "Profile is incomplete", "value": null }]
const colourStyles = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? 'blue' : '#75459b',
        borderRadius: 20,
        fontSize: '12px',
        boxShadow: 'none',
        padding: "2px 10px",
        '&:focus': {
            border: '1px solid blue !important'
        },
        '&:active': {
            border: '1px solid blue !important'
        },
        '&:hover': {
            border: '1px solid blue !important'
        }
    }),
    menu: base => ({
        ...base,
        fontSize: 12,
        zIndex: 9999
    }),
    menuList: (base) => ({
        ...base,
        "::-webkit-scrollbar": {
            width: "9px"
        },
        "::-webkit-scrollbar-track": {
            background: "white"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#8282C8"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        }
    })

}
const colourStylesTitle = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        padding: "2px 10px",
        borderColor: state.isFocused ? 'blue' : '#75459b',
        borderRadius: 20,
        boxShadow: 'none',
        '&:focus': {
            border: '1px solid blue !important'
        },
        '&:active': {
            border: '1px solid blue !important'
        },
        '&:hover': {
            border: '1px solid #8282C8 !important'
        }
    }),
    menu: base => ({
        ...base,
        fontSize: 12,
        position: "fixed",
        zIndex: 9999, /* Adjust the z-index value as needed */
        background: "whitesmoke",
        width: "auto",
        top: "none",
        maxHeight: 300, /* Adjust the height as needed */
        overflowY: "auto"
    }),
    menuList: (base) => ({
        ...base,
        height: "auto",

        "::-webkit-scrollbar": {
            width: "9px"
        },
        "::-webkit-scrollbar-track": {
            background: "white"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#8282C8"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        }
    })
}

const notificationSchema = Yup.object().shape({
    heading: Yup.string().required('Required'),
    // content: Yup.string().required('Required'),
    group_type: Yup.string().required('Required'),
    send_mail: Yup.string().required('Required'),
    send_sms: Yup.string().required('Required'),
});
const NotificationAdd = observer(() => {
    // Create a ref for the Select component

    const navigate = useNavigate();
    const params = useLocation();
    const [degrees, setDegrees] = useState([]);
    const [staffByProfession, setStaffByProfession] = useState([]);
    const [staffByProfessionDummy, setStaffByProfessionDummy] = useState([]);
    const [clientList, setClientList] = useState([]);
    const [adminList, setAdminList] = useState([]);
    const [regions, setRegions] = useState([]);

    useEffect(() => {
        let clients = []
        if (AdminStore?.data?.allClientList?.length > 0) {
            clients = [{ label: 'Select All', value: 'all' }, // Add a "Select All" option
            ...toJS(AdminStore?.data?.allClientList)?.map((option) => ({
                value: option.id,
                label: `${option.first_name} - ${option.last_name} - ${option.organization_name}`,
            }))]
        } else {
            clients = []
        }
        setClientList(clients)
    }, [AdminStore?.data?.allClientList]);

    useEffect(() => {
        AdminStore.getAllClient();
        AdminStore.getAdmin();
        StaffsStore.getRegionsList();
    }, []);

    useEffect(() => {
        let admins = []
        if (AdminStore?.data?.adminList?.length > 0) {
            admins = [{ label: 'Select All', value: 'all' }, // Add a "Select All" option
            ...toJS(AdminStore?.data?.adminList)?.map((option) => ({
                value: option.id,
                label: `${option.first_name} - ${option.last_name} -  ${option.is_super === 1 ? "Super Admin" : "Sub-Admin"}`,
            }))]
        } else {
            admins = []
        }
        setAdminList(admins)
    }, [AdminStore?.data?.adminList]);

    useEffect(() => {
        let staffs = []
        if (AdminStore?.data?.staffListByProfession?.length > 0) {
            staffs = [{ label: 'Select All', value: 'all' }, // Add a "Select All" option
            ...toJS(AdminStore?.data?.staffListByProfession)?.map((option) => ({
                value: option.id,
                label: `${option.first_name} ${option.last_name} - ${option.staffer_id} - ${regions?.find(x => x.ID === option.region_id)?.Name} `,
                region_id: option.region_id,
                status: option.status
            }))]
        } else {
            staffs = []
        }
        setStaffByProfession(staffs)
        setStaffByProfessionDummy(staffs)
    }, [AdminStore?.data?.staffListByProfession]);

    useEffect(() => {
        const degreesData = HomeStore.data.dashboardData?.find(x => x.key_name === 'DEGREES');
        if (degreesData) {
            const parsedDegrees = JSON.parse(toJS(degreesData.value));
            // Add "Select All" option
            const selectAllOption = { Id: 'all', Name: 'Select All' };
            const degreesWithSelectAll = [selectAllOption, ...parsedDegrees];
            setDegrees(degreesWithSelectAll);
        }
    }, [HomeStore.data.dashboardData]);

    useEffect(() => {
        // Add "Select All" option
        const selectAllOption = { ID: 'all', Name: 'Select All' };
        const regionWithSelectAll = [selectAllOption, ...StaffsStore?.data?.regionsList];
        setRegions(regionWithSelectAll)
    }, [StaffsStore?.data?.regionsList]);

    const saveNotification = (values) => {
        let param = {
            admin_ids: values.admin_ids?.map(x => { return x.value }),
            client_ids: values.client_ids?.map(x => { return x.value }),
            staff_ids: values.staff_ids?.map(x => { return x.value }),
            heading: values.heading,
            subject: values.subject,
            content: values.content,
            keywords: values.keywords,
            link: values.link,
            send_mail: values.send_mail,
            send_sms: values.send_sms,
        }

        AdminStore.saveNotification(param, navigationCallBack)
    }

    const navigationCallBack = () => {
        AdminStore.getManageNotification();
        navigate('/admin/manage-notification-list');
    }

    return (
        <React.Fragment>
            {/* <body> */}
            <Helmet>
                <title>Staffers LLC | Send Notification</title>
            </Helmet>
            <div className="container-scroller">
                <HeaderPage />
                <div className="container-fluid page-body-wrapper">
                    <DrawerMenuPage />
                    <div className="main-panel" style={{ right: 0, position: "absolute" }}>
                        <div className="content-wrapper p-3 bg-white ">
                            <h3 className="page-title">
                                <span className="page-title-icon linear text-white me-2">
                                    <i className="fas fa-paper-plane"></i>
                                </span>{params?.state?.item ? 'Edit Notification' : 'Send Notification'}
                            </h3>

                        </div>
                        {/* <!-- partial --> */}
                        <div className=" row justify-content-center mt-4">
                            <div className="col-lg-11">
                                <Formik
                                    initialValues={{
                                        heading: '',
                                        subject: '',
                                        content: '',
                                        send_mail: '0',
                                        send_sms: '0',
                                        group_type: '',
                                        link: '',
                                        keywords: '',
                                        client_ids: [],
                                        staff_ids: [],
                                        admin_ids: [],
                                        profession: [],
                                        region_id: [],
                                        status_id: [],
                                    }}
                                    onSubmit={values => saveNotification(values)}
                                    validationSchema={notificationSchema}
                                    enableReinitialize
                                    validateOnChange={false}
                                >
                                    {
                                        ({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched
                                        }) =>
                                        (
                                            <form onSubmit={handleSubmit} className="wow fadeInUp">

                                                <div className="row g-2">
                                                    <small className="text-danger pb-2">Note: In text notification, the user will receive only the heading and link section.</small>

                                                    <div className="col-sm-6 mb-3">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                            type="text"
                                                            maxLength={100}
                                                            name="heading"
                                                            label={"Heading"}
                                                            value={values.heading}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.heading && touched.heading ? (
                                                            <div className="error-message">{errors.heading}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="col-sm-6 mb-3">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            type="text"
                                                            name="subject"
                                                            label={"Subject"}
                                                            value={values.subject}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row g-2">
                                                    <div className="col-sm-6 mb-3">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            type="text"
                                                            name="link"
                                                            label={"Link"}
                                                            value={values.link}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                    </div>
                                                    {/* <div className="col-sm-6 mb-3">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            type="text"
                                                            name="keywords"
                                                            label={"Keywords"}
                                                            value={values.keywords}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                    </div> */}
                                                    <div className="col-sm-3 mb-3">
                                                        <div className="did-floating-label-content">
                                                            <Select
                                                                onChange={(e) => setFieldValue("send_sms", e.value)}
                                                                placeholder={''}
                                                                label={'Send SMS?*'}
                                                                value={options.filter(({ value }) => values?.send_sms == value) || ''}
                                                                styles={colourStyles}
                                                                options={options}
                                                                components={{ Control }}
                                                                isRequired={true}
                                                            />
                                                        </div>
                                                        {errors.send_sms && touched.send_mail ? (
                                                            <div className="error-message">{errors.send_sms}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="col-sm-3 mb-3">
                                                        <div className="did-floating-label-content">
                                                            <Select
                                                                onChange={(e) => setFieldValue("send_mail", e.value)}
                                                                placeholder={''}
                                                                label={'Send Mail?'}
                                                                value={options.filter(({ value }) => values?.send_mail == value) || ''}
                                                                styles={colourStyles}
                                                                options={options}
                                                                components={{ Control }}
                                                                isRequired={true}
                                                            />
                                                        </div>
                                                        {errors.send_mail && touched.send_mail ? (
                                                            <div className="error-message">{errors.send_mail}</div>
                                                        ) : null}
                                                    </div>
                                                </div>

                                                <div className="row g-2" >
                                                    <div className="col-sm-6 ">
                                                        <Select
                                                            onChange={(e) => setFieldValue("group_type", e.value)}
                                                            placeholder={''}
                                                            label={'Group Type?'}
                                                            value={optionsGroup.filter(({ value }) => values?.group_type == value) || ''}
                                                            styles={colourStyles}
                                                            options={optionsGroup}
                                                            components={{ Control }}
                                                            isRequired={true}
                                                        />
                                                        {errors.group_type && touched.group_type ? (
                                                            <div className="error-message">{errors.group_type}</div>
                                                        ) : null}
                                                    </div>


                                                </div>
                                                {/* FOR CAREGIVER */}
                                                <div className="col-sm-12 mb-3">                                                    {
                                                    (values.group_type === '2' || values.group_type === '0') &&
                                                    <div className="d-flex ">
                                                        <div className="col-sm-3 p-1">
                                                            <Select
                                                                components={{ Control, MultiValue }}
                                                                isMulti
                                                                closeMenuOnSelect={false}
                                                                hideSelectedOptions={false}
                                                                placeholder={'Select Profession'}
                                                                required={true}
                                                                styles={colourStylesTitle}
                                                                isSearchable={true}
                                                                options={degrees}
                                                                onChange={(event) => {
                                                                    if (!event) {
                                                                        setFieldValue("region_id", [])
                                                                        setFieldValue("staff_ids", [])
                                                                        setFieldValue("status_id", [])
                                                                        setStaffByProfession([])
                                                                    }
                                                                    if (event && event.length > 0 && event?.some(item => item.Id === "all")) {
                                                                        // If "Select All" is selected, set all options as selected
                                                                        setFieldValue('profession', degrees?.map((value) => value)?.filter(x => x.Id !== 'all'));
                                                                        AdminStore.getStaffByProfession({ "all": 2, "profession_ids": degrees?.filter(x => x.Id !== 'all')?.map(x => { return x.Id }) })
                                                                    } else {
                                                                        // Otherwise, update the selected options as usual
                                                                        setFieldValue('profession', event?.map((value) => value));
                                                                        if (event && event.length > 0) {
                                                                            AdminStore.getStaffByProfession({ "all": 2, "profession_ids": event?.map(x => { return x.Id }) })
                                                                        }
                                                                    }
                                                                }}
                                                                getOptionLabel={({ Name }) => Name}
                                                                getOptionValue={({ Id }) => Id}
                                                                value={values.profession}

                                                            />
                                                            {errors.profession && touched.profession ? (
                                                                <div className="error-message">{errors.profession}</div>
                                                            ) : null}
                                                        </div>
                                                        <div className="col-sm-3 p-1">
                                                            <Select
                                                                placeholder={'Select Region'}
                                                                isMulti
                                                                closeMenuOnSelect={false}
                                                                hideSelectedOptions={false}
                                                                styles={colourStylesTitle}
                                                                isSearchable={true}
                                                                options={regions}
                                                                value={values.region_id}
                                                                getOptionLabel={(option) => option.Name}
                                                                getOptionValue={(option) => option.ID}
                                                                components={{ Control, MultiValue }}
                                                                onChange={(event) => {
                                                                    if (!event) {
                                                                        setStaffByProfession(staffByProfessionDummy)
                                                                        setFieldValue("region_id", event)
                                                                    }
                                                                    if (event && event.length > 0 && event?.some(item => item.ID === "all")) {
                                                                        // If "Select All" is selected, set all options as selected
                                                                        setFieldValue('region_id', regions?.map((value) => value)?.filter(x => x.ID !== 'all'));
                                                                        const idsToMatch = regions?.map(region => region.ID);
                                                                        let filtered = staffByProfessionDummy?.filter(item => idsToMatch?.includes(item.region_id))
                                                                        filtered = [{ label: 'Select All', value: 'all' }, ...filtered]
                                                                        setStaffByProfession(filtered)
                                                                    } else {
                                                                        // Otherwise, update the selected options as usual
                                                                        if (event && event.length > 0) {
                                                                            const idsToMatch = event?.map(region => region.ID);
                                                                            let filtered = staffByProfessionDummy?.filter(item => idsToMatch?.includes(item.region_id))
                                                                            filtered = [{ label: 'Select All', value: 'all' }, ...filtered]
                                                                            setStaffByProfession(filtered)
                                                                            setFieldValue('region_id', event?.map((value) => value));
                                                                        }

                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-sm-3 p-1">
                                                            <Select
                                                                placeholder={'Select Status'}
                                                                isMulti
                                                                closeMenuOnSelect={false}
                                                                hideSelectedOptions={false}
                                                                styles={colourStylesTitle}
                                                                isSearchable={true}
                                                                options={statusList}
                                                                value={values.status_id}
                                                                getOptionLabel={(option) => option.label}
                                                                getOptionValue={(option) => option.value}
                                                                components={{ Control, MultiValue }}
                                                                onChange={(event) => {
                                                                    if (!event) {
                                                                        let filtered = [];
                                                                        const idsToMatchTwo = values?.region_id?.map(region => region.ID);
                                                                        filtered = staffByProfessionDummy?.filter(item => idsToMatchTwo?.includes(item.region_id))
                                                                        setStaffByProfession(filtered)
                                                                        setFieldValue("status_id", event)
                                                                    }
                                                                    if (event && event.length > 0 && event?.some(item => item.value === "all")) {
                                                                        let filtered = []
                                                                        // If "Select All" is selected, set all options as selected
                                                                        setFieldValue('status_id', statusList?.map((value) => value)?.filter(x => x.value !== 'all'));
                                                                        const idsToMatch = statusList?.map(stat => stat.value);
                                                                        const idsToMatchTwo = values?.region_id?.map(region => region.ID);
                                                                        filtered = staffByProfessionDummy?.filter(item => idsToMatch?.includes(item.status))
                                                                        filtered = filtered?.filter(item => idsToMatchTwo?.includes(item.region_id))
                                                                        filtered = [{ label: 'Select All', value: 'all' }, ...filtered]
                                                                        setStaffByProfession(filtered)
                                                                    } else {

                                                                        // Otherwise, update the selected options as usual
                                                                        if (event && event.length > 0) {
                                                                            let filtered = []
                                                                            const idsToMatch = event?.map(stat => stat.value);
                                                                            const idsToMatchTwo = values?.region_id?.map(region => region.ID);
                                                                            filtered = staffByProfessionDummy?.filter(item => idsToMatchTwo?.includes(item.region_id))
                                                                            filtered = filtered?.filter(item => idsToMatch?.includes(item.status))
                                                                            filtered = [{ label: 'Select All', value: 'all' }, ...filtered]
                                                                            setStaffByProfession(filtered)
                                                                            setFieldValue('status_id', event?.map((value) => value));
                                                                        }

                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-sm-3 p-1">
                                                            <Select
                                                                isMulti
                                                                closeMenuOnSelect={false}
                                                                hideSelectedOptions={false}
                                                                placeholder={'Select Caregiver'}
                                                                required={true}
                                                                styles={colourStylesTitle}
                                                                isSearchable={true}
                                                                isDisabled={values.profession?.length === 0}
                                                                options={staffByProfession || []}
                                                                components={{ Control, MultiValue }}
                                                                value={values.staff_ids}
                                                                onChange={(event) => {
                                                                    if (!event) {
                                                                        setFieldValue("staff_ids", event)
                                                                    }
                                                                    if (event && event.length > 0 && event?.some(item => item.value === "all")) {
                                                                        // If "Select All" is selected, set all options as selected
                                                                        setFieldValue('staff_ids', staffByProfession?.map((value) => value)?.filter(x => x.value !== 'all'));
                                                                    } else {

                                                                        setFieldValue('staff_ids', event?.map((value) => value));
                                                                    }
                                                                }}
                                                            />
                                                        </div>

                                                    </div>
                                                }
                                                    {/* FOR EMPLOYER */}
                                                    <div className="d-flex mb-3 mt-3">
                                                        {(values.group_type === '1' || values.group_type === '0') &&
                                                            <div className="col-sm-3 me-2">
                                                                <Select
                                                                    name="client_ids"
                                                                    placeholder={'Select F.User'}
                                                                    isMulti
                                                                    closeMenuOnSelect={false}
                                                                    hideSelectedOptions={false}
                                                                    components={{ Control, MultiValue }}
                                                                    required={true}
                                                                    options={clientList}
                                                                    styles={colourStylesTitle}
                                                                    isSearchable={true}
                                                                    value={values.client_ids}
                                                                    onChange={(event) => {
                                                                        if (!event) {
                                                                            setFieldValue("client_ids", event)
                                                                        }
                                                                        if (event && event.length > 0 && event?.some(item => item.value === "all")) {
                                                                            // If "Select All" is selected, set all options as selected
                                                                            setFieldValue('client_ids', clientList?.map((value) => value)?.filter(x => x.value !== 'all'));
                                                                        } else {
                                                                            // Otherwise, update the selected options as usual
                                                                            if (event && event.length > 0) {
                                                                                setFieldValue('client_ids', event?.map((value) => value));
                                                                            }
                                                                        }
                                                                    }}
                                                                />
                                                            </div>}

                                                        {/* FOR ADMIN */}
                                                        {(values.group_type === '3' || values.group_type === '0') &&
                                                            <div className="col-sm-3">
                                                                <Select
                                                                    name="admin_ids"
                                                                    placeholder={'Select Admin'}
                                                                    required={true}
                                                                    styles={colourStylesTitle}
                                                                    isSearchable={true}
                                                                    closeMenuOnSelect={false}
                                                                    hideSelectedOptions={false}
                                                                    options={adminList}
                                                                    components={{ Control, MultiValue }}
                                                                    isMulti
                                                                    value={values.admin_ids}
                                                                    onChange={(event) => {
                                                                        if (!event) {
                                                                            setFieldValue("admin_ids", event)
                                                                        }
                                                                        if (event && event.length > 0 && event?.some(item => item.value === "all")) {
                                                                            // If "Select All" is selected, set all options as selected
                                                                            setFieldValue('admin_ids', adminList?.map((value) => value)?.filter(x => x.value !== 'all'));
                                                                        } else {
                                                                            // Otherwise, update the selected options as usual
                                                                            if (event && event.length > 0) {
                                                                                setFieldValue('admin_ids', event?.map((value) => value));
                                                                            }
                                                                        }
                                                                    }}

                                                                />
                                                            </div>}

                                                    </div>
                                                </div>

                                                <div className="col-sm-12 mb-3">
                                                    <label className="pb-2 label">Content<span className="text-danger">*</span></label>
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        placeholder="Type something"
                                                        data={values?.content}
                                                        onChange={(event, editor) => {
                                                            const data = editor.getData();
                                                            setFieldValue("content", data)
                                                        }}

                                                    />
                                                    {errors.content && touched.content ? (
                                                        <div className="error-message">{errors.content}</div>
                                                    ) : null}
                                                </div>
                                                <div className="pt-4 d-flex justify-content-center pb-3">
                                                    <button className="btn btn-primary w-25 p-2" type="submit">{params?.state?.item ? 'Update' : 'Submit'}</button>
                                                </div>
                                            </form>
                                        )
                                    }
                                </Formik>
                            </div>
                        </div>
                    </div>

                    {/* <!-- main-panel ends --> */}
                </div>
                {/* <!-- page-body-wrapper ends --> */}
            </div>
            {/* </body> */}
            <Spinner isLoading={AdminStore.loading} />

        </React.Fragment >
    );
})
export const Control = (props) => {
    return (
        <>
            <label className={props.hasValue || props.isFocused ? props?.selectProps?.isRequired ? "did-floating-label did-floating-label-top did-floating-labe-astrisk" : "did-floating-label did-floating-label-top" : props?.selectProps?.isRequired ? "did-floating-label did-floating-label did-floating-labe-astrisk" : "did-floating-label did-floating-label"} style={{ zIndex: 100 }}>{props?.selectProps?.label}</label>
            <components.Control {...props} />
        </>
    );
};
const MoreSelectedBadge = ({ items }) => {
    const style = {
        marginLeft: "auto",
        background: "#d4eefa",
        borderRadius: "4px",
        fontFamily: "Open Sans",
        fontSize: "11px",
        padding: "3px",
        order: 99
    };

    const title = items.join(", ");
    const length = items.length;
    const label = `+ ${length} item${length !== 1 ? "s" : ""}`;

    return (
        <div style={style} title={title}>
            {label}
        </div>
    );
};
const MultiValue = ({ index, getValue, ...props }) => {
    const maxToShow = 2;
    const overflow = getValue()
        .slice(maxToShow)
        .map((x) => x.label);

    return index < maxToShow ? (
        <components.MultiValue {...props} />
    ) : index === maxToShow ? (
        <MoreSelectedBadge items={overflow} />
    ) : null;
};
export default NotificationAdd;