import React from "react";
import Header from '@components/PDFHeader';
import moment from "moment";
import { Images } from "@elements/index"
import { HOME_URL } from "../../../api/config";

export const PolicyandProcedureAcknow = (props) => {
    console.log(props.inputValues.policy_checkbox, "checkbo");
    return (
        <div class="container" id="form-content">
            <div className="mt-5 mb-5">
                <div className="container">
                    <div class="row justify-content-center mb-2">
                        <center>
                            <img
                                src={`${HOME_URL}static/media/brand.750d576a52f3ac057b3d.png`}
                                alt="staffers logo"
                                style={{
                                    width: "200px",
                                    height: "100px",
                                    textAlign: "center"
                                }}
                            />
                        </center>
                    </div>
                    <h2 className="text-center">POLICY & PROCEDURES</h2>
                    <h3 className="text-center">ACKNOWLEDGEMENT</h3>
                    <p> My signature on this document indicates I have received the following:</p>
                    <p>Staffers LLC policies and procedures: <br /></p>
                    <ul>
                        <li>Assignment Schedules & Payroll Reimbursement</li>
                        <li>Staffers LLC employee handbook</li>
                        <li>Resident Rights</li>
                        <li>Abuse: Prevent/Reporting</li>
                        <li>Incident Investigation & Follow-up Responsibility</li>
                        <li>Communication and Responsibility of Assignment</li>
                        <li>Fire Safety</li>
                        <li>Emergency Procedures</li>
                        <li>Hazardous Materials</li>
                        <li>Blood Born Pathogens</li>
                        <li>HIPPA</li>
                        <li>OSHA</li>
                    </ul>
                    <p>I understand it is my responsibility to read and understand these Staffers LLC <br />policies and procedures. If I have any questions concerning these policies and <br />procedures, I know that I can contact the Staffers Manager. Further, it is the <br />expectation of Staffers LLC that I will follow these policies and procedures. Failure <br />to comply with them will result in corrective discipline, up to including termination. <br /></p>
                    <p><input
                        type="checkbox"
                        checked={props.inputValues.policy_checkbox === 'on'}
                        onChange={(e) => props.handleInputChange('policy_checkbox', e.target.checked ? 'on' : '')}
                        required
                    /> &nbsp;Checking box and signing below is consent for electronic signature on    <br />all new hire paperwork. <br /></p>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <span className="d-flex justify-content-between align-items-center">
                                    Employee's Printed Name
                                    <input
                                        placeholder="Full Name"
                                        style={{ border: 'none' }}
                                        required
                                        type="text"
                                        minLength={2}
                                        maxLength={20}
                                        name="full_name"
                                        value={props.inputValues.full_name}
                                        onChange={(e) => props.handleInputChange('full_name', e.target.value)}
                                    />
                                </span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <span className="d-flex justify-content-between align-items-center">
                                    Employee's Signature
                                    {props?.signature ? (
                                        <img src={props?.signature} width={180} height={50} alt="Signature here" />
                                    ) : (
                                        <div className="badge badge-primary text-wrap pointer" onClick={props?.onOpenModal}>
                                            Click Here To Sign
                                        </div>
                                    )}
                                </span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <span className="d-flex justify-content-between align-items-center">
                                    Date
                                    <input type="text" value={moment(new Date()).format("MM-DD-YYYY HH:mm:ss")} disabled />
                                </span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    );
};

