import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';

const ExcelPreview = (props) => {
  const [excelPreview, setExcelPreview] = useState('');


  useEffect(() => {
    const file = props?.file;

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });

        // Assuming there is only one sheet in the workbook
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Convert sheet data to HTML
        const html = XLSX.utils.sheet_to_html(sheet);

        // Display HTML in the preview div
        setExcelPreview(html);
      };

      reader.readAsArrayBuffer(file);
    }
  }, [props?.file]);

  const containerStyle = {
    // maxHeight: '150px',
    overflow: 'auto',
    position: 'relative',
    border: '1px solid #ddd',
  };
  const cellStyle = {
    border: '1px solid #ddd',
    padding: '4px', // Reduced padding for smaller cells
    fontSize: '20px', // Optional: Adjust font size for smaller text
    lineHeight: '30px'
  };
  return (
    <div style={containerStyle}>
      <div dangerouslySetInnerHTML={{ __html: excelPreview }} style={cellStyle} />
    </div>
  );
};

export default ExcelPreview;
