import React from "react";
import { Images } from "@elements/index"
import { MultiDatePicker } from "@elements/index"
import { HOME_URL } from "../../../api/config";
export const BackgroundCheckChecklist = (props) => {

  return (
    <div id="form-content">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="border-box p-2">
              <div className="agreement-sec content-wrap mb-3">
                <h2>Background Checklist:</h2>
                <p>
                  <img
                    src={`${HOME_URL}static/media/BG.08a11f89ba95ef61aaa8.png`}
                    alt="Pineapple"
                    style={{
                      width: "340px",
                      height: "90px",
                      marginLeft: "15px",
                    }}
                  />
                  <span> Information You Will Need to Submit a Background Check into
                    ORCHARDS</span> <br></br> <br></br> This checklist to gather
                  necessary information for BCU to submit a background check
                  request into ORCHARDS for your position. See page 2 for
                  examples of types of identity documents.
                </p>
              </div>
              <p>
                <b>
                  Subject individual (SI) Information &nbsp; &nbsp; Required
                  Fields Marked with Asterisk (*){" "}
                </b>
              </p>
              <table className="table-screening paragraph-wrap">
                <tbody>
                  <tr>
                    <td>
                      <span>&nbsp;1</span>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <p>Social Security # (Note This is voluntary.)</p>
                        <input className="table-screening-input" type="text" placeholder="AAA-GG-SSSS" value={props.inputValues.ssn} onChange={(e) => props.handleInputChange('ssn', e.target.value)} />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>&nbsp;2</span>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <p>*Complete Name:</p>
                        <input required className="table-screening-input" placeholder="Full Name" minLength={2} maxLength={20} name="complete name" value={props.inputValues.full_name} onChange={(e) => props.handleInputChange('full_name', e.target.value)} />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>&nbsp;3</span>

                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <p>*Date of birth (mm/dd/yyyy):</p>
                        {/* <input type="date"  value={props.inputValues.dob} onChange={(e) => props.handleInputChange('dob', e.target.value)} /> */}
                        <MultiDatePicker className="table-screening-input" required={false} multiple={false} value={props.inputValues.dob} onChange={(e) => props.handleInputChange('dob', e)} />

                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>&nbsp;4</span>

                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <p>*Residential address:</p>
                        <input required className="table-screening-input" minLength={2} maxLength={100} name="residential address" value={props.inputValues.address} onChange={(e) => props.handleInputChange('address', e.target.value)} />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>&nbsp;5</span>

                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <p>Mailing address (if different):</p>
                        <input className="table-screening-input" type="email" value={props.inputValues.diff_email} onChange={(e) => props.handleInputChange('diff_email', e.target.value)} />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>&nbsp;6</span>

                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <p>*Prior names and aliases:</p>
                        <input className="table-screening-input" minLength={2} maxLength={100} name="prior names and aliases" value={props.inputValues.aliases_first} onChange={(e) => props.handleInputChange('aliases_first', e.target.value)} />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>&nbsp;7</span>

                    </td>
                    <td>
                      <div className="d-flex align-items-center" style={{ flexWrap: "wrap" }}>
                        <p>*Gender: </p>
                        <label>
                          <input
                            type="radio"
                            name="gender"
                            value="male"
                            checked={props?.inputValues?.gender === "male"} // Use 'checked' attribute
                            onChange={(e) => { props?.handleInputChange('gender', e.target.value) }}
                          />&nbsp;
                          Male
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="gender"
                            value="female"
                            checked={props?.inputValues?.gender === "female"} // Use 'checked' attribute
                            onChange={(e) => { props?.handleInputChange('gender', e.target.value) }}
                          />&nbsp;
                          Female
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="gender"
                            value="unknown"
                            checked={props?.inputValues?.gender === "unknown"} // Use 'checked' attribute
                            onChange={(e) => { props?.handleInputChange('gender', e.target.value) }}
                          />&nbsp;
                          Unknown/Not Specified
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="gender"
                            value="other"
                            checked={props?.inputValues?.gender === "other"} // Use 'checked' attribute
                            onChange={(e) => { props?.handleInputChange('gender', e.target.value) }}
                          />&nbsp;
                          Other
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="gender"
                            value="both"
                            checked={props?.inputValues?.gender === "both"} // Use 'checked' attribute
                            onChange={(e) => { props?.handleInputChange('gender', e.target.value) }}
                          />&nbsp;
                          Both
                        </label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>&nbsp;8</span>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center">
                          <p>*Phone:</p>
                          <input required minLength={7} maxLength={12} name="phone number" type="text" className="table-screening-input" value={props.inputValues.telephone} onChange={(e) => props.handleInputChange('telephone', e.target.value)} />
                        </div>
                        <div className="d-flex align-items-center">
                          <p> *Type of Phone (home, mobile, etc.):</p>
                          <input required type="text" maxLength={10} name="Type of Phone (home, mobile, etc.)" className="table-screening-input" value={props.inputValues.telephone_type} onChange={(e) => props.handleInputChange('telephone_type', e.target.value)} />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>&nbsp;9</span>

                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center">
                          <p>2nd Phone:</p>
                          <input type="text" className="table-screening-input" value={props.inputValues.telephoneSecond} onChange={(e) => props.handleInputChange('telephoneSecond', e.target.value)} />
                        </div>
                        <div className="d-flex align-items-center">
                          <p> Type Phone :</p>
                          <input type="text" className="table-screening-input" value={props.inputValues.telephone_type2} onChange={(e) => props.handleInputChange('telephone_type2', e.target.value)} />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>&nbsp;10</span>

                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <p>*Email:</p>
                        <input type="email" className="table-screening-input" value={props.inputValues.email} onChange={(e) => props.handleInputChange('email', e.target.value)} />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>&nbsp;11</span>

                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <p>Residential History outside OR, past five years:</p>
                        <input type="text" className="table-screening-input" value={props.inputValues.ResidentialHistory} onChange={(e) => props.handleInputChange('ResidentialHistory', e.target.value)} />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <div className="d-flex align-items-center">
                        <input type="text" className="table-screening-input" value={props.inputValues.ResidentialHistory2} onChange={(e) => props.handleInputChange('ResidentialHistory2', e.target.value)} />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <p>
                        <b>
                          Employment Information &nbsp; &nbsp; Required Fields
                          Marked with Asterisk (*)
                        </b>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>&nbsp;12</span>

                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <p>*Provider (Place of Employment):</p>
                        <input type="text" required maxLength={50} name="place of employment" className="table-screening-input" value={props.inputValues.PlaceofEmployment} onChange={(e) => props.handleInputChange('PlaceofEmployment', e.target.value)} />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>&nbsp;13</span>

                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <p>*Position:</p>
                        <input type="text" required maxLength={50} name="position" className="table-screening-input" value={props.inputValues.EmploymentPosition} onChange={(e) => props.handleInputChange('EmploymentPosition', e.target.value)} />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>&nbsp;14</span>

                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <p>
                          *Position Description (include worksite location; you
                          can also upload on Verify Identity page):
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <div className="d-flex align-items-center">
                        <textarea className="table-screening-input" value={props.inputValues.PositionDescription} onChange={(e) => props.handleInputChange('PositionDescription', e.target.value)} />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>&nbsp;15</span>

                    </td>
                    <td>
                      <div
                        className="d-flex align-items-center"
                        style={{ flexWrap: "wrap" }}
                      >
                        <p>Position Requires Direct Contact with: </p>
                        <label>
                          <input
                            type="radio"
                            name="PositionRequires"
                            value="Adults"
                            checked={props?.inputValues?.PositionRequires === "Adults"} // Use 'checked' attribute
                            onChange={(e) => { props?.handleInputChange('PositionRequires', e.target.value) }}
                          />&nbsp;
                          Adults
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="PositionRequires"
                            value="Children"
                            checked={props?.inputValues?.PositionRequires === "Children"} // Use 'checked' attribute
                            onChange={(e) => { props?.handleInputChange('PositionRequires', e.target.value) }}
                          />&nbsp;
                          Children
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="PositionRequires"
                            value="ConfidentialInformation"
                            checked={props?.inputValues?.PositionRequires === "ConfidentialInformation"} // Use 'checked' attribute
                            onChange={(e) => { props?.handleInputChange('PositionRequires', e.target.value) }}
                          />&nbsp;
                          Confidential Information
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="PositionRequires"
                            value="Finances"
                            checked={props?.inputValues?.PositionRequires === "Finances"} // Use 'checked' attribute
                            onChange={(e) => { props?.handleInputChange('PositionRequires', e.target.value) }}
                          />&nbsp;
                          Finances/Financial Records
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="PositionRequires"
                            value="ITS"
                            checked={props?.inputValues?.PositionRequires === "ITS"} // Use 'checked' attribute
                            onChange={(e) => { props?.handleInputChange('PositionRequires', e.target.value) }}
                          />&nbsp;
                          Information Technology Systems
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="PositionRequires"
                            value="SecureFacilities"
                            checked={props?.inputValues?.PositionRequires === "SecureFacilities"} // Use 'checked' attribute
                            onChange={(e) => { props?.handleInputChange('PositionRequires', e.target.value) }}
                          />&nbsp;
                          Secure Facilities
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="PositionRequires"
                            value="Seniors"
                            checked={props?.inputValues?.PositionRequires === "Seniors"} // Use 'checked' attribute
                            onChange={(e) => { props?.handleInputChange('PositionRequires', e.target.value) }}
                          />&nbsp;
                          Seniors
                        </label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>&nbsp;16</span>

                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <p>Position Requires : </p>
                        <label>
                          <input
                            type="checkbox"
                            name="PositionRequiresDriving"
                            value={props.PositionRequiresDriving}// Use 'checked' attribute
                            onChange={(e) => { props?.handleInputChange('PositionRequiresDriving', e.target.value) }}
                          />
                          Driving
                        </label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} className="pb-2 pt-2">
                      <p>
                        <b >
                          Applicant: Verify Identity &nbsp; &nbsp; Required
                          Fields Marked with Asterisk (*)
                        </b>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>&nbsp;17</span>

                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <p>*Document type:</p>
                        <input type="text" maxLength={50} name="document type" className="table-screening-input" value={props.inputValues.Documenttype} onChange={(e) => props.handleInputChange('Documenttype', e.target.value)} />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>&nbsp;18</span>

                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <p>*Issuing State/Authority:</p>
                        <input type="text" className="table-screening-input" maxLength={10} name="Issuing State/Authority" value={props.inputValues.IssuingState} onChange={(e) => props.handleInputChange('IssuingState', e.target.value)} />
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <span>&nbsp;19</span>

                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <p>*Document Number:</p>
                        <input type="text" maxLength={50} name="document number" className="table-screening-input" value={props.inputValues.DocumentNumber} onChange={(e) => props.handleInputChange('DocumentNumber', e.target.value)} />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>&nbsp;20</span>

                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <p>*Expiration Date: </p>
                        <MultiDatePicker className="table-screening-input" required={false} multiple={false} value={props.inputValues.ExpirationDate} onChange={(e) => props.handleInputChange('ExpirationDate', e)} />
                      </div>
                    </td>
                  </tr>
                  {/* <tr>
                    <td>
                      <span>&nbsp;21</span>

                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <p>
                          Identity Document to upload (can be .doc, .pdf, .jpg,
                          etc.).{" "}
                        </p>
                        <input type="text" required  className="table-screening-input" value={props.inputValues.DocExt} onChange={(e) => props.handleInputChange('DocExt', e.target.value)} />
                      </div>
                    </td>
                  </tr> */}
                </tbody>
              </table>
              {/* <div className="row">
                <div className="col-md-6">
                  <p className="mt-3">
                    [Identity] Document: Used to confirm an SI’s identity with a
                    government-issued photo identification. Please provide a
                    copy of the document along with the completed Background
                    Check Checklist. The following are types of documents listed
                    in ORCHARDS:{" "}
                  </p>
                  <ul className="para-bottom">
                    <li>
                      <p>Oregon State Issued Driver’s License</p>
                    </li>
                    <li>
                      <p> Oregon State Issues Identification Card </p>
                    </li>
                    <li>
                      <p> Non Oregon State Issued Driver’s License </p>
                    </li>
                    <li>
                      <p> Non Oregon State Issued Identification Card </p>
                    </li>
                    <li>
                      <p> United States Armed Forces ID </p>
                    </li>
                    <li>
                      <p> Passport </p>
                    </li>
                    <li>
                      <p> Visa </p>
                    </li>
                    <li>
                      <p> High School/College ID </p>
                    </li>
                    <li>
                      <p> Other Government-Issued Photo ID</p>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
