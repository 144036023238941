import React from "react";
import UserStore from "@store/UserStore";
import { toast } from "react-toastify";
import moment from "moment";

const StaffsItem = ({ item, onOpen, isAuthenticated, bookmarkList, regions }) => {
    const currentDate = moment();
    var regex = /\b(\w{1})(\w+)(\w)\b/g;

    const bookmark = (data, e) => {
        e?.stopPropagation();
        let param = {
            bluesky_staff_id: data?.staff_profile?.bluesky_staff_id,
            first_name: data.first_name,
            last_name: data.last_name,
            state: data.state_id,
            city: data.city,
        }
        UserStore.bookmark(param, navigationCallBackBookmark)
    }
    const navigationCallBackBookmark = (msg) => {
        UserStore.getBookmark();
        toast.success("A caregiver is successfully saved to your favourites list.");
    }
    return (
        <>
            <div className="grid__item">
                <a href={!isAuthenticated?"/register/employer":undefined} className={isAuthenticated ? "" : "list"}>
                    <div className='flip-card-inner' onClick={() => onOpen(item)}>
                        <div className="flip-card-front">
                            <div className="front-side">
                                <div className="color-grid">
                                    <div className="black"></div>
                                    <div className="red1"></div>
                                    <div className="red2"></div>
                                    <div className="green"></div>
                                </div>
                                <div className="info-grid">
                                    <div className="name">
                                        <div className="flip-text">
                                            <div className="">
                                                <h2 className="title-text">{isAuthenticated ? item?.first_name : item?.first_name.replace(
                                                    regex,
                                                    (_, first, middle, last) => `${first}${'x'.repeat(middle.length)}${last}`
                                                )}
                                                </h2>
                                                {item?.avg_rating ? <h3><span className={item?.avg_rating ? "badge success-tag" : "badge"} style={{ fontSize: 12, fontWeight: 'bolder' }}>{item?.avg_rating ? item?.avg_rating : ""} {item?.avg_rating ? <i className="fas fa-star text-warning"></i> : ""}</span><span className="rating-count">  ({item?.rating_count ? item?.rating_count : ""})</span></h3> : null}
                                            </div>
                                        </div>
                                        {/* {item?.degree_specialities && <h5 className="info-text">{JSON.parse(item?.degree_specialities)?.map((pro,i) => { return <div key={i+'prop'}>{pro?.profession_name}{pro.exp ? pro.exp === 0 ? "" : `, Experience (${pro.exp === 1 ?pro.exp + "Year" :pro.exp + "Year(s)"})` : "" || ''} < br /></div> })}</h5>} */}
                                        {item?.degree_specialities && <h6 className="info-text">{JSON.parse(item?.degree_specialities)?.map((spec,i) => { return <div key={i+'spec'}>{spec.specialties_name || ''} < br /></div> })}</h6>}
                                        {item?.city && <p className="info-text"><span><i className="fa fa-map-marker-alt fs-4" /> {item?.city}, {item?.state_id}</span></p>}
                                    </div>
                                    <div className="addr">
                                    </div>
                                    {isAuthenticated && <div className="vaccinated" onClick={(e) => bookmark(item, e)}>
                                        {bookmarkList?.find(x => x == item?.staff_profile?.bluesky_staff_id) ? <div className="bookmark-filled"></div> : <div className="bookmark"></div>}
                                    </div>}
                                    <div className="emailId">
                                        <p className="email">
                                            <strong></strong></p>
                                        <p className="web">{regions?.find(reg => reg.ID == item?.region_id)?.Name}</p>
                                    </div>
                                    <div className="view-more">
                                        <p className="email">
                                            <strong></strong></p>
                                        {isAuthenticated && <p className="web" >View Details</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={isAuthenticated ? "d-none" : "flip-card-back"}>
                            <div className="front-side">
                                <div className="info-grid">
                                    <a >
                                        <div className="signup pe-auto d-flex justify-content-center">
                                            <h5 className="signup-link">Signup to view details</h5>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                </a>
            </div>
        </>
    )
};
export default StaffsItem;