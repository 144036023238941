/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import Header from '@components/Header';
import { CModal, CButton, CModalHeader, CModalTitle, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CCardBody, CModalBody, CModalFooter } from "@coreui/react";
import SelectField from "@elements/select/SelectField";
import { InputField } from "@elements/index";
import { Helmet } from 'react-helmet';
import 'react-spring-bottom-sheet/dist/style.css';
import UserStore from "@store/UserStore";
import HomeStore from "@store/HomeStore";
import StaffsStore from "@store/StaffsStore";
import { observer } from "mobx-react-lite";
import { toJS } from 'mobx';
import swal from '@sweetalert/with-react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import Spinner from "@components/Spinner";
import * as Yup from 'yup';
import { Formik, Field, FieldArray, ErrorMessage } from "formik";
import { toast } from "react-toastify";
import ReactDOM from "react-dom";
import Select, { components } from 'react-select';
import moment from 'moment';
import { SignUpSubSchema, SubClientEditSchema } from '@utils/schemas/allSchema';

import { colourStylesState, colourStyles } from '@utils/customStyle/style';

var citys = require('@assets/city.json');
var states = require('@assets/states.json');
var countryCodes = require('@assets/CountryCodes.json');

const SubAccountList = observer(() => {
    const tableRef = useRef()
    const tableName = "table1"
    const [subAccountList, setSubAccountList] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [subUser, setSubUser] = useState();
    const [regions, setRegions] = useState([]);
    const [icon, setIcon] = useState("fa fa-fw fa-eye-slash field-icon toggle-password")
    const [passwordType, setPasswordType] = useState("password");
    const [userData, setUserData] = useState();
    const _changeIcon = () => {
        icon === "fa fa-fw fa-eye-slash field-icon toggle-password"
            ? (setIcon("fa fa-fw fa-eye field-icon toggle-password"), setPasswordType("text"))
            : (setIcon("fa fa-fw fa-eye-slash field-icon toggle-password"), setPasswordType("password"))
    }
    useEffect(() => {
        StaffsStore.getRegionsList();
        UserStore.getUserProfile()
        UserStore.getSubAccounts()
    }, [])

    useEffect(() => {
        setRegions(StaffsStore?.data?.regionsList)
    }, [StaffsStore?.data?.regionsList]);

    useEffect(() => {
        setSubAccountList(toJS(UserStore?.data?.subAccounts))
    }, [UserStore?.data?.subAccounts]);

    useEffect(() => {
        $.fn.dataTable.ext.errMode = 'none';
        $($.fn.dataTable.tables(true)).DataTable()
            .columns.adjust();
        const table = $(`#${tableName}`).DataTable(
            {
                data: subAccountList,
                order: [], // Disable initial sorting
                columns: [
                    {
                        title: "#", data: 'id', width: 30, "render": function (data, type, full, meta) {
                            return meta.row + 1;
                        }
                    },
                    {
                        title: "Username", data: "username", "render": function (data, type, full, meta) {
                            return '<a class="text-info pointer"><strong>' + data + '</strong></a>';
                        }
                    },
                    {
                        title: "Full Name", "render": function (data, type, row, full, meta) {
                            return '<a class="text-info pointer"><strong>' + row?.first_name + " " + row?.last_name + '</strong></a>';
                        }
                    },
                    {
                        title: "Designation", data: "designation", "render": function (data, type, full, meta) {
                            return data;
                        }
                    },
                    {
                        title: "Department", data: "department", "render": function (data, type, full, meta) {
                            return '<a class="text-info pointer"><strong>' + data + '</strong></a>';
                        }
                    },
                    {
                        title: "Email", data: "email", "render": function (data, type, full, meta) {
                            return data;
                        }
                    },
                    {
                        title: "Contact No.", data: "contact", "render": function (data, type, full, meta) {
                            return data;
                        }
                    },
                    {
                        title: "Last Updated By", width: 100, data: 'last_updated_by', "render": function (data, type, row, meta) {
                            return data ? (data?.first_name + " " + data?.last_name) : "-"
                        }
                    },
                    {
                        title: "Last Update", width: 100, data: 'updated_at', "render": function (data, type, row, meta) {
                            return moment(data).fromNow() || "-";
                        }
                    },
                    {
                        title: "Status", data: 'status', "render": function (data, type, row, meta) {
                            return data == '0' ? '<span class="text-warning">' + "Pending" + '</span>' : data == '1' ? '<span class="text-success">' + "Active" + '</span>' : data == '2' ? '<span class="text-danger">' + "Rejected" + '</span>' : data == '3' ? '<span class="text-dark">' + "Blocked" + '</span>' : 'No Update'
                        }
                    },
                    {
                        title: "Action",
                    },
                ],
                columnDefs: [
                    {
                        targets: 9,
                        responsivePriority: 1,
                        createdCell: (td, cellData, rowData, row, col) =>
                            ReactDOM.render(
                                <div>
                                    <CDropdown variant="btn-group" direction='dropstart'>
                                        <CDropdownToggle size="sm" color="transparent" caret={false} href="#" className="p-0"><i className="fa fa-ellipsis-v text-primary"></i></CDropdownToggle>
                                        <CDropdownMenu >
                                            <CDropdownItem>
                                                <CButton onClick={() => {
                                                    showDetails(rowData)
                                                }} size="sm" className="w-100" variant='outline' color="primary" >
                                                    View <i className="fas fa-eye"></i>
                                                </CButton>
                                            </CDropdownItem>
                                            <CDropdownItem className="more-menu">
                                                <CButton onClick={() => {
                                                    setSubUser(rowData)
                                                    setIsOpenModal(true)
                                                }} size="sm" className="w-100" variant='outline' color="primary" >
                                                    Edit <i className="fas fa-edit"></i>
                                                </CButton>
                                            </CDropdownItem>
                                            {rowData.status != 0 && (<CDropdownItem className="more-menu">
                                                <CButton
                                                    onClick={() => {
                                                        blockSubAccount(rowData)
                                                    }}
                                                    size="sm" className="w-100" variant='outline' color={rowData.status === 3 ? "success" : "danger"}>
                                                    {rowData.status === 3 ? 'Activate' : 'Block'} {rowData.status === 3 ? <i className="fas fa-user-check"></i> : <i className="fas fa-ban"></i>}
                                                </CButton>
                                            </CDropdownItem>)}
                                        </CDropdownMenu>
                                    </CDropdown>
                                </div>
                                , td),
                    }
                ],
                destroy: true,  // I think some clean up is happening here
                searching: true,
                "language": {
                    search: '',
                    searchPlaceholder: "Search",
                    emptyTable: "No Record Found"
                },
                initComplete: function () {
                    // Apply the search
                    this.api()
                        .columns([0, 1, 2, 3, 4, 5, 6, 7, 8])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><input class="form-control " style="width:100%;margin-top:5px" type="text" placeholder="Filter" />')
                                .appendTo($(column.header()))

                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $('input', this.header()).on('keyup change clear', function () {
                                if (column.search() !== this.value) {
                                    column.search(this.value).draw();
                                }
                            });
                        });
                    this.api()
                        .columns([9])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><select class="form-control" placeholder="search" style="width:100%;margin-top:5px;background-color:white""><option value="">All</option></select>')
                                .appendTo($(column.header()))
                                .on('change', function () {
                                    var val = $.fn.dataTable.util.escapeRegex($(this).val());
                                    column.search(val ? '^' + val + '$' : '', true, false).draw();
                                });
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            column
                                .data()
                                .unique()
                                .sort()
                                .each(function (d, j) {
                                    let name = [{ "name": "Pending", "value": 0 }, { "name": "Active", "value": 1 }, { "name": "Rejected", "value": 2 }, { "name": "Blocked", "value": 3 }]?.find(stat => stat.value == d)?.name;
                                    select.append('<option value="' + name + '">' + name + '</option>');
                                });
                        });
                },
            }

        )
        table.on('click', 'td', function () {
            // Get the column index of the clicked cell
            const columnIndex = table.cell(this).index()?.column;
            // Check if the clicked cell belongs to the desired column
            if (columnIndex === 1 || columnIndex === 2 || columnIndex === 4) {
                // Get the data for the clicked cell
                const rowData = table.row($(this).closest('tr')).data();
                // Handle the event for the clicked cell
                if (rowData) {
                    showDetails(rowData)
                }
            }
        });
        // Extra step to do extra clean-up.
        return function () {
            console.log("Table destroyed")
            table.destroy()
        }
    }, [subAccountList])

    useEffect(() => {
        setUserData(UserStore?.data?.profile)
    }, [UserStore.data.profile]);

    const showDetails = (item) => {
        setSubUser(item)
        setIsOpen(true)
    }

    const onDismiss = () => {
        setIsOpen(false)

    }
    const addSubAccount = (values) => {
        if (subUser?.id) {
            if (values.password != values.confirm_password) {
                toast.error("Confirm password must match");
                return false
            }
            values.id = subUser?.id;
            UserStore.updateSubAccount(values, navigationCallBack)
        } else {
            if (values.password != values.confirm_password) {
                toast.error("Confirm password must match");
                return false
            }
            UserStore.addSubAccount(values, navigationCallBack)
        }
    }

    const navigationCallBack = () => {
        setIsOpenModal(false)
        UserStore.getSubAccounts()
    }
    const blockSubAccount = (item) => {
        swal({
            title: item?.status === 3 ? "Do you really want to unblock this account?" : "Do you really want to block this account?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    UserStore.blockSubAccount({ id: item.id }, navigationCallBack)
                }
            });
    }


    return (
        <>
            <Header />
            <Helmet>
                <title>Sub Accounts</title>
            </Helmet>
            <>
                <div className="container-fluid callback mt-5 py-5">
                    <div className="container pt-lg-5">
                        <h5 className="display-5 mb-4 animated slideInDown login-head">
                            Sub Accounts</h5>
                    </div>
                    <div className="col-lg-12">
                        <div className="row bg-white pt-3">
                            <div className="mb-0 pb-0">
                                <div className='d-flex justify-content-center'>
                                    <a onClick={() => setIsOpenModal(true)} href="#">
                                        <h1 className="page-title signup-link fs-4" >
                                            <span className="page-title-icon linear text-white me-2">
                                                <i className="fas fa-plus"></i>
                                            </span>Add Sub Account</h1>
                                    </a>
                                </div>
                            </div>
                            <table className="table dt-responsive table-hover nowrap" style={{ width: "100%" }} id={tableName} ref={tableRef}></table>
                        </div>
                    </div>
                </div>
                <BottomSheet open={isOpen} onDismiss={onDismiss} scrollLocking={true}
                    header={
                        <>
                            <div className="text-end" style={{ cursor: "pointer" }} onClick={onDismiss}>
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <h4 className="text-center text-uppercase">
                                {subUser?.title + " " + subUser?.first_name + " " + subUser?.last_name}
                            </h4>
                        </>
                    }
                >
                    <div style={{ height: global.config.windowHeight / 2.5, overflowY: 'scroll' }}>
                        <div className="col-xl-12 col-md-12">
                            <div className=" user-card-full">
                                <div className="row m-l-0 m-r-0">
                                    <div className="col-sm-12">
                                        <div className="card-block">
                                            <div className='d-flex justify-content-between align-items-center b-b-default'>
                                                <h6 className="f-w-600">Account Details</h6>
                                                <h5 ><span className="badge badge-primary">{moment(subUser?.created_at).fromNow()}</span></h5>
                                            </div>
                                            <div className="row mt-4">
                                                {(subUser && subUser?.username) && <div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">Username</p>
                                                    <h6 className="text-dark"><strong>{subUser?.username}</strong></h6>
                                                </div>}
                                                {(subUser && subUser?.first_name) && <div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">Name</p>
                                                    <h6 className="text-muted f-w-400">{subUser?.title} {subUser?.first_name}{subUser?.last_name}</h6>
                                                </div>}
                                                {subUser && subUser?.designation && <div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">Job Titile</p>
                                                    <h6 className="text-muted f-w-400">{subUser?.designation}</h6>
                                                </div>}
                                                {subUser && subUser?.department && <div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">Department</p>
                                                    <h6 className="text-muted f-w-400">{subUser?.department}</h6>
                                                </div>}
                                                {subUser && subUser?.email && <div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">Email</p>
                                                    <h6 className="text-muted f-w-400"><a className='' href={"mailto:" + subUser?.email}>{subUser?.email}</a></h6>
                                                </div>}
                                                {subUser && subUser?.contact && <div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">Contact No.</p>
                                                    <h6 className="text-muted f-w-400"><a className='' href={"tel:" + subUser?.country_code + subUser?.contact}>({subUser?.country_code}){subUser?.contact}</a></h6>
                                                </div>}

                                                {subUser && subUser?.region_id && <div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">Region</p>
                                                    <h6 className="text-muted f-w-400">{regions?.find(reg => reg.ID == subUser?.region_id)?.Name}</h6>
                                                </div>}
                                                {subUser && subUser?.state && <div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">State</p>
                                                    <h6 className="text-muted f-w-400">{subUser?.state}</h6>
                                                </div>}
                                                {subUser && subUser?.city && <div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">City</p>
                                                    <h6 className="text-muted f-w-400">{subUser?.city}</h6>
                                                </div>}
                                                {subUser && subUser?.address && <div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">Address</p>
                                                    <h6 className="text-muted f-w-400">{subUser?.address}</h6>
                                                </div>}
                                                {subUser && subUser?.zipcode && <div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">Zip Code</p>
                                                    <h6 className="text-muted f-w-400">{subUser?.zipcode}</h6>
                                                </div>}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </BottomSheet>
            </>
            {/* Add/Update Sub-accont*/}
            < CModal  className="custom-modal-size" scrollable visible={isOpenModal}  onClose={() => setIsOpenModal(false)} backdrop='static'>
                <CModalHeader onClose={() => setIsOpenModal(false)}>
                    <CModalTitle className="text-center">{subUser ? "UPDATE SUB ACCOUNT" : "ADD SUB ACCOUNT"}</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <div className="p-2">
                            <Formik
                                initialValues={{
                                    first_name: subUser?.first_name
                                        || '', last_name: subUser?.last_name
                                            || '', contact: subUser?.contact
                                                || '', email: subUser?.email
                                                    || '', country_code: (subUser?.country_code ? subUser?.country_code : userData?.country_code) || '+1',
                                    designation: subUser?.designation
                                        || '', department: subUser?.department
                                            || "", description: subUser?.description
                                                || "", password: '', confirm_password: '',

                                    additional_contact: (subUser?.additional_contact && JSON.parse(subUser?.additional_contact)) || [],
                                    additional_mail: (subUser?.additional_mail && JSON.parse(subUser?.additional_mail)) || [],
                                }}
                                onSubmit={values => addSubAccount(values)}
                                enableReinitialize
                                validationSchema={subUser ? SubClientEditSchema : SignUpSubSchema}
                                validateOnChange={false} >
                                {
                                    ({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched
                                    }) =>
                                    (
                                        <form onSubmit={handleSubmit} className="wow fadeInUp">
                                            <div className="row">
                                                <div className="col-6 mb-3">
                                                    <InputField
                                                        className={"did-floating-input"}
                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                        type="text"
                                                        name="first_name"
                                                        label={"First Name"}
                                                        value={values.first_name}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    {errors.first_name && touched.first_name ? (
                                                        <div className="error-message">{errors.first_name}</div>
                                                    ) : null}
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <InputField
                                                        className={"did-floating-input"}
                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                        type="text"
                                                        name="last_name"
                                                        label={"Last Name"}
                                                        value={values.last_name}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    {errors.last_name && touched.last_name ? (
                                                        <div className="error-message">{errors.last_name}</div>
                                                    ) : null}
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <InputField
                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                        className={"did-floating-input"}
                                                        type="text"
                                                        name="designation"
                                                        label={"Job Title"}
                                                        value={values.designation}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    {errors.designation && touched.designation ? (
                                                        <div className="error-message">{errors.designation}</div>
                                                    ) : null}
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <InputField
                                                        className={"did-floating-input"}
                                                        type="text"
                                                        name="department"
                                                        label={"Department"}
                                                        value={values.department}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    {errors.department && touched.department ? (
                                                        <div className="error-message">{errors.department}</div>
                                                    ) : null}
                                                </div>
                                                <div className="col-3 mb-3">
                                                    <Select
                                                        onChange={(e) => setFieldValue("country_code", e.label)}
                                                        placeholder={''}
                                                        label={'Country Code'}
                                                        value={countryCodes.filter(({ label }) => values?.country_code?.includes(label)) || ''}
                                                        styles={colourStyles}
                                                        isSearchable={true}
                                                        options={countryCodes}
                                                        defaultValue={{ label: "+1", value: 'CA' }}
                                                        components={{ Control }}
                                                    />
                                                </div>
                                                <div className="col-3 mb-3">
                                                    <InputField
                                                        className={"did-floating-input"}
                                                        type="text"
                                                        name="contact"
                                                        label={"Contact Number"}
                                                        value={values?.contact?.replace(/[^\d]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}
                                                        maxLength={12}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    {errors.contact && touched.contact ? (
                                                        <div className="error-message">{errors.contact}</div>
                                                    ) : null}
                                                </div>
                                                <div className="col-sm-6 mb-3">
                                                    <InputField
                                                        className={"did-floating-input"}
                                                        type="email"
                                                        name="email"
                                                        label={"Email"}
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    {errors.email && touched.email ? (
                                                        <div className="error-message">{errors.email}</div>
                                                    ) : null}
                                                </div>
                                                <div className="col-sm-12 d-flex mt-0 pt-0">
                                                    <div className="col-sm-6">
                                                        <FieldArray
                                                            name="additional_contact"
                                                            render={({ push, remove }) => (
                                                                <div>
                                                                    {values?.additional_contact?.map((device, index) => (
                                                                        <div key={index} className="row g-2">
                                                                            <div className="col-sm-4">
                                                                                <Select
                                                                                    onChange={(e) => setFieldValue(`additional_contact.${index}.country_code`, e.label)}
                                                                                    placeholder={''}
                                                                                    label={'Country'}
                                                                                    styles={colourStyles}
                                                                                    isSearchable={true}
                                                                                    options={countryCodes}
                                                                                    defaultValue={{ label: "+1", value: 'CA' }}
                                                                                    components={{ Control, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                                                />
                                                                            </div>
                                                                            <div className="col-sm-7 pb-2">
                                                                                <InputField
                                                                                    className={"did-floating-input"}
                                                                                    classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                                    type="text"
                                                                                    name="contact"
                                                                                    label={"Contact Number"}
                                                                                    value={device?.contact?.replace(/[^\d]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3') || ""}
                                                                                    maxLength={12}
                                                                                    onChange={(e) => setFieldValue(`additional_contact.${index}.contact`, e.target.value)}
                                                                                    onBlur={handleBlur}
                                                                                    required={true}
                                                                                />
                                                                                <ErrorMessage
                                                                                    name={`additional_contact.${index}.contact`}
                                                                                    component="div"
                                                                                    className="error-message"
                                                                                />
                                                                            </div>
                                                                            <div className="col-sm-1">
                                                                                <i className="fas fa-times-circle text-danger" onClick={() => remove(index)}></i>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                    {values.additional_contact?.length < 3 && <button
                                                                        type="button"
                                                                        className="btn btn-sm btn-light mb-2"
                                                                        onClick={() => push({ country_code: '+1', contact: '' })}
                                                                    >
                                                                        Add Contact
                                                                    </button>}
                                                                </div>

                                                            )}
                                                        />
                                                    </div>
                                                    <div className="col-sm-6">
                                                        {/* More email input */}
                                                        <FieldArray
                                                            name="additional_mail"
                                                            render={({ push, remove }) => (
                                                                <div >
                                                                    {values.additional_mail?.map((device, index) => (
                                                                        <div className="row g-2" key={index}>
                                                                            <div className="col-sm-11 pb-2">
                                                                                <InputField
                                                                                    className={"did-floating-input"}
                                                                                    classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                                    type="email"
                                                                                    name="email"
                                                                                    label={"Email Address"}
                                                                                    value={device.email}
                                                                                    onChange={(e) => setFieldValue(`additional_mail.${index}.email`, e.target.value)}
                                                                                    onBlur={handleBlur}
                                                                                    required={true}
                                                                                />
                                                                                <ErrorMessage
                                                                                    name={`additional_mail.${index}.email`}
                                                                                    component="div"
                                                                                    className="error-message"
                                                                                />
                                                                            </div>
                                                                            <div className="col-sm-1">
                                                                                <i className="fas fa-times-circle text-danger" onClick={() => remove(index)}></i>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                    {values.additional_mail?.length < 3 && <button
                                                                        type="button"
                                                                        className="btn btn-sm btn-light ms-2 mb-2"
                                                                        onClick={() => push({ email: '' })}
                                                                    >
                                                                        Add Email
                                                                    </button>}
                                                                </div>

                                                            )}
                                                        />
                                                    </div>
                                                </div>



                                                {subUser ? <label className="py-3">Change password</label> : <label className="py-3">Create password</label>}
                                                <div className="col-sm-6">
                                                    <InputField
                                                        className={"did-floating-input"}
                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                        type={passwordType}
                                                        name="password"
                                                        label={"Password"}
                                                        value={values.password}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    <span toggle="#password-field" className={icon} onClick={() => _changeIcon()}></span>

                                                    {errors.password ? (
                                                        <div className="error-message">{errors.password}</div>
                                                    ) : null}
                                                </div>
                                                <div className="col-sm-6">
                                                    <InputField
                                                        className={"did-floating-input"}
                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                        type="password"
                                                        name="confirm_password"
                                                        label={"Confirm Password"}
                                                        value={values.confirm_password}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    {errors.confirm_password ? (
                                                        <div className="error-message">{errors.confirm_password}</div>
                                                    ) : null}
                                                </div>

                                                <div className="col-12 mt-3">
                                                    <label className="pb-2 label">Tell us about yourself</label>
                                                    <div className="form-floating">
                                                        <textarea className="form-control"
                                                            placeholder=""
                                                            name={'description'}
                                                            value={values.description}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            style={{ height: 100 }}></textarea>
                                                    </div>
                                                </div>
                                                <div className="pt-4 pb-4 d-flex justify-content-center">
                                                    <button className="btn btn-primary w-25 p-2" type="submit">Submit</button>
                                                </div>
                                            </div>
                                        </form>
                                    )
                                }
                            </Formik>
                        </div>
                </CModalBody>
            </CModal >
            <Spinner isLoading={HomeStore.loading || UserStore.loading} />
        </>
    )
})
export const Control = (props) => {
    return (
        <>
            <label className={props.hasValue || props.isFocused ? "did-floating-label did-floating-label-top" : "did-floating-label did-floating-label"} style={{ zIndex: 1 }}>{props?.selectProps?.label}</label>
            <components.Control {...props} />
        </>
    );
};

export default SubAccountList;