import React from "react";
import Header from '@components/PDFHeader';
import moment from "moment";


export const HandbookOrientation = (props) => {
  var today = moment(new Date()).format("MM/DD/YYYY hh:mm:ss");
  return (
    <div id="form-content">
      <Header
        title="Acknowledgment of Responsibility for Content of
        Staffers LLC Employee Handbook & New Hire Orientation"
      />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="p-2">
              <span className="mb-2">
                The undersigned employee hereby acknowledges that he/she has received a copy of the Employee Handbook for
                Staffers LLC and has attended our virtual orientation via Zoom
              </span>
              <p>The employee further understands and agrees that:</p>
              <ul>
                <li>
                  <span>
                    The handbook summarizes the rules, regulations, policies and procedures that have been proposed by Staffers
                    LLC and adopted by the facility in which you are employed.
                  </span>
                </li>
                <li>
                  <span>
                    Understand the policies and procedures provided during New
                    Employee Orientation.
                  </span>
                </li>
                <li>
                  <span>
                    We reserve the right to make changes to the employee
                    handbook without prior notice.
                  </span>
                </li>
                <li>
                  <span>
                    The undersigned’s employment relationship with the facility in which the employee is employed and Staffers LLC
                    and its affiliated entities are “at-will” and that either party to that relationship may terminate the employment
                    relationship at any time, for any or no reason, and without notice.
                  </span>
                </li>
              </ul>
              <h4 className="heading-head mt-2 mb-2 text-start">
                Employee Statement{" "}
              </h4>
              <span>
                I acknowledge receipt of the Staffers LLC Employee Handbook and General Orientation and understand that I am
                responsible to read and familiarize myself with its contents. I have read and understood all the above statements and
                I acknowledge that my employment is an “at-will” employment.
              </span>
              <div className="bonus-sec">
                <p className="w-50">
                  <input placeholder="Full Name" type="text" minLength={2} maxLength={20} name="full name" required value={props.inputValues.full_name} onChange={(e) => props.handleInputChange('full_name', e.target.value)} />
                  {/* <span></span> */}
                  <label>Print Name<small className="text-danger">*</small></label>
                </p>

              </div>
              <div className="row justify-content-between align-items-center ">
                <p className="col-6">
                  <span>{props?.signature ? <img src={props?.signature} width={180} height={50} alt="Signature here" /> : <span class="badge badge-primary text-wrap pointer" onClick={props?.onOpenModal}>
                    Click Here To Sign
                  </span>}</span>
                  <hr />
                  <label>Employee Signature<small className="text-danger">*</small></label>
                </p>
                <p className="col-6">
                  <input type="text" placeholder="Sign Date" className="mb-4" value={today} disabled />
                  <hr />
                  <label>Date</label>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
