import React from "react";

const StarRating = ({ stars }) => {
  return (
    <div className="text-center d-flex">
      {[1, 2, 3, 4, 5].map((star) => (
        <span key={star} className="star-icon">
          {star <= stars ? (
            <img
              src="/assets/img/website/star-full-icon.svg"
              className="img-fluid"
              style={{ height: "30px" }}
              alt="filled star"
            />
          ) : (
            <img
              src="/assets/img/website/star-full-icon-white.svg"
              className="img-fluid"
              style={{ height: "30px" }}
              alt="empty star"
            />
          )}
        </span>
      ))}
    </div>
  );
};

export default StarRating;
