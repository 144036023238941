/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Header from '@components/Header';
import { Helmet } from 'react-helmet';
import swal from '@sweetalert/with-react';
import { CFormSwitch } from "@coreui/react";
import 'react-spring-bottom-sheet/dist/style.css';
import StaffsStore from "@store/StaffsStore";
import { toJS } from 'mobx';
import { observer } from "mobx-react"
import Spinner from "@components/Spinner";
import UserStore from '@store/UserStore';
import secureLocalStorage from 'react-secure-storage';


const AlertsSettingsPage = observer(() => {
    window.onscroll = function () { };
    const [email, setEmail] = useState(false);
    const [sms, setSMS] = useState(false);
    const [userType, setUserType] = useState();

    useEffect(() => {
        let userType = secureLocalStorage.getItem("type");
        setUserType(userType);
        if (userType === 'false') {
            UserStore.getAlert();
        }
        if (userType === 'true') {
            StaffsStore.getAlert();
        }
    }, [])

    const handleChangeEmail = (event) => {
        let param = {
            sms_alert: sms === true ? 1 : 0,
            email_alert: event.target.checked === true ? 1 : 0,
        }
        if (userType === 'false') {
            UserStore.updateAlert(param, navigationCallBack)
        }
        if (userType === 'true') {
            StaffsStore.updateAlert(param, navigationCallBack)
        }
    }
    const handleChangeSMS = (event) => {
        let param = {
            sms_alert: event.target.checked === true ? 1 : 0,
            email_alert: email === true ? 1 : 0
        }
        if (userType === 'false') {
            UserStore.updateAlert(param, navigationCallBack)
        }
        if (userType === 'true') {
            StaffsStore.updateAlert(param, navigationCallBack)
        }
    }

    useEffect(() => {
        if (userType === 'true') {
            setEmail(toJS(StaffsStore.data.myAlert)?.mail_notify === 1)
            setSMS(toJS(StaffsStore.data.myAlert)?.sms_notify === 1)
        }
    }, [StaffsStore?.data?.myAlert]);

    useEffect(() => {
        if (userType === 'false') {
            setEmail(toJS(UserStore.data.myAlert)?.mail_notify === 1)
            setSMS(toJS(UserStore.data.myAlert)?.sms_notify === 1)
        }
    }, [UserStore?.data?.myAlert]);

    const navigationCallBack = () => {
        if (userType === 'false') {
            UserStore.getAlert();
            swal("Updated!", "Your notification has been updated successfully", "success");
        }
        if (userType === 'true') {
            StaffsStore.getAlert();
            swal("Updated!", "Your notification has been updated successfully", "success");
        }
    }

    return (
        <React.Fragment>
            <Header />
            <Helmet>
                <title>Emails & SMS alerts</title>
            </Helmet>
            <>
                <div className="container-fluid callback-small mt-5 py-5">
                    <div className="container pt-lg-5">
                        <h5 className="display-5 mb-4 animated slideInDown login-head">Email & SMS Notification</h5>
                    </div>
                    <div className="col-lg-12">
                        <div className="container">
                            <div className="row wow bounceInUp d-flex justify-content-center">
                                <div className="col-sm-6 col-md-6 col-lg-6">
                                    <div className="card bg-white p-3 mb-4 shadow">
                                        {/* <div className="d-flex justify-content-between mt-2">
                                            <div>
                                                <h5 className="mb-0">Newsletter<br />  </h5>
                                                <small className="text-muted ml-1">Receive our newsletter emails to stay up to date.</small>
                                            </div>
                                            <CFormSwitch size="xl" id="formSwitchCheckDefaultXL" />
                                        </div> */}
                                        {/* <div className="dropdown-divider"></div> */}
                                        <div className="d-flex justify-content-between mt-2">
                                            <div>
                                                <h5 className="mb-0">Email Notification<br />  </h5>
                                                <small className="text-muted ml-1">Receive email notifications about new openings, assigned shifts, shift confirmations/cancellations and reminders!</small>
                                            </div>
                                            <CFormSwitch size="xl" id="email"
                                                name="email"
                                                checked={email}
                                                onChange={handleChangeEmail}
                                            />
                                        </div>
                                        <div className="dropdown-divider"></div>
                                        <div className="d-flex justify-content-between mt-2">
                                            <div className='w-75'>
                                                <h5 className="mb-0">SMS Notification<br />  </h5>
                                                <small className="text-muted ml-1">Receive sms notifications about new openings, assigned shifts, shift confirmations/cancellations and reminders!</small>
                                            </div>
                                            <CFormSwitch size="xl" id="sms"
                                                name="sms"
                                                checked={sms}
                                                onChange={handleChangeSMS}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Spinner isLoading={StaffsStore.loading || UserStore.loading} />

                </div>


            </>

        </React.Fragment>
    )
})

export default AlertsSettingsPage;