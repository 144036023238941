/* eslint-disable */
import React, { useEffect } from "react";
import { observer } from "mobx-react"
import BaseLayout from "@components/BaseLayout";
import HomeStore from '@store/HomeStore';
import Jumbotron from "@components/Jumbotron";


const HomePage = observer(() => {
    window.onscroll = function () { };
   
    return (
        <>
            <BaseLayout title={'Staffers LLC | Home'}>
                <Jumbotron />
                {/* <AboutUs /> */}
                {/* <Testimonials /> */}
                {/* <Faq /> */}
            </BaseLayout>
        </>
    );
});

export default HomePage;
