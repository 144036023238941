import React from "react";
import Header from '@components/PDFHeader';
import { Formik, FieldArray, Field, Form, ErrorMessage } from 'formik';
import { referenceSchema } from '@utils/schemas/allSchema';
import { colourStylesCountry } from '@utils/customStyle/style';
import { InputField } from "@elements/index"
import Select, { components } from 'react-select';
import { observer } from "mobx-react"
var data = require('@assets/CountryCodes.json');

export const Reference = observer((props) => {
  return (
    <div>
      <Header />
      <div className=" agreement-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12 ">
              <div>
                <Formik
                  initialValues={{
                    references: props.referencesData?.length === 0 ? [{ name: '', email: '', designation: '', country_code: '', contact: '', organization_name: '', response: '' }] : props.referencesData,
                  }}
                  validationSchema={referenceSchema}
                  onSubmit={(values, { resetForm }) => {
                    // Handle form submission here
                    props.saveReference(values);
                  }}
                  enableReinitialize
                  validateOnChange={false}
                >
                  {({ handleChange, handleBlur, setFieldValue, values, errors }) => (

                    <Form>
                      <div>
                        <FieldArray
                          name="references"
                          render={arrayHelpers => (
                            <div>
                              {values.references.map((org, index) => (
                                <div key={index}>
                                  <h3 className="text-center">{index === 0 ? "First" : index === 1 ? "Second" : "Third"} Reference</h3>
                                  <div className="card p-2">
                                    <div className="row">
                                      {index > 0 && (
                                        <div className="d-flex justify-content-end me-2 mb-2">
                                          <i className="fas fa-times-circle text-danger pointer" onClick={() => { arrayHelpers.remove(index); props.onDelete(org?.id) }}></i>
                                        </div>
                                      )}
                                      <div className="col-sm-6 mt-2 mb-2">
                                        <InputField
                                          className={"did-floating-input"}
                                          classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                          type="text"
                                          name={`references[${index}].name`}
                                          label={"Full Name"}
                                          value={org.name}
                                          onChange={handleChange}
                                          required={true}
                                          onBlur={handleBlur}
                                        />
                                        {errors.name ? (
                                          <div className="error-message">{errors.name}</div>
                                        ) : null}
                                      </div>
                                      <div className="col-sm-6 mb-2">
                                        <InputField
                                          className={"did-floating-input"}
                                          classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                          type="text"
                                          name={`references[${index}].designation`}
                                          label={"Job Title"}
                                          value={org.designation}
                                          onChange={handleChange}
                                          required={true}
                                          onBlur={handleBlur}
                                        />
                                        {errors?.designation ? (
                                          <div className="error-message">{errors.designation}</div>
                                        ) : null}
                                      </div>
                                      <div className="col-sm-6 mb-2">
                                        <InputField
                                          className={"did-floating-input"}
                                          classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                          type="email"
                                          name={`references[${index}].email`}
                                          label={"Email Address"}
                                          value={org.email}
                                          onChange={handleChange}
                                          required={true}
                                          onBlur={handleBlur}
                                        />
                                        {errors?.email ? (
                                          <div className="error-message">{errors.email}</div>
                                        ) : null}
                                      </div>
                                      <div className="col-sm-2 mb-2">
                                        <Select
                                          onChange={(e) => setFieldValue(`references.${index}.country_code`, e.label)}
                                          placeholder={''}
                                          label={'Country'}
                                          styles={colourStylesCountry}
                                          isSearchable={true}
                                          options={data}
                                          defaultValue={{ label: "+1", value: 'CA' }}
                                          components={{ Control, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                        />
                                      </div>
                                      <div className="col-sm-4 mb-2">
                                        <InputField
                                          className={"did-floating-input"}
                                          classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                          type="text"
                                          name={`references[${index}].contact`}
                                          label={"Contact"}
                                          value={org.contact}
                                          onChange={handleChange}
                                          required={true}
                                          onBlur={handleBlur}
                                        />
                                        {errors?.references && errors?.references?.length != 0 && errors?.references[index] ? (
                                          <div className="error-message">{errors?.references[index]?.contact}</div>
                                        ) : null}
                                      </div>
                                      <div className="col-sm-12 mb-2">
                                        <InputField
                                          className={"did-floating-input"}
                                          classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                          type="text"
                                          name={`references[${index}].organization_name`}
                                          label={"Organization Name"}
                                          value={org.organization_name}
                                          onChange={handleChange}
                                          required={true}
                                          onBlur={handleBlur}
                                        />
                                        {errors.organization_name ? (
                                          <div className="error-message">{errors.organization_name}</div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}

                              {values.references?.length < 3 && <div className="row">
                                <div className="col-md-12">
                                <button
                                    type="button"
                                    className="btn btn-link"
                                    onClick={() =>
                                      arrayHelpers.push({
                                        name: '', email: '', designation: '', country_code: '', contact: '', organization_name: '', response: ''
                                      })
                                    }
                                  >
                                     <u> + Add Reference</u>
                                  </button>
                                </div>
                              </div>}

                            </div>
                          )}
                        />
                        {
                          !Array.isArray(errors?.references) && errors?.references ?
                            <div className="error-message mt-2 mb-2"><i className="fas fa-exclamation-triangle"></i> {errors?.references}</div> : null
                        }
                        <div className="d-flex justify-content-center mt-1">
                          <button className="btn btn-outline-primary" type="submit">Submit & Next</button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
export const Control = (props) => {
  return (
    <>
      <label className={props.hasValue || props.isFocused ? props?.selectProps?.isRequired ? "did-floating-label did-floating-label-top did-floating-labe-astrisk" : "did-floating-label did-floating-label-top" : props?.selectProps?.isRequired ? "did-floating-label did-floating-label did-floating-labe-astrisk" : "did-floating-label did-floating-label"} style={{ zIndex: 100 }}>{props?.selectProps?.label}</label>
      <components.Control {...props} />
    </>
  );
};