import { configure, makeAutoObservable, runInAction } from "mobx";
import { axiosInstanceInt, setAuthToken } from "../api/axiosInstance";
import { toast } from "react-toastify";
import swal from "@sweetalert/with-react";

class WebsiteStore {
  loading = false;
  loadingApply = false;
  data = {
    total: 0,
    from: 0,
    to: 0,
    degree: "",
    page: 1,
    lastPage: null,
    pageSize: 25,
    sort_by: 1,
    location: "",
    miles: 40, //default
    keyword: "",
    contract_type: "",
    exclusive: 0, //default
    jobsList: [],
    page_id: null,
    pageData: [],
    featuredLocations: [],
    featuredReviews: [],
    caregiversList: [],
    globalSearchList: [],
    jobDetails: null,
    storedJobId: 0,
  };
  errors = {};
  constructor() {
    makeAutoObservable(this);
    configure({
      useProxies: "never",
    });
  }
  toggleLoading = (toggle) => {
    this.loading = toggle;
  };
  toggleLoadingApply = (toggle) => {
    this.loadingApply = toggle;
  };
  resetSearch() {
    this.data.degree = "";
    this.data.location = "";
    this.data.keyword = "";
    this.data.exclusive = 0;
    this.data.contract_type = "";
    this.data.miles = 40;
  }

  async getPageDetails() {
    setAuthToken(global.config.token);
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.get(
        `getPageDetails?page_id=${this.page_id}`
      );
      if (response) {
        runInAction(() => {
          this.data.pageData = response?.data?.data;
          this.toggleLoading(false);
        });
      } else {
        console.log(response);
        this.toggleLoading(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.toggleLoading(false);
    }
  }
  async getFeaturedLocations() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.get(`/featuredLocations`);
      if (response) {
        runInAction(() => {
          this.data.featuredLocations = response?.data?.data;
          this.toggleLoading(false);
        });
      } else {
        console.log(response);
        this.toggleLoading(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.toggleLoading(false);
    }
  }
  async getFeaturedReviews() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.get(`/getReviews`);
      if (response) {
        runInAction(() => {
          this.data.featuredReviews = response?.data?.data;
        });
      } else {
        console.log(response);
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.toggleLoading(false);
    }
  }
  async getJobs() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.get(
        `/jobSearch?page=${this.data.page}&per_page=${
          this.data.pageSize
        }&sort_by=${this.data.sort_by}&location=${this.data.location}&miles=${
          this.data.miles
        }&keyword=${this.data.keyword}&contract_type=${
          this.data.contract_type || ""
        }&exclusive=${this.data.exclusive}`
      );
      if (response) {
        console.log("response from getJobs", response);
        runInAction(() => {
          this.data.page = response?.data?.data?.current_page || 1;
          this.data.lastPage = response?.data?.data?.last_page || 0;
          this.data.pageSize = response?.data?.params?.per_page || 25;
          this.data.total = response?.data?.data?.total;
          this.data.from = response?.data?.data?.from;
          this.data.to = response?.data?.data?.to;
          this.data.sort_by = response?.data?.params?.sort_by || "";
          this.data.location = response?.data?.params?.location || "";
          this.data.miles = response?.data?.params?.miles;
          this.data.keyword = response?.data?.params?.keyword || "";
          this.data.contract_type = response?.data?.params?.contract_type || "";
          this.data.exclusive = response?.data?.params?.exclusive || "";
          this.data.jobsList = response?.data?.data?.data;
        });
      } else {
        console.log(response);
      }
    } catch (err) {
      console.log("logineere", err);
    } finally {
      this.toggleLoading(false);
    }
  }
  async getJobDetails(jobId) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.get(`/getJobDetails?id=${jobId}`);
      if (response) {
        console.log("response from getJobDetails", response);
        // Assuming the response structure is similar to getJobs
        runInAction(() => {
          // Update your data properties with the received job details
          this.data.jobDetails = response.data.data;
        });
      } else {
        console.log(response);
      }
    } catch (err) {
      console.log("Error fetching job details:", err);
    } finally {
      this.toggleLoading(false);
    }
  }

  async getCaregivers() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.get(
        `/caregiverSearch?page=${this.data.page}&per_page=${
          this.data.pageSize
        }&location=${this.data.location || ""}&degree=${
          this.data.degree || ""
        }&keyword=${this.data.keyword}`
      );
      if (response) {
        console.log("response from getCaregivers", response);
        runInAction(() => {
          this.data.degree = response?.data?.params?.degree || "";
          this.data.page = response?.data?.data?.current_page;
          this.data.lastPage = response?.data?.data?.last_page || 0;
          this.data.pageSize = response?.data?.data?.per_page;
          this.data.total = response?.data?.data?.total;
          this.data.from = response?.data?.data?.from;
          this.data.to = response?.data?.data?.to;
          this.data.location = response?.data?.params?.location || "";
          this.data.keyword = response?.data?.params?.keyword || "";
          this.data.caregiversList = response?.data?.data?.data;
        });
      } else {
        console.log(response);
      }
    } catch (err) {
      console.log("logineere", err);
    } finally {
      this.toggleLoading(false);
    }
  }

  async postenquiry(data, resetForm, callback) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post("/saveEnquiry", data);
      if (response) {
        callback(resetForm);
      } else {
        //
      }
    } catch (err) {
      if (err.response.status !== 500) {
        toast.error(Object.values(err?.response?.data?.message)[0][0]);
      } else {
        toast.error("Something went to wrong!");
      }
    } finally {
      this.toggleLoading(false);
    }
  }
  async postReview(data, resetForm, callback) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post("/saveReview", data);
      if (response) {
        swal("Success!", "Thank you for getting in touch.");
        callback(resetForm);
      } else {
        //
      }
    } catch (err) {
      if (err.response.status !== 500) {
        toast.error(Object.values(err?.response?.data?.message)[0][0]);
      } else {
        toast.error("Something went to wrong!");
      }
    } finally {
      this.toggleLoading(false);
    }
  }
  async postGlobalSearch(data) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post("/globalSearch", data);
      if (response) {
        this.data.globalSearchList = response.data.data;
      } else {
        //
      }
    } catch (err) {
      if (err.response.status !== 500) {
        toast.error(Object.values(err?.response?.data?.message)[0][0]);
      } else {
        toast.error("Something went to wrong!");
      }
    } finally {
      this.toggleLoading(false);
    }
  }
}

export default new WebsiteStore();
