/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
import { observer } from "mobx-react"
import HomeStore from "@store/HomeStore";
import Skeleton from 'react-loading-skeleton';

const Footer = observer(() => {
    const [isAuthenticated, setAuthenticated] = useState(null);
    const [role, setRole] = useState();

    useEffect(() => {
        let token = secureLocalStorage.getItem("token");
        setAuthenticated(token);
        let userType = secureLocalStorage.getItem("type");
        setRole(userType);
    }, []);
    return (
        <footer>
            <div className="container-fluid linear text-light footer mt-0 py-0 wow fadeIn" data-wow-delay="0.03s">
                <div className="container pb-2 py-5">
                    <div className="row g-5">
                        <div className="col-lg-3 col-md-6">
                            <h4 className="text-white mb-4">STAFFERS</h4>
                            <p className="text-nowrap">“{HomeStore?.data?.dashboardData?.find(x => x.key_name === 'Moto')?.value || <Skeleton className="info-text" height={17} width={200} />}”</p>
                            {/* <div className="position-relative w-100">
                                <input className="form-control bg-white border-0 w-100 py-3 ps-4 pe-5" type="text"
                                    placeholder="Your email" />
                                <button type="button"
                                    className="btn btn-primary py-2 position-absolute top-0 end-0 me-2"style={{marginTop:5}}>Subscribe</button>
                            </div> */}
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <h4 className="text-white mb-4">Links</h4>
                            {/* {(role == 'false' || !isAuthenticated) && <a className="btn btn-link" href="/search-caregivers">Search Caregiver</a>} */}
                            {/* {(role == 'true' || !isAuthenticated) && <a className="btn btn-link" href="/search-jobs">Search Job</a>} */}
                            <a className="btn btn-link" href="/howdoesitwork">How Does It Work?</a>
                            <a className="btn btn-link" target="_blank" href={HomeStore?.data?.dashboardData?.find(x => x.key_name === 'Official Youtube')?.value}>Help Videos</a>
                            {/* <a className="btn btn-link" onClick={() => onFeedback()}>Feedback</a> */}
                        </div>
                        {isAuthenticated && <div className="col-lg-2 col-md-6">
                            <h4 className="text-white mb-4">Documentation</h4>
                            {role == 'true' && <a className="btn btn-link" download="Staffers Caregivers User Manual.pdf" href="/documentation/Staffers-Caregivers-User-Manual.pdf">Caregiver Guide</a>}
                            {role == 'false' && <a className="btn btn-link" download="Staffers Facility User Manual.pdf" href="/documentation/Staffers-Employer-User-Manual.pdf">Facility User Guide</a>}
                            {/* <a className="btn btn-link" href="/howdoesitwork">Admin Guide</a> */}
                        </div>}
                        {/* <div className="col-lg-2 col-md-6">
                            <h4 className="text-white mb-4">Our Policies</h4> */}
                        {/* <a className="btn btn-link" href="">Our Story</a> */}
                        {/* <a className="btn btn-link" href="">Comunity Driven</a> */}
                        {/* <a className="btn btn-link" href="">Our Customer</a> */}
                        {/* <a className="btn btn-link" href={HomeStore?.data?.dashboardData?.find(x => x.key_name === 'Privacy Policy')?.value || "https://staffers.us/privacy-policy/"} target="_blank">Privacy & Policy</a>
                            <a className="btn btn-link" href={HomeStore?.data?.dashboardData?.find(x => x.key_name === 'Terms And Conditions')?.value || "https://staffers.us/terms-and-conditions/"} target="_blank">Terms & Conditions</a>

                        </div> */}

                        <div className="col-lg-3 col-md-6">
                            <h4 className="text-white mb-4">Get In Touch</h4>
                            {/* <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>info@staffers.com</p> */}
                            <a className="btn btn-link-custom" href={`tel:${HomeStore?.data?.dashboardData?.find(x => x.key_name === 'Official Contact')?.value}`}>
                                <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>{HomeStore?.data?.dashboardData?.find(x => x.key_name === 'Official Contact')?.value || '(801) 820-0788'}</p>
                            </a>
                            <a className="btn btn-link-custom" href={`mailto:${HomeStore?.data?.dashboardData?.find(x => x.key_name === 'Official E-Mail')?.value}`}>
                                <p className="mb-2" style={{ textTransform: "none" }}><i className="fa fa-envelope me-3"></i>{HomeStore?.data?.dashboardData?.find(x => x.key_name === 'Official E-Mail')?.value || "staffers@staffers.us"}</p>
                            </a>
                            <div className="d-flex pt-2 mb-2">

                                <a className="btn btn-square btn-outline-light rounded-circle me-2 social-btn" target="_blank" href={HomeStore?.data?.dashboardData?.find(x => x.key_name === 'Facebook Link')?.value || "https://www.facebook.com/staffersllc/"}><i
                                    className="fab fa-facebook-f" style={{ paddingLeft: 1, paddingRight: 1 }}></i></a>

                                <a className="btn btn-square btn-outline-light rounded-circle me-2 social-btn" target="_blank" href={HomeStore?.data?.dashboardData?.find(x => x.key_name === 'Linkedin Link')?.value || "https://www.linkedin.com/company/staffersinc?trk=public_jobs_topcard-org-name"}><i
                                    className="fab fa-linkedin-in"></i></a>
                                <a className="btn btn-square btn-outline-light rounded-circle me-2 social-btn" target="_blank" href={HomeStore?.data?.dashboardData?.find(x => x.key_name === 'Official Youtube')?.value || "https://www.youtube.com/channel/UCbHMLDnTQibj7QNb0v2L8jQ"}>
                                    <i className="fab fa-youtube-square"></i></a>
                            </div>
                            {!isAuthenticated && window.location.pathname != '/admin-login' && <div className="d-flex pt-2">
                                <NavLink className="btn btn-link " to='../admin-login'><label className='admin-link'>Are you an admin?</label></NavLink>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid copyright py-4 ">
                <div className="container ">
                    <div className="row" >
                        <div className="text-center mb-3 mb-md-0 ">
                            <a href="#">{HomeStore?.data?.dashboardData?.find(x => x.key_name === 'Site Name & Year')?.value || <Skeleton className="info-text" height={17} width={200} />}</a>
                        </div>

                    </div>
                </div>
            </div>
            <a href="#" className="back-to-top "><i className="fas fa-chevron-up text-white"></i></a>
            <div className='mb-4 mb-lg-0 mb-md-0'>
            </div>
        </footer>
    );
})

export default Footer;