import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react"
import Multiselect from 'multiselect-react-dropdown';
import HomeStore from '../store/HomeStore';
import StaffsStore from '../store/StaffsStore';
import UserStore from '../store/UserStore';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
import { InputField } from "@elements/index";
import { CButton, CModal, CModalBody, CModalHeader, CModalTitle } from "@coreui/react";
import swal from '@sweetalert/with-react';
import { Images } from "../elements";
import { toJS } from 'mobx';
import DatePicker from "react-multi-date-picker"
import ShiftRequestsCalendar from '../pages/employer/ShiftRequestsCalendar';
import CategoryItems from "@components/category/CategoryItems";
var citiesData = require('@assets/city.json');
var states = require('@assets/states.json');
const onlyAlphabets = /^[A-Za-z ]+$/;
const Jumbotron = observer(() => {
    const navigate = useNavigate();
    const [degree, setDegree] = useState([]);
    const [specialties, setSpecialties] = useState([]);
    const [error, setError] = useState('');
    const [selectedDegree, setSelectedDegree] = useState();
    const [selectedName, setSelectedName] = useState();
    const [selectedSpecialties, setSelectedSpecialties] = useState();
    const [selectedLocations, setSelectedLocations] = useState();
    const [selectedCity, setSelectedCity] = useState([]);
    const [selectedRating, setSelectedRating] = useState(null);
    const [selectedState, setSelectedState] = useState();
    const [imported, setImported] = useState();

    const specialtiesRef = React.createRef();
    const [isAuthenticated, setAuthenticated] = useState(null);
    const [role, setRole] = useState();
    const [userStaffID, setUserStaffID] = useState();
    const [submitted, setSubmitted] = useState(false);
    const [rating, setRating] = useState();
    const [feedback, setFeedback] = useState("");
    const [requestData, setRequestData] = useState();
    const [unRatedShift, setUnratedShift] = useState([]);
    const [traveller, setTraveller] = useState(0);
    const [cityList, setCityList] = useState([]);
    const [signPopUp, setSignPopUp] = useState(false);
    const [eligibleFacility, setEligibleFacility] = useState([]);
    const [selectedFacilities, setSelectedFacilities] = useState([]);

    const [range, setRange] = useState()

    useEffect(() => {
        let token = secureLocalStorage.getItem("token");
        setAuthenticated(token);
        let userType = secureLocalStorage.getItem("type");
        setRole(userType);
        let userData = secureLocalStorage.getItem("userData");
        if (userData !== 'undefined') {
            setUserStaffID(JSON?.parse(userData)?.bluesky_staff_id);
            setImported(JSON?.parse(userData)?.is_imported)
        }
        if (userType == "true" && userData && JSON?.parse(userData)?.bluesky_staff_id) {
            StaffsStore.getEligibleFacility();
            HomeStore.getCaregiverDetails(JSON?.parse(userData)?.bluesky_staff_id)?.then((data) => {
                StaffsStore.updateProfileStatus({ "status_id": data?.DelFl });
            });
        }
        //THIS IF FOR RATING 
        if (userType == "false" && unRatedShift?.length === 0) {//Client
            UserStore.getUnratedShift();
        }
    }, []);
    useEffect(() => {
        setEligibleFacility(StaffsStore?.data?.eligibleFacility)
        let selectedFacility = secureLocalStorage.getItem("selectedFacilites")
        if (selectedFacility && toJS(StaffsStore?.data?.eligibleFacility)?.length > 0) {
            let facilitiesIds = JSON.parse(selectedFacility);
            setSelectedFacilities(facilitiesIds)
        }
    }, [StaffsStore?.data?.eligibleFacility]);
    //THIS IF FOR ONBOARDING POPUP 
    useEffect(() => {        
        if (HomeStore?.data?.dashboardData?.find(x => x.key_name === 'Onboarding Sign Required?')?.value === 'Yes') {
            setSignPopUp((StaffsStore?.data?.signedDocumentsList?.filter(x => x.document_id !== 77)?.length === 0) && imported === 0 && userStaffID)
        } else {
            setSignPopUp(false)
        }
    }, [StaffsStore?.data?.signedDocumentsList]);

    useEffect(() => {
        let closedAssignmentData;
        let finalSlots;
        let feedbackData;
        let contractList = toJS(UserStore?.data?.unRatedShift)?.contracts;
        let shiftList = toJS(UserStore?.data?.unRatedShift)?.data;
        if (contractList?.length > 0) {
            contractList = contractList?.map(item => {
                if (item.feedbacks?.find(y => y.shift_id === item.id && y.staff_id === item.staff_id)) {
                    return
                } else {
                    return item;
                }
            });
        }
        if (shiftList?.length > 0) {
            closedAssignmentData = shiftList?.flatMap(item => item.closed_assignments || []);
            feedbackData = shiftList?.flatMap(item => item.feedbacks || []);
            finalSlots = [...new Map(closedAssignmentData?.map(item => [item.staff_id, item])).values()];
            finalSlots = finalSlots?.map(item => {
                if (feedbackData?.find(y => y.shift_id === item.shift_id && y.staff_id === item.staff_id)) {
                    return
                } else {
                    return item;
                }
            });
        }
        setUnratedShift(contractList?.filter(item => item !== undefined)?.concat(finalSlots?.filter(item => item !== undefined)))
    }, [UserStore?.data?.unRatedShift]);

    useEffect(() => {
        setDegree(HomeStore?.data?.degreeList)
    }, [HomeStore?.data?.degreeList]);


    const navigationCallBack = () => {
        UserStore.getStaffingRequest();
        swal("Thank you for your review!", `We received ${rating}/5 rating from you.`, "success").then(() => {
            setFeedback()
            setRating()
            setSubmitted(false)
        })
    }

    useEffect(() => {
        if (role == 'false' && submitted === true) {
            let param = {
                shift_id: requestData?.category === 'TC' ? requestData.id : requestData.shift_id,
                staff_id: requestData.staff_id,
                user_rating: rating,
                user_comment: feedback,
            }
            UserStore.saveFeedback(param, navigationCallBack);
        }
    }, [submitted]);

    useEffect(() => {
        let checkClosed = localStorage.getItem("rating")
        if ((unRatedShift && unRatedShift?.length !== 0 && !unRatedShift.includes(undefined)) && (checkClosed !== 'false')) {
            setRequestData(unRatedShift[0])
            onFeedback(unRatedShift[0])
        }
    }, [unRatedShift]);


    const handleChange = (event) => {
        setFeedback(event.target.value);
    };
    const onPick = (value) => {
        setRating(value);
    }

    const onChangeLocations = (value) => {
        setSelectedLocations(value);
    };

    const onSearch = () => {
        navigate('/staffs', { state: { name: selectedName, degree: selectedDegree, specialties: selectedSpecialties, traveller: traveller, rating: selectedRating, states: selectedState, city: selectedCity } });
    }

    const onSearchJob = () => {
        navigate('/jobs', { state: { degree: selectedDegree, specialties: selectedSpecialties, location: selectedLocations } });
    }
    const onSearchShift = () => {
        let filterDate = []
        if (range?.length === 2) {
            filterDate = [range[0]?.format(), range[1]?.format()]
        } else if (range?.length === 1) {
            filterDate = [range[0]?.format()]
        }

        navigate('/available-shifts', { state: { facility: selectedFacilities, range: filterDate } });
    }

    const gotoMyJobProfile = () => {
        StaffsStore.getStaffProfile().then((data) => {
            secureLocalStorage.setItem("userData", JSON.stringify(data));
            if (data?.bluesky_staff_id) {
                setUserStaffID(data?.bluesky_staff_id)
                navigate('/')
            } else {
                navigate('/my-job-profile')
            }
        })
    }
    const onFeedback = async (shiftData) => {
        swal({
            title: "Feedback/Rating",
            buttons: [true, "Submit"],
            content: (
                <div className='row' style={{ textAlign: 'start' }}>
                    <div className="">
                        <label className='mb-2'>Please rate your experience for your most recent shift #<strong className="text-success">{shiftData?.category === 'TC' ? shiftData?.id : shiftData?.shift_id}</strong> with <br /> <strong className='text-success fs-5 '>{shiftData?.staff?.title} {shiftData?.staff?.first_name} {shiftData?.staff?.last_name}</strong>.</label>
                        <br />
                        <div className="wrapper">
                            <input type="radio" onClick={() => onPick(1)} value="1" name="rate" id="star-1" style={{ display: "none" }} />
                            <input type="radio" onClick={() => onPick(2)} value="2" name="rate" id="star-2" style={{ display: "none" }} />
                            <input type="radio" onClick={() => onPick(3)} value="3" name="rate" id="star-3" style={{ display: "none" }} />
                            <input type="radio" onClick={() => onPick(4)} value="4" name="rate" id="star-4" style={{ display: "none" }} />
                            <input type="radio" onClick={() => onPick(5)} value="5" name="rate" id="star-5" style={{ display: "none" }} />
                            <div className="content">
                                <div className="outer">
                                    <div className="emojis">
                                        <li className="slideImg"><img src={Images?.think} style={{
                                            width: "90%",
                                            height: "100%",
                                            paddingTop: 10
                                        }} alt="" /></li>
                                        <li ><img src={Images?.hate} alt="" /></li>
                                        <li><img src={Images?.unlike} alt="" /></li>
                                        <li><img src={Images?.like} alt="" /></li>
                                        <li><img src={Images?.awesome} alt="" /></li>
                                        <li><img src={Images?.love} alt="" /></li>
                                    </div>
                                </div>
                                <div className="stars">
                                    <label htmlFor="star-1" className="star-1 fas fa-star pointer"></label>
                                    <label htmlFor="star-2" className="star-2 fas fa-star pointer"></label>
                                    <label htmlFor="star-3" className="star-3 fas fa-star pointer"></label>
                                    <label htmlFor="star-4" className="star-4 fas fa-star pointer"></label>
                                    <label htmlFor="star-5" className="star-5 fas fa-star pointer"></label>
                                </div>
                            </div>
                            <div className="footer">
                                <span className="text"></span>
                                {/* <span className="numb"></span> */}
                            </div>
                        </div>
                    </div>
                    <div className='mt-5'>
                        <label className='mb-3'>Share your thoughts about our shift service.</label>
                        <div className="col-12">
                            <div className="">
                                <textarea
                                    className="form-control"
                                    required
                                    placeholder="Write something"
                                    name={'feedback'}
                                    onChange={handleChange}
                                    style={{ height: 100 }}></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            )

        })
            .then((value) => {
                if (value) {
                    setSubmitted(true)
                } else {
                    setRating()
                    setFeedback()
                    setRequestData()
                    localStorage.setItem("rating", 'false')
                    setTimeout(() => localStorage.removeItem('rating'), 2 * 60 * 60 * 1000);
                }
            });
    }

    //Search codes
    const handleChangeName = (event) => {

        if (onlyAlphabets?.test(event.target.value) || event.target.value === '') {
            let value = event.target.value;
            setSelectedName(value)
            setError('');
        } else {
            setError('Only alphabets are allowed');
        }

    };

    const onChangeDegree = (value) => {
        setSelectedDegree(value);
        specialtyLoad(value);
    };

    const onChangeDegreeRemove = (event) => {
        setSelectedDegree(event);
        setSpecialties(specialties?.filter(item => event?.find(data => data.Id === item.DegreeID)));
        setSelectedSpecialties(selectedSpecialties?.filter(item => event.find(data => data.Id === item.DegreeID)))
    };
    const specialtyLoad = (value) => {
        if (value?.length > 0) {
            value?.map(async (item) => {
                if (!specialties.find(x => x.DegreeID == item.Id)) {
                    await HomeStore.getSpecialtiesListData(item.Id).then((spec) => {
                        if (spec?.length != 0) {
                            setSpecialties([...specialties, ...spec])
                        }
                    })
                }
            })
        }
    }
    const onChangeSpecialties = (value) => {
        setSelectedSpecialties(value);
    };
    const onChangeSpecialtiesRemove = (value) => {
        setSelectedSpecialties(value);
    };
    const onChangeState = (value) => {
        setCityList(citiesData?.filter(x => value?.find(y => y.abbreviation === x.state_id)))
        setSelectedFacilities(value)
    };
    const onChangeFacility = (value) => {
        secureLocalStorage.setItem("selectedFacilites", JSON.stringify(value));
        setSelectedFacilities(value)
    };
    const onChangeStateRemove = (value) => {
        setSelectedState(value);
        setSelectedCity("");
        setCityList(citiesData?.filter(x => value?.find(y => y.abbreviation === x.state_id)))
        if (selectedCity && selectedCity?.length != 0) {
            setSelectedCity(selectedCity?.filter(item => value?.find(data => data.abbreviation === item.state_id)))
        }
    }

    const onChangeCity = (value) => {
        setSelectedCity(value);
    };

    const onChangeCityRemove = (value) => {
        setSelectedCity(value)
    }

    const onChangeRating = (value) => {
        setSelectedRating(value);
    };

    return (
        <>
            {(isAuthenticated && role == 'false') ?
                <ShiftRequestsCalendar />
                : <div>
                    <div className="container-fluid p-0 mb-5 wow fadeIn" data-wow-delay="0.03s">
                        <div id="header-carousel" className="carousel slide carousel-fade" data-bs-ride="carousel">
                            <div className="carousel-inner carousel-radius">
                                <div className="carousel-item active">
                                    <img className="w-100" src="assets/img/carousel-1.png" alt="banner" />
                                    <div className="carousel-caption">
                                        <div className="container">
                                            <div className="row justify-content-start">
                                                <div className="col-lg-8 pt-5">
                                                    <p
                                                        className="display-6 d-inline-block text-light py-1 px-0 animated slideInDown">
                                                        {HomeStore?.data?.dashboardData?.find(x => x.key_name === 'Banner H1')?.value || "COMING TO THE RESCUE"}</p>
                                                    <h6 className="display-5 mb-4 animated slideInDown text-light">{HomeStore?.data?.dashboardData?.find(x => x.key_name === 'Banner H2')?.value || "Welcome to Staffers Community!"}</h6>
                                                    <small className="mb-4 animated slideInDown text-light">{HomeStore?.data?.dashboardData?.find(x => x.key_name === 'Banner H3')?.value || "Staffers provides a technology platform to connect high quality clinicians with open shifts in their area. Facilities get the help they need to manage flexible, temporary, and contract labor exclusive to the healthcare industry. Our unique marketplace allows facilities to post shifts and enables caregivers to work those shifts easily. Sign up today and start working tomorrow!"}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Job Search */}
                    {(isAuthenticated && role == 'true') && !userStaffID && <div className="container float-search">
                        <div className="shadow border rounded p-4 p-sm-2 wow fadeInUp" style={{ backgroundColor: "#f6e8ff" }} data-wow-delay="0.03s">
                            <div className="row">
                                <div className="col-lg-12 col-12 pt-2 pb-3 ps-3">
                                    <b className="">SEARCH JOB</b>
                                </div>
                                <div className="col-lg-2 wow fadeIn" data-wow-delay="0.03s">
                                    <div className="ps-3 mb-3">
                                        <Multiselect
                                            placeholder="State"
                                            className="dropdown-multi"
                                            onSelect={onChangeState}
                                            options={states} // Options to display in the dropdown
                                            selectedValues={selectedState}
                                            displayValue="name"
                                            groupBy="group"
                                            showArrow
                                            avoidHighlightFirstOption={true}
                                            onRemove={onChangeStateRemove}
                                            singleSelect={true}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-2 wow fadeIn" data-wow-delay="0.03s">
                                    <div className="ps-3 mb-3">
                                        <Multiselect
                                            placeholder="City"
                                            className="dropdown-multi"
                                            onSelect={onChangeLocations}
                                            options={cityList}
                                            selectedValues={selectedCity}
                                            displayValue="label"
                                            singleSelect={true}
                                            avoidHighlightFirstOption={true}
                                            groupBy="group"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 wow fadeIn" data-wow-delay="0.03s">
                                    <div className="h-100 text-center">
                                        <div className="ps-3 mb-3 ">
                                            <Multiselect
                                                className="dropdown-multi"
                                                placeholder="Profession"
                                                onSelect={onChangeDegree}
                                                singleSelect={true}
                                                avoidHighlightFirstOption={true}
                                                options={degree}
                                                displayValue="Name"
                                                disable={selectedLocations ? false : true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 wow fadeIn" data-wow-delay="0.03s">
                                    <div className="h-100 text-center">
                                        <div className="ps-3 mb-3">
                                            <Multiselect
                                                placeholder="Specialties"
                                                className="dropdown-multi"
                                                onSelect={onChangeSpecialties}
                                                singleSelect={true}
                                                options={specialties}
                                                displayValue="Name"
                                                loading={HomeStore?.loading}
                                                ref={specialtiesRef}
                                                disable={selectedLocations ? false : true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 wow fadeIn" align="center" data-wow-delay="0.03s">
                                    <div className="ps-3">
                                        <button className="btn btn-primary py-2 w-75" onClick={onSearchJob}>Search</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                    {/* Caregiver Search */}
                    {(!isAuthenticated || role == 'false') && <div className="container">
                        <div className="shadow border rounded p-4 p-sm-2 wow fadeInUp" style={{ backgroundColor: "#f6e8ff" }} data-wow-delay="0.03s">
                            <div className="row">
                                <div className="col-lg-10 col-12">
                                    <div className="row">
                                        <div className="col-lg-12 col-12 pt-2 pb-3 ps-4">
                                            <b className="">SEARCH CAREGIVER</b>
                                        </div>
                                    </div>
                                    <div className="row" style={{ display: "flex", textAlign: "-webkit-center" }}>
                                        <div className="col-lg-4 col-12">
                                            <div className="h-100 text-center">
                                                <div className="ps-3 mb-3 ">
                                                    <InputField
                                                        className={"did-floating-select dropdown-multi searchBox "}
                                                        type="text"
                                                        name="name"
                                                        value={selectedName}
                                                        label={"Caregiver Name"}
                                                        onChange={handleChangeName}
                                                        classNameLabel="did-floating-label-custom"
                                                    />
                                                    {error && <small className='text-danger'>{error}</small>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-12">
                                            <div className="h-100 text-center">
                                                <div className="ps-3 mb-3">
                                                    <Multiselect
                                                        placeholder="Select Degree"
                                                        className="dropdown-multi"
                                                        onSelect={onChangeDegree}
                                                        onRemove={onChangeDegreeRemove}
                                                        selectedValues={selectedDegree}
                                                        avoidHighlightFirstOption={true}
                                                        options={degree}
                                                        showArrow
                                                        displayValue="Name"
                                                        closeOnSelect="true"

                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-12">
                                            <div className="h-100 text-center">
                                                <div className="ps-3 mb-3">
                                                    <Multiselect
                                                        placeholder="Select Specialties"
                                                        id='one'
                                                        className="dropdown-multi"
                                                        showArrow
                                                        selectedValues={selectedSpecialties}
                                                        onSelect={onChangeSpecialties}
                                                        onRemove={onChangeSpecialtiesRemove}
                                                        options={specialties}
                                                        avoidHighlightFirstOption={true}
                                                        displayValue="Name"
                                                        ref={specialtiesRef}
                                                        closeOnSelect="true"

                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" style={{ display: "flex", textAlign: "-webkit-center" }}>
                                        <div className="col-lg-4 col-12">
                                            <div className="text-center">
                                                <div className="ps-3 mb-3">
                                                    <Multiselect
                                                        placeholder="State"
                                                        className="dropdown-multi"
                                                        onSelect={onChangeState}
                                                        options={states} // Options to display in the dropdown
                                                        selectedValues={selectedState}
                                                        displayValue="name"
                                                        groupBy="group"
                                                        showArrow
                                                        avoidHighlightFirstOption={true}
                                                        onRemove={onChangeStateRemove}
                                                        closeOnSelect="true"

                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-12">
                                            <div className=" text-center">
                                                <div className="ps-3 mb-3">
                                                    <Multiselect
                                                        placeholder="City"
                                                        className="dropdown-multi"
                                                        avoidHighlightFirstOption={true}
                                                        onSelect={onChangeCity}
                                                        onRemove={onChangeCityRemove}
                                                        options={cityList} // Options to display in the dropdown city
                                                        selectedValues={selectedCity}
                                                        displayValue="label"
                                                        showArrow
                                                        groupBy="group"
                                                        closeOnSelect="true"

                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-12">
                                            <div className="h-100 text-center">
                                                <div className="ps-3 mb-3">
                                                    <Multiselect
                                                        className="dropdown-multi"
                                                        avoidHighlightFirstOption={true}
                                                        singleSelect
                                                        onSelect={onChangeRating}
                                                        options={global.config.ratingOptions} // Options to display in the dropdown
                                                        selectedValues={selectedRating}
                                                        displayValue="label"
                                                        closeOnSelect="true"

                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-12 d-flex align-items-center justify-content-center mt-lg-4">
                                    <div className="wow fadeIn w-75" align="center" data-wow-delay="0.03s">
                                        <button className="btn btn-primary w-100 py-2" name="submit" type="submit" onClick={onSearch}>Search</button>
                                    </div>
                                </div>
                            </div>
                        </div> </div>}
                    {/* Shift Search */}
                    {(isAuthenticated && role == 'true') && userStaffID && <div className="container float-search">
                        <div className="shadow border rounded p-4 p-sm-2 wow fadeInUp" style={{ backgroundColor: "#f6e8ff" }} data-wow-delay="0.03s">
                            <div className="row justify-content-center">
                                <div className="col-lg-12 col-12 pt-2 pb-3 ps-3">
                                    <b className="">SEARCH SHIFT</b>
                                </div>
                                <div className='d-flex flex-column flex-lg-row align-items-center mb-lg-3'>
                                    <div className="col-12 col-lg-5 p-2 wow fadeIn mb-3" data-wow-delay="0.03s">
                                        <Multiselect
                                            placeholder="Facilities In Your Area"
                                            className="dropdown-multi"
                                            options={eligibleFacility}
                                            selectedValues={selectedFacilities}
                                            displayValue="organization_name"
                                            showArrow
                                            avoidHighlightFirstOption={true}
                                            onSelect={onChangeFacility}
                                        />
                                    </div>
                                    <div className="col-12 col-lg-5 p-2  wow fadeIn mb-3" data-wow-delay="0.03s">
                                        <DatePicker
                                            value={range}
                                            minDate={new Date()}
                                            onChange={setRange}
                                            placeholder="Select Date"
                                            format="MM/DD/YYYY"
                                            inputClass='search-datepicker w-100'
                                            range
                                            shadow
                                        />
                                    </div>
                                    <div className="col-12 col-lg-2 p-2 wow fadeIn mb-3" align="center" data-wow-delay="0.03s">
                                        <button className="btn btn-primary py-2 w-100 " onClick={onSearchShift}>Search</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>}
                    <CategoryItems />
                </div>}





            {/* JOBPROFILE POPUP */}
            {(isAuthenticated && role == 'true' && userStaffID == null && StaffsStore?.data?.jobProfile?.profile?.status === null) &&
                <div className='animated rubberBand infinite'>
                    <CModal
                        alignment="center"
                        backdrop={true}
                        visible={true}
                        onClose={() => null}
                    >
                        <CModalHeader style={{ borderBottom: 'none' }}>
                            <CModalTitle></CModalTitle>
                        </CModalHeader>
                        <CModalBody className='m-3 text-center text-dark d-flex' onClick={() => gotoMyJobProfile()}>
                            <div className='m-2'>
                                <h3>It appears that you haven't finished your job profile</h3>
                                <br />Once you complete your profile, you can view all the available shifts and see what they pay.<br />
                                <CButton size="lg" variant='outline' color="primary" className='animate-btn mt-5' >
                                    <span> Create Job Profile</span>
                                </CButton>
                            </div>
                        </CModalBody>
                    </CModal>
                </div>}
            {/* JOBPROFILE POPUP */}
            {(isAuthenticated && signPopUp) &&
                <div className='animated rubberBand infinite'>
                    <CModal
                        alignment="center"
                        backdrop={true}
                        visible={true}
                        onClose={() => null}
                    >
                        <CModalHeader style={{ borderBottom: 'none' }}>
                            <CModalTitle></CModalTitle>
                        </CModalHeader>
                        <CModalBody className='m-3 text-center text-dark d-flex' onClick={() => navigate('/onboarding-documents')}>
                            <div className='m-2'>
                                <h3>It appears that you haven't sign your onboarding documents</h3>
                                <br />Once you complete your onboarding documents, you can view all the available shifts and see what they pay.<br />
                                <CButton size="lg" variant='outline' color="primary" className='animate-btn mt-5' >
                                    <span> Sign Onboarding Documents</span>
                                </CButton>
                            </div>
                        </CModalBody>
                    </CModal>
                </div>}

        </>
    );
});

export default Jumbotron;