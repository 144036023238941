import React from "react";
import Header from '@components/PDFHeader';
import moment from "moment";
import { MultiDatePicker } from "@elements/index"
export const JobDescriptionLicensedLPN = (props) => {
  return (
    <div id="form-content">
      <Header title="" />
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="border-box">
                <div>
                  <div className="d-md-flex">
                    <table className="table table-all bordered">
                      <tbody>
                        <tr>
                          <td width="40%" className="head">
                            Job Title:
                          </td>
                          <td width="60%" className="input">
                            <input
                              type="text"
                              value="Licensed Practical Nurse - LPN"
                              disabled
                            />
                          </td>
                        </tr>
                        <tr>
                          <td width="40%" className="head">
                            Location:
                          </td>
                          <td width="60%" className="input">
                          <input type="text" value={props.inputValues.location} onChange={(e) => props.handleInputChange('location', e.target.value)} />
                         
                          </td>
                        </tr>
                        <tr>
                          <td width="40%" className="head">
                            Effective Date:
                          </td>
                          <td width="60%" className="input">
                          {/* <input type="date" value={props.inputValues.EffectiveDate} onChange={(e) => props.handleInputChange('EffectiveDate', e.target.value)} /> */}
                          <MultiDatePicker required={false} multiple={false} value={props.inputValues.EffectiveDate} onChange={(e) => props.handleInputChange('EffectiveDate', e)} />

                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="table table-all bordered">
                      <tbody>
                        <tr>
                          <td width="40%" className="head">
                            Department
                          </td>
                          <td width="60%" className="input">
                          <input type="text" value={"Nursing"} disabled onChange={(e) => props.handleInputChange('Department', e.target.value)} />
                         
                          </td>
                        </tr>
                        <tr>
                          <td width="40%" className="head">
                            FLSA Status:
                          </td>
                          <td width="60%" className="input">
                             <input type="text"  placeholder="Non-exempt/Hourly" value={props.inputValues.FLSA} onChange={(e) => props.handleInputChange('FLSA', e.target.value)} />
                          
                          </td>
                        </tr>
                        <tr>
                          <td width="40%" className="head">
                            Revised Date:
                          </td>
                          <td width="60%" className="input">
                          <input type="text" value={props.inputValues.Revised} onChange={(e) => props.handleInputChange('Revised', e.target.value)} />
                         
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <table className="table table-all bordered">
                    <tbody>
                      <tr>
                        <td className="head width-set-h">Reporting to:</td>
                        <td className="input width-set-i">
                        <input type="text" value={props.inputValues.Reportingto} onChange={(e) => props.handleInputChange('Reportingto', e.target.value)} /> 
                     

                        </td>
                      </tr>
                      <tr>
                        <td className="head width-set-h">Date of Hire:</td>
                        <td className="input width-set-i">
                        {/* <input type="date" value={props.inputValues.DOH} onChange={(e) => props.handleInputChange('DOH', e.target.value)} />  */}
                        <MultiDatePicker required={false} multiple={false} value={props.inputValues.DOH} onChange={(e) => props.handleInputChange('DOH', e)} />
                      
                        </td>
                      </tr>
                      <tr>
                        <td className="head width-set-h">Employee Name:</td>
                        <td className="input width-set-i">
                        <input placeholder="Full Name"  required value={props.inputValues.full_name} type="text" minLength={2} maxLength={20}    name="full name" onChange={(e) => props.handleInputChange('full_name', e.target.value)} />
                 </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="back-grad grey">
                  <h2 className="heading-line">JOB SUMMARY:</h2>
                  <p className="bold-para">
                    The role of a Licensed Practical Nurse - LPN is to work as
                    and when required in support of the nursing team in
                    providing high quality care and support. Responsible for the
                    independent supervision of the delivery of care to a group
                    of residents alongside other Licensed Practical Nurse - LPN
                    and care assistants in supporting them to provide quality
                    care to all residents and their families at all times.
                    Assess resident needs, develop individual care plans,
                    administer nursing care, evaluate nursing care, and
                    supervise Certified Nursing Assistants (CNAs) and other
                    personnel in the delivery of nursing care.
                  </p>
                </div>
                <div className="back-grad">
                  <h3 className="same-head">
                    <span>E</span>ssential job functions
                  </h3>
                  <h4 className="same-small-head">Customer Service</h4>
                  <ul>
                    <li>
                      <span>
                        Provide compassionate resident care services. Establish
                        and maintain positive interactions with internal and
                        external customers, including co-workers, facility
                        employees and direct reports.
                      </span>
                    </li>
                    <li>
                      <span>
                        Take responsibility for resolving customer’s issues and
                        concerns striving to make outstanding experiences for
                        our customers. Customers include residents, families,
                        vendors and other employees.
                      </span>
                    </li>
                    <li>
                      <span>
                        Promote positive relationships with residents, visitors,
                        and regulators, to include presenting a professional
                        appearance.
                      </span>
                    </li>
                  </ul>
                  <br></br>
                  <h4 className="same-small-head">
                    Specific Duties and Responsibilities
                  </h4>
                  <ul>
                    <li>
                      To show by personal example the highest standard of
                      patient care and participate in active bedside nursing
                      care including the full use of appropriate nursing
                      equipment.
                    </li>
                    <li>
                      To be aware of and meet the needs of the residents as
                      specified in the care plans and as directed by the
                      DON/ADON of the shift.
                    </li>
                    <li>
                      To liaise effectively with professionals and significant
                      others involved in the resident’s care, ensuring an
                      integrated pattern of service delivered.
                    </li>
                    <li>
                      To check and administer medications, reporting any
                      discrepancies to the Nurse Manager on duty.
                    </li>
                    <li>
                      To observe and repot to the Nurse Manager on duty any
                      changes in the resident’s condition and for this
                      information to be entered in the resident’s records.
                    </li>
                    <li>
                      Implement plan of care consistently, effectively and cost
                      efficiently with focus on resident centered outcomes.
                    </li>
                    <li>
                      Utilize time efficiently to assure completion of duties
                      and responsibilities in allotted time frame.
                    </li>
                    <li>
                      Follow company policies and procedures to ensure a safe,
                      caring, comfortable and clean environment.
                    </li>
                    <li>
                      Keep physician and/or health care professionals informed
                      of resident’s condition; and notify physician and/or other
                      healthcare professionals immediately of significant
                      changes of conditions.
                    </li>
                    <li>
                      Work in collaboration with physician and/or other
                      healthcare professionals by sharing information relevant
                      to changing plan of care.
                    </li>
                    <li>Question and seek clarification of unclear orders.</li>
                  </ul>
                  <br></br>
                  <p>
                    Licensed Practical Nurse – LPN - Job Description – Staffers
                    LLC – Revised 7/15/2019
                  </p>
                  <ul>
                    <li>
                      Perform only those activities which are within the scope
                      of RN nursing practice.
                    </li>
                    <li>
                      Follow facilities policies and procedures, and
                      appropriately utilize nursing judgment.
                    </li>
                    <li>
                      Ensure accurate documentation of all medical records and
                      reporting forms.
                    </li>
                    <li>
                      Promptly respond to call lights and other resident needs.
                    </li>
                    <li>
                      Demonstrate strong ethical practices regarding
                      individual/resident rights as stated in facilities policy.
                    </li>
                    <li>
                      Understand, comply with and promote all rules and
                      regulations regarding resident rights
                    </li>
                  </ul>
                  <br></br>
                  <h4 className="same-small-head">Supervision</h4>
                  <ul>
                    <li>
                      <span>
                        Supervise Certified Nursing Assistants and other
                        personnel. Coach, counsel and assign Certified Nursing
                        Assistants to provide quality resident care.
                      </span>
                    </li>
                    <li>
                      <span>
                        Recognize Certified Nursing Assistants and other Nurses
                        for exceptional care and job performance on a regular
                        basis.
                      </span>
                    </li>
                    <li>
                      <span>
                        Attempt to effectively resolve Certified Nursing
                        Assistant and others Nurses’ problems/complaints
                      </span>
                    </li>
                    <li>
                      <span>
                        Support and follow through with facility management team
                        goals.
                      </span>
                    </li>
                  </ul>
                  <br></br>
                  <h4 className="same-small-head">Safeguarding and Safety</h4>
                  <ul>
                    <li>
                      <span>
                        To ensure that residents are safeguarded against the
                        risk of abuse. This includes but is not limited to: a
                        zero tolerance for harassment and violence; following
                        infection control procedures; following safe patient
                        handling as required with your specific job position.
                      </span>
                    </li>
                    <li>
                      <span>
                        Demonstrate safe practices in regard to resident’s
                        comfort and safety by applying knowledge of proper body
                        alignment for self and resident. Practice and promote
                        adherence to institutional safety codes.
                      </span>
                    </li>
                    <li>
                      <span>
                        Comply with all facility safety policies, regulations
                        and requirements regarding fire and life safety.
                      </span>
                    </li>
                    <li>
                      <span>
                        Meet the general health requirements set forth by the
                        policies of the facility.
                      </span>
                    </li>
                  </ul>

                  <br></br>
                  <h4 className="same-small-head">
                    Other Functions and Responsibilities
                  </h4>
                  <ul>
                    <li>
                      <span>Flexibility and ability to work under pressure.</span>
                    </li>
              
                    <li>
                      <span>The ability to prioritize a busy workload.</span>
                    </li>
                    <li>
                      <span>
                        Must adhere to Code of Conduct and Business Ethics
                        policy, including documentation and reporting
                        responsibilities.
                      </span>
                    </li>
                    <li>
                      <span>
                        This is a position in health care facilities open 24
                        hours a day, 365 days a year. Shifts on holidays and
                        working during inclement weather may be required.
                      </span>
                    </li>
                    <li>
                      <span>
                        To complete and submit all timesheets correctly and in a
                        timely manner to enable authorization and payment.
                      </span>
                    </li>
                  </ul>
                  <br></br>
                  <h4 className="same-head">
                    Physical and Sensory Requirements
                  </h4>
                  <p>This position is very active and requires:</p>
                  <ul>
                    <li>
                      <span>
                        Walking, standing, reaching, bending, crouching,
                        grasping, pushing, pulling, and lifting.
                      </span>
                    </li>
                    <li>
                      <span>
                        The ability to work at a rapid apace for an entire
                        shift.
                      </span>
                    </li>
                    <li>
                      <span>
                        The employee must frequently lift or move objects and
                        patients weighing up to 40 pounds. Following patient
                        care plan and using a variety of equipment, the employee
                        may frequently move, reposition and transfer residents
                        of varying weights up to 300 lbs.
                      </span>
                    </li>
                    <li>
                      <span>
                        Must have a finger and hand dexterity sufficient to
                        type, handle and manipulate instruments and operate
                        departmental equipment.
                      </span>
                    </li>
                    <li>
                      <span>
                        Have a working understand of English: ability to read
                        and write; ability to understand and follow written and
                        oral instructions; ability to verbally communicate with
                        residents and others; ability to hear and respond to
                        pages.
                      </span>
                    </li>
                    <li>
                      <span>
                        Ability to remain calm in emergency situations and when
                        handling multiple tasks.
                      </span>
                    </li>
                  </ul>
                  <br></br>
                  <h4 className="same-head">
                    QUALIFICATIONS AND EDUCATION REQUIREMENTS
                  </h4>
                  <ul>
                    <li>
                      <span>
                        Must hold and maintain a current license to practice as
                        a Licensed Practical Nurse in practicing state.
                      </span>
                    </li>
                    <li>
                      <span>
                        Recent clinical experience, education, or specialty
                        skills specific to the population providing care to.
                      </span>
                    </li>
                    <li>
                      <span>
                        Demonstrate understanding that state and Federal rules
                        and regulations govern the practices within a facility.
                      </span>
                    </li>
                    <li>
                      <span>
                        {" "}
                        Demonstrate working knowledge of infection control
                        principles and practices.
                      </span>
                    </li>
                    <li>
                      <span>
                        Ongoing pursuit of Continuing Education Credits in
                        clinical subjects, management, personal growth and
                        development.
                      </span>
                    </li>
                    <li>
                      <span>
                        Proven ability to communicate effectively and positively
                        and favorably to residents and families and to work
                        cooperatively with facility staff and supervisors.
                      </span>
                    </li>
                    <li>
                      <span>
                        Basic computer knowledge and ability with an aptitude to
                        learn required software.
                      </span>
                    </li>
                    <li>
                      <span>Must be capable of maintaining regular attendance.</span>
                    </li>
                    <li>
                      <span>
                        Must meet all local health regulations. This includes
                        drug screening, criminal background investigation and
                        reference inquiry.
                      </span>
                    </li>
                  </ul>
                  <br></br>
                  <h2 className="heading-line">JOB DESCRIPTION REVIEW:</h2>
                  <span>
                    I understand this job description and its requirements, and
                    that I am expected to complete all duties as assigned. I
                    understand the job functions may be altered from time to
                    time. I further understand that the purpose of this job
                    description is to identify the major duties and
                    responsibilities of the job and this is not intended to
                    contain each and every inherent in this job. I have noted
                    below any accommodations that are required to enable me to
                    perform these duties. I have also noted below any job
                    responsibilities or functions which I am unable to perform,
                    with or without accommodation.
                  </span>
                </div>
                <table className="table table-all bordered">
                  <tbody>
                    <tr>
                      <td width="20%" className="head not-bold">
                        Employee's<br></br> Signature
                      </td>
                      <td width="20%" className="input">
                        <div>{props?.signature ? <img src={props?.signature} width={180} height={50}  className="w-100 rounded-0" alt="Signature here" /> : <div class="badge badge-primary text-wrap pointer" onClick={props?.onOpenModal}>
                          Click Here To Sign
                        </div>}</div>
                      </td>
                      <td width="20%" className="head not-bold">
                        Date:
                      </td>
                      <td width="20%" className="input">
                        <div> 
                          <input type='text' value={moment(new Date()).format("MM-DD-YYYY HH:mm:ss")} disabled />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width="20%" className="head not-bold">
                        Supervisor's<br></br> Signature
                      </td>
                      <td width="20%" className="input">
                        <div></div>
                      </td>
                      <td width="20%" className="head not-bold">
                        Date:
                      </td>
                      <td width="20%" className="input">
                        <div></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="border-box mt-3">
                <div className="back-grad">
                  <p className=" para-underline">Accomodation Needed:</p>
                  <p className=" para-underline">
                    Job Responsibilities I am unable to perform:
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
