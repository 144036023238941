import html2pdf from 'html2pdf.js';
import AdminStore from "@store/AdminStore";
import { toast } from 'react-toastify';
import Select, { components } from 'react-select';
import moment from 'moment';
import { MultiDatePicker } from "@elements/index"
import SelectField from "@elements/select/SelectField";
import { CFormCheck, CModalBody, CModalHeader, CModalTitle } from '@coreui/react-pro';
import DatePicker, { DateObject, getAllDatesInRange } from "react-multi-date-picker"
import weekends from "react-multi-date-picker/plugins/highlight_weekends"
import { Field, FieldArray, Formik } from 'formik';
import { AddShiftSchema } from '@utils/schemas/allSchema';
import HomeStore from "@store/HomeStore";
import { InputField } from "@elements/index"
import { colourStylesState } from '@utils/customStyle/style';
import swal from '@sweetalert/with-react';
import { toJS } from "mobx";
import { createEvent } from 'ics';

export const printCalendar = (data) => {
    var divContents2 = document.getElementById('header').innerHTML;
    var divContents = document.getElementById("calendar-print").innerHTML;
    var printWindow = window.open('', '', 'height=400,width=800');
    printWindow.document.write(`<html><head><title>${data?.first_name}_${data?.last_name}</title></head><body>`);
    printWindow.document.write(divContents2);
    printWindow.document.write(divContents);
    printWindow.document.write('<link rel="stylesheet" type="text/css" href="https://unpkg.com/react-big-calendar@1.8.5/lib/css/react-big-calendar.css">');
    printWindow.document.write('<style>');
    printWindow.document.write(`
        .rbc-row-content .rbc-row:not(:first-child):not(:nth-child(2)):not(:last-child) {
            display: none;
        }
        .rbc-overlay {
            max-height: 200px;
            overflow-y: auto;
        }
        .rbc-show-more {
            position: absolute;
            top: 0;
        }
    `);
    printWindow.document.write('</style></head><body>');
    printWindow.document.close();
    printWindow.print();

}
export const downloadCalendar = (data) => {
    AdminStore.toggleLoading(true);
    const wrapper = document.createElement('div');
    // Clone and append the header content
    const headerContent = document.getElementById('header').cloneNode(true);
    wrapper.appendChild(headerContent);
    const calendarContent = document.getElementById('calendar-download').cloneNode(true);
    wrapper.appendChild(calendarContent);
    const calendarContentTwo = document.querySelector('.rbc-agenda-view').cloneNode(true);
    // Clone the node
    wrapper.appendChild(calendarContentTwo);
    const opt = {
        filename: `${data?.first_name}_${data?.last_name}.pdf`,
        margin: [10, 10, 10, 10],
        image: { type: 'jpeg', quality: 0.95 },
        html2canvas: { scale: 1, letterRendering: false },
        jsPDF: { orientation: 'portrait' }
    };
    html2pdf().from(wrapper).set(opt).save().then(() => {
        AdminStore.toggleLoading(false);
    });
}

export const TimeRangePickerField = (props) => {
    const hnadleStart = (e) => {
        const startTime = e.target.value;
        if (props?.values?.alltime?.length === 0) {
            props.setFieldValue(props.name, [startTime, undefined]);
        } else {
            props.setFieldValue(props.name, [startTime, props?.values?.alltime[props.index]?.length === 2 ? props?.values?.alltime[props.index][1] : undefined]);
        }
    }
    const hnadleEnd = (e) => {
        const endTime = e.target.value;
        if (props?.values?.alltime?.length === 0) {
            props.setFieldValue(props.name, [undefined, endTime]);
        } else {
            if (props?.values?.alltime[props.index][0] !== endTime) {
                props.setFieldValue(props.name, [props?.values?.alltime[props.index][0], endTime]);
            } else {
                toast.error("The start and finish timings must not be the same!")
            }
        }
    }
    return (
        <>
            <input required type="time" id="start" className="border-0" name="start" value={props?.values?.alltime[props?.index] ? props?.values?.alltime[props?.index][0] : ''} onChange={hnadleStart} />
            <span className="slot-time-to">To</span>
            <input required type="time" id="end" className="border-0" name="end" disabled={props?.values?.alltime[props?.index] ? props?.values?.alltime[props?.index][0] ? false : true : true} value={props?.values?.alltime[props?.index]?.length === 2 ? props?.values?.alltime[props.index][1] : ""} onChange={hnadleEnd} />
        </>
    );
}
export const Control = (props) => {
    return (
        <>
            <label className={props.hasValue || props.isFocused ? "did-floating-label did-floating-label-top" : "did-floating-label did-floating-label"} style={{ zIndex: 99 }}>{props?.selectProps?.label}</label>
            <components.Control {...props} />
        </>
    );
};
export const CustomEventClient = ({ event }) => (
    <div className={"event-wrapper"}>
        <small className="event-title">{event.title}</small>
        <br />
        <small className="event-details">{event?.caregiver}</small>
        {event?.data?.category !== 'TC' && (
            <>
                <div className={`event-time ${event?.data?.status === 3 || event?.data?.status === 5 || event?.data?.status === 6 ? 'text-decoration-line-through' : ''}`}>
                    <small>{`Time: ${moment(event.start).format('hh:mm A')} - ${moment(event.end).format('hh:mm A')}`}</small>
                </div>
                <small className="event-status">{
                    (event?.data?.status === 1 && !event?.data?.staff_id) ? "Open" :
                        (event?.data?.status === 1 && event?.data?.staff_id) ? "Pending Approval" :
                            event?.data?.status === 2 ? "Confirmed" :
                                event?.data?.status === 4 ? "Extension pending" :
                                    event?.data?.status === 3 ? "Rejected" :
                                        event?.data?.status === 5 ? "Cancelled by caregiver" :
                                            event?.data?.status === 6 ? "Cancelled by facility" :
                                                event?.data?.status === 7 ? "Completed" : "No Update"
                }</small>
            </>
        )}
        {event?.data?.category === 'TC' && (
            <>
                <div className={`event-time ${event?.data?.status === 2 ? 'text-decoration-line-through' : ''}`}>
                    <small>{`Start: ${moment(event.start).format('hh:mm A')} - ${moment(event.end).format('hh:mm A')}`}</small>
                </div>
                <small className="event-status">{
                    event?.data?.status === 0 ? "Open" :
                        event?.data?.status === 5 ? "Confirmed" :
                            (event?.data?.status === 2) ? "Cancelled" :
                                event?.data?.status === 3 ? "Complete" : "No Update"
                }</small>
            </>
        )}
        <style jsx="true">{`
      
        .event-title {
          font-size: 12px;
        }
        .event-details {
          font-size: 12px;
        }
     
        .event-status {
          font-size: 12px;
        }
        .text-decoration-line-through {
          text-decoration: line-through;
        }
        .event-wrapper {
            overflow-x: auto;
          }
        @media screen and (max-width: 600px) {
          .event-title {
            font-size: 12px;
          }
          .event-wrapper {
            overflow-x: auto;
          }
          .event-details {
            font-size: 12px;
          }
          .event-time, .event-status {
            font-size: 10px;
          }
        }
      `}</style>
    </div>
);

export const ViewEventClient = ({
    selectedEvent,
    degree,
    slotChanges,
    assignToOtherStaff,
    onChangeSlotStatus,
    handleDateChange,
    handleSlotStartChange,
    handleSlotEndChange,
    editSlot,
    staffByProfession,
    setAssignToStaff,
    assignToStaff,
    handleSlotTypeChange,
    onCopySlot,
    handleCloseModal
}) => {
    return (
        <div >
            {selectedEvent?.data?.category !== 'TC' && <ul className="list-group list-group-flush">
                <li className="list-group-item">
                    <i className="fas fa-hashtag  text-primary"></i> <strong>Order Ref:</strong> {selectedEvent?.data?.shift_id}
                </li>
                <li className="list-group-item">
                    <i className="fas fa-building  text-primary"></i> <strong>Facility Name:</strong> {selectedEvent?.data?.shift_data?.facility?.organization_name}
                </li>
                <li className="list-group-item row align-items-center">

                    <div className='col-12'>
                        <i className="fas fa-user text-primary"></i>
                        <strong className="ml-2"> Caregiver:</strong>
                        {selectedEvent?.data?.staff?.first_name ? "" : <span className="text-info pe-2 "> NO ASSIGNMENT </span>
                        }
                    </div>

                    <div className='col-12'>
                        <Select
                            onChange={(e) => setAssignToStaff(e.staff_id)}
                            placeholder={'Select Caregiver'}
                            required={true}
                            isDisabled={!((selectedEvent?.data?.status !== 5 && selectedEvent?.data?.status !== 6 && selectedEvent?.data?.status !== 7) && selectedEvent?.data?.is_exported === 0)}
                            value={assignToStaff ? staffByProfession?.filter(({ staff_id }) => staff_id === assignToStaff) : staffByProfession?.filter(({ staff_id }) => staff_id === selectedEvent?.data?.staff_id)}
                            // styles={colourStylesTitle}
                            isSearchable={true}
                            options={staffByProfession}
                            getOptionLabel={(option) => `${option.first_name} ${option.last_name} (Bluesky Id: ${option.staffer_id})`}
                            getOptionValue={(option) => option.staff_id}
                            components={{ Control }}
                        // className="ml-auto" // This class will push the Select to the right
                        />
                    </div>
                    {((selectedEvent?.data?.status !== 5 && selectedEvent?.data?.status !== 6 && selectedEvent?.data?.status !== 7) && selectedEvent?.data?.is_exported === 0) &&
                        <button className="btn btn-sm btn-success me-2 mt-2" onClick={() => assignToOtherStaff(selectedEvent)}>
                            <i className="fas fa-user-plus"></i> {selectedEvent?.data?.staff_id ? "Change" : "Assign"} Caregiver
                        </button>}
                </li>

                <li className="list-group-item">
                    <i className="fas fa-id-badge  text-primary"></i> <strong>Profession-Specilaity:</strong> {selectedEvent?.data?.shift_data?.specialty_name ? selectedEvent?.data?.shift_data?.specialty_name : degree?.find(deg => deg.Id == selectedEvent?.data?.shift_data?.profession)?.Name}
                </li>
                <li className="list-group-item">
                    <i className="fas fa-clipboard text-primary"></i> <strong>Shift Category:</strong> {selectedEvent?.data?.request?.category === 'PD' ? "Per Diem" : "Local Contract"}
                </li>

                <li className="list-group-item">
                    <i className="far fa-calendar-check text-primary"></i> <strong>Shift Status:</strong> {selectedEvent?.data?.status === 1 ? "Open" : selectedEvent?.data?.status === 2 ? "Confirmed" : (selectedEvent?.data?.status === 5) ? "Cancelled by caregiver" : (selectedEvent?.data?.status === 6) ? "Cancelled by facility" : selectedEvent?.data?.status === 7 ? "Closed" : "No Update"}
                </li>
                <li className="list-group-item d-flex align-items-center">
                    {/* Calendar Icon */}
                    <i className="far fa-calendar-alt text-primary me-1"></i>

                    {/* Date Label */}
                    <strong> Date: </strong>

                    {/* Conditional rendering based on status */}
                    {
                        selectedEvent?.data?.is_exported === 1 || [4, 5, 6].includes(selectedEvent?.data?.status) ? (
                            // Display formatted date if status is not 1
                            <span className="ml-2">
                                {moment(selectedEvent?.data?.slot_date).format('MMMM Do, YYYY')}
                            </span>
                        ) : (
                            // Display MultiDatePicker component if status is 1
                            <div className="ps-1">
                                <MultiDatePicker onChange={handleDateChange} multiple={false} value={editSlot?.slot_date} />
                            </div>
                        )
                    }
                </li>
                <li className="list-group-item d-flex align-items-center">
                    <i className="far fa-clock text-primary"></i> <strong className='me-2 ms-1'> Shift Type: </strong>

                    {
                        selectedEvent?.data?.is_exported === 1 || [4, 5, 6].includes(selectedEvent?.data?.status) ? selectedEvent?.data?.slot_type :
                            <SelectField
                                name="shift_type"
                                label={""}
                                onChange={handleSlotTypeChange}
                                value={editSlot?.slot_type}
                                required
                                data={global.config.shiftType}
                                optionLabel={"name"}
                                optionValue={"value"}
                            />
                    }

                </li>
                <li className="list-group-item d-flex align-items-center">
                    {/* Calendar Icon */}
                    <i className="far fa-calendar-alt text-primary me-1"></i>

                    {/* Date Label */}
                    <strong> Time: </strong>

                    {/* Conditional rendering based on status */}
                    {
                        selectedEvent?.data?.is_exported === 1 || [4, 5, 6].includes(selectedEvent?.data?.status) ? (
                            // Display formatted date if status is not 1
                            <span className="ml-2">
                                {moment(selectedEvent?.start).format('hh:mm A')} - {moment(selectedEvent?.end).format('hh:mm A')}
                            </span>
                        ) : (
                            // Display MultiDatePicker component if status is 1
                            <div className="ps-1">
                                <input type="time" onChange={handleSlotStartChange} value={editSlot?.slot_start_time} />
                                <small> To </small>
                                <input type="time" onChange={handleSlotEndChange} value={editSlot?.slot_end_time} />
                            </div>
                        )
                    }
                </li>

                {selectedEvent?.data?.remark && <li className="list-group-item">
                    <i className="fas fa-comment text-primary"></i> <strong>Comment:</strong> {selectedEvent?.data?.remark}
                </li>}
                {selectedEvent?.data?.status === 1 && <li className="list-group-item">
                    <i className="far fa-copy  text-primary"></i> : <button
                        className="btn btn-sm btn-info ms-2 pointer"
                        onClick={() => {
                            onCopySlot(selectedEvent?.data)
                        }}
                    >Copy Shift URL</button>
                </li>}
            </ul>}
            {selectedEvent?.data?.category === 'TC' &&
                <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                        <i className="fas fa-user text-primary"></i> <strong>Caregiver:</strong> {selectedEvent?.data?.first_name} {selectedEvent?.data?.last_name}
                    </li>
                    <li className="list-group-item">
                        <i className="fas fa-id-badge  text-primary"></i> <strong>Shift Ref:</strong> {selectedEvent?.data?.id}
                    </li>
                    <li className="list-group-item">
                        <i className="fas fa-clipboard text-primary"></i> <strong>Shift Category:</strong> Traveler Contract
                    </li>
                    <li className="list-group-item">
                        <i className="far fa-clock text-primary"></i> <strong>Shift Type:</strong> {selectedEvent?.data?.shift_type}
                    </li>
                    <li className="list-group-item">
                        <i className="far fa-calendar-check text-primary"></i> <strong>Shift Status:</strong> {selectedEvent?.data?.status === 1 ? "Open" : selectedEvent?.data?.status === 5 ? "Confirmed" : selectedEvent?.data?.status === 2 ? "Cancelled" : selectedEvent?.data?.status === 3 ? "Closed" : "No Update"}
                    </li>
                    <li className="list-group-item">
                        <i className="far fa-calendar-alt text-primary"></i> <strong>Start Date:</strong> {moment(selectedEvent?.data?.start_date).format('MMMM Do, YYYY')}
                    </li>
                    <li className="list-group-item">
                        <i className="far fa-calendar-alt text-primary"></i> <strong>End Date:</strong> {moment(selectedEvent?.data?.end_date).format('MMMM Do, YYYY')}
                    </li>
                    <li className="list-group-item">
                        <i className="far fa-clock text-primary"></i> <strong>Time:</strong> {moment(selectedEvent?.start).format('hh:mm A')} - {moment(selectedEvent?.end).format('hh:mm A')}
                    </li>
                    <li className="list-group-item">
                        <i className="far fa-clock text-primary"></i> <strong>Hours Per Week:</strong> {selectedEvent?.data?.hours_per_week}
                    </li>

                    <li className="list-group-item">
                        <i className="fas fa-hashtag text-primary"></i> <strong>No. Of Week:</strong> {selectedEvent?.data?.no_of_weeks}
                    </li>
                    <li className="list-group-item">
                        <i className="fas fa-dollar-sign text-primary"></i> <strong>Pay Details:</strong> {selectedEvent?.data?.pay_details}
                    </li>
                    <li className="list-group-item">
                        <i className="fas fa-graduation-cap text-primary"></i> <strong>Qualifications:</strong> {selectedEvent?.data?.qualifications}
                    </li>
                    {selectedEvent?.data?.description && <li className="list-group-item">
                        <i className="fas fa-comment text-primary"></i> <strong>Comment:</strong> {selectedEvent?.data?.description}
                    </li>}

                </ul>}

            <div className="card-footer d-flex justify-content-center ">
                {((selectedEvent?.data?.is_exported !== 1 || [4, 5, 6].includes(!selectedEvent?.data?.status)) && selectedEvent?.data?.is_exported === 0) &&
                    <button className="btn btn-sm btn-success me-2" onClick={() => slotChanges(selectedEvent)}>
                        <i className="fas fa-save"></i> Update Details
                    </button>}
                {((selectedEvent?.data?.status === 2 && selectedEvent?.data?.staff_id)) && <button className="btn btn-sm btn-danger me-2" onClick={() => onChangeSlotStatus(selectedEvent, 'cancel')}>
                    <i className="fas fa-times"></i> Cancel By Facility
                </button>}
                {((selectedEvent?.data?.status === 2 && selectedEvent?.data?.staff_id)) && <button className="btn btn-sm btn-danger me-2" onClick={() => onChangeSlotStatus(selectedEvent, 'cancel-caregiver')}>
                    <i className="fas fa-times"></i>  Cancel By Caregiver
                </button>}
                {(selectedEvent?.data?.status === 1 && selectedEvent?.data?.staff_id) && <button className="btn btn-sm btn-success me-2" onClick={() => onChangeSlotStatus(selectedEvent, 'approve')}>
                    <i className="fas fa-check"></i> Approve
                </button>}
                {(selectedEvent?.data?.status === 1 && selectedEvent?.data?.staff_id && selectedEvent?.data?.is_exported === 0) && <button className="btn btn-sm btn-danger me-2" onClick={() => onChangeSlotStatus(selectedEvent, 'reject-close')}>
                    <i className="fas fa-times"></i> Reject & Close
                </button>}
                {(selectedEvent?.data?.status === 1 && selectedEvent?.data?.staff_id && selectedEvent?.data?.is_exported === 0) && <button className="btn btn-sm btn-danger me-2" onClick={() => onChangeSlotStatus(selectedEvent, 'reject-open')}>
                    <i className="fas fa-times"></i> Reject & Open
                </button>}
                {selectedEvent?.data?.status === 1 && <button className="btn btn-sm btn-danger me-2" onClick={() => onChangeSlotStatus(selectedEvent, 'delete')}>
                    <i className="fas fa-trash"></i> Delete
                </button>}
                <button className="btn btn-primary" onClick={handleCloseModal}>
                    Close
                </button>
            </div>
        </div>
    );
};
export const ViewEventFacility = ({
    selectedEvent,
    degree,
    slotChanges,
    assignToOtherStaff,
    onChangeSlotStatus,
    handleDateChange,
    handleSlotStartChange,
    handleSlotEndChange,
    editSlot,
    staffByProfession,
    setAssignToStaff,
    assignToStaff,
    handleSlotTypeChange,
    onCopySlot,
    handleCloseModal
}) => {
    return (
        <div >
            {selectedEvent?.data?.category !== 'TC' && <ul className="list-group list-group-flush">
                <li className="list-group-item">
                    <i className="fas fa-hashtag  text-primary"></i> <strong>Order Ref:</strong> {selectedEvent?.data?.shift_id}
                </li>
                <li className="list-group-item">
                    <i className="fas fa-building  text-primary"></i> <strong>Facility Name:</strong> {selectedEvent?.data?.shift_data?.facility?.organization_name}
                </li>
                <li className="list-group-item row align-items-center">

                    <div className='col-12'>
                        <i className="fas fa-user text-primary"></i>
                        <strong className="ml-2"> Caregiver:</strong>
                        {selectedEvent?.data?.staff?.first_name ? "" : <span className="text-info pe-2 "> NO ASSIGNMENT </span>
                        }
                    </div>

                    <div className='col-12'>
                        <Select
                            onChange={(e) => setAssignToStaff(e.staff_id)}
                            placeholder={'Select Caregiver'}
                            required={true}
                            isDisabled={!((selectedEvent?.data?.status !== 5 && selectedEvent?.data?.status !== 6 && selectedEvent?.data?.status !== 7) && selectedEvent?.data?.is_exported === 0)}
                            value={assignToStaff ? staffByProfession?.filter(({ staff_id }) => staff_id === assignToStaff) : staffByProfession?.filter(({ staff_id }) => staff_id === selectedEvent?.data?.staff_id)}
                            // styles={colourStylesTitle}
                            isSearchable={true}
                            options={staffByProfession}
                            getOptionLabel={(option) => `${option.first_name} ${option.last_name} (Bluesky Id: ${option.staffer_id})`}
                            getOptionValue={(option) => option.staff_id}
                            components={{ Control }}
                        // className="ml-auto" // This class will push the Select to the right
                        />
                    </div>
                    {/* {((selectedEvent?.data?.status !== 5 && selectedEvent?.data?.status !== 6 && selectedEvent?.data?.status !== 7) && selectedEvent?.data?.is_exported === 0) &&
                        <button className="btn btn-sm btn-success me-2 mt-2" onClick={() => assignToOtherStaff(selectedEvent)}>
                            <i className="fas fa-user-plus"></i> {selectedEvent?.data?.staff_id ? "Change" : "Assign"} Caregiver
                        </button>} */}
                </li>

                <li className="list-group-item">
                    <i className="fas fa-id-badge  text-primary"></i> <strong>Profession-Specilaity:</strong> {selectedEvent?.data?.shift_data?.specialty_name ? selectedEvent?.data?.shift_data?.specialty_name : degree?.find(deg => deg.Id == selectedEvent?.data?.shift_data?.profession)?.Name}
                </li>
                <li className="list-group-item">
                    <i className="fas fa-clipboard text-primary"></i> <strong>Shift Category:</strong> {selectedEvent?.data?.request?.category === 'PD' ? "Per Diem" : "Local Contract"}
                </li>

                <li className="list-group-item">
                    <i className="far fa-calendar-check text-primary"></i> <strong>Shift Status:</strong> {selectedEvent?.data?.status === 1 ? "Open" : selectedEvent?.data?.status === 2 ? "Confirmed" : (selectedEvent?.data?.status === 5) ? "Cancelled by caregiver" : (selectedEvent?.data?.status === 6) ? "Cancelled by facility" : selectedEvent?.data?.status === 7 ? "Closed" : "No Update"}
                </li>
                <li className="list-group-item d-flex align-items-center">
                    {/* Calendar Icon */}
                    <i className="far fa-calendar-alt text-primary me-1"></i>

                    {/* Date Label */}
                    <strong> Date: </strong>

                    {/* Conditional rendering based on status */}
                    {
                        selectedEvent?.data?.is_exported === 1 || [4, 5, 6].includes(selectedEvent?.data?.status) ? (
                            // Display formatted date if status is not 1
                            <span className="ml-2">
                                {moment(selectedEvent?.data?.slot_date).format('MMMM Do, YYYY')}
                            </span>
                        ) : (
                            // Display MultiDatePicker component if status is 1
                            <div className="ps-1">
                                <MultiDatePicker onChange={handleDateChange} multiple={false} value={editSlot?.slot_date} />
                            </div>
                        )
                    }
                </li>
                <li className="list-group-item d-flex align-items-center">
                    <i className="far fa-clock text-primary"></i> <strong className='me-2 ms-1'> Shift Type: </strong>

                    {
                        selectedEvent?.data?.is_exported === 1 || [4, 5, 6].includes(selectedEvent?.data?.status) ? selectedEvent?.data?.slot_type :
                            <SelectField
                                name="shift_type"
                                label={""}
                                onChange={handleSlotTypeChange}
                                value={editSlot?.slot_type}
                                required
                                data={global.config.shiftType}
                                optionLabel={"name"}
                                optionValue={"value"}
                            />
                    }

                </li>
                <li className="list-group-item d-flex align-items-center">
                    {/* Calendar Icon */}
                    <i className="far fa-calendar-alt text-primary me-1"></i>

                    {/* Date Label */}
                    <strong> Time: </strong>

                    {/* Conditional rendering based on status */}
                    {
                        selectedEvent?.data?.is_exported === 1 || [4, 5, 6].includes(selectedEvent?.data?.status) ? (
                            // Display formatted date if status is not 1
                            <span className="ml-2">
                                {moment(selectedEvent?.start).format('hh:mm A')} - {moment(selectedEvent?.end).format('hh:mm A')}
                            </span>
                        ) : (
                            // Display MultiDatePicker component if status is 1
                            <div className="ps-1">
                                <input type="time" onChange={handleSlotStartChange} value={editSlot?.slot_start_time} />
                                <small> To </small>
                                <input type="time" onChange={handleSlotEndChange} value={editSlot?.slot_end_time} />
                            </div>
                        )
                    }
                </li>

                {selectedEvent?.data?.remark && <li className="list-group-item">
                    <i className="fas fa-comment text-primary"></i> <strong>Comment:</strong> {selectedEvent?.data?.remark}
                </li>}
                {selectedEvent?.data?.status === 1 && <li className="list-group-item">
                    <i className="far fa-copy  text-primary"></i> : <button
                        className="btn btn-sm btn-info ms-2 pointer"
                        onClick={() => {
                            onCopySlot(selectedEvent?.data)
                        }}
                    >Copy Shift URL</button>
                </li>}
            </ul>}
            {selectedEvent?.data?.category === 'TC' &&
                <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                        <i className="fas fa-user text-primary"></i> <strong>Caregiver:</strong> {selectedEvent?.data?.first_name} {selectedEvent?.data?.last_name}
                    </li>
                    <li className="list-group-item">
                        <i className="fas fa-id-badge  text-primary"></i> <strong>Shift Ref:</strong> {selectedEvent?.data?.id}
                    </li>
                    <li className="list-group-item">
                        <i className="fas fa-clipboard text-primary"></i> <strong>Shift Category:</strong> Traveler Contract
                    </li>
                    <li className="list-group-item">
                        <i className="far fa-clock text-primary"></i> <strong>Shift Type:</strong> {selectedEvent?.data?.shift_type}
                    </li>
                    <li className="list-group-item">
                        <i className="far fa-calendar-check text-primary"></i> <strong>Shift Status:</strong> {selectedEvent?.data?.status === 1 ? "Open" : selectedEvent?.data?.status === 5 ? "Confirmed" : selectedEvent?.data?.status === 2 ? "Cancelled" : selectedEvent?.data?.status === 3 ? "Closed" : "No Update"}
                    </li>
                    <li className="list-group-item">
                        <i className="far fa-calendar-alt text-primary"></i> <strong>Start Date:</strong> {moment(selectedEvent?.data?.start_date).format('MMMM Do, YYYY')}
                    </li>
                    <li className="list-group-item">
                        <i className="far fa-calendar-alt text-primary"></i> <strong>End Date:</strong> {moment(selectedEvent?.data?.end_date).format('MMMM Do, YYYY')}
                    </li>
                    <li className="list-group-item">
                        <i className="far fa-clock text-primary"></i> <strong>Time:</strong> {moment(selectedEvent?.start).format('hh:mm A')} - {moment(selectedEvent?.end).format('hh:mm A')}
                    </li>
                    <li className="list-group-item">
                        <i className="far fa-clock text-primary"></i> <strong>Hours Per Week:</strong> {selectedEvent?.data?.hours_per_week}
                    </li>

                    <li className="list-group-item">
                        <i className="fas fa-hashtag text-primary"></i> <strong>No. Of Week:</strong> {selectedEvent?.data?.no_of_weeks}
                    </li>
                    <li className="list-group-item">
                        <i className="fas fa-dollar-sign text-primary"></i> <strong>Pay Details:</strong> {selectedEvent?.data?.pay_details}
                    </li>
                    <li className="list-group-item">
                        <i className="fas fa-graduation-cap text-primary"></i> <strong>Qualifications:</strong> {selectedEvent?.data?.qualifications}
                    </li>
                    {selectedEvent?.data?.description && <li className="list-group-item">
                        <i className="fas fa-comment text-primary"></i> <strong>Comment:</strong> {selectedEvent?.data?.description}
                    </li>}

                </ul>}

            <div className="card-footer d-flex justify-content-center ">
                {/* {((selectedEvent?.data?.is_exported !== 1 || [4, 5, 6].includes(!selectedEvent?.data?.status)) && selectedEvent?.data?.is_exported === 0) &&
                    <button className="btn btn-sm btn-success me-2" onClick={() => slotChanges(selectedEvent)}>
                        <i className="fas fa-save"></i> Update Details
                    </button>}
                {((selectedEvent?.data?.status === 2 && selectedEvent?.data?.staff_id)) && <button className="btn btn-sm btn-danger me-2" onClick={() => onChangeSlotStatus(selectedEvent, 'cancel')}>
                    <i className="fas fa-times"></i> Cancel By Facility
                </button>}
                {((selectedEvent?.data?.status === 2 && selectedEvent?.data?.staff_id)) && <button className="btn btn-sm btn-danger me-2" onClick={() => onChangeSlotStatus(selectedEvent, 'cancel-caregiver')}>
                    <i className="fas fa-times"></i>  Cancel By Caregiver
                </button>}
                {(selectedEvent?.data?.status === 1 && selectedEvent?.data?.staff_id) && <button className="btn btn-sm btn-success me-2" onClick={() => onChangeSlotStatus(selectedEvent, 'approve')}>
                    <i className="fas fa-check"></i> Approve
                </button>}
                {(selectedEvent?.data?.status === 1 && selectedEvent?.data?.staff_id && selectedEvent?.data?.is_exported === 0) && <button className="btn btn-sm btn-danger me-2" onClick={() => onChangeSlotStatus(selectedEvent, 'reject-close')}>
                    <i className="fas fa-times"></i> Reject & Close
                </button>}
                {(selectedEvent?.data?.status === 1 && selectedEvent?.data?.staff_id && selectedEvent?.data?.is_exported === 0) && <button className="btn btn-sm btn-danger me-2" onClick={() => onChangeSlotStatus(selectedEvent, 'reject-open')}>
                    <i className="fas fa-times"></i> Reject & Open
                </button>}
                {selectedEvent?.data?.status === 1 && <button className="btn btn-sm btn-danger me-2" onClick={() => onChangeSlotStatus(selectedEvent, 'delete')}>
                    <i className="fas fa-trash"></i> Delete
                </button>} */}
                <button className="btn btn-primary" onClick={handleCloseModal}>
                    Close
                </button>
            </div>
        </div>
    );
};
export const ViewEventCaregiver = ({
    selectedEvent,
    onChangeSlotStatus,
    handleCloseModal,
    degree,
    handleDateChange,
    editSlot,
    handleSlotStartChange,
    handleSlotEndChange,
    handleSlotTypeChange,
    slotChanges
}) => {
    return (
        <div className="">
            <h4 className="ml-2 text-primary">{selectedEvent?.organization_name}</h4>
            <li className="list-group-item">
                <i className="fas fa-hashtag  text-primary"></i> <strong>Order Ref:</strong> {selectedEvent?.data?.shift_id}
            </li>
            {selectedEvent?.data?.category !== 'TC' && <ul className="list-group list-group-flush">
                <li className="list-group-item">
                    <i className="fas fa-user text-primary"></i> <strong>Contact Person:</strong> {selectedEvent?.data?.request?.user?.first_name} {selectedEvent?.data?.request?.user?.last_name}
                </li>
                <li className="list-group-item">
                    <i className="fas fa-building text-primary"></i> <strong>Facility:</strong><b className='text-info'> {selectedEvent?.data?.request?.facility?.organization_name}</b></li>
                <li className="list-group-item">
                    <i className="fas fa-clipboard text-primary"></i> <strong>Shift Category:</strong> {selectedEvent?.data?.request?.category === 'PD' ? "Per Diem" : "Local Contract"}
                </li>
                <li className="list-group-item">
                    <i className="fas fa-id-badge  text-primary"></i> <strong>Profession-Specilaity:</strong> {selectedEvent?.data?.request?.specialty_name ? selectedEvent?.data?.request?.specialty_name : degree?.find(deg => deg.Id == selectedEvent?.data?.request?.profession)?.Name}
                </li>
                <li className="list-group-item">
                    <i className="far fa-clock text-primary"></i> <strong>Shift Type:</strong> {selectedEvent?.data?.slot_type}
                </li>
                <li className="list-group-item">{console.log(selectedEvent?.data?.request)}
                    <i className="fas fa-dollar-sign text-primary"></i><strong>&nbsp;&nbsp;Cost:</strong> {(selectedEvent?.data?.request?.rate) ? <span className="text-success" id="per-cost">{(selectedEvent?.data?.request?.rate * calculateTotalHr(editSlot?.slot_start_time, editSlot?.slot_end_time.selectedEvent?.data?.request?.break_time)?.toFixed(2))}</span> : "N/A"}

                </li>
                <li className="list-group-item d-flex align-items-center">
                    <i className="far fa-calendar-alt text-primary me-1"></i> <strong>Date:</strong>
                    {/* Conditional rendering based on status */}
                    {
                        selectedEvent?.data?.is_exported === 1 || [4, 5, 6].includes(selectedEvent?.data?.status) ? (
                            // Display formatted date if status is not 1
                            <span className="ml-2">
                                {moment(selectedEvent?.data?.slot_date).format('MMMM Do, YYYY')}
                            </span>
                        ) : (
                            // Display MultiDatePicker component if status is 1
                            <div className="ps-1">
                                <MultiDatePicker minDate={new Date()} onChange={handleDateChange} multiple={false} value={editSlot?.slot_date} />
                            </div>
                        )
                    }
                </li>
                <li className="list-group-item d-flex align-items-center">
                    <i className="far fa-clock text-primary"></i> <strong className='me-2 ms-1'> Shift Type: </strong>

                    {
                        selectedEvent?.data?.is_exported === 1 || [4, 5, 6].includes(selectedEvent?.data?.status) ? selectedEvent?.data?.slot_type :
                            <SelectField
                                name="shift_type"
                                label={""}
                                onChange={handleSlotTypeChange}
                                value={editSlot?.slot_type}
                                required
                                data={global.config.shiftType}
                                optionLabel={"name"}
                                optionValue={"value"}
                            />
                    }

                </li>
                <li className="list-group-item d-flex align-items-center">
                    {/* Calendar Icon */}
                    <i className="far fa-calendar-alt text-primary me-1"></i>

                    {/* Date Label */}
                    <strong> Time: </strong>

                    {/* Conditional rendering based on status */}
                    {
                        selectedEvent?.data?.is_exported === 1 || [4, 5, 6].includes(selectedEvent?.data?.status) ? (
                            // Display formatted date if status is not 1
                            <span className="ml-2">
                                {moment(selectedEvent?.start).format('hh:mm A')} - {moment(selectedEvent?.end).format('hh:mm A')}
                            </span>
                        ) : (
                            // Display MultiDatePicker component if status is 1
                            <div className="ps-1">
                                <input type="time" onChange={handleSlotStartChange} value={editSlot?.slot_start_time} />
                                <small> To </small>
                                <input type="time" onChange={handleSlotEndChange} value={editSlot?.slot_end_time} />
                            </div>
                        )
                    }
                </li>

                {selectedEvent?.data?.remark && <li className="list-group-item">
                    <i className="fas fa-comment text-primary"></i> <strong>Client Comment:</strong> {selectedEvent?.data?.remark}
                </li>}
            </ul>}
            {selectedEvent?.data?.category === 'TC' &&
                <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                        <i className="fas fa-user text-primary"></i> <strong>Contact Person:</strong> {selectedEvent?.data?.user?.first_name} {selectedEvent?.data?.user?.last_name}
                    </li>
                    <li className="list-group-item">
                        <i className="fas fa-id-badge  text-primary"></i> <strong>Shift Ref:</strong> {selectedEvent?.data?.id}
                    </li>
                    <li className="list-group-item">
                        <i className="fas fa-clipboard text-primary"></i> <strong>Shift Category:</strong> Traveler Contract
                    </li>
                    <li className="list-group-item">
                        <i className="far fa-clock text-primary"></i> <strong>Shift Type:</strong> {selectedEvent?.data?.shift_type}
                    </li>
                    <li className="list-group-item">
                        <i className="far fa-calendar-check text-primary"></i> <strong>Shift Status:</strong> {selectedEvent?.data?.status === 1 ? "Pending" : selectedEvent?.data?.status === 5 ? "Confirmed" : selectedEvent?.data?.status === 2 ? "Cancelled" : selectedEvent?.data?.status === 3 ? "Closed" : "No Update"}
                    </li>
                    <li className="list-group-item">
                        <i className="far fa-calendar-alt text-primary"></i> <strong>Start Date:</strong> {moment(selectedEvent?.data?.start_date).format('MMMM Do, YYYY')}
                    </li>
                    <li className="list-group-item">
                        <i className="far fa-calendar-alt text-primary"></i> <strong>End Date:</strong> {moment(selectedEvent?.data?.end_date).format('MMMM Do, YYYY')}
                    </li>
                    <li className="list-group-item">
                        <i className="far fa-clock text-primary"></i> <strong>Time:</strong> {moment(selectedEvent?.start).format('hh:mm A')} - {moment(selectedEvent?.end).format('hh:mm A')}
                    </li>
                    <li className="list-group-item">
                        <i className="far fa-clock text-primary"></i> <strong>Hours Per Week:</strong> {selectedEvent?.data?.hours_per_week}
                    </li>

                    <li className="list-group-item">
                        <i className="fas fa-hashtag text-primary"></i> <strong>No. Of Week:</strong> {selectedEvent?.data?.no_of_weeks}
                    </li>
                    <li className="list-group-item">
                        <i className="fas fa-dollar-sign text-primary"></i> <strong>Pay Details:</strong> {selectedEvent?.data?.pay_details}
                    </li>
                    <li className="list-group-item">
                        <i className="fas fa-graduation-cap text-primary"></i> <strong>Qualifications:</strong> {selectedEvent?.data?.qualifications}
                    </li>
                    {selectedEvent?.data?.description && <li className="list-group-item">
                        <i className="fas fa-comment text-primary"></i> <strong>Client Comment:</strong> {selectedEvent?.data?.description}
                    </li>}
                </ul>}

            <div className="card-footer d-flex justify-content-center">
                {(selectedEvent?.data?.is_exported === 0 || [4, 5, 6].includes(!selectedEvent?.data?.status) && selectedEvent?.data?.is_exported === 0) &&
                    <button className="btn btn-sm btn-success me-2" onClick={() => slotChanges(selectedEvent)}>
                        <i className="fas fa-save"></i> Update Details
                    </button>}
                {((selectedEvent?.data?.status === 2 && selectedEvent?.data?.staff_id) && selectedEvent?.data?.is_exported === 0) && <button className="btn btn-sm btn-danger me-2" onClick={() => onChangeSlotStatus(selectedEvent, 'cancel')}>
                    <i className="fas fa-times"></i> Cancel By Facility
                </button>}
                {((selectedEvent?.data?.status === 2 && selectedEvent?.data?.staff_id) && selectedEvent?.data?.is_exported === 0) && <button className="btn btn-sm btn-danger me-2" onClick={() => onChangeSlotStatus(selectedEvent, 'cancel-caregiver')}>
                    <i className="fas fa-times"></i>  Cancel By Caregiver
                </button>}
                <button className="btn btn-sm btn-primary" onClick={handleCloseModal}>
                    Close
                </button>
            </div>
        </div>
    )
}
export const CustomEventCaregiver = ({ event }) => (
    <div className="event-wrapper" >
        <div>
            <small> {event.title}</small><br />
            <small> {event?.organization_name}</small>
            {event?.data?.category !== 'TC' && <>
                <div className={(event?.data?.status === 3 || event?.data?.status === 5 || event?.data?.status === 6) ? 'text-decoration-line-through' : ''}>
                    <small>{`Time: ${moment(event.start).format('hh:mm A')} - ${moment(event.end).format('hh:mm A')}`}</small>
                </div>
                <small>Status: {event?.data?.status === 1 ? "Pending By Facility" : event?.data?.status === 2 ? "Confirmed" : event?.data?.status === 3 ? "Rejected" : event?.data?.status === 4 ? "Extention pending" : event?.data?.status === 5 ? "Cancelled by caregiver" : event?.data?.status === 6 ? "Cancelled by facility" : event?.data?.status === 7 ? "Completed" : "No Update"}</small>
            </>}
            {event?.data?.category === 'TC' && <>
                <div className={(event?.data?.status === 2) ? 'text-decoration-line-through' : ''}>
                    <small>{`Start: ${moment(event.start).format('hh:mm A')} - ${moment(event.end).format('hh:mm A')}`}</small>
                </div>
                <small>Status: {event?.data?.status === 0 ? "Open" : event?.data?.status === 5 ? "Confirmed" : (event?.data?.status === 2) ? "Cancelled" : event?.data?.status === 3 ? "Closed" : "No Update"}</small>
            </>}
        </div>
        <style jsx="true">{`
      
      .event-title {
        font-size: 12px;
      }
      .event-details {
        font-size: 12px;
      }
   
      .event-status {
        font-size: 12px;
      }
      .text-decoration-line-through {
        text-decoration: line-through;
      }
      .event-wrapper {
          overflow-x: auto;
        }
      @media screen and (max-width: 600px) {
        .event-title {
          font-size: 12px;
        }
        .event-wrapper {
          overflow-x: auto;
        }
        .event-details {
          font-size: 12px;
        }
        .event-time, .event-status {
          font-size: 10px;
        }
      }
    `}</style>
    </div >
);
export const AddShiftClient = ({
    facilityList,
    clientData,
    selectedDate,
    degree,
    specialties,
    setValues,
    setDates,
    setAllDates,
    assignShift,
    setSpecialties,
    multi,
    allDates,
    dates,
    ClearButton,
    onDismissAddShift,
}) => (

    <div className="wow fadeIn" data-wow-delay="0.03s">
        {/* <CModalHeader> */}
        <CModalTitle className='text-info'>Assign New Shift For {selectedDate?.format()}</CModalTitle>
        {/* </CModalHeader> */}
        <CModalBody>
            <>
                <Formik

                    initialValues={{
                        profession: "",
                        specialty: "",
                        client_id: clientData?.id,
                        facility_id: clientData?.facility_id,
                        category: selectedDate ? 'PD' : "",
                        nop: "",
                        hours_per_week: "",
                        no_of_weeks: "",
                        pay_detail: "",
                        start_date: "",
                        end_date: "",
                        start_time: "",
                        end_time: "",
                        qualifications: "",
                        specialty_name: "",
                        region: clientData?.region || "",
                        address: clientData?.address || "",
                        state: clientData?.state || "",
                        city: clientData?.city || "",
                        zipcode: clientData?.zipcode || "",
                        shift_type: "",
                        description: "",
                        facility_code: clientData?.facility_code || "",
                        unit_code: clientData?.unit_code || "",
                        tenure:"",
                        alltime: "",
                        alldate: "",
                        alltype: "",
                        break_time: 0,
                        rate: 0
                    }}
                    onSubmit={values =>
                        assignShift(values)
                    }
                    validationSchema={AddShiftSchema}
                    enableReinitialize={true}
                    validateOnChange={false}
                >
                    {
                        ({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched
                        }) =>
                        (
                            <form onSubmit={handleSubmit}>
                                <div className="row ">
                                    <div className="col-sm-6">
                                        <div className="did-floating-label-content">
                                            <select className="did-floating-dropdown"
                                                name="profession"
                                                value={values.profession}
                                                required
                                                onChange={(e) => {
                                                    if (e.target.value) {
                                                        setFieldValue('profession', e.target.value)
                                                        setFieldValue('tenure', multi)
                                                        HomeStore.getSpecialtiesListData(e.target.value)
                                                        if (facilityList?.find(({ facility_id }) => facility_id == values.facility_id)?.facilities?.rates?.length > 0) {
                                                            if (facilityList?.find(({ facility_id }) => facility_id == values.facility_id)?.facilities?.rates?.find(x => x.profession_id == e.target.value) !== undefined) {
                                                                setFieldValue("rate", facilityList?.find(({ facility_id }) => facility_id == values.facility_id)?.facilities?.rates?.find(x => x.profession_id == e.target.value)?.cost);
                                                            }
                                                        }

                                                    } else {
                                                        setSpecialties([])
                                                    }
                                                }}
                                            >
                                                <option
                                                    value=""
                                                >
                                                </option>
                                                {degree && degree?.map((option, index) => (
                                                    <option
                                                        key={index}
                                                        value={option.Id}
                                                    >
                                                        {option.Name}
                                                    </option>
                                                ))}
                                            </select>
                                            <label className={values.profession == "" ? "did-floating-label did-floating-label-down did-floating-labe-astrisk" : "did-floating-label did-floating-label-top did-floating-labe-astrisk"}>Profession</label>
                                        </div>
                                        {errors.profession && touched.profession ? (
                                            <div className="error-message">{errors.profession}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-sm-6 pb-lg-3 pb-md-2">
                                        <SelectField
                                            name="specialty"
                                            label={HomeStore.loading ? "Loading..." : specialties?.length === 0 ? "No Specialty Found" : "Specialty"}
                                            disabled={!values.profession || HomeStore.loading}
                                            value={values.specialty}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            data={specialties}
                                            optionLabel={"Name"}
                                            optionValue={"Id"}
                                            required={specialties?.length !== 0}
                                        />
                                    </div>
                                    <div className="col-sm-6 pb-lg-3 pb-md-2">
                                        <Select
                                            onChange={(e) => {
                                                setFieldValue("facility_id", e.facility_id);
                                                if (e?.facilities?.rates?.length > 0) {
                                                    if (e?.facilities?.rates?.find(x => x.profession_id == values?.profession) !== undefined) {
                                                        setFieldValue("rate", e?.facilities?.rates?.find(x => x.profession_id == values?.profession)?.cost);
                                                    }
                                                }
                                            }}
                                            placeholder={''}
                                            label={'Select Facility'}
                                            required={true}
                                            value={facilityList?.filter(({ facility_id }) => facility_id === values.facility_id) || ""}
                                            styles={colourStylesState}
                                            isSearchable={true}
                                            options={facilityList}
                                            getOptionLabel={(option) => `${option.facilities.organization_name}`}
                                            getOptionValue={(option) => option.facility_id}
                                            components={{ Control }}
                                        />
                                        {errors.facility_id && touched.facility_id ? (
                                            <div className="error-message">{errors.facility_id}</div>
                                        ) : null}
                                    </div>

                                    <div className="col-sm-6 pb-lg-3 pb-md-2">
                                        <SelectField
                                            name="category"
                                            label={"Category"}
                                            value={values.category}
                                            onChange={(e) => {
                                                setFieldValue("category", e.target.value)
                                                setValues([])
                                                setDates([])
                                                setAllDates([])
                                                setFieldValue("tenure", "")
                                                setFieldValue("alldate", "")
                                                setFieldValue("alltime", "")
                                                setFieldValue("alltype", "")
                                            }}
                                            onBlur={handleBlur}
                                            required
                                            data={global.config.shiftCaregory}
                                            optionLabel={"label"}
                                            optionValue={"value"}
                                        />
                                        {errors.category && touched.category ? (
                                            <div className="error-message">{errors.category}</div>
                                        ) : null}
                                    </div>

                                    {values.category === 'TC' &&
                                        <>
                                            <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                <SelectField
                                                    name="shift_type"
                                                    label={"Shift Type"}
                                                    value={values.shift_type}
                                                    onChange={(event) => {
                                                        setFieldValue("shift_type", event.target.value)
                                                        setFieldValue(`start_time`, global.config.shiftTimeRange?.find(x => x.label === event.target.value)?.value?.start)
                                                        setFieldValue(`end_time`, global.config.shiftTimeRange?.find(x => x.label === event.target.value)?.value?.end)
                                                    }}
                                                    onBlur={handleBlur}
                                                    required
                                                    data={global.config.shiftType}
                                                    optionLabel={"name"}
                                                    optionValue={"value"}
                                                />
                                                {errors.shift_type && touched.shift_type ? (
                                                    <div className="error-message">{errors.shift_type}</div>
                                                ) : null}
                                            </div>

                                            <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                <InputField
                                                    className={"did-floating-input"}
                                                    classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                    type="text"
                                                    name="pay_detail"
                                                    label={"Pay Detail"}
                                                    value={values.pay_detail}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    required={true}
                                                />
                                                {errors.pay_detail && touched.pay_detail ? (
                                                    <div className="error-message">{errors.pay_detail}</div>
                                                ) : null}
                                            </div>

                                            <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                <InputField
                                                    className={"did-floating-input"}
                                                    classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                    type="date"
                                                    name="start_date"
                                                    label={"Start Date"}
                                                    value={values.start_date}
                                                    min={new Date().toISOString().split('T')[0]}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    required={true}
                                                />
                                                {errors.start_date && touched.start_date ? (
                                                    <div className="error-message">{errors.start_date}</div>
                                                ) : null}
                                            </div>
                                            <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                <InputField
                                                    className={"did-floating-input"}
                                                    classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                    type="date"
                                                    name="end_date"
                                                    label={"End Date"}
                                                    value={values.end_date}
                                                    min={values.start_date ? values.start_date : new Date().toISOString().split('T')[0] || values.start_date}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    required={true}
                                                />
                                                {errors.end_date && touched.end_date ? (
                                                    <div className="error-message">{errors.end_date}</div>
                                                ) : null}
                                            </div>
                                            <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                <InputField
                                                    className={"did-floating-input"}
                                                    classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                    type="time"
                                                    name="start_time"
                                                    label={"Start Time"}
                                                    value={values.start_time}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    required={true}
                                                />
                                                {errors.start_time && touched.start_time ? (
                                                    <div className="error-message">{errors.start_time}</div>
                                                ) : null}
                                            </div>
                                            <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                <InputField
                                                    className={"did-floating-input"}
                                                    classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                    type="time"
                                                    name="end_time"
                                                    label={"End Time"}
                                                    value={values.end_time}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    required={true}
                                                />
                                                {errors.end_time && touched.end_time ? (
                                                    <div className="error-message">{errors.end_time}</div>
                                                ) : null}
                                            </div>
                                            <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                <InputField
                                                    className={"did-floating-input"}
                                                    classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                    type="number"
                                                    name="hours_per_week"
                                                    label={"Guaranteed Hours Per Week"}
                                                    value={values.hours_per_week}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    required={true}
                                                    min={1}
                                                />
                                                {errors.hours_per_week && touched.hours_per_week ? (
                                                    <div className="error-message">{errors.hours_per_week}</div>
                                                ) : null}
                                            </div>
                                            <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                <InputField
                                                    className={"did-floating-input"}
                                                    classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                    type="number"
                                                    name="no_of_weeks"
                                                    label={"# of Weeks"}
                                                    value={values.no_of_weeks}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    required={true}
                                                    min={1}
                                                />
                                                {errors.no_of_weeks && touched.no_of_weeks ? (
                                                    <div className="error-message">{errors.no_of_weeks}</div>
                                                ) : null}
                                            </div>
                                            <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                <InputField
                                                    className={"did-floating-input"}
                                                    classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                    type="number"
                                                    name="nop"
                                                    label={"# of Openings"}
                                                    value={values.nop}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    required={true}
                                                    min={1}
                                                />
                                                {errors.nop && touched.nop ? (
                                                    <div className="error-message">{errors.nop}</div>
                                                ) : null}
                                            </div>
                                            <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                <InputField
                                                    className={"did-floating-input"}
                                                    classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                    type="text"
                                                    name="qualifications"
                                                    label={"Qualifications"}
                                                    value={values.qualifications}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    required={true}
                                                />
                                                {errors.qualifications && touched.qualifications ? (
                                                    <div className="error-message">{errors.qualifications}</div>
                                                ) : null}
                                            </div>
                                        </>
                                    }
                                    <div className="col-sm-6 pb-lg-3 pb-md-2">
                                        {values.category === 'PD' ?
                                            <DatePicker
                                                disabled={values.category === null}
                                                required
                                                calendarPosition="top-left"
                                                placeholder="Select Dates"
                                                format="MM/DD/YYYY"
                                                inputClass="did-floating-dropdown"
                                                multiple
                                                value={multi?.sort((a, b) => new Date(a) - new Date(b))}
                                                minDate={new Date()}
                                                onChange={(e) => {
                                                    setValues(e)
                                                    setFieldValue('tenure', e)
                                                }}
                                                plugins={[
                                                    weekends()
                                                ]}
                                            /> : values.category === "TR" ?
                                                <DatePicker
                                                    disabled={values.category === null}
                                                    placeholder="Select Start & End Date"
                                                    calendarPosition="top-left"
                                                    required
                                                    format="MM/DD/YYYY"
                                                    inputClass="did-floating-dropdown"
                                                    multiple
                                                    range
                                                    numberOfMonths={2}
                                                    rangeHover
                                                    value={dates}
                                                    minDate={new Date()}
                                                    onChange={(dateObjects) => {
                                                        // let allDtOne = dateObjects?.map((date) => getAllDatesInRange([date[0], date[1]]))?.flat();
                                                        // let finalOne = allDtOne?.map((x) => x?.format?.())
                                                        // let finalTwo = allDates?.map((x) => x?.format?.())
                                                        // console.log(finalOne?.filter(date => finalTwo?.includes(date)),"find");
                                                        // if (allDates?.find(x => dateObjects?.map((date) => getAllDatesInRange([date[0], date[1]]))?.flat()?.some(new Date(x)))) {
                                                        setDates(dateObjects)
                                                        setAllDates(dateObjects?.map((date) => getAllDatesInRange([date[0], date[1]]))?.flat())
                                                        setFieldValue('tenure', dateObjects?.map((date) => getAllDatesInRange([date[0], date[1]]))?.flat())

                                                        // } else {
                                                        //     console.log("not find");
                                                        // }
                                                    }}
                                                    plugins={[
                                                        weekends(),
                                                        <ClearButton setFiled={setFieldValue} position="bottom" />
                                                    ]}
                                                /> : ""}
                                    </div>
                                    <div className="col-sm-12 pb-lg-12 pb-md-2">
                                        <div className="d-flex align-items-center">
                                            <span>Break Time:</span>
                                            <div className="d-flex align-items-center ms-3">
                                                <CFormCheck
                                                    type="radio"
                                                    name="break_time"
                                                    id="30min"
                                                    value="30"
                                                    checked={values.break_time == 30}
                                                    onChange={handleChange}
                                                    label="30 minutes"
                                                />
                                                <CFormCheck
                                                    className="ms-3 me-3"
                                                    type="radio"
                                                    name="break_time"
                                                    id="1hr"
                                                    value="60"
                                                    checked={values.break_time == 60}
                                                    onChange={handleChange}
                                                    label="1 hour"
                                                />
                                                <CFormCheck
                                                    type="radio"
                                                    name="break_time"
                                                    id="none"
                                                    value="0"
                                                    checked={values.break_time == 0}
                                                    onChange={handleChange}
                                                    label="None"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        {/* FOR TR */}
                                        {allDates?.length > 0 &&
                                            <div>
                                                <div className="d-flex justify-content-start mt-2 mb-2"><span className="">Shifts
                                                    Approx. Cost:<b className="text-success"> {values.rate ? ("$" + values.rate) + "/hr" : "N/A"}</b>
                                                    ,&nbsp;Approx. Total Cost:<b className="text-danger"> ${calculateDomIdSum()}</b>
                                                </span>
                                                </div>
                                                <ul className="list-unstyled row">
                                                    <FieldArray name="tenure">
                                                        {({ push, remove, form }) => (
                                                            values?.tenure.length !== 0 && values?.tenure?.sort((a, b) => new Date(a) - new Date(b))?.map((date, index) =>
                                                                <div className="card p-2 col-6 col-lg-12 mb-3 border" key={index}>
                                                                    <div className="row align-items-center">
                                                                        <div className="col-5 pt-1 pb-2" >
                                                                            <SelectField
                                                                                id={"slot-type"}
                                                                                label={"Shift Type"}
                                                                                name={`alltype[${index}]`}
                                                                                onChange={(event) => {
                                                                                    setFieldValue(`alltype[${index}]`, event.target.value)
                                                                                    setFieldValue(`alltime[${index}]`, [global.config.shiftTimeRange?.find(x => x.label === event.target.value)?.value?.start, global.config.shiftTimeRange?.find(x => x.label === event.target.value)?.value?.end])
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                                value={values?.alltype && values?.alltype[index]}
                                                                                required
                                                                                data={global.config.shiftType}
                                                                                optionLabel={"name"}
                                                                                optionValue={"value"}
                                                                            />
                                                                        </div>
                                                                        <div className="col-5 pt-1 pb-2" >
                                                                            {values?.alltime[index]?.indexOf(undefined) === -1 && <span className="text-success me-3"><i className="fas fa-business-time"></i> {calculateLunch(values?.alltime[index][0], values?.alltime[index][1], values.break_time)}</span>}
                                                                            {values.break_time ? <span className="text-success me-3"><i className="fas fa-clock"></i> {values.break_time} Min</span> : ""}
                                                                            {(values.rate && values?.alltime[index]?.length === 2) ? <span className="text-success" id="per-cost"><i className="fas fa-dollar-sign"></i> {(values?.allqty[index] * values.rate * calculateTotalHr(values?.alltime[index][0], values?.alltime[index][1], values.break_time)?.toFixed(2))}</span> : ""}
                                                                        </div>
                                                                    </div>
                                                                    <li>
                                                                        <div className="d-flex align-items-center mb-2">
                                                                            <div className="border border-primary all-slot-border">
                                                                                <input className="border-0 start-date-width" id={`alldate[${index}]`} name={`alldate[${index}]`} value={date?.format()} />
                                                                                <br />   <Field id={`alltime[${index}]`} name={`alltime[${index}]`} render={({ field, form }) => <TimeRangePickerField  {...field} {...form} firstTime={values?.alltime?.length > 0 && values?.alltime[0]} index={index} />} />
                                                                                <input
                                                                                    className="slot-quantity-width"
                                                                                    placeholder="No. Of Positions"
                                                                                    type="number"
                                                                                    required
                                                                                    name={`allqty[${index}]`}
                                                                                    onChange={(event) => {
                                                                                        setFieldValue(`allqty[${index}]`, event.target.value)
                                                                                    }}
                                                                                    onBlur={handleBlur}
                                                                                    value={values?.allqty && values?.allqty[index]}
                                                                                    min="1"
                                                                                />
                                                                            </div>
                                                                            <div className="btn position-absolute top-0 end-0" onClick={() => {
                                                                                setAllDates([
                                                                                    ...allDates.slice(0, index),
                                                                                    ...allDates.slice(index + 1)
                                                                                ]);
                                                                                setDates([
                                                                                    ...allDates.slice(0, index),
                                                                                    ...allDates.slice(index + 1)
                                                                                ]);
                                                                                (values?.alltime?.length !== 0 && values?.alltime?.length >= index) && values?.alltime?.splice(index, 1);
                                                                                (values?.allqty?.length !== 0 && values?.allqty?.length >= index) && values?.allqty?.splice(index, 1);
                                                                                (values?.alltype?.length !== 0 && values?.alltype?.length >= index) && values?.alltype?.splice(index, 1);
                                                                                remove(index)
                                                                            }}>
                                                                                <i className="fas fa-times-circle text-danger" aria-hidden="true"></i>
                                                                            </div>

                                                                            <div className="btn" onClick={() => {
                                                                                const elementToCopy = values.tenure[index];
                                                                                const elementToCopyTime = [undefined, undefined];
                                                                                setFieldValue("tenure", [...values?.tenure, elementToCopy]);
                                                                                (values?.alltime && values?.alltime?.length !== 0 && values?.alltime?.length >= index) && values?.alltime?.splice(index + 1, 0, elementToCopyTime);
                                                                                (values?.allqty && values?.allqty?.length !== 0 && values?.allqty?.length >= index) && values?.allqty?.splice(index + 1, 0, values?.allqty[0]);
                                                                                (values?.alltype && values?.alltype?.length !== 0 && values?.alltype?.length >= index) && values?.alltype?.splice(index + 1, 0, values?.alltype[0]);
                                                                                setAllDates([...allDates, elementToCopy])
                                                                                setDates([...allDates, elementToCopy])
                                                                            }}>
                                                                                <div className="btn btn-outline-success pt-1 pb-1"><i className="fa fa-plus" aria-hidden="true"></i></div>
                                                                            </div>
                                                                            <div className="btn btn-outline-success pt-1 pb-1">
                                                                                <i className="far fa-copy pointer" onClick={() => {
                                                                                    if (values?.alltime[index]?.length === 2 && !values?.alltime[index]?.some(item => item === null || item === undefined)) {
                                                                                        values?.tenure?.map((item, ind) => {
                                                                                            setFieldValue(`alltime[${ind}]`, values?.alltime[index]);
                                                                                            if (values?.allqty?.length && (values?.allqty[index] !== null || values?.allqty[index] !== undefined)) {
                                                                                                setFieldValue(`allqty[${ind}]`, values?.allqty[index])
                                                                                            }
                                                                                            if (values?.alltype?.length && (values?.alltype[index] !== null || values?.alltype[index] !== undefined)) {
                                                                                                setFieldValue(`alltype[${ind}]`, values?.alltype[index])
                                                                                            }
                                                                                        })
                                                                                    } else {
                                                                                        toast.warn("Please select start end time")
                                                                                    }
                                                                                }} data-toggle="tooltip" data-placement="right" title="Copy Shift Type,Start Time,End Time & No Of Positions"></i>
                                                                            </div>   </div>
                                                                        <div className="col-12">
                                                                            <InputField
                                                                                className={"did-floating-input"}
                                                                                type="text"
                                                                                name={`allcomment[${index}]`}
                                                                                onChange={(event) => {
                                                                                    setFieldValue(`allcomment[${index}]`, event.target.value)
                                                                                }}
                                                                                label={"Comments For Caregiver"}
                                                                                value={values?.allcomment && values?.allcomment[index]}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        </div>
                                                                    </li>
                                                                </div>
                                                            )
                                                        )}
                                                    </FieldArray>

                                                </ul>
                                            </div>
                                        }
                                        {/* FOR PD */}
                                        {
                                            multi?.length > 0 && multi &&
                                            <div>
                                                <div className="d-flex justify-content-start mt-2 mb-2"><span className="">Shifts
                                                    Approx. Cost:<b className="text-success"> {values.rate ? ("$" + values.rate) + "/hr" : "N/A"}</b>
                                                    ,&nbsp;Approx. Total Cost:<b className="text-danger"> ${calculateDomIdSum()}</b>
                                                </span>
                                                </div>
                                                <ul className="list-unstyled row">
                                                    <FieldArray name="tenure">
                                                        {({ push, remove, form }) => (
                                                            values?.tenure.length !== 0 && values?.tenure?.sort((a, b) => new Date(a) - new Date(b))?.map((date, index) =>
                                                                <div className="card p-2 col-6 col-lg-12 mb-3 border" key={index}>
                                                                    <div className="row align-items-center">
                                                                        <div className="col-5 pt-1 pb-2" >
                                                                            <SelectField
                                                                                id={"slot-type"}
                                                                                label={"Shift Type"}
                                                                                name={`alltype[${index}]`}
                                                                                onChange={(event) => {
                                                                                    setFieldValue(`alltype[${index}]`, event.target.value)
                                                                                    const shiftTime = global.config.shiftTimeRange?.find(x => x.label === event.target.value)?.value;
                                                                                    const startTime = shiftTime?.start;
                                                                                    const endTime = shiftTime?.end;
                                                                                    setFieldValue(`alltime[${index}]`, [startTime, endTime]);
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                                value={values?.alltype && values?.alltype[index]}
                                                                                required
                                                                                data={global.config.shiftType}
                                                                                optionLabel={"name"}
                                                                                optionValue={"value"}
                                                                            />
                                                                        </div>
                                                                        <div className="col-5 pt-1 pb-2" >
                                                                            {values?.alltime[index]?.indexOf(undefined) === -1 && <span className="text-success me-3"><i className="fas fa-business-time"></i> {calculateLunch(values?.alltime[index][0], values?.alltime[index][1], values.break_time)}</span>}
                                                                            {values.break_time ? <span className="text-success me-3"><i className="fas fa-clock"></i> {values.break_time} Min</span> : ""}
                                                                            {(values.rate && values?.alltime[index]?.length === 2 && values?.allqty) ? <span className="text-success" id="per-cost"><i className="fas fa-dollar-sign"></i> {(values?.allqty[index] * values.rate * calculateTotalHr(values?.alltime[index][0], values?.alltime[index][1], values.break_time)?.toFixed(2))}</span> : ""}
                                                                        </div>
                                                                    </div>
                                                                    <li>
                                                                        <div className="d-flex align-items-center mb-2">
                                                                            <div className="border border-primary all-slot-border ">
                                                                                <input className="border-0 start-date-width" id={`alldate[${index}]`} name={`alldate[${index}]`} value={date?.format()} />
                                                                                <br />  <Field id={`alltime[${index}]`} name={`alltime[${index}]`} render={({ field, form }) => <TimeRangePickerField  {...field} {...form} index={index} />} />
                                                                                <input
                                                                                    // className="slot-qty"
                                                                                    className="w-100"
                                                                                    placeholder="No. Of Positions"
                                                                                    type="number"
                                                                                    required
                                                                                    name={`allqty[${index}]`}
                                                                                    onChange={(event) => {
                                                                                        setFieldValue(`allqty[${index}]`, event.target.value)
                                                                                    }}
                                                                                    onBlur={handleBlur}
                                                                                    value={values?.allqty && values?.allqty[index]}
                                                                                    min="1"
                                                                                />
                                                                            </div>
                                                                            {/* DELETE */}
                                                                            <div className="btn position-absolute top-0 end-0" onClick={() => {
                                                                                setValues([
                                                                                    ...multi.slice(0, index),
                                                                                    ...multi.slice(index + 1)
                                                                                ]);
                                                                                (values?.alltime?.length !== 0 && values?.alltime?.length >= index) && values?.alltime?.splice(index, 1);
                                                                                (values?.allqty?.length !== 0 && values?.allqty?.length >= index) && values?.allqty?.splice(index, 1);
                                                                                remove(index)
                                                                            }}>
                                                                                <i className="fas fa-times-circle text-danger" aria-hidden="true"></i>
                                                                            </div>
                                                                            {<div className="btn" onClick={() => {
                                                                                const elementToCopy = values.tenure[index];
                                                                                const elementToCopyTime = [undefined, undefined];
                                                                                setFieldValue("tenure", [...values?.tenure, elementToCopy]);
                                                                                (values?.alltime && values?.alltime?.length !== 0 && values?.alltime?.length >= index) && values?.alltime?.splice(index + 1, 0, elementToCopyTime);
                                                                                (values?.allqty && values?.allqty?.length !== 0 && values?.allqty?.length >= index) && values?.allqty?.splice(index + 1, 0, values?.allqty[0]);
                                                                                (values?.alltype && values?.alltype?.length !== 0 && values?.alltype?.length >= index) && values?.alltype?.splice(index + 1, 0, values?.alltype[0]);
                                                                                setValues([...multi, elementToCopy])
                                                                            }}>
                                                                                <div className="btn btn-outline-success pt-1 pb-1"><i className="fa fa-plus" aria-hidden="true"></i></div>
                                                                            </div>}
                                                                            <div className="btn btn-outline-success pt-1 pb-1">
                                                                                <i className="far fa-copy pointer" onClick={() => {
                                                                                    if (values?.alltime[index]?.length === 2 && !values?.alltime[index].some(item => item === null || item === undefined)) {
                                                                                        values?.tenure?.map((item, ind) => {
                                                                                            setFieldValue(`alltime[${ind}]`, values?.alltime[index]);
                                                                                            if (values?.allqty?.length && (values?.allqty[index] !== null || values?.allqty[index] !== undefined)) {
                                                                                                setFieldValue(`allqty[${ind}]`, values?.allqty[index])
                                                                                            }
                                                                                            if (values?.alltype?.length && (values?.alltype[index] !== null || values?.alltype[index] !== undefined)) {
                                                                                                setFieldValue(`alltype[${ind}]`, values?.alltype[index])
                                                                                            }
                                                                                        })
                                                                                    } else {
                                                                                        toast.warn("Please select start end time")
                                                                                    }
                                                                                }} data-toggle="tooltip" data-placement="right" title="Copy Shift Type,Start Time,End Time & No Of Positions"></i>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <InputField
                                                                                className={"did-floating-input"}
                                                                                type="text"
                                                                                name={`allcomment[${index}]`}
                                                                                onChange={(event) => {
                                                                                    setFieldValue(`allcomment[${index}]`, event.target.value)
                                                                                }}
                                                                                label={"Comments For Caregiver"}
                                                                                value={values?.allcomment && values?.allcomment[index]}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        </div>
                                                                    </li>
                                                                </div>

                                                            )
                                                        )}
                                                    </FieldArray>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                    <div className="col-12">
                                        <label className="pb-2 label">Comments For Staffers Management</label>
                                        <div className="form-floating">
                                            <textarea className="form-control"
                                                placeholder=""
                                                name={'description'}
                                                value={values.description}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                style={{ height: 100 }}>
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center mt-3">
                                    <button className="btn btn-primary w-auto pe-5 ps-5 p-2" type="submit">Submit</button>
                                    <button className="btn btn-primary w-auto pe-5 ps-5 p-2 ms-2" onClick={() => onDismissAddShift()} >Close</button>
                                </div>
                            </form>
                        )
                    }
                </Formik>

            </>
        </CModalBody>
    </div>

);

export const AddShiftCaregiver = ({
    onDismissAddShift,
    facilityList,
    selectedDate,
    caregiverData,
    assignShift,
    clientList,
    degree,
    specialties,
    setDates,
    setAllDates,
    setValues,
    setSpecialties,
    multi,
    dates,
    ClearButton,
    allDates,
}) => (
    <div >
        {/* <CModalHeader> */}
        <CModalTitle className='text-info'>Assign New Shift For {selectedDate?.format()}</CModalTitle>
        {/* </CModalHeader> */}
        <CModalBody>
            <Formik
                initialValues={{
                    profession: "",
                    specialty: "",
                    client_id: "",
                    facility_id: "",
                    staff_id: caregiverData?.id,
                    category: selectedDate ? 'PD' : "",
                    nop: "",
                    hours_per_week: "",
                    no_of_weeks: "",
                    pay_detail: "",
                    start_date: "",
                    end_date: "",
                    start_time: "",
                    end_time: "",
                    qualifications: "",
                    specialty_name: "",
                    tenure: "",
                    shift_type: "",
                    description: "",
                    alltime: "",
                    alldate: "",
                    alltype: "",
                    break_time: 0,
                    rate: 0,
                }}
                onSubmit={values =>
                    assignShift(values)
                }
                validationSchema={AddShiftSchema}
                enableReinitialize={true}
                validateOnChange={false}
            >
                {
                    ({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched
                    }) =>
                    (
                        <form onSubmit={handleSubmit}>
                            <div className="row">

                                <div className="col-sm-6 pb-lg-3 pb-md-2">
                                    <Select
                                        onChange={(e) => {
                                            setFieldValue("facility_id", e.id);
                                            setFieldValue("tenure", multi);
                                            if (e?.rates?.length > 0) {
                                                if (e?.rates?.find(x => x.profession_id == values?.profession) !== undefined) {
                                                    setFieldValue("rate", e?.rates?.find(x => x.profession_id == values?.profession)?.cost);
                                                }
                                            }
                                            AdminStore.getClientsByFacility(e.id);
                                        }}
                                        placeholder={''}
                                        label={'Select Facility'}
                                        required={true}
                                        value={facilityList?.find((item) => item.id === values.facility_id) || ""}
                                        styles={colourStylesState}
                                        isSearchable={true}
                                        options={facilityList}
                                        getOptionLabel={(option) => `${option.organization_name} - ${option.city}, ${option.state}`}
                                        getOptionValue={(option) => option.id}
                                        components={{ Control }}
                                    />
                                    {errors.facility_id && touched.facility_id ? (
                                        <div className="error-message">{errors.facility_id}</div>
                                    ) : null}
                                </div>
                                <div className="col-sm-6 pb-lg-2 pb-md-2">
                                    <Select
                                        onChange={(e) => {
                                            console.log(e);
                                            setFieldValue("client_id", e.user_id);
                                        }}
                                        placeholder={''}
                                        label={'Select F.User'}
                                        required={true}
                                        value={clientList?.find((item) => item.user_id === values.client_id) || ""}
                                        styles={colourStylesState}
                                        isSearchable={true}
                                        options={clientList}
                                        getOptionLabel={(option) => `${option?.users.first_name} ${option?.users.last_name}`}
                                        getOptionValue={(option) => option?.users?.id}
                                        components={{ Control }}
                                    />
                                    {errors.client_id && touched.client_id ? (
                                        <div className="error-message">{errors.client_id}</div>
                                    ) : null}
                                </div>
                                <div className="col-sm-6">
                                    <div className="did-floating-label-content">
                                        <select className="did-floating-dropdown"
                                            name="profession"
                                            value={values.profession}
                                            required
                                            onChange={(e) => {
                                                if (e.target.value) {
                                                    setFieldValue('profession', e.target.value)
                                                    HomeStore.getSpecialtiesListData(e.target.value)
                                                } else {
                                                    setSpecialties([])
                                                }
                                            }}
                                        >
                                            <option
                                                value=""
                                            >
                                            </option>
                                            {degree && degree?.filter(degreeItem => caregiverData?.profession && JSON.parse(caregiverData?.profession)?.some(profId => profId?.profession === degreeItem.Id))?.map((option, index) => (
                                                <option
                                                    key={index}
                                                    value={option.Id}
                                                >
                                                    {option.Name}
                                                </option>
                                            ))}
                                        </select>
                                        <label className={values.profession == "" ? "did-floating-label did-floating-label-down did-floating-labe-astrisk" : "did-floating-label did-floating-label-top did-floating-labe-astrisk"}>Profession</label>
                                    </div>
                                    {errors.profession && touched.profession ? (
                                        <div className="error-message">{errors.profession}</div>
                                    ) : null}
                                </div>
                                <div className="col-sm-6 pb-lg-3 pb-md-2">
                                    <SelectField
                                        name="specialty"
                                        label={HomeStore.loading ? "Loading..." : specialties?.length === 0 ? "No Specialty Found" : "Specialty"}
                                        disabled={!values.profession || HomeStore.loading}
                                        value={values.specialty}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        data={specialties}
                                        optionLabel={"Name"}
                                        optionValue={"Id"}
                                        required={specialties?.length !== 0}
                                    />
                                </div>
                                <div className="col-sm-6 pb-lg-3 pb-md-2">
                                    <SelectField
                                        name="category"
                                        label={"Category"}
                                        value={values.category}
                                        onChange={(e) => {
                                            setFieldValue("category", e.target.value)
                                            setValues([])
                                            setDates([])
                                            setAllDates([])
                                            setFieldValue("tenure", "")
                                            setFieldValue("alldate", "")
                                            setFieldValue("alltime", "")
                                            setFieldValue("alltype", "")
                                        }}
                                        onBlur={handleBlur}
                                        required
                                        data={global.config.shiftCaregory}
                                        optionLabel={"label"}
                                        optionValue={"value"}
                                    />
                                    {errors.category && touched.category ? (
                                        <div className="error-message">{errors.category}</div>
                                    ) : null}
                                </div>

                                {values.category === 'TC' &&
                                    <>
                                        <div className="col-sm-6 pb-lg-2 pb-md-2">
                                            <SelectField
                                                name="shift_type"
                                                label={"Shift Type"}
                                                value={values.shift_type}
                                                onChange={(event) => {
                                                    setFieldValue("shift_type", event.target.value)
                                                    setFieldValue(`start_time`, global.config.shiftTimeRange?.find(x => x.label === event.target.value)?.value?.start)
                                                    setFieldValue(`end_time`, global.config.shiftTimeRange?.find(x => x.label === event.target.value)?.value?.end)
                                                }}
                                                onBlur={handleBlur}
                                                required
                                                data={global.config.shiftType}
                                                optionLabel={"name"}
                                                optionValue={"value"}
                                            />
                                            {errors.shift_type && touched.shift_type ? (
                                                <div className="error-message">{errors.shift_type}</div>
                                            ) : null}
                                        </div>

                                        <div className="col-sm-6 pb-lg-2 pb-md-2">
                                            <InputField
                                                className={"did-floating-input"}
                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                type="text"
                                                name="pay_detail"
                                                label={"Pay Detail"}
                                                value={values.pay_detail}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={true}
                                            />
                                            {errors.pay_detail && touched.pay_detail ? (
                                                <div className="error-message">{errors.pay_detail}</div>
                                            ) : null}
                                        </div>

                                        <div className="col-sm-6 pb-lg-2 pb-md-2">
                                            <InputField
                                                className={"did-floating-input"}
                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                type="date"
                                                name="start_date"
                                                label={"Start Date"}
                                                value={values.start_date}
                                                min={new Date().toISOString().split('T')[0]}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={true}
                                            />
                                            {errors.start_date && touched.start_date ? (
                                                <div className="error-message">{errors.start_date}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-6 pb-lg-2 pb-md-2">
                                            <InputField
                                                className={"did-floating-input"}
                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                type="date"
                                                name="end_date"
                                                label={"End Date"}
                                                value={values.end_date}
                                                min={values.start_date ? values.start_date : new Date().toISOString().split('T')[0] || values.start_date}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={true}
                                            />
                                            {errors.end_date && touched.end_date ? (
                                                <div className="error-message">{errors.end_date}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-6 pb-lg-2 pb-md-2">
                                            <InputField
                                                className={"did-floating-input"}
                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                type="time"
                                                name="start_time"
                                                label={"Start Time"}
                                                value={values.start_time}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={true}
                                            />
                                            {errors.start_time && touched.start_time ? (
                                                <div className="error-message">{errors.start_time}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-6 pb-lg-2 pb-md-2">
                                            <InputField
                                                className={"did-floating-input"}
                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                type="time"
                                                name="end_time"
                                                label={"End Time"}
                                                value={values.end_time}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={true}
                                            />
                                            {errors.end_time && touched.end_time ? (
                                                <div className="error-message">{errors.end_time}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-6 pb-lg-2 pb-md-2">
                                            <InputField
                                                className={"did-floating-input"}
                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                type="number"
                                                name="hours_per_week"
                                                label={"Guaranteed Hours Per Week"}
                                                value={values.hours_per_week}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={true}
                                                min={1}
                                            />
                                            {errors.hours_per_week && touched.hours_per_week ? (
                                                <div className="error-message">{errors.hours_per_week}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-6 pb-lg-2 pb-md-2">
                                            <InputField
                                                className={"did-floating-input"}
                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                type="number"
                                                name="no_of_weeks"
                                                label={"# of Weeks"}
                                                value={values.no_of_weeks}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={true}
                                                min={1}
                                            />
                                            {errors.no_of_weeks && touched.no_of_weeks ? (
                                                <div className="error-message">{errors.no_of_weeks}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-6 pb-lg-2 pb-md-2">
                                            <InputField
                                                className={"did-floating-input"}
                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                type="number"
                                                name="nop"
                                                label={"# of Openings"}
                                                value={values.nop}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={true}
                                                min={1}
                                            />
                                            {errors.nop && touched.nop ? (
                                                <div className="error-message">{errors.nop}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-6 pb-lg-2 pb-md-2">
                                            <InputField
                                                className={"did-floating-input"}
                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                type="text"
                                                name="qualifications"
                                                label={"Qualifications"}
                                                value={values.qualifications}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={true}
                                            />
                                            {errors.qualifications && touched.qualifications ? (
                                                <div className="error-message">{errors.qualifications}</div>
                                            ) : null}
                                        </div>
                                    </>
                                }
                                <div className="col-sm-6 pb-lg-3 pb-md-2">
                                    {values.category === 'PD' ?
                                        <DatePicker
                                            disabled={values.category === null}
                                            required
                                            calendarPosition="top-left"
                                            placeholder="Select Dates"
                                            format="MM/DD/YYYY"
                                            inputClass="did-floating-dropdown"
                                            multiple
                                            value={multi?.sort((a, b) => new Date(a) - new Date(b))}
                                            minDate={new Date()}
                                            onChange={(e) => {
                                                setValues(e)
                                                setFieldValue('tenure', e)
                                            }}
                                            plugins={[
                                                weekends()
                                            ]}
                                        /> : values.category === "TR" ?
                                            <DatePicker
                                                disabled={values.category === null}
                                                placeholder="Select Start & End Date"
                                                calendarPosition="top-left"
                                                required
                                                format="MM/DD/YYYY"
                                                inputClass="did-floating-dropdown"
                                                multiple
                                                range
                                                numberOfMonths={2}
                                                rangeHover
                                                value={dates}
                                                minDate={new Date()}
                                                onChange={(dateObjects) => {
                                                    // let allDtOne = dateObjects?.map((date) => getAllDatesInRange([date[0], date[1]]))?.flat();
                                                    // let finalOne = allDtOne?.map((x) => x?.format?.())
                                                    // let finalTwo = allDates?.map((x) => x?.format?.())
                                                    // console.log(finalOne?.filter(date => finalTwo?.includes(date)),"find");
                                                    // if (allDates?.find(x => dateObjects?.map((date) => getAllDatesInRange([date[0], date[1]]))?.flat()?.some(new Date(x)))) {
                                                    setDates(dateObjects)
                                                    setAllDates(dateObjects?.map((date) => getAllDatesInRange([date[0], date[1]]))?.flat())
                                                    setFieldValue('tenure', dateObjects?.map((date) => getAllDatesInRange([date[0], date[1]]))?.flat())

                                                    // } else {
                                                    //     console.log("not find");
                                                    // }
                                                }}
                                                plugins={[
                                                    weekends(),
                                                    <ClearButton setFiled={setFieldValue} position="bottom" />
                                                ]}
                                            /> : ""}
                                </div>
                                <div className="col-sm-12 pb-lg-2 pb-md-2">
                                    <div className="d-flex align-items-center">
                                        <span>Break Time:</span>
                                        <div className="d-flex align-items-center ms-3">
                                            <CFormCheck
                                                type="radio"
                                                name="break_time"
                                                id="30min"
                                                value="30"
                                                checked={values.break_time == 30}
                                                onChange={handleChange}
                                                label="30 minutes"
                                            />
                                            <CFormCheck
                                                className="ms-3 me-3"
                                                type="radio"
                                                name="break_time"
                                                id="1hr"
                                                value="60"
                                                checked={values.break_time == 60}
                                                onChange={handleChange}
                                                label="1 hour"
                                            />
                                            <CFormCheck
                                                type="radio"
                                                name="break_time"
                                                id="none"
                                                value="0"
                                                checked={values.break_time == 0}
                                                onChange={handleChange}
                                                label="None"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {/* FOR TR */}
                                    {allDates?.length > 0 &&
                                        <div>

                                            <div className="d-flex justify-content-start mt-2 mb-2"><span className="">Shifts
                                                Approx. Cost:<b className="text-success"> {values.rate ? ("$" + values.rate) + "/hr" : "N/A"}</b>
                                                ,&nbsp;Approx. Total Cost:<b className="text-danger"> ${calculateDomIdSum()}</b>
                                            </span>
                                            </div>
                                            <ul className="list-unstyled row">
                                                <FieldArray name="tenure">
                                                    {({ push, remove, form }) => (
                                                        values?.tenure.length !== 0 && values?.tenure?.sort((a, b) => new Date(a) - new Date(b))?.map((date, index) =>
                                                            <div className="card p-2 col-6 col-lg-12 mb-3 border" key={index}>
                                                                <div className="col-5 pt-1 pb-2" >
                                                                    <SelectField
                                                                        id={"slot-type"}
                                                                        label={"Shift Type"}
                                                                        name={`alltype[${index}]`}
                                                                        onChange={(event) => {
                                                                            setFieldValue(`alltype[${index}]`, event.target.value)
                                                                            setFieldValue(`alltime[${index}]`, [global.config.shiftTimeRange?.find(x => x.label === event.target.value)?.value?.start, global.config.shiftTimeRange?.find(x => x.label === event.target.value)?.value?.end])
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        value={values?.alltype && values?.alltype[index]}
                                                                        required
                                                                        data={global.config.shiftType}
                                                                        optionLabel={"name"}
                                                                        optionValue={"value"}
                                                                    />
                                                                </div>
                                                                <li>
                                                                    <div className="d-flex align-items-center mb-2">
                                                                        <div className="border border-primary all-slot-border">
                                                                            <input className="border-0 start-date-width" id={`alldate[${index}]`} name={`alldate[${index}]`} value={date?.format()} />
                                                                            <br /> <Field id={`alltime[${index}]`} name={`alltime[${index}]`} render={({ field, form }) => <TimeRangePickerField  {...field} {...form} firstTime={values?.alltime?.length > 0 && values?.alltime[0]} index={index} />} />
                                                                            <input
                                                                                className="w-100"
                                                                                placeholder="No. Of Positions"
                                                                                type="number"
                                                                                required
                                                                                name={`allqty[${index}]`}
                                                                                onChange={(event) => {
                                                                                    setFieldValue(`allqty[${index}]`, event.target.value)
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                                value={values?.allqty && values?.allqty[index]}
                                                                                min="1"
                                                                            />
                                                                        </div>
                                                                        <div className="btn position-absolute top-0 end-0" onClick={() => {
                                                                            setAllDates([
                                                                                ...allDates.slice(0, index),
                                                                                ...allDates.slice(index + 1)
                                                                            ]);
                                                                            setDates([
                                                                                ...allDates.slice(0, index),
                                                                                ...allDates.slice(index + 1)
                                                                            ]);
                                                                            (values?.alltime?.length !== 0 && values?.alltime?.length >= index) && values?.alltime?.splice(index, 1);
                                                                            (values?.allqty?.length !== 0 && values?.allqty?.length >= index) && values?.allqty?.splice(index, 1);
                                                                            (values?.alltype?.length !== 0 && values?.alltype?.length >= index) && values?.alltype?.splice(index, 1);
                                                                            remove(index)
                                                                        }}>
                                                                            <i className="fas fa-times-circle text-danger" aria-hidden="true"></i>
                                                                        </div>

                                                                        <div className="btn" onClick={() => {
                                                                            const elementToCopy = values.tenure[index];
                                                                            const elementToCopyTime = [undefined, undefined];
                                                                            setFieldValue("tenure", [...values?.tenure, elementToCopy]);
                                                                            (values?.alltime && values?.alltime?.length !== 0 && values?.alltime?.length >= index) && values?.alltime?.splice(index + 1, 0, elementToCopyTime);
                                                                            (values?.allqty && values?.allqty?.length !== 0 && values?.allqty?.length >= index) && values?.allqty?.splice(index + 1, 0, values?.allqty[0]);
                                                                            (values?.alltype && values?.alltype?.length !== 0 && values?.alltype?.length >= index) && values?.alltype?.splice(index + 1, 0, values?.alltype[0]);
                                                                            setAllDates([...allDates, elementToCopy])
                                                                            setDates([...allDates, elementToCopy])
                                                                        }}>
                                                                            <div className="btn btn-outline-success pt-1 pb-1"><i className="fa fa-plus" aria-hidden="true"></i></div>
                                                                        </div>
                                                                        <div className="btn btn-outline-success pt-1 pb-1">
                                                                            <i className="far fa-copy pointer" onClick={() => {
                                                                                if (values?.alltime[index]?.length === 2 && !values?.alltime[index]?.some(item => item === null || item === undefined)) {
                                                                                    values?.tenure?.map((item, ind) => {
                                                                                        setFieldValue(`alltime[${ind}]`, values?.alltime[index]);
                                                                                        if (values?.allqty?.length && (values?.allqty[index] !== null || values?.allqty[index] !== undefined)) {
                                                                                            setFieldValue(`allqty[${ind}]`, values?.allqty[index])
                                                                                        }
                                                                                        if (values?.alltype?.length && (values?.alltype[index] !== null || values?.alltype[index] !== undefined)) {
                                                                                            setFieldValue(`alltype[${ind}]`, values?.alltype[index])
                                                                                        }
                                                                                    })
                                                                                } else {
                                                                                    toast.warn("Please select start end time")
                                                                                }
                                                                            }} data-toggle="tooltip" data-placement="right" title="Copy Shift Type,Start Time,End Time & No Of Positions"></i>
                                                                        </div>   </div>
                                                                    <div className="col-12">
                                                                        <InputField
                                                                            className={"did-floating-input"}
                                                                            type="text"
                                                                            name={`allcomment[${index}]`}
                                                                            onChange={(event) => {
                                                                                setFieldValue(`allcomment[${index}]`, event.target.value)
                                                                            }}
                                                                            label={"Comments For Caregiver"}
                                                                            value={values?.allcomment && values?.allcomment[index]}
                                                                            onBlur={handleBlur}
                                                                        />
                                                                    </div>
                                                                </li>
                                                            </div>
                                                        )
                                                    )}
                                                </FieldArray>

                                            </ul>
                                        </div>
                                    }
                                    {/* FOR PD */}
                                    {
                                        multi?.length > 0 && multi &&
                                        <div>
                                            <div className="d-flex justify-content-start mt-2 mb-2"><span className="">Shifts
                                                Approx. Cost:<b className="text-success"> {values.rate ? ("$" + values.rate) + "/hr" : "N/A"}</b>
                                                ,&nbsp;Approx. Total Cost:<b className="text-danger"> ${calculateDomIdSum()}</b>
                                            </span>
                                            </div>
                                            <ul className="list-unstyled row">
                                                <FieldArray name="tenure">
                                                    {({ push, remove, form }) => (
                                                        values?.tenure.length !== 0 && values?.tenure?.sort((a, b) => new Date(a) - new Date(b))?.map((date, index) =>
                                                            <div className="card p-2 col-6 col-lg-12 mb-3 border" key={index}>
                                                                <div className="row align-items-center">
                                                                    <div className="col-5 pt-1 pb-2" >
                                                                        <SelectField
                                                                            id={"slot-type"}
                                                                            label={"Shift Type"}
                                                                            name={`alltype[${index}]`}
                                                                            onChange={(event) => {
                                                                                setFieldValue(`alltype[${index}]`, event.target.value)
                                                                                setFieldValue(`alltime[${index}]`, [global.config.shiftTimeRange?.find(x => x.label === event.target.value)?.value?.start, global.config.shiftTimeRange?.find(x => x.label === event.target.value)?.value?.end])
                                                                            }}
                                                                            onBlur={handleBlur}
                                                                            value={values?.alltype && values?.alltype[index]}
                                                                            required
                                                                            data={global.config.shiftType}
                                                                            optionLabel={"name"}
                                                                            optionValue={"value"}
                                                                        />
                                                                    </div>
                                                                    <div className="col-5 pt-1 pb-2" >
                                                                        {values?.alltime[index]?.indexOf(undefined) === -1 && <span className="text-success me-3"><i className="fas fa-business-time"></i> {calculateLunch(values?.alltime[index][0], values?.alltime[index][1], values.break_time)}</span>}
                                                                        {values.break_time ? <span className="text-success me-3"><i className="fas fa-clock"></i> {values.break_time} Min</span> : ""}
                                                                        {(values.rate && values?.alltime[index]?.length === 2) ? <span className="text-success" id="per-cost"><i className="fas fa-dollar-sign"></i> {(values?.allqty[index] * values.rate * calculateTotalHr(values?.alltime[index][0], values?.alltime[index][1], values.break_time)?.toFixed(2))}</span> : ""}
                                                                    </div>
                                                                </div>
                                                                <li>
                                                                    <div className="d-flex align-items-center mb-2">
                                                                        <div className="border border-primary all-slot-border ">
                                                                            <input className="border-0 start-date-width" id={`alldate[${index}]`} name={`alldate[${index}]`} value={date?.format()} />
                                                                            <br />     <Field id={`alltime[${index}]`} name={`alltime[${index}]`} render={({ field, form }) => <TimeRangePickerField  {...field} {...form} index={index} />} />
                                                                            <input
                                                                                // className="slot-qty"
                                                                                className="w-100"
                                                                                placeholder="No. Of Positions"
                                                                                type="number"
                                                                                required
                                                                                name={`allqty[${index}]`}
                                                                                onChange={(event) => {
                                                                                    setFieldValue(`allqty[${index}]`, event.target.value)
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                                value={values?.allqty && values?.allqty[index]}
                                                                                min="1"
                                                                            />
                                                                        </div>
                                                                        {/* DELETE */}
                                                                        <div className="btn position-absolute top-0 end-0" onClick={() => {
                                                                            setValues([
                                                                                ...multi.slice(0, index),
                                                                                ...multi.slice(index + 1)
                                                                            ]);
                                                                            (values?.alltime?.length !== 0 && values?.alltime?.length >= index) && values?.alltime?.splice(index, 1);
                                                                            (values?.allqty?.length !== 0 && values?.allqty?.length >= index) && values?.allqty?.splice(index, 1);
                                                                            remove(index)
                                                                        }}>
                                                                            <i className="fas fa-times-circle text-danger" aria-hidden="true"></i>
                                                                        </div>
                                                                        {<div className="btn" onClick={() => {
                                                                            const elementToCopy = values.tenure[index];
                                                                            const elementToCopyTime = [undefined, undefined];
                                                                            setFieldValue("tenure", [...values?.tenure, elementToCopy]);
                                                                            (values?.alltime && values?.alltime?.length !== 0 && values?.alltime?.length >= index) && values?.alltime?.splice(index + 1, 0, elementToCopyTime);
                                                                            (values?.allqty && values?.allqty?.length !== 0 && values?.allqty?.length >= index) && values?.allqty?.splice(index + 1, 0, values?.allqty[0]);
                                                                            (values?.alltype && values?.alltype?.length !== 0 && values?.alltype?.length >= index) && values?.alltype?.splice(index + 1, 0, values?.alltype[0]);
                                                                            setValues([...multi, elementToCopy])
                                                                        }}>
                                                                            <div className="btn btn-outline-success pt-1 pb-1"><i className="fa fa-plus" aria-hidden="true"></i></div>
                                                                        </div>}
                                                                        <div className="btn btn-outline-success pt-1 pb-1">
                                                                            <i className="far fa-copy pointer" onClick={() => {
                                                                                if (values?.alltime[index]?.length === 2 && !values?.alltime[index].some(item => item === null || item === undefined)) {
                                                                                    values?.tenure?.map((item, ind) => {
                                                                                        setFieldValue(`alltime[${ind}]`, values?.alltime[index]);
                                                                                        if (values?.allqty?.length && (values?.allqty[index] !== null || values?.allqty[index] !== undefined)) {
                                                                                            setFieldValue(`allqty[${ind}]`, values?.allqty[index])
                                                                                        }
                                                                                        if (values?.alltype?.length && (values?.alltype[index] !== null || values?.alltype[index] !== undefined)) {
                                                                                            setFieldValue(`alltype[${ind}]`, values?.alltype[index])
                                                                                        }
                                                                                    })
                                                                                } else {
                                                                                    toast.warn("Please select start end time")
                                                                                }
                                                                            }} data-toggle="tooltip" data-placement="right" title="Copy Shift Type,Start Time,End Time & No Of Positions"></i>
                                                                        </div>  </div>
                                                                    <div className="col-12">
                                                                        <InputField
                                                                            className={"did-floating-input"}
                                                                            type="text"
                                                                            name={`allcomment[${index}]`}
                                                                            onChange={(event) => {
                                                                                setFieldValue(`allcomment[${index}]`, event.target.value)
                                                                            }}
                                                                            label={"Comments For Caregiver"}
                                                                            value={values?.allcomment && values?.allcomment[index]}
                                                                            onBlur={handleBlur}
                                                                        />
                                                                    </div>
                                                                </li>
                                                            </div>

                                                        )
                                                    )}
                                                </FieldArray>
                                            </ul>
                                        </div>
                                    }
                                </div>
                                <div className="col-12">
                                    <label className="pb-2 label">Comments For Staffers Management</label>
                                    <div className="form-floating">
                                        <textarea className="form-control"
                                            placeholder=""
                                            name={'description'}
                                            value={values.description}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            style={{ height: 100 }}>
                                        </textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center pt-3">
                                <button className="btn btn-primary w-auto pe-5 ps-5 p-2" type="submit">Submit</button>
                                <button className="btn btn-primary w-auto pe-5 ps-5 p-2 ms-3" onClick={() => onDismissAddShift()}>Close</button>
                            </div>
                        </form>
                    )
                }
            </Formik>

        </CModalBody>
    </div>
);
export const onChangeSlotStatus = (slotData, mode, refreshList) => {

    if (mode === 'approve') {
        swal({
            title: "Are you sure to approve this shift?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    const slotDetails = [{ "id": slotData?.id, "code": 2 }]
                    let param = {
                        'slots': JSON.stringify(slotDetails)
                    }
                    AdminStore.updateSlots(param, refreshList)
                }
            });
    }
    if (mode === 'reject-close') {
        swal({
            title: "Are you sure to reject & close this shift?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    const slotDetails = [{ "id": slotData?.id, "code": 10 }]
                    let param = {
                        'slots': JSON.stringify(slotDetails)
                    }
                    AdminStore.updateSlots(param, refreshList)
                }
            });
    }
    if (mode === 'reject-open') {
        swal({
            title: "Are you sure to reject & open this shift?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    const slotDetails = [{ "id": slotData?.id, "code": 1 }]
                    let param = {
                        'slots': JSON.stringify(slotDetails)
                    }
                    AdminStore.updateSlots(param, refreshList)
                }
            });
    }
    if (mode === 'cancel-facility') {//Cancel by Facility
        swal({
            content: (
                <div>
                    <h3>Are you sure to cancel this shift by facility?</h3>
                    <p>After this action, you can't roll back!</p>
                    <input
                        type="text"
                        placeholder="Enter the reason for cancellation..."
                        id="cancelReason"
                        className='did-floating-dropdown'
                    />
                </div>
            ),
            buttons: {
                cancel: {
                    text: 'Cancel',
                    value: null,
                    visible: true,
                    className: '',
                    closeModal: true,
                },
                confirm: {
                    text: 'OK',
                    value: true,
                    visible: true,
                    className: '',
                    closeModal: true,
                },
            },
            icon: 'warning', // Add alert icon
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    const reason = document.getElementById('cancelReason')?.value;
                    let param = {
                        slot_id_facility: [slotData?.id],
                        slot_id_caregiver: [],
                        slot_id_delete: [],
                        reason: reason,
                    };
                    AdminStore.cancelSlot(param, refreshList);
                }
            });
    }
    if (mode === 'cancel-caregiver') {
        swal({
            content: (
                <div>
                    <h3>Are you sure to cancel this shift by caregiver?</h3>
                    <p>After this action, you can't roll back!</p>
                    <input
                        type="text"
                        placeholder="Enter the reason for cancellation..."
                        id="cancelReason"
                        className='did-floating-dropdown'
                    />
                </div>
            ),
            buttons: {
                cancel: {
                    text: 'Cancel',
                    value: null,
                    visible: true,
                    className: '',
                    closeModal: true,
                },
                confirm: {
                    text: 'OK',
                    value: true,
                    visible: true,
                    className: '',
                    closeModal: true,
                },
            },
            icon: 'warning', // Add alert icon
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    const reason = document.getElementById('cancelReason')?.value;
                    let param = {
                        slot_id_facility: [],
                        slot_id_caregiver: [slotData?.id],
                        slot_id_delete: [],
                        reason: reason,
                    };

                    AdminStore.cancelSlot(param, refreshList);
                }
            });
    }
    if (mode === 'delete') {
        swal({
            title: "Are you sure to delete this shift?",
            text: "After an delete, You can't roll back an delete.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    let param = {
                        slot_id_facility: [],
                        slot_id_caregiver: [],
                        slot_id_delete: [slotData?.id],
                    }
                    AdminStore.cancelSlot(param, refreshList)
                }
            });
    }
}
export const onChangeSlotStatusCalendar = (slotData, mode, refreshList) => {
    if (mode === 'approve') {
        swal({
            title: "Are you sure to approve this shift?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    const slotDetails = [{ "id": slotData?.data?.id, "code": 2 }]
                    let param = {
                        'slots': JSON.stringify(slotDetails)
                    }
                    AdminStore.updateSlots(param, refreshList)
                }
            });
    }
    if (mode === 'reject-open') {
        swal({
            title: "Are you sure to reject & open this shift?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    const slotDetails = [{ "id": slotData?.data?.id, "code": 1 }]
                    let param = {
                        'slots': JSON.stringify(slotDetails)
                    }
                    AdminStore.updateSlots(param, refreshList)
                }
            });
    }
    if (mode === 'reject-close') {
        swal({
            title: "Are you sure to reject &close this shift?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    const slotDetails = [{ "id": slotData?.data?.id, "code": 10 }]
                    let param = {
                        'slots': JSON.stringify(slotDetails)
                    }
                    AdminStore.updateSlots(param, refreshList)
                }
            });
    }
    if (mode === 'cancel') {//Cancel by Facility
        swal({
            content: (
                <div>
                    <h3>Are you sure to cancel this shift by facility?</h3>
                    <p>After this action, you can't roll back!</p>
                    <input
                        type="text"
                        placeholder="Enter the reason for cancellation..."
                        id="cancelReason"
                        className='did-floating-dropdown'
                    />
                </div>
            ),
            buttons: {
                cancel: {
                    text: 'Cancel',
                    value: null,
                    visible: true,
                    className: '',
                    closeModal: true,
                },
                confirm: {
                    text: 'OK',
                    value: true,
                    visible: true,
                    className: '',
                    closeModal: true,
                },
            },
            icon: 'warning', // Add alert icon
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    const reason = document.getElementById('cancelReason')?.value;
                    let param = {
                        slot_id_facility: [slotData?.data?.id],
                        slot_id_caregiver: [],
                        slot_id_delete: [],
                        reason: reason,
                    };
                    AdminStore.cancelSlot(param, refreshList);
                }
            });
    }
    if (mode === 'cancel-caregiver') {
        swal({
            content: (
                <div>
                    <h3>Are you sure to cancel this shift by caregiver?</h3>
                    <p>After this action, you can't roll back!</p>
                    <input
                        type="text"
                        placeholder="Enter the reason for cancellation..."
                        id="cancelReason"
                        className='did-floating-dropdown'
                    />
                </div>
            ),
            buttons: {
                cancel: {
                    text: 'Cancel',
                    value: null,
                    visible: true,
                    className: '',
                    closeModal: true,
                },
                confirm: {
                    text: 'OK',
                    value: true,
                    visible: true,
                    className: '',
                    closeModal: true,
                },
            },
            icon: 'warning', // Add alert icon
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    const reason = document.getElementById('cancelReason')?.value;
                    let param = {
                        slot_id_facility: [],
                        slot_id_caregiver: [slotData?.data?.id],
                        slot_id_delete: [],
                        reason: reason,
                    };

                    AdminStore.cancelSlot(param, refreshList);
                }
            });
    }
    if (mode === 'delete') {
        swal({
            title: "Are you sure to delete this shift?",
            text: "After an delete, You can't roll back an delete.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    let param = {
                        slot_id_facility: [],
                        slot_id_caregiver: [],
                        slot_id_delete: [slotData?.data?.id],
                    }
                    AdminStore.cancelSlot(param, refreshList)
                }
            });
    }
}

export const MultiValue = ({ index, getValue, ...props }) => {
    const maxToShow = 3;
    const overflow = getValue()
        .slice(maxToShow)
        .map((x) => x.label);

    return index < maxToShow ? (
        <components.MultiValue {...props} />
    ) : index === maxToShow ? (
        <MoreSelectedBadge items={overflow} />
    ) : null;
};
export const MoreSelectedBadge = ({ items }) => {
    const style = {
        marginLeft: "auto",
        background: "#d4eefa",
        borderRadius: "4px",
        fontFamily: "Open Sans",
        fontSize: "11px",
        padding: "3px",
        order: 99
    };

    const title = items.join(", ");
    const length = items.length;
    const label = `+ ${length} item${length !== 1 ? "s" : ""}`;

    return (
        <div style={style} title={title}>
            {label}
        </div>
    );
};
export const calculateLunch = (start, end, subtractMinutes = 0) => {
    if (!start && !end) return
    const startTime = moment(start, 'HH:mm');
    const endTime = moment(end, 'HH:mm');
    // Check if end time is on the next day
    if (endTime?.isBefore(startTime)) {
        endTime.add(1, 'day');
    }

    const duration = moment?.duration(endTime?.diff(startTime))?.subtract(subtractMinutes, 'minutes');

    const days = Math.floor(duration.asDays());
    const hours = Math.floor(duration.asHours()) % 24;
    const minutes = Math.round(duration.asMinutes()) % 60;

    const formattedDays = days > 0 ? days + " Day " : "";
    const formattedHours = hours > 0 ? hours + " Hr " : "";
    const formattedMinutes = minutes > 0 ? minutes + " Min" : "";

    return formattedDays + formattedHours + formattedMinutes;
}
export const calculateTotalHr = (start, end, subtractMinutes = 0) => {
    if (!start && !end) return 0

    const startTime = moment(start, 'HH:mm');
    const endTime = moment(end, 'HH:mm');

    // Check if end time is on the next day
    if (endTime?.isBefore(startTime)) {
        endTime.add(1, 'day');
    }
    const durationInMinutes = endTime.diff(startTime, 'minutes') - subtractMinutes;
    const durationInHours = durationInMinutes / 60; // Convert minutes to hours
    return durationInHours || 0;
}

export const generateTooltipHTML = missingDataArray => missingDataArray?.map(key => "\n" + convertToUserFriendly(key) + " is missing!")
// Function to convert keys to user-friendly format
export const convertToUserFriendly = key => {
    const words = key?.split(/[_ ]+/); // Split by underscore or space
    const capitalizedWords = words?.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
    return capitalizedWords?.join(' ');
};

export const checkAccountValidation = (data, type) => {
    if (!data) return
    const isEmpty = toJS(AdminStore?.data?.settings)?.find(x => x.id === (type === "caregiver" ? 63 : 62))?.value?.split(',')?.some(key => {
        const value = data[key];
        return (
            (value === undefined || value === null)
        );
    });

    return isEmpty
}
export const checkAccountValidationOnHover = (data, type) => {
    const missingData = [];
    const isEmpty = toJS(AdminStore?.data?.settings)?.find(x => x.id === (type === "caregiver" ? 63 : 62))?.value?.split(',')?.some(key => {
        const value = data[key];
        const isEmptyValue =
            (value === undefined || value === null || typeof value === 'string' && value.trim() === '')

        if (isEmptyValue) {
            missingData.push(key);
        }
        return;
    });
    return missingData
}


export const showCaregiverProfile = (user) => {
    if (!user?.staff_id) {
        return
    }
    const caregiver = {
        id: user?.staff?.id,
        first_name: user?.staff?.first_name,
        last_name: user?.staff?.last_name,
        bluesky_staff_id: user?.staff?.bluesky_staff_id,
    }
    const url = `/admin/caregiver-shift?data=${encodeURIComponent(JSON.stringify(caregiver))}`;
    window.open(url, '_blank');
};
export const calculateDomIdSum = () => {
    let total = 0;
    const elements = document.querySelectorAll('#per-cost');
    elements?.forEach(element => {
        const value = parseInt(element?.textContent.trim());
        if (!isNaN(value)) {
            total += value;
        }
    });

    return total || 0;
};

//ICS
export const onDownloadICS = (data) => {
    AdminStore.getStaffICS({ staff_id: data.id }).then((icsData) => {
        if (icsData?.length === 0) {
            swal("Alert", "There are no upcoming slots.", "warning")
        } else {
            createICS(icsData, data)
        }
    })
}

export const createICS = (slots, userData) => {
    const events = slots?.map((slot) => {
        // Validate the slot data
        if (!slot || !slot.slot_date || !slot.slot_start_time || !slot.slot_end_time) {
            return null; // Handle missing or invalid data
        }

        const slotDate = moment(slot.slot_date, 'MM/DD/YYYY');
        const slotStartTime = moment(slot.slot_start_time, 'HH:mm');
        const slotEndTime = moment(slot.slot_end_time, 'HH:mm');

        // Construct the location string properly
        const location = `${slot?.request?.facility?.organization_name}, ${slot?.request?.facility?.address}, ${slot?.request?.facility?.city}, ${slot?.facility?.request?.state}, ${slot?.request?.facility?.zipcode}`;

        return {
            start: [
                slotDate.year(),
                slotDate.month() + 1,
                slotDate.date(),
                slotStartTime.hour(),
                slotStartTime.minute(),
            ],
            end: [
                slotDate.year(),
                slotDate.month() + 1,
                slotDate.date(),
                slotEndTime.hour(),
                slotEndTime.minute(),
            ],
            title: `Your Upcoming Shift - ${slot?.shift_id}`,
            description: 'You will start your shift at this time and be ready for work.',
            organizer: {
                name: slot?.request?.facility?.organization_name,
                email: slot?.request?.user?.email,
            },
            location: location,
            url: 'https://staffers.us',
            status: 'CONFIRMED',
            attendees: [
                {
                    name: slot?.request?.facility?.organization_name,
                    email: slot?.request?.user?.email,
                    rsvp: true,
                    partstat: 'ACCEPTED',
                    cutype: 'INDIVIDUAL',
                },
            ],
            alarms: [
                {
                    action: 'display',
                    trigger: { minutes: 15 }, // Adjust as needed
                    description: 'Shift Reminder',
                },
            ],
        };
    }).filter(Boolean); // Remove null values


    if (events?.length == 0) {
        toast.warn("There are no upcoming slots.");
        return
    }
    // Create an array to hold the events in ICS format
    const icsEvents = [];
    for (const event of events) {
        const { error, value } = createEvent(event);
        if (error) {
            console.error('Error generating ICS file:', error);
            return;
        }
        icsEvents.push(value);
    }
    // Join the ICS event strings
    const icsContent = icsEvents.join('\r\n');
    const blob = new Blob([icsContent], { type: 'text/calendar;charset=utf-8' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${userData?.first_name}_${userData?.last_name}_${userData?.bluesky_staff_id}.ics`;
    a.click();
    window.URL.revokeObjectURL(url);
    toast.success("ICS was successfully downloaded.");
}