import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react"
import HeaderPage from "../layout/HeaderPage";
import DrawerMenuPage from "../layout/DrawerMenuPage";
import { CButton, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CInputGroup, CFormInput } from "@coreui/react";
import './StaffsList.css'
import AdminStore from "@store/AdminStore";
import { toJS } from "mobx";
import HomeStore from "@store/HomeStore";
import { useNavigate } from "react-router-dom";
import swal from '@sweetalert/with-react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import StaffsStore from "@store/StaffsStore";
import { BASE_API_URL_FILE } from "@api/config"
import Spinner from "@components/Spinner";
import ReactDOM from "react-dom";
import moment from "moment";
import { selectedKeysOne, selectedKeysTwo, selectedKeysThree, selectedKeysFour, selectedKeysFive, selectedKeysSix } from '@utils/constant/constant';
import {onDownloadICS } from "../commonFunctions";

const $ = window.$;
var states = require('@assets/states.json');

const StaffsList = observer(() => {
    const tableRef = useRef()
    const tableName = "table1"
    const navigate = useNavigate();
    const [staffList, setStaffList] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [profile, setProfile] = useState();
    const [regions, setRegions] = useState([]);
    const [infoSource, setInfoSource] = useState([]);
    const [devices, setDevices] = useState([]);
    const [requiredDoc, setRequiredDoc] = useState([]);
    const [paginationLength, setPaginationLength] = useState(25);
    const [inputSearch, setInputSearch] = useState('');
    const [degrees, setDegrees] = useState([]);
    const [progressOne, setProgressOne] = useState(0);
    const [progressTwo, setProgressTwo] = useState(0);
    const [progressThree, setProgressThree] = useState(0);
    const [progressFour, setProgressFour] = useState(0);
    const [progressFive, setProgressFive] = useState(0);
    const [progressSix, setProgressSix] = useState(0);

    useEffect(() => {
        AdminStore.updatePage();
        HomeStore.getDashboardData();
        AdminStore.getStaff();
        HomeStore.getInformationSource();
        HomeStore.getContactDevices();
        StaffsStore.getRegionsList();
        StaffsStore.getRequiredDoc();
    }, []);
    useEffect(() => {
        setDegrees(HomeStore.data.degreeList);
    }, [HomeStore.data.degreeList]);

    useEffect(() => {
        setStaffList(toJS(AdminStore?.data?.staffList))
    }, [AdminStore?.data?.staffList]);

    useEffect(() => {
        setRegions(StaffsStore?.data?.regionsList)
    }, [StaffsStore?.data?.regionsList]);

    useEffect(() => {
        setInfoSource(HomeStore?.data?.informationSource)
    }, [HomeStore?.data?.informationSource]);

    useEffect(() => {
        setDevices(HomeStore?.data?.contactDevices)
    }, [HomeStore?.data?.contactDevices]);

    useEffect(() => {
        setRequiredDoc(StaffsStore?.data?.requiredDocList)
    }, [StaffsStore?.data?.requiredDocList]);

    const showDetails = (item) => {
        setProfile(item)
        setIsOpen(true);
        let jobProfile = item?.job_profile;
        if (jobProfile) {
            setProgressOne((((selectedKeysOne?.filter(key => jobProfile[key] !== null && (typeof jobProfile[key] !== 'string' || jobProfile[key]?.trim() !== ''))?.length) / selectedKeysOne?.length) * 100)?.toFixed(0))
            setProgressTwo((((selectedKeysTwo?.filter(key => jobProfile[key] !== null && (typeof jobProfile[key] !== 'string' || jobProfile[key]?.trim() !== ''))?.length) / selectedKeysTwo?.length) * 100)?.toFixed(0))
            setProgressThree((((selectedKeysThree?.filter(key => jobProfile[key] !== null && (typeof jobProfile[key] !== 'string' || jobProfile[key]?.trim() !== ''))?.length) / selectedKeysThree?.length) * 100)?.toFixed(0))
            setProgressFour((
                ((selectedKeysFour?.filter(key => jobProfile[key] !== null && (!Array.isArray(jobProfile[key]) || jobProfile[key].length !== 0))?.length || 0) /
                    (selectedKeysFour?.length || 1)) *
                100
            )?.toFixed(0)
            )
            setProgressFive((((selectedKeysFive?.filter(key => jobProfile[key] !== null && (typeof jobProfile[key] !== 'string' || jobProfile[key]?.trim() !== ''))?.length) / selectedKeysFive?.length) * 100)?.toFixed(0))
            setProgressSix((((selectedKeysSix?.filter(key => jobProfile[key] !== null && (typeof jobProfile[key] !== 'string' || jobProfile[key]?.trim() !== ''))?.length) / selectedKeysSix?.length) * 100)?.toFixed(0))
        }
    }


    useEffect(() => {
        const renderPagination = () => {
            const table = $('#table1').DataTable();
            const totalPages = AdminStore.data.lastPage;
            const currentPage = AdminStore.data.page;
            const paginationContainer = $('.dataTables_paginate');
            const paginationList = $('<ul>', { class: 'pagination' });
            paginationContainer.empty().append(paginationList);

            let startPage = Math.max(1, currentPage - 2);
            let endPage = Math.min(totalPages, currentPage + 2);

            if (totalPages > 5 && currentPage <= 3) {
                endPage = 5;
            } else if (totalPages > 5 && currentPage >= totalPages - 2) {
                startPage = totalPages - 4;
            }
            const firstButton = $('<a>', {
                text: 'First',
                class: `page-link pointer ${currentPage === 1 ? 'disabled' : ''}`,
                click: function () {
                    if (currentPage > 1) {
                        AdminStore.data.page = 1;
                        AdminStore.getStaff();
                        table.page('first').draw(false);
                    }
                },
            });

            const previousButton = $('<a>', {
                text: 'Previous',
                class: `page-link pointer ${currentPage === 1 ? 'disabled' : ''}`,
                click: function () {
                    if (currentPage > 1) {
                        AdminStore.data.page = AdminStore.data.page - 1;
                        AdminStore.getStaff();
                        table.page('previous').draw(false);
                    }
                },
            });

            const nextButton = $('<a>', {
                text: 'Next',
                class: `page-link pointer ${currentPage === totalPages ? 'disabled' : ''}`,
                click: function () {
                    if (currentPage < totalPages) {
                        AdminStore.data.page = AdminStore.data.page + 1;
                        AdminStore.getStaff();
                        table.page('next').draw(false);
                    }
                },
            });
            const lastButton = $('<a>', {
                text: 'Last',
                class: `page-link pointer ${currentPage === totalPages ? 'disabled' : ''}`,
                click: function () {
                    if (currentPage < totalPages) {
                        AdminStore.data.page = AdminStore.data.lastPage;
                        AdminStore.getStaff();
                        table.page('last').draw(false);
                    }
                },
            });

            const firstListItem = $('<li>', { class: `page-item ${currentPage === 1 ? 'disabled' : ''}` }).append(firstButton);
            const previousListItem = $('<li>', { class: `page-item ${currentPage === 1 ? 'disabled' : ''}` }).append(previousButton);
            const nextListItem = $('<li>', { class: `page-item ${currentPage === totalPages ? 'disabled' : ''}` }).append(nextButton);
            const lastListItem = $('<li>', { class: `page-item ${currentPage === totalPages ? 'disabled' : ''}` }).append(lastButton);
            paginationList.append(firstListItem);
            paginationList.append(previousListItem);

            for (let i = startPage; i <= endPage; i++) {
                const pageLink = $('<a>', {
                    text: i,
                    class: `page-link pointer ${i === currentPage ? 'active' : ''}`,
                    click: function () {
                        AdminStore.data.page = i;
                        AdminStore.getStaff();
                        table.page(i - 1).draw(false);
                    },
                });

                const listItem = $('<li>', { class: `page-item ${i === currentPage ? 'active' : ''}` }).append(pageLink);
                paginationList.append(listItem);
            }

            paginationList.append(nextListItem);
            paginationList.append(lastListItem);
        };

        $.fn.dataTable.ext.errMode = 'none';
        $($.fn.dataTable.tables(true)).DataTable()
            .columns.adjust();

        const table = $(`#${tableName}`).DataTable(
            {
                data: staffList,
                order: [], // Disable initial sorting
                dom: 'Blfrtip',
                buttons: [
                    'copy', 'csv', 'excel', 'print',
                ],
                pageLength: paginationLength,
                lengthMenu: [25, 50, 100, 500, 1000],
                columns: [
                    {
                        title: "Sr", data: 'id', width: 60, "render": function (data, type, full, meta) {
                            return ((AdminStore.data.page - 1) * paginationLength + 1) + meta.row
                        }
                    },
                    {
                        title: "BlueSky ID", data: "", "render": function (data, type, row, full, meta) {
                            return row?.job_profile ? row?.bluesky_staff_id ? row?.bluesky_staff_id : 'Onboard pending' : "Not yet created job profile";
                        }
                    },
                    {
                        title: "Caregiver", width: 100, "render": function (data, type, row, meta) {
                            return "<span class='text-info pointer'><strong>" + (row?.title && row?.title !== 'None' ? row?.title : " ") + " " + row?.first_name + " " + row?.last_name + "</strong></span>";
                        }
                    },
                    {
                        title: "Profession", data: "profession", "render": function (data, type, row, meta) {
                            const professionNames = data && degrees?.find(x => x.Id == data)?.Name
                            return professionNames || "-";
                        }
                    },
                    {
                        title: "Email", data: "email"
                    },
                    {
                        title: "Contact", "render": function (data, type, row, meta) {

                            return row?.contact ? (row?.country_code ? row?.country_code : "" + " " + row?.contact) : "-";
                        }
                    },
                    {
                        title: "Status", width: 100, data: 'status', "render": function (data, type, row, meta) {
                            return data == '0' ? '<span class="text-warning">' + "Pending" + '</span>' : data == '1' ? '<span class="text-success">' + "Active" + '</span>' : data == '2' ? '<span class="text-danger">' + "Rejected" + '</span>' : data == '3' ? 'Blocked' : 'No Update'
                        }
                    },
                    {
                        title: "Last Updated By", width: 100, data: 'last_updated_by', "render": function (data, type, row, meta) {
                            return data ? ((data?.title || " ") + " " + data?.first_name + " " + data?.last_name) : "-"
                        }
                    },
                    {
                        title: "Last Update", width: 100, data: 'updated_at', "render": function (data, type, row, meta) {
                            return moment(data).fromNow() || "-";
                        }
                    },
                    {
                        title: "Action"
                    },


                ],
                columnDefs: [
                    {
                        targets: 9,
                        responsivePriority: 1,
                        createdCell: (td, cellData, rowData, row, col) =>
                            ReactDOM.render(
                                <div>
                                    <CDropdown variant="btn-group" direction='dropstart' alignment="end">
                                        <CDropdownToggle size="sm" color="transparent" caret={false} href="#" className="p-0"><i className="fa fa-ellipsis-v text-primary"></i></CDropdownToggle>
                                        <CDropdownMenu >
                                            {rowData?.bluesky_staff_id && <CDropdownItem className="more-menu">
                                                <CButton onClick={() => {
                                                    onDownloadICS(rowData)
                                                }} size="sm" className="w-100" variant='outline' color="success" >
                                                    Download (.ics) <i className="far fa-calendar-plus"></i>
                                                </CButton>
                                            </CDropdownItem>}
                                            <CDropdownItem className="more-menu">
                                                <CButton onClick={() => {
                                                    onEdit(rowData)
                                                }} size="sm" className="w-100" variant='outline' color="primary" >
                                                    Edit <i className="fas fa-edit"></i>
                                                </CButton>
                                            </CDropdownItem>
                                            {<CDropdownItem className="more-menu">
                                                <CButton onClick={() => {
                                                    showDetails(rowData)
                                                }} size="sm" className="w-100" variant='outline' color="primary" >
                                                    Job Profile <i className="fas fa-id-card-alt"></i>
                                                </CButton>
                                            </CDropdownItem>}
                                            {rowData?.status != 0 && (
                                                <><CDropdownItem>
                                                    <CButton
                                                        onClick={() => {
                                                            onBlock(rowData)
                                                        }}
                                                        size="sm" className="w-100" variant='outline' color={rowData.status === 3 ? "success" : "danger"}>
                                                        {rowData.status === 3 ? 'Activate' : 'Block'}    {row.status === 3 ? <i className="fas fa-user-check"></i> : <i className="fas fa-ban"></i>}
                                                    </CButton>
                                                </CDropdownItem></>)}
                                            <CDropdownItem>
                                                <CButton
                                                    onClick={() => {
                                                        confirm(rowData.id)
                                                    }}
                                                    size="sm" className="w-100" variant='outline' color="danger">
                                                    Delete <i className="fas fa-trash"></i>
                                                </CButton>
                                            </CDropdownItem>
                                        </CDropdownMenu>
                                    </CDropdown>
                                </div>
                                , td),
                    }
                ],
                destroy: true,  // I think some clean up is happening here
                searching: true,
                "language": {
                    search: '',
                    searchPlaceholder: "Search",
                    emptyTable: "No Record Found",
                    info: "Showing " + AdminStore.data.from + " to " + AdminStore.data.to + " of " + AdminStore.data.total + " entries",
                },

                initComplete: function () {
                    // Apply the search
                    this.api()
                        .columns([0, 1, 2, 3, 4, 5])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><input class="form-control " style="width:100%;margin-top:5px" type="text" placeholder="Filter per page" />')
                                .appendTo($(column.header()))

                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $('input', this.header()).on('keyup change clear', function () {
                                if (column.search() !== this.value) {
                                    column.search(this.value).draw();
                                }
                            });
                        });
                    this.api()
                        .columns([6])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><select class="form-control" placeholder="search" style="width:100%;margin-top:5px;background-color:white""><option value="">All</option></select>')
                                .appendTo($(column.header()))
                                .on('change', function () {
                                    var val = $.fn.dataTable.util.escapeRegex($(this).val());
                                    column.search(val ? '^' + val + '$' : '', true, false).draw();
                                });
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            column
                                .data()
                                .unique()
                                .sort()
                                .each(function (d, j) {
                                    let name = [{ "name": "Pending", "value": 0 }, { "name": "Active", "value": 1 }, { "name": "Rejected", "value": 2 }, { "name": "Blocked", "value": 3 }]?.find(stat => stat.value == d)?.name;
                                    select.append('<option value="' + name + '">' + name + '</option>');
                                });
                        });

                },
                drawCallback: function () {
                    renderPagination();
                }
            });

        renderPagination();
        table.page.len(paginationLength).draw();
        $(`#${tableName}`).on('length.dt', function (e, settings, len) {
            handlePaginationLengthChange(len)
        });

        table.on('click', 'td', function () {
            // Get the column index of the clicked cell
            const columnIndex = table.cell(this).index()?.column;
            // Check if the clicked cell belongs to the desired column
            if (columnIndex === 2) {
                // Get the data for the clicked cell
                const rowData = table.row($(this).closest('tr')).data();
                // Handle the event for the clicked cell
                if (rowData) {
                    showDetails(rowData)
                }
            }
        });
        // Extra step to do extra clean-up.
        return function () {
            $(`#${tableName}`).off('length.dt');
            table.destroy()
        }
    }, [staffList])

    const onBlock = (item) => {
        swal({
            title: item?.status === 3 ? "Do you really want to unblock user?" : "Do you really want to block?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    AdminStore.blockStaff({ id: item?.id }, navigationCallBackBlock)
                }
            });
    }

    const confirm = (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this admin details!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    AdminStore.deleteStaff({ id: id }, navigationCallBackBlock)
                }
            });
    }

    const navigationCallBackBlock = () => {
        AdminStore.getStaff();
    }
    const onEdit = (item) => {
        navigate('/admin/staffs-edit', { state: { item: item } });
    }
    const onDismiss = () => {
        setIsOpen(false)
    }
    const handlePaginationLengthChange = (newPageSize) => {
        setPaginationLength(newPageSize)
        AdminStore.data.pageSize = newPageSize;
        AdminStore.getStaff();
    };

    const handleChange = (event) => {
        setInputSearch(event.target.value);
    };

    const onSearch = () => {
        AdminStore.data.search = inputSearch;
        AdminStore.getStaff();
    }
    const onClearSearch = () => {
        setInputSearch('');
        AdminStore.data.search = '';
        AdminStore.getStaff();
    }
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            onSearch();
        }
    };
    const setShowSyncModal = () => {
        swal({
            title: "Import Caregiver",
            icon: "info",
            buttons: true,
            dangerMode: true,
            content: {
                element: "input",
                attributes: {
                    placeholder: "Enter bluesky id",
                    type: "number",
                    required: true,
                },
            },
        })
            .then((id) => {
                if (id) {
                    let param = {
                        bluesky_id: id
                    }
                    AdminStore.syncCaregiverWithBluesky(param, navigationCallBackSync)
                }
            });
    }

    const navigationCallBackSync = () => {
        swal("Success", "Successfully caregiver imported", "success").then((value) => {
            // This block will be executed when the user interacts with the swal dialog
            // You can put your code here to handle the user's action
            onSearch()
        });
    }
    return (
        <React.Fragment>
            {/* <body> */}
            <div className="container-scroller">
                <HeaderPage />
                <div className="container-fluid page-body-wrapper">
                    <DrawerMenuPage />
                    <div className="main-panel" style={{ right: 0, position: "absolute" }}>
                        <div className="content-wrapper p-3 bg-white ">
                            <h3 className="page-title">
                                <span className="page-title-icon linear text-white me-2">
                                    <i className="fas fa-user-tie"></i>
                                </span> Caregiver
                            </h3>
                        </div>
                        <div className='col-12 d-flex justify-content-lg-end mb-2 pe-2'>
                            <a onClick={() => setShowSyncModal()}>
                                <h1 className="page-title signup-link fs-4" >
                                    <span className="page-title-icon linear text-white me-2">
                                        <i className="fas fa-plus"></i>
                                    </span>Import Caregiver
                                </h1>
                            </a>
                        </div>
                        <div className="d-flex justify-content-end me-1">
                            <CInputGroup className="w-25">
                                <CFormInput placeholder="Search" disabled={AdminStore.data.search} value={inputSearch} onChange={handleChange} onKeyPress={handleKeyPress} />
                                {inputSearch && (
                                    <CButton onClick={onClearSearch} type="button" color="primary" variant="outline">
                                        <i className="fa fa-times" aria-hidden="true"></i>
                                    </CButton>
                                )}
                                <CButton onClick={onSearch} type="button" color="primary" variant="outline">
                                    <i className="fa fa-search" aria-hidden="true"></i>
                                </CButton>
                            </CInputGroup>

                        </div>

                        <div className="col-lg-12">
                            <div className="row bg-white" style={{ height: 500, paddingTop: 20 }}>
                                <table className="table dt-responsive table-hover nowrap" style={{ width: "100%" }} id={tableName} ref={tableRef}></table>
                            </div>
                        </div>
                    </div>

                    {/* <!-- main-panel ends --> */}
                </div>
                {/* <!-- page-body-wrapper ends --> */}
            </div>
            <Spinner isLoading={AdminStore.loading} />
            <BottomSheet open={isOpen} onDismiss={onDismiss}
                header={
                    <div>
                        <div className="text-end" style={{ cursor: "pointer" }} onClick={onDismiss}>
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </div>
                        <div className="d-flex items-center">
                            <h4 className="me-3 text-xl font-bold text-primary-800">
                                {profile?.first_name?.toUpperCase()} PROFILE DETAILS
                            </h4>
                            {profile?.is_imported !== 1 && <>
                                <div className="progressBox">
                                    <div className="progress ">
                                        <div className="progress-bar progress-bar-striped stripBar "
                                            role="progressbar"
                                            style={{ width: `${progressOne}%` }}
                                            aria-valuenow={progressOne} aria-valuemin="0" aria-valuemax="100">
                                            {progressOne}%
                                        </div>
                                    </div>
                                    <small>Personal Info</small>
                                </div>
                                &nbsp;
                                &nbsp;
                                <div className="progressBox">
                                    <div className="progress ">
                                        <div className="progress-bar progress-bar-striped stripBar "
                                            role="progressbar"
                                            style={{ width: progressTwo + "%" }}
                                            aria-valuenow={progressTwo} aria-valuemin="0" aria-valuemax="100">
                                            {progressTwo}%
                                        </div>
                                    </div>
                                    <small>Professional Info</small>
                                </div>
                                &nbsp;
                                &nbsp;
                                <div className="progressBox">
                                    <div className="progress ">
                                        <div className="progress-bar progress-bar-striped stripBar "
                                            role="progressbar"
                                            style={{ width: progressThree + "%" }}
                                            aria-valuenow={progressThree} aria-valuemin="0" aria-valuemax="100">
                                            {progressThree}%
                                        </div>
                                    </div>
                                    <small>Availability</small>
                                </div>
                                &nbsp;
                                &nbsp;
                                <div className="progressBox">
                                    <div className="progress ">
                                        <div className="progress-bar progress-bar-striped stripBar "
                                            role="progressbar"
                                            style={{ width: progressFour + "%" }}
                                            aria-valuenow={progressFour} aria-valuemin="0" aria-valuemax="100">
                                            {progressFour}%
                                        </div>
                                    </div>
                                    <small>Documents</small>
                                </div>
                                &nbsp;
                                &nbsp;
                                <div className="progressBox">
                                    <div className="progress ">
                                        <div className="progress-bar progress-bar-striped stripBar "
                                            role="progressbar"
                                            style={{ width: progressFive + "%" }}
                                            aria-valuenow={progressFive} aria-valuemin="0" aria-valuemax="100">
                                            {progressFive}%
                                        </div>
                                    </div>
                                    <small>Acknowledgments</small>
                                </div>
                                &nbsp;
                                &nbsp;
                                <div className="progressBox">
                                    <div className="progress ">
                                        <div className="progress-bar progress-bar-striped stripBar "
                                            role="progressbar"
                                            style={{ width: progressSix + "%" }}
                                            aria-valuenow={progressSix} aria-valuemin="0" aria-valuemax="100">
                                            {progressSix}%
                                        </div>
                                    </div>
                                    <small>Finish</small>
                                </div>
                            </>}</div>
                    </div>
                }

            >
                <div style={{ height: global.config.windowHeight / 2.5, overflowY: 'scroll' }}>
                    <div className="col-xl-12 col-md-12">
                        <div className="user-card-full">
                            <div className="row m-l-0 m-r-0">
                                <div className="col-sm-12">
                                    <div className="card-block">
                                        <div className="d-flex justify-content-between">
                                            <div className="d-flex">
                                                <h6 className="m-b-20 p-b-5 f-w-600 me-2"> Caregiver ID:{profile?.id} </h6>
                                                <h6 className="m-b-20 p-b-5 f-w-600 ms-2"> Bluesky ID:{profile?.bluesky_staff_id || <span className="text-danger">&nbsp;Onboard Pending</span>} </h6>
                                            </div>
                                            <div>
                                                {profile?.job_profile?.apply_date && <span>Apply Date : <strong className="text-success">{profile?.job_profile?.apply_date} </strong></span>}
                                                {profile?.job_profile?.hire_date && <span>| Hire Date : <strong className="text-success">{profile?.job_profile?.hire_date}</strong></span>}
                                            </div>
                                        </div>
                                        <h6 className="m-b-20 p-b-5 b-b-default f-w-600">Basic Details</h6>
                                        <div className="row">
                                            {profile && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">First Name</p>
                                                <h6 className="text-muted f-w-400">{profile?.first_name || <span className="text-warning">Pending</span>}</h6>
                                            </div>}
                                            {profile && profile?.middle_name && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Middle Name</p>
                                                <h6 className="text-muted f-w-400">{profile?.middle_name}</h6>
                                            </div>}
                                            {profile && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Last Name</p>
                                                <h6 className="text-muted f-w-400">{profile?.last_name || <span className="text-warning">Pending</span>}</h6>
                                            </div>}
                                            {(profile && profile?.job_profile?.birth_date) && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Birth Date</p>
                                                <h6 className="text-muted f-w-400">{moment(profile?.job_profile?.birth_date).format('MM/DD/YYYY') || <span className="text-warning">Pending</span>}</h6>
                                            </div>}
                                            {profile && profile?.gender && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Gender</p>
                                                <h6 className="text-muted f-w-400">{profile?.job_profile?.gender === 'M' ? "MALE" : "FEMALE"}</h6>
                                            </div>}
                                            {profile && profile?.region_id && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Region</p>
                                                <h6 className="text-muted f-w-400">{regions?.find(reg => reg.ID == profile?.job_profile?.region_id)?.Name}</h6>
                                            </div>}
                                            {profile && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Address</p>
                                                <h6 className="text-muted f-w-400">{profile?.job_profile?.address || <span className="text-warning">Pending</span>}</h6>
                                            </div>}
                                            {(profile) && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Zipcode</p>
                                                <h6 className="text-muted f-w-400">{profile?.job_profile?.zipcode || <span className="text-warning">Pending</span>}</h6>
                                            </div>}
                                            {(profile) && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">City</p>
                                                <h6 className="text-muted f-w-400">{profile?.job_profile?.city || <span className="text-warning">Pending</span>}</h6>
                                            </div>}
                                            {(profile) && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">State</p>
                                                <h6 className="text-muted f-w-400">{profile?.job_profile?.state_id || <span className="text-warning">Pending</span>}</h6>
                                            </div>}
                                            {(profile) && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">SSN</p>
                                                <h6 className="text-muted f-w-400">{profile?.job_profile?.ssn || <span className="text-warning">Pending</span>}</h6>
                                            </div>}
                                            {profile && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Availability</p>
                                                <h6 className="text-muted f-w-400">{profile?.job_profile?.availability && JSON.parse(profile?.job_profile?.availability)?.map((prefer, index) => {
                                                    return prefer + (index === JSON?.parse(profile?.job_profile?.availability).length - 1 ? "" : ", ")
                                                }) || <span className="text-warning">Pending</span>}</h6>
                                            </div>}
                                            {(profile && profile?.job_profile?.available_date) && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Available Date</p>
                                                <h6 className="text-muted f-w-400">{moment(profile?.job_profile?.available_date).format('MM/DD/YYYY')}</h6>
                                            </div>}
                                            {(profile && profile?.job_profile?.available_day) && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Available Day</p>
                                                <h6 className="text-muted f-w-400">{JSON.parse(profile?.job_profile?.available_day)?.map((prefer, index) => {
                                                    return prefer + (index === JSON?.parse(profile?.job_profile?.available_day).length - 1 ? "" : ", ")
                                                })}</h6>
                                            </div>}
                                            {profile && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Interested In</p>
                                                <h6 className="text-muted f-w-400">{profile?.job_profile?.interested_in && JSON.parse(profile?.job_profile?.interested_in)?.map((prefer, index) => {
                                                    return prefer + (index === JSON?.parse(profile?.job_profile?.interested_in).length - 1 ? "" : ", ")
                                                }) || <span className="text-warning">Pending</span>}</h6>
                                            </div>}
                                            {(profile) && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Disability</p>
                                                <h6 className="text-muted f-w-400">{(profile?.job_profile?.disability === true ? "Yes" : "No") || <span className="text-warning">Pending</span>}</h6>
                                            </div>}
                                            {(profile && profile?.job_profile?.info_sources) ? <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Info Sources</p>
                                                <h6 className="text-muted f-w-400">{infoSource?.find(info => info.SystemValueID === profile?.job_profile?.info_sources)?.SystemValue}</h6>
                                            </div> : null}
                                            {(profile) && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Preferred State</p>
                                                <h6 className="text-muted f-w-400">{profile?.job_profile?.preferred_state && JSON.parse(profile?.job_profile?.preferred_state)?.map((prefer, index) => {
                                                    const stateName = states?.find(stat => stat?.abbreviation === prefer)?.name || '-';
                                                    return stateName + (index === JSON?.parse(profile?.job_profile?.preferred_state).length - 1 ? "" : ", ")
                                                }) || <span className="text-warning">Pending</span>}</h6>
                                            </div>}
                                            {(profile) && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Contact Devices</p>
                                                <h6 className="text-muted f-w-400">{profile?.job_profile?.contact_devices && JSON.parse(profile?.job_profile?.contact_devices)?.map(cont => {
                                                    return <>{(devices?.find(device => device.SystemValueID == cont?.type)?.SystemValue) + " - " + (profile?.job_profile?.country_id != 0 ? profile?.job_profile?.country_id : "") + " " + cont.number}< br /></>
                                                }) || <span className="text-warning">Pending</span>}</h6>
                                            </div>}
                                            {(profile && profile?.job_profile?.last_updated_by) && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Last Updated By</p>
                                                <h6 className="text-muted f-w-400">{profile?.job_profile?.last_updated_by?.title} {profile?.job_profile?.last_updated_by?.first_name} {profile?.job_profile?.last_updated_by?.last_name}</h6>
                                            </div>}
                                            {(profile && profile?.job_profile?.last_updated_by) && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Last Update</p>
                                                <h6 className="text-muted f-w-400">{moment(profile?.job_profile?.updated_at).fromNow()}</h6>
                                            </div>}
                                        </div>
                                    </div>
                                    <div className="card-block">
                                        <h6 className="m-b-20 p-b-5 b-b-default f-w-600">Professional/Documents Details</h6>
                                        <div className="row">
                                            {(profile && profile?.job_profile?.degree_specialities) && <div className="col-sm-6">
                                                <p className="m-b-10 f-w-600">Profession Specialities</p>
                                                <h6 className="text-muted f-w-400">{
                                                    profile?.job_profile?.degree_specialities && JSON.parse(profile?.job_profile?.degree_specialities)?.map((prof, index) => {
                                                        return <div key={index + "list"}> {prof?.profession_name} {prof?.specialties_name ? "➤" : ""}  {prof?.specialties_name}{(prof?.exp && prof?.exp != 0) ? <> | <i className="fas fa-briefcase"></i> {prof?.exp} {prof?.exp == 1 ? "year" : "year(s)"}</> : ""}< br /></div>;
                                                    }) || <span className="text-warning">Pending</span>}</h6>
                                            </div>}
                                            {profile && <div className="col-sm-4">
                                                <p className="m-b-10 f-w-600">Documents</p>
                                                <h6 className="text-muted f-w-400">{
                                                    (profile?.job_profile?.staff_profile && profile?.documents?.length !== 0) && profile?.job_profile?.documents?.map((doc, index) => {
                                                        return <a href={BASE_API_URL_FILE + doc?.document_path} target="_blank" key={index + "doc"}> {requiredDoc.find(docum => docum.ID === doc.document_id)?.Name}  <i className="fa fa-eye text-primary" aria-hidden="true"></i>< br /></a>;
                                                    }) || <span className="text-warning">Pending</span>}</h6>
                                            </div>}
                                            {(profile && profile?.job_profile?.remark) && <div className="col-sm-3">
                                                <p className="m-b-10 f-w-600">Rejection Reason</p>
                                                <h6 className="text-danger f-w-400">{profile?.job_profile?.remark}</h6>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BottomSheet>
        </React.Fragment>
    );
})

export default StaffsList;