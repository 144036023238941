import React from 'react';
import { CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter, CButton } from '@coreui/react';
import { CFormCheck, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react-pro'
import moment from "moment";
import TimeRangePicker from '@wojtekmaj/react-timerange-picker';

const EmployeeModal = ({
    showSlotModal,
    setShowSlotModal,
    pendingContractList,
    pendingShiftList,
    pendingContractExtList,
    handleSelectAllContract,
    contractAdd,
    slotAdd,
    handleSelectAllSlots,
    handleSelectAllContractExt,
    contractAcceptReject,
    slotAcceptReject,
    contractExtAcceptReject,
    slotExtAcceptReject,
    slotExtAdd,
    handleSelectAllSlotsExt,
    contractExtAdd,
    pendingShiftExtList,
    selectedContractIds,
    selectedSlotIds
}) => {
    return (
        <CModal
            id="approval"
            size="lg"
            backdrop="static"
            visible={showSlotModal}
            onClose={() => setShowSlotModal(false)}
        >
            <CModalHeader closeButton>
                <CModalTitle>Shift Request's</CModalTitle>
            </CModalHeader>
            <CModalBody>
                {/* CONTRACT TABLE */}
                {(pendingContractList && pendingContractList?.length !== 0) && <>
                    <h5 className="d-flex justify-content-center pt-2"><b>CONTRACTS</b></h5>
                    <CTable responsive>
                        <CTableHead>
                            <CTableRow>
                                <CTableHeaderCell>
                                    <CFormCheck onClick={handleSelectAllContract} data-toggle="tooltip" data-placement="right" title="Approve All"
                                        checked={pendingContractList?.every(({ id }) => selectedContractIds?.some(y => y.id == id && y.code === 5))}
                                        inline style={{ borderColor: "green" }} type="radio" name="all_contract" id="all_contract_approve" value="Approve" label="Approve All" />
                                </CTableHeaderCell>

                                <CTableHeaderCell>
                                    <CFormCheck onClick={handleSelectAllContract} data-toggle="tooltip" data-placement="right" title="Reject All" inline style={{ borderColor: "red" }}
                                        checked={pendingContractList?.every(({ id }) => selectedContractIds?.some(y => y.id == id && y.code === 3))}
                                        type="radio" name="all_contract" id="all_contract_reject" value="Reject" label="Reject All" />
                                </CTableHeaderCell>
                                <CTableHeaderCell scope="col">Shift Ref.</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Shift Date/Time</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Request By</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Organization Name</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Contact</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Email</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Requested Time</CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>
                        <CTableBody>
                            {/* FOR CONTACT */}
                            {pendingContractList?.map((care, index) => {
                                return <CTableRow key={index + "care"}>
                                    <CTableHeaderCell id="approve-contract">
                                        <CFormCheck inline
                                            data-toggle="tooltip" data-placement="right" title="Approve"
                                            style={{ borderColor: "green" }} type="radio"
                                            id={care.id + "approve"}
                                            name={care.id}
                                            label=""
                                            value={"Approve"}
                                            onClick={(e) => {
                                                contractAdd(e.target.value, care.id);
                                            }}
                                            checked={selectedContractIds?.some(x => x.id == care.id && x.code === 5)}

                                        />
                                    </CTableHeaderCell>
                                    <CTableHeaderCell id="reject-contract">
                                        <CFormCheck inline
                                            data-toggle="tooltip" data-placement="right" title="Reject"
                                            style={{ borderColor: "red" }} type="radio"
                                            name={care.id}
                                            id={care.id + "reject"}
                                            value={"Reject"}
                                            label=""
                                            onClick={(e) => {
                                                contractAdd(e.target.value, care.id);
                                            }}
                                            checked={selectedContractIds?.some(x => x.id == care.id && x.code === 3)}

                                        />
                                    </CTableHeaderCell>
                                    <CTableHeaderCell>{care.id}</CTableHeaderCell>
                                    <CTableHeaderCell>{
                                        <span>
                                            <input className="slot" readOnly value={moment(care?.start_date, 'YYYY-MM-DD').format('MM/DD/YYYY')} />
                                            <span className='slot-to'>To</span>
                                            <input className="slot-right" readOnly value={moment(care?.end_date, 'YYYY-MM-DD').format('MM/DD/YYYY')} />
                                            <TimeRangePicker
                                                value={[care?.start_time, care?.end_time]}
                                                disabled={true}
                                                className={"timer"}
                                                rangeDivider="To"
                                                clearIcon={null}
                                                required
                                                disableClock={true}
                                            />
                                        </span>}
                                    </CTableHeaderCell>
                                    <CTableDataCell>{care?.request?.user?.first_name} {care?.request?.user?.last_name}</CTableDataCell>
                                    <CTableDataCell> {care?.request?.user?.organization_name}</CTableDataCell>
                                    <CTableDataCell><a href={"tel:" + care?.user?.contact}>{care?.user?.contact}&nbsp;&nbsp;{care?.request?.user?.contact && <i className="fas fa-phone"></i> || "-"}</a></CTableDataCell>
                                    <CTableDataCell><a href={"mailto:" + care?.user?.email}> {care?.user?.email}&nbsp;&nbsp;<i className="fas fa-envelope"></i></a></CTableDataCell>
                                    <CTableDataCell>{moment(care.created_at).fromNow()}</CTableDataCell>
                                </CTableRow>
                            })}

                        </CTableBody>
                    </CTable>
                </>}
                {/* SLOTS TABLE */}
                {(pendingShiftList && pendingShiftList?.length !== 0) && <><h5 className="d-flex justify-content-center pt-2"><b>SHIFT</b></h5>
                    <CTable responsive>
                        <CTableHead>
                            <CTableRow>
                                <CTableHeaderCell>
                                    <CFormCheck onClick={handleSelectAllSlots} data-toggle="tooltip" data-placement="right" title="Approve All"
                                        checked={pendingShiftList?.every(({ id }) => selectedSlotIds?.some(y => y.id === id && y.code === 2))}
                                        inline style={{ borderColor: "green" }} type="radio" value="Approve" label="Approve All" name="all_slot" id="all_slot_approve" />
                                </CTableHeaderCell>
                                <CTableHeaderCell>
                                    <CFormCheck onClick={handleSelectAllSlots} data-toggle="tooltip" name="all_slot" id="all_slot_reject"
                                        data-placement="right" title="Reject All" inline style={{ borderColor: "red" }} type="radio"
                                        checked={pendingShiftList?.every(({ id }) => selectedSlotIds?.some(y => y.id === id && y.code === 1))}
                                        value="Reject" label="Reject All" />
                                </CTableHeaderCell>
                                <CTableHeaderCell scope="col">Shift Ref.</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Shift Date/Time</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Request By</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Organization Name</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Contact</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Email</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Requested Time</CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>
                        <CTableBody>
                            {pendingShiftList?.map((care, index) => {
                                return <CTableRow key={index + "care"}>
                                    <CTableHeaderCell id="approve-slots">
                                        <CFormCheck inline
                                            data-toggle="tooltip" data-placement="right" title="Approve"
                                            style={{ borderColor: "green" }} type="radio"
                                            id={care.id + "approve"}
                                            name={care.id}
                                            label=""
                                            value={"Approve"}
                                            onClick={(e) => {
                                                slotAdd(e.target.value, care.id);
                                            }}
                                            checked={selectedSlotIds?.some(x => x.id === care.id && x.code === 2)}
                                        />
                                    </CTableHeaderCell>
                                    <CTableHeaderCell id="reject-slots">
                                        <CFormCheck inline
                                            data-toggle="tooltip" data-placement="right" title="Reject"
                                            style={{ borderColor: "red" }} type="radio"
                                            name={care.id}
                                            id={care.id + "reject"}
                                            value={"Reject"}
                                            label=""
                                            onClick={(e) => {
                                                slotAdd(e.target.value, care.id);
                                            }}
                                            checked={selectedSlotIds?.some(x => x.id === care.id && x.code === 1)}
                                        />
                                    </CTableHeaderCell>
                                    <CTableHeaderCell>{care.id}</CTableHeaderCell>
                                    <CTableHeaderCell>{
                                        <span>
                                            <input className={"slot"} readOnly value={care?.slot_date} />
                                            <TimeRangePicker
                                                value={[care?.slot_start_time, care?.slot_end_time]}
                                                disabled={true}
                                                className={"timer"}
                                                rangeDivider="To"
                                                clearIcon={null}
                                                required
                                                disableClock={true}
                                            />
                                        </span>}
                                    </CTableHeaderCell>
                                    <CTableDataCell>{care?.request?.user?.first_name} {care?.user?.last_name}</CTableDataCell>
                                    <CTableDataCell> {care?.request?.user?.organization_name}</CTableDataCell>
                                    <CTableDataCell><a href={"tel:" + care?.request?.user?.contact}>{care?.request?.user?.contact}&nbsp;&nbsp;{care?.request?.user?.contact && <i className="fas fa-phone"></i> || "-"}</a></CTableDataCell>
                                    <CTableDataCell><a href={"mailto:" + care?.request?.user?.email}> {care?.request?.user?.email}&nbsp;&nbsp;<i className="fas fa-envelope"></i></a></CTableDataCell>
                                    <CTableDataCell>{moment(care.created_at).fromNow()}</CTableDataCell>
                                </CTableRow>
                            })}

                        </CTableBody>

                    </CTable></>}
                {/* CONTRACT EXT TABLE */}
                {(pendingContractExtList && pendingContractExtList?.length !== 0) && <>
                    <h5 className="d-flex justify-content-center pt-2"><b>CONTRACTS EXTENSION REQUEST</b></h5>
                    <CTable responsive>
                        <CTableHead>
                            <CTableRow>
                                <CTableHeaderCell>
                                    <CFormCheck onClick={handleSelectAllContractExt} data-toggle="tooltip" data-placement="right" title="Approve All"
                                        inline style={{ borderColor: "green" }} type="radio" name="all_contract_ext" id="all_contract_ext_approve" value="Approve" label="Approve All" />
                                </CTableHeaderCell>

                                <CTableHeaderCell>
                                    <CFormCheck onClick={handleSelectAllContractExt} data-toggle="tooltip" data-placement="right" title="Reject All" inline style={{ borderColor: "red" }}
                                        type="radio" name="all_contract_ext" id="all_contract_ext_reject" value="Reject" label="Reject All" />
                                </CTableHeaderCell>
                                <CTableHeaderCell scope="col">Shift Ref.</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Shift Date/Time</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Request By</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Organization Name</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Contact</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Email</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Requested Time</CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>
                        <CTableBody>
                            {/* FOR CONTACT */}
                            {pendingContractExtList?.map((care, index) => {
                                return <CTableRow key={index + "care"}>
                                    <CTableHeaderCell id="approve-contract-ext">
                                        <CFormCheck inline
                                            data-toggle="tooltip" data-placement="right" title="Approve"
                                            style={{ borderColor: "green" }} type="radio"
                                            id={care.id + "approve"}
                                            name={care.id}
                                            label=""
                                            value={"Approve"}
                                            onClick={(e) => {
                                                var checkbox = document.getElementById("all_contract_ext_reject");
                                                checkbox.checked = false;
                                                contractExtAdd(e.target.value, care.id);
                                            }}
                                        />
                                    </CTableHeaderCell>
                                    <CTableHeaderCell id="reject-contract-ext">
                                        <CFormCheck inline
                                            data-toggle="tooltip" data-placement="right" title="Reject"
                                            style={{ borderColor: "red" }} type="radio"
                                            name={care.id}
                                            id={care.id + "reject"}
                                            value={"Reject"}
                                            label=""
                                            onClick={(e) => {
                                                var checkbox = document.getElementById("all_contract_ext_approve");
                                                checkbox.checked = false;
                                                contractExtAdd(e.target.value, care.id);
                                            }}
                                        />
                                    </CTableHeaderCell>
                                    <CTableHeaderCell>{care.id}</CTableHeaderCell>
                                    <CTableHeaderCell>{
                                        <span>
                                            <input className="slot" readOnly value={moment(care?.start_date, 'YYYY-MM-DD').format('MM/DD/YYYY')} />
                                            <span className='slot-to'>To</span>
                                            <input className="slot-right" readOnly value={moment(care?.end_date, 'YYYY-MM-DD').format('MM/DD/YYYY')} />
                                            <TimeRangePicker
                                                value={[care?.start_time, care?.end_time]}
                                                disabled={true}
                                                className={"timer"}
                                                rangeDivider="To"
                                                clearIcon={null}
                                                required
                                                disableClock={true}
                                            />
                                        </span>}
                                    </CTableHeaderCell>
                                    <CTableDataCell>{care?.user?.first_name} {care?.user?.last_name}</CTableDataCell>
                                    <CTableDataCell><a href={"tel:" + care?.user?.contact}>{care?.user?.contact}&nbsp;&nbsp;{care?.user?.contact && <i className="fas fa-phone"></i> || "-"}</a></CTableDataCell>
                                    <CTableDataCell><a href={"mailto:" + care?.user?.email}> {care?.user?.email}&nbsp;&nbsp;<i className="fas fa-envelope"></i></a></CTableDataCell>
                                    <CTableDataCell>{moment(care.created_at).fromNow()}</CTableDataCell>
                                </CTableRow>
                            })}

                        </CTableBody>
                    </CTable>
                </>}
                {/* SLOTS EXT TABLE */}
                {(pendingShiftExtList && pendingShiftExtList?.length !== 0) && <><h5 className="d-flex justify-content-center pt-2"><b>SLOTS EXTENSION REQUEST</b></h5>
                    <CTable responsive>
                        <CTableHead>
                            <CTableRow>
                                <CTableHeaderCell>
                                    <CFormCheck onClick={handleSelectAllSlotsExt} data-toggle="tooltip" data-placement="right" title="Approve All"
                                        inline style={{ borderColor: "green" }} type="radio" value="Approve" label="Approve All" name="all_slot_ext" id="all_slot_ext_approve" />
                                </CTableHeaderCell>
                                <CTableHeaderCell>
                                    <CFormCheck onClick={handleSelectAllSlotsExt} data-toggle="tooltip" name="all_slot_ext" id="all_slot_ext_reject" data-placement="right" title="Reject All" inline style={{ borderColor: "red" }} type="radio" value="Reject" label="Reject All" />
                                </CTableHeaderCell>
                                <CTableHeaderCell scope="col">Slot Ref.</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Shift Date/Time</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Request By</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Contact</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Email</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Requested Time</CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>
                        <CTableBody>
                            {pendingShiftExtList?.map((care, index) => {
                                return <CTableRow key={index + "care"}>
                                    <CTableHeaderCell id="approve-slots-ext">
                                        <CFormCheck inline
                                            data-toggle="tooltip" data-placement="right" title="Approve"
                                            style={{ borderColor: "green" }} type="radio"
                                            id={care.id + "approve"}
                                            name={care.id}
                                            label=""
                                            value={"Approve"}
                                            onClick={(e) => {
                                                var checkbox = document.getElementById("all_slot_ext_reject");
                                                checkbox.checked = false;
                                                slotExtAdd(e.target.value, care.id);
                                            }}
                                        />
                                    </CTableHeaderCell>
                                    <CTableHeaderCell id="reject-slots-ext">
                                        <CFormCheck inline
                                            data-toggle="tooltip" data-placement="right" title="Reject"
                                            style={{ borderColor: "red" }} type="radio"
                                            name={care.id}
                                            id={care.id + "reject"}
                                            value={"Reject"}
                                            label=""
                                            onClick={(e) => {
                                                var checkbox = document.getElementById("all_slot_ext_approve");
                                                checkbox.checked = false;
                                                slotExtAdd(e.target.value, care.id);
                                            }}
                                        />
                                    </CTableHeaderCell>
                                    <CTableHeaderCell>{care.id}</CTableHeaderCell>
                                    <CTableHeaderCell>{
                                        <span>
                                            <input className={"slot"} readOnly value={care?.slot_date} />
                                            <TimeRangePicker
                                                value={[care?.slot_start_time, care?.slot_end_time]}
                                                disabled={true}
                                                className={"timer"}
                                                rangeDivider="To"
                                                clearIcon={null}
                                                required
                                                disableClock={true}
                                            />
                                        </span>}
                                    </CTableHeaderCell>
                                    <CTableDataCell>{care?.user?.first_name} {care?.user?.last_name}</CTableDataCell>
                                    <CTableDataCell><a href={"tel:" + care?.user?.contact}>{care?.user?.contact}&nbsp;&nbsp;{care?.user?.contact && <i className="fas fa-phone"></i> || "-"}</a></CTableDataCell>
                                    <CTableDataCell><a href={"mailto:" + care?.user?.email}> {care?.user?.email}&nbsp;&nbsp;<i className="fas fa-envelope"></i></a></CTableDataCell>
                                    <CTableDataCell>{moment(care.created_at).fromNow()}</CTableDataCell>
                                </CTableRow>
                            })}

                        </CTableBody>
                    </CTable></>}
                <div className="modal-footer justify-content-center">
                    <button type="button" className="btn btn-primary w-15" onClick={() => { contractAcceptReject(); slotAcceptReject(); contractExtAcceptReject(); slotExtAcceptReject(); }} >Submit</button>
                </div>
            </CModalBody>
        </CModal>
    );
};

export default EmployeeModal;
