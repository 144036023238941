import './JobItem.css'
import JobsStore from "@store/JobsStore";
import { toast } from "react-toastify";

const JobItem = ({ item, onOpen, isAuthenticated, bookmarkJobList }) => {
    var regex = /\b(\w{1})(\w+)(\w)\b/g;
    const bookmark = (data, e) => {
        e?.stopPropagation();
        e?.preventDefault();
        let param = {
            'job_id': data?.Guid,
            'title': data?.Title,
            'date': data?.Date,
            'end_date': data?.EndDate,
            'duration': data?.Duration,
            'company_name': data?.CompanyName,
            'reference_number': data?.Referencenumber,
            'url': data?.Url,
            'city': data?.City,
            'state': data?.State,
            'country': data?.Country,
            'zip': data?.Zip,
            'description': data?.Description,
            'category': data?.Category,
            'payrate': data?.PayRate,
            'expected_salary': data?.ExpectedSalary,
            'positions': data?.Positions,
            'experience': data?.Experience,
            'publish_date': data?.PublishDate,
            'default_email': data?.DefaultEmail,
            'recruiter_email': data?.RecruiterEmail,
            'degree': data?.Degree,
            'specialty': data?.Specialty,
            'shift': data?.Shift,
            'date_m': data?.DateM,
            'recruiter': data?.Recruiter,
            'hot_job': data?.HotJob,
            'facility_name': data?.FacilityName,
            'bill_rate': data?.BillRate,
            'source_name': data?.SourceName,
            'facility_type_id': data?.FacilityTypeId,
            'unit_description': data?.UnitDescription,
        }
        JobsStore.bookmarkJob(param, navigationCallBackBookmark)
    }
    const navigationCallBackBookmark = (msg) => {
        JobsStore.getBookmarkJob();
        toast.success("A job is successfully saved to your favourites list.");
    }
    const bookmarkDelete = (data, e) => {
        e.stopPropagation();
        e?.preventDefault();
        let param = {
            'job_id': data?.Guid,
        }
        JobsStore.deleteBookmarkJob(param, navigationCallBackBookmarkDelete)
    }
    const navigationCallBackBookmarkDelete = (msg) => {
        JobsStore.getBookmarkJob();
        toast.success("Successfully Removed");
    }
    return (
        <>
            <div className="grid__item cursor" onClick={(e) => onOpen(item,e)}>
                <a href='#'>
                    <div className='flip-card-inner'>
                        <div className="flip-card-front">
                            <div className="front-side">
                                <div className="info-grid">
                                    <div className="job">
                                        {item?.Guid && <h5 className="info-text"> JOB ID: {item?.Guid}</h5>}
                                    </div>
                                    <div className="jobname">
                                        <div className="flip-text pb-2">
                                            <div>
                                                <h5 className="title-text">{isAuthenticated ? item?.Title?.substr(0, item?.Title.lastIndexOf("/")) : item?.Title}
                                                </h5>
                                            </div>
                                        </div>
                                        {item?.CompanyName && <span><h5 className="info-text"><i className="fa fa-building pe-2" />{item?.CompanyName}</h5></span>}
                                        {item?.Degree && <span> <h5 className="info-text"><i className="fa fa-graduation-cap pe-1" aria-hidden="true"></i>{item?.Degree}</h5></span>}
                                        {item?.Specialty && <h5 className="info-text"><i className="fa fa-briefcase pe-2" aria-hidden="true"></i>{item?.Specialty}</h5>}
                                        {item?.PayRate > 0 && <h5 className="info-text"><i className="fas fa-dollar-sign pe-3" aria-hidden="true"></i>{item?.PayRate}</h5>}
                                        {item?.City && <p className="info-text"><span><i className="fa fa-map-marker-alt pe-2" />{item?.City}, {item?.State}</span></p>}
                                    </div>
                                    <div className="addr">
                                    </div>
                                    {isAuthenticated && <div className="vaccinated" >
                                        {bookmarkJobList?.includes(item?.Guid) ? <div className="bookmark-filled" onClick={(e) => bookmarkDelete(item, e)}></div> : <div className="bookmark" onClick={(e) => bookmark(item, e)}></div>}
                                    </div>}
                                    <div className="emailId">
                                        <p className="email">
                                            <strong></strong></p>
                                        {item?.Category && <p className="web">{item?.Category}</p>}
                                    </div>
                                    <div className="view-more" >
                                        <p className="email">
                                            <strong></strong></p>
                                        <p className="web" >View Details</p>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </a >
            </div >
        </>


    );
};
export default JobItem;
