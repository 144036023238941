/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import Header from '@components/Header';
import { Helmet } from 'react-helmet';
import { CFormCheck, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react-pro'
import { CModal, CModalHeader, CModalTitle, CButton, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CTooltip, CListGroup, CModalBody, CListGroupItem } from "@coreui/react";
import 'react-spring-bottom-sheet/dist/style.css';
import UserStore from "@store/UserStore";
import HomeStore from "@store/HomeStore";
import { observer } from "mobx-react-lite";
import { toJS } from 'mobx';
import { BottomSheet } from 'react-spring-bottom-sheet';
import TimeRangePicker from '@wojtekmaj/react-timerange-picker';
import Spinner from "@components/Spinner";
import moment from 'moment';
import swal from '@sweetalert/with-react';
import Images from '../../elements/Images';
import { toast } from 'react-toastify';
import { Formik, Field, FieldArray } from "formik";
import ReactDOM from "react-dom";
import { InputField } from "@elements/index";
import DatePicker, { getAllDatesInRange } from "react-multi-date-picker"
import weekends from "react-multi-date-picker/plugins/highlight_weekends"
import { useNavigate } from 'react-router-dom';
import { UpdateTCSchema, shiftImportClientSchema } from '@utils/schemas/allSchema';
import SelectField from "@elements/select/SelectField";
import ExcelPreview from "@elements/excelViewer/ExcelPreview";
import { useMediaQuery } from 'react-responsive';
import secureLocalStorage from 'react-secure-storage';

const { parse, isBefore, isAfter, addDays } = require('date-fns');
var states = require('@assets/states.json');
const StaffingRequestListPage = observer(() => {
    const isMobile = useMediaQuery({ maxWidth: 768 });

    var selectedIds = [];
    const formikRef = useRef(null);
    window.onscroll = function () { };
    const tableRef = useRef()
    const tableRefSlot = useRef()
    const navigate = useNavigate();
    const tableName = "table1"
    const tableNameSlot = "table2"
    const [staffingRequestList, setStaffingRequestList] = useState([]);
    const [staffingRequestContractList, setStaffingRequestContractList] = useState([]);
    const [degree, setDegree] = useState([]);
    const [unRatedShift, setUnratedShift] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [requestData, setRequestData] = useState();
    const [isOpenModal, setOpenModal] = useState(false);
    const [caregiverList, setCaregiverList] = useState();
    const [assignTime, setAssignTime] = useState();
    const [submitted, setSubmitted] = useState();
    const [rating, setRating] = useState();
    const [feedback, setFeedback] = useState("");
    const [formData, setFormData] = useState();
    const [disabledSlots, setDisabledSlots] = useState();
    const [isOpenForm, setIsOpenForm] = useState(false);
    const [reqSlotList, setReqSlotList] = useState([]);
    const [isOpenReqSlot, setIsOpenReqSlot] = useState(false);
    const [dates, setDates] = useState([])
    const [allDates, setAllDates] = useState([])
    const [assignDate, setAssignDate] = useState();
    const [activeTab, setActiveTab] = useState('tab1');
    const [editMode, setEditMode] = useState(false);
    const [cancelMode, setCancelMode] = useState(false);
    const [removeSlot, setRemoveSlot] = useState([]);
    const [isTouched, setIsTouched] = useState(false);
    const [editSlot, setEditSlot] = useState([]);
    const [isOpenFormTC, setIsOpenFormTC] = useState(false);
    const [tcFormDataTC, setTCFormData] = useState();
    const [isOpenFormEditTC, setIsOpenFormEditTC] = useState(false);
    const [isOpenCSVUpload, setIsOpenCSVUpload] = useState(false);
    const [specialties, setSpecialties] = useState([]);
    const [facilityList, setFacilityList] = useState([]);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        let userData = secureLocalStorage.getItem("userData");
        userData && setFacilityList(JSON.parse(userData)?.facilities)
        UserStore.data.alertPOP = false;
        // onFeedback()
        UserStore.getStaffingRequest();
        // UserStore.getUnratedShift();
    }, [])

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const type = urlParams.get('type');
        if (type === 'tab1') {
            setActiveTab(type)
        }
        const id = urlParams.get('id');
        let popUpData = staffingRequestList?.find(x => x.id == id?.replace(/\D/g, ""))
        if (popUpData) {
            onSlotApprovalOpen(popUpData)
        }
    }, [staffingRequestList]);

    useEffect(() => {
        setStaffingRequestList(toJS(UserStore?.data?.staffingRequestList))
        setStaffingRequestContractList(toJS(UserStore?.data?.staffingRequestList)?.filter(x => x.category === 'TC'))
    }, [UserStore?.data?.staffingRequestList]);

    useEffect(() => {
        setUnratedShift(toJS(UserStore?.data?.unRatedShift))
    }, [UserStore?.data?.unRatedShift]);

    useEffect(() => {
        setDegree(HomeStore?.data?.degreeList)
    }, [HomeStore?.data?.degreeList]);
    
    useEffect(() => {
        setSpecialties(HomeStore?.data?.dropDown)
    }, [HomeStore?.data?.dropDown]);

    useEffect(() => {
        if (rating && feedback) {
            let param = {
                request_id: requestData.id,
                rating: rating,
                feedback: feedback
            }
            UserStore.saveFeedback(param, navigationCallBack);
        }
    }, [submitted]);

    const navigationCallBack = () => {
        UserStore.getStaffingRequest();
        swal("Thank you for your review!", `We received ${rating}/5 rating from you.`, "success").then(() => {
            setFeedback()
            setRating()
            setSubmitted(false)
        })
    }

    // CONTRACT WISE
    useEffect(() => {
        $.fn.dataTable.ext.errMode = 'none';
        $($.fn.dataTable.tables(true)).DataTable()
            .columns.adjust();
        const table = $(`#${tableName}`).DataTable(
            {
                data: staffingRequestContractList,
                order: [], // Disable initial sorting
                columns: [
                    {
                        title: "Ref.", data: 'id',
                        width: 60,
                        render: function (data, type, full, meta) {
                            return data;
                        }
                    },

                    {
                        title: "Shift Date/Time", data: "assignments", "render": function (data, type, full, meta) {
                            const dates = data?.map(item => new Date(item?.slot_date));
                            // // Find the minimum and maximum dates
                            const minDate = new Date(Math?.min.apply(null, dates));
                            const maxDate = new Date(Math?.max.apply(null, dates));
                            // // Function to pad a number with leading zeros
                            const zeroPad = (value, length) => {
                                return value.toString().padStart(length, "0");
                            };
                            // // Format the dates as strings with zero-padded month and day values
                            const minDateString = `${zeroPad(minDate.getMonth() + 1, 2)}/${zeroPad(minDate.getDate(), 2)}/${minDate.getFullYear()}`;
                            const maxDateString = `${zeroPad(maxDate.getMonth() + 1, 2)}/${zeroPad(maxDate.getDate(), 2)}/${maxDate.getFullYear()}`;

                            return data?.length !== 0 ? '<strong><span class="pointer link-info">' + minDateString + " To " + maxDateString + '</span></strong>' :
                                full?.category === 'TC' ? '<strong><span class="pointer link-info">' + moment(full.start_date, 'YYYY-MM-DD').format('MM/DD/YYYY') + " To " + moment(full.end_date, 'YYYY-MM-DD').format('MM/DD/YYYY') + '</span></strong>' :
                                    "No Shift Available";
                        }
                    },
                    {
                        title: "Contract Details", "render": function (data, type, full, meta) {
                            return `
                            <div style="text-align: left; border: 1px solid #ccc; padding: 2px; display: grid; grid-template-columns: max-content auto;">
                                <strong style="border: 1px solid #ccc; padding: 2px;">Shift Type:</strong> 
                                <span style="border: 1px solid #ccc; padding: 2px;">${full?.shift_type}</span>
                                
                                <strong style="border: 1px solid #ccc; padding: 2px;">No Of Weeks:</strong> 
                                <span style="border: 1px solid #ccc; padding: 2px;">${full?.no_of_weeks}</span>
                                
                                <strong style="border: 1px solid #ccc; padding: 2px;">Hours/Week:</strong> 
                            <span style="border: 1px solid #ccc; padding: 2px;">${full?.hours_per_week}</span>

                                <strong style="border: 1px solid #ccc; padding: 2px;">Pay Details:</strong> 
                                <span style="border: 1px solid #ccc; padding: 2px;">${full?.pay_details}</span>
                                
                                <strong style="border: 1px solid #ccc; padding: 2px;">Qualification:</strong> 
                                <span style="border: 1px solid #ccc; padding: 2px;">${full?.qualifications}</span>
                            </div>
                        `;
                        }
                    },
                    {
                        title: "Caregiver Name", data: "staff_name", "render": function (data, type, full, meta) {
                            return data?.first_name ? ('<span class="text-success">' + (data?.first_name + " " + data?.last_name) + "</span>") : "No Assignment";
                        }
                    },
                    {
                        title: "Profession-Specialty", data: "profession", "render": function (data, type, full, meta) {
                            return full?.specialty_name ? full?.specialty_name : degree?.find(pro => pro.Id == data)?.Name;
                        }
                    },

                    {
                        title: "Category", width: 100, data: 'category', "render": function (data, type, full, meta) {
                            return global.config.shiftCaregory?.find(x => x.value === data)?.label
                        }
                    },
                    {
                        title: "State", width: 100, data: "state"
                    },
                    {
                        title: "City", width: 100, data: "city"
                    },
                    {
                        title: "Extention Times", width: 10, data: "extentions"
                    },
                    {
                        title: "Status", data: "status"
                    },

                    {
                        title: "Action", data: ""
                    },
                ],
                columnDefs: [
                    {
                        targets: 1,
                        responsivePriority: 1,
                        createdCell: function (td, cellData, rowData, row, col) {
                            var listHtml = "";
                            cellData.forEach(function (data) {
                                listHtml += "" + data?.slot_date + " "
                                    + moment(data?.slot_start_time, "HH:mm").format("hh:mm A") + " To "
                                    + moment(data?.slot_end_time, "HH:mm").format("hh:mm A") + " "
                                    + (data.status === 2 ? "Filled"
                                        : data.status === 3 ? "Rejected"
                                            : data.status === 4 ? "Extention Pending"
                                                : data.status === 5 ? "Cancelled By Caregiver"
                                                    : data.status === 6 ? "Cancelled By Facility"
                                                        : data.status === 7 ? "Completed" : "Unfilled") + "\n";
                            });
                            listHtml += "";
                            $(td).attr('data-toggle', 'tooltip');
                            $(td).attr('data-html', 'true');
                            $(td).attr('data-placement', 'top');
                            $(td).attr('title', listHtml);
                        },
                    },
                    {
                        targets: 10,
                        responsivePriority: 1,
                        createdCell: (td, cellData, rowData, row, col) =>
                            ReactDOM.render(
                                <div>
                                    <CDropdown variant="btn-group" direction='dropstart'>
                                        <CDropdownToggle size="sm" color="transparent" caret={false} href="#" className="p-0"><i className="fa fa-ellipsis-v text-primary"></i></CDropdownToggle>
                                        <CDropdownMenu >
                                            <CDropdownItem>
                                                <CButton onClick={() => {
                                                    showDetails(rowData)
                                                }} size="sm" className="w-100" variant='outline' color="primary" >
                                                    View <i className="fas fa-eye"></i>
                                                </CButton>
                                            </CDropdownItem>
                                            {
                                                (rowData?.category === 'TR' && (rowData?.status == 1 || rowData?.status == 3 || rowData?.status == 4) && !rowData?.extention_request) && <CDropdownItem>
                                                    <CButton onClick={() => {
                                                        onOpenForm(rowData)
                                                    }} size="sm" className="w-100" variant='outline' color="warning" >
                                                        Extend <i className="far fa-calendar-plus"></i>
                                                    </CButton>
                                                </CDropdownItem>
                                            }
                                            {/* FOR CONTACT */}
                                            {
                                                (rowData?.category === "TC" && rowData?.status === 5 && !rowData?.extention_request) && <CDropdownItem>
                                                    <CButton onClick={() => {
                                                        onOpenFormForTC(rowData)
                                                    }} size="sm" className="w-100" variant='outline' color="warning" >
                                                        Extend <i className="far fa-calendar-plus"></i>
                                                    </CButton>
                                                </CDropdownItem>
                                            }
                                            {
                                                (rowData?.assignments?.filter(obj => obj.staff_id && obj.status === 1)?.length > 0) || (rowData?.category === 'TC' && rowData?.status === 1 && rowData?.staff_id) || (rowData?.category === 'TC' && rowData?.status === 1 && rowData?.staff_id && rowData?.staff_response === 1) && <CDropdownItem>
                                                    <CButton onClick={() => {
                                                        onSlotApprovalOpen(rowData)
                                                    }} size="sm" className="w-100" color="warning" >
                                                        Order/Shift <i className="fas fa-user-clock"></i>
                                                    </CButton>
                                                </CDropdownItem>
                                            }
                                            {
                                                (rowData?.category === 'TC' && !rowData.staff_id) && <CDropdownItem>
                                                    <CButton onClick={() => {
                                                        editTCShift(rowData)
                                                    }} size="sm" className="w-100" variant='outline' color="primary" >
                                                        Edit <i className="fas fa-edit"></i>
                                                    </CButton>
                                                </CDropdownItem>
                                            }
                                            {
                                                (rowData?.category === 'TC' && !rowData.staff_id) && <CDropdownItem>
                                                    <CButton onClick={() => {
                                                        onDeleteContract(rowData)
                                                    }} size="sm" className="w-100" variant='outline' color="danger">
                                                        Delete <i className="fas fa-trash"></i>
                                                    </CButton>
                                                </CDropdownItem>
                                            }

                                        </CDropdownMenu>
                                    </CDropdown>
                                </div>
                                , td),
                    },
                    {
                        targets: 9,
                        responsivePriority: 2,
                        createdCell: (td, cellData, rowData, row, col) =>
                            ReactDOM.render(
                                <div>
                                    {console.log("status", rowData?.status)}
                                    <span>
                                        {
                                            rowData?.status == 0 ? <span className={'text-warning'}>Pending</span> :
                                                (rowData?.status == 1 && rowData?.staff_id && rowData?.staff_response == 0) || (rowData?.shift_data?.category === 'TC' && rowData?.shift_data?.staff_response === 0 && rowData?.shift_data?.status === 1) ? <span className={'text-warning'}><strong>Pending <br /> By Caregiver</strong></span> :
                                                    rowData?.extention_request
                                                        ? <span className={'text-info'}><b>Requested</b></span>
                                                        : ((rowData?.assignments?.filter(obj => obj.status === 1 && obj.staff_id)?.length > 0)
                                                            || (rowData?.category === 'TC' && rowData?.status === 1 && rowData?.staff_id && rowData?.staff_response === 1)) ? <strong><span className="text-info">Waiting For Approval!</span></strong> : (rowData?.category === 'TC' && rowData?.status === 1 && rowData?.staff_response === 0) ? <span className={'text-warning'}>Pending</span> :
                                                            rowData?.status == 2 ? <span className={'text-danger'}>Rejected</span>
                                                                : rowData?.status == 3 ? <span className={'text-dark'}>Closed</span>
                                                                    : (rowData?.assignments?.length === 0 && rowData?.category !== 'TC') ? <span className={'text-dark'}>No Shift Available</span>
                                                                        : rowData?.assignments?.some(item => item.status === 4) ? <span className={'text-warning'}>Extention Pending</span>
                                                                            : (rowData?.assignments?.length > 0 && rowData?.assignments?.every(item => item.status === 2 && item?.staff_id)) || (rowData?.category === 'TC' && rowData?.status === 5 && rowData?.staff_id && rowData?.staff_response === 1) ? <span className={'text-success'}><b>Filled</b></span>
                                                                                : (rowData?.assignments?.some(item => item.status === 2 && item?.staff_id))
                                                                                    ? <span className={'text-warning'}><b>Partially Filled</b></span>
                                                                                    : rowData?.status == 1 ? <span className={'text-danger'}>Unfilled</span>
                                                                                        : rowData?.status == 4 ? <span className={'text-success'}>Extended</span> : ""}

                                    </span>

                                    {
                                        (((rowData.status === 1 && !rowData.staff_id) && (rowData?.assignments?.filter(obj => obj.status === 1 && obj.staff_id)?.length > 0)) || (rowData?.category === 'TC' && rowData?.status === 1 && rowData?.staff_id && rowData?.staff_response === 1)) && <div>
                                            <CButton onClick={() => {
                                                onSlotApprovalOpen(rowData)
                                            }} size="sm" className="w-100 mt-1" color="warning" >
                                                Approve/Reject Shift
                                            </CButton>
                                        </div>
                                    }
                                </div>
                                , td),
                    },

                ],
                destroy: true,  // I think some clean up is happening here
                searching: true,
                "language": {
                    search: '',
                    searchPlaceholder: "Search",
                    emptyTable: "No Record Found"
                },
                responsive: false,
                initComplete: function () {

                    // Apply the search
                    this.api()
                        .columns([0, 2, 3, 4, 7, 8])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><input class="form-control " style="width:100%;margin-top:5px" type="text" placeholder="Filter" />')
                                .appendTo($(column.header()))

                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $('input', this.header()).on('keyup change clear', function () {
                                if (column.search() !== this.value) {
                                    column.search(this.value).draw();
                                }
                            });
                        });
                    this.api()
                        .columns([1])//Date filter
                        .every(function () {
                            var column = this;
                            var select = $('<br/><div class="input-daterange input-group" style="width:250px;margin-top:5px"></div>')
                                .appendTo($(column.header()));

                            var startDateInput = $('<input type="text" class="form-control" placeholder="Start Date" style="border-top-left-radius:8px;border-bottom-left-radius:8px;"/>');
                            var endDateInput = $('<input type="text" class="form-control" placeholder="End Date"  style="border-top-right-radius:8px;border-bottom-right-radius:8px;"/>');

                            startDateInput.appendTo(select);
                            endDateInput.appendTo(select);

                            $('.input-daterange').datepicker({
                                format: 'mm-dd-yyyy', // Specify your desired date format here
                                // autoclose: true,
                                todayHighlight: true, // Highlight today's date
                                clearBtn: true, // Show the "Clear" button
                                orientation: 'bottom',
                                daysOfWeekHighlighted: '0,6', // Highlight weekends (0: Sunday, 6: Saturday)
                                templates: {
                                    leftArrow: '<i class="fas fa-chevron-left"></i>', // Customize the left arrow icon
                                    rightArrow: '<i class="fas fa-chevron-right"></i>', // Customize the right arrow icon
                                },
                            });

                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $('input', this.header()).on('keyup change clear', function () {
                                if (!this.value) {
                                    $(`#${tableName}`).DataTable().column([2]).search('').draw();
                                }
                                if (column.search() !== this.value) {
                                    column.search(moment(this.value).format('MM/DD/YYYY')).draw();
                                }
                            });
                        });

                    this.api().columns([5]).every(function () {//Category Filter
                        var column = this;
                        var select = $('<br/><select class="form-control" placeholder="search" style="width:100%;margin-top:5px;background-color:white""><option value="">All</option></select>')
                            .appendTo($(column.header()))
                            .on('change', function () {
                                var val = $.fn.dataTable.util.escapeRegex($(this).val());
                                column.search(val ? '^' + val + '$' : '', true, false).draw();
                            });

                        $(select).click(function (e) {
                            e.stopPropagation();
                        });

                        column
                            .data()
                            .unique()
                            .sort()
                            .each(function (d, j) {
                                if (select.find('option[value="' + (global.config.shiftCaregory.find(x => x.value == d))?.label + '"]').length === 0) {
                                    select.append('<option value="' + (global.config.shiftCaregory.find(x => x.value == d)?.label) + '">' + (global.config.shiftCaregory.find(x => x.value == d)?.label) + '</option>');
                                }
                            });
                    });
                    this.api()//STATE FILTER
                        .columns([6])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><select class="form-control" placeholder="search" style="width:100%;margin-top:5px;background-color:white""><option value="" selected>All</option></select>')
                                .appendTo($(column.header()))
                                .on('change', function () {
                                    var val = $.fn.dataTable.util.escapeRegex($(this).val());
                                    column.search(val ? '^' + val + '$' : '', true, false).draw();
                                });
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            column
                                .data()
                                .unique()
                                .sort()
                                .each(function (d, j) {
                                    let name = states?.find(stat => stat?.abbreviation == d);
                                    select.append('<option value="' + name?.abbreviation + '">' + name?.name + '</option>');
                                });
                        });
                    this.api()
                        .columns([9])//STATUS FILTER
                        .every(function () {
                            var column = this;
                            var select = $('<br/><select class="form-control" placeholder="search" style="width:100%;margin-top:5px;background-color:white""><option value="">All</option></select>')
                                .appendTo($(column.header()))
                                .on('change', function () {
                                    var val = $.fn.dataTable.util.escapeRegex($(this).val());
                                    column.search(val ? '^' + val + '$' : '', true, false).draw();
                                });
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            [{ "name": "Pending", "value": 0 }, { "name": "Unfilled", "value": 1 }, { "name": "Rejected", "value": 2 }, { "name": "Extention Pending", "value": 4 }, { "name": "Cancelled By You", "value": 5 }, { "name": "Cancelled By Facility", "value": 6 }, { "name": "Closed", "value": 3 }].forEach(function (option) {
                                var selected = '';
                                select.append('<option value="' + option.value + '" ' + selected + '>' + option?.name + '</option>');
                            });
                        });
                },
            }
        )
        // table.column(1).visible(false);
        table.on('click', 'td', function () {
            // Get the column index of the clicked cell
            const columnIndex = table.cell(this).index()?.column;
            // Check if the clicked cell belongs to the desired column
            if (columnIndex === 1) {
                // Get the data for the clicked cell
                const rowData = table.row($(this).closest('tr')).data();
                // Handle the event for the clicked cell
                if (rowData) {
                    showDetails(rowData)
                }
            }
        });

        // Extra step to do extra clean-up.
        return function () {
            table.destroy()
        }
    }, [staffingRequestList, degree])

    // SLOT WISE
    useEffect(() => {
        const assignmentDataWithId = staffingRequestList?.flatMap(item =>
            item.assignments.map(assignment => ({ ...assignment, shift_data: item }))
        );
        $.fn.dataTable.ext.errMode = 'none';
        $($.fn.dataTable.tables(true)).DataTable()
            .columns.adjust();

        const tableSlot = $(`#${tableNameSlot}`).DataTable(
            {
                data: assignmentDataWithId,
                order: [], // Disable initial sorting
                columns: [
                    {
                        title: "Order Ref.", data: 'shift_id',
                        width: 60,
                        render: function (data, type, full, meta) {
                            return data;
                        }
                    },

                    {
                        title: "Shift Date", data: 'slot_date', "render": function (data, type, row, meta) {
                            return '<strong><a class="link-info pointer">' + data + '</a></strong>'
                        }
                    },
                    {
                        title: "Time", "render": function (data, type, row, meta) {
                            return '<strong><a class="link-info pointer">' + moment(row?.slot_start_time, "HH:mm").format("hh:mm A") + " To " + moment(row?.slot_end_time, "HH:mm").format("hh:mm A") + '</a></strong>'
                        }
                    },
                    {
                        title: "Shift Type", data: 'slot_type',
                    },
                    {
                        title: "Facility Name", data: "shift_data", "render": function (data, type, full, meta) {
                            return facilityList?.find(x => x.id === data?.facility_id)?.organization_name;
                        }

                    },
                    {
                        title: "Assigned Caregiver", data: "staff", "render": function (data, type, full, meta) {
                            return data?.first_name ? '<strong><a class="link-info pointer">' + data?.first_name + " " + data?.last_name + '</a></strong>' : "No Assignment";
                        }

                    },
                    {
                        title: "Profession-Specialty", data: "shift_data", "render": function (data, type, full, meta) {
                            return data?.specialty_name ? data?.specialty_name : degree?.find(pro => pro.Id == data?.profession)?.Name;
                        }
                    },
                    {
                        title: "Shift Category", data: 'shift_data', "render": function (data, type, row, meta) {
                            return global.config.shiftCaregory?.find(x => x.value === data.category)?.label;
                        }
                    },
                    {
                        title: "State", data: 'shift_data', "render": function (data, type, row, meta) {
                            return data?.state || "-";
                        }
                    },

                    {
                        title: "City", data: 'shift_data', "render": function (data, type, row, meta) {
                            return data?.city || "-";
                        }
                    },
                    {
                        title: "Shift Status", data: "status"
                    },
                    {
                        title: "Comment For Caregiver", data: "remark"
                    },
                    {
                        title: "Action", data: ""
                    },
                ],
                columnDefs: [
                    {
                        targets: 1,
                        responsivePriority: 1,
                    },
                    {
                        targets: 2,
                        responsivePriority: 2,
                    },
                    {
                        targets: 5,
                        responsivePriority: 3,
                    },
                    {
                        targets: 12,
                        responsivePriority: 4,
                        createdCell: (td, cellData, rowData, row, col) =>
                            ReactDOM.render(
                                <div>
                                    <CDropdown variant="btn-group" direction='dropstart'>
                                        <CDropdownToggle size="sm" color="transparent" caret={false} href="#" className="p-0"><i className="fa fa-ellipsis-v text-primary"></i></CDropdownToggle>
                                        <CDropdownMenu >
                                            <CDropdownItem>
                                                <CButton onClick={() => {
                                                    showDetails(rowData?.shift_data)
                                                }} size="sm" className="w-100" variant='outline' color="primary" >
                                                    View Shift <i className="fas fa-eye"></i>
                                                </CButton>
                                            </CDropdownItem>
                                            {/* FOR SLOT */}
                                            {
                                                (rowData?.shift_data?.category === "TR" && (rowData?.shift_data?.status == 1 || rowData?.shift_data?.status == 3 || rowData?.shift_data?.status == 4) && !rowData?.shift_data?.extention_request) && <CDropdownItem>
                                                    <CButton onClick={() => {
                                                        onOpenForm(rowData?.shift_data)
                                                    }} size="sm" className="w-100" variant='outline' color="warning" >
                                                        Extend Shift <i className="far fa-calendar-plus"></i>
                                                    </CButton>
                                                </CDropdownItem>
                                            }

                                            {
                                                (rowData?.shift_data?.assignments?.filter(obj => obj.staff_id && obj.status === 1)?.length > 0) || (rowData?.category === 'TC' && rowData?.status === 1 && rowData?.staff_id && rowData?.staff_response === 1) && <CDropdownItem>
                                                    <CButton onClick={() => {
                                                        onSlotApprovalOpen(rowData?.shift_data)
                                                    }} size="sm" className="w-100" color="warning" >
                                                        Order/Shifts <i className="fas fa-user-clock"></i>
                                                    </CButton>
                                                </CDropdownItem>
                                            }
                                        </CDropdownMenu>
                                    </CDropdown>
                                </div>
                                , td),
                    },
                    {
                        targets: 10,
                        responsivePriority: 5,
                        createdCell: (td, cellData, rowData, row, col) =>
                            ReactDOM.render(
                                <div>
                                    <span>
                                        {
                                            (rowData?.status == 1 && rowData?.staff_id && rowData?.shift_data?.staff_response === 0 && rowData?.shift_data?.staff_id) ? <strong> <span className={'text-warning'}>Pending
                                                By Caregiver </span></strong> : (rowData?.shift_data?.staff_response === 1 && rowData?.shift_data?.staff_id) || (rowData?.status === 1 && rowData?.staff_id) ? <strong> <span className={'text-info'}>Waiting For Approval!</span></strong> :
                                                rowData?.status == 1 ? <span className={'text-danger'}>Unfilled</span> :
                                                    rowData?.status == 2 ? <span className={'text-success'}>Confirmed</span> :
                                                        rowData?.status == 3 ? <span className={'text-danger'}>Rejected</span> :
                                                            rowData?.status == 4 ? <span className={'text-warning'}>Extention Pending</span> :
                                                                rowData?.status == 5 ? <span className={'text-danger'}>Cancelled by caregiver</span> :
                                                                    rowData?.status == 6 ? <span className={'text-danger'}>Cancelled by facility</span> :
                                                                        rowData?.status == 7 ? <span className={'text-muted'}>Closed</span> : ""}

                                    </span>

                                    {/* {
                                        (rowData?.status == 1 && rowData?.staff_id && !rowData?.shift_data?.staff_id) && <div>
                                            <CButton onClick={() => {
                                                onSlotApprovalOpen(rowData?.shift_data)
                                            }} size="sm" className="w-100 mt-1" color="warning" >
                                                Approve/Reject Slots
                                            </CButton>
                                        </div>
                                    } */}
                                </div>
                                , td),
                    }
                ],
                destroy: true,  // I think some clean up is happening here
                searching: true,
                scrollX: true,
                responsive: isMobile ? false : true,

                "language": {
                    search: '',
                    searchPlaceholder: "Search",
                    emptyTable: "No Record Found"
                },

                initComplete: function () {
                    // Apply the search
                    this.api()
                        .columns([0, 3, 5, 9, 11])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><input class="form-control " style="width:100%;margin-top:5px" type="text" placeholder="Filter" />')
                                .appendTo($(column.header()))

                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $('input', this.header()).on('keyup change clear', function () {
                                if (column.search() !== this.value) {
                                    column.search(this.value).draw();
                                }
                            });
                        });

                    this.api().columns([7])//Category Filter
                        .every(function () {
                            var column = this;
                            var select = $('<br/><select class="form-control" placeholder="search" style="width:100%;margin-top:5px;background-color:white""><option value="">All</option></select>')
                                .appendTo($(column.header()))
                                .on('change', function () {
                                    var val = $.fn.dataTable.util.escapeRegex($(this).val());
                                    column.search(val ? '^' + val + '$' : '', true, false).draw();
                                });

                            $(select).click(function (e) {
                                e.stopPropagation();
                            });

                            column
                                .data()
                                .unique()
                                .sort()
                                .each(function (d, j) {
                                    if (select.find('option[value="' + (d?.category === 'TR' ? 'Local Contract' : 'Per Diem') + '"]').length === 0) {
                                        select.append('<option value="' + (d?.category === 'TR' ? 'Local Contract' : 'Per Diem') + '">' + (d?.category === 'TR' ? 'Local Contract' : "Per Diem") + '</option>');
                                    }
                                });
                        });

                    this.api()
                        .columns([8])//State filter
                        .every(function () {
                            var column = this;
                            var select = $('<br/><select class="form-control" placeholder="search" style="width:100%;margin-top:5px;background-color:white""><option value="" selected>All</option></select>')
                                .appendTo($(column.header()))
                                .on('change', function () {
                                    var val = $.fn.dataTable.util.escapeRegex($(this).val());
                                    column.search(val ? '^' + val + '$' : '', true, false).draw();
                                });
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });

                            // Create an array to store unique values
                            var uniqueValues = [];

                            column
                                .data()
                                .unique()
                                .sort()
                                .each(function (d, j) {
                                    let name = states?.find(stat => stat?.abbreviation == d?.state);

                                    // Check if the value is already in the uniqueValues array
                                    if (name && uniqueValues.indexOf(name.abbreviation) === -1) {
                                        uniqueValues.push(name.abbreviation);
                                        select.append('<option value="' + name.abbreviation + '">' + name.name + '</option>');
                                    }
                                });
                        });
                    this.api()
                        .columns([1])//Date filter
                        .every(function () {
                            var column = this;
                            var select = $('<br/><input type="date" class="form-control" style="width:100%;margin-top:5px" type="text" placeholder="" />')
                                .appendTo($(column.header()))
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $('input', this.header()).on('keyup change clear', function () {
                                if (!this.value) {
                                    $(`#${tableNameSlot}`).DataTable().column([1]).search('').draw();
                                }
                                if (column.search() !== this.value) {
                                    column.search(moment(this.value).format('MM/DD/YYYY')).draw();
                                }
                            });
                        });
                    this.api()
                        .columns([2])//Time filter
                        .every(function () {
                            var column = this;
                            var select = $('<br/><input type="time" class="form-control" style="width:100%;margin-top:5px" type="text" placeholder="" />')
                                .appendTo($(column.header()))

                            $(select).click(function (e) {
                                e.stopPropagation();
                            });

                            $('input', this.header()).on('keyup change clear', function () {
                                if (!moment(this.value, "HH:mm").format("hh:mm A")) {
                                    $(`#${tableNameSlot}`).DataTable().column([2]).search('').draw();
                                }
                                if (column.search() !== moment(this.value, "HH:mm").format("hh:mm A")) {
                                    column.search(moment(this.value, "HH:mm").format("hh:mm A")).draw();
                                }
                            });
                        });
                    this.api()
                        .columns([10])//Status filter
                        .every(function () {
                            var column = this;
                            var select = $('<br/><select class="form-control" placeholder="search" style="width:100%;margin-top:5px;background-color:white""><option value="">All</option></select>')
                                .appendTo($(column.header()))
                                .on('change', function () {
                                    var val = $.fn.dataTable.util.escapeRegex($(this).val());
                                    if (val === '1') {
                                        column.search('^1$', true, false).column(4).search('^((?!No Assignment).)*$', true, false).draw();
                                    } else if (val === '0') {
                                        column.search('^1$', true, false).column(4).search('No Assignment', true, false).draw();
                                    } else {
                                        column.search(val ? '^' + val + '$' : '', true, false).draw();
                                    }

                                });
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            [{ "name": "Unfilled", "value": 0 }, { "name": "Pending Approval", "value": 1 }, { "name": "Confirmed", "value": 2 }, { "name": "Rejected", "value": 3 }, { "name": "Extention", "value": 4 }, { "name": "Cancelled by caregiver", "value": 5 }, { "name": "Cancelled by facility", "value": 6 }, { "name": "Closed", "value": 7 }].forEach(function (option) {
                                var selected = '';
                                select.append('<option value="' + option.value + '" ' + selected + '>' + option?.name + '</option>');
                            });
                        });
                    this.api().columns([6]).every(function () {
                        $(select).click(function (e) {
                            e.stopPropagation();
                        });
                        var column = this;

                        // Create the dropdown with "All" as the default option
                        var select = $('<br/><select class="form-control" placeholder="filter" style="width:100%;margin-top:5px;background-color:white"><option value="" selected>All</option></select>')
                            .appendTo($(column.header()));

                        // Keep track of unique values
                        var uniqueValues = {};

                        // Populate the dropdown with unique values from column 5
                        column.data().each(function (d, j) {
                            // Get the HTML content of the cell
                            var cellText = column.cell(j, 6).node().innerHTML;
                            // Check if the value is unique before adding it to the dropdown
                            if (!uniqueValues[cellText]) {
                                uniqueValues[cellText] = true;
                                select.append('<option value="' + cellText + '">' + cellText + '</option>');
                            }
                        });

                        // Add change event listener to the dropdown
                        select.on('change', function () {
                            var val = $.fn.dataTable.util.escapeRegex(
                                $(this).val()
                            );

                            // Search and filter the table
                            column.search(val ? '^' + val + '$' : '', true, false).draw();
                        });

                    });
                    this.api().columns([4]).every(function () {
                        $(select).click(function (e) {
                            e.stopPropagation();
                        });
                        var column = this;

                        // Create the dropdown with "All" as the default option
                        var select = $('<br/><select class="form-control" placeholder="filter" style="width:100%;margin-top:5px;background-color:white"><option value="" selected>All</option></select>')
                            .appendTo($(column.header()));

                        // Keep track of unique values
                        var uniqueValues = {};

                        // Populate the dropdown with unique values from column 5
                        column.data().each(function (d, j) {
                            // Get the HTML content of the cell
                            var cellText = column.cell(j, 4).node().innerHTML;
                            // Check if the value is unique before adding it to the dropdown
                            if (!uniqueValues[cellText]) {
                                uniqueValues[cellText] = true;
                                select.append('<option value="' + cellText + '">' + cellText + '</option>');
                            }
                        });

                        // Add change event listener to the dropdown
                        select.on('change', function () {
                            var val = $.fn.dataTable.util.escapeRegex(
                                $(this).val()
                            );

                            // Search and filter the table
                            column.search(val ? '^' + val + '$' : '', true, false).draw();
                        });

                    });
                },

            }
        )
        tableSlot.on('click', 'td', function () {
            // Get the column index of the clicked cell
            const columnIndex = tableSlot.cell(this).index()?.column;
            // Check if the clicked cell belongs to the desired column
            if (columnIndex === 1 || columnIndex === 2 || columnIndex === 4) {
                // Get the data for the clicked cell
                const rowData = tableSlot.row($(this).closest('tr')).data();
                // Handle the event for the clicked cell
                if (rowData) {
                    showDetails(rowData?.shift_data)
                }
            }
        });

        // Extra step to do extra clean-up.
        return function () {
            tableSlot.destroy()
        }
    }, [staffingRequestList, degree])

    const onDismiss = () => {
        setIsOpen(false)
        if (isTouched) {
            swal({
                title: "Are you sure?",
                text: "You have some modifications to your shift that you want to really cancel.",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        setIsTouched(false)
                        setRequestData([])
                        setEditSlot([])
                        setRemoveSlot([])
                        setEditMode(false)
                        setCancelMode(false)
                    } else {
                        setIsOpen(true)
                    }
                });
        } else {
            setIsOpen(false)

        }
    }

    const showDetails = (item) => {
        setIsTouched(false)
        setRequestData(item)
        setIsOpen(true)
    }

    const assignedCaregivers = (slot) => {
        if (slot) {
            const times = [slot?.slot_start_time, slot?.slot_end_time];
            let formattedTime = [];
            times.forEach((time) => {
                formattedTime.push(moment(time, 'HH:mm').format('hh:mm A'));
            });
            setAssignTime(formattedTime.join(" TO "))
            setAssignDate(slot?.slot_date)
            setCaregiverList(slot)
            setIsOpen(false);
            setOpenModal(true)
        } else {
            toast.warn("No assign caregiver")
        }
    }

    const onDismissModal = () => {
        setAssignTime()
        setAllDates()
        setDates()
        setOpenModal(false)
        setIsOpen(true);
    }
    const onDismissForm = () => {
        setAssignTime()
        setAllDates()
        setDates()
        setIsOpenForm(false)
    }

    const handleChange = (event) => {
        setFeedback(event.target.value);
    };
    const onPick = (value) => {
        setRating(value);
    }

    const onFeedback = async (unRatedShift) => {
        setIsOpen(false)
        swal({
            title: "Feedback/Rating",
            buttons: [true, "Submit"],
            content: (
                <div className='row' style={{ textAlign: 'start' }}>
                    <div className="">
                        <label className='mb-2'>Please rate your experience for your most recent shift #<strong className='text-danger'>{requestData?.id || unRatedShift?.id}</strong>.</label>
                        <br />
                        <div className="wrapper">
                            <input type="radio" onClick={() => onPick(1)} value="1" name="rate" id="star-1" style={{ display: "none" }} />
                            <input type="radio" onClick={() => onPick(2)} value="2" name="rate" id="star-2" style={{ display: "none" }} />
                            <input type="radio" onClick={() => onPick(3)} value="3" name="rate" id="star-3" style={{ display: "none" }} />
                            <input type="radio" onClick={() => onPick(4)} value="4" name="rate" id="star-4" style={{ display: "none" }} />
                            <input type="radio" onClick={() => onPick(5)} value="5" name="rate" id="star-5" style={{ display: "none" }} />
                            <div className="content">
                                <div className="outer">
                                    <div className="emojis">
                                        <li className="slideImg"><img src={Images?.think} style={{
                                            width: "90%",
                                            height: "100%",
                                            paddingTop: 10
                                        }} alt="" /></li>
                                        <li ><img src={Images?.hate} alt="" /></li>
                                        <li><img src={Images?.unlike} alt="" /></li>
                                        <li><img src={Images?.like} alt="" /></li>
                                        <li><img src={Images?.awesome} alt="" /></li>
                                        <li><img src={Images?.love} alt="" /></li>
                                    </div>
                                </div>
                                <div className="stars">
                                    <label htmlFor="star-1" className="star-1 fas fa-star"></label>
                                    <label htmlFor="star-2" className="star-2 fas fa-star"></label>
                                    <label htmlFor="star-3" className="star-3 fas fa-star"></label>
                                    <label htmlFor="star-4" className="star-4 fas fa-star"></label>
                                    <label htmlFor="star-5" className="star-5 fas fa-star"></label>
                                </div>
                            </div>
                            <div className="footer">
                                <span className="text"></span>
                                {/* <span className="numb"></span> */}
                            </div>
                        </div>
                    </div>
                    <div className='mt-5'>
                        <label className='mb-3'>Share any additional comments about the services you received below.</label>
                        <div className="col-12">
                            <div className="form-floating">
                                <textarea className="form-control"
                                    placeholder="Write something"
                                    name={'feedback'}
                                    onChange={handleChange}
                                    style={{ height: 100 }}></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
            .then((value) => {
                if (value) {
                    setSubmitted(value)
                } else {
                    setIsOpen(false)
                    setRating()
                    setFeedback()
                }
            });
    }

    const onOpenForm = (item) => {
        setFormData(item)
        setIsOpenForm(true)
    }
    const onOpenFormForTC = (item) => {
        setTCFormData(item)
        setIsOpenFormTC(true)
    }
    //FOR SLOT
    const onExtendStatus = (values) => {
        let finalslot = values.tenure.map((x, index) => {
            const copiedObjects = Array.from({ length: values?.allqty ? values?.allqty[index] || '' : 1 }, () => Object.assign({}, { "date": x.format(), "time": values.alltime[index] ? values.alltime[index] : values.alltime[0], "remark": values?.allcomment ? values?.allcomment[index] || '' : '', "slot_type": values?.alltype ? values?.alltype[index] || '' : '' }));
            return copiedObjects
        })
        finalslot?.forEach((obj, index) => {
            obj.ind = index + 1;
        });
        if (formData?.assignments?.length !== 0) {
            const conflictingEntries = checkConflicts(finalslot, formData?.assignments);
            if (conflictingEntries?.length !== 0) {
                setDisabledSlots(conflictingEntries)
                return
            }
        }
        finalslot = [].concat(...finalslot);
        let check = finalslot.some((obj) => !Object.values(obj).every((val) => val !== undefined));
        if (check) {
            toast.warn("Please select date & time!")
        } else {
            let param = {
                request_id: values.request_id,
                requested_slots: JSON.stringify(finalslot)
            }

            UserStore.extendShift(param, navigationCallBackExtend)
        }
    }

    const navigationCallBackExtend = () => {
        swal("Success!", "Your shift request was successfully submitted. Please wait for approval. after it has been granted, you will receive a message from us. Thank you for utilizing our service.", "success");
        UserStore.getStaffingRequest();
        setFormData();
        setIsOpenForm(false)
        setDisabledSlots();
        onDismiss()
    }

    //FOR TC
    const onExtendTCStatus = (values) => {
        UserStore.extendContract(values, navigationCallBackExtendTC)
    }
    const navigationCallBackExtendTC = () => {
        swal("Success!", "Your extension of contract request was successfully submitted. Please wait for approval. after it has been granted, you will receive a message from us. Thank you for utilizing our service.", "success");
        UserStore.getStaffingRequest();
        setIsOpenFormTC(false)
    }

    const onSlotApprovalOpen = (slots) => {
        if (slots.category === 'TC') {
            setReqSlotList([slots])
        } else {
            setReqSlotList(slots?.assignments?.filter(obj => obj.status === 1 && obj.staff_id))
        }
        setIsOpenReqSlot(true)
    }

    const onSlotApprovalDismiss = () => {
        setReqSlotList([])
        setIsOpenReqSlot(false)
    }

    const onApproveSlot = () => {
        if (selectedIds?.length === 0) {
            toast.warn("Please choose at least one shift!")
            return
        }
        if (reqSlotList[0]?.category === 'TC') {
            const param = {
                'request_id': reqSlotList[0]?.id,
                'status_id': selectedIds[0]?.code === 1 ? 3 : 5
            }
            // console.log(param, "contract payload"); return
            UserStore.onApproveContract(param, navigationCallBackApprove)
        } else {
            const param = {
                'request_id': reqSlotList[0]?.shift_id,
                'slots': JSON.stringify(selectedIds)
            }
            // console.log(param, "slot payload"); return
            UserStore.onApproveSlots(param, navigationCallBackApprove)
        }
    }
    const navigationCallBackApprove = () => {
        onSlotApprovalDismiss();
        UserStore.getStaffingRequest();
        swal("Success!", "Shift Approval/Reject has been submitted successfully.", "success");
        navigate('/shift-request-list');
    }

    function checkConflicts(array1, array2) {
        let conflicts = [];
        if (array2) {
            array1?.find(entry1 => {
                if (array2.find(entry2 => {
                    let entry1StartTime = parse(`${entry1[0].date} ${entry1[0].time[0]}`, 'MM/dd/yyyy HH:mm', new Date()).getTime();
                    let entry1EndTime = parse(`${entry1[0].date} ${entry1[0].time[1]}`, 'MM/dd/yyyy HH:mm', new Date()).getTime();
                    let entry2StartTime = parse(`${entry2.slot_date} ${entry2.slot_start_time}`, 'MM/dd/yyyy HH:mm', new Date()).getTime();
                    let entry2EndTime = parse(`${entry2.slot_date} ${entry2.slot_end_time}`, 'MM/dd/yyyy HH:mm', new Date()).getTime();
                    if (isBefore(entry1EndTime, entry1StartTime)) {
                        // Add one day to the end1 time
                        entry1StartTime = addDays(entry1StartTime, 1);
                    }
                    return (isBefore(entry1StartTime, entry2EndTime) && isAfter(entry1EndTime, entry2StartTime));
                }
                )) {
                    conflicts.push(entry1?.ind)
                }
            })
            return conflicts
        } else {
            return true;
        }
    }

    const handleSelectAllRows = (e) => {//2 for approve 1 for reject
        selectedIds = []
        if (e.target.checked) {
            if (e.target.value === 'Approve') {
                const checkboxes = document.querySelectorAll(`#approval #approve input[type='radio']`);
                for (const checkbox of checkboxes) {
                    checkbox.checked = e.target.checked;
                }
                selectedIds = reqSlotList?.map(x => { return { "id": x.id, "code": 2 } })
                return
            }
            if (e.target.value === 'Reject') {
                const checkboxes = document.querySelectorAll(`#approval #reject input[type='radio']`);
                for (const checkbox of checkboxes) {
                    checkbox.checked = e.target.checked;
                }
                selectedIds = reqSlotList?.map(x => { return { "id": x.id, "code": 1 } })
                return
            }
            if (e.target.value === 'Reject-Close') {
                const checkboxes = document.querySelectorAll(`#approval #reject-close input[type='radio']`);
                for (const checkbox of checkboxes) {
                    checkbox.checked = e.target.checked;
                }
                selectedIds = reqSlotList?.map(x => { return { "id": x.id, "code": 3 } })
                return
            }
        }
    };

    const slotAdd = (type, slot_id) => {
        const index = selectedIds.findIndex(item => item.id === slot_id);
        index !== -1 ? selectedIds[index] = { "id": slot_id, "code": type === 'Approve' ? 2 : type === 'Reject-Close' ? 3 : 1 } : selectedIds.push({ "id": slot_id, "code": type === 'Approve' ? 2 : type === 'Reject-Close' ? 3 : 1 });
        return
    }

    //EDIT/Delete SLOTS
    const slotChanges = (id) => {
        setIsOpen(false)
        if (cancelMode) {
            swal({
                title: "Do you really want to delete this shift(s)?",
                text: "After a delete, You can't roll back!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        setIsTouched(false)
                        let param = {
                            slot_id_delete: removeSlot,
                        }
                        UserStore.deleteSlot(param, navigationCallBackSlotChange)
                    } else {
                        setIsOpen(true);
                    }
                });
        }
        if (editMode) {
            swal({
                title: "Do you really want to update this shift(s)?",
                text: "After an update, You can't roll back an update.",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        setIsTouched(false)
                        let param = {
                            request_id: id,
                            slots: JSON.stringify(editSlot)
                        }
                        UserStore.updateSlotData(param, navigationCallBackSlotChange)
                    } else {
                        setIsOpen(true);
                    }
                });
        }
    }

    const removeSlots = (id, type) => {
        setIsTouched(true)
        // Check if the element already exists in the array
        if (type === "remove") {
            const elementExists = removeSlot.includes(id);
            // Remove the existing element if it exists, and add the new element to the array
            const updatedArray = elementExists
                ? removeSlot.filter(item => item !== id)
                : removeSlot.concat(id);
            // Update the state variable with the updated array
            setRemoveSlot(updatedArray);
        }
        if (type === "undo-remove") {
            if (removeSlot.includes(id)) {
                let updatedArray = removeSlot.filter(item => item !== id)
                setRemoveSlot(updatedArray);
            } else {
                let updatedArray = removeSlot.filter(item => item !== id)
                setRemoveSlot(updatedArray);

            }
        }
    }

    const updateSlots = (slot, start, end, type) => {
        setIsTouched(true)
        let newSlot = { "id": slot?.id, "staff_id": slot?.staff_id, "slot_status": slot?.status, "date": slot?.slot_date, "time": [start === null ? editSlot?.find(s => s.id === slot.id)?.time[0] || slot.slot_start_time : start, end === null ? editSlot?.find(s => s.id === slot.id)?.time[1] || slot.slot_end_time : end], "slot_type": type === null ? editSlot?.find(s => s.id === slot.id)?.slot_type || slot?.slot_type : type };
        // Check if a slot with the same id and time already exists in editSlot
        const duplicateSlotIndex = editSlot?.findIndex(s => s.id === newSlot.id);
        if (duplicateSlotIndex !== -1) {
            // A duplicate slot already exists, replace it with the new slot
            const updatedEditSlot = [...editSlot];
            updatedEditSlot[duplicateSlotIndex] = newSlot;
            setEditSlot(updatedEditSlot);
            return;
        } else {
            setEditSlot([...editSlot, newSlot]);
            return;
        }
    }
    const navigationCallBackSlotChange = () => {
        setIsTouched(false)
        setRequestData([])
        setIsOpen(false)
        setEditSlot([])
        setRemoveSlot([])
        setEditMode(false)
        setCancelMode(false)
        setIsOpenFormTC(false)
        setTCFormData();
        setIsOpenFormEditTC(false)
        UserStore.getStaffingRequest();
    }

    //Contract delete
    const onDeleteContract = (item) => {
        swal({
            title: "Do you really want to delete this contract?",
            text: "After an deletion, You can't roll back an delete.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    let param = {
                        "request_id": item.id,
                        "status_id": 6
                    }
                    UserStore.onApproveContract(param, navigationCallBackSlotChange)
                }
            });
    }

    //Contract Edit
    const editTCShift = (data) => {
        setIsOpen(false)
        setIsOpenFormTC(false)
        setTCFormData(data);
        setIsOpenFormEditTC(true)
    }
    const updateContractData = (values) => {
        UserStore.updateContractData(values, navigationCallBackSlotChange)
    }
    const openCSVUpload = () => {
        setIsOpenCSVUpload(true)
    }
    const uploadShiftCSV = (values) => {
        values.specialty_name = specialties?.find(x => x.Id == values.specialty)?.Name
        UserStore.uploadShiftCSV(values, navigationCallBackCSVUpload)
    }
    const navigationCallBackCSVUpload = () => {
        UserStore.getStaffingRequest();
        setIsOpenCSVUpload(false)
        if (formikRef.current) {
            formikRef.current.resetForm();
        }
    }
    function ClearButton({ setFiled }) {
        return <div>
            <button
                className="btn btn-sm btn-primary"
                type="button"
                onClick={() => {
                    setDates([])
                    setAllDates([])
                    setFiled('tenure', [])
                }}
            >
                Clear
            </button>
        </div>;
    }
    return (
        <React.Fragment>
            <Header />
            <Helmet>
                <title>Orders</title>
            </Helmet>
            <>
                <div className="container-fluid callback mt-5 py-5">
                    <div className="container mt-5 mt-lg-0 pt-lg-5">
                        <h5 className="display-5 mb-4 animated slideInDown login-head">
                            Orders</h5>
                    </div>
                    <div className="col-lg-12">
                        <div className="row bg-white pt-3">
                            <div className="col-lg-12">
                                <div className='d-flex justify-content-center'>

                                    <a onClick={() => HomeStore.loading = true} href="/shift-request">
                                        <h1 className="page-title signup-link fs-4" >
                                            <span className="page-title-icon linear text-white me-2">
                                                <i className="fas fa-plus"></i>
                                            </span>Add Order
                                        </h1>
                                    </a>
                                    <div onClick={openCSVUpload}>
                                        <h1 className="page-title signup-link fs-4 ms-3" >
                                            <span className="page-title-icon linear text-white me-2">
                                                <i className="fas fa-upload"></i>
                                            </span>Upload Order
                                        </h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <p className='pt-3 pt-lg-2 pt-md-2'><strong>Please Note: </strong><span className='text-danger'><i>To view the list of shifts click on the shift tab and to view traveler contracts click on the contracts tab.</i></span></p>
                                <div className="row bg-white">
                                    <div className=''>
                                        <ul className="nav nav-tabs d-flex justify-content-center">
                                            <li className="nav-item">
                                                <button
                                                    className={`nav-link ${activeTab === 'tab1' ? 'active text-primary border-3 border-primary' : 'text-muted border border-muted'}`}
                                                    onClick={() => handleTabChange('tab1')}
                                                >
                                                    <strong><i className="fas fa-list"></i> Shifts</strong>
                                                </button>
                                            </li>
                                            <li className="nav-item">
                                                <button
                                                    className={`nav-link ${activeTab === 'tab2' ? 'active text-primary border-3 border-primary' : 'text-muted border border-muted'}`}
                                                    onClick={() => handleTabChange('tab2')}
                                                >
                                                    <strong><i className="fas fa-file-signature"></i> Contracts</strong>
                                                </button>
                                            </li>
                                        </ul>
                                        <div className={`tab-list-content ${activeTab === 'tab1' ? 'active' : ''}`}>
                                            <table className="table dt-responsive table-hover nowrap" style={{ width: "100%" }} id={tableNameSlot} ref={tableRefSlot}></table>
                                        </div>
                                        <div className={`tab-list-content ${activeTab === 'tab2' ? 'active' : ''}`}>
                                            <div className='table-responsive'>
                                                <table className="table dt-responsive table-hover nowrap" style={{ width: "100%" }} id={tableName} ref={tableRef}></table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <BottomSheet open={isOpen} onDismiss={onDismiss}
                                header={
                                    <>
                                        <div className="text-end" style={{ cursor: "pointer" }} onClick={onDismiss}>
                                            <i className="fa fa-times" aria-hidden="true"></i>
                                        </div>
                                        <h4 className="flex items-center text-xl justify-center font-bold text-primary-800">
                                            ORDER REQUEST #{requestData?.id}
                                        </h4>
                                    </>
                                }

                                footer={
                                    <div>
                                        <div className="text-end">{
                                            (!requestData?.rating && requestData?.status) === 3 &&
                                            <button onClick={() => onFeedback(requestData)} className="btn btn-primary">
                                                Give Feedback/Rating
                                            </button>}
                                        </div>
                                        {((editSlot && editSlot?.length !== 0) || (removeSlot && removeSlot?.length !== 0)) && isTouched &&
                                            <div className="text-end">
                                                <CButton onClick={() => {
                                                    slotChanges(requestData?.id)
                                                }} className="w-15" color="primary" >
                                                    {editMode ? "Update Shifts" : "Delete Shifts"}
                                                </CButton>
                                            </div>
                                        }
                                    </div>
                                }
                            >
                                <div style={{ height: global.config.windowHeight / 1.5, overflowY: 'scroll' }}>
                                    <div className="col-xl-12 col-md-12">
                                        <div className="user-card-full">
                                            <div className="row m-l-0 m-r-0">
                                                <div className="col-sm-12">
                                                    {(requestData?.category === 'TC' && requestData.staff_id) &&
                                                        <div className="card-block mb-0 pb-0">
                                                            <h6 className="m-b-20 p-b-5 b-b-default f-w-600">Assigned Caregiver Details <i className="far fa-address-card"></i></h6>
                                                            <div className="row">
                                                                <div className="col-sm-2">
                                                                    <p className="m-b-10 f-w-600">Caregiver Bluesky Id</p>
                                                                    <h6 className="text-success f-w-400">{requestData?.staff?.bluesky_staff_id}</h6>
                                                                </div>
                                                                <div className="col-sm-2">
                                                                    <p className="m-b-10 f-w-600">Caregiver Name</p>
                                                                    <h6 className="text-success text-capitalize f-w-400">{requestData?.staff?.title} {requestData?.staff?.first_name} {requestData?.staff?.last_name}</h6>
                                                                </div>
                                                                <div className="col-sm-2">
                                                                    <p className="m-b-10 f-w-600">Contact</p>
                                                                    <h6 className="text-muted f-w-400">{requestData?.staff?.country_code} {requestData?.staff?.contact}</h6>
                                                                </div>
                                                                <div className="col-sm-2">
                                                                    <p className="m-b-10 f-w-600">Email</p>
                                                                    <h6 className="text-muted f-w-400">{requestData?.staff?.email}</h6>
                                                                </div>

                                                            </div>
                                                        </div>}
                                                    <div className="card-block">
                                                        <h6 className="m-b-20 p-b-5 b-b-default f-w-600">Shift Request Details <i className="far fa-calendar-alt"></i></h6>
                                                        <div className="row">
                                                            <div className="col-sm-3">
                                                                <p className="m-b-10 f-w-600">Profession - Specialty</p>
                                                                <h6 className="text-muted f-w-400">{requestData?.specialty_name ? requestData?.specialty_name : HomeStore?.data?.degreeList?.find(pro => pro.Id == requestData?.profession)?.Name}</h6>
                                                            </div>
                                                            <div className="col-sm-2">
                                                                <p className="m-b-10 f-w-600">City - State</p>
                                                                <h6 className="text-muted f-w-400">{requestData?.city} - {requestData?.state}</h6>
                                                            </div>
                                                            <div className="col-sm-2">
                                                                <p className="m-b-10 f-w-600">Category</p>
                                                                <h6 className="text-muted f-w-400">{global.config.shiftCaregory?.find(x => x.value === requestData?.category)?.label}</h6>
                                                            </div>
                                                            {requestData?.shift_type && <div className="col-sm-2">
                                                                <p className="m-b-10 f-w-600">Shift Type</p>
                                                                <h6 className="text-muted f-w-400">{requestData?.shift_type}</h6>
                                                            </div>}
                                                            {requestData?.pay_details && <div className="col-sm-2">
                                                                <p className="m-b-10 f-w-600">Pay Detail</p>
                                                                <h6 className="text-muted f-w-400">{requestData?.pay_details}</h6>
                                                            </div>}
                                                            {requestData?.start_date && <div className="col-sm-3">
                                                                <p className="m-b-10 f-w-600">Start Date To End Date</p>
                                                                <h6 className="f-w-600 text-success"><i className="fa fa-calendar-alt" aria-hidden="true"></i> {requestData?.start_date} To <i className="fa fa-calendar-alt" aria-hidden="true"></i> {requestData?.end_date}</h6>
                                                            </div>}
                                                            {requestData?.start_time && <div className="col-sm-2">
                                                                <p className="m-b-10 f-w-600">Start Time To End Time</p>
                                                                <h6 className="f-w-600 text-success"><i className="far fa-clock"></i> {moment(requestData?.start_time, "HH:mm").format("hh:mm A")} To <i className="far fa-clock"></i> {moment(requestData?.end_time, "HH:mm").format("hh:mm A")}</h6>
                                                            </div>}
                                                            {requestData?.hours_per_week && <div className="col-sm-2">
                                                                <p className="m-b-10 f-w-600">Guaranteed Hours Per Week</p>
                                                                <h6 className="text-muted f-w-400">{requestData?.hours_per_week} hr/week</h6>
                                                            </div>}
                                                            {requestData?.no_of_weeks && <div className="col-sm-2">
                                                                <p className="m-b-10 f-w-600">Numbers Of Weeks</p>
                                                                <h6 className="text-muted f-w-400">{requestData?.no_of_weeks}</h6>
                                                            </div>}
                                                            {requestData?.nop && <div className="col-sm-2">
                                                                <p className="m-b-10 f-w-600">Numbers Of Openings</p>
                                                                <h6 className="text-muted f-w-400">{requestData?.nop}</h6>
                                                            </div>}
                                                            {requestData?.qualifications && <div className="col-sm-3">
                                                                <p className="m-b-10 f-w-600">Qualifications</p>
                                                                <h6 className="text-muted f-w-400">{requestData?.qualifications}</h6>
                                                            </div>}
                                                            <div className="col-sm-2">
                                                                <p className="m-b-10 f-w-600">Extension</p>
                                                                <h6 className="text-muted f-w-400">{requestData?.extentions}</h6>
                                                            </div>
                                                            {<div className="col-sm-2">
                                                                <p className="m-b-10 f-w-600">Shift Status</p>
                                                                <h6 className="text-muted f-w-400">
                                                                    {
                                                                        requestData?.status == 0 ? <span className={'text-warning'}>Pending</span> :
                                                                            (requestData?.status == 1 && requestData?.staff_response == 0 && requestData?.staff_id) ? <span className={'text-warning'}><strong>Pending By Caregiver</strong></span> : requestData?.extention_request
                                                                                ? <span className={'text-info'}><b>Requested</b></span>
                                                                                : (requestData?.assignments?.filter(obj => obj.status === 1 && obj.staff_id)?.length > 0)
                                                                                    || (requestData?.category === 'TC' && requestData?.status === 1 && requestData?.staff_id && requestData?.staff_response === 1) ? <span className="text-info">Waiting For Approval!</span> : (requestData?.category === 'TC' && requestData?.status === 1 && requestData?.staff_response === 0) ? <span className={'text-warning'}>Pending</span> :
                                                                                    requestData?.status == 2 ? <span className={'text-danger'}>Rejected</span>
                                                                                        : requestData?.status == 3 ? <span className={'text-dark'}>Closed</span>
                                                                                            : (requestData?.assignments?.length === 0 && requestData?.category !== 'TC') ? <span className={'text-dark'}>No Shift Available</span>
                                                                                                : requestData?.assignments?.some(item => item.status === 4 && item?.staff_id) ? <span className={'text-warning'}>Extention Pending</span> : (requestData?.assignments?.length > 0 && requestData?.assignments?.every(item => item.status === 2 && item?.staff_id))
                                                                                                    || (requestData?.category === 'TC' && requestData?.status === 5 && requestData?.staff_id && requestData?.staff_response === 1) ? <span className={'text-success'}><b>Filled</b></span>
                                                                                                    : (requestData?.assignments?.some(item => item.status === 2 && item?.staff_id))
                                                                                                        ? <span className={'text-warning'}><b>Partially Filled</b></span>
                                                                                                        : requestData?.status == 1 ? <span className={'text-danger'}>Unfilled</span>
                                                                                                            : requestData?.status == 4 ? <span className={'text-success'}>Extended</span> : ""}
                                                                </h6>
                                                            </div>}
                                                            {requestData?.rating && <div className="col-sm-2">
                                                                <p className="f-w-600 mb-0">Rating</p>
                                                                <h6 className="text-muted f-w-400">
                                                                    <div className="rate-view">
                                                                        <input type="radio" id="star5" checked={requestData?.rating == 5} readOnly name="rate" value="5" />
                                                                        <label htmlFor="star5" title="Five">5 stars</label>
                                                                        <input type="radio" id="star4" checked={requestData?.rating == 4} readOnly name="rate" value="4" />
                                                                        <label htmlFor="star4" title="Four">4 stars</label>
                                                                        <input type="radio" id="star3" checked={requestData?.rating == 3} readOnly name="rate" value="3" />
                                                                        <label htmlFor="star3" title="Three">3 stars</label>
                                                                        <input type="radio" id="star2" checked={requestData?.rating == 2} readOnly name="rate" value="2" />
                                                                        <label htmlFor="star2" title="Two">2 stars</label>
                                                                        <input type="radio" id="star1" checked={requestData?.rating == 1} readOnly name="rate" value="1" />
                                                                        <label htmlFor="star1" title="One">1 star</label>
                                                                    </div>
                                                                </h6>
                                                            </div>
                                                            }
                                                            {requestData?.feedback && <div className="col-sm-2">
                                                                <p className="m-b-10 f-w-600">Feedback</p>
                                                                <h6 className="text-muted f-w-400">{requestData?.feedback}</h6>
                                                            </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    {requestData?.assignments?.length !== 0 &&
                                                        <div className="card-block">
                                                            {(requestData?.status !== 2 && requestData?.status !== 3) ?
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <h6 className="m-b-20 p-b-5 b-b-default f-w-600">Shift Details &nbsp;&nbsp;
                                                                            <CButton size="sm" active={editMode} onClick={() => { setEditMode(true); setCancelMode(false); setRemoveSlot([]) }} type="button" color="primary" variant="outline">
                                                                                <i className="fa fa-edit" aria-hidden="true"></i> Edit Mode
                                                                            </CButton>
                                                                            &nbsp;&nbsp;
                                                                            <CButton size="sm" className="mt-lg-0 mt-md-0" active={cancelMode} onClick={() => { setCancelMode(true); setEditMode(false); setEditSlot([]) }} type="button" color="primary" variant="outline">
                                                                                <i className="fa fa-times" aria-hidden="true"></i> Delete Mode
                                                                            </CButton>
                                                                            &nbsp; &nbsp;
                                                                            <span className="d-inline-block mt-2">
                                                                                {cancelMode ? " Here, you can remove shift(s)." : editMode ? "  Here, you can edit shift timing." : ""}
                                                                            </span>
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                                : <h6 className="m-b-20 p-b-5 b-b-default f-w-600">Order Details</h6>}
                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                    <ul className="list-unstyled">
                                                                        <div className="row">
                                                                            {requestData?.assignments?.filter(slt => !slt.cancelled_date)?.map((slot, index) =>
                                                                                <div className="col-sm-12 col-md-6 col-lg-4" key={index + 'slots'}>
                                                                                    <li className='card p-3 mb-2'>
                                                                                        <div>
                                                                                            <div className='col-6 col-lg-3 mb-1'>
                                                                                                <SelectField
                                                                                                    id={"slot-type"}
                                                                                                    label={"Shift Type"}
                                                                                                    name={"slot_type"}
                                                                                                    disabled={!editMode || (slot?.status === 5 || slot?.status === 3)}
                                                                                                    onChange={(event) => {
                                                                                                        let start = global.config.shiftTimeRange?.find(x => x.label === event.target.value)?.value?.start;
                                                                                                        let end = global.config.shiftTimeRange?.find(x => x.label === event.target.value)?.value?.end;
                                                                                                        var inputStart = document.getElementById("start" + slot?.id);
                                                                                                        var inputEnd = document.getElementById("end" + slot?.id);
                                                                                                        // Change the value of the input element
                                                                                                        inputStart.value = start;
                                                                                                        inputEnd.value = end;
                                                                                                        updateSlots(slot, start, end, event.target.value)
                                                                                                    }}
                                                                                                    defaultValue={slot.slot_type}
                                                                                                    data={global.config.shiftType}
                                                                                                    optionLabel={"name"}
                                                                                                    optionValue={"value"}
                                                                                                />
                                                                                            </div>
                                                                                            <span className={removeSlot?.includes(slot?.id) || (slot?.status === 5 || slot?.status === 6 || slot?.status === 3) ? "deleted" : ""}>
                                                                                                <span style={{ position: "relative" }}>
                                                                                                    <input className={slot?.status === 2 ? "slot green-border" : slot?.status === 4 ? "slot warning-border" : "slot"} readOnly value={slot?.slot_date} />
                                                                                                    {slot?.is_extended === 1 && <span className='suffix-container'><span className="suffix-lable ">Extended</span></span>}
                                                                                                    {slot?.status === 3 && <span className='suffix-container'><span className="suffix-lable ">Rejected</span></span>}
                                                                                                </span>
                                                                                                <span style={{ position: "relative" }}>
                                                                                                    <input required type="time" disabled={!editMode || (slot?.status === 5 || slot?.status === 3)}
                                                                                                        id={"start" + slot.id} className={slot?.status === 2 ? "timer-filled-admin text-success border-right-0" : slot?.status === 4 ? "timer-admin warning-border" : "timer-admin"} name="start"
                                                                                                        defaultValue={slot?.slot_start_time} onChange={(e) => {
                                                                                                            updateSlots(slot, e.target.value, null, null)
                                                                                                        }} />

                                                                                                    <span className={slot?.status === 2 ? "border-right-0 border-left-0 timer-filled-admin text-success slight-padding " : slot?.status === 4 ? "timer-admin warning-border slight-padding" : "timer-admin slight-padding"}>To</span>

                                                                                                    <input required type="time" disabled={!editMode || (slot?.status === 5 || slot?.status === 3)}
                                                                                                        className={slot?.status === 2 ? "timer-filled text-success" : slot?.status === 4 ? "timer-admin-end warning-border" : "timer"}
                                                                                                        id={"end" + slot.id} name="end" defaultValue={slot?.slot_end_time} onChange={(e) => updateSlots(slot, null, e.target.value, null)} />
                                                                                                    {slot?.status === 5 ? <span className='suffix-container-cancelled'><span className="suffix-lable ">Cancelled by caregiver</span></span> : slot?.status === 6 ? <span className='suffix-container'><span className="suffix-lable ">Cancelled by you</span></span> : ""}
                                                                                                </span>
                                                                                            </span>
                                                                                            {slot?.staff && slot?.status === 2 &&
                                                                                                <CTooltip
                                                                                                    content={
                                                                                                        <ul>
                                                                                                            <CTable responsive>
                                                                                                                <CTableHead>
                                                                                                                    <CTableRow>
                                                                                                                        <CTableHeaderCell scope="col" style={{ color: "white" }}>#</CTableHeaderCell>
                                                                                                                        <CTableHeaderCell scope="col " style={{ color: "white" }}>Bluesky ID</CTableHeaderCell>
                                                                                                                        <CTableHeaderCell scope="col " style={{ color: "white" }}>Caregiver</CTableHeaderCell>
                                                                                                                        <CTableHeaderCell scope="col " style={{ color: "white" }}>Contact</CTableHeaderCell>
                                                                                                                        <CTableHeaderCell scope="col " style={{ color: "white" }}>Email</CTableHeaderCell>
                                                                                                                        <CTableHeaderCell scope="col " style={{ color: "white" }}>Req. Time</CTableHeaderCell>
                                                                                                                    </CTableRow>
                                                                                                                </CTableHead>
                                                                                                                <CTableBody>
                                                                                                                    <CTableRow>
                                                                                                                        <CTableHeaderCell style={{ color: "white" }} scope="row">1</CTableHeaderCell>
                                                                                                                        <CTableDataCell style={{ color: "white" }}><span className="badge btn btn-primary" >  {slot?.staff?.bluesky_staff_id}</span></CTableDataCell>
                                                                                                                        <CTableDataCell style={{ color: "white" }}>{slot?.staff?.first_name} {slot?.staff?.last_name}</CTableDataCell>
                                                                                                                        <CTableDataCell style={{ color: "white" }}>{slot?.staff?.contact}</CTableDataCell>
                                                                                                                        <CTableDataCell style={{ color: "white" }}>{slot?.staff?.email}</CTableDataCell>
                                                                                                                        <CTableDataCell style={{ color: "white" }}>{moment(slot?.updated_at).fromNow()}</CTableDataCell>
                                                                                                                    </CTableRow>
                                                                                                                </CTableBody>
                                                                                                            </CTable>
                                                                                                        </ul>}
                                                                                                    placement="top">
                                                                                                    <i
                                                                                                        onClick={() => assignedCaregivers(slot)} className="far fa-list-alt text-info ms-2 fs-5 align-middle" aria-hidden="true">
                                                                                                    </i>
                                                                                                </CTooltip>
                                                                                            }
                                                                                            {requestData?.status !== 3 && cancelMode && !slot?.staff && (removeSlot?.includes(slot?.id) ?
                                                                                                <i onClick={() => removeSlots(slot?.id, "undo-remove")} className="fa fa-undo pointer ms-1" aria-hidden="true"></i> :
                                                                                                <i onClick={() => removeSlots(slot?.id, "remove")} className="fas fa-trash text-danger pointer ms-2" data-toggle="tooltip" data-placement="left" title="Remove this slot?" aria-hidden="true">
                                                                                                </i>
                                                                                            )}
                                                                                            {slot?.staff?.bluesky_staff_id ? <ul className="list-unstyled">
                                                                                                <li className="list-group-item"><small className="card-text">Bluesky Id : {slot?.staff?.bluesky_staff_id} </small></li>
                                                                                                <li className="list-group-item"><small className="card-text">Caregiver :  {slot?.staff?.first_name} {slot?.staff?.last_name}</small></li>
                                                                                                <li className="list-group-item"><small className="card-text">Contact :<a href={`tel:${slot?.staff?.contact}`}> <i className="fas fa-phone-alt"></i> {slot?.staff?.contact}</a> </small></li>
                                                                                                <li className="list-group-item"> <small className="card-text">Email :<a href={`mailto:${slot?.staff?.email}`}> <i className="fas fa-envelope"></i> {slot?.staff?.email}</a> </small></li>
                                                                                            </ul> : <p className='mt-1'><em>No Assignment!</em></p>}

                                                                                            {slot?.remark && slot?.remark?.length > 100 ? (
                                                                                                <div className="list-group-item">
                                                                                                    <i className="fas fa-info-circle text-primary"></i><marquee className="news-content"><small> {slot.remark}</small></marquee>
                                                                                                </div>
                                                                                            ) : slot?.remark && <div className={'list-group-item'}><i className="fas fa-info-circle text-primary"></i><small> {slot?.remark}</small></div>}
                                                                                        </div>
                                                                                    </li>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </ul>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </BottomSheet>
                        </div>
                    </div >
                </div >
            </>

            {/* ASIGGNED AREGIVERS MODAL */}
            < CModal scrollable visible={isOpenModal} size="lg" onClose={() => onDismissModal()}>
                <CModalHeader onClose={() => onDismissModal()}>
                    <CModalTitle>Assigned Caregivers For  &nbsp; &nbsp;<i className="far fa-calendar-alt"></i>  {assignDate}  &nbsp; &nbsp; <i className="far fa-clock"></i> {assignTime}</CModalTitle>
                </CModalHeader>
                <CListGroup>
                    <CTable responsive >
                        <CTableHead>
                            <CTableRow>
                                <CTableHeaderCell scope="col">#</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Bluesky ID</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Caregiver</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Contact</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Email</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Requested Time</CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>
                        <CTableBody>
                            <CTableRow>
                                <CTableHeaderCell scope="row">1</CTableHeaderCell>
                                <CTableDataCell><span className="badge btn btn-primary" >{caregiverList?.staff?.bluesky_staff_id}</span></CTableDataCell>
                                <CTableDataCell> {caregiverList?.staff?.first_name} {caregiverList?.staff?.last_name}</CTableDataCell>
                                <CTableDataCell>{caregiverList?.staff?.contact}&nbsp;&nbsp;({caregiverList?.staff?.contact && <a href={"tel:" + caregiverList?.staff?.contact}><i className="fas fa-phone"></i></a>})</CTableDataCell>
                                <CTableDataCell>{caregiverList?.staff?.email}&nbsp;&nbsp;<a href={"mailto:" + caregiverList?.staff?.email}><i className="fas fa-envelope"></i></a></CTableDataCell>
                                <CTableDataCell>{moment(caregiverList?.created_at).fromNow()}</CTableDataCell>
                            </CTableRow>
                        </CTableBody>
                    </CTable>
                </CListGroup>
            </CModal >

            {/* SLOT/Shift APPROVAL */}
            < CModal
                id="approval"
                size="lg"
                backdrop="static"
                visible={isOpenReqSlot}
                onClose={() => onSlotApprovalDismiss()}>
                <CModalHeader>
                    <CModalTitle>Order/Shifts Approval</CModalTitle>
                </CModalHeader>
                <CTable responsive >
                    <CTableHead>
                        <CTableRow>
                            <CTableHeaderCell>
                                <CFormCheck onClick={handleSelectAllRows} data-toggle="tooltip" data-placement="right" title="Approve All"
                                    inline style={{ borderColor: "green" }} type="radio" name="all_status_code" id="all_status_code_approve" value="Approve" label="Approve All" />
                            </CTableHeaderCell>
                            <CTableHeaderCell>
                                <CFormCheck onClick={handleSelectAllRows} data-toggle="tooltip" data-placement="right" title="Reject & Close All" inline style={{ borderColor: "red" }} type="radio" name="all_status_code" id="all_status_code_reject_close" value="Reject-Close" label="Reject & Close All" />
                            </CTableHeaderCell>
                            <CTableHeaderCell>
                                <CFormCheck onClick={handleSelectAllRows} data-toggle="tooltip" data-placement="right" title="Reject & Open All" inline style={{ borderColor: "red" }} type="radio" name="all_status_code" id="all_status_code_reject" value="Reject" label="Reject & Open All" />
                            </CTableHeaderCell>
                            <CTableHeaderCell scope="col">Order Ref.</CTableHeaderCell>
                            <CTableHeaderCell scope="col">Shift Date/Time</CTableHeaderCell>
                            <CTableHeaderCell scope="col">Bluesky ID</CTableHeaderCell>
                            <CTableHeaderCell scope="col">Caregiver</CTableHeaderCell>
                            <CTableHeaderCell scope="col">Prof.-Speciality</CTableHeaderCell>
                            <CTableHeaderCell scope="col">Contact</CTableHeaderCell>
                            <CTableHeaderCell scope="col">Email</CTableHeaderCell>
                            <CTableHeaderCell scope="col">Requested Time</CTableHeaderCell>
                        </CTableRow>
                    </CTableHead>
                    <CTableBody>
                        {reqSlotList?.map((care, index) => {
                            return <CTableRow key={index + "care"}>
                                <CTableHeaderCell id="approve">
                                    <CFormCheck inline
                                        data-toggle="tooltip" data-placement="right" title="Approve"
                                        style={{ borderColor: "green" }} type="radio"
                                        name={care.id}
                                        id={care.id + "approve"}
                                        label=""
                                        value={"Approve"}
                                        onClick={(e) => {
                                            var checkbox = document.getElementById("all_status_code_reject");
                                            checkbox.checked = false;
                                            slotAdd(e.target.value, care.id);
                                        }}
                                    />
                                </CTableHeaderCell>
                                <CTableHeaderCell id="reject-close">
                                    <CFormCheck inline
                                        data-toggle="tooltip" data-placement="right" title="Reject & Close"
                                        style={{ borderColor: "red" }} type="radio"
                                        name={care.id}
                                        id={care.id + "reject_close"}
                                        value={"Reject-Close"}
                                        label=""
                                        onClick={(e) => {
                                            var checkbox = document.getElementById("all_status_code_reject_close");
                                            checkbox.checked = false;
                                            slotAdd(e.target.value, care.id);
                                        }}
                                    />
                                </CTableHeaderCell>
                                <CTableHeaderCell id="reject">
                                    <CFormCheck inline
                                        data-toggle="tooltip" data-placement="right" title="Reject & Open"
                                        style={{ borderColor: "red" }} type="radio"
                                        name={care.id}
                                        id={care.id + "reject"}
                                        value={"Reject"}
                                        label=""
                                        onClick={(e) => {
                                            var checkbox = document.getElementById("all_status_code_reject");
                                            checkbox.checked = false;
                                            slotAdd(e.target.value, care.id);
                                        }}
                                    />
                                </CTableHeaderCell>
                                <CTableHeaderCell>{care.id}</CTableHeaderCell>
                                <CTableHeaderCell>{
                                    care?.category !== 'TC' ? <span>
                                        <input className="slot" readOnly value={care?.slot_date} />
                                        <TimeRangePicker
                                            value={[care?.slot_start_time, care?.slot_end_time]}
                                            disabled={true}
                                            className={"timer"}
                                            rangeDivider="To"
                                            clearIcon={null}
                                            required
                                            disableClock={true}
                                        />
                                    </span>
                                        : <>
                                            <span>
                                                <input className="slot" readOnly value={care?.start_date} />
                                                <span className='slot-to'>To</span>
                                                <input className="slot-right" readOnly value={care?.end_date} />
                                                <TimeRangePicker
                                                    value={[care?.start_time, care?.end_time]}
                                                    disabled={true}
                                                    className={"timer"}
                                                    rangeDivider="To"
                                                    clearIcon={null}
                                                    required
                                                    disableClock={true}
                                                />
                                            </span></>

                                }</CTableHeaderCell>
                                <CTableDataCell><span className="badge btn btn-primary" >{care?.staff?.bluesky_staff_id}</span></CTableDataCell>
                                <CTableDataCell> {care?.staff?.first_name} {care?.staff?.last_name}</CTableDataCell>
                                <CTableDataCell> {care?.specialty_name}</CTableDataCell>
                                <CTableDataCell><a href={"tel:" + care?.staff?.contact}>{care?.staff?.contact}&nbsp;&nbsp;{care?.staff?.contact && <i className="fas fa-phone"></i> || "-"}</a></CTableDataCell>
                                <CTableDataCell><a href={"mailto:" + care?.staff?.email}> {care?.staff?.email}&nbsp;&nbsp;<i className="fas fa-envelope"></i></a></CTableDataCell>
                                <CTableDataCell>{moment(care.created_at).fromNow()}</CTableDataCell>
                            </CTableRow>
                        })}

                    </CTableBody>

                </CTable>
                {/* </CModalBody> */}
                <div className="modal-footer justify-content-center">
                    <button type="button" className="btn btn-primary" onClick={() => onApproveSlot()} >Submit</button>
                </div>
            </CModal>

            {/* EXTEND POP UP FOR SLOTS*/}
            < CModal className="custom-modal-size" backdrop="static" visible={isOpenForm} onClose={() => onDismissForm()} >
                <CModalHeader onClose={() => onDismiss()}>
                    <CModalTitle>Select Shifts</CModalTitle>
                </CModalHeader>
                <Formik
                    innerRef={formikRef}
                    initialValues={{ request_id: formData?.id, tenure: "", alldate: "", alltime: "" }}
                    onSubmit={values => onExtendStatus(values)}
                    validateOnChange={false}
                >
                    {
                        ({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched
                        }) =>
                        (
                            <form onSubmit={handleSubmit}>
                                <div className="modal-body p-4">
                                    <div className="row g-2">
                                        <div className="pb-2 col-sm-6">
                                            <DatePicker
                                                placeholder="Select Start & End Date"
                                                calendarPosition="bottom"
                                                fixMainPosition
                                                required
                                                format="MM/DD/YYYY"
                                                inputClass="did-floating-dropdown"
                                                multiple
                                                range
                                                rangeHover
                                                value={dates}
                                                minDate={new Date()}
                                                onChange={dateObjects => {
                                                    setDates(dateObjects)
                                                    setAllDates(dateObjects?.map((date) => getAllDatesInRange([date[0], date[1]]))?.flat())
                                                    setFieldValue('tenure', dateObjects?.map((date) => getAllDatesInRange([date[0], date[1]]))?.flat())
                                                    setDisabledSlots()
                                                }
                                                }
                                                plugins={[
                                                    weekends(),
                                                    <ClearButton setFiled={setFieldValue} position="bottom" />

                                                ]}
                                            />
                                        </div>
                                        <div>
                                            {allDates?.length > 0 &&
                                                <div>
                                                    <div className="d-flex justify-content-start mt-2 mb-2">Slots:</div>
                                                    <ul className='list-unstyled'>
                                                        <FieldArray name="tenure">
                                                            {({ push, remove, form }) => (
                                                                values?.tenure?.length > 0 && values?.tenure?.sort((a, b) => new Date(a) - new Date(b))?.map((date, index) =>
                                                                    <div className={`card p-2 mb-3 ${disabledSlots?.includes(index + 1) ? "border border-2 border-danger" : "border"}`}>
                                                                        {disabledSlots?.includes(index + 1) && <span className='suffix-container mt-1'> <span class="suffix-lable">Assigned</span></span>}
                                                                        <div className="col-2 pt-1 pb-2" >
                                                                            <SelectField
                                                                                id={"slot-type"}
                                                                                label={"Shift Type"}
                                                                                name={`alltype[${index}]`}
                                                                                onChange={(event) => {
                                                                                    setFieldValue(`alltype[${index}]`, event.target.value)
                                                                                    setFieldValue(`alltime[${index}]`, [global.config.shiftTimeRange?.find(x => x.label === event.target.value)?.value?.start, global.config.shiftTimeRange?.find(x => x.label === event.target.value)?.value?.end])
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                                value={values?.alltype && values?.alltype[index]}
                                                                                required
                                                                                data={global.config.shiftType}
                                                                                optionLabel={"name"}
                                                                                optionValue={"value"}
                                                                            />
                                                                        </div>
                                                                        <li key={index}>
                                                                            <input className="slot" id={`alldate[${index}]`} name={`alldate[${index}]`} value={date?.format()} />
                                                                            <Field id={`alltime[${index}]`} name={`alltime[${index}]`} render={({ field, form }) => <TimeRangePickerField  {...field} {...form} index={index} />} />
                                                                            <input
                                                                                className="slot-qty"
                                                                                disabled={formData?.staff_id}
                                                                                placeholder="No. Of Positions"
                                                                                type="number"
                                                                                required
                                                                                name={`allqty[${index}]`}
                                                                                onChange={(event) => {
                                                                                    setFieldValue(`allqty[${index}]`, event.target.value)
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                                value={values?.allqty && values?.allqty[index]}
                                                                                min="1"
                                                                            />
                                                                            <div className="btn position-absolute top-0 end-0" onClick={() => {
                                                                                setAllDates([
                                                                                    ...allDates.slice(0, index),
                                                                                    ...allDates.slice(index + 1)
                                                                                ]);
                                                                                setDates([
                                                                                    ...allDates.slice(0, index),
                                                                                    ...allDates.slice(index + 1)
                                                                                ]);
                                                                                (values?.alltime?.length !== 0 && values?.alltime?.length >= index) && values?.alltime?.splice(index, 1);
                                                                                (values?.allqty?.length !== 0 && values?.allqty?.length >= index) && values?.allqty?.splice(index, 1);
                                                                                (values?.alltype?.length !== 0 && values?.alltype?.length >= index) && values?.alltype?.splice(index, 1);
                                                                                remove(index)
                                                                            }}>
                                                                                <i className="fas fa-times-circle text-danger" aria-hidden="true"></i>

                                                                            </div>
                                                                            <div className="btn" onClick={() => {
                                                                                const elementToCopy = values.tenure[index];
                                                                                const elementToCopyTime = [undefined, undefined];
                                                                                setFieldValue("tenure", [...values?.tenure, elementToCopy]);
                                                                                (values?.alltime && values?.alltime?.length !== 0 && values?.alltime?.length >= index) && values?.alltime?.splice(index + 1, 0, elementToCopyTime);
                                                                                (values?.allqty && values?.allqty?.length !== 0 && values?.allqty?.length >= index) && values?.allqty?.splice(index + 1, 0, values?.allqty[0]);
                                                                                (values?.alltype && values?.alltype?.length !== 0 && values?.alltype?.length >= index) && values?.alltype?.splice(index + 1, 0, values?.alltype[0]);
                                                                                setAllDates([...allDates, elementToCopy])
                                                                                setDates([...allDates, elementToCopy])
                                                                            }}>
                                                                                <div className="btn btn-outline-success pt-1 pb-1"><i className="fa fa-plus" aria-hidden="true"></i></div>
                                                                            </div>
                                                                            {values.tenure?.length > 0 && <i className="far fa-copy pointer" onClick={() => {
                                                                                if (values?.alltime[index]?.length === 2 && !values?.alltime[index]?.some(item => item === null || item === undefined)) {
                                                                                    values?.tenure?.map((item, ind) => {
                                                                                        setFieldValue(`alltime[${ind}]`, values?.alltime[index]);
                                                                                        if (values?.allqty?.length && (values?.allqty[index] !== null || values?.allqty[index] !== undefined)) {
                                                                                            setFieldValue(`allqty[${ind}]`, values?.allqty[index])
                                                                                        }
                                                                                        if (values?.alltype?.length && (values?.alltype[index] !== null || values?.alltype[index] !== undefined)) {
                                                                                            setFieldValue(`alltype[${ind}]`, values?.alltype[index])
                                                                                        }
                                                                                    })
                                                                                } else {
                                                                                    toast.warn("Please select start end time")
                                                                                }
                                                                            }} data-toggle="tooltip" data-placement="right" title="Copy to all"></i>}
                                                                        </li>
                                                                        <div className="col-12">
                                                                            <InputField
                                                                                className={"did-floating-input"}
                                                                                type="text"
                                                                                name={`allcomment[${index}]`}
                                                                                onChange={(event) => {
                                                                                    setFieldValue(`allcomment[${index}]`, event.target.value)
                                                                                }}
                                                                                label={"Comments For Caregiver"}
                                                                                value={values?.allcomment && values?.allcomment[index]}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )
                                                            )}
                                                        </FieldArray>
                                                    </ul>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-center">
                                    <button type="submit" className="btn btn-primary w-25">Submit</button>
                                </div>
                            </form>
                        )
                    }
                </Formik>
            </CModal >

            {/* EXTEND POP UP FOR CONTACT TC*/}
            < CModal className="custom-modal-size" backdrop="static" visible={isOpenFormTC} onClose={() => setIsOpenFormTC(false)} >
                <CModalHeader onClose={() => setIsOpenFormTC(false)}>
                    <CModalTitle>Select Contract</CModalTitle>
                </CModalHeader>
                <Formik
                    innerRef={formikRef}
                    initialValues={{ request_id: tcFormDataTC?.id, end_date: "" }}
                    onSubmit={values => onExtendTCStatus(values)}
                    
                >
                    {
                        ({ handleChange, handleBlur, handleSubmit, values, errors, touched
                        }) =>
                        (
                            <form onSubmit={handleSubmit}>
                                <div className="modal-body p-4">
                                    <div className='row'>
                                        <div className="col-sm-6 pb-lg-2 pb-md-2">
                                            <InputField
                                                className={"did-floating-input"}
                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                type="date"
                                                name="start_date"
                                                label={"Start Date"}
                                                value={tcFormDataTC.end_date}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="col-sm-6 pb-lg-2 pb-md-2">
                                            <InputField
                                                className={"did-floating-input"}
                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                type="date"
                                                name="end_date"
                                                label={"End Date"}
                                                value={values.end_date}
                                                min={tcFormDataTC.end_date}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={true}
                                            />
                                            {errors.end_date && touched.end_date ? (
                                                <div className="error-message">{errors.end_date}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-center">
                                    <button type="submit" className="btn btn-primary w-25">Submit</button>
                                </div>
                            </form>
                        )
                    }
                </Formik>
            </CModal >
            {/* EDIT POP UP FOR CONTACT TC*/}
            < CModal className="custom-modal-size" backdrop="static" visible={isOpenFormEditTC} onClose={() => { setIsOpenFormEditTC(false) }} >
                <CModalHeader onClose={() => { setIsOpenFormEditTC(false) }}>
                    <CModalTitle>Edit Contract Ref No. {tcFormDataTC?.id}</CModalTitle>
                </CModalHeader>
                <Formik
                    innerRef={formikRef}
                    initialValues={{
                        request_id: tcFormDataTC?.id,
                        profession: tcFormDataTC?.profession || "",
                        specialty_name: tcFormDataTC?.specialty_name || "",
                        hours_per_week: tcFormDataTC?.hours_per_week || "",
                        no_of_weeks: tcFormDataTC?.no_of_weeks || "",
                        pay_details: tcFormDataTC?.pay_details || "",
                        start_date: tcFormDataTC?.start_date || "",
                        end_date: tcFormDataTC?.end_date || "",
                        start_time: tcFormDataTC?.start_time || "",
                        end_time: tcFormDataTC?.end_time || "",
                        qualifications: tcFormDataTC?.qualifications || ""
                    }}
                    onSubmit={values => updateContractData(values)}
                    validationSchema={UpdateTCSchema}
                    enableReinitialize={true}
                    validateOnChange={false}
                >
                    {
                        ({ handleChange, handleBlur, handleSubmit, values, errors, touched
                        }) =>
                        (
                            <form onSubmit={handleSubmit}>
                                <div className="modal-body p-4">
                                    <div className="row g-2">
                                        <div className="col-sm-6">
                                            <div className="did-floating-label-content">
                                                <select className="did-floating-dropdown"
                                                    name="profession"
                                                    value={values.profession}
                                                    disabled
                                                >
                                                    <option
                                                        value=""
                                                    >
                                                    </option>
                                                    {degree && degree?.map((option, index) => (
                                                        <option
                                                            key={index}
                                                            value={option.Id}
                                                        >
                                                            {option.Name}
                                                        </option>
                                                    ))}
                                                </select>
                                                <label className={values.profession == "" ? "did-floating-label did-floating-label-down did-floating-labe-astrisk" : "did-floating-label did-floating-label-top did-floating-labe-astrisk"}>Profession</label>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 pb-lg-2 pb-md-2">
                                            <InputField
                                                className={"did-floating-input"}
                                                type="text"
                                                label={"Specialty"}
                                                value={values.specialty_name}
                                                disabled={true}
                                            />
                                        </div>

                                        <div className="col-sm-6 pb-lg-2 pb-md-2">
                                            <InputField
                                                className={"did-floating-input"}
                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                type="date"
                                                name="start_date"
                                                label={"Start Date"}
                                                value={values.start_date}
                                                min={new Date().toISOString().split('T')[0]}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={true}
                                            />
                                            {errors.start_date && touched.start_date ? (
                                                <div className="error-message">{errors.start_date}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-6 pb-lg-2 pb-md-2">
                                            <InputField
                                                className={"did-floating-input"}
                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                type="date"
                                                name="end_date"
                                                label={"End Date"}
                                                value={values.end_date}
                                                min={values.start_date ? values.start_date : new Date().toISOString().split('T')[0] || values.start_date}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={true}
                                            />
                                            {errors.end_date && touched.end_date ? (
                                                <div className="error-message">{errors.end_date}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-6 pb-lg-2 pb-md-2">
                                            <InputField
                                                className={"did-floating-input"}
                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                type="time"
                                                name="start_time"
                                                label={"Start Time"}
                                                value={values.start_time}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={true}
                                            />
                                            {errors.start_time && touched.start_time ? (
                                                <div className="error-message">{errors.start_time}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-6 pb-lg-2 pb-md-2">
                                            <InputField
                                                className={"did-floating-input"}
                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                type="time"
                                                name="end_time"
                                                label={"End Time"}
                                                value={values.end_time}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={true}
                                            />
                                            {errors.end_time && touched.end_time ? (
                                                <div className="error-message">{errors.end_time}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-6 pb-lg-2 pb-md-2">
                                            <InputField
                                                className={"did-floating-input"}
                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                type="text"
                                                name="pay_details"
                                                label={"Pay Detail"}
                                                value={values.pay_details}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={true}
                                            />
                                            {errors.pay_detail && touched.pay_detail ? (
                                                <div className="error-message">{errors.pay_detail}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-6 pb-lg-2 pb-md-2">
                                            <InputField
                                                className={"did-floating-input"}
                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                type="number"
                                                name="hours_per_week"
                                                label={"Guaranteed Hours Per Week"}
                                                value={values.hours_per_week}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={true}
                                                min={1}
                                            />
                                            {errors.hours_per_week && touched.hours_per_week ? (
                                                <div className="error-message">{errors.hours_per_week}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-6 pb-lg-2 pb-md-2">
                                            <InputField
                                                className={"did-floating-input"}
                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                type="number"
                                                name="no_of_weeks"
                                                label={"# of Weeks"}
                                                value={values.no_of_weeks}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={true}
                                                min={1}
                                            />
                                            {errors.no_of_weeks && touched.no_of_weeks ? (
                                                <div className="error-message">{errors.no_of_weeks}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-6 pb-lg-2 pb-md-2">
                                            <InputField
                                                className={"did-floating-input"}
                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                type="text"
                                                name="qualifications"
                                                label={"Qualifications"}
                                                value={values.qualifications}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={true}
                                            />
                                            {errors.qualifications && touched.qualifications ? (
                                                <div className="error-message">{errors.qualifications}</div>
                                            ) : null}
                                        </div>
                                        <div className="d-flex justify-content-center pt-1">
                                            <button className="btn btn-primary w-auto pe-5 ps-5 p-2" type="submit">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )
                    }
                </Formik>
            </CModal >
            {/* CSV UPLOAD Modal*/}
            < CModal className="custom-modal-size" backdrop="static" visible={isOpenCSVUpload} onClose={() => { setIsOpenCSVUpload(false) }} >
                <CModalHeader onClose={() => { setIsOpenCSVUpload(false) }}>
                    <CModalTitle>Upload Order</CModalTitle>
                </CModalHeader>
                <Formik
                    initialValues={{
                        shift_file: "",
                        profession: "",
                        specialty: "",
                        specialty_name: "",
                        shift_type: "",
                        comment: ""
                    }}
                    onSubmit={values => uploadShiftCSV(values)}
                    innerRef={formikRef}
                    validationSchema={shiftImportClientSchema}
                >
                    {
                        ({ handleChange, handleBlur, handleSubmit, setFieldValue, resetForm, values, errors, touched
                        }) =>
                        (
                            <form onSubmit={handleSubmit}>
                                <div className="modal-body p-4">
                                    <div className="row">
                                        <div className="col-sm-6 mb-3">
                                            <div className="did-floating-label-content">
                                                <select className="did-floating-dropdown"
                                                    name="profession"
                                                    value={values.profession}
                                                    required
                                                    onChange={(e) => {
                                                        if (e.target.value) {
                                                            setFieldValue('profession', e.target.value)
                                                            HomeStore.getSpecialtiesListData(e.target.value)
                                                        } else {
                                                            setSpecialties([])
                                                        }
                                                    }}
                                                >
                                                    <option
                                                        value=""
                                                    >
                                                    </option>
                                                    {degree && degree?.map((option, index) => (
                                                        <option
                                                            key={index}
                                                            value={option.Id}
                                                        >
                                                            {option.Name}
                                                        </option>
                                                    ))}
                                                </select>
                                                <label className={values.profession == "" ? "did-floating-label did-floating-label-down did-floating-labe-astrisk" : "did-floating-label did-floating-label-top did-floating-labe-astrisk"}>Profession</label>
                                            </div>
                                            {errors.profession && touched.profession ? (
                                                <div className="error-message">{errors.profession}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-6 mb-3">
                                            <SelectField
                                                name="specialty"
                                                label={HomeStore.loading ? "Loading..." : specialties?.length === 0 ? "No Specialty Found" : "Specialty"}
                                                disabled={!values.profession || HomeStore.loading}
                                                value={values.specialty}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                data={specialties}
                                                optionLabel={"Name"}
                                                optionValue={"Id"}
                                                required={specialties?.length === 0 ? false : true}
                                            />
                                        </div>
                                        <div className="col-sm-6 mb-3">
                                            <SelectField
                                                name="shift_type"
                                                label={"Category"}
                                                value={values.shift_type}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                data={[{ "label": "Per Diem", "value": "PD" }, { "label": "Local Contract", "value": "TC" }]}
                                                optionLabel={"label"}
                                                optionValue={"value"}
                                                required
                                            />
                                            {errors.shift_type && touched.shift_type ? (
                                                <div className="error-message">{errors.shift_type}</div>
                                            ) : null}</div>
                                        <div className="d-flex justify-content-center align-items-center mb-2">
                                            {!values.shift_file ? <div className="col-sm-5 card input-border-common justify-content-center align-items-center m-2 p-3 pointer" onClick={() => document.getElementById('file').click()}>
                                                <label className="file-upload-label">
                                                    <img className="upload-image pointer" width="100" src="/upload_exl.png" alt="Upload" />
                                                </label>
                                                <input
                                                    type="file"
                                                    id="file"
                                                    name="file"
                                                    className="file-input"
                                                    onChange={(event) => {
                                                        const exfile = event.currentTarget.files[0];
                                                        // Set the file value in Formik
                                                        setFieldValue('shift_file', exfile);
                                                    }}
                                                    style={{ display: 'none' }}
                                                    accept=".xlsx"
                                                />
                                                <p className="card-text text-primary">Per Diem/Local Contract</p>
                                            </div> :
                                                <div className="card input-border-common justify-content-center align-items-center m-2 p-3 ">
                                                    <div className='d-flex'>
                                                        <small className='text-success'>{values.shift_file?.name}</small>
                                                        <i className="fas fa-times-circle text-danger ms-2 pointer" onClick={() => setFieldValue('shift_file', '')}></i></div>
                                                    <ExcelPreview file={values.shift_file} />
                                                </div>}
                                        </div>
                                        {errors.shift_file && touched.shift_file ? (
                                            <div className="error-message text-center">{errors.shift_file}</div>
                                        ) : null}
                                        <div className="col-12">
                                            <label className="pb-2 label">Comments For Staffers Management</label>
                                            <div className="form-floating">
                                                <textarea className="form-control"
                                                    placeholder=""
                                                    name={'comment'}
                                                    value={values.comment}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    style={{ height: 100 }}>
                                                </textarea>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-center pt-3">
                                            <a href="/shift_sample.xlsx" download="shift_import_sample.xlsx">
                                                <div className="btn btn-info w-auto pe-5 ps-5 p-2 me-2">Download Sample <i className="fas fa-download"></i></div>
                                            </a>
                                            <button className="btn btn-light w-auto pe-5 ps-5 p-2 me-2" type="reset" onClick={() => resetForm()}>Clear</button>
                                            <button className="btn btn-primary w-auto pe-5 ps-5 p-2" type="submit">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )
                    }
                </Formik>
            </CModal >
            <Spinner isLoading={UserStore.loading} />
        </React.Fragment >
    )

    function TimeRangePickerField(props) {
        const hnadleStart = (e) => {
            const startTime = e.target.value;
            if (props?.values?.alltime?.length === 0) {
                props.setFieldValue(props.name, [startTime, undefined]);
            } else {
                props.setFieldValue(props.name, [startTime, props?.values?.alltime[props.index]?.length === 2 ? props?.values?.alltime[props.index][1] : undefined]);
            }
        }
        const hnadleEnd = (e) => {
            const endTime = e.target.value;
            if (props?.values?.alltime?.length === 0) {
                props.setFieldValue(props.name, [undefined, endTime]);
            } else {
                if (props?.values?.alltime[props.index][0] !== endTime) {
                    props.setFieldValue(props.name, [props?.values?.alltime[props.index][0], endTime]);
                } else {
                    toast.error("The start and finish timings must not be the same!")
                }
            }
        }
        return (
            <>
                <input required type="time" id="start" style={{ paddingTop: 1, marginBottom: 1 }} name="start" value={props?.values?.alltime[props?.index] ? props?.values?.alltime[props?.index][0] : ''} onChange={hnadleStart} />
                <span className="timer-form slight-padding border-right-0 border-left-0">To</span>
                <input required type="time" id="end" style={{ paddingTop: 1, marginBottom: 1 }} name="end" disabled={props?.values?.alltime[props?.index] ? props?.values?.alltime[props?.index][0] ? false : true : true} value={props?.values?.alltime[props?.index]?.length === 2 ? props?.values?.alltime[props.index][1] : ""} onChange={hnadleEnd} />
            </>
        );
    }
});

export default StaffingRequestListPage;