import React, { useState } from "react";
import CountUp from "react-countup";
import { Waypoint } from "react-waypoint";

const Ticker = ({ end, suffix }) => {
  const [viewPortEntered, setViewPortEntered] = useState(false);

  const onVWEnter = () => {
    setViewPortEntered(true);
  };

  return (
    <Waypoint onEnter={onVWEnter}>
      <div>
        {viewPortEntered && <CountUp end={end} suffix={suffix} start={0} />}
      </div>
    </Waypoint>
  );
};

export default Ticker;
