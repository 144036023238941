import React from "react";

const CoreValues = ({ sectionData }) => {
  return (
    <div className="core-value-sec bg-img">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div>
              <h2 className=" text-center common-web-head">
                <span> {sectionData?.[0] || `Core Values`} </span>
              </h2>
              <div className="row justify-content-center">
                <div className="col-md-10">
                  <p className="common-web-para bold text-center">
                    {sectionData?.[1] ||
                      `At the foundation of any successful organization are a set
                    of core values. These core values serve as the key
                    principles which the organization and its members follow. At
                    Staffers, these values are:`}
                  </p>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-lg-3 col-md-4 col-sm-6 core-height-set">
                  <div className="space-bottom">
                    <div>
                      <div>
                        <img
                          className="img-fluid"
                          src="/assets/img/website/Sincerity.png"
                          alt="sincerityIcon"
                        />
                      </div>
                      <h3 className="common-web-third">
                        {sectionData?.[2] || `Sincerity`}
                      </h3>
                      <p className="common-web-para">
                        {sectionData?.[3] ||
                          `We provide services that reflect care, consideration,
                        genuineness, and respect for all customers.`}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 core-height-set">
                  <div className="space-bottom">
                    <div>
                      <div>
                        <img
                          className="img-fluid"
                          src="/assets/img/website/trustworthy.png"
                          alt="trustworthyIcon"
                        />
                      </div>
                      <h3 className="common-web-third">
                        {sectionData?.[4] || `Trustworthy`}
                      </h3>
                      <p className="common-web-para">
                        {sectionData?.[5] ||
                          `We keep our promises and can count on each other.`}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 core-height-set">
                  <div className="space-bottom">
                    <div>
                      <div>
                        <img
                          className="img-fluid"
                          src="/assets/img/website/attitude.png"
                          alt="attitudeIcon"
                        />
                      </div>
                      <h3 className="common-web-third">
                        {sectionData?.[6] || `Attitude`}
                      </h3>
                      <p className="common-web-para">
                        {sectionData?.[7] ||
                          `We always maintain a positive attitude which is vital to
                        our customer experience.`}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6  core-height-set">
                  <div className="space-bottom">
                    <div>
                      <div>
                        <img
                          className="img-fluid"
                          src="/assets/img/website/flexibility.png"
                          alt="flexibilityIcon"
                        />
                      </div>
                      <h3 className="common-web-third">
                        {sectionData?.[8] || `Flexibility`}
                      </h3>
                      <p className="common-web-para">
                        {sectionData?.[9] ||
                          `We embrace some level flexibility in order to deliver
                        excellent customer service.`}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6  core-height-set">
                  <div className="space-bottom">
                    <div>
                      <div>
                        <img
                          className="img-fluid"
                          src="/assets/img/website/fun.png"
                          alt="funicon"
                        />
                      </div>
                      <h3 className="common-web-third">
                        {sectionData?.[10] || `Fun`}
                      </h3>
                      <p className="common-web-para">
                        {sectionData?.[11] ||
                          `We encourage and foster a work environment that is fun
                        and inspires employees to take joy in their work.`}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6  core-height-set">
                  <div className="space-bottom">
                    <div>
                      <div>
                        <img
                          className="img-fluid"
                          src="/assets/img/website/exceedingExpectations.png"
                          alt="ExpectationsIcon"
                        />
                      </div>
                      <h3 className="common-web-third">
                        {sectionData?.[12] || `Exceed Expectations`}
                      </h3>
                      <p className="common-web-para">
                        {sectionData?.[13] ||
                          `We don’t just meet expectations, we exceed them.`}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 core-height-set">
                  <div className="space-bottom">
                    <div>
                      <div>
                        <img
                          className="img-fluid"
                          src="/assets/img/website/respectful.png"
                          alt="RespectfulIcon"
                        />
                      </div>
                      <h3 className="common-web-third">
                        {sectionData?.[14] || `Respectful`}
                      </h3>
                      <p className="common-web-para">
                        {sectionData?.[15] ||
                          `We respect our customer’s moods, their feelings and each
                        other at all times.{" "}`}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6  core-height-set">
                  <div className="space-bottom">
                    <div>
                      <div>
                        <img
                          className="img-fluid"
                          src="/assets/img/website/satisfaction.png"
                          alt="SatisfactionIcon"
                        />
                      </div>
                      <h3 className="common-web-third">
                        {sectionData?.[16] || `Satisfaction`}
                      </h3>
                      <p className="common-web-para">
                        {sectionData?.[17] ||
                          `We ensure all of our customers are 100% satisfied with
                        the services delivered.`}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <p className="common-web-para bold text-center mb-0">
                {sectionData?.[18] ||
                  `These core values are the basis of how we do business. They are
                the framework for how we act day-to-day.`}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoreValues;
