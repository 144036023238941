/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import Header from "@components/Header";
import { Helmet } from "react-helmet";
import { CButton, CFormLabel } from "@coreui/react";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import JobsStore from "@store/JobsStore";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import ReactDOM from "react-dom";
import moment from "moment";
import { Images } from "@elements";

const AppliedJobsPage = observer(() => {
  window.onscroll = function () { };
  const tableRef = useRef();
  const tableName = "table1";
  const [isOpen, setIsOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState();
  const [appliedJobList, setAppliedJobList] = useState([]);

  useEffect(() => {
    JobsStore.getAppliedJobsData();
  }, []);

  useEffect(() => {
    setAppliedJobList(toJS(JobsStore.data.appliedJobs));
  }, [JobsStore?.data?.appliedJobs]);

  useEffect(() => {
    $.fn.dataTable.ext.errMode = "none";
    $($.fn.dataTable.tables(true)).DataTable().columns.adjust();
    const table = $(`#${tableName}`).DataTable({
      data: appliedJobList,
      order: [], // Disable initial sorting
      columns: [
        {
          title: "Job Id",
          data: "jobs",
          width: 50,
          render: function (data, type, full, meta) {
            return data?.id;
          },
        },
        {
          title: "Industry",
          data: "jobs",
          render: function (data, type, row, meta) {
            return data?.industry || "-";
          },
        },

        {
          title: "Degree-Specialty",
          data: "jobs",
          render: function (data, type, row, meta) {
            return row?.jobs?.speciality
              ? JSON.parse(row?.jobs?.speciality)?.Name
              : row?.jobs?.degree
                ? JSON.parse(row?.jobs?.degree)?.Name
                : "-";
          },
        },
        {
          title: "Pay Details",
          data: "jobs",
          render: function (data, type, row, meta) {
            return (
              "$" +
              data?.pay_details_min +
              " To " +
              "$" +
              data?.pay_details_max || "-"
            );
          },
        },
        {
          title: "Shift Type",
          data: "jobs",
          render: function (data, type, row, meta) {
            return data?.shift_type ? JSON?.parse(data?.shift_type)?.map(x => x)?.join(', ') : "-";
          },
        },
        {
          title: "Contract Type",
          width: 10,
          data: "jobs",
          render: function (data, type, row, meta) {
            return data?.contract_type || "-";
          },
        },

        {
          title: "City",
          width: 100,
          data: "jobs",
          render: function (data, type, row, meta) {
            return data?.city || "-";
          },
        },
        {
          title: "State",
          width: 50,
          data: "jobs",
          render: function (data, type, row, meta) {
            return data?.state || "-";
          },
        },

        {
          title: "Status",
          width: 50,
          data: "status",
          render: function (data, type, row, meta) {
            return data == 1
              ? '<span class="text-success">' + "Applied" + "</span>"
              : data == 0
                ? '<span class="text-dark">' + "Closed" + "</span>"
                : data == 2
                  ? '<span class="text-danger">' + "Cancelled" + "</span>"
                  : "No Update";
          },
        },
        {
          title: "Action",
        },
      ],

      columnDefs: [
        {
          targets: 9,
          responsivePriority: 1,
          createdCell: (td, cellData, rowData, row, col) =>
            ReactDOM.render(
              <div>
                <CButton
                  onClick={() => {
                    showDetails(rowData);
                  }}
                  size="sm"
                  className="m-2 w-100"
                  variant="outline"
                  color="primary"
                >
                  <i className="fa fa-eye" aria-hidden="true"></i> View
                </CButton>
              </div>,
              td
            ),
        },
        {
          targets: 3,
          responsivePriority: 2,
        },
      ],
      destroy: true, // I think some clean up is happening here
      searching: true,
      language: {
        search: "",
        searchPlaceholder: "Search",
        emptyTable: "No Record Found",
      },
      initComplete: function () {
        // Apply the search
        this.api()
          .columns([0, 1, 2, 3, 4, 5, 6, 7])
          .every(function () {
            var column = this;
            var select = $(
              '<br/><input class="form-control" style="width:100%;margin-top:5px;background-color:white"" type="text" placeholder="Filter" />'
            ).appendTo($(column.header()));

            $(select).click(function (e) {
              e.stopPropagation();
            });
            $("input", this.header()).on("keyup change clear", function () {
              if (column.search() !== this.value) {
                column.search(this.value).draw();
              }
            });
          });
        // this.api()
        //     .columns([7])
        //     .every(function () {
        //         var column = this;
        //         var select = $('<br/><input class="form-control" style="width:100%;margin-top:5px;background-color:white"" type="date" placeholder="Filter" />')
        //             .appendTo($(column.header()))

        //         $(select).click(function (e) {
        //             e.stopPropagation();
        //         });
        //         $('input', this.header()).on('keyup change clear', function () {
        //             if (column.search() !== this.value) {
        //                 column.search(this.value).draw();
        //             }
        //         });
        //     });

        this.api()
          .columns([8])
          .every(function () {
            var column = this;
            var select = $(
              '<br/><select class="form-control" placeholder="search" style="width:100%;margin-top:5px;background-color:white""><option value="">All</option></select>'
            )
              .appendTo($(column.header()))
              .on("change", function () {
                var val = $.fn.dataTable.util.escapeRegex($(this).val());
                column.search(val ? "^" + val + "$" : "", true, false).draw();
              });
            $(select).click(function (e) {
              e.stopPropagation();
            });
            column
              .data()
              .unique()
              .sort()
              .each(function (d, j) {
                let name = [
                  { name: "Applied", value: 0 },
                  { name: "Closed", value: 1 },
                  { name: "Cancelled", value: 2 },
                ]?.find((stat) => stat.value == d)?.name;
                select.append(
                  '<option value="' + name + '">' + name + "</option>"
                );
              });
          });
      },
    });
    // Extra step to do extra clean-up.
    return function () {
      table.destroy();
    };
  }, [appliedJobList]);

  const showDetails = (item) => {
    setSelectedJob(item?.jobs);
    setIsOpen(true);
  };
  const onDismiss = () => {
    setIsOpen(false);
  };

  return (
    <React.Fragment>
      <Header />
      <Helmet>
        <title>Staffers LLC | Applied Jobs</title>
      </Helmet>
      <>
        <div className="container-fluid callback-small mt-5 py-5">
          <div className="container pt-lg-5">
            <h5 className="display-5 mb-4 animated slideInDown login-head">
              Applied Jobs
            </h5>
          </div>
          <div className="col-lg-12">
            <div
              className="row bg-white"
              style={{ height: 500, paddingTop: 50 }}
            >
              <table
                className="table dt-responsive table-hover nowrap"
                style={{ width: "100%" }}
                id={tableName}
                ref={tableRef}
              ></table>
            </div>
          </div>
        </div>
        <BottomSheet
          open={isOpen}
          onDismiss={onDismiss}
          scrollLocking={true}
          header={
            <>
              <div
                className="text-end"
                style={{ cursor: "pointer" }}
                onClick={onDismiss}
              >
                <i className="fa fa-times" aria-hidden="true"></i>
              </div>
              <h4 className="flex items-center text-xl justify-center font-bold text-primary-800">
                JOB DETAILS #{selectedJob?.id}{" "}
                {selectedJob?.is_exclusive === 1 ? (
                  <img src={Images.exclusive} width={50} />
                ) : (
                  ""
                )}
              </h4>
            </>
          }
        >
          <div style={{ height: "60vh", overflowY: "scroll" }}>
            <div className="col-xl-12 col-md-12">
              <div className="user-card-full">
                <div className="row m-l-0 m-r-0">
                  <div className="col-sm-12">
                    <div className="card-block">
                      {/* <h6 className="m-b-20 p-b-5 b-b-default f-w-600">Job </h6> */}
                      <div className="row">
                        {selectedJob?.industry && (
                          <div className="col-sm-2">
                            <p className="m-b-10 f-w-600">Industry</p>
                            <h6 className="text-muted f-w-400">
                              {selectedJob?.industry}
                            </h6>
                          </div>
                        )}
                        {selectedJob?.shift_type && (
                          <div className="col-sm-2">
                            <p className="m-b-10 f-w-600">Shift Type</p>
                            <h6 className="text-muted f-w-400">
                              {selectedJob?.shift_type? JSON?.parse(selectedJob?.shift_type)?.map(x => x)?.join(', '):""}
                            </h6>
                          </div>
                        )}
                        {selectedJob?.contract_type && (
                          <div className="col-sm-2">
                            <p className="m-b-10 f-w-600">Contact Type</p>
                            <h6 className="text-muted f-w-400">
                              {selectedJob?.contract_type}
                            </h6>
                          </div>
                        )}
                        {selectedJob && (
                          <div className="col-sm-2">
                            <p className="m-b-10 f-w-600">Degree-Specialty</p>
                            <h6 className="text-muted f-w-400">
                              {selectedJob?.speciality
                                ? JSON.parse(selectedJob?.speciality)?.Name
                                : selectedJob?.degree
                                  ? JSON.parse(selectedJob?.degree)?.Name
                                  : "-"}
                            </h6>
                          </div>
                        )}
                        {selectedJob?.pay_details_max && (
                          <div className="col-sm-2">
                            <p className="m-b-10 f-w-600">Pay Details</p>
                            <h6 className="text-muted f-w-400">
                              ${selectedJob?.pay_details_min} - $
                              {selectedJob?.pay_details_max}{" "}
                            </h6>
                          </div>
                        )}
                        {selectedJob?.city && (
                          <div className="col-sm-2">
                            <p className="m-b-10 f-w-600">City-State</p>
                            <h6 className="text-muted f-w-400">
                              {selectedJob?.city}, {selectedJob?.state}{" "}
                            </h6>
                          </div>
                        )}
                        {selectedJob?.end_date && (
                          <div className="col-sm-2">
                            <p className="m-b-10 f-w-600">
                              Start Date To End Date
                            </p>
                            <h6 className="text-muted f-w-400">
                              {moment(
                                selectedJob?.start_date,
                                "MM/DD/YYYY"
                              ).format("MMMM DD, YYYY")}{" "}
                              To{" "}
                              {moment(
                                selectedJob?.end_date,
                                "MM/DD/YYYY"
                              ).format("MMMM DD, YYYY")}{" "}
                            </h6>
                          </div>
                        )}
                        {selectedJob?.gauranteed_hours && (
                          <div className="col-sm-2">
                            <p className="m-b-10 f-w-600">Gauranteed Hours</p>
                            <h6 className="text-muted f-w-400">
                              {" "}
                              {selectedJob?.gauranteed_hours}{" "}
                            </h6>
                          </div>
                        )}
                        {selectedJob?.length_weeks && (
                          <div className="col-sm-2">
                            <p className="m-b-10 f-w-600">Length Of Contact</p>
                            <h6 className="text-muted f-w-400">
                              {" "}
                              {selectedJob?.length_weeks}{" "}
                            </h6>
                          </div>
                        )}
                        {selectedJob?.opening && (
                          <div className="col-sm-2">
                            <p className="m-b-10 f-w-600">No. Of Opening</p>
                            <h6 className="text-muted f-w-400">
                              {" "}
                              {selectedJob?.opening}{" "}
                            </h6>
                          </div>
                        )}

                        {selectedJob && selectedJob?.qualifications && (
                          <div class="col-sm-6">
                            <p class="m-b-10 f-w-600">Qualifications</p>
                            {/* <h6 class="text-muted f-w-400">{signleData?.content}</h6> */}
                            <div
                              dangerouslySetInnerHTML={{
                                __html: selectedJob?.qualifications,
                              }}
                            />
                          </div>
                        )}
                        {selectedJob && selectedJob?.benefits && (
                          <div class="col-sm-6">
                            <p class="m-b-10 f-w-600">Benefits</p>
                            {/* <h6 class="text-muted f-w-400">{signleData?.content}</h6> */}
                            <div
                              dangerouslySetInnerHTML={{
                                __html: selectedJob?.benefits,
                              }}
                            />
                          </div>
                        )}
                        {selectedJob && selectedJob?.description && (
                          <div class="col-sm-6">
                            <p class="m-b-10 f-w-600">Description</p>
                            {/* <h6 class="text-muted f-w-400">{signleData?.content}</h6> */}
                            <div
                              dangerouslySetInnerHTML={{
                                __html: selectedJob?.description,
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BottomSheet>
      </>
    </React.Fragment>
  );
});

export default AppliedJobsPage;
