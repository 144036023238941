import { configure, makeAutoObservable, runInAction } from "mobx"
import { axiosInstanceInt, setAuthTokenIntAdmin } from "../api/axiosInstance"
import { toast } from 'react-toastify';
import secureLocalStorage from "react-secure-storage";
import swal from '@sweetalert/with-react';
import TokenStore from "@store/TokenStore";

class AdminStore {
  loading = false
  loadingLong = false
  data = {
    page: 1,
    pageSize: 25,
    lastPage: 0,
    total: 0,
    from: 0,
    to: 0,
    search: '',
    email: "",
    name: "",
    admintoken: "",
    id: "",
    refreshToken: null,
    dashboard: [],
    notifications: [],
    manageNotification: [],
    adminList: [],
    clientList: [],
    allClientList: [],
    allStaffList: [],
    staffList: [],
    hiringList: [],
    jobProfileList: [],
    staffingList: [],
    settings: [],
    profileDetails: [],
    clientsByFacility: [],
    status_id: '',
    staffListByProfession: [],
    pendingSlots: [],
    staffPendingSlots: [],
    dnrList: [],
    referenceList: [],
    shiftByCaregiver: [],
    shiftByEmployer: [],
    shiftByFacility: [],
    facilityByEmployer: [],
    employersByFacility: [],
    exportHistoryList: [],
    pages: [],
    jobs: [],
    enquiry: [],
    review: [],
    featuredLocation: [],
    weeklyData: [],
    facilityList: [],
    allFacilityList: [],
    converterHistoryList:[]
  }
  errors = {}
  constructor() {
    makeAutoObservable(this, {
      facilityList: true,
      allFacilityList: true,
      settings: true
    });
    configure({
      useProxies: "never"
    })
  }
  toggleLoading = (toggle) => {
    this.loading = toggle
  }
  toggleLoadingLong = (toggle) => {
    this.loadingLong = toggle
  }

  async updateAsyncStore(data) {
    secureLocalStorage.removeItem('userData');
    secureLocalStorage.removeItem('token');
    secureLocalStorage.setItem("admin-token", data.token);
    secureLocalStorage.setItem("type", data.is_super);
    secureLocalStorage.setItem("adminData", JSON.stringify(data));
  }
  updateTokenFromStorage(data, token) {
    this.data.admintoken = token
    this.data.name = data.name
    this.data.email = data.email
    this.data.id = data.id
  }

  updatePage() {
    this.data.page = 1;
    this.data.pageSize = 25;
    this.data.lastPage = 0;
    this.data.total = 0;
    this.data.from = 0;
    this.data.to = 0;
    this.data.search = '';
  }
  async getRefreshToken(encrypted) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('/token', encrypted)
      if (response) {
        localStorage.setItem("refresh-token", response?.data?.access_token)
      } else {
        console.log("Failed...");
      }
    }
    catch (err) {
      console.log("error", err);
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async login(creds, navigationCallBack, data, token) {
    if (token) {
      setAuthTokenIntAdmin(token)
      this.updateTokenFromStorage(data, token)
    } else {
      this.toggleLoading(true);
      try {
        const response = await axiosInstanceInt.post('/admin/login', creds)
        this.updateAsyncStore(response.data)
        setAuthTokenIntAdmin(response.data.token)
        runInAction(() => {
          this.data.admintoken = response?.data?.token;
          this.data.email = response?.data?.email;
          this.data.name = response?.data.name;
          this.data.id = response?.data?.id;
        })
        navigationCallBack(response?.data?.is_super);
      }
      catch (err) {
        if (err?.response?.status !== 500) {
          if (err?.response?.data?.status === 0) {
            swal("Pending!", "Your account is under review. You will be notified via mail once activated.", "warning");
            return false
          }
          if (err?.response?.data?.status === 2) {
            swal("Rejected!", "Your account verification is rejected. Please contact Staffers administrator", "error");
            return false
          }
          if (err?.response?.data?.status === 3) {
            swal("Blocked!", "Your account has been temporarily disabled. Please contact the Administrator of Staffers.", "error");
            return false
          }
          if (!err?.response?.data?.status) {
            toast.error(err?.response?.data?.message);
          }
        } else {
          toast.error("Something went wrong!");
        }
      }
      finally {
        this.toggleLoading(false);
      }
    }
  }

  async fogotPassword(data, forgotPasswordCallback) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('admin/sendResetOtp', data);
      if (response.data.email) {
        toast.success("Successfully Sent OTP");
        this.loading = false
        forgotPasswordCallback(response.data.email);
      } else {
        toast.error("OTP not send!");
        this.loading = false

      }
    }
    catch (err) {
      toast.error(err?.response?.data?.message);
      console.log(err)
    }
    finally {
      this.loading = false
    }
  }

  async verifyResetOtp(data, navigationCallBackVerify) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('admin/verifyResetOtp', data);
      if (response.data.message === 'success') {
        toast.success("Successfully Verified");
        this.loading = false
        navigationCallBackVerify(response.data.email);
      } else {
        toast.error("Verification Failed!");
        this.loading = false

      }
    }
    catch (err) {
      toast.error(err?.response?.data?.message);
      console.log(err)
    }
    finally {
      this.loading = false
    }
  }

  async setNewPassword(data, navigationCallBackNewPassword) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('admin/setNewPassword', data);
      if (response.data.message === 'success') {
        toast.success("Successfully Reset Your Password");
        this.loading = false
        navigationCallBackNewPassword();
      } else {
        toast.error("Reset Password Failed!");
        this.loading = false

      }
    }
    catch (err) {
      toast.error(err?.response?.data?.message);
      console.log(err)
    }
    finally {
      this.loading = false
    }
  }

  async saveAdmin(data, navigationCallBack) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('admin/saveAdmin', data);
      if (response.status === 200) {
        toast.success(response?.data?.message);
        this.loading = false
        navigationCallBack();
      } else {
        this.loading = false

      }
    }
    catch (err) {
      toast.error(Object.values(err?.response?.data?.message)[0][0]);
    }
    finally {
      this.loading = false
    }
  }

  async updateAdmin(data, navigationCallBack) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('admin/updateAdmin', data);
      if (response.status === 200) {
        toast.success(response?.data?.message);
        this.loading = false
        navigationCallBack();
      } else {
        this.loading = false

      }
    }
    catch (err) {
      toast.error(Object.values(err?.response?.data?.message)[0][0]);
    }
    finally {
      this.loading = false
    }
  }

  async updateAdminById(data, navigationCallBack) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('admin/updateDetails', data);
      if (response.status === 200) {
        toast.success(response?.data?.message);
        this.loading = false
        navigationCallBack();
      } else {
        this.loading = false

      }
    }
    catch (err) {
      toast.error(Object.values(err?.response?.data?.message)[0][0]);
    }
    finally {
      this.loading = false
    }
  }

  async getAdminById() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.get('admin/getDetails');
      if (response.status === 200) {
        this.loading = false
        runInAction(() => {
          this.data.profileDetails = response?.data;
        })
      } else {
        this.loading = false

      }
    }
    catch (err) {
      console.log(err);
    }
    finally {
      this.loading = false
    }
  }

  async blockAdmin(data, navigationCallBackBlock) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('admin/blockAdmin', data);
      if (response.status === 200) {
        toast.success(response?.data?.message);
        this.loading = false
        navigationCallBackBlock();
      } else {
        this.loading = false

      }
    }
    catch (err) {
      toast.error(err?.response?.message);
      console.log(err)
    }
    finally {
      this.loading = false
    }
  }

  async deleteAdmin(data, navigationCallBackBlock) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('admin/deleteAdmin', data);
      if (response.status === 200) {
        toast.success(response?.data?.message);
        this.loading = false
        navigationCallBackBlock();
      } else {
        this.loading = false

      }
    }
    catch (err) {
      toast.error(err?.response?.message);
      console.log(err)
    }
    finally {
      this.loading = false
    }
  }

  //CLIENT
  async addClient(data, navigationCallBack) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('admin/addClient', data);
      if (response.status === 200) {
        toast.success(response?.data?.message);
        this.loading = false
        navigationCallBack();
      } else {
        this.loading = false
      }
    }
    catch (err) {
      toast.error(Object.values(err?.response?.data?.message)[0][0]);
    }
    finally {
      this.loading = false
    }
  }

  async blockClient(data, navigationCallBackBlock) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('admin/blockActivateClient', data);
      if (response.status === 200) {
        toast.success(response?.data?.message);
        this.loading = false
        navigationCallBackBlock();
      } else {
        this.loading = false

      }
    }
    catch (err) {
      toast.error(err?.response?.message);
      console.log(err)
    }
    finally {
      this.loading = false
    }
  }
  async deleteClient(data, navigationCallBackBlock) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('admin/deleteClient', data);
      if (response.status === 200) {
        toast.success(response?.data?.message);
        this.loading = false
        navigationCallBackBlock();
      } else {
        this.loading = false

      }
    }
    catch (err) {
      toast.error(Object.values(err?.response?.data?.message)[0][0]);
    }
    finally {
      this.loading = false
    }
  }

  async statusClient(data, navigationCallBackBlock) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('admin/approveRejectClient', data);
      if (response.status === 200) {
        toast.success(response?.data?.message);
        this.loading = false
        navigationCallBackBlock();
      } else {
        this.loading = false

      }
    }
    catch (err) {
      toast.error(err?.response?.message);
      console.log(err)
    }
    finally {
      this.loading = false
    }
  }

  async updateClient(data, navigationCallBack) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('admin/updateClient', data);
      if (response.status === 200) {
        toast.success(response?.data?.message);
        this.loading = false
        navigationCallBack();
      } else {
        this.loading = false

      }
    }
    catch (err) {
      toast.error(Object.values(err?.response?.data?.message)[0][0]);
    }
    finally {
      this.loading = false
    }
  }
  //FACILITY 
  async addFacility(data, navigationCallBack) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('admin/addFacility', data);
      if (response.status === 200) {
        toast.success(response?.data?.message);
        this.loading = false
        navigationCallBack();
      } else {
        this.loading = false
      }
    }
    catch (err) {
      toast.error(Object.values(err?.response?.data?.message)[0][0]);
    }
    finally {
      this.loading = false
    }
  }
  async updateFacility(data, navigationCallBack) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('admin/updateFacility', data);
      if (response.status === 200) {
        toast.success(response?.data?.message);
        this.loading = false
        navigationCallBack();
      } else {
        this.loading = false

      }
    }
    catch (err) {
      toast.error(Object.values(err?.response?.data?.message)[0][0]);
    }
    finally {
      this.loading = false
    }
  }
  async deleteFacility(data, navigationCallBackBlock) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('admin/deleteFacility', data);
      if (response.status === 200) {
        toast.success(response?.data?.message);
        this.loading = false
        navigationCallBackBlock();
      } else {
        this.loading = false

      }
    }
    catch (err) {
      toast.error(Object.values(err?.response?.data?.message)[0][0]);
    }
    finally {
      this.loading = false
    }
  }
  //STAFFS
  async blockStaff(data, navigationCallBackBlock) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('admin/blockActivateStaff', data);
      if (response.status === 200) {
        toast.success(response?.data?.message);
        this.loading = false
        navigationCallBackBlock();
      } else {
        this.loading = false

      }
    }
    catch (err) {
      toast.error(err?.response?.message);
      console.log(err)
    }
    finally {
      this.loading = false
    }
  }

  async deleteStaff(data, navigationCallBackBlock) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('admin/deleteStaff', data);
      if (response.status === 200) {
        toast.success(response?.data?.message);
        this.loading = false
        navigationCallBackBlock();
      } else {
        this.loading = false

      }
    }
    catch (err) {
      toast.error(err?.response?.message);
      console.log(err)
    }
    finally {
      this.loading = false
    }
  }

  async updateStaff(data, navigationCallBack) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('admin/updateStaff', data);
      if (response.status === 200) {
        toast.success(response?.data?.message);
        this.loading = false
        navigationCallBack();
      } else {
        this.loading = false

      }
    }
    catch (err) {
      toast.error(Object.values(err?.response?.data?.message)[0][0]);
    }
    finally {
      this.loading = false
    }
  }

  async getDashboard() {
    setAuthTokenIntAdmin()
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.get('admin/getDashboardStats')
      if (response) {
        runInAction(() => {
          this.data.dashboard = response?.data?.data;
        })
      } else {
      }
    }
    catch (err) {
      console.log("logineere", err)
    }
    finally {
      this.toggleLoading(false);
    }
  }


  async saveNotification(data, navigationCallBack) {
    setAuthTokenIntAdmin()
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('admin/saveNotification', data)
      if (response?.data?.message) {
        toast.success("Successfully sent!");
        navigationCallBack();
        this.toggleLoading(false);
      } else {
        console.log("else");
        this.toggleLoading(false);
      }
    }
    catch (err) {
      toast.error(Object.values(err?.response?.data?.message)[0][0]);
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async updateNotification(data, navigationCallBack) {
    setAuthTokenIntAdmin()
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('admin/updateNotification', data)
      console.log(response);
      if (response?.data?.message) {
        toast.success("Successfully updated!");
        navigationCallBack();
        this.toggleLoading(false);
      } else {
        console.log("else");
        this.toggleLoading(false);
      }
    }
    catch (err) {
      console.log("logineere", err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async statusNotification(data, navigationCallBack) {
    setAuthTokenIntAdmin()
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('admin/activateDeactivateNotification', data)
      if (response?.data?.message) {
        toast.success(response?.data?.message);
        navigationCallBack();
        this.toggleLoading(false);
      } else {
        console.log("else");
        this.toggleLoading(false);
      }
    }
    catch (err) {
      console.log("logineere", err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async getAdmin() {
    setAuthTokenIntAdmin()
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.get('admin/getAdminList')
      if (response) {
        runInAction(() => {
          this.data.adminList = response?.data?.data;
        })
      } else {
        console.log(response);
      }
    }
    catch (err) {
      console.log("logineere", err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async getFacility() {
    setAuthTokenIntAdmin()
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.get(`admin/getFacilityList?page=${this.data.page}&per_page=${this.data.pageSize}&search=${this.data.search}`)
      if (response) {
        runInAction(() => {
          this.data.facilityList = response?.data?.data?.data;
          this.data.page = response?.data?.data?.current_page;
          this.data.lastPage = response?.data?.data?.last_page;
          this.data.pageSize = response?.data?.data?.per_page;
          this.data.total = response?.data?.data?.total;
          this.data.from = response?.data?.data?.from;
          this.data.to = response?.data?.data?.to;
        })
      } else {
        console.log(response);
      }
    }
    catch (err) {
      console.log("logineere", err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async getClient() {
    setAuthTokenIntAdmin()
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.get(`admin/getClientList?page=${this.data.page}&per_page=${this.data.pageSize}&search=${this.data.search}`)
      if (response) {
        runInAction(() => {
          this.data.clientList = response?.data?.data?.data;
          this.data.page = response?.data?.data?.current_page;
          this.data.lastPage = response?.data?.data?.last_page;
          this.data.pageSize = response?.data?.data?.per_page;
          this.data.total = response?.data?.data?.total;
          this.data.from = response?.data?.data?.from;
          this.data.to = response?.data?.data?.to;
        })
      } else {
        console.log(response);
      }
    }
    catch (err) {
      console.log("logineere", err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async getAllClient() {
    setAuthTokenIntAdmin()
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.get(`admin/getEmployersList`)
      if (response) {
        runInAction(() => {
          this.data.allClientList = response?.data?.data;
        })
      } else {
        console.log(response);
      }
    }
    catch (err) {
      console.log("logineere", err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async getAllFacility() {
    setAuthTokenIntAdmin()
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.get(`admin/getAllFacilityList`)
      if (response) {
        runInAction(() => {
          this.data.allFacilityList = response?.data?.data;
        })
      } else {
        console.log(response);
      }
    }
    catch (err) {
      console.log("logineere", err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async getClientsByFacility(param) {
    setAuthTokenIntAdmin()
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.get(`admin/getClientsByFacility?facility_id=${param}`)
      if (response) {
        runInAction(() => {
          this.data.clientsByFacility = response?.data?.data;
        })
      } else {
        console.log(response);
      }
    }
    catch (err) {
      console.log("logineere", err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async getAllStaff() {
    setAuthTokenIntAdmin()
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.get(`admin/getEmployeesList`)
      if (response) {
        runInAction(() => {
          this.data.allStaffList = response?.data?.data;
        })
      } else {
        console.log(response);
      }
    }
    catch (err) {
      console.log("logineere", err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async getStaff() {
    setAuthTokenIntAdmin()
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.get(`admin/getStaffList?page=${this.data.page}&per_page=${this.data.pageSize}&search=${this.data.search}`)
      if (response) {
        runInAction(() => {
          this.data.staffList = response?.data?.data?.data;
          this.data.page = response?.data?.data?.current_page;
          this.data.lastPage = response?.data?.data?.last_page;
          this.data.pageSize = response?.data?.data?.per_page;
          this.data.total = response?.data?.data?.total;
          this.data.from = response?.data?.data?.from;
          this.data.to = response?.data?.data?.to;
        })
      } else {
        console.log(response);
      }
    }
    catch (err) {
      console.log("logineere", err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async getManageNotification() {
    setAuthTokenIntAdmin()
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.get(`admin/getNotificationList?page=${this.data.page}&per_page=${this.data.pageSize}&search=${this.data.search}`)
      if (response) {
        runInAction(() => {
          this.data.manageNotification = response?.data?.data?.data;
          this.data.page = response?.data?.data?.current_page;
          this.data.lastPage = response?.data?.data?.last_page;
          this.data.pageSize = response?.data?.data?.per_page;
          this.data.total = response?.data?.data?.total;
          this.data.from = response?.data?.data?.from;
          this.data.to = response?.data?.data?.to;
        })
      } else {
        console.log(response);
      }
    }
    catch (err) {
      console.log("logineere", err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async getNotificationsData() {
    setAuthTokenIntAdmin()
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.get('admin/getNotifications')
      if (response) {
        runInAction(() => {
          this.data.notifications = response?.data;
        })
      } else {
        console.log(response);
      }
    }
    catch (err) {
      if (err?.response?.status === 401) {
        TokenStore.clearAllAsyncStore();
        swal({
          title: "Session Expired",
          text: "Your Session Has Expired, Please Login Again!",
          icon: "warning"
        }).then((willReload) => {
          if (willReload) {
            window.location.reload();
          }
        });
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async getHireRequestList() {
    setAuthTokenIntAdmin()
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.get('admin/getHireRequestList')
      if (response) {
        runInAction(() => {
          this.data.hiringList = response?.data?.data;
        })
      } else {
        console.log(response);
      }
    }
    catch (err) {
      console.log("logineere", err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async getStaffingRequestList() {
    setAuthTokenIntAdmin()
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.get(`admin/getStaffingRequestList?page=${this.data.page}&per_page=${this.data.pageSize}&search=${this.data.search}&export="0"`)
      if (response) {
        runInAction(() => {
          this.data.page = response?.data?.data?.current_page;
          this.data.lastPage = response?.data?.data?.last_page;
          this.data.pageSize = response?.data?.data?.per_page;
          this.data.total = response?.data?.data?.total;
          this.data.from = response?.data?.data?.from;
          this.data.to = response?.data?.data?.to;
          this.data.staffingList = response?.data?.data?.data;
        })
      } else {
        console.log(response);
      }
    }
    catch (err) {
      console.log("Staffing", err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async getAllStaffingRequestList() {
    setAuthTokenIntAdmin()
    try {
      const response = await axiosInstanceInt.get(`admin/getStaffingRequestList?export=1`)
      if (response) {
        return response?.data?.data;
      } else {
        console.log(response);
      }
    }
    catch (err) {
      console.log("Staffing", err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async changeExportedStatus(param, fileName, csv, navigationCallBackExport) {
    setAuthTokenIntAdmin()
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post(`admin/markExportedSlots`, param)
      if (response) {
        navigationCallBackExport(fileName, csv)
      } else {
        console.log(response);
      }
    }
    catch (err) {
      console.log("Staffing", err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async saveExportFile(param) {
    setAuthTokenIntAdmin()
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post(`admin/saveExportFile`, param)
      if (response) {
        this.toggleLoading(false);
      } else {
        console.log(response);
      }
    }
    catch (err) {
      console.log("Staffing", err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async getExportList() {
    setAuthTokenIntAdmin()
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.get('admin/getExportList')
      if (response) {
        runInAction(() => {
          this.data.exportHistoryList = response?.data?.data;
        })
      } else {
        console.log(response);
      }
    }
    catch (err) {
      console.log("Staffing", err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async getSettings() {
    setAuthTokenIntAdmin()
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.get('admin/getSettingList')
      if (response) {
        runInAction(() => {
          this.data.settings = response?.data?.data;
        })
      } else {
        console.log(response);
      }
    }
    catch (err) {
      console.log("logineere", err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async saveSettings(data, navigationCallBack) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('admin/saveSetting', data);
      if (response.status === 200) {
        toast.success("Successfully updated!");
        this.loading = false
        navigationCallBack();
      } else {
        this.loading = false
      }
    }
    catch (err) {
      toast.error(err?.response?.message);
      console.log(err)
    }
    finally {
      this.loading = false
    }
  }

  async statusStaffingRequest(data, navigationCallBack) {
    setAuthTokenIntAdmin()
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('admin/changeStaffingRequestStatus', data)
      if (response?.data) {
        toast.success(response?.data?.message);
        navigationCallBack();
        this.toggleLoading(false);
      } else {
        this.toggleLoading(false);
      }
    }
    catch (err) {
      console.log("staffing", err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async updateSlotData(data, navigationCallBack) {
    setAuthTokenIntAdmin()
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('admin/updateSlotData', data)
      if (response?.data) {
        if (response?.data?.failed_slots > 0) {
          swal({
            title: 'Below specified shift(s) are currently not available for this caregiver,please allocate these shift to another available caregiver with same speciality.',
            content: (
              <div className='row' style={{ textAlign: 'start' }}>
                <h4>Unallocated Slots</h4>
                <ul className="list-unstyled ms-3">{response?.data?.failed_dates?.map((item, ind) => {
                  return <li className="text-danger" key={ind + "list"}>
                    <input className="slot" value={item?.date} readOnly />
                    <input style={{ lineHeight: 2, width: 100 }} type="time" readOnly value={item?.start} />
                    <span style={{ padding: 5, paddingBottom: 7, paddingTop: 6, borderLeftWidth: 0, borderRightWidth: 0 }} className="timer-form text-dark">To</span>
                    <input style={{ lineHeight: 2, width: 100 }} type="time" readOnly value={item?.end} className="timer-border" />
                  </li>
                })}</ul>
              </div>
            ),
            icon: 'info',
            backdrop: false
          });
          navigationCallBack();
        } else {
          navigationCallBack();
        }
        this.toggleLoading(false);
      } else {
        this.toggleLoading(false);
      }
    }
    catch (err) {
      console.log("staffing", err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async cancelSlot(data, navigationCallBack) {
    setAuthTokenIntAdmin()
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('admin/cancelSlot', data)
      if (response?.data) {
        toast.success("Slot Updated");
        navigationCallBack();
        this.toggleLoading(false);
      } else {
        this.toggleLoading(false);
      }
    }
    catch (err) {
      console.log("staffing", err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async statusHiringRequest(data, navigationCallBack) {
    setAuthTokenIntAdmin()
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('admin/changeHireRequestStatus', data)
      if (response?.data) {
        toast.success(response?.data?.message);
        navigationCallBack();
        this.toggleLoading(false);
      } else {
        this.toggleLoading(false);
      }
    }
    catch (err) {
      console.log("staffing", err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async getJobProfileRequests(loadingPage) {
    setAuthTokenIntAdmin()
    loadingPage(true);
    try {
      const response = await axiosInstanceInt.get(`admin/getJobProfiles?page=${this.data.page}&per_page=${this.data.pageSize}&search=${this.data.search}&status_id=${this.data.status_id}`)
      if (response) {
        runInAction(() => {
          this.data.page = response?.data?.data?.current_page;
          this.data.lastPage = response?.data?.data?.last_page;
          this.data.pageSize = response?.data?.data?.per_page;
          this.data.total = response?.data?.data?.total;
          this.data.from = response?.data?.data?.from;
          this.data.to = response?.data?.data?.to;
          this.data.jobProfileList = response?.data?.data?.data;
        })
      }
    }
    catch (err) {
      console.log("jobProfle", err)
    }
    finally {
      loadingPage(false);
    }
  }

  async extentionRespond(data, navigationCallBack) {
    setAuthTokenIntAdmin()
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('admin/changeHireRequestStatus', data)
      if (response?.data) {
        toast.success(response?.data?.message);
        navigationCallBack();
        this.toggleLoading(false);
      } else {
        this.toggleLoading(false);
      }
    }
    catch (err) {
      console.log("staffing", err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async readNotification(data, notify, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.post('/admin/readNotification', data)
      if (response) {
        navigationCallBack(notify);
      } else {
        console.log("Someting went wrong!");
      }
    }
    catch (err) {
      console.log("")
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async getStaffByProfession(param) {
    setAuthTokenIntAdmin()
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post(`admin/caregiverByProfession`, param)
      if (response) {
        runInAction(() => {
          this.data.staffListByProfession = response?.data?.data;
        })
      } else {
        console.log(response);
      }
    }
    catch (err) {
      console.log("logineere", err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async liveUpdateStatus(param, navigationCallBackLiveUpdate) {
    setAuthTokenIntAdmin()
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post(`admin/updateStaffStatus`, param)
      if (response) {
        navigationCallBackLiveUpdate()
      } else {
        console.log(response);
      }
    }
    catch (err) {
      console.log("logineere", err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async checkLiveStatusForAll() {
    setAuthTokenIntAdmin()
    this.toggleLoadingLong(true);
    try {
      const response = await axiosInstanceInt.get("admin/updateAllStatus")
      if (response) {
        // swal("Updated!", "Your whole job profile status has been changed, please see the job profile request table for further information.", "success");
      } else {
        console.log(response);
      }
    }
    catch (err) {
      console.log("logineere", err)
      this.toggleLoadingLong(false);
      toast.error("Please try again!");
    }
    finally {
      // this.toggleLoadingLong(false);
    }
  }

  async setStaffingRequest(data, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.post('/admin/saveStaffingRequest', data)
      if (response?.data) {
        toast.success("Successfully Added");
        navigationCallBack();
      } else {
        console.log("Someting went wrong!");
      }
    }
    catch (err) {
      if (err?.response?.status !== 500) {
        toast.error(Object.values(err?.response?.data?.message)[0][0]);
      } else {
        toast.error("Something went wrong!");
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async syncCaregiverWithBluesky(data, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.post('/admin/importCaregiver', data)
      if (response?.data) {
        navigationCallBack();
      } else {
        console.log("Someting went wrong!");
      }
    }
    catch (err) {
      if (err?.response?.status !== 500) {
        toast.error(Object.values(err?.response?.data?.message)[0][0]);
      } else {
        toast.error("Something went wrong!");
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async getPendingSlots() {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.get('/admin/getPendingSlots')
      if (response?.data) {
        this.data.pendingSlots = response?.data
      } else {
        console.log("Someting went wrong!");
      }
    }
    catch (err) {
      if (err?.response?.status !== 500) {
        toast.error(Object.values(err?.response?.data?.message)[0][0]);
      } else {
        toast.error("Something went wrong!");
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async getStaffPendingSlots() {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.get('/admin/getPendingSlotStaff')
      console.log(response?.data);
      if (response?.data) {
        this.data.staffPendingSlots = response?.data
      } else {
        console.log("Someting went wrong!");
      }
    }
    catch (err) {
      if (err?.response?.status !== 500) {
        toast.error(Object.values(err?.response?.data?.message)[0][0]);
      } else {
        toast.error("Something went wrong!");
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async updateSlots(param, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.post('/admin/updatePendingSlotStatus', param)
      if (response?.data) {
        navigationCallBack();
      } else {
        console.log("Someting went wrong!");
      }
    }
    catch (err) {
      if (err?.response?.status !== 500) {
        toast.error(Object.values(err?.response?.data?.message)[0][0]);
      } else {
        toast.error("Something went wrong!");
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async updateContract(param, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.post('/admin/updatePendingContractStatus', param)
      if (response?.data) {
        navigationCallBack();
      } else {
        console.log("Someting went wrong!");
      }
    }
    catch (err) {
      if (err?.response?.status !== 500) {
        toast.error(Object.values(err?.response?.data?.message)[0][0]);
      } else {
        toast.error("Something went wrong!");
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async cancelContract(param, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.post('/admin/cancelContract', param)
      if (response?.data) {
        navigationCallBack();
      } else {
        console.log("Someting went wrong!");
      }
    }
    catch (err) {
      if (err?.response?.status !== 500) {
        toast.error(Object.values(err?.response?.data?.message)[0][0]);
      } else {
        toast.error("Something went wrong!");
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async updateContractData(param, navigationCallBackTCEdit) {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.post('/admin/updateContractData', param)
      if (response?.data) {
        toast.success("Contract Updated");
        navigationCallBackTCEdit();
      } else {
        console.log("Someting went wrong!");
      }
    }
    catch (err) {
      if (err?.response?.status !== 500) {
        toast.error(Object.values(err?.response?.data?.message)[0][0]);
      } else {
        toast.error("Something went wrong!");
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
  //DNR API
  async getDnrList() {
    setAuthTokenIntAdmin()
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.get(`admin/getDnrList`)
      if (response) {
        runInAction(() => {
          this.data.dnrList = response?.data?.data;
        })
      } else {
        console.log(response);
      }
    }
    catch (err) {
      console.log("jobProfle", err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async addDnr(param, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.post('/admin/addDnr', param)
      if (response?.data) {
        toast.success("DNR Added Successfully");
        navigationCallBack();
      } else {
        console.log("Someting went wrong!");
      }
    }
    catch (err) {
      if (err?.response?.status !== 500) {
        toast.error(Object.values(err?.response?.data?.message)[0][0]);
      } else {
        toast.error("Something went wrong!");
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async removeDnr(param, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.post('/admin/removeDnr', param)
      if (response?.data) {
        toast.success("DNR Deleted Successfully");
        navigationCallBack();
      } else {
        console.log("Someting went wrong!");
      }
    }
    catch (err) {
      if (err?.response?.status !== 500) {
        toast.error(Object.values(err?.response?.data?.message)[0][0]);
      } else {
        toast.error("Something went wrong!");
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
  //ICS API
  async getStaffICS(param) {
    setAuthTokenIntAdmin()
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post(`admin/icsData`, param)
      if (response) {
        return response?.data?.data
      } else {
        console.log("Someting went wrong!");
      }
    }
    catch (err) {
      if (err?.response?.status !== 500) {
        toast.error(Object.values(err?.response?.data?.message)[0][0]);
      } else {
        toast.error("Something went wrong!");
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async addReferences(data, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.post('/admin/addReferences', data)
      if (response.status === 200) {
        toast.success("Reference Added Successfully");
        navigationCallBack();
      } else {
        console.log("Someting went wrong!");
      }
    }
    catch (err) {
      console.log("")
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async editReferences(data, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.post('/admin/editReferences', data)
      if (response.status === 200) {
        toast.success("Reference Updated Successfully");
        navigationCallBack();
      } else {
        console.log("Someting went wrong!");
      }
    }
    catch (err) {
      console.log("")
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async editReponse(data, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.post('/admin/editResponse', data)
      if (response.status === 200) {
        toast.success("Successfully Response Updated");
        navigationCallBack();
      } else {
        console.log("Someting went wrong!");
      }
    }
    catch (err) {
      console.log("")
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async getReferences() {
    this.toggleLoadingLong(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.get('/admin/getReferences')
      if (response.status === 200) {
        this.data.referenceList = response?.data?.data
      } else {
        console.log("other");
      }
    }
    catch (err) {
      console.log("error", err);
    }
    finally {
      this.toggleLoadingLong(false);
    }
  }
  async sendReferenceReminder(data) {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.post('/admin/sendReferenceReminder', data)
      if (response.status === 200) {
        toast.success("Sent Successfully");
      } else {
        console.log("Someting went wrong!");
      }
    }
    catch (err) {
      console.log("")
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async saveSignedDocuments(param, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.post('/admin/saveSignedDocuments', param)
      if (response.status === 200) {
        toast.success("Successfully Reference Updated on Bluesky");
        navigationCallBack(response?.data?.request_id)
      } else {
        console.log("other");
      }
    }
    catch (err) {
      console.log("error", err);
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async uploadSignedDocuments(param, navigationCallBackFinal) {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.post('/admin/uploadSignedDocuments', param)
      if (response.status === 200) {
        navigationCallBackFinal()
      } else {
        console.log("other");
      }
    }
    catch (err) {
      console.log("error", err);
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async uploadShiftCSV(param, navigationCallBackFinal) {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.post('/admin/importShifts', param)
      if (response.status === 200) {
        navigationCallBackFinal()
      } else {
        console.log("other");
      }
    }
    catch (err) {
      console.log("error", err);
    }
    finally {
      this.toggleLoading(false);
    }
  }
  //Shift By Caregiver
  async getShiftByCaregiver(param) {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.post('/admin/staffShiftsById', param)
      if (response.status === 200) {
        this.data.shiftByCaregiver = response?.data
      } else {
        console.log("other");
      }
    }
    catch (err) {
      console.log("error", err);
    }
    finally {
      this.toggleLoading(false);
    }
  }
  //Shift By Employer
  async getShiftByEmployer(param) {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.post('/admin/clientShiftsById', param)
      if (response.status === 200) {
        this.data.shiftByEmployer = response?.data?.data
        this.data.facilityByEmployer = response?.data?.facility
      } else {
        console.log("other");
      }
    }
    catch (err) {
      console.log("error", err);
    }
    finally {
      this.toggleLoading(false);
    }
  }
  //Shift By Facility
  async getShiftsByFacility(param) {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.get(`/admin/getShiftsByFacility?facility_id=${param}`)
      if (response.status === 200) {
        this.data.shiftByFacility = response?.data?.data;
        this.data.employersByFacility = response?.data?.users
      } else {
        console.log("other");
      }
    }
    catch (err) {
      console.log("error", err);
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async updateWorkPreference(param, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.post('/admin/updateWorkPreference', param)
      if (response?.data) {
        navigationCallBack();
      } else {
        console.log("Someting went wrong!");
      }
    }
    catch (err) {
      if (err?.response?.status !== 500) {
        toast.error(Object.values(err?.response?.data?.message)[0][0]);
      } else {
        toast.error("Something went wrong!");
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async unExportSlots(param, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.post('/admin/unExportSlots', param)
      if (response?.data) {
        navigationCallBack();
      } else {
        console.log("Someting went wrong!");
      }
    }
    catch (err) {
      if (err?.response?.status !== 500) {
        toast.error(Object.values(err?.response?.data?.message)[0][0]);
      } else {
        toast.error("Something went wrong!");
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
  //Update Slot Profession/Specality
  async editProfession(param, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.post('/admin/editProfession', param)
      if (response?.data) {
        toast.success("Prefession Changed Successfully");
        navigationCallBack();
      } else {
        console.log("Someting went wrong!");
      }
    }
    catch (err) {
      if (err?.response?.status !== 500) {
        toast.error(Object.values(err?.response?.data?.message)[0][0]);
      } else {
        toast.error("Something went wrong!");
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async updateAlert(values, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.post('/admin/clientNotification', values)
      if (response.status === 200) {
        navigationCallBack()
      } else {
        console.log("other");
      }
    }
    catch (err) {
      console.log("error", err);
      toast.error("Someting went wrong!");
    }
    finally {
      this.toggleLoading(false);
    }
  }
  //Website Manage
  async getPages() {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.get('/admin/getPages')
      if (response.status === 200) {
        runInAction(() => {
          this.data.pages = response?.data?.data;
        })
      } else {
        console.log("error ");
      }
    }
    catch (err) {
      console.log("error", err);
      toast.error("Someting went wrong!");
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async addPage(values, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.post('/admin/addPage', values)
      if (response.status === 200) {
        toast.success("Successfully added!");

        navigationCallBack()
      } else {
        console.log("other");
      }
    }
    catch (err) {
      console.log("error", err);
      toast.error("Someting went wrong!");
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async addSection(values, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.post('/admin/addSection', values)
      if (response.status === 200) {
        toast.success("Successfully added!");

        navigationCallBack()
      } else {
        console.log("other");
      }
    }
    catch (err) {
      console.log("error", err);
      toast.error("Someting went wrong!");
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async updateSection(values, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.post('/admin/editSection', values)
      if (response.status === 200) {
        toast.success("Successfully updated!");
        navigationCallBack()
      } else {
        console.log("other");
      }
    }
    catch (err) {
      console.log("error", err);
      toast.error("Someting went wrong!");
    }
    finally {
      this.toggleLoading(false);
    }
  }
  // JOBS MANAGE
  async getJobs() {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.get('/admin/getJob')
      if (response.status === 200) {
        runInAction(() => {
          this.data.jobs = response?.data?.data;
        })
      } else {
        console.log("error ");
      }
    }
    catch (err) {
      console.log("error", err);
      toast.error("Someting went wrong!");
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async addJob(values, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.post('/admin/addJob', values)
      if (response.status === 200) {
        toast.success("Successfully added!");

        navigationCallBack()
      } else {
        console.log("other");
      }
    }
    catch (err) {
      console.log("error", err);
      toast.error("Someting went wrong!");
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async updateJob(values, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.post('/admin/editJob', values)
      if (response.status === 200) {
        toast.success("Successfully updated!");
        navigationCallBack()
      } else {
        console.log("other");
      }
    }
    catch (err) {
      console.log("error", err);
      toast.error("Someting went wrong!");
    }
    finally {
      this.toggleLoading(false);
    }
  }
  //Enquiry
  async getEnquiry() {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.get('/admin/getEnquiry')
      if (response.status === 200) {
        runInAction(() => {
          this.data.enquiry = response?.data?.data;
        })
      } else {
        console.log("error ");
      }
    }
    catch (err) {
      console.log("error", err);
      toast.error("Someting went wrong!");
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async addEnquiry(param, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.post('/admin/addEnquiry', param)
      if (response.status === 200) {
        toast.success("Successfully added!");
        navigationCallBack()
      } else {
        console.log("error ");
      }
    }
    catch (err) {
      console.log("error", err);
      toast.error("Someting went wrong!");
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async updateEnquiry(param, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.post('/admin/editEnquiry', param)
      if (response.status === 200) {
        toast.success("Successfully updated!");
        navigationCallBack()
      } else {
        console.log("error ");
      }
    }
    catch (err) {
      console.log("error", err);
      toast.error("Someting went wrong!");
    }
    finally {
      this.toggleLoading(false);
    }
  }
  //Review
  async getReview() {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.get('/admin/getReview')
      if (response.status === 200) {
        runInAction(() => {
          this.data.review = response?.data?.data;
        })
      } else {
        console.log("error ");
      }
    }
    catch (err) {
      console.log("error", err);
      toast.error("Someting went wrong!");
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async addReview(values, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.post('/admin/addReview', values)
      if (response.status === 200) {
        toast.success("Successfully added!");
        navigationCallBack()
      } else {
        console.log("other");
      }
    }
    catch (err) {
      console.log("error", err);
      toast.error("Someting went wrong!");
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async updateReview(values, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.post('/admin/editReview', values)
      if (response.status === 200) {
        toast.success("Successfully updated!");
        navigationCallBack()
      } else {
        console.log("other");
      }
    }
    catch (err) {
      console.log("error", err);
      toast.error("Someting went wrong!");
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async getFeaturedLocation() {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.get('/admin/getFeaturedLocation')
      if (response.status === 200) {
        runInAction(() => {
          this.data.featuredLocation = response?.data?.data;
        })
      } else {
        console.log("error ");
      }
    }
    catch (err) {
      console.log("error", err);
      toast.error("Someting went wrong!");
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async addFeaturedLocation(values, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.post('/admin/addFeaturedLocation', values)
      if (response.status === 200) {
        toast.success("Successfully added!");
        navigationCallBack()
      } else {
        console.log("other");
      }
    }
    catch (err) {
      console.log("error", err);
      toast.error("Someting went wrong!");
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async updateFeaturedLocation(values, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.post('/admin/editFeaturedLocation', values)
      if (response.status === 200) {
        toast.success("Successfully updated!");
        navigationCallBack()
      } else {
        console.log("other");
      }
    }
    catch (err) {
      console.log("error", err);
      toast.error("Someting went wrong!");
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async getWeeklyData(path) {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.get(path)
      if (response.status === 200) {
        runInAction(() => {
          this.data.weeklyData = response?.data?.data;
        })
      } else {
        console.log("error ");
      }
    }
    catch (err) {
      console.log("error", err);
      toast.error("Someting went wrong!");
    }
    finally {
      this.toggleLoading(false);
    }
  }

  //Shift Rate API's
  async saveFacilityRate(values, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.post('/admin/saveRates', values)
      if (response.status === 200) {
        toast.success("Successfully Added!");
        navigationCallBack()
      } else {
        console.log("other");
      }
    }
    catch (err) {
      if (err?.response?.status !== 500) {
        toast.error(Object.values(err?.response?.data?.message)[0][0]);
      } else {
        toast.error("Something went wrong!");
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async updateFacilityRate(values, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.post('/admin/updateRates', values)
      if (response.status === 200) {
        toast.success("Successfully Updated!");
        navigationCallBack()
      } else {
        console.log("other");
      }
    }
    catch (err) {
      if (err?.response?.status !== 500) {
        toast.error(Object.values(err?.response?.data?.message)[0][0]);
      } else {
        toast.error("Something went wrong!");
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async deleteFacilityRate(values, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.post('/admin/deleteRates', values)
      if (response.status === 200) {
        toast.success("Successfully Deleted!");
        navigationCallBack()
      } else {
        console.log("other");
      }
    }
    catch (err) {
      if (err?.response?.status !== 500) {
        toast.error(Object.values(err?.response?.data?.message)[0][0]);
      } else {
        toast.error("Something went wrong!");
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async ubCheckRequest(values, navigationCallBackUBC) {
    // this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.post('/admin/ubCheckRequest', values)
      if (response) {
        navigationCallBackUBC(true)
      } else {
        console.log("other");
      }
    }
    catch (err) {
      navigationCallBackUBC(false)
    }
    finally {
      // this.toggleLoading(false);
    }
  }
  async weeklyReportDetails(param) {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.post('/admin/weeklyReportDetails', param)
      if (response.status === 200) {
        return response?.data?.data;
      } else {
        console.log("other");
      }
    }
    catch (err) {
      if (err?.response?.status !== 500) {
        toast.error(Object.values(err?.response?.data?.message)[0][0]);
      } else {
        toast.error("Something went wrong!");
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async saveConverterCSV(param,navigationCallBackImport) {
    setAuthTokenIntAdmin()
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post(`admin/saveConverterFile`, param)
      if (response) {
        this.toggleLoading(false);
        navigationCallBackImport()
      } else {
        console.log(response);
      }
    }
    catch (err) {
      console.log("Staffing", err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async getConverterCSV() {
    setAuthTokenIntAdmin()
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.get('admin/getConverterList')
      if (response) {
        runInAction(() => {
          this.data.converterHistoryList = response?.data?.data;
        })
      } else {
        console.log(response);
      }
    }
    catch (err) {
      console.log("Staffing", err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  //Create New Caregiver
  async createjobProfile(values, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.post('/admin/addNewCaregiver', values)
      if (response.status === 200) {
        toast.success("Successfully added!");
        navigationCallBack()
      } else {
        console.log("other");
      }
    }
    catch (err) {
      if (err?.response?.status !== 500) {
        toast.error(Object.values(err?.response?.data?.message)[0][0]);
      } else {
        toast.error("Something went wrong!");
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async getJobDetails(param) {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.post(`/admin/getJobDetails`, param)
      if (response.status === 200) {
        return response?.data?.data;
      } else {
        console.log("error ");
      }
    }
    catch (err) {
      console.log("error", err);
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async updateJobProfile(values, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenIntAdmin()
      const response = await axiosInstanceInt.post('/admin/updateCriticalData', values)
      if (response.status === 200) {
        toast.success("Successfully updated!");
        navigationCallBack()
      } else {
        console.log("other");
      }
    }
    catch (err) {
      if (err?.response?.status !== 500) {
        toast.error(Object.values(err?.response?.data?.message)[0][0]);
      } else {
        toast.error("Something went wrong!");
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
}


export default new AdminStore()
