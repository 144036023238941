import React from "react";
import { useNavigate } from "react-router-dom";

const FeaturedLocationCard = ({ locationData }) => {
  const navigate = useNavigate();
  return (
    <div>
      <div
        className="trend-img-box"
        onClick={() => {
          navigate(`/search-jobs/${locationData?.id}`);
        }}
      >
        <img
          src={`/assets/img/statepics/${
            (locationData?.state).toLowerCase() || "ar"
          }.png`}
          className="img-fluid"
          alt="trend"
        />
        <div className="inner-content">
          <h3>{`${locationData?.city}, ${locationData?.state}`}</h3>
          {/* <p>CT Technologist / CT Technologist</p> */}
        </div>
      </div>
    </div>
  );
};

export default FeaturedLocationCard;
