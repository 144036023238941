import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import FeaturedLocationCard from "./FeaturedLocationCard";
import { observer } from "mobx-react";
import WebsiteStore from "../../store/WebsiteStore";
import { toJS } from "mobx";

const TrendingJobsSlider = observer(() => {
  const [featuredLocationData, setFeaturedLocationData] = useState([]);

  useEffect(() => {
    if (WebsiteStore.data.featuredLocations?.length > 0) {
      setFeaturedLocationData(toJS(WebsiteStore.data.featuredLocations));
    }
  }, [WebsiteStore.data.featuredLocations]);

  useEffect(() => {
    WebsiteStore.getFeaturedLocations();
  }, []);

  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 560,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="trending-jobs-slide">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-3">
            <h3 className="common-web-head mt-3 text-center">
              Featured Locations
            </h3>
          </div>
          <div className="col-md-9">
            <Slider {...settings2}>
              {featuredLocationData?.map((locationData, index) => {
                return (
                  <FeaturedLocationCard
                    locationData={locationData}
                    key={locationData?.id || index}
                  />
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
});

export default TrendingJobsSlider;
