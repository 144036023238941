import React from "react";
import Header from '@components/PDFHeader';
import moment from "moment";

import { MultiDatePicker } from "@elements/index"
const BhtJobDescription = (props) => {
  return (
    <div id="form-content">
      <Header title="Behavioral health 
Technician - BHT" />
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="border-box">
                <div>
                  <div className="d-md-flex">
                    <table className="table table-all bordered">
                      <tbody>
                        <tr>
                          <td width="40%" className="head">
                            Job Title:
                          </td>
                          <td width="60%" className="input">
                            <input
                              type="text"
                              disabled
                              value="Behavioral Health Technician - BHT"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td width="40%" className="head">
                            Location:
                          </td>
                          <td width="60%" className="input">
                            <input type="text" value={"Utah - Staffers"} disabled onChange={(e) => props.handleInputChange('location', e.target.value)} />
                          </td>
                        </tr>
                        <tr>
                          <td width="40%" className="head">
                            Effective Date:
                          </td>
                          <td width="60%" className="input">
                            {/* <input type="date" value={props.inputValues.EffectiveDate} onChange={(e) => props.handleInputChange('EffectiveDate', e.target.value)} /> */}
                            <MultiDatePicker required={false} multiple={false} value={props.inputValues.EffectiveDate} onChange={(e) => props.handleInputChange('EffectiveDate', e)} />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="table table-all bordered">
                      <tbody>
                        <tr>
                          <td width="40%" className="head">
                            Department
                          </td>
                          <td width="60%" className="input">
                            <input type="text" disabled value={"Nursing"} onChange={(e) => props.handleInputChange('Department', e.target.value)} />
                          </td>
                        </tr>
                        <tr>
                          <td width="40%" className="head">
                            FLSA Status:
                          </td>
                          <td width="60%" className="input">
                            <input type="text" value={props.inputValues.FLSA} onChange={(e) => props.handleInputChange('FLSA', e.target.value)} />
                          </td>
                        </tr>
                        <tr>
                          <td width="40%" className="head">
                            Revised Date:
                          </td>
                          <td width="60%" className="input">
                            <input type="text" value={props.inputValues.Revised} onChange={(e) => props.handleInputChange('Revised', e.target.value)} />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <table className="table table-all bordered">
                    <tbody>
                      <tr>
                        <td className="head width-set-h">Reporting to:</td>
                        <td className="input width-set-i">
                          <input type="text" value={props.inputValues.Reportingto} onChange={(e) => props.handleInputChange('Reportingto', e.target.value)} />
                        </td>
                      </tr>
                      <tr>
                        <td className="head width-set-h">Date of Hire:</td>
                        <td className="input width-set-i">
                          {/* <input type="date" value={props.inputValues.DOH} onChange={(e) => props.handleInputChange('DOH', e.target.value)} /> */}
                          <MultiDatePicker required={false} multiple={false} value={props.inputValues.DOH} onChange={(e) => props.handleInputChange('DOH', e)} />

                        </td>
                      </tr>
                      <tr>
                        <td className="head width-set-h">Employee Name:</td>
                        <td className="input width-set-i">
                          <input required value={props.inputValues.full_name} placeholder="Full Name" type="text" minLength={2} maxLength={20} name="full name" onChange={(e) => props.handleInputChange('full_name', e.target.value)} />

                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="back-grad grey">
                  <h2 className="heading-line">JOB SUMMARY:</h2>
                  <span className="bold-para">
                    Responsible for providing personal care services to patients
                    at the facility under the direction of the Director of
                    Nursing.
                  </span>
                </div>
                <div className="back-grad">
                  <h3 className="same-head">
                    <span>E</span>ssential job functions
                  </h3>
                  <h4 className="same-small-head">ESSENTIAL FUNCTIONS:</h4>

                  <ul>
                    <li>
                      <span>
                        Ensure the well-being of patients and provide a
                        positive, supportive and structured environment.
                      </span>
                    </li>
                    <li>
                      <span>
                        Responsible for conducting safety checks and ensuring
                        that supervision is conducted at 15 minute intervals, as
                        noted in special precautions, or in accordance with
                        individualized supervision guidelines as needed.
                      </span>
                    </li>
                    <li>
                      <span>
                        Document timely, accurate and appropriate clinical
                        information in patient's medical record.
                      </span>
                    </li>
                    <li>
                      <span>
                        Assist in providing a safe, secure and comfortable
                        environment for patients, significant others and staff.
                      </span>
                    </li>
                    <li>
                      <span>
                        Interact routinely with patients, observe behaviors and
                        communicate significant observations to nursing staff.
                      </span>
                    </li>
                    <li>
                      <span>
                        Oversee or assist patients with activities of daily
                        living, including toileting, bathing, dressing,
                        grooming, oral hygiene, meals, snacks, and hydration and
                        changing bed linens.
                      </span>
                    </li>
                    <li>
                      <span>
                        May obtain patient's vital signs, height and weight as
                        assigned and document in patient record.
                      </span>
                    </li>
                    <li>
                      <span>
                        Facilitate patient educational-rehabilitative groups
                        which cover a variety of topics including social skills,
                        coping skills, anger management and independent living
                        skills.
                      </span>
                    </li>
                    <li>
                      <span>
                        Engage patients in activities and interactions designed
                        to encourage achievement of treatment goals.
                      </span>
                    </li>
                    <li>
                      <span>
                        Complete and maintain required documentation. Assist
                        with follow-up and paperwork as required on incidents
                        and events that may take place in the facility.
                      </span>
                    </li>
                    <li>
                      <span>
                        May provide transportation for patient or coordinate
                        transportation with appropriate staff member.
                      </span>
                    </li>
                  </ul>

                  <h4 className="same-small-head">OTHER FUNCTIONS:</h4>
                  <ul>
                    <li>
                      <span>Perform other functions and tasks as assigned.</span>
                    </li>
                  </ul>
                  <br></br>
                  <h4 className="same-small-head">
                    EDUCATION/EXPERIENCE/SKILL REQUIREMENTS:
                  </h4>
                  <ul>
                    <li>
                      <span>
                        High school diploma or equivalent required. Prefer one
                        year experience working with the specific population of
                        the facility.
                      </span>
                    </li>
                  </ul>
                  <br></br>
                  <h4 className="same-small-head">
                    LICENSES/DESIGNATIONS/CERTIFICATIONS:
                  </h4>
                  <ul>
                    <li>
                      <span>
                        First Aid, CPR, de-escalation and restraint
                        certification required (training available upon hire and
                        offered by facility). May require a valid state driver's
                        in facilities where transporting patient is an essential
                        function.
                      </span>
                    </li>
                  </ul>
                  <br></br>

                  <h2 className="heading-line">JOB DESCRIPTION REVIEW:</h2>
                  <span>
                    I understand this job description and its requirements, and
                    that I am expected to complete all duties as assigned. I
                    understand the job functions may be altered from time to
                    time. I further understand that the purpose of this job
                    description is to identify the major duties and
                    responsibilities of the job and this is not intended to
                    contain each and every inherent in this job. I have noted
                    below any accommodations that are required to enable me to
                    perform these duties. I have also noted below any job
                    responsibilities or functions which I am unable to perform,
                    with or without accommodation.
                  </span>
                </div>
                <table className="table table-all bordered">
                  <tbody>
                    <tr>
                      <td width="20%" className="head not-bold">
                        Employee's<br></br> Signature
                      </td>
                      <td className="input" width="20%" >
                        <div>{props?.signature ? <img src={props?.signature} width={180} height={50} className="w-100 rounded-0" alt="Signature" /> : <div class="badge badge-primary text-wrap pointer" onClick={props?.onOpenModal}>
                          Click Here To Sign
                        </div>}</div>
                      </td>
                      <td width="20%" className="head not-bold">
                        Date:
                      </td>
                      <td className="input" width="20%" >
                        <div><input type="text" value={moment(new Date()).format("MM-DD-YYYY HH:mm:ss")} disabled /></div>
                      </td>
                    </tr>
                    <tr>
                      <td width="20%" className="head not-bold">
                        Supervisor's<br></br> Signature
                      </td>
                      <td className="input">
                        <div></div>
                      </td>
                      <td width="20%" className="head not-bold">
                        Date:
                      </td>
                      <td className="input">
                        <div></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="border-box mt-3">
                <div className="back-grad">
                  <p className=" para-underline">Accomodation Needed:</p>
                  <p className=" para-underline">
                    Job Responsibilities I am unable to perform:
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BhtJobDescription;
