import React, { useState, useRef, useEffect } from 'react';
import { CButton } from "@coreui/react";
import StaffsStore from "@store/StaffsStore";
import UserStore from "@store/UserStore";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import secureLocalStorage from "react-secure-storage";
import { useLocation, useNavigate } from 'react-router-dom';
import ReactDOM from "react-dom";
import { Helmet } from 'react-helmet';
import Header from '@components/Header';
import Footer from '@components/Footer';
import moment from 'moment';
import Spinner from "@components/Spinner";
const $ = window.$;

const NotificationPage = observer(() => {
    window.onscroll = function () { };
    const params = useLocation();
    const tableRef = useRef()
    const tableName = "table1"
    const [notificationsData, setNotificationData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [signleData, setSingleData] = useState();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        navigate(location.pathname, {});
        if (params?.state?.item) {
            showDetails(params?.state?.item)
        }
    }, []);

    useEffect(() => {
        setNotificationData(toJS(UserStore?.data?.notifications))
    }, [UserStore?.data?.notifications]);

    useEffect(() => {
        setNotificationData(toJS(StaffsStore?.data?.notifications))
    }, [StaffsStore?.data?.notifications]);

    useEffect(() => {
        $.fn.dataTable.ext.errMode = 'none';
        $($.fn.dataTable.tables(true)).DataTable()
            .columns.adjust();
        const table = $(`#${tableName}`).DataTable(
            {
                data: notificationsData,
                order: [], // Disable initial sorting
                columns: [
                    {
                        title: "Sr", data: 'id', "render": function (data, type, full, meta) {
                            return meta.row + 1;
                        }
                    },
                    {
                        title: "Heading", data: "data",  "render": function (data, type, full, meta) {
                            return data ? JSON.parse(data)?.data?.heading : "-";
                        }
                    },
                    {
                        title: "Subject", data: 'data',  "render": function (data, type, full, meta) {
                            return data ? JSON.parse(data)?.data?.subject : "-";;
                        }
                    }, 
                    {
                        title: "Content",data: "data", "render": function (data, type, full, meta) {
                            return "-";
                        }
                    },

                    {
                        title: "Link", data: "data", "render": function (data, type, full, meta) {
                            return data ? JSON.parse(data)?.data?.link ? `<a href="${JSON.parse(data)?.data?.link}" target="_blank">${JSON.parse(data)?.data?.link}</a>` : "-" : "-";
                        }
                    },
                    {
                        title: "Time", data: "created_at", "render": function (data, type, full, meta) {
                            return moment(data).fromNow();
                        }
                    },

                    {
                        title: "Action"
                    },
                ],
               
                columnDefs: [
                    {
                        targets: 1,
                        responsivePriority: 2,
                    },
                    {
                        targets: 2,
                        responsivePriority: 3,
                    },
                    {
                        targets: 6,
                        responsivePriority: 1,
                        createdCell: (td, cellData, rowData, row, col) =>
                            ReactDOM.render(
                                <div>
                                    <CButton onClick={() => {
                                        showDetails(rowData)
                                    }} size="sm" className="w-100" variant={rowData?.read_at ? 'outline' : ""} color="primary" >
                                        {rowData?.read_at ? <i class="far fa-envelope-open"></i> : <i class="far fa-envelope"></i>} Open
                                    </CButton>
                                </div>
                                , td),
                    }
                ],
                destroy: true,  // I think some clean up is happening here
                searching: true,
                "language": {
                    search: '',
                    searchPlaceholder: "Search",
                    emptyTable: "No Record Found"
                },
                "rowCallback": function (row, data) {
                    // Add your condition here
                    if (!data.read_at) {
                        $(row).addClass('highlight'); // Apply the CSS class to highlight the row
                    }
                },
                initComplete: function () {
                    // Apply the search
                    this.api()
                        .columns([0, 1, 2, 3, 4])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><input class="form-control " style="width:100%;margin-top:5px" type="text" placeholder="Filter" />')
                                .appendTo($(column.header()))

                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $('input', this.header()).on('keyup change clear', function () {
                                if (column.search() !== this.value) {
                                    column.search(this.value).draw();
                                }
                            });
                        });

                },

            }
        )
        table.column(3).visible(false);
        // Extra step to do extra clean-up.
        return function () {
            table.destroy()
        }
    }, [notificationsData])

    const showDetails = (item) => {
        setSingleData(item);
        setIsOpen(true);
    }
    const onDismiss = () => {
        if (!signleData?.read_at) {
            let param = {
                "notification_id": signleData?.id
            }
            if (secureLocalStorage.getItem("type") == "false") {
                UserStore.readNotification(param, null, navigationCallBack)
            } else if (secureLocalStorage.getItem("type") == "true") {
                StaffsStore.readNotification(param, null, navigationCallBack)
            }
        }
        setIsOpen(false)
    }
    const navigationCallBack = () => {
        if (secureLocalStorage.getItem("type") == "false") {
            UserStore.getNotificationsData();
        } else if (secureLocalStorage.getItem("type") == "true") {
            StaffsStore.getNotificationsData();
        }
        setSingleData()
    }
    return (
        <React.Fragment>
            <Header />
            <Helmet>
                <title>Staffers LLC | Notifications</title>
            </Helmet>
            <div className="container-fluid callback mt-5 py-5">
                <div className="container pt-lg-5">
                    <h5 className="display-5 mb-4 animated slideInDown login-head">
                        Notification</h5>
                </div>
                <div className="col-lg-12">
                    <div className="row bg-white" style={{ paddingTop: 50 }}>
                        <table className="table dt-responsive table-hover " style={{ width: "100%" }} id={tableName} ref={tableRef}></table>
                    </div>
                </div>
            </div>
            <BottomSheet open={isOpen} onDismiss={onDismiss} scrollLocking={false}
                header={
                    <>
                        <div className="text-end" style={{ cursor: "pointer" }} onClick={onDismiss}>
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </div>
                        <h4 className="flex items-center text-xl justify-center font-bold text-primary-800">
                            {signleData?.data ? JSON.parse(signleData?.data)?.data?.heading : "NOTIFICATION"}
                        </h4>
                    </>
                }>
                <div style={{ height: 'auto', overflowY: 'scroll' }}>
                    <div class="col-xl-12 col-md-12">
                        <div class="card user-card-full">
                            <div class="row m-l-0 m-r-0">
                                <div class="col-sm-12">
                                    <div class="card-block">
                                        <div className='d-flex justify-content-between align-items-center b-b-default p-b-5'>
                                            <h6 class="f-w-600">Notification Details</h6>
                                            {signleData?.read_at && <h5><span class="badge badge-primary"> {moment(signleData?.read_at).fromNow()}</span></h5>}
                                        </div>
                                        <div class="row pt-3">
                                            {signleData && signleData?.data && <div class="col-sm-3">
                                                <p class="m-b-10 f-w-600">Subject</p>
                                                <h6 class="text-muted f-w-400">{JSON.parse(signleData?.data)?.data?.subject || "-"}</h6>
                                            </div>}
                                            {signleData && signleData?.data && JSON.parse(signleData?.data)?.data?.link && <div class="col-sm-3">
                                                <p class="m-b-10 f-w-600">Link</p>
                                                <a href={`${JSON.parse(signleData?.data)?.data?.link}`} target="_blank"><u className="f-w-400">{JSON.parse(signleData?.data)?.data?.link || "-"}</u></a>
                                            </div>}
                                            {signleData && signleData?.data && JSON.parse(signleData?.data)?.data?.content && Object.keys(JSON.parse(signleData?.data)?.data?.content).length > 0 &&<div class="col-sm-12">
                                                <p class="m-b-10 f-w-600">Content</p>
                                                <div dangerouslySetInnerHTML={{ __html: JSON.parse(signleData?.data)?.data?.content }} />
                                            </div>}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BottomSheet>
            <Footer />
            <Spinner isLoading={StaffsStore.loading || UserStore.loading} />
        </React.Fragment>
    );
});

export default NotificationPage;