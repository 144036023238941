/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react"
import HeaderPage from "../layout/HeaderPage";
import DrawerMenuPage from "../layout/DrawerMenuPage";
import { CButton } from "@coreui/react";
import './DNRReportList.css'
import AdminStore from "@store/AdminStore";
import { toJS } from "mobx";
import swal from '@sweetalert/with-react';
import { CModal, CModalHeader, CModalTitle } from "@coreui/react";
import { Formik } from "formik";
import Spinner from "@components/Spinner";
import ReactDOM from "react-dom";
import moment from "moment";
import { colourStylesState } from '@utils/customStyle/style';
import { dnrSchema } from '@utils/schemas/allSchema';
import Select, { components } from 'react-select';
import { Helmet } from "react-helmet";
const $ = window.$;

const DNRReportList = observer(() => {

    const tableRef = useRef()
    const tableName = "table1"

    const formikRef = useRef(null);
    const [dnrList, setDNRList] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [paginationLength, setPaginationLength] = useState(25);
    const [inputSearch, setInputSearch] = useState('');
    const [clientList, setClientList] = useState([]);
    const [staffList, setStaffList] = useState([]);

    useEffect(() => {
        AdminStore.updatePage();
        AdminStore.getDnrList();
    }, []);

    useEffect(() => {
        setDNRList(toJS(AdminStore?.data?.dnrList))
    }, [AdminStore?.data?.dnrList]);

    useEffect(() => {
        setClientList(toJS(AdminStore?.data?.allFacilityList))
    }, [AdminStore?.data?.allFacilityList]);

    useEffect(() => {
        setStaffList(toJS(AdminStore?.data?.allStaffList))
    }, [AdminStore?.data?.allStaffList]);

    useEffect(() => {
        $.fn.dataTable.ext.errMode = 'none';
        $($.fn.dataTable.tables(true)).DataTable()
            .columns.adjust();
        const table = $(`#${tableName}`).DataTable(
            {
                data: dnrList,
                order: [], // Disable initial sorting
                dom: 'Blfrtip',
                buttons: [
                    'copy', 'csv', 'excel', 'print',
                ],
                pageLength: paginationLength,
                lengthMenu: [25, 50, 100, 500, 1000],
                columns: [
                    {
                        title: "Facility Id", data: "facility_id", "render": function (data, type, row, meta) {
                            return data || "-";
                        }
                    },
                    {
                        title: "Facility", "render": function (data, type, row, meta) {
                            return row?.facility?.organization_name || "-";
                        }
                    },
                    {
                        title: "Facility Address", "render": function (data, type, row, meta) {
                            return (row?.facility?.address + ", " + row?.facility?.city + ", " + row?.facility?.state) || "-";
                        }
                    },
                    {
                        title: "Bluesky Id", "render": function (data, type, row, meta) {
                            return row?.staff?.bluesky_staff_id || "-";
                        }
                    },
                    {
                        title: "Caregiver Name", "render": function (data, type, row, meta) {
                            return (row?.staff?.first_name + " " + row?.staff?.last_name) || "-";
                        }
                    },
                    {
                        title: "Caregiver Email", "render": function (data, type, row, meta) {
                            return row?.staff?.email || "-";
                        }
                    },
                    {
                        title: "Caregiver Contact", "render": function (data, type, row, meta) {
                            return row?.staff?.contact || "-";
                        }
                    },
                    {
                        title: "Comment", data: "comment", "render": function (data, type, row, meta) {
                            return data || "-";
                        }
                    },
                    {
                        title: "Last Updated By", width: 100, data: 'last_updated_by', "render": function (data, type, row, meta) {
                            return data?.first_name ? (data?.first_name + " " + data?.last_name) : "-"
                        }
                    },
                    {
                        title: "Last Update", width: 100, data: 'updated_at', "render": function (data, type, row, meta) {
                            return moment(data).fromNow() || "-";
                        }
                    },

                    {
                        title: "Action"
                    },
                ],
                columnDefs: [
                    {
                        targets: 8,
                        responsivePriority: 2,
                    },
                    {
                        targets: 0,
                        responsivePriority: 3,
                    },
                    {
                        targets: 10,
                        responsivePriority: 1,
                        createdCell: (td, cellData, rowData, row, col) =>
                            ReactDOM.render(
                                <div>
                                    <CButton
                                        onClick={() => {
                                            onRemove(rowData.id)
                                        }}
                                        size="sm" className="w-100" variant='outline' color="danger">
                                        Delete <i className="fas fa-trash"></i>
                                    </CButton>
                                </div>
                                , td),
                    }
                ],
                destroy: true,  // I think some clean up is happening here
                searching: true,
                "language": {
                    search: '',
                    searchPlaceholder: "Search",
                    emptyTable: "No Record Found",
                },
                initComplete: function () {
                    // Apply the search
                    this.api()
                        .columns([0, 1, 2, 3, 4, 5, 6, 7, 8, 9])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><input class="form-control " style="width:100%;margin-top:5px" type="text" placeholder="Filter per page" />')
                                .appendTo($(column.header()))

                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $('input', this.header()).on('keyup change clear', function () {
                                if (column.search() !== this.value) {
                                    column.search(this.value).draw();
                                }
                            });
                        });


                },
            });
        // Extra step to do extra clean-up.
        return function () {
            $(`#${tableName}`).off('length.dt');
            table.destroy()
        }
    }, [dnrList])
    const handlePaginationLengthChange = (newPageSize) => {
        setPaginationLength(newPageSize)
        AdminStore.data.pageSize = newPageSize;
        AdminStore.getClient();
    };
    const handleChange = (event) => {
        setInputSearch(event.target.value);
        AdminStore.data.search = event.target.value;
    };
    const onSearch = () => {
        AdminStore.getDNRReport();
    }
    const onClearSearch = () => {
        setInputSearch('');
        AdminStore.data.search = '';
        AdminStore.getClient();
    }
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            onSearch();
        }
    };

    const onOpenForm = () => {
        if (clientList?.length === 0) {
            AdminStore.getAllFacility()
        }
        if (staffList?.length === 0) {
            AdminStore.getAllStaff();
        }
        setIsOpen(true)
    }
    const onDismiss = () => {
        if (formikRef.current) {
            formikRef.current.resetForm();
        }
        setIsOpen(false)
    }
    const addDNR = (values) => {
        AdminStore.addDnr(values, navigationCallBack)
    }
    const navigationCallBack = () => {
        if (formikRef.current) {
            formikRef.current.resetForm();
        }
        setIsOpen(false);
        AdminStore.getDnrList();
    }
    const onRemove = (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this DNR!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    AdminStore.removeDnr({ record_id: id }, navigationCallBack)
                }
            });
    }
    return (
        <React.Fragment>
            <Helmet>
                <title>Staffers LLC | DNR Report List</title>
            </Helmet>
            {/* <body> */}
            <div className="container-scroller">
                <HeaderPage />
                <div className="container-fluid page-body-wrapper">
                    <DrawerMenuPage />
                    <div className="main-panel" style={{ right: 0, position: "absolute" }}>
                        <div className="content-wrapper p-3 bg-white ">
                            <h3 className="page-title">
                                <span className="page-title-icon linear text-white me-2">
                                    <i className="fas fa-user-friends"></i>
                                </span> DNR Records
                            </h3>
                            <div onClick={() => onOpenForm()} className='col-12 d-flex justify-content-lg-end pt-lg-0 pt-2'>
                                <h1 className="page-title signup-link fs-4" >
                                    <span className="page-title-icon linear text-white me-2">
                                        <i className="fas fa-plus"></i>
                                    </span>Add New DNR
                                </h1>
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <div className="row bg-white table-responsive" style={{ paddingTop: 20 }}>
                                <div className="table-wrapper">
                                    <table className="table dt-responsive table-hover nowrap" style={{ width: "100%" }} id={tableName} ref={tableRef}>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <!-- main-panel ends --> */}
                </div>
                {/* <!-- page-body-wrapper ends --> */}
            </div>
            <Spinner isLoading={AdminStore.loading} />
            <CModal visible={isOpen} onClose={() => onDismiss()} backdrop="static">
                <CModalHeader onClose={() => onDismiss()}>
                    <CModalTitle>Add DNR</CModalTitle>
                </CModalHeader>
                {/* <CModalBody> */}
                <Formik
                    innerRef={formikRef}
                    initialValues={{ facility_id: "", staff_id: "", comment: "" }}
                    onSubmit={values => addDNR(values)}
                    validationSchema={dnrSchema}
                >
                    {
                        ({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched
                        }) =>
                        (
                            <form onSubmit={handleSubmit}>=
                                <div className="modal-body p-4">
                                    <div className="row g-2">
                                        <div className="pb-2 col-sm-12">
                                            <Select
                                                label="Select an Facility"
                                                isLoading={clientList?.length === 0}
                                                options={clientList} // Assuming clientList is an array of objects with 'value' and 'label' properties
                                                onChange={(selectedOption) => {
                                                    setFieldValue("facility_id", selectedOption?.id);
                                                }}
                                                value={clientList.find((option) => option.id === values.facility_id) || null} // Set to null if no match is found
                                                isRequired={true}
                                                components={{ Control }}
                                                styles={colourStylesState} // Assuming colourStylesState is defined
                                                getOptionLabel={(option) => option.organization_name}
                                                getOptionValue={(option) => option.id}
                                            />
                                            {errors.facility_id && touched.facility_id ? (
                                                <div className="error-message">{errors.facility_id}</div>
                                            ) : null}
                                        </div>
                                        <div className="pb-2 col-sm-12">
                                            <Select
                                                label="Select a Caregiver"
                                                options={staffList}
                                                isLoading={staffList?.length === 0}
                                                onChange={(selectedOption) => {
                                                    setFieldValue("staff_id", selectedOption ? selectedOption.id : null);
                                                }}
                                                value={staffList.find((option) => option.id === values.staff_id) || null}
                                                getOptionLabel={(option) => `${option.first_name} - ${option.last_name} - ${option.bluesky_staff_id}`}
                                                getOptionValue={(option) => option.id}
                                                isRequired={true}
                                                components={{ Control }}
                                                styles={colourStylesState} // Assuming colourStylesState is defined
                                            />
                                            {errors.staff_id && touched.staff_id ? (
                                                <div className="error-message">{errors.staff_id}</div>
                                            ) : null}
                                        </div>
                                        <div className="pb-2 col-sm-12">
                                            <label className="pb-2 label">Comment</label>
                                            <div className="">
                                                <textarea className="form-control"
                                                    placeholder=""
                                                    name={'comment'}
                                                    value={values.comment}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    style={{ height: 100 }}>

                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-center">
                                    <button type="submit" className="btn btn-success w-25">Submit</button>
                                </div>
                            </form>
                        )
                    }
                </Formik>
                {/* </CModalBody> */}
            </CModal>
            {/* </body> */}
        </React.Fragment>
    );
})
export const Control = (props) => {
    return (
        <>
            <label className={props.hasValue || props.isFocused ? props?.selectProps?.isRequired ? "did-floating-label did-floating-label-top did-floating-labe-astrisk" : "did-floating-label did-floating-label-top" : props?.selectProps?.isRequired ? "did-floating-label did-floating-label did-floating-labe-astrisk" : "did-floating-label did-floating-label"} style={{ zIndex: 100 }}>{props?.selectProps?.label}</label>
            <components.Control {...props} />
        </>
    );
};

export default DNRReportList;
