/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react"
import HeaderPage from "../layout/HeaderPage";
import DrawerMenuPage from "../layout/DrawerMenuPage";
import { CFormLabel, CSmartTable } from '@coreui/react-pro'
import { CButton, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem } from "@coreui/react";
import './SubAdminList.css'
import AdminStore from "@store/AdminStore";
import { toJS } from "mobx";
import moment from "moment";
import Spinner from "@components/Spinner";
import { useNavigate } from "react-router-dom";
import ReactDOM from "react-dom";
import { Helmet } from "react-helmet";
const $ = window.$;
const SubAdminList = observer(() => {
    const tableRef = useRef()
    const tableName = "table1"
    const navigate = useNavigate();
    const [adminList, setAdminList] = useState([]);
    const [paginationLength, setPaginationLength] = useState(100);

    useEffect(() => {
        AdminStore.getAdmin();
    }, []);

    useEffect(() => {
        setAdminList(toJS(AdminStore?.data?.adminList))
    }, [AdminStore?.data?.adminList]);

    useEffect(() => {
        $.fn.dataTable.ext.errMode = 'none';
        $($.fn.dataTable.tables(true)).DataTable()
            .columns.adjust();
        const table = $(`#${tableName}`).DataTable(
            {
                data: adminList,
                order: [], // Disable initial sorting
                dom: 'Blfrtip',
                buttons: [
                    'copy', 'csv', 'excel', 'print',
                ],
                pageLength: paginationLength,
                lengthMenu: [100,500,1000,2000,3000,5000],              
                columns: [
                    {
                        title: "Sr", data: 'id', width: 50, "render": function (data, type, full, meta) {
                            return ((AdminStore.data.page - 1) * paginationLength + 1) + meta.row
                        }
                    },
                    {
                        title: "Name", width: 100, "render": function (data, type, row, meta) {
                            return row?.first_name + " " + row?.last_name;
                        }
                    },
                    {
                        title: "Role", width: 100, data: "role", "render": function (data, type, row, meta) {
                            return data?.is_super === 1 ? "Super Admin" : "Sub Admin";
                        }
                    },
                    {
                        title: "Email", data: "email", "render": function (data, type, row, meta) {
                            return row?.email || "-";
                        }
                    },
                    {
                        title: "Contact", data: "contact", "render": function (data, type, row, meta) {
                            return row?.contact || "-"
                        }
                    },
                    {
                        title: "Status", width: 100, data: 'status', "render": function (data, type, row, meta) {
                            return data == '0' ? '<span class="text-warning">' + "Pending" + '</span>' : data == '1' ? '<span class="text-success">' + "Active" + '</span>' : data == '2' ? '<span class="text-danger">' + "Rejected" + '</span>' : data == '3' ? '<span class="text-dark">' + "Blocked" + '</span>' : 'No Update'
                        }
                    },
                    {
                        title: "Action", data: " "
                    },
                ],
                columnDefs: [
                    {
                        targets: 6,
                        responsivePriority: 1,
                        createdCell: (td, cellData, rowData, row, col) =>
                            ReactDOM.render(
                                <div>
                                    <CDropdown variant="btn-group" direction='dropstart' alignment="end">
                                        <CDropdownToggle size="sm" color="transparent" caret={false} href="#" className="p-0"><i className="fa fa-ellipsis-v text-primary"></i></CDropdownToggle>
                                        <CDropdownMenu >
                                            <CDropdownItem className="more-menu">
                                                <CButton onClick={() => {
                                                    onEdit(rowData)
                                                }} size="sm" className="w-100" variant='outline' color="primary" >
                                                    Edit  <i className="fas fa-edit"></i>
                                                </CButton>
                                            </CDropdownItem>
                                            {rowData.status != 0 && (<CDropdownItem className="more-menu">
                                                <CButton
                                                    onClick={() => {
                                                        onBlock(rowData)
                                                    }}
                                                    size="sm" className="w-100" variant='outline' color={rowData.status === 3 ? "success" : "danger"}>
                                                    {rowData.status === 3 ? 'Activate' : 'Block'} {rowData.status === 3 ? <i className="fas fa-user-check"></i> : <i className="fas fa-ban"></i>}
                                                </CButton>
                                            </CDropdownItem>)}
                                            <CDropdownItem> <CButton
                                                onClick={() => {
                                                    confirm(rowData.id)
                                                }}
                                                size="sm" className="w-100" variant='outline' color="danger">
                                                Delete <i className="fas fa-trash"></i>
                                            </CButton>
                                            </CDropdownItem>
                                        </CDropdownMenu>
                                    </CDropdown>
                                </div>
                                , td),
                    }
                ],
                destroy: true,  // I think some clean up is happening here
                searching: true,
                "language": {
                    search: '',
                    searchPlaceholder: "Search",
                    emptyTable: "No Record Found",
                },
                initComplete: function () {  
                    // Apply the search
                    this.api()
                        .columns([0, 1, 2, 3, 4])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><input class="form-control " style="width:100%;margin-top:5px" type="text" placeholder="Filter" />')
                                .appendTo($(column.header()))

                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $('input', this.header()).on('keyup change clear', function () {
                                if (column.search() !== this.value) {
                                    column.search(this.value).draw();
                                }
                            });
                        });
                    this.api()
                        .columns([5])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><select class="form-control" placeholder="search" style="width:100%;margin-top:5px;background-color:white""><option value="">All</option></select>')
                                .appendTo($(column.header()))
                                .on('change', function () {
                                    var val = $.fn.dataTable.util.escapeRegex($(this).val());
                                    column.search(val ? '^' + val + '$' : '', true, false).draw();
                                });
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            column
                                .data()
                                .unique()
                                .sort()
                                .each(function (d, j) {
                                    let name = [{ "name": "Pending", "value": 0 }, { "name": "Active", "value": 1 }, { "name": "Rejected", "value": 2 }, { "name": "Blocked", "value": 3 }]?.find(stat => stat.value == d)?.name;
                                    select.append('<option value="' + name + '">' + name + '</option>');
                                });
                        });

                }
            });

        // Extra step to do extra clean-up.
        return function () {
            console.log("Table destroyed")
            table.destroy()
        }
    }, [adminList])

    const confirm = (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this admin details!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    AdminStore.deleteAdmin({ id: id }, navigationCallBackBlock)
                }
            });
    }
    const onBlock = (item) => {
        swal({
            title: item?.status === 3 ? "Do you really want to unblock user?" : "Do you really want to block user?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    AdminStore.blockAdmin({ id: item.id }, navigationCallBackBlock)
                }
            });
    }
    const navigationCallBackBlock = () => {
        AdminStore.getAdmin();
    }
    const onEdit = (item) => {
        navigate('/admin/admin-add', { state: { item: item } });
    }

    return (
        <React.Fragment>
              <Helmet>
                <title>Staffers LLC | Sub Admin List</title>
            </Helmet>
            {/* <body> */}
            <div className="container-scroller">
                <HeaderPage />
                <div className="container-fluid page-body-wrapper">
                    <DrawerMenuPage />
                    <div className="main-panel" style={{ right: 0, position: "absolute" }}>
                        <div className="d-flex justify-content-between content-wrapper p-3 bg-white ">
                            <h3 className="page-title">
                                <span className="page-title-icon linear text-white me-2">
                                    <i className="fas fa-user-shield"></i>
                                </span> Admins
                            </h3>
                            <a href="/admin/admin-add">
                                <h3 className="page-title signup-link" >
                                    <span className="page-title-icon linear text-white me-2">
                                        <i className="fas fa-plus"></i>
                                    </span> Add New Admin
                                </h3>
                            </a>
                        </div>
                        {/* <!-- partial --> */}
                        <div className="row bg-white pb-3">
                            <table className="table dt-responsive table-hover nowrap" style={{ width: "100%" }} id={tableName} ref={tableRef}></table>
                        </div>
                    </div>

                    {/* <!-- main-panel ends --> */}
                </div>
                {/* <!-- page-body-wrapper ends --> */}
            </div>
            {/* </body> */}
            <Spinner isLoading={AdminStore.loading} />
        </React.Fragment>
    );
})

export default SubAdminList;