/* eslint-disable */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react"
import HeaderPage from "../layout/HeaderPage";
import DrawerMenuPage from "../layout/DrawerMenuPage";
import * as Yup from 'yup';
import { Formik } from "formik";
import '../sub-admin/SubAdminList.css'
import Select, { components } from 'react-select';
import { useNavigate} from 'react-router-dom';
import Spinner from "@components/Spinner";
import { InputField } from "@elements/index"
import AdminStore from "@store/AdminStore";
import HomeStore from "@store/HomeStore";
import { toast } from "react-toastify";
import SelectField from "@elements/select/SelectField";
var data = require('@assets/CountryCodes.json');
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const SignUpUpdateSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name Required').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
    last_name: Yup.string().required('Last Name Required').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
    contact: Yup.string().matches(phoneRegExp, 'Contact number is not valid').required('Contact number required').min(7).max(15, "Wrong contact number"),
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string().trim().min(6, "Your password must be longer than 6 characters.").test('no-spaces', 'Password must not contain spaces', (value) => !/\s/.test(value)),
    profession: Yup.string().required('Profession required'),
});
const colourStyles = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? 'blue' : '#75459b',
        borderRadius: 20,
        fontSize: '12px',
        boxShadow: 'none',
        '&:focus': {
            border: '1px solid blue !important'
        },
        '&:active': {
            border: '1px solid blue !important'
        },
        '&:hover': {
            border: '1px solid blue !important'
        }
    }),
    menu: base => ({
        ...base,
        fontSize: 12,
    }),
    menuList: (base) => ({
        ...base,
        height: "200px",

        "::-webkit-scrollbar": {
            width: "9px"
        },
        "::-webkit-scrollbar-track": {
            background: "white"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#8282C8"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        }
    })

}

const StaffsEdit = observer(() => {
    const navigate = useNavigate();
    const [degreeList, setDegreeList] = useState([]);
    const [icon, setIcon] = useState("fa fa-fw fa-eye-slash field-icon toggle-password")
    const [passwordType, setPasswordType] = useState("password");
    const [caregiverData, setCaregiverData] = useState();

    useEffect(() => {
        const encodedData = new URLSearchParams(window.location.search);
        try {
            const data = JSON.parse(decodeURIComponent(encodedData.get("data")));
            setCaregiverData(data);
        } catch (error) {
            console.error('Error decoding JSON data:', error);
        }
    }, []);

    useEffect(() => {
        setDegreeList(HomeStore.data.degreeList)
    }, [HomeStore.data.degreeList]);

    const updateStaff = (values) => {
        if (caregiverData?.id) {
            if (values.password != values.confirm_password) {
                toast.error("Confirm password must match");
                return false
            }
            values.id = caregiverData?.id;
            AdminStore.updateStaff(values, navigationCallBack)
        }
    }
    const navigationCallBack = () => {
        navigate('/admin/job-profile-request');
    }
    const _changeIcon = () => {
        icon === "fa fa-fw fa-eye-slash field-icon toggle-password"
            ? (setIcon("fa fa-fw fa-eye field-icon toggle-password"), setPasswordType("text"))
            : (setIcon("fa fa-fw fa-eye-slash field-icon toggle-password"), setPasswordType("password"))
    }
    return (
        <React.Fragment>
            {/* <body> */}
            <div className="container-scroller">
                <HeaderPage />
                <div className="container-fluid page-body-wrapper">
                    <DrawerMenuPage />
                    <div className="main-panel" style={{ right: 0, position: "absolute" }}>
                        <div className="content-wrapper p-3 bg-white ">
                            <h3 className="page-title">
                                <span className="page-title-icon linear text-white me-2">
                                    <i className="far fa-edit"></i>
                                </span>Edit Staff Profile
                            </h3>

                        </div>
                        {/* <!-- partial --> */}
                        <div className=" row justify-content-center mt-4">
                            <div className="col-lg-11">
                                <Formik
                                    initialValues={{ title: caregiverData?.title || "Mr.", first_name: caregiverData?.first_name || '', last_name: caregiverData?.last_name || '', contact: caregiverData?.contact || '', email: caregiverData?.email || '', country_code: caregiverData?.country_code || '+1', profession: caregiverData?.profession || "", description: caregiverData?.description || "", password: '', confirm_password: '' }}
                                    onSubmit={values => updateStaff(values)}
                                    enableReinitialize
                                    validationSchema={SignUpUpdateSchema} >
                                    {
                                        ({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched
                                        }) =>
                                        (
                                            <form onSubmit={handleSubmit} className="wow fadeInUp">
                                                <div className="row g-2">
                                                    {/* <div className="col-sm-1">
                                                        <Select
                                                            onChange={(e) => setFieldValue("title", e.value)}
                                                            placeholder={''}
                                                            label={'Title'}
                                                            value={global.config.titleList.filter(({ value }) => values?.title?.includes(value)) || ''}
                                                            styles={colourStylesTitle}
                                                            isSearchable={true}
                                                            options={global.config.titleList}
                                                            defaultValue={{ label: "Mr.", value: 'Mr.' }}
                                                            components={{ Control, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                        />
                                                    </div> */}
                                                    <div className="col-sm-6 mb-3">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            type="text"
                                                            name="first_name"
                                                            label={"First Name"}
                                                            value={values.first_name}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.first_name && touched.first_name ? (
                                                            <div className="error-message">{errors.first_name}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="col-sm-6 mb-3">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            type="text"
                                                            name="last_name"
                                                            label={"Last Name"}
                                                            value={values.last_name}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.last_name && touched.last_name ? (
                                                            <div className="error-message">{errors.last_name}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="col-sm-2 mb-3">
                                                        <Select
                                                            onChange={(e) => setFieldValue("country_code", e.label)}
                                                            placeholder={''}
                                                            label={'Country Code'}
                                                            value={data.filter(({ label }) => values?.country_code?.includes(label)) || ''}
                                                            styles={colourStyles}
                                                            isSearchable={true}
                                                            options={data}
                                                            defaultValue={{ label: "+1", value: 'CA' }}
                                                            components={{ Control }}
                                                        />
                                                    </div>
                                                    <div className="col-sm-4 mb-3">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            type="text"
                                                            name="contact"
                                                            label={"Contact Number"}
                                                            value={values?.contact?.replace(/[^\d]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}
                                                                    maxLength={12}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.contact && touched.contact ? (
                                                            <div className="error-message">{errors.contact}</div>
                                                        ) : null}
                                                    </div>

                                                    <div className="col-sm-6 mb-3">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            type="email"
                                                            name="email"
                                                            label={"Email"}
                                                            value={values.email}
                                                            disabled={caregiverData ? true : false}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.email && touched.email ? (
                                                            <div className="error-message">{errors.email}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <SelectField
                                                            name="profession"
                                                            label={"Profession*"}
                                                            value={values.profession}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            data={degreeList}
                                                            optionLabel={"Name"}
                                                            optionValue={"Id"}
                                                        />
                                                        {errors.profession && touched.profession ? (
                                                            <div className="error-message">{errors.profession}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="col-6">
                                                        <label className="pb-2 label">Tell us about yourself</label>
                                                        <div className="form-floating">
                                                            <textarea className="form-control"
                                                                placeholder=""
                                                                name={'description'}
                                                                value={values.description}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                style={{ height: 100 }}></textarea>
                                                        </div>
                                                    </div>

                                                    <label className="py-3">Change password</label>
                                                    <div className="col-sm-6">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            type={passwordType}
                                                            name="password"
                                                            label={"Password"}
                                                            value={values.password}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        <span toggle="#password-field" class={icon} onClick={() => _changeIcon()}></span>
                                                        {errors.password ? (
                                                            <div className="error-message">{errors.password}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="col-sm-6 mb-3">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            type="password"
                                                            name="confirm_password"
                                                            label={"Confirm Password"}
                                                            value={values.confirm_password}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.confirm_password ? (
                                                            <div className="error-message">{errors.confirm_password}</div>
                                                        ) : null}
                                                    </div>

                                                    <div className="pt-4 pb-4 d-flex justify-content-center">
                                                        <button className="btn btn-primary w-25 p-2" type="submit">Update</button>
                                                    </div>
                                                </div>
                                            </form>
                                        )
                                    }
                                </Formik>
                            </div>
                        </div>
                    </div>

                    {/* <!-- main-panel ends --> */}
                </div>
                {/* <!-- page-body-wrapper ends --> */}
            </div>
            {/* </body> */}
            <Spinner isLoading={AdminStore.loading} />
        </React.Fragment>
    );
})
export const Control = (props) => {
    return (
        <>
            <label className={props.hasValue || props.isFocused ? "did-floating-label did-floating-label-top" : "did-floating-label did-floating-label"} style={{ zIndex: 999 }}>{props?.selectProps?.label}</label>
            <components.Control {...props} />
        </>
    );
};

export default StaffsEdit;