import { configure, makeAutoObservable, runInAction } from "mobx"
import { axiosInstanceInt, setAuthTokenInt, setAuthToken } from "../api/axiosInstance"
import { toast } from 'react-toastify';
import swal from '@sweetalert/with-react';
import secureLocalStorage from "react-secure-storage";
import TokenStore from "@store/TokenStore";

class UserStore {
  loading = false
  data = {
    email: "",
    name: "",
    token: "",
    id: "",
    refreshToken: null,
    profile: [],
    notifications: [],
    hiringRequestList: [],
    bookmarkList: [],
    staffingRequestList: [],
    googleSignInUrl: '',
    unRatedShift: [],
    pendingApprovals: [],
    subAccounts: [],
    alertPOP: true,
    myAlert: [],
    recentProfession: []
  }
  errors = {}
  constructor() {
    makeAutoObservable(this, {
      recentProfession: true
    });
    configure({
      useProxies: "never"
    })
  }
  toggleLoading = (toggle) => {
    this.loading = toggle
  }
  async updateAsyncStore(data) {
    secureLocalStorage.setItem("token", data.token);
    secureLocalStorage.setItem("userData", JSON.stringify(data));
  }

  updateTokenFromStorage(data, token) {
    this.data.token = token
    this.data.name = data.name
    this.data.email = data.email
    this.data.id = data.id
  }

  async getRefreshToken(encrypted, navigationCallBackSuccess, navigationCallBack) {
    this.toggleLoading(false);
    try {
      const response = await axiosInstanceInt.post('/token', encrypted)
      if (response) {
        localStorage.setItem("refresh-token", response?.data?.access_token)
        navigationCallBackSuccess()
      } else {
        console.log("Failed...");
      }
    }
    catch (err) {
      console.log("error", err);
      navigationCallBack()
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async login(creds, navigationCallBack, data, token) {
    if (token) {
      setAuthToken(token)
      this.updateTokenFromStorage(data, token)
    } else {
      this.toggleLoading(true);
      try {
        const response = await axiosInstanceInt.post('user/login', creds)
        this.updateAsyncStore(response.data)
        setAuthToken(response.data.token)
        runInAction(() => {
          this.data.token = response?.data?.token;
          this.data.email = response?.data?.email;
          this.data.name = response?.data.name;
          this.data.id = response?.data?.id;
        })
        navigationCallBack();
      }
      catch (err) {
        if (err?.response?.status !== 500) {
          if (err?.response?.data?.status === 0) {
            swal("Pending!", "Your account is under review. You will be notified via mail once activated.", "warning");
            return false
          }
          if (err?.response?.data?.status === 2) {
            swal("Rejected!", "Your account verification is rejected. Please contact Staffers administrator", "error");
            return false
          }
          if (err?.response?.data?.status === 3) {
            swal("Blocked!", "Your account has been temporarily disabled. Please contact the Administrator of Staffers.", "error");
            return false
          }
          if (!err?.response?.data?.status) {
            toast.error(err?.response?.data?.message);
          }
        } else {
          toast.error("Something went wrong!");
        }
      }
      finally {
        this.toggleLoading(false);
      }
    }
  }

  async register(data, navigationCallBack) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('/user/register', data)
      if (response.data.email || response.data.id) {
        this.toggleLoading(false);
        navigationCallBack(response.data.email, response.data.id)
      } else {
        // 
      }
    }
    catch (err) {
      if (err.response.status !== 500) {
        toast.error(Object.values(err?.response?.data?.message)[0][0]);
      } else {
        toast.error("Something went to wrong!");
      }
      this.toggleLoading(false);
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async verifyOTP(data, navigationCallBackVerify) {
    this.loading = true
    try {
      const response = await axiosInstanceInt.post('user/verifyOtp', data)
      if (response.data.message === 'success') {
        navigationCallBackVerify()
      } else {
        toast.error("Verification Failed!!");
        // 
      }
    }
    catch (err) {
      console.log(err)
      if (err?.response?.status !== 500) {
        toast.error(err?.response?.data?.message);
      } else {
        toast.error("Something went to wrong!");
      }
    }
    finally {
      this.loading = false
    }
  }

  async reSendOTP(data) {
    this.loading = true
    try {
      const response = await axiosInstanceInt.post('user/sendOtp', data)
      if (response.data.email || response.data.id) {
        toast.success("Successfully sent OTP");
        this.loading = false
      } else {
        toast.error("OTP not send!");
        // 
      }
    }
    catch (err) {
      toast.error("Something went to wrong!");
    }
    finally {
      this.loading = false
    }
  }

  async fogotPassword(data, forgotPasswordCallback) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('user/sendResetOtp', data);
      if (response.data.email) {
        toast.success("Successfully Sent OTP");
        this.loading = false
        forgotPasswordCallback(response.data.email);
      } else {
        toast.error("OTP not send!");
        this.loading = false
        // 
      }
    }
    catch (err) {
      toast.error(err?.response?.data?.message);
      console.log(err)
    }
    finally {
      this.loading = false
    }
  }

  async verifyResetOtp(data, navigationCallBackVerify) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('user/verifyResetOtp', data);
      if (response.data.message === 'success') {
        toast.success("Successfully Verifyed");
        this.loading = false
        navigationCallBackVerify(response.data.email);
      } else {
        toast.error("Verification Failed!");
        this.loading = false
        // 
      }
    }
    catch (err) {
      toast.error(err?.response?.data?.message);
      console.log(err)
    }
    finally {
      this.loading = false
    }
  }

  async setNewPassword(data, navigationCallBackNewPassword) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('user/setNewPassword', data);
      if (response.data.message === 'success') {
        toast.success("Successfully Reset Your Password");
        this.loading = false
        navigationCallBackNewPassword();
      } else {
        toast.error("Reset Password Failed!");
        this.loading = false
        // 
      }
    }
    catch (err) {
      toast.error(err?.response?.data?.message);
      console.log(err)
    }
    finally {
      this.loading = false
    }
  }
  async getUserProfession() {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.get('/user/getRecentProfession')
      if (response.data !== "") {
        runInAction(() => {
          this.data.recentProfession = response?.data?.data;
        })
      } else {
        toast.error("Someting went to wrong!");
      }
    }
    catch (err) {
      toast.error("Someting went to wrong!");
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async getUserProfile() {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.get('/user/getDetails')
      if (response.data !== "") {
        runInAction(() => {
          this.data.profile = response?.data;
        })
      } else {
        toast.error("Someting went to wrong!");
      }
    }
    catch (err) {
      toast.error("Someting went to wrong!");
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async updateUserProfile(data, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.post('/user/updateDetails', data)
      if (response.status === 200) {
        navigationCallBack();
      } else {
        toast.error("Someting went to wrong!");
      }
    }
    catch (err) {
      if (err.response.status !== 500) {
        toast.error(Object.values(err?.response?.data?.message)[0][0]);
      } else {
        toast.error("Something went to wrong!");
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async changePassword(data, passwordnavigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.post('/user/changePassword', data)
      if (response.status === 200) {
        passwordnavigationCallBack();
      } else {
        toast.error("Someting went to wrong!");
      }
    }
    catch (err) {
      toast.error(err?.response?.data?.message);
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async getNotificationsData() {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.get('/user/getNotifications')
      if (response.data !== "") {
        runInAction(() => {
          this.data.notifications = response?.data;
        })
      } else {
        console.log("Someting went to wrong!");
      }
    }
    catch (err) {
      if (err?.response?.status === 401) {
        TokenStore.clearAllAsyncStore();
        swal({
          title: "Session Expired",
          text: "Your Session Has Expired, Please Login Again!",
          icon: "warning"
        }).then((willReload) => {
          if (willReload) {
            window.location.reload();
          }
        });
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async setStaffingRequest(data, navigationCallBack, navigationCallBackUnallocated) {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.post('/user/saveStaffingRequest', data)
      if (response?.data) {
        if (response?.data?.failed_slots > 0) {
          swal({
            title: 'Below specified slot(s) are currently not available for this caregiver, we will allocate these slot to another available caregiver with same speciality ASAP.',
            content: (
              <div className='row' style={{ textAlign: 'start' }}>
                <h4>Unallocated Slots</h4>
                <ul className="list-unstyled ms-3">{response?.data?.failed_dates?.map((item, ind) => {
                  return <li className="text-danger" key={ind + "list"}>
                    <input className="slot" value={item?.date} readOnly />
                    <input style={{ lineHeight: 2, width: 100 }} type="time" readOnly value={item?.start} />
                    <span style={{ padding: 5, paddingBottom: 7, paddingTop: 6, borderLeftWidth: 0, borderRightWidth: 0 }} className="timer-form text-dark">To</span>
                    <input style={{ lineHeight: 2, width: 100 }} type="time" readOnly value={item?.end} className="timer-border" />
                  </li>
                })}</ul>
              </div>
            ),
            icon: 'info',
            backdrop: false
          });
          navigationCallBackUnallocated();
        } else {
          navigationCallBack();
        }
      } else {
        console.log("Someting went wrong!");
      }
    }
    catch (err) {
      if (err?.response?.status !== 500) {
        toast.error(Object.values(err?.response?.data?.message)[0][0]);
      } else {
        toast.error("Something went wrong!");
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async getStaffingRequest() {
    this.toggleLoading(true);
    try {
      setAuthTokenInt();
      const response = await axiosInstanceInt.get('/user/getStaffingRequest')
      if (response.data !== "") {
        runInAction(() => {
          this.data.staffingRequestList = response?.data?.data;
        })
      } else {
        console.log("Someting went wrong!");
      }
    }
    catch (err) {
      toast.error("Someting went wrong!");
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async setHiringRequet(data, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.post('/user/saveHireRequest', data)
      if (response.data !== "") {
        navigationCallBack();
      } else {
        console.log("Someting went to wrong!");
      }
    }
    catch (err) {
      toast.error("Someting went to wrong!");
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async getHiringingRequest() {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.get('/user/getHireRequest')
      if (response.data !== "") {
        runInAction(() => {
          this.data.hiringRequestList = response?.data?.data;
        })
      } else {
        // toast.error("Someting went to wrong!");
        console.log("err");
      }
    }
    catch (err) {
      console.log("err", err);
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async bookmark(data, navigationCallBackBookmark) {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.post('/user/saveBookmarkStaff', data)
      if (response.data !== "") {
        navigationCallBackBookmark(response.data.message);
      } else {
        console.log("Someting went to wrong!");
      }
    }
    catch (err) {
      navigationCallBackBookmark(err.response.data.message);
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async getBookmark() {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.get('/user/bookmarkStaffList')
      if (response.data !== "") {
        runInAction(() => {
          this.data.bookmarkList = response?.data?.data;
        })
      } else {
        // toast.error("Someting went  wrong!");
        console.log("err");
      }
    }
    catch (err) {
      console.log("err", err);
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async saveFeedback(data, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.post('/user/saveFeedback', data)
      if (response.data !== "") {
        navigationCallBack();
      } else {
        console.log("Someting went wrong!");
      }
    }
    catch (err) {
      toast.error("Someting went wrong!");
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async getUnratedShift() {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.get('/user/getRatingPopupData')
      if (response.data !== "") {
        runInAction(() => {
          this.data.unRatedShift = response?.data;
        })
      } else {
        console.log("err");
      }
    }
    catch (err) {
      console.log("err", err);
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async extendShift(data, navigationCallBackExtend) {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.post('/user/extentionRequest', data)
      if (response.data !== "") {
        navigationCallBackExtend();
      } else {
        console.log("Someting went wrong!");
      }
    }
    catch (err) {
      toast.error("Someting went wrong!");
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async extendContract(data, navigationCallBackExtend) {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.post('/user/extendContract', data)
      if (response.data !== "") {
        navigationCallBackExtend();
      } else {
        console.log("Someting went wrong!");
      }
    }
    catch (err) {
      toast.error("Someting went wrong!");
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async onApproveSlots(data, navigationCallBackApprove) {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.post('/user/markSlotAssignment', data)
      if (response.data !== "") {
        navigationCallBackApprove();
      } else {
        console.log("Someting went wrong!");
      }
    }
    catch (err) {
      toast.error("Someting went wrong!");
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async onApproveContract(data, navigationCallBackApprove) {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.post('/user/markContract', data)
      if (response.data !== "") {
        navigationCallBackApprove();
      } else {
        console.log("Someting went wrong!");
      }
    }
    catch (err) {
      toast.error("Someting went wrong!");
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async readNotification(data, notify, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.post('/user/readNotification', data)
      if (response) {
        navigationCallBack(notify);
      } else {
        console.log("Someting went wrong!");
      }
    }
    catch (err) {
      console.log("")
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async getPendingApprovals() {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.get('/user/getPendingApprovals')
      if (response) {
        this.data.pendingApprovals = response?.data
      } else {
        console.log("Someting went wrong!");
      }
    }
    catch (err) {
      console.log("err", err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async deleteSlot(data, navigationCallBack) {
    setAuthTokenInt()
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('user/cancelSlot', data)
      if (response?.data) {
        toast.success(response?.data?.message);
        navigationCallBack();
        this.toggleLoading(false);
      } else {
        this.toggleLoading(false);
      }
    }
    catch (err) {
      console.log("delete slot", err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async updateSlotData(data, navigationCallBack) {
    setAuthTokenInt()
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('user/updateSlotData', data)
      if (response?.data) {
        toast.success(response?.data?.message);
        navigationCallBack();
        this.toggleLoading(false);
      } else {
        this.toggleLoading(false);
      }
    }
    catch (err) {
      console.log("update slot", err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async updateContractData(data, navigationCallBack) {
    setAuthTokenInt()
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('user/updateContract', data)
      if (response?.data) {
        toast.success(response?.data?.message);
        navigationCallBack();
        this.toggleLoading(false);
      } else {
        this.toggleLoading(false);
      }
    }
    catch (err) {
      console.log("update slot", err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async getSubAccounts() {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.get('/user/getSubAccounts')
      if (response) {
        this.data.subAccounts = response?.data?.data
      } else {
        console.log("Someting went wrong!");
      }
    }
    catch (err) {
      console.log("err", err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async addSubAccount(data, navigationCallBack) {
    setAuthTokenInt()
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('user/addSubAccounts', data)
      if (response?.data) {
        toast.success(response?.data?.message);
        navigationCallBack();
        this.toggleLoading(false);
      } else {
        this.toggleLoading(false);
      }
    }
    catch (err) {
      if (err?.response?.status !== 500) {
        toast.error(Object.values(err?.response?.data?.message)[0][0]);
      } else {
        toast.error("Something went wrong!");
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async updateSubAccount(data, navigationCallBack) {
    setAuthTokenInt()
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('user/updateSubAccounts', data)
      if (response?.data) {
        toast.success(response?.data?.message);
        navigationCallBack();
        this.toggleLoading(false);
      } else {
        this.toggleLoading(false);
      }
    }
    catch (err) {
      if (err?.response?.status !== 500) {
        toast.error(Object.values(err?.response?.data?.message)[0][0]);
      } else {
        toast.error("Something went wrong!");
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async blockSubAccount(data, navigationCallBack) {
    setAuthTokenInt()
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('user/markSubAccounts', data)
      if (response?.data) {
        toast.success(response?.data?.message);
        navigationCallBack();
        this.toggleLoading(false);
      } else {
        this.toggleLoading(false);
      }
    }
    catch (err) {
      console.log("delete slot", err)
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async uploadShiftCSV(param, navigationCallBackCSVUpload) {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.post('/user/importShifts', param)
      if (response.status === 200) {
        navigationCallBackCSVUpload()
      } else {
        console.log("other");
      }
    }
    catch (err) {
      console.log("error", err);
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async updateAlert(values, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.post('/user/updateAlerts', values)
      if (response.status === 200) {
        navigationCallBack()
      } else {
        console.log("other");
      }
    }
    catch (err) {
      console.log("error", err);
      toast.error("Someting went wrong!");
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async getAlert() {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.get('/user/getAlerts')
      if (response.status === 200) {
        runInAction(() => {
          this.data.myAlert = response?.data?.data;
        })
      } else {
        console.log("other");
      }
    }
    catch (err) {
      console.log("error", err);
    }
    finally {
      this.toggleLoading(false);
    }
  }
}
export default new UserStore()

