/* eslint-disable */
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
import { observer } from "mobx-react"
import StaffsStore from "@store/StaffsStore";
import UserStore from "@store/UserStore";
import { toJS } from "mobx";
import moment from "moment";
import HomeStore from "@store/HomeStore";
import TokenStore from "@store/TokenStore";
import Skeleton from "react-loading-skeleton";
import { getProfessionKeyList } from '@utils/constant/constant';
import { useMediaQuery } from 'react-responsive';
const Header = observer(() => {
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const navigate = useNavigate();
    const location = useLocation();
    const [isAuthenticated, setAuthenticated] = useState(null);
    const [role, setRole] = useState();
    const [subUser, setSubUSer] = useState();
    const [blueSky, setBlueSky] = useState();
    const [notificationClient, setNotificationClient] = useState([]);
    const [notificationStaff, setNotificationStaff] = useState([]);
    const [notificationClientCount, setNotificationClientCount] = useState(0);
    const [notificationStaffCount, setNotificationStaffCount] = useState(0);
    const [imported, setImported] = useState(0);


    useEffect(() => {
        let token = secureLocalStorage.getItem("token");
        setAuthenticated(token);
        let userType = secureLocalStorage.getItem("type");
        setRole(userType);
        let userData = secureLocalStorage.getItem("userData");
        if (userData !== 'undefined') {
            setBlueSky(JSON?.parse(userData)?.bluesky_staff_id);
            setImported(JSON?.parse(userData)?.is_imported)
            setSubUSer(JSON?.parse(userData)?.master)
        }
        if (secureLocalStorage.getItem("type") == "false") {
            UserStore.getNotificationsData();
        } else if (secureLocalStorage.getItem("type") == "true") {
            StaffsStore.getNotificationsData();
        }
    }, []);

    useEffect(() => {
        setNotificationClient(toJS(UserStore?.data?.notifications)?.filter(x => x.read_at == null)?.slice(0, 5))
        setNotificationClientCount(toJS(UserStore?.data?.notifications)?.filter(x => x.read_at == null)?.length)
    }, [UserStore?.data?.notifications]);

    useEffect(() => {
        setNotificationStaff(toJS(StaffsStore?.data?.notifications)?.filter(x => x.read_at == null)?.slice(0, 5))
        setNotificationStaffCount(toJS(StaffsStore?.data?.notifications)?.filter(x => x.read_at == null)?.length)
    }, [StaffsStore?.data?.notifications]);

    const handleLogout = () => {
        swal({
            title: "Are you sure you want to log out?",
            text: "Your session will be terminated, and you will need to log in again to access your account. Click 'Yes' to confirm or 'Cancel' to stay logged in.",
            icon: "warning",
            buttons: true,
            buttons: ["Cancel", "Yes"],
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    TokenStore.clearAllAsyncStore();
                    try {
                        window.Echo.disconnect();
                    } catch (e) {
                        console.log("connection disconnect error", e);
                    }

                    window.location.replace('/login/employee');
                    // navigate("", { replace: true });
                }
            });

    }

    const getFullName = () => {
        let userData = JSON.parse(secureLocalStorage.getItem("userData"));
        return `${userData?.first_name}` == 'undefined' ? "Loading..." : `${userData?.first_name}`;
    }
    const getOrganizationName = () => {
        let userData = JSON.parse(secureLocalStorage.getItem("userData"));
        return <div className="nav-item dropdown">
            <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">   <i className="far fa-building"></i>  {userData && userData?.facilities && userData?.facilities[0] && userData?.facilities[0].organization_name}
                {userData?.facilities?.length > 1 && <sup>+{userData && userData?.facilities ? userData?.facilities?.length - 1 : 0}</sup>}
            </a>
            <div className="dropdown-menu border-light m-0">
                <ul className="list-unstyled">
                    {userData && userData?.facilities && userData?.facilities?.map((item, index) => (
                        <li key={index} className="dropdown-item">{item.organization_name}</li>
                    ))}
                </ul>
            </div>
        </div>
    }
    const getProfessionName = () => {
        let professionData = toJS(StaffsStore.data.jobProfile)?.profile?.degree_specialities ? JSON.parse(toJS(StaffsStore.data.jobProfile)?.profile?.degree_specialities) : ""
        if (professionData?.length > 0) {
            professionData = getProfessionKeyList(professionData)
        } else {
            professionData = ''
        }
        return `${professionData}` == 'undefined' ? "Loading..." : `${professionData}`;
    }

    const openNotification = (notify) => {
        let param = {
            "notification_id": notify?.id
        }
        if (role == "false") {
            UserStore.readNotification(param, notify, navigationCallBack)
        } else if (role == "true") {
            StaffsStore.readNotification(param, notify, navigationCallBack)
        }
    }

    const navigationCallBack = (notify) => {
        if (role == "false") {
            UserStore.getNotificationsData();
            navigate('/notification', { state: { item: notify } });
        } else if (role == "true") {
            StaffsStore.getNotificationsData();
            navigate('/notification', { state: { item: notify } });
        }
    }

    return (
        <div className="container-fluid fixed-top px-0 wow fadeIn" data-wow-delay="0.03s">
            <div className="top-bar row gx-0 align-items-center d-none d-lg-flex">
                <div className="col-lg-6 px-5 text-start">
                    <a target="_blank" href="http://maps.google.com/?q=9980 South 300 West,Suite 200 Sandy, UT 84070">   <small><i className="fa fa-map-marker-alt text-primary me-2"></i>{HomeStore?.data?.dashboardData?.find(x => x.key_name === 'Address')?.value || <Skeleton className="info-text" height={17} width={200} />}</small></a>
                </div>
                <div className="col-lg-6 px-5 text-end">
                    <a href="mailto:staffers@staffersllc.com"> <small><i className="fa fa-envelope text-primary me-2"></i>{HomeStore?.data?.dashboardData?.find(x => x.key_name === 'Official E-Mail')?.value || <Skeleton className="info-text" height={17} width={200} />}</small></a>
                    <a href="tel:801-820-0788"><small className="ms-4"><i className="fa fa-phone-alt text-primary me-2"></i>{HomeStore?.data?.dashboardData?.find(x => x.key_name === 'Official Contact')?.value || <Skeleton className="info-text" height={17} width={200} />}</small></a>
                    <a href={HomeStore?.data?.dashboardData?.find(x => x.key_name === 'Official Youtube')?.value} target="_blank"><small className="ms-4"><i className="fas fa-question-circle text-primary me-2"></i>{HomeStore?.data?.dashboardData?.find(x => x.key_name === 'Official Youtube')?.value ? "Help Videos" : <Skeleton className="info-text" height={17} width={50} />}</small></a>
                </div>
            </div>

            <nav className="navbar navbar-expand-lg navbar-light py-lg-0 px-lg-5 wow fadeIn" data-wow-delay="0.03s">
                <NavLink className='navbar-brand' to='/portal'><img src={"/assets/images/web-images/staffers_logo.svg"} alt="StaffersLLC" className="img-fluid" /></NavLink>
                <button type="button" className="navbar-toggler me-4" data-bs-toggle="collapse"
                    data-bs-target="#navbarCollapse">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav ms-5 p-4 p-lg-0">
                        {(role == 'false' || !isAuthenticated) && <NavLink className="nav-item nav-link" to='/staffs'>Search Caregiver</NavLink>}
                        {((role == 'true') && !blueSky) && <NavLink className="nav-item nav-link" to='/jobs'>Search Job</NavLink>}
                        {!secureLocalStorage.getItem("token") && <NavLink className="nav-item nav-link" to='/howdoesitwork'>How Does It Work?</NavLink>}
                        {
                            // 'employer'
                            isAuthenticated && role == 'false' && (
                                <>
                                    <NavLink className="nav-item nav-link" to='/shift-request-list'>Orders</NavLink>
                                    <NavLink className="nav-item nav-link" to='/favorite'>Saved Caregivers</NavLink>

                                    {/* <a href="/edit-profile" className="nav-link"><i className="fas fa-user-friends" aria-hidden="true" /> {getFullName()}</a> */}
                                    <div className="nav-item dropdown">
                                        <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown"><i className="fas fa-user-friends" aria-hidden="true" /> {getFullName()}

                                        </a>
                                        <div className="dropdown-menu border-light m-0">
                                            <>
                                                <NavLink className="dropdown-item" to='/edit-profile'>My Account</NavLink>
                                                {!subUser && <NavLink className="dropdown-item" to='/sub-accounts'>Sub Accounts</NavLink>}
                                                <NavLink className="dropdown-item" to='/alerts-settings'>Manage Notification</NavLink>
                                            </>
                                        </div>
                                    </div>
                                    <NavLink className="d-lg-none nav-item nav-link" to='/notification'>Notifications <sup className="text-danger">{notificationClientCount}</sup></NavLink>
                                </>
                            )
                        }
                        {
                            // 'employee'
                            isAuthenticated && role == 'true' && (
                                <>

                                    {(role == 'true' || !isAuthenticated) && blueSky && <NavLink className="nav-item nav-link" to='/available-shifts'>Pickup Shifts</NavLink>}


                                    {blueSky && <div className="nav-item dropdown">
                                        <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Shifts</a>

                                        <div className="dropdown-menu border-light m-0">
                                            <>
                                                <NavLink className="dropdown-item" to='/applied-shifts'>My Shifts</NavLink>
                                                <NavLink className="dropdown-item" to='/applied-shifts-calendar'>My Calendar</NavLink>
                                                <NavLink className="dropdown-item" to='/view-shifts'>BlueSky Shifts</NavLink>
                                            </>
                                        </div>
                                    </div>
                                    }
                                    {(role == 'true' && blueSky) &&
                                        <NavLink className="nav-item nav-link" to='/applied-jobs'>Applied Job</NavLink>

                                    }
                                    {!blueSky && <div className="nav-item dropdown">
                                        <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Jobs</a>
                                        <div className="dropdown-menu border-light m-0">
                                            <NavLink className="dropdown-item" to='/applied-jobs'>Applied Job</NavLink>
                                            <NavLink className="dropdown-item" to='/favorite-job'>Saved Jobs</NavLink>
                                        </div>
                                    </div>}
                                    {(role == 'true' || !isAuthenticated) && blueSky && <NavLink className="nav-item nav-link" to='/my-teams'>My Teams</NavLink>}
                                    <div className="nav-item dropdown">
                                        <a href="#" className="nav-link dropdown-toggle" data-bs-toggle={isMobile ? "dropdown" : ""}><i className="fas fa-user-tie" aria-hidden="true" /> {getFullName()}</a>
                                        <div className="dropdown-menu border-light m-0">
                                            <NavLink className="dropdown-item" to='/my-job-profile'>My Job Profile</NavLink>
                                            <NavLink className="dropdown-item" to='/edit-profile'>My Account</NavLink>
                                            {(blueSky && imported === 0 && (HomeStore?.data?.dashboardData?.find(x => x.key_name === 'Onboarding Sign Required?')?.value === 'Yes')) && < NavLink className="dropdown-item" to='/onboarding-documents'>Onboarding documents</NavLink>}
                                            <NavLink className="dropdown-item" to='/alerts-settings'>Manage Notification</NavLink>
                                        </div>
                                    </div>
                                    <NavLink className="d-lg-none nav-item nav-link" to='/notification'>Notifications <sup className="text-danger">{notificationStaffCount}</sup></NavLink>

                                </>
                            )
                        }
                        <div className="nav-item nav-link d-flex justify-content-end d-lg-none">
                            {
                                !isAuthenticated &&
                                <>
                                    <NavLink className="btn" style={{ backgroundColor: "transparent", border: "none", color: "black" }} to='/login/employee'><small >Sign In</small></NavLink>
                                    <NavLink className="btn btn-primary rounded-10 ms-3 " to="/register/employee">
                                        <small>Sign Up</small>
                                    </NavLink>
                                </>
                            }

                            {role && (
                                <div className="btn btn-primary" onClick={handleLogout} style={{ cursor: 'pointer' }}>
                                    <small> <i className="fas fa-sign-out-alt"></i> Logout</small>

                                </div>
                            )}

                        </div>
                    </div>
                </div>
                {role == 'false' && <h5 className="mt-2 ms-2 ms-lg-0">{getOrganizationName()}</h5>}
                {(role == 'true' && getProfessionName()) && <h5 className="mt-2 ms-2 ms-lg-0 col-12 col-lg-1 d-lg-block d-none"><i className="fas fa-graduation-cap"></i>&nbsp;{getProfessionName()}</h5>}

                {
                    isAuthenticated && <div className="d-none d-lg-flex nav-item dropdown" style={{ right: "-10px" }}>

                        <a href="/notification">
                            <i className="far fa-bell fs-3" /><span className="badge-tag">{role == 'true' ? notificationStaffCount : notificationClientCount}</span>
                        </a>
                        <div>
                            <div className="dropdown-menu preview-list dropdown-menu-end mt-4">
                                <div className="d-flex justify-content-between">
                                    <h6 className="p-3 mb-0">Notifications</h6>
                                    <a href="/alerts-settings">  <i className="fa fa-cog p-3 mb-0 pointer" aria-hidden="true"></i></a>
                                </div>
                                {(role == 'true' && notificationStaff?.length === 0) || (role == 'false' && notificationClient?.length === 0) ?
                                    <>
                                        <div className="dropdown-divider"></div>
                                        <div className="dropdown-item preview-item">
                                            <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                                                <h6 className="preview-subject font-weight-normal mb-1">No New Notification</h6>
                                            </div>
                                        </div>
                                        <div className="dropdown-divider"></div>
                                    </>
                                    : null
                                }

                                {
                                    role == 'true' && notificationStaff && notificationStaff?.length > 0 && notificationStaff?.map((notify, i) => {
                                        return (
                                            <div key={i + 'noti'} style={JSON.parse(notify?.data)?.read_at ? {} : { backgroundColor: "ghostwhite" }}>
                                                <a className="dropdown-item preview-item ">
                                                    <div onClick={() => openNotification(notify)} className="preview-item-content d-flex align-items-start flex-column justify-content-center pointer">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div> <h6 className="preview-subject font-weight-normal mb-1 pe-3">{JSON.parse(notify?.data)?.data?.heading}</h6>
                                                                <p className="text-gray ellipsis mb-0"> {JSON.parse(notify?.data)?.data?.subject}</p>
                                                            </div>
                                                            <div>
                                                                <h5><span className="badge badge-primary"> {moment(notify?.created_at).fromNow()}</span></h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        )
                                    })}

                                {
                                    role == 'false' && notificationClient && notificationClient?.length > 0 && notificationClient?.map((notify, i) => {
                                        return (
                                            <div key={i + 'noti'} style={JSON.parse(notify?.data)?.read_at ? {} : { backgroundColor: "ghostwhite" }}>
                                                <div className="dropdown-divider"></div>
                                                <a className="dropdown-item preview-item pointer" onClick={() => openNotification(notify)} >
                                                    <div className="preview-item-content d-flex align-items-start flex-column justify-content-center pointer">
                                                        <h6 className="preview-subject font-weight-normal mb-1">{JSON.parse(notify?.data)?.data?.heading}</h6>
                                                        <p className="text-gray ellipsis mb-0"> {JSON.parse(notify?.data)?.data?.subject}</p>
                                                    </div>
                                                </a>
                                            </div>
                                        )
                                    })}
                                < div className="dropdown-divider"></div>
                                <h6 className="p-3 mb-0 text-center"><a href="/notification">See all notifications</a></h6>
                            </div>
                        </div>
                    </div>
                }

                <div className="d-none d-lg-flex ms-2">
                    {
                        !isAuthenticated &&
                        <>
                            <NavLink className="btn ms-3" style={{ backgroundColor: "transparent", border: "none", color: "black" }} to='/login/employee'><small >Sign In</small></NavLink>
                            <NavLink className="btn btn-primary rounded-10 ms-3" style={{ background: "#75459b" }} to="/register/employee">
                                <small>Sign Up</small>
                            </NavLink>
                        </>
                    }

                    {(role && isAuthenticated) && (
                        <div className="btn btn-primary ms-3" onClick={handleLogout} style={{ cursor: 'pointer' }}>
                            <small> <i className="fas fa-sign-out-alt"></i> Logout</small>
                        </div>
                    )}
                </div>
            </nav >
        </div >
    );
})

export default Header;