const newLogo = require("../assets/img/staffers_new_logo.png");
const oldLogo = require("../assets/img/staffers_logo.png");
const brand = require("../assets/img/brand.png");
const fb = require("../assets/img/icons/fb.png");
const google = require("../assets/img/icons/google.png");
const linkedin = require("../assets/img/icons/linkedin.png");
const vaccinated = require("../assets/img/icons/vaccinated.png");
const health = require("../assets/img/icons/Trustworthy.png");
const quality = require("../assets/img/icons/Flexibility.png");
const support = require("../assets/img/icons/Satisfaction.png");
const hate = require("../assets/img/icons/emoji-1.png");
const unlike = require("../assets/img/icons/emoji-2.png");
const awesome = require("../assets/img/icons/emoji-3.png");
const like = require("../assets/img/icons/emoji-4.png");
const love = require("../assets/img/icons/emoji-5.png");
const think = require("../assets/img/icons/think.png");
const exclusive = require("../assets/img/icons/exclusive.png");
const notificationAlert = require("../assets/audio/notif.mp3");
const BgLogo = require("../assets/img/icons/BG.png");
const verified = require("../assets/img/icons/verified.png");
export default {newLogo,oldLogo,brand,fb,google,linkedin,vaccinated,health,quality,support,hate,unlike,awesome,like,love,think,notificationAlert,BgLogo,exclusive,verified};