import React from "react";
import Header from '@components/PDFHeader';
import moment from "moment";
import { MultiDatePicker } from "@elements/index"

export const JobDescriptionCertifiedCNA = (props) => {
  return (
    <div id="form-content">
      <Header />
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="border-box">
                <div>
                  <div className="d-md-flex">
                    <table className="table table-all bordered">
                      <tbody>
                        <tr>
                          <td width="40%" className="head">
                            Job Title:
                          </td>
                          <td width="60%" className="input">
                            <input type="text" value="CNA/Med Tech" readOnly />
                          </td>
                        </tr>
                        <tr>
                          <td width="40%" className="head">
                            Location:
                          </td>
                          <td width="60%" className="input">
                            <input type="text" value={props.inputValues.location} readOnly onChange={(e) => props.handleInputChange('location', e.target.value)} />

                          </td>
                        </tr>
                        <tr>
                          <td width="40%" className="head">
                            Effective Date:
                          </td>
                          <td width="60%" className="input">
                            <input type="text" readOnly value={"07/15/2019"} onChange={(e) => props.handleInputChange('EffectiveDate', e.target.value)} />

                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="table table-all bordered">
                      <tbody>
                        <tr>
                          <td width="40%" className="head">
                            Department
                          </td>
                          <td width="60%" className="input">
                            <input type="text" value={"Nursing"} readOnly onChange={(e) => props.handleInputChange('Department', e.target.value)} />

                          </td>
                        </tr>
                        <tr>
                          <td width="40%" className="head">
                            FLSA Status:
                          </td>
                          <td width="60%" className="input">

                            <input type="text" placeholder="Non-exempt/Hourly" readOnly value={props.inputValues.FLSA} onChange={(e) => props.handleInputChange('FLSA', e.target.value)} />

                          </td>
                        </tr>
                        <tr>
                          <td width="40%" className="head">
                            Revised Date:
                          </td>
                          <td width="60%" className="input">
                            <MultiDatePicker required={false} multiple={false} value={props.inputValues.Revised} onChange={(e) => props.handleInputChange('Revised', e)} />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <table className="table table-all bordered">
                    <tbody>
                      <tr>
                        <td className="head width-set-h">Reporting to:</td>
                        <td className="input width-set-i">
                          <input type="text" value={props.inputValues.Reportingto} readOnly onChange={(e) => props.handleInputChange('Reportingto', e.target.value)} />

                        </td>
                      </tr>
                      <tr>
                        <td className="head width-set-h">Date of Hire:</td>
                        <td className="input width-set-i">
                          <MultiDatePicker required={false} multiple={false} value={props.inputValues.DOH} onChange={(e) => props.handleInputChange('DOH', e)} />
                        </td>
                      </tr>
                      <tr>
                        <td className="head width-set-h">Employee Name:</td>
                        <td className="input width-set-i">
                          <input placeholder="Full Name" required value={props.inputValues.full_name} type="text" minLength={2} maxLength={20} name="full name" onChange={(e) => props.handleInputChange('full_name', e.target.value)} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="back-grad grey">
                  <h2 className="heading-line">JOB SUMMARY:</h2>
                  <p className="bold-para">
                    The role of a Certified Nursing Assistant (CNA) is to work
                    as and when required in support of the nursing team in
                    providing high quality care and support. Perform direct
                    resident care duties under the supervision of licensed
                    nursing personnel. Assist residents with activities of daily
                    living, provides basic nursing care to resident’s and
                    assists in maintenance of a safe and clean environment.
                    Assist with promoting a compassionate physical and
                    psychosocial environment for the residents.
                  </p>
                </div>
                <div className="back-grad">
                  <h3 className="same-head">
                    <span>E</span>ssential job functions
                  </h3>
                  <h4 className="same-small-head">Customer Service</h4>
                  <p className="">
                    <ul>
                      <li>
                        <span>
                          Provide compassionate resident care services.
                          Establish and maintain positive interactions with
                          internal and external customers, including co-workers,
                          facility employees and direct reports.
                        </span>
                      </li>
                      <li>
                        <span>
                          Take responsibility for resolving customer’s issues
                          and concerns striving to make outstanding experiences
                          for our customers. Customers include residents,
                          families, vendors and other employees.
                        </span>
                      </li>
                      <li>
                        <span>
                          Promote positive relationships with residents,
                          visitors, and regulators, to include presenting a
                          professional appearance.
                        </span>
                      </li>
                    </ul>
                  </p>
                  <br></br>
                  <h4 className="same-small-head">
                    Specific Duties and Responsibilities
                  </h4>
                  <ul>
                    <li>
                      <span>Assist nurses in providing care and medications.</span>
                    </li>
                    <li>
                      <span>
                        Follow written and/or oral instructions to administer
                        medications and document dosages and times.
                      </span>
                    </li>
                    <li>
                      <span>
                        Monitor patients and report changes in condition or
                        vital signs.
                      </span>
                    </li>
                    <li>
                      <span>
                        Promote the mission, vision, and values of the
                        organization.
                      </span>
                    </li>
                    <li>
                      <span>
                        Provide basic nursing care within the Nursing
                        Assistant’s scope of practice.
                      </span>
                    </li>
                    <li>
                      <span>
                        Able to explain and demonstrate fire/emergency
                        procedures and must participate in any emergency or
                        drill activities.
                      </span>
                    </li>
                    <li className="">
                      <span>Properly use and maintain facility equipment.</span>
                    </li>
                    <li>
                      <span>Show respect for facility property.</span>
                    </li>
                    <li>
                      <span>
                        Demonstrate and able to explain infection control and
                        isolation procedures.
                      </span>
                    </li>
                    <li>
                      <span>
                        {" "}
                        Demonstrate and able to explain proper body mechanics in
                        all resident handling interactions & processes.{" "}
                      </span>
                    </li>
                    <li>
                      <span>Ensure residents are given correct diet.</span>
                    </li>
                    <li>
                      <span>
                        Use proper feeding techniques while respecting personal
                        dignity and meeting the residents’ needs for
                        socialization.
                      </span>
                    </li>
                    <li>
                      <span>
                        Maintain residents’ rooms in neat, orderly and
                        clutter-free manner.
                      </span>
                    </li>
                    <li>
                      <span>
                        Promote and maintain positive relationships with
                        co-workers, residents, visitors, volunteers, and
                        regulators.
                      </span>
                    </li>
                    <li>
                      <span>
                        Directly respond, within the scope of practice, to needs
                        and concerns of residents and family members.{" "}
                      </span>
                    </li>
                    <li>
                      <span>
                        Work with the care giving team to identify and resolve
                        resident and family issues.
                      </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <span>
                        Promptly respond to call lights and other resident
                        needs.
                      </span>
                    </li>
                    <li>
                      <span>Treat residents with compassion and dignity.</span>
                    </li>
                    <li>
                      <span>
                        Interact professionally with residents and their
                        families and involves resident/family in the formation
                        of the plan of care.
                      </span>
                    </li>
                    <li>
                      <span>
                        Maintain a good working relationship both within the
                        department and with other departments.
                      </span>
                    </li>
                    <li>
                      <span>
                        Establish effective communication/interactions with
                        residents and families.
                      </span>
                    </li>
                    <li>
                      <span>
                        Ensure residents’ right to privacy (visual and
                        auditory).
                      </span>
                    </li>
                    <li>
                      <span>Show respect for residents’ personal property.</span>
                    </li>
                    <li>
                      <span>
                        Recognize a resident’s ability to make choices and
                        support these within the constraints of the care giving
                        environment.
                      </span>
                    </li>
                    <li>
                      <span>
                        Take and record temperature, pulse, respirations and
                        blood pressure.
                      </span>
                    </li>
                    <li>
                      <span>Ambulate residents.</span>
                    </li>
                    <li>
                      <span>Apply proper lifting and transferring techniques.</span>
                    </li>
                    <li>
                      <span>
                        Provide proper positioning and use of postural supports.
                      </span>
                    </li>
                    <li>
                      <span>
                        Ensure residents’ comfort while assisting them in
                        achieving their highest practicable level of
                        functioning.
                      </span>
                    </li>
                    <li>
                      <span>
                        Meet current documentation standards and policies and
                        complete within shift.
                      </span>
                    </li>
                    <li>
                      <span>
                        Report significant change in a resident’s condition to
                        the facilities Nurse Manager and the care giving team.
                      </span>
                    </li>
                    <li>
                      <span>Maintain resident confidentiality at all times.</span>
                    </li>
                  </ul>
                  <br></br>
                  <h4 className="same-small-head">Safeguarding and Safety</h4>
                  <ul>
                    <li>
                      <span>
                        To ensure that residents are safeguarded against the
                        risk of abuse. This includes but is not limited to: a
                        zero tolerance for harassment and violence; following
                        infection control procedures; following safe patient
                        handling as required with your specific job position.
                      </span>
                    </li>
                    <li>
                      <span>
                        Demonstrate safe practices in regard to resident’s
                        comfort and safety by applying knowledge of proper body
                        alignment for self and resident. Practice and promote
                        adherence to institutional safety codes.
                      </span>
                    </li>
                    <li>
                      <span>
                        Comply with all facility safety policies, regulations
                        and requirements regarding fire and life safety.
                      </span>
                    </li>
                    <li>
                      <span>
                        Meet the general health requirements set forth by the
                        policies of the facility.
                      </span>
                    </li>
                  </ul>
                  <br></br>
                  <h4 className="same-small-head">
                    Other Functions and Responsibilities
                  </h4>
                  <ul>
                    <li>
                      <span>Flexibility and ability to work under pressure.</span>
                    </li>
                    <li>
                      <span>The ability to prioritize a busy workload.</span>
                    </li>
                    <li>
                      <span>
                        Must adhere to Code of Conduct and Business Ethics
                        policy, including documentation and reporting
                        responsibilities.
                      </span>
                    </li>
                    <li>
                      <span>
                        This is a position in health care facilities open 24
                        hours a day, 365 days a year. Shifts on holidays and
                        working during inclement weather may be required.
                      </span>
                    </li>
                    <li>
                      <span>
                        Complete and submit all timesheets correctly and in a
                        timely manner to enable authorization and payment.
                      </span>
                    </li>
                    <li>
                      <span>Complete annual education requirements.</span>
                    </li>
                    <li>
                      <span>Wear proper identification.</span>
                    </li>
                    <li>
                      <span>Report to assignment on time and as scheduled.</span>
                    </li>
                  </ul>
                  <h4 className="same-head">
                    Physical and Sensory Requirements
                  </h4>
                  <p>This position is very active and requires:</p>
                  <ul>
                    <li>
                      <span>
                        Walking, standing, reaching, bending, crouching,
                        grasping, pushing, pulling, and lifting.
                      </span>
                    </li>
                    <li>
                      <span>
                        The ability to work at a rapid apace for an entire
                        shift.
                      </span>
                    </li>
                    <li>
                      <span>
                        The employee must frequently lift or move objects and
                        patients weighing up to 40 pounds. Following patient
                        care plan and using a variety of equipment, the employee
                        may frequently move, reposition and transfer residents
                        of varying weights up to 300 lbs.
                      </span>
                    </li>
                    <li>
                      <span>
                        Must have a finger and hand dexterity sufficient to
                        type, handle and manipulate instruments and operate
                        departmental equipment.
                      </span>
                    </li>
                    <li>
                      <span>
                        Have a working understand of English: ability to read
                        and write; ability to understand and follow written and
                        oral instructions; ability to verbally communicate with
                        residents and others; ability to hear and respond to
                        pages.
                      </span>
                    </li>
                    <li>
                      <span>
                        Ability to remain calm in emergency situations and when
                        handling multiple tasks.
                      </span>
                    </li>
                  </ul>
                  <br></br>
                  <h4 className="same-head">
                    QUALIFICATIONS AND EDUCATION REQUIREMENTS
                  </h4>
                  <ul>
                    <li>
                      <span>
                        Must be a Certified Nursing Assistant in good standing
                        with the state, or must within four (4) months of
                        employment successfully complete state-required and
                        competency evaluation program. Must maintain a current
                        certification in the state throughout employment.
                      </span>
                    </li>
                    <li>
                      <span>
                        Must achieve 12 hours of in service education annually
                        as required by federal regulations (as well as meet
                        state requirements, if exceeds federal).
                      </span>
                    </li>
                    <li>
                      <span>
                        Proven ability to communicate effectively and positively
                        and favorably to residents and families and to work
                        cooperatively with facility staff and supervisors.
                      </span>
                    </li>
                    <li>
                      <span>
                        Must have ability to read, write and follow oral and
                        written directions at a level necessary to accomplish
                        the job.
                      </span>
                    </li>
                    <li className="">
                      <span>Must be capable of maintaining regular attendance.</span>
                    </li>
                
                    <li>
                      <span>
                        Must meet all local health regulations. This includes
                        drug screening, criminal background investigation and
                        reference inquiry.
                      </span>
                    </li>
                  </ul>
                  <br></br>
                  <h2 className="heading-line">JOB DESCRIPTION REVIEW:</h2>
                  <span>
                    I understand this job description and its requirements, and
                    that I am expected to complete all duties as assigned. I
                    understand the job functions may be altered from time to
                    time. I further understand that the purpose of this job
                    description is to identify the major duties and
                    responsibilities of the job and this is not intended to
                    contain each and every inherent in this job. I have noted
                    below any accommodations that are required to enable me to
                    perform these duties. I have also noted below any job
                    responsibilities or functions which I am unable to perform,
                    with or without accommodation.
                  </span>
                </div>
                <table className="table table-all bordered">
                  <tbody>
                    <tr>
                      <td width="20%" className="head not-bold">
                        Employee's<br></br> Signature
                      </td>
                      <td className="input" width="20%">
                        <div> {props?.signature ? <img src={props?.signature} width={180} height={50}  className="w-100 rounded-0" alt="Signature here" /> : <div class="badge badge-primary text-wrap pointer" onClick={props?.onOpenModal}>
                          Click Here To Sign
                        </div>}</div>
                      </td>
                      <td width="20%" className="head not-bold">
                        Date:
                      </td>
                      <td className="input">
                        <div><div><input type='text' value={moment(new Date()).format("MM-DD-YYYY HH:mm:ss")} disabled /></div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width="20%" className="head not-bold">
                        Supervisor's<br></br> Signature
                      </td>
                      <td width="20%" className="input">
                        <div>   </div>
                      </td>
                      <td width="20%" className="head not-bold">
                        Date:
                      </td>
                      <td width="20%" className="input">
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="border-box mt-3">
                <div className="back-grad">
                  <p className=" para-underline">Accomodation Needed:</p>
                  <p className=" para-underline">
                    Job Responsibilities I am unable to perform:
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
