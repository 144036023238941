import { configure, makeAutoObservable, runInAction, toJS } from "mobx"
import { toast } from "react-toastify"
import { axiosInstance, axiosInstanceInt, setAuthToken } from "../api/axiosInstance"

class HomeStore {
  loading = false
  data = {
    dropDown: [],
    jobs: [],
    degreedropDown: [],
    informationSource: [],
    contactDevices: [],
    dashboardData: [],
    blueSkyCaregiverDetails: null,
    degreeList: []
  }
  errors = {}
  constructor() {
    makeAutoObservable(this, {
      degreeList: true
    });
    configure({
      useProxies: "never"
    })
  }
  toggleLoading = (toggle) => {
    this.loading = toggle
  }

  async getDashboardData() {
    try {
      const response = await axiosInstanceInt.get('/dashboard')
      if (response) {

        runInAction(() => {
          this.data.dashboardData = response?.data?.data;
          this.data.degreeList = JSON.parse(response?.data?.data?.find(x => x.key_name === 'DEGREES')?.value);
        })
      } else {
        console.log(response);
      }
    }
    catch (err) {
      console.log("logineere", err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async getDegreeListData() {
    setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get('api/Contractor/DegreeList')
      if (response) {
        runInAction(() => {
          this.data.degreedropDown = response.data;
        })
      } else {
        console.log(response);
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log("logineere", err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async getSpecialtiesListData(id) {
    setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get(`api/Contractor/SpecialtiesList/${id}`)
      if (response) {
        runInAction(() => {
          this.data.dropDown = response.data;
        })
        return response.data;
      } else {
        console.log("No Response");
      }
    }
    catch (err) {
      console.log("logineere", err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async getInformationSource() {
    setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get('api/Contractor/source/list')
      if (response) {
        runInAction(() => {
          this.data.informationSource = response.data;
        })
      } else {
        console.log(response);
      }
    }
    catch (err) {
      console.log("logineere", err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async getContactDevices() {
    setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get('api/Contractor/phoneType/list')
      if (response) {
        runInAction(() => {
          this.data.contactDevices = response.data;
        })
      } else {
        console.log(response);
      }
    }
    catch (err) {
      console.log("logineere", err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async getCaregiverDegreeData(id) {
    this.toggleLoading(true);
    try {
      setAuthToken(global.config.token)
      const response = await axiosInstance.get(`/api/contractor/${id}/degrees`)
      if (response.data !== "") {
        return response?.data;
      } else {
        console.log(response);
        // toast.error("Someting went to wrong!");
      }
    }
    catch (err) {
      console.log(err);
      // toast.error("Someting went to wrong!");
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async getCaregiverDetails(id) {
    // this.toggleLoading(true);
    try {
      setAuthToken(global.config.token)
      const response = await axiosInstance.get(`/api/Contractor/get/${id}`)
      if (response.data !== "") {
        runInAction(() => {
          this.data.blueSkyCaregiverDetails = response?.data;
        })
        return response?.data;
      } else {
        console.log(response);
        // toast.error("Someting went to wrong!");
      }
    }
    catch (err) {
      console.log(err);
      // toast.error("Someting went to wrong!");
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async referenceResponse(values, navigationCallBack) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('/referenceResponse', values)
      if (response.status === 200) {
        navigationCallBack(response?.data)
      } else {
        console.log("other");
      }
    }
    catch (err) {
      console.log(err);
      if (err.response.status !== 500) {
        toast.error(err?.response?.data?.message);
      } else {
        toast.error("Something went to wrong!");
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }


}

export default new HomeStore()
