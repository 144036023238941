/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Header from '@components/Header';
import { Helmet } from 'react-helmet';
import 'react-spring-bottom-sheet/dist/style.css';
import StaffsStore from "@store/StaffsStore";
import { toJS } from 'mobx';
import { observer } from "mobx-react"
import { NavLink } from 'react-router-dom';
import Spinner from "@components/Spinner";

const MyTeamsPage = observer(() => {
    window.onscroll = function () { };
    const [myTeamList, setMyTeamList] = useState([]);

    useEffect(() => {
        StaffsStore.getMyTeam();
    }, [])

    useEffect(() => {
        setMyTeamList(toJS(StaffsStore.data.myTeamList))
    }, [StaffsStore?.data?.myTeamList]);

    return (
        <React.Fragment>
            <Header />
            <Helmet>
                <title>Staffers LLC | My Teams</title>
            </Helmet>
            <>
                <div className="container-fluid callback-small mt-5 py-5">
                    <div className="container pt-lg-5" >
                        <h5 className="display-5 mb-4 animated slideInDown login-head" style={myTeamList?.length === 0 ? { paddingBottom: "10%" } : {}}>
                            My Teams</h5>
                    </div>
                    <div className="col-lg-12">
                        <div className="container">
                            <div className="row wow bounceInUp d-flex justify-content-center">
                                {myTeamList?.length === 0 && <p className='d-flex justify-content-center'>There are no teams in records.</p>}
                                {
                                    myTeamList?.map((team, index) => {
                                        return <div className="col-sm-6 col-md-6 col-lg-4" key={index + "team"}>
                                            <div className="card bg-white p-3 mb-4 shadow">
                                                <div className="d-flex justify-content-between mb-4">
                                                    <div className="user-info">
                                                        <div className="user-info__img">
                                                            {/* <img src="img/user1.jpg" alt="User Img" /> */}
                                                        </div>
                                                        <div className="user-info__basic">
                                                            <h5 className="mb-0"> {team?.first_name} {team?.last_name}</h5>
                                                            <p className="text-muted mb-0">{team?.organization_name}, {team?.organization_type}</p>
                                                        </div>
                                                    </div>
                                                    <div className="dropdown open">
                                                        <a href="#" className="px-2" id="triggerId1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i className="fa fa-ellipsis-v"></i>
                                                        </a>
                                                        <div className="dropdown-menu" aria-labelledby="triggerId1">
                                                            <NavLink to={{ pathname: '/applied-shifts'}} state={{ organization_name:team?.organization_name }}  ><i className="fas fa-user-clock text-success"></i>  View Shifts</NavLink>
                                                        {/* <a className="dropdown-item text-danger" href="#"><i className="fa fa-trash mr-1"></i> Delete</a> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <h6 className="mb-2"><a href={"tel:"+team?.contact}> <i className="fas fa-phone-alt"></i>  {team?.country_code} {team?.contact}</a></h6>
                                                    <h6 className="mb-0"><a href={"mailto:"+team?.email}><i className="fas fa-envelope"></i>  {team?.email}</a></h6>
                                                </div>
                                                <span className="text-success font-weight-bold mt-3">{team?.designation}</span>
                                            </div>
                                        </div>
                                        </div>
                                    })
                                }

                        </div>
                    </div>
                </div>

            </div>
            <Spinner isLoading={StaffsStore.loading} />

        </>

        </React.Fragment >
    )
})

export default MyTeamsPage;