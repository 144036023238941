/* eslint-disable */
import React, { } from "react";
import Skeleton from "react-loading-skeleton";

const JobItemSkeleton = (props) => {
    return (
        <div className="grid__item">
            <a className="" >
                <div className='flip-card-inner'>
                    <div className="flip-card-front">
                        <div className="front-side">
                            <div className="info-grid" style={{background: "bottom"}} >
                                <div className="name">
                                    <div className="flip-text">
                                        <div>
                                            <Skeleton height={30} />
                                        </div>
                                    </div>
                                    <Skeleton className="info-text" height={10} />
                                    <Skeleton className="info-text" height={10} />
                                    <Skeleton className="info-text" height={10} />
                                    <Skeleton className="info-text" height={10} />
                                    <Skeleton className="info-text" height={10} />

                                </div>
                                <div className="addr">
                                </div>
                                <div className="vaccinated">
                                    <Skeleton className="m-2" circle={true} height={50} width={50} />
                                </div>
                                <div className="emailId pb-2">
                                    <p className="email">
                                        <strong></strong></p>
                                    <Skeleton className="info-text" height={20} width={100} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </a>
        </div>
        // <div className="col-lg-4 col-md-4 col-xs-12 card shadow m-4 w-50">
        //     <div className="job-featured front-side-sekelton">
        //         <div className="icon">
        //             <Skeleton circle={true} height={50} width={50} />
        //         </div>
        //         <div className="content">
        //             <h3>
        //                 <Skeleton height={10} />
        //             </h3>
        //             <p className="brand">
        //                 <Skeleton height={10} />
        //             </p>
        //             <div className="tags">
        //                 <span><Skeleton height={10} /></span>
        //                 <span><Skeleton height={10} /></span>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    );
};

export default JobItemSkeleton;
