import React from "react";
import Skeleton from "react-loading-skeleton";
import Ticker from "./Ticker";
import WebsiteStore from "../../store/WebsiteStore";

const StatsSection = ({ sectionData }) => {
  const isLoading = WebsiteStore.loading;

  return (
    <div className="stats-section">
      <div className="container">
        <div className="row">
          {[0, 1, 2, 3].map((index) => (
            <div className="col-lg-3 col-md-6" key={index}>
              <div
                className="stats-content text-center"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="200"
              >
                <h2 className="common-web-head">
                  <div className="d-flex align-items-center justify-content-center">
                    {isLoading ? (
                      <Skeleton width={80} height={40} />
                    ) : (
                      <React.Fragment>
                        <Ticker
                          end={sectionData?.[index * 3] || 100}
                          suffix={index !== 3 && index !== 1 ? "K" : ""}
                        />
                        {<sup>+</sup>}
                      </React.Fragment>
                    )}
                  </div>
                </h2>
                <h6 className="common-web-third">
                  {isLoading ? (
                    <Skeleton width={120} />
                  ) : (
                    sectionData?.[index * 3 + 1] || `Completed Shifts`
                  )}
                </h6>
                <p className="common-web-para">
                  {isLoading ? (
                    <Skeleton width={200} count={2} />
                  ) : (
                    sectionData?.[index * 3 + 2] ||
                    `Tracking Success, One Shift at a Time`
                  )}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StatsSection;
