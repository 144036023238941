import React from 'react';
import { dropdownCalendarStatusStyles } from '@utils/customStyle/style';
import Select, { components } from 'react-select';

const ToolbarDropdown = ({selectedOption, slotStatusList, handleSelectChange, placeholder, isMulti, handleClearAction,labelProperty,valueProperty}) => {
    return (
        <div className="d-flex ms-1">
                <Select
                    id="selectInput"
                    placeholder={placeholder}
                    options={slotStatusList}
                    onChange={handleSelectChange} 
                    value={selectedOption}
                    styles={dropdownCalendarStatusStyles}
                    isMulti={isMulti}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{ Control, MultiValue }}
                    getOptionLabel={(option) => option[labelProperty]} 
                    getOptionValue={(option) => option[valueProperty]}
                />
            {!isMulti && <div className=" ms-2">
                <button className='btn btn-danger' onClick={handleClearAction}>Clear</button>
            </div>}
        </div>
    ); 
};

export default ToolbarDropdown;
export const Control = (props) => {
    return (
        <>
            <label className={props.hasValue || props.isFocused ? props?.selectProps?.isRequired ? "did-floating-label did-floating-label-top did-floating-labe-astrisk" : "did-floating-label did-floating-label-top" : props?.selectProps?.isRequired ? "did-floating-label did-floating-label did-floating-labe-astrisk" : "did-floating-label did-floating-label"} style={{ zIndex: 100 }}>{props?.selectProps?.label}</label>
            <components.Control {...props} />
        </>
    );
};
const MoreSelectedBadge = ({ items }) => {
    const style = {
        marginLeft: "auto",
        background: "#d4eefa",
        borderRadius: "4px",
        fontFamily: "Open Sans",
        fontSize: "11px",
        padding: "3px",
        order: 99
    };

    const title = items.join(", ");
    const length = items.length;
    const label = `+ ${length} item${length !== 1 ? "s" : ""}`;

    return (
        <div style={style} title={title}>
            {label}
        </div>
    );
};
const MultiValue = ({ index, getValue, ...props }) => {
    const maxToShow = 2;
    const overflow = getValue()
        .slice(maxToShow)
        .map((x) => x.label);

    return index < maxToShow ? (
        <components.MultiValue {...props} />
    ) : index === maxToShow ? (
        <MoreSelectedBadge items={overflow} />
    ) : null;
};