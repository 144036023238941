import React, { useEffect, useState } from "react";
import WebsiteLayout from "../components/WebsiteLayout";
import TrendingJobsSlider from "../components/TrendingJobsSlider";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import WebsiteStore from "../../store/WebsiteStore";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
import ReactGA from "react-ga";
import moment from "moment";

const JobDetails = observer(() => {
  const [isPageLoading, setIsPageLoading] = useState(false);
  const navigate = useNavigate();

  const [featuredLocationData, setFeaturedLocationData] = useState([]);
  const [jobDetailsData, setJobDetailsData] = useState(null);

  const params = useParams();

  useEffect(() => {
    setIsPageLoading(true);
    WebsiteStore.getFeaturedLocations();
    WebsiteStore.getJobDetails(params?.jobId);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [params?.jobId]);

  useEffect(() => {
    setIsPageLoading(true);
    if (WebsiteStore.data.featuredLocations?.length > 0) {
      setFeaturedLocationData(toJS(WebsiteStore.data.featuredLocations));
    }
    setIsPageLoading(false);
  }, [WebsiteStore.data.featuredLocations]);

  useEffect(() => {
    setIsPageLoading(true);
    if (WebsiteStore.data.jobDetails) {
      setJobDetailsData(toJS(WebsiteStore.data.jobDetails));
    }
    setIsPageLoading(false);
  }, [WebsiteStore.data.jobDetails]);

  const metaData = {
    title: "Job Details",
    description: "Explore job details and apply now.",
  };

  const structuredData = {
    "@context": "https://schema.org/",
    "@type": "JobPosting",
    title: jobDetailsData?.degree
      ? JSON.parse(jobDetailsData?.degree)?.Name
      : "Job Title",
    description: jobDetailsData?.description?.replace(/<\/?[^>]+(>|$)/g, "") || "Job description not available",
    identifier: {
      "@type": "PropertyValue",
      name: "STAFFERS",
      value: params?.jobId,
    },
    datePosted: moment(jobDetailsData?.created_at)?.format(
      "YYYY-MM-DD"
    ) ||  moment()?.format(
      "YYYY-MM-DD"
    ),
    validThrough: jobDetailsData?.valid_through || "2024-12-31",
    employmentType: jobDetailsData?.employment_type || "FULL_TIME",
    hiringOrganization: {
      "@type": "Organization",
      name: "STAFFERS",
      sameAs: "https://www.staffers.com",
      logo: "https://www.staffers.com/logo.png",
    },
    jobLocation: {
      "@type": "Place",
      address: {
        "@type": "PostalAddress",
        streetAddress: jobDetailsData?.address || "123 Main St",
        addressLocality: jobDetailsData?.city || "Anytown",
        addressRegion: jobDetailsData?.state || "CA",
        postalCode: jobDetailsData?.postal_code || "12345",
        addressCountry: "US",
      },
    },
    baseSalary: {
      "@type": "MonetaryAmount",
      currency: "USD",
      value: {
        "@type": "QuantitativeValue",
        minValue: jobDetailsData?.pay_details_min || 0,
        maxValue: jobDetailsData?.pay_details_max || 0,
        unitText: "HOUR",
      },
    },
    jobBenefits: jobDetailsData?.benefits
      ? jobDetailsData?.benefits.replace(/<\/?[^>]+(>|$)/g, "")
      : "Standard benefits",
    qualifications:
      jobDetailsData?.qualifications || "Qualifications not specified",
    responsibilities:
      jobDetailsData?.responsibilities || "Responsibilities not specified",
    educationRequirements:
      jobDetailsData?.education || "Education requirements not specified",
    experienceRequirements:
      jobDetailsData?.experience || "Experience not specified",
    industry: "Healthcare",
    occupationalCategory: "Healthcare Practitioners and Technical Occupations",
    workHours: jobDetailsData?.shift_type
      ? JSON.parse(jobDetailsData?.shift_type).join(", ")
      : "Shift details not available",
    jobLocationType: jobDetailsData?.contract_type || "Onsite",
  };

  return (
    <div>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <WebsiteLayout>
        <div className="job-details-main-section">
          <div className="container">
            <div className="job-left-describe">
              <div className="job-detail-flex">
                <div>
                  <h3 className="common-web-head mb-2 text-left">
                    {isPageLoading ? (
                      <Skeleton width={200} />
                    ) : jobDetailsData?.degree ? (
                      JSON.parse(jobDetailsData?.degree)?.Name
                    ) : (
                      "-"
                    )}
                  </h3>
                  <p>Job ID : {params?.jobId}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="job-box">
                    {isPageLoading ? (
                      <Skeleton height={200} />
                    ) : (
                      <div className="image-hover">
                        <img
                          src={`/assets/img/statepics/${
                            jobDetailsData?.state
                              ? jobDetailsData?.state.toLowerCase()
                              : "al"
                          }.png`}
                          alt="state"
                          className="img-fluid"
                        />
                        {jobDetailsData?.is_exclusive === 1 && (
                          <p>
                            Host Staffers has exclusive access to this job, so
                            you get top priority when applying
                          </p>
                        )}
                      </div>
                    )}

                    <div>
                      <ul className="nav nav-tabs list-unstyled">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            data-bs-toggle="tab"
                            href="#menu1"
                          >
                            Job Details
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-bs-toggle="tab"
                            href="#menu2"
                          >
                            Perks & Benefits
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-bs-toggle="tab"
                            href="#menu3"
                          >
                            Requirements
                          </a>
                        </li>
                      </ul>
                      {/* Tab panes */}
                      <div className="tab-content">
                        <div id="menu1" className=" tab-pane active">
                          <h3 className="common-web-para mb-4 text-left border-bottom-1">
                            {jobDetailsData?.description ? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: jobDetailsData?.description,
                                }}
                              />
                            ) : (
                              "-"
                            )}
                          </h3>
                          <div className="row">
                            <div className="col-md-8">
                              <ul className="new-listing">
                                <li>
                                  <p className="text-left-content">
                                    Discipline
                                  </p>
                                  <p>
                                    <b>
                                      {jobDetailsData?.degree
                                        ? JSON.parse(jobDetailsData?.degree)
                                            ?.Name
                                        : "-"}
                                    </b>
                                  </p>
                                </li>
                                {jobDetailsData?.speciality && (
                                  <li>
                                    <p className="text-left-content">
                                      Speciality
                                    </p>
                                    <p>
                                      <b>
                                        {jobDetailsData?.speciality
                                          ? JSON.parse(
                                              jobDetailsData?.speciality
                                            )?.Name
                                          : "-"}
                                      </b>
                                    </p>
                                  </li>
                                )}
                                <li>
                                  <p className="text-left-content">
                                    Destination
                                  </p>
                                  <p>
                                    <b>
                                      {jobDetailsData?.city
                                        ? jobDetailsData?.city
                                        : "-"}
                                      ,{" "}
                                      {jobDetailsData?.state
                                        ? jobDetailsData?.state
                                        : "-"}
                                    </b>
                                  </p>
                                </li>
                                <li>
                                  <p className="text-left-content">Shift</p>
                                  <p>
                                    <b>
                                      {jobDetailsData?.shift_type &&
                                        JSON.parse(jobDetailsData?.shift_type)
                                          ?.map((x, idx) => {
                                            return x;
                                          })
                                          .join(", ")}
                                    </b>
                                  </p>
                                </li>
                                {jobDetailsData?.contract_type ===
                                  "Travel Contract" && (
                                  <li>
                                    <p className="text-left-content">
                                      Contract length
                                    </p>
                                    <p>
                                      <b>{jobDetailsData?.length_weeks}</b>
                                    </p>
                                  </li>
                                )}
                                <li>
                                  <p className="text-left-content">Employer</p>

                                  {/* <i class="fas fa-unlock-alt"></i> */}
                                  {/* <Link
                                  onClick={() => {
                                    WebsiteStore.data.storedJobId =
                                      params?.jobId;
                                    localStorage.setItem(
                                      "jobId",
                                      params?.jobId
                                    );
                                    navigate("/register/employee");
                                  }}
                                  to={
                                    (WebsiteStore.data.storedJobId =
                                      params?.jobId &&
                                      "/register/employee")
                                  }
                                >
                                  Sign up to view
                                </Link> */}
                                  <div>
                                    {jobDetailsData?.paycom_url ? (
                                      <a
                                        className="common-web-btn purple-back btn-11"
                                        href={jobDetailsData?.paycom_url}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <i className="fas fa-user-lock mx-2"></i>
                                        Apply
                                      </a>
                                    ) : (
                                      <button
                                        className="common-web-btn purple-back btn-11"
                                        onClick={() => {
                                          WebsiteStore.data.storedJobId =
                                            params?.jobId;
                                          localStorage.setItem(
                                            "jobId",
                                            params?.jobId
                                          );
                                          navigate("/register/employee");
                                        }}
                                      >
                                        <i className="fas fa-user-lock mx-2"></i>
                                        Sign up to apply
                                      </button>
                                    )}
                                  </div>
                                </li>
                                <li>
                                  <p className="text-left-content">
                                    Estimated Total Pay
                                  </p>
                                  <p>
                                    <b>
                                      ${jobDetailsData?.pay_details_min} to $
                                      {jobDetailsData?.pay_details_max}/hr
                                    </b>
                                  </p>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <hr />
                          <div>
                            <p>
                              *Estimated taxable pay includes estimates wages of
                              ${jobDetailsData?.pay_details_min} - $
                              {jobDetailsData?.pay_details_max}/hr
                            </p>
                            <p>
                              **Actual amount dependent on seasonal adjustment
                            </p>
                          </div>
                        </div>
                        <div id="menu2" class=" tab-pane fade">
                          <p>
                            The benefits of taking a job with <em>STAFFERS</em>{" "}
                            include:
                          </p>
                          <br></br>
                          {jobDetailsData?.benefits ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: jobDetailsData.benefits,
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </div>
                        <div id="menu3" className=" tab-pane fade">
                          <p>What we are looking from you:</p>
                          <br></br>
                          <div
                            className="list-block"
                            dangerouslySetInnerHTML={{
                              __html:
                                jobDetailsData?.qualifications ||
                                "No Records Found",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <TrendingJobsSlider featuredLocationData={featuredLocationData} />
          <div className="new-extra-space">.</div>
        </div>
      </WebsiteLayout>
    </div>
  );
});

export default JobDetails;
