import { Formik, Form } from 'formik';
import { InputField } from "@elements/index"
import React, { useState } from 'react';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import swal from '@sweetalert/with-react';
import HomeStore from '@store/HomeStore';
import Spinner from "@components/Spinner";
import { observer } from 'mobx-react-lite';
import { CFormCheck } from '@coreui/react-pro';
import { BottomSheet } from 'react-spring-bottom-sheet';
import html2pdf from 'html2pdf.js';
import { Helmet } from 'react-helmet';

const validationSchemaStepOne = Yup.object({
    designation: Yup.string().required('Required'),
    workplace: Yup.string().required('Required'),
    timeFrameStart: Yup.string().required('Required'),
    timeFrameEnd: Yup.string().required('Required'),
});
const validationSchemaStepTwo = Yup.object({
    healthcareSkills: Yup.number().required('Required').min(1, 'Must be at least 1').max(5, 'Must be at most 5'),
    careForOthers: Yup.number().required('Required').min(1, 'Must be at least 1').max(5, 'Must be at most 5'),
    stressHandling: Yup.number().required('Required').min(1, 'Must be at least 1').max(5, 'Must be at most 5'),
});
const validationSchemaStepThree = Yup.object({
    strengthsOne: Yup.string().required('Required'),
    strengthsTwo: Yup.string().required('Required'),
    weaknessesOne: Yup.string().required('Required'),
    weaknessesTwo: Yup.string().required('Required'),
    hireAgain: Yup.string().required('Required'),
});
const containerStyle = {
    textAlign: "center",
    width: "100%",
    height: "130vh",
    margin: 0,
    padding: 0,
    backgroundImage: "url(assets/img/carousel-1.png)",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",

};
const employeeReviewData = {
    designation: 'Position when worked with employee?',
    workplace: 'Where did you work with them?',
    timeFrame: 'During what time frame did you work with them?',
    healthcareSkills: "How would you rate their healthcare worker skills on a scale of 1-5?",
    careForOthers: "How would you rate their ability to care for others on a scale of 1-5?",
    stressHandling: "How would you rate their ability to handle stressful situations on a scale of 1-5?",
    strengths: 'Please provide 2 strengths:',
    weaknesses: 'Please provide 2 weaknesses or areas of improvement:',
    hireAgain: 'Would you work with or hire them again?',
    attendance: "how would you rate their attendance and punctuality on a scale of 1-5"
};
const ReferenceReview = observer(() => {
    const [step, setStep] = useState(0);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [isShaking, setIsShaking] = useState(false);
    const [pdfData, setPdfData] = useState([]);

    const initialValues = {
        designation: '',
        workplace: '',
        timeFrameStart: '',
        timeFrameEnd: '',
        healthcareSkills: '',
        careForOthers: '',
        stressHandling: '',
        strengthsOne: '',
        strengthsTwo: '',
        weaknessesOne: '',
        weaknessesTwo: '',
        hireAgain: '',
        attendance: ''
    };

    const onSubmit = (values) => {
        if (step === 2) {
            const questionAnswerPairs = generateQuestionAnswerPairs(values, employeeReviewData);
            const param = {
                'enc_id': searchParams.get('id'),
                'response': JSON.stringify(questionAnswerPairs)
            }
            // ?.slice(1, -1)
            HomeStore.referenceResponse(param, navigationCallBack)
        }
    };



    const navigationCallBack = (data) => {
        // setPdfData(data?.total)
        swal("Success!", `Thank you for taking the time to share your valuable insights about ${searchParams.get('name')}'s performance and work ethic. Your input has been immensely helpful in our hiring process.`, "success");
        window.location.replace('/');
        // if (data?.total?.length === 3) {
        //     if (data?.fill >= 2) {
        //         setPdfData(data?.total)
        //     }
        // } else if (data?.total?.length === 2) {
        //     if (data?.fill === 2) {
        //         setPdfData(data?.total)
        //     }
        // } else if (data?.total?.length === 1) {
        //     if (data?.fill === 1) {
        //         setPdfData(data?.total)
        //     }
        // } else {

        // }
    }
    const nextStep = (errors) => {
        if (Object.keys(errors).length !== 0) {
            setIsShaking(true);

            // Reset the shaking animation after 500ms (or the animation duration)
            setTimeout(() => {
                setIsShaking(false);
            }, 500);
            return
        }
        setStep(step + 1);
    }
    const previousStep = () => {
        setStep(step - 1);
    }
    // Function to generate question-answer pairs
    function generateQuestionAnswerPairs(data, template) {
        const pairs = [];
        for (const key in template) {
            if (key === "strengths") {
                pairs.push({
                    question: template[key],
                    answer: `${data["strengthsOne"]},${data["strengthsTwo"]}`,
                });
            } else if (key === "weaknesses") {
                pairs.push({
                    question: template[key],
                    answer: `${data["weaknessesOne"]},${data["weaknessesTwo"]}`,
                });
            } else if (key === "timeFrame") {
                pairs.push({
                    question: template[key],
                    answer: `${data["timeFrameStart"]} To ${data["timeFrameEnd"]}`,
                });
            } else {
                pairs.push({
                    question: template[key],
                    answer: data[key],
                });
            }
        }

        return pairs;
    }
    const sendToBluesky = (refData) => {
        const element = document.getElementById('reference-content');
        const options = {
            margin: [10, 10, 10, 10],
            filename: 'references.pdf',
            image: { type: 'jpeg', quality: 0.95 },
            jsPDF: { format: 'a4', orientation: 'portrait' },
            pagebreak: { mode: 'css', before: 'table' },
        };
        // Convert HTML to PDF
        html2pdf().from(element).set(options).output('blob').then((pdfBlob) => {
            console.log("convert pdf....");
            // // Create a download link
            // const downloadLink = document.createElement("a");
            // downloadLink.href = URL.createObjectURL(pdfBlob);
            // console.log(downloadLink);
            // // Trigger a click event on the link to start the download
            // // downloadLink.click();
            // return
            let file = { 'doc_id': 77, "file": new File([pdfBlob], `${"reference_form"}.pdf`, { type: 'application/pdf' }, "bin") }
            let param = {
                doc_list: [file],
                staff_id: refData?.id
            }
            // AdminStore.saveSignedDocuments(param, navigationCallBackFinal)
        });
    }
    const navigationCallBackFinal = (res) => {
        let param = {
            request_id: res
        }
        // AdminStore.uploadSignedDocuments(param, navigationCallBackAddDoc)
    }
    const navigationCallBackAddDoc = () => {
        swal("Success!", `Thank you for taking the time to share your valuable insights about ${searchParams.get('name')}'s performance and work ethic. Your input has been immensely helpful in our hiring process.`, "success");
        window.location.replace('/');
    }
    return (

        <div style={containerStyle}>
            <Helmet>
                <title>Staffers LLC | Reference Response</title>
            </Helmet>
            <div className="container">
                {/* <!-- progressbar --> */}

                <Formik
                    validateOnChange={false}
                    initialValues={initialValues}
                    validationSchema={
                        step == 2
                            ? validationSchemaStepThree
                            : step == 1
                                ? validationSchemaStepTwo
                                : validationSchemaStepOne
                    }
                    onSubmit={onSubmit}
                >
                    {
                        ({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched, resetForm
                        }) =>
                        (
                            <Form  >
                                <h2 className='text-white text-uppercase pt-5'>Tell us about your experience working with {searchParams.get('name')}.</h2>
                                <div className={`${isShaking ? 'shake' : ''}`}>
                                    <div className="container mt-sm-5 my-1" style={styles.container}>
                                        <ul id="progressbar" className='pt-2'>
                                            <li className={step >= 0 ? 'active' : ''}></li>
                                            <li className={step >= 1 ? 'active' : ''}></li>
                                            <li className={step >= 2 ? 'active' : ''}></li>
                                        </ul>
                                        {step === 0 && <>
                                            <div className="">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="py-3 h5 text-primary"><b>Q. {employeeReviewData?.designation}</b></div>

                                                            <div className="d-flex justify-content-center">
                                                                <CFormCheck
                                                                    className={`me-3 text-dark`}
                                                                    autoComplete="off"
                                                                    id="Supervisor"
                                                                    name="designation"
                                                                    value="Supervisor/Manager"
                                                                    label="Supervisor/Manager"
                                                                    checked={values.designation == 'Supervisor/Manager'}
                                                                    onChange={(e) => setFieldValue("designation", e.target.value)}

                                                                />
                                                                <CFormCheck
                                                                    className={`ms-2 text-dark`}
                                                                    autoComplete="off"
                                                                    id="Coworker"
                                                                    name="designation"
                                                                    value="Coworker/Peer"
                                                                    label="Coworker/Peer"
                                                                    onChange={(e) => setFieldValue("designation", e.target.value)}
                                                                    checked={values.designation == 'Coworker/Peer'}

                                                                />
                                                            </div>
                                                            {errors.designation && touched.designation ? (
                                                                <div className="error-message">{errors.designation}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="py-3 h5 text-primary "><b>Q. {employeeReviewData?.workplace}</b></div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <InputField
                                                                className={"did-floating-input"}
                                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                type="text"
                                                                name="workplace"
                                                                label={"Work Place"}
                                                                value={values.workplace}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                required={true}
                                                            />
                                                            {errors.workplace && touched.workplace ? (
                                                                <div className="error-message">{errors.workplace}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="py-3 h5 text-primary"><b>Q. {employeeReviewData?.timeFrame}</b></div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <InputField
                                                                className={"did-floating-input"}
                                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                type="month"
                                                                name="timeFrameStart"
                                                                label={"Work Start"}
                                                                value={values.timeFrameStart}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                required={true}
                                                            />
                                                            {errors.timeFrameStart && touched.timeFrameStart ? (
                                                                <div className="error-message">{errors.timeFrameStart}</div>
                                                            ) : null}
                                                        </div>
                                                        <div className="col">
                                                            <InputField
                                                                className={"did-floating-input"}
                                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                type="month"
                                                                name="timeFrameEnd"
                                                                label={"Work End"}
                                                                value={values.timeFrameEnd}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                required={true}
                                                                max={getCurrentMonthYear()}
                                                            />
                                                            {errors.timeFrameEnd && touched.timeFrameEnd ? (
                                                                <div className="error-message">{errors.timeFrameEnd}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>}
                                        {
                                            step === 1 &&
                                            <div className="question ml-sm-5 pl-sm-5 pt-2 mb-2">
                                                <div className="py-3 h5 text-primary"><b>Q. {employeeReviewData?.healthcareSkills}</b></div>
                                                <div className="row">
                                                    <div className="col mb-2">
                                                        <div className="rating-employee">
                                                            <input type="radio"
                                                                value={5}
                                                                checked={values.healthcareSkills == 5}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                name="healthcareSkills"
                                                                style={{ '--c': '#ff9933' }}
                                                            />
                                                            <input type="radio"
                                                                value={4}
                                                                checked={values.healthcareSkills == 4}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                name="healthcareSkills"
                                                                style={{ '--c': '#ff9933' }}
                                                            />
                                                            <input type="radio"
                                                                value={3}
                                                                checked={values.healthcareSkills == 3}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                name="healthcareSkills"
                                                                style={{ '--c': '#ff9933' }}
                                                            />
                                                            <input type="radio"
                                                                value={3}
                                                                checked={values.healthcareSkills == 3}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                name="healthcareSkills"
                                                                style={{ '--c': '#ff9933' }}
                                                            />
                                                            <input type="radio"
                                                                value={1}
                                                                checked={values.healthcareSkills == 1}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                name="healthcareSkills"
                                                                style={{ '--c': '#ff9933' }}
                                                            />
                                                        </div>
                                                        {errors.healthcareSkills && touched.healthcareSkills ? (
                                                            <div className="error-message">{errors.healthcareSkills}</div>
                                                        ) : null}
                                                    </div>

                                                </div>
                                                <div className="py-3 h5 text-primary"><b>Q. {employeeReviewData?.careForOthers}</b></div>
                                                <div className="row">
                                                    <div className="col mt-2 mb-2">
                                                        <div className="rating-employee">
                                                            <input type="radio"
                                                                value={5}
                                                                checked={values.careForOthers == 5}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                name="careForOthers"
                                                                style={{ '--c': '#ff9933' }}
                                                            />
                                                            <input type="radio"
                                                                value={4}
                                                                checked={values.careForOthers == 4}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                name="careForOthers"
                                                                style={{ '--c': '#ff9933' }}
                                                            />
                                                            <input type="radio"
                                                                value={3}
                                                                checked={values.careForOthers == 3}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                name="careForOthers"
                                                                style={{ '--c': '#ff9933' }}
                                                            />
                                                            <input type="radio"
                                                                value={2}
                                                                checked={values.careForOthers == 2}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                name="careForOthers"
                                                                style={{ '--c': '#ff9933' }}
                                                            />
                                                            <input type="radio"
                                                                value={1}
                                                                checked={values.careForOthers == 1}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                name="careForOthers"
                                                                style={{ '--c': '#ff9933' }}
                                                            />
                                                        </div>
                                                        {errors.careForOthers && touched.careForOthers ? (
                                                            <div className="error-message">{errors.careForOthers}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="py-3 h5 text-primary"><b>Q. {employeeReviewData?.stressHandling}</b></div>
                                                <div className="row">
                                                    <div className="col mt-2 mb-2">
                                                        <div className="rating-employee">
                                                            <input type="radio"
                                                                value={5}
                                                                checked={values.stressHandling == 5}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                name="stressHandling"
                                                                style={{ '--c': '#ff9933' }}
                                                            />
                                                            <input type="radio"
                                                                value={4}
                                                                checked={values.stressHandling == 4}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                name="stressHandling"
                                                                style={{ '--c': '#ff9933' }}
                                                            />
                                                            <input type="radio"
                                                                value={3}
                                                                checked={values.stressHandling == 3}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                name="stressHandling"
                                                                style={{ '--c': '#ff9933' }}
                                                            />
                                                            <input type="radio"
                                                                value={2}
                                                                checked={values.stressHandling == 2}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                name="stressHandling"
                                                                style={{ '--c': '#ff9933' }}
                                                            />
                                                            <input type="radio"
                                                                value={1}
                                                                checked={values.stressHandling == 1}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                name="stressHandling"
                                                                style={{ '--c': '#ff9933' }}
                                                            />
                                                        </div>
                                                        {errors.stressHandling && touched.stressHandling ? (
                                                            <div className="error-message">{errors.stressHandling}</div>
                                                        ) : null}
                                                    </div>

                                                </div>
                                                <div className="py-3 h5 text-primary"><b>Q. {employeeReviewData?.attendance}</b></div>
                                                <div className="row">
                                                    <div className="col mt-2 mb-2">
                                                        <div className="rating-employee">
                                                            <input type="radio"
                                                                value={5}
                                                                checked={values.attendance == 5}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                name="attendance"
                                                                style={{ '--c': '#ff9933' }}
                                                            />
                                                            <input type="radio"
                                                                value={4}
                                                                checked={values.attendance == 4}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                name="attendance"
                                                                style={{ '--c': '#ff9933' }}
                                                            />
                                                            <input type="radio"
                                                                value={3}
                                                                checked={values.attendance == 3}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                name="attendance"
                                                                style={{ '--c': '#ff9933' }}
                                                            />
                                                            <input type="radio"
                                                                value={2}
                                                                checked={values.attendance == 2}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                name="attendance"
                                                                style={{ '--c': '#ff9933' }}
                                                            />
                                                            <input type="radio"
                                                                value={1}
                                                                checked={values.attendance == 1}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                name="attendance"
                                                                style={{ '--c': '#ff9933' }}
                                                            />
                                                        </div>
                                                        {errors.attendance && touched.attendance ? (
                                                            <div className="error-message">{errors.attendance}</div>
                                                        ) : null}
                                                    </div>

                                                </div>
                                            </div>

                                        }
                                        {
                                            step === 2 &&
                                            <div className="question ml-sm-5 pl-sm-5 pt-2 mb-2">
                                                <div className="py-3 h5 text-primary"><b>Q. {employeeReviewData?.strengths}</b></div>
                                                <div className="row">
                                                    <div className="col">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                            type="text"
                                                            name="strengthsOne"
                                                            label={"First Strength"}
                                                            value={values.strengthsOne}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            required={true}
                                                        />
                                                        {errors.strengthsOne && touched.strengthsOne ? (
                                                            <div className="error-message">{errors.strengthsOne}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="col">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                            type="text"
                                                            name="strengthsTwo"
                                                            label={"Second Strength"}
                                                            value={values.strengthsTwo}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            required={true}
                                                        />
                                                        {errors.strengthsTwo && touched.strengthsTwo ? (
                                                            <div className="error-message">{errors.strengthsTwo}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="py-3 h5 text-primary"><b>Q. {employeeReviewData?.weaknesses}</b></div>
                                                <div className="row">
                                                    <div className="col">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                            type="text"
                                                            name="weaknessesOne"
                                                            label={"First Weakness"}
                                                            value={values.weaknessesOne}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            required={true}
                                                        />
                                                        {errors.weaknessesOne && touched.weaknessesOne ? (
                                                            <div className="error-message">{errors.weaknessesOne}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="col">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                            type="text"
                                                            name="weaknessesTwo"
                                                            label={"Second Weakness"}
                                                            value={values.weaknessesTwo}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            required={true}
                                                        />
                                                        {errors.weaknessesTwo && touched.weaknessesTwo ? (
                                                            <div className="error-message">{errors.weaknessesTwo}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="py-3 h5 text-primary"><b>Q. {employeeReviewData?.hireAgain}</b></div>
                                                <div className="row">
                                                    <div className="col">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                            type="text"
                                                            name="hireAgain"
                                                            label={"Hire again?"}
                                                            value={values.hireAgain}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            required={true}
                                                        />
                                                        {errors.hireAgain && touched.hireAgain ? (
                                                            <div className="error-message">{errors.hireAgain}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {<div className="d-flex justify-content-between pt-3">
                                            <div id="prev">
                                                {step !== 0 && <button className="btn btn-primary" type='button' onClick={previousStep}><i className="fas fa-chevron-left"></i> Previous</button>}
                                            </div>
                                            {step !== 2 && <div className="ml-auto mr-sm-5"  >
                                                <button className="btn btn-success" type='submit' onClick={() => nextStep(errors)} disabled={!values.designation}>Next</button>
                                            </div>}
                                            {step === 2 && <div className="ml-auto mr-sm-5" >
                                                <button className="btn btn-success" type='submit'>Submit</button>
                                            </div>}
                                        </div>}

                                    </div>
                                </div>
                            </Form>
                        )}
                </Formik>
            </div>
            <Spinner isLoading={HomeStore.loading} />
            <BottomSheet open={false}>
                <div>
                    <div className="col-xl-12 col-md-12">
                        <div className="user-card-full table-contact" id="reference-content">
                            <div className="row m-l-0 m-r-0">
                                <div className="col-sm-12">
                                    <div className="card-block">
                                        <h6 className="m-b-20 p-b-5 f-w-600"> Caregiver(Bluesky ID): {pdfData?.bluesky_staff_id} </h6>
                                        <h6 className="m-b-20 p-b-5 b-b-default f-w-600">Basic Details <i className="far fa-id-card"></i></h6>
                                        <div className="row">
                                            {pdfData && pdfData?.first_name && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Full Name</p>
                                                <h6 className="text-muted f-w-400">{pdfData?.title} {pdfData?.first_name} {pdfData?.last_name}</h6>
                                            </div>}
                                            {pdfData && pdfData?.contact && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Contact</p>
                                                <h6 className="text-muted f-w-400">{pdfData?.country_code} {pdfData?.contact}</h6>
                                            </div>}
                                            {pdfData && pdfData?.email && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Email Address</p>
                                                <h6 className="text-muted f-w-400">{pdfData?.email}</h6>
                                            </div>}
                                            <h6 className="m-b-20 p-b-5 b-b-default f-w-600">References Details <i className="far fa-list-alt"></i></h6>
                                            {
                                                pdfData?.references?.length > 0 && pdfData?.references?.map((item, index) => {
                                                    return <div className="card p-2 mb-2" style={{ borderStyle: 'dashed', borderWidth: 1 }} key={index + "list"}> <div className="row" data-hide-in-pdf={item?.response ? "false" : "true"}>
                                                        <div className="d-flex justify-content-between">
                                                            <span className="mb-1">
                                                                <small className="badge badge-primary text-uppercase">{index === 0 ? 'First' : index === 1 ? 'Second' : 'Third'} Reference</small>

                                                            </span>
                                                        </div>
                                                        <div className="col-sm-2">
                                                            <p className="m-b-10 f-w-600">Name</p>
                                                            <h6 className="text-muted f-w-400">{item?.name}</h6>
                                                        </div>
                                                        <div className="col-sm-2">
                                                            <p className="m-b-10 f-w-600">Organization Name</p>
                                                            <h6 className="text-muted f-w-400">{item?.organization_name}</h6>
                                                        </div>
                                                        <div className="col-sm-2">
                                                            <p className="m-b-10 f-w-600">Email Address</p>
                                                            <h6 className="text-muted f-w-400">{item?.email}</h6>
                                                        </div>
                                                        <div className="col-sm-2">
                                                            <p className="m-b-10 f-w-600">Contact</p>
                                                            <h6 >{item?.country_code} {item?.contact}</h6>
                                                        </div>
                                                    </div>
                                                        <div className="row" data-hide-in-pdf={item?.response ? "false" : "true"}>
                                                            <div className="col-sm-12">
                                                                <p className="m-b-10 f-w-600 text-uppercase text-success">Reference Feedback <h6 className="text-warning f-w-400">{item?.response ? "" : "Pending"}</h6></p>
                                                                {item?.response && (
                                                                    <div>
                                                                        {JSON.parse(item?.response)?.map((item, index) => (
                                                                            <div className="list-group" key={index}>
                                                                                <span className="list-group-item list-group-item-action"><span className="badge badge-primary">Q.</span> {item?.question}</span>
                                                                                <span className="list-group-item list-group-item-action"><span className="badge badge-success">A.</span> {item?.answer}</span>
                                                                                <br />

                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        {/* <span className="text-primary" ></span> */}
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BottomSheet>
        </div>

    );
});

export default ReferenceReview;
const styles = {
    container: {
        backgroundColor: "#fff",
        color: "#ddd",
        borderRadius: "10px",
        padding: "20px",
        fontFamily: "'Montserrat', sans-serif",
        maxWidth: "750px",
    },
};

function getCurrentMonthYear() {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1; // January is 0, so add 1
    const formattedDate = `${year}-${month.toString().padStart(2, '0')}`;
    return formattedDate;
}