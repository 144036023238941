/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react"
import HeaderPage from "../layout/HeaderPage";
import DrawerMenuPage from "../layout/DrawerMenuPage";
import { CButton, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CInputGroup, CFormInput, CFormSwitch } from "@coreui/react";
import './ClientsList.css'
import { toJS } from "mobx";
import { useNavigate } from "react-router-dom";
import swal from '@sweetalert/with-react';
import { InputField } from "@elements/index"
import { CModal, CModalHeader, CModalTitle } from "@coreui/react";
import { Formik } from "formik";
import * as Yup from 'yup';
import Spinner from "@components/Spinner";
import ReactDOM from "react-dom";
import moment from "moment";
import SelectField from "@elements/select/SelectField";
import { Helmet } from "react-helmet";
import StaffsStore from "@store/StaffsStore";
import AdminStore from "@store/AdminStore";
import { Control, MultiValue } from "../commonFunctions";
import Select from 'react-select';
import { colourStyles, colourStylesState, colourStylesTitle } from '@utils/customStyle/style';


const $ = window.$;
const approveSchema = Yup.object().shape({
    facility_code: Yup.string().required('Facility code is required!'),
    unit_code: Yup.string().required('Unit code is required!'),
});
const ClientsList = observer(() => {
    const tableRef = useRef()
    const tableName = "table1"
    const formikRef = useRef(null);
    const navigate = useNavigate();
    const [clientList, setClientList] = useState([]);
    const [formData, setFormData] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [paginationLength, setPaginationLength] = useState(25);
    const [inputSearch, setInputSearch] = useState('');
    const [regions, setRegions] = useState([]);
    useEffect(() => {
        StaffsStore.getRegionsList();
        AdminStore.updatePage();
        AdminStore.getClient();
        AdminStore.getAllFacility();
    }, []);

    useEffect(() => {
        setClientList(toJS(AdminStore?.data?.clientList))
    }, [AdminStore?.data?.clientList]);

    useEffect(() => {
        setRegions(StaffsStore?.data?.regionsList)
    }, [StaffsStore?.data?.regionsList]);


    useEffect(() => {
        const renderPagination = () => {
            const table = $('#table1').DataTable();
            const totalPages = AdminStore.data.lastPage;
            const currentPage = AdminStore.data.page;

            const paginationContainer = $('.dataTables_paginate');
            const paginationList = $('<ul>', { class: 'pagination' });
            paginationContainer.empty().append(paginationList);

            let startPage = Math.max(1, currentPage - 2);
            let endPage = Math.min(totalPages, currentPage + 2);

            if (totalPages > 5 && currentPage <= 3) {
                endPage = 5;
            } else if (totalPages > 5 && currentPage >= totalPages - 2) {
                startPage = totalPages - 4;
            }
            const firstButton = $('<a>', {
                text: 'First',
                class: `page-link pointer ${currentPage === 1 ? 'disabled' : ''}`,
                click: function () {
                    if (currentPage > 1) {
                        AdminStore.data.page = 1;
                        AdminStore.getClient();
                        table.page('first').draw(false);
                    }
                },
            });

            const previousButton = $('<a>', {
                text: 'Previous',
                class: `page-link pointer ${currentPage === 1 ? 'disabled' : ''}`,
                click: function () {
                    if (currentPage > 1) {
                        AdminStore.data.page = AdminStore.data.page - 1;
                        AdminStore.getClient();
                        table.page('previous').draw(false);
                    }
                },
            });

            const nextButton = $('<a>', {
                text: 'Next',
                class: `page-link pointer ${currentPage === totalPages ? 'disabled' : ''}`,
                click: function () {
                    if (currentPage < totalPages) {
                        AdminStore.data.page = AdminStore.data.page + 1;
                        AdminStore.getClient();
                        table.page('next').draw(false);
                    }
                },
            });
            const lastButton = $('<a>', {
                text: 'Last',
                class: `page-link pointer ${currentPage === totalPages ? 'disabled' : ''}`,
                click: function () {
                    if (currentPage < totalPages) {
                        AdminStore.data.page = AdminStore.data.lastPage;
                        AdminStore.getClient();
                        table.page('last').draw(false);
                    }
                },
            });

            const firstListItem = $('<li>', { class: `page-item ${currentPage === 1 ? 'disabled' : ''}` }).append(firstButton);
            const previousListItem = $('<li>', { class: `page-item ${currentPage === 1 ? 'disabled' : ''}` }).append(previousButton);
            const nextListItem = $('<li>', { class: `page-item ${currentPage === totalPages ? 'disabled' : ''}` }).append(nextButton);
            const lastListItem = $('<li>', { class: `page-item ${currentPage === totalPages ? 'disabled' : ''}` }).append(lastButton);
            paginationList.append(firstListItem);
            paginationList.append(previousListItem);

            for (let i = startPage; i <= endPage; i++) {
                const pageLink = $('<a>', {
                    text: i,
                    class: `page-link pointer ${i === currentPage ? 'active' : ''}`,
                    click: function () {
                        AdminStore.data.page = i;
                        AdminStore.getClient();
                        table.page(i - 1).draw(false);
                    },
                });

                const listItem = $('<li>', { class: `page-item ${i === currentPage ? 'active' : ''}` }).append(pageLink);
                paginationList.append(listItem);
            }

            paginationList.append(nextListItem);
            paginationList.append(lastListItem);
        };
        $.fn.dataTable.ext.errMode = 'none';
        $($.fn.dataTable.tables(true)).DataTable()
            .columns.adjust();
        const table = $(`#${tableName}`).DataTable(
            {
                data: clientList,
                order: [], // Disable initial sorting
                dom: 'Blfrtip',
                buttons: [
                    'copy', 'csv', 'excel', 'print',
                ],
                pageLength: paginationLength,
                lengthMenu: [25, 50, 100, 500, 1000],
                columns: [
                    {
                        title: "Sr", data: 'id', width: 50, "render": function (data, type, full, meta) {
                            return ((AdminStore.data.page - 1) * paginationLength + 1) + meta.row
                        }
                    },
                    {
                        title: "Username", data: 'username', width: 50, "render": function (data, type, full, meta) {
                            return data ? "<span class='text-success'><strong>" + data + "</strong></span>" : "-"
                        }
                    },
                    {
                        title: "User Type", data: 'master', width: 50, "render": function (data, type, full, meta) {
                            return data ? "Sub Account" : "Master"
                        }
                    },
                    {
                        title: "Name", width: 100, "render": function (data, type, row, meta) {
                            return row?.first_name + " " + row?.last_name
                        }
                    },
                    {
                        title: "Facilities", data: "facility_relations", "render": function (data, type, row, meta) {
                            return data.length > 0 ? '<span class="text-info"><b>' + data[0]?.facilities?.organization_name + '</span>' + '<sup class="text-info"><b>' + (data?.length > 1 ? " +" + (data.length - 1) : "") + '</b></sup>' : "No Facility";
                        }
                    },
                    {
                        title: "Email", data: "email"
                    },
                    {
                        title: "Contact", data: "contact", "render": function (data, type, row, meta) {
                            return data || "-";
                        }
                    },
                    {
                        title: "Status", width: 100, data: 'status', "render": function (data, type, row, meta) {
                            return data == '0' ? '<span class="text-warning">' + "Pending" + '</span>' : data == '1' ? '<span class="text-success">' + "Active" + '</span>' : data == '2' ? '<span class="text-danger">' + "Rejected" + '</span>' : data == '3' ? '<span class="text-dark">' + "Blocked" + '</span>' : 'No Update'
                        }
                    },
                    {
                        title: "Last Updated By", width: 100, data: 'last_updated_by', "render": function (data, type, row, meta) {
                            return data ? data?.first_name + " " + data?.last_name : "-"
                        }
                    },
                    {
                        title: "Last Update", width: 100, data: 'updated_at', "render": function (data, type, row, meta) {
                            return moment(data).fromNow() || "-";
                        }
                    },
                    {
                        title: "Notification"
                    },
                    {
                        title: "Action"
                    },

                ],
                columnDefs: [
                    {
                        targets: 4,
                        responsivePriority: 3,
                        createdCell: function (td, cellData, rowData, row, col) {
                            var listHtml = "";
                            cellData.forEach(function (data) {
                                listHtml += "" + data?.facilities?.organization_name + "\n";
                            })
                            listHtml += "";
                            $(td).attr('data-toggle', 'tooltip');
                            $(td).attr('data-html', 'true');
                            $(td).attr('data-placement', 'top');
                            $(td).attr('title', listHtml);
                        },
                    },
                    {
                        targets: 0,
                        visible: false,
                    },
                    {
                        targets: 10,
                        responsivePriority: 2,
                        createdCell: (td, cellData, rowData, row, col) =>
                            ReactDOM.render(
                                <div>
                                    <CFormSwitch size="xl" onChange={(event) => handleChangeSMS(event, rowData?.id, rowData?.mail_notify)} checked={rowData?.sms_notify === 1} label="SMS" id="sms" />
                                    <CFormSwitch size="xl" onChange={(event) => handleChangeEmail(event, rowData?.id, rowData?.sms_notify)} checked={rowData?.mail_notify === 1} label="EMAIL" id="email" />
                                </div>
                                , td),
                    },
                    {
                        targets: 11,
                        responsivePriority: 1,
                        createdCell: (td, cellData, rowData, row, col) =>
                            ReactDOM.render(
                                <div>
                                    <CDropdown variant="btn-group" direction='dropstart' alignment="end">
                                        <CDropdownToggle size="sm" color="transparent" caret={false} href="#" className="p-0"><i className="fa fa-ellipsis-v text-primary"></i></CDropdownToggle>
                                        <CDropdownMenu>
                                            {<CDropdownItem className="more-menu">
                                                <CButton onClick={() => {
                                                    onShiftView(rowData)
                                                }} size="sm" className="w-100" variant='outline' color="primary" >
                                                    View Shifts <i className="fas fa-eye"></i>
                                                </CButton>
                                            </CDropdownItem>}

                                            {<CDropdownItem className="more-menu">
                                                <CButton onClick={() => {
                                                    onShiftCalendarView(rowData)
                                                }} size="sm" className="w-100" variant='outline' color="primary" >
                                                    View Calendar <i className="fas fa-calendar"></i>
                                                </CButton>
                                            </CDropdownItem>}
                                            {rowData.status != 0 && <CDropdownItem className="more-menu">
                                                <CButton onClick={() => {
                                                    onEdit(rowData)
                                                }} size="sm" className="w-100" variant='outline' color="primary" >
                                                    Edit <i className="fas fa-edit"></i>
                                                </CButton>
                                            </CDropdownItem>}
                                            {rowData.status == 0 && (<>  <CDropdownItem>
                                                <CButton onClick={() => {
                                                    onOpenForm(rowData)
                                                }} size="sm" className="w-100" variant='outline' color="success" >
                                                    Approve <i className="fas fa-user-check"></i>
                                                </CButton>
                                            </CDropdownItem>
                                            </>)}
                                            {rowData.status == 0 && (<><CDropdownItem>
                                                <CButton
                                                    onClick={() => {
                                                        onReject(rowData.id)
                                                    }}
                                                    size="sm" className="w-100" variant='outline' color="danger">
                                                    Reject <i className="fas fa-user-times"></i>
                                                </CButton>

                                            </CDropdownItem>
                                            </>)}
                                            {rowData.status != 0 && <CDropdownItem>
                                                <CButton
                                                    onClick={() => {
                                                        onBlock(rowData)
                                                    }}
                                                    size="sm" className="w-100" variant='outline' color={rowData.status === 3 ? "success" : "danger"}>
                                                    {rowData.status === 3 ? 'Activate' : 'Block'}    {rowData.status === 3 ? <i className="fas fa-user-check"></i> : <i className="fas fa-ban"></i>}
                                                </CButton>
                                            </CDropdownItem>
                                            }
                                            <CDropdownItem>
                                                <CButton
                                                    onClick={() => {
                                                        confirm(rowData.id)
                                                    }}
                                                    size="sm" className="w-100" variant='outline' color="danger">
                                                    Delete <i className="fas fa-trash"></i>
                                                </CButton>
                                            </CDropdownItem>
                                        </CDropdownMenu>
                                    </CDropdown>
                                </div>
                                , td),
                    }
                ],
                destroy: true,  // I think some clean up is happening here
                searching: true,
                "language": {
                    search: '',
                    searchPlaceholder: "Search",
                    emptyTable: "No Record Found",
                    info: "Showing " + AdminStore.data.from + " to " + AdminStore.data.to + " of " + AdminStore.data.total + " entries",
                },
                initComplete: function () {
                    // Apply the search
                    this.api()
                        .columns([0, 1, 2, 3, 4, 5, 6, 7, 8])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><input class="form-control " style="width:100%;margin-top:5px" type="text" placeholder="Filter per page" />')
                                .appendTo($(column.header()))

                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $('input', this.header()).on('keyup change clear', function () {
                                if (column.search() !== this.value) {
                                    column.search(this.value).draw();
                                }
                            });
                        });
                    this.api()
                        .columns([9])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><select class="form-control" placeholder="search" style="width:100%;margin-top:5px;background-color:white""><option value="">All</option></select>')
                                .appendTo($(column.header()))
                                .on('change', function () {
                                    var val = $.fn.dataTable.util.escapeRegex($(this).val());
                                    column.search(val ? '^' + val + '$' : '', true, false).draw();
                                });
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            column
                                .data()
                                .unique()
                                .sort()
                                .each(function (d, j) {
                                    let name = [{ "name": "Pending", "value": 0 }, { "name": "Active", "value": 1 }, { "name": "Rejected", "value": 2 }, { "name": "Blocked", "value": 3 }]?.find(stat => stat.value == d)?.name;
                                    select.append('<option value="' + name + '">' + name + '</option>');
                                });
                        });

                },

                drawCallback: function () {
                    renderPagination();
                }
            });

        renderPagination();
        table.page.len(paginationLength).draw();
        $(`#${tableName}`).on('length.dt', function (e, settings, len) {
            handlePaginationLengthChange(len)
        });
        table.on('click', 'td', function () {
            // Get the column index of the clicked cell
            const columnIndex = table.cell(this).index()?.column;
            // Check if the clicked cell belongs to the desired column
            if (columnIndex === 3 || columnIndex === 5) {
                // Get the data for the clicked cell
                const rowData = table.row($(this).closest('tr')).data();
                // Handle the event for the clicked cell
                if (rowData?.requests?.length > 0) {
                    onShiftView(rowData)
                }
            }
        });
        // Extra step to do extra clean-up.
        return function () {
            $(`#${tableName}`).off('length.dt');
            table.destroy()
        }
    }, [clientList])

    const onBlock = (item) => {
        swal({
            title: item?.status === 3 ? "Do you really want to unblock facility user?" : "Do you really want to block?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    AdminStore.blockClient({ id: item.id }, navigationCallBackBlock)
                }
            });
    }

    const confirm = (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this facility user details!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    AdminStore.deleteClient({ id: id }, navigationCallBackBlock)
                }
            });
    }

    const onReject = (id) => {
        swal("Remark:", {
            title: "Do you really want to reject?",
            icon: "warning",
            content: { element: "textarea" },
            buttons: ["Cancel", "Reject"],
        })
            .then((value) => {
                if (value) {
                    value = document.querySelector(".swal-content__textarea").value;
                    let param = {
                        id: id,
                        status: 2,
                        remark: value
                    }
                    AdminStore.statusClient(param, navigationCallBackBlock)
                }
            });
    }

    const onEdit = (item) => {
        navigate('/admin/client-edit', { state: { item: item } });
    }

    const onApprove = (values) => {
        let formData = new FormData();
        formData.append("id", values.id)
        formData.append("status", 1)
        formData.append("facility_code", values.facility_code)
        formData.append("unit_code", values.unit_code)
        formData.append("region_id", values.region_id)
        values?.facility_ids?.forEach(id => {
            formData.append("facility_ids[]", id);
        });

        AdminStore.statusClient(formData, navigationCallBackBlock)
    }

    const navigationCallBackBlock = () => {
        setFormData()
        onDismiss()
        AdminStore.getClient();
    }
    const onOpenForm = (item) => {
        setFormData(item)
        setIsOpen(true)
    }
    const onDismiss = () => {
        if (formikRef.current) {
            formikRef.current.resetForm();
        }
        setIsOpen(false)
    }

    const handlePaginationLengthChange = (newPageSize) => {
        setPaginationLength(newPageSize)
        AdminStore.data.pageSize = newPageSize;
        AdminStore.getClient();
    };
    const handleChange = (event) => {
        setInputSearch(event.target.value);
        AdminStore.data.search = event.target.value;
    };
    const onSearch = () => {
        AdminStore.getClient();
    }
    const onClearSearch = () => {
        setInputSearch('');
        AdminStore.data.search = '';
        AdminStore.getClient();
    }
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            onSearch();
        }
    };
    const onShiftView = (clientData) => {
        const client = {
            id: clientData?.id,
            first_name: clientData?.first_name,
            last_name: clientData?.last_name,
        }
        const url = `/admin/employer-shift?data=${encodeURIComponent(JSON.stringify(client))}`;
        window.open(url, '_blank');
    }
    const onShiftCalendarView = (clientData) => {
        const client = {
            id: clientData?.id,
            organization_name: clientData?.organization_name,
            first_name: clientData?.first_name,
            last_name: clientData?.last_name,
            region: clientData?.region,
            address: clientData?.address,
            state: clientData?.state,
            city: clientData?.city,
            zipcode: clientData?.zipcode,
            facility_code: clientData?.facility_code,
            unit_code: clientData?.unit_code,
        }
        const url = `/admin/employer-calendar?data=${encodeURIComponent(JSON.stringify(client))}`;
        window.open(url, '_blank');
    }
    const handleChangeEmail = (event, user_id, sms) => {
        let param = {
            user_id: user_id,
            sms_alert: sms,
            email_alert: event.target.checked === true ? 1 : 0,
        }
        AdminStore.updateAlert(param, navigationCallBack)

    }
    const handleChangeSMS = (event, user_id, email) => {
        let param = {
            user_id: user_id,
            sms_alert: event.target.checked === true ? 1 : 0,
            email_alert: email
        }
        AdminStore.updateAlert(param, navigationCallBack)
    }

    const navigationCallBack = () => {
        onSearch()
        swal("Updated!", "Your notification has been updated successfully", "success");
    }
    return (
        <React.Fragment>
            <Helmet>
                <title>Staffers LLC | Facility User List</title>
            </Helmet>
            {/* <body> */}
            <div className="container-scroller">
                <HeaderPage />
                <div className="container-fluid page-body-wrapper">
                    <DrawerMenuPage />
                    <div className="main-panel" style={{ right: 0, position: "absolute" }}>
                        <div className="content-wrapper p-3 bg-white row pe-0">
                            <h3 className="col-6 col-lg-6 page-title">
                                <span className="page-title-icon linear text-white me-2">
                                    <i className="fas fa-user-friends"></i>
                                </span> Facility Users
                            </h3>
                            <div className='col-6 col-lg-3 text-end'>
                                <a onClick={() => AdminStore.loading = true} href="/admin/client-add">
                                    <h1 className="page-title signup-link fs-4" >
                                        <span className="page-title-icon linear text-white me-2">
                                            <i className="far fa-building"></i>
                                        </span>Add New Facility User
                                    </h1>
                                </a>
                            </div>
                            <div className="col-12 col-lg-3 mt-lg-0 mt-3">
                                <CInputGroup>
                                    <CFormInput placeholder="Search" value={inputSearch} onChange={handleChange} onKeyPress={handleKeyPress} />
                                    {inputSearch && (
                                        <CButton onClick={onClearSearch} type="button" color="primary" variant="outline">
                                            <i className="fa fa-times" aria-hidden="true"></i>
                                        </CButton>
                                    )}
                                    <CButton onClick={onSearch} type="button" color="primary" variant="outline">
                                        <i className="fa fa-search" aria-hidden="true"></i>
                                    </CButton>
                                </CInputGroup>
                            </div>


                        </div>
                        {/* <!-- partial --> */}

                        <div className="col-lg-12">
                            <div className="row bg-white" style={{ height: 500, paddingTop: 20 }}>
                                <table className="table dt-responsive table-hover nowrap" style={{ width: "100%" }} id={tableName} ref={tableRef}></table>
                            </div>
                        </div>
                    </div>

                    {/* <!-- main-panel ends --> */}
                </div>
                {/* <!-- page-body-wrapper ends --> */}
            </div>

            <Spinner isLoading={AdminStore.loading} />
            <CModal visible={isOpen} onClose={() => onDismiss()}>
                <CModalHeader onClose={() => onDismiss()}>
                    <CModalTitle>Approve {formData?.first_name} {formData?.last_name}</CModalTitle>
                </CModalHeader>
                {/* <CModalBody> */}
                <Formik
                    innerRef={formikRef}
                    initialValues={{ id: formData?.id, facility_code: "", unit_code: "", facility_ids: [] }}
                    onSubmit={values => onApprove(values)}
                    validationSchema={approveSchema}
                >
                    {
                        ({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched
                        }) =>
                        (
                            <form onSubmit={handleSubmit}>
                                <div className="modal-body p-4">
                                    <div className="row g-2">
                                        <div className="pb-2 col-sm-12">
                                            <Select
                                                onChange={(event) => { setFieldValue("facility_ids", event?.map(({ id }) => id)); }}
                                                placeholder={''}
                                                closeMenuOnSelect={false}
                                                label={'Facility?'}
                                                value={toJS(AdminStore?.data?.allFacilityList)?.filter(({ id }) => values?.facility_ids?.includes(id)) || ''}
                                                styles={colourStylesTitle}
                                                isSearchable={true}
                                                isMulti
                                                options={toJS(AdminStore?.data?.allFacilityList)}
                                                getOptionLabel={(option) => option.organization_name}
                                                getOptionValue={(option) => option.id}
                                                components={{ Control, MultiValue, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                            />
                                        </div>
                                        <div className="pb-2 col-sm-12">
                                            <InputField
                                                className={"did-floating-input"}
                                                type="text"
                                                name="facility_code"
                                                label={"Facility Code*"}
                                                value={values.facility_code}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.facility_code && touched.facility_code ? (
                                                <div className="error-message">{errors.facility_code}</div>
                                            ) : null}
                                        </div>
                                        <div className="pb-2 col-sm-12">
                                            <InputField
                                                className={"did-floating-input"}
                                                type="text"
                                                name="unit_code"
                                                label={"Unit Code*"}
                                                value={values.unit_code}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.unit_code && touched.unit_code ? (
                                                <div className="error-message">{errors.unit_code}</div>
                                            ) : null}
                                        </div>
                                        <div className="pb-2 col-sm-12">
                                            <SelectField
                                                name="region_id"
                                                label={"Add Region"}
                                                value={values.region_id}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                data={regions}
                                                optionLabel={"Name"}
                                                optionValue={"ID"}
                                                required={true}
                                            />
                                            {errors.region_id && touched.region_id ? (
                                                <div className="error-message">{errors.region_id}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-center">
                                    <button type="submit" className="btn btn-success w-25">Approve</button>
                                </div>
                            </form>
                        )
                    }
                </Formik>
                {/* </CModalBody> */}
            </CModal>
            {/* </body> */}
        </React.Fragment>
    );
})

export default ClientsList;