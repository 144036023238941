/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import HeaderPage from "../layout/HeaderPage";
import DrawerMenuPage from "../layout/DrawerMenuPage";
import Select, { components } from "react-select";
import { Formik, Form } from "formik";
import "./UpdatePages.css";
import Spinner from "@components/Spinner";
import { InputField } from "@elements/index";
import AdminStore from "@store/AdminStore";
import HomeStore from "@store/HomeStore";
import { toJS } from "mobx";
import { Helmet } from "react-helmet";
import {
  CButton,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from "@coreui/react-pro";
import SelectField from "@elements/select/SelectField";
import { colourStylesState, colourStyles } from '@utils/customStyle/style';
import { statusList } from '@utils/constant/constant';
import ReactDOM from "react-dom";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import { ReviewValidationSchema } from '@utils/schemas/allSchema';

var citys = require("@assets/city.json");
var states = require("@assets/states.json");
const Review = observer(() => {

  const tableRef = useRef()
  const tableName = "table1"
  const [paginationLength, setPaginationLength] = useState(25);
  const [isOpenReview, setIsOpenReview] = useState(false);
  const [reviewList, setReviewList] = useState([]);
  const [selectedReview, setSelectedReview] = useState();
  const [degrees, setDegrees] = useState([]);


  useEffect(() => {
    AdminStore.getReview();
  }, []);

  useEffect(() => {
    setReviewList(toJS(AdminStore?.data?.review))
  }, [AdminStore?.data?.review]);

  useEffect(() => {
    const degreesData = HomeStore.data.dashboardData?.find(
      (x) => x.key_name === "DEGREES"
    );
    if (degreesData) setDegrees(JSON.parse(toJS(degreesData.value)));
  }, [HomeStore.data.dashboardData]);
  useEffect(() => {
    $.fn.dataTable.ext.errMode = 'none';
    $($.fn.dataTable.tables(true)).DataTable()
      .columns.adjust();
    const table = $(`#${tableName}`).DataTable(
      {
        data: reviewList,
        order: [], // Disable initial sorting
        dom: 'Blfrtip',
        buttons: [
          'copy', 'csv', 'excel', 'print',
        ],
        pageLength: paginationLength,
        lengthMenu: [25, 50, 100, 500, 1000],
        columns: [
          {
            title: "Ref.", data: "id", "render": function (data, type, row, meta) {
              return data || "-";
            }
          },
          {
            title: "Sequence", data: "sequence"
          },
          {
            title: "Name", data: "name"
          },
          {
            title: "Company Name", data: "company_name"
          },
          {
            title: "Job Title", data: "designation", "render": function (data, type, row, meta) {
              return data || "-";
            }
          },
          {
            title: "Email", data: "email"
          },
          {
            title: "Contact", data: "contact"
          },
          {
            title: "City", data: "city"
          },
          {
            title: "State", data: "state"
          },
          {
            title: "Rate", data: "stars"
          },
          {
            title: "Feedback", data: "feedback"
          },
          {
            title: "Status", data: 'status', "render": function (data, type, row, meta) {
              return data == '1' ? '<span class="text-danger">' + "In-active" + '</span>' : data == '0' ? '<span class="text-success">' + "Active" + '</span>' : 'No Update'
            }
          },

          {
            title: "Action",
          },
        ],
        columnDefs: [
          {
            targets: 12,
            responsivePriority: 1,
            createdCell: (td, cellData, rowData, row, col) =>
              ReactDOM.render(
                <div>
                  <CDropdown
                    variant="btn-group"
                    direction="dropstart"
                    alignment="end"
                  >
                    <CDropdownToggle
                      size="sm"
                      color="transparent"
                      caret={false}
                      href="#"
                      className="p-0"
                    >
                      <i className="fa fa-ellipsis-v text-primary"></i>
                    </CDropdownToggle>
                    <CDropdownMenu>
                      <CDropdownItem className="more-menu">
                        <CButton
                          onClick={() => {
                            onEdit(rowData);
                          }}
                          size="sm"
                          className="w-100"
                          variant="outline"
                          color="primary"
                        >
                          Edit <i className="fas fa-edit"></i>
                        </CButton>
                      </CDropdownItem>
                    </CDropdownMenu>
                  </CDropdown>
                </div>,
                td
              ),
          },
          {
            targets: 11,
            responsivePriority: 2,
          },
          {
            targets: 2,
            responsivePriority: 3,
          },
          {
            targets: 3,
            responsivePriority: 4,
          },
          {
            targets: 4,
            responsivePriority: 5,
          },
          {
            targets: 5,
            responsivePriority: 6,
          },
          {
            targets: 9,
            responsivePriority: 7,
          },
        ],
        destroy: true, // I think some clean up is happening here
        searching: true,
        language: {
          search: "",
          searchPlaceholder: "Search",
          emptyTable: "No Review Found",
        },
        initComplete: function () {
          this.api()
            .columns([0, 1, 2, 3, 4, 5, 6, 9, 10])
            .every(function () {
              var column = this;
              var select = $(
                '<br/><input class="form-control " style="width:100%;margin-top:5px" type="text" placeholder="Filter per page" />'
              ).appendTo($(column.header()));

              $(select).click(function (e) {
                e.stopPropagation();
              });
              $("input", this.header()).on("keyup change clear", function () {
                if (column.search() !== this.value) {
                  column.search(this.value).draw();
                }
              });
            });
          this.api()
            .columns([7])
            .every(function () {
              $(select).click(function (e) {
                e.stopPropagation();
              });
              var column = this;

              // Create the dropdown with "All" as the default option
              var select = $(
                '<br/><select class="form-control" placeholder="filter" style="width:100%;margin-top:5px;background-color:white"><option value="" selected>All</option></select>'
              ).appendTo($(column.header()));
              $(select).click(function (e) {
                e.stopPropagation();
              });
              // Keep track of unique values
              var uniqueValues = {};

              // Populate the dropdown with unique values from column 5
              column.data().each(function (d, j) {
                // Get the HTML content of the cell
                var cellText = column.cell(j, 7).node().innerHTML;
                // Check if the value is unique before adding it to the dropdown
                if (!uniqueValues[cellText]) {
                  uniqueValues[cellText] = true;
                  select.append(
                    '<option value="' + cellText + '">' + cellText + "</option>"
                  );
                }
              });

              // Add change event listener to the dropdown
              select.on("change", function () {
                var val = $.fn.dataTable.util.escapeRegex($(this).val());

                // Search and filter the table
                column.search(val ? "^" + val + "$" : "", true, false).draw();
              });
            });
          this.api()
            .columns([8])
            .every(function () {
              $(select).click(function (e) {
                e.stopPropagation();
              });
              var column = this;

              // Create the dropdown with "All" as the default option
              var select = $(
                '<br/><select class="form-control" placeholder="filter" style="width:100%;margin-top:5px;background-color:white"><option value="" selected>All</option></select>'
              ).appendTo($(column.header()));
              $(select).click(function (e) {
                e.stopPropagation();
              });
              // Keep track of unique values
              var uniqueValues = {};

              // Populate the dropdown with unique values from column 5
              column.data().each(function (d, j) {
                // Get the HTML content of the cell
                var cellText = column.cell(j, 8).node().innerHTML;
                // Check if the value is unique before adding it to the dropdown
                if (!uniqueValues[cellText]) {
                  uniqueValues[cellText] = true;
                  select.append(
                    '<option value="' + cellText + '">' + cellText + "</option>"
                  );
                }
              });

              // Add change event listener to the dropdown
              select.on("change", function () {
                var val = $.fn.dataTable.util.escapeRegex($(this).val());

                // Search and filter the table
                column.search(val ? "^" + val + "$" : "", true, false).draw();
              });
            });
          this.api()
            .columns([11]) //Status
            .every(function () {
              var column = this;
              var select = $(
                '<br/><select class="form-control" placeholder="search" style="width:100%;margin-top:5px;background-color:white""><option value="">All</option></select>'
              )
                .appendTo($(column.header()))
                .on("change", function () {
                  var val = $.fn.dataTable.util.escapeRegex($(this).val());
                  column.search(val ? "^" + val + "$" : "", true, false).draw();
                });
              $(select).click(function (e) {
                e.stopPropagation();
              });
              $(select).click(function (e) {
                e.stopPropagation();
              });
              column
                .data()
                .unique()
                .sort()
                .each(function (d, j) {
                  let name = [
                    { name: "In-active", value: 0 },
                    { name: "Active", value: 1 },
                  ]?.find((stat) => stat.value == d)?.name;
                  select.append(
                    '<option value="' + name + '">' + name + "</option>"
                  );
                });
            });
        },
      });

    // table.columns(4).visible(false);
    // Extra step to do extra clean-up.
    return function () {
      $(`#${tableName}`).off("length.dt");
      table.destroy();
    };
  }, [reviewList]);

  const onRefresh = () => {
    setSelectedReview()
    setIsOpenReview(false)
    AdminStore.getReview();
  }
  const addReview = (values) => {
    if (selectedReview?.id) {
      AdminStore.updateReview(values, onRefresh)
    } else {
      AdminStore.addReview(values, onRefresh)
    }
  }

  const openModal = () => {
    setIsOpenReview(true);
  };
  const onDismiss = () => {
    setSelectedReview();
    setIsOpenReview(false);
  };

  const onEdit = (data) => {
    setSelectedReview(data);
    setIsOpenReview(true);
  };

  return (
    <React.Fragment>
      {/* <body> */}
      <Helmet>
        <title>Staffers LLC | Review</title>
      </Helmet>
      <div className="container-scroller">
        <HeaderPage />
        <div className="container-fluid page-body-wrapper">
          <DrawerMenuPage />
          <div
            className="main-panel"
            style={{ right: 0, position: "absolute" }}
          >
            <div className="content-wrapper p-3 bg-white ">
              <h3 className="page-title">
                <span className="page-title-icon linear text-white me-2">
                  <i className="fas fa-user-friends"></i>
                </span>{" "}
                Review
              </h3>
              <div className="col-12 d-flex justify-content-lg-end pt-lg-0 pt-2">
                <a onClick={() => openModal(true)} href="#">
                  <h1 className="page-title signup-link fs-4">
                    <span className="page-title-icon linear text-white me-2">
                      <i className="fas fa-plus"></i>
                    </span>
                    Add New Review
                  </h1>
                </a>
              </div>
            </div>
            <div
              className="row bg-white table-responsive"
              style={{ paddingTop: 20 }}
            >
              <div className="table-wrapper">
                <table
                  className="table dt-responsive table-hover nowrap"
                  style={{ width: "100%" }}
                  id={tableName}
                  ref={tableRef}
                ></table>
              </div>
            </div>
          </div>

          {/* <!-- main-panel ends --> */}
        </div>
        {/* <!-- page-body-wrapper ends --> */}

      </div>
      {/* Add Review*/}
      <CModal className="custom-modal-size" backdrop="static" visible={isOpenReview} onClose={onDismiss} >
        <CModalHeader onClose={onDismiss} >
          <CModalTitle>Add New Review</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <Formik
            initialValues={{
              'id': selectedReview?.id || '',
              'sequence': selectedReview?.sequence || reviewList?.length + 1,
              'name': selectedReview?.name || '',
              'company_name': selectedReview?.company_name || '',
              'designation':selectedReview?.designation,
              'email': selectedReview?.email || '',
              'contact': selectedReview?.contact || '',
              'city': selectedReview?.city || '',
              'state': selectedReview?.state || '',
              'stars': selectedReview?.stars || '',
              'feedback': selectedReview?.feedback || '',
              'status': selectedReview?.status || '0',
            }}
            validationSchema={ReviewValidationSchema}
            validateOnChange={false}
            onSubmit={(values, { resetForm }) => {
              addReview(values);
            }}
          >
            {({ values, errors, touched, handleBlur, handleChange, setFieldValue }) => (
              <Form>
                <div className="row g-2">
              {console.log(errors)}
                  <div className="col-sm-2  mb-2">
                    <InputField
                      className={"did-floating-input"}
                      classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                      type="number"
                      name="sequence"
                      label={"Sequence"}
                      min={1}
                      value={values.sequence}
                      onChange={handleChange}
                      required={true}
                      onBlur={handleBlur}
                      disabled={true}
                    />
                  </div>
                  <div className="col-sm-4 mb-2">
                    <InputField
                      className={"did-floating-input"}
                      classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                      type="text"
                      name="name"
                      label={"Name"}
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.name ? (
                      <div className="error-message">{errors.name}</div>
                    ) : null}
                  </div>
                  <div className="col-sm-6 mb-2">
                    <InputField
                      className={"did-floating-input"}
                      classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                      type="text"
                      name="company_name"
                      label={"Company Name"}
                      value={values.company_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.company_name ? (
                      <div className="error-message">{errors.company_name}</div>
                    ) : null}
                  </div>
                  <div className="col-sm-6 mb-2">
                    <InputField
                      className={"did-floating-input"}
                      classNameLabel={"did-floating-label"}
                      type="text"
                      name="designation"
                      label={"Job Title"}
                      value={values.designation}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="col-sm-6 mb-2">
                    <InputField
                      className={"did-floating-input"}
                      // classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                      type="email"
                      name="email"
                      label={"Email"}
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                  </div>
                  <div className="col-sm-6 mb-2">
                    <InputField
                      className={"did-floating-input"}
                      // classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                      type="text"
                      name="contact"
                      label={"Contact Number"}
                      value={values?.contact?.replace(/[^\d]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}
                      maxLength={12}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                  </div>
                  <div className="col-sm-6 mb-2">
                    <Select
                      onChange={(e) => { setFieldValue("state", e.abbreviation), setFieldValue("city", '') }}
                      placeholder={''}
                      label={'State'}
                      isRequired
                      styles={colourStylesState}
                      value={states.filter(({ abbreviation }) => values?.state?.includes(abbreviation)) || ''}
                      options={states}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.abbreviation}
                      components={{ Control, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                    />
                  </div>
                  <div className="col-sm-6 mb-2">
                    <Select
                      onChange={(e) => { setFieldValue("city", e.label); setFieldValue("city_data", [JSON.stringify(e)]) }}
                      placeholder={''}
                      label={'City'}
                      isRequired
                      isDisabled={!values?.state}
                      styles={colourStylesState}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.label}
                      value={citys.filter(({ label }) => values?.city === label) || ''}
                      options={citys?.filter(ct => ct.state === states?.find(x => x.abbreviation == values?.state)?.name)}
                      components={{ Control, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                    />
                  </div>
                  <div className="col-sm-6 mb-2">
                    <div className="did-floating-input d-flex align-items-center did-floating-label-content">
                      <span className="rate-view float-none mb-2">
                        <input onChange={handleChange} checked={values?.stars == 5} className="pb-1" type="radio" id="star5" name="stars" value="5" />
                        <label htmlFor="star5" title="5 Start">5 stars</label>
                        <input onChange={handleChange} checked={values?.stars == 4} type="radio" id="star4" name="stars" value="4" />
                        <label htmlFor="star4" title="4 Start">4 stars</label>
                        <input onChange={handleChange} checked={values?.stars == 3} type="radio" id="star3" name="stars" value="3" />
                        <label htmlFor="star3" title="3 Start">3 stars</label>
                        <input onChange={handleChange} checked={values?.stars == 2} type="radio" id="star2" name="stars" value="2" />
                        <label htmlFor="star2" title="2 Start">2 stars</label>
                        <input onChange={handleChange} checked={values?.stars == 1} type="radio" id="star1" name="stars" value="1" />
                        <label htmlFor="star1" title="1 Start">1 star</label>
                      </span>
                      <label className="did-floating-label did-floating-label-top did-floating-labe-astrisk">Rate</label>
                    </div>
                    {errors.stars ? (
                      <div className="error-message">{errors.stars}</div>
                    ) : null}
                  </div>
                  <div className="col-sm-6 mb-2">
                    <SelectField
                      name="status"
                      label={"Status"}
                      value={values.status}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      data={statusList}
                      optionLabel={"label"}
                      optionValue={"value"}
                    />
                    {errors.status ? (
                      <div className="error-message">{errors.status}</div>
                    ) : null}
                  </div>

                  <div className="col-sm-12 mb-2">
                    <label className="pb-2 label">Feedback</label>
                    <div className="">
                      <textarea className="form-control"
                        placeholder=""
                        name={'feedback'}
                        value={values.feedback}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        style={{ height: 100 }}>
                      </textarea>
                      {errors.feedback ? (
                        <div className="error-message">{errors.feedback}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="pt-4 d-flex justify-content-center pb-3">
                  <button className="btn btn-primary w-25 p-2" type="submit">Submit</button>
                </div>
              </Form>
            )}
          </Formik>
        </CModalBody>
      </CModal >

      <Spinner isLoading={AdminStore.loading} />
    </React.Fragment>
  );
});
export const Control = (props) => {
  return (
    <>
      <label
        className={
          props.hasValue || props.isFocused
            ? props?.selectProps?.isRequired
              ? "did-floating-label did-floating-label-top did-floating-labe-astrisk"
              : "did-floating-label did-floating-label-top"
            : props?.selectProps?.isRequired
              ? "did-floating-label did-floating-label did-floating-labe-astrisk"
              : "did-floating-label did-floating-label"
        }
        style={{ zIndex: 100 }}
      >
        {props?.selectProps?.label}
      </label>
      <components.Control {...props} />
    </>
  );
};
const MoreSelectedBadge = ({ items }) => {
  const style = {
    marginLeft: "auto",
    background: "#d4eefa",
    borderRadius: "4px",
    fontFamily: "Open Sans",
    fontSize: "11px",
    padding: "3px",
    order: 99,
  };

  const title = items.join(", ");
  const length = items.length;
  const label = `+ ${length} item${length !== 1 ? "s" : ""}`;

  return (
    <div style={style} title={title}>
      {label}
    </div>
  );
};
const MultiValue = ({ index, getValue, ...props }) => {
  const maxToShow = 2;
  const overflow = getValue()
    .slice(maxToShow)
    .map((x) => x.label);

  return index < maxToShow ? (
    <components.MultiValue {...props} />
  ) : index === maxToShow ? (
    <MoreSelectedBadge items={overflow} />
  ) : null;
};
export default Review;
