import { configure, makeAutoObservable, runInAction } from "mobx"
import { axiosInstance, axiosInstanceInt, setAuthToken, setAuthTokenInt } from "../api/axiosInstance"
import { toast } from 'react-toastify';
import swal from '@sweetalert/with-react';
import secureLocalStorage from "react-secure-storage";
import TokenStore from "@store/TokenStore";

class StaffsStore {
  loading = false
  data = {
    page: 1,
    pageSize: 0,
    lastPage: 0,
    total: 0,
    from: 0,
    to: 0,
    search: '',
    sort_by: 'dateAsc',
    filter_start_date: '',
    filter_end_date: '',
    filter_time_start: '',
    filter_time_end: '',
    filter_shift_type: '',
    profile: [],
    staffsList: [],
    regionsList: [],
    pg: 1,
    recs: 15,
    searchString: "",
    email: "",
    name: "",
    token: "",
    id: "",
    refreshToken: null,
    notifications: [],
    shiftList: [],
    requiredDocList: [],
    availableShifts: [],
    appliedShiftList: [],
    newJobs: [],
    jobProfile: "",
    myTeamList: [],
    myAlert: [],
    onboardingDocumentsList: [],
    signedDocumentsList: [],
    pendingShiftList: [],
    referenceData: [],
    onBoardingData: [],
    eligibleFacility: [],
    eligibleFacilityType: null,
  }
  errors = {}
  constructor() {
    makeAutoObservable(this, {
      jobProfile: true,
    });
    configure({
      useProxies: "never",
    })
  }
  toggleLoading = (toggle) => {
    this.loading = toggle
  }
  async updateAsyncStore(data) {
    secureLocalStorage.setItem("token", data.token);
    secureLocalStorage.setItem("userData", JSON.stringify(data));
  }

  updateTokenFromStorage(data, token) {
    this.data.token = token
    this.data.name = data.name
    this.data.email = data.email
    this.data.id = data.id
    this.data.picture = data.picture
  }

  async getStaffsList(param) {
    setAuthTokenInt()
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post(`user/searchCaregiver`, param)
      if (response) {
        runInAction(() => {
          this.data.staffsList = response.data;
        })
        return (response.data)
      } else {
        console.log("staff")
      }
    }
    catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log("logineere", err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async getRegionsList() {
    setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.post("api/Companystructure/list")
      if (response) {
        runInAction(() => {
          this.data.regionsList = response?.data?.rows;
        })

      } else {
        this.toggleLoading(false);
      }
    }
    catch (err) {
      console.log("logineere", err)
      this.toggleLoading(false);
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async login(creds, navigationCallBack, navigationCallBackFailed, data, token) {
    if (token) {
      setAuthToken(token)
      this.updateTokenFromStorage(data, token)
    } else {
      this.toggleLoading(true);
      try {
        const response = await axiosInstanceInt.post('/staff/login', creds)
        this.updateAsyncStore(response.data);
        setAuthToken(response.data.token);
        runInAction(() => {
          this.data.token = response?.data?.token;
          this.data.email = response?.data?.email;
          this.data.name = response?.data.name;
          this.data.id = response?.data?.id;
        })
        navigationCallBack();
      }
      catch (err) {
        if (err?.response?.status !== 500) {
          if (err?.response?.data?.status === 0) {
            navigationCallBackFailed(creds)
            return false
          }
          if (err?.response?.data?.status === 2) {
            swal("Rejected!", "Your account verification is rejected. Please contact Staffers administrator", "error");
            return false
          }
          if (err?.response?.data?.status === 3) {
            swal("Blocked!", "Your account has been temporarily disabled. Please contact the Administrator of Staffers.", "error");
            return false
          }
          if (!err?.response?.data?.status) {
            toast.error(err?.response?.data?.message);
          }
        } else {
          toast.error("Something went wrong!");
        }
      }
      finally {
        this.toggleLoading(false);
      }
    }
  }

  async registerStaff(data, navigationCallBack) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('/staff/register', data)
      if (response.data.email) {
        this.toggleLoading(false);
        navigationCallBack(response.data.email)
      } else {
        toast.error("Something went wrong!");

      }
    }
    catch (err) {
      if (err?.response?.status !== 500) {
        toast.error(Object.values(err?.response?.data?.message)[0][0]);
      } else {
        toast.error("Something went to wrong!");
      }
      this.toggleLoading(false);
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async verifyOTP(data, navigationCallBackVerify) {
    this.loading = true
    try {
      const response = await axiosInstanceInt.post('staff/verifyOtp', data)
      if (response.data.message === 'success') {
        navigationCallBackVerify()
      } else {
        toast.error("Verification Failed!!");

      }
    }
    catch (err) {
      console.log(err)
      if (err?.response?.status !== 500) {
        toast.error(err?.response?.data?.message);
      } else {
        toast.error("Something went to wrong!");
      }
    }
    finally {
      this.loading = false
    }
  }

  async reSendOTP(data) {
    this.loading = true
    try {
      const response = await axiosInstanceInt.post('staff/sendOtp', data)
      if (response.data.email) {
        toast.success("Successfully sent OTP");
        this.loading = false
      } else {
        toast.error("OTP not send!");

      }
    }
    catch (err) {
      toast.error("Something went to wrong!");
    }
    finally {
      this.loading = false
    }
  }

  async fogotPassword(data, forgotPasswordCallback) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('staff/sendResetOtp', data);
      if (response.data.email) {
        toast.success("Successfully Sent OTP");
        this.loading = false
        forgotPasswordCallback(response.data.email);
      } else {
        toast.error("OTP not send!");
        this.loading = false

      }
    }
    catch (err) {
      toast.error(err?.response?.data?.message);
      console.log(err)
    }
    finally {
      this.loading = false
    }
  }

  async verifyResetOtp(data, navigationCallBackVerify) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('staff/verifyResetOtp', data);
      if (response.data.message === 'success') {
        toast.success("Successfully Verified");
        this.loading = false
        navigationCallBackVerify(response.data.email);
      } else {
        toast.error("Verification Failed!");
        this.loading = false

      }
    }
    catch (err) {
      toast.error(err?.response?.data?.message);
      console.log(err)
    }
    finally {
      this.loading = false
    }
  }

  async setNewPassword(data, navigationCallBackNewPassword) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('staff/setNewPassword', data);
      if (response.data.message === 'success') {
        toast.success("Successfully Reset Your Password");
        this.loading = false
        navigationCallBackNewPassword();
      } else {
        toast.error("Reset Password Failed!");
        this.loading = false

      }
    }
    catch (err) {
      toast.error(err?.response?.data?.message);
      console.log(err)
    }
    finally {
      this.loading = false
    }
  }

  async getStaffProfile() {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.get('/staff/getDetails')
      if (response.data !== "") {
        runInAction(() => {
          this.data.profile = response?.data;
        })
        return response?.data
      } else {
        toast.error("Someting went to wrong!");
      }
    }
    catch (err) {
      toast.error("Someting went to wrong!");
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async updateStaffProfile(data, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.post('/staff/updateDetails', data)
      if (response.status === 200) {
        navigationCallBack(data);
      } else {
        toast.error("Someting went wrong!");
      }
    }
    catch (err) {
      if (err.response.status !== 500) {
        toast.error(Object.values(err?.response?.data?.message)[0][0]);
      } else {
        toast.error("Something went to wrong!");
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async changePassword(data, passwordnavigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.post('/staff/changePassword', data)
      if (response.status === 200) {
        passwordnavigationCallBack();
      } else {
        toast.error("Someting went to wrong!");
      }
    }
    catch (err) {
      console.log("error===>", err);
      toast.error(err?.response?.data?.message);
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async getNotificationsData() {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.get('/staff/getNotifications')
      if (response.data !== "") {
        runInAction(() => {
          this.data.notifications = response?.data;
        })
      } else {
        console.log("Someting went to wrong!");
      }
    }
    catch (err) {
      if (err?.response?.status === 401) {
        TokenStore.clearAllAsyncStore();
        swal({
          title: "Session Expired",
          text: "Your Session Has Expired, Please Login Again!",
          icon: "warning"
        }).then((willReload) => {
          if (willReload) {
            window.location.reload();
          }
        });
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async getShiftData(id, date) {
    this.toggleLoading(true);
    try {
      setAuthToken(global.config.token)
      const response = await axiosInstance.get(`api/Contractor/${id}/shifts?date=${date}`)
      if (response.data !== "") {
        runInAction(() => {
          this.data.shiftList = response?.data;
        })
      } else {
        // toast.error("Someting went to wrong!");
      }
    }
    catch (err) {
      // toast.error("Someting went to wrong!");
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async getRequiredDoc() {
    // this.toggleLoading(true);
    try {
      setAuthToken(global.config.token)
      const response = await axiosInstance.get(`api/CertificationCredentialName/list`)
      runInAction(() => {
        this.data.requiredDocList = response.data;
      })
    }
    catch (err) {
      // toast.error("Someting went to wrong!");
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async createJobProfile(values, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.post('/staff/saveJobProfile', values)
      if (response.data.message === 'success') {
        toast.success("Successfully Submitted");
        navigationCallBack()
      } else {
        toast.error("Someting went to wrong!");
      }
    }
    catch (err) {
      console.log("error", err);
      toast.error("Someting went to wrong!");
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async updateProfileStatus(values) {
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.post('/staff/updateProfileStatus', values)
      if (response.data.message === 'success') {
      } else {
        toast.error("Someting went to wrong!");
      }
    }
    catch (err) {
      console.log("error", err);
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async updateJobProfile(values, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.post('/staff/updateJobProfile', values)
      if (response.data.message === 'success') {
        toast.success("Successfully Submitted");
        navigationCallBack(values)
      } else {
        toast.error("Someting went to wrong!");
      }
    }
    catch (err) {
      console.log("error", err);
      toast.error("Someting went to wrong!");
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async getJobProfile(param) {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.get(`/staff/getJobProfile?document=${param}`)
      runInAction(() => {
        this.data.jobProfile = response.data;
      })
      return response.data;
    }
    catch (err) {
      // toast.error("Someting went to wrong!");
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async addNewDocument(data, navigationCallBack) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('/staff/addNewDocuments', data)
      if (response.status === 200) {
        navigationCallBack(response?.data?.data)
      } else {
        console.log("Failed...");
      }
    }
    catch (err) {
      this.toggleLoading(false);
      toast.error("Please try again!")
    }
    finally {
      // this.toggleLoading(false);
    }
  }
  async uploadExtDocuments(data, navigationCallBackAddDoc) {
    // this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('/staff/uploadDocuments', data)
      if (response.status === 200) {
        navigationCallBackAddDoc()
      } else {
        console.log("Failed...");
      }
    }
    catch (err) {
      this.toggleLoading(false);
      toast.error("Please try again!")
    }
    finally {
      // this.toggleLoading(false);
    }
  }

  async getSpecialtiesList(id) {
    setAuthToken(global.config.token)
    // this.toggleLoading(true);
    try {
      const response = await axiosInstance.get(`api/Contractor/SpecialtiesList/${id}`)
      if (response) {
        // this.toggleLoading(false);
        return (response.data)
      } else {
        console.log("Someting went to wrong!")
      }
    }
    catch (err) {
      console.log("logineere", err)
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async getNewJobs() {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.get("/staff/getOpportunitiesList")
      runInAction(() => {
        this.data.newJobs = response?.data?.data;
      })
    }
    catch (err) {
      // toast.error("Someting went to wrong!");
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async acceptNewJob(values, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.post('/staff/updateOpportunityStatus', values)
      if (response.status === 200) {
        toast.success("Successfully Submitted");
        navigationCallBack()
      } else {
        console.log("other");
      }
    }
    catch (err) {
      console.log("error", err);
      toast.error("Someting went wrong!");
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async getAvailableShifts(values, pageLoading) {
    pageLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.post('/staff/searchSlots', values)
      if (response.status === 200) {
        runInAction(() => {
          this.data.page = response?.data?.data?.current_page;
          this.data.lastPage = response?.data?.data?.last_page;
          this.data.pageSize = response?.data?.data?.per_page;
          this.data.total = response?.data?.data?.total;
          this.data.from = response?.data?.data?.from;
          this.data.to = response?.data?.data?.to;
          this.data.availableShifts = response?.data?.data;
        })
      } else {
        console.log("other");
      }
    }
    catch (err) {
      console.log("error", err);
      toast.error("Someting went wrong!");
    }
    finally {
      pageLoading(false);
    }
  }

  async applyShifts(values, navigationCallBack, navigationCallBackUnallocated, pageLoading) {
    pageLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.post('/staff/applySlots', values)
      if (response.status === 200) {
        if (response?.data?.failed_slots > 0) {
          swal({
            title: 'Below specified shift(s) are currently not available. Please choose another shift.',
            content: (
              <div className='row' style={{ textAlign: 'start' }}>
                <h4>Unallocated Slots</h4>
                <ul className="list-unstyled ms-3">{response?.data?.failed_dates?.map((item, ind) => {
                  return <li className="text-danger" key={ind + "list"}>
                    <input className="slot" value={item?.date} readOnly />
                    <input style={{ lineHeight: 2, width: 100 }} type="time" readOnly value={item?.start} />
                    <span style={{ padding: 5, paddingBottom: 7, paddingTop: 6, borderLeftWidth: 0, borderRightWidth: 0 }} className="timer-form text-dark">To</span>
                    <input style={{ lineHeight: 2, width: 100 }} type="time" readOnly value={item?.end} className="timer-border" />
                  </li>
                })}</ul>
              </div>
            ),
            icon: 'info',
            backdrop: false
          });
          navigationCallBackUnallocated();
        } else {
          navigationCallBack();
        }
      } else {
        console.log("other");
      }
    }
    catch (err) {
      console.log("error", err);
      toast.error("Someting went wrong!");
    }
    finally {
      pageLoading(false);
    }
  }

  async getAppliedShiftData() {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.get('/staff/getSlots')
      if (response.status === 200) {
        runInAction(() => {
          this.data.appliedShiftList = response?.data;
        })
      } else {
        console.log("other");
      }
    }
    catch (err) {
      console.log("error", err);
    }
    finally {
      this.toggleLoading(false);
    }
  }


  async getPendingShiftData() {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.get('/staff/getPendingApprovals')
      if (response.status === 200) {
        runInAction(() => {
          this.data.pendingShiftList = response?.data;
        })
      } else {
        console.log("other");
      }
    }
    catch (err) {
      console.log("error", err);
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async getMyTeam() {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.get('/staff/getMyTeam')
      if (response.status === 200) {
        runInAction(() => {
          this.data.myTeamList = response?.data?.team;
        })
      } else {
        console.log("other");
      }
    }
    catch (err) {
      console.log("error", err);
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async getAlert() {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.get('/staff/getAlerts')
      if (response.status === 200) {
        runInAction(() => {
          this.data.myAlert = response?.data?.data;
        })
      } else {
        console.log("other");
      }
    }
    catch (err) {
      console.log("error", err);
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async updateAlert(values, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.post('/staff/updateAlerts', values)
      if (response.status === 200) {
        navigationCallBack()
      } else {
        console.log("other");
      }
    }
    catch (err) {
      console.log("error", err);
      toast.error("Someting went wrong!");
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async getOnboardingDocuments() {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.post('/staff/getSignableDocuments')
      if (response.status === 200) {
        runInAction(() => {
          this.data.onboardingDocumentsList = response?.data?.data;
        })
      } else {
        console.log("other");
      }
    }
    catch (err) {
      console.log("error", err);
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async saveSignedDocuments(param, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.post('/staff/saveSignedDocuments', param)
      if (response.status === 200) {
        navigationCallBack(response?.data?.request_id)
      } else {
        console.log("other");
      }
    }
    catch (err) {
      console.log("error", err);
    }
    // finally {
    //   this.toggleLoading(false);
    // }
  }
  async generateOnboardPDFs(param, navigationCallBack) {
    // this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.post('/staff/generateOnboardPDFs', param)
      if (response.status === 200) {
        navigationCallBack()
      } else {
        console.log("other");
        navigationCallBack()
      }
    }
    catch (err) {
      navigationCallBack()
    }
    // finally {
    //   this.toggleLoading(false);
    // }
  }

  async uploadSignedDocuments(param, navigationCallBackFinal) {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.post('/staff/uploadSignedDocuments', param)
      if (response.status === 200) {
        navigationCallBackFinal()
      } else {
        console.log("other");
      }
    }
    catch (err) {
      navigationCallBackFinal()
      console.log("error", err);
    }
    // finally {
    //   this.toggleLoading(false);
    // }
  }

  async getSignedDocuments() {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.get('/staff/getSignedDocuments')
      if (response.status === 200) {
        this.data.signedDocumentsList = response?.data?.data
      } else {
        console.log("other");
      }
    }
    catch (err) {
      console.log("error", err);
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async readNotification(data, notify, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.post('/staff/readNotification', data)
      if (response.data) {
        navigationCallBack(notify);
      } else {
        console.log("Someting went wrong!");
      }
    }
    catch (err) {
      console.log("")
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async getNotify(data, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.post('/staff/notifyDeparture', data)
      if (response.status === 200) {
        navigationCallBack();
      } else {
        console.log("Someting went wrong!");
      }
    }
    catch (err) {
      console.log("")
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async saveFeedback(data, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.post('/staff/saveRating', data)
      if (response.status === 200) {
        navigationCallBack();
      } else {
        console.log("Someting went wrong!");
      }
    }
    catch (err) {
      console.log("")
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async extentionAppRejectRequest(data, navigationCallBackExtention) {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.post('/staff/markExtensionSlots', data)
      if (response.status === 200) {
        navigationCallBackExtention();
      } else {
        console.log("Someting went wrong!");
      }
    }
    catch (err) {
      console.log("")
    }
    finally {
      this.toggleLoading(false);
    }
  }
  //Accept reject Slot for staff(caregiver)
  async contractAppRejectRequest(data, navigationCallBack) {
    try {
      this.toggleLoading(true);
      setAuthTokenInt()
      const response = await axiosInstanceInt.post('/staff/acceptRejectContract', data)
      if (response.status === 200) {
        navigationCallBack();
      } else {
        console.log("Someting went wrong!");
      }
    }
    catch (err) {
      console.log("")
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async slotAppRejectRequest(data, navigationCallBack) {
    try {
      this.toggleLoading(true);
      setAuthTokenInt()
      const response = await axiosInstanceInt.post('/staff/acceptRejectSlots', data)
      if (response.status === 200) {
        navigationCallBack();
      } else {
        console.log("Someting went wrong!");
      }
    }
    catch (err) {
      console.log("")
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async addReferences(data, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.post('/staff/addReferences', data)
      if (response.status === 200) {
        navigationCallBack();
      } else {
        console.log("Someting went wrong!");
      }
    }
    catch (err) {
      if (err?.response?.status !== 500) {
        toast.error(Object.values(err?.response?.data?.message)[0][0]);
      } else {
        if (err?.response?.data?.message ==
          "Expected response code \"250\" but got code \"500\", with message \"500 5.0.0 ERROR\".") {
          toast.error("Reference email is wrong!");
          navigationCallBack();
        } else {
          toast.error("Something went to wrong!");
        }
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async editReferences(data, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.post('/staff/editReferences', data)
      if (response.status === 200) {
        navigationCallBack();
      } else {
        console.log("Someting went wrong!");
      }
    }
    catch (err) {
      console.log("")
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async getReferences() {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.get('/staff/getReferences')
      if (response.status === 200) {
        this.data.referenceData = response?.data?.data
      } else {
        console.log("other");
      }
    }
    catch (err) {
      console.log("error", err);
    }
    finally {
      this.toggleLoading(false);
    }
  }

  //SAVE ONBOARDING DATA
  async saveOnboardingData(data, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.post('/staff/saveOnboardingData', data)
      if (response.status === 200) {
        navigationCallBack();
      } else {
        console.log("Someting went wrong!");
      }
    }
    catch (err) {
      console.log("")
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async getOnboardingData() {
    // this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.get('/staff/getOnboardingData')
      if (response.status === 200) {
        this.data.onBoardingData = response?.data?.data
      } else {
        console.log("other");
      }
    }
    catch (err) {
      console.log("error", err);
    }
    finally {
      this.toggleLoading(false);
    }
  }
  //Save work prefeces
  async updateWorkPreference(data, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.post('/staff/updateWorkPreference', data)
      if (response.status === 200) {
        toast.success("Successfully Updated Your Work Preference");
        navigationCallBack();
      } else {
        console.log("Someting went wrong!");
      }
    }
    catch (err) {
      console.log("")
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async getEligibleFacility() {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.get('/staff/getEligibleFacility')
      if (response.status === 200) {
        runInAction(() => {
          this.data.eligibleFacilityType = response?.data?.region
          this.data.eligibleFacility = response?.data?.data
        });
      } else {
        console.log("other");
      }
    }
    catch (err) {
      console.log("error", err);
    }
    finally {
      this.toggleLoading(false);
    }
  }
  async reopenShifts(data, navigationCallBack) {
    this.toggleLoading(true);
    try {
      setAuthTokenInt()
      const response = await axiosInstanceInt.post('/staff/reopenShifts', data)
      if (response.status === 200) {
        navigationCallBack();
      } else {
        console.log("Someting went wrong!");
      }
    }
    catch (err) {
      console.log("")
    }
    finally {
      this.toggleLoading(false);
    }
  }
}
export default new StaffsStore()
