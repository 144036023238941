import React from "react";
import Header from '@components/PDFHeader';
import moment from "moment";

const ArbitrationAgreementCA = (props) => {
  return (
    <div id="form-content">
      <Header />
      <div className="agreement-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className=" text-decoration-underline text-center">
                ARBITRATION POLICY & AGREEMENT
              </h2>
              <h2 className=" ">
                READ THIS AGREEMENT CAREFULLY BECAUSE YOUR SIGNATURE BELOW
                CONFIRMS THAT YOU HAVE READ, UNDERSTAND AND AGREE TO THE TERMS
                AND PROCEDURES OF THIS ARBITRATION AGREEMENT.
              </h2>
              <p>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; In recognition that
                differences between Staffers Holdings LLC. (“the Company”) and
                its employees may arise during the employment relationship
                and/or as a result of the termination of that relationship, the
                Company has adopted this Arbitration Policy and Agreement
                (“Agreement”) to provide for the mutually agreed use of a
                streamlined and efficient arbitration process for the resolution
                of any Arbitrable Claim(s) between the Parties, pursuant to the
                policy and procedures set forth herein, as an alternative to
                litigating any such Claim(s) in a court, to the fullest extent
                allowed by law.
              </p>
              <ul className="main-list">
                <li>
                  hi
                  <p>
                    <h4>
                      1.{" "}
                      <span className="text-decoration-underline">
                        Parties:{" "}
                      </span>
                    </h4>
                    The Parties to this Agreement are, on the one hand, the
                    undersigned Employee, and, on the other hand, Staffers
                    Holdings LLC. its officers, directors, members, partners,
                    owners, agents, employees, parents, subsidiaries, and
                    affiliated entities (collectively referred to herein as “the
                    Company”). The Company and the Employee are collectively
                    referred to as “the Parties” herein, and may be referred to
                    interchangeably as “the Party.”
                  </p>
                </li>
                <li>
                  <p>
                    <h4 className="">
                      2.{" "}
                      <span className="text-decoration-underline">
                        Effective Date:
                      </span>
                    </h4>
                    The Effective Date of this Agreement is the date Employee
                    signed this Agreement.
                  </p>
                </li>
                <li>
                  <p>
                    <h4 className="">
                      3.
                      <span className="text-decoration-underline">
                        Term of Agreement and Third-Party Beneficiaries:
                      </span>{" "}
                    </h4>
                    This Agreement shall remain in effect even after the
                    termination of Employee's employment with the Company. This
                    Agreement is intended to also inure to the benefit of the
                    Company's clients for whom Employee performed work to the
                    extent an employment dispute arises between Employee and
                    such client and involves any Arbitrable Claim(s) under this
                    Agreement.
                  </p>
                </li>
                <li>
                  <p>
                    <h4 className="">
                      4.{" "}
                      <span className="text-decoration-underline">
                        Claim(s):
                      </span>
                    </h4>
                    As used in this Agreement, the term Claim, Claims and
                    Claim(s) means any and all causes of action, claims,
                    controversies and/or disputes.
                  </p>
                </li>
                <li>
                  <p>
                    <h4 className="">
                      5.
                      <span className="text-decoration-underline">
                        Parties’ Mutual Intent to Arbitrate Any Arbitrable
                        Claim(s) on An Individual Basis:
                      </span>
                    </h4>
                    Although the Parties hope that no employment-related Claims
                    arise between them, the Parties agree that it is in their
                    mutual interest to resolve any such matter promptly and with
                    a minimum level of disturbance to the Parties, through a
                    streamlined resolution process afforded by arbitration
                    procedures set forth herein. Accordingly, to the greatest
                    extent permitted by law, the Parties mutually agree that any
                    Arbitrable Claim(s) that the Employee, including Employee’s
                    heirs, successors and assigns, may have against the Company
                    and/or any of the Company’s clients for whom Employee
                    performed work, and that any Arbitrable Claim(s) that the
                    Company may have against Employee, which could be brought in
                    a court of law, will instead be brought in and resolved
                    through binding arbitration between the Parties, who will be
                    the only parties to the arbitration proceeding. The Parties
                    mutually agree that the arbitration procedures in this
                    Agreement, including the Waivers of Representative
                    Procedures and Claims set forth below, will constitute the
                    sole and exclusive method to resolve any Arbitrable
                    Claim(s).
                  </p>
                </li>
                <li>
                  <p>
                    <h4 className="">
                      6.{" "}
                      <span className="text-decoration-underline">
                        No Right to Trial Before Judge or Jury in Court for Any
                        Arbitrable Claim(s):
                      </span>
                    </h4>
                    The Parties understand and fully agree that by entering into
                    this Agreement providing for binding arbitration, they are
                    expressly waiving any right they may have to a trial on any
                    Arbitrable Claim(s) before a judge or jury in a federal,
                    state, or local court. The Parties anticipate that by
                    entering into this Agreement they will gain the benefits of
                    a speedy, impartial dispute resolution procedure.
                  </p>
                </li>
                <li>
                  <p>
                    <h4 className="">
                      7.{" "}
                      <span className="text-decoration-underline">
                        Arbitrable Claims:
                      </span>{" "}
                    </h4>
                    To the fullest extent permitted by law, and in accordance
                    with the Waivers of Representative Procedures and Claims set
                    forth herein, Arbitrable Claims means all Claims between
                    Employee and the Company that the Employee may have against
                    the Company, or the Company may have against the Employee
                    that arise prior to or at any time after the Effective Date
                    of this Agreement. Arbitrable Claims include, but are not
                    limited to, any Claim involving allegations of the following
                    matters:
                  </p>
                </li>
                <ul className="inner-list">
                  <li>
                    <p>
                      Wrongful or retaliatory discharge, demotion, failure to
                      hire, or failure to promote under statutory and common
                      law;
                    </p>
                  </li>
                  <li>
                    <p>
                      Discrimination, harassment or retaliation based on sex,
                      gender, gender identity, race, sexual orientation, marital
                      status, religion, national origin, veteran status, age,
                      pregnancy, leaves of absence, handicap, genetic condition,
                      a physical or mental disability, whistleblowing, or any
                      other characteristic or activity protected by applicable
                      law;
                    </p>
                  </li>
                  <li>
                    <p>
                      Violations of Title VII of the Civil Rights Act, the
                      Americans with Disabilities Act, the California Unruh
                      Civil Rights Act, the Families First Coronavirus Response
                      Act, the Coronavirus Aid, Relief, and Economic Security
                      Act, the California Fair Employment and Housing Act, the
                      Family and Medical Leave Act, the California Family Rights
                      Act, the Age Discrimination in Employment Act, the
                      California Labor Code, the Fair Labor Standards Act, the
                      Pregnancy Discrimination Act, the Railroad Labor Act, the
                      Equal Pay Act, the Jones Act, California Consumer Privacy
                      Act, the Employee Retirement Income Security Act, and/or
                      other applicable laws;
                    </p>
                  </li>
                  <li>
                    <p>
                      Tortious conduct of any type, including, but not limited
                      to, fraud, misrepresentation, concealment, negligence,
                      gross negligence, conversion, invasion of privacy,
                      defamation (including, but not limited to, libel and
                      slander, and/or infliction of emotional distress;
                    </p>
                  </li>
                  <li>
                    <p>
                      Misclassification, an unlawful exempt status designation,
                      unfair competition, issues involving drug/alcohol testing;
                    </p>
                  </li>
                  <li>
                    <p>
                      Unlawful compensation policies, practices, and/or
                      procedures, including, but not limited to, those involving
                      the alleged recovery of any earned or accrued wages,
                      bonuses, commission, benefits or other renumeration,
                      premiums, reimbursement or indemnification, statutory
                      penalties, civil penalties, the timing or accuracy of any
                      payment, and/or any required notices or information
                      related to such compensation;
                    </p>
                  </li>
                  <li>
                    <p>
                      Contractual violations (although no contractual
                      relationship is hereby created other than this Agreement),
                      breach of fiduciary duty, misappropriation, disclosure of
                      trade secrets, conversion, fraud, infringement of
                      intellectual property rights, interference with
                      contractual relations or trade, breach of the duty of
                      loyalty and/or similar wrongful conduct; and/or,
                    </p>
                  </li>
                  <li>
                    <p>
                      Any other statutory or common law claims under any law of
                      the United States, or any State, municipality, local
                      agency or any other government entity, including any
                      applicable laws relating to discrimination in employment
                      and/or wage and hour laws, and/or, violations providing
                      for injunctive, declaratory, and/or other equitable
                      relief.
                    </p>
                  </li>
                </ul>
                <p className="mb-3">
                  To the fullest extent permitted by law, Arbitrable Claims
                  include any Claim, or portion of a Claim, by Employee seeking
                  to recover civil penalties for alleged Labor Code violations
                  by the Company against Employee pursuant to California’s
                  Private Attorneys General Act of 2004, California Labor Code
                  sections 2698, et seq. (“PAGA”).
                </p>
                <li>
                  <p>
                    <h4 className="">
                      8.{" "}
                      <span className="text-decoration-underline">
                        Non-Arbitrable Claims:
                      </span>{" "}
                    </h4>
                    The Arbitrable Claims under this Agreement do not cover or
                    include any Claim Employee may have for workers'
                    compensation, unemployment compensation benefits, or any
                    other Claim, or portion of a claim deemed to not be subject
                    to a pre-dispute arbitration agreement by or under governing
                    and/or controlling law (hereinafter referred to as
                    “Non-Arbitrable Claims”). Non-Arbitrable Claims may include
                    any Claim, or portion of a Claim, by Employee (1) seeking to
                    recover civil penalties for alleged Labor Code violations
                    against any other current or former employee(s) under PAGA,
                    and/or (2) alleging sexual assault, sexual harassment, and
                    related Claims, but only to the extent that the Claim, or
                    portion of the Claim, is not subject to arbitration under
                    governing law.
                  </p>
                </li>
                <li>
                  <p>
                    <h4 className="">
                      9.
                      <span className="text-decoration-underline">
                        Waiver of Representative Procedures and Claims in
                        Arbitration:
                      </span>
                    </h4>
                    To the fullest extent permitted by law, neither the Employee
                    nor the Company will have a right to commence, initiate, or
                    participate in an arbitration of any Arbitrable Claim on (a)
                    a class or collective basis, or (b) any other representative
                    basis to the extent the Claim is brought, in whole or in
                    part, on behalf of natural persons who are not a Party under
                    this Agreement. Neither the arbitrator nor the Parties will
                    have a right to join, aggregate, or consolidate any Claim
                    brought by any other natural person in an arbitration
                    against the Employee or the Company, with any Claim in
                    arbitration between the Parties, unless agreed to in writing
                    by all parties to all Claims, including the Parties. The
                    arbitrator will have no right or authority to preside over
                    any form of a class, collective, or representative
                    proceeding brought by a Party to the extent it involves any
                    Claim brought by the Party on behalf of any other natural
                    person.
                  </p>
                </li>
                <li>
                  <p>
                    <h4 className="">
                      10.{" "}
                      <span className="text-decoration-underline">
                        Waiver of Representative Procedures and Claims in Court:
                      </span>
                    </h4>
                    To the fullest extent permitted by law, no Party will have a
                    right to file and/or participate in any class, collective,
                    and/or representative action or lawsuit against the other
                    Party in a court of law, to the extent the action or lawsuit
                    involves an Arbitrable Claim.
                  </p>
                </li>
                <li>
                  <p>
                    <h4 className="">
                      11.{" "}
                      <span className="text-decoration-underline">
                        Resolution of Disputes Involving Arbitrable Claims and
                        Non-Arbitrable Claims:
                      </span>{" "}
                    </h4>
                    In the event it is determined that a dispute between the
                    Parties involves both one or more Arbitrable Claims, and one
                    or more Non-Arbitrable Claims, the Parties agree that the
                    Arbitrable Claim(s) Page 4 2043932.1 will be resolved in
                    arbitration pursuant to the arbitration policy and
                    procedures in this Agreement, without delay or abeyance
                    based on or due to the Non-Arbitrable Claim(s), and that the
                    Non-Arbitrable Claim(s) shall only be heard by a proper
                    court of competent jurisdiction and treated according to
                    applicable law therein.
                  </p>
                </li>
                <li>
                  <p>
                    <h4 className="">
                      12.{" "}
                      <span className="text-decoration-underline">
                        Required Notice of All Claims:
                      </span>
                    </h4>
                    The Party seeking to raise any Arbitrable Claim(s) under
                    this Agreement must give written notice of the claim(s) to
                    the other Party. The written notice shall identify and
                    describe the nature of all claims asserted, the general
                    facts upon which such claims are based, and the relief
                    sought. An Employee shall provide the written notice to
                    Staffers Holdings LLC 9980 South 300 West Suite 200 Sandy
                    Utah 84070, via electronic mail at byron.kirton@staffers.us
                  </p>
                </li>
                <li>
                  <p>
                    <h4 className="">
                      13.{" "}
                      <span className="text-decoration-underline">
                        Arbitration Procedures:
                      </span>
                    </h4>
                  </p>
                </li>
                <ul className="inner-list">
                  <li>
                    <p>
                      <span className="text-decoration-underline">
                        Applicable Rules:
                      </span>
                      Any arbitration under this Agreement shall be conducted
                      pursuant to the Employment Arbitration Rules of the
                      American Arbitration Association (“AAA”), currently in
                      effect ("Applicable Rules"). By signing this Agreement,
                      Employee acknowledges that they have received a copy of
                      the Applicable Rules or had the opportunity to request a
                      copy of the Applicable Rules, and had an opportunity to
                      review them.
                    </p>
                
                    <p>
                      Employee may also obtain and review a copy of the
                      Applicable Rules through the AAA website at www.adr.org,
                      or by making a request to Employee’s applicable HR
                      business partner. By signing this Agreement, Employee
                      acknowledges that they have received a copy of the
                      Applicable Rules or have had the opportunity to request
                      and receive a copy of the Applicable Rules.
                    </p>
                    <p>
                      If there is any discrepancy between the Applicable Rules
                      and this Arbitration Policy and Agreement, this
                      Arbitration Policy and Agreement shall prevail.
                    </p>
                  </li>
                  <li>
                    <p>
                      <span className="text-decoration-underline">
                        {" "}
                        Arbitration Service Provider:
                      </span>{" "}
                      The Parties may mutually agree to use a different but
                      comparable arbitration service provider other than AAA.
                      Under any circumstance, the arbitration shall be conducted
                      pursuant to the Applicable Rules.
                    </p>
                  </li>
                  <li>
                    <p>
                      <span className="text-decoration-underline">
                        Arbitration Location/Venue:
                      </span>{" "}
                      The Parties agree that arbitration shall take place in the
                      county where the Employee performs or performed work for
                      the Company unless the Parties mutually agree to conduct
                      the arbitration elsewhere. In its sole and exclusive
                      discretion, the Company may elect to conduct arbitration
                      via electronic and/or remote means (e.g., via Zoom or a
                      comparable software platform).
                    </p>
                  </li>
                  <li>
                    <p>
                      <span className="text-decoration-underline">
                        Selection of Neutral Arbitrator:
                      </span>
                      The arbitrator shall be a neutral arbitrator, selected by
                      the agreement of the Parties, who has previous experience
                      arbitrating employment law disputes. If the Parties cannot
                      agree on a neutral arbitrator, Employee and the Company
                      will use the strike and ranking method provided for under
                      the Applicable Rules.
                    </p>
                  </li>
                  <li>
                    <p>
                      <span className="text-decoration-underline">
                        Arbitrator’s Scope of Authority:
                      </span>
                      The neutral arbitrator shall have exclusive authority to
                      resolve any Arbitrable Claim(s). The arbitrator may not,
                      however, add to or modify this Agreement, or resolve any
                      Non-Arbitrable Claim. The same statute of limitations and
                      remedies that would apply to and be available on a claim
                      in court will apply to and be available on the claim in
                      arbitration. The Parties further agree that they shall
                      have the right to bring a dispositive motion in
                      arbitration (e.g. Motion to Dismiss, Motion for Judgment
                      on the Pleadings, Motion for Summary Judgment, etc.) and
                      that any such motion will be decided under the appropriate
                      standards set forth in the Federal Rules of Civil
                      Procedure.
                    </p>
                  </li>
                  <li>
                    <p>
                      <span className="text-decoration-underline">
                        Discovery:
                      </span>
                      The Parties agree that reasonable discovery is essential
                      to the just resolution of any claims covered by this
                      Agreement. Accordingly, nothing in this Agreement or in
                      the Applicable Rules shall be interpreted to limit the
                      Parties' rights to reasonable discovery. Rather,
                      reasonable discovery shall be allowed that is sufficient
                      to ensure the adequate arbitration of any claims covered
                      by this Agreement. Generally, the Parties agree that
                      reasonable discovery means up to three depositions per
                      side, one set of requests for production of documents with
                      up to 35 requests, and one set of interrogatories with up
                      to 25 requests. In the event that a Party believes this
                      scope of discovery is inadequate, the Parties shall meet
                      and confer and try to reach agreement on the scope of
                      discovery and the arbitrator shall have discretion to
                      resolve any disagreement concerning the scope of discovery
                      and to allow discovery determined by the arbitrator to be
                      reasonably necessary to the just resolution of the dispute
                      yet consistent with the streamlined nature and purpose of
                      arbitration under this Agreement.
                    </p>
                  </li>
                  <li>
                    <p>
                      <span className="text-decoration-underline">
                        Post-Hearing Briefs, Written Opinion and Award:
                      </span>
                      At the election of either Party, the arbitrator shall
                      direct the Parties to submit post-hearing briefs. The
                      neutral arbitrator shall issue a written opinion and award
                      within 30 days of the close of the arbitration hearing,
                      which shall be signed and dated, and which shall generally
                      set forth the reasons for the arbitrator's decision. The
                      arbitrator shall be permitted to award those remedies and
                      damages that are available under applicable law and that
                      would otherwise be available in a court of law. The
                      arbitrator's decision regarding the claims shall be final
                      and binding upon the Parties. The arbitrator's award shall
                      be enforceable in any court having proper jurisdiction.
                      Any Party may be represented by an attorney or other
                      representative. The Parties' appeal rights are governed by
                      the Federal Arbitration Act.
                    </p>
                  </li>
                  <li>
                    <p>
                      <span className="text-decoration-underline">
                        Arbitration Fees and Costs:
                      </span>
                      Should Employee initiate arbitration, Employee will be
                      required to pay a reasonable cost equal to what Employee
                      would be charged to initiate a lawsuit in state court in
                      the county where the arbitration is being held. The
                      Company shall pay all other fees and costs that are unique
                      to the arbitration process, including the arbitrator's
                      fees and administrative fees. The arbitration hearing is
                      not required to be stenographically recorded, but may be
                      at the option of a Party. The Party requesting the
                      arbitration hearing to be stenographically recorded is
                      responsible for paying for the court reporter's service.
                    </p>
                    <p>
                      Except as set forth above, each Party shall pay its own
                      attorneys' fees and costs. Page 5 2043932.1 Page 6
                      2043932.1 If, however, any Party prevails on a statutory
                      claim that affords the prevailing party attorneys' fees
                      and costs, or if there is a written agreement providing
                      for attorneys' fees and costs, the arbitrator may award
                      reasonable fees and costs to the prevailing party in
                      accordance with applicable law
                    </p>
                  </li>
                </ul>
                <li>
                  <p>
                    <h4 className="">
                      14.{" "}
                      <span className="text-decoration-underline">
                        Interpretation, Validity, and Enforceability of this
                        Agreement:
                      </span>
                    </h4>
                    The Parties expressly agree that the interpretation,
                    validity and enforceability of this Agreement shall be
                    governed by the Federal Arbitration Act. Notwithstanding any
                    other provision of this Agreement or the Applicable Rules,
                    any dispute that may arise, whether in court or in
                    arbitration, regarding the validity or enforceability of the
                    Agreement, including the Waivers of Representative
                    Procedures and Claims herein, shall be resolved by a court
                    and shall not be resolved by an arbitrator.
                  </p>
                </li>
                <li>
                  <p>
                    <h4 className="">
                      15.{" "}
                      <span className="text-decoration-underline">
                        Entire Agreement:
                      </span>
                    </h4>
                    This is the complete agreement of the Parties on the subject
                    of arbitration of disputes (except for any arbitration
                    agreement that may exist in connection with any pension or
                    benefit plan). No Party is relying on any promises, oral or
                    written, on the subject of the effect, enforceability, or
                    meaning of this Agreement, except as specifically set forth
                    in this Agreement.
                  </p>
                </li>
                <li>
                  <p>
                    <h4 className="">
                      16.
                      <span className="text-decoration-underline">
                        Severability:
                      </span>
                    </h4>
                    If it is deemed that any term, phrase, provision, or portion
                    of this Agreement is invalid, void or unenforceable, or
                    otherwise in contravention of law, that term, phrase,
                    provision, or portion of the Agreement shall be severed
                    and/or modified by a court of law, and the remainder of this
                    Agreement shall be enforced to the fullest extent permitted
                    by law. In such a case, this Agreement shall be enforced to
                    the greatest extent possible under the Federal Arbitration
                    Act.
                  </p>
                </li>
                <li>
                  <p>
                    <h4 className="">
                      17.{" "}
                      <span className="text-decoration-underline">
                        Modifications to this Agreement:
                      </span>
                    </h4>
                    There shall be no modification to this Agreement unless
                    agreed to in writing by the Parties, after a dispute arises,
                    and any such written modification agreed to by the Parties
                    shall only have effect with regard to the specific dispute
                    that arose. Absent such written, contemporaneous consent of
                    the Parties in a specific dispute, any arbitration ruling by
                    an arbitrator consolidating, aggregating or otherwise
                    joining any dispute(s) of any non-Party to a dispute of a
                    Party, or allowing a class, collective, or other
                    non-individual representative dispute to proceed in
                    arbitration, would be contrary to the intent of this
                    Agreement.
                  </p>
                </li>
                <li>
                  <p>
                    <h4 className="">
                      18.{" "}
                      <span className="text-decoration-underline">
                        Not an Employment Contract:
                      </span>{" "}
                    </h4>
                    This Agreement is not, and shall not be construed to create,
                    a separate contract of employment, express or implied, nor
                    shall this Agreement be construed in any way to change the
                    relationship between the Company and Employee from that of
                    at-will employment.
                  </p>
                </li>
              </ul>
              <h4 className="text-decoration-underline text-center d-block mb-3">
                Acknowledgement of Receipt of Arbitration Policy and Agreement
              </h4>
              <p>
                I, the undersigned employee, acknowledge that I have received a
                copy of the Company’s Arbitration Policy and Agreement, that I
                have been provided an opportunity to read the Agreement, and
                that I understand and agree to its terms.
              </p>
              <div className="acknowledge-sec">
                <p>
                  <label>Signature:</label>
                  {props?.signature ? <img src={props?.signature} width={180} height={50} alt="Signature here" /> : <div class="badge badge-primary text-wrap w-25 pointer" onClick={props?.onOpenModal}>
                    Click Here To Sign
                  </div>}
                  {/* <span></span> */}
                </p>
                <p>
                  <label> Name (print):</label> <input placeholder="Full Name" style={{ borderWidth: "0px 0px 1px 0px" }} required type="text" minLength={2} maxLength={20}    name="full name" value={props.inputValues.full_name} onChange={(e) => props.handleInputChange('full_name', e.target.value)} />
                </p>
                <p>
                  <label>Date: </label>
                  <input type="text" value={moment(new Date()).format("MM-DD-YYYY HH:mm:ss")} disabled />
                  {/* <span></span> */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArbitrationAgreementCA;
