/* eslint-disable */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react"
import { Helmet } from "react-helmet";
import HeaderPage from "../layout/HeaderPage";
import DrawerMenuPage from "../layout/DrawerMenuPage";
import { ErrorMessage, FieldArray, Formik } from "formik";
import '../sub-admin/SubAdminList.css'
import Select from 'react-select';
import { useNavigate, useLocation } from 'react-router-dom';
import Spinner from "@components/Spinner";
import { InputField } from "@elements/index"
import AdminStore from "@store/AdminStore";
import StaffsStore from "@store/StaffsStore";
import { toast } from "react-toastify";
import SelectField from "@elements/select/SelectField";
import { toJS } from "mobx";
import { colourStyles, colourStylesTitle, colourStylesState } from '@utils/customStyle/style';
import { ClientSignUpSchema, SignUpSubSchema } from '@utils/schemas/allSchema';
import { Control, MultiValue } from "../commonFunctions";
var countryCodes = require('@assets/CountryCodes.json');
var citys = require('@assets/city.json');
var states = require('@assets/states.json');

const ClientAdd = observer(() => {
    const navigate = useNavigate();
    const params = useLocation();
    const [icon, setIcon] = useState("fa fa-fw fa-eye-slash field-icon toggle-password")
    const [passwordType, setPasswordType] = useState("password");
    const [clientType, setClientType] = useState('');
    const [regions, setRegions] = useState([]);
    const [clientList, setClientList] = useState([]);
    const [facilityList, setFacilityList] = useState([]);
    const [selectedClient, setSelectedClient] = useState();

    useEffect(() => {
        StaffsStore.getRegionsList();
        AdminStore.getAllClient();
        AdminStore.getAllFacility();
    }, []);

    useEffect(() => {
        setRegions(StaffsStore?.data?.regionsList)
    }, [StaffsStore?.data?.regionsList]);

    useEffect(() => {
        setClientList(toJS(AdminStore?.data?.allClientList)?.filter(x => !x.master))
    }, [AdminStore?.data?.allClientList]);

    const addClient = (values) => {
        if (values.password !== values.passwordConfirmation) {
            toast.error("Confirm password must match");
            return false
        }
        let formData = new FormData()
        formData.append("additional_contact", values.additional_contact?.length > 0 ? JSON.stringify(values.additional_contact) : "")
        formData.append("additional_mail", values.additional_mail?.length > 0 ? JSON.stringify(values.additional_mail) : "")
        values?.facility_ids?.forEach(id => {
            formData.append("facility_ids[]", id);
        });
        formData.append("title", values.title)
        formData.append("first_name", values.first_name)
        formData.append("last_name", values.last_name)
        formData.append("email", values.email)
        formData.append("contact", values.contact)
        formData.append("country_code", values.country_code)
        formData.append("description", values.description)
        formData.append("designation", values.designation)
        formData.append("password", values.password)
        values.master && formData.append("master", values.master)
        AdminStore.addClient(formData, navigationCallBack)
    }

    const navigationCallBack = () => {
        navigate('/admin/clients');
    }

    const _changeIcon = () => {
        icon === "fa fa-fw fa-eye-slash field-icon toggle-password"
            ? (setIcon("fa fa-fw fa-eye field-icon toggle-password"), setPasswordType("text"))
            : (setIcon("fa fa-fw fa-eye-slash field-icon toggle-password"), setPasswordType("password"))
    }
    return (
        <React.Fragment>
            <Helmet>
                <title>Staffers LLC | Facility User Add</title>
            </Helmet>
            {/* <body> */}
            <div className="container-scroller">
                <HeaderPage />
                <div className="container-fluid page-body-wrapper">
                    <DrawerMenuPage />
                    <div className="main-panel" style={{ right: 0, position: "absolute" }}>
                        <div className="content-wrapper p-3 bg-white ">
                            <h3 className="page-title">
                                <span className="page-title-icon linear text-white me-2">
                                    <i className="far fa-building"></i>
                                </span>Add New Facility User Profile
                            </h3>
                        </div>
                        <div className="d-flex justify-content-center">
                            <button onClick={() => setClientType('master')} className={clientType === 'master' ? 'btn btn-primary me-2' : 'btn btn-light me-2'}>Create Master Account</button>
                            <button onClick={() => { setClientType('sub'), setSelectedClient() }} className={clientType === 'sub' ? 'btn btn-primary' : 'btn btn-light'}>Create Sub Account</button>
                        </div>
                        {!clientType && <small className="d-flex justify-content-center m-3 text-danger"><i>Please select a facility user type</i></small>}
                        {/* <!-- FOR MASTER ONLY --> */}

                        {clientType === 'master' && <div className=" row justify-content-center mt-4">
                            <div className="col-lg-11">
                                <Formik
                                    initialValues={{
                                        title: "Mr.",
                                        first_name: "",
                                        last_name: "",
                                        contact: "",
                                        email: "",
                                        country_code: '+1',
                                        designation: "",
                                        facility_ids: [],
                                        description: "",
                                        password: "",
                                        passwordConfirmation: "",
                                        additional_contact: [],
                                        additional_mail: [],
                                    }}
                                    onSubmit={values => addClient(values)}
                                    enableReinitialize
                                    validationSchema={ClientSignUpSchema} >
                                    {
                                        ({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched
                                        }) =>
                                        (
                                            <form onSubmit={handleSubmit} className="wow fadeInUp">
                                                <div className="row g-2">
                                                    <div className="col-sm-6 mb-3">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                            type="text"
                                                            name="first_name"
                                                            label={"First Name"}
                                                            value={values.first_name}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            required={true}
                                                        />
                                                        {errors.first_name && touched.first_name ? (
                                                            <div className="error-message">{errors.first_name}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="col-sm-6 mb-3">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                            type="text"
                                                            name="last_name"
                                                            label={"Last Name"}
                                                            value={values.last_name}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            required={true}
                                                        />
                                                        {errors.last_name && touched.last_name ? (
                                                            <div className="error-message">{errors.last_name}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="col-sm-2 mb-3">
                                                        <Select
                                                            onChange={(e) => setFieldValue("country_code", e.label)}
                                                            placeholder={''}
                                                            label={'Country Code'}
                                                            value={countryCodes.filter(({ label }) => values?.country_code?.includes(label)) || ''}
                                                            styles={colourStyles}
                                                            isSearchable={true}
                                                            options={countryCodes}
                                                            defaultValue={{ label: "+1", value: 'CA' }}
                                                            components={{ Control }}
                                                        />
                                                    </div>
                                                    <div className="col-sm-4 mb-3">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                            type="text"
                                                            name="contact"
                                                            label={"Contact Number"}
                                                            value={values?.contact?.replace(/[^\d]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}
                                                            maxLength={12}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            required={true}
                                                        />
                                                        {errors.contact && touched.contact ? (
                                                            <div className="error-message">{errors.contact}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="col-sm-6 mb-3">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                            type="email"
                                                            name="email"
                                                            label={"Email"}
                                                            value={values.email}
                                                            disabled={params?.state?.item ? true : false}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            required={true}
                                                        />
                                                        {errors.email && touched.email ? (
                                                            <div className="error-message">{errors.email}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="col-sm-12 d-flex mt-0 pt-0">
                                                        <div className="col-sm-6">
                                                            <FieldArray
                                                                name="additional_contact"
                                                                render={({ push, remove }) => (
                                                                    <div>
                                                                        {values?.additional_contact?.map((device, index) => (
                                                                            <div key={index} className="row g-2">
                                                                                <div className="col-sm-4">
                                                                                    <Select
                                                                                        onChange={(e) => setFieldValue(`additional_contact.${index}.country_code`, e.label)}
                                                                                        placeholder={''}
                                                                                        label={'Country'}
                                                                                        styles={colourStyles}
                                                                                        isSearchable={true}
                                                                                        options={countryCodes}
                                                                                        defaultValue={{ label: "+1", value: 'CA' }}
                                                                                        components={{ Control, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-sm-7 pb-2">
                                                                                    <InputField
                                                                                        className={"did-floating-input"}
                                                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                                        type="text"
                                                                                        name="contact"
                                                                                        label={"Contact Number"}
                                                                                        value={device?.contact?.replace(/[^\d]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3') || ""}
                                                                                        maxLength={12}
                                                                                        onChange={(e) => setFieldValue(`additional_contact.${index}.contact`, e.target.value)}
                                                                                        onBlur={handleBlur}
                                                                                        required={true}
                                                                                    />
                                                                                    <ErrorMessage
                                                                                        name={`additional_contact.${index}.contact`}
                                                                                        component="div"
                                                                                        className="error-message"
                                                                                    />
                                                                                </div>
                                                                                <div className="col-sm-1">
                                                                                    <i className="fas fa-times-circle text-danger" onClick={() => remove(index)}></i>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                        {values.additional_contact?.length < 3 && <button
                                                                            type="button"
                                                                            className="btn btn-sm btn-light"
                                                                            onClick={() => push({ country_code: '+1', contact: '' })}
                                                                        >
                                                                            Add Contact
                                                                        </button>}
                                                                    </div>

                                                                )}
                                                            />
                                                        </div>
                                                        <div className="col-sm-6">
                                                            {/* More email input */}
                                                            <FieldArray
                                                                name="additional_mail"
                                                                render={({ push, remove }) => (
                                                                    <div >
                                                                        {values.additional_mail.map((device, index) => (
                                                                            <div className="row g-2" key={index}>
                                                                                <div className="col-sm-11 pb-2">
                                                                                    <InputField
                                                                                        className={"did-floating-input"}
                                                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                                        type="email"
                                                                                        name="email"
                                                                                        label={"Email Address"}
                                                                                        value={device.email}
                                                                                        onChange={(e) => setFieldValue(`additional_mail.${index}.email`, e.target.value)}
                                                                                        onBlur={handleBlur}
                                                                                        required={true}
                                                                                    />
                                                                                    <ErrorMessage
                                                                                        name={`additional_mail.${index}.email`}
                                                                                        component="div"
                                                                                        className="error-message"
                                                                                    />
                                                                                </div>
                                                                                <div className="col-sm-1">
                                                                                    <i className="fas fa-times-circle text-danger" onClick={() => remove(index)}></i>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                        {values.additional_mail?.length < 3 && <button
                                                                            type="button"
                                                                            className="btn btn-sm btn-light ms-2"
                                                                            onClick={() => push({ email: '' })}
                                                                        >
                                                                            Add Email
                                                                        </button>}
                                                                    </div>

                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 mb-3 mt-3">
                                                        <Select
                                                            onChange={(event) => { setFieldValue("facility_ids", event?.map(({ id }) => id)); }}
                                                            placeholder={''}
                                                            closeMenuOnSelect={false}
                                                            label={'Select Facility'}
                                                            value={toJS(AdminStore?.data?.allFacilityList)?.filter(({ id }) => values?.facility_ids?.includes(id)) || ''}
                                                            styles={colourStylesState}
                                                            isSearchable={true}
                                                            isMulti
                                                            options={toJS(AdminStore?.data?.allFacilityList)}
                                                            getOptionLabel={(option) => option.organization_name}
                                                            getOptionValue={(option) => option.id}
                                                            components={{ Control, MultiValue, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                        />
                                                    </div>
                                                    <div className="col-sm-6 mb-3 mt-3">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            type="text"
                                                            name="designation"
                                                            label={"Job Title"}
                                                            value={values.designation}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            required={true}
                                                        />
                                                        {errors.designation && touched.designation ? (
                                                            <div className="error-message">{errors.designation}</div>
                                                        ) : null}
                                                    </div>
                                                    {/* <div className="col-sm-6  mb-3">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                            type="text"
                                                            name="facility_code"
                                                            label={"Facility Code"}
                                                            value={values.facility_code}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            required={true}
                                                        />
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                            type="text"
                                                            name="unit_code"
                                                            label={"Unit Code"}
                                                            value={values.unit_code}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            required={true}
                                                        />
                                                    </div> */}
                                                    <div className="col-sm-6 mb-3">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                            type={passwordType}
                                                            name="password"
                                                            label={"Password"}
                                                            value={values.password}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            required={true}
                                                        />
                                                        <span toggle="#password-field" className={icon} onClick={() => _changeIcon()}></span>

                                                        {errors.password && touched.password ? (
                                                            <div className="error-message">{errors.password}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="col-sm-6 mb-3">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                            type="password"
                                                            name="passwordConfirmation"
                                                            label={"Confirm Password"}
                                                            value={values.passwordConfirmation}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            required={true}
                                                        />
                                                        {errors.passwordConfirmation && touched.passwordConfirmation ? (
                                                            <div className="error-message">{errors.passwordConfirmation}</div>
                                                        ) : null}
                                                    </div>
                                                    {/* <div className="col-sm-6 mb-3">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            type={"text"}
                                                            name="address"
                                                            label={"Full Address"}
                                                            value={values.address}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            required={true}
                                                        />
                                                    </div> */}
                                                    {/* <div className="col-sm-6 mb-3">
                                                        <Select
                                                            onChange={(e) => { setFieldValue("state", e.abbreviation), setFieldValue("city", '') }}
                                                            placeholder={''}
                                                            label={'State'}
                                                            styles={colourStylesState}
                                                            value={states.filter(({ abbreviation }) => values?.state?.includes(abbreviation)) || ''}
                                                            options={states}
                                                            getOptionLabel={(option) => option.name}
                                                            getOptionValue={(option) => option.abbreviation}
                                                            components={{ Control, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                        />
                                                    </div>
                                                    <div className="col-sm-6 mb-3">
                                                        <Select
                                                            onChange={(e) => { setFieldValue("city", e.label); setFieldValue("city_data", [e]) }}
                                                            placeholder={''}
                                                            label={'City'}
                                                            isDisabled={!values?.state}
                                                            styles={colourStylesState}
                                                            getOptionLabel={(option) => option.label}
                                                            getOptionValue={(option) => option.label}
                                                            value={citys.filter(({ label }) => values?.city === label) || ''}
                                                            options={citys?.filter(ct => ct.state === states?.find(x => x.abbreviation == values?.state)?.name)}
                                                            components={{ Control, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                        />
                                                    </div> */}
                                                    {/* <div className="col-sm-6 mb-3">
                                                        <InputField
                                                            name={"zipcode"}
                                                            className={"did-floating-input"}
                                                            type="number"
                                                            label={"Zip Code"}
                                                            value={values.zipcode}
                                                            onChange={(event) => { handleChange(event); }
                                                            }
                                                            onBlur={handleBlur}
                                                            required={true}
                                                        />
                                                        {errors.zipcode && touched.zipcode ? (
                                                            <div className="error-message">{errors.zipcode}</div>
                                                        ) : null}
                                                    </div> */}
                                                    <div className="col-12">
                                                        <label className="pb-2 label">Tell us about yourself</label>
                                                        <div className="form-floating">
                                                            <textarea className="form-control"
                                                                placeholder=""
                                                                name={'description'}
                                                                value={values.description}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                style={{ height: 100 }}></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="pt-4 pb-4 d-flex justify-content-center">
                                                        <button className="btn btn-primary w-25 p-2" type="submit">Submit</button>
                                                    </div>
                                                </div>
                                            </form>
                                        )
                                    }
                                </Formik>
                            </div>
                        </div>}

                        {//FOR SUB ACCOUNT
                            clientType === 'sub' &&
                            <div className=" row justify-content-center mt-4">
                                <div className="col-lg-11">
                                    <Formik
                                        initialValues={{
                                            title: "",
                                            first_name: "",
                                            last_name: "",
                                            contact: "",
                                            email: "",
                                            country_code: selectedClient?.country_code || '+1',
                                            designation: "",
                                            description: "",
                                            password: "",
                                            passwordConfirmation: "",
                                            master: selectedClient?.id || "",
                                            additional_contact: [],
                                            additional_mail: [],
                                            facility_ids: selectedClient?.facility_relations?.map(x => x?.facilities?.id) || [],
                                        }}
                                        onSubmit={values => addClient(values)}
                                        enableReinitialize
                                        validationSchema={SignUpSubSchema} >
                                        {
                                            ({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched
                                            }) =>
                                            (
                                                <form onSubmit={handleSubmit} className="wow fadeInUp">
                                                    <div className="row">
                                                        <div className="col-sm-3 mb-3">
                                                            <Select
                                                                onChange={(e) => { setSelectedClient(e) }}
                                                                placeholder={''}
                                                                label={'Master'}
                                                                value={clientList?.filter(({ id }) => values?.master == id)}
                                                                styles={colourStylesState}
                                                                isSearchable={true}
                                                                options={clientList}
                                                                isRequired={true}
                                                                getOptionLabel={(option) => `${option.first_name}  ${option.last_name}`}
                                                                getOptionValue={(option) => option.id}
                                                                components={{ Control, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                            />
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <Select
                                                                onChange={(event) => { setFieldValue("facility_ids", event?.map(({ id }) => id)); }}
                                                                placeholder={''}
                                                                closeMenuOnSelect={false}
                                                                label={'Select Facility'}
                                                                value={toJS(AdminStore?.data?.allFacilityList)?.filter(({ id }) => values?.facility_ids?.includes(id)) || ''}
                                                                styles={colourStylesState}
                                                                isSearchable={true}
                                                                isRequired={true}
                                                                isMulti
                                                                isDisabled
                                                                options={toJS(AdminStore?.data?.allFacilityList)}
                                                                getOptionLabel={(option) => option.organization_name}
                                                                getOptionValue={(option) => option.id}
                                                                components={{ Control, MultiValue, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                            />
                                                        </div>
                                                        <div className="col-sm-3 mb-3">
                                                            <InputField
                                                                className={"did-floating-input"}
                                                                type="text"
                                                                name="department"
                                                                label={"Department"}
                                                                value={values.department}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            />
                                                            {errors.department && touched.department ? (
                                                                <div className="error-message">{errors.department}</div>
                                                            ) : null}
                                                        </div>
                                                        <div className="col-sm-3 mb-3">
                                                            <InputField
                                                                className={"did-floating-input"}
                                                                type="text"
                                                                name="designation"
                                                                label={"Job Title"}
                                                                value={values.designation}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            />
                                                            {errors.designation && touched.designation ? (
                                                                <div className="error-message">{errors.designation}</div>
                                                            ) : null}
                                                        </div>
                                                        <div className="col-sm-6 mb-3">
                                                            <InputField
                                                                className={"did-floating-input"}
                                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                type="text"
                                                                name="first_name"
                                                                label={"First Name"}
                                                                value={values.first_name}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            />
                                                            {errors.first_name && touched.first_name ? (
                                                                <div className="error-message">{errors.first_name}</div>
                                                            ) : null}
                                                        </div>
                                                        <div className="col-sm-6 mb-3">
                                                            <InputField
                                                                className={"did-floating-input"}
                                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                type="text"
                                                                name="last_name"
                                                                label={"Last Name"}
                                                                value={values.last_name}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            />
                                                            {errors.last_name && touched.last_name ? (
                                                                <div className="error-message">{errors.last_name}</div>
                                                            ) : null}
                                                        </div>

                                                        <div className="col-sm-2 mb-3">
                                                            <Select
                                                                onChange={(e) => setFieldValue("country_code", e.label)}
                                                                placeholder={''}
                                                                label={'Country Code'}
                                                                value={countryCodes.filter(({ label }) => values?.country_code?.includes(label)) || ''}
                                                                styles={colourStyles}
                                                                isSearchable={true}
                                                                options={countryCodes}
                                                                defaultValue={{ label: "+1", value: 'CA' }}
                                                                components={{ Control }}
                                                            />
                                                        </div>
                                                        <div className="col-sm-4 mb-3">
                                                            <InputField
                                                                className={"did-floating-input"}
                                                                type="text"
                                                                name="contact"
                                                                label={"Contact Number"}
                                                                value={values?.contact?.replace(/[^\d]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}
                                                                maxLength={12}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            />
                                                        </div>
                                                        <div className="col-sm-6 mb-3">
                                                            <InputField
                                                                className={"did-floating-input"}
                                                                type="email"
                                                                name="email"
                                                                label={"Email Address"}
                                                                value={values.email}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            />
                                                        </div>
                                                        <div className="col-sm-12 d-flex mt-0 pt-0">
                                                            <div className="col-sm-6">
                                                                <FieldArray
                                                                    name="additional_contact"
                                                                    render={({ push, remove }) => (
                                                                        <div>
                                                                            {values?.additional_contact?.map((device, index) => (
                                                                                <div key={index} className="row g-2">
                                                                                    <div className="col-sm-4">
                                                                                        <Select
                                                                                            onChange={(e) => setFieldValue(`additional_contact.${index}.country_code`, e.label)}
                                                                                            placeholder={''}
                                                                                            label={'Country'}
                                                                                            styles={colourStyles}
                                                                                            isSearchable={true}
                                                                                            options={countryCodes}
                                                                                            defaultValue={{ label: "+1", value: 'CA' }}
                                                                                            components={{ Control, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-sm-7 pb-2">
                                                                                        <InputField
                                                                                            className={"did-floating-input"}
                                                                                            classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                                            type="text"
                                                                                            name="contact"
                                                                                            label={"Contact Number"}
                                                                                            value={device?.contact?.replace(/[^\d]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3') || ""}
                                                                                            maxLength={12}
                                                                                            onChange={(e) => setFieldValue(`additional_contact.${index}.contact`, e.target.value)}
                                                                                            onBlur={handleBlur}
                                                                                            required={true}
                                                                                        />
                                                                                        <ErrorMessage
                                                                                            name={`additional_contact.${index}.contact`}
                                                                                            component="div"
                                                                                            className="error-message"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-sm-1">
                                                                                        <i className="fas fa-times-circle text-danger" onClick={() => remove(index)}></i>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                            {values.additional_contact?.length < 3 && <button
                                                                                type="button"
                                                                                className="btn btn-sm btn-light mb-2"
                                                                                onClick={() => push({ country_code: '+1', contact: '' })}
                                                                            >
                                                                                Add Contact
                                                                            </button>}
                                                                        </div>

                                                                    )}
                                                                />
                                                            </div>
                                                            <div className="col-sm-6">
                                                                {/* More email input */}
                                                                <FieldArray
                                                                    name="additional_mail"
                                                                    render={({ push, remove }) => (
                                                                        <div >
                                                                            {values.additional_mail?.map((device, index) => (
                                                                                <div className="row g-2" key={index}>
                                                                                    <div className="col-sm-11 pb-2">
                                                                                        <InputField
                                                                                            className={"did-floating-input"}
                                                                                            classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                                            type="email"
                                                                                            name="email"
                                                                                            label={"Email Address"}
                                                                                            value={device.email}
                                                                                            onChange={(e) => setFieldValue(`additional_mail.${index}.email`, e.target.value)}
                                                                                            onBlur={handleBlur}
                                                                                            required={true}
                                                                                        />
                                                                                        <ErrorMessage
                                                                                            name={`additional_mail.${index}.email`}
                                                                                            component="div"
                                                                                            className="error-message"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-sm-1">
                                                                                        <i className="fas fa-times-circle text-danger" onClick={() => remove(index)}></i>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                            {values.additional_mail?.length < 3 && <button
                                                                                type="button"
                                                                                className="btn btn-sm btn-light ms-2 mb-2"
                                                                                onClick={() => push({ email: '' })}
                                                                            >
                                                                                Add Email
                                                                            </button>}
                                                                        </div>

                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <label className="py-3 pt-0">Create password</label>
                                                        <div className="col-sm-6">
                                                            <InputField
                                                                className={"did-floating-input"}
                                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                type={passwordType}
                                                                name="password"
                                                                label={"Password"}
                                                                value={values.password}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            />
                                                            <span toggle="#password-field" className={icon} onClick={() => _changeIcon()}></span>

                                                            {errors.password ? (
                                                                <div className="error-message">{errors.password}</div>
                                                            ) : null}
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <InputField
                                                                className={"did-floating-input"}
                                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                type="password"
                                                                name="passwordConfirmation"
                                                                label={"Confirm Password"}
                                                                value={values.passwordConfirmation}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            />
                                                            {errors.passwordConfirmation ? (
                                                                <div className="error-message">{errors.passwordConfirmation}</div>
                                                            ) : null}
                                                        </div>


                                                        <div className="col-12 pt-2">
                                                            <label className="pb-2 label">Tell us about yourself</label>
                                                            <div className="form-floating">
                                                                <textarea className="form-control"
                                                                    placeholder=""
                                                                    name={'description'}
                                                                    value={values.description}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    style={{ height: 100 }}></textarea>
                                                            </div>
                                                        </div>
                                                        <div className="pt-4 pb-4 d-flex justify-content-center">
                                                            <button className="btn btn-primary w-25 p-2" type="submit">Submit</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            )
                                        }
                                    </Formik>
                                </div>
                            </div>
                        }
                    </div>

                    {/* <!-- main-panel ends --> */}
                </div>
                {/* <!-- page-body-wrapper ends --> */}
            </div>
            {/* </body> */}
            <Spinner isLoading={AdminStore.loading} />
        </React.Fragment>
    );
})

export default ClientAdd;