import React from "react";
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import './sheetcomponent.css'
import parse from 'html-react-parser';
const SheetComponent = ({ isOpen, onDismiss, onApply, data }) => (
    <>
        <BottomSheet blocking={false} open={isOpen} onDismiss={onDismiss} onApply={onApply} scrollLocking={false}

            header={
                <>
                    <div className="text-end" style={{ cursor: "pointer" }} onClick={onDismiss}>
                        <i className="fa fa-times" aria-hidden="true"></i>
                    </div>
                    <h4 className="flex items-center text-xl justify-center font-bold text-primary-800">
                        {data && data.CompanyName && data?.CompanyName}
                    </h4>
                </>
            }
            footer={
                <div className="text-end">
                    <button onClick={() => onApply(data)} className="btn btn-primary">
                        Apply
                    </button>
                </div>
            }>
            <div style={{ height: global.config.windowHeight / 2.5, overflowY: 'scroll',userSelect:'contain' }}>
                <div className="col-xl-12 col-md-12">
                    <div className="card user-card-full">
                        <div className="row m-l-0 m-r-0">
                            <div className="col-sm-12">
                                <div className="card-block">
                                    <h6 className="m-b-20 p-b-5 f-w-600"> JOB ID:{data?.Guid} </h6>

                                    <h6 className="m-b-20 p-b-5 b-b-default f-w-600">Job Description</h6>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <p className="m-b-10 f-w-600">Profession</p>
                                            <h6 className="text-muted f-w-400">{data && data.Degree && data?.Degree}</h6>
                                        </div>
                                        {data && data.Specialty && <div className="col-sm-3">
                                            <p className="m-b-10 f-w-600">Specialty</p>
                                            <h6 className="text-muted f-w-400">{data?.Specialty}</h6>
                                        </div>}
                                        {data && !data.Experience && <div className="col-sm-2">
                                            <p className="m-b-10 f-w-600">Experience</p>
                                            <h6 className="text-muted f-w-400">6 year</h6>
                                        </div>}
                                        {data && data.City && <div className="col-sm-2">
                                            <p className="m-b-10 f-w-600">City</p>
                                            <h6 className="text-muted f-w-400">{data.City}</h6>
                                        </div>}
                                        {data && data.State && <div className="col-sm-1">
                                            <p className="m-b-10 f-w-600">State</p>
                                            <h6 className="text-muted f-w-400">{data.State}</h6>
                                        </div>}
                                        {data && data.Country && <div className="col-sm-1">
                                            <p className="m-b-10 f-w-600">Country</p>
                                            <h6 className="text-muted f-w-400">{data.Country}</h6>
                                        </div>}

                                    </div>
                                    <h6 className="m-b-20 m-t-40 p-b-5 b-b-default f-w-600"></h6>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <p className="m-b-10 f-w-600">Start Date</p>
                                            <h6 className="text-muted f-w-400">{data?.Date}</h6>
                                        </div>
                                        <div className="col-sm-3">
                                            <p className="m-b-10 f-w-600">Duration</p>
                                            <h6 className="text-muted f-w-400">{data?.Duration}</h6>
                                        </div>
                                        <div className="col-sm-2">
                                            <p className="m-b-10 f-w-600">Category</p>
                                            <h6 className="text-muted f-w-400">{data?.Category}</h6>
                                        </div>
                                        <div className="col-sm-2">
                                            <p className="m-b-10 f-w-600">No Of Needs</p>
                                            <h6 className="text-muted f-w-400">{data?.Positions}</h6>
                                        </div>
                                        <div className="col-sm-1">
                                            <p className="m-b-10 f-w-600">Pay Rate</p>
                                            <h6 className="text-muted f-w-400">${data?.PayRate}</h6>
                                        </div>
                                        <div className="col-sm-1">
                                            <p className="m-b-10 f-w-600">Shift</p>
                                            <h6 className="text-muted f-w-400">{data?.Shift}</h6>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {/* <div className="col-sm-3">
                                            <p className="m-b-10 f-w-600">Facility Name</p>
                                            <h6 className="text-muted f-w-400">{data?.FacilityName}</h6>
                                        </div> */}

                                        <div className="col-sm-2">
                                            <p className="m-b-10 f-w-600">Hot Job</p>
                                            <h6 className="text-muted f-w-400">{data?.HotJob}</h6>
                                        </div>
                                    </div>
                                    <h6 className="m-b-20 m-t-40 p-b-5 b-b-default f-w-600"></h6>
                                    <div className="row pb-0">
                                        <div className="col-sm-12">
                                            {data && data?.Description && parse(data?.Description)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BottomSheet>
    </>
);
export default SheetComponent;