// ReusableForm.js
import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import { BASE_API_URL_FILE } from '../../../../api/config';

const ReusableForm = ({ initialValues, onSubmit, validationSchema, fields }) => {
    const [selectedImages, setSelectedImages] = useState([]);
    const handleImageClick = (image) => {
        window.open(image, '_blank');
    }

    console.log(fields);

    return (
        <Formik
            initialValues={{
                page_id: fields?.page_id,
                section_id: fields?.id,
                sequence: fields?.sequence,
                section: [{
                    images: fields?.images,
                    content: fields?.content && JSON.parse(fields?.content),
                    links: fields?.link && JSON.parse(fields?.link)
                }]
            }}
            // validationSchema={validationSchemaAddSection}
            onSubmit={onSubmit}>
            {
                ({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched
                }) =>
                (
                    <Form>
                        {values?.section[0]?.images?.length > 0 && <div className="mb-3">
                            <p className="input-title">Images:</p>
                            <FieldArray >
                                {({ push: pushImage, remove: removeImage }) => (
                                    <div>
                                        {fields?.images && fields?.images?.map((img, imageIndex) => (
                                            <div className="input-group mb-2 me-2 pe-3" key={imageIndex + 'img'}>
                                                <div className="input-group-prepend border border-primary">
                                                    <span className="input-group-text bg-primary text-primary"><span className="badge badge-primary">#{imageIndex + 1}</span></span>
                                                </div>
                                                <input
                                                    className="form-control"
                                                    accept="image/*"
                                                    style={{ lineHeight: '40px', borderRadius: "0px 8px 8px 0px" }}
                                                    type="file"

                                                    onChange={(event) => {
                                                        const selectedFile = event.currentTarget.files[0];
                                                        if (selectedFile) {
                                                            setFieldValue(`section[0].images[${imageIndex}].file`, selectedFile);
                                                        } else {
                                                            // If no file is selected, set it to null or an empty string based on your preference
                                                            setFieldValue(`section[0].images[${imageIndex}].file`, img);
                                                        }
                                                    }}
                                                    onBlur={(e) => {
                                                        const file = e.target.files[0];
                                                        if (file) {
                                                            const reader = new FileReader();
                                                            reader.onloadend = () => {
                                                                const newImages = [...selectedImages];
                                                                newImages[imageIndex] = reader.result;
                                                                setSelectedImages(newImages);
                                                            };
                                                            reader.readAsDataURL(file);
                                                        } else {
                                                            const newImages = [...selectedImages];
                                                            newImages.splice(imageIndex, 1); // Remove the entry at imageIndex
                                                            setSelectedImages(newImages);
                                                        }
                                                    }}
                                                />
                                                {/* Right side preview */}
                                                {
                                                    <div>
                                                        {selectedImages?.length >= (imageIndex + 1)
                                                            ?
                                                            <img onClick={() => handleImageClick(selectedImages[imageIndex])} src={selectedImages[imageIndex]} alt={`Selected Preview ${imageIndex + 1}`} style={{ maxHeight: '76px', maxWidth: '76px' }} />
                                                            : <img src={BASE_API_URL_FILE + img?.url} alt={`Selected Preview ${imageIndex + 1}`} style={{ maxHeight: '76px', maxWidth: '76px' }} />
                                                        }
                                                    </div>
                                                }

                                                <input
                                                    className={"form-control"}
                                                    type="text"
                                                    label={"Image Text"}
                                                    placeholder="Type Some Text"
                                                    min={1}
                                                    // value={img?.alt_text}
                                                    name={`section[0].images[${imageIndex}].alt_text`}
                                                    onChange={handleChange}
                                                    required={true}
                                                    onBlur={handleBlur}
                                                />
                                                {/* {imageIndex > -1 && (
                                                    <button className="btn btn-outline-danger" type="button" onClick={() => {
                                                        const newImages = [...selectedImages];
                                                        newImages.splice(imageIndex, 1); // Remove the entry at imageIndex
                                                        setSelectedImages(newImages);
                                                        removeImage(imageIndex)
                                                    }}>
                                                        Remove Image
                                                    </button>
                                                )} */}
                                            </div>
                                        ))}
                                        {/* <button className="btn btn-outline-primary" type="button" onClick={() => pushImage('')}>
                                            Add Image
                                        </button> */}
                                    </div>
                                )}
                            </FieldArray>
                        </div>}
                        {values?.section[0]?.content && <div className="mb-3">
                            <p className="input-title">Content:</p>
                            <FieldArray >
                                {({ push: pushText, remove: removeText }) => (
                                    <div>
                                        {values?.section[0]?.content && values?.section[0]?.content?.map((con, textIndex) => (
                                            <div className="input-group mb-2" key={textIndex + 'content'}>
                                                <div className="input-group-prepend border border-primary">
                                                    <span className="input-group-text bg-primary text-primary"><span className="badge badge-primary">#{textIndex + 1}</span></span>
                                                </div>
                                                <Field
                                                    name={`section[0].content[${textIndex}]`} // Name added here
                                                    value={con}
                                                    required
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-control"
                                                    placeholder="Enter content"
                                                    type="text" />
                                                {/* {textIndex > -1 && (
                                                    <button className="btn btn-outline-danger" type="button" onClick={() => removeText(textIndex)}>
                                                        Remove content
                                                    </button>
                                                )} */}
                                            </div>
                                        ))}
                                        {/* <button className="btn btn-outline-primary" type="button" onClick={() => pushText('')}>
                                            Add content
                                        </button> */}
                                    </div>
                                )}
                            </FieldArray>
                        </div>}
                        {values?.section[0]?.links && <div className="mb-3">
                            <p className="input-title">Links:</p>
                            <FieldArray>
                                {({ push: pushLink, remove: removeLink }) => (
                                    <div>
                                        {values?.section[0]?.links && values?.section[0]?.links?.map((link, linkIndex) => (
                                            <div className="input-group mb-2" key={linkIndex + 'link'}>
                                                <div className="input-group-prepend border border-primary">
                                                    <span className="input-group-text bg-primary text-primary"><span className="badge badge-primary">#{linkIndex + 1}</span></span>
                                                </div>
                                                <Field
                                                    name={`section[0].links[${linkIndex}]`} // Name added here
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={link}
                                                    required
                                                    className="form-control"
                                                    placeholder="Enter link"
                                                    type="text" />
                                                {/* {linkIndex > -1 && (
                                                    <button className="btn btn-outline-danger" type="button" onClick={() => removeLink(linkIndex)}>
                                                        Remove Link
                                                    </button>
                                                )} */}
                                            </div>
                                        ))}
                                        {/* <button className="btn btn-outline-primary" type="button" onClick={() => pushLink('')}>
                                            Add Link
                                        </button> */}
                                    </div>
                                )}
                            </FieldArray>
                        </div>}
                        <div>
                            <button className="btn btn-primary w-auto pe-5 ps-5 p-2 mt-2" type="submit">Submit</button>
                        </div>
                    </Form>
                )}
        </Formik>
    );
};

export default ReusableForm;
