const colourStylesState = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? 'blue' : '#75459b',
        borderRadius: 20,
        boxShadow: 'none',
        padding: "2px 10px",
        '&:focus': {
            border: '1px solid blue !important'
        },
        '&:active': {
            border: '1px solid blue !important'
        },
        '&:hover': {
            border: '1px solid blue !important'
        }
    }),
    valueContainer: (provided) => ({
        ...provided,
        fontSize: 12,
    }),
    menu: base => ({
        ...base,
        fontSize: 12,
        zIndex: 9999, /* Adjust the z-index value as needed */
    }),
    menuList: (base) => ({
        ...base,
        height: "200px",

        "::-webkit-scrollbar": {
            width: "9px"
        },
        "::-webkit-scrollbar-track": {
            background: "white"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#8282C8"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        }
    })

}
const dropdownStyles = {
    // Define your custom styles here
    control: (provided) => ({
        ...provided,
        // You can modify other styles here if needed
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 9999, // Set the desired z-index value
    }),
};

const colourStylesCountry = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? 'blue' : '#75459b',
        borderRadius: 20,
        boxShadow: 'none',
        padding: "2px 10px",

        '&:focus': {
            border: '1px solid blue !important'
        },
        '&:active': {
            border: '1px solid blue !important'
        },
        '&:hover': {
            border: '1px solid blue !important'
        }
    }),
    menu: base => ({
        ...base,
        fontSize: 12,
        zIndex: 1999
    }),
    menuList: (base) => ({
        ...base,
        height: "200px",

        "::-webkit-scrollbar": {
            width: "9px"
        },
        "::-webkit-scrollbar-track": {
            background: "white"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#8282C8"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        }
    })

}
const colourStylesTitle = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        padding: "2px 10px",
        borderColor: state.isFocused ? 'blue' : '#75459b',
        borderRadius: 20,
        boxShadow: 'none',
        '&:focus': {
            border: '1px solid blue !important'
        },
        '&:active': {
            border: '1px solid blue !important'
        },
        '&:hover': {
            border: '1px solid #8282C8 !important'
        }
    }),
    menu: base => ({
        ...base,
        fontSize: 12,
        position: "fixed",
        zIndex: 9999, /* Adjust the z-index value as needed */
        background: "whitesmoke",
        width: "auto",
        top: "none",
        maxHeight: 300, /* Adjust the height as needed */
        overflowY: "auto"
    }),
    menuList: (base) => ({
        ...base,
        height: "auto",
        "::-webkit-scrollbar": {
            width: "9px"
        },
        "::-webkit-scrollbar-track": {
            background: "white"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#8282C8"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        }
    })
}
const dropdownStatusStyles = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? '#75459B' : '#75459B',
        borderRadius: 8,
        padding: "1px 9px",
        minWidth: 200
    }),
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: '#1e4c82',
            fontSize: 13,
        }
    },

    menu: base => ({
        ...base,
        fontSize: 12,
        zIndex: 200 // set the z-index to a high value
    }),
    multiValueLabel: (base) => ({
        ...base,
        backgroundColor: "#75459B",
        color: 'white',
    }),
    menuList: (base) => ({
        ...base,
        height: "200px",
        "::-webkit-scrollbar": {
            width: "9px"
        },
        "::-webkit-scrollbar-track": {
            background: "white"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#8282C8"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        }
    })
}
const dropdownCalendarStatusStyles = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? '#75459B' : '#75459B',
        borderRadius: 8,
        padding: "1px 9px",
        minWidth: 300
    }),
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: '#1e4c82',
            fontSize: 13,
        }
    },

    menu: base => ({
        ...base,
        fontSize: 12,
        zIndex: 200 // set the z-index to a high value
    }),
    multiValueLabel: (base) => ({
        ...base,
        backgroundColor: "#75459B",
        color: 'white',
    }),
    menuList: (base) => ({
        ...base,
        height: "200px",

        "::-webkit-scrollbar": {
            width: "9px"
        },
        "::-webkit-scrollbar-track": {
            background: "white"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#8282C8"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        }
    })
}
const colourStyles = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? '#75459B' : '#75459B',
        borderRadius: 20,
        padding: "1px 9px",
        fontSize: 15
    }),
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: '#1e4c82',
            fontSize: 13,
        }
    },

    menu: base => ({
        ...base,
        fontSize: 12,
        zIndex: 200 // set the z-index to a high value
    }),
    multiValueLabel: (base) => ({
        ...base,
        backgroundColor: "#75459B",
        color: 'white',
    }),
    menuList: (base) => ({
        ...base,
        height: "200px",

        "::-webkit-scrollbar": {
            width: "9px"
        },
        "::-webkit-scrollbar-track": {
            background: "white"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#8282C8"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        }
    })
}
export {
    colourStylesState,
    dropdownStyles,
    dropdownStatusStyles,
    colourStylesCountry,
    colourStylesTitle,
    colourStyles,
    dropdownCalendarStatusStyles
};