/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import Header from '@components/Header';
import { CButton, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem } from "@coreui/react";
import { Helmet } from 'react-helmet';
import 'react-spring-bottom-sheet/dist/style.css';
import JobsStore from "@store/JobsStore";
import { observer } from "mobx-react-lite";
import { toJS } from 'mobx';
import 'react-spring-bottom-sheet/dist/style.css';
import Spinner from "@components/Spinner";
import { toast } from "react-toastify";
import secureLocalStorage from 'react-secure-storage';
import { BottomSheet } from 'react-spring-bottom-sheet';
import parse from 'html-react-parser';
import ReactDOM from "react-dom";

const FavoriteJobList = observer(() => {
    const tableRef = useRef()
    const tableName = "table1"
    const [bookmarkJobList, setBookmarkJobList] = useState([]);
    const [jobData, setJobData] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [careGiverID, setCareGiverID] = useState(null);

    useEffect(() => {
        JobsStore.getBookmarkJob()
        let userData = secureLocalStorage.getItem("userData");
        setCareGiverID(userData)
    }, [])

    useEffect(() => {
        setBookmarkJobList(toJS(JobsStore?.data?.bookmarkJob))
    }, [JobsStore?.data?.bookmarkJob]);

    useEffect(() => {
        $.fn.dataTable.ext.errMode = 'none';
        $($.fn.dataTable.tables(true)).DataTable()
            .columns.adjust();
        const table = $(`#${tableName}`).DataTable(
            {
                data: bookmarkJobList,
                order: [], // Disable initial sorting
                columns: [
                    {
                        title: "Sr", data: 'id', width: 50, "render": function (data, type, full, meta) {
                            return meta.row + 1;
                        }
                    },
                    { title: "Job Id", data: 'job_id', width: 60 },
                    {
                        title: "Company Name", data: "company_name", "render": function (data, type, row, meta) {
                            return data || "-"
                        }
                    },
                    {
                        title: "Degree", data: "degree", "render": function (data, type, row, meta) {
                            return data || "-"
                        }
                    },
                    {
                        title: "Specialty", data: "specialty", "render": function (data, type, row, meta) {
                            return data || "-"
                        }
                    },
                    {
                        title: "Shift", data: "shift", "render": function (data, type, row, meta) {
                            return data || "-"
                        }
                    },
                    {
                        title: "No. Of Positions", width: 10, data: 'positions', "render": function (data, type, row, meta) {
                            return data || "-";
                        }
                    },
                    {
                        title: "Date", width: 50, data: 'date'
                    },
                    {
                        title: "City", width: 100, data: 'city'
                    },
                    {
                        title: "State", width: 50, data: 'state'
                    },
                    {
                        title: "Country", width: 50, data: 'country'
                    },
                    {
                        title: "Action"
                    },

                ],

                columnDefs: [
                    {
                        targets: 11,
                        responsivePriority: 1,
                        createdCell: (td, cellData, rowData, row, col) =>
                            ReactDOM.render(
                                <div>
                                    <CDropdown variant="btn-group" direction='dropstart' alignment="end">
                                        <CDropdownToggle size="sm" color="transparent" caret={false} href="#" className="p-0"><i class="fa fa-ellipsis-v text-primary"></i></CDropdownToggle>
                                        <CDropdownMenu >
                                            <CDropdownItem className="more-menu">
                                                <CButton onClick={() => {
                                                    onApply(rowData)
                                                }} size="sm" className="w-100" variant='outline' color="primary" >
                                                    <i className="far fa-edit"></i> Apply
                                                </CButton>
                                            </CDropdownItem>
                                            <CDropdownItem className="more-menu">
                                                <CButton onClick={() => {
                                                    showDetails(rowData)
                                                }} size="sm" className="w-100" variant='outline' color="primary" >
                                                    <i className="fa fa-eye" aria-hidden="true"></i> View
                                                </CButton>
                                            </CDropdownItem>
                                            <CDropdownItem className="more-menu">
                                                <CButton onClick={() => deleteBookmark(rowData)} size="sm" className="w-100" variant='outline' color="danger" >
                                                    <i className="fa fa-trash" aria-hidden="true"></i> Delete
                                                </CButton>
                                            </CDropdownItem>
                                        </CDropdownMenu>
                                    </CDropdown>
                                </div>
                                , td),
                    }
                ],
                destroy: true,  // I think some clean up is happening here
                searching: true,
                "language": {
                    search: '',
                    searchPlaceholder: "Search",
                    emptyTable: "No Record Found"
                },
                initComplete: function () {
                    // Apply the search
                    this.api()
                        .columns([0, 1, 2, 3, 4, 5, 6, 8, 9, 10])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><input class="form-control" style="width:100%;margin-top:5px;background-color:white"" type="text" placeholder="Filter" />')
                                .appendTo($(column.header()))

                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $('input', this.header()).on('keyup change clear', function () {
                                if (column.search() !== this.value) {
                                    column.search(this.value).draw();
                                }
                            });
                        });
                        this.api()
                        .columns([7])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><input class="form-control" style="width:100%;margin-top:5px;background-color:white"" type="date" placeholder="Filter" />')
                                .appendTo($(column.header()))

                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $('input', this.header()).on('keyup change clear', function () {
                                if (column.search() !== this.value) {
                                    column.search(this.value).draw();
                                }
                            });
                        });

                

                },

            }

        )
        // Extra step to do extra clean-up.
        return function () {
            table.destroy()
        }
    }, [bookmarkJobList])

    const deleteBookmark = (item) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this saved caregiver details!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    let param = {
                        'job_id': item.job_id
                    }
                    JobsStore.deleteBookmarkJob(param, navigationCallBackBookmark)
                }
            });

    }
    const navigationCallBackBookmark = () => {
        JobsStore.getBookmarkJob();
        toast.success("Successfully Removed");
    }

    const showDetails = (item) => {
        setJobData(item)
        setIsOpen(true)
    }

    const onDismissForm = () => {
        setIsOpenForm(false)
        setIsOpen(true)
    }
    const onOpenForm = () => {
        setIsOpen(false)
        setIsOpenForm(true)
    }
    const onDismiss = () => {
        setIsOpen(false)
    }
    const onApply = (jobData) => {
        JobsStore.loading = true
        if (JSON.parse(careGiverID).bluesky_staff_id) {
            let overview = {
                Title: jobData?.title,
                Date: jobData,
                EndDate: jobData?.end_date,
                Duration: jobData?.duration,
                CompanyName: jobData?.company_name,
                City: jobData?.city,
                State: jobData?.state_id,
                Country: jobData?.country,
                Category: jobData?.category,
                Degree: jobData?.degree,
                Specialty: jobData?.specialty,
                Shift: jobData?.shift
            }
            let param = {
                job_id: jobData?.job_id,
                details: overview
            }
            JobsStore.applyJob(param, navigationCallBack)
        } else {
            JobsStore.loading = false
            swal("Alert!", "You don't have an active job profile!", "warning");
        }
    }

    const navigationCallBack = (guid) => {
        let param = {
            'job_id': guid
        }
        JobsStore.deleteBookmarkJob(param, null)
        JobsStore.applyForBlueSky(JSON.parse(careGiverID).bluesky_staff_id, guid, navigationCallBackFinal)
    }

    const navigationCallBackFinal = () => {
        setIsOpen(false)
        JobsStore.getBookmarkJob()
        JobsStore.loading = false
        toast.success("Successfully Applied");
    }

    return (
        <React.Fragment>
            <Header />
            <Helmet>
                <title>Staffers LLC | Saved Jobs</title>
            </Helmet>
            <>
                <div className="container-fluid callback mt-5 py-5">
                    <div className="container pt-lg-5">
                        <h5 className="display-5 mb-4 animated slideInDown login-head">
                            Saved Jobs</h5>
                    </div>
                    <div className="col-lg-12">
                        <div className="row bg-white pt-3 ">
                            <div className="row bg-white" style={{ height: 500, paddingTop: 50 }}>
                                <table className="table dt-responsive table-hover nowrap" style={{ width: "100%" }} id={tableName} ref={tableRef}></table>
                            </div>
                        </div>
                    </div>
                </div>
                <BottomSheet blocking={false} open={isOpen} onDismiss={onDismiss} onApply={onApply} scrollLocking={false}
                    header={
                        <>
                            <div className="text-end" style={{ cursor: "pointer" }} onClick={onDismiss}>
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <h4 className="flex items-center text-xl justify-center font-bold text-primary-800">
                                {jobData && jobData.title}
                            </h4>
                        </>
                    }
                    footer={
                        <div className="text-end">
                            <button onClick={() => onApply(jobData)} className="btn btn-primary">
                                Apply
                            </button>
                        </div>
                    }>
                    <div style={{ height: global.config.windowHeight / 2.5, overflowY: 'scroll' }}>
                        <div className="col-xl-12 col-md-12">
                            <div className="user-card-full">
                                <div className="row m-l-0 m-r-0">
                                    <div className="col-sm-12">
                                        <div className="card-block">
                                            <h6 className="m-b-20 p-b-5 f-w-600"> JOB ID:{jobData?.job_id} </h6>

                                            <h6 className="m-b-20 p-b-5 b-b-default f-w-600">Job Description</h6>
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <p className="m-b-10 f-w-600">Company Name</p>
                                                    <h6 className="text-muted f-w-400">{jobData && jobData.company_name}</h6>
                                                </div>
                                                <div className="col-sm-3">
                                                    <p className="m-b-10 f-w-600">Profession</p>
                                                    <h6 className="text-muted f-w-400">{jobData && jobData.degree}</h6>
                                                </div>
                                                {jobData && jobData.specialty && <div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">Specialty</p>
                                                    <h6 className="text-muted f-w-400">{jobData?.specialty}</h6>
                                                </div>}
                                                {jobData && jobData.experience && <div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">Experience</p>
                                                    <h6 className="text-muted f-w-400">{jobData?.experience}</h6>
                                                </div>}
                                                {jobData && jobData.city && <div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">City</p>
                                                    <h6 className="text-muted f-w-400">{jobData.city}</h6>
                                                </div>}
                                                {jobData && jobData.state_id && <div className="col-sm-1">
                                                    <p className="m-b-10 f-w-600">State</p>
                                                    <h6 className="text-muted f-w-400">{jobData.state_id}</h6>
                                                </div>}
                                                {jobData && jobData.country && <div className="col-sm-1">
                                                    <p className="m-b-10 f-w-600">Country</p>
                                                    <h6 className="text-muted f-w-400">{jobData.country}</h6>
                                                </div>}

                                            </div>
                                            <h6 className="m-b-20 m-t-40 p-b-5 b-b-default f-w-600"></h6>
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <p className="m-b-10 f-w-600">Start Date</p>
                                                    <h6 className="text-muted f-w-400">{jobData?.date}</h6>
                                                </div>
                                                <div className="col-sm-3">
                                                    <p className="m-b-10 f-w-600">Duration</p>
                                                    <h6 className="text-muted f-w-400">{jobData?.duration}</h6>
                                                </div>
                                                <div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">Category</p>
                                                    <h6 className="text-muted f-w-400">{jobData?.category}</h6>
                                                </div>
                                                <div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">No Of Needs</p>
                                                    <h6 className="text-muted f-w-400">{jobData?.positions}</h6>
                                                </div>
                                                <div className="col-sm-1">
                                                    <p className="m-b-10 f-w-600">Pay Rate</p>
                                                    <h6 className="text-muted f-w-400">${jobData?.payrate}</h6>
                                                </div>
                                                <div className="col-sm-1">
                                                    <p className="m-b-10 f-w-600">Shift</p>
                                                    <h6 className="text-muted f-w-400">{jobData?.shift}</h6>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <p className="m-b-10 f-w-600">Facility Name</p>
                                                    <h6 className="text-muted f-w-400">{jobData?.facility_name}</h6>
                                                </div>

                                                <div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">Hot Job</p>
                                                    <h6 className="text-muted f-w-400">{jobData?.hot_job}</h6>
                                                </div>
                                            </div>
                                            <h6 className="m-b-20 m-t-40 p-b-5 b-b-default f-w-600"></h6>
                                            <div className="row pb-0">
                                                <div className="col-sm-12">
                                                    {jobData && jobData?.description && parse(jobData?.description)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </BottomSheet>
            </>
            <Spinner isLoading={JobsStore.loading} />
        </React.Fragment>
    )
})

export default FavoriteJobList;