import React, { useState, useEffect } from "react";

import { InputField } from "../../elements";
import { Formik } from "formik";
import SelectField from "@elements/select/SelectField";
import WebsiteLayout from "../components/WebsiteLayout";
import { Link } from "react-router-dom";
import WebsiteStore from "../../store/WebsiteStore";
import splitTextArray from "../../helpers/splitArray";
import { colourStylesState } from "@utils/customStyle/style";
import { toJS } from "mobx";
import HomeStore from "@store/HomeStore";
import Select, { components } from "react-select";
import { observer } from "mobx-react";
import { contactQuery, jobsCompany } from "../../utils/constant/constant";
import ReactGA from "react-ga";
import { EnquiryValidationSchema } from "../../utils/schemas/allSchema";
import swal from "@sweetalert/with-react";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";

import Skeleton from "react-loading-skeleton";
import PageHelmet from "../components/PageHelmet";
import moment from "moment/moment";
import { toast } from "react-toastify";
var cities = require("@assets/city.json");
var states = require("@assets/states.json");

const colourStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderColor: state.isFocused ? "#75459B" : "#75459B",
    borderRadius: 20,
    padding: "1px 9px",
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#1e4c82",
      fontSize: 13,
    };
  },

  menu: (base) => ({
    ...base,
    fontSize: 12,
    zIndex: 200, // set the z-index to a high value
  }),
  multiValueLabel: (base) => ({
    ...base,
    backgroundColor: "#75459B",
    color: "white",
  }),
  menuList: (base) => ({
    ...base,
    height: "200px",

    "::-webkit-scrollbar": {
      width: "9px",
    },
    "::-webkit-scrollbar-track": {
      background: "white",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#8282C8",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
};

const ContactUs = observer(() => {
  const [enquiryType, setEnquiryType] = useState(0);

  const [isPageLoading, setIsPageLoading] = useState(false);

  const [section1Data, setSection1Data] = useState([]);
  const [section2Data, setSection2Data] = useState([]);
  const [section3Data, setSection3Data] = useState([]);
  const [degrees, setDegrees] = useState([]);
  const [pageData, setPageData] = useState(null);
  useEffect(() => {
    loadCaptchaEnginge(6);
  }, []);
  useEffect(() => {
    const degreesData = HomeStore.data.dashboardData?.find(
      (x) => x.key_name === "DEGREES"
    );
    if (degreesData) setDegrees(JSON.parse(toJS(degreesData.value)));
  }, [HomeStore.data.dashboardData]);

  useEffect(() => {
    setIsPageLoading(true);

    WebsiteStore.page_id = 15;
    WebsiteStore.getPageDetails();
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    if (WebsiteStore?.data?.pageData !== null) {
      setPageData(toJS(WebsiteStore?.data?.pageData));
    }
    if (WebsiteStore?.data?.pageData?.sections?.length > 0) {
      console.log(
        "WebsiteStore?.data?.pageData?.sections",
        toJS(WebsiteStore?.data?.pageData?.sections)
      );

      setSection1Data(
        splitTextArray(
          toJS(WebsiteStore?.data?.pageData?.sections)?.[0]?.content
        )
      );
      setSection2Data(
        splitTextArray(
          toJS(WebsiteStore?.data?.pageData?.sections)?.[1]?.content
        )
      );
      setSection3Data(
        splitTextArray(
          toJS(WebsiteStore?.data?.pageData?.sections)?.[2]?.content
        )
      );

      setIsPageLoading(false);
    }
  }, [WebsiteStore.data.pageData]);

  const submitContactForm = (values, resetForm) => {
    const user_captcha_value =
      document.getElementById("user_captcha_input")?.value;
    if (validateCaptcha(user_captcha_value)) {
      ReactGA.event({
        category: "Contact Form Submit",
        action: "Clicked on Submit",
        label: "Contact Us Page",
      });

      const params = {
        enquiry_type: values?.enquiry_type,
        name: values?.name,
        degree: null,
        positions: values?.nurseCount,
        available_date:
          moment(
            values?.available_date,
            "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
          ).format("YYYY-MM-DD") || null,
        email: values?.email,
        contact: values?.contact,
        designation: values?.designation
          ? JSON.stringify(values?.designation)
          : null,
        company_name: values?.company_name,
        city: values?.city,
        state: values?.state,
        status: "0",
        heard_us: values?.heard_us,
        message: values?.message,
      };
      WebsiteStore.postenquiry(params, resetForm, callback);
    } else {
      toast.error("Captcha Does Not Match");
    }
  };

  const callback = (resetForm) => {
    resetForm();
    document.getElementById("remember_me").checked = false;
    swal(
      "Success!",
      "Thank you for getting in touch, Our Team will contact you soon."
    );
  };

  return (
    <div>
      <PageHelmet
        name={pageData?.page_name || "Contact Us"}
        metaData={pageData?.meta_data}
        ldjson={pageData?.ldjson}
      />
      <WebsiteLayout>
        <div className="contact-section">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-md-12">
                <div className="contact-us-main">
                  <div>
                    <img
                      className="img-fluid contact-hero"
                      src="/assets/img/website/contactus1.png"
                      alt="Contact Us Background"
                    />
                    <h3 className="common-web-head mb-4 text-center">
                      {isPageLoading ? (
                        <Skeleton height={40} />
                      ) : (
                        section1Data?.[0] || `We'd Love to Hear From You`
                      )}
                    </h3>
                  </div>
                </div>
                <div className="contact-form">
                  <div className=" callback ">
                    <div className=" ">
                      <div className="row justify-content-center ">
                        <div className="col-lg-12">
                          <div
                            className="bg-white   p-2 p-sm-4 wow fadeInUp running-border"
                            style={{ borderRadius: 25 }}
                            data-wow-delay="0.03s"
                          >
                            <div>
                              <div
                                className="text-center pb-4 mx-auto wow fadeInUp"
                                data-wow-delay="0.03s"
                                style={{ maxWidth: 600 }}
                              >
                                {isPageLoading ? (
                                  <Skeleton height={20} width={200} />
                                ) : (
                                  <p>
                                    {section1Data?.[1] || `Feel free to`}
                                    <Link
                                      className="common-web-btn purple-back p-2 mx-2"
                                      to="/register/employee"
                                    >
                                      <small>
                                        <i
                                          className="fa fa-user"
                                          aria-hidden="true"
                                        ></i>{" "}
                                        {section1Data?.[2] || `Sign Up`}
                                      </small>
                                    </Link>{" "}
                                    {section1Data?.[3] ||
                                      `join our platform directly for a more in-depth exploration`}
                                  </p>
                                )}

                                <Formik
                                  initialValues={{
                                    status: 0,
                                    name: "",
                                    email: "",
                                    enquiry_type: "",
                                    contact: "",
                                    state: "",
                                    city: "",
                                    designation: null,
                                    message: "",
                                    referral_name: "",
                                    company_name: "",
                                    positions: 0,
                                    heard_us: "",
                                    available_date: new Date(),
                                  }}
                                  onSubmit={(values, { resetForm }) =>
                                    submitContactForm(values, resetForm)
                                  }
                                  validationSchema={EnquiryValidationSchema}
                                  validateOnChange={false}
                                >
                                  {({
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    values,
                                    errors,
                                    touched,
                                    setFieldValue,
                                  }) => (
                                    <form onSubmit={handleSubmit}>
                                      {console.log("values", values)}
                                      <div className="row">
                                        <div className="col-md-6 mb-3">
                                          <SelectField
                                            name="enquiry_type"
                                            label={"How can we help you?"}
                                            required
                                            value={values.enquiry_type}
                                            onChange={(e) => {
                                              setEnquiryType(e.target.value);
                                              handleChange(e);
                                            }}
                                            onBlur={handleBlur}
                                            data={contactQuery}
                                            optionLabel={"enquiry"}
                                            optionValue={"id"}
                                          />
                                          {errors.enquiry_type &&
                                          touched.enquiry_type ? (
                                            <div className="error-message">
                                              {errors.enquiry_type}
                                            </div>
                                          ) : null}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                          <InputField
                                            className={"did-floating-input"}
                                            classNameLabel={
                                              "did-floating-label did-floating-labe-astrisk"
                                            }
                                            type="text"
                                            name="name"
                                            label={"Your Name"}
                                            value={values?.name || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                          />
                                          {errors.name ? (
                                            <div className="error-message">
                                              {errors.name}
                                            </div>
                                          ) : null}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                          <InputField
                                            className={"did-floating-input"}
                                            classNameLabel={
                                              "did-floating-label did-floating-labe-astrisk"
                                            }
                                            type="email"
                                            name={"email"}
                                            label={"Email Address"}
                                            value={values.email || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                          />
                                          {errors.email ? (
                                            <div className="error-message">
                                              {errors.email}
                                            </div>
                                          ) : null}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                          <InputField
                                            className={"did-floating-input"}
                                            classNameLabel={
                                              "did-floating-label did-floating-labe-astrisk"
                                            }
                                            type="text"
                                            name="contact"
                                            label={"Contact Number"}
                                            value={values?.contact}
                                            maxLength={12}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            required={true}
                                          />
                                          {errors.contact ? (
                                            <div className="error-message">
                                              {errors.contact}
                                            </div>
                                          ) : null}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                          <Select
                                            placeholder={""}
                                            label={"State"}
                                            styles={colourStylesState}
                                            isRequired={true}
                                            onChange={(e) => {
                                              setFieldValue(
                                                "state",
                                                e.abbreviation
                                              );
                                              setFieldValue("city", "");
                                            }}
                                            value={
                                              states.filter(
                                                ({ abbreviation }) =>
                                                  values?.state?.includes(
                                                    abbreviation
                                                  )
                                              ) || ""
                                            }
                                            options={states}
                                            getOptionLabel={(option) =>
                                              option.name
                                            }
                                            getOptionValue={(option) =>
                                              option.abbreviation
                                            }
                                            components={{
                                              Control,
                                              DropdownIndicator: () => null,
                                              IndicatorSeparator: () => null,
                                            }}
                                          />
                                          {errors.state ? (
                                            <div className="error-message">
                                              {errors.state}
                                            </div>
                                          ) : null}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                          <Select
                                            onChange={(e) => {
                                              setFieldValue("city", e.label);
                                              setFieldValue("city_data", [e]);
                                            }}
                                            placeholder={""}
                                            isRequired={true}
                                            label={"City"}
                                            isDisabled={!values?.state}
                                            styles={colourStylesState}
                                            getOptionLabel={(option) =>
                                              option.label
                                            }
                                            getOptionValue={(option) =>
                                              option.label
                                            }
                                            value={
                                              cities.filter(
                                                ({ label }) =>
                                                  values?.city === label
                                              ) || ""
                                            }
                                            options={cities?.filter(
                                              (ct) =>
                                                ct.state ===
                                                states?.find(
                                                  (x) =>
                                                    x.abbreviation ==
                                                    values?.state
                                                )?.name
                                            )}
                                            components={{
                                              Control,
                                              DropdownIndicator: () => null,
                                              IndicatorSeparator: () => null,
                                            }}
                                          />
                                          {errors.city ? (
                                            <div className="error-message">
                                              {errors.city}
                                            </div>
                                          ) : null}
                                        </div>

                                        {values?.enquiry_type === "2" && (
                                          <div className="col-md-6 mb-3">
                                            <InputField
                                              className={"did-floating-input"}
                                              classNameLabel={
                                                "did-floating-label"
                                              }
                                              type="text"
                                              name="company_name"
                                              label={"Company Name"}
                                              value={values?.company_name || ""}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                            />
                                            {errors.company_name ? (
                                              <div className="error-message">
                                                {errors.company_name}
                                              </div>
                                            ) : null}
                                          </div>
                                        )}
                                        {values?.enquiry_type === "2" && (
                                          <div className="col-md-6 mb-3">
                                            <InputField
                                              className={"did-floating-input"}
                                              type="number"
                                              name={"positions"}
                                              value={values?.positions || ""}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              label={
                                                "Total nurses required at your facility?"
                                              }
                                            />
                                          </div>
                                        )}
                                        {values?.enquiry_type === "2" && (
                                          <div className="col-md-6 mb-3">
                                            <InputField
                                              className={"did-floating-input"}
                                              classNameLabel={
                                                "did-floating-label"
                                              }
                                              type="date"
                                              name="available_date"
                                              label={
                                                "When do you need them by?"
                                              }
                                              value={values.available_date}
                                              min={
                                                new Date()
                                                  .toISOString()
                                                  .split("T")[0]
                                              }
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              required={false}
                                            />
                                            {errors.available_date &&
                                            touched.available_date ? (
                                              <div className="error-message">
                                                {errors.available_date}
                                              </div>
                                            ) : null}
                                          </div>
                                        )}
                                        {values?.enquiry_type === "5" && (
                                          <div className="col-md-6 mb-3">
                                            <InputField
                                              className={"did-floating-input"}
                                              classNameLabel={
                                                "did-floating-label did-floating-labe-astrisk"
                                              }
                                              type="text"
                                              name="referral_name"
                                              label={"Referral Name"}
                                              value={
                                                values?.referral_name || ""
                                              }
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                            />
                                            {errors.referral_name ? (
                                              <div className="error-message">
                                                {errors.referral_name}
                                              </div>
                                            ) : null}
                                          </div>
                                        )}
                                        <div className={"col-md-6 mb-3"}>
                                          <Select
                                            getOptionLabel={(option) =>
                                              `${option.Name}`
                                            }
                                            getOptionValue={(option) =>
                                              option.Id
                                            }
                                            isMulti
                                            closeMenuOnSelect={false}
                                            options={degrees}
                                            styles={colourStyles}
                                            placeholder=""
                                            isRequired={false}
                                            value={values.designation}
                                            label={
                                              values?.enquiry_type === "2"
                                                ? "What positions do you need filled"
                                                : "What positions are you interested in?"
                                            }
                                            onChange={(selectedOption) => {
                                              handleChange({
                                                target: {
                                                  name: "designation",
                                                  value: selectedOption,
                                                },
                                              });
                                            }}
                                            components={{
                                              Control,
                                              MultiValue,
                                              DropdownIndicator: () => null,
                                              IndicatorSeparator: () => null,
                                            }}
                                          />
                                          {errors.designation &&
                                          touched.designation ? (
                                            <div className="error-message">
                                              {errors.designation}
                                            </div>
                                          ) : null}
                                        </div>
                                        <div
                                          className={
                                            values?.enquiry_type !== "2" &&
                                            values?.enquiry_type !== "5"
                                              ? "col-md-6 mb-3"
                                              : "col-md-12 mb-3"
                                          }
                                        >
                                          <SelectField
                                            name="heard_us"
                                            label={"How Did You Hear About Us?"}
                                            value={values.heard_us}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            data={jobsCompany}
                                            optionLabel={"label"}
                                            optionValue={"value"}
                                          />
                                        </div>
                                        <div className="col-md-12 mb-3">
                                          <label className="pb-2 label">
                                            Message
                                          </label>
                                          <div className="form-floating mb-3">
                                            <textarea
                                              className="form-control"
                                              placeholder=""
                                              name={"message"}
                                              value={values?.message || ""}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              style={{ height: 100 }}
                                            ></textarea>
                                          </div>
                                          <div className="d-flex pb-2">
                                            <span>
                                              <label className="label">
                                                {" "}
                                                <input
                                                  type="checkbox"
                                                  required
                                                  style={{ marginTop: "0px" }}
                                                  className="form-check-input"
                                                  id="remember_me"
                                                  name="remember_me"
                                                  value="remember_me"
                                                />{" "}
                                                I agree to Staffers{" "}
                                                <a
                                                  href="https://staffers.us/terms-and-conditions/"
                                                  rel="noreferrer"
                                                  target="_blank"
                                                  style={{
                                                    textDecoration: "underline",
                                                    color: "#50B5FF",
                                                  }}
                                                >
                                                  T&C
                                                </a>{" "}
                                                and{" "}
                                                <a
                                                  href="https://staffers.us/privacy-policy/"
                                                  rel="noreferrer"
                                                  target="_blank"
                                                  style={{
                                                    textDecoration: "underline",
                                                    color: "#50B5FF",
                                                  }}
                                                >
                                                  Privacy Policy
                                                </a>
                                                .
                                              </label>
                                            </span>
                                          </div>
                                        </div>
                                        <div>
                                          <LoadCanvasTemplate />
                                        </div>
                                        <div>
                                          <input
                                            type="text"
                                            id="user_captcha_input"
                                            className="border rounded px-2 py-1 mb-3"
                                            style={{
                                              width: "100%",
                                              maxWidth: "300px",
                                            }}
                                            placeholder="Enter captcha"
                                          />
                                        </div>
                                        <div className="text-center">
                                          <button
                                            className="common-web-btn purple-back p-2"
                                            type="submit"
                                          >
                                            SEND MESSAGE
                                          </button>
                                        </div>
                                      </div>
                                    </form>
                                  )}
                                </Formik>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="welcome-staffer">
          <div className="container">
            {isPageLoading ? (
              // Render skeleton loaders while data is loading
              <div className="row align-items-center">
                <div className="col-md-12">
                  <div>
                    <h2 className="text-center common-web-head">
                      <Skeleton height={40} width={200} />
                    </h2>
                    <p className="common-web-para">
                      <Skeleton height={20} width={500} />
                      <Skeleton height={20} width={500} />
                      <Skeleton height={20} width={500} />
                    </p>
                  </div>
                </div>
                {[...Array(6)].map((_, index) => (
                  <div key={index} className="col-lg-3 col-md-3 col-sm-6">
                    <div className="welcome-box">
                      <h4 className="common-web-third">
                        <Skeleton height={20} width={200} />
                      </h4>
                      <p className="common-web-para mb-0">
                        <Skeleton height={20} width={100} />
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              // Render actual content once data is loaded
              <div className="row align-items-center">
                <div className="col-md-12">
                  <div>
                    <h2 className="text-center common-web-head">
                      {section2Data?.[0] ||
                        `Feel free to get in touch with us.`}
                    </h2>
                    <p className="common-web-para">
                      {section2Data?.[1] ||
                        `The Staffers team is dedicated to your success! Should you
                    have questions or need assistance during your assignment,
                    you can reach out to any of our specialists to assist you.
                    After hours and on weekends, you can contact our main line
                    at 801-820-0788.`}
                    </p>
                  </div>
                </div>
                {[...Array(8)].map((_, index) => (
                  <div key={index} className="col-lg-3 col-md-3 col-sm-6">
                    <div className="welcome-box">
                      <h4 className="common-web-third">
                        {section2Data?.[index * 2 + 2] || `Contact Title`}
                      </h4>
                      <p className="common-web-para mb-0">
                        {section2Data?.[index * 2 + 3] || `Contact Number`}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="contact-section-2">
          <div className="container mb-4">
            {isPageLoading ? (
              // Render skeleton loaders while data is loading
              <div className="row">
                <div className="col-md-4 p-2">
                  <div className="contact-info">
                    <div className="elementor-subtitle">
                      <h6>
                        <Skeleton height={20} width={120} />
                      </h6>
                    </div>
                    <h3 className="common-web-head mb-4 text-left">
                      <Skeleton height={40} width={200} />
                    </h3>
                    <h5>
                      <Skeleton height={20} width={100} />
                    </h5>
                    <ul>
                      {[...Array(4)].map((_, index) => (
                        <li key={index}>
                          <i className="fas fa-map-marker-alt"></i>
                          <p>
                            <Skeleton height={20} width={300} />
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="col-md-8">
                  <Skeleton height={450} />
                </div>
              </div>
            ) : (
              // Render actual content once data is loaded
              <div className="row">
                <div className="col-md-4 p-2">
                  <div className="contact-info">
                    <div className="elementor-subtitle">
                      <h6>{section3Data?.[0] || `GET IN TOUCH`}</h6>
                    </div>
                    <h3 className="common-web-head mb-4 text-left">
                      {section3Data?.[1] || `We are here to help.`}
                    </h3>
                    <h5>{section3Data?.[2] || `Head Office`}</h5>
                    <ul>
                      <li>
                        <i className="fas fa-map-marker-alt"></i>
                        <p>
                          {section3Data?.[3] ||
                            `9980 South 300 West, Suite 200 Sandy, UT 84070`}
                        </p>
                      </li>
                      <li>
                        <i className="fas fa-envelope"></i>
                        <div>
                          <p>{section3Data?.[4] || `staff@staffers.us`}</p>
                          <p>{section3Data?.[5] || `travel@staffers.us`}</p>
                        </div>
                      </li>
                      <li>
                        <i className="fas fa-mobile-alt"></i>
                        <p>{section3Data?.[6] || `(801) 820-0788`}</p>
                      </li>
                      <li>
                        <div>
                          <p className="follow">
                            {section3Data?.[7] || `Follow Us:`}
                          </p>
                        </div>
                        <div className="social-link">
                          <div>
                            <a className="">
                              <img
                                src="/assets/images/web-images/facebook.svg"
                                className="img-fluid"
                                alt=""
                              />
                            </a>
                          </div>
                          <div>
                            <a className="">
                              <img
                                src="/assets/images/web-images/linkedin.svg"
                                className="img-fluid"
                                alt=""
                              />
                            </a>
                          </div>
                          <div>
                            <a className="">
                              <img
                                src="/assets/images/web-images/instagram.svg"
                                className="img-fluid"
                                alt=""
                              />
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-8">
                  <iframe
                    className="map-view"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3030.705366670139!2d-111.90372892519115!3d40.57018324628739!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x875287d3a62fab25%3A0x48e306e15e89f22!2s9980%20S%20300%20W%2C%20Sandy%2C%20UT%2084070%2C%20USA!5e0!3m2!1sen!2sin!4v1704629922100!5m2!1sen!2sin"
                    width="100%"
                    height="450"
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            )}
          </div>
          <div className="new-extra-space">.</div>
        </div>
      </WebsiteLayout>
    </div>
  );
});

export default ContactUs;

export const Control = (props) => {
  return (
    <>
      <label
        className={
          props.hasValue || props.isFocused
            ? props?.selectProps?.isRequired
              ? "did-floating-label did-floating-label-top did-floating-labe-astrisk"
              : "did-floating-label did-floating-label-top"
            : props?.selectProps?.isRequired
            ? "did-floating-label did-floating-label did-floating-labe-astrisk"
            : "did-floating-label did-floating-label"
        }
        style={{ zIndex: 1 }}
      >
        {props?.selectProps?.label}
      </label>
      <components.Control {...props} />
    </>
  );
};

const MoreSelectedBadge = ({ items }) => {
  console.log("items", items);
  const style = {
    marginLeft: "auto",
    background: "#d4eefa",
    borderRadius: "4px",
    // fontFamily: "Open Sans",
    fontSize: "11px",
    padding: "3px",
    order: 99,
  };

  const title = items.join(", ");
  const length = items.length;
  const label = `+ ${length} Degr${length !== 1 ? "ees" : "ee"}`;

  return (
    <div style={style} title={title}>
      {label}
    </div>
  );
};
const MultiValue = ({ index, getValue, ...props }) => {
  const maxToShow = 3;
  const overflow = getValue()
    .slice(maxToShow)
    .map((x) => x.label);

  return index < maxToShow ? (
    <components.MultiValue {...props} />
  ) : index === maxToShow ? (
    <MoreSelectedBadge items={overflow} />
  ) : null;
};
