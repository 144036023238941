/* eslint-disable */
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react"
import { Images } from "../../elements";

const CategoryItems = observer(() => {

    return (
        <div className="container-xxl mt-5 mt-lg-5 pt-lg-5 pb-5" >
            <div className="container">
                <div className="border rounded p-4 wow fadeInUp" data-wow-delay="0.03s">
                    <div className="row g-4 ">
                        <div className="col-lg-4 wow fadeIn text-center" data-wow-delay="0.03s">
                            <div className="h-100 text-center">
                                <div className="d-flex justify-content-center">
                                    <div className="ps-3">
                                        <div className="btn btn-custom-light rounded-circle">
                                            <img className="img-fluid rounded" src={Images.health} />
                                        </div>
                                        <div className="p-4">
                                            <h4>Trustworthy</h4>
                                            <span>We keep our promises and can count on each other</span>
                                        </div>
                                    </div>
                                    <div className="border-end d-none d-lg-block"></div>
                                </div>
                                <div className="border-bottom mt-4 d-block d-lg-none"></div>
                            </div>
                        </div>
                        <div className="col-lg-4 wow fadeIn text-center" data-wow-delay="0.03s">
                            <div className="h-100  text-center">
                                <div className="d-flex">
                                    <div className="ps-3 ">
                                        <div className="btn btn-custom-light rounded-circle">
                                            {/* <i className="fa fa-trophy text-white " ></i> */}
                                            <img className="img-fluid rounded" src={Images.quality} />

                                        </div>
                                        <div className="p-4">
                                            <h4>Flexibility</h4>
                                            <span>We embrace some level flexibility in order to deliver excellent customer service</span>
                                        </div>
                                    </div>
                                    <div className="border-end d-none d-lg-block"></div>
                                </div>
                                <div className="border-bottom mt-4 d-block d-lg-none"></div>
                            </div>
                        </div>
                        <div className="col-lg-4 wow fadeIn text-center" data-wow-delay="0.03s">
                            <div className="h-100">
                                <div className="d-flex">
                                    <div className="ps-3">
                                        {/* <div className="flex-shrink-0 btn-lg-square rounded-circle bg-primary"> */}
                                        <div className="btn btn-custom-light rounded-circle">
                                            {/* <i className="fa fa-phone text-white"></i> */}
                                            <img className="img-fluid rounded" src={Images.support} />

                                        </div>
                                        <div className="p-4">
                                            <h4>Satisfaction</h4>
                                            <span>We ensure all of our customers are 100% satisfied with the services delivered</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default CategoryItems;
