import React from 'react'
import HeaderWeb from './HeaderWeb'
import FooterWeb from './FooterWeb'

const WebsiteLayout = (props) => {
  return (
    <div className="font-same-all" >
    <HeaderWeb />
    {props.children}
    <FooterWeb />
</div>
  )
}

export default WebsiteLayout