/* eslint-disable */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react"
import { Helmet } from "react-helmet";
import HeaderPage from "../layout/HeaderPage";
import DrawerMenuPage from "../layout/DrawerMenuPage";
import { ErrorMessage, FieldArray, Formik } from "formik";
import '../sub-admin/SubAdminList.css'
import Select from 'react-select';
import { useNavigate, useLocation } from 'react-router-dom';
import Spinner from "@components/Spinner";
import { InputField } from "@elements/index"
import AdminStore from "@store/AdminStore";
import StaffsStore from "@store/StaffsStore";
import { toast } from "react-toastify";
import SelectField from "@elements/select/SelectField";
import { ClientEditSchema, SubClientEditSchema } from '@utils/schemas/allSchema';
import { colourStyles, colourStylesState, colourStylesTitle } from '@utils/customStyle/style';
import { Control, MultiValue } from "../commonFunctions";
import { toJS } from "mobx";
var countryCodes = require('@assets/CountryCodes.json');
var citys = require('@assets/city.json');
var states = require('@assets/states.json');

const ClientEdit = observer(() => {
    const navigate = useNavigate();
    const params = useLocation();
    const [icon, setIcon] = useState("fa fa-fw fa-eye-slash field-icon toggle-password")
    const [passwordType, setPasswordType] = useState("password");
    const [regions, setRegions] = useState([]);
    useEffect(() => {
        StaffsStore.getRegionsList();
        AdminStore.getAllFacility();
    }, []);
    useEffect(() => {
        setRegions(StaffsStore?.data?.regionsList)
    }, [StaffsStore?.data?.regionsList]);

    const updateClient = (values) => {
        if (params?.state?.item?.id) {
            if (values.password != values.confirm_password) {
                toast.error("Confirm password must match");
                return false
            }
            let formData = new FormData()
            values.password && formData.append("password", values.password)
            formData.append("additional_contact", values.additional_contact?.length > 0 ? JSON.stringify(values.additional_contact) : "")
            formData.append("additional_mail", values.additional_mail?.length > 0 ? JSON.stringify(values.additional_mail) : "")
            values?.facility_ids?.forEach(id => {
                formData.append("facility_ids[]", id);
            });
            formData.append("first_name", values.first_name)
            formData.append("last_name", values.last_name)
            formData.append("email", values.email)
            formData.append("contact", values.contact)
            formData.append("country_code", values.country_code)
            formData.append("description", values.description)
            formData.append("designation", values.designation)
            formData.append("id", params?.state?.item?.id)
            params?.state?.item?.master && formData.append("master", params?.state?.item?.master)
            AdminStore.updateClient(formData, navigationCallBack)
        }
    }
    const navigationCallBack = () => {
        navigate('/admin/clients');
    }
    const _changeIcon = () => {
        icon === "fa fa-fw fa-eye-slash field-icon toggle-password"
            ? (setIcon("fa fa-fw fa-eye field-icon toggle-password"), setPasswordType("text"))
            : (setIcon("fa fa-fw fa-eye-slash field-icon toggle-password"), setPasswordType("password"))
    }
    return (
        <React.Fragment>
            <Helmet>
                <title>Staffers LLC | Facility User Edit</title>
            </Helmet>
            {/* <body> */}
            <div className="container-scroller">
                <HeaderPage />
                <div className="container-fluid page-body-wrapper">
                    <DrawerMenuPage />
                    <div className="main-panel" style={{ right: 0, position: "absolute" }}>
                        <div className="content-wrapper p-3 bg-white ">
                            <h3 className="page-title">
                                <span className="page-title-icon linear text-white me-2">
                                    <i className="far fa-edit"></i>
                                </span>Edit Facility User Profile
                            </h3>

                        </div>
                        {/* <!-- partial --> */}
                        <div className=" row justify-content-center mt-4">
                            <div className="col-lg-11">
                                <Formik
                                    initialValues={{
                                        first_name: params?.state?.item?.first_name
                                            || '', last_name: params?.state?.item?.last_name
                                                || '', contact: params?.state?.item?.contact
                                                    || '', email: params?.state?.item?.email
                                                        || '', country_code: params?.state?.item?.country_code
                                                            || '+1', designation: params?.state?.item?.designation

                                                                || "", description: params?.state?.item?.description
                                                                    || "", password: '', confirm_password: '',


                                        additional_contact: params?.state?.item?.additional_contact ? JSON.parse(params?.state?.item?.additional_contact) : [],
                                        additional_mail: params?.state?.item?.additional_mail ? JSON.parse(params?.state?.item?.additional_mail) : [],
                                        master: params?.state?.item?.master || '',
                                        facility_ids: params?.state?.item?.facility_relations?.map(x => x.facility_id) || '',
                                    }}
                                    onSubmit={values => updateClient(values)}
                                    enableReinitialize
                                    validationSchema={params?.state?.item?.master ? SubClientEditSchema : ClientEditSchema} 
                                    validateOnChange={false}>
                                    {
                                        ({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched
                                        }) =>
                                        (
                                            <form onSubmit={handleSubmit} className="wow fadeInUp">
                                                <div className="row g-2">
                                                    <div className="col-sm-6 mb-3">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            type="text"
                                                            name="first_name"
                                                            label={"First Name"}
                                                            value={values.first_name}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.first_name && touched.first_name ? (
                                                            <div className="error-message">{errors.first_name}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="col-sm-6 mb-3">
                                                        <InputField
                                                            className={"did-floating-input"}

                                                            type="text"
                                                            name="last_name"
                                                            label={"Last Name"}
                                                            value={values.last_name}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.last_name && touched.last_name ? (
                                                            <div className="error-message">{errors.last_name}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="col-sm-2 mb-3">
                                                        <Select
                                                            onChange={(e) => setFieldValue("country_code", e.label)}
                                                            placeholder={''}
                                                            label={'Country Code'}
                                                            value={countryCodes?.filter(({ label }) => values?.country_code?.includes(label)) || ''}
                                                            styles={colourStyles}
                                                            isSearchable={true}
                                                            options={countryCodes}
                                                            defaultValue={{ label: "+1", value: 'CA' }}
                                                            components={{ Control }}
                                                        />
                                                    </div>
                                                    <div className="col-sm-4 mb-3">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            classNameLabel={params?.state?.item?.master ? "" : "did-floating-label did-floating-labe-astrisk"}
                                                            type="text"
                                                            name="contact"
                                                            label={"Contact Number"}
                                                            value={values?.contact?.replace(/[^\d]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}
                                                            maxLength={12}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.contact && touched.contact ? (
                                                            <div className="error-message">{errors.contact}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="col-sm-6 mb-3">
                                                        <InputField
                                                            classNameLabel={params?.state?.item?.master ? "" : "did-floating-label did-floating-labe-astrisk"}
                                                            className={"did-floating-input"}
                                                            type="email"
                                                            name="email"
                                                            label={"Email Address"}
                                                            value={values.email}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.email && touched.email ? (
                                                            <div className="error-message">{errors.email}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="col-sm-12 d-flex mt-0 pt-0">
                                                        <div className="col-sm-6">
                                                            <FieldArray
                                                                name="additional_contact"
                                                                render={({ push, remove }) => (
                                                                    <div>
                                                                        {values?.additional_contact?.map((device, index) => (
                                                                            <div key={index} className="row g-2">
                                                                                <div className="col-sm-4">
                                                                                    <Select
                                                                                        onChange={(e) => setFieldValue(`additional_contact.${index}.country_code`, e.label)}
                                                                                        value={countryCodes?.filter(({ label }) => device?.country_code === label)}
                                                                                        placeholder={''}
                                                                                        label={'Country'}
                                                                                        styles={colourStyles}
                                                                                        isSearchable={true}
                                                                                        options={countryCodes}
                                                                                        components={{ Control, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-sm-7 pb-2">
                                                                                    <InputField
                                                                                        className={"did-floating-input"}
                                                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                                        type="text"
                                                                                        name="contact"
                                                                                        label={"Contact Number"}
                                                                                        value={device?.contact?.replace(/[^\d]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3') || ""}
                                                                                        maxLength={12}
                                                                                        onChange={(e) => setFieldValue(`additional_contact.${index}.contact`, e.target.value)}
                                                                                        onBlur={handleBlur}
                                                                                        required={true}
                                                                                    />
                                                                                    <ErrorMessage
                                                                                        name={`additional_contact.${index}.contact`}
                                                                                        component="div"
                                                                                        className="error-message"
                                                                                    />
                                                                                </div>
                                                                                <div className="col-sm-1">
                                                                                    <i className="fas fa-times-circle text-danger" onClick={() => remove(index)}></i>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                        {values.additional_contact?.length < 3 && <button
                                                                            type="button"
                                                                            className="btn btn-sm btn-light"
                                                                            onClick={() => push({ country_code: '+1', contact: '' })}
                                                                        >
                                                                            Add Contact
                                                                        </button>}
                                                                    </div>

                                                                )}
                                                            />
                                                        </div>
                                                        <div className="col-sm-6">
                                                            {/* More email input */}
                                                            <FieldArray
                                                                name="additional_mail"
                                                                render={({ push, remove }) => (
                                                                    <div >
                                                                        {values.additional_mail?.map((device, index) => (
                                                                            <div className="row g-2" key={index}>
                                                                                <div className="col-sm-11 pb-2">
                                                                                    <InputField
                                                                                        className={"did-floating-input"}
                                                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                                        type="email"
                                                                                        name="email"
                                                                                        label={"Email Address"}
                                                                                        value={device.email}
                                                                                        onChange={(e) => setFieldValue(`additional_mail.${index}.email`, e.target.value)}
                                                                                        onBlur={handleBlur}
                                                                                        required={true}
                                                                                    />
                                                                                    <ErrorMessage
                                                                                        name={`additional_mail.${index}.email`}
                                                                                        component="div"
                                                                                        className="error-message"
                                                                                    />
                                                                                </div>
                                                                                <div className="col-sm-1">
                                                                                    <i className="fas fa-times-circle text-danger" onClick={() => remove(index)}></i>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                        {values.additional_mail?.length < 3 && <button
                                                                            type="button"
                                                                            className="btn btn-sm btn-light ms-2"
                                                                            onClick={() => push({ email: '' })}
                                                                        >
                                                                            Add Email
                                                                        </button>}
                                                                    </div>

                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 mb-3 mt-3">
                                                        <Select
                                                            onChange={(event) => { setFieldValue("facility_ids", event?.map(({ id }) => id)); }}
                                                            placeholder={''}
                                                            closeMenuOnSelect={false}
                                                            label={'Facility?'}
                                                            value={toJS(AdminStore?.data?.allFacilityList)?.filter(({ id }) => values?.facility_ids?.includes(id)) || ''}
                                                            styles={colourStylesTitle}
                                                            isSearchable={true}
                                                            isMulti
                                                            options={toJS(AdminStore?.data?.allFacilityList)}
                                                            getOptionLabel={(option) => option.organization_name}
                                                            getOptionValue={(option) => option.id}
                                                            components={{ Control, MultiValue, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                        />
                                                    </div>

                                                    <div className="col-sm-6 mb-3 mt-3">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            type="text"
                                                            name="designation"
                                                            label={"Job Title"}
                                                            value={values.designation}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.designation && touched.designation ? (
                                                            <div className="error-message">{errors.designation}</div>
                                                        ) : null}
                                                    </div>

                                                    {<label className="py-3 pt-0">Change password</label>}
                                                    <div className="col-sm-6">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            type={passwordType}
                                                            name="password"
                                                            label={"Password"}
                                                            value={values.password}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        <span toggle="#password-field" className={icon} onClick={() => _changeIcon()}></span>

                                                        {errors.password ? (
                                                            <div className="error-message">{errors.password}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="col-sm-6 mb-3">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            type="password"
                                                            name="confirm_password"
                                                            label={"Confirm Password"}
                                                            value={values.confirm_password}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.confirm_password ? (
                                                            <div className="error-message">{errors.confirm_password}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="col-12 mt-2">
                                                        <label className="pb-2 label">Tell us about yourself</label>
                                                        <div className="">
                                                            <textarea
                                                                className="form-control"
                                                                placeholder=""
                                                                name={'description'}
                                                                value={values.description}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                style={{ height: 100 }}></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="pt-4 pb-4 d-flex justify-content-center">
                                                        <button className="btn btn-primary w-25 p-2" type="submit">Update</button>
                                                    </div>
                                                </div>
                                            </form>
                                        )
                                    }
                                </Formik>
                            </div>
                        </div>
                    </div>

                    {/* <!-- main-panel ends --> */}
                </div>
                {/* <!-- page-body-wrapper ends --> */}
            </div>
            {/* </body> */}
            <Spinner isLoading={AdminStore.loading} />
        </React.Fragment>
    );
})

export default ClientEdit;