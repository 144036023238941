/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Header from '@components/Header';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import Select from 'react-select';
import { toJS } from 'mobx';
import Spinner from "@components/Spinner";
import secureLocalStorage from 'react-secure-storage';
import 'react-spring-bottom-sheet/dist/style.css';
import swal from '@sweetalert/with-react';
import { momentLocalizer } from 'react-big-calendar'
import UserStore from '@store/UserStore';
import HomeStore from '@store/HomeStore';
import MyCalendar from '@elements/calendar/MyCalendar';
import ToolbarDropdown from "@elements/select/ToolbarDropdown";
import CustomModal from '@components/modals/CustomModal';
import { slotStatusListClient, determineDiscipline } from '@utils/constant/constant';
import { toast } from 'react-toastify';
import { DateObject } from "react-multi-date-picker"
import { CustomEvent, ViewEvent, printCalendar, downloadCalendar, AddShift, Control, MultiValue } from "./commonFunctions"
import { dropdownCalendarStatusStyles } from '@utils/customStyle/style';

const today = moment(new Date());
const localizer = momentLocalizer(moment)
const ShiftRequestsCalendar = observer(() => {
    const [staffingRequestList, setStaffingRequestList] = useState([]);
    const [staffingRequestListCopy, setStaffingRequestListCopy] = useState([]);
    const [selectedOption, setSelectedOption] = useState();
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selectedFacility, setSelectedFacility] = useState();
    const [userData, setUserData] = useState();
    const [editSlot, setEditSlot] = useState({
        slot_date: '',
        slot_type: '',
        slot_start_time: '',
        slot_end_time: ''
    });
    const [isOpenAddShift, setIsOpenShift] = useState(false);
    const [selectedDate, setSelectedDate] = useState();
    const [degree, setDegree] = useState([]);
    const [specialties, setSpecialties] = useState([]);
    const [multi, setValues] = useState([])
    const [dates, setDates] = useState([])
    const [allDates, setAllDates] = useState([])
    const [facilityList, setFacilityList] = useState([]);

    useEffect(() => {
        UserStore.getUserProfession();
        UserStore.getStaffingRequest();
        let userData = secureLocalStorage.getItem("userData");
        setUserData(JSON.parse(userData));
        setFacilityList(JSON.parse(userData)?.facilities)
    }, []);

    useEffect(() => {
        if (toJS(UserStore?.data?.recentProfession)?.length > 0) {
            setDegree(JSON.parse(toJS(UserStore?.data?.recentProfession)))
        }
    }, [UserStore?.data?.recentProfession]);

    useEffect(() => {
        let pendingShifts = toJS(UserStore?.data?.staffingRequestList);
        let slots = pendingShifts?.flatMap(item =>
            item.assignments.map(assignment => ({ ...assignment, shift_data: item }))
        );

        // const upcoming = mergedShifts?.filter(x => x?.status === 2);
        const formattedEvents = slots?.map(event => {
            if (event?.category === 'TC') {
                let startDate = new Date(`${event.start_date} ${event.start_time}`);
                let endDate = new Date(`${event.end_date} ${event.end_time}`);
                return {
                    id: event.id,
                    data: event,
                    title: event?.user?.organization_name,
                    start: startDate,
                    end: endDate,
                    allDay: true,
                    color: event?.status === 2 ? "black" : event?.status === 5 ? 'black' : '#FFBA00',
                    // colorEvento:'transparent'
                    colorEvento: event?.status === 1 ? '#E1F2FA' : event?.status === 5 ? '#08bdff' : (event?.status === 2) ? '#55A5C3' : '#6c757d', // Adjust text color for better visibility
                    // You can include other properties as needed
                };
            } else {

                let startDate = new Date(`${event.slot_date} ${event.slot_start_time}`);
                let endDate = new Date(`${event.slot_date} ${event.slot_end_time}`);
                return {
                    id: event.id,
                    data: event,
                    title: event?.staff_id ? event?.staff?.first_name + " " + event?.staff?.last_name : determineDiscipline(event?.shift_data?.specialty_name, event?.shift_data?.profession, HomeStore?.data?.degreeList), start: startDate,
                    end: endDate,
                    allDay: false,
                    color: (event?.status === 5 || event?.status === 6 || event?.status === 3) ? "red" : (event?.status === 2) ? '#0873ff' : (event?.status === 7) ? 'black' : '#F16D14',
                    colorEvento: '#E1F2FA',

                    // colorEvento: event?.status === 1 ? 'gray' : event?.status === 2 ? '#87CEEB' : (event?.status === 3 || event?.status === 5 || event?.status === 6) ? '#dc3545' : '#6c757d', // Adjust text color for better visibility
                    // You can include other properties as needed
                };
            }
        });
        setStaffingRequestList(formattedEvents);
        setStaffingRequestListCopy(formattedEvents)
    }, [UserStore?.data?.staffingRequestList]);

    useEffect(() => {
        setSpecialties(HomeStore?.data?.dropDown)
    }, [HomeStore?.data?.dropDown]);

    const handleDateChange = (newDate) => {
        setEditSlot(prevState => ({
            ...prevState,
            slot_date: newDate.format()
        }));
    };
    const handleSlotTypeChange = (event) => {
        event?.persist();
        setEditSlot(prevState => ({
            ...prevState,
            slot_type: event?.target?.value,
            slot_start_time: global.config.shiftTimeRange?.find(x => x.label === event?.target?.value)?.value?.start,
            slot_end_time: global.config.shiftTimeRange?.find(x => x.label === event?.target?.value)?.value?.end

        }));
    };
    const handleSlotStartChange = (event) => {
        event?.persist();
        setEditSlot(prevState => ({
            ...prevState,
            slot_start_time: event?.target?.value
        }));
    };
    const handleSlotEndChange = (event) => {
        event?.persist();
        setEditSlot(prevState => ({
            ...prevState,
            slot_end_time: event?.target?.value
        }));
    };
    const handleEventClick = (event) => {
        setSelectedEvent(event);
        setEditSlot(prevTimeSlot => ({
            ...prevTimeSlot,
            slot_date: event?.data?.slot_date,
            slot_type: event?.data?.slot_type,
            slot_start_time: event?.data?.slot_start_time,
            slot_end_time: event?.data?.slot_end_time
        }));
    };
    const handleCloseModal = () => {
        setSelectedEvent(false);
    };
    const onRefresh = () => {
        UserStore.getStaffingRequest();
        setSelectedEvent(false);
        setSelectedDate()
        setIsOpenShift(false);
    }
    const onChangeSlotStatus = (slot, mode) => {
        if (mode === 'delete') {
            swal({
                title: "Are you sure to delete this slot?",
                text: "After this action, you can't roll back!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        let param = {
                            slot_id_delete: [slot?.data?.id],
                        }
                        UserStore.deleteSlot(param, onRefresh)
                    } else {
                        handleCloseModal(false);
                    }
                });
        }
        if (mode === 'approve') {
            if (selectedEvent?.data.id?.category === 'TC') {
                const param = {
                    'request_id': selectedEvent?.data.id,
                    'status_id': 5//for approval contract
                }
                UserStore.onApproveContract(param, onRefresh)
            } else {
                const param = {
                    'slots': JSON.stringify([{ "id": selectedEvent?.data.id, "code": 2 }])
                }
                UserStore.onApproveSlots(param, onRefresh)
            }
        }
        if (mode === 'reject') {
            if (selectedEvent?.data.id?.category === 'TC') {
                const param = {
                    'request_id': selectedEvent?.data.id,
                    'status_id': 3//for reject contract
                }
                UserStore.onApproveContract(param, onRefresh)
            } else {
                const param = {
                    'slots': JSON.stringify([{ "id": selectedEvent?.data.id, "code": 1 }])
                }
                UserStore.onApproveSlots(param, onRefresh)
            }
        }
        if (mode === 'cancel') {//Cancel by Facility
            swal({
                content: (
                    <div>
                        <h3>Are you sure to cancel this shift?</h3>
                        <p>After this action, you can't roll back!</p>
                        <input
                            type="text"
                            placeholder="Enter the reason for cancellation..."
                            id="cancelReason"
                            className='did-floating-dropdown'
                        />
                    </div>
                ),
                buttons: {
                    cancel: {
                        text: 'Cancel',
                        value: null,
                        visible: true,
                        className: '',
                        closeModal: true,
                    },
                    confirm: {
                        text: 'OK',
                        value: true,
                        visible: true,
                        className: '',
                        closeModal: true,
                    },
                },
                icon: 'warning', // Add alert icon
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        const reason = document.getElementById('cancelReason')?.value;
                        let param = {
                            slot_id_facility: [selectedEvent?.id],
                            slot_id_caregiver: [],
                            slot_id_delete: [],
                            reason: reason,
                        };
                        UserStore.deleteSlot(param, onRefresh);
                    }
                });
        }
    }
    //SLOT EDIT API CALL 
    const slotChanges = (slot) => {
        swal({
            title: "Are you sure to update this shift?",
            text: "After this action, you can't roll back!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    let newSlot = [{ "id": slot.data.id, "staff_id": slot?.data?.staff, "slot_status": slot?.data?.status, "date": editSlot?.slot_date, "time": [editSlot?.slot_start_time, editSlot?.slot_end_time], "slot_type": editSlot?.slot_type }];
                    let param = {
                        request_id: slot?.data?.shift_id,
                        slots: JSON.stringify(newSlot)
                    }
                    UserStore.updateSlotData(param, onRefresh)
                }
            });
    }
    const handleSelectChange = (event) => {
        setSelectedOption(event);
    }

    //ADD SHIFT
    const CustomDayWrapper = ({ children, value }) => {
        return (
            <div className='rbc-day-bg'>
                {children}
                {moment(value).isSameOrAfter(today, 'day') && <i className='position-absolute fas fa-plus-circle pointer text-success' data-toggle="tooltip" data-placement="left" title="Add Shift" style={{ zIndex: 99 }} onClick={() => handleDoubleClickEvent(value)}>
                </i>}
            </div>
        );
    };

    const handleDoubleClickEvent = async (event) => {
        setSelectedEvent(false)
        setSelectedDate(null)
        setValues(null)
        setIsOpenShift(false)
        const date = moment(event);
        const month = date.month() + 1; // Adding 1 since moment months are zero-indexed
        const day = date.format('DD');
        const year = date.format('YYYY');
        await setValues([new DateObject({ month, day, year, locale: 'en', format: 'MM/DD/YYYY' })])
        await setSelectedDate(new DateObject({ month, day, year, locale: 'en', format: 'MM/DD/YYYY' }))
        setIsOpenShift(true);
    }
    function ClearButton({ setFiled }) {
        return <div>
            <button
                className="btn btn-sm btn-primary"
                type="button"
                onClick={() => {
                    setDates([])
                    setAllDates([])
                    setFiled('tenure', [])
                }}
            >
                Clear
            </button>
        </div>;
    }

    const onStaffingRequest = (values) => {
        var check;
        var finalslot;
        if (values.category !== "TC") {
            finalslot = values?.tenure?.map((x, index) => {
                const copiedObjects = Array.from({ length: values.allqty[index] }, () => Object.assign({}, { "date": x.format(), "time": values.alltime[index] ? values.alltime[index] : values.alltime[0], "remark": values?.allcomment ? values?.allcomment[index] || '' : '', "slot_type": values?.alltype ? values?.alltype[index] || '' : '' }));
                return copiedObjects
            })
            finalslot = [].concat(...finalslot);
            check = finalslot.some((obj) => !Object.values(obj).every((val) => val !== undefined));
        } else {
            check = false
        }
        if (check) {
            toast.warn("Please select date & time!")
        } else {
            let param = {
                profession: values.profession,
                facility_id: values.facility_id,
                category: values.category,
                specialty: values.specialty,
                specialty_name: specialties.find(x => x.Id == values.specialty)?.Name,
                slots: JSON.stringify(finalslot),
                shift_type: values.shift_type,
                break_time: values.break_time,
                description: values.description,
                nop: values.nop,
                hours_per_week: values.hours_per_week,
                no_of_weeks: values.no_of_weeks,
                pay_details: values.pay_detail,
                start_date: values.start_date,
                end_date: values.end_date,
                start_time: values.start_time,
                end_time: values.end_time,
                qualifications: values.qualifications,
                rate: values.rate,
            }
            UserStore.setStaffingRequest(param, navigationCallBack, onRefresh);
        }
    }
    const navigationCallBack = () => {
        let emailList;
        let contactList;
        if (userData?.additional_mail) {
            emailList = JSON.parse(userData?.additional_mail)?.map(function (item) {
                return '<strong class="text-primary">&#128231;' + item?.email + '</strong>';
            }).join('<br>');
        }
        if (userData?.additional_contact) {
            contactList = JSON.parse(userData?.additional_contact)?.map(function (item) {
                return '<strong class="text-primary">&#128231;' + item?.country_code + " " + item?.contact + '</strong>';
            }).join('<br>');
        }
        var content = document.createElement('div');
        content.innerHTML = 'Thank you for submitting a staff request through Staffers!<br>We will notify you as soon as your request has been claimed.<br>Your specific request has been sent to all available healthcare professionals.<br> The notification will be sent to:<br><strong class="text-primary">&#128222; ' + userData?.contact + '</strong><br>' + (contactList ? contactList : "") + '<br><strong class="text-primary">&#128231;'
            + userData?.email + '</strong><br>' + (emailList ? emailList : "") + '<br>Thank you for trusting Staffers with your staffing needs!';
        swal({
            title: "Success",
            content: content,
            icon: "success"
        }).then((value) => {
            if (value) {
                onRefresh()
            }
        })
    }
    const onDismissAddShift = () => {
        setSelectedDate()
        setIsOpenShift(false);
    }
    const handleFacilityChange = (event) => {
        if (event?.length === 0 || !event) {
            setSelectedFacility(event);
            setStaffingRequestList(staffingRequestListCopy)
            return true
        }
        setSelectedFacility(event);
        let filteredShifts = staffingRequestListCopy?.filter(item => event?.some(filterItem => filterItem?.id === item?.data?.shift_data?.facility_id))
        setStaffingRequestList(filteredShifts)
    }
    return (
        <React.Fragment>
            <Header />
            <Helmet>
                <title>Staffers LLC | My Calendar</title>
            </Helmet>
            <>
                <div className="container-fluid callback-small">
                    <div className="container-fluid pt-2 pb-2">

                    </div>
                    <div className="col-lg-12 mt-5 pt-5">
                        <div className="row bg-white pb-2" style={{ paddingTop: 2 }}>
                            <div className='row mt-3 align-items-center '>
                                <div className='col-12 col-lg-2'>
                                    <h3 className="text-start">My Calendar</h3>
                                </div>
                                <div className='col-12 col-lg-7'>
                                    <div className='d-flex align-items-center mb-2'>
                                        <Select
                                            id="select_facility"
                                            placeholder={"Select Facility"}
                                            options={facilityList}
                                            onChange={handleFacilityChange}
                                            value={selectedFacility}
                                            styles={dropdownCalendarStatusStyles}
                                            isMulti={true}
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            components={{ Control, MultiValue }}
                                            getOptionLabel={(option) => option?.organization_name}
                                            getOptionValue={(option) => option?.id}
                                        />
                                        {<ToolbarDropdown labelProperty={"label"} valueProperty={"value"} isMulti={true} placeholder={"Select Status"} selectedOption={selectedOption} slotStatusList={slotStatusListClient} handleSelectChange={handleSelectChange} />}
                                    </div>
                                </div>
                                <div className='col-12 col-lg-3'>
                                    <button type="button" onClick={() => printCalendar(userData)} className="btn btn-primary mb-2 ms-3 me-3">
                                        <i className="fas fa-print"></i> Print
                                    </button>
                                    <button type="button" onClick={() => downloadCalendar(userData)} className="btn btn-primary mb-2">
                                        <i className="fas fa-download"></i> Download
                                    </button>
                                </div>
                            </div>
                            <div className="row" id="calendar-print">
                                <div className="col-sm-12 col-lg-8" >
                                    <div id="calendar-download">
                                        <MyCalendar
                                            localizer={localizer}
                                            events={selectedOption?.length > 0 ? staffingRequestList?.filter(item => selectedOption?.some(filterItem => filterItem.value === item.data.status)) : staffingRequestList}
                                            handleEventClick={handleEventClick}
                                            CustomEvent={CustomEvent}
                                            CustomDayWrapper={CustomDayWrapper}
                                        />
                                    </div>
                                </div>
                                {window.innerWidth > 800 && <div className="col-lg-4 d-sm-none d-lg-block">
                                    {
                                        selectedEvent ? <ViewEvent
                                            selectedEvent={selectedEvent}
                                            handleSlotTypeChange={handleSlotTypeChange}
                                            handleSlotStartChange={handleSlotStartChange}
                                            handleSlotEndChange={handleSlotEndChange}
                                            handleDateChange={handleDateChange}
                                            editSlot={editSlot}
                                            slotChanges={slotChanges}
                                            onChangeSlotStatus={onChangeSlotStatus}
                                            handleCloseModal={handleCloseModal}
                                            degree={HomeStore.data.degreeList}

                                        /> : isOpenAddShift ?
                                            <AddShift
                                                selectedDate={selectedDate}
                                                degree={degree}
                                                specialties={specialties}
                                                setValues={setValues}
                                                setDates={setDates}
                                                setAllDates={setAllDates}
                                                setSpecialties={setSpecialties}
                                                multi={multi}
                                                allDates={allDates}
                                                dates={dates}
                                                ClearButton={ClearButton}
                                                onDismissAddShift={onDismissAddShift}
                                                facilityList={facilityList}
                                                addShift={onStaffingRequest}
                                            /> :
                                            <MyCalendar
                                                localizer={localizer}
                                                events={selectedOption?.length > 0 ? staffingRequestList?.filter(item => selectedOption?.some(filterItem => filterItem.value === item.data.status)) : staffingRequestList}
                                                handleEventClick={handleEventClick}
                                                CustomEvent={CustomEvent}
                                                View={true}
                                            />
                                    }
                                </div>}
                            </div>
                        </div>
                    </div>

                </div>
            </>
            {window?.innerWidth <= 800 && <CustomModal
                isOpen={selectedEvent ? true : false}
                closeModal={handleCloseModal}
                title={"Shift Details"}
                content={<ViewEvent
                    selectedEvent={selectedEvent}
                    handleSlotTypeChange={handleSlotTypeChange}
                    handleSlotStartChange={handleSlotStartChange}
                    handleSlotEndChange={handleSlotEndChange}
                    handleDateChange={handleDateChange}
                    editSlot={editSlot}
                    slotChanges={slotChanges}
                    onChangeSlotStatus={onChangeSlotStatus}
                    handleCloseModal={handleCloseModal}
                />}
            />}

            {/* <FloatMenu /> */}

            <Spinner isLoading={UserStore.loading} />
        </React.Fragment >
    )
})

export default ShiftRequestsCalendar;

