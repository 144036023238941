/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import Spinner from "@components/Spinner";
import Header from "@components/Header";
import { Helmet } from "react-helmet";
import { observer } from "mobx-react"
import Footer from "@components/Footer";
import { InputField } from "@elements/index"
import { Formik, FieldArray } from "formik";
import * as Yup from 'yup';
import Select, { components } from 'react-select';
import UserStore from "@store/UserStore";
import HomeStore from "@store/HomeStore";
import StaffsStore from "@store/StaffsStore";
import SelectField from "@elements/select/SelectField";
import { BASE_API_URL_FILE } from "../../api/config"
import "react-step-progress-bar/styles.css";
import MultiStepProgressBar from "../../elements/MultiStepProgressBar/MultiStepProgressBar";
import { toast } from "react-toastify";
import secureLocalStorage from "react-secure-storage";
import { CButton, CFormCheck, CTooltip } from "@coreui/react-pro";
import MultiStepProgressBarInner from "../../elements/MultiStepProgressBarInner/MultiStepProgressBarInner";
import { useNavigate } from "react-router-dom";
var data = require('@assets/CountryCodes.json');
var cities = require('@assets/city.json');
var states = require('@assets/states.json');
const importSMS = "You are not eligible to update any information."

const colourStyles = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? '#75459B' : '#75459B',
        borderRadius: 20,
        padding: "1px 9px"
    }),
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: '#1e4c82',
            fontSize: 13,
        }
    },

    menu: base => ({
        ...base,
        fontSize: 12,
        zIndex: 200 // set the z-index to a high value
    }),
    multiValueLabel: (base) => ({
        ...base,
        backgroundColor: "#75459B",
        color: 'white',
    }),
    menuList: (base) => ({
        ...base,
        height: "200px",

        "::-webkit-scrollbar": {
            width: "9px"
        },
        "::-webkit-scrollbar-track": {
            background: "white"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#8282C8"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        }
    })
}
const colourStylesState = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? 'blue' : '#75459b',
        borderRadius: 20,
        boxShadow: 'none',
        padding: "2px 10px",
        '&:focus': {
            border: '1px solid blue !important'
        },
        '&:active': {
            border: '1px solid blue !important'
        },
        '&:hover': {
            border: '1px solid blue !important'
        }
    }),
    valueContainer: (provided) => ({
        ...provided,
        fontSize: 12,
    }),
    menu: base => ({
        ...base,
        fontSize: 12,
        zIndex: 200 // set the z-index to a high value
    }),
    menuList: (base) => ({
        ...base,
        height: "200px",
        "::-webkit-scrollbar": {
            width: "9px"
        },
        "::-webkit-scrollbar-track": {
            background: "white"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#8282C8"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        }
    })

}
const stepZeroSchema = Yup.object().shape({
    city: Yup.string().required('City required'),
    first_name: Yup.string().required('First name required').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
    last_name: Yup.string().required('Last name required').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
    middle_name: Yup.string().nullable().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
    state_id: Yup.string().required('State required'),
    zipcode: Yup.string().min(4, 'Must have atleast 4 digit').required('Zip Code required'),
    preferred_state: Yup.array().min(1, 'Must have atleast 1 state').required('Preferred state required'),
    preferred_cities: Yup.array().min(1, 'Must have atleast 1 city').required('Preferred city required').nullable(),
    address: Yup.string().required('Address required'),
});
const stepOneSchema = Yup.object().shape({
    contact_devices: Yup.array().of(
        Yup.object().shape({
            number: Yup.string().required('Number required').min(7, "Must be atleast 7 digit").max(15, "Wrong contact number")
        })
    ),
    degree_specialities: Yup.array().of(
        Yup.object().shape({
            exp: Yup.string().required('Experience required'),
            profession: Yup.string().required('Profession required').nullable()
        }),
    )
});
const stepTwoSchema = Yup.object().shape({
    interested_in: Yup.array().required('Interest required'),
    availability: Yup.array().required('Availability required'),
});
const stepThreeSchema = Yup.object().shape({
    req_doc_list: Yup.array().of(
        Yup.object().shape({
            file: Yup.string().required('Document file is required')
        })
    ),
    doc_list: Yup.array().of(
        Yup.object().shape({
            file: Yup.string().required('Document file is required'),
            doc_id: Yup.string().required('Document type is required'),
        })
    ),
});

const optionsRace = [
    { value: '0', label: 'White', info: 'A person having origins in any of the original peoples of Europe, the Middle East or North Africa.' },
    { value: '1', label: 'Black or African American', info: 'A person having origins in any of the black racial groups of Africa.' },
    { value: '2', label: 'Native Hawaiian or Other Pacific Islander', info: 'A person having origins in any of the peoples of Hawaii, Guam, Samoa or other Pacific Islands.' },
    { value: '3', label: 'Asian', info: 'A person having origins in any of the original peoples of the Far East, Southeast Asia, or the Indian subcontinent, including, for example, Cambodia, China, India, Japan, Korea, Malaysia, Pakistan, the Philippine Islands, Thailand and Vietnam.' },
    { value: '4', label: 'American Indian or Alaskan Native', info: 'A person having origins in any of the original peoples of North and South America (including Central America) and who maintains cultural identification through tribal affiliation or community recognition.' }
];
const optionsRaceInAddition = [
    { value: '-1', label: 'No', info: 'I do not identify as belonging to any other race.' },
    { value: '0', label: 'White', info: 'A person having origins in any of the original peoples of Europe, the Middle East or North Africa.' },
    { value: '1', label: 'Black or African American', info: 'A person having origins in any of the black racial groups of Africa.' },
    { value: '2', label: 'Native Hawaiian or Other Pacific Islander', info: 'A person having origins in any of the peoples of Hawaii, Guam, Samoa or other Pacific Islands.' },
    { value: '3', label: 'Asian', info: 'A person having origins in any of the original peoples of the Far East, Southeast Asia, or the Indian subcontinent, including, for example, Cambodia, China, India, Japan, Korea, Malaysia, Pakistan, the Philippine Islands, Thailand and Vietnam.' },
    { value: '4', label: 'American Indian or Alaskan Native', info: 'A person having origins in any of the original peoples of North and South America (including Central America) and who maintains cultural identification through tribal affiliation or community recognition.' }
];


const MyJobProfilePage = observer(() => {
    const inputRef = useRef(null);
    const today = new Date();
    const initDate = new Date();
    const navigate = useNavigate();
    // Then inside the component body
    today.setFullYear(today.getFullYear() - 14);
    const minDate = today.toISOString().substr(0, 10);
    const [regions, setRegions] = useState([]);
    const [userData, setUserData] = useState();
    const [infoSource, setInfoSource] = useState([]);
    const [requiredDoc, setRequiredDoc] = useState([]);
    const [devices, setDevices] = useState([]);
    const [degree, setDegree] = useState([]);
    const [specialties, setSpecialties] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [jobProfile, setJobProfile] = useState(false);
    const [jobDocuments, setJobDocuments] = useState([]);
    const [jobDocumentsDummy, setJobDocumentsDummy] = useState([]);
    const [reqJobDocuments, setReqJobDocuments] = useState([]);
    const [currentStep, setCurrentStep] = useState(0);
    const [currentStepInner, setCurrentStepInner] = useState(0);
    const [deletedDoc, setDeletedDoc] = useState([]);
    const [isDirtyOne, setIsDirtyOne] = useState(false);
    const [externalUpdate, setExternalUpdate] = useState(false);
    const [blueSkyStatus, setBlueSkyStatus] = useState();
    const imageRef = useRef();

    const handleFieldChange = () => {
            setIsDirtyOne(true);
    }

    useEffect(() => {
        StaffsStore.getRegionsList();
        HomeStore.getInformationSource();
        HomeStore.getContactDevices();
        StaffsStore.getRequiredDoc();
        StaffsStore.getJobProfile(1);
        let userData = secureLocalStorage.getItem("userData");
        setUserData(JSON.parse(userData));
    }, [])

    useEffect(() => {
        if (userData?.bluesky_staff_id) {
            HomeStore.getCaregiverDetails(userData?.bluesky_staff_id);
        }
    }, [userData])

    useEffect(() => {
        setBlueSkyStatus(HomeStore?.data?.blueSkyCaregiverDetails?.DelFl)
    }, [HomeStore?.data?.blueSkyCaregiverDetails]);

    useEffect(() => {
        setJobProfile(StaffsStore?.data?.jobProfile?.profile)
        setJobDocuments(StaffsStore?.data?.jobProfile?.documents)
        setJobDocumentsDummy(StaffsStore?.data?.jobProfile?.documents)
    }, [StaffsStore?.data?.jobProfile]);


    useEffect(() => {
        setReqJobDocuments(StaffsStore?.data?.requiredDocList.filter(item => item.Name.includes('*') && !item.Name.includes('**')));
        setRequiredDoc(StaffsStore?.data?.requiredDocList)
    }, [StaffsStore?.data?.requiredDocList]);

    useEffect(() => {
        setDegree(HomeStore.data.degreeList)
    }, [HomeStore.data.degreeList]);

    const onGetSpecialties = (e, index) => {
        setLoading(true)
        StaffsStore.getSpecialtiesList(e).then((data) => {
            let sItems = [...specialties];
            let sItem = { ...sItems[index] };
            sItem = data;
            sItems[index] = sItem;
            setSpecialties(sItems)
            setLoading(false)
        })
    }

    useEffect(() => {
        setRegions(StaffsStore?.data?.regionsList)
    }, [StaffsStore?.data?.regionsList]);
    useEffect(() => {
        setInfoSource(HomeStore?.data?.informationSource)
    }, [HomeStore?.data?.informationSource]);

    useEffect(() => {
        setDevices(HomeStore?.data?.contactDevices)
    }, [HomeStore?.data?.contactDevices]);

    const createJobProfile = (values) => {
        let param = {
            "id": values.id,
            "first_name": values.first_name,
            "last_name": values.last_name,
            "middle_name": values.middle_name,
            "gender": values.gender,
            "ssn": values.ssn,
            "birth_date": values.birth_date,
            "address": values.address,
            "state_id": values.state_id,
            "city": values.city,
            "country_id": values.country_id,
            "zipcode": values.zipcode,
            "info_sources": values.info_sources,
            "region_id": values.region_id,
            "interested_in": values.interested_in,
            "availability": values.availability,
            "available_day": values.available_day,
            "available_date": values.available_date,
            "hispanic": values.hispanic,
            "race": values.race,
            "race_in_addition": values.race_in_addition,
            "equal_opportunity": values.equal_opportunity,
            "veteran": values.veteran,
            "disability": values.disability,
            "tnc": values.tnc,
            "employment_tnc": values.employment_tnc,
            "contact_devices": !values.contact_devices.every(obj => Object.values(obj).every(val => val === "")) ? values.contact_devices : [],
            "degree_specialities": values.degree_specialities.some(obj => !obj.profession) ? [] : values.degree_specialities,
            "doc_list": !values.req_doc_list.every(obj => Object.values(obj).every(val => val === "" || val == undefined)) ? values?.req_doc_list?.map(doc => {
                return { "doc_id": doc.ID, "file": doc.file }
            }) : [],
            "preferred_state": !values.preferred_state.every(obj => Object.values(obj).every(val => val === "")) ? values.preferred_state : [],
            "preferred_cities": !values.preferred_cities.every(obj => Object.values(obj).every(val => val === "")) ? values.preferred_cities : [],
            "work_distance": values.work_distance,
            "delete_doc_id": [],
            "status": jobProfile?.status > 2 ? jobProfile?.status : !(!values?.disability || jobDocuments?.length === 0 || jobDocuments?.some(obj => obj?.document_path == null) || (values?.degree_specialities?.some(obj => obj.profession === null)) || !values.interested_in || !values?.preferred_state || !values?.tnc || !values?.ssn || !values?.birth_date) ? '0' : null
        }
        let mergedArr = [...param.doc_list, ...values?.doc_list];
        param.doc_list = mergedArr.filter(x => x.file !== undefined);
        if (param?.id) {
            param.delete_doc_id = deletedDoc;
            StaffsStore.updateJobProfile(param, navigationCallBack);
        } else {
            StaffsStore.createJobProfile(param, navigationCallBack);
        }
    }

    const navigationCallBack = (values) => {
        setIsDirtyOne(false)
        if (currentStep === 5) {
            swal("Submitted", "Thank you for applying with Staffers!  Once your profile is approved by our credentialing team, you will be able to search for local shifts, view open travel contracts, and get the flexibility, freedom, and great rates to live life to the fullest! A member of our team will be in contact with you soon!", "success");
            navigate("/", { replace: true });
        } else {
            if (externalUpdate) {
                externalDocSave(values)
            }
            swal("Updated", "Your data has been updated!", "success");
            window.scrollTo(0, 250)
        }
        StaffsStore.getJobProfile(1);
        setJobDocuments([])
        setDeletedDoc([])
        if (currentStep != 5 && !externalUpdate) {
            setCurrentStep(currentStep + 1);
        }

    }

    const updateFields = () => {
        if ((jobProfile && jobProfile?.degree_specialities) && JSON.parse(jobProfile?.degree_specialities).length > 0) {
            let degree = JSON.parse(jobProfile?.degree_specialities);
            var arr = [];
            degree?.map(async (element, index) => {
                await StaffsStore.getSpecialtiesList(element?.profession).then((data) => {
                    let sItems = [...arr];
                    let sItem = { ...sItems[index] };
                    sItem = data;
                    sItems[index] = sItem;
                    arr = sItems;
                    setSpecialties(arr)
                })
            });
        }
    }

    const openDoc = (path) => {
        // Append a cache-busting parameter to the URL
        var cacheBuster = new Date().getTime();
        window.open(BASE_API_URL_FILE + path + "?cb=" + cacheBuster, '_blank');
    }
    const deleteDoc = (id) => {
        setDeletedDoc([...deletedDoc, id]);
        let jobDoc = jobDocuments.filter(x => x.id != id)
        setJobDocuments(jobDoc)
    }
    const checkFormChanges = (tab, resetForm) => {
        if (jobProfile?.status || jobProfile?.status === null || jobProfile?.status === 0) {
            if (isDirtyOne) {
                swal({
                    title: "Are you sure?",
                    text: "Your changes will be discarded if you switch this section.",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                    buttons: {
                        anotherButton: { text: 'SAVE & CONTINUE', type: 'button' },
                        confirmChange: { text: 'DISCARD & CONTINUE', type: 'reset' },
                        cancel: 'CANCEL',
                    },
                })
                    .then((willDelete) => {
                        if (willDelete === "confirmChange") {
                            resetForm()
                            document.getElementById("create-course-form").reset();
                            setDeletedDoc([])
                            setJobDocuments(jobDocumentsDummy)
                            setIsDirtyOne(false)
                            setCurrentStep(tab);
                            return
                        }
                        if (willDelete === "anotherButton") {
                            if (inputRef.current) {
                                if (inputRef.current.errors) {
                                    toast.warn("Please Fill All Required Fields!")
                                } else {
                                    inputRef?.current?.handleSubmit()
                                }
                            }
                            return
                        }
                    });
            } else {
                if (tab === 1) {
                    updateFields()
                }
                // window.scrollTo(0, 0)
                setCurrentStep(tab);
            }
        } else {
            toast.warn("Fill out and submit your personal information first, then go to any section!")
        }

    }
    const _Skip = (tab, resetForm) => {
        if (jobProfile?.status || jobProfile?.status === null || jobProfile?.status === 0) {
            if (isDirtyOne) {
                swal({
                    title: "Are you sure?",
                    text: "Your changes will be discarded if you switch this section.",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                    buttons: {
                        anotherButton: { text: 'SAVE & CONTINUE', type: 'button' },
                        confirmChange: { text: 'DISCARD & CONTINUE', type: 'reset' },
                        cancel: 'CANCEL',
                    },
                })
                    .then((willDelete) => {
                        if (willDelete === "confirmChange") {
                            resetForm()
                            document.getElementById("create-course-form").reset();
                            setIsDirtyOne(false)
                            setCurrentStep(tab);
                            window.scrollTo(0, 250)
                            return
                        }
                        if (willDelete === "anotherButton") {
                            if (inputRef.current) {
                                var form = document.getElementById("create-course-form");
                                if (!form.checkValidity()) {
                                    toast.warn("Please Fill All Required Fields!")
                                } else {
                                    const errors = Object.keys(inputRef?.current?.errors ?? {}).length === 0;
                                    if (!errors) {
                                        toast.warn("Please Fill All Required Fields!!")
                                        return
                                    }
                                    inputRef?.current?.handleSubmit()
                                }
                            }
                            return
                        }
                    });
            } else {
                if (tab === 1) {
                    updateFields()
                }
                window.scrollTo(0, 250)
                setCurrentStep(tab);
            }
        }
        else {
            toast.warn("Fill out and submit your personal information first, then go to any section!")
        }
    }
    const setStepInner = (step) => {
        if (jobProfile?.status || jobProfile?.status === null || jobProfile?.status === 0) {
            window.scrollTo(0, 250)
            setCurrentStepInner(step);
        } else {
            toast.warn("Fill out and submit your personal information first, then go to any section!")
        }
    }

    const externalDocSave = (values) => {
        setExternalUpdate(false)
        if (values?.doc_list?.length !== 0) {
            console.log("called Blueskty update....");
            const ids = values?.doc_list.map(obj => obj.doc_id);
            let param = {
                profile_id: jobProfile.id,
                document_ids: ids
            }
            StaffsStore.uploadExtDocuments(param, navigationCallBackAddDoc)
        }

    }

    const navigationCallBackAddDoc = () => {
        console.log("successfully updated");
    }

    return (
        <React.Fragment>
            <Header />
            <Helmet>
                <title>Staffers LLC | My Job Profile</title>
            </Helmet>
            <div className="container-fluid callback mt-lg-5 py-5">
                <div className="container pt-5">
                    <h5 className="display-5 mb-4 animated slideInDown login-head">My Job Profile</h5>
                    <p className="d-flex justify-content-center text-white">Welcome to Staffers Community! We connect healthcare professionals with available shifts at top facilities!</p>
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-10">
                            <>
                                <Formik
                                    initialValues={{
                                        id: jobProfile?.id,
                                        delete_doc_id: [],
                                        first_name: jobProfile?.first_name || userData?.first_name,
                                        last_name: jobProfile?.last_name || userData?.last_name,
                                        middle_name: jobProfile?.middle_name || "",
                                        region_id: jobProfile?.region_id || "1",
                                        gender: jobProfile?.gender || "",
                                        ssn: jobProfile?.ssn || "",
                                        address: jobProfile?.address || "",
                                        city: jobProfile?.city || "",
                                        work_distance: jobProfile?.work_distance || 40,
                                        preferred_state: (jobProfile && jobProfile?.preferred_state) && JSON.parse(jobProfile?.preferred_state) || [],
                                        preferred_cities: (jobProfile && jobProfile?.preferred_cities) && JSON.parse(jobProfile?.preferred_cities) || [],
                                        degree_specialities: (jobProfile && jobProfile?.degree_specialities) && JSON.parse(jobProfile?.degree_specialities) || [{ "profession": '', "specialties": '', 'exp': '', 'working': 'No' }],
                                        state_id: jobProfile?.state_id || "",
                                        country_id: "+1",
                                        zipcode: jobProfile?.zipcode || "",
                                        info_sources: jobProfile?.info_sources,
                                        contact_devices: (jobProfile && jobProfile?.contact_devices) && JSON.parse(jobProfile?.contact_devices) || [{ "type": '', "number": '' }],
                                        doc_list: [],
                                        req_doc_list: reqJobDocuments?.filter(obj => obj.ID !== jobDocuments?.find(x => x.document_id === obj.ID)?.document_id),
                                        employment_tnc: jobProfile?.employment_tnc || 0,
                                        tnc: jobProfile?.tnc || 0,
                                        birth_date: jobProfile?.birth_date || '',
                                        available_day: (jobProfile && jobProfile?.available_day) && JSON?.parse(jobProfile?.available_day) || [],
                                        available_date: jobProfile?.available_date || '',
                                        hispanic: jobProfile?.hispanic || null,
                                        race: jobProfile?.race || '',
                                        race_in_addition: jobProfile?.race_in_addition || '',
                                        equal_opportunity: jobProfile?.equal_opportunity || 'false',
                                        veteran: jobProfile?.veteran || null,
                                        disability: jobProfile?.disability || '',
                                        interested_in: jobProfile?.interested_in || '',
                                        availability: jobProfile?.availability || ''
                                    }}
                                    onSubmit={values => createJobProfile(values)}
                                    validationSchema={currentStep === 0 ? stepZeroSchema : currentStep === 1 ? stepOneSchema : currentStep === 2 ? stepTwoSchema : currentStep === 3 ? stepThreeSchema : ""}
                                    enableReinitialize
                                    innerRef={inputRef}

                                >
                                    {({ handleChange, handleBlur, handleSubmit, setFieldValue, touched, values, errors, resetForm }) => (

                                        <form onSubmit={handleSubmit} id="create-course-form">
                                            <MultiStepProgressBar data={values} jobProfile={jobProfile} reqJobDocuments={reqJobDocuments} onTabClick={_Skip} resetForm={resetForm} exported={userData?.is_imported === 1} blueSkyStatus={blueSkyStatus} />
                                            <div className="bg-white shadow border p-4 p-sm-4 wow fadeInUp" style={{ borderRadius: 25 }} data-wow-delay="0.03s">
                                                <div className="row">
                                                    <div className="col-sm-12 col-md-3 col-lg-2 ps-0">
                                                        <ul id="progress-bar" className="d-flex flex-row flex-wrap">
                                                            <li className={currentStep === 0 ? "col-4  col-md-12 col-lg-12 tab active py-3 pointer" : "col-4  col-md-12 col-lg-12 tab py-3 pointer"} id="account" onClick={() => checkFormChanges(0, resetForm)}><i className="fas fa-lock-open tab-step-icon"></i><strong className="tab-bar-name">Personal Info</strong></li>
                                                            <li className={currentStep === 1 ? "col-4  col-md-12 col-lg-12 tab active py-3 pointer" : "col-4  col-md-12 col-lg-12 tab py-3 pointer"} id="account" onClick={() => checkFormChanges(1, resetForm)}><i className="fas fa-user-tie tab-step-icon"></i><strong className="tab-bar-name">Professional info</strong></li>
                                                            <li className={currentStep === 2 ? "col-4  col-md-12 col-lg-12 tab active py-3 pointer" : "col-4  col-md-12 col-lg-12 tab py-3 pointer"} id="account" onClick={() => checkFormChanges(2, resetForm)}><i className="fas fa-business-time tab-step-icon"></i><strong className="tab-bar-name">Availability</strong></li>
                                                            <li className={currentStep === 3 ? "col-4  col-md-12 col-lg-12 tab active py-3 pointer" : "col-4  col-md-12 col-lg-12 tab py-3 pointer"} id="account" onClick={() => checkFormChanges(3, resetForm)} ><i className="fas fa-paperclip tab-step-icon"></i><strong className="tab-bar-name">Documents</strong></li>
                                                            <li className={currentStep === 4 ? "col-4  col-md-12 col-lg-12 tab active py-3 pointer" : "col-4  col-md-12 col-lg-12 tab py-3 pointer"} id="account" onClick={() => checkFormChanges(4, resetForm)} ><i className="fas fa-handshake tab-step-icon"></i><strong className="tab-bar-name">Acknowledgments</strong></li>
                                                            <li className={currentStep === 5 ? "col-4  col-md-12 col-lg-12 tab active py-3 pointer" : "col-4  col-md-12 col-lg-12 tab py-3 pointer"} id="account" onClick={() => checkFormChanges(5, resetForm)}><i className="fas fa-tasks tab-step-icon"></i><strong className="tab-bar-name">Finish</strong></li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-sm-12 col-md-9 col-lg-10 pe-lg-5 pb-lg-3 pt-lg-3">
                                                        <div className="row h-100">
                                                            {/* Account  */}
                                                            {currentStep === 0 &&
                                                                <div className="border-check">

                                                                    <div className="row g-4 p-lg-5 p-3">
                                                                        {
                                                                            (userData?.is_imported === 1 || jobProfile?.status === 1 || jobProfile?.status >= 3) && <small className="text-danger"><i className="fas fa-exclamation-circle"></i> Only editable input fields are available for updating.</small>
                                                                        }
                                                                        <div className="d-flex justify-content-center"><span className="small">Your personal information is needed for many reasons – from facilities or recruiters contacting you for interviews to fun gifts from Staffers! We also use your location preferences and specialties to find the perfect positions and shifts for you!</span>
                                                                        </div>
                                                                        <div className="col-sm-6">
                                                                            <InputField
                                                                                className={"did-floating-input"}
                                                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                                type="text"
                                                                                name="first_name"
                                                                                label={"First Name"}
                                                                                value={values.first_name}
                                                                                onChange={(event) => { handleChange(event); handleFieldChange(); }
                                                                                }
                                                                                required={true}
                                                                                onBlur={handleBlur}
                                                                                disabled={jobProfile?.status === 1 || jobProfile?.status >= 3}
                                                                            />
                                                                            {errors.first_name ? (
                                                                                <div className="error-message">{errors.first_name}</div>
                                                                            ) : null}
                                                                        </div>
                                                                        <div className="col-sm-6">
                                                                            <InputField
                                                                                className={"did-floating-input"}
                                                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                                type="text"
                                                                                name="last_name"
                                                                                label={"Last Name"}
                                                                                value={values.last_name}
                                                                                onChange={(event) => { handleChange(event); handleFieldChange(); }
                                                                                }
                                                                                onBlur={handleBlur}
                                                                                required={true}
                                                                                disabled={jobProfile?.status === 1 || jobProfile?.status >= 3}

                                                                            />
                                                                            {errors.last_name ? (
                                                                                <div className="error-message">{errors.last_name}</div>
                                                                            ) : null}
                                                                        </div>
                                                                        <div className="col-sm-6">
                                                                            <InputField
                                                                                className={"did-floating-input"}
                                                                                type="text"
                                                                                name="middle_name"
                                                                                label={"Middle name"}
                                                                                value={values.middle_name}
                                                                                onChange={(event) => { handleChange(event); handleFieldChange(); }
                                                                                }
                                                                                onBlur={handleBlur}
                                                                                disabled={jobProfile?.status === 1 || jobProfile?.status >= 3}

                                                                            />
                                                                            {/* {errors.middle_name ? (
                                                                                <div className="error-message">{errors.middle_name}</div>
                                                                            ) : null} */}
                                                                        </div>
                                                                        <div className="col-sm-6">
                                                                            <SelectField
                                                                                name="region_id"
                                                                                label={"Add Preferred Region"}
                                                                                value={values.region_id}
                                                                                onChange={(event) => { handleChange(event); handleFieldChange(); }}
                                                                                onBlur={handleBlur}
                                                                                data={regions}
                                                                                optionLabel={"Name"}
                                                                                optionValue={"ID"}
                                                                                // required={true}
                                                                                disabled={true}

                                                                            />
                                                                            {errors.region_id ? (
                                                                                <div className="error-message">{errors.region_id}</div>
                                                                            ) : null}
                                                                        </div>
                                                                        <div className="col-sm-6">
                                                                            <SelectField
                                                                                name="info_sources"
                                                                                label={"Referral Source"}
                                                                                value={values.info_sources}
                                                                                onChange={(event) => { handleChange(event); handleFieldChange(); }
                                                                                }
                                                                                onBlur={handleBlur}
                                                                                data={infoSource || []}
                                                                                optionLabel={"SystemValue"}
                                                                                optionValue={"SystemValueID"}
                                                                                disabled={jobProfile?.status === 1 || jobProfile?.status >= 3}

                                                                            />
                                                                        </div>
                                                                        <div className="col-sm-6">
                                                                            <InputField
                                                                                className={"did-floating-input"}
                                                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                                type="text"
                                                                                name="address"
                                                                                label={"Address"}
                                                                                value={values.address}
                                                                                onChange={(event) => { handleChange(event); handleFieldChange(); }
                                                                                }
                                                                                onBlur={handleBlur}
                                                                                disabled={jobProfile?.status === 1 || jobProfile?.status >= 3}
                                                                                required={true}
                                                                            />
                                                                            {errors.address && touched.address ? (
                                                                                <div className="error-message">{errors.address}</div>
                                                                            ) : null}
                                                                        </div>
                                                                        <div className="col-sm-6">
                                                                            <Select
                                                                                onChange={(e) => { setFieldValue("state_id", e.abbreviation), setFieldValue("city", ''), handleFieldChange(); }}
                                                                                placeholder={''}
                                                                                label={'State'}
                                                                                isRequired={true}
                                                                                isDisabled={jobProfile?.status === 1 || jobProfile?.status >= 3}
                                                                                styles={colourStylesState}
                                                                                value={states.filter(({ abbreviation }) => values?.state_id?.includes(abbreviation)) || ''}
                                                                                options={states}
                                                                                getOptionLabel={(option) => option.name}
                                                                                getOptionValue={(option) => option.abbreviation}
                                                                                components={{ Control, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                                            />
                                                                            {errors.state_id && touched.state_id ? (
                                                                                <div className="error-message">{errors.state_id}</div>
                                                                            ) : null}
                                                                        </div>
                                                                        <div className="col-sm-6">
                                                                            <Select
                                                                                onChange={(e) => { setFieldValue("city", e.label), handleFieldChange(); }}
                                                                                placeholder={''}
                                                                                label={'City'}
                                                                                isDisabled={!values?.state_id || jobProfile?.status === 1 || jobProfile?.status >= 3}
                                                                                isRequired={true}
                                                                                styles={colourStylesState}
                                                                                getOptionLabel={(option) => option.label}
                                                                                getOptionValue={(option) => option.label}
                                                                                value={cities.filter(({ label }) => values?.city === label) || ''}
                                                                                options={cities?.filter(ct => ct.state === states?.find(x => x.abbreviation == values?.state_id)?.name)}
                                                                                components={{ Control, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                                            />
                                                                            {errors.city && touched.city ? (
                                                                                <div className="error-message">{errors.city}</div>
                                                                            ) : null}
                                                                        </div>
                                                                        <div className="col-sm-6">
                                                                            <InputField
                                                                                name={"zipcode"}
                                                                                className={"did-floating-input"}
                                                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                                type="number"
                                                                                label={"Zip Code"}
                                                                                value={values.zipcode}
                                                                                required={true}
                                                                                onChange={(event) => { handleChange(event); handleFieldChange(); }}
                                                                                onBlur={handleBlur}
                                                                                disabled={jobProfile?.status === 1 || jobProfile?.status >= 3}

                                                                            />
                                                                            {errors.zipcode ? (
                                                                                <div className="error-message">{errors.zipcode}</div>
                                                                            ) : null}
                                                                        </div>
                                                                        <div className="col-sm-6">
                                                                            <Select
                                                                                onChange={(event) => { setFieldValue("country_id", event.label); handleFieldChange(); }}
                                                                                placeholder=""
                                                                                label="Country"
                                                                                isRequired={true}
                                                                                value={data.filter(({ label }) => values?.country_id?.includes(label)) || ''}
                                                                                styles={colourStyles}
                                                                                isSearchable={true}
                                                                                options={data}
                                                                                components={{ Control }}
                                                                                isDisabled={jobProfile?.status === 1 || jobProfile?.status >= 3}
                                                                            />
                                                                        </div>
                                                                        <div className="col-sm-6">
                                                                            <Select
                                                                                // isDisabled={jobProfile?.status === 1 || jobProfile?.status >= 3}
                                                                                closeMenuOnSelect={false}
                                                                                getOptionLabel={(option) => option.name}
                                                                                getOptionValue={(option) => option.abbreviation}
                                                                                isMulti
                                                                                hideSelectedOptions={false}
                                                                                options={states}
                                                                                styles={colourStyles}
                                                                                placeholder=""
                                                                                isRequired={true}
                                                                                value={states.filter(({ abbreviation }) => values?.preferred_state?.includes(abbreviation)) || ''}
                                                                                label="Add Preferred States"
                                                                                onChange={(event) => {
                                                                                    setFieldValue("preferred_state", event?.map(({ abbreviation }) => abbreviation));
                                                                                    setFieldValue("preferred_cities", values?.preferred_cities?.filter(ct => event?.map(({ abbreviation }) => abbreviation)?.find(st => st === ct.state_id)))
                                                                                    handleFieldChange();
                                                                                }}
                                                                                components={{ Control, MultiValue, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                                            />
                                                                            {errors.preferred_state ? (
                                                                                <div className="error-message">{errors.preferred_state}</div>
                                                                            ) : null}
                                                                        </div>

                                                                        <div className="col-sm-6">
                                                                            <Select
                                                                                // isDisabled={jobProfile?.status === 1 || jobProfile?.status >= 3}
                                                                                closeMenuOnSelect={false}
                                                                                getOptionLabel={(option) => `${option.label}, ${option.state_id}`}
                                                                                getOptionValue={(option) => option.label}
                                                                                isMulti
                                                                                hideSelectedOptions={false}
                                                                                options={values?.preferred_state?.length > 0 ? cities.filter(city => values?.preferred_state?.find(x => x === city.state_id)) : []}
                                                                                styles={colourStyles}
                                                                                placeholder=""
                                                                                isRequired={true}
                                                                                value={values.preferred_cities}
                                                                                label="Add Preferred Cities"
                                                                                onChange={(event) => { setFieldValue("preferred_cities", event); handleFieldChange(); }} components={{ Control, MultiValue, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                                            />
                                                                            {errors.preferred_cities ? (
                                                                                <div className="error-message">{errors.preferred_cities}</div>
                                                                            ) : null}
                                                                        </div>
                                                                        <div className="col-sm-6">
                                                                            <Select
                                                                                // isDisabled={jobProfile?.status === 1 || jobProfile?.status >= 3}
                                                                                onChange={(event) => { setFieldValue("work_distance", event.value); handleFieldChange(); }}
                                                                                placeholder=""
                                                                                label="Work Area Distance"
                                                                                isRequired={true}
                                                                                value={global.config.radiusMiles?.filter(({ value }) => values?.work_distance === value) || ''}
                                                                                styles={colourStyles}
                                                                                isSearchable={true}
                                                                                options={global.config.radiusMiles}
                                                                                components={{ Control }}
                                                                            />
                                                                        </div>
                                                                        {<div className="col-sm-12 d-grid gap-2 d-md-flex justify-content-center">
                                                                            <CButton size="lg" color="primary" shape="rounded-pill" type="submit">Save & Continue</CButton>
                                                                            <CButton size="lg" color="success" shape="rounded-pill" variant="outline" onClick={() => _Skip(1, resetForm)}>Next Section</CButton>
                                                                        </div>}
                                                                        {/* {
                                                                            (jobProfile?.status === 1 || jobProfile?.status >= 3) && <div><p className="text-danger"></p></div>
                                                                        } */}
                                                                    </div>
                                                                </div>}
                                                            {/* Professional info  */}
                                                            {currentStep === 1 &&
                                                                <div className="border-check">
                                                                    <div className="row g-4 p-lg-5 p-3">
                                                                        {
                                                                            userData?.is_imported === 1 && <small className="text-danger"><i className="fas fa-exclamation-circle"></i> {importSMS}</small>
                                                                        }
                                                                        <div className="d-flex justify-content-center pb-2"><span className="small fs-6">Please provide the necessary professional information as per our requirements.</span></div>
                                                                        <div><h5 className="text-primary"><i className="fas fa-briefcase"></i> Professional Experience</h5></div>
                                                                        <FieldArray
                                                                            name="degree_specialities"
                                                                            render={({ handleRemove, push }) => (
                                                                                <>
                                                                                    {
                                                                                        values.degree_specialities && values.degree_specialities.length > 0 ?
                                                                                            values.degree_specialities.map((deg, index) => (
                                                                                                <div key={index + 'list'} className="card">
                                                                                                    <div className="row p-lg-4">
                                                                                                        {(jobProfile?.status === 0 || jobProfile?.status === 2 || jobProfile?.status === null) && <div className="col-sm-12 d-flex justify-content-end pb-0 ms-3" style={{ marginTop: -10 }}>
                                                                                                            {values?.degree_specialities?.length !== 1 && <button
                                                                                                                type="button"
                                                                                                                className="btn btn-sm text-danger pointer w-10 me-1 mt-lg-0 mt-3"
                                                                                                                onClick={() => {
                                                                                                                    values.degree_specialities.splice(index, 1)
                                                                                                                    specialties.splice(index, 1)
                                                                                                                    setFieldValue('degree_specialities', values.degree_specialities)
                                                                                                                }}
                                                                                                            >
                                                                                                                <i className="fas fa-times"></i>
                                                                                                            </button>}
                                                                                                        </div>}
                                                                                                        <div className="col-sm-6 pb-lg-0 pb-md-0 pb-3">
                                                                                                            <div className="did-floating-label-content">
                                                                                                                <select
                                                                                                                    className="did-floating-dropdown"
                                                                                                                    value={deg?.profession}
                                                                                                                    required={true}
                                                                                                                    name={`degree_specialities[${index}].profession`}
                                                                                                                    onBlur={handleBlur}
                                                                                                                    disabled={jobProfile?.status === 1 || jobProfile?.status >= 3}
                                                                                                                    onChange={(e) => {
                                                                                                                        handleChange(e);
                                                                                                                        const selectedIndex = e.target.selectedIndex;
                                                                                                                        const selectedOptionLabel = e.target.options[selectedIndex].label;
                                                                                                                        setFieldValue(`degree_specialities[${index}].profession`, e.target.value)
                                                                                                                        setFieldValue(`degree_specialities[${index}].profession_name`, selectedOptionLabel)
                                                                                                                        setFieldValue(`degree_specialities[${index}].specialties_name`, null);
                                                                                                                        setFieldValue(`degree_specialities[${index}].specialties`, null);
                                                                                                                        if (selectedIndex !== 0) {
                                                                                                                            onGetSpecialties(e.target.value, index)
                                                                                                                        } else {
                                                                                                                            specialties[index] = []
                                                                                                                        }
                                                                                                                        handleFieldChange();
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <option
                                                                                                                        value=""
                                                                                                                    >
                                                                                                                    </option>
                                                                                                                    {degree && degree?.map((option, index) => (
                                                                                                                        <option
                                                                                                                            key={index}
                                                                                                                            value={option.Id}
                                                                                                                        >
                                                                                                                            {option.Name}
                                                                                                                        </option>
                                                                                                                    ))}
                                                                                                                </select>
                                                                                                                <label className={deg?.profession == "" ? "did-floating-label did-floating-label-down did-floating-labe-astrisk" : "did-floating-label did-floating-label-top did-floating-labe-astrisk"}>Profession</label>
                                                                                                            </div>
                                                                                                            {errors?.degree_specialities && errors?.degree_specialities?.length != 0 && errors?.degree_specialities[index] ? (
                                                                                                                <div className="error-message">{errors?.degree_specialities[index]?.profession}</div>
                                                                                                            ) : null}
                                                                                                        </div>
                                                                                                        {<div className="col-sm-6 pb-lg-0 pb-md-0 pb-3">
                                                                                                            <SelectField
                                                                                                                label={isLoading ? "Loading..." : "Speciality"}
                                                                                                                disabled={!(specialties[index] && specialties[index]?.length > 0) || jobProfile?.status === 1 || jobProfile?.status >= 3}
                                                                                                                required={(specialties[index] && specialties[index]?.length > 0)}
                                                                                                                name={`degree_specialities[${index}].specialties`}
                                                                                                                value={deg.specialties}
                                                                                                                onChange={(event) => {
                                                                                                                    handleChange(event);
                                                                                                                    const selectedIndex = event.target.selectedIndex;
                                                                                                                    const selectedOptionLabel = event.target.options[selectedIndex].label;
                                                                                                                    setFieldValue(`degree_specialities[${index}].specialties_name`, selectedOptionLabel);
                                                                                                                    handleFieldChange();
                                                                                                                }}
                                                                                                                onBlur={handleBlur}
                                                                                                                data={(deg.specialties ? specialties[index]?.filter(x => x?.Id == deg?.specialties) :
                                                                                                                    specialties[index]?.filter(x => {
                                                                                                                        if (values?.degree_specialities?.find(rm => rm?.specialties == x?.Id)) {
                                                                                                                            return
                                                                                                                        } else {
                                                                                                                            return x;
                                                                                                                        }
                                                                                                                    }
                                                                                                                    )) || []}
                                                                                                                optionLabel={"Name"}
                                                                                                                optionValue={"Id"}
                                                                                                            />
                                                                                                        </div>}
                                                                                                        {<div className="col-sm-6 pb-lg-0 pb-md-0 pb-3 mt-3">
                                                                                                            <InputField
                                                                                                                className={"did-floating-input"}
                                                                                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                                                                type="number"
                                                                                                                min="0"
                                                                                                                name={`degree_specialities[${index}].exp`}
                                                                                                                label={"Years of Experience"}
                                                                                                                value={deg.exp}
                                                                                                                onChange={(event) => { handleChange(event); handleFieldChange(); }}
                                                                                                                onBlur={handleBlur}
                                                                                                                disabled={jobProfile?.status === 1 || jobProfile?.status >= 3}
                                                                                                                required={true}
                                                                                                            />
                                                                                                            {errors?.degree_specialities && errors?.degree_specialities?.length != 0 && errors?.degree_specialities[index] ? (
                                                                                                                <div className="error-message">{errors?.degree_specialities[index]?.exp}</div>
                                                                                                            ) : null}
                                                                                                        </div>}
                                                                                                        {<div className="col-sm-6 pb-lg-0 pb-md-0 pb-3 mt-3">
                                                                                                            <SelectField
                                                                                                                label={"Currently working in this role?"}
                                                                                                                name={`degree_specialities[${index}].working`}
                                                                                                                value={deg.working}
                                                                                                                onChange={(event) => {
                                                                                                                    handleChange(event);
                                                                                                                    handleFieldChange();
                                                                                                                }}
                                                                                                                onBlur={handleBlur}
                                                                                                                disabled={jobProfile?.status === 1 || jobProfile?.status >= 3}
                                                                                                                data={[{ "label": "Yes", "value": "Yes" }, { "label": "No", "value": "No" }]}
                                                                                                                optionLabel={"label"}
                                                                                                                optionValue={"value"}
                                                                                                            />
                                                                                                        </div>}
                                                                                                    </div>
                                                                                                </div>
                                                                                            )) : null
                                                                                    }
                                                                                    {(userData?.is_imported === 0 && (jobProfile?.status === 0 || jobProfile?.status === 2 || jobProfile?.status === null)) &&
                                                                                        <div className="col-sm-12 d-flex justify-content-sm-end">
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-sm btn-light w-10"
                                                                                                onClick={() => { push({ profession: '', specialties: '', exp: '', working: 'No' }); handleFieldChange(); }} // insert an empty string at a position
                                                                                            >
                                                                                                Add Profession
                                                                                            </button>
                                                                                        </div>}
                                                                                </>)}
                                                                        />
                                                                        <div><h5 className="text-primary"><i className="fas fa-address-book"></i> Contact Info</h5></div>
                                                                        <FieldArray
                                                                            name="contact_devices"
                                                                            render={({ handleRemove, push }) => (
                                                                                <>
                                                                                    {
                                                                                        values.contact_devices && values.contact_devices.length > 0 ?
                                                                                            values.contact_devices.map((dev, index) => (
                                                                                                <div key={index + 'list'} className="card">
                                                                                                    <div className="row p-lg-4">
                                                                                                        {(jobProfile?.status === 0 || jobProfile?.status === 2 || jobProfile?.status === null) && <div className="col-sm-12 d-flex justify-content-end pb-2 ms-3" style={{ marginTop: -10 }}>
                                                                                                            {values?.contact_devices?.length !== 1 && <button
                                                                                                                type="button"
                                                                                                                className="btn btn-sm w-10 me-1 mt-lg-0 mt-3 text-danger pointer"
                                                                                                                onClick={() => {
                                                                                                                    values.contact_devices.splice(index, 1)
                                                                                                                    specialties.splice(index, 1)
                                                                                                                    handleFieldChange();
                                                                                                                    setFieldValue('contact_devices', values.contact_devices)
                                                                                                                }}
                                                                                                            >
                                                                                                                <i className="fas fa-times"></i>
                                                                                                            </button>}
                                                                                                        </div>}

                                                                                                        <div className="col-sm-6 pb-lg-0 pb-md-0 pb-3">
                                                                                                            <div className="did-floating-label-content">
                                                                                                                <select className="did-floating-dropdown"
                                                                                                                    value={dev.type}
                                                                                                                    required
                                                                                                                    disabled={jobProfile?.status === 1 || jobProfile?.status >= 3}
                                                                                                                    name={`contact_devices[${index}].type`}
                                                                                                                    onChange={(event) => {
                                                                                                                        handleChange(event);
                                                                                                                        handleFieldChange();
                                                                                                                    }
                                                                                                                    }
                                                                                                                >
                                                                                                                    <option
                                                                                                                        value=""
                                                                                                                    >
                                                                                                                    </option>
                                                                                                                    {devices && devices?.map((option, index) => (
                                                                                                                        <option
                                                                                                                            key={index}
                                                                                                                            value={option.SystemValueID}
                                                                                                                        >
                                                                                                                            {option.SystemValue}
                                                                                                                        </option>)
                                                                                                                    )}
                                                                                                                </select>
                                                                                                                <label className={dev.type == "" ? "did-floating-label did-floating-label-down did-floating-labe-astrisk" : "did-floating-label did-floating-label-top did-floating-labe-astrisk"}>Contact Device Type</label>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div className="col-sm-6 pb-lg-0 pb-md-0 pb-3">
                                                                                                            <InputField
                                                                                                                name={`contact_devices[${index}].number`}
                                                                                                                className={"did-floating-input"}
                                                                                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                                                                type="number"
                                                                                                                label={"Number"}
                                                                                                                value={dev.number}
                                                                                                                required
                                                                                                                onChange={(event) => {
                                                                                                                    handleChange(event);
                                                                                                                    handleFieldChange();
                                                                                                                }}
                                                                                                                onBlur={handleBlur}
                                                                                                                disabled={jobProfile?.status === 1 || jobProfile?.status >= 3}
                                                                                                            />
                                                                                                            {errors?.contact_devices && errors?.contact_devices?.length != 0 && errors?.contact_devices[index] ? (
                                                                                                                <div className="error-message">{errors?.contact_devices[index]?.number}</div>
                                                                                                            ) : null}
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>

                                                                                            )) : null
                                                                                    }

                                                                                    {
                                                                                        (userData?.is_imported === 0 && (jobProfile?.status === 0 || jobProfile?.status === 2 || jobProfile?.status === null)) && <div className="col-sm-12 d-flex justify-content-sm-end">
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-sm btn-light w-10"
                                                                                                onClick={() => { push({ type: '', number: '' }); handleFieldChange(); }} // insert an empty string at a position
                                                                                            >
                                                                                                Add Contact Devices
                                                                                            </button>
                                                                                        </div>}
                                                                                </>)}
                                                                        />
                                                                        {(userData?.is_imported === 0 && (jobProfile?.status === 0 || jobProfile?.status === 2 || jobProfile?.status === null)) && <div className="col-sm-12 d-grid gap-2 d-md-flex justify-content-center">
                                                                            <CButton size="lg" color="primary" shape="rounded-pill" type="submit" disabled={!document.getElementById("create-course-form")?.checkValidity()}>Save & Continue</CButton>
                                                                            <CButton size="lg" color="success" shape="rounded-pill" variant="outline" onClick={() => _Skip(2, resetForm)}>  Next Section</CButton>
                                                                        </div>}
                                                                        {
                                                                            (jobProfile?.status === 1 || jobProfile?.status >= 3) && <div><p className="text-danger">This section is locked and cannot be modified. However, you can still make changes to other editable sections of your application.</p></div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }
                                                            {/* "Availability"  */}
                                                            {currentStep === 2 &&
                                                                <div className="border-check">
                                                                    <div className="row g-4 p-lg-5 p-3">
                                                                        {/* {
                                                                            userData?.is_imported === 1 && <small className="text-danger"><i className="fas fa-exclamation-circle"></i> {importSMS}</small>
                                                                        } */}
                                                                        <div className="d-flex justify-content-center pb-2"><span className="small">Specify the job type and preferred shift time that you're interested in.</span></div>
                                                                        <div className="col-sm-6">
                                                                            <Select
                                                                                // isDisabled={jobProfile?.status === 1 || jobProfile?.status >= 3}
                                                                                closeMenuOnSelect={false}
                                                                                name="interested_in"
                                                                                getOptionLabel={(option) => option.label}
                                                                                getOptionValue={(option) => option.value}
                                                                                isMulti
                                                                                hideSelectedOptions={false}
                                                                                options={global.config.interestedIn}
                                                                                styles={colourStyles}
                                                                                placeholder=""
                                                                                isRequired={true}
                                                                                label="Interested In"
                                                                                onChange={(event) => { setFieldValue("interested_in", event?.map(({ value }) => value)); handleFieldChange(); }}
                                                                                value={global.config.interestedIn.filter(({ value }) => values.interested_in?.includes(value)) || ''}
                                                                                components={{ Control, MultiValue, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                                            />
                                                                            {errors.interested_in ? (
                                                                                <div className="error-message">{errors.interested_in}</div>
                                                                            ) : null}
                                                                        </div>

                                                                        <div className="col-sm-6">
                                                                            <Select
                                                                                // isDisabled={jobProfile?.status === 1 || jobProfile?.status >= 3}
                                                                                closeMenuOnSelect={false}
                                                                                name="availability"
                                                                                getOptionLabel={(option) => option.label}
                                                                                getOptionValue={(option) => option.value}
                                                                                isMulti
                                                                                isRequired={true}
                                                                                hideSelectedOptions={false}
                                                                                options={global.config.shiftDay}
                                                                                styles={colourStyles}
                                                                                placeholder=""
                                                                                label="Your Available Shifts?"
                                                                                onChange={(event) => { setFieldValue("availability", event?.map(({ value }) => value)); handleFieldChange(); }}
                                                                                value={global.config.shiftDay.filter(({ value }) => values.availability?.includes(value)) || ''}
                                                                                components={{ Control, MultiValue, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                                            />
                                                                            {errors.availability ? (
                                                                                <div className="error-message">{errors.availability}</div>
                                                                            ) : null}
                                                                        </div>
                                                                        <div className="col-sm-6">
                                                                            <Select
                                                                                closeMenuOnSelect={false}
                                                                                getOptionLabel={(option) => option.label}
                                                                                getOptionValue={(option) => option.value}
                                                                                isMulti
                                                                                hideSelectedOptions={false}
                                                                                options={global.config.shiftWeeks}
                                                                                styles={colourStyles}
                                                                                placeholder=""
                                                                                label="Your Available Weekdays?"
                                                                                onChange={(event) => { setFieldValue("available_day", event?.map(({ value }) => value)); handleFieldChange(); }}
                                                                                value={global.config.shiftWeeks.filter(({ value }) => values?.available_day?.includes(value)) || ''}
                                                                                components={{ Control, MultiValue, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                                            />
                                                                            {errors.available_day ? (
                                                                                <div className="error-message">{errors.available_day}</div>
                                                                            ) : null}
                                                                        </div>

                                                                        <div className="col-sm-6">
                                                                            <InputField
                                                                                name={"available_date"}
                                                                                className={"did-floating-input"}
                                                                                type="date"
                                                                                label={"When You Start Working?"}
                                                                                value={values.available_date}
                                                                                min={initDate.toISOString().split('T')[0]}
                                                                                onChange={(event) => { handleChange(event); handleFieldChange(); }}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        </div>
                                                                        {(userData?.is_imported === 0 && (jobProfile?.status === 0 || jobProfile?.status === 2 || jobProfile?.status === null)) && <div className="col-sm-12 d-grid gap-2 d-md-flex justify-content-center">
                                                                            <CButton size="lg" color="primary" disabled={values?.availability?.length === 0 || values.interested_in?.length === 0} shape="rounded-pill" type="submit">Save & Continue</CButton>
                                                                            <CButton size="lg" color="success" shape="rounded-pill" variant="outline" onClick={() => _Skip(3, resetForm)}>  Next Section</CButton>
                                                                        </div>}
                                                                        {(jobProfile?.status === 1 || jobProfile?.status >= 3) && <div className="col-sm-12 d-grid gap-2 d-md-flex justify-content-center">
                                                                            <CButton size="lg" color="primary" disabled={(values?.availability?.length === 0 || values.interested_in?.length === 0) || !isDirtyOne} shape="rounded-pill" type="submit">Update</CButton>
                                                                        </div>}
                                                                    </div>
                                                                </div>
                                                            }
                                                            {/* "Documents"  */}
                                                            {currentStep === 3 &&
                                                                <div className="border-check">
                                                                    <div className="row g-4 p-lg-5 p-3">

                                                                        <>
                                                                            <>
                                                                                <div className="d-flex justify-content-center pb-2"><span className="small">Please provide the necessary professional information as per our requirements. This includes your profession,speciality and contact details.that are required for the position you are applying for.</span></div>
                                                                                <div className="">
                                                                                    <h5 className="text-primary text-sm h6"><i className="fas fa-file-upload"></i> Documents & Certificates</h5>
                                                                                </div>
                                                                                {
                                                                                    jobDocuments?.map((doc, index) => {
                                                                                        return (
                                                                                            <div className="row" key={index + 'files'}>
                                                                                                <div className="col-lg-10 col-9 pb-lg-0 pb-md-0 mb-1 mt-3">
                                                                                                    <SelectField
                                                                                                        label={"Document"}
                                                                                                        id="select-disabled"
                                                                                                        value={doc.document_id}
                                                                                                        onChange={handleChange}
                                                                                                        onBlur={handleBlur}
                                                                                                        data={requiredDoc || []}
                                                                                                        optionLabel={"Name"}
                                                                                                        optionValue={"ID"}
                                                                                                        disabled
                                                                                                    />
                                                                                                </div>
                                                                                                <div className="col-1 pb-lg-0 pb-md-0 mt-2" style={{ display: "flex", alignItems: "center" }}>
                                                                                                    <div className="btn" onClick={() => { openDoc(doc.document_path); handleFieldChange(); }}>
                                                                                                        <i className="fa fa-eye" aria-hidden="true"></i>
                                                                                                    </div>
                                                                                                </div>
                                                                                                { <div className="col-1 pb-lg-0 pb-md-0 mt-2" style={{ display: "flex", alignItems: "center" }}>
                                                                                                    <div className="btn" onClick={() => { deleteDoc(doc.id); handleFieldChange(); }}>
                                                                                                        <i className="fa fa-trash text-danger" aria-hidden="true"></i>
                                                                                                    </div>
                                                                                                </div>}
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </>
                                                                            {< FieldArray
                                                                                name="req_doc_list"
                                                                                render={() => (
                                                                                    <>
                                                                                        {values.req_doc_list && values.req_doc_list.length > 0 ?
                                                                                            values.req_doc_list.map((doc, index) => (
                                                                                                <div key={index + 'list'}>
                                                                                                    <div className="row">
                                                                                                        <div className="col-lg-6 pb-lg-0 pb-md-0 pb-3">
                                                                                                            <SelectField
                                                                                                                id="select-disabled"
                                                                                                                label={"Document Type"}
                                                                                                                name={`req_doc_list[${index}].doc_id`}
                                                                                                                value={doc.ID}
                                                                                                                onChange={(event) => { handleChange(event); handleFieldChange(); }}
                                                                                                                onBlur={handleBlur}
                                                                                                                data={requiredDoc || []}
                                                                                                                optionLabel={"Name"}
                                                                                                                optionValue={"ID"}
                                                                                                                required={true}
                                                                                                                disabled
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div className="col-sm-6 did-floating-label-content">
                                                                                                            <input
                                                                                                                id={`req_doc_list[${index}].file`}
                                                                                                                name={`req_doc_list[${index}].file`}
                                                                                                                title="click here to upload"
                                                                                                                style={{ display: "flex" }}
                                                                                                                accept="image/*,.pdf"
                                                                                                                className={doc?.file ? "did-floating-input" : "did-floating-input file-placeholder"}
                                                                                                                placeholder="click here to upload"
                                                                                                                type="file"
                                                                                                                required={true}
                                                                                                                onChange={(e) => {
                                                                                                                    if (e.currentTarget.files[0] && e.currentTarget.files[0].size >= 5 * 1024 * 1024) {
                                                                                                                        // File size is too large, display an error message
                                                                                                                        toast.warn("Your file is too large, maximum allowed size is 5MB!")
                                                                                                                    } else {
                                                                                                                        handleFieldChange();
                                                                                                                        setFieldValue(`req_doc_list[${index}].file`, e.currentTarget.files[0])
                                                                                                                    }
                                                                                                                }}
                                                                                                            />
                                                                                                            <label className="did-floating-label did-floating-label-top">Attach File*</label>
                                                                                                            {errors?.req_doc_list && errors?.req_doc_list?.length !== 0 && errors?.req_doc_list[index] ? (
                                                                                                                <div className="error-message">{errors?.req_doc_list[index]?.file}</div>
                                                                                                            ) : null}
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>

                                                                                            )) : null
                                                                                        }</>)}
                                                                            />}
                                                                            <FieldArray
                                                                                name="doc_list"
                                                                                render={({ handleRemove, push }) => (
                                                                                    <>
                                                                                        {
                                                                                            values.doc_list && values.doc_list.length > 0 ?
                                                                                                values.doc_list.map((doc, index) => (
                                                                                                    <div key={index + 'list'}>
                                                                                                        <div className="row">
                                                                                                            <div className="col-sm-6 pb-lg-0 pb-md-0 pb-3">
                                                                                                                <SelectField
                                                                                                                    label={"Document Type"}
                                                                                                                    name={`doc_list[${index}].doc_id`}
                                                                                                                    value={doc.doc_id}
                                                                                                                    onChange={(event) => {
                                                                                                                        if (!values.doc_list.find(x => x.doc_id == event.target.value)) {
                                                                                                                            handleChange(event);
                                                                                                                        } else {
                                                                                                                            toast.warn("You have Already Selected This Option")
                                                                                                                        }
                                                                                                                        handleFieldChange();
                                                                                                                    }
                                                                                                                    }
                                                                                                                    onBlur={handleBlur}
                                                                                                                    data={(requiredDoc?.filter(x => x.Name?.includes("**"))) || []}
                                                                                                                    optionLabel={"Name"}
                                                                                                                    optionValue={"ID"}
                                                                                                                    required
                                                                                                                />
                                                                                                                {errors?.doc_list && errors?.doc_list?.length !== 0 && errors?.doc_list[index] ? (
                                                                                                                    <div className="error-message">{errors?.doc_list[index]?.doc_id}</div>
                                                                                                                ) : null}
                                                                                                            </div>
                                                                                                            <div className="col-sm-5 did-floating-label-content">
                                                                                                                <input
                                                                                                                    id={`doc_list[${index}].file`}
                                                                                                                    name={`doc_list[${index}].file`}
                                                                                                                    title="File"
                                                                                                                    accept="image/*,.pdf"
                                                                                                                    className={"did-floating-input"}
                                                                                                                    type="file"
                                                                                                                    onChange={(e) => {
                                                                                                                        if (e.currentTarget.files[0] && e.currentTarget.files[0].size >= 5 * 1024 * 1024) {
                                                                                                                            // File size is too large, display an error message
                                                                                                                            toast.warn("Your file is too large, maximum allowed size is 5MB!")
                                                                                                                        } else {
                                                                                                                            handleFieldChange();
                                                                                                                            setFieldValue(`doc_list[${index}].file`, e.currentTarget.files[0])
                                                                                                                        }

                                                                                                                    }}
                                                                                                                />
                                                                                                                <label className="did-floating-label did-floating-label-top">Attach File*</label>
                                                                                                                {errors?.doc_list && errors?.doc_list?.length !== 0 && errors?.doc_list[index] ? (
                                                                                                                    <div className="error-message">{errors?.doc_list[index]?.file}</div>
                                                                                                                ) : null}
                                                                                                            </div>
                                                                                                            <div className="col-sm-1 mt-1">
                                                                                                                <button
                                                                                                                    disabled={jobDocuments?.length > 0 ? false : ""}
                                                                                                                    type="button"
                                                                                                                    className="btn btn-sm btn-light me-1"
                                                                                                                    onClick={() => {
                                                                                                                        values?.doc_list?.splice(index, 1);
                                                                                                                        setFieldValue('doc_list', values.doc_list)
                                                                                                                    }}
                                                                                                                >
                                                                                                                    x
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                )) : null
                                                                                        }
                                                                                        {
                                                                                            ( <div className="col-sm-12 d-flex justify-content-sm-end">
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn btn-sm btn-light w-10"
                                                                                                    onClick={(event) => { push({ doc_id: '', file: '' }), handleFieldChange(); }} // insert an empty string at a position
                                                                                                >
                                                                                                    Add Documents
                                                                                                </button>
                                                                                            </div>)}</>)}
                                                                            />
                                                                        </>
                                                                        {(jobProfile?.status === 0 || jobProfile?.status === 2 || jobProfile?.status === null) &&
                                                                            <div className="col-sm-12 d-grid gap-2 d-md-flex justify-content-center">
                                                                                <CButton size="lg" color="primary" shape="rounded-pill" type="submit" disabled={values.doc_list?.some(obj => obj.file === "")}>Save & Continue</CButton>
                                                                                <CButton size="lg" color="success" shape="rounded-pill" variant="outline" onClick={() => _Skip(4, resetForm)}>Next Section</CButton>
                                                                            </div>}
                                                                            {console.log(values.doc_list,isDirtyOne)}
                                                                        {((jobProfile?.status === 1 || jobProfile?.status >= 3)) && <div className="col-sm-12 d-grid gap-2 d-md-flex justify-content-center">
                                                                            <CButton size="lg" color="primary" shape="rounded-pill" type="submit" onClick={() => setExternalUpdate(true)} disabled={values.doc_list?.some(obj => obj.file === "") || !isDirtyOne}>Update</CButton>
                                                                        </div>}
                                                                    </div>
                                                                </div>}

                                                            {/* "Acknowledgments"  */}
                                                            {currentStep === 4 &&
                                                                <div className="border-check">
                                                                    <div className="row g-4 p-lg-5 p-3">
                                                                        {
                                                                            userData?.is_imported === 1 && <small className="text-danger"><i className="fas fa-exclamation-circle"></i> {importSMS}</small>
                                                                        }
                                                                        <div className="col-sm-12 mb-3">
                                                                            <label>The questions below are legally required for job applicants. Don't worry - we'll keep your information secure.</label>
                                                                        </div>
                                                                        <div className="col-12 mt-5">
                                                                            <MultiStepProgressBarInner currentStep={currentStepInner} setStep={setStepInner} />
                                                                        </div>
                                                                        {
                                                                            currentStepInner === 0 &&
                                                                            <div className="row g-4 p-2">
                                                                                <div className="col-sm-12">
                                                                                    <h5>AFFIRMATIVE ACTION</h5>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <label><b>What does affirmative action mean?</b></label>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <small>Affirmative action is best understood in terms of the goal: equal employment opportunity for everyone. Equal Employment Opportunity is the condition where all personnel decisions such as hiring, promotions, etc., are made without any consideration of race, color, national origin, religion, sex, gender preference, physical or mental disability, medical condition, ancestry, marital status, age, sexual orientation, citizenship, or status as a covered veteran. Affirmative action, therefore, is a vehicle by which we seek to reach the goal of equal employment opportunity. Affirmative Action may take the form of outreach recruitment efforts, educational opportunities for career advancement, and other training and skill enhancement programs for all employees, including women, minorities, persons with disabilities and covered veterans.</small>
                                                                                </div>

                                                                                <div className="col-sm-12">
                                                                                    <label><b>What is an affirmative action program?</b></label>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <small>An affirmative action program is a tool designed to ensure equal employment opportunity. It includes those policies, practices and procedures that Staffers LLC will implement to ensure that all qualified applicants and employees are receiving an equal opportunity for recruitment, selection, advancement, training, development and every other term, condition and privilege of employment.</small>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <label><b>Who benefits from the affirmative action policies and programs?</b></label>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <small>All employees benefit from the affirmative action policies and programs as they help to ensure a fair work environment for everyone.</small>
                                                                                </div>

                                                                                <div className="col-sm-12">
                                                                                    <label><b>Do I have to provide the information request on my race, gender, disability, and veteran status?</b></label>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <small>Yes. Staffers LLC is a federal contractor and therefore is required to report information about its workforce, including race and sex data, to the federal government. You therefore must provide this information after you are hired. This information is determined by self-identification. That is, you decide which of the five racial categories best describes you.</small>
                                                                                </div>

                                                                                <div className="col-sm-12">
                                                                                    <em>This information is used for statistical reporting to the federal government. It has no relation to your performance and/or your future career with Staffers LLC.</em>
                                                                                </div>
                                                                                <hr />
                                                                                <div className="col-sm-12">
                                                                                    <h5>FEDERAL EQUAL OPPORTUNITY QUESTIONS</h5>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <label><b>Invitation to Self-Identify</b></label>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <small>In order to help us comply with Federal Equal Opportunity record keeping and legal requirements, we encourage you to answer the questions below.</small>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <small>Please note that Staffers LLC adheres to and believes in equal employment opportunity for all applicants and employees without regard to race, color, sex, age, disability, national origin, religion or veteran status. This information is strictly confidential and kept separate from employee files.</small>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <CFormCheck
                                                                                        onChange={(event) => {
                                                                                            setFieldValue("equal_opportunity", event?.target.checked ? 'true' : 'false')
                                                                                            setFieldValue("hispanic", "")
                                                                                            setFieldValue("gender", "")
                                                                                            setFieldValue("race", "")
                                                                                            setFieldValue("race_in_addition", "")
                                                                                        }}
                                                                                        onBlur={handleBlur}
                                                                                        disabled={jobProfile?.status === 1 || jobProfile?.status >= 3}
                                                                                        checked={values.equal_opportunity == 'true'}
                                                                                        name="equal_opportunity"
                                                                                        value={values.equal_opportunity}
                                                                                        id="equalOpportunity"
                                                                                        label="I decline to answer these federal equal opportunity questions at this time." />
                                                                                </div>
                                                                                {values.equal_opportunity == "false" && <div className="col-sm-6">
                                                                                    <SelectField
                                                                                        name="gender"
                                                                                        label={"What is your gender?"}
                                                                                        value={values.gender}
                                                                                        onChange={(event) => { handleChange(event); handleFieldChange(); }}
                                                                                        required={true}
                                                                                        onBlur={handleBlur}
                                                                                        data={[{ "label": "Male", "value": "M" }, { "label": "Female", "value": "F" }]}
                                                                                        optionLabel={"label"}
                                                                                        optionValue={"value"}
                                                                                        disabled={jobProfile?.status === 1 || jobProfile?.status >= 3}
                                                                                    />
                                                                                    {errors.gender ? (
                                                                                        <div className="error-message">{errors.gender}</div>
                                                                                    ) : null}
                                                                                </div>}
                                                                                {values.equal_opportunity == "false" && <div className="col-sm-6">
                                                                                    <SelectField
                                                                                        name="hispanic"
                                                                                        label={"Are you Hispanic?"}
                                                                                        value={values.hispanic}
                                                                                        required={true}
                                                                                        onChange={(event) => { handleChange(event); handleFieldChange(); }}
                                                                                        onBlur={handleBlur}
                                                                                        data={[{ "label": "Yes", "value": "Yes" }, { "label": "No", "value": "No" }]}
                                                                                        optionLabel={"label"}
                                                                                        optionValue={"value"}
                                                                                        disabled={jobProfile?.status === 1 || jobProfile?.status >= 3}
                                                                                    />
                                                                                    {errors.hispanic ? (
                                                                                        <div className="error-message">{errors.hispanic}</div>
                                                                                    ) : null}
                                                                                </div>}
                                                                                {values.hispanic === 'No' && values.equal_opportunity == "false" && <><div className="col-sm-6">
                                                                                    <label>What is your race?</label>
                                                                                    <Select
                                                                                        label={""}
                                                                                        options={optionsRace}
                                                                                        disabled={jobProfile?.status === 1 || jobProfile?.status >= 3}
                                                                                        formatOptionLabel={formatOptionLabel}
                                                                                        styles={colourStyles}
                                                                                        value={optionsRace.filter(({ value }) => values.race?.includes(value)) || '' || ''}
                                                                                        onChange={(event) => { setFieldValue("race", event?.value); handleFieldChange(); }
                                                                                        }
                                                                                        components={{ Control }}
                                                                                    />
                                                                                </div>
                                                                                    <div className="col-sm-6">
                                                                                        <label>In addition to the race above, do you identify as belonging to any other race?</label>
                                                                                        <Select
                                                                                            label={""}
                                                                                            options={optionsRaceInAddition}
                                                                                            formatOptionLabel={formatOptionLabel}
                                                                                            styles={colourStyles}
                                                                                            disabled={jobProfile?.status === 1 || jobProfile?.status >= 3}
                                                                                            value={optionsRaceInAddition.filter(({ value }) => values.race_in_addition?.includes(value)) || '' || ''}
                                                                                            onChange={(event) => { setFieldValue("race_in_addition", event?.value); handleFieldChange(); }
                                                                                            }
                                                                                            components={{ Control }}
                                                                                        />
                                                                                    </div>
                                                                                </>}
                                                                                <div className="col-sm-12 d-grid gap-2 d-md-flex justify-content-center">
                                                                                    <CButton size="lg" color="primary" shape="rounded-pill" disabled={(values.equal_opportunity == "true" ? false : values.gender ? (values.hispanic == "No" ? (!values.race_in_addition || !values.race) : false) : true)} onClick={() => setStepInner(1)}>Continue</CButton>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            currentStepInner === 1 &&
                                                                            <div className="row g-4 p-2">
                                                                                <div className="col-sm-12">
                                                                                    <h5>PROTECTED VETERAN SELF-IDENTIFICATION</h5>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <h3 className="text-center">APPLICATION FORM</h3>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <small>This employer is a Government contractor subject to the Vietnam Era Veterans' Readjustment Assistance Act of 1974, as amended by the Jobs for Veterans Act of 2002, 38 U.S.C. 4212 (VEVRAA), which requires Government contractors to take affirmative action to employ and advance in employment: (1) disabled veterans; (2) recently separated veterans; (3) active duty wartime or campaign badge veterans; and (4) Armed Forces service medal veterans. These classifications are defined as follows:</small>
                                                                                </div>
                                                                                <div className="col-sm-12">

                                                                                    <ul className="list-group list-group-flush">
                                                                                        <li className="list-group-item"> • A “disabled veteran” is one of the following:
                                                                                            <ul className="list-unstyled ms-5">
                                                                                                <li>i.	a veteran of the U.S. military, ground, naval or air service who is entitled to compensation (or who but for the receipt of military retired pay would be entitled to compensation) under laws administered by the Secretary of Veterans Affairs; or </li>
                                                                                                <li>ii.	a person who was discharged or released from active duty because of a service-connected disability.</li>
                                                                                            </ul>
                                                                                        </li>
                                                                                        <li className="list-group-item">• A “recently separated veteran” means any veteran during the three-year period beginning on the date of such veteran's discharge or release from active duty in the U.S. military, ground, naval, or air service.</li>
                                                                                        <li className="list-group-item">• An “active duty wartime or campaign badge veteran” means a veteran who served on active duty in the U.S. military, ground, naval or air service during a war, or in a campaign or expedition for which a campaign badge has been authorized under the laws administered by the Department of Defense.</li>
                                                                                        <li className="list-group-item">• An “Armed forces service medal veteran” means a veteran who, while serving on active duty in the U.S. military, ground, naval or air service, participated in a United States military operation for which an Armed Forces service medal was awarded pursuant to Executive Order 12985.</li>
                                                                                    </ul>
                                                                                </div>

                                                                                <div className="col-sm-12">
                                                                                    <small>Protected veterans may have additional rights under USERRA—the Uniformed Services Employment and Reemployment Rights Act. In particular, if you were absent from employment in order to perform service in the uniformed service, you may be entitled to be reemployed by your employer in the position you would have obtained with reasonable certainty if not for the absence due to service. For more information, call the U.S. Department of Labor's Veterans Employment and Training Service (VETS), toll-free, at 1-866-4-USA-DOL.</small>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <label>If you believe you belong to any of the categories of protected veterans listed above, please indicate by checking the appropriate box below. As a Government contractor subject to VEVRAA, we request this information in order to measure the effectiveness of the outreach and positive recruitment efforts we undertake pursuant to VEVRAA, and if you are a current employee because we are.</label>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <CFormCheck
                                                                                        type="radio"
                                                                                        id="veteranYes"
                                                                                        name="veteran"
                                                                                        onChange={handleChange}
                                                                                        onBlur={handleBlur}
                                                                                        checked={values.veteran === 'true'}
                                                                                        disabled={jobProfile?.status === 1 || jobProfile?.status >= 3}
                                                                                        value={true}
                                                                                        label="I identify as one or more of the classifications of protected veteran listed above" />
                                                                                    <CFormCheck
                                                                                        type="radio"
                                                                                        id="veteranNo"
                                                                                        name="veteran"
                                                                                        onChange={handleChange}
                                                                                        onBlur={handleBlur}
                                                                                        disabled={jobProfile?.status === 1 || jobProfile?.status >= 3}
                                                                                        checked={values.veteran === 'false'}
                                                                                        value={false}
                                                                                        label="I am not a protected veteran" />
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <small>Submission of this information is voluntary and refusal to provide it will not subject you to any adverse treatment. The information provided will be used only in ways that are not inconsistent with the Vietnam Era Veterans' Readjustment Assistance Act of 1974, as amended.</small>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <small>The information you submit will be kept confidential, except that (i) supervisors and managers may be informed regarding restrictions on the work or duties of disabled veterans, and regarding necessary accommodations; (ii) first aid and safety personnel may be informed, when and to the extent appropriate, if you have a condition that might require emergency treatment; and (iii) Government officials engaged in enforcing laws administered by the Office of Federal Contract Compliance Programs, or enforcing the Americans with Disabilities Act, may be informed.</small>
                                                                                </div>
                                                                                <div className="col-sm-12 d-grid gap-2 d-md-flex justify-content-center">
                                                                                    <CButton size="lg" color="primary" shape="rounded-pill" onClick={() => setStepInner(0)}>Back</CButton>
                                                                                    <CButton size="lg" color="primary" shape="rounded-pill" disabled={!values.veteran} onClick={() => setStepInner(2)}>Continue</CButton>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            currentStepInner === 2 &&
                                                                            <div className="row g-4 p-2">
                                                                                <div className="col-sm-12">
                                                                                    <h5>VOLUNTARY SELF-IDENTIFICATION OF DISABILITY</h5>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <label><b>Why are you being asked to complete this form?</b></label>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <small>Because we do business with the government, we must reach out to, hire, and provide equal opportunity to qualified people with disabilities. To help us measure how well we are doing, we are asking you to tell us if you have a disability or if you ever had a disability. Completing this form is voluntary, but we hope that you will choose to fill it out. If you are applying for a job, any answer you give will be kept private and will not be used against you in any way.</small>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <small>If you already work for us, your answer will not be used against you in any way. Because a person may become disabled at any time, we are required to ask all of our employees to update their information every five years. You may voluntarily self-identify as having a disability on this form without fear of any punishment because you did not identify as having a disability earlier.</small>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <label><b>How do I know if I have a disability?</b></label>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <small>You are considered to have a disability if you have a physical or mental impairment or medical condition that substantially limits a major life activity, or if you have a history or record of such an impairment or medical condition.</small>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <small>Disabilities include, but are not limited to:</small>
                                                                                </div>
                                                                                <div className="col-sm-6">
                                                                                    <ul className="list-group list-group-flush">
                                                                                        <li className="list-group-item">• Blindness</li>
                                                                                        <li className="list-group-item">• Deafness</li>
                                                                                        <li className="list-group-item">• Cancer</li>
                                                                                        <li className="list-group-item">• Diabetes</li>
                                                                                        <li className="list-group-item">• Epilepsy</li>
                                                                                        <li className="list-group-item">• Autism</li>
                                                                                        <li className="list-group-item">• Cerebral palsy</li>
                                                                                        <li className="list-group-item">• HIV/AIDS</li>
                                                                                        <li className="list-group-item">• Schizophrenia</li>
                                                                                    </ul>
                                                                                </div>
                                                                                <div className="col-sm-6">
                                                                                    <ul className="list-group list-group-flush">
                                                                                        <li className="list-group-item">• Muscular dystrophy</li>
                                                                                        <li className="list-group-item">• Bipolar disorder</li>
                                                                                        <li className="list-group-item">• Major depression</li>
                                                                                        <li className="list-group-item">• Multiple sclerosis (MS)</li>
                                                                                        <li className="list-group-item">• Missing limbs or partially missing limbs</li>
                                                                                        <li className="list-group-item">• Post-traumatic stress disorder (PTSD)</li>
                                                                                        <li className="list-group-item">• Obsessive compulsive disorder</li>
                                                                                        <li className="list-group-item">• Impairments requiring the use of a wheelchair</li>
                                                                                        <li className="list-group-item">• Intellectual disability (previously called mental retardation)</li>
                                                                                    </ul>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <label><b>Please check one of the boxes below:</b></label>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <CFormCheck
                                                                                        type="radio"
                                                                                        name="disability"
                                                                                        id="disabilityYes"
                                                                                        onChange={handleChange}
                                                                                        onBlur={handleBlur}
                                                                                        checked={values.disability === 'Yes'}
                                                                                        disabled={jobProfile?.status === 1 || jobProfile?.status >= 3}
                                                                                        value="Yes"
                                                                                        label="Yes, I have a disability" />
                                                                                    <CFormCheck
                                                                                        type="radio"
                                                                                        name="disability"
                                                                                        value="No"
                                                                                        onChange={handleChange}
                                                                                        onBlur={handleBlur}
                                                                                        disabled={jobProfile?.status === 1 || jobProfile?.status >= 3}
                                                                                        checked={values.disability === 'No'}
                                                                                        id="disabilityNo"
                                                                                        label="No, I don't have a disability" />
                                                                                    <CFormCheck
                                                                                        type="radio"
                                                                                        name="disability"
                                                                                        value="None"
                                                                                        onChange={handleChange}
                                                                                        onBlur={handleBlur}
                                                                                        disabled={jobProfile?.status === 1 || jobProfile?.status >= 3}
                                                                                        checked={values.disability === 'None'}
                                                                                        id="disabilityNone"
                                                                                        label="I don't wish to answer" />
                                                                                </div>

                                                                                <div className="col-sm-12">
                                                                                    <label><b>Reasonable Accommodation Notice</b></label>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <small>Federal law requires employers to provide reasonable accommodation to qualified individuals with disabilities. Please tell us if you require a reasonable accommodation to apply for a job or to perform your job. Examples of reasonable accommodation include making a change to the application process or work procedures, providing documents in an alternate format, using a sign language interpreter, or using specialized equipment.</small>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <small>* Section 503 of the Rehabilitation Act of 1973, as amended. For more information about this form or the equal employment obligations of Federal contractors, visit the U.S. Department of Labor’s Office of Federal Contract Compliance Programs (OFCCP) website at www.dol.gov/ofccp.</small>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <small>PUBLIC BURDEN STATEMENT: According to the Paperwork Reduction Act of 1995 no persons are required to respond to a collection of information unless such collection displays a valid OMB control number. This survey should take about 5 minutes to complete.</small>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <small>OMB Control Number 1250-0005</small>
                                                                                </div>
                                                                                <div className="col-sm-12 d-grid gap-2 d-md-flex justify-content-center">
                                                                                    <CButton size="lg" color="primary" shape="rounded-pill" onClick={() => setStepInner(1)}>Back</CButton>
                                                                                    {(userData?.is_imported === 0 && (jobProfile?.status === 0 || jobProfile?.status === 2 || jobProfile?.status === null)) && <CButton size="lg" color="primary" disabled={!values.disability} shape="rounded-pill" type="submit">Save & Continue</CButton>}
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>}

                                                            {/* FINISH  */}
                                                            {currentStep === 5 &&
                                                                <div className="border-check">
                                                                    <div className="row g-4 p-lg-5 p-3">
                                                                        {
                                                                            userData?.is_imported === 1 && <small className="text-danger"><i className="fas fa-exclamation-circle"></i> {importSMS}</small>
                                                                        }
                                                                        <div className="col-sm-12">
                                                                            <h5>Staffers collects and uses this info consistent with its Privacy Policy
                                                                            </h5>
                                                                        </div>
                                                                        <div className="col-sm-6">
                                                                            <InputField
                                                                                className={"did-floating-input"}
                                                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                                type="date"
                                                                                name="birth_date"
                                                                                max={minDate}
                                                                                min="1900-01-01"
                                                                                label={"Date of birth"}
                                                                                value={values.birth_date}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                disabled={jobProfile?.status === 1 || jobProfile?.status >= 3}
                                                                            />
                                                                            {errors.dob ? (
                                                                                <div className="error-message">{errors.dob}</div>
                                                                            ) : null}
                                                                        </div>
                                                                        <div className="col-sm-6">
                                                                            <InputField
                                                                                className={"did-floating-input"}
                                                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                                type="text"
                                                                                name="ssn"
                                                                                label={"SSN"}
                                                                                value={values.ssn}
                                                                                onChange={(event) => {
                                                                                    const inputSsn = event.target.value;
                                                                                    const formattedSsn = inputSsn
                                                                                        .replace(/\D/g, '')
                                                                                        .replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');
                                                                                    handleChange({
                                                                                        target: {
                                                                                            name: 'ssn',
                                                                                            value: formattedSsn,
                                                                                        },
                                                                                    });
                                                                                }}
                                                                                maxLength={11}
                                                                                onBlur={handleBlur}
                                                                                disabled={jobProfile?.status === 1 || jobProfile?.status >= 3}

                                                                            />
                                                                            {
                                                                                values.ssn?.length < 11 ? (
                                                                                    <div className="error-message">Social Security Number (SSN) to have a minimum of 9 digits</div>
                                                                                ) : null
                                                                            }
                                                                        </div>

                                                                        <div className="col-sm-12">
                                                                            <label>Will you now, or in the future for continued employment with Employer, require Employer to sponsor you for U.S. employment authorization, work visa/status, employer-based immigration benefit(s), such as an H-1B, E-3, TN, PERM, I-140, I-485 Supplement J, “green card”, etc.?
                                                                                <span className="text-danger">*</span>
                                                                            </label>
                                                                            <CFormCheck type="radio"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                checked={values.employment_tnc == 0}
                                                                                name="employment_tnc"
                                                                                value={0}
                                                                                id="employmentTNCNo"
                                                                                label="No"
                                                                                disabled={jobProfile?.status === 1 || jobProfile?.status >= 3}
                                                                            />
                                                                            <CFormCheck type="radio"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                checked={values.employment_tnc == 1}
                                                                                disabled={jobProfile?.status === 1 || jobProfile?.status >= 3}
                                                                                name="employment_tnc"
                                                                                value={1}
                                                                                id="employmentTNCYes"
                                                                                label="Yes" />
                                                                        </div>
                                                                        <div><h5 className="text-primary"><i className="fas fa-user-check"></i> Application Verification</h5></div>

                                                                        <div className="col-sm-12">
                                                                            <CFormCheck
                                                                                onChange={() => {
                                                                                    swal({
                                                                                        title: "ACCEPT & SUBMIT APPLICATION",
                                                                                        text: `Thank you for applying with Staffers LLC. It is important that the information you provide is
                                                                                         accurate and complete so that we can best serve you and our clients. By clicking 
                                                                                         “Accept and Submit Application” below, you certify, acknowledge, and agree to the following:\n
                                                                                         • The information provided on this application is true and accurate to the best of your knowledge. You understand any false or misleading statements provided in connection with your application shall be grounds for disqualifying you for employment or, if you are already employed, immediate dismissal, to the fullest extent permitted by law.\n
                                                                                         • You authorize Staffers LLC and its parent and affiliated companies (collectively Staffers), to verify all statements relating to your application, including by obtaining information from relevant parties such as your past employers and academic institutions. You also authorize Staffers LLC to release your personnel, medical or compliance information to its clients to the extent such information may be relevant to your employment, in response to lawful requests from other parties, including, but not limited to state or federal licensing bodies or, with respect to personnel information only, to prospective third-party employers. You release Staffers LL from all liabilities relating to such inquiries and disclosures.\n
                                                                                         • You understand that any offer of employment will be contingent upon verification of qualifications and authorization to work under applicable law, including the Immigration Reform and Control Act, as well as passing drug and background screening.\n
                                                                                         • You understand that any prospective employment with Staffers LLC would be at-will. This means either Staffers LLC or you could terminate or modify the employment relationship or its terms with or without notice, at any time. This at-will employment status may only be changed through a writing signed by the CEO of Staffers LLC.\n
                                                                                        `,
                                                                                        className: "w-75",
                                                                                        buttons: true,
                                                                                        dangerMode: true,
                                                                                        buttons: {
                                                                                            cancel: 'DECLINE',
                                                                                            confirm: { text: 'ACCEPT & SUBMIT', className: 'btn-success' },
                                                                                        },
                                                                                    })
                                                                                        .then((willDelete) => {
                                                                                            if (willDelete) {
                                                                                                setFieldValue('tnc', 1)
                                                                                                // handleSubmit()
                                                                                            } else {
                                                                                                setFieldValue('tnc', 0)

                                                                                            }
                                                                                        });

                                                                                }
                                                                                }
                                                                                required
                                                                                onBlur={handleBlur}
                                                                                checked={values.tnc === 1}
                                                                                name="tnc"
                                                                                value={values.tnc} disabled={jobProfile?.status === 1 || jobProfile?.status >= 3}
                                                                                id="tnc"
                                                                                label="I have read and agreed to the Terms and Conditions*" />
                                                                        </div>
                                                                        {(jobDocuments?.some(obj => obj?.document_path == null) || jobDocuments?.length === 0 || (values?.degree_specialities?.some(obj => obj.profession === null)) || !values.interested_in || !values?.preferred_state || !values?.tnc || !values?.birth_date || !values?.ssn) && userData?.is_imported === 0 && <div className="alert alert-danger">
                                                                            <strong><i className="fas fa-info-circle"></i></strong> Fill out every field with a * to continue.
                                                                        </div>}

                                                                        {((jobProfile?.status === 0 || jobProfile?.status === 2 || jobProfile?.status === null) && userData.is_imported === 0) && <div className="col-sm-12 d-grid gap-2 d-md-flex justify-content-center">
                                                                            <CButton size="lg" color="primary" shape="rounded-pill" disabled={!values?.disability || jobDocuments?.length === 0 || jobDocuments?.some(obj => obj?.document_path == null) || (values?.degree_specialities?.some(obj => obj.profession === null)) || !values.interested_in || !values?.preferred_state || !values?.tnc || !values?.ssn || !values?.birth_date} type="submit">Submit Application</CButton>
                                                                        </div>}
                                                                        {
                                                                            (jobProfile?.status === 1 || jobProfile?.status >= 3) && <div><p className="text-danger">This section is locked and cannot be modified. However, you can still make changes to other editable sections of your application.</p></div>
                                                                        }
                                                                    </div>
                                                                </div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </>
                        </div>
                    </div>
                </div>
                <div>
                </div>
            </div>
            <Spinner isLoading={UserStore.loading || StaffsStore.loading} dontClose={true} />
            <Footer />
        </React.Fragment>
    )
});
const MoreSelectedBadge = ({ items }) => {
    const style = {
        marginLeft: "auto",
        background: "#d4eefa",
        borderRadius: "4px",
        fontFamily: "Open Sans",
        fontSize: "11px",
        padding: "3px",
        order: 99
    };

    const title = items.join(", ");
    const length = items.length;
    const label = `+ ${length} item${length !== 1 ? "s" : ""}`;

    return (
        <div style={style} title={title}>
            {label}
        </div>
    );
};
const MultiValue = ({ index, getValue, ...props }) => {
    const maxToShow = 3;
    const overflow = getValue()
        .slice(maxToShow)
        .map((x) => x.label);

    return index < maxToShow ? (
        <components.MultiValue {...props} />
    ) : index === maxToShow ? (
        <MoreSelectedBadge items={overflow} />
    ) : null;
};
export const Control = (props) => {
    return (
        <>
            <label className={props.hasValue || props.isFocused ? props?.selectProps?.isRequired ? "did-floating-label did-floating-label-top did-floating-labe-astrisk" : "did-floating-label did-floating-label-top" : props?.selectProps?.isRequired ? "did-floating-label did-floating-label did-floating-labe-astrisk" : "did-floating-label did-floating-label"} style={{ zIndex: 100 }}>{props?.selectProps?.label}</label>
            <components.Control {...props} />
        </>
    );
};
const formatOptionLabel = ({ label, info }) => (
    <div>
        <div>{label}</div>
        <div style={{ fontSize: '12px', color: '#999' }}>{info}</div>
    </div>
);

export default MyJobProfilePage;