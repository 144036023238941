/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import 'react-spring-bottom-sheet/dist/style.css';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { toJS } from 'mobx';
import Spinner from "@components/Spinner";
import ReactDOM from "react-dom";
import swal from '@sweetalert/with-react';
import { useMediaQuery } from 'react-responsive';
import HeaderPage from "../layout/HeaderPage";
import DrawerMenuPage from "../layout/DrawerMenuPage";
import AdminStore from "@store/AdminStore";
import HomeStore from "@store/HomeStore";
import { CButton, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CFormCheck } from '@coreui/react-pro';
import ProfileView from '@components/profile-view/ProfileView';
import ToolbarDropdown from "@elements/select/ToolbarDropdown";
import { onCopySlot, massActionListCaregiver } from '@utils/constant/constant';

const ShiftByCaregiver = observer(() => {
    const tableRef = useRef()
    const tableName = "table1"
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const [shiftList, setShiftList] = useState([]);
    const [degree, setDegree] = useState([]);
    const [paginationLength, setPaginationLength] = useState(100);
    const [caregiverData, setCaregiverData] = useState();
    const [selectedAction, setSelectedAction] = useState(null);
    const [dummySlots, setDummySlotList] = useState([]);
    const [selectedSlots, setSelectedSlots] = useState([]);
    const [selectedProfession, setSelectedProfession] = useState();
    const [massAssign, setMassAssign] = useState(false);
    useEffect(() => {
        const encodedData = new URLSearchParams(window.location.search);
        try {
            const data = JSON.parse(decodeURIComponent(encodedData.get("data")));
            setCaregiverData(data);
        } catch (error) {
            console.error('Error decoding JSON data:', error);
        }
    }, []);

    useEffect(() => {
        AdminStore.getShiftByCaregiver({ "staff_id": caregiverData?.id });
    }, [caregiverData]);

    useEffect(() => {
        setDegree(HomeStore?.data?.degreeList)
    }, [HomeStore?.data?.degreeList]);

    useEffect(() => {
        let pendingShifts = toJS(AdminStore?.data?.shiftByCaregiver);
        const mergedShifts = pendingShifts?.contracts?.concat(pendingShifts?.shifts);
        setShiftList(mergedShifts);
        setDummySlotList(mergedShifts);
    }, [AdminStore?.data?.shiftByCaregiver]);

    useEffect(() => {
        $.fn.dataTable.ext.errMode = 'none';
        const table = $(`#${tableName}`).DataTable(
            {
                data: shiftList,
                order: [], // Disable initial sorting
                pageLength: paginationLength,
                lengthMenu: [100, 500, 1000, 2000, 3000, 5000],
                dom: 'Blfrtip',
                buttons: [
                    'copy', 'csv', 'excel', 'print',
                ],
                // scrollX: true,
                responsive: isMobile ? false : true,
                columns: [
                    {
                        title: "Order Ref.", data: 'shift_id', "render": function (data, type, row, meta) {
                            return data
                        }
                    },
                    {
                        data: "id",
                        orderable: false
                    },
                    {
                        title: "Shift Date/Time", "render": function (data, type, full, meta) {
                            return full?.category === 'TC' ? '<strong><span class="link-info">' + moment(full.start_date, 'YYYY-MM-DD').format('MM/DD/YYYY') + " To " + moment(full.end_date, 'YYYY-MM-DD').format('MM/DD/YYYY') + '</span></strong>' : '<strong><span class="link-info">' + full?.slot_date + '</strong></span>';
                        }
                    },
                    {
                        title: "Time", "render": function (data, type, row, meta) {
                            return (row?.category === 'TC' ? '<strong><span class="link-info">' + moment(row?.start_time, "HH:mm").format("hh:mm A") + " To " + moment(row?.end_time, "HH:mm").format("hh:mm A") : '<strong><span class="link-info">' + moment(row?.slot_start_time, "HH:mm").format("hh:mm A") + " To " + moment(row?.slot_end_time, "HH:mm").format("hh:mm A") + '</span></strong>') || ''
                        }

                    },
                    {
                        title: "Shift Type/Category", "render": function (data, type, row, meta) {
                            return row?.category === 'TC' ? (row?.shift_type + " & " + global.config.shiftCaregory?.find(x => x.value === row?.category)?.label) : row?.slot_type + " & " + global.config.shiftCaregory?.find(x => x.value === row?.request?.category)?.label;
                        }
                    },
                    {
                        title: "Facility Name", "render": function (data, type, row, meta) {
                            return row?.category === 'TC' ? '<strong><span class="link-info pointer">' + row?.user?.organization_name + '</span></strong>' : '<strong><span class="link-info pointer">' + row?.request?.facility?.organization_name + '</span></strong>';
                        }

                    },
                    {
                        title: "Contact Person", "render": function (data, type, row, meta) {
                            return row?.category === 'TC' ? `${row?.user?.first_name} ${row?.user?.last_name}` : `${row?.request?.user?.first_name} ${row?.request?.user?.last_name}`;
                        }

                    },
                    {
                        title: "Address", "render": function (data, type, row, meta) {
                            return (row?.category === 'TC' ? row?.address : row?.request?.address) || "-"
                        }
                    },

                    {
                        title: "State", "render": function (data, type, row, meta) {
                            return (row?.category === 'TC' ? row?.state : row?.request?.state) || "-"
                        }
                    },
                    {
                        title: "City", width: 150, "render": function (data, type, row, meta) {
                            return (row?.category === 'TC' ? row?.city : row?.request?.city) || "-"
                        }
                    },
                    {
                        title: "Profession-Specialty", "render": function (data, type, row, meta) {
                            return row?.category === 'TC' ? row?.specialty_name ? row?.specialty_name : degree?.find(deg => deg.Id == row?.profession)?.Name : row?.request?.specialty_name ? row?.request?.specialty_name : degree?.find(deg => deg.Id == row?.request?.profession)?.Name
                        }
                    },
                    {
                        title: "Zip Code", "render": function (data, type, row, meta) {
                            return (row?.category === 'TC' ? row?.zipcode : row?.request?.zipcode) || "-"
                        }
                    },
                    {
                        title: "Client Comments", data: 'remark', "render": function (data, type, row, meta) {
                            const label = (row?.category === 'TC' ? `
                            <div style="text-align: left; border: 1px solid #ccc; padding: 2px; display: grid; grid-template-columns: max-content auto;">
                                <strong style="border: 1px solid #ccc; padding: 2px;">Shift Type:</strong> 
                                <span style="border: 1px solid #ccc; padding: 2px;">${row?.shift_type}</span>
                                
                                <strong style="border: 1px solid #ccc; padding: 2px;">No Of Weeks:</strong> 
                                <span style="border: 1px solid #ccc; padding: 2px;">${row?.no_of_weeks}</span>
                                
                                <strong style="border: 1px solid #ccc; padding: 2px;">Hours/Week:</strong> 
                                <span style="border: 1px solid #ccc; padding: 2px;">${row?.hours_per_week}</span>
                                
                                <strong style="border: 1px solid #ccc; padding: 2px;">Pay Details:</strong> 
                                <span style="border: 1px solid #ccc; padding: 2px;">${row?.pay_details}</span>
                                
                                <strong style="border: 1px solid #ccc; padding: 2px;">Qualification:</strong> 
                                <span style="border: 1px solid #ccc; padding: 2px;">${row?.qualifications}</span>
                            </div>
                        `: data) || '-';
                            return label;
                        }
                    },
                    {
                        title: "Status", data: 'status'
                    },

                    {
                        title: "Manage"
                    },
                    {
                        title: "Exported", data: "is_exported", "render": function (data, type, row, meta) {
                            return data === 1 ? "Yes" : "No";
                        }
                    },
                ],
                columnDefs: [
                    {
                        targets: 1,
                        responsivePriority: 1,
                        title: `
                        <label style="font-size: 0.875rem;"><b>Select All</b></label><br/>
                        <input type="checkbox"id="select-all-checkbox" class="select-all-checkbox">                     
                 
                    `,
                        render: function (data, type, row, meta) {
                            // Render a checkbox for each row
                            return `<input type="checkbox" class="row-select" data-id="${data}"/>`;
                        }
                    },
                    {
                        responsivePriority: 7,
                        targets: 3,
                    },
                    {
                        responsivePriority: 6,
                        targets: 2,
                    },
                    {
                        responsivePriority: 5,
                        targets: 1,
                    },
                    {
                        responsivePriority: 8,
                        targets: 5,
                    },

                    {
                        responsivePriority: 2,
                        targets: 13,
                        createdCell: (td, cellData, rowData, row, col) =>

                            ReactDOM.render(
                                <div>
                                    <span>
                                        {

                                            (rowData?.category == "TC" && rowData?.status === 1 && rowData?.staff_response === 0) ? <strong><span className='text-info'>Pending By Caregiver</span></strong> :
                                                (rowData?.status === 1 && rowData?.request?.staff_id) ? <strong><span className='text-info'>Pending By Caregiver</span></strong> : rowData?.status === 1 ? <span className="text-warning">Pending</span> :
                                                    rowData?.status === 2 || (rowData?.status === 5 && rowData?.category === 'TC') ? <span className='text-success'>Confirmed</span> :
                                                        rowData?.status === 3 ? <span className='text-danger'>Rejected</span> :
                                                            rowData?.status === 4 ? <span className='text-warning'><strong>Extention Pending</strong></span> :
                                                                (rowData?.status === 5 && rowData?.category !== 'TC') ? <span className='text-danger'>Cancelled By Caregiver</span> :
                                                                    rowData?.status === 6 ? <span className='text-danger'>Cancelled By Facility</span> :
                                                                        <span className='text-muted'>Closed</span>

                                        }
                                    </span>
                                </div>
                                , td)
                    },
                    {
                        responsivePriority: 3,
                        targets: 14,

                        createdCell: (td, cellData, rowData, row, col) =>
                            ReactDOM.render(
                                <div>
                                    <CDropdown variant="btn-group" direction='dropstart' alignment="end">
                                        <CDropdownToggle size="sm" color="transparent" caret={false} href="#" className="p-0"><i className="fa fa-ellipsis-v text-primary"></i></CDropdownToggle>
                                        <CDropdownMenu>
                                            {/* FOR SLOT */}
                                            {(rowData?.request?.staff_id && rowData?.status === 1) && <CDropdownItem>
                                                <CButton onClick={() => {
                                                    onChangeSlotStatus(rowData, 'approve')
                                                }} size="sm" className="w-100" variant='outline' color="success" >
                                                    Approve Shift <i className="fas fa-check"></i>
                                                </CButton>
                                            </CDropdownItem>
                                            }
                                            {(rowData?.request?.staff_id && rowData?.status === 1) && <CDropdownItem>
                                                <CButton onClick={() => {
                                                    onChangeSlotStatus(rowData, 'reject')
                                                }} size="sm" className="w-100" variant='outline' color="danger" >
                                                    Reject Shift <i className="fas fa-times"></i>
                                                </CButton>
                                            </CDropdownItem>}
                                            {(rowData?.category !== 'TC' && rowData.status === 2) && <CDropdownItem className="more-menu">
                                                <CButton color="danger" onClick={() => {
                                                    onChangeSlotStatus(rowData, 'cancel')
                                                }} size="sm" className="w-100" variant='outline'>
                                                    Cancel By Caregiver <i className="fas fa-times"></i>
                                                </CButton>
                                            </CDropdownItem>}
                                            {(rowData?.category !== 'TC' && rowData.status === 2) && <CDropdownItem className="more-menu">
                                                <CButton color="danger" onClick={() => {
                                                    onChangeSlotStatus(rowData, 'cancel-facility')
                                                }} size="sm" className="w-100" variant='outline'>
                                                    Cancel By Facility <i className="fas fa-times"></i>
                                                </CButton>
                                            </CDropdownItem>}
                                            {rowData?.status === 5 && rowData?.category && <CDropdownItem className="more-menu">
                                                <CButton color="danger" onClick={() => {
                                                    onChangeContractStatus(rowData, 'cancel')
                                                }} size="sm" className="w-100" variant='outline'>
                                                    Cancel Contract <i className="fas fa-times"></i>
                                                </CButton>
                                            </CDropdownItem>}

                                        </CDropdownMenu>
                                    </CDropdown>
                                </div>
                                , td),
                    },

                ],
                destroy: true,  // I think some clean up is happening here
                searching: true,
                "language": {
                    search: '',
                    emptyTable: "No shift found"
                },
                initComplete: function () {
                    // Apply the search
                    this.api()
                        .columns([0, 4, 5, 6, 7, 8, 9, 10, 12])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><input class="form-control " style="width:100%;margin-top:5px;background-color:white"" type="text" placeholder="Filter" />')
                                .appendTo($(column.header()))

                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $('input', this.header()).on('keyup change clear', function () {
                                if (column.search() !== this.value) {
                                    column.search(this.value).draw();
                                }
                            });
                        });

                    this.api()
                        .columns([2])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><input type="date" class="form-control" style="width:100%;margin-top:5px"s type="text" placeholder="" />')
                                .appendTo($(column.header()))
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $('input', this.header()).on('keyup change clear', function () {
                                if (!this.value) {
                                    $(`#${tableName}`).DataTable().column([2]).search('').draw();
                                }
                                if (column.search() !== this.value) {
                                    column.search(moment(this.value).format('MM/DD/YYYY')).draw();
                                }
                            });
                        });
                    this.api()
                        .columns([3])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><input type="time" class="form-control" style="width:100%;margin-top:5px"s type="text" placeholder="" />')
                                .appendTo($(column.header()))

                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $('input', this.header()).on('keyup change clear', function () {
                                if (!moment(this.value, "HH:mm").format("hh:mm A")) {
                                    $(`#${tableName}`).DataTable().column([3]).search('').draw();
                                }
                                if (column.search() !== moment(this.value, "HH:mm").format("hh:mm A")) {
                                    column.search(moment(this.value, "HH:mm").format("hh:mm A")).draw();
                                }
                            });
                        });
                    this.api()
                        .columns([13])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><select class="form-control" placeholder="search" style="width:100%;margin-top:5px;background-color:white""><option value="">All</option></select>')
                                .appendTo($(column.header()))
                                .on('change', function () {
                                    var val = $.fn.dataTable.util.escapeRegex($(this).val());
                                    column.search(val ? '^' + val + '$' : '', true, false).draw();
                                });
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            [{ "name": "Pending", "value": 1 }, { "name": "Confirmed", "value": 2 }, { "name": "Rejected", "value": 3 }, { "name": "Extention Pending", "value": 4 }, { "name": "Cancelled By You", "value": 5 }, { "name": "Cancelled By Facility", "value": 6 }, { "name": "Closed", "value": 7 }].forEach(function (option) {
                                var selected = '';
                                select.append('<option value="' + option.value + '" ' + selected + '>' + option?.name + '</option>');
                            });
                        });

                }
            }
        )

        table.on('page.dt', function () {
            // Clear all checkboxes and reset selection state
            $('.row-select').prop('checked', false);
            setSelectedSlots([]);
            $('.select-all-checkbox').prop('checked', false); // Uncheck 'Select All'
        });
        $(document).on('click', '.select-all-checkbox', function () {
            const isChecked = $(this).prop('checked');
            // Check/uncheck all checkboxes in the visible rows
            table.rows({ page: 'current' }).nodes().to$().find('.row-select').prop('checked', isChecked);
            // If "Select All" checkbox is checked, add all rows' data to the state constant
            if (isChecked) {
                setSelectedSlots(table.rows({ page: 'current' }).data().toArray());
            } else {
                setSelectedSlots([]); // Clear the state constant if "Select All" is unchecked
            }
        });

        // Handle individual row checkbox click
        $(document).on('click', '.row-select', function () {
            // If any row is unchecked, uncheck the "Select All" checkbox
            if (!$(this).prop('checked')) {
                $('.select-all-checkbox').prop('checked', false);
            }

            // Update selectedSlots based on the checked checkboxes 
            const selectedRows = [];
            table.rows({ page: 'current' }).nodes().to$().find('.row-select:checked').each(function () {
                const rowData = table.row($(this).closest('tr')).data();
                selectedRows.push(rowData);
            });
            setSelectedSlots(selectedRows);
            const allChecked = table.rows({ page: 'current' }).nodes().to$().find('.row-select:checked').length === table.rows({ page: 'current' }).count();

            // Update the "Select All" checkbox status
            $('.select-all-checkbox').prop('checked', allChecked);
        });
        // Check the action value and enable/disable checkboxes accordingly
        if (selectedAction) {
            $('.row-select').prop('disabled', false); // Enable checkboxes
            $('.select-all-checkbox').prop('disabled', false);
        } else {
            $('.row-select').prop('disabled', true); // Disable checkboxes
            $('.select-all-checkbox').prop('disabled', true); // Disable checkboxes
        }
        if (location?.state?.organization_name) {
            table.column(3).search(location?.state?.organization_name).draw();
        }

        table.on('click', 'td', function () {
            // Get the column index of the clicked cell
            const columnIndex = table.cell(this).index()?.column;
            // Check if the clicked cell belongs to the desired column
            if (columnIndex === 5) {
                // Get the data for the clicked cell
                const rowData = table.row($(this).closest('tr')).data();
                // Handle the event for the clicked cell
                if (rowData) {
                    showProfileDetails(rowData)
                }
            }
        });
        // Extra step to do extra clean-up.
        return function () {
            table.destroy()
        }
    }, [shiftList, degree])

    const refreshList = () => {
        setSelectedAction(null)
        setSelectedSlots([])
        AdminStore.getShiftByCaregiver({ "staff_id": caregiverData?.id });
    }
    const onChangeContractStatus = (contractData, mode) => {
        if (mode === 'cancel') {
            swal({
                title: "Are you sure to cancel this contract?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        let param = {
                            'request_id': contractData?.id,
                            'status_id': 2,
                        }
                        AdminStore.updateContract(param, refreshList)
                    }
                });
        }
        if (mode === 'reject') {
            swal({
                title: "Are you sure to cancel this contract?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        let param = {
                            'request_id': contractData?.id,
                            'status_id': 2,
                        }
                        AdminStore.updateContract(param, refreshList)
                    }
                });
        }
        if (mode === 'accept') {
            swal({
                title: "Are you sure to accept this contract?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        let param = {
                            'request_id': contractData?.id,
                            'status_id': 5,
                        }
                        AdminStore.updateContract(param, refreshList)
                    }
                });
        }
    }
    const onChangeSlotStatus = (slotData, mode) => {
        if (mode === 'cancel') {
            swal({
                content: (
                    <div>
                        <h3>Are you sure to cancel this shift by caregiver?</h3>
                        <p>After a cancel, you can't roll back a cancel.</p>
                        <input
                            type="text"
                            placeholder="Enter the reason for cancellation..."
                            id="cancelReason"
                            className='did-floating-dropdown'
                        />
                    </div>
                ),
                buttons: {
                    cancel: {
                        text: 'Cancel',
                        value: null,
                        visible: true,
                        className: '',
                        closeModal: true,
                    },
                    confirm: {
                        text: 'OK',
                        value: true,
                        visible: true,
                        className: '',
                        closeModal: true,
                    },
                },
                icon: 'warning', // Add alert icon
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        const reason = document.getElementById('cancelReason')?.value;

                        let param = {
                            slot_id_facility: [],
                            slot_id_caregiver: [slotData?.id],
                            slot_id_delete: [],
                            reason: reason,
                        };

                        AdminStore.cancelSlot(param, refreshList);
                    }
                });
        }
        if (mode === 'cancel-facility') {
            swal({
                content: (
                    <div>
                        <h3>Are you sure to cancel this shift by facility?</h3>
                        <p>After a cancel, you can't roll back a cancel.</p>
                        <input
                            type="text"
                            placeholder="Enter the reason for cancellation..."
                            id="cancelReason"
                            className='did-floating-dropdown'
                        />
                    </div>
                ),
                buttons: {
                    cancel: {
                        text: 'Cancel',
                        value: null,
                        visible: true,
                        className: '',
                        closeModal: true,
                    },
                    confirm: {
                        text: 'OK',
                        value: true,
                        visible: true,
                        className: '',
                        closeModal: true,
                    },
                },
                icon: 'warning', // Add alert icon
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        const reason = document.getElementById('cancelReason')?.value;
                        let param = {
                            slot_id_facility: [slotData?.id],
                            slot_id_caregiver: [],
                            slot_id_delete: [],
                            reason: reason,
                        };
                        AdminStore.cancelSlot(param, refreshList);
                    }
                });

        }
        if (mode === 'approve') {
            swal({
                title: "Are you sure to approve this shift?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        const slotDetails = [{ "id": slotData?.id, "code": 2 }]
                        let param = {
                            'slots': JSON.stringify(slotDetails)
                        }
                        AdminStore.updateSlots(param, refreshList)
                    }
                });
        }
        if (mode === 'reject') {
            swal({
                title: "Are you sure to reject & open this Shift?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        const slotDetails = [{ "id": slotData?.id, "code": 1 }]
                        let param = {
                            'slots': JSON.stringify(slotDetails)
                        }
                        AdminStore.updateSlots(param, refreshList)
                    }
                });
        }
    }
    const showProfileDetails = (user) => {
        const clientData = {
            id: user?.category === 'TC' ? user?.user?.id : user?.request?.user?.id,
            first_name: user?.category === 'TC' ? user?.user?.first_name : user?.request?.user?.first_name,
            last_name: user?.category === 'TC' ? user?.user?.last_name : user?.request?.user?.last_name,
            organization_name: user?.category === 'TC' ? user?.user?.organization_name : user?.request?.facility?.organization_name,
        }

        const url = `/admin/employer-shift?data=${encodeURIComponent(JSON.stringify(clientData))}`;
        let userData = user?.category === 'TC' ? user?.user : user?.request?.user
        return ProfileView({ userData, url,clientData});
    };
    const handleActionChange = (e) => {
        setSelectedAction(e)
        setSelectedSlots([])
        if (e?.value === 2 || e?.value === 3) {
            setShiftList(dummySlots?.filter(x => x.status === 2))
        }
        if (e?.value === 1 || e?.value === 4) {
            console.log(e.value);
            setShiftList(dummySlots?.filter(x => x.status === 1 && x?.request?.staff_id))
        }
    }
    const handleClearAction = () => {
        setSelectedAction(null)
        setSelectedSlots([])
        setShiftList(dummySlots)
    }
    const onMassAction = () => {
        switch (selectedAction?.value) {
            case 1:
                swal({
                    title: "Are you sure to approve this shift(s)?",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                    .then((willDelete) => {
                        if (willDelete) {
                            let finalSlot = selectedSlots?.map(slt => {
                                return { "id": slt?.id, "code": 2 }
                            })
                            let param = {
                                'slots': JSON.stringify(finalSlot)
                            }
                            AdminStore.updateSlots(param, refreshList)
                        }
                    });

                break;
            case 2:// 2 for CBC, 
                swal({
                    content: (
                        <div>
                            <h3>Are you sure to cancel this shift by caregiver?</h3>
                            <p>After this action, you can't roll back!</p>
                            <input
                                type="text"
                                placeholder="Enter the reason for cancellation..."
                                id="cancelReason"
                                className='did-floating-dropdown'
                            />
                        </div>
                    ),
                    buttons: {
                        cancel: {
                            text: 'Cancel',
                            value: null,
                            visible: true,
                            className: '',
                            closeModal: true,
                        },
                        confirm: {
                            text: 'OK',
                            value: true,
                            visible: true,
                            className: '',
                            closeModal: true,
                        },
                    },
                    icon: 'warning', // Add alert icon
                    dangerMode: true,
                })
                    .then((willDelete) => {
                        if (willDelete) {
                            const reason = document.getElementById('cancelReason')?.value;
                            let param = {
                                slot_id_facility: [],
                                slot_id_caregiver: selectedSlots?.map(x => x.id),
                                slot_id_delete: [],
                                reason: reason,
                            };
                            AdminStore.cancelSlot(param, refreshList);
                        }
                    });
                break;
            case 3:
                // 3 for CBF
                swal({
                    content: (
                        <div>
                            <h3>Are you sure to cancel this shift by facility?</h3>
                            <p>After this action, you can't roll back!</p>
                            <input
                                type="text"
                                placeholder="Enter the reason for cancellation..."
                                id="cancelReason"
                                className='did-floating-dropdown'
                            />
                        </div>
                    ),
                    buttons: {
                        cancel: {
                            text: 'Cancel',
                            value: null,
                            visible: true,
                            className: '',
                            closeModal: true,
                        },
                        confirm: {
                            text: 'OK',
                            value: true,
                            visible: true,
                            className: '',
                            closeModal: true,
                        },
                    },
                    icon: 'warning', // Add alert icon
                    dangerMode: true,
                })
                    .then((willDelete) => {
                        if (willDelete) {
                            const reason = document.getElementById('cancelReason')?.value;
                            let param = {
                                slot_id_facility: selectedSlots?.map(x => x.id),
                                slot_id_caregiver: [],
                                slot_id_delete: [],
                                reason: reason,
                            };
                            AdminStore.cancelSlot(param, refreshList);
                        }
                    });
                break;
            case 4:
                swal({
                    title: "Are you sure to reject this shift(s)?",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                    .then((willDelete) => {
                        if (willDelete) {
                            let finalSlot = selectedSlots?.map(slt => {
                                return { "id": slt?.id, "code": 1 }
                            })
                            let param = {
                                'slots': JSON.stringify(finalSlot)
                            }
                            AdminStore.updateSlots(param, refreshList)
                        }
                    });
                break;
            default:
                toast.warn("Please select any action!")
        }
    }


    return (
        <React.Fragment>
            <Helmet>
                <title>Staffers LLC | Shift List</title>
            </Helmet>
            {/* <body> */}
            <div className="container-scroller">
                <HeaderPage />
                <div className="container-fluid page-body-wrapper">
                    <DrawerMenuPage />
                    <div className="main-panel" style={{ right: 0, position: "absolute" }}>
                        <div className="row content-wrapper p-3 bg-white">
                            <h2 className="col-11 mb-2 mb-lg-0 page-title">
                                <h3><i className="far fa-id-card"></i> Caregiver Information</h3>
                                <div className="card-title row">
                                    <div className="col-12 col-sm-auto mb-2">
                                        <span>Bluesky Id: {caregiverData?.bluesky_staff_id},</span>
                                    </div>
                                    <div className="col-12 col-sm-auto mb-2">
                                        <span>Caregiver Id: {caregiverData?.id},</span>
                                    </div>
                                    <div className="col-12 col-sm-auto mb-2">
                                        <span>Caregiver Name: {caregiverData?.first_name} {caregiverData?.last_name}</span>
                                    </div>
                                    {caregiverData?.profession && <div className="col-12 col-sm-auto mb-2">
                                        <span>Caregiver Profession: {determineDiscipline(null, caregiverData?.profession, degree)}</span>
                                    </div>}
                                </div>
                            </h2>
                            <h2 className="col-1 mb-2 mb-lg-0 page-title">
                                <div className='d-flex'>
                                    <i data-toggle="tooltip" data-placement="right" title="Refresh" className="fas fa-redo fs-1 text-primary pointer" onClick={refreshList}></i>
                                </div> </h2>
                        </div>

                        <ToolbarDropdown labelProperty={"label"} valueProperty={"value"} isMulti={false} placeholder={"Select Action"} selectedOption={selectedAction} slotStatusList={massActionListCaregiver} handleSelectChange={handleActionChange} handleClearAction={handleClearAction} />
                        <div className="table-responsive">
                            <table className="table dt-responsive table-hover nowrap" style={{ width: "100%" }} id={tableName} ref={tableRef}></table>
                        </div>
                    </div>
                    {selectedSlots?.length !== 0 && <div className="float-btn">
                        <button type="submit" onClick={() => onMassAction()} className="btn btn-success w-100 py-3">
                            Selected Shift's({selectedSlots?.length}) Submit</button>
                    </div>}
                    {/* <!-- main-panel ends --> */}
                </div>
                {/* <!-- page-body-wrapper ends --> */}
            </div>

            <Spinner isLoading={AdminStore.loading} />
        </React.Fragment>
    );

})


export default ShiftByCaregiver;