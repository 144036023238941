/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import 'react-spring-bottom-sheet/dist/style.css';
import { observer } from "mobx-react-lite";
import { toJS } from 'mobx';
import Spinner from "@components/Spinner";
import moment from 'moment';
import swal from '@sweetalert/with-react';
import AdminStore from "@store/AdminStore";
import HeaderPage from "../layout/HeaderPage";
import DrawerMenuPage from "../layout/DrawerMenuPage";
import CustomModal from '@components/modals/CustomModal';
import ProfileView from '../../../components/profile-view/ProfileView';
import MyCalendar from '@elements/calendar/MyCalendar';
import { momentLocalizer } from 'react-big-calendar';
import { toast } from 'react-toastify';
import { onCopySlot, slotStatusList, determineDiscipline } from '@utils/constant/constant';
import HomeStore from "@store/HomeStore";
import ToolbarDropdown from "@elements/select/ToolbarDropdown";
import DatePicker, { DateObject } from "react-multi-date-picker"
import { dropdownCalendarStatusStyles } from '@utils/customStyle/style';
import Select from 'react-select';
import { printCalendar, downloadCalendar, onChangeSlotStatusCalendar, CustomEventClient, ViewEventClient, AddShiftClient, Control, MultiValue } from "../commonFunctions"
const today = moment(new Date());

const localizer = momentLocalizer(moment)
const ShiftCalendarByEmployer = observer(() => {
    const [staffingRequestList, setStaffingRequestList] = useState([]);
    const [staffingRequestListCopy, setStaffingRequestListCopy] = useState([]);
    const [facilityList, setFacilityList] = useState([]);
    const [clientData, setClientData] = useState();
    const [staffByProfession, setStaffByProfession] = useState([]);
    const [assignToStaff, setAssignToStaff] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [degree, setDegree] = useState([]);
    const [selectedOption, setSelectedOption] = useState();
    const [selectedFacility, setSelectedFacility] = useState();
    const [isOpenAddShift, setIsOpenShift] = useState(false);
    const [selectedDate, setSelectedDate] = useState();
    const [editSlot, setEditSlot] = useState({
        slot_date: '',
        slot_type: '',
        slot_start_time: '',
        slot_end_time: ''
    });
    const [specialties, setSpecialties] = useState([]);
    const [multi, setValues] = useState([])
    const [dates, setDates] = useState([])
    const [allDates, setAllDates] = useState([])

    useEffect(() => {
        const encodedData = new URLSearchParams(window.location.search);
        try {
            const data = JSON.parse(decodeURIComponent(encodedData.get("data")));
            AdminStore.getShiftByEmployer({ "user_id": data?.id });
            setClientData(data);
        } catch (error) {
            console.error('Error decoding JSON data:', error);
        }

    }, []);

    useEffect(() => {
        setDegree(HomeStore.data.degreeList)
    }, [HomeStore.data.degreeList]);

    useEffect(() => {
        setSpecialties(HomeStore?.data?.dropDown)
    }, [HomeStore?.data?.dropDown]);

    useEffect(() => {
        setFacilityList(toJS(AdminStore?.data?.facilityByEmployer))
        let pendingShifts = toJS(AdminStore?.data?.shiftByEmployer);
        let slots = pendingShifts?.flatMap(item =>
            item.assignments.map(assignment => ({ ...assignment, shift_data: item }))
        );
        // const upcoming = mergedShifts?.filter(x => x?.status === 2);
        const formattedEvents = slots?.map(event => {
            if (event?.category === 'TC') {
                let startDate = new Date(`${event.start_date} ${event.start_time}`);
                let endDate = new Date(`${event.end_date} ${event.end_time}`);
                return {
                    id: event.id,
                    data: event,
                    title: event?.user?.organization_name,
                    start: startDate,
                    end: endDate,
                    allDay: true,
                    color: event?.status === 2 ? "black" : event?.status === 5 ? 'black' : '#FFBA00',
                    // colorEvento:'transparent'
                    colorEvento: event?.status === 1 ? '#1F2FA' : event?.status === 5 ? '#08bdff' : (event?.status === 2) ? '#55A5C3' : '#6c757d', // Adjust text color for better visibility
                    // You can include other properties as needed
                };
            } else {
                let startDate = new Date(`${event.slot_date} ${event.slot_start_time}`);
                let endDate = new Date(`${event.slot_date} ${event.slot_end_time}`);
                return {
                    id: event.id,
                    data: event,
                    title: determineDiscipline(event?.shift_data?.specialty_name, event?.shift_data?.profession, degree),
                    caregiver: event?.staff?.first_name ? event?.staff?.first_name + " " + event?.staff?.last_name : "",
                    start: startDate,
                    end: endDate,
                    allDay: false,
                    color: (event?.status === 5 || event?.status === 6 || event?.status === 3) ? "red" : (event?.status === 2) ? '#0873ff' : (event?.status === 7) ? 'black' : '#F16D14',
                    colorEvento: '#E1F2FA',

                    // colorEvento: event?.status === 1 ? 'gray' : event?.status === 2 ? '#87CEEB' : (event?.status === 3 || event?.status === 5 || event?.status === 6) ? '#dc3545' : '#6c757d', // Adjust text color for better visibility
                    // You can include other properties as needed
                };
            }
        });
        setStaffingRequestList(formattedEvents);
        setStaffingRequestListCopy(formattedEvents);
    }, [AdminStore?.data?.shiftByEmployer]);

    useEffect(() => {
        setStaffByProfession(toJS(AdminStore?.data?.staffListByProfession)?.filter(x => x.staffer_id && x.external_id && x.status === 3))
    }, [AdminStore?.data?.staffListByProfession]);

    const refreshList = () => {
        setAssignToStaff()
        setSelectedEvent(null)
        AdminStore.getShiftByEmployer({ "user_id": clientData?.id });
        setSelectedDate();
        setIsOpenShift(false);
        setEditSlot()
    }

    const handleEventClick = (event) => {
        setAssignToStaff()
        setSelectedEvent(event);
        setEditSlot(prevTimeSlot => ({
            ...prevTimeSlot,
            slot_date: event?.data?.slot_date,
            slot_type: event?.data?.slot_type,
            slot_start_time: event?.data?.slot_start_time,
            slot_end_time: event?.data?.slot_end_time
        }));
        AdminStore.getStaffByProfession({ "profession_ids": [event?.data?.shift_data?.profession] })

    };

    //SLOT EDIT API CALL 
    const slotChanges = (slot) => {
        swal({
            title: "Are you sure to update this slot?",
            text: "After this action, you can't roll back!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    let newSlot = [{ "id": slot.data.id, "staff_id": slot?.data?.staff_id, "slot_status": slot?.data?.status, "date": editSlot?.slot_date, "time": [editSlot?.slot_start_time, editSlot?.slot_end_time], "slot_type": editSlot?.slot_type }];
                    let param = {
                        request_id: slot?.data?.shift_id,
                        slots: JSON.stringify(newSlot)
                    }
                    AdminStore.updateSlotData(param, navigationCallBack)
                }
            });
    }
    const navigationCallBack = () => {
        toast.success("Successfully updated!");
        refreshList()
    }
    const handleCloseModal = () => {
        setSelectedEvent(false);
    };
    //Assign new caregiver API call
    const assignToOtherStaff = (slot) => {
        if (assignToStaff) {
            swal({
                title: "Do you really want to assign this caregiver?",
                text: "After this action, you can't roll back!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        let newSlot = [{ "id": slot?.data?.id, "slot_type": slot?.data?.slot_type, "staff_id": assignToStaff, "slot_status": 2, "date": moment(slot?.data?.slot_date, "MM-DD-YYYY").format("MM/DD/YYYY"), "time": [slot?.data?.slot_start_time, slot?.data?.slot_end_time] }];
                        let param = {
                            request_id: slot?.data?.shift_id,
                            slots: JSON.stringify(newSlot)
                        }
                        AdminStore.updateSlotData(param, refreshList)
                    } else {
                    }
                });
        } else {
            toast.warn("Please select any other caregiver!")
        }
    }
    // Handle the change in the MultiDatePicker
    const handleDateChange = (newDate) => {
        setEditSlot(prevState => ({
            ...prevState,
            slot_date: newDate?.format("MM/DD/YYYY")
        }));
    };
    const handleSlotTypeChange = (newDate) => {
        newDate?.persist();
        setEditSlot(prevState => ({
            ...prevState,
            slot_type: newDate?.target.value,
            slot_start_time: global.config.shiftTimeRange?.find(x => x.label === newDate.target.value)?.value?.start,
            slot_end_time: global.config.shiftTimeRange?.find(x => x.label === newDate.target.value)?.value?.end

        }));
    };

    const handleSlotStartChange = (newDate) => {
        newDate?.persist();
        setEditSlot(prevState => ({
            ...prevState,
            slot_start_time: newDate?.target.value
        }));
    };
    const handleSlotEndChange = (newDate) => {
        newDate?.persist();
        setEditSlot(prevState => ({
            ...prevState,
            slot_end_time: newDate?.target.value
        }));
    };
    const showProfileDetails = (user) => {
        if (!user) {
            return
        }
        const caregiver = {
            id: user?.staff?.id,
            first_name: user?.staff?.first_name,
            last_name: user?.staff?.last_name,
            bluesky_staff_id: user?.staff?.bluesky_staff_id,
        }
        const url = `/admin/caregiver-shift?data=${encodeURIComponent(JSON.stringify(caregiver))}`;
        let userData = user?.staff;
        return ProfileView({ userData, url });
    };
    const handleSelectChange = (event) => {
        setSelectedOption(event);
    }
    const handleDoubleClickEvent = async (event) => {
        setSelectedEvent(false)
        setSelectedDate(null)
        setValues(null)
        setIsOpenShift(false)
        const date = moment(event);
        const month = date.month() + 1; // Adding 1 since moment months are zero-indexed
        const day = date.format('DD');
        const year = date.format('YYYY');
        await setValues([new DateObject({ month, day, year, locale: 'en', format: 'MM/DD/YYYY' })])
        await setSelectedDate(new DateObject({ month, day, year, locale: 'en', format: 'MM/DD/YYYY' }))
        setIsOpenShift(true);
    }

    const CustomDayWrapper = ({ children, value }) => {
        return (
            <div className='rbc-day-bg'>
                {children}
                {moment(value).isSameOrAfter(today, 'day') && <i className='position-absolute fas fa-plus-circle pointer text-success' data-toggle="tooltip" data-placement="left" title="Add Shift" style={{ zIndex: 99 }} onClick={() => handleDoubleClickEvent(value)}>
                </i>}
            </div>
        );
    };
    const onDismissAddShift = () => {
        setSelectedDate()
        setIsOpenShift(false);
    }

    const assignShift = (values) => {
        var check;
        var finalslot;
        if (values.category !== "TC") {
            finalslot = values?.tenure?.map((x, index) => {
                const copiedObjects = Array.from({ length: values.allqty[index] }, () => Object.assign({}, { "date": x.format(), "time": values.alltime[index] ? values.alltime[index] : values.alltime[0], "remark": values?.allcomment ? values?.allcomment[index] || '' : '', "slot_type": values?.alltype ? values?.alltype[index] || '' : '' }));
                return copiedObjects
            })
            finalslot = [].concat(...finalslot);
            check = finalslot.some((obj) => !Object.values(obj).every((val) => val !== undefined));
        } else {
            check = false
        }
        if (check) {
            toast.warn("Please select date & time!")
        } else {
            let param = {
                profession: values.profession,
                specialty: values.specialty,
                facility_id: values.facility_id,
                facility_code: values.facility_code,
                unit_code: values.unit_code,
                client_id: values.client_id,
                category: values.category,
                specialty_name: specialties?.find(x => x.Id == values.specialty)?.Name,
                city: values.city,
                state: values.state,
                address: values.address,
                zipcode: values.zipcode,
                slots: JSON.stringify(finalslot),
                shift_type: values.shift_type,
                description: values.description,
                nop: values.nop,
                hours_per_week: values.hours_per_week,
                no_of_weeks: values.no_of_weeks,
                pay_details: values.pay_detail,
                start_date: values.start_date,
                end_date: values.end_date,
                start_time: values.start_time,
                end_time: values.end_time,
                qualifications: values.qualifications
            }
            AdminStore.setStaffingRequest(param, refreshList);
        }
    }
    function ClearButton({ setFiled }) {
        return <div>
            <button
                className="btn btn-sm btn-primary"
                type="button"
                onClick={() => {
                    setDates([])
                    setAllDates([])
                    setFiled('tenure', [])
                }}
            >
                Clear
            </button>
        </div>;
    }
    const handleFacilityChange = (event) => {
        if (event?.length === 0 || !event) {
            setSelectedFacility(event);
            setStaffingRequestList(staffingRequestListCopy)
            return true
        }
        setSelectedFacility(event);
        let filteredShifts = staffingRequestListCopy?.filter(item => event?.some(filterItem => filterItem?.facility_id === item?.data?.shift_data?.facility_id))
        setStaffingRequestList(filteredShifts)
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>Staffers LLC | Facility User Calendar</title>
            </Helmet>
            <div className="container-scroller">
                <HeaderPage />
                <div className="container-fluid page-body-wrapper">
                    <DrawerMenuPage />
                    <div className="main-panel" style={{ right: 0, position: "absolute" }}>
                        <div className="row content-wrapper p-3 bg-white pb-0" id="header">
                            <div className="col-11 mb-2 mb-lg-0 page-title">
                                <b><i className="far fa-id-card"></i> Facility User Information</b>
                                <div className="card-title row">
                                    <div className="col-12 col-sm-auto mb-2">
                                        <span>Facility User Id: {clientData?.id},</span>
                                    </div>
                                    <div className="col-12 col-sm-auto mb-2">
                                        <span>Facility User Name: {clientData?.first_name} {clientData?.last_name}</span>
                                    </div>
                                    {/* <div className="col-12 col-sm-auto mb-2">

                                        <div className="nav-item dropdown">
                                            <a href="#" className="nav-link dropdown-toggle  pt-0 mt-0" data-bs-toggle="dropdown">
                                                <i className="far fa-building"></i>&nbsp;
                                                {facilityList?.length > 0 && facilityList[0]?.facilities?.organization_name}
                                                <sup>+{facilityList ? facilityList?.length - 1 : 0}</sup>
                                                <i className="fas fa-angle-down ps-2"></i></a>
                                            <div className="dropdown-menu border-light m-0">
                                                <ul className="list-unstyled">
                                                    {facilityList && facilityList?.map((item, index) => (
                                                        <li key={index} className="dropdown-item">{item?.facilities?.organization_name}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <h2 className="col-1 mb-2 mb-lg-0 page-title">
                                <div className='d-flex'>
                                    {/* <i data-toggle="tooltip" data-placement="right" title="Back" className="fas fa-arrow-alt-circle-left fs-1 text-primary pointer" onClick={goBack}></i> */}
                                    <i data-toggle="tooltip" data-placement="right" title="Refresh" className="fas fa-redo fs-1 text-primary pointer" onClick={refreshList}></i>
                                </div>
                            </h2>
                        </div>
                        <div className="col-lg-12 mb-3">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className='d-flex align-items-center mb-2'>
                                    <Select
                                        id="select_facility"
                                        placeholder={"Select Facility"}
                                        options={facilityList}
                                        onChange={handleFacilityChange}
                                        value={selectedFacility}
                                        styles={dropdownCalendarStatusStyles}
                                        isMulti={true}
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        components={{ Control, MultiValue }}
                                        getOptionLabel={(option) => option?.facilities?.organization_name}
                                        getOptionValue={(option) => option?.facilities?.id}
                                    />
                                    {<ToolbarDropdown labelProperty={"label"} valueProperty={"value"} isMulti={true} placeholder={"Select Status"} selectedOption={selectedOption} slotStatusList={slotStatusList} handleSelectChange={handleSelectChange} />}
                                </div>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <button type="button" onClick={() => printCalendar(clientData)} className="btn btn-primary me-3">
                                        <i className="fas fa-print"></i> Print
                                    </button>
                                    <button type="button" onClick={() => downloadCalendar(clientData)} className="btn btn-primary me-3">
                                        <i className="fas fa-download"></i> Download
                                    </button>
                                </div>
                            </div>
                            <div >
                                <div className="row" id="calendar-print">
                                    <div className="col-sm-12 col-lg-8" >
                                        <div id="calendar-download">
                                            <MyCalendar
                                                localizer={localizer}
                                                events={selectedOption?.length > 0 ? staffingRequestList?.filter(item => selectedOption?.some(filterItem => filterItem.value === item.data.status)) : staffingRequestList}
                                                handleEventClick={handleEventClick}
                                                CustomEvent={CustomEventClient}
                                                CustomDayWrapper={CustomDayWrapper}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 d-sm-none d-lg-block">
                                        {
                                            selectedEvent ? <div className="wow fadeIn " data-wow-delay="0.1s">
                                                <ViewEventClient
                                                    selectedEvent={selectedEvent}
                                                    degree={degree}
                                                    slotChanges={slotChanges}
                                                    assignToOtherStaff={assignToOtherStaff}
                                                    onChangeSlotStatus={onChangeSlotStatusCalendar}
                                                    handleDateChange={handleDateChange}
                                                    handleSlotStartChange={handleSlotStartChange}
                                                    handleSlotEndChange={handleSlotEndChange}
                                                    editSlot={editSlot}
                                                    staffByProfession={staffByProfession}
                                                    setAssignToStaff={setAssignToStaff}
                                                    assignToStaff={assignToStaff}
                                                    handleSlotTypeChange={handleSlotTypeChange}
                                                    onCopySlot={onCopySlot}
                                                    handleCloseModal={handleCloseModal}

                                                /> </div> : isOpenAddShift ?
                                                <div className="wow fadeIn" data-wow-delay="0.1s">
                                                    <AddShiftClient
                                                        facilityList={facilityList}
                                                        clientData={clientData}
                                                        selectedDate={selectedDate}
                                                        degree={degree}
                                                        specialties={specialties}
                                                        setValues={setValues}
                                                        setDates={setDates}
                                                        setAllDates={setAllDates}
                                                        assignShift={assignShift}
                                                        setSpecialties={setSpecialties}
                                                        multi={multi}
                                                        allDates={allDates}
                                                        dates={dates}
                                                        ClearButton={ClearButton}
                                                        onDismissAddShift={onDismissAddShift}

                                                    /></div> :
                                                <MyCalendar
                                                    localizer={localizer}
                                                    events={selectedOption?.length > 0 ? staffingRequestList?.filter(item => selectedOption?.some(filterItem => filterItem.value === item.data.status)) : staffingRequestList}
                                                    handleEventClick={handleEventClick}
                                                    CustomEvent={CustomEventClient}
                                                    View={true}
                                                />
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {/* <!-- page-body-wrapper ends --> */}
            </div>
            {window?.innerWidth <= 800 && <CustomModal
                isOpen={selectedEvent ? true : false}
                closeModal={handleCloseModal}
                title={"Shift Details"}
                content={<ViewEventClient />}
            />}

            <Spinner isLoading={AdminStore.loading} />
        </React.Fragment >
    )
})
export default ShiftCalendarByEmployer;
