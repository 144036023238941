/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
const DrawerMenuPage = () => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState();
    useEffect(() => {
        let data = secureLocalStorage.getItem("adminData");
        setUserData(JSON.parse(data));
    }, []);

    const goto = (path) => {
        navigate(path);
    }

    return (
        <nav className="sidebar sidebar-offcanvas position-fixed" id="sidebar">
            <ul className="nav" style={{ overflowY: 'auto' }}>
                <li className="nav-item nav-profile pointer">
                    <a href="/admin/profile" className="nav-link ">
                        <div className="nav-profile-image" >
                            <i className="fas fa-user-shield text-primary" style={{ fontSize: 38 }} aria-hidden="true"></i>
                            {/* <span className="login-status online"></span> */}
                        </div>
                        <div className="nav-profile-text d-flex flex-column">
                            <span className="font-weight-bold mb-2 text-capitalize">{userData?.first_name}</span>
                            <span className="text-secondary text-small">{userData?.is_super == 0 ? 'Admin' : userData?.is_super == 1 ? 'Super Admin' : "Loading..."}</span>
                        </div>
                    </a>
                </li>
                <li className="nav-item pointer" style={window.location.pathname === '/admin/dashboard' ? { backgroundColor: '#75459B' } : {}}>
                    <a className="nav-link" onClick={() => goto("/admin/dashboard")}>
                        <span style={window.location.pathname === '/admin/dashboard' ? { color: '#fff' } : {}} className="menu-title">Dashboard</span>
                        <i className="fas fa-tachometer-alt menu-icon" style={window.location.pathname === '/admin/dashboard' ? { color: '#fff' } : {}} ></i>
                    </a>
                </li>
                {userData?.is_super === 1 && <li className="nav-item pointer">
                    <a className="nav-link" data-bs-toggle="collapse" href="#ui-basic" aria-expanded="false" aria-controls="ui-basic">
                        <span className="menu-title">Admins</span>
                        <i className="fas fa-chevron-down ms-2"></i>
                        <i className="fas fa-user-shield menu-icon drawer-icon" aria-hidden="true"></i>

                    </a>
                    <div className={"collapse"} id="ui-basic">
                        <ul className="nav flex-column sub-menu">
                            <li className="nav-item pointer" > <a className="nav-link" style={window.location.pathname === '/admin/admin-add' ? { color: '#75459B' } : {}} onClick={() => goto("/admin/admin-add")} >Add Admin</a></li>
                            <li className="nav-item pointer"> <a className="nav-link" style={window.location.pathname === '/admin/admin-list' ? { color: '#75459B' } : {}} onClick={() => goto("/admin/admin-list")}>Manage Admin</a></li>
                        </ul>
                    </div>
                </li>}
                <li className="nav-item pointer">
                    <a className="nav-link" data-bs-toggle="collapse" href="#pending-shift" aria-expanded="false" aria-controls="pending-shift">
                        <span className="menu-title">Orders/Shift</span>
                        <i className="fas fa-chevron-down ms-2 ms-2"></i>
                        <i className="fas fa-clock menu-icon drawer-icon" aria-hidden="true"></i>
                    </a>
                    <div className={"collapse"} id="pending-shift">
                        <ul className="nav flex-column sub-menu">
                            <li className="nav-item pointer" style={window.location.pathname === '/admin/shift-request' ? { backgroundColor: '#75459B' } : {}}>
                                <a className="nav-link" onClick={() => goto("/admin/shift-request")}>
                                    <span className="menu-title" style={window.location.pathname === '/admin/shift-request' ? { color: '#fff' } : {}}>Orders</span>
                                    <i className="far fa-calendar-alt menu-icon drawer-icon" aria-hidden="true" style={window.location.pathname === '/admin/shift-request' ? { color: '#fff' } : {}}></i>
                                </a>
                            </li>

                            <li className="nav-item pointer" style={window.location.pathname === '/admin/pending-shift-request' ? { backgroundColor: '#75459B' } : {}}>
                                <a className="nav-link" onClick={() => goto("/admin/pending-shift-request")}>
                                    <span className="menu-title" style={window.location.pathname === '/admin/pending-shift-request' ? { color: '#fff' } : {}}>Facility User Pending Shift</span>
                                    {/* <i className="fas fa-clock menu-icon drawer-icon" aria-hidden="true" style={window.location.pathname === '/admin/pending-shift-request' ? { color: '#fff' } : {}}></i> */}
                                </a>
                            </li>
                            <li className="nav-item pointer" style={window.location.pathname === '/admin/pending-shift-request-employee' ? { backgroundColor: '#75459B' } : {}}>
                                <a className="nav-link" onClick={() => goto("/admin/pending-shift-request-employee")}>
                                    <span className="menu-title" style={window.location.pathname === '/admin/pending-shift-request-employee' ? { color: '#fff' } : {}}>Caregiver Pending Shift</span>
                                    {/* <i className="fas fa-clock menu-icon drawer-icon" aria-hidden="true" style={window.location.pathname === '/admin/pending-shift-request-employee' ? { color: '#fff' } : {}}></i> */}
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
                {/* //Employee */}
                <li className="nav-item pointer">
                    <a className="nav-link" data-bs-toggle="collapse" href="#employee" aria-expanded="false" aria-controls="employee">
                        <span className="menu-title">Caregiver</span>
                        <i className="fas fa-chevron-down ms-2"></i>
                        <i className="fas fa-user-tie menu-icon drawer-icon" aria-hidden="true"></i>
                    </a>
                    <div className={"collapse"} id="employee">
                        <ul className="nav flex-column sub-menu">
                            <li className="nav-item pointer" style={window.location.pathname === '/admin/job-profile-request' ? { backgroundColor: '#75459B' } : {}}>
                                <a className="nav-link" onClick={() => goto("/admin/job-profile-request")}>
                                    <span className="menu-title" style={window.location.pathname === '/admin/job-profile-request' ? { color: '#fff' } : {}}>Caregivers</span>&nbsp;
                                    <i className="far fa-id-card menu-icon drawer-icon" aria-hidden="true" style={window.location.pathname === '/admin/job-profile-request' ? { color: '#fff' } : {}}></i>
                                </a>
                            </li>
                            <li className="nav-item pointer" style={window.location.pathname === '/admin/add-caregiver' ? { backgroundColor: '#75459B' } : {}}>
                                <a className="nav-link" onClick={() => goto("/admin/add-caregiver")} >
                                    <span className="menu-title" style={window.location.pathname === '/admin/add-caregiver' ? { color: '#fff' } : {}}>Add Caregiver</span>
                                    <i className="fas fa-user-plus menu-icon drawer-icon" style={window.location.pathname === '/admin/add-caregiver' ? { color: '#fff' } : {}}></i>
                                </a>
                            </li>
                            <li className="nav-item pointer" style={window.location.pathname === '/admin/references' ? { backgroundColor: '#75459B' } : {}}>
                                <a className="nav-link" onClick={() => goto("/admin/references")} >
                                    <span className="menu-title" style={window.location.pathname === '/admin/references' ? { color: '#fff' } : {}}>References</span>
                                    <i className="far fa-handshake menu-icon drawer-icon" style={window.location.pathname === '/admin/references' ? { color: '#fff' } : {}}></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
                {/* //Employer */}
                <li className="nav-item pointer">
                    <a className="nav-link" data-bs-toggle="collapse" href="#employer" aria-expanded="false" aria-controls="employer">
                        <span className="menu-title">Facility</span>
                        <i className="fas fa-chevron-down ms-2"></i>
                        <i className="fas fa-building menu-icon drawer-icon" aria-hidden="true"></i>
                    </a>
                    <div className={"collapse"} id="employer">
                        <ul className="nav flex-column sub-menu">
                            <li className="nav-item pointer" style={window.location.pathname === '/admin/facility' ? { backgroundColor: '#75459B' } : {}}>
                                <a className="nav-link" onClick={() => goto("/admin/facility")}>
                                    <span className="menu-title" style={window.location.pathname === '/admin/facility' ? { color: '#fff' } : {}}>Facility</span>
                                    <i className="fas fa-building menu-icon drawer-icon" style={window.location.pathname === '/admin/clients' ? { color: '#fff' } : {}}></i>
                                </a>
                            </li>
                            <li className="nav-item pointer" style={window.location.pathname === '/admin/clients' ? { backgroundColor: '#75459B' } : {}}>
                                <a className="nav-link" onClick={() => goto("/admin/clients")}>
                                    <span className="menu-title" style={window.location.pathname === '/admin/clients' ? { color: '#fff' } : {}}>Facility Users</span>
                                    <i className="fas fa-user-friends menu-icon drawer-icon" style={window.location.pathname === '/admin/clients' ? { color: '#fff' } : {}}></i>
                                </a>
                            </li>
                            <li className="nav-item" style={window.location.pathname === '/admin/dnr-report' ? { backgroundColor: '#75459B' } : {}}>
                                <a className="nav-link" onClick={() => goto("/admin/dnr-report")}  >
                                    <span className="menu-title" style={window.location.pathname === '/admin/dnr-report' ? { color: '#fff' } : {}}>DNR Records</span>
                                    <i className="fas fa-user-lock menu-icon drawer-icon" style={window.location.pathname === '/admin/dnr-report' ? { color: '#fff' } : {}}></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
                {userData?.is_super === 1 && <li className="nav-item pointer" style={window.location.pathname === '/admin/jobs' ? { backgroundColor: '#75459B' } : {}}>
                    <a className="nav-link" onClick={() => goto("/admin/jobs")}>
                        <span className="menu-title" style={window.location.pathname === '/admin/jobs' ? { color: '#fff' } : {}}>Jobs</span>
                        <i className="fas fa-briefcase menu-icon drawer-icon" style={window.location.pathname === '/admin/jobs' ? { color: '#fff' } : {}}></i>
                    </a>
                </li>}
                {userData?.is_super === 1 && <li className="nav-item pointer" style={window.location.pathname === '/admin/weekly-report' ? { backgroundColor: '#75459B' } : {}}>
                    <a className="nav-link" onClick={() => goto("/admin/weekly-report")}>
                        <span className="menu-title" style={window.location.pathname === '/admin/weekly-report' ? { color: '#fff' } : {}}>Weekly Report</span>
                        <i className="fas fa-calendar-week menu-icon drawer-icon" style={window.location.pathname === '/admin/weekly-report' ? { color: '#fff' } : {}}></i>
                    </a>
                </li>}
                <li className="nav-item pointer">
                    <a className="nav-link" data-bs-toggle="collapse" href="#general-pages" aria-expanded="false" aria-controls="general-pages">
                        <span className="menu-title">Manage Notification</span>
                        <i className="fas fa-chevron-down ms-2"></i>
                        <i className="fa fa-bell menu-icon drawer-icon"></i>
                    </a>
                    <div className="collapse" id="general-pages">
                        <ul className="nav flex-column sub-menu">
                            <li className="nav-item pointer"> <a className="nav-link" onClick={() => goto("/admin/notification-add")} > Send Notification </a></li>
                            <li className="nav-item pointer"> <a className="nav-link" onClick={() => goto("/admin/manage-notification-list")} > Notification List </a></li>
                        </ul>
                    </div>
                </li>



                {userData?.is_super === 1 && <li className="nav-item pointer">
                    <a className="nav-link" data-bs-toggle="collapse" href="#website-pages" aria-expanded="false" aria-controls="general-pages">
                        <span className="menu-title">Manage Website</span>
                        <i className="fas fa-chevron-down ms-2"></i>
                        <i className="fas fa-laptop menu-icon drawer-icon"></i>
                    </a>
                    <div className="collapse" id="website-pages">
                        <ul className="nav flex-column sub-menu">
                            <li className="nav-item pointer"> <a className="nav-link" onClick={() => goto("/admin/update-pages")} >Pages </a></li>
                            <li className="nav-item pointer"> <a className="nav-link" onClick={() => goto("/admin/inquiry")} >Inquiry</a></li>
                            <li className="nav-item pointer"> <a className="nav-link" onClick={() => goto("/admin/review")} >Review</a></li>
                            <li className="nav-item pointer"> <a className="nav-link" onClick={() => goto("/admin/featured-location")} >Featured Location</a></li>
                        </ul>
                    </div>
                </li>}
                {userData?.is_super === 1 && <li className="nav-item pointer" style={window.location.pathname === '/admin/settings' ? { backgroundColor: '#75459B' } : {}}>
                    <a className="nav-link" onClick={() => goto("/admin/settings")}  >
                        <span className="menu-title" style={window.location.pathname === '/admin/settings' ? { color: '#fff' } : {}}>Settings</span>
                        <i className="fa fa-cog menu-icon drawer-icon" style={window.location.pathname === '/admin/settings' ? { color: '#fff' } : {}}></i>
                    </a>
                </li>}
                {<li className="nav-item pointer mb-3" style={window.location.pathname === '/admin/converter' ? { backgroundColor: '#75459B' } : {}}>
                    <a className="nav-link" onClick={() => goto("/admin/converter")}  >
                        <span className="menu-title" style={window.location.pathname === '/admin/converter' ? { color: '#fff' } : {}}>Converter</span>
                        <i className="fas fa-pencil-ruler menu-icon drawer-icon" style={window.location.pathname === '/admin/converter' ? { color: '#fff' } : {}}></i>
                    </a>
                </li>}
            </ul>
        </nav>
    );
}

export default DrawerMenuPage;