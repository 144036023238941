import React from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
const MultiStepProgressBarOnboarding = props => {
  return (
    <div className="vertical-progress-bar">
      <ProgressBar
        percent={Math.round((props?.currentStep / (props?.onboardingDocuments?.length - 1)) * 100) + 1}
        fillBackground="linear-gradient(to right, #fefb72, #f0bb31)"
      >
        {props?.onboardingDocuments?.map((item, ind) => {
          return <Step transition="scale" key={ind + "tab"}>
            {({ accomplished, index }) => (
              <div
                // onClick={() => props.setStep(index, item)}
                className={`step indexedStepOnboarding ${props?.currentStep >= index ? "accomplishedOnboarding" : null}`}
              >
                <small className="text-center tab-title">{item?.key_name || item?.document_type?.key_name}</small>
              </div>
            )}
          </Step>
        })}

      </ProgressBar>
    </div>

  );
};

export default MultiStepProgressBarOnboarding;
