/* eslint-disable */
import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";

const EmployeePrivateRoute = () => {
    const location = useLocation();
    if (secureLocalStorage.getItem("token") !== null) {
        const isAuthenticated = secureLocalStorage.getItem("token");
        return isAuthenticated ? <Outlet /> : null; // or loading indicator, etc...
    }
    return <Navigate to={"/login/staff"} state={{ from: location }} replace />;
}

export default EmployeePrivateRoute;