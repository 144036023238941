/* eslint-disable */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react"
import JobItem from "@components/job/JobItem";
import JobItemSkeleton from "@components/skeletons/JobItemSkeleton";
import Multiselect from "multiselect-react-dropdown";
import HomeStore from "@store/HomeStore";
import { useNavigate, useLocation } from 'react-router-dom';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import JobsStore from "@store/JobsStore";
import SheetComponent from "@elements/bottomSheet/SheetComponent";
import secureLocalStorage from "react-secure-storage";
import Spinner from "@components/Spinner";
import { toast } from "react-toastify";
import Header from "@components/Header";
import { toJS } from "mobx";
import { Helmet } from "react-helmet";

var citys = require('@assets/city.json');
var states = require('@assets/states.json');
var dymmyItem = require('@assets/dummyItems.json');
const JobsPage = observer(() => {

    let default_profession = JSON?.parse(secureLocalStorage.getItem("userData"));

    const params = useLocation();
    const navigate = useNavigate();
    const [showType, setShowType] = useState(0)
    const [jobType, setJobType] = useState([{ "name": "All", "value": 2, "type": "Job Type" }])
    const [hot, setHot] = useState(0)
    const specialtiesRef = React.createRef();
    const degreeRef = React.createRef();
    const stateRef = React.createRef();
    const locationRef = React.createRef();
    const regionRef = React.createRef();
    const [degree, setDegrees] = useState([]);
    const [specialties, setSpecialties] = useState([]);
    const [selectedDegree, setSelectedDegree] = useState(params?.state?.degree || null);
    const [selectedSpecialties, setSelectedSpecialties] = useState(params?.state?.specialties);
    const [selectedLocations, setSelectedLocations] = useState(params?.state?.location);
    const [selectedState, setSelectedState] = useState(params?.state?.state);
    const [jobs, setJobs] = useState([]);
    const [isLoading, setLoad] = useState(false);
    const [isLoadingText, setLoadText] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isAuthenticated, setAuthenticated] = useState(null);
    const [sheetData, setSheetData] = useState(null);
    const [careGiverID, setCareGiverID] = useState(null);
    const [bookmarkJobList, setBookmarkJobList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [noData, setNoData] = useState(false);

    const goToPage = (user) => {
        if (user == 'staff') {
            navigate('/howdoesitwork', { state: { type: user } });
        }
        if (user == 'join') {
            navigate('/register', { state: { type: 'Staff' } });
        }
    }
    useEffect(() => {
        let token = secureLocalStorage.getItem("token");
        setAuthenticated(token);
        let userData = secureLocalStorage.getItem("userData");
        setCareGiverID(userData)
        setLoad(true);
        JobsStore.data.degree = null
        JobsStore.data.hot = 0
        JobsStore.data.page = 0
        JobsStore.getJobsData().then(async (jobData) => {
            setJobs(jobData)
            JobsStore.data.degree = null
            setLoad(false);
        });
        if (token) {
            JobsStore.getBookmarkJob();
        }
        navigate("", { replace: true });
    }, []);

    useEffect(() => {
        setBookmarkJobList(toJS(JobsStore?.data?.bookmarkJob).map(x => x.job_id))
    }, [JobsStore?.data?.bookmarkJob]);

    useEffect(() => {
        const degreesData = HomeStore.data.dashboardData?.find(x => x.key_name === 'DEGREES');
        if (degreesData) setDegrees(JSON.parse(toJS(degreesData.value)));
    }, [HomeStore.data.dashboardData]);

    useEffect(() => {
        setSpecialties(HomeStore?.data?.dropDown)
    }, [HomeStore?.data?.dropDown]);

    const onChangeLocations = (value) => {
        setSelectedLocations(value);
    };

    const onChangeDegree = (value) => {
        JobsStore.loading = false
        setSelectedDegree(value);
        HomeStore.getSpecialtiesListData(value[0].Id)
    };

    const onChangeSpecialties = (value) => {
        setSelectedSpecialties(value);
    };


    const onChangeState = (value) => {
        setSelectedState(value);
        setSelectedLocations("")
        setCityList(citys?.filter(x => value?.find(y => y.abbreviation === x.state_id)))
    };

    const onSearch = () => {
        setLoad(true);
        selectedLocations && (JobsStore.data.city = selectedLocations[0]?.label || null)
        selectedDegree && (JobsStore.data.degree = selectedDegree[0]?.Name || null)
        selectedSpecialties && (JobsStore.data.specialty = selectedSpecialties[0]?.Name || null)
        selectedState && (JobsStore.data.state = selectedState[0]?.abbreviation || null)
        jobType && (JobsStore.data.jobType = jobType[0]?.value || null)
        JobsStore.data.showType = showType
        JobsStore.data.hot = hot
        JobsStore.data.pg = 1
        JobsStore.data.recs = 15
        JobsStore.getJobsData().then(async (jobData) => {
            setJobs(jobData)
            setLoad(false);
        });
    }

    const onLoadList = () => {
        if (isAuthenticated) {
            if (!noData) {
                selectedLocations && (JobsStore.data.city = selectedLocations[0]?.label || null)
                selectedDegree && (JobsStore.data.degree = selectedDegree[0]?.Name || null)
                selectedSpecialties && (JobsStore.data.specialty = selectedSpecialties[0]?.Name || null)
                selectedState && (JobsStore.data.state = selectedState[0]?.abbreviation || null)
                jobType && (JobsStore.data.jobType = jobType[0]?.value || null)
                JobsStore.data.showType = showType
                JobsStore.data.hot = hot
                JobsStore.data.pg = JobsStore.data.pg + 1
                JobsStore.data.recs = 15
                JobsStore.getJobsData().then(async (jobData) => {
                    if (jobData.length == 0) {
                        JobsStore.toggleLoading(false)
                        setNoData(true)
                        setLoad(false)
                        return false
                    }
                    await setJobs((prev) => [...prev, ...jobData])
                    setLoad(false);
                });
            }
        } else {
            swal({
                title: "Alert!",
                text: "Please login/register to see more results.",
                icon: "info",
                buttons: {
                    login: {
                        text: "Login",
                        value: "login",
                    },
                    register: {
                        text: "Register",
                        value: "register",
                        className: "btn-success"
                    },
                    cancel: true,
                },
            })
                .then((value) => {
                    if (value === "register") {
                        navigate('/register/staff');
                    } else if (value === "login") {
                        navigate('/login/staff');
                    }
                    else {
                        // Handle the OK button click or do nothing
                        console.log("User clicked OK");
                    }
                });
        }

    }

    const onClear = (type) => {        
        if (type == 'city') {
            JobsStore.data.city = null
            setSelectedLocations("")
        }
        if (type == 'state') {
            JobsStore.data.state = null
            setSelectedState("")
            setSelectedLocations("")
        }
        if (type == 'profession') {
            JobsStore.data.degree = null
            JobsStore.data.specialty = null
            setSelectedDegree("")
            setSelectedSpecialties("")
            setSpecialties([])
        }
        if (type == 'specialties') {
            JobsStore.data.specialty = null
            setSelectedSpecialties("")
        }
        if (type == 'jobType') {
            JobsStore.data.jobType = null
            setJobType("")
        }
    }

    const scrollToEnd = () => {
        if (jobs?.length >= 14) {
            onLoadList()
        }
    }

    window.onscroll = function () {
        if ((window.innerHeight - 200) + document.documentElement.scrollTop + 700 > document.documentElement.offsetHeight && !JobsStore.loading) {
            scrollToEnd();
        }
    }

    const onDismiss = () => {
        setIsOpen(false)
    }
    const onApply = (data) => {
        if (!isAuthenticated) {
            swal({
                title: "Alert!",
                text: "Please register or login first before applying for this job.",
                icon: "error",
                buttons: {
                    login: {
                        text: "Login",
                        value: "login",
                    },
                    register: {
                        text: "Register",
                        value: "register",
                        className: "btn-success"
                    },
                    cancel: true,
                },
            })
                .then((value) => {
                    if (value === "register") {
                        navigate('/register/staff');
                    } else if (value === "login") {
                        navigate('/login/staff');
                    }
                    else {
                        // Handle the OK button click or do nothing
                        console.log("User clicked OK");
                    }
                });
            return
        }
        if (JSON.parse(careGiverID)?.bluesky_staff_id) {
            let overview = {
                Title: data?.Title,
                Date: data?.Data,
                EndDate: data?.EndDate,
                Duration: data?.Duration,
                CompanyName: data?.CompanyName,
                City: data?.City,
                State: data?.State,
                Country: data?.Country,
                Category: data?.Category,
                Degree: data?.Degree,
                Specialty: data?.Specialty,
                Shift: data?.Shift
            }
            let param = {
                job_id: data?.Guid,
                details: overview
            }
            JobsStore.applyJob(param, navigationCallBack)
        } else {
            swal("Alert!", "You don't have an active job profile!", "warning");
        }
    }
    const navigationCallBack = (guid) => {
        JobsStore.applyForBlueSky(JSON.parse(careGiverID).bluesky_staff_id, guid, navigationCallBackFinal)
    }

    const navigationCallBackFinal = () => {
        setIsOpen(false)
        toast.success("Successfully Applied");
    }

    const onOpen = (item, e) => {
        e?.stopPropagation();
        e?.preventDefault();
        setSheetData(item)
        setIsOpen(true)
    }

    const reset = async () => {
        setSelectedState(null)
        setSelectedLocations(null)
        setSelectedDegree(null)
        setSpecialties([])
        setSelectedSpecialties(null)
        setHot(0)
        setShowType(0)
        JobsStore.data.city = null
        JobsStore.data.degree = null
        JobsStore.data.specialty = null
        JobsStore.data.state = null
        JobsStore.data.jobType = jobType[0]?.value
        JobsStore.data.showType = showType
        JobsStore.data.hot = 0
        JobsStore.data.pg = 1
        JobsStore.data.recs = 15
        setLoad(true);
        JobsStore.getJobsData().then(async (jobData) => {
            setJobs(jobData)
            setLoad(false);
        });
    }
    const onChangeStateRemove = (value) => {
        setSelectedState(value);
        setSelectedLocations("");
        setCityList(citys?.filter(x => value?.find(y => y.abbreviation === x.state_id)))
        if (selectedLocations && selectedLocations?.length != 0) {
            setSelectedLocations(selectedLocations?.filter(item => value?.find(data => data.abbreviation === item.state_id)))
        }
    }
    const goToResult = () => {
        const element = document.getElementById('featured');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <>
          <Helmet>
                <title>Staffers LLC | Job Search</title>
            </Helmet>
            <Header />
            {/* TOP BANNER START*/}
            <div className="container-fluid p-0 mb-5 wow fadeIn" data-wow-delay="0.03s">
                <div id="header-carousel" className="carousel slide carousel-fade" data-bs-ride="carousel">
                    <div className="carousel-inner carousel-radius">
                        <div className="carousel-item active">
                            <img className="w-100" src="assets/img/carousel-1.png" alt="banner" />
                            <div className="carousel-caption">
                                <div className="container">
                                    <div className="row justify-content-start">
                                        <div className="col-lg-8 pt-5">
                                            <p
                                                className=" display-6 d-inline-block text-light py-1 px-0 animated slideInDown">
                                                COMING TO THE RESCUE</p>
                                            <h6 className="display-4 mb-4 animated slideInDown text-light">Nursing Jobs</h6>
                                            <small className="mb-4 animated slideInDown text-light">Whether you want to travel across the country, or pick up shifts close to home, our team can assist you with your career goals.
                                            </small>
                                            <div className="pt-5">
                                                {!isAuthenticated && <a href="/register/staff" className="btn btn-primary-light  py-3 px-5 mb-3 animated slideInDown">Join Now</a>}
                                                &nbsp;
                                                <a onClick={() => goToPage("staff")} className="btn bg-transparent border border-light text-light py-3 px-5 mb-3 animated slideInDown">Learn more</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* TOP BANNER END*/}

            {/* SERCH BOX */}
            {/* <div style={!isAuthenticated ? { filter: "blur(4px)" } : {}}> */}
            <div>
                <div className="container float-search">
                    <div className="shadow border rounded p-4 p-sm-2 wow fadeInUp" style={{ backgroundColor: "#f6e8ff" }}>
                        <div className="row">
                            <div className="col-lg-10 col-12">
                                <div className="col-lg-10 col-12 pt-2 pb-2 ps-3">
                                    <b className="">SEARCH JOB</b>
                                </div>
                                <div className="row mb-3 mb-mb-0">
                                    <div className="col-lg-4 col-12 mb-lg-0 mb-md-2 ">
                                        <div className="h-100 text-center">
                                            <div className="ps-3">
                                                <Multiselect
                                                    placeholder="State"
                                                    className="dropdown-multi"
                                                    onSelect={onChangeState}
                                                    onRemove={() => onClear('state')}
                                                    selectedValues={selectedState}
                                                    selectionLimit={1}
                                                    avoidHighlightFirstOption={true}
                                                    options={states}
                                                    displayValue="name" // Property name to display in the dropdown options
                                                    ref={stateRef}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-12 mb-lg-0 mb-md-2">
                                        <div className="h-100 text-center">
                                            <div className="ps-3">
                                                <Multiselect
                                                    placeholder="City"
                                                    onSelect={onChangeLocations}
                                                    options={cityList} // Options to display in the dropdown
                                                    selectedValues={selectedLocations}
                                                    displayValue="label"
                                                    groupBy="group"
                                                    className="dropdown-multi"
                                                    onRemove={() => onClear('city')}
                                                    selectionLimit={1}
                                                    showCheckbox={true}
                                                    ref={locationRef}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-12">
                                        <div className="h-100 text-center">
                                            <div className="ps-2">
                                                <Multiselect
                                                    className="dropdown-multi"
                                                    placeholder="Job Types"
                                                    avoidHighlightFirstOption={true}
                                                    onSelect={(value) => setJobType(value)}
                                                    selectedValues={jobType}
                                                    onRemove={() => onClear('jobType')}
                                                    options={global.config.jobType}
                                                    singleSelect={true}
                                                    groupBy="type"
                                                    displayValue="name"
                                                    ref={regionRef}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="row wow fadeIn" data-wow-delay="0.03s">
                                    <div className="col-lg-6 col-12">
                                        <div className="h-100 text-center">
                                            <div className="ps-3 mb-3 ">
                                                <Multiselect
                                                    placeholder="Select Profession"
                                                    className="dropdown-multi"
                                                    onSelect={onChangeDegree}
                                                    onRemove={() => onClear('profession')}
                                                    selectedValues={selectedDegree}
                                                    singleSelect={true}
                                                    selectionLimit={1}
                                                    avoidHighlightFirstOption={true}
                                                    options={degree}
                                                    displayValue="Name" // Property name to display in the dropdown options
                                                    ref={degreeRef}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-12">
                                        <div className="h-100 text-center">
                                            <div className="ps-3 mb-3">
                                                <Multiselect
                                                    avoidHighlightFirstOption={true}
                                                    className="dropdown-multi"
                                                    placeholder="Select Specialties"
                                                    selectedValues={selectedSpecialties}
                                                    onRemove={() => onClear('specialties')}
                                                    onSelect={onChangeSpecialties}
                                                    options={specialties}
                                                    selectionLimit={1}
                                                    loading={HomeStore?.loading}
                                                    displayValue="Name"
                                                    singleSelect={true}
                                                    ref={specialtiesRef}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 mt-3 col-12" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div className="col-md-auto wow fadeIn w-75" align="center" data-wow-delay="0.03s">
                                    <button className="btn btn-primary w-100 py-2 m-2" name="submit" onClick={()=>{reset(),goToResult()}}>Reset</button>
                                    <button className="btn btn-primary w-100 py-2 m-2" name="submit" onClick={()=>{onSearch(),goToResult()}}>Search</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* SERCH BOX */}

            {/* {!isAuthenticated && <div id="watermark">
                <a href="/register/staff"><h1 style={{ cursor: 'pointer', textDecoration: "underline", color: "#75459B" }}>Join Now To Access Job List For Free</h1></a>
            </div>} */}
            {/* <div style={!isAuthenticated ? { filter: "blur(4px)", pointerEvents: 'none' } : {}}> */}
            <div>
                <section id="featured" className="section bg-cyan pt-xl-5">
                    <div className="container-fluid mb-5">
                        <div className="row">
                            {
                                (isLoading) ?
                                    (<>
                                        <div>
                                            <p className="text-end mt-5 me-5">0 entries</p>
                                        </div>
                                        <div className="grid-job">
                                            {Array(6)
                                                .fill(0)
                                                .map((_, index) => (
                                                    <JobItemSkeleton key={index} />
                                                ))}
                                        </div>
                                    </>) : null
                            }
                            {
                                jobs && jobs?.length === 0 &&
                                <>
                                    <p className="text-center mt-5">No Jobs Found</p>
                                </>
                            }
                            <div>
                                {jobs?.length != 0 && <p className="text-end mt-5 me-5">{jobs && jobs?.length} entries</p>}
                            </div>

                            {
                                <div>
                                    <div className="grid_jobs wow fadeInUp" data-wow-delay="0.03s">
                                        {
                                            !isLoading && jobs && jobs?.length > 0 && jobs.map((item, index) => {
                                                return <JobItem onOpen={onOpen} item={item} key={index} isAuthenticated={isAuthenticated} bookmarkJobList={bookmarkJobList} />;
                                            })
                                        }
                                    </div>
                                    {(isLoadingText || JobsStore.loading) && <p className="text-center mt-5 me-5">Loading...</p>}

                                </div>
                            }

                        </div>

                        <div>
                            {jobs?.length != 0 && <p className="text-end mt-5 me-5">{jobs && jobs.length} entries</p>}
                        </div>
                    </div>
                </section>

            </div>
            <div data-body-scroll-lock-ignore="true">
                <SheetComponent isOpen={isOpen} onDismiss={onDismiss} onApply={onApply} data={sheetData} />
            </div>

            <Spinner isLoading={JobsStore.loadingApply} />
        </>
        // </BaseLayout >
    );
});

export default JobsPage;
