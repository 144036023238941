/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
import AdminStore from "@store/AdminStore";
import TokenStore from "@store/TokenStore";
import { toJS } from "mobx";
import { observer } from "mobx-react"
import moment from "moment";

const HeaderPage = observer(() => {
    const navigate = useNavigate();
    const [userType, setUserType] = useState();
    const [userData, setUserData] = useState();
    const [notificationsData, setNotificationData] = useState([]);
    const [notificationAdminCount, setNotificationAdminCount] = useState(0);

    useEffect(() => {
        let value = secureLocalStorage.getItem("type");
        let data = secureLocalStorage.getItem("adminData");
        let token = secureLocalStorage.getItem("admin-token");
        if (!token) {
            navigate("/", { replace: true });
        }
        setUserType(value);
        setUserData(JSON.parse(data));
        if (JSON.parse(data)) {
            AdminStore.getNotificationsData();
            AdminStore.getSettings();
        }
    }, []);

    useEffect(() => {
        AdminStore.toggleLoadingLong(AdminStore?.data?.settings?.find(x => x.key_name === 'sync_process')?.value == 1)
    }, [AdminStore?.data?.settings]);


    useEffect(() => {
        setNotificationData(toJS(AdminStore?.data?.notifications).filter(x => x.read_at == null).slice(0, 5))
        setNotificationAdminCount(toJS(AdminStore?.data?.notifications).filter(x => x.read_at == null)?.length)
    }, [AdminStore?.data?.notifications]);

    const logout = () => {
        swal({
            title: "Do you really want to logout?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    TokenStore.clearAllAsyncStore();
                    secureLocalStorage.removeItem('admin-token');
                    secureLocalStorage.removeItem('adminData');
                    secureLocalStorage.removeItem('type');
                    window.location.replace('/admin-login');
                }
            });
    }
    const openNotification = (notify) => {
        let param = {
            "notification_id": notify?.id
        }
        AdminStore.readNotification(param, notify, navigationCallBack)
    }
    const navigationCallBack = (notify) => {
        AdminStore.getNotificationsData();
        navigate('/admin/notification-list', { state: { item: notify } });
    }

    const minimiz = () => {
        var body = $('body');
        if ((body.hasClass('sidebar-toggle-display')) || (body.hasClass('sidebar-absolute'))) {
            body.toggleClass('sidebar-hidden');
        } else {
            body.toggleClass('sidebar-icon-only');
        }
    }

    const minimizMobile = () => {
        $('.sidebar-offcanvas').toggleClass('active')
    }

    const goto = (path) => {
        navigate(path);
    }
    const liveUpdateStatusAll = () => {
        swal({
            title: "Do you really want to update all status?",
            text: "The update might take up to 15 minutes, Please don't refresh the page or logout!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    AdminStore.checkLiveStatusForAll()
                }
            });
    }


    return (
        <nav className="navbar fixed-top navbar-admin col-lg-12 col-12 p-0 flex-row shadow-none">
            <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
                <a className="navbar-brand brand-logo" style={{ width: "40%" }} href="/admin/dashboard/"><img src="/brand.png" alt="logo" /></a>
                <a className="navbar-brand brand-logo-mini" href="/admin/dashboard/">
                    <img src="/people.png" style={{ width: 45, maxWidth: 'none', height: 'auto', margin: "auto" }} alt="logo" /></a>
            </div>
            <div className="navbar-menu-wrapper d-flex align-items-stretch">
                <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={() => minimiz()} data-toggle="minimize">
                    <span className="fas fa-bars"></span>
                </button>
                <ul className="navbar-nav navbar-nav-left d-none d-lg-flex">
                    <a className="pointer" download="Staffers Admin User Manual.pdf" href="/documentation/Staffers-Admin-User-Manual.pdf">
                        User Manual <span className="fas fa-download"></span>
                    </a>
                </ul>
                <ul className="navbar-nav navbar-nav-right">

                    {(AdminStore.data.settings?.find(x => x.id === 44)?.value) && <span data-toggle="tooltip" data-placement="top" title={AdminStore.data.settings?.find(x => x.id === 44)?.value + " profiles synced with BlueSky today"} className="btn text-start" type="none" disabled={AdminStore.loadingLong}>
                        <span>
                            <strong className="text-success text-start">{AdminStore.data.settings?.find(x => x.id === 44)?.value} </strong>
                            <i className="fas fa-sync-alt"></i>
                            <div className="row m-0 p-0">
                                <div className="col m-0 p-0">
                                    <small className="text-success text-start fs-8 d-lg-block d-none">
                                        {moment(AdminStore.data.settings?.find(x => x.id === 44)?.updated_at).fromNow()}
                                    </small>
                                </div>
                            </div>
                        </span>
                    </span>}
                    {userType == '1' && (
                        <>
                            <li className="nav-item dropdown">
                                <a className="nav-link count-indicator dropdown-toggle" id="notificationDropdown" href="/admin/notification-list">
                                    <i className="far fa-bell fs-3" /><span className="badge-tag-admin">{notificationAdminCount}</span>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="notificationDropdown">
                                    <h6 className="p-3 mb-0">Notifications</h6>
                                    <div className="dropdown-divider"></div>
                                    {
                                        notificationsData && notificationsData?.length > 0 ? notificationsData?.map(notify => {
                                            return (
                                                <div key={notify?.id + "noti"} style={JSON.parse(notify?.data)?.read_at ? {} : { backgroundColor: "ghostwhite" }}>
                                                    <a className="dropdown-item preview-item">
                                                        <div onClick={() => openNotification(notify)} className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <div><h6 className="preview-subject font-weight-normal mb-1 pe-3">{JSON.parse(notify?.data)?.data?.heading}</h6>
                                                                    <p className="text-gray ellipsis mb-0"> {JSON.parse(notify?.data)?.data?.subject}</p>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <h5><span className="badge badge-primary"> {moment(notify?.created_at).fromNow()}</span></h5>
                                                            </div>
                                                        </div>
                                                    </a>
                                                    <div className="dropdown-divider"></div>
                                                </div>)
                                        }) : <>
                                            <div className="dropdown-divider"></div>
                                            <div className="dropdown-item preview-item">
                                                <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                                                    <h6 className="preview-subject font-weight-normal mb-1">No New Notification</h6>
                                                </div>
                                            </div>
                                            <div className="dropdown-divider"></div>
                                        </>

                                    }
                                    <div className="dropdown-divider"></div>
                                    <h6 className="p-3 mb-0 text-center" onClick={() => goto("/admin/notification-list")} >See all notifications</h6>
                                </div>
                            </li>
                        </>)}

                    <li className="nav-item nav-profile dropdown">
                        <a className="nav-link dropdown-toggle" id="profileDropdown" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                            <div className="nav-profile-img">
                                {/* <img src="/assets/images/faces/face1.png" style={{borderRadius:0}} alt="image" /> */}
                                <i className="fas fa-user-shield text-primary" aria-hidden="true"></i>
                                <span className="availability-status online"></span>
                            </div>
                            <div className="nav-profile-text">
                                <p className="mb-1 text-black text-capitalize">{userData?.first_name} </p>
                            </div>
                        </a>
                        <div className="dropdown-menu navbar-dropdown" aria-labelledby="profileDropdown">

                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" href="#" onClick={() => logout()}>
                                <i className="fas fa-sign-out-alt me-2 text-primary"></i> Logout </a>
                        </div>
                    </li>
                </ul>
                <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={() => minimizMobile()} data-toggle="offcanvas">
                    <i className="fas fa-bars"></i>
                </button>
            </div>
        </nav>
    );
})

export default HeaderPage;