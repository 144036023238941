import React from 'react';
import { Calendar } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useMediaQuery } from 'react-responsive';
const MyCalendar = ({ localizer, events, handleEventClick, CustomEvent, View, CustomDayWrapper }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const eventPropGetter = (myEventsList, start, end, isSelected) => {
    const backgroundColor = myEventsList.colorEvento ? myEventsList.colorEvento : '#75459B';
    const color = myEventsList.color ? myEventsList.color : 'white';
    const fontSize = '13px';
    const className = isSelected ? 'event-rotating-border' : ''
    return { style: { backgroundColor, color, fontSize }, className };
  };
  return (
    <Calendar
      defaultView={isMobile || View ? 'agenda' : 'month'}
      localizer={localizer}
      events={events}
      startAccessor='start'
      endAccessor='end'
      popup
      handleDragStart={() => null}
      views={View ? { month: false, week: false, day: false, agenda: true } : { month: true, week: true, day: true, agenda: true }}
      style={{ height: 600 }}
      eventPropGetter={eventPropGetter}
      onSelectEvent={handleEventClick}
      dayFormat="dddd"
      components={CustomDayWrapper ? {
        event: CustomEvent, // Assuming CustomEvent is defined elsewhere
        dateCellWrapper: (props) => (
          <CustomDayWrapper {...props} />)
      } : {
        event: CustomEvent, // Assuming CustomEvent is defined elsewhere
      }}
      formats={{
        monthHeaderFormat: 'MMMM YYYY',
        weekdayFormat: 'dddd'

      }}
    />

  );
};

export default MyCalendar;
