/* eslint-disable */
import React, { useState, useEffect } from "react";
import Header from "@components/Header";
import { Helmet } from "react-helmet";
import { observer } from "mobx-react"
import Footer from "@components/Footer";
import { InputField } from "@elements/index"
import { ErrorMessage, FieldArray, Formik } from "formik";
import * as Yup from 'yup';
import Select, { components } from 'react-select';
import UserStore from "@store/UserStore";
import HomeStore from "@store/HomeStore";
import StaffsStore from "@store/StaffsStore";
import Spinner from "@components/Spinner";
import swal from '@sweetalert/with-react';
import secureLocalStorage from "react-secure-storage";
import { CModal, CModalBody, CModalHeader, CModalTitle } from "@coreui/react";
import SelectField from "@elements/select/SelectField";
import { ClientEditSchema, StaffUpdateSchema, SubClientEditSchema } from '@utils/schemas/allSchema';
import { colourStyles, colourStylesState } from '@utils/customStyle/style';
var citys = require('@assets/city.json');
var states = require('@assets/states.json');
var countryCodes = require('@assets/CountryCodes.json');

const passwordSchema = Yup.object().shape({
    current_password: Yup.string().required('Current Password required').min(6, "Your password must be longer than 6 characters."),
    new_password: Yup.string().required('Password required').min(6, "Your password must be longer than 6 characters.").test('no-spaces', 'Password must not contain spaces', (value) => !/\s/.test(value))
        .required('Required'),
    passwordConfirmation: Yup.string().required('Confirm password required')
        .oneOf([Yup.ref('new_password'), null], 'Passwords must match'),
});

const EditProfilePage = observer(() => {
    const [userData, setUserData] = useState();
    const [userType, setUserType] = useState();
    const [isDisabled, setDisabled] = useState(true);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [icon, setIcon] = useState("fa fa-fw fa-eye-slash field-icon toggle-password")
    const [passwordType, setPasswordType] = useState("password");
    const [regions, setRegions] = useState([]);

    useEffect(() => {
        StaffsStore.getRegionsList();
        let userType = secureLocalStorage.getItem("type");
        setUserType(userType);
        if (userType === 'false') {
            UserStore.getUserProfile()
        } else {
            StaffsStore.getStaffProfile()
        }
    }, []);

    useEffect(() => {
        setUserData(UserStore?.data?.profile)
    }, [UserStore.data.profile]);

    useEffect(() => {
        setUserData(StaffsStore.data.profile)
    }, [StaffsStore.data.profile]);

    useEffect(() => {
        setRegions(StaffsStore?.data?.regionsList)
    }, [StaffsStore?.data?.regionsList]);

    const updateForm = (values) => {
        if (userType === 'false') {
            let formData = new FormData()
            formData.append("additional_contact", values.additional_contact?.length > 0 ? JSON.stringify(values.additional_contact) : "")
            formData.append("additional_mail", values.additional_mail?.length > 0 ? JSON.stringify(values.additional_mail) : "")
            // formData.append("organization_name", values.organization_name)
            // formData.append("organization_type", values.organization_type)
            formData.append("title", values.title)
            formData.append("first_name", values.first_name)
            formData.append("last_name", values.last_name)
            formData.append("email", values.email)
            formData.append("contact", values.contact)
            formData.append("country_code", values.country_code)
            formData.append("state", values.state)
            formData.append("city", values.city)
            formData.append("city_data", JSON.stringify(values?.city_data))
            formData.append("address", values.address)
            formData.append("zipcode", values.zipcode)
            formData.append("description", values.description)
            formData.append("designation", values.designation)
            formData.append("master", values.master)
            formData.append("region_id", values.region_id)
            UserStore.updateUserProfile(formData, navigationCallBack)
        } else {
            StaffsStore.updateStaffProfile(values, navigationCallBack)
        }
    }
    const navigationCallBack = () => {
        setDisabled(true)
        if (userType === 'false') {
            UserStore.getUserProfile()
        } else {
            StaffsStore.getStaffProfile()
        }
        swal("Updated!", "Successfully updated!", "success");
    }
    const onPassowrdChange = () => {
        setIsOpen(true);
    }

    const passwordForm = (values) => {
        if (userType === 'false') {
            UserStore.changePassword(values, passwordnavigationCallBack)
        } else {
            StaffsStore.changePassword(values, passwordnavigationCallBack)
        }
    }
    const passwordnavigationCallBack = () => {
        setIsOpen(false);
        swal("Updated!", "Successfully updated!", "success");
    }
    const _changeIcon = () => {
        icon === "fa fa-fw fa-eye-slash field-icon toggle-password"
            ? (setIcon("fa fa-fw fa-eye field-icon toggle-password"), setPasswordType("text"))
            : (setIcon("fa fa-fw fa-eye-slash field-icon toggle-password"), setPasswordType("password"))
    }

    return (
        <React.Fragment>
            <Header />
            <Helmet>
                <title>Staffers LLC | Edit Profile</title>
            </Helmet>
            <div className="container-fluid callback mt-5 py-5">
                <div className="container pt-5">
                    <h5 className="display-5 mb-4 animated slideInDown login-head">My Account</h5>
                    <div className="row justify-content-center ">
                        <div className="col-lg-6">
                            <div className="bg-white shadow border p-4 p-sm-4 wow fadeInUp" style={{ borderRadius: 25 }} data-wow-delay="0.3s">
                                <>
                                    <Formik
                                        initialValues={{
                                            master: userData?.master || '',
                                            title: userData?.title || '',
                                            email: userData?.email || '',
                                            first_name: userData?.first_name,
                                            last_name: userData?.last_name,
                                            region_id: userData?.region_id || '',
                                            profession: userData?.profession,
                                            contact: userData?.contact || '',
                                            country_code: userData?.country_code,
                                            state: userData?.state || '',
                                            city: userData?.city || '',
                                            city_data: userData?.city_data ? JSON.parse(userData?.city_data) : [],
                                            address: userData?.address || '',
                                            zipcode: userData?.zipcode || '',
                                            description: userData?.description || '',
                                            designation: userData?.designation,
                                            password: "",
                                            additional_contact: (userData?.additional_contact && JSON.parse(userData?.additional_contact)) || [],
                                            additional_mail: (userData?.additional_mail && JSON.parse(userData?.additional_mail)) || [],
                                        }}
                                        onSubmit={values => updateForm(values)}
                                        validationSchema={userType == 'false' ? userData?.master ? SubClientEditSchema : ClientEditSchema : StaffUpdateSchema}
                                        enableReinitialize={true}
                                        validateOnChange={false}
                                    >
                                        {
                                            ({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched
                                            }) =>
                                            (

                                                <form onSubmit={handleSubmit}>
                                                    <div className="row g-3">
                                                        {userType == 'false' && (<>
                                                            <strong className="text-center text-success pb-3"><i className="far fa-id-card"></i> {userData?.username}</strong>
                                                        </>)}
                                                        <div className="col-sm-6">
                                                            <InputField
                                                                className={"did-floating-input"}
                                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                type="text"
                                                                name="first_name"
                                                                label={"First Name"}
                                                                value={values.first_name || ''}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                disabled={isDisabled}
                                                            />
                                                            {errors.first_name ? (
                                                                <div className="error-message">{errors.first_name}</div>
                                                            ) : null}
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <InputField
                                                                className={"did-floating-input"}
                                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                type="text"
                                                                name="last_name"
                                                                label={"Last Name"}
                                                                value={values.last_name || ''}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                disabled={isDisabled}
                                                            />
                                                            {errors.last_name ? (
                                                                <div className="error-message">{errors.last_name}</div>
                                                            ) : null}
                                                        </div>
                                                        {/* {userType == 'false' && (<>
                                                            <div className="col-sm-6">
                                                                <InputField
                                                                    className={"did-floating-input"}
                                                                    classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                    type="text"
                                                                    name="organization_name"
                                                                    label={"Organization Name"}
                                                                    value={values.organization_name || ''}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    disabled={isDisabled || userData?.master}
                                                                />
                                                                {errors.organization_name ? (
                                                                    <div className="error-message">{errors.organization_name}</div>
                                                                ) : null}
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <SelectField
                                                                    name="organization_type"
                                                                    label={"Organization Type"}
                                                                    classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                    value={values.organization_type}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    data={global.config.organizationType}
                                                                    required={true}
                                                                    optionLabel={"label"}
                                                                    optionValue={"value"}
                                                                    disabled={isDisabled || userData?.master}
                                                                />
                                                                {errors.organization_type ? (
                                                                    <div className="error-message">{errors.organization_type}</div>
                                                                ) : null}
                                                            </div></>)} */}
                                                        <div className="col-sm-2">
                                                            <Select
                                                                onChange={(e) => setFieldValue("country_code", e.label)}
                                                                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                                placeholder={''}
                                                                styles={colourStyles}
                                                                value={countryCodes.filter(({ label }) => label === values.country_code) || ''}
                                                                isSearchable={true}
                                                                options={countryCodes}
                                                                defaultValue={{ label: "+1", value: 'CA' }}
                                                                required={true}
                                                                disabled={isDisabled}
                                                            />
                                                        </div>

                                                        <div className="col-sm-4">
                                                            <InputField
                                                                className={"did-floating-input"}
                                                                classNameLabel={userData?.master ? "" : "did-floating-label did-floating-labe-astrisk"}
                                                                type="text"
                                                                name="contact"
                                                                label={"Contact Number"}
                                                                value={values?.contact?.replace(/[^\d]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3') || ""}
                                                                maxLength={12}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            />
                                                            {errors.contact ? (
                                                                <div className="error-message">{errors.contact}</div>
                                                            ) : null}
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <InputField
                                                                className={"did-floating-input"}
                                                                classNameLabel={userData?.master ? "" : "did-floating-label did-floating-labe-astrisk"}
                                                                type="email"
                                                                name={'email'}
                                                                label={"Email Address"}
                                                                value={values.email || ''}
                                                                disabled={true}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            />
                                                        </div>
                                                        {userType == 'false' && (<>
                                                            <div className="col-sm-12 d-flex mt-3 pt-0">
                                                                <div className="col-sm-6">
                                                                    <FieldArray
                                                                        name="additional_contact"
                                                                        render={({ push, remove }) => (
                                                                            <div>
                                                                                {values?.additional_contact?.map((device, index) => (
                                                                                    <div key={index} className="row g-2">
                                                                                        <div className="col-sm-4">
                                                                                            <Select
                                                                                                onChange={(e) => setFieldValue(`additional_contact.${index}.country_code`, e.label)}
                                                                                                placeholder={''}
                                                                                                label={'Country'}
                                                                                                styles={colourStyles}
                                                                                                isSearchable={true}
                                                                                                options={countryCodes}
                                                                                                defaultValue={{ label: "+1", value: 'CA' }}
                                                                                                isDisabled={isDisabled}
                                                                                                components={{ Control, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                                                            />
                                                                                        </div>
                                                                                        <div className="col-sm-7 pb-2">
                                                                                            <InputField
                                                                                                className={"did-floating-input"}
                                                                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                                                type="text"
                                                                                                name="contact"
                                                                                                label={"Contact Number"}
                                                                                                value={device?.contact?.replace(/[^\d]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3') || ""}
                                                                                                maxLength={12}
                                                                                                onChange={(e) => setFieldValue(`additional_contact.${index}.contact`, e.target.value)}
                                                                                                onBlur={handleBlur}
                                                                                                required={true}
                                                                                                disabled={isDisabled}
                                                                                            />
                                                                                            <ErrorMessage
                                                                                                name={`additional_contact.${index}.contact`}
                                                                                                component="div"
                                                                                                className="error-message"
                                                                                            />
                                                                                        </div>
                                                                                        {!isDisabled && <div className="col-sm-1">
                                                                                            <i className="fas fa-times-circle text-danger" onClick={() => remove(index)}></i>
                                                                                        </div>}
                                                                                    </div>
                                                                                ))}
                                                                                {values.additional_contact?.length < 3 && !isDisabled && <button
                                                                                    type="button"
                                                                                    className="btn btn-sm btn-light"
                                                                                    onClick={() => push({ country_code: '+1', contact: '' })}
                                                                                >
                                                                                    Add Contact
                                                                                </button>}
                                                                            </div>

                                                                        )}
                                                                    />
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    {/* More email input */}
                                                                    <FieldArray
                                                                        name="additional_mail"
                                                                        render={({ push, remove }) => (
                                                                            <div >
                                                                                {values.additional_mail.map((device, index) => (
                                                                                    <div className="row g-2" key={index}>
                                                                                        <div className="col-sm-11 pb-2">
                                                                                            <InputField
                                                                                                className={"did-floating-input"}
                                                                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                                                type="email"
                                                                                                name="email"
                                                                                                label={"Email Address"}
                                                                                                value={device.email}
                                                                                                onChange={(e) => setFieldValue(`additional_mail.${index}.email`, e.target.value)}
                                                                                                onBlur={handleBlur}
                                                                                                required={true}
                                                                                                disabled={isDisabled}
                                                                                            />
                                                                                            <ErrorMessage
                                                                                                name={`additional_mail.${index}.email`}
                                                                                                component="div"
                                                                                                className="error-message"
                                                                                            />
                                                                                        </div>
                                                                                        {!isDisabled && <div className="col-sm-1">
                                                                                            <i className="fas fa-times-circle text-danger" onClick={() => remove(index)}></i>
                                                                                        </div>}
                                                                                    </div>
                                                                                ))}
                                                                                {values.additional_mail?.length < 3 && !isDisabled && <button
                                                                                    type="button"
                                                                                    className="btn btn-sm btn-light ms-2"
                                                                                    onClick={() => push({ email: '' })}
                                                                                    disabled={isDisabled}
                                                                                >
                                                                                    Add Email
                                                                                </button>}
                                                                            </div>

                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>


                                                        </>)}




                                                        {userType == 'false' && (<>
                                                            <div className="col-sm-12">
                                                                <InputField
                                                                    className={"did-floating-input"}
                                                                    classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                    type="text"
                                                                    name="designation"
                                                                    label={"Job Title"}
                                                                    value={values.designation || ''}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    disabled={isDisabled}
                                                                />
                                                                {errors.designation ? (
                                                                    <div className="error-message">{errors.designation}</div>
                                                                ) : null}
                                                            </div>
                                                        </>)}
                                                        <div className="col-12">
                                                            {userType == 'true' && <>
                                                                <SelectField
                                                                    name="profession"
                                                                    label={"Profession"}
                                                                    value={values.profession}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    data={HomeStore?.data?.degreeList}
                                                                    optionLabel={"Name"}
                                                                    optionValue={"Id"}
                                                                    disabled={isDisabled}
                                                                />
                                                                {errors.profession ? (
                                                                    <div className="error-message">{errors.profession}</div>
                                                                ) : null}
                                                            </>
                                                            }
                                                        </div>
                                                        {
                                                            userType == 'false' && <>
                                                                <div className="col-sm-6">
                                                                    <InputField
                                                                        className={"did-floating-input"}
                                                                        type={"text"}
                                                                        name="address"
                                                                        label={"Full Address"}
                                                                        value={values.address}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        disabled={isDisabled}
                                                                    />
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <Select
                                                                        onChange={(e) => { setFieldValue("state", e.abbreviation), setFieldValue("city", '') }}
                                                                        placeholder={''}
                                                                        label={'State'}
                                                                        styles={colourStylesState}
                                                                        value={states.filter(({ abbreviation }) => values?.state?.includes(abbreviation)) || ''}
                                                                        options={states}
                                                                        getOptionLabel={(option) => option.name}
                                                                        getOptionValue={(option) => option.abbreviation}
                                                                        components={{ Control, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                                        isDisabled={isDisabled}
                                                                    />
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <Select
                                                                        onChange={(e) => { setFieldValue("city", e.label); setFieldValue("city_data", [e]) }}
                                                                        placeholder={''}
                                                                        label={'City'}
                                                                        isDisabled={!values?.state || isDisabled}
                                                                        styles={colourStylesState}
                                                                        getOptionLabel={(option) => option.label}
                                                                        getOptionValue={(option) => option.label}
                                                                        value={citys.filter(({ label }) => values?.city === label) || ''}
                                                                        options={citys?.filter(ct => ct.state === states?.find(x => x.abbreviation == values?.state)?.name)}
                                                                        components={{ Control, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                                    />
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <InputField
                                                                        name={"zipcode"}
                                                                        className={"did-floating-input"}
                                                                        type="number"
                                                                        label={"Zip Code"}
                                                                        value={values.zipcode}
                                                                        onChange={(event) => { handleChange(event); }}
                                                                        onBlur={handleBlur}
                                                                        disabled={isDisabled}
                                                                    />
                                                                </div>
                                                            </>
                                                        }
                                                        <div className="col-12">
                                                            <label className="pb-2 label">Tell us about yourself</label>
                                                            <div className="form-floating">
                                                                <textarea className="form-control"
                                                                    placeholder=""
                                                                    name={'description'}
                                                                    value={values.description || ''}
                                                                    onChange={handleChange}
                                                                    disabled={isDisabled}
                                                                    onBlur={handleBlur}
                                                                    style={{ height: 100 }}>
                                                                </textarea>
                                                            </div>
                                                        </div>
                                                        <div className="col-4">
                                                            {!isDisabled && <button className="btn btn-light w-100 p-2 " type="submit">Update</button>}
                                                            {isDisabled && <button className="btn btn-light w-100 p-2 " type="button" onClick={() => setDisabled(false)}>Edit</button>}
                                                        </div>
                                                        <div className="col-8">
                                                            <button className="btn btn-primary w-100 p-2" type="button" onClick={() => onPassowrdChange()}>Change Password</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            )
                                        }
                                    </Formik>

                                </>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <CModal visible={modalIsOpen} onClose={() => setIsOpen(false)}>
                        <CModalHeader onClose={() => setVisible(false)}>
                            <CModalTitle>Change Password</CModalTitle>
                        </CModalHeader>
                        <CModalBody><Formik
                            initialValues={{ current_password: "", new_password: "", passwordConfirmation: "" }}
                            onSubmit={values => passwordForm(values)}
                            validationSchema={passwordSchema}
                        >
                            {
                                ({ handleChange, handleBlur, handleSubmit, values, errors, touched
                                }) =>
                                (
                                    <form onSubmit={handleSubmit}>
                                        <div className="modal-body">
                                            <div className="form-group pb-3">
                                                <InputField
                                                    className={"did-floating-input"}
                                                    type="password"
                                                    name="current_password"
                                                    label={"Current Password*"}
                                                    value={values.current_password}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.current_password && touched.current_password ? (
                                                    <div className="error-message">{errors.current_password}</div>
                                                ) : null}
                                            </div>
                                            <div className="form-group pb-3">
                                                <InputField
                                                    className={"did-floating-input"}
                                                    type={passwordType}
                                                    name="new_password"
                                                    label={"New Password*"}
                                                    value={values.new_password}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                <span toggle="#password-field" class={icon} onClick={() => _changeIcon()}></span>
                                                {errors.new_password && touched.new_password ? (
                                                    <div className="error-message">{errors.new_password}</div>
                                                ) : null}
                                            </div>
                                            <div className="form-group pb-3">
                                                <InputField
                                                    className={"did-floating-input"}
                                                    type="password"
                                                    name="passwordConfirmation"
                                                    label={"Confirm Password*"}
                                                    value={values.passwordConfirmation}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.passwordConfirmation && touched.passwordConfirmation ? (
                                                    <div className="error-message">{errors.passwordConfirmation}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="modal-footer justify-content-center ">
                                            <button type="submit" className="btn btn-primary">Save changes</button>
                                        </div>
                                    </form>
                                )
                            }
                        </Formik>
                        </CModalBody>
                    </CModal>
                </div>
            </div>
            <Spinner isLoading={UserStore.loading || StaffsStore.loading} />
            <Footer />
        </React.Fragment>
    )
});
export const Control = (props) => {
    return (
        <>
            <label className={props.hasValue || props.isFocused ? "did-floating-label did-floating-label-top" : "did-floating-label did-floating-label"} style={{ zIndex: 999 }}>{props?.selectProps?.label}</label>
            <components.Control {...props} />
        </>
    );
};


export default EditProfilePage;