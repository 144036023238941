import React from "react";
import { HOME_URL } from "../api/config";

const PDFHeader = ({ title }) => {
  return (
    <>
      <div className="">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="header-pdf">
                <img src={`${HOME_URL}static/media/brand.750d576a52f3ac057b3d.png`} width={100} height={60} alt={"staffers logo"} className="img-fluid" />
                <h1>{title}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PDFHeader;
