/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import Header from '@components/Header';
import { Helmet } from 'react-helmet';
import { CButton } from '@coreui/react-pro'
import 'react-spring-bottom-sheet/dist/style.css';
import StaffsStore from '@store/StaffsStore';
import HomeStore from '@store/HomeStore';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { toJS } from 'mobx';
import Spinner from "@components/Spinner";
import ReactDOM from "react-dom";
import { useLocation } from "react-router-dom"
import Images from '../../elements/Images';
import swal from '@sweetalert/with-react';
import { saveAs } from 'file-saver';
import { createEvent } from 'ics';
import { toast } from 'react-toastify';
import { useMediaQuery } from 'react-responsive';
import secureLocalStorage from 'react-secure-storage';

const AppliedShifts = observer(() => {
    const isMobile = useMediaQuery({ maxWidth: 768 });
    let location = useLocation();
    const tableRef = useRef()
    const tableName = "table1"
    const [appliedShiftList, setAppliedShiftList] = useState([]);
    const [upcComingShiftList, setComingShiftList] = useState([]);
    const [degree, setDegree] = useState([]);
    const [rating, setRating] = useState();
    // const [feedback, setFeedback] = useState("");
    const [submitted, setSubmitted] = useState();
    const [reviewSlot, setReviewSlot] = useState();
    const [userData, setUserData] = useState();
    useEffect(() => {
        let userData = secureLocalStorage.getItem("userData");
        setUserData(JSON.parse(userData));
        StaffsStore.getAppliedShiftData();
    }, []);

    useEffect(() => {
        let pendingShifts = toJS(StaffsStore.data.appliedShiftList);
        const mergedShifts = pendingShifts?.contracts?.concat(pendingShifts?.data);
        setAppliedShiftList(mergedShifts);
        setComingShiftList(mergedShifts?.filter(x => x?.status === 2))
    }, [StaffsStore.data.appliedShiftList]);


    useEffect(() => {
        setDegree(HomeStore.data.degreeList)
    }, [HomeStore.data.degreeList]);

    const responsiveOptions = isMobile ? {
        details: {
            display: $.fn.dataTable.Responsive.display.childRowImmediate,
            target: '',
            type: 'none'
        }
    } : {};

    useEffect(() => {
        $.fn.dataTable.ext.errMode = 'none';
        const table = $(`#${tableName}`).DataTable(
            {
                data: appliedShiftList,
                order: [], // Disable initial sorting
                // scrollX: true,
                responsive: responsiveOptions,
                columns: [
                    {
                        title: "Order ID", data: 'id', "render": function (data, type, row, meta) {
                            return "#" + data
                        }
                    },
                    {
                        title: "Date", "render": function (data, type, full, meta) {
                            return full?.category === 'TC' ? '<strong><span class="link-info">' + moment(full.start_date, 'YYYY-MM-DD').format('MM/DD/YYYY') + " To " + moment(full.end_date, 'YYYY-MM-DD').format('MM/DD/YYYY') + '</span></strong>' : '<strong><span class="link-info">' + full?.slot_date + '</strong></span>';
                        }
                    },
                    {
                        title: "Time", "render": function (data, type, row, meta) {
                            return (row?.category === 'TC' ? '<strong><span class="link-info">' + moment(row?.start_time, "HH:mm").format("hh:mm A") + " To " + moment(row?.end_time, "HH:mm").format("hh:mm A") : '<strong><span class="link-info">' + moment(row?.slot_start_time, "HH:mm").format("hh:mm A") + " To " + moment(row?.slot_end_time, "HH:mm").format("hh:mm A") + '</span></strong>') || ''
                        }

                    },
                    {
                        title: "Type & Category", "render": function (data, type, row, meta) {
                            return (row?.category === 'TC' ? row?.shift_type : row?.slot_type) + " & " + (row?.category === 'TC' ? "Traveler" : global.config.shiftCaregory?.find(x => x.value === row?.request?.category)?.label);
                        }
                    },

                    {
                        title: "Facility", "render": function (data, type, row, meta) {
                            return row?.request?.facility?.organization_name;
                        }

                    },
                    {
                        title: "Contact Person", "render": function (data, type, row, meta) {
                            return row?.category === 'TC' ? `${row?.user?.first_name} ${row?.user?.last_name}` : `${row?.request?.user?.first_name} ${row?.request?.user?.last_name}`;
                        }

                    },
                    {
                        title: "Address", "render": function (data, type, row, meta) {
                            return (row?.request?.facility?.address + ", " + row?.request?.facility?.city + ", " + row?.request?.facility?.state) || "-"
                        }
                    },

                                 
                    {
                        title: "Client Comments", data: 'remark', "render": function (data, type, row, meta) {
                            const label = (row?.category === 'TC' ? `
                            <div style="text-align: left; border: 1px solid #ccc; padding: 2px; display: grid; grid-template-columns: max-content auto;">
                                <strong style="border: 1px solid #ccc; padding: 2px;">Shift Type:</strong> 
                                <span style="border: 1px solid #ccc; padding: 2px;">${row?.shift_type}</span>
                                
                                <strong style="border: 1px solid #ccc; padding: 2px;">No Of Weeks:</strong> 
                                <span style="border: 1px solid #ccc; padding: 2px;">${row?.no_of_weeks}</span>
                                
                                <strong style="border: 1px solid #ccc; padding: 2px;">Hours/Week:</strong> 
                                <span style="border: 1px solid #ccc; padding: 2px;">${row?.hours_per_week}</span>
                                
                                <strong style="border: 1px solid #ccc; padding: 2px;">Pay Details:</strong> 
                                <span style="border: 1px solid #ccc; padding: 2px;">${row?.pay_details}</span>
                                
                                <strong style="border: 1px solid #ccc; padding: 2px;">Qualification:</strong> 
                                <span style="border: 1px solid #ccc; padding: 2px;">${row?.qualifications}</span>
                            </div>
                        `: data) || '-';
                            return label;
                        }
                    },
                    {
                        title: "Status", data: 'status'
                    },
                    {
                        title: "Notify"
                    },
                ],
                columnDefs: [
                    {
                        responsivePriority: 1,
                        targets: 1,
                    },
                    {
                        responsivePriority: 2,
                        targets: 8,
                        createdCell: (td, cellData, rowData, row, col) =>
                            ReactDOM.render(
                                <div>
                                    <span>
                                        {
                                            rowData?.request?.status !== 3 ?
                                                (rowData?.category == "TC" && rowData?.status === 1 && rowData?.staff_response === 0 ? <strong><span className='text-info'>Pending By You</span></strong> :
                                                    (rowData?.status === 1 && rowData?.request?.staff_id) ? <strong><span className='text-info'>Pending By You</span></strong> : (rowData?.status === 1 && rowData?.staff_id == userData?.id) ? <span className="text-warning">Pending</span> :
                                                        (rowData?.staff_id && (rowData?.staff_id != userData?.id) && rowData?.status === 2) ? <strong><span className='text-warning'>Assigned To Other</span></strong> : rowData?.status === 2 || (rowData?.status === 1 && rowData?.nominator_id === userData?.id) || (rowData?.status === 5 && rowData?.category === 'TC') ? <span className='text-success'>Upcoming</span> :
                                                            rowData?.status === 3 ? <span className='text-danger'>Rejected</span> :
                                                                rowData?.status === 4 ? <span className='text-warning'><strong>Extention Pending</strong></span> :
                                                                    (rowData?.status === 5 && rowData?.category !== 'TC') ? <span className='text-danger'>Cancelled By You</span> :
                                                                        rowData?.status === 6 ? <span className='text-danger'>Cancelled By Facility</span> :
                                                                            rowData?.status === 7 ? <span className='text-muted'>Closed</span> : <span className='text-muted'>No Update</span>) : "Closed"

                                        }
                                    </span>
                                    {(rowData?.extention_request || rowData?.staff_response === 0) && <div>
                                        <CButton onClick={() => {
                                            onSlotApprovalOpen(rowData)
                                        }} size="sm" className="w-100 mt-1" color="warning" >
                                            Approve/Reject Shifts
                                        </CButton>
                                    </div>}
                                </div>
                                , td)
                    },
                    {
                        targets: 9,
                        responsivePriority: 3,
                        createdCell: (td, cellData, rowData, row, col) =>
                            ReactDOM.render(
                                <>
                                    <div>
                                        {(rowData?.status === 2 && rowData?.staff_id == userData?.id) || (rowData?.status === 1 && rowData?.nominator_id == userData?.id) && rowData?.request?.status !== 3 ? rowData?.notified_at ? <span className='text-success'>Notified</span> :
                                            <CButton onClick={() => {
                                                onGetNotify(rowData)
                                            }} size="sm" className="w-100" variant='outline' color="warning" >
                                                Departure Alert <i className="fas fa-bullhorn"></i>
                                            </CButton>
                                            : rowData?.request?.status === 3 && !rowData?.caregiver_experience ? <CButton onClick={() => {
                                                onFeedback(rowData)
                                            }} size="sm" className="w-100" variant='outline' color="info" >
                                                Rate This Client <i className="far fa-edit"></i>
                                            </CButton> : "Not available"}
                                    </div >
                                    <div className='mt-1 mb-2'>   {(rowData?.status === 2 && rowData?.staff_id == userData?.id) || (rowData?.status === 1 && rowData?.nominator_id == userData?.id) &&
                                        <CButton onClick={() => {
                                            onDownloadICS(rowData)
                                        }} size="sm" className="w-100" variant='outline' color="success" >
                                            Download (.ics) <i className="far fa-calendar-plus"></i>
                                        </CButton>
                                    }</div>
                                    {(rowData?.status === 2 && !rowData?.nominator_id) ? <div className='mt-1'>
                                        <CButton onClick={() => {
                                            reopenShifts(rowData?.id)
                                        }} size="sm" className="w-100" variant='outline' color="info" >
                                            Open For Others <i className="fas fa-user-plus"></i>
                                        </CButton>
                                    </div> : (rowData?.status === 1 && rowData?.nominator_id === userData?.id) ? <span className='text-danger mt-2 ms-2'> Open For Others <i className="fas fa-user-clock"></i></span> : ""}
                                </>
                                , td),
                    }
                ],

                destroy: true,  // I think some clean up is happening here
                searching: true,
                "language": {
                    search: '',
                    emptyTable: "No shift found"
                },

                initComplete: function () {
                    // Apply the search
                    this.api()
                        .columns([0, 3, 4, 5, 6, 7])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><input class="form-control " style="width:100%;margin-top:5px;background-color:white"" type="text" placeholder="Filter" />')
                                .appendTo($(column.header()))

                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $('input', this.header()).on('keyup change clear', function () {
                                if (column.search() !== this.value) {
                                    column.search(this.value).draw();
                                }
                            });
                        });

                    this.api()
                        .columns([1])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><input type="date" class="form-control" style="width:100%;margin-top:5px"s type="text" placeholder="" />')
                                .appendTo($(column.header()))
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $('input', this.header()).on('keyup change clear', function () {
                                if (!this.value) {
                                    $(`#${tableName}`).DataTable().column([1]).search('').draw();
                                }
                                if (column.search() !== this.value) {
                                    column.search(moment(this.value).format('MM/DD/YYYY')).draw();
                                }
                            });
                        });
                    this.api()
                        .columns([2])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><input type="time" class="form-control" style="width:100%;margin-top:5px"s type="text" placeholder="" />')
                                .appendTo($(column.header()))

                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $('input', this.header()).on('keyup change clear', function () {
                                if (!moment(this.value, "HH:mm").format("hh:mm A")) {
                                    $(`#${tableName}`).DataTable().column([2]).search('').draw();
                                }
                                if (column.search() !== moment(this.value, "HH:mm").format("hh:mm A")) {
                                    column.search(moment(this.value, "HH:mm").format("hh:mm A")).draw();
                                }
                            });
                        });
                    this.api()
                        .columns([8])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><select class="form-control" placeholder="search" style="width:100%;margin-top:5px;background-color:white""><option value="">All</option></select>')
                                .appendTo($(column.header()))
                                .on('change', function () {
                                    var val = $.fn.dataTable.util.escapeRegex($(this).val());
                                    column.search(val ? '^' + val + '$' : '', true, false).draw();
                                });
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            [{ "name": "Pending", "value": 1 }, { "name": "Confirmed", "value": 2 }, { "name": "Rejected", "value": 3 }, { "name": "Extention Pending", "value": 4 }, { "name": "Cancelled By You", "value": 5 }, { "name": "Cancelled By Facility", "value": 6 }, { "name": "Closed", "value": 7 }].forEach(function (option) {
                                var selected = '';
                                select.append('<option value="' + option.value + '" ' + selected + '>' + option?.name + '</option>');
                            });
                        });

                }
            }
        )
        if (location?.state?.organization_name) {
            table.column(3).search(location?.state?.organization_name).draw();
        }
        // Extra step to do extra clean-up.
        return function () {
            table.destroy()
        }
    }, [appliedShiftList, degree])

    const onGetNotify = (item) => {
        let param = {
            "slot_id": item?.id
        }
        StaffsStore.getNotify(param, navigationCallBack)
    }
    const reopenShifts = (id) => {
        swal({
            title: "Are you sure you want to request replacement on this shift?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    StaffsStore.reopenShifts({ "ids": [id] }, navigationCallBackReopen)
                }
            });
    }
    const navigationCallBackReopen = () => {
        StaffsStore.getAppliedShiftData();
        swal("Success!", "This shift is open for others to apply for, but if no one else picks up the shift, you have to perform this shift as scheduled!", "success", {
            button: "Close",
        });
    }
    const navigationCallBack = () => {
        StaffsStore.getAppliedShiftData();
        swal("Success!", "The client has been notified that you are in route", "success", {
            button: "Close",
        });
    }

    useEffect(() => {
        if (rating && reviewSlot) {
            let param = {
                request_id: reviewSlot?.request?.id,
                rating: rating
            }
            StaffsStore.saveFeedback(param, navigationCallBackReview);
        }
    }, [submitted]);

    const navigationCallBackReview = () => {
        StaffsStore.getAppliedShiftData();
        swal("Thank you for your review!", `We received ${rating}/5 rating from you.`, "success").then(() => {
            // setFeedback()
            setRating()
            setReviewSlot()
            setSubmitted(false)
        })
    }

    const onPick = (value) => {
        setRating(value);
    }

    const onFeedback = async (slot) => {
        setSubmitted(false)
        setRating()
        // setFeedback()
        setReviewSlot(slot)
        swal({
            title: "Feedback/Rating",
            buttons: [true, "Submit"],
            content: (
                <div className='row' style={{ textAlign: 'start' }}>
                    <div className="">
                        <label className='mb-2'>Please rate your experience for {slot?.request?.user?.organization_name} & {slot?.request?.user?.first_name}.</label>
                        <br />
                        <div className="wrapper">
                            <input type="radio" onClick={() => onPick(1)} value="1" name="rate" id="star-1" style={{ display: "none" }} />
                            <input type="radio" onClick={() => onPick(2)} value="2" name="rate" id="star-2" style={{ display: "none" }} />
                            <input type="radio" onClick={() => onPick(3)} value="3" name="rate" id="star-3" style={{ display: "none" }} />
                            <input type="radio" onClick={() => onPick(4)} value="4" name="rate" id="star-4" style={{ display: "none" }} />
                            <input type="radio" onClick={() => onPick(5)} value="5" name="rate" id="star-5" style={{ display: "none" }} />
                            <div className="content">
                                <div className="outer">
                                    <div className="emojis">
                                        <li className="slideImg"><img src={Images?.think} style={{
                                            width: "90%",
                                            height: "100%",
                                            paddingTop: 10
                                        }} alt="" /></li>
                                        <li ><img src={Images?.hate} alt="" /></li>
                                        <li><img src={Images?.unlike} alt="" /></li>
                                        <li><img src={Images?.like} alt="" /></li>
                                        <li><img src={Images?.awesome} alt="" /></li>
                                        <li><img src={Images?.love} alt="" /></li>
                                    </div>
                                </div>
                                <div className="stars">
                                    <label htmlFor="star-1" className="star-1 fas fa-star"></label>
                                    <label htmlFor="star-2" className="star-2 fas fa-star"></label>
                                    <label htmlFor="star-3" className="star-3 fas fa-star"></label>
                                    <label htmlFor="star-4" className="star-4 fas fa-star"></label>
                                    <label htmlFor="star-5" className="star-5 fas fa-star"></label>
                                </div>
                            </div>
                            <div className="footer">
                                <span className="text"></span>
                            </div>
                        </div>
                    </div>
                    {/* <div className='mt-5'>
                        <label className='mb-3'>Share your thoughts about {slot?.user?.organization_name}.</label>
                        <div className="col-12">
                            <div className="form-floating">
                                <textarea className="form-control"
                                    placeholder="Write something"
                                    name={'feedback'}
                                    onChange={handleChange}
                                    style={{ height: 100 }}></textarea>
                            </div>
                        </div>
                    </div> */}
                </div>
            )
        })
            .then((value) => {
                if (value) {
                    setSubmitted(value)
                } else {
                    setRating()
                    // setFeedback()
                    setReviewSlot()
                }
            });
    }

    const onDownloadICS = (slot) => {
        // Ensure moment.js is properly imported and available
        const moment = require('moment');

        const slotDate = moment(slot.slot_date, 'MM/DD/YYYY');
        const slotStartTime = moment(slot.slot_start_time, 'HH:mm');
        const slotEndTime = moment(slot.slot_end_time, 'HH:mm');

        const event = {
            start: [
                slotDate.year(),
                slotDate.month() + 1,
                slotDate.date(),
                slotStartTime.hour(),
                slotStartTime.minute(),
            ],
            end: [
                slotDate.year(),
                slotDate.month() + 1,
                slotDate.date(),
                slotEndTime.hour(),
                slotEndTime.minute(),
            ],
            title: `Your Upcoming Shift - ${slot?.shift_id}`,
            description: 'You will start your shift at this time and be ready for work.',
            organizer: {
                name: slot?.request?.user?.organization_name,
                email: slot?.request?.user?.email,
            },
            location: `${slot?.request?.user?.organization_name}, ${slot?.request?.address},${slot?.request?.city},${slot?.request?.state},${slot?.request?.zipcode}`,
            url: 'https://staffers.us',
            status: 'CONFIRMED',
            attendees: [
                {
                    name: slot?.request?.user?.organization_name,
                    email: slot?.request?.user?.email,
                    rsvp: true,
                    partstat: 'ACCEPTED',
                    cutype: 'INDIVIDUAL',
                },
            ],
            alarms: [
                {
                    action: 'display',
                    trigger: { minutes: 30 },
                    description: 'Shift Reminder',
                },
            ],
        };

        createEvent(event, (error, value) => {
            if (!error) {
                const blob = new Blob([value], { type: 'text/calendar;charset=utf-8' });
                saveAs(blob, `Shift-${slot?.id}.ics`);
            } else {
                console.log("Error creating event:", error);
            }
        });
    };

    const onDownloadAllICS = () => {
        createICS(upcComingShiftList)
    }
    const createICS = (slots) => {
        const events = slots?.map((slot) => {
            // Validate the slot data
            if (!slot || !slot.slot_date || !slot.slot_start_time || !slot.slot_end_time) {
                return null; // Handle missing or invalid data
            }

            const slotDate = moment(slot.slot_date, 'MM/DD/YYYY');
            const slotStartTime = moment(slot.slot_start_time, 'HH:mm');
            const slotEndTime = moment(slot.slot_end_time, 'HH:mm');

            // Construct the location string properly
            const location = `${slot?.request?.user?.organization_name}, ${slot?.request?.address}, ${slot?.request?.city}, ${slot?.request?.state}, ${slot?.request?.zipcode}`;

            return {
                start: [
                    slotDate.year(),
                    slotDate.month() + 1,
                    slotDate.date(),
                    slotStartTime.hour(),
                    slotStartTime.minute(),
                ],
                end: [
                    slotDate.year(),
                    slotDate.month() + 1,
                    slotDate.date(),
                    slotEndTime.hour(),
                    slotEndTime.minute(),
                ],
                title: `Your Upcoming Shift - ${slot?.shift_id}`,
                description: 'You will start your shift at this time and be ready for work.',
                organizer: {
                    name: slot?.request?.user?.organization_name,
                    email: slot?.request?.user?.email,
                },
                location: location,
                url: 'https://staffers.us',
                status: 'CONFIRMED',
                attendees: [
                    {
                        name: slot?.request?.user?.organization_name,
                        email: slot?.request?.user?.email,
                        rsvp: true,
                        partstat: 'ACCEPTED',
                        cutype: 'INDIVIDUAL',
                    },
                ],
                alarms: [
                    {
                        action: 'display',
                        trigger: { minutes: 15 }, // Adjust as needed
                        description: 'Shift Reminder',
                    },
                ],
            };
        }).filter(Boolean); // Remove null values


        if (events?.length == 0) {
            toast.warn("There are no upcoming slots.");
            return
        }
        // Create an array to hold the events in ICS format
        const icsEvents = [];

        for (const event of events) {
            const { error, value } = createEvent(event);
            if (error) {
                console.error('Error generating ICS file:', error);
                return;
            }
            icsEvents.push(value);
        }

        // Join the ICS event strings
        const icsContent = icsEvents.join('\r\n');
        const blob = new Blob([icsContent], { type: 'text/calendar;charset=utf-8' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Your_all_upcoming_shifts.ics';
        a.click();
        window.URL.revokeObjectURL(url);
        toast.success("ICS was successfully downloaded.");
    }


    return (
        <React.Fragment>
            <Header />
            <Helmet>
                <title>Staffers LLC | My Shifts</title>
            </Helmet>
            <>
                <div className="container-fluid callback-small">
                    <div className="container-fluid mt-5 pt-5">
                        <div className="container mt-lg-5 mt-5 mb-3">
                            <h5 className="display-5 animated slideInDown login-head">My Shifts</h5>
                            <div className="row justify-content-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="row bg-white" style={{ paddingTop: 20 }}>
                            {upcComingShiftList?.length > 0 && <ul className="nav nav-tabs d-flex justify-content-center">
                                <li className="nav-item">
                                    <CButton onClick={() => {
                                        onDownloadAllICS()
                                    }} size="sm" className="w-100" variant='outline' color="success">
                                        Download (.ics) <i className="far fa-calendar-plus"></i>
                                    </CButton>
                                </li>
                            </ul>}
                            <div className="table-wrapper table-responsive">
                                <table className="table dt-responsive table-hover nowrap" style={{ width: "100%" }} id={tableName} ref={tableRef}></table>
                            </div>
                        </div>
                    </div>

                </div>
            </>

            <Spinner isLoading={StaffsStore.loading} />
        </React.Fragment >
    )
})

export default AppliedShifts;