/* eslint-disable */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react"
import HeaderPage from "../layout/HeaderPage";
import DrawerMenuPage from "../layout/DrawerMenuPage";
import AdminStore from "@store/AdminStore";
import Spinner from "@components/Spinner";
import { Helmet } from "react-helmet";
import DatePicker from "react-multi-date-picker"
import weekends from "react-multi-date-picker/plugins/highlight_weekends"
import { toJS } from "mobx";
import { toast } from "react-toastify";
import moment from "moment";
import StaffsStore from "@store/StaffsStore";
import { colourStyles } from '@utils/customStyle/style';
import Select, { components } from 'react-select';
import { formatDuration } from '@utils/constant/constant';

var today = new Date();
const WeeklyReport = observer(() => {
    const [dates, setDates] = useState();
    const [weeklyData, setWeeklyData] = useState([]);
    const [regions, setRegions] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState();
    useEffect(() => {
        StaffsStore.getRegionsList();
        const urlParams = new URLSearchParams(window.location.search);
        const start_date = urlParams.get('start_date');
        const end_date = urlParams.get('end_date');
        if (start_date && end_date) {
            // Construct the dynamic URL
            AdminStore.getWeeklyData(constructURL(start_date, end_date, selectedRegion?.ID))
        } else {
            // Calculate the start and end dates for the past 7 days
            var startDate = moment(today).subtract(6, 'days').format('MM/DD/YYYY');
            var endDate = moment(today).format('MM/DD/YYYY');
            AdminStore.getWeeklyData(constructURL(startDate, endDate, selectedRegion?.ID))
            setDates([startDate, endDate])
        }
    }, []);


    useEffect(() => {
        setWeeklyData(toJS(AdminStore.data.weeklyData))
    }, [AdminStore.data.weeklyData]);
    useEffect(() => {
        setRegions(StaffsStore?.data?.regionsList)
    }, [StaffsStore?.data?.regionsList]);

    const getReport = () => {
        if (dates?.length == 2) {
            const [startDate, endDate] = dates;
            if (typeof startDate === 'object' && typeof endDate === 'object') {
                AdminStore.getWeeklyData(constructURL(startDate?.format(), endDate?.format(), selectedRegion?.ID))
            } else {
                AdminStore.getWeeklyData(constructURL(startDate, endDate, selectedRegion?.ID))
            }
        } else {
            toast.warn("Please select start end dates!")
        }

    }
    const clearReport = () => {
        setDates();
        setWeeklyData([])
        setSelectedRegion(null)
    }

    const handleChange = (e) => {
        setSelectedRegion(e)
    }

    const onClickDetails = (report_type,filter) => {
        var param;
        const [startDate, endDate] = dates;
        if (typeof startDate === 'object' && typeof endDate === 'object') {
            param = {
                report_type: report_type,
                startDate: startDate?.format(),
                endDate: endDate?.format(),
                selectedRegionID: selectedRegion?.ID,
                filter:filter
            }
        } else {
            param = {
                report_type: report_type,
                startDate: startDate,
                endDate: endDate,
                selectedRegionID: selectedRegion?.ID,
                filter:filter
            }
        }

        const url = `/admin/report-view?data=${encodeURIComponent(JSON.stringify(param))}`;
        window.open(url, '_blank');
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>Staffers LLC | Weekly Report</title>
            </Helmet>
            {/* <body> */}
            <div className="container-scroller">
                <HeaderPage />
                <div className="container-fluid page-body-wrapper">
                    <DrawerMenuPage />
                    <div className="main-panel" style={{ right: 0, position: "absolute" }}>
                        <div className="content-wrapper p-3 bg-white ">
                            <h3 className="page-title">
                                <span className="page-title-icon linear text-white me-2">
                                    <i className="fas fa-calendar-week"></i>
                                </span> Weekly Report
                            </h3>
                        </div>
                        <div className="main-content">
                            <div className="header bg-gradient-primary pb-8 pt-2 pt-md-4">
                                <div className="container-fluid">
                                    <div className="row align-items-center mb-3">
                                        <div className="col-lg-3 col-12 mb-md-3 mb-lg-0 mb-3">
                                            <DatePicker
                                                calendarPosition="top-left"
                                                placeholder="Select Dates"
                                                format="MM/DD/YYYY"
                                                inputClass="did-floating-dropdown"
                                                range
                                                value={dates}
                                                onChange={(dateObjects) => {
                                                    // Update state with the selected dates
                                                    setDates(dateObjects);
                                                }}
                                                plugins={[
                                                    weekends()
                                                ]}
                                            />
                                        </div>
                                        <div className="col-lg-3">
                                            <Select
                                                placeholder={"Select Region"}
                                                options={regions}
                                                onChange={handleChange}
                                                value={selectedRegion}
                                                styles={colourStyles}
                                                isMulti={false}
                                                closeMenuOnSelect={true}
                                                hideSelectedOptions={false}
                                                getOptionLabel={(option) => `${option.Name}`}
                                                getOptionValue={(option) => option.ID}
                                                isClearable
                                            />
                                        </div>
                                        <div className="col-lg-1 mt-lg-0 mt-3 d-flex justify-content-center justify-content-lg-start">
                                            <button className="btn btn-primary" onClick={getReport}>View</button>
                                            <button className="btn btn-danger ms-2" onClick={clearReport}>Clear</button>
                                        </div>
                                    </div>
                                    <div className="header-body pb-5">
                                        <div className="row">
                                            <div className="col-lg-4 equal-height-cards">
                                                <div className="card card-stats mb-4 mb-xl-0">
                                                    <div className="card-body ">
                                                        <div className="row">
                                                            <div className="col">
                                                                <h5 onClick={() => onClickDetails('active_caregiver')} data-toggle="tooltip" data-placement="bottom" title="View Full Report" className="pointer-link card-title text-uppercase text-info mb-0">Active Caregivers</h5>
                                                                <span className="h2 font-weight-bold mb-0">{weeklyData?.active_caregivers}</span>
                                                            </div>
                                                            <div className="col-auto">
                                                                <i className="fas fa-user-tie text-primary fs-3"></i>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-3">
                                                            <div className="col">
                                                                <h5 onClick={() => onClickDetails('active_facility_user')} data-placement="bottom" title="View Full Report" className="pointer-link card-title text-uppercase text-info mb-0">Active Facility Users</h5>
                                                                <span className="h2 font-weight-bold mb-0">{weeklyData?.active_employers}</span>
                                                            </div>
                                                            <div className="col-auto">
                                                                <i className="fas fa-users text-primary fs-3 "></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4  equal-height-cards">
                                                <div className="card card-stats mb-4 mb-xl-0">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col">
                                                                <h5 onClick={() => onClickDetails('most_shifts_by_facility')} data-placement="bottom" title="View Full Report" className="pointer-link card-title text-uppercase text-info mb-0">Most Shift Created By Facility</h5>
                                                                <span className="h2 font-weight-bold mb-0">{weeklyData?.top_facility?.count}</span>
                                                            </div>
                                                            <div className="col-auto">
                                                                <i className="fas fa-crown text-primary fs-3"></i>
                                                            </div>
                                                        </div>
                                                        {weeklyData?.top_facility?.name && <p className="mt-3 mb-0 text-muted text-sm">
                                                            <span className="text-success mr-2"><i className="fas fa-crown"></i></span>
                                                            <span className="text-nowrap"> {weeklyData?.top_facility?.name}</span>
                                                        </p>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 equal-height-cards">
                                                <div className="card card-stats mb-4 mb-xl-0">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col">
                                                                <h5 onClick={() => onClickDetails('most_shifts_by_caregiver')} className="pointer-link card-title text-uppercase text-info mb-0">Most Shift Picked By Caregiver</h5>
                                                                <span className="h2 font-weight-bold mb-0">{weeklyData?.top_picker?.count}</span>
                                                            </div>
                                                            <div className="col-auto">
                                                                <i className="fas fa-crown text-primary fs-3"></i>
                                                            </div>
                                                        </div>
                                                        {weeklyData?.top_picker?.name && <p className="mt-3 mb-0 text-muted text-sm">
                                                            <span className="text-success mr-2"><i className="fas fa-crown"></i></span>
                                                            <span className="text-nowrap"> {weeklyData?.top_picker?.name}</span>
                                                        </p>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row pt-4">
                                            <div className="col-lg-4 equal-height-cards">
                                                <div className="card card-stats mb-4 mb-xl-0">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col">
                                                                <h5 onClick={() => onClickDetails('shift_posted')} data-placement="bottom" title="View Full Report" className="pointer-link card-title text-uppercase text-info mb-0">Shifts Posted</h5>
                                                                <span className="h2 font-weight-bold mb-0">{weeklyData.shifts_posted}</span>
                                                            </div>
                                                            <div className="col-auto">
                                                                <i className="far fa-list-alt text-primary fs-3"></i>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <p className="mb-0 mt-2 text-muted text-sm">
                                                                    <strong className="text-nowrap text-primary text-sm pointer-link" onClick={() => onClickDetails('shift_posted',1)} data-placement="bottom" title="View Full Report" ><i className="fas fa-business-time"></i> Shifts Open</strong>
                                                                    <span className="text-nowrap text-success "> <b>{weeklyData?.shifts_opened || "0"} </b></span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <p className="mt-2 mb-0 text-primary text-sm">
                                                                    <strong className="text-nowrap"><i className="fas fa-business-time"></i> Average Fill Time</strong>
                                                                    <span className="text-success mr-2">
                                                                        &nbsp;<b> <i className="far fa-clock"></i> {weeklyData && weeklyData.avg_fill_time ? formatDuration(weeklyData.avg_fill_time) : 'N/A'}</b>
                                                                    </span>
                                                                </p>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 equal-height-cards">
                                                <div className="card card-stats mb-4 mb-xl-0">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col">
                                                                <h5 onClick={() => onClickDetails('shift_filled')} data-placement="bottom" title="View Full Report" className="pointer-link card-title text-uppercase text-info mb-0">Shifts Filled</h5>
                                                                <span className="h2 font-weight-bold mb-0">{weeklyData?.shifts_picked}</span>
                                                            </div>
                                                            <div className="col-auto">
                                                                <i className="fas fa-tasks text-primary fs-3 "></i>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-12 mb-2">
                                                                <p className="mt-2 mb-0 text-muted text-sm">
                                                                    <strong className="text-nowrap text-primary text-sm pointer-link" onClick={() => onClickDetails('shift_filled',2)} data-placement="bottom" title="View Full Report"><i className="fas fa-calendar-check"></i> Shifts Confirmed</strong>
                                                                    <span className="text-nowrap text-success "> <b>{weeklyData?.shifts_confirmed || "0"} </b></span>
                                                                </p>
                                                            </div>
                                                            <div className="col-sm-12 mb-2">
                                                                <p className="mb-0 text-muted text-sm">
                                                                    <strong className="text-nowrap text-primary text-sm pointer-link" onClick={() => onClickDetails('shift_filled',7)} data-placement="bottom" title="View Full Report"><i className="fas fa-clipboard-check"></i> Shifts Closed</strong>
                                                                    <span className="text-nowrap text-success "> <b>{weeklyData?.shifts_closed || "0"} </b></span>
                                                                </p>
                                                            </div>
                                                            <div className="col-sm-12 mb-2">
                                                                <p className="mb-0 text-primary text-sm">
                                                                    <strong className="text-nowrap"><i className="fas fa-fas fa-chart-line"></i> Shifts Fill Rate</strong>
                                                                    <span className="text-success">
                                                                        <b>  {weeklyData?.shifts_fill_rate ? weeklyData.shifts_fill_rate?.toFixed(2) + "%" : 0}</b>
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 equal-height-cards">
                                                <div className="card card-stats mb-4 mb-xl-0">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col">
                                                                <h5 onClick={() => onClickDetails('shift_cancelled')} data-placement="bottom" title="View Full Report" className="pointer-link card-title text-uppercase text-info mb-0">Shifts Cancelled</h5>
                                                                <span className="h2 font-weight-bold mb-0">{weeklyData?.shifts_cancelled_caregiver + weeklyData?.shifts_cancelled_facility || "0"}</span>
                                                            </div>
                                                            <div className="col-auto">
                                                                <i className="fas fa-times text-primary fs-3"></i>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-12 mb-2">
                                                                <p className="mb-0 text-muted text-sm">
                                                                    <strong className="text-nowrap text-primary text-sm pointer-link" onClick={() => onClickDetails('shift_cancelled',5)} data-placement="bottom" title="View Full Report"><i className="fas fa-user-tie"></i> Cancelled By Caregiver</strong>
                                                                    <span className="text-nowrap text-success "> <b>{weeklyData?.shifts_cancelled_caregiver || "0"} </b></span>
                                                                </p>
                                                            </div>
                                                            <div className="col-sm-12 mb-2">
                                                                <p className="mb-0 text-primary text-sm" >
                                                                    <strong className="text-nowrap pointer-link" onClick={() => onClickDetails('shift_cancelled',6)} data-placement="bottom" title="View Full Report"><i className="fas fa-user-friends "  ></i> Cancelled By Facility</strong>
                                                                    <span className="text-success">
                                                                        <span className="text-nowrap text-success "> <b>{weeklyData?.shifts_cancelled_facility || "0"} </b></span>
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <!-- main-panel ends --> */}
                </div>
                {/* <!-- page-body-wrapper ends --> */}
            </div>
            <Spinner isLoading={AdminStore.loading} />

        </React.Fragment >
    );
})

export default WeeklyReport;

function constructURL(startDate, endDate, selectedRegion) {
    var url = '/admin/weeklyReport' + '?';

    // Add start_date parameter if available
    if (startDate) {
        url += 'start_date=' + startDate + '&';
    }

    // Add end_date parameter if available
    if (endDate) {
        url += 'end_date=' + endDate + '&';
    }

    // Add region_id parameter if available
    if (selectedRegion) {
        url += 'region_id=' + selectedRegion;
    }

    // Remove trailing '&' if it exists
    if (url.endsWith('&')) {
        url = url.slice(0, -1);
    }

    return url;
}