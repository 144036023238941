import { configure, makeAutoObservable } from "mobx"
import { toast } from "react-toastify";
import { axiosInstanceInt, setAuthTokenIntAdmin } from "../api/axiosInstance"
class JobProfileStore {
  loading = false
  errors = {}
  constructor() {
    makeAutoObservable(this);
    configure({
      useProxies: "never"
    })
  }
  toggleLoading = (toggle) => {
    this.loading = toggle
  }

  async addCaregiver(data, navigationCallBackAddCaregiver) {
    // this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('/admin/addCaregiver', data)
      if (response?.status === 200) {
        navigationCallBackAddCaregiver(response?.data)
      } else {
        console.log("Failed...");
      }
    }
    catch (err) {
      toast.error("Please try again!")
      this.toggleLoading(false);
    }
    finally {
      // this.toggleLoading(false);
    }
  }
  async addSpecialty(data, navigationCallBackAddSpecialty) {
    // this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('/admin/addSpeciality', data)
      if (response?.status === 200) {
        navigationCallBackAddSpecialty(response?.data)
      } else {
        console.log("Failed...");
      }
    }
    catch (err) {
      toast.error("Please try again!")
      this.toggleLoading(false);

    }
    finally {
      // this.toggleLoading(false);
    }
  }
  async addDevices(data, navigationCallBackAddDevices) {
    // this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('/admin/addDevices', data)
      if (response?.status === 200) {
        navigationCallBackAddDevices(response?.data)
      } else {
        console.log("Failed...");
      }
    }
    catch (err) {
      this.toggleLoading(false);
      toast.error("Please try again!")
    }
    finally {
      // this.toggleLoading(false);
    }
  }
  async uploadDocuments(data,navigationCallBackAddDoc) {
    // this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('/admin/uploadDocuments', data)
      if (response.status === 200) {
        navigationCallBackAddDoc(response.data)
      } else {
        console.log("Failed...");
      }
    }
    catch (err) {
      this.toggleLoading(false);
      toast.error("Please try again!")
    }
    finally {
      // this.toggleLoading(false);
    }
  }
  async addPreferredState(data,navigationCallBackAddedPreferredState) {
    // this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('/admin/addPreferredState', data)
      if (response.status === 200) {
        navigationCallBackAddedPreferredState(response.data)
      } else {
        console.log("Failed...");
      }
    }
    catch (err) {
      this.toggleLoading(false);
      toast.error("Please try again!")
    }
    finally {
      // this.toggleLoading(false);
    }
  }

  async approveJobProfile(data, navigationCallBack) {
    setAuthTokenIntAdmin()
    // this.toggleLoading(true);
    try {
      const response = await axiosInstanceInt.post('admin/markJobProfile', data)
      // console.log("response====",response);
      if (response?.data) {
        this.toggleLoading(false);
        navigationCallBack();
      } else {
        this.toggleLoading(false);
      }
    }
    catch (err) {
      this.toggleLoading(false);
      toast.error("Please try again!")
    }
    finally {
      this.toggleLoading(false);
    }
  }
}

export default new JobProfileStore()
