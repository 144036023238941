/* eslint-disable */
import React, { useState } from "react";
import { observer } from "mobx-react"
import HeaderPage from "../layout/HeaderPage";
import DrawerMenuPage from "../layout/DrawerMenuPage";
import * as Yup from 'yup';
import { Formik } from "formik";
import './SubAdminList.css'
import Select, { components } from 'react-select';
import { useNavigate, useLocation } from 'react-router-dom';
import Spinner from "@components/Spinner";
import { InputField } from "@elements/index"
import AdminStore from "@store/AdminStore";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

var data = require('@assets/CountryCodes.json');
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const SignUpSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name Required').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
    last_name: Yup.string().required('Last Name Required').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
    contact: Yup.string().matches(phoneRegExp, 'Contact number is not valid').required('Contact number required').min(7).max(15, "Wrong contact number"),
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string().trim().min(8, "Your password must be longer than 8 characters.").test('no-spaces', 'Password must not contain spaces', (value) => !/\s/.test(value))
        .required('Required'),
    confirm_password: Yup.string().required('Confirm password required')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});
const SignUpUpdateSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name Required').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
    last_name: Yup.string().required('Last Name Required').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
    contact: Yup.string().matches(phoneRegExp, 'Contact number is not valid').required('Contact number required').min(7).max(15, "Wrong contact number"),
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string().trim().min(8, "Your password must be longer than 8 characters.").test('no-spaces', 'Password must not contain spaces', (value) => !/\s/.test(value)),
});

const colourStyles = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? 'blue' : '#75459b',
        borderRadius: 20,
        fontSize: '12px',
        boxShadow: 'none',
        padding: "2px 10px",
        '&:focus': {
            border: '1px solid blue !important'
        },
        '&:active': {
            border: '1px solid blue !important'
        },
        '&:hover': {
            border: '1px solid blue !important'
        }
    }),
    menu: base => ({
        ...base,
        fontSize: 12,
        zIndex: 9999
    }),
    menuList: (base) => ({
        ...base,
        "::-webkit-scrollbar": {
            width: "9px"
        },
        "::-webkit-scrollbar-track": {
            background: "white"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#8282C8"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        }
    })

}
const colourStylesTitle = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? 'blue' : '#75459b',
        borderRadius: 20,
        boxShadow: 'none',
        fontSize: '12px',
        padding: "2px 10px",
        '&:focus': {
            border: '1px solid blue !important'
        },
        '&:active': {
            border: '1px solid blue !important'
        },
        '&:hover': {
            border: '1px solid #8282C8 !important'
        }
    }),
    menu: base => ({
        ...base,
        fontSize: 12,
        zIndex: 9999
    }),
    menuList: (base) => ({
        ...base,
        height: "auto",

        "::-webkit-scrollbar": {
            width: "9px"
        },
        "::-webkit-scrollbar-track": {
            background: "white"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#8282C8"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        }
    })
}
const options = [{ label: 'Yes', value: '1' }, { label: 'No', value: '0' }]

const SubAdminAdd = observer(() => {
    const navigate = useNavigate();
    const params = useLocation();
    const [icon, setIcon] = useState("fa fa-fw fa-eye-slash field-icon toggle-password")
    const [passwordType, setPasswordType] = useState("password");

    const saveAdmin = (values) => {
        if (params?.state?.item?.id) {
            if (values.password != values.confirm_password) {
                toast.error("Confirm password must match");
                return false
            }
            values.id = params?.state?.item?.id;
            AdminStore.updateAdmin(values, navigationCallBack)
        } else {
            AdminStore.saveAdmin(values, navigationCallBack)
        }
    }
    const navigationCallBack = () => {
        AdminStore.getAdmin();
        navigate('/admin/admin-list');
    }

    const _changeIcon = () => {
        icon === "fa fa-fw fa-eye-slash field-icon toggle-password"
            ? (setIcon("fa fa-fw fa-eye field-icon toggle-password"), setPasswordType("text"))
            : (setIcon("fa fa-fw fa-eye-slash field-icon toggle-password"), setPasswordType("password"))
    }
    return (
        <React.Fragment>
            <Helmet>
                <title>Staffers LLC | Add Sub Admin</title>
            </Helmet>
            {/* <body> */}
            <div className="container-scroller">
                <HeaderPage />
                <div className="container-fluid page-body-wrapper">
                    <DrawerMenuPage />
                    <div className="main-panel" style={{ right: 0, position: "absolute" }}>
                        <div className="content-wrapper p-3 bg-white ">
                            <h3 className="page-title">
                                <span className="page-title-icon linear text-white me-2">
                                    <i className="fas fa-user-shield"></i>
                                </span> {params?.state?.item ? 'Edit Admin' : 'Add New Admin'}
                            </h3>

                        </div>
                        {/* <!-- partial --> */}
                        <div className=" row justify-content-center mt-4">
                            <div className="col-lg-11">
                                <Formik
                                    initialValues={{ title: params?.state?.item?.title || "Mr.", first_name: params?.state?.item?.first_name || '', last_name: params?.state?.item?.last_name || '', contact: params?.state?.item?.contact || '', email: params?.state?.item?.email || '', country_code: params?.state?.item?.country_code || '+1', is_super: params?.state?.item?.is_super || '0', password: '', confirm_password: '' }}
                                    onSubmit={values => saveAdmin(values)}
                                    enableReinitialize
                                    validationSchema={params?.state?.item ? SignUpUpdateSchema : SignUpSchema} >
                                    {
                                        ({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched
                                        }) =>
                                        (
                                            <form onSubmit={handleSubmit} className="wow fadeInUp">
                                                <div className="row g-2">
                                                    {/* <div className="col-sm-1">
                                                        <Select
                                                            onChange={(e) => setFieldValue("title", e.value)}
                                                            placeholder={''}
                                                            label={'Title'}
                                                            value={global.config.titleList.filter(({ value }) => values?.title?.includes(value)) || ''}
                                                            styles={colourStylesTitle}
                                                            isSearchable={true}
                                                            options={global.config.titleList}
                                                            defaultValue={{ label: "Mr.", value: 'Mr.' }}
                                                            components={{ Control, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                        />
                                                    </div> */}
                                                    <div className="col-sm-6 mb-3">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                            type="text"
                                                            name="first_name"
                                                            label={"First Name"}
                                                            value={values.first_name}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.first_name && touched.first_name ? (
                                                            <div className="error-message">{errors.first_name}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="col-sm-6 mb-3">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                            type="text"
                                                            name="last_name"
                                                            label={"Last Name"}
                                                            value={values.last_name}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.last_name && touched.last_name ? (
                                                            <div className="error-message">{errors.last_name}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="col-sm-6 mb-3">
                                                        <Select
                                                            onChange={(e) => setFieldValue("country_code", e.label)}
                                                            placeholder={''}
                                                            label={'Country Code'}
                                                            value={data.filter(({ label }) => values?.country_code?.includes(label)) || ''}
                                                            styles={colourStyles}
                                                            isSearchable={true}
                                                            options={data}
                                                            defaultValue={{ label: "+1", value: 'CA' }}
                                                            components={{ Control }}
                                                        />
                                                    </div>
                                                    <div className="col-sm-6 mb-3">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                            type="text"
                                                            name="contact"
                                                            label={"Contact Number"}
                                                            value={values?.contact?.replace(/[^\d]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}
                                                            maxLength={12}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.contact && touched.contact ? (
                                                            <div className="error-message">{errors.contact}</div>
                                                        ) : null}
                                                    </div>

                                                    <div className="col-sm-6 mb-3">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                            type="email"
                                                            name="email"
                                                            label={"Email"}
                                                            value={values.email}
                                                            disabled={params?.state?.item ? true : false}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.email && touched.email ? (
                                                            <div className="error-message">{errors.email}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <Select
                                                            onChange={(e) => setFieldValue("is_super", e.value)}
                                                            placeholder={''}
                                                            label={'Super Privileges?'}
                                                            value={options.filter(({ value }) => values?.is_super == value) || ''}
                                                            styles={colourStyles}
                                                            options={options}
                                                            components={{ Control }}
                                                        />
                                                    </div>
                                                    {params?.state?.item && <label className="py-3">Change password</label>}
                                                    <div className="col-sm-6">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                            name="password"
                                                            label={"Password"}
                                                            type={passwordType}
                                                            value={values.password}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        <span toggle="#password-field" class={icon} onClick={() => _changeIcon()}></span>
                                                        {errors.password ? (
                                                            <div className="error-message">{errors.password}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="col-sm-6 mb-3">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                            type="password"
                                                            name="confirm_password"
                                                            label={"Confirm Password"}
                                                            value={values.confirm_password}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.confirm_password ? (
                                                            <div className="error-message">{errors.confirm_password}</div>
                                                        ) : null}
                                                    </div>

                                                    <div className="pt-4 d-flex justify-content-center">
                                                        <button className="btn btn-primary w-25 p-2" type="submit">{params?.state?.item ? 'Update' : 'Create Admin'}</button>
                                                    </div>
                                                </div>
                                            </form>
                                        )
                                    }
                                </Formik>
                            </div>
                        </div>
                    </div>

                    {/* <!-- main-panel ends --> */}
                </div>
                {/* <!-- page-body-wrapper ends --> */}
            </div>
            {/* </body> */}
            <Spinner isLoading={AdminStore.loading} />
        </React.Fragment>
    );
})
export const Control = (props) => {
    return (
        <>
            <label className={props.hasValue || props.isFocused ? "did-floating-label did-floating-label-top did-floating-labe-astrisk" : "did-floating-label did-floating-label did-floating-labe-astrisk"} style={{ zIndex: 999 }}>{props?.selectProps?.label}</label>
            <components.Control {...props} />
        </>
    );
};

export default SubAdminAdd;