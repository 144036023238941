import React from "react";
import Slider from "react-slick";
import FeedbackCard from "./FeedbackCard";
import Skeleton from "react-loading-skeleton";
import WebsiteStore from "../../store/WebsiteStore";

const FeedbackSlider = ({ sectionData, reviewData }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="we-care-slider d-flex align-items-center justify-content-center">
      <div className="container">
        <h3 className="common-web-head mb-4 text-center">
          {"We Care" || <Skeleton width={200} />}
        </h3>
        <div className="row">
          <div className="col-md-12">
            {WebsiteStore.loading ? (
              <Skeleton height={200} count={2} />
            ) : (
              <Slider {...settings}>
                {reviewData?.map((feedbackData, index) => {
                  return (
                    <FeedbackCard
                      feedbackData={feedbackData}
                      key={feedbackData?.id || index}
                    />
                  );
                })}
              </Slider>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackSlider;
