import React from "react";
import Header from '@components/PDFHeader';
import moment from "moment";


export const HepatitisBVaccinationWiver = (props) => {
  var today = moment(new Date()).format("MM/DD/YYYY hh:mm:ss");
  return (
    <div id="form-content">
      <Header />
      <div className="agreement-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="text-center">HEPATITIS B VACCINATION WAIVER</h2>
              <span>At this time, I am declining the hepatitis B vaccination.</span>
              <span>
                I understand that by declining this vaccine, I continue to be at
                risk of acquiring hepatitis 8, a serious disease.
              </span>
              <span>
                If in the future I continue to have occupational exposure to
                blood or other potentially infectious materials, and I want to
                be vaccinated with hepatitis B vaccine, I can receive the
                vaccination series at a local healthcare clinic.
              </span>
              <br></br>
              <p className="text-center">
                Acknowledgement and Informed Consent
              </p>
              <br></br>
              <div className="mb-3 mb-4">
                <div className="checkbox-sec">
                  <input
                    type="checkbox"
                    id="demoCheckbox"
                    name="checkbox"
                    checked={props.inputValues.vaccine_declined_time} // Use 'checked' attribute
                    onChange={(e) => { props.handleInputChange('vaccine_declined_time', e.target.checked) }} // Use 'e.target.checked'
                  />
                  <label htmlFor="demoCheckbox">
                    {" "}
                    &nbsp;The vaccine is declined at this time.
                  </label>
                </div>
                <div className="checkbox-sec">
                  <input
                    type="checkbox"
                    id="demoCheckbox1"
                    name="checkbox"
                    checked={props.inputValues.hbv} // Use 'checked' attribute
                    onChange={(e) => { props.handleInputChange('hbv', e.target.checked) }} // Use 'e.target.checked'
                  />
                  <label htmlFor="demoCheckbox1">
                    {" "}
                    &nbsp;I have previously completed the HBV Series
                  </label>
                </div>
              </div>
              <div class="row justify-content-between align-items-center">
                <div class="col-6">
                  <div>
                    <p>
                      {props?.signature ? <img src={props?.signature} width={180} height={50} alt="Signature here" /> :
                        <div class="badge badge-primary text-wrap pointer" onClick={props?.onOpenModal}>
                          Click Here To Sign
                        </div>}
                    </p>
                  </div>
                  <hr />
                  <label>Employee Signature</label>
                </div>
                <div class="col-6">
                  <div>
                    <p>
                      <input type="text" class="mb-3" value={today} disabled />
                    </p>
                  </div>
                  <hr />
                  <label>Date</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};
