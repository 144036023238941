/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react';
import Header from '@components/Header';
import { Helmet } from 'react-helmet';
import swal from '@sweetalert/with-react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import UserStore from "@store/UserStore";
import HomeStore from "@store/HomeStore";
import { observer } from 'mobx-react-lite';
import secureLocalStorage from 'react-secure-storage';
import Spinner from "@components/Spinner";
import TimeRangePicker from '@wojtekmaj/react-timerange-picker';
import { CButton, CFormLabel } from "@coreui/react";
import ReactDOM from "react-dom";

const HiringRequestPage = observer(() => {
    window.onscroll = function () { };
    const [isOpen, setIsOpen] = useState(false);
    const [rating, setRating] = useState();
    const [feedback, setFeedback] = useState("");
    const [hiringList, setHiringList] = useState([]);
    const [isAuthenticated, setAuthenticated] = useState(null);

    const [hiringData, setHiringData] = useState();
    const [staffData, setStaffData] = useState();
    const [degreeData, setDegreeData] = useState();
    const [submitted, setSubmitted] = useState();

    const tableRef = useRef()
    const tableName = "table1"

    useEffect(() => {
        let token = secureLocalStorage.getItem("token");
        setAuthenticated(token);
        UserStore.getHiringingRequest();
    }, [])

    useEffect(() => {
        setHiringList(toJS(UserStore?.data?.hiringRequestList))
    }, [UserStore?.data?.hiringRequestList]);

    useEffect(() => {
        $.fn.dataTable.ext.errMode = 'none';
        $($.fn.dataTable.tables(true)).DataTable()
            .columns.adjust();
        const table = $(`#${tableName}`).DataTable(
            {
                data: hiringList,
                order: [], // Disable initial sorting
                columns: [
                    {
                        title: "Sr", data: 'id', width: 60, "render": function (data, type, full, meta) {
                            return meta.row + 1;
                        }
                    },
                    {
                        title: "Staff Name", data: "staff_name"
                    },
                    {
                        title: "Job Type", width: 100, data: 'job_type', "render": function (data, type, row, meta) {
                            return global.config.jobType?.find(job => job.value == data)?.name;
                        }
                    },
                    {
                        title: "State", data: "state"
                    },
                    {
                        title: "City", data: "city"
                    },
                    {
                        title: "Extention Times", data: "extention_times"
                    },
                    {
                        title: "Status", width: 100, data: 'status', "render": function (data, type, row, meta) {

                            return global.config.status?.find(stat => stat.value == data)?.name;
                        }
                    },
                    {
                        title: "Action"
                    },


                ],

                columnDefs: [
                    {
                        targets: 7,
                        responsivePriority: 1,
                        createdCell: (td, cellData, rowData, row, col) =>
                            ReactDOM.render(
                                <div>
                                    <CButton onClick={() => {
                                        showDetails(rowData)
                                    }} size="sm" className="w-100" variant='outline' color="primary" >
                                        <i className="fas fa-eye"></i>
                                    </CButton>
                                </div>
                                , td),
                    }
                ],
                destroy: true,  // I think some clean up is happening here
                searching: true,
                "language": {
                    search: '',
                    searchPlaceholder: "Search",
                    emptyTable: "No Record Found"
                },
                initComplete: function () {
                    // Apply the search
                    this.api()
                        .columns([0, 1, 3, 4, 5])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><input class="form-control " style="width:100%;margin-top:5px" type="text" placeholder="Filter" />')
                                .appendTo($(column.header()))

                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $('input', this.header()).on('keyup change clear', function () {
                                if (column.search() !== this.value) {
                                    column.search(this.value).draw();
                                }
                            });
                        });
                    this.api()
                        .columns([2])//Job type filter
                        .every(function () {
                            var column = this;
                            var select = $('<br/><select class="form-control" placeholder="filter" style="width:100%;margin-top:5px;background-color:white"><option value="">All</option></select>')
                                .appendTo($(column.header()))
                                .on('change', function () {
                                    var val = $.fn.dataTable.util.escapeRegex($(this).val());
                                    column.search(val ? '^' + val + '$' : '', true, false).draw();
                                });
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            column
                                .data()
                                .unique()
                                .sort()
                                .each(function (d, j) {
                                    let name = global.config.jobType?.find(job => job.value == d)?.name;
                                    select.append('<option value="' + name + '">' + name + '</option>');
                                });
                        });
                    this.api()
                        .columns([6])//Status Filter
                        .every(function () {
                            var column = this;
                            var select = $('<br/><select class="form-control" placeholder="search" style="width:100%;margin-top:5px;background-color:white""><option value="">All</option></select>')
                                .appendTo($(column.header()))
                                .on('change', function () {
                                    var val = $.fn.dataTable.util.escapeRegex($(this).val());
                                    column.search(val ? '^' + val + '$' : '', true, false).draw();
                                });
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            column
                                .data()
                                .unique()
                                .sort()
                                .each(function (d, j) {
                                    let name = global.config.status?.find(stat => stat.value == d)?.name;
                                    select.append('<option value="' + name + '">' + name + '</option>');
                                });
                        });

                },

            }

        )
        // Extra step to do extra clean-up.
        return function () {
            table.destroy()
        }
    }, [hiringList])

    useEffect(() => {
        if (rating) {
            let param = {
                request_id: hiringData.id,
                rating: rating,
                feedback: feedback
            }
            UserStore.saveFeedback(param, navigationCallBack);
        } else {
            console.log("");
        }
    }, [submitted]);

    const showDetails = (item) => {
        setHiringData(item);
        if (isAuthenticated) {
            HomeStore.getCaregiverDegreeData(item?.staff_id).then(data => {
                setDegreeData(data);
            })
            HomeStore.getCaregiverDetails(item?.staff_id).then(data => {
                setStaffData(data);
                setIsOpen(true);
            })
        }
    }
    const onDismiss = () => {
        setIsOpen(false)
    }

    const onPick = (value) => {
        setRating(value);
    }

    const handleChange = (event) => {
        setFeedback(event.target.value);
    };

    const onFeedback = async (hiringData) => {
        setIsOpen(false)
        swal({
            title: "Feedback/Rating",
            buttons: [true, "Submit"],
            content: (
                <div className='row' style={{ textAlign: 'start' }}>

                    <div className="">
                        <label className='mb-3'>How was your experience?</label>
                        <br />
                        <div className="rate">
                            <input type="radio" onClick={() => onPick(5)} id="star5" name="rate" value="5" />
                            <label for="star5" title="">5 stars</label>
                            <input type="radio" onClick={() => onPick(4)} id="star4" name="rate" value="4" />
                            <label for="star4" title="">4 stars</label>
                            <input type="radio" onClick={() => onPick(3)} id="star3" name="rate" value="3" />
                            <label for="star3" title="">3 stars</label>
                            <input type="radio" onClick={() => onPick(2)} id="star2" name="rate" value="2" />
                            <label for="star2" title="">2 stars</label>
                            <input type="radio" onClick={() => onPick(1)} id="star1" name="rate" value="1" />
                            <label for="star1" title="">1 star</label>
                        </div>
                    </div>
                    <div className='mt-5'>
                        <label className='mb-3'>Give a feedback to staff</label>
                        <div className="col-12">
                            <div className="form-floating">
                                <textarea className="form-control"
                                    placeholder="Write something"
                                    name={'feedback'}
                                    onChange={handleChange}
                                    style={{ height: 100 }}></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
            .then((value) => {
                setSubmitted(value)
            });
    }

    const navigationCallBack = () => {
        UserStore.getHiringingRequest();
        swal("Thanks for your rating!", `You rated us ${rating}/5`, "success").then(() => {
            setFeedback()
            setRating()
            setSubmitted(false)
        })
    }

    return (
        <React.Fragment>
            <Header />
            <Helmet>
                <title>Hire Requests</title>
            </Helmet>
            <>
                <div className="container-fluid callback mt-5 py-5">
                    <div className="container pt-lg-5">
                        <h5 className="display-5 mb-4 animated slideInDown login-head">
                            Hire Requests</h5>
                    </div>
                    <div className="col-lg-12">
                        <div className="row bg-white" style={{ height: 500, paddingTop: 50 }}>
                            <table className="table table-striped dt-responsive nowrap" style={{ width: "100%" }} id={tableName} ref={tableRef}></table>
                        </div>
                    </div>
                </div>
                <BottomSheet open={isOpen} onDismiss={onDismiss} scrollLocking={true}
                    header={
                        <>
                            <div className="text-end" style={{ cursor: "pointer" }} onClick={onDismiss}>
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <h4 className="flex items-center text-xl justify-center font-bold text-primary-800">
                                HIRE REQUEST
                            </h4>
                        </>
                    }
                    footer={
                        <div className="text-end">{
                            !hiringData?.rating && hiringData?.status > 1 &&
                            <button onClick={() => onFeedback(hiringData)} className="btn btn-primary">
                                Give Feedback/Rating
                            </button>}
                        </div>
                    }>
                    <div style={{ height: '60vh', overflowY: 'scroll' }}>
                        <div className="col-xl-12 col-md-12">
                            <div className="card user-card-full">
                                <div className="row m-l-0 m-r-0">
                                    <div className="col-sm-12">
                                        <div className="card-block">
                                            <h6 className="m-b-20 p-b-5 b-b-default f-w-600">Staff Details</h6>
                                            <div className="row">
                                                {staffData && staffData?.FullName && <div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">Name</p>
                                                    <h6 className="text-muted f-w-400">{staffData?.FullName}</h6>
                                                </div>}
                                                {degreeData && degreeData.degrees.length > 0 && <div class="col-sm-3">
                                                    <p class="m-b-10 f-w-600">Profession</p>
                                                    <h6 class="text-muted f-w-400">{degreeData?.degrees.map(tl => { return <>{tl.title} < br /></> })}</h6>
                                                </div>}
                                                {degreeData && degreeData?.degrees.length > 0 && <div class="col-sm-3">
                                                    <p class="m-b-10 f-w-600">Specialty</p>
                                                    <h6 class="text-muted f-w-400">{degreeData?.degrees.map(tl => tl.specialties?.map(sp => { return <>{sp.name} < br /></> }))}</h6>
                                                </div>}

                                                {staffData && staffData?.City && <div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">City</p>
                                                    <h6 className="text-muted f-w-400">{staffData?.City}</h6>
                                                </div>}
                                                {staffData && staffData?.StateID && <div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">State</p>
                                                    <h6 className="text-muted f-w-400">{staffData?.StateID}</h6>
                                                </div>}
                                            </div>
                                        </div>
                                        <div className="card-block">
                                            <h6 className="m-b-10 p-b-5 b-b-default f-w-600">Hiring Request Details</h6>
                                            <div className="row">
                                                {hiringData && hiringData?.state && <div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">State</p>
                                                    <h6 className="text-muted f-w-400">{hiringData?.state}</h6>
                                                </div>}
                                                {hiringData && hiringData?.city && <div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">City</p>
                                                    <h6 className="text-muted f-w-400">{hiringData?.city}</h6>
                                                </div>}

                                                {hiringData && <div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">Job Type</p>
                                                    <h6 className="text-muted f-w-400">{global.config.jobType?.find(job => job.value == hiringData?.job_type)?.name}</h6>
                                                </div>}

                                                {<div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">Extention Times</p>
                                                    <h6 className="text-muted f-w-400">{hiringData?.extention_times}</h6>
                                                </div>
                                                }

                                                {<div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">Status</p>
                                                    <h6 className="text-muted f-w-400">
                                                        {hiringData?.status == 0 ?
                                                            <CFormLabel className="w-100 text-warning" >
                                                                Pending
                                                            </CFormLabel>
                                                            : hiringData?.status == 1 ? <CFormLabel className="w-100 text-success" >
                                                                Accepted
                                                            </CFormLabel>
                                                                : hiringData?.status == 2 ? <CFormLabel className="w-100 text-info" >
                                                                    Ongoing
                                                                </CFormLabel>
                                                                    : hiringData?.status == 3 ? <CFormLabel className="w-100 text-success" >
                                                                        Completed
                                                                    </CFormLabel>
                                                                        : hiringData?.status == 4 ? <CFormLabel className="w-100 text-danger" >
                                                                            Rejected
                                                                        </CFormLabel>
                                                                            : hiringData?.status == 5 ? <CFormLabel className="w-100 text-warning" >
                                                                                Extended
                                                                            </CFormLabel> : null

                                                        }
                                                    </h6>
                                                </div>}

                                                {<div className="col-sm-2">
                                                    <p className="f-w-600 mb-0">Rating</p>
                                                    <h6 className="text-muted f-w-400">
                                                        <div className="rate-view">
                                                            <input type="radio" id="star5" checked={hiringData?.rating == 5} readOnly name="rate" value="5" />
                                                            <label htmlFor="star5" title="Five">5 stars</label>
                                                            <input type="radio" id="star4" checked={hiringData?.rating == 4} readOnly name="rate" value="4" />
                                                            <label htmlFor="star4" title="Four">4 stars</label>
                                                            <input type="radio" id="star3" checked={hiringData?.rating == 3} readOnly name="rate" value="3" />
                                                            <label htmlFor="star3" title="Three">3 stars</label>
                                                            <input type="radio" id="star2" checked={hiringData?.rating == 2} readOnly name="rate" value="2" />
                                                            <label htmlFor="star2" title="Two">2 stars</label>
                                                            <input type="radio" id="star1" checked={hiringData?.rating == 1} readOnly name="rate" value="1" />
                                                            <label htmlFor="star1" title="One">1 star</label>
                                                        </div>
                                                    </h6>
                                                </div>
                                                }
                                                {<div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">Feedback</p>
                                                    <h6 className="text-muted f-w-400">{hiringData?.feedback}</h6>
                                                </div>
                                                }
                                                {hiringData && hiringData?.description && <div className="col-sm-4">
                                                    <p className="m-b-10 f-w-600">Description</p>
                                                    <h6 className="text-muted f-w-400">{hiringData?.description}</h6>
                                                </div>}

                                            </div>
                                        </div>
                                        <div className="card-block">
                                            {hiringData?.tenure && (
                                                <div >
                                                    <h6 className="m-b-20 p-b-5 b-b-default f-w-600 row">Order Details (&nbsp;<div class="green-box"> </div>&nbsp;= Shift Filled )</h6>
                                                </div>)}
                                            <div className="row">
                                                {hiringData?.tenure && <div className="col-sm-12">
                                                    {/* <p className="m-b-10 f-w-600">Shift Slots</p> */}
                                                    <ul className="list-unstyled">
                                                        <div className="row">
                                                            {JSON?.parse(hiringData?.tenure)?.map((slot, index) =>
                                                                <div className="col-sm-6 col-md-6 col-lg-4" key={index + 'slots'}>
                                                                    <li key={index}>
                                                                        <input className={slot?.filled == 1 ? "slot green-border" : "slot"} readOnly value={slot?.date} />
                                                                        <TimeRangePicker
                                                                            style={{ color: 'red' }}
                                                                            className={slot?.filled == 1 ? "timer-filled" : "timer"}
                                                                            value={slot?.time}
                                                                            rangeDivider="To"
                                                                            clearIcon={null}
                                                                            disabled
                                                                        />
                                                                    </li>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </ul>

                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </BottomSheet>
                <Spinner isLoading={HomeStore.loading} />
            </>

        </React.Fragment >
    )
})

export default HiringRequestPage;