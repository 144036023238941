/* eslint-disable */
import React from 'react';
import { Navigate, Outlet,useLocation } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";

const AdminPrivateRoute = () => {
    const location = useLocation();
    if (secureLocalStorage.getItem("admin-token") !== null) {
        const isAuthenticated = secureLocalStorage.getItem("admin-token");
        return isAuthenticated ? <Outlet /> : null; // or loading indicator, etc...
    }
    return <Navigate to={"/admin-login"} state={ { from: location } } replace />;
}

export default AdminPrivateRoute;