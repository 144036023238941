/* eslint-disable */
import moment from "moment";
import React from "react";
import AdminStore from "@store/AdminStore";
import Skeleton from "react-loading-skeleton";

const DashboardRecentPage = ({ recentUser }) => {
    return (

        <div className="row">
            <div className="col-12 grid-margin">
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-title">Recently Joined Facility Users</h4>
                        {recentUser?.length === 0 && <small className="text-center">No new facility user</small>}


                        {recentUser?.length !== 0 && <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th> Facility User Name </th>
                                        <th> Facilities</th>
                                        <th> Email </th>
                                        <th> Joining Date </th>
                                    </tr>
                                </thead>
                                {AdminStore.loading ? <tbody>
                                    <tr>
                                        <td><Skeleton className="info-text" count={1} height={15} width={150} /></td>
                                        <td><Skeleton className="info-text" count={1} height={15} width={150} /></td>
                                        <td><Skeleton className="info-text" count={1} height={15} width={150} /></td>
                                        <td><Skeleton className="info-text" count={1} height={15} width={150} /></td>
                                    </tr>
                                </tbody> : null

                                }
                                <tbody>
                                    {
                                        recentUser?.map(user => {
                                            return (
                                                <tr key={user?.id}>
                                                    <td>
                                                        {user?.first_name} {user?.last_name}
                                                    </td>
                                                    <td>
                                                        <span className="text-info"><b>{user?.facility_relations.filter(item => item?.facilities)[0]?.facilities?.organization_name}</b>
                                                        </span><sup class="text-info"><b>{user?.facility_relations?.length > 1 ?" +" + (user?.facility_relations?.length - 1) : ""}</b>
                                                        </sup>
                                                    </td>
                                                    <td>{user?.email}</td>
                                                    <td>{moment(user.created_at).fromNow()}</td>
                                                </tr>
                                            )
                                        })}
                                </tbody>
                            </table>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DashboardRecentPage;