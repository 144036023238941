/* eslint-disable */
import React from 'react';
import secureLocalStorage from "react-secure-storage";
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const EmployerPrivateRoute = () => {
    const location = useLocation();

    if (secureLocalStorage.getItem("token") !== null) {
        const isAuthenticated = secureLocalStorage.getItem("token");
        const userType = secureLocalStorage.getItem("type");
        return isAuthenticated && userType === 'false' ? <Outlet /> : null; // or loading indicator, etc...
    }
    return <Navigate to={"/login/client"} state={{ from: location }} replace />;
}

export default EmployerPrivateRoute;