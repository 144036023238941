// DatePickerComponent.js

import React from 'react';
import DatePicker from 'react-multi-date-picker';
import InputIcon from "react-multi-date-picker/components/input_icon"
const MultiDatePicker = ({ multiple, value, onChange, minDate, required, inputClass, disabled, placeholder, calendarPosition, plugins }) => {
    return (
        <DatePicker
            calendarPosition={calendarPosition}
            inputClass={inputClass}
            multiple={multiple}
            value={value}
            onChange={onChange}
            format="MM/DD/YYYY"
            minDate={minDate}
            required={required}
            disabled={disabled}
            placeholder={placeholder}
            plugins={plugins}
            render={<InputIcon />}
            editable={false}
        />

    );
};

export default MultiDatePicker;
