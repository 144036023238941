import React from "react";
import Header from '@components/PDFHeader';
import moment from "moment";
import { MultiDatePicker } from "@elements/index"
const AdministratorJobDescription = (props) => {
  return (
    <div id="form-content">
      <Header title="Administrator Job Description" />
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="border-box">
                <div>
                  <div className="d-md-flex">
                    <table className="table table-all ">
                      <tbody>
                        <tr>
                          <td width="40%" className="head">
                            Job Title:
                          </td>
                          <td width="60%" className="input">
                            <input type="text" value={"Administrator"} disabled onChange={(e) => props.handleInputChange('job_type', e.target.value)} />
                          </td>
                        </tr>
                        <tr>
                          <td width="40%" className="head">
                            Location:
                          </td>
                          <td width="60%" className="input">
                            <input type="text" value={props.inputValues.location} disabled onChange={(e) => props.handleInputChange('location', e.target.value)} />
                          </td>
                        </tr>
                        <tr>
                          <td width="40%" className="head">
                            Effective Date:
                          </td>
                          <td width="60%" className="input">
                            <input type="text" value={"07/15/2019 "} readOnly onChange={(e) => props.handleInputChange('EffectiveDate', e.target.value)} />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="table table-all bordered">
                      <tbody>
                        <tr>
                          <td width="40%" className="head">
                            Department
                          </td>
                          <td width="60%" className="input">
                            <input type="text" value={"Nursing"} disabled onChange={(e) => props.handleInputChange('Department', e.target.value)} />
                          </td>
                        </tr>
                        <tr>
                          <td width="40%" className="head">
                            FLSA Status:
                          </td>
                          <td width="60%" className="input">
                            <input type="text" value={props.inputValues.FLSA} onChange={(e) => props.handleInputChange('FLSA', e.target.value)} />
                          </td>
                        </tr>
                        <tr>
                          <td width="40%" className="head">
                            Revised Date:
                          </td>
                          <td width="60%" className="input">
                            <input type="text" value={props.inputValues.Revised} onChange={(e) => props.handleInputChange('Revised', e.target.value)} />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <table className="table table-all bordered">
                    <tbody>
                      <tr>
                        <td className="head width-set-h">Reporting to:</td>
                        <td className="input width-set-i">
                          <input type="text" value={"Staffing Manager"} readOnly onChange={(e) => props.handleInputChange('Reportingto', e.target.value)} />
                        </td>
                      </tr>
                      <tr>
                        <td className="head width-set-h">Date of Hire:</td>
                        <td className="input width-set-i">

                          <MultiDatePicker required={false} multiple={false} value={props.inputValues.DOH} onChange={(e) => props.handleInputChange('DOH', e)} />

                        </td>
                      </tr>
                      <tr>
                        <td className="head width-set-h">Employee Name:</td>
                        <td className="input width-set-i">
                          <input required placeholder="Full Name" value={props.inputValues.full_name} type="text" minLength={2} maxLength={20} name="full name" onChange={(e) => props.handleInputChange('full_name', e.target.value)} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="back-grad grey">
                  <h2 className="heading-line">JOB SUMMARY:</h2>
                  <span className="bold-para">
                    Nursing home administrators oversee a wide landscape of differing terrain. They manage a long-term care
                    facility’s staff—from hiring, training, and evaluation, to scheduling. They also take control of the
                    organization’s finances and design budgets, manage billing and reimbursement, monitor payroll, and report
                    to board-level governance. And they ensure that the facility is complying with federal and state regulations in
                    the delivery of care, in the safety of the building, and in the security of patient data.
                  </span>
                </div>
                <div className="back-grad">
                  <h3 className="same-head">
                    <span>E</span>SSENTIAL JOB FUNCTIONS
                  </h3>
                  <h4 className="same-small-head">Customer Service</h4>
                  <span className="">
                    It’s primarily about people. As a resident’s primary advocate, NHAs often serve as a liaison between staff,
                    residents, families, and doctors. This goes beyond the purely physical aspect of caretaking, as social and
                    emotional dynamics, as well as the grieving process, must be taken into account during an individual’s transition
                    to a long-term care facility.
                  </span>
                  <br></br>
                  <h4 className="same-small-head">
                    Specific Duties and Responsibilities
                  </h4>
                  <span className="">
                    The day-to-day tasks of a nursing home administrator can
                    vary depending on the size, setting, and exact type of
                    facility. However, even in the same facility, administrative
                    needs can differ from day to day. Here are some typical
                    activities a nursing home administrator might perform:
                    <ul>
                      <li>
                        <span>Implementing facility-wide policy</span>
                      </li>
                      <li>
                        <span>Overseeing quality assurance protocols</span>
                      </li>
                      <li>
                        <span>Interfacing with residents, families, and staff</span>
                      </li>
                      <li>
                        <span>
                          Coordinating between care and organizational
                          departments
                        </span>
                      </li>
                      <li>
                        <span>
                          Updating processes to comply with state and federal
                          regulations
                        </span>
                      </li>
                      <li>
                        <span>
                          Representing the facility in professional and
                          community activities
                        </span>
                      </li>
                      <li>
                        <span>
                          Interpreting resident needs and collaborating with
                          families
                        </span>
                      </li>
                    </ul>
                  </span>
                  <br></br>
                  <h4 className="same-small-head">Supervision</h4>
                  <ul>
                    <li>
                      <span>
                        Supervise Certified Nursing Assistants and other
                        personnel. Coach, counsel and assign Certified Nursing
                        Assistants to provide quality resident care.
                      </span>
                    </li>
                    <li>
                      <span>
                        Recognize Certified Nursing Assistants and other Nurses
                        for exceptional care and job performance on a regular
                        basis.
                      </span>
                    </li>
                    <li>
                      <span>
                        Attempt to effectively resolve Certified Nursing
                        Assistant and other Nurses’ problems/complaints.
                      </span>
                    </li>
                    <li>
                      <span>
                        Support and follow through with facility management team
                        goals.
                      </span>
                    </li>
                  </ul>
                  <br></br>
                  <h4 className="same-small-head">Safeguarding and Safety</h4>
                  <ul>
                    <li>
                      <span>
                        To ensure that residents are safeguarded against the
                        risk of abuse. This includes but is not limited to: a
                        zero tolerance for harassment and violence; following
                        infection control procedures; following safe patient
                        handling as required with your specific job position
                      </span>
                    </li>
                    <li>
                      <span>
                        Demonstrate safe practices in regard to resident’s
                        comfort and safety by applying knowledge of proper body
                        alignment for self and resident. Practice and promote
                        adherence to institutional safety codes.
                      </span>
                    </li>
                    <li>
                      <span>
                        Comply with all Company safety policies, regulations and
                        requirements regarding fire and life safety.
                      </span>
                    </li>
                    <li>
                      <span>
                        Meet the general health requirements set forth by the
                        policies of the facility.
                      </span>
                    </li>
                  </ul>
                  <br></br>
                  <h4 className="same-small-head">
                    Other Functions and Responsibilities
                  </h4>
                  <ul>
                    <li>
                      <span>
                        Managing and leading all nursing personnel operations.
                      </span>
                    </li>
                    <li>
                      <span>
                        Developing short and long-term goals for the entire
                        nursing department.
                      </span>
                    </li>
                    <li>
                      <span>
                        Establishing new policies and updating existing policies
                        to improve the standard of care for patients.
                      </span>
                    </li>
                    <li>
                      <span>
                        Planning and overseeing admission, nursing, and patient
                        care processes.
                      </span>
                    </li>
                    <li>
                      <span>
                        Maintaining department budgets and recording all
                        expenses.
                      </span>
                    </li>
                    <li>
                      <span>
                        Responding to any nursing-related issues in a timely
                        manner.
                      </span>
                    </li>
                    <li>
                      <span>
                        Coordinating with medical staff and other departments to
                        ensure hospital efficiency.
                      </span>
                    </li>
                    <li>
                      <span>
                        Overseeing all record-keeping processes and ensuring all
                        necessary documents are accurate and up to date
                      </span>
                    </li>
                    <li>
                      <span>Hiring and training new nursing staff members.</span>
                    </li>
                    <li>
                      <span>
                        Evaluating staff performance and preparing accurate
                        reports detailing your findings.
                      </span>
                    </li>
                  </ul>
                  <h4 className="same-small-head">
                    Physical and Sensory Requirements
                  </h4>
                  <span>This position is very active and requires:</span>
                  <ul>
                    <li>
                      <span>
                        Walking, standing, reaching, bending, crouching,
                        grasping, pushing, pulling, and lifting.
                      </span>
                    </li>
                    <li>
                      <span>
                        The ability to work at a rapid apace for an entire
                        shift.
                      </span>
                    </li>
                    <li>
                      <span>
                        The employee must frequently lift or move objects and
                        patients weighing up to 40 pounds. Following patient
                        care plan and using a variety of equipment, the employee
                        may frequently move, reposition and transfer residents
                        of varying weights up to 300 lbs.
                      </span>
                    </li>
                    <li>
                      <span>
                        Must have a finger and hand dexterity sufficient to type, handle and manipulate instruments and operate departmental equipment.
                      </span>
                    </li>
                    <li>
                      <span>
                        Have a working understand of English: ability to read
                        and write; ability to understand and follow written and
                        oral instructions; ability to verbally communicate with
                        residents and others; ability to hear and respond to
                        pages.
                      </span>
                    </li>
                    <li>
                      <span>
                        Ability to remain calm in emergency situations and when
                        handling multiple tasks.
                      </span>
                    </li>
                  </ul>
                  <br></br>
                  <h4 className="same-head">
                    QUALIFICATIONS AND EDUCATION REQUIREMENTS
                  </h4>
                  <ul>
                    <li>
                      <span>A master’s in healthcare administration</span>
                    </li>
                    <li>
                      <span>Experience in Nursing.</span>
                    </li>
                    <li>
                      <span>
                        Good critical thinking, communication, and leadership
                        skills.
                      </span>
                    </li>
                    <li>
                      <span>Good planning and problem-solving skills.</span>
                    </li>
                    <li>
                      <span>Knowledge of computers and healthcare software</span>
                    </li>
                    <li>
                      <span>The ability to work under stressful conditions.</span>
                    </li>
                  </ul>
                  <br></br>
                  <h2 className="heading-line">JOB DESCRIPTION REVIEW:</h2>
                  <span>
                    I understand this job description and its requirements, and
                    that I am expected to complete all duties as assigned. I
                    understand the job functions may be altered from time to
                    time. I further understand that the purpose of this job
                    description is to identify the major duties and
                    responsibilities of the job and this is not intended to
                    contain each and every inherent in this job. I have noted
                    below any accommodations that are required to enable me to
                    perform these duties. I have also noted below any job
                    responsibilities or functions which I am unable to perform,
                    with or without accommodation.
                  </span>
                </div>
                <table className="table table-all bordered">
                  <tbody>
                    <tr>
                      <td width="20%" className="head not-bold">
                        Employee's<br></br> Signature
                      </td>
                      <td width="20%" className="input">
                        <div>
                          {props?.signature ? <img src={props?.signature} width={180} height={50} className="w-100 rounded-0" alt="Signature here" /> : <div class="badge badge-primary text-wrap w-100 pointer" onClick={props?.onOpenModal}>
                            Click Here To Sign
                          </div>}
                        </div>
                      </td>
                      <td width="20%" className="head not-bold">
                        Date:
                      </td>
                      <td width="20%" className="input">
                        <div><input type="text" value={moment(new Date()).format("MM-DD-YYYY HH:mm:ss")} disabled /></div>
                      </td>
                    </tr>
                    <tr>
                      <td width="20%" className="head not-bold">
                        Supervisor's<br></br> Signature
                      </td>
                      <td className="input">
                        <div></div>
                      </td>
                      <td width="20%" className="head not-bold">
                        Date:
                      </td>
                      <td className="input">
                        <div></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="border-box mt-3">
                <div className="back-grad">
                  <p className=" para-underline">Accomodation Needed:</p>
                  <p className=" para-underline">
                    Job Responsibilities I am unable to perform:
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdministratorJobDescription;
