/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react"
import { InputField } from "@elements/index"
import { Formik, Field, FieldArray } from "formik";
import HeaderPage from "../layout/HeaderPage";
import DrawerMenuPage from "../layout/DrawerMenuPage";
import AdminStore from "@store/AdminStore";
import HomeStore from "@store/HomeStore";
import Spinner from "@components/Spinner";
import { useNavigate } from "react-router-dom";
import SelectField from "@elements/select/SelectField";
import { toast } from "react-toastify";
import DatePicker, { getAllDatesInRange } from "react-multi-date-picker"
import weekends from "react-multi-date-picker/plugins/highlight_weekends"
import swal from '@sweetalert/with-react';
import Select from 'react-select';
import { toJS } from "mobx";
import { Helmet } from "react-helmet";
import { colourStylesState } from '@utils/customStyle/style';
import { AddShiftSchema } from '@utils/schemas/allSchema';
import { calculateDomIdSum, calculateLunch, calculateTotalHr, Control } from "../commonFunctions"
import { CButton, CForm, CFormCheck, CFormLabel, CModal, CModalBody, CModalHeader, CModalTitle, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from "@coreui/react-pro";
import moment from "moment";
var transformedAmounts = 0;
const StaffingRequestAdminPage = observer(() => {
    const formikRef = useRef();
    const navigate = useNavigate();
    window.onscroll = function () { };
    const [specialties, setSpecialties] = useState([]);
    const [degree, setDegree] = useState([]);
    const [clientList, setClientList] = useState([]);
    const [multi, setValues] = useState([])
    const [dates, setDates] = useState([])
    const [allDates, setAllDates] = useState([])
    const [pastOrders, setPastOrders] = useState(false)
    const [staffingList, setStaffingList] = useState([]);
    const [facilityList, setFacilityList] = useState([]);
    const [copiedOrder, setCopiedOrder] = useState({
        profession: "",
        specialty: "",
        client_id: "",
        category: "",
        nop: "",
        hours_per_week: "",
        no_of_weeks: "",
        pay_detail: "",
        qualifications: "",
        specialty_name: "",
        region: "",
        address: "",
        state: "",
        city: "",
        zipcode: "",
        tenure: "",
        shift_type: "",
        description: "",
        facility_code: "",
        slot_position: "",
        slot_type: "",
        slot_comment: "",
        slot_start_time: "",
        slot_end_time: "",
    });

    useEffect(() => {
        AdminStore.getAllFacility();
    }, [])

    useEffect(() => {
        setClientList(toJS(AdminStore?.data?.clientsByFacility)?.filter(item => item.users !== null))
    }, [AdminStore?.data?.clientsByFacility]);

    useEffect(() => {
        setFacilityList(toJS(AdminStore?.data?.allFacilityList))
    }, [AdminStore?.data?.allFacilityList]);

    useEffect(() => {
        setDegree(HomeStore.data.degreeList)
    }, [HomeStore.data.degreeList]);

    useEffect(() => {
        setSpecialties(HomeStore?.data?.dropDown)
    }, [HomeStore?.data?.dropDown]);

    useEffect(() => {
        setStaffingList(toJS(AdminStore?.data?.staffingList))
    }, [AdminStore?.data?.staffingList]);

    const onStaffingRequest = (values) => {

        var check;
        var finalslot;
        if (values.category !== "TC") {
            finalslot = values?.tenure?.map((x, index) => {
                const copiedObjects = Array.from({ length: values.allqty[index] }, () => Object.assign({}, { "date": x.format(), "time": values.alltime[index] ? values.alltime[index] : values.alltime[0], "remark": values?.allcomment ? values?.allcomment[index] || '' : '', "slot_type": values?.alltype ? values?.alltype[index] || '' : '' }));
                return copiedObjects
            })
            finalslot = [].concat(...finalslot);
            check = finalslot.some((obj) => !Object.values(obj).every((val) => val !== undefined));
        } else {
            check = false
        }
        if (check) {
            toast.warn("Please select date & time!")
        } else {
            let param = {
                profession: values.profession,
                specialty: values.specialty,
                client_id: values.client_id,
                facility_id: values.facility_id,
                client_id: values.client_id,
                category: values.category,
                specialty_name: specialties?.find(x => x.Id == values.specialty)?.Name,
                slots: JSON.stringify(finalslot),
                shift_type: values.shift_type,
                break_time: values.break_time,
                description: values.description,
                nop: values.nop,
                hours_per_week: values.hours_per_week,
                no_of_weeks: values.no_of_weeks,
                pay_details: values.pay_detail,
                start_date: values.start_date,
                end_date: values.end_date,
                start_time: values.start_time,
                end_time: values.end_time,
                qualifications: values.qualifications,
                rate: values.rate,
            }
            AdminStore.setStaffingRequest(param, navigationCallBack);
        }
    }

    const navigationCallBack = () => {
        swal({
            title: "Success",
            text: "Successfully Added",
            icon: "success"
        });
        navigate('../admin/shift-request');
    }

    function ClearButton({ setFiled }) {
        return <div>
            <button
                className="btn btn-sm btn-primary"
                type="button"
                onClick={() => {
                    setDates([])
                    setAllDates([])
                    setValues([])
                    setFiled('tenure', [])
                }}
            >
                Clear
            </button>
        </div>;
    }

    const openPastOrders = async () => {
        if (formikRef.current) {
            formikRef.current.resetForm();
            setCopiedOrder();
            setDates();
            setValues();
        }
        if (staffingList?.length === 0) {
            await AdminStore.getStaffingRequestList();
        }
        setPastOrders(true)
    }
    const setCopyOrder = (shift) => {
        HomeStore.getSpecialtiesListData(shift?.profession);
        AdminStore.getClientsByFacility(shift.facility_id)
        setCopiedOrder({
            profession: shift?.profession,
            specialty: shift?.specialty,
            client_id: shift?.user_id,
            facility_id: shift?.facility_id,
            category: shift?.category,
            nop: shift?.nop,
            hours_per_week: shift?.hours_per_week,
            no_of_weeks: shift?.no_of_weeks,
            pay_detail: shift?.pay_detail,
            qualifications: shift?.qualifications,
            specialty_name: shift?.specialty_name,
            region: shift?.user?.region_id,
            address: shift?.address,
            state: shift?.state,
            city: shift?.city,
            zipcode: shift?.zipcode,
            shift_type: shift?.shift_type,
            description: shift?.description,
            facility_code: shift?.user?.facility_code,
            unit_code: shift?.user?.unit_code,
            //Slot
            slot_position: 1,
            slot_type: shift?.assignments[0]?.slot_type,
            slot_comment: shift?.assignments[0]?.comment,
            slot_start_time: shift?.assignments[0]?.slot_start_time,
            slot_end_time: shift?.assignments[0]?.slot_end_time,
        });

        toast.success(`Copied Order Ref. ${shift?.id}`)
        setPastOrders(false)
    }
    return (
        <React.Fragment>
            <Helmet>
                <title>Staffers LLC | Order Request</title>
            </Helmet>
            {/* <body> */}
            <div className="container-scroller">
                <HeaderPage />
                <div className="container-fluid page-body-wrapper">
                    <DrawerMenuPage />
                    <div className="main-panel" style={{ right: 0, position: "absolute" }}>
                        <div className="row content-wrapper p-3 bg-white">
                            <div className="d-flex justify-content-between mb-3">
                                <h3 className="page-title">
                                    <span className="page-title-icon linear text-white me-2">
                                        <i className="fa fa-envelope "></i>
                                    </span> Order Requests
                                </h3>
                                <h3 className="page-title text-decoration-underline pointer" onClick={openPastOrders}>
                                    <span className="page-title-icon linear text-white me-2">
                                        <i className="fas fa-history "></i>
                                    </span> Past Orders
                                </h3>
                            </div>
                            <div className="border p-4 p-sm-4 wow fadeInUp" style={{ borderRadius: 25 }} >
                                <>
                                    <Formik
                                        innerRef={formikRef}
                                        initialValues={{
                                            profession: copiedOrder ? copiedOrder?.profession : "",
                                            specialty: copiedOrder ? copiedOrder?.specialty : "",
                                            client_id: copiedOrder ? copiedOrder?.client_id : "",
                                            facility_id: copiedOrder ? copiedOrder?.facility_id : "",
                                            category: copiedOrder ? copiedOrder?.category : "",
                                            nop: copiedOrder ? copiedOrder?.nop : "",
                                            hours_per_week: copiedOrder ? copiedOrder?.hours_per_week : "",
                                            no_of_weeks: copiedOrder ? copiedOrder?.no_of_weeks : "",
                                            pay_detail: copiedOrder ? copiedOrder?.pay_detail : "",
                                            start_date: copiedOrder ? copiedOrder?.start_date : "",
                                            end_date: copiedOrder ? copiedOrder?.end_date : "",
                                            start_time: copiedOrder ? copiedOrder?.start_time : "",
                                            end_time: copiedOrder ? copiedOrder?.end_time : "",
                                            qualifications: copiedOrder ? copiedOrder?.qualifications : "",
                                            specialty_name: copiedOrder ? copiedOrder?.specialty_name : "",
                                            region: copiedOrder ? copiedOrder?.region : "",
                                            address: copiedOrder ? copiedOrder?.address : "",
                                            state: copiedOrder ? copiedOrder?.state : "",
                                            city: copiedOrder ? copiedOrder?.city : "",
                                            zipcode: copiedOrder ? copiedOrder?.zipcode : "",
                                            tenure: [],
                                            shift_type: copiedOrder ? copiedOrder?.shift_type : "",
                                            description: copiedOrder ? copiedOrder?.description : "",
                                            facility_code: copiedOrder ? copiedOrder?.facility_code : "",
                                            unit_code: copiedOrder ? copiedOrder?.unit_code : "",
                                            alltime: "",
                                            alldate: "",
                                            alltype: "",
                                            allqty: "",
                                            break_time: 0,
                                            rate: 0
                                        }}
                                        onSubmit={values =>
                                            onStaffingRequest(values)
                                        }
                                        validationSchema={AddShiftSchema}
                                        enableReinitialize={true}
                                        validateOnChange={false}
                                    >
                                        {
                                            ({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched
                                            }) =>
                                            (
                                                <form onSubmit={handleSubmit}>
                                                    <div className="row g-2">
                                                        <div className="col-sm-6">
                                                            <div className="did-floating-label-content">
                                                                <select className="did-floating-dropdown"
                                                                    name="profession"
                                                                    value={values.profession}
                                                                    required
                                                                    onChange={(e) => {
                                                                        if (e.target.value) {
                                                                            setFieldValue('profession', e.target.value)
                                                                            HomeStore.getSpecialtiesListData(e.target.value)
                                                                            if (facilityList?.find(({ id }) => id === values.facility_id)?.rates?.length > 0) {

                                                                                if (facilityList?.find(({ id }) => id === values.facility_id)?.rates?.find(x => x.profession_id == e.target.value) !== undefined) {
                                                                                    setFieldValue("rate", facilityList?.find(({ id }) => id === values.facility_id)?.rates?.find(x => x.profession_id == e.target.value)?.cost);
                                                                                }
                                                                            }
                                                                        } else {
                                                                            setSpecialties([])
                                                                        }
                                                                    }}
                                                                >
                                                                    <option
                                                                        value=""
                                                                    >
                                                                    </option>
                                                                    {degree && degree?.map((option, index) => (
                                                                        <option
                                                                            key={index}
                                                                            value={option.Id}
                                                                        >
                                                                            {option.Name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                <label className={values.profession == "" ? "did-floating-label did-floating-label-down did-floating-labe-astrisk" : "did-floating-label did-floating-label-top did-floating-labe-astrisk"}>Profession</label>
                                                            </div>
                                                            {errors.profession && touched.profession ? (
                                                                <div className="error-message">{errors.profession}</div>
                                                            ) : null}
                                                        </div>
                                                        <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                            <SelectField
                                                                name="specialty"
                                                                label={HomeStore.loading ? "Loading..." : specialties?.length === 0 ? "No Specialty Found" : "Specialty"}
                                                                disabled={!values.profession || HomeStore.loading}
                                                                value={values.specialty}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                data={specialties}
                                                                optionLabel={"Name"}
                                                                optionValue={"Id"}
                                                                required={specialties?.length !== 0}
                                                            />
                                                        </div>
                                                        <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                            <Select
                                                                onChange={(e) => {
                                                                    setFieldValue("facility_id", e.id);
                                                                    if (e?.rates?.length > 0) {
                                                                        if (e?.rates?.find(x => x.profession_id == values?.profession) !== undefined) {
                                                                            setFieldValue("rate", e?.rates?.find(x => x.profession_id == values?.profession)?.cost);
                                                                        }
                                                                    }
                                                                    AdminStore.getClientsByFacility(e.id)
                                                                }}
                                                                placeholder={''}
                                                                label={'Select Facility'}
                                                                required={true}
                                                                value={facilityList?.filter(({ id }) => id === values.facility_id) || ""}
                                                                styles={colourStylesState}
                                                                isSearchable={true}
                                                                options={facilityList}
                                                                getOptionLabel={(option) => `${option.organization_name} - ${option.city}, ${option.state}`}
                                                                getOptionValue={(option) => option.id}
                                                                components={{ Control }}
                                                            />
                                                            {errors.facility_id && touched.facility_id ? (
                                                                <div className="error-message">{errors.facility_id}</div>
                                                            ) : null}
                                                        </div>
                                                        <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                            <Select
                                                                onChange={(e) => {
                                                                    setFieldValue("client_id", e?.users?.id);
                                                                }}
                                                                placeholder={''}
                                                                label={'Select F.User'}
                                                                required={true}
                                                                value={clientList?.filter((data) => data?.users?.id === values.client_id) || ""}
                                                                styles={colourStylesState}
                                                                isSearchable={true}
                                                                options={clientList}
                                                                getOptionLabel={(option) => `${option?.users.first_name} ${option?.users.last_name}`}
                                                                getOptionValue={(option) => option?.users?.id}
                                                                components={{ Control }}
                                                            />
                                                            {errors.client_id && touched.client_id ? (
                                                                <div className="error-message">{errors.client_id}</div>
                                                            ) : null}
                                                        </div>

                                                        <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                            <SelectField
                                                                name="category"
                                                                label={"Category"}
                                                                value={values.category}
                                                                onChange={(e) => {
                                                                    setFieldValue("category", e.target.value)
                                                                    setValues([])
                                                                    setDates([])
                                                                    setAllDates([])
                                                                    setFieldValue("tenure", "")
                                                                    setFieldValue("alldate", "")
                                                                    setFieldValue("alltime", "")
                                                                    setFieldValue("alltype", "")
                                                                }}
                                                                onBlur={handleBlur}
                                                                required
                                                                data={global.config.shiftCaregory}
                                                                optionLabel={"label"}
                                                                optionValue={"value"}
                                                            />
                                                            {errors.category && touched.category ? (
                                                                <div className="error-message">{errors.category}</div>
                                                            ) : null}
                                                        </div>
                                                        <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                            <div className="d-flex align-items-center">
                                                                <span>Break Time:</span>
                                                                <div className="d-flex align-items-center ms-3">
                                                                    <CFormCheck
                                                                        type="radio"
                                                                        name="break_time"
                                                                        id="30min"
                                                                        value="30"
                                                                        checked={values.break_time == 30}
                                                                        onChange={handleChange}
                                                                        label="30 minutes"
                                                                    />
                                                                    <CFormCheck
                                                                        className="ms-3 me-3"
                                                                        type="radio"
                                                                        name="break_time"
                                                                        id="1hr"
                                                                        value="60"
                                                                        checked={values.break_time == 60}
                                                                        onChange={handleChange}
                                                                        label="1 hour"
                                                                    />
                                                                    <CFormCheck
                                                                        type="radio"
                                                                        name="break_time"
                                                                        id="none"
                                                                        value="0"
                                                                        checked={values.break_time == 0}
                                                                        onChange={handleChange}
                                                                        label="None"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {values.category === 'TC' &&
                                                            <>
                                                                <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                                    <SelectField
                                                                        name="shift_type"
                                                                        label={"Shift Type"}
                                                                        value={values.shift_type}
                                                                        onChange={(event) => {
                                                                            setFieldValue("shift_type", event.target.value)
                                                                            setFieldValue(`start_time`, global.config.shiftTimeRange?.find(x => x.label === event.target.value)?.value?.start)
                                                                            setFieldValue(`end_time`, global.config.shiftTimeRange?.find(x => x.label === event.target.value)?.value?.end)
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        required
                                                                        data={global.config.shiftType}
                                                                        optionLabel={"name"}
                                                                        optionValue={"value"}
                                                                    />
                                                                    {errors.shift_type && touched.shift_type ? (
                                                                        <div className="error-message">{errors.shift_type}</div>
                                                                    ) : null}
                                                                </div>

                                                                <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                                    <InputField
                                                                        className={"did-floating-input"}
                                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                        type="text"
                                                                        name="pay_detail"
                                                                        label={"Pay Detail"}
                                                                        value={values.pay_detail}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        required={true}
                                                                    />
                                                                    {errors.pay_detail && touched.pay_detail ? (
                                                                        <div className="error-message">{errors.pay_detail}</div>
                                                                    ) : null}
                                                                </div>

                                                                <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                                    <InputField
                                                                        className={"did-floating-input"}
                                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                        type="date"
                                                                        name="start_date"
                                                                        label={"Start Date"}
                                                                        value={values.start_date}
                                                                        min={new Date().toISOString().split('T')[0]}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        required={true}
                                                                    />
                                                                    {errors.start_date && touched.start_date ? (
                                                                        <div className="error-message">{errors.start_date}</div>
                                                                    ) : null}
                                                                </div>
                                                                <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                                    <InputField
                                                                        className={"did-floating-input"}
                                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                        type="date"
                                                                        name="end_date"
                                                                        label={"End Date"}
                                                                        value={values.end_date}
                                                                        min={values.start_date ? values.start_date : new Date().toISOString().split('T')[0] || values.start_date}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        required={true}
                                                                    />
                                                                    {errors.end_date && touched.end_date ? (
                                                                        <div className="error-message">{errors.end_date}</div>
                                                                    ) : null}
                                                                </div>
                                                                <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                                    <InputField
                                                                        className={"did-floating-input"}
                                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                        type="time"
                                                                        name="start_time"
                                                                        label={"Start Time"}
                                                                        value={values.start_time}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        required={true}
                                                                    />
                                                                    {errors.start_time && touched.start_time ? (
                                                                        <div className="error-message">{errors.start_time}</div>
                                                                    ) : null}
                                                                </div>
                                                                <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                                    <InputField
                                                                        className={"did-floating-input"}
                                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                        type="time"
                                                                        name="end_time"
                                                                        label={"End Time"}
                                                                        value={values.end_time}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        required={true}
                                                                    />
                                                                    {errors.end_time && touched.end_time ? (
                                                                        <div className="error-message">{errors.end_time}</div>
                                                                    ) : null}
                                                                </div>
                                                                <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                                    <InputField
                                                                        className={"did-floating-input"}
                                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                        type="number"
                                                                        name="hours_per_week"
                                                                        label={"Guaranteed Hours Per Week"}
                                                                        value={values.hours_per_week}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        required={true}
                                                                        min={1}
                                                                    />
                                                                    {errors.hours_per_week && touched.hours_per_week ? (
                                                                        <div className="error-message">{errors.hours_per_week}</div>
                                                                    ) : null}
                                                                </div>
                                                                <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                                    <InputField
                                                                        className={"did-floating-input"}
                                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                        type="number"
                                                                        name="no_of_weeks"
                                                                        label={"# of Weeks"}
                                                                        value={values.no_of_weeks}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        required={true}
                                                                        min={1}
                                                                    />
                                                                    {errors.no_of_weeks && touched.no_of_weeks ? (
                                                                        <div className="error-message">{errors.no_of_weeks}</div>
                                                                    ) : null}
                                                                </div>
                                                                <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                                    <InputField
                                                                        className={"did-floating-input"}
                                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                        type="number"
                                                                        name="nop"
                                                                        label={"# of Openings"}
                                                                        value={values.nop}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        required={true}
                                                                        min={1}
                                                                    />
                                                                    {errors.nop && touched.nop ? (
                                                                        <div className="error-message">{errors.nop}</div>
                                                                    ) : null}
                                                                </div>
                                                                <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                                    <InputField
                                                                        className={"did-floating-input"}
                                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                        type="text"
                                                                        name="qualifications"
                                                                        label={"Qualifications"}
                                                                        value={values.qualifications}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        required={true}
                                                                    />
                                                                    {errors.qualifications && touched.qualifications ? (
                                                                        <div className="error-message">{errors.qualifications}</div>
                                                                    ) : null}
                                                                </div>
                                                            </>
                                                        }
                                                        <div className="col-sm-12 pb-lg-2 pb-md-2">
                                                            {values.category === 'PD' ?
                                                                <DatePicker
                                                                    disabled={values.category === null}
                                                                    required
                                                                    calendarPosition="top-left"
                                                                    placeholder="Select Dates"
                                                                    format="MM/DD/YYYY"
                                                                    inputClass="did-floating-dropdown"
                                                                    multiple
                                                                    value={multi?.sort((a, b) => new Date(a) - new Date(b))}
                                                                    minDate={new Date()}
                                                                    onChange={(e) => {
                                                                        setValues(e)
                                                                        setFieldValue('tenure', e)
                                                                        if (copiedOrder) {
                                                                            e?.map((x, index) => {
                                                                                if (!values?.alltype[index]) {
                                                                                    setFieldValue(`alltype[${index}]`, copiedOrder?.slot_type)
                                                                                    setFieldValue(`alltime[${index}]`, [global.config.shiftTimeRange?.find(x => x.label === copiedOrder?.slot_type)?.value?.start, global.config.shiftTimeRange?.find(x => x.label === copiedOrder?.slot_type)?.value?.end])
                                                                                }
                                                                                if (!values?.allqty[index]) {
                                                                                    setFieldValue(`allqty[${index}]`, copiedOrder?.slot_position)
                                                                                }

                                                                            })
                                                                        }
                                                                    }}
                                                                    plugins={[
                                                                        weekends(),
                                                                        <ClearButton setFiled={setFieldValue} position="bottom" />
                                                                    ]}
                                                                    zIndex={9999}
                                                                /> : values.category === "TR" ?
                                                                    <DatePicker
                                                                        disabled={values.category === null}
                                                                        placeholder="Select Start & End Date"
                                                                        calendarPosition="top-left"
                                                                        required
                                                                        format="MM/DD/YYYY"
                                                                        inputClass="did-floating-dropdown"
                                                                        multiple
                                                                        range
                                                                        numberOfMonths={2}
                                                                        rangeHover
                                                                        value={dates}
                                                                        minDate={new Date()}
                                                                        onChange={(dateObjects) => {
                                                                            setDates(dateObjects)
                                                                            setAllDates(dateObjects?.map((date) => getAllDatesInRange([date[0], date[1]]))?.flat())
                                                                            setFieldValue('tenure', dateObjects?.map((date) => getAllDatesInRange([date[0], date[1]]))?.flat())
                                                                            if (copiedOrder) {
                                                                                dateObjects?.map((date) => getAllDatesInRange([date[0], date[1]]))?.flat()?.map((x, index) => {
                                                                                    if (!values?.alltype[index]) {
                                                                                        setFieldValue(`alltype[${index}]`, copiedOrder?.slot_type)
                                                                                        setFieldValue(`alltime[${index}]`, [global.config.shiftTimeRange?.find(x => x.label === copiedOrder?.slot_type)?.value?.start, global.config.shiftTimeRange?.find(x => x.label === copiedOrder?.slot_type)?.value?.end])
                                                                                    }
                                                                                    if (!values?.allqty[index]) {
                                                                                        setFieldValue(`allqty[${index}]`, copiedOrder?.slot_position)
                                                                                    }

                                                                                })
                                                                            }
                                                                        }}
                                                                        plugins={[
                                                                            weekends(),
                                                                            <ClearButton setFiled={setFieldValue} position="bottom" />
                                                                        ]}
                                                                        zIndex={9999}
                                                                    /> : ""}
                                                        </div>

                                                        <div>
                                                            {/* FOR TR */}
                                                            {allDates?.length > 0 &&
                                                                <div>
                                                                    <div className="d-flex justify-content-start mt-2 mb-2"><span className="">Shifts
                                                                        Approx. Cost:<b className="text-success"> {values.rate ? ("$" + values.rate) + "/hr" : "N/A"}</b>
                                                                        ,&nbsp;Approx. Total Cost:<b className="text-danger"> ${calculateDomIdSum()}</b>
                                                                    </span>
                                                                    </div>
                                                                    <ul className="list-unstyled row">
                                                                        <FieldArray name="tenure">
                                                                            {({ push, remove, form }) => (
                                                                                values?.tenure?.sort((a, b) => new Date(a) - new Date(b))?.map((date, index) =>
                                                                                    <div className="card p-2 col-6 mb-3 border" key={index}>
                                                                                        <div className="row align-items-center">
                                                                                            <div className="col-5 pt-1 pb-2" >
                                                                                                <SelectField
                                                                                                    id={"slot-type"}
                                                                                                    label={"Shift Type"}
                                                                                                    name={`alltype[${index}]`}
                                                                                                    onChange={(event) => {
                                                                                                        setFieldValue(`alltype[${index}]`, event.target.value)
                                                                                                        setFieldValue(`alltime[${index}]`, [global.config.shiftTimeRange?.find(x => x.label === event.target.value)?.value?.start, global.config.shiftTimeRange?.find(x => x.label === event.target.value)?.value?.end])
                                                                                                    }}
                                                                                                    onBlur={handleBlur}
                                                                                                    value={values?.alltype && values?.alltype[index]}
                                                                                                    required
                                                                                                    data={global.config.shiftType}
                                                                                                    optionLabel={"name"}
                                                                                                    optionValue={"value"}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="col-5 pt-1 pb-2" >
                                                                                                {values?.alltime[index]?.indexOf(undefined) === -1 && <span className="text-success me-3"><i className="fas fa-business-time"></i> {calculateLunch(values?.alltime[index][0], values?.alltime[index][1], values.break_time)}</span>}
                                                                                                {values.break_time ? <span className="text-success me-3"><i className="fas fa-clock"></i> {values.break_time} Min</span> : ""}
                                                                                                {(values.rate && values?.alltime[index]?.length === 2) ? <span className="text-success " id="per-cost"><i className="fas fa-dollar-sign"></i> {(values.rate * calculateTotalHr(values?.alltime[index][0], values?.alltime[index][1], values.break_time)?.toFixed(2))}</span> : ""}
                                                                                            </div>
                                                                                        </div>
                                                                                        <li>
                                                                                            <div className="d-flex align-items-center mb-2">
                                                                                                <div className="border border-primary all-slot-border">
                                                                                                    <input className="border-0 start-date-width" id={`alldate[${index}]`} name={`alldate[${index}]`} value={date?.format()} />
                                                                                                    <Field id={`alltime[${index}]`} name={`alltime[${index}]`} render={({ field, form }) => <TimeRangePickerField  {...field} {...form} firstTime={values?.alltime?.length > 0 && values?.alltime[0]} index={index} />} />
                                                                                                    <input
                                                                                                        className="slot-quantity-width"
                                                                                                        placeholder="No. Of Positions"
                                                                                                        type="number"
                                                                                                        required
                                                                                                        name={`allqty[${index}]`}
                                                                                                        onChange={(event) => {
                                                                                                            setFieldValue(`allqty[${index}]`, event.target.value)
                                                                                                        }}
                                                                                                        onBlur={handleBlur}
                                                                                                        value={values?.allqty && values?.allqty[index]}
                                                                                                        min="1"
                                                                                                    />
                                                                                                </div>
                                                                                                <div className="btn position-absolute top-0 end-0" onClick={() => {
                                                                                                    setAllDates([
                                                                                                        ...allDates.slice(0, index),
                                                                                                        ...allDates.slice(index + 1)
                                                                                                    ]);
                                                                                                    setDates([
                                                                                                        ...allDates.slice(0, index),
                                                                                                        ...allDates.slice(index + 1)
                                                                                                    ]);
                                                                                                    (values?.alltime?.length !== 0 && values?.alltime?.length >= index) && values?.alltime?.splice(index, 1);
                                                                                                    (values?.allqty?.length !== 0 && values?.allqty?.length >= index) && values?.allqty?.splice(index, 1);
                                                                                                    (values?.alltype?.length !== 0 && values?.alltype?.length >= index) && values?.alltype?.splice(index, 1);
                                                                                                    remove(index)
                                                                                                }}>
                                                                                                    <i className="fas fa-times-circle text-danger" aria-hidden="true"></i>
                                                                                                </div>

                                                                                                <div className="btn" onClick={() => {
                                                                                                    const elementToCopy = values.tenure[index];
                                                                                                    const elementToCopyTime = [undefined, undefined];
                                                                                                    setFieldValue("tenure", [...values?.tenure, elementToCopy]);
                                                                                                    (values?.alltime && values?.alltime?.length !== 0 && values?.alltime?.length >= index) && values?.alltime?.splice(index + 1, 0, elementToCopyTime);
                                                                                                    (values?.allqty && values?.allqty?.length !== 0 && values?.allqty?.length >= index) && values?.allqty?.splice(index + 1, 0, values?.allqty[0]);
                                                                                                    (values?.alltype && values?.alltype?.length !== 0 && values?.alltype?.length >= index) && values?.alltype?.splice(index + 1, 0, values?.alltype[0]);
                                                                                                    setAllDates([...allDates, elementToCopy])
                                                                                                    setDates([...allDates, elementToCopy])
                                                                                                }}>
                                                                                                    <div className="btn btn-outline-success pt-1 pb-1"><i className="fa fa-plus" aria-hidden="true"></i></div>
                                                                                                </div>
                                                                                                <div className="btn btn-outline-success pt-1 pb-1" onClick={() => {
                                                                                                    if (values?.alltime[index]?.length === 2 && !values?.alltime[index]?.some(item => item === null || item === undefined)) {
                                                                                                        values?.tenure?.map((item, ind) => {
                                                                                                            setFieldValue(`alltime[${ind}]`, values?.alltime[index]);
                                                                                                            if (values?.allqty?.length && (values?.allqty[index] !== null || values?.allqty[index] !== undefined)) {
                                                                                                                setFieldValue(`allqty[${ind}]`, values?.allqty[index])
                                                                                                            }
                                                                                                            if (values?.alltype?.length && (values?.alltype[index] !== null || values?.alltype[index] !== undefined)) {
                                                                                                                setFieldValue(`alltype[${ind}]`, values?.alltype[index])
                                                                                                            }
                                                                                                        })
                                                                                                    } else {
                                                                                                        toast.warn("Please select start end time")
                                                                                                    }
                                                                                                }} data-toggle="tooltip" data-placement="right" title="Copy Shift Type,Start Time,End Time & No Of Positions">
                                                                                                    <i className="far fa-copy pointer"></i>
                                                                                                </div>   </div>
                                                                                            <div className="col-12">
                                                                                                <InputField
                                                                                                    className={"did-floating-input"}
                                                                                                    type="text"
                                                                                                    name={`allcomment[${index}]`}
                                                                                                    onChange={(event) => {
                                                                                                        setFieldValue(`allcomment[${index}]`, event.target.value)
                                                                                                    }}
                                                                                                    label={"Comments For Caregiver"}
                                                                                                    value={values?.allcomment && values?.allcomment[index]}
                                                                                                    onBlur={handleBlur}
                                                                                                />
                                                                                            </div>
                                                                                        </li>
                                                                                    </div>
                                                                                )
                                                                            )}
                                                                        </FieldArray>

                                                                    </ul>
                                                                </div>
                                                            }
                                                            {/* FOR PD */}
                                                            {
                                                                multi?.length > 0 && multi &&
                                                                <div>
                                                                    <div className="d-flex justify-content-start mt-2 mb-2"><span className="">Shifts
                                                                        Approx. Cost:<b className="text-success"> {values.rate ? ("$" + values.rate) + "/hr" : "N/A"}</b>
                                                                        ,&nbsp;Approx. Total Cost:<b className="text-danger"> ${calculateDomIdSum()}</b>
                                                                    </span>
                                                                    </div>
                                                                    <ul className="list-unstyled row">
                                                                        <FieldArray name="tenure">
                                                                            {({ push, remove, form }) => (
                                                                                values?.tenure?.sort((a, b) => new Date(a) - new Date(b))?.map((date, index) =>
                                                                                    <div className="card p-2 col-6 mb-3 border" key={index}>
                                                                                        <div className="row align-items-center">
                                                                                            <div className="col-5 pt-1 pb-2" >
                                                                                                <SelectField
                                                                                                    id={"slot-type"}
                                                                                                    label={"Shift Type"}
                                                                                                    name={`alltype[${index}]`}
                                                                                                    onChange={(event) => {
                                                                                                        setFieldValue(`alltype[${index}]`, event.target.value)
                                                                                                        setFieldValue(`alltime[${index}]`, [global.config.shiftTimeRange?.find(x => x.label === event.target.value)?.value?.start, global.config.shiftTimeRange?.find(x => x.label === event.target.value)?.value?.end])
                                                                                                    }}
                                                                                                    onBlur={handleBlur}
                                                                                                    value={values?.alltype ? values?.alltype[index] : null}
                                                                                                    required
                                                                                                    data={global.config.shiftType}
                                                                                                    optionLabel={"name"}
                                                                                                    optionValue={"value"}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="col-5 pt-1 pb-2" >
                                                                                                {values?.alltime[index]?.indexOf(undefined) === -1 && <span className="text-success me-3"><i className="fas fa-business-time"></i> {calculateLunch(values?.alltime[index][0], values?.alltime[index][1], values.break_time)}</span>}
                                                                                                {values.break_time ? <span className="text-success me-3"><i className="fas fa-clock"></i> {values.break_time} Min</span> : ""}
                                                                                                {(values.rate && values?.alltime[index]?.length === 2) ? <span className="text-success" id="per-cost"><i className="fas fa-dollar-sign"></i> {(values?.allqty[index] * values.rate * calculateTotalHr(values?.alltime[index][0], values?.alltime[index][1], values.break_time)?.toFixed(2))}</span> : ""}
                                                                                            </div>
                                                                                        </div>
                                                                                        <li>
                                                                                            <div className="d-flex align-items-center mb-2">
                                                                                                <div className="border border-primary all-slot-border ">
                                                                                                    <input className="border-0 start-date-width" readOnly id={`alldate[${index}]`} name={`alldate[${index}]`} value={date?.format()} />
                                                                                                    <Field name={`alltime[${index}]`}>
                                                                                                        {({ field, form }) => (
                                                                                                            <TimeRangePickerField {...field} {...form} index={index} />
                                                                                                        )}
                                                                                                    </Field>
                                                                                                    <input
                                                                                                        // className="slot-qty"
                                                                                                        className="slot-quantity-width"
                                                                                                        placeholder="No. Of Positions"
                                                                                                        type="number"
                                                                                                        required
                                                                                                        name={`allqty[${index}]`}
                                                                                                        onChange={(event) => {
                                                                                                            setFieldValue(`allqty[${index}]`, event.target.value)
                                                                                                        }}
                                                                                                        onBlur={handleBlur}
                                                                                                        value={values?.allqty ? values?.allqty[index] : null}
                                                                                                        min="1"
                                                                                                    />
                                                                                                </div>
                                                                                                {/* DELETE */}
                                                                                                <div className="btn position-absolute top-0 end-0" onClick={() => {
                                                                                                    setValues([
                                                                                                        ...multi.slice(0, index),
                                                                                                        ...multi.slice(index + 1)
                                                                                                    ]);
                                                                                                    (values?.alltime?.length !== 0 && values?.alltime?.length >= index) && values?.alltime?.splice(index, 1);
                                                                                                    (values?.allqty?.length !== 0 && values?.allqty?.length >= index) && values?.allqty?.splice(index, 1);
                                                                                                    (values?.alltype?.length !== 0 && values?.alltype?.length >= index) && values?.alltype?.splice(index, 1);
                                                                                                    remove(index)
                                                                                                }}>
                                                                                                    <i className="fas fa-times-circle text-danger" aria-hidden="true"></i>
                                                                                                </div>
                                                                                                {<div className="btn" onClick={() => {
                                                                                                    const elementToCopy = values.tenure[index];
                                                                                                    const elementToCopyTime = [undefined, undefined];
                                                                                                    setFieldValue("tenure", [...values?.tenure, elementToCopy]);
                                                                                                    (values?.alltime && values?.alltime?.length !== 0 && values?.alltime?.length >= index) && values?.alltime?.splice(index + 1, 0, elementToCopyTime);
                                                                                                    (values?.allqty && values?.allqty?.length !== 0 && values?.allqty?.length >= index) && values?.allqty?.splice(index + 1, 0, values?.allqty[0]);
                                                                                                    (values?.alltype && values?.alltype?.length !== 0 && values?.alltype?.length >= index) && values?.alltype?.splice(index + 1, 0, values?.alltype[0]);
                                                                                                    setValues([...multi, elementToCopy])
                                                                                                }}>
                                                                                                    <div className="btn btn-outline-success pt-1 pb-1"><i className="fa fa-plus" aria-hidden="true"></i></div>
                                                                                                </div>}
                                                                                                <div className="btn btn-outline-success pt-1 pb-1" onClick={() => {
                                                                                                    if (values?.alltime[index]?.length === 2 && !values?.alltime[index].some(item => item === null || item === undefined)) {
                                                                                                        values?.tenure?.map((item, ind) => {
                                                                                                            setFieldValue(`alltime[${ind}]`, values?.alltime[index]);
                                                                                                            if (values?.allqty?.length && (values?.allqty[index] !== null || values?.allqty[index] !== undefined)) {
                                                                                                                setFieldValue(`allqty[${ind}]`, values?.allqty[index])
                                                                                                            }
                                                                                                            if (values?.alltype?.length && (values?.alltype[index] !== null || values?.alltype[index] !== undefined)) {
                                                                                                                setFieldValue(`alltype[${ind}]`, values?.alltype[index])
                                                                                                            }
                                                                                                        })
                                                                                                    } else {
                                                                                                        toast.warn("Please select start end time")
                                                                                                    }
                                                                                                }} data-toggle="tooltip" data-placement="right" title="Copy Shift Type,Start Time,End Time & No Of Positions">
                                                                                                    <i className="far fa-copy pointer"></i>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-12">
                                                                                                <InputField
                                                                                                    className={"did-floating-input"}
                                                                                                    type="text"
                                                                                                    name={`allcomment[${index}]`}
                                                                                                    onChange={(event) => {
                                                                                                        setFieldValue(`allcomment[${index}]`, event.target.value)
                                                                                                    }}
                                                                                                    label={"Comments For Caregiver"}
                                                                                                    value={values?.allcomment ? values?.allcomment[index] : null}
                                                                                                    onBlur={handleBlur}
                                                                                                />
                                                                                            </div>
                                                                                        </li>
                                                                                    </div>

                                                                                )
                                                                            )}
                                                                        </FieldArray>
                                                                    </ul>
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="col-12">
                                                            <label className="pb-2 label">Comments For Staffers Management</label>
                                                            <div className="form-floating">
                                                                <textarea className="form-control"
                                                                    placeholder=""
                                                                    name={'description'}
                                                                    value={values.description}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    style={{ height: 100 }}>
                                                                </textarea>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-center pt-1">
                                                            <button className="btn btn-primary w-auto pe-5 ps-5 p-2" type="submit">Submit</button>
                                                            <button className="btn btn btn-outline-secondary w-auto pe-5 ps-5 p-2 ms-3" onClick={() => setCopiedOrder()} type="reset">Reset</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            )
                                        }
                                    </Formik>

                                </>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <CModal
                size="lg"
                visible={pastOrders}
                onClose={() => setPastOrders(false)}
                scrollable
            >
                <CModalHeader>
                    <CModalTitle>Past ({staffingList?.length}) Orders</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <CTable>
                        <CTableHead>
                            <CTableRow>
                                <CTableHeaderCell scope="col">Ref.</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Facility</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Shift Date/Time</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Shift Type</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Profession-Specialty</CTableHeaderCell>
                                <CTableHeaderCell scope="col">City</CTableHeaderCell>
                                <CTableHeaderCell scope="col">State</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Manage</CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>
                        <CTableBody>
                            {staffingList && staffingList?.map((shift, ind) => {
                                return <CTableRow key={ind + "list"}>
                                    <CTableHeaderCell scope="row">{shift?.id}</CTableHeaderCell>
                                    <CTableDataCell>{shift?.facility?.organization_name}</CTableDataCell>
                                    <CTableDataCell>{getShiftRange(shift, shift?.assignments)}</CTableDataCell>
                                    <CTableDataCell>{shift?.category === 'TC' ? "Traveler" : global.config.shiftCaregory?.find(x => x.value === shift?.category)?.label}</CTableDataCell>
                                    <CTableDataCell>{shift?.specialty_name ? shift?.specialty_name : degree?.find(deg => deg.Id == shift?.profession)?.Name}</CTableDataCell>
                                    <CTableDataCell>{shift?.facility?.city}</CTableDataCell>
                                    <CTableDataCell>{shift?.facility?.state}</CTableDataCell>
                                    <CTableDataCell><CButton onClick={() => setCopyOrder(shift)}><i className="far fa-copy pe-2"></i>Copy</CButton></CTableDataCell>
                                </CTableRow>
                            })}
                        </CTableBody>
                    </CTable>
                </CModalBody>
            </CModal>



            <Spinner isLoading={AdminStore.loading} />
        </React.Fragment >
    )
    function TimeRangePickerField(props) {
        const hnadleStart = (e) => {
            const startTime = e.target.value;
            if (props?.values?.alltime?.length === 0) {
                props.setFieldValue(props.name, [startTime, undefined]);
            } else {
                props.setFieldValue(props.name, [startTime, props?.values?.alltime[props.index]?.length === 2 ? props?.values?.alltime[props.index][1] : undefined]);
            }
        }
        const hnadleEnd = (e) => {
            const endTime = e.target.value;
            if (props?.values?.alltime?.length === 0) {
                props.setFieldValue(props.name, [undefined, endTime]);
            } else {
                if (props?.values?.alltime[props.index][0] !== endTime) {
                    props.setFieldValue(props.name, [props?.values?.alltime[props.index][0], endTime]);
                } else {
                    toast.error("The start and finish timings must not be the same!")
                }
            }
        }
        return (
            <>
                <input
                    required
                    type="time"
                    id="start"
                    className="border-0"
                    name="start"
                    value={
                        (props?.values?.alltime?.length !== 0 &&
                            props?.values?.alltime[props?.index])
                            ? props?.values?.alltime[props?.index][0]
                            : null
                    }
                    onChange={hnadleStart}
                />
                <span className="slot-time-to">To</span>
                <input
                    required
                    type="time"
                    id="end"
                    className="border-0"
                    name="end"
                    disabled={
                        ((props?.values?.alltime?.length !== 0 &&
                            props?.values?.alltime[props?.index] &&
                            props?.values?.alltime[props?.index][0]) || null)
                            ? false : true}
                    value={
                        (props?.values?.alltime[props?.index]?.length === 2)
                            ? props?.values?.alltime[props.index][1]
                            : null
                    }
                    onChange={hnadleEnd}
                />
            </>

        );
    }
});

export default StaffingRequestAdminPage;

const getShiftRange = (shift, slot) => {
    const dates = slot?.map(itm => new Date(itm?.slot_date));
    // Find the minimum and maximum dates
    const minDate = new Date(Math?.min.apply(null, dates));
    const maxDate = new Date(Math?.max.apply(null, dates));

    // Function to pad a number with leading zeros
    const zeroPad = (value, length) => {
        return value.toString().padStart(length, "0");
    };

    // Format the dates as strings with zero-padded month and day values
    const minDateString = `${zeroPad(minDate.getMonth() + 1, 2)}/${zeroPad(minDate.getDate(), 2)}/${minDate.getFullYear()}`;
    const maxDateString = `${zeroPad(maxDate.getMonth() + 1, 2)}/${zeroPad(maxDate.getDate(), 2)}/${maxDate.getFullYear()}`;

    return slot?.length > 0 ? <span className=" link-info">{minDateString} To {maxDateString}</span> :
        shift?.category === 'TC' ? <span className=" link-info"> + {moment(shift?.start_date, 'YYYY-MM-DD').format('MM/DD/YYYY')} To {moment(shift?.end_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}</span> :
            "No Slot Available";
}