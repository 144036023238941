/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import 'react-spring-bottom-sheet/dist/style.css';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { toJS } from 'mobx';
import Spinner from "@components/Spinner";
import { InputField } from "@elements/index"
import SelectField from "@elements/select/SelectField";
import swal from '@sweetalert/with-react';
import { useMediaQuery } from 'react-responsive';
import HeaderPage from "../layout/HeaderPage";
import DrawerMenuPage from "../layout/DrawerMenuPage";
import AdminStore from "@store/AdminStore";
import HomeStore from "@store/HomeStore";
import { determineDiscipline } from '@utils/constant/constant';
import ToolbarDropdown from "@elements/select/ToolbarDropdown";
import { momentLocalizer } from 'react-big-calendar'
import CustomModal from '@components/modals/CustomModal';
import { slotStatusList } from '@utils/constant/constant';
import { printCalendar, downloadCalendar, AddShiftCaregiver } from "../commonFunctions"
import { AddShiftSchema } from '@utils/schemas/allSchema';
import MyCalendar from '@elements/calendar/MyCalendar';
import { CModal, CModalBody, CModalHeader, CModalTitle } from '@coreui/react-pro';
import DatePicker, { DateObject, getAllDatesInRange } from "react-multi-date-picker"
import { CustomEventCaregiver, ViewEventCaregiver, TimeRangePickerField, Control } from "../commonFunctions"
import { toast } from 'react-toastify';
const today = moment(new Date());
const localizer = momentLocalizer(moment)

const ShiftCalendarByCaregiver = observer(() => {
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const [shiftList, setShiftList] = useState([]);
    const [degree, setDegree] = useState([]);
    const [caregiverData, setCaregiverData] = useState();
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selectedOption, setSelectedOption] = useState();
    const [isOpenAddShift, setIsOpenShift] = useState(false);
    const [selectedDate, setSelectedDate] = useState();
    const [multi, setValues] = useState([])
    const [dates, setDates] = useState([])
    const [allDates, setAllDates] = useState([])
    const [specialties, setSpecialties] = useState([]);
    const [clientList, setClientList] = useState([]);
    const [editSlot, setEditSlot] = useState({
        slot_date: '',
        slot_type: '',
        slot_start_time: '',
        slot_end_time: ''
    });
    const [facilityList, setFacilityList] = useState([]);

    useEffect(() => {
        const encodedData = new URLSearchParams(window.location.search);
        try {
            const data = JSON.parse(decodeURIComponent(encodedData.get("data")));
            setCaregiverData(data);
        } catch (error) {
            console.error('Error decoding JSON data:', error);
        }
        AdminStore.getAllFacility();
    }, []);

    useEffect(() => {

        setClientList(toJS(AdminStore?.data?.clientsByFacility)?.filter(item => item.users !== null))
    }, [AdminStore?.data?.clientsByFacility]);

    useEffect(() => {
        setFacilityList(toJS(AdminStore?.data?.allFacilityList))
    }, [AdminStore?.data?.allFacilityList]);
    
    useEffect(() => {
        let pendingShifts = toJS(AdminStore?.data?.shiftByCaregiver);
        const mergedShifts = pendingShifts?.contracts?.concat(pendingShifts?.shifts);
        // const upcoming = mergedShifts?.filter(x => x?.status === 2);
        const formattedEvents = mergedShifts?.map(event => {
            if (event?.category === 'TC') {
                let startDate = new Date(`${event.start_date} ${event.start_time}`);
                let endDate = new Date(`${event.end_date} ${event.end_time}`);
                return {
                    id: event.id,
                    data: event,
                    title: determineDiscipline(event?.request?.specialty_name, event?.request?.profession, degree),
                    organization_name: event?.user?.organization_name,
                    start: startDate,
                    end: endDate,
                    allDay: true,
                    color: event?.status === 2 ? "black" : event?.status === 5 ? 'black' : '#FFBA00',
                    // colorEvento:'transparent'
                    colorEvento: event?.status === 1 ? '#1F2FA' : event?.status === 5 ? '#08bdff' : (event?.status === 2) ? '#55A5C3' : '#6c757d', // Adjust text color for better visibility
                    // You can include other properties as needed
                };
            } else {
                let startDate = new Date(`${event.slot_date} ${event.slot_start_time}`);
                let endDate = new Date(`${event.slot_date} ${event.slot_end_time}`);
                return {
                    id: event.id,
                    data: event,
                    title: determineDiscipline(event?.request?.specialty_name, event?.request?.profession, degree),
                    organization_name: event?.request?.user?.organization_name,
                    start: startDate,
                    end: endDate,
                    allDay: false,
                    color: (event?.status === 5 || event?.status === 6 || event?.status === 3) ? "red" : (event?.status === 2) ? '#0873ff' : (event?.status === 7) ? 'black' : '#F16D14',
                    colorEvento: '#E1F2FA',
                    // colorEvento: event?.status === 1 ? 'gray' : event?.status === 2 ? '#87CEEB' : (event?.status === 3 || event?.status === 5 || event?.status === 6) ? '#dc3545' : '#6c757d', // Adjust text color for better visibility
                    // You can include other properties as needed
                };
            }
        });

        setShiftList(formattedEvents);
    }, [AdminStore?.data?.shiftByCaregiver]);

    useEffect(() => {
        AdminStore.getShiftByCaregiver({ "staff_id": caregiverData?.id });
    }, [caregiverData]);

    useEffect(() => {
        setDegree(HomeStore?.data?.degreeList)
    }, [HomeStore?.data?.degreeList]);
    
    useEffect(() => {
        setSpecialties(HomeStore?.data?.dropDown)
    }, [HomeStore?.data?.dropDown]);

    const refreshList = () => {
        setIsOpenShift(false)
        setSelectedDate()
        setSelectedEvent(false);
        AdminStore.getShiftByCaregiver({ "staff_id": caregiverData?.id });
    }
    const handleEventClick = (event) => {
        setEditSlot(prevTimeSlot => ({
            ...prevTimeSlot,
            slot_date: event?.data?.slot_date,
            slot_type: event?.data?.slot_type,
            slot_start_time: event?.data?.slot_start_time,
            slot_end_time: event?.data?.slot_end_time
        }));
        setSelectedEvent(event);

    };
    const handleCloseModal = () => {
        setSelectedEvent(false);

    };
    const onChangeSlotStatus = (slotData, mode) => {
        if (mode === 'approve') {
            swal({
                title: "Are you sure to approve this shift?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        const slotDetails = [{ "id": slotData?.data?.id, "code": 2 }]
                        let param = {
                            'slots': JSON.stringify(slotDetails)
                        }
                        AdminStore.updateSlots(param, refreshList)
                    }
                });
        }
        if (mode === 'reject') {
            swal({
                title: "Are you sure to reject this shift?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        const slotDetails = [{ "id": slotData?.data?.id, "code": 3 }]
                        let param = {
                            'slots': JSON.stringify(slotDetails)
                        }
                        AdminStore.updateSlots(param, refreshList)
                    }
                });
        }
        if (mode === 'cancel') {//Cancel by Facility
            swal({
                content: (
                    <div>
                        <h3>Are you sure to cancel this shift by facility?</h3>
                        <p>After this action, you can't roll back!</p>
                        <input
                            type="text"
                            placeholder="Enter the reason for cancellation..."
                            id="cancelReason"
                            className='did-floating-dropdown'
                        />
                    </div>
                ),
                buttons: {
                    cancel: {
                        text: 'Cancel',
                        value: null,
                        visible: true,
                        className: '',
                        closeModal: true,
                    },
                    confirm: {
                        text: 'OK',
                        value: true,
                        visible: true,
                        className: '',
                        closeModal: true,
                    },
                },
                icon: 'warning', // Add alert icon
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        const reason = document.getElementById('cancelReason')?.value;
                        let param = {
                            slot_id_facility: [slotData?.data?.id],
                            slot_id_caregiver: [],
                            slot_id_delete: [],
                            reason: reason,
                        };
                        AdminStore.cancelSlot(param, refreshList);
                    }
                });
        }
        if (mode === 'cancel-caregiver') {
            swal({
                content: (
                    <div>
                        <h3>Are you sure to cancel this shift by caregiver?</h3>
                        <p>After this action, you can't roll back!</p>
                        <input
                            type="text"
                            placeholder="Enter the reason for cancellation..."
                            id="cancelReason"
                            className='did-floating-dropdown'
                        />
                    </div>
                ),
                buttons: {
                    cancel: {
                        text: 'Cancel',
                        value: null,
                        visible: true,
                        className: '',
                        closeModal: true,
                    },
                    confirm: {
                        text: 'OK',
                        value: true,
                        visible: true,
                        className: '',
                        closeModal: true,
                    },
                },
                icon: 'warning', // Add alert icon
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        const reason = document.getElementById('cancelReason')?.value;
                        let param = {
                            slot_id_facility: [],
                            slot_id_caregiver: [slotData?.data?.id],
                            slot_id_delete: [],
                            reason: reason,
                        };

                        AdminStore.cancelSlot(param, refreshList);
                    }
                });
        }
        if (mode === 'delete') {
            swal({
                title: "Are you sure to delete this shift?",
                text: "After an delete, You can't roll back an delete.",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        let param = {
                            slot_id_facility: [],
                            slot_id_caregiver: [],
                            slot_id_delete: [slotData?.data?.id],
                        }
                        AdminStore.cancelSlot(param, refreshList)
                    }
                });
        }
    }

    const handleSelectChange = (event) => {
        setSelectedOption(event);
    }

    //ADD SHIFT

    const handleDoubleClickEvent = async (event) => {
        setSelectedEvent(false)
        setValues()
        setSelectedDate()
        setIsOpenShift()
        const date = moment(event);
        const month = date.month() + 1; // Adding 1 since moment months are zero-indexed
        const day = date.format('DD');
        const year = date.format('YYYY');
        await setValues([new DateObject({ month, day, year, locale: 'en', format: 'MM/DD/YYYY' })])
        await setSelectedDate(new DateObject({ month, day, year, locale: 'en', format: 'MM/DD/YYYY' }))
        setIsOpenShift(true);
    }
    const CustomDayWrapper = ({ children, value }) => {
        return (
            <div className='rbc-day-bg'>
                {children}
                {moment(value).isSameOrAfter(today, 'day') && <i className='position-absolute fas fa-plus-circle pointer text-success' data-toggle="tooltip" data-placement="left" title="Add Shift" style={{ zIndex: 99 }} onClick={() => handleDoubleClickEvent(value)}>
                </i>}
            </div>
        );
    };
    const onDismissAddShift = () => {
        setIsOpenShift(false);
    }

    const assignShift = (values) => {
        var check;
        var finalslot;
        if (values.category !== "TC") {
            finalslot = values?.tenure?.map((x, index) => {
                const copiedObjects = Array.from({ length: values.allqty[index] }, () => Object.assign({}, { "date": x.format(), "time": values.alltime[index] ? values.alltime[index] : values.alltime[0], "remark": values?.allcomment ? values?.allcomment[index] || '' : '', "slot_type": values?.alltype ? values?.alltype[index] || '' : '' }));
                return copiedObjects
            })
            finalslot = [].concat(...finalslot);
            check = finalslot.some((obj) => !Object.values(obj).every((val) => val !== undefined));
        } else {
            check = false
        }
        if (check) {
            toast.warn("Please select date & time!")
        } else {
            let param = {
                profession: values.profession,
                specialty: values.specialty,
                client_id: values.client_id,
                facility_id: values.facility_id,
                staff_id: values.staff_id,
                category: values.category,
                specialty_name: specialties?.find(x => x.Id == values.specialty)?.Name,
                slots: JSON.stringify(finalslot),
                shift_type: values.shift_type,
                description: values.description,
                nop: values.nop,
                hours_per_week: values.hours_per_week,
                no_of_weeks: values.no_of_weeks,
                pay_details: values.pay_detail,
                start_date: values.start_date,
                end_date: values.end_date,
                start_time: values.start_time,
                end_time: values.end_time,
                qualifications: values.qualifications,
                rate: values.rate,
            }
            AdminStore.setStaffingRequest(param, refreshList);
        }
    }
    function ClearButton({ setFiled }) {
        return <div>
            <button
                className="btn btn-sm btn-primary"
                type="button"
                onClick={() => {
                    setDates([])
                    setAllDates([])
                    setFiled('tenure', [])
                }}
            >
                Clear
            </button>
        </div>;
    }
    // Handle the change in the MultiDatePicker
    const handleDateChange = (newDate) => {
        setEditSlot(prevState => ({
            ...prevState,
            slot_date: newDate?.format("MM/DD/YYYY")
        }));
    };
    const handleSlotTypeChange = (newDate) => {
        newDate?.persist();
        setEditSlot(prevState => ({
            ...prevState,
            slot_type: newDate?.target.value,
            slot_start_time: global.config.shiftTimeRange?.find(x => x.label === newDate.target.value)?.value?.start,
            slot_end_time: global.config.shiftTimeRange?.find(x => x.label === newDate.target.value)?.value?.end

        }));
    };

    const handleSlotStartChange = (newDate) => {
        newDate?.persist();
        setEditSlot(prevState => ({
            ...prevState,
            slot_start_time: newDate?.target.value
        }));
    };
    const handleSlotEndChange = (newDate) => {
        newDate?.persist();
        setEditSlot(prevState => ({
            ...prevState,
            slot_end_time: newDate?.target.value
        }));
    };
    //SLOT EDIT API CALL 
    const slotChanges = (slot) => {
        swal({
            title: "Are you sure to update this slot?",
            text: "After this action, you can't roll back!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    let newSlot = [{ "id": slot.data.id, "staff_id": slot?.data?.staff_id, "slot_status": slot?.data?.status, "date": editSlot?.slot_date, "time": [editSlot?.slot_start_time, editSlot?.slot_end_time], "slot_type": editSlot?.slot_type }];
                    let param = {
                        request_id: slot?.data?.shift_id,
                        slots: JSON.stringify(newSlot)
                    }
                    AdminStore.updateSlotData(param, navigationCallBack)
                }
            });
    }
    const navigationCallBack = () => {
        toast.success("Successfully updated!");
        refreshList()
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>Staffers LLC | Shift List</title>
            </Helmet>
            {/* <body> */}
            <div className="container-scroller">
                <HeaderPage />
                <div className="container-fluid page-body-wrapper">
                    <DrawerMenuPage />
                    <div className="main-panel" style={{ right: 0, position: "absolute" }}>
                        <div className="row content-wrapper p-3 pb-0 bg-white" id="header">
                            <h2 className="col-11 mb-2 mb-lg-0 page-title">
                                <h3><i className="far fa-id-card"></i> Caregiver Information</h3>
                                <div className="card-title row">
                                    <div className="col-12 col-sm-auto mb-2">
                                        <span>Bluesky Id: {caregiverData?.id},</span>
                                    </div>
                                    <div className="col-12 col-sm-auto mb-2">
                                        <span>Caregiver Name: {caregiverData?.first_name} {caregiverData?.last_name},</span>
                                    </div>
                                    {caregiverData?.profession && (
                                        <div className="col-12 col-sm-auto mb-2">
                                            <span>Caregiver Profession: {
                                                JSON.parse(caregiverData?.profession)?.map((prof, index) => (
                                                    <span key={index}>
                                                        {determineDiscipline(prof?.specialties_name, prof?.profession, degree)}
                                                        {index !== JSON.parse(caregiverData?.profession).length - 1 && ', '}
                                                    </span>
                                                ))
                                            }</span>
                                        </div>
                                    )}

                                </div>
                            </h2>
                            <h2 className="col-1 mb-2 mb-lg-0 page-title">
                                <div className='d-flex'>
                                    <i data-toggle="tooltip" data-placement="right" title="Refresh" className="fas fa-redo fs-1 text-primary pointer" onClick={refreshList}></i>
                                </div> </h2>

                        </div>

                        <div className="col-lg-12 mb-3">
                            <div className="d-flex justify-content-between align-items-center ">
                                {<ToolbarDropdown labelProperty={"label"} valueProperty={"value"} isMulti={true} placeholder={"Select Status"} selectedOption={selectedOption} slotStatusList={slotStatusList} handleSelectChange={handleSelectChange} />}
                                <div className='d-flex justify-content-between align-items-center'>
                                    <button type="button" onClick={() => printCalendar(caregiverData)} className="btn btn-primary me-3">
                                        <i className="fas fa-print"></i> Print
                                    </button>
                                    <button type="button" onClick={() => downloadCalendar(caregiverData)} className="btn btn-primary me-3">
                                        <i className="fas fa-download"></i> Download
                                    </button>
                                </div>
                            </div>
                            <div>
                                <div className="row" id="calendar-print">
                                    <div className="col-sm-12 col-lg-8" >
                                        <div id="calendar-download">
                                            <MyCalendar
                                                localizer={localizer}
                                                events={selectedOption?.length > 0 ? shiftList?.filter(item => selectedOption?.some(filterItem => filterItem.value === item.data.status)) : shiftList}
                                                handleEventClick={handleEventClick}
                                                CustomEvent={CustomEventCaregiver}
                                                CustomDayWrapper={CustomDayWrapper}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 d-sm-none d-lg-block" >
                                        {
                                            selectedEvent ?
                                                <ViewEventCaregiver
                                                    selectedEvent={selectedEvent}
                                                    onChangeSlotStatus={onChangeSlotStatus}
                                                    handleCloseModal={handleCloseModal}
                                                    degree={degree}
                                                    handleDateChange={handleDateChange}
                                                    handleSlotStartChange={handleSlotStartChange}
                                                    handleSlotEndChange={handleSlotEndChange}
                                                    editSlot={editSlot}
                                                    handleSlotTypeChange={handleSlotTypeChange}
                                                    slotChanges={slotChanges}

                                                /> :
                                                isOpenAddShift ?
                                                    <AddShiftCaregiver
                                                        onDismissAddShift={onDismissAddShift}
                                                        selectedDate={selectedDate}
                                                        caregiverData={caregiverData}
                                                        assignShift={assignShift}
                                                        clientList={clientList}
                                                        degree={degree}
                                                        specialties={specialties}
                                                        setDates={setDates}
                                                        setAllDates={setAllDates}
                                                        setValues={setValues}
                                                        setSpecialties={setSpecialties}
                                                        multi={multi}
                                                        dates={dates}
                                                        ClearButton={ClearButton}
                                                        allDates={allDates}
                                                        facilityList={facilityList}
                                                    /> : <MyCalendar
                                                        localizer={localizer}
                                                        events={selectedOption?.length > 0 ? shiftList?.filter(item => selectedOption?.some(filterItem => filterItem.value === item.data.status)) : shiftList}
                                                        handleEventClick={handleEventClick}
                                                        CustomEvent={CustomEventCaregiver}
                                                        View={true}
                                                    />
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <!-- main-panel ends --> */}
                </div>
                {/* <!-- page-body-wrapper ends --> */}
            </div>


            {isMobile && <CustomModal
                isOpen={selectedEvent ? true : false}
                closeModal={handleCloseModal}
                title={"Shift Details"}
                content={<ViewEventCaregiver />}
            />}
            <Spinner isLoading={AdminStore.loading} />
        </React.Fragment>
    );

})
export default ShiftCalendarByCaregiver;
