/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react"
import HeaderPage from "../../layout/HeaderPage";
import DrawerMenuPage from "../../layout/DrawerMenuPage";
import { CButton, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CInputGroup, CFormInput, CFormSwitch } from "@coreui/react";
import Spinner from "@components/Spinner";
import ReactDOM from "react-dom";
import moment from "moment";
import { Helmet } from "react-helmet";
import HomeStore from "@store/HomeStore";
import AdminStore from "@store/AdminStore";
import { generateTooltipHTML, checkAccountValidation, checkAccountValidationOnHover,onDownloadICS} from "../../commonFunctions";
var states = require('@assets/states.json');

const WeeklyReportView = observer(() => {
    const tableName = "table1"
    const [degree, setDegree] = useState([]);
    const [listData, setListData] = useState();
    const [data, setData] = useState('');
    const [columns, setColumns] = useState();
    const [type, setType] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [filter, setFilter] = useState(null);
    const tableRef = useRef(null);
    useEffect(() => {
        setDegree(HomeStore?.data?.degreeList)
    }, [HomeStore?.data?.degreeList]);

    useEffect(() => {
        setIsLoading(true);
        const encodedData = new URLSearchParams(window.location.search);
        try {
            const data = JSON.parse(decodeURIComponent(encodedData.get("data")));
            setFilter(data?.filter);
            let param = {
                'start_date': data?.startDate,// m/d/Y
                'end_date': data?.endDate,// m/d/Y
                'region_id': data?.selectedRegionID,
                'report_type': data?.report_type,
            }
            setType(data?.report_type)
            AdminStore.weeklyReportDetails(param)?.then((data) => {
                setData(data);
                setIsLoading(false);
            })
        } catch (error) {
            setIsLoading(false);
            console.error('Error decoding JSON data:', error);
        }
    }, []);

    useEffect(() => {
        setListData(
            data?.active_caregivers
            || data?.active_employers
            || data?.top_facility?.shifts
            || (filter ? data?.shifts_posted?.filter(x => x.status === filter && !x?.staff_id) : data?.shifts_posted)
            || (type === 'shift_filled' && (filter ? [...data?.shifts_closed, ...data?.shifts_confirmed, ...data?.shifts_pending]?.filter(x => x.status === filter) : [...data?.shifts_closed, ...data?.shifts_confirmed, ...data?.shifts_pending]))
            || (type === 'shift_cancelled' && (filter ? [...data?.shifts_cancelled_caregiver, ...data?.shifts_cancelled_facility]?.filter(x => x.status === filter): [...data?.shifts_cancelled_caregiver, ...data?.shifts_cancelled_facility]))
            || data?.most_shifts_by_caregiver?.shifts
        );
    }, [data]);


    useEffect(() => {
        $.fn.dataTable.ext.errMode = 'none';
        $($.fn.dataTable.tables(true)).DataTable()
            .columns.adjust();
        if (type === 'active_caregiver') {
            const table = $(`#${tableName}`).DataTable(
                {
                    data: listData,
                    order: [], // Disable initial sorting
                    dom: 'Blfrtip',
                    buttons: [
                        'copy', 'csv', 'excel', 'print',
                    ],
                    pageLength: 25,
                    lengthMenu: [25, 50, 100, 500, 1000],
                    columns: [

                        {
                            title: "Bluesky Id", data: 'staffer_id', width: 50, "render": function (data, type, full, meta) {
                                return data || "-"
                            }
                        },
                        {
                            title: "Name", width: 100, "render": function (data, type, row, meta) {
                                return '<a class="link-info pointer">' + (row?.first_name || row?.staff_profile?.first_name) + " " + (row?.middle_name || "") + " " + (row?.last_name || row?.staff_profile?.last_name) + '</a>'
                                    + (checkAccountValidation(row, "caregiver") ? `<span id="check-validation" class="text-warning ps-2"><i class="fas fa-exclamation-triangle"></i></span>` : "")
                            }
                        },
                        {
                            title: "Email", data: "email", "render": function (data, type, row, meta) {
                                return row?.staff_profile?.email || "-";
                            }
                        },
                        {
                            title: "Profession/Specialty", data: "degree_specialities", "render": function (data, type, row, meta) {
                                const professionNames = data && (JSON?.parse(data)?.map(x => x?.specialties_name)[0] === undefined ? JSON?.parse(data)?.map(x => x?.profession_name) : JSON?.parse(data)?.map(x => x?.specialties_name));
                                return professionNames?.join("<br/>") || "-";
                            }
                        },
                        {
                            title: "Contact", data: "contact", "render": function (data, type, row, meta) {
                                return row?.staff_profile?.contact || "-"
                            }
                        },
                        {
                            title: "Apply & Hire Date", width: 100, "render": function (data, type, row, meta) {
                                return (row?.apply_date ? row?.apply_date : "-") + (row?.hire_date ? " & " : "") + (row?.hire_date ? row?.hire_date : "");
                            }
                        },
                        {
                            title: "Bluesky Status", width: 100, data: "status"
                        },
                        {
                            title: "Account Status", data: "staff_profile", width: 100, "render": function (data, type, row, meta) {
                                return row?.staff_profile?.status == '0' ? '<span class="text-warning">' + "Pending" + '</span>' : row?.staff_profile?.status == '1' ? '<span class="text-success">' + "Active" + '</span>' : row?.staff_profile?.status == '2' ? '<span class="text-danger">' + "Rejected" + '</span>' : row?.staff_profile?.status == '3' ? 'Blocked' : 'No Update'
                            }
                        },
                        {
                            title: "Action", data: ""
                        },
                        {
                            title: "Area Preference", width: 50, "render": function (data, type, full, meta) {
                                return full?.preferred_cities ? full?.work_distance + " miles/" + JSON?.parse(full?.preferred_cities)?.map(({ label }) => label) : "-"
                            }
                        },
                        {
                            title: "Status Reason", width: 100, data: "status_reason", "render": function (data, type, row, meta) {
                                const label = global?.config?.reasonoptions?.find(x => x.value == data)?.label || "-";
                                return `<strong>${label}</strong>`;
                            }
                        },
                        {
                            title: "Last Updated By", width: 100, data: 'last_updated_by', "render": function (data, type, row, meta) {
                                return data ? (data?.first_name + " " + data?.last_name) : "-"
                            }
                        },
                        {
                            title: "Last Update", width: 100, data: 'updated_at', "render": function (data, type, row, meta) {
                                return moment(data).fromNow() || "-";
                            }
                        },
                    ],
                    columnDefs: [
                        {
                            targets: 1,
                            responsivePriority: 2,
                            createdCell: function (td, cellData, rowData, row, col) {
                                if (col !== 1) return; // Exit if not column index 1

                                var tdId = $(td).find('span').attr('id');
                                if (tdId === 'check-validation') {
                                    // Update tooltip content with HTML returned by checkAccountValidationOnHover
                                    const tooltipContent = checkAccountValidationOnHover(rowData, "caregiver");
                                    $(td).attr('data-toggle', 'tooltip');
                                    $(td).attr('data-html', 'true');
                                    $(td).attr('data-placement', 'top');
                                    $(td).attr('title', generateTooltipHTML(tooltipContent));
                                }
                            }
                        },
                        {
                            targets: 3,
                            responsivePriority: 4,
                        },
                        {
                            targets: 5,
                            responsivePriority: 5,
                        },

                        {
                            targets: 8,
                            responsivePriority: 1,
                            visible: false,
                            createdCell: (td, cellData, rowData, row, col) =>
                                ReactDOM.render(
                                    <div>
                                        <CDropdown variant="btn-group" direction='dropstart' alignment="end">
                                            <CDropdownToggle size="sm" color="transparent" caret={false} href="#" className="p-0"><i className="fa fa-ellipsis-v text-primary"></i></CDropdownToggle>
                                            <CDropdownMenu >
                                                {<CDropdownItem className="more-menu">
                                                    <CButton onClick={() => {
                                                        showDetails(rowData)
                                                    }} size="sm" className="w-100" variant='outline' color="primary" >
                                                        Job Profile <i className="fas fa-id-card-alt"></i>
                                                    </CButton>
                                                </CDropdownItem>}
                                                {rowData?.staff_profile?.bluesky_staff_id && <CDropdownItem className="more-menu">
                                                    <CButton onClick={() => {
                                                        onSetMaxDist(rowData)
                                                    }} size="sm" className="w-100" variant='outline' color="primary" >
                                                        Update Work Area <i className="fas fa-map-marked-alt"></i>
                                                    </CButton>
                                                </CDropdownItem>}
                                                {rowData?.staff_profile?.bluesky_staff_id && <CDropdownItem className="more-menu">
                                                    <CButton onClick={() => {
                                                        showShiftDetails(rowData?.staff_profile)
                                                    }} size="sm" className="w-100" variant='outline' color="primary" >
                                                        View Shifts <i className="far fa-calendar-alt"></i>
                                                    </CButton>
                                                </CDropdownItem>}
                                                {rowData?.staff_profile?.bluesky_staff_id && <CDropdownItem className="more-menu">
                                                    <CButton onClick={() => {
                                                        showCalendarDetails(rowData)
                                                    }} size="sm" className="w-100" variant='outline' color="primary" >
                                                        View Calendar <i className="far fa-calendar-alt"></i>
                                                    </CButton>
                                                </CDropdownItem>}
                                                {rowData?.staff_profile?.bluesky_staff_id && <CDropdownItem className="more-menu">
                                                    <CButton onClick={() => {
                                                        onDownloadICS(rowData?.staff_profile)
                                                    }} size="sm" className="w-100" variant='outline' color="success" >
                                                        Download (.ics) <i className="far fa-calendar-plus"></i>
                                                    </CButton>
                                                </CDropdownItem>}
                                                <CDropdownItem className="more-menu">
                                                    <CButton onClick={() => {
                                                        onEdit(rowData?.staff_profile)
                                                    }} size="sm" className="w-100" variant='outline' color="primary" >
                                                        Edit Account <i className="fas fa-edit"></i>
                                                    </CButton>
                                                </CDropdownItem>
                                                {
                                                    <CDropdownItem className="more-menu">
                                                        <CButton onClick={() => {
                                                            onEditJobProfile(rowData)
                                                        }} size="sm" className="w-100" variant='outline' color="primary" >
                                                            Edit Job Profile <i className="fas fa-edit"></i>
                                                        </CButton>
                                                    </CDropdownItem>
                                                }

                                                {rowData?.staff_profile?.status != 0 && (
                                                    <><CDropdownItem>
                                                        <CButton
                                                            onClick={() => {
                                                                onBlock(rowData?.staff_profile)
                                                            }}
                                                            size="sm" className="w-100" variant='outline' color={rowData?.staff_profile?.status === 3 ? "success" : "danger"}>
                                                            {rowData?.staff_profile?.status === 3 ? 'Activate' : 'Block'}    {row?.staff_profile?.status === 3 ? <i className="fas fa-user-check"></i> : <i className="fas fa-ban"></i>}
                                                        </CButton>
                                                    </CDropdownItem></>)}
                                                <CDropdownItem>
                                                    <CButton
                                                        onClick={() => {
                                                            onDelete(rowData?.staff_profile?.id)
                                                        }}
                                                        size="sm" className="w-100" variant='outline' color="danger">
                                                        Delete <i className="fas fa-trash"></i>
                                                    </CButton>
                                                </CDropdownItem>
                                            </CDropdownMenu>
                                        </CDropdown>
                                    </div>
                                    , td),
                        },

                        {
                            targets: 6,
                            responsivePriority: 3,
                            createdCell: (td, cellData, rowData, row, col) =>
                                ReactDOM.render(
                                    <div className="d-flex">
                                        {rowData?.status === 0 ? <span className="text-warning">Pending</span>
                                            : rowData?.status === 1 ? <span className="text-warning">Incomplete Applicant</span>
                                                : rowData?.status === 2 ? <span className="text-danger">Rejected</span>
                                                    : rowData?.status === 3 ? <span className="text-success">Active</span>
                                                        : rowData?.status === 4 ? <span className="text-danger">In-Active</span> ://same
                                                            rowData?.status === 5 ? <span className="text-info">Applicant</span> ://same
                                                                rowData?.status === 6 ? <span className="text-success">Pre-active</span>//same
                                                                    : rowData?.status === null ? <span className="text-warning">Profile is incomplete</span> : 'No Update'
                                        }
                                        {rowData?.staffer_id && <div onClick={() => { checkLiveStatus(rowData) }} data-toggle="tooltip" data-placement="right" title="Update Status">
                                            &nbsp;<a href="#rotate" className="rotate-link"><i className="fas fa-sync-alt pointer"></i></a>
                                        </div>}
                                    </div>
                                    , td),
                        }
                    ],
                    destroy: true,  // I think some clean up is happening here
                    searching: true,
                    "language": {
                        search: '',
                        searchPlaceholder: "Search",
                        emptyTable: "No Record Found",
                    },
                    initComplete: function () {
                        // Apply the search
                        this.api()
                            .columns([0, 1, 2, 3, 4, 5])
                            .every(function () {
                                var column = this;
                                var select = $('<br/><input class="form-control " style="width:100%;margin-top:5px" type="text" placeholder="Filter per page" />')
                                    .appendTo($(column.header()))

                                $(select).click(function (e) {
                                    e.stopPropagation();
                                });
                                $('input', this.header()).on('keyup change clear', function () {
                                    if (column.search() !== this.value) {
                                        column.search(this.value).draw();
                                    }
                                });
                            });

                        this.api()
                            .columns([6])//Bluesky search
                            .every(function () {
                                var column = this;
                                var select = $('<br/><select class="form-control" placeholder="search" style="width:100%;margin-top:5px;background-color:white""><option value="">All</option></select>')
                                    .appendTo($(column.header()))
                                    .on('change', function () {
                                        var val = $.fn.dataTable.util.escapeRegex($(this).val());
                                        if (val === "") {
                                            column.search(val, true, false).draw();
                                        } else if (val === '-1') {
                                            column.search('^$', true, false, true).draw();

                                        } else {
                                            column.search('^' + val + '$', true, false).draw();
                                        }
                                    });
                                $(select).click(function (e) {
                                    e.stopPropagation();
                                });
                                column
                                    .data()
                                    .unique()
                                    .sort()
                                    .each(function (d, j) {
                                        let opt = global.config.blueSkyStatusOptions?.find(stat => stat.value == d);
                                        select.append('<option value="' + opt?.value + '">' + opt?.text + '</option>');
                                    });
                            });

                        this.api()//Acount search
                            .columns([7]).every(function () {
                                var column = this;
                                var select = $('<br/><select class="form-control" placeholder="filter" style="width:100%;margin-top:5px;background-color:white"><option value="" selected>All</option></select>')
                                    .appendTo($(column.header()))
                                    .on('change', function () {
                                        var val = $.fn.dataTable.util.escapeRegex($(this).val());
                                        column.search(val ? '^' + val + '$' : '', true, false).draw();
                                    });
                                $(select).click(function (e) {
                                    e.stopPropagation();
                                });
                                var options = []; // To store unique options
                                column.data().unique().sort().each(function (d, j) {
                                    if (d) {
                                        let name = [{ "name": "Pending", "value": 0 }, { "name": "Active", "value": 1 }, { "name": "Rejected", "value": 2 }, { "name": "Blocked", "value": 3 }]?.find(item => item.value == d?.status)?.name;
                                        if (options.indexOf(name) === -1) {
                                            options.push(name); // Add to options array if not already present
                                            select.append('<option value="' + name + '">' + name + '</option>');
                                        }
                                    }
                                });
                            });
                        this.api()//Response search
                            .columns([10]).every(function () {
                                var column = this;
                                var select = $('<br/><select class="form-control" placeholder="filter" style="width:100%;margin-top:5px;background-color:white"><option value="" selected>All</option></select>')
                                    .appendTo($(column.header()))
                                    .on('change', function () {
                                        var val = $.fn.dataTable.util.escapeRegex($(this).val());
                                        column.search(val ? '^' + val + '$' : '', true, false).draw();
                                    });
                                $(select).click(function (e) {
                                    e.stopPropagation();
                                });
                                var options = []; // To store unique options
                                column.data().unique().sort().each(function (d, j) {
                                    if (d) {
                                        let name = global?.config?.reasonoptions?.find(item => item.value == d)?.label;
                                        if (options.indexOf(name) === -1) {
                                            options.push(name); // Add to options array if not already present
                                            select.append('<option value="' + name + '">' + name + '</option>');
                                        }
                                    }
                                });
                            });

                    },
                });
            return function () {
                $(`#${tableName}`).off('length.dt');
                table.destroy()
            }
        }
        if (type === "active_facility_user") {
            const table = $(`#${tableName}`).DataTable(
                {
                    data: listData,
                    order: [], // Disable initial sorting
                    dom: 'Blfrtip',
                    buttons: [
                        'copy', 'csv', 'excel', 'print',
                    ],
                    pageLength: 25,
                    lengthMenu: [25, 50, 100, 500, 1000],
                    columns: [
                        {
                            title: "Sr", data: 'id', width: 50, "render": function (data, type, full, meta) {
                                return data
                            }
                        },
                        {
                            title: "Username", data: 'username', width: 50, "render": function (data, type, full, meta) {
                                return data ? "<span class='text-success'><strong>" + data + "</strong></span>" : "-"
                            }
                        },
                        {
                            title: "User Type", data: 'master', width: 50, "render": function (data, type, full, meta) {
                                return data ? "Sub Account" : "Master"
                            }
                        },
                        {
                            title: "Name", width: 100, "render": function (data, type, row, meta) {
                                return row?.first_name + " " + row?.last_name
                            }
                        },
                        {
                            title: "Facilities", data: "facility_relations", "render": function (data, type, row, meta) {
                                return data.length > 0 && '<span class="text-info"><b>' + data[0]?.facilities?.organization_name + '</span>' + '<sup class="text-info"><b>' + (data?.length > 1 ? " +" + (data.length - 1) : "") + '</b></sup>';
                            }
                        },
                        {
                            title: "Email", data: "email"
                        },
                        {
                            title: "Contact", data: "contact", "render": function (data, type, row, meta) {
                                return data || "-";
                            }
                        },
                        {
                            title: "Status", width: 100, data: 'status', "render": function (data, type, row, meta) {
                                return data == '0' ? '<span class="text-warning">' + "Pending" + '</span>' : data == '1' ? '<span class="text-success">' + "Active" + '</span>' : data == '2' ? '<span class="text-danger">' + "Rejected" + '</span>' : data == '3' ? '<span class="text-dark">' + "Blocked" + '</span>' : 'No Update'
                            }
                        },
                        {
                            title: "Last Updated By", width: 100, data: 'last_updated_by', "render": function (data, type, row, meta) {
                                return data ? data?.first_name + " " + data?.last_name : "-"
                            }
                        },
                        {
                            title: "Last Update", width: 100, data: 'updated_at', "render": function (data, type, row, meta) {
                                return moment(data).fromNow() || "-";
                            }
                        },
                        {
                            title: "Notification"
                        },
                        {
                            title: "Action"
                        },

                    ],
                    columnDefs: [
                        {
                            targets: 4,
                            responsivePriority: 3,
                            createdCell: function (td, cellData, rowData, row, col) {
                                var listHtml = "";
                                cellData.forEach(function (data) {
                                    listHtml += "" + data?.facilities?.organization_name + "\n";
                                })
                                listHtml += "";
                                $(td).attr('data-toggle', 'tooltip');
                                $(td).attr('data-html', 'true');
                                $(td).attr('data-placement', 'top');
                                $(td).attr('title', listHtml);
                            },
                        },
                        {
                            targets: 0,
                            visible: false,
                        },
                        {
                            targets: 10,
                            responsivePriority: 2,
                            visible: false,
                            createdCell: (td, cellData, rowData, row, col) =>
                                ReactDOM.render(
                                    <div>
                                        <CFormSwitch size="xl" onChange={(event) => handleChangeSMS(event, rowData?.id, rowData?.mail_notify)} checked={rowData?.sms_notify === 1} label="SMS" id="sms" />
                                        <CFormSwitch size="xl" onChange={(event) => handleChangeEmail(event, rowData?.id, rowData?.sms_notify)} checked={rowData?.mail_notify === 1} label="EMAIL" id="email" />
                                    </div>
                                    , td),
                        },
                        {
                            targets: 11,
                            responsivePriority: 1,
                            visible: false,
                            createdCell: (td, cellData, rowData, row, col) =>
                                ReactDOM.render(
                                    <div>
                                        <CDropdown variant="btn-group" direction='dropstart' alignment="end">
                                            <CDropdownToggle size="sm" color="transparent" caret={false} href="#" className="p-0"><i className="fa fa-ellipsis-v text-primary"></i></CDropdownToggle>
                                            <CDropdownMenu>
                                                {<CDropdownItem className="more-menu">
                                                    <CButton onClick={() => {
                                                        onShiftView(rowData)
                                                    }} size="sm" className="w-100" variant='outline' color="primary" >
                                                        View Shifts <i className="fas fa-eye"></i>
                                                    </CButton>
                                                </CDropdownItem>}

                                                {<CDropdownItem className="more-menu">
                                                    <CButton onClick={() => {
                                                        onShiftCalendarView(rowData)
                                                    }} size="sm" className="w-100" variant='outline' color="primary" >
                                                        View Calendar <i className="fas fa-calendar"></i>
                                                    </CButton>
                                                </CDropdownItem>}
                                                {rowData.status != 0 && <CDropdownItem className="more-menu">
                                                    <CButton onClick={() => {
                                                        onEdit(rowData)
                                                    }} size="sm" className="w-100" variant='outline' color="primary" >
                                                        Edit <i className="fas fa-edit"></i>
                                                    </CButton>
                                                </CDropdownItem>}
                                                {rowData.status == 0 && (<>  <CDropdownItem>
                                                    <CButton onClick={() => {
                                                        onOpenForm(rowData)
                                                    }} size="sm" className="w-100" variant='outline' color="success" >
                                                        Approve <i className="fas fa-user-check"></i>
                                                    </CButton>
                                                </CDropdownItem>
                                                </>)}
                                                {rowData.status == 0 && (<><CDropdownItem>
                                                    <CButton
                                                        onClick={() => {
                                                            onReject(rowData.id)
                                                        }}
                                                        size="sm" className="w-100" variant='outline' color="danger">
                                                        Reject <i className="fas fa-user-times"></i>
                                                    </CButton>

                                                </CDropdownItem>
                                                </>)}
                                                {rowData.status != 0 && <CDropdownItem>
                                                    <CButton
                                                        onClick={() => {
                                                            onBlock(rowData)
                                                        }}
                                                        size="sm" className="w-100" variant='outline' color={rowData.status === 3 ? "success" : "danger"}>
                                                        {rowData.status === 3 ? 'Activate' : 'Block'}    {rowData.status === 3 ? <i className="fas fa-user-check"></i> : <i className="fas fa-ban"></i>}
                                                    </CButton>
                                                </CDropdownItem>
                                                }
                                                <CDropdownItem>
                                                    <CButton
                                                        onClick={() => {
                                                            confirm(rowData.id)
                                                        }}
                                                        size="sm" className="w-100" variant='outline' color="danger">
                                                        Delete <i className="fas fa-trash"></i>
                                                    </CButton>
                                                </CDropdownItem>
                                            </CDropdownMenu>
                                        </CDropdown>
                                    </div>
                                    , td),
                        }
                    ],
                    destroy: true,  // I think some clean up is happening here
                    searching: true,
                    "language": {
                        search: '',
                        searchPlaceholder: "Search",
                        emptyTable: "No Record Found",
                    },
                    initComplete: function () {
                        // Apply the search
                        this.api()
                            .columns([0, 1, 2, 3, 4, 5, 6, 8])
                            .every(function () {
                                var column = this;
                                var select = $('<br/><input class="form-control " style="width:100%;margin-top:5px" type="text" placeholder="Filter per page" />')
                                    .appendTo($(column.header()))

                                $(select).click(function (e) {
                                    e.stopPropagation();
                                });
                                $('input', this.header()).on('keyup change clear', function () {
                                    if (column.search() !== this.value) {
                                        column.search(this.value).draw();
                                    }
                                });
                            });
                        this.api()
                            .columns([7])
                            .every(function () {
                                var column = this;
                                var select = $('<br/><select class="form-control" placeholder="search" style="width:100%;margin-top:5px;background-color:white""><option value="">All</option></select>')
                                    .appendTo($(column.header()))
                                    .on('change', function () {
                                        var val = $.fn.dataTable.util.escapeRegex($(this).val());
                                        column.search(val ? '^' + val + '$' : '', true, false).draw();
                                    });
                                $(select).click(function (e) {
                                    e.stopPropagation();
                                });
                                column
                                    .data()
                                    .unique()
                                    .sort()
                                    .each(function (d, j) {
                                        let name = [{ "name": "Pending", "value": 0 }, { "name": "Active", "value": 1 }, { "name": "Rejected", "value": 2 }, { "name": "Blocked", "value": 3 }]?.find(stat => stat.value == d)?.name;
                                        select.append('<option value="' + name + '">' + name + '</option>');
                                    });
                            });

                    },
                });
            return function () {
                $(`#${tableName}`).off('length.dt');
                table.destroy()
            }
        }
        if (type === 'shift_posted' || type === 'shift_filled' || type === 'shift_cancelled' || type === 'most_shifts_by_facility' || type === 'most_shifts_by_caregiver') {
            const table = $(`#${tableName}`).DataTable(
                {
                    data: listData,
                    pageLength: 100,
                    lengthMenu: [100, 500, 1000, 2000, 3000, 5000],
                    dom: 'Blfrtip',
                    buttons: [
                        'copy', 'csv', 'excel', 'print',
                    ],
                    columns: [
                        {
                            title: "Order Ref.", data: 'shift_id',
                            width: 60,
                            render: function (data, type, full, meta) {
                                return data;
                            }
                        },
                        {
                            data: "id",
                            orderable: false
                        },
                        {
                            title: "Shift Date", data: 'slot_date', "render": function (data, type, row, meta) {
                                return '<strong><a class="link-info pointer">' + data + '</a></strong>'
                            }
                        },
                        {
                            title: "Time", "render": function (data, type, row, meta) {
                                return '<strong><a class="link-info pointer">' + moment(row?.slot_start_time, "HH:mm").format("hh:mm A") + " To " + moment(row?.slot_end_time, "HH:mm").format("hh:mm A") + '</a></strong>'
                            }
                        },
                        {
                            title: "Shift Type", "render": function (data, type, row, meta) {
                                return row?.slot_type + " & " + global.config.shiftCaregory?.find(x => x.value === row?.request?.category)?.label;
                            }
                        },
                        {
                            title: "Facility Name", data: "request", "render": function (data, type, full, meta) {
                                return data?.facility?.organization_name || "-";
                            }

                        },
                        {
                            title: "Assigned Caregiver", data: "staff", "render": function (data, type, full, meta) {
                                return data?.first_name ? '<strong><a class="link-info pointer">' + data?.first_name + " " + data?.last_name + '</a></strong>' : "No Assignment";
                            }

                        },
                        {
                            title: "Profession-Specialty", data: "request", "render": function (data, type, full, meta) {
                                return data?.specialty_name ? data?.specialty_name : degree?.find(pro => pro.Id == data?.profession)?.Name;
                            }
                        },
                        {
                            title: "State", data: 'request', "render": function (data, type, row, meta) {
                                return data?.state || "-";
                            }
                        },
                        {
                            title: "City", data: 'request', "render": function (data, type, row, meta) {
                                return data?.city || "-";
                            }
                        },
                        {
                            title: "Shift Status", data: "status"
                        },
                        {
                            title: "Comment For Caregiver", data: "remark"
                        },
                        {
                            title: "Creation Time", data: "created_at", "render": function (data, type, row, meta) {
                                return moment(data).format('MM/DD/yy hh:mm A') || "-";
                            }
                        },
                        {
                            title: "Manage", data: ""
                        },
                        {
                            title: "Exported", data: "is_exported", "render": function (data, type, row, meta) {
                                return data === 1 ? "Yes" : "No";
                            }
                        },
                    ],
                    columnDefs: [

                        {
                            targets: 1,
                            responsivePriority: 1,
                            visible: false,
                            title: `
                            <label style="font-size: 0.875rem;"><b>Select All</b></label><br/>
                            <input type="checkbox"id="select-all-checkbox" class="select-all-checkbox">                     
                     
                        `,
                            render: function (data, type, row, meta) {
                                // Render a checkbox for each row
                                return `<input type="checkbox" class="row-select" data-id="${data}"/>`;
                            }
                        },
                        {
                            targets: 2,
                            responsivePriority: 2,
                        },
                        {
                            targets: 4,
                            responsivePriority: 3,
                        },
                        {
                            targets: 13,
                            visible: false,
                            responsivePriority: 4,
                            visible: false,
                            createdCell: (td, cellData, rowData, row, col) =>
                                ReactDOM.render(
                                    <div>
                                        <CDropdown variant="btn-group" direction='dropstart'>
                                            <CDropdownToggle size="sm" color="transparent" caret={false} href="#" className="p-0"><i className="fa fa-ellipsis-v text-primary"></i></CDropdownToggle>
                                            <CDropdownMenu>
                                                {rowData?.status === 1 && <CDropdownItem>
                                                    <CButton onClick={() => {
                                                        onCopySlot(rowData)
                                                    }} size="sm" className="w-100" variant='outline' color="info" >
                                                        Copy Shift URL <i className="far fa-copy "></i>
                                                    </CButton>
                                                </CDropdownItem>}
                                                {((rowData?.status !== 5 && rowData?.status !== 6 && rowData?.status !== 7) && rowData?.is_exported === 0) && <CDropdownItem>
                                                    <CButton onClick={() => {
                                                        assignNewCaregiver(rowData)
                                                    }} size="sm" className="w-100" variant='outline' color="primary" >
                                                        Assign Caregiver <i className="fas fa-user-plus"></i>
                                                    </CButton>
                                                </CDropdownItem>}
                                                {rowData?.status === 1 && <CDropdownItem>
                                                    <CButton onClick={() => {
                                                        onEditSlot(rowData)
                                                    }} size="sm" className="w-100" variant='outline' color="primary" >
                                                        Edit Shift <i className="far fa-edit"></i>
                                                    </CButton>
                                                </CDropdownItem>}
                                                {(rowData?.shift_data?.staff_response === 1 && rowData?.shift_data?.staff_id) || (rowData?.status === 1 && rowData?.staff_id) && <CDropdownItem>
                                                    <CButton onClick={() => {
                                                        onChangeSlotStatus(rowData, 'approve', refreshList)
                                                    }} size="sm" className="w-100" variant='outline' color="success" >
                                                        Approve Shift <i className="fas fa-check"></i>
                                                    </CButton>
                                                </CDropdownItem>
                                                }
                                                {(rowData?.shift_data?.staff_response === 1 && rowData?.shift_data?.staff_id) || (rowData?.status === 1 && rowData?.staff_id) && <CDropdownItem>
                                                    <CButton onClick={() => {
                                                        onChangeSlotStatus(rowData, 'reject-close', refreshList)
                                                    }} size="sm" className="w-100" variant='outline' color="danger" >
                                                        Reject & Close Shift <i className="fas fa-times"></i>
                                                    </CButton>
                                                </CDropdownItem>}
                                                {(rowData?.shift_data?.staff_response === 1 && rowData?.shift_data?.staff_id) || (rowData?.status === 1 && rowData?.staff_id) && <CDropdownItem>
                                                    <CButton onClick={() => {
                                                        onChangeSlotStatus(rowData, 'reject-open', refreshList)
                                                    }} size="sm" className="w-100" variant='outline' color="danger" >
                                                        Reject & Open Shift <i className="fas fa-times"></i>
                                                    </CButton>
                                                </CDropdownItem>}
                                                {rowData?.status === 2 && <CDropdownItem>
                                                    <CButton onClick={() => {
                                                        onChangeSlotStatus(rowData, 'cancel-facility', refreshList)
                                                    }} size="sm" className="w-100" variant='outline' color="danger" >
                                                        Cancel By Facility <i className="fas fa-times"></i>
                                                    </CButton>
                                                </CDropdownItem>}
                                                {rowData?.status === 2 && <CDropdownItem>
                                                    <CButton onClick={() => {
                                                        onChangeSlotStatus(rowData, 'cancel-caregiver', refreshList)
                                                    }} size="sm" className="w-100" variant='outline' color="danger" >
                                                        Cancel By Caregiver <i className="fas fa-times"></i>
                                                    </CButton>
                                                </CDropdownItem>}
                                                {<CDropdownItem>
                                                    <CButton onClick={() => {
                                                        onChangeSlotStatus(rowData, 'delete', refreshList)
                                                    }} size="sm" className="w-100" variant='outline' color="danger" >
                                                        Delete Shift <i className="far fa-trash-alt"></i>
                                                    </CButton>
                                                </CDropdownItem>}
                                            </CDropdownMenu>
                                        </CDropdown>
                                    </div>
                                    , td),
                        },
                        {
                            targets: 10,
                            responsivePriority: 5,
                            createdCell: (td, cellData, rowData, row, col) =>
                                ReactDOM.render(
                                    <div>
                                        <span>
                                            {
                                                (rowData?.status == 1 && rowData?.staff_id && rowData?.shift_data?.staff_response === 0 && rowData?.shift_data?.staff_id) ? <strong> <span className={'text-warning'}>Pending
                                                    By Caregiver </span></strong> : (rowData?.shift_data?.staff_response === 1 && rowData?.shift_data?.staff_id) || (rowData?.status === 1 && rowData?.staff_id) ? <strong> <span className={'text-info'}>Waiting For Approval!</span></strong> :
                                                    rowData?.status == 1 ? <span className={'text-danger'}>Unfilled</span> :
                                                        rowData?.status == 2 ? <span className={'text-success'}>Confirmed</span> :
                                                            rowData?.status == 3 ? <span className={'text-danger'}>Rejected</span> :
                                                                rowData?.status == 4 ? <span className={'text-warning'}>Extention Pending</span> :
                                                                    rowData?.status == 5 ? <span className={'text-danger'}>Cancelled by caregiver</span> :
                                                                        rowData?.status == 6 ? <span className={'text-danger'}>Cancelled by facility</span> :
                                                                            rowData?.status == 7 ? <span className={'text-muted'}>Closed</span> : ""}

                                        </span>
                                    </div>
                                    , td),
                        }
                    ],
                    destroy: true,  // I think some clean up is happening here
                    searching: true,
                    "language": {
                        search: '',
                        searchPlaceholder: "Search",
                        emptyTable: "No Record Found"
                    },
                    initComplete: function () {
                        // Apply the search
                        this.api()
                            .columns([0, 4, 6, 9, 11])
                            .every(function () {
                                var column = this;
                                var select = $('<br/><input class="form-control " style="width:100%;margin-top:5px" type="text" placeholder="Filter" />')
                                    .appendTo($(column.header()))

                                $(select).click(function (e) {
                                    e.stopPropagation();
                                });
                                $('input', this.header()).on('keyup change clear', function () {
                                    if (column.search() !== this.value) {
                                        column.search(this.value).draw();
                                    }
                                });
                            });

                        this.api()
                            .columns([8])//State search
                            .every(function () {
                                var column = this;
                                var select = $('<br/><select class="form-control" placeholder="search" style="width:100%;margin-top:5px;background-color:white""><option value="" selected>All</option></select>')
                                    .appendTo($(column.header()))
                                    .on('change', function () {
                                        var val = $.fn.dataTable.util.escapeRegex($(this).val());
                                        column.search(val ? '^' + val + '$' : '', true, false).draw();
                                    });
                                $(select).click(function (e) {
                                    e.stopPropagation();
                                });

                                // Create an array to store unique values
                                var uniqueValues = [];

                                column
                                    .data()
                                    .unique()
                                    .sort()
                                    .each(function (d, j) {
                                        let name = states?.find(stat => stat?.abbreviation == d?.state);

                                        // Check if the value is already in the uniqueValues array
                                        if (name && uniqueValues.indexOf(name.abbreviation) === -1) {
                                            uniqueValues.push(name.abbreviation);
                                            select.append('<option value="' + name.abbreviation + '">' + name.name + '</option>');
                                        }
                                    });
                            });
                        this.api()
                            .columns([2])//Date
                            .every(function () {
                                var column = this;
                                var select = $('<br/><input type="date" class="form-control" style="width:100%;margin-top:5px" type="text" placeholder="" />')
                                    .appendTo($(column.header()))
                                $(select).click(function (e) {
                                    e.stopPropagation();
                                });
                                $('input', this.header()).on('keyup change clear', function () {
                                    if (!this.value) {
                                        $(`#${tableNameSlot}`).DataTable().column([1]).search('').draw();
                                    }
                                    if (column.search() !== this.value) {
                                        column.search(moment(this.value).format('MM/DD/YYYY')).draw();
                                    }
                                });
                            });

                        this.api()
                            .columns([3])//Time
                            .every(function () {
                                var column = this;
                                var select = $('<br/><input type="time" class="form-control" style="width:100%;margin-top:5px" type="text" placeholder="" />')
                                    .appendTo($(column.header()))

                                $(select).click(function (e) {
                                    e.stopPropagation();
                                });

                                $('input', this.header()).on('keyup change clear', function () {
                                    if (!moment(this.value, "HH:mm").format("hh:mm A")) {
                                        $(`#${tableNameSlot}`).DataTable().column([3]).search('').draw();
                                    }
                                    if (column.search() !== moment(this.value, "HH:mm").format("hh:mm A")) {
                                        column.search(moment(this.value, "HH:mm").format("hh:mm A")).draw();
                                    }
                                });
                            });
                        //Specilaty + Profession Dropdown
                        this.api().columns([7]).every(function () {
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            var column = this;

                            // Create the dropdown with "All" as the default option
                            var select = $('<br/><select class="form-control" placeholder="filter" style="width:100%;margin-top:5px;background-color:white"><option value="" selected>All</option></select>')
                                .appendTo($(column.header()));

                            // Keep track of unique values
                            var uniqueValues = {};

                            // Populate the dropdown with unique values from column 5
                            column.data().each(function (d, j) {
                                // Get the HTML content of the cell
                                var cellText = column.cell(j, 7).node().innerHTML;
                                // Check if the value is unique before adding it to the dropdown
                                if (!uniqueValues[cellText]) {
                                    uniqueValues[cellText] = true;
                                    select.append('<option value="' + cellText + '">' + cellText + '</option>');
                                }
                            });

                            // Add change event listener to the dropdown
                            select.on('change', function () {
                                var val = $.fn.dataTable.util.escapeRegex(
                                    $(this).val()
                                );

                                // Search and filter the table
                                column.search(val ? '^' + val + '$' : '', true, false).draw();
                            });

                        });
                        this.api()
                            .columns([10])
                            .every(function () {
                                var column = this;
                                var select = $('<br/><select class="form-control" placeholder="search" style="width:100%;margin-top:5px;background-color:white""><option value="">All</option></select>')
                                    .appendTo($(column.header()))
                                    .on('change', function () {
                                        var val = $.fn.dataTable.util.escapeRegex($(this).val());
                                        column.search(val ? '^' + val + '$' : '', true, false).draw();
                                    });
                                $(select).click(function (e) {
                                    e.stopPropagation();
                                });
                                [{ "name": "Confirmed", "value": 2 }, { "name": "Unfilled", "value": 1 }, { "name": "Rejected", "value": 3 }, { "name": "Extention", "value": 4 }, { "name": "Cancelled by caregiver", "value": 5 }, { "name": "Cancelled by facility", "value": 6 }, { "name": "Closed", "value": 7 }].forEach(function (option) {
                                    var selected = '';
                                    select.append('<option value="' + option.value + '" ' + selected + '>' + option?.name + '</option>');
                                });
                            });
                        //Facility Dropdown
                        this.api().columns([5]).every(function () {
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            var column = this;

                            // Create the dropdown with "All" as the default option
                            var select = $('<br/><select class="form-control" placeholder="filter" style="width:100%;margin-top:5px;background-color:white"><option value="" selected>All</option></select>')
                                .appendTo($(column.header()));

                            // Keep track of unique values
                            var uniqueValues = {};

                            // Populate the dropdown with unique values from column 5
                            column.data().each(function (d, j) {
                                // Get the HTML content of the cell
                                var cellText = column.cell(j, 5).node().innerHTML;
                                // Check if the value is unique before adding it to the dropdown
                                if (!uniqueValues[cellText]) {
                                    uniqueValues[cellText] = true;
                                    select.append('<option value="' + cellText + '">' + cellText + '</option>');
                                }
                            });

                            // Add change event listener to the dropdown
                            select.on('change', function () {
                                var val = $.fn.dataTable.util.escapeRegex(
                                    $(this).val()
                                );

                                // Search and filter the table
                                column.search(val ? '^' + val + '$' : '', true, false).draw();
                            });

                        });
                    },
                }

            )
            return function () {
                $(`#${tableName}`).off('length.dt');
                table.destroy()
            }
        }
    }, [listData]);

    return (
        <React.Fragment>
            <Helmet>
                <title>Staffers LLC | Weekly Report Details</title>
            </Helmet>
            {/* <body> */}
            <div className="container-scroller">
                <HeaderPage />
                <div className="container-fluid page-body-wrapper">
                    <DrawerMenuPage />
                    <div className="main-panel" style={{ right: 0, position: "absolute" }}>
                        <div className="p-3 bg-white row">
                            <h3 className="page-title col-6 col-lg-12">
                                <span className="page-title-icon linear text-white me-2">
                                    <i className="far fa-id-card"></i>
                                </span> {type?.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}
                            </h3>
                        </div>
                        {(data?.top_facility?.name || data?.most_shifts_by_caregiver) && <div className="col-11 mb-2 mb-lg-0 ms-3 page-title">
                            <div className="row mt-2">
                                <div className="col-12 col-sm-auto mb-2">
                                    {data?.top_facility?.name && <span><strong  >Name: {data?.top_facility?.name}</strong></span>}
                                    {data?.most_shifts_by_caregiver?.name && <span><strong >Bluesky Id: {data?.most_shifts_by_caregiver?.name?.bluesky_staff_id}</strong>,&nbsp;</span>}
                                    {data?.most_shifts_by_caregiver?.name && <span><strong >Name: {data?.most_shifts_by_caregiver?.name?.first_name + " " + data?.most_shifts_by_caregiver?.name?.last_name}</strong></span>}
                                </div>
                            </div>
                        </div>}
                        {/* <!-- partial --> */}
                        <div className="col-lg-12">
                            <div className="row bg-white table-responsive" style={{ height: 'auto', minHeight: 500, paddingTop: 20 }}>
                                <div className="table-wrapper">
                                    <table className="table dt-responsive table-hover nowrap" style={{ width: "100%" }} id={tableName} ref={tableRef}>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <!-- main-panel ends --> */}
                </div>
                {/* <!-- page-body-wrapper ends --> */}
            </div>
            <Spinner isLoading={isLoading} />
        </React.Fragment >
    );

})
export default WeeklyReportView;
