/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react"
import HeaderPage from "../layout/HeaderPage";
import DrawerMenuPage from "../layout/DrawerMenuPage";
import Select, { components } from 'react-select';
import { Formik, Form } from "formik";
import './UpdatePages.css'
import Spinner from "@components/Spinner";
import { InputField } from "@elements/index"
import AdminStore from "@store/AdminStore";
import { toJS } from "mobx";
import { Helmet } from "react-helmet";
import SelectField from "@elements/select/SelectField";
import { colourStylesState } from '@utils/customStyle/style';
import { statusList } from '@utils/constant/constant';
import { CButton, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CModal, CModalBody, CModalHeader, CModalTitle } from "@coreui/react-pro";
import ReactDOM from "react-dom";
import { FeaturedLocValidationSchema } from '@utils/schemas/allSchema';

var cities = require('@assets/city.json');
var states = require('@assets/states.json');
const FeaturedLocation = observer(() => {

    const tableRef = useRef()
    const tableName = "table1"
    const [paginationLength, setPaginationLength] = useState(25);
    const [isOpen, setIsOpen] = useState(false);
    const [featuredLocation, setFeaturedLocation] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState();

    useEffect(() => {
        AdminStore.getFeaturedLocation();
    }, []);

    useEffect(() => {
        setFeaturedLocation(toJS(AdminStore?.data?.featuredLocation))
    }, [AdminStore?.data?.featuredLocation]);


    useEffect(() => {
        $.fn.dataTable.ext.errMode = 'none';
        $($.fn.dataTable.tables(true)).DataTable()
            .columns.adjust();
        const table = $(`#${tableName}`).DataTable(
            {
                data: featuredLocation,
                order: [], // Disable initial sorting
                dom: 'Blfrtip',
                buttons: [
                    'copy', 'csv', 'excel', 'print',
                ],
                pageLength: paginationLength,
                lengthMenu: [25, 50, 100, 500, 1000],
                columns: [
                    {
                        title: "Ref.", data: "id", "render": function (data, type, row, meta) {
                            return data || "-";
                        }
                    },
                    {
                        title: "Sequence", data: "sequence"
                    },

                    {
                        title: "City", data: "city"
                    },
                    {
                        title: "State", data: "state"
                    },
                    {
                        title: "Status", data: 'status', "render": function (data, type, row, meta) {
                            return data == '1' ? '<span class="text-danger">' + "In-active" + '</span>' : data == '0' ? '<span class="text-success">' + "Active" + '</span>' : 'No Update'
                        }
                    },
                    {
                        title: "Action"
                    },
                ],
                columnDefs: [
                    {
                        targets: 5,
                        responsivePriority: 1,
                        createdCell: (td, cellData, rowData, row, col) =>
                            ReactDOM.render(
                                <div>
                                    <CDropdown variant="btn-group" direction='dropstart' alignment="end">
                                        <CDropdownToggle size="sm" color="transparent" caret={false} href="#" className="p-0"><i className="fa fa-ellipsis-v text-primary"></i></CDropdownToggle>
                                        <CDropdownMenu>
                                            <CDropdownItem className="more-menu">
                                                <CButton onClick={() => {
                                                    onEdit(rowData)
                                                }} size="sm" className="w-100" variant='outline' color="primary" >
                                                    Edit <i className="fas fa-edit"></i>
                                                </CButton>
                                            </CDropdownItem>
                                        </CDropdownMenu>
                                    </CDropdown>
                                </div>
                                , td),
                    },
                    {
                        targets: 5,
                        responsivePriority: 2,
                    },
                ],
                destroy: true,  // I think some clean up is happening here
                searching: true,
                "language": {
                    search: '',
                    searchPlaceholder: "Search",
                    emptyTable: "No Location Found",
                },
                initComplete: function () {
                    this.api()
                        .columns([0, 1])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><input class="form-control " style="width:100%;margin-top:5px" type="text" placeholder="Filter per page" />')
                                .appendTo($(column.header()))

                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $('input', this.header()).on('keyup change clear', function () {
                                if (column.search() !== this.value) {
                                    column.search(this.value).draw();
                                }
                            });
                        });
                    this.api()
                        .columns([2])//city
                        .every(function () {
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            var column = this;

                            // Create the dropdown with "All" as the default option
                            var select = $('<br/><select class="form-control" placeholder="filter" style="width:100%;margin-top:5px;background-color:white"><option value="" selected>All</option></select>')
                                .appendTo($(column.header()));
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            // Keep track of unique values
                            var uniqueValues = {};

                            // Populate the dropdown with unique values from column 5
                            column.data().each(function (d, j) {
                                // Get the HTML content of the cell
                                var cellText = column.cell(j, 2).node().innerHTML;
                                // Check if the value is unique before adding it to the dropdown
                                if (!uniqueValues[cellText]) {
                                    uniqueValues[cellText] = true;
                                    select.append('<option value="' + cellText + '">' + cellText + '</option>');
                                }
                            });

                            // Add change event listener to the dropdown
                            select.on('change', function () {
                                var val = $.fn.dataTable.util.escapeRegex(
                                    $(this).val()
                                );

                                // Search and filter the table
                                column.search(val ? '^' + val + '$' : '', true, false).draw();
                            });

                        });
                    this.api()
                        .columns([3])//city
                        .every(function () {
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            var column = this;

                            // Create the dropdown with "All" as the default option
                            var select = $('<br/><select class="form-control" placeholder="filter" style="width:100%;margin-top:5px;background-color:white"><option value="" selected>All</option></select>')
                                .appendTo($(column.header()));
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            // Keep track of unique values
                            var uniqueValues = {};

                            // Populate the dropdown with unique values from column 5
                            column.data().each(function (d, j) {
                                // Get the HTML content of the cell
                                var cellText = column.cell(j, 3).node().innerHTML;
                                // Check if the value is unique before adding it to the dropdown
                                if (!uniqueValues[cellText]) {
                                    uniqueValues[cellText] = true;
                                    select.append('<option value="' + cellText + '">' + cellText + '</option>');
                                }
                            });

                            // Add change event listener to the dropdown
                            select.on('change', function () {
                                var val = $.fn.dataTable.util.escapeRegex(
                                    $(this).val()
                                );

                                // Search and filter the table
                                column.search(val ? '^' + val + '$' : '', true, false).draw();
                            });

                        });
                    this.api()
                        .columns([4])//Status
                        .every(function () {
                            var column = this;
                            var select = $('<br/><select class="form-control" placeholder="search" style="width:100%;margin-top:5px;background-color:white""><option value="">All</option></select>')
                                .appendTo($(column.header()))
                                .on('change', function () {
                                    var val = $.fn.dataTable.util.escapeRegex($(this).val());
                                    column.search(val ? '^' + val + '$' : '', true, false).draw();
                                });
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            column
                                .data()
                                .unique()
                                .sort()
                                .each(function (d, j) {
                                    let name = [{ "name": "In-active", "value": 0 }, { "name": "Active", "value": 1 }]?.find(stat => stat.value == d)?.name;
                                    select.append('<option value="' + name + '">' + name + '</option>');
                                });
                        });
                },
            });
        // Extra step to do extra clean-up.
        return function () {
            $(`#${tableName}`).off('length.dt');
            table.destroy()
        }
    }, [featuredLocation])

    const onRefresh = () => {
        setSelectedLocation()
        setIsOpen(false)
        AdminStore.getFeaturedLocation();
    }
    const addFeaturedLocation = (values) => {
        if (selectedLocation?.id) {
            AdminStore.updateFeaturedLocation(values, onRefresh)
        } else {
            AdminStore.addFeaturedLocation(values, onRefresh)
        }
    }
    const openModal = () => {
        setSelectedLocation()
        setIsOpen(true)
    }
    const onDismiss = () => {
        setSelectedLocation()
        setIsOpen(false)
    }
    const onEdit = (data) => {
        setSelectedLocation(data)
        setIsOpen(true)
    }

    return (
        <React.Fragment>
            {/* <body> */}
            <Helmet>
                <title>Staffers LLC | Featured Location</title>
            </Helmet>
            <div className="container-scroller">
                <HeaderPage />
                <div className="container-fluid page-body-wrapper">
                    <DrawerMenuPage />
                    <div className="main-panel" style={{ right: 0, position: "absolute" }}>
                        <div className="content-wrapper p-3 bg-white ">
                            <h3 className="page-title">
                                <span className="page-title-icon linear text-white me-2">
                                    <i className="fas fa-user-friends"></i>
                                </span> Featured Location
                            </h3>
                            <div className='col-12 d-flex justify-content-lg-end pt-lg-0 pt-2'>
                                <a onClick={() => openModal(true)} href="#">
                                    <h1 className="page-title signup-link fs-4" >
                                        <span className="page-title-icon linear text-white me-2">
                                            <i className="fas fa-plus"></i>
                                        </span>Add Featured Location
                                    </h1>
                                </a>
                            </div>
                        </div>
                        <div className="row bg-white table-responsive" style={{ paddingTop: 20 }}>
                            <div className="table-wrapper">
                                <table className="table dt-responsive table-hover nowrap" style={{ width: "100%" }} id={tableName} ref={tableRef}>
                                </table>
                            </div>
                        </div>

                    </div>

                    {/* <!-- main-panel ends --> */}
                </div>
                {/* <!-- page-body-wrapper ends --> */}


            </div>
            {/* Add Featured Location*/}
            <CModal className="custom-modal-size" backdrop="static" visible={isOpen} onClose={onDismiss} >
                <CModalHeader onClose={onDismiss} >
                    <CModalTitle>{selectedLocation ? "Edit" : "Add"} Featured Location</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <Formik
                        initialValues={{
                            'id': selectedLocation?.id || '',
                            'status': selectedLocation?.status || '0',
                            'city': selectedLocation?.city || '',
                            'state': selectedLocation?.state || '',
                            'location_data': selectedLocation?.location_data || '',
                            'sequence': selectedLocation?.sequence || featuredLocation?.length + 1,
                        }}
                        validationSchema={FeaturedLocValidationSchema}
                        enableReinitialize
                        validateOnChange={false}
                        onSubmit={(values, { resetForm }) => {
                            addFeaturedLocation(values);
                        }}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, setFieldValue }) => (
                            <Form>
                                <div className="row g-2">
                                    <div className="col-sm-2 mb-2">
                                        <InputField
                                            className={"did-floating-input"}
                                            classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                            type="number"
                                            name="sequence"
                                            label={"Sequence"}
                                            min={1}
                                            value={values.sequence}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            required={true}
                                            disabled={true}
                                        />
                                    </div>
                                    <div className="col-sm-4 mb-2">
                                        <Select
                                            onChange={(e) => { setFieldValue("state", e.abbreviation), setFieldValue("city", '') }}
                                            onBlur={handleBlur}
                                            placeholder={''}
                                            label={'State'}
                                            styles={colourStylesState}
                                            value={states.filter(({ abbreviation }) => values?.state?.includes(abbreviation)) || ''}
                                            options={states}
                                            isRequired
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.abbreviation}
                                            components={{ Control, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                        />
                                        {errors.state ? (
                                            <div className="error-message">{errors.state}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-sm-6 mb-2">
                                        <Select
                                            onChange={(e) => { setFieldValue("city", e.label); setFieldValue("location_data", JSON.stringify(e)) }}
                                            onBlur={handleBlur}
                                            placeholder={''}
                                            label={'City'}
                                            isDisabled={!values?.state}
                                            styles={colourStylesState}
                                            isRequired
                                            getOptionLabel={(option) => option.label}
                                            getOptionValue={(option) => option.label}
                                            value={cities.filter(({ label }) => values?.city === label) || ''}
                                            options={cities?.filter(ct => ct.state === states?.find(x => x.abbreviation == values?.state)?.name)}
                                            components={{ Control, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                        />
                                        {errors.city ? (
                                            <div className="error-message">{errors.city}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-sm-6 mb-2">
                                        <SelectField
                                            name="status"
                                            label={"Status"}
                                            value={values.status}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            data={statusList}
                                            optionLabel={"label"}
                                            optionValue={"value"}
                                        />
                                        {errors.status ? (
                                            <div className="error-message">{errors.status}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="pt-4 d-flex justify-content-center pb-3">
                                    <button className="btn btn-primary w-25 p-2" type="submit">Submit</button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </CModalBody>
            </CModal >

            <Spinner isLoading={AdminStore.loading} />

        </React.Fragment >
    );
})
export const Control = (props) => {
    return (
        <>
            <label className={props.hasValue || props.isFocused ? props?.selectProps?.isRequired ? "did-floating-label did-floating-label-top did-floating-labe-astrisk" : "did-floating-label did-floating-label-top" : props?.selectProps?.isRequired ? "did-floating-label did-floating-label did-floating-labe-astrisk" : "did-floating-label did-floating-label"} style={{ zIndex: 100 }}>{props?.selectProps?.label}</label>
            <components.Control {...props} />
        </>
    );
};
const MoreSelectedBadge = ({ items }) => {
    const style = {
        marginLeft: "auto",
        background: "#d4eefa",
        borderRadius: "4px",
        fontFamily: "Open Sans",
        fontSize: "11px",
        padding: "3px",
        order: 99
    };

    const title = items.join(", ");
    const length = items.length;
    const label = `+ ${length} item${length !== 1 ? "s" : ""}`;

    return (
        <div style={style} title={title}>
            {label}
        </div>
    );
};
const MultiValue = ({ index, getValue, ...props }) => {
    const maxToShow = 2;
    const overflow = getValue()
        .slice(maxToShow)
        .map((x) => x.label);

    return index < maxToShow ? (
        <components.MultiValue {...props} />
    ) : index === maxToShow ? (
        <MoreSelectedBadge items={overflow} />
    ) : null;
};
export default FeaturedLocation;
