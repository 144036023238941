import React from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

const MultiStepProgressBarInner = props => {
  var stepPercentage = 0;

  if (props.currentStep === 0) {
    stepPercentage = 0;
  } else if (props.currentStep === 1) {
    stepPercentage = 50;
  } else if (props.currentStep === 2) {
    stepPercentage = 100;
  } else {
    stepPercentage = 0;
  }
  return (
    <ProgressBar
      percent={stepPercentage}
      fillBackground="linear-gradient(to right, #fefb72, #f0bb31)"
    >
      <Step transition="scale">
        {({ accomplished, index }) => (
          <div
            onClick={() => props.setStep(index)}
            className={`indexedStep ${accomplished ? "accomplished" : null}`}
          >
            <small className="text-center">Equal Opportunity Employer</small>
          </div>
        )}
      </Step>
      <Step transition="scale">
        {({ accomplished, index }) => (
          <div
            onClick={() => props.setStep(index)}
            className={`indexedStep ${accomplished ? "accomplished" : null}`}
          >
            <small className="text-center">Veteran Status</small>
          </div>
        )}
      </Step>
      <Step transition="scale">
        {({ accomplished, index }) => (
          <div
            onClick={() => props.setStep(index)}
            className={`indexedStep ${accomplished ? "accomplished" : null}`}
          >
            <small className="text-center">Disability Status</small>
          </div>
        )}
      </Step>
    </ProgressBar>

  );
};

export default MultiStepProgressBarInner;
