import React from "react";

const SelectField = ({ data, value,defaultValue, label, optionLabel, optionValue, name, disabled, onChange, onBlur, required, id }) => (
  <div className="did-floating-label-content">
    <select className="did-floating-dropdown"
      name={name}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
      required={required}
      id={id || ""}
    >
      <option
        value=""
      >
      </option>
      {data?.map((option, index) => (
        <option
          key={index + 'key'}
          value={option[optionValue]}
        >
          {option[optionLabel]}
        </option>
      ))}
    </select>
    <label className={value == "" ? required ? "did-floating-label did-floating-label-down did-floating-labe-astrisk" : "did-floating-label did-floating-label-down" : required ? "did-floating-label did-floating-label-top did-floating-labe-astrisk" : "did-floating-label did-floating-label-top"}>{label}</label>
  </div>
);

export default SelectField;