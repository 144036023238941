import React, { useState } from "react";

import { observer } from "mobx-react";
import { Link, useNavigate } from "react-router-dom";
import HomeStore from "@store/HomeStore";
import Skeleton from "react-loading-skeleton";

const FooterWeb = observer(() => {
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (!searchTerm || searchTerm?.trim()?.length === 0) {
      return;
    }
    console.log("searchTerm", searchTerm);
    navigate("/search", { state: { search: searchTerm } });
    setSearchTerm("");
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <React.Fragment>
      <div className="footer-background">
        <div className="web-footer">
          <div className="container">
            <div className="row align-items-center">
              {/* <div className="col-md-3">
                <div className="footer-coin">
                  <img
                    src="/assets/images/web-images/goldseal_national-png_ashley.png"
                    className="img-fluid"
                    alt="rescue"
                  />
                </div>
              </div> */}
              <div className="col-md-4">
                <div className="contact-footer">
                  <div>
                    <img
                      src="/assets/images/web-images/Location.png"
                      alt="location"
                      className="img-fluid"
                    />
                  </div>
                  <a
                    href="http://maps.google.com/?q=9980 South 300 West,Suite 200 Sandy, UT 84070"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <p>9980 South 300 West, Suite 200</p>
                    <p> Sandy, UT 84070</p>
                  </a>
                </div>
              </div>
              <div className="col-md-4">
                <div className="contact-footer">
                  <div>
                    <img
                      src="/assets/images/web-images/help.png"
                      alt="help"
                      className="img-fluid"
                    />
                  </div>

                  <div className="d-flex align-items-center flex-wrap justify-content-center">
                    <a
                      className="btn btn-link-custom"
                      href={`mailto:${
                        HomeStore?.data?.dashboardData?.find(
                          (x) => x.key_name === "Official E-Mail"
                        )?.value
                      }`}
                    >
                      <span className="mb-2" style={{ textTransform: "none" }}>
                        <i className="fa fa-envelope me-1"></i>
                        {HomeStore?.data?.dashboardData?.find(
                          (x) => x.key_name === "Official E-Mail"
                        )?.value || "staffers@staffers.us"}
                      </span>
                    </a>
                    <span className="mb-2" style={{ textTransform: "none" }}>
                      ,
                    </span>
                    &nbsp;
                    <a
                      className="btn btn-link-custom"
                      href={`mailto:ustravel@staffers.us`}
                    >
                      <span className="mb-2" style={{ textTransform: "none" }}>
                        <i className="fa fa-envelope me-1"></i>
                        {"ustravel@staffers.us"}
                      </span>
                    </a>
                  </div>

                  <a
                    className="btn btn-link-custom"
                    href={`tel:${
                      HomeStore?.data?.dashboardData?.find(
                        (x) => x.key_name === "Official Contact"
                      )?.value
                    }`}
                  >
                    <p className="mb-2">
                      <i className="fa fa-phone-alt me-1"></i>
                      {HomeStore?.data?.dashboardData?.find(
                        (x) => x.key_name === "Official Contact"
                      )?.value || "(801) 820-0788"}
                    </p>
                  </a>
                </div>
              </div>
              <div className="col-md-4">
                <div className="search-footer">
                  <form onSubmit={handleSearchSubmit}>
                    <div class="input-group ">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search"
                        aria-label="Search"
                        aria-describedby="basic-addon1"
                        onChange={handleSearchChange}
                      />
                      <button class="input-group-text" id="basic-addon1">
                        <svg
                          height="21"
                          viewBox="0 0 20 21"
                          width="20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="m19.867263 17.9850772-6.2569568-6.2571569c.8125944-1.2113916 1.2491913-2.63578181 1.2491913-4.12717153 0-1.98458631-.7727946-3.85037345-2.176185-5.25376377-1.4033903-1.40319033-3.26897741-2.175985-5.25356373-2.175985-1.98458631 0-3.85037345.77279467-5.25376377 2.175985-1.40319033 1.40339032-2.175985 3.26917746-2.175985 5.25376377 0 1.98458632.77279467 3.85017343 2.176185 5.25356373 1.40339032 1.4033904 3.26897746 2.176185 5.25376377 2.176185 1.5081896 0 2.94737963-.4467969 4.16757123-1.2769912l6.2509569 6.250557c.2109986.2111985.5989959.1651988.8663941-.1021993l1.0503927-1.0503928c.2673982-.2673982.3131979-.6553955.1019993-.866394zm-12.43751423-4.9673658c-2.9871794 0-5.41716265-2.4299832-5.41716265-5.41696263 0-2.9869794 2.43018325-5.41716265 5.41716265-5.41716265 2.98697943 0 5.41696263 2.43018325 5.41696263 5.41716265 0 2.98697943-2.4299832 5.41696263-5.41696263 5.41696263z"
                            fill="#75459b"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  </form>
                </div>
                <div className="d-flex flex-column">
                  <div className="pt-1">
                    <Link to="/admin-login" className="admin-link">
                      Are you an Admin?
                    </Link>
                  </div>
                  <div className="pt-1">
                    <p>
                      <a
                        className="policy-link"
                        rel="noreferrer"
                        href={
                          HomeStore?.data?.dashboardData?.find(
                            (x) => x.key_name === "Official Youtube"
                          )?.value
                        }
                        target="_blank"
                      >
                        <small className="ms-4">
                          <i className="fas fa-question-circle me-2"></i>
                          {HomeStore?.data?.dashboardData?.find(
                            (x) => x.key_name === "Official Youtube"
                          )?.value ? (
                            "Help Videos"
                          ) : (
                            <Skeleton
                              className="info-text"
                              height={17}
                              width={50}
                            />
                          )}
                        </small>
                      </a>
                    </p>
                  </div>
                  <a href="#" className="back-to-top ">
                    <i className="fas fa-chevron-up text-white"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="web-footer-strip">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="copyright-link">
                  <p>
                    Staffers Holdings LLC. © Copyright{" "}
                    {new Date().getFullYear()}
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <ul className="social-link">
                  <li>
                    <a
                      rel="noreferrer"
                      className="btn btn-square btn-outline-light rounded-circle me-1 social-btn footer-social"
                      target="_blank"
                      href={
                        HomeStore?.data?.dashboardData?.find(
                          (x) => x.key_name === "Facebook Link"
                        )?.value || "https://www.facebook.com/staffersllc/"
                      }
                    >
                      <i
                        className="fab fa-facebook-f"
                        style={{ paddingLeft: 1, paddingRight: 1 }}
                      ></i>
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noreferrer"
                      className="btn btn-square btn-outline-light rounded-circle me-1 social-btn footer-social"
                      target="_blank"
                      href={
                        HomeStore?.data?.dashboardData?.find(
                          (x) => x.key_name === "Linkedin Link"
                        )?.value ||
                        "https://www.linkedin.com/company/staffersinc?trk=public_jobs_topcard-org-name"
                      }
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noreferrer"
                      className="btn btn-square btn-outline-light rounded-circle me-1 social-btn footer-social"
                      target="_blank"
                      href={
                        HomeStore?.data?.dashboardData?.find(
                          (x) => x.key_name === "Official Youtube"
                        )?.value ||
                        "https://www.youtube.com/channel/UCbHMLDnTQibj7QNb0v2L8jQ"
                      }
                    >
                      <i className="fab fa-youtube-square"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-4">
                <div className="copyright-link">
                  <p className="text-align-end">
                    <Link
                      className="policy-link"
                      to="/terms-and-conditions"
                      target
                    >
                      Terms & Conditions
                    </Link>{" "}
                    |{" "}
                    <Link className="policy-link" to="/privacy-policy">
                      {" "}
                      Privacy Policy
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
});

export default FooterWeb;
