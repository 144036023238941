import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

const ContentWithTabs = ({ section1Data }) => {
  const [isLoading, setIsLoading] = useState(true);

  // Simulating data fetching
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Adjust the timeout as needed
  }, []);

  return (
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 col-md-6">
          <div className="who-content">
            <h2 className="common-web-head mb-4">
              {isLoading ? (
                <Skeleton width={300} height={40} />
              ) : (
                section1Data?.[0] ||
                `Ready to 'Come to the Rescue' <br /> for Those in Need.`
              )}
            </h2>
            <div>
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-bs-toggle="tab"
                    href="#home"
                  >
                    {isLoading ? (
                      <Skeleton width={100} />
                    ) : (
                      section1Data?.[1] || `Our Mission`
                    )}
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-bs-toggle="tab" href="#menu1">
                    {isLoading ? (
                      <Skeleton width={100} />
                    ) : (
                      section1Data?.[2] || `Our Vision`
                    )}
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div id="home" className="container tab-pane active">
                  <br />
                  <p>
                    {isLoading ? (
                      <Skeleton count={3} />
                    ) : (
                      section1Data?.[3] ||
                      `Our mission is to connect skilled healthcare professionals with organizations, optimizing workforce dynamics and elevating patient care standards. Committed to compliance, continuous improvement, and fostering positive environments, we strive to create a collaborative space that contributes to the well-being of communities and industry innovation.`
                    )}
                  </p>
                </div>
                <div id="menu1" className="container tab-pane fade">
                  <br />
                  <p>
                    {isLoading ? (
                      <Skeleton count={3} />
                    ) : (
                      section1Data?.[4] ||
                      `We envision a healthcare landscape where seamlessly tailored staffing solutions meet the dynamic and evolving needs of institutions. Our vision is to contribute to a future where every patient receives exceptional care, facilitated by a diverse, qualified, and compassionate workforce. Through innovation and excellence, we strive to shape a brighter and healthier tomorrow for all.`
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <Link
                to="/why-work-with-staffers"
                className="common-web-btn purple-border mt-3 p-20 fw-600 btn-icon"
              >
                <i
                  className="fa fa-arrow-right left-icon"
                  aria-hidden="true"
                ></i>
                {isLoading ? (
                  <Skeleton width={200} />
                ) : (
                  `WHY WORK WITH STAFFERS`
                )}
                <i
                  className="fa fa-arrow-right right-icon"
                  aria-hidden="true"
                ></i>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 ">
          <div className="rescue-img remove-hover">
            {isLoading ? (
              <Skeleton width={300} height={200} />
            ) : (
              <img
                className="img-fluid"
                src="/assets/img/website/travelnursecollage.png"
                alt="rescue"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentWithTabs;
