/* eslint-disable */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react"
import HeaderPage from "../layout/HeaderPage";
import DrawerMenuPage from "../layout/DrawerMenuPage";
import DashboardCardPage from "./components/DashboardCardPage";
import DashboardRecentPage from "./components/DashboardRecentPage";
import AdminStore from "@store/AdminStore";
import { Helmet } from "react-helmet";

const AdminDashboardPage = observer(() => {
    const [dashboard, setDashboard] = useState([]);

    useEffect(() => {
        AdminStore.getDashboard();
    }, []);

    useEffect(() => {
        setDashboard(AdminStore?.data?.dashboard)
    }, [AdminStore?.data?.dashboard]);


    return (
        <React.Fragment>
            <Helmet>
                <title>Staffers LLC | Admin Dashboard</title>
            </Helmet>
            {/* <body> */}
            <div className="container-scroller">
                <HeaderPage />
                <div className="container-fluid page-body-wrapper">
                    <DrawerMenuPage />
                    <div className="main-panel" style={{ right: 0, position: "absolute" }}>
                        <div className="content-wrapper">
                            <h3 className="page-title">
                                <span className="page-title-icon linear text-white me-2">
                                    <i className="fas fa-tachometer-alt"></i>
                                </span> Dashboard
                            </h3>
                            <div className="mt-4">
                                <DashboardCardPage counts={dashboard} />
                                <DashboardRecentPage recentUser={dashboard?.recent_users} />
                            </div>
                        </div>
                        {/* <!-- partial --> */}
                    </div>
                    {/* <!-- main-panel ends --> */}
                </div>
                {/* <!-- page-body-wrapper ends --> */}
            </div>
            {/* </body> */}
        </React.Fragment>
    );
})
export default AdminDashboardPage;