import React from 'react'
import Header from '@components/PDFHeader';
import moment from "moment";
export const HealthQuestionaire = (props) => {
  var today = moment(new Date()).format("MM/DD/YYYY hh:mm:ss");
  return (
    <div id="form-content">
      <Header title="Health Questionnaire" />
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="border-box">
                <p className="mx-2 mb-0">
                  <b>
                    Congratulations on your conditional offer of employment.
                  </b>{" "}
                 <span>It is Staffer's policy that employees be able to safely
                  perform essential functions of the job without risk to your
                  health or the safety and health of our patients and staff.</span> 
                </p>
              </div>
              <div className="mb-3">
                <p>
                  <b>Instructions:</b>
                </p>
                <ul>
                  <li>
                    <span>
                      Answer all the questions and provide as much detail as
                      possible.
                    </span>
                  </li>
                </ul>
              </div>
              <div className="border-box p-2 input-bottom-border">
                <div className="d-md-flex gap-3 mb-2">
                  <div className="w-100">
                    <div className="d-flex align-items-center">
                      <label className="w-30">Employee Name<small className="text-danger">*</small>: </label>
                      <input type="text" className="w-100" required value={props.inputValues.full_name} minLength={2} maxLength={20} name="full name" onChange={(e) => props.handleInputChange('full_name', e.target.value)} />
                    </div>
                  </div>
                  <div className="w-100">
                    <div className="d-flex align-items-center">
                      <label className="w-30">Position<small className="text-danger">*</small>: </label>
                      <input type="text" className="w-100" value={props.inputValues.Position} minLength={2} maxLength={20} name="position" required onChange={(e) => props.handleInputChange('Position', e.target.value)} />
                    </div>
                  </div>
                </div>
                <div className="d-md-flex gap-3 mb-1">
                  <div className="w-100">
                    <div className="d-flex gap-2 align-items-center">
                      <label className="">Address<small className="text-danger">*</small>: </label>
                      <input type="text" className="w-100" required value={props.inputValues.address} minLength={2} maxLength={20} name="address" onChange={(e) => props.handleInputChange('address', e.target.value)} />
                    </div>
                  </div>
                </div>
                <div className="d-md-flex gap-3 mb-2 offset-md-2">
                  <div className="w-100">
                    <div className="d-flex gap-2 align-items-center">
                      <label className="">Street</label>
                      <input type="text" className="w-100 " value={props.inputValues.street} onChange={(e) => props.handleInputChange('street', e.target.value)} />
                    </div>
                  </div>
                  <div className="w-100">
                    <div className="d-flex gap-2 align-items-center">
                      <label className="">City<small className="text-danger">*</small></label>
                      <input className="w-100" type="text" minLength={2} maxLength={30} name="city" required value={props.inputValues.city} onChange={(e) => props.handleInputChange('city', e.target.value)} />
                    </div>
                  </div>
                  <div className="w-100">
                    <div className="d-flex gap-2 align-items-center">
                      <label className="">State<small className="text-danger">*</small> </label>
                      <input className="w-100 " type="text" minLength={2} maxLength={20} name="state" required value={props.inputValues.state} onChange={(e) => props.handleInputChange('state', e.target.value)} />
                    </div>
                  </div>
                  <div className="w-100">
                    <div className="d-flex gap-2 align-items-center">
                      <label className="">Zip Code<small className="text-danger">*</small></label>
                      <input type="number" min="1" required className="w-100 " name='zip code' value={props.inputValues.zip} onChange={(e) => props.handleInputChange('zip', e.target.value)} />
                    </div>
                  </div>
                </div>
                <div className="d-md-flex gap-3 mb-2 align-items-center ">
                  <p className="w-30 mb-0"> Telephone Number:</p>
                  <div className="w-100">
                    <div className="d-flex gap-2 align-items-center">
                      <label className="">Home<small className="text-danger">*</small>: </label>
                      <input className="w-100 " type="text" minLength={7} maxLength={15} name="telephone number" required value={props.inputValues.telephone} onChange={(e) => props.handleInputChange('telephone', e.target.value)} />
                    </div>
                  </div>
                  <div className="w-100">
                    <div className="d-flex gap-2 align-items-center">
                      <label className="">Work<small className="text-danger">*</small>: </label>
                      <input className="w-100 " type="text" minLength={7} maxLength={15} name="work telephone number" required value={props.inputValues.work} onChange={(e) => props.handleInputChange('work', e.target.value)} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="input-bottom-border p-2">
                <ul className="number-list">
                  <li>
                    <p className="mb-0">
                      Have you ever worked under a different name? Yes or No If
                      “Yes”, please list names and circumstances:
                    </p>
                    <textarea rows={2} type="text" value={props.inputValues.workedList} onChange={(e) => props.handleInputChange('workedList', e.target.value)} />
                  </li>
                  <li>
                    <p className="mb-0">
                      Have you ever received workers’ compensation benefits or
                      filed a claim for job-related injuries or illnesses? Yes
                      or No
                    </p>
                    <p className="mb-0">
                      Date of Claims: <input className="" type="date" style={{width:100}}  value={props.inputValues.claims} onChange={(e) => props.handleInputChange('claims', e.target.value)} />
                      List specific part of body and diagnosis for each claim:
                    </p>
                    <textarea rows={1} type="text" />
                    <p className="mb-0">
                      If "Yes", please explain circumstances:{" "}
                    </p>
                    <textarea rows={1} type="text" value={props.inputValues.circumstances} onChange={(e) => props.handleInputChange('circumstances', e.target.value)} />
                  </li>
                  <li>
                    <p className="mb-0">
                      Do you currently have any work restrictions? Yes or No If
                      “Yes”, what are they:
                    </p>
                    <textarea rows={2} type="text" value={props.inputValues.workRestrictions} onChange={(e) => props.handleInputChange('workRestrictions', e.target.value)} />
                  </li>
                  <li>
                    <p className="mb-0 d-sm-flex gap-2 align-items-center">
                      In previous employment, have you been exposed to:
                      <label className="d-flex gap-1">
                        <input
                          type="checkbox"
                          name="chemicalFumes"
                          checked={props.inputValues.chemicalFumes} // Use 'checked' attribute
                          onChange={(e) => { props.handleInputChange('chemicalFumes', e.target.checked) }} // Use 'e.target.checked'
                        />
                        <span>Chemical Fumes</span>
                      </label>
                      <label className="d-flex gap-1">
                        <input type="checkbox"
                          name="heavyLifting"
                          checked={props.inputValues.heavyLifting} // Use 'checked' attribute
                          onChange={(e) => { props.handleInputChange('heavyLifting', e.target.checked) }} // Use 'e.target.checked'
                        />
                        <span>Heavy Lifting</span>
                      </label>
                      <label className="d-flex gap-1">
                        <input
                          type="checkbox"
                          name="repetitiveMotion"
                          checked={props.inputValues.repetitiveMotion} // Use 'checked' attribute
                          onChange={(e) => { props.handleInputChange('repetitiveMotion', e.target.checked) }}
                        />
                        <span> Repetitive Motion</span>
                      </label>
                    </p>
                    <p className="mb-0">
                      If “Yes”, did this affect your ability to perform your
                      job? Please explain:
                    </p>
                    <textarea rows={2} type="text" value={props.inputValues.ability} onChange={(e) => props.handleInputChange('ability', e.target.value)} />
                  </li>
                  <li>
                    <p className="mb-0">
                      Have you had any prior occupational exposures to chemicals
                      and/or cleaning solvents which caused skin sensitivity, an
                      allergic reaction, breathing difficulties, nausea,
                      headaches, or/or nosebleed? &nbsp;<label htmlFor="nosebleedNo">No</label>
                      <input
                        type="radio"
                        name="nosebleed"
                        label="No"
                        value="nosebleedNo"
                        checked={props?.inputValues?.nosebleed === "nosebleedNo"} // Use 'checked' attribute
                        onChange={(e) => { props?.handleInputChange('nosebleed', e.target.value) }}
                      /> &nbsp; or &nbsp;
                      <input
                        type="radio"
                        name="nosebleed"
                        value="nosebleedYes"
                        checked={props?.inputValues?.nosebleed === "nosebleedYes"} // Use 'checked' attribute
                        onChange={(e) => { props?.handleInputChange('nosebleed', e.target.value) }}
                      />
                      &nbsp;<label htmlFor="nosebleedNo">Yes</label> &nbsp; If “Yes”, explain:
                    </p>
                    <textarea rows={1} type="text" value={props.inputValues.occupationalExposures} onChange={(e) => props.handleInputChange('occupationalExposures', e.target.value)} />
                  </li>
                  <li>
                    <p className="mb-0">
                      Do you have now, or have you ever had, any of the
                      following?
                    </p>
                    <div className="row ">
                      <div className="col-md-4">
                        <table className="set-line-height">
                          <thead>
                            <tr>
                              <th width="10%">Yes</th>
                              <th width="10%">No</th>
                              <th width="20%">Chest</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="persistentCough"
                                    value="persistentCoughYes"
                                    checked={props?.inputValues?.persistentCough === "persistentCoughYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('persistentCough', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="persistentCough"
                                    value="persistentCoughNo"
                                    checked={props?.inputValues?.persistentCough === "persistentCoughNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('persistentCough', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">Persistent Cough</label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="wheezing"
                                    value="wheezingNo"
                                    checked={props?.inputValues?.wheezing === "wheezingNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('wheezing', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="wheezing"
                                    value="wheezingYes"
                                    checked={props?.inputValues?.wheezing === "wheezingYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('wheezing', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">Wheezing</label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="asthma"
                                    value="asthmaNo"
                                    checked={props?.inputValues?.asthma === "asthmaNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('asthma', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="asthma"
                                    value="asthmaYes"
                                    checked={props?.inputValues?.asthma === "asthmaYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('asthma', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">Asthma</label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="bronchitis"
                                    value="bronchitisNo"
                                    checked={props?.inputValues?.bronchitis === "bronchitisNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('bronchitis', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="bronchitis"
                                    value="bronchitisYes"
                                    checked={props?.inputValues?.bronchitis === "bronchitisYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('bronchitis', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">Bronchitis</label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="pneumonia"
                                    value="pneumoniaNo"
                                    checked={props?.inputValues?.pneumonia === "pneumoniaNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('pneumonia', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="pneumonia"
                                    value="pneumoniaYes"
                                    checked={props?.inputValues?.pneumonia === "pneumoniaYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('pneumonia', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">Pneumonia</label>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-md-4">
                        <table className="set-line-height">
                          <thead>
                            <tr>
                              <th width="10%">Yes</th>
                              <th width="10%">No</th>
                              <th width="20%">Chest</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Tuberculosis"
                                    value="TuberculosisNo"
                                    checked={props?.inputValues?.Tuberculosis === "TuberculosisNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Tuberculosis', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Tuberculosis"
                                    value="TuberculosisYes"
                                    checked={props?.inputValues?.Tuberculosis === "TuberculosisYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Tuberculosis', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">Tuberculosis</label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Chickenpox"
                                    value="ChickenpoxNo"
                                    checked={props?.inputValues?.Chickenpox === "ChickenpoxNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Chickenpox', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Chickenpox"
                                    value="ChickenpoxYes"
                                    checked={props?.inputValues?.Chickenpox === "ChickenpoxYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Chickenpox', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">Chickenpox</label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Hepatitis"
                                    value="HepatitisNo"
                                    checked={props?.inputValues?.Hepatitis === "HepatitisNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Hepatitis', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Hepatitis"
                                    value="HepatitisYes"
                                    checked={props?.inputValues?.Hepatitis === "HepatitisYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Hepatitis', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">Hepatitis (Type__<input value={props.inputValues.htype} onChange={(e) => props.handleInputChange('htype', e.target.value)} />)</label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Measles"
                                    value="MeaslesNo"
                                    checked={props?.inputValues?.Measles === "MeaslesNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Measles', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Measles"
                                    value="MeaslesYes"
                                    checked={props?.inputValues?.Measles === "MeaslesYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Measles', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">Measles</label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Mumps"
                                    value="MumpsNo"
                                    checked={props?.inputValues?.Mumps === "MumpsNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Mumps', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Mumps"
                                    value="MumpsYes"
                                    checked={props?.inputValues?.Mumps === "MumpsYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Mumps', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">Mumps</label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Rhewmatic"
                                    value="RhewmaticNo"
                                    checked={props?.inputValues?.Rhewmatic === "RhewmaticNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Rhewmatic', e.target.value) }}

                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Rhewmatic"
                                    value="RhewmaticYes"
                                    checked={props?.inputValues?.Rhewmatic === "RhewmaticYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Rhewmatic', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  Rhewmatic/Scarlet Fever
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Polio"

                                    value="PolioNo"
                                    checked={props?.inputValues?.Polio === "PolioNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Polio', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Polio"
                                    value="PolioYes"
                                    checked={props?.inputValues?.Polio === "PolioYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Polio', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">Polio</label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="MRSA"
                                    value="MRSANo"
                                    checked={props?.inputValues?.MRSA === "MRSANo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('MRSA', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="MRSA"
                                    value="MRSAYes"
                                    checked={props?.inputValues?.MRSA === "MRSAYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('MRSA', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">MRSA</label>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-md-4">
                        <table className="set-line-height">
                          <thead>
                            <tr>
                              <th width="10%">Yes</th>
                              <th width="10%">No</th>
                              <th width="50%">Muscle/Skelton</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Head"
                                    value="HeadNo"
                                    checked={props?.inputValues?.Head === "HeadNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Head', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Head"
                                    value="HeadYes"
                                    checked={props?.inputValues?.Head === "HeadYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Head', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">Head Pain</label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Neck"
                                    value="NeckNo"
                                    checked={props?.inputValues?.Neck === "NeckNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Neck', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Neck"
                                    value="NeckYes"
                                    checked={props?.inputValues?.Neck === "NeckYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Neck', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">Neck Pain</label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Upper"
                                    value="UpperNo"
                                    checked={props?.inputValues?.Upper === "UpperNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Upper', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Upper"
                                    value="UpperYes"
                                    checked={props?.inputValues?.Upper === "UpperYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Upper', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">Upper Back Pain</label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Middle"
                                    value="MiddleNo"
                                    checked={props?.inputValues?.Middle === "MiddleNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Middle', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Middle"
                                    value="MiddleYes"
                                    checked={props?.inputValues?.Middle === "MiddleYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Middle', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">Middle Back Pain</label>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Low"
                                    value="LowNo"
                                    checked={props?.inputValues?.Low === "LowNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Low', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Low"
                                    value="LowYes"
                                    checked={props?.inputValues?.Low === "LowYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Low', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">Low Back Pain</label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Swollen"
                                    value="SwollenNo"
                                    checked={props?.inputValues?.Swollen === "SwollenNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Swollen', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Swollen"
                                    value="SwollenYes"
                                    checked={props?.inputValues?.Swollen === "SwollenYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Swollen', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">Swollen Joints</label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Arthritis"

                                    value="ArthritisNo"
                                    checked={props?.inputValues?.Arthritis === "ArthritisNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Arthritis', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Arthritis"
                                    value="ArthritisYes"
                                    checked={props?.inputValues?.Arthritis === "ArthritisYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Arthritis', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">Rheumatism/Arthritis</label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Foot"
                                    value="FootYes"
                                    checked={props?.inputValues?.Foot === "FootYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Foot', e.target.value) }}

                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Foot"
                                    value="FootNo"
                                    checked={props?.inputValues?.Foot === "FootNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Foot', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  Foot Trouble - Right/Left/Both
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Wrist"
                                    value="WristNo"
                                    checked={props?.inputValues?.Wrist === "WristNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Wrist', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Wrist"
                                    value="WristYes"
                                    checked={props?.inputValues?.Wrist === "WristYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Wrist', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  Hand/Wrist - Right/Left/Both
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Elbow"
                                    value="ElbowNo"
                                    checked={props?.inputValues?.Elbow === "ElbowNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Elbow', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Elbow"
                                    value="ElbowYes"
                                    checked={props?.inputValues?.Elbow === "ElbowYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Elbow', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">Elbow - R/L/Both</label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Shoulder"
                                    value="ShoulderNo"
                                    checked={props?.inputValues?.Shoulder === "ShoulderNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Shoulder', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Shoulder"
                                    value="ShoulderYes"
                                    checked={props?.inputValues?.Shoulder === "ShoulderYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Shoulder', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  Shoulder Problems - Right/Left/Both
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Knee"
                                    value="KneeNo"
                                    checked={props?.inputValues?.Knee === "KneeNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Knee', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Knee"
                                    value="KneeYes"
                                    checked={props?.inputValues?.Knee === "KneeYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Knee', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  Knee Problems - Right/Left/Both
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Leg"
                                    value="LegNo"
                                    checked={props?.inputValues?.Leg === "LegNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Leg', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Leg"
                                    value="LegYes"
                                    checked={props?.inputValues?.Leg === "LegYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Leg', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  Leg - Right/Left/Both
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <p>
                      (Employee Name:  <input required value={props.inputValues.full_name} onChange={(e) => props.handleInputChange('full_name', e.target.value)} />)
                    </p>
                    <p>
                      <b>Miscellaneous:</b>
                    </p>
                    <div className="row">
                      <div className="col-md-4">
                        <table className="set-line-height">
                          <thead>
                            <tr>
                              <th width="10%">Yes</th>
                              <th width="10%">No</th>
                              <th width="30%"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Heart"
                                    value="HeartYes"
                                    checked={props?.inputValues?.Heart === "HeartYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Heart', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>

                                <label className="">
                                  <input
                                    type="radio"
                                    name="Heart"
                                    value="HeartNo"
                                    checked={props?.inputValues?.Heart === "HeartNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Heart', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">Heart Problems</label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Shortness"
                                    value="ShortnessYes"
                                    checked={props?.inputValues?.Shortness === "ShortnessYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Shortness', e.target.value) }}
                                  />
                                </label>

                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Shortness"
                                    value="ShortnessNo"
                                    checked={props?.inputValues?.Shortness === "ShortnessNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Shortness', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  Shortness of Breath or
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Chest"
                                    value="ChestYes"
                                    checked={props?.inputValues?.Chest === "ChestYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Chest', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Chest"
                                    value="ChestNo"
                                    checked={props?.inputValues?.Chest === "ChestNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Chest', e.target.value) }}
                                  />
                                </label>

                              </td>
                              <td>
                                <label className="">Chest Pain/Tightness</label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Blood"
                                    value="BloodYes"
                                    checked={props?.inputValues?.Blood === "BloodYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Blood', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>

                                <label className="">
                                  <input
                                    type="radio"
                                    name="Blood"
                                    value="BloodNo"
                                    checked={props?.inputValues?.Blood === "BloodNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Blood', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  Blood Pressure Problems
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Nosebleeds"
                                    value="NosebleedsYes"
                                    checked={props?.inputValues?.Nosebleeds === "NosebleedsYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Nosebleeds', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Nosebleeds"
                                    value="NosebleedsNo"
                                    checked={props?.inputValues?.Nosebleeds === "NosebleedsNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Nosebleeds', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">Frequent Nosebleeds</label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Dizzy"
                                    value="DizzyYes"
                                    checked={props?.inputValues?.Dizzy === "DizzyYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Dizzy', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Dizzy"
                                    value="DizzyNo"
                                    checked={props?.inputValues?.Dizzy === "DizzyNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Dizzy', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">Dizzy Spells</label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Numbness"
                                    value="NumbnessYes"
                                    checked={props?.inputValues?.Numbness === "NumbnessYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Numbness', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Numbness"
                                    value="NumbnessNo"
                                    checked={props?.inputValues?.Numbness === "NumbnessNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Numbness', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  Numbness of Hands/Feet
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Varicose"
                                    value="VaricoseNo"
                                    checked={props?.inputValues?.Varicose === "VaricoseNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Varicose', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Varicose"
                                    value="VaricoseYes"
                                    checked={props?.inputValues?.Varicose === "VaricoseYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Varicose', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">Varicose Veins</label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Thrombophlebitis"
                                    value="ThrombophlebitisYes"
                                    checked={props?.inputValues?.Thrombophlebitis === "ThrombophlebitisYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Thrombophlebitis', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Thrombophlebitis"
                                    value="ThrombophlebitisNo"
                                    checked={props?.inputValues?.Thrombophlebitis === "ThrombophlebitisNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Thrombophlebitis', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">Thrombophlebitis</label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="LSugar"
                                    value="LSugarYes"
                                    checked={props?.inputValues?.LSugar === "LSugarYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('LSugar', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="LSugar"
                                    value="LSugarNo"
                                    checked={props?.inputValues?.LSugar === "LSugarNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('LSugar', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">Low Blood Sugar</label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Diabetes"
                                    value="DiabetesYes"
                                    checked={props?.inputValues?.Diabetes === "DiabetesYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Diabetes', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Diabetes"
                                    value="DiabetesNo"
                                    checked={props?.inputValues?.Diabetes === "DiabetesNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Diabetes', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">Diabetes</label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Bruise"
                                    value="BruiseYes"
                                    checked={props?.inputValues?.Bruise === "BruiseYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Bruise', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Bruise"
                                    value="BruiseNo"
                                    checked={props?.inputValues?.Bruise === "BruiseNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Bruise', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">Bruise Easily</label>
                              </td>
                            </tr>
                            <tr >
                              <td>
                                <label >
                                  <input
                                    type="radio"
                                    name="Epilepsy"
                                    value="EpilepsyYes"
                                    checked={props?.inputValues?.Epilepsy === "EpilepsyYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Epilepsy', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Epilepsy"
                                    value="EpilepsyNo"
                                    checked={props?.inputValues?.Epilepsy === "EpilepsyNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Epilepsy', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">Epilepsy</label>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-md-6">
                        <table className="set-line-height">
                          <thead>
                            <tr>
                              <th width="10%">Yes</th>
                              <th width="10%">No</th>
                              <th width="20%"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Swallowing"
                                    value="SwallowingYes"
                                    checked={props?.inputValues?.Swallowing === "SwallowingYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Swallowing', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Swallowing"
                                    value="SwallowingNo"
                                    checked={props?.inputValues?.Swallowing === "SwallowingNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Swallowing', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  Difficulty Swallowing
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Rashes"
                                    value="RashesYes"
                                    checked={props?.inputValues?.Rashes === "RashesYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Rashes', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Rashes"
                                    value="RashesNo"
                                    checked={props?.inputValues?.Rashes === "RashesNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Rashes', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">Rashes/Sores</label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Hoarseness"
                                    value="HoarsenessYes"
                                    checked={props?.inputValues?.Hoarseness === "HoarsenessYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Hoarseness', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Hoarseness"
                                    value="HoarsenessNo"
                                    checked={props?.inputValues?.Hoarseness === "HoarsenessNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Hoarseness', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  Hoarseness of the throat
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Amputation"
                                    value="AmputationYes"
                                    checked={props?.inputValues?.Amputation === "AmputationYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Amputation', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Amputation"
                                    value="AmputationNo"
                                    checked={props?.inputValues?.Amputation === "AmputationNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Amputation', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">Amputation</label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Circulation"
                                    value="CirculationYes"
                                    checked={props?.inputValues?.Circulation === "CirculationYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Circulation', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Circulation"
                                    value="CirculationNo"
                                    checked={props?.inputValues?.Circulation === "CirculationNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Circulation', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  Circulation Problems/Numbness
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Breathing"
                                    value="BreathingYes"
                                    checked={props?.inputValues?.Breathing === "BreathingYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Breathing', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Breathing"
                                    value="BreathingNo"
                                    checked={props?.inputValues?.Breathing === "BreathingNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Breathing', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">Difficulty Breathing</label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Blindness"
                                    value="BlindnessYes"
                                    checked={props?.inputValues?.Blindness === "BlindnessYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Blindness', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Blindness"
                                    value="BlindnessNo"
                                    checked={props?.inputValues?.Blindness === "BlindnessNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Blindness', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">Color Blindness</label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Hearing"
                                    value="HearingYes"
                                    checked={props?.inputValues?.Hearing === "HearingYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Hearing', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Hearing"
                                    value="HearingNo"
                                    checked={props?.inputValues?.Hearing === "HearingNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Hearing', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">Hearing Loss</label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Muscle"
                                    value="MuscleYes"
                                    checked={props?.inputValues?.Muscle === "MuscleYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Muscle', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Muscle"
                                    value="MuscleNo"
                                    checked={props?.inputValues?.Muscle === "MuscleNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Muscle', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">Muscle Weakness</label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Problems"
                                    value="ProblemsYes"
                                    checked={props?.inputValues?.Problems === "ProblemsYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Problems', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Problems"
                                    value="ProblemsNo"
                                    checked={props?.inputValues?.Problems === "ProblemsNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Problems', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  Problems with sight (other than use of
                                  glasses)
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Itching"
                                    value="ItchingYes"
                                    checked={props?.inputValues?.Itching === "ItchingYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Itching', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Itching"
                                    value="ItchingNo"
                                    checked={props?.inputValues?.Itching === "ItchingNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Itching', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">Itching/Burning Skin</label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Jaundice"
                                    value="JaundiceYes"
                                    checked={props?.inputValues?.IJaundice === "JaundiceYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Jaundice', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Jaundice"
                                    value="JaundiceNo"
                                    checked={props?.inputValues?.Jaundice === "JaundiceNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Jaundice', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">Jaundice</label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Latex"
                                    value="LatexYes"
                                    checked={props?.inputValues?.Latex === "LatexYes"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Latex', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  <input
                                    type="radio"
                                    name="Latex"
                                    value="LatexNo"
                                    checked={props?.inputValues?.Latex === "LatexNo"} // Use 'checked' attribute
                                    onChange={(e) => { props?.handleInputChange('Latex', e.target.value) }}
                                  />
                                </label>
                              </td>
                              <td>
                                <label className="">
                                  Latex Allergy/Sensitivity
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <p>
                      Please provide additional information about any “Yes”
                      response in #8 such as a diagnosis and part of body:
                      <textarea row={1} value={props.inputValues.diagnosis} onChange={(e) => props.handleInputChange('diagnosis', e.target.value)} />
                    </p>
                  </li>
                  <li>
                    <div className="">
                      <table className="set-line-height">
                        <thead>
                          <tr>
                            <th width="30%">
                              <p className="mb-0">
                                Have you ever been immunized for:
                              </p>
                            </th>
                            <th width="10%">Yes</th>
                            <th width="10%">No</th>
                            <th width="20%">Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <label className="">Diphtheria/Tetanus</label>
                            </td>
                            <td>
                              <label className="">
                                <input
                                  type="radio"
                                  name="Diphtheria"
                                  value="DiphtheriaYes"
                                  checked={props?.inputValues?.Diphtheria === "DiphtheriaYes"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('Diphtheria', e.target.value) }}

                                />
                              </label>
                            </td>
                            <td>
                              <label className="">
                                <input
                                  type="radio"
                                  name="Diphtheria"
                                  value="DiphtheriaNo"
                                  checked={props?.inputValues?.Diphtheria === "DiphtheriaNo"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('Diphtheria', e.target.value) }}
                                />
                              </label>
                            </td>
                            <td className="input-bottom-border">
                              <input  type="date" style={{width:100}} value={props.inputValues.ExpDiphtheria} onChange={(e) => props.handleInputChange('ExpDiphtheria', e.target.value)} />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <label className="">Measles/Mumps/Rubella</label>
                            </td>
                            <td>
                              <label className="">
                                <input
                                  type="radio"
                                  name="Rubella"
                                  value="RubellaYes"
                                  checked={props?.inputValues?.Rubella === "RubellaYes"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('Rubella', e.target.value) }}

                                />
                              </label>
                            </td>
                            <td>
                              <label className="">
                                <input
                                  type="radio"
                                  name="Rubella"
                                  value="RubellaNo"
                                  checked={props?.inputValues?.Rubella === "RubellaNo"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('Rubella', e.target.value) }}

                                />
                              </label>
                            </td>
                            <td className="input-bottom-border">
                              <input   type="date" style={{width:100}}  value={props.inputValues.ExpRubella} onChange={(e) => props.handleInputChange('ExpRubella', e.target.value)} />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <label className="">Polio</label>
                            </td>
                            <td>
                              <label className="">
                                <input
                                  type="radio"
                                  name="Polio2"
                                  value="Polio2Yes"
                                  checked={props?.inputValues?.Polio2 === "Polio2Yes"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('Polio2', e.target.value) }}

                                />
                              </label>
                            </td>
                            <td>
                              <label className="">
                                <input
                                  type="radio"
                                  name="Polio2"
                                  value="Polio2No"
                                  checked={props?.inputValues?.Polio2 === "Polio2No"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('Polio2', e.target.value) }}

                                />
                              </label>
                            </td>
                            <td className="input-bottom-border">
                              <input   type="date" style={{width:100}} value={props.inputValues.Polio2Date} onChange={(e) => props.handleInputChange('Polio2Date', e.target.value)} />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <label className="">Hepatitis B</label>
                            </td>
                            <td>
                              <label className="">
                                <input
                                  type="radio"
                                  name="HepatitisB"
                                  value="HepatitisBYes"
                                  checked={props?.inputValues?.HepatitisB === "HepatitisBYes"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('HepatitisB', e.target.value) }}

                                />
                              </label>
                            </td>
                            <td>
                              <label className="">
                                <input
                                  type="radio"
                                  name="HepatitisB"
                                  value="HepatitisBNo"
                                  checked={props?.inputValues?.HepatitisB === "HepatitisBNo"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('HepatitisB', e.target.value) }}

                                />
                              </label>
                            </td>
                            <td className="input-bottom-border">
                              <input   type="date" style={{width:100}} value={props.inputValues.HepatitisBDate} onChange={(e) => props.handleInputChange('HepatitisBDate', e.target.value)} />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <label className="">Varicella (Chickenpox)</label>
                            </td>
                            <td>
                              <label className="">
                                <input
                                  type="radio"
                                  name="Varicella"
                                  value="VaricellaYes"
                                  checked={props?.inputValues?.Varicella === "VaricellaYes"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('Varicella', e.target.value) }}
                                />
                              </label>
                            </td>
                            <td>
                              <label className="">
                                <input
                                  type="radio"
                                  name="Varicella"
                                  value="VaricellaNo"
                                  checked={props?.inputValues?.Varicella === "VaricellaNo"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('Varicella', e.target.value) }}

                                />
                              </label>
                            </td>
                            <td className="input-bottom-border">
                              <input   type="date" style={{width:100}} value={props.inputValues.VaricellaDate} onChange={(e) => props.handleInputChange('VaricellaDate', e.target.value)} />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </li>
                  <li>
                    <p className="mb-0">
                      Have you had Chickenpox? &nbsp;
                      <input
                        type="radio"
                        name="ChickenpoxHave"
                        value="ChickenpoxHaveYes"
                        checked={props?.inputValues?.ChickenpoxHave === "ChickenpoxHaveYes"} // Use 'checked' attribute
                        onChange={(e) => { props?.handleInputChange('ChickenpoxHave', e.target.value) }}

                      />   <label htmlFor="nosebleedYes">Yes</label> &nbsp; or &nbsp;  <label htmlFor="nosebleedNo">No</label>  <input
                        type="radio"
                        name="ChickenpoxHave"
                        value="ChickenpoxHaveNo"
                        checked={props?.inputValues?.ChickenpoxHave === "ChickenpoxHaveNo"} // Use 'checked' attribute
                        onChange={(e) => { props?.handleInputChange('ChickenpoxHave', e.target.value) }}
                      />
                    </p>
                  </li>
                  <li>
                    <p className="mb-0">Have you had a PPD or Chest X-ray?</p>
                    <div className="">
                      <table className="set-line-height w-100">
                        <thead>
                          <tr>
                            <th width="40%"></th>
                            <th width="10%">
                              <b>Yes</b>
                            </th>
                            <th width="10%">
                              <b>No</b>
                            </th>
                            <th width="10%">
                              <b>Date</b>
                            </th>
                            <th width="30%">
                              <b>Results</b>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <label className="">PPD</label>
                            </td>
                            <td>
                              <label className="">
                                <input
                                  type="radio"
                                  name="PPD"
                                  value="PPDYes"
                                  checked={props?.inputValues?.PPD === "PPDYes"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('PPD', e.target.value) }}
                                />
                              </label>
                            </td>
                            <td>
                              <label className="">
                                <input
                                  type="radio"
                                  name="PPD"
                                  value="PPDNo"
                                  checked={props?.inputValues?.PPD === "PPDNo"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('PPD', e.target.value) }}

                                />
                              </label>
                            </td>
                            <td className="input-bottom-border">
                              <input   type="date" style={{width:100}} value={props.inputValues.PPDDate} onChange={(e) => props.handleInputChange('PPDDate', e.target.value)} />
                            </td>
                            <td className="input-bottom-border">
                              <input className="w-100" type="text" value={props.inputValues.PPDDateResult} onChange={(e) => props.handleInputChange('PPDDateResult', e.target.value)} />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <label className="">Chest X-ray</label>
                            </td>
                            <td>
                              <label className="">
                                <input
                                  type="radio"
                                  name="ChestX"
                                  value="ChestXYes"
                                  checked={props?.inputValues?.ChestX === "ChestXYes"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('ChestX', e.target.value) }}

                                />
                              </label>
                            </td>
                            <td>
                              <label className="">
                                <input
                                  type="radio"
                                  name="ChestX"
                                  value="ChestXNo"
                                  checked={props?.inputValues?.ChestX === "ChestXNo"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('ChestX', e.target.value) }}

                                />
                              </label>
                            </td>
                            <td className="input-bottom-border">
                              <input  type="date" style={{width:100}} value={props.inputValues.ChestXDate} onChange={(e) => props.handleInputChange('ChestXDate', e.target.value)} />
                            </td>
                            <td className="input-bottom-border">
                              <input className="w-100" type="text" value={props.inputValues.ChestXResult} onChange={(e) => props.handleInputChange('ChestXResult', e.target.value)} />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <label className="">Polio</label>
                            </td>
                            <td>
                              <label className="">
                                <input
                                  type="radio"
                                  name="Polio3"
                                  value="Polio3Yes"
                                  checked={props?.inputValues?.Polio3 === "Polio3Yes"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('Polio3', e.target.value) }}

                                />
                              </label>
                            </td>
                            <td>
                              <label className="">
                                <input
                                  type="radio"
                                  name="Polio3"
                                  value="Polio3No"
                                  checked={props?.inputValues?.Polio3 === "Polio3No"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('Polio3', e.target.value) }}

                                />
                              </label>
                            </td>
                            <td className="input-bottom-border">
                              <input  type="date" style={{width:100}} value={props.inputValues.Polio3Date} onChange={(e) => props.handleInputChange('Polio3Date', e.target.value)} />
                            </td>
                            <td className="input-bottom-border">
                              <input className="w-100" type="text" value={props.inputValues.Polio3Result} onChange={(e) => props.handleInputChange('Polio3Result', e.target.value)} />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <label className="">Hepatitis B</label>
                            </td>
                            <td>
                              <label className="">
                                <input
                                  type="radio"
                                  name="Hepatitis3"
                                  value="Hepatitis3Yes"
                                  checked={props?.inputValues?.Hepatitis3 === "Hepatitis3Yes"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('Hepatitis3', e.target.value) }}

                                />
                              </label>
                            </td>
                            <td>
                              <label className="">
                                <input
                                  type="radio"
                                  name="Hepatitis3"
                                  value="Hepatitis3No"
                                  checked={props?.inputValues?.Hepatitis3 === "Hepatitis3No"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('Hepatitis3', e.target.value) }}

                                />
                              </label>
                            </td>
                            <td className="input-bottom-border">
                              <input   type="date" style={{width:100}} value={props.inputValues.Hepatitis3Date} onChange={(e) => props.handleInputChange('Hepatitis3Date', e.target.value)} />
                            </td>
                            <td className="input-bottom-border">
                              <input className="w-100" type="text" value={props.inputValues.Hepatitis3Result} onChange={(e) => props.handleInputChange('Hepatitis3Result', e.target.value)} />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <label className="">Varicella (Chickenpox)</label>
                            </td>
                            <td>
                              <label className="">
                                <input
                                  type="radio"
                                  name="Varicella3"
                                  value="Varicella3Yes"
                                  checked={props?.inputValues?.Varicella3 === "Varicella3Yes"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('Varicella3', e.target.value) }}

                                />
                              </label>
                            </td>
                            <td>
                              <label className="">
                                <input
                                  type="radio"
                                  name="Varicella3"
                                  value="Varicella3No"
                                  checked={props?.inputValues?.Varicella3 === "Varicella3No"} // Use 'checked' attribute
                                  onChange={(e) => { props?.handleInputChange('Varicella3', e.target.value) }}

                                />
                              </label>
                            </td>
                            <td className="input-bottom-border">
                              <input   type="date" style={{width:100}}  value={props.inputValues.Varicella3Date} onChange={(e) => props.handleInputChange('Varicella3Date', e.target.value)} />
                            </td>
                            <td className="input-bottom-border">
                              <input className="w-100" type="text" value={props.inputValues.Varicella3Result} onChange={(e) => props.handleInputChange('Varicella3Result', e.target.value)} />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </li>
                  <li>
                    <p>
                      Do you have any allergies? &nbsp; Yes or No &nbsp; If so,
                      what are they and the specific reactions:
                      <textarea row={2} value={props.inputValues.haveAllergies} onChange={(e) => props.handleInputChange('haveAllergies', e.target.value)} />
                    </p>
                  </li>
                  <li>
                    <p>
                      List all treating physicians for the past five years:
                      <textarea row={2} value={props.inputValues.physicians} onChange={(e) => props.handleInputChange('physicians', e.target.value)} />
                    </p>
                  </li>
                </ul>
              </div>
              <hr className="border-sec"></hr>
              <p>
                I certify that all the information I have provided on this
                Post-Offer Health Questionnaire is true and correct. I
                understand that any false or misleading information or omission
                may disqualify me from employment and may result in revocation
                of any offer of employment and/or discharge from employment.
              </p>
              <div className=" p-2 input-bottom-border">
                <div className="d-md-flex gap-3 mb-2">
                  <div className="w-100">
                    <div className="d-flex align-items-center">
                      <label className="w-30">Employee Signature: </label>
                      <div>{props?.signature ? <img src={props?.signature} width={180} height={50} alt="Signature here" /> : <div class="badge badge-primary text-wrap pointer" onClick={props?.onOpenModal}>
                        Click Here To Sign
                      </div>}</div>
                    </div>
                  </div>
                  <div className="w-100">
                    <div className="d-flex align-items-center">
                      <label className="w-30">Date: </label>

                      <input className='w-100' type="text" value={today} disabled />

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
