/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react"
import HeaderPage from "../layout/HeaderPage";
import DrawerMenuPage from "../layout/DrawerMenuPage";
import '../sub-admin/SubAdminList.css'
import Spinner from "@components/Spinner";
import AdminStore from "@store/AdminStore";
import { Formik, FieldArray } from "formik";
import { InputField } from "@elements/index"
import Select, { components } from 'react-select';
import HomeStore from "@store/HomeStore";
import SelectField from "@elements/select/SelectField";
import StaffsStore from "@store/StaffsStore";
import { colourStylesState, colourStyles } from '@utils/customStyle/style';
import { Control, MultiValue } from "../commonFunctions";
import { CButton, CFormCheck, CTooltip } from "@coreui/react-pro";
import { toast } from "react-toastify";
import MultiStepProgressBarInner from "../../../elements/MultiStepProgressBarInner/MultiStepProgressBarInner";
import { CaregiverOnboardSchema } from '@utils/schemas/allSchema';
import { useNavigate } from "react-router-dom";

var data = require('@assets/CountryCodes.json');
var cities = require('@assets/city.json');
var states = require('@assets/states.json');

const AddCaregiver = observer(() => {
    const inputRef = useRef(null);
    const today = new Date();
    const minDate = today.toISOString().substr(0, 10);

    const initDate = new Date();
    const [currentStep, setCurrentStep] = useState(0);
    const [infoSource, setInfoSource] = useState([]);
    const [regions, setRegions] = useState([]);
    const [degree, setDegree] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [specialties, setSpecialties] = useState([]);
    const [devices, setDevices] = useState([]);
    const [requiredDoc, setRequiredDoc] = useState([]);
    const [reqJobDocuments, setReqJobDocuments] = useState([]);
    const [currentStepInner, setCurrentStepInner] = useState(0);
    const [passwordType, setPasswordType] = useState("password");
    const [icon, setIcon] = useState("fa fa-fw fa-eye-slash field-icon toggle-password")
    const navigate = useNavigate();

    const _changeIcon = () => {
        icon === "fa fa-fw fa-eye-slash field-icon toggle-password"
            ? (setIcon("fa fa-fw fa-eye field-icon toggle-password"), setPasswordType("text"))
            : (setIcon("fa fa-fw fa-eye-slash field-icon toggle-password"), setPasswordType("password"))
    }

    useEffect(() => {
        StaffsStore.getRegionsList();
        HomeStore.getInformationSource();
        HomeStore.getContactDevices();
        StaffsStore.getRequiredDoc();
        StaffsStore.getJobProfile(1);
    }, []);
    useEffect(() => {
        setReqJobDocuments(StaffsStore?.data?.requiredDocList.filter(item => item.Name.includes('*') && !item.Name.includes('**')));
        setRequiredDoc(StaffsStore?.data?.requiredDocList)
    }, [StaffsStore?.data?.requiredDocList]);
    useEffect(() => {
        setInfoSource(HomeStore?.data?.informationSource)
    }, [HomeStore?.data?.informationSource]);

    useEffect(() => {
        setDegree(HomeStore.data.degreeList)
    }, [HomeStore.data.degreeList]);
    useEffect(() => {
        setRegions(StaffsStore?.data?.regionsList)
    }, [StaffsStore?.data?.regionsList]);

    useEffect(() => {
        setDevices(HomeStore?.data?.contactDevices)
    }, [HomeStore?.data?.contactDevices]);

    const _Next = (tab) => {
        var form = document.getElementById("create-course-form");
        if (!form.checkValidity()) {
            toast.warn("Please Fill All Required Fields!")
            return
        } else {
            const errors = Object.keys(inputRef?.current?.errors ?? {}).length === 0;
            if (!errors) {
                toast.warn("Please Fill All Required Fields!!")
                return
            }
        }
        window.scrollTo(0, 250)
        setCurrentStep(tab);
    }
    const onGetSpecialties = (e, index) => {
        setLoading(true)
        StaffsStore.getSpecialtiesList(e).then((data) => {
            let sItems = [...specialties];
            let sItem = { ...sItems[index] };
            sItem = data;
            sItems[index] = sItem;
            setSpecialties(sItems)
            setLoading(false)
        })
    }
    const setStepInner = (step) => {
        window.scrollTo(0, 250)
        setCurrentStepInner(step);
    }
    const createJobProfile = (values) => {
        let param = {
            "email": values.email,
            "contact": values.contact,
            "password": values.password,
            "profession": values?.degree_specialities[0]?.profession,
            "country_code": values.country_code,
            "first_name": values.first_name,
            "last_name": values.last_name,
            "middle_name": values.middle_name,
            "gender": values.gender,
            "ssn": values.ssn,
            "birth_date": values.birth_date,
            "address": values.address,
            "state_id": values.state_id,
            "city": values.city,
            "country_id": values.country_id,
            "zipcode": values.zipcode,
            "info_sources": values.info_sources,
            "region_id": values.region_id,
            "interested_in": values.interested_in,
            "availability": values.availability,
            "available_day": values.available_day,
            "available_date": values.available_date,
            "hispanic": values.hispanic,
            "race": values.race,
            "race_in_addition": values.race_in_addition,
            "equal_opportunity": values.equal_opportunity,
            "veteran": values.veteran,
            "disability": values.disability,
            "tnc": values.tnc,
            "employment_tnc": values.employment_tnc,
            "contact_devices": !values.contact_devices.every(obj => Object.values(obj).every(val => val === "")) ? values.contact_devices : [],
            "degree_specialities": values.degree_specialities.some(obj => !obj.profession) ? [] : values.degree_specialities,
            "doc_list": !values.req_doc_list.every(obj => Object.values(obj).every(val => val === "" || val == undefined)) ? values?.req_doc_list?.map(doc => {
                return { "doc_id": doc.ID, "file": doc.file }
            }) : [],
            "preferred_state": !values.preferred_state.every(obj => Object.values(obj).every(val => val === "")) ? values.preferred_state : [],
            "preferred_cities": !values.preferred_cities.every(obj => Object.values(obj).every(val => val === "")) ? values.preferred_cities : [],
            "work_distance": values.work_distance,
            "delete_doc_id": [],
            "status": '0'
        }
        let mergedArr = [...param.doc_list, ...values?.doc_list];
        param.doc_list = mergedArr.filter(x => x.file !== undefined);
        AdminStore.createjobProfile(param, navigationCallBack);
    }

    const navigationCallBack = () => {
        navigate('/admin/job-profile-request');
    }
    return (
        <React.Fragment>
            {/* <body> */}
            <div className="container-scroller">
                <HeaderPage />
                <div className="container-fluid page-body-wrapper">
                    <DrawerMenuPage />
                    <div className="main-panel" style={{ right: 0, position: "absolute" }}>
                        <div className="content-wrapper p-3 bg-white ">
                            <h3 className="page-title">
                                <span className="page-title-icon linear text-white me-2">
                                    <i className="far fa-edit"></i>
                                </span>Add Caregiver
                            </h3>
                        </div>

                        <Formik
                            initialValues={{
                                delete_doc_id: [],
                                first_name: "",
                                last_name: "",
                                password: "",
                                profession: "",
                                email: "",
                                contact: "",
                                country_code: '+1',
                                middle_name: "",
                                region_id: "1",
                                gender: "",
                                ssn: "",
                                address: "",
                                city: "",
                                work_distance: 40,
                                preferred_state: [],
                                preferred_cities: [],
                                degree_specialities: [{ "profession": '', "specialties": '', 'exp': '', 'working': 'No' }],
                                state_id: "",
                                country_id: "+1",
                                zipcode: "",
                                info_sources: "",
                                contact_devices: [{ "type": '', "number": '' }],
                                doc_list: [],
                                req_doc_list: reqJobDocuments || [],
                                employment_tnc: 0,
                                tnc: 0,
                                birth_date: '',
                                available_day: [],
                                available_date: '',
                                hispanic: null,
                                race: '',
                                race_in_addition: '',
                                equal_opportunity: 'false',
                                veteran: null,
                                disability: '',
                                interested_in: '',
                                availability: '',
                                passwordConfirmation: ''
                            }}
                            onSubmit={values => createJobProfile(values)}
                            validationSchema={CaregiverOnboardSchema}
                            enableReinitialize
                            innerRef={inputRef}

                        >
                            {({ handleChange, handleBlur, handleSubmit, setFieldValue, touched, values, errors, resetForm }) => (

                                <form onSubmit={handleSubmit} id="create-course-form">
                                    <ul id="progressbar-step" className=" p-0">
                                        <li id="account" className={currentStep >= 0 ? 'active' : null}><strong></strong></li>
                                        <li id="personal" className={currentStep >= 1 ? 'active' : null}><strong></strong></li>
                                        <li id="availability" className={currentStep >= 2 ? 'active' : null}><strong></strong></li>
                                        <li id="documents" className={currentStep >= 3 ? 'active' : null}><strong></strong></li>
                                        <li id="acknowkedge" className={currentStep >= 4 ? 'active' : null}><strong></strong></li>
                                        <li id="finish" className={currentStep >= 5 ? 'active' : null}><strong></strong></li>
                                    </ul>
                                    {/* Account  */}
                                    {currentStep === 0 &&
                                        <div className="border-check">
                                            <div className="row g-4 p-lg-5 p-3">
                                                <div className="col-sm-6">
                                                    <InputField
                                                        className={"did-floating-input"}
                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                        type="email"
                                                        name="email"
                                                        label={"Email Address"}
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        required={true}
                                                    />
                                                    {errors.email && touched.email ? (
                                                        <div className="error-message">{errors.email}</div>
                                                    ) : null}
                                                </div>

                                                <div className="col-sm-6">
                                                    <InputField
                                                        className={"did-floating-input"}
                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                        type="text"
                                                        name="contact"
                                                        label={"Contact Number"}
                                                        value={values?.contact?.replace(/[^\d]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3') || ""}
                                                        maxLength={12}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        required={true}
                                                    />
                                                    {errors.contact && touched.contact ? (
                                                        <div className="error-message">{errors.contact}</div>
                                                    ) : null}
                                                </div>
                                                <div className="col-sm-6">
                                                    <InputField
                                                        className={"did-floating-input"}
                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                        type={passwordType}
                                                        name="password"
                                                        label={"Password"}
                                                        value={values.password}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        required={true}
                                                    />
                                                    <span toggle="#password-field" className={icon} onClick={() => _changeIcon()}></span>
                                                    {errors.password && touched.password ? (
                                                        <div className="error-message">{errors.password}</div>
                                                    ) : null}
                                                </div>
                                                <div className="col-sm-6">
                                                    <InputField
                                                        className={"did-floating-input"}
                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                        type="password"
                                                        name="passwordConfirmation"
                                                        label={"Confirm Password"}
                                                        value={values.passwordConfirmation}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        required={true}
                                                    />
                                                    {errors.passwordConfirmation && touched.passwordConfirmation ? (
                                                        <div className="error-message">{errors.passwordConfirmation}</div>
                                                    ) : null}
                                                </div>
                                                <div className="col-sm-6">
                                                    <InputField
                                                        className={"did-floating-input"}
                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                        type="text"
                                                        name="first_name"
                                                        label={"First Name"}
                                                        value={values.first_name}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        required={true}
                                                    />
                                                    {errors.first_name ? (
                                                        <div className="error-message">{errors.first_name}</div>
                                                    ) : null}
                                                </div>
                                                <div className="col-sm-6">
                                                    <InputField
                                                        className={"did-floating-input"}
                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                        type="text"
                                                        name="last_name"
                                                        label={"Last Name"}
                                                        value={values.last_name}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        required={true}
                                                    />
                                                    {errors.last_name ? (
                                                        <div className="error-message">{errors.last_name}</div>
                                                    ) : null}
                                                </div>
                                                <div className="col-sm-6">
                                                    <InputField
                                                        className={"did-floating-input"}
                                                        type="text"
                                                        name="middle_name"
                                                        label={"Middle name"}
                                                        value={values.middle_name}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur} />
                                                </div>
                                                <div className="col-sm-6">
                                                    <SelectField
                                                        name="region_id"
                                                        label={"Add Preferred Region"}
                                                        value={values.region_id}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        data={regions}
                                                        optionLabel={"Name"}
                                                        optionValue={"ID"}
                                                        disabled={true}
                                                    />
                                                    {errors.region_id ? (
                                                        <div className="error-message">{errors.region_id}</div>
                                                    ) : null}
                                                </div>
                                                <div className="col-sm-6">
                                                    <SelectField
                                                        name="info_sources"
                                                        label={"Referral Source"}
                                                        value={values.info_sources}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        data={infoSource || []}
                                                        optionLabel={"SystemValue"}
                                                        optionValue={"SystemValueID"}
                                                    />
                                                </div>
                                                <div className="col-sm-6">
                                                    <InputField
                                                        className={"did-floating-input"}
                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                        type="text"
                                                        name="address"
                                                        label={"Address"}
                                                        value={values.address}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        required={true}
                                                    />
                                                    {errors.address && touched.address ? (
                                                        <div className="error-message">{errors.address}</div>
                                                    ) : null}
                                                </div>
                                                <div className="col-sm-6">
                                                    <Select
                                                        onChange={(e) => { setFieldValue("state_id", e.abbreviation), setFieldValue("city", '') }}
                                                        placeholder={''}
                                                        label={'State'}
                                                        isRequired={true}
                                                        styles={colourStylesState}
                                                        value={states.filter(({ abbreviation }) => values?.state_id?.includes(abbreviation)) || ''}
                                                        options={states}
                                                        getOptionLabel={(option) => option.name}
                                                        getOptionValue={(option) => option.abbreviation}
                                                        components={{ Control, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                    />
                                                    {errors.state_id && touched.state_id ? (
                                                        <div className="error-message">{errors.state_id}</div>
                                                    ) : null}
                                                </div>
                                                <div className="col-sm-6">
                                                    <Select
                                                        onChange={(e) => { setFieldValue("city", e.label) }}
                                                        placeholder={''}
                                                        label={'City'}
                                                        isRequired={true}
                                                        styles={colourStylesState}
                                                        getOptionLabel={(option) => option.label}
                                                        getOptionValue={(option) => option.label}
                                                        value={cities.filter(({ label }) => values?.city === label) || ''}
                                                        options={cities?.filter(ct => ct.state === states?.find(x => x.abbreviation == values?.state_id)?.name)}
                                                        components={{ Control, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                    />
                                                    {errors.city && touched.city ? (
                                                        <div className="error-message">{errors.city}</div>
                                                    ) : null}
                                                </div>
                                                <div className="col-sm-6">
                                                    <InputField
                                                        name={"zipcode"}
                                                        className={"did-floating-input"}
                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                        type="number"
                                                        label={"Zip Code"}
                                                        value={values.zipcode}
                                                        required={true}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}

                                                    />
                                                    {errors.zipcode ? (
                                                        <div className="error-message">{errors.zipcode}</div>
                                                    ) : null}
                                                </div>
                                                <div className="col-sm-6">
                                                    <Select
                                                        onChange={(event) => { setFieldValue("country_id", event.label), setFieldValue("country_code", event.label); }}
                                                        placeholder=""
                                                        label="Country"
                                                        isRequired={true}
                                                        value={data.filter(({ label }) => values?.country_id?.includes(label)) || ''}
                                                        styles={colourStyles}
                                                        isSearchable={true}
                                                        options={data}
                                                        components={{ Control }}
                                                    />
                                                </div>
                                                <div className="col-sm-6">
                                                    <Select

                                                        closeMenuOnSelect={false}
                                                        getOptionLabel={(option) => option.name}
                                                        getOptionValue={(option) => option.abbreviation}
                                                        isMulti
                                                        hideSelectedOptions={false}
                                                        options={states}
                                                        styles={colourStyles}
                                                        placeholder=""
                                                        isRequired={true}
                                                        value={states.filter(({ abbreviation }) => values?.preferred_state?.includes(abbreviation)) || ''}
                                                        label="Add Preferred States"
                                                        onChange={(event) => {
                                                            setFieldValue("preferred_state", event?.map(({ abbreviation }) => abbreviation));
                                                            setFieldValue("preferred_cities", values?.preferred_cities?.filter(ct => event?.map(({ abbreviation }) => abbreviation)?.find(st => st === ct.state_id)))
                                                        }}
                                                        components={{ Control, MultiValue, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                    />
                                                    {errors.preferred_state ? (
                                                        <div className="error-message">{errors.preferred_state}</div>
                                                    ) : null}
                                                </div>

                                                <div className="col-sm-6">
                                                    <Select
                                                        closeMenuOnSelect={false}
                                                        getOptionLabel={(option) => `${option.label}, ${option.state_id}`}
                                                        getOptionValue={(option) => option.label}
                                                        isMulti
                                                        hideSelectedOptions={false}
                                                        options={values?.preferred_state?.length > 0 ? cities.filter(city => values?.preferred_state?.find(x => x === city.state_id)) : []}
                                                        styles={colourStyles}
                                                        placeholder=""
                                                        isRequired={true}
                                                        value={values.preferred_cities}
                                                        label="Add Preferred Cities"
                                                        onChange={(event) => { setFieldValue("preferred_cities", event) }} components={{ Control, MultiValue, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                    />
                                                    {errors.preferred_cities ? (
                                                        <div className="error-message">{errors.preferred_cities}</div>
                                                    ) : null}
                                                </div>
                                                <div className="col-sm-6">
                                                    <Select
                                                        onChange={(event) => { setFieldValue("work_distance", event.value); }}
                                                        placeholder=""
                                                        label="Work Area Distance"
                                                        isRequired={true}
                                                        value={global.config.radiusMiles?.filter(({ value }) => values?.work_distance === value) || ''}
                                                        styles={colourStyles}
                                                        isSearchable={true}
                                                        options={global.config.radiusMiles}
                                                        components={{ Control }}
                                                    />
                                                </div>
                                                {<div className="col-sm-12 d-grid gap-2 d-md-flex justify-content-center">
                                                    <CButton size="lg" color="success" shape="rounded-pill" onClick={() => _Next(1)} variant="outline">Next Section</CButton>
                                                </div>}
                                            </div>
                                        </div>}
                                    {currentStep === 1 &&
                                        <div className="border-check">
                                            <div className="row g-4 p-lg-5 p-3">

                                                <div className="d-flex justify-content-center pb-2"><span className="small fs-6">Please provide the necessary professional information as per our requirements.</span></div>
                                                <div><h5 className="text-primary"><i className="fas fa-briefcase"></i> Professional Experience</h5></div>
                                                <FieldArray
                                                    name="degree_specialities"
                                                    render={({ handleRemove, push }) => (
                                                        <>
                                                            {
                                                                values.degree_specialities && values.degree_specialities.length > 0 ?
                                                                    values.degree_specialities.map((deg, index) => (
                                                                        <div key={index + 'list'} className="card">
                                                                            <div className="row p-lg-4">
                                                                                {<div className="col-sm-12 d-flex justify-content-end pb-0 ms-3" style={{ marginTop: -10 }}>
                                                                                    {values?.degree_specialities?.length !== 1 && <button
                                                                                        type="button"
                                                                                        className="btn btn-sm text-danger pointer w-10 me-1 mt-lg-0 mt-3"
                                                                                        onClick={() => {
                                                                                            values.degree_specialities.splice(index, 1)
                                                                                            specialties.splice(index, 1)
                                                                                            setFieldValue('degree_specialities', values.degree_specialities)
                                                                                        }}
                                                                                    >
                                                                                        <i className="fas fa-times"></i>
                                                                                    </button>}
                                                                                </div>}
                                                                                <div className="col-sm-6 pb-lg-0 pb-md-0 pb-3">
                                                                                    <div className="did-floating-label-content">
                                                                                        <select
                                                                                            className="did-floating-dropdown"
                                                                                            value={deg?.profession}
                                                                                            required={true}
                                                                                            name={`degree_specialities[${index}].profession`}
                                                                                            onBlur={handleBlur}
                                                                                            onChange={(e) => {
                                                                                                handleChange(e);
                                                                                                const selectedIndex = e.target.selectedIndex;
                                                                                                const selectedOptionLabel = e.target.options[selectedIndex].label;
                                                                                                setFieldValue(`degree_specialities[${index}].profession`, e.target.value)
                                                                                                setFieldValue(`degree_specialities[${index}].profession_name`, selectedOptionLabel)
                                                                                                setFieldValue(`degree_specialities[${index}].specialties_name`, null);
                                                                                                setFieldValue(`degree_specialities[${index}].specialties`, null);
                                                                                                if (selectedIndex !== 0) {
                                                                                                    onGetSpecialties(e.target.value, index)
                                                                                                } else {
                                                                                                    specialties[index] = []
                                                                                                }
                                                                                            }}
                                                                                        >
                                                                                            <option
                                                                                                value=""
                                                                                            >
                                                                                            </option>
                                                                                            {degree && degree?.map((option, index) => (
                                                                                                <option
                                                                                                    key={index}
                                                                                                    value={option.Id}
                                                                                                >
                                                                                                    {option.Name}
                                                                                                </option>
                                                                                            ))}
                                                                                        </select>
                                                                                        <label className={deg?.profession == "" ? "did-floating-label did-floating-label-down did-floating-labe-astrisk" : "did-floating-label did-floating-label-top did-floating-labe-astrisk"}>Profession</label>
                                                                                    </div>
                                                                                    {errors?.degree_specialities && errors?.degree_specialities?.length != 0 && errors?.degree_specialities[index] ? (
                                                                                        <div className="error-message">{errors?.degree_specialities[index]?.profession}</div>
                                                                                    ) : null}
                                                                                </div>
                                                                                {<div className="col-sm-6 pb-lg-0 pb-md-0 pb-3">
                                                                                    <SelectField
                                                                                        label={isLoading ? "Loading..." : "Speciality"}
                                                                                        required={(specialties[index] && specialties[index]?.length > 0)}
                                                                                        name={`degree_specialities[${index}].specialties`}
                                                                                        value={deg.specialties}
                                                                                        onChange={(event) => {
                                                                                            handleChange(event);
                                                                                            const selectedIndex = event.target.selectedIndex;
                                                                                            const selectedOptionLabel = event.target.options[selectedIndex].label;
                                                                                            setFieldValue(`degree_specialities[${index}].specialties_name`, selectedOptionLabel);
                                                                                        }}
                                                                                        onBlur={handleBlur}
                                                                                        data={(deg.specialties ? specialties[index]?.filter(x => x?.Id == deg?.specialties) :
                                                                                            specialties[index]?.filter(x => {
                                                                                                if (values?.degree_specialities?.find(rm => rm?.specialties == x?.Id)) {
                                                                                                    return
                                                                                                } else {
                                                                                                    return x;
                                                                                                }
                                                                                            }
                                                                                            )) || []}
                                                                                        optionLabel={"Name"}
                                                                                        optionValue={"Id"}
                                                                                    />
                                                                                </div>}
                                                                                {<div className="col-sm-6 pb-lg-0 pb-md-0 pb-3 mt-3">
                                                                                    <InputField
                                                                                        className={"did-floating-input"}
                                                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                                        type="number"
                                                                                        min="0"
                                                                                        name={`degree_specialities[${index}].exp`}
                                                                                        label={"Years of Experience"}
                                                                                        value={deg.exp}
                                                                                        onChange={(event) => { handleChange(event) }}
                                                                                        onBlur={handleBlur}
                                                                                        required={true}
                                                                                    />
                                                                                    {errors?.degree_specialities && errors?.degree_specialities?.length != 0 && errors?.degree_specialities[index] ? (
                                                                                        <div className="error-message">{errors?.degree_specialities[index]?.exp}</div>
                                                                                    ) : null}
                                                                                </div>}
                                                                                {<div className="col-sm-6 pb-lg-0 pb-md-0 pb-3 mt-3">
                                                                                    <SelectField
                                                                                        label={"Currently working in this role?"}
                                                                                        name={`degree_specialities[${index}].working`}
                                                                                        value={deg.working}
                                                                                        onChange={(event) => {
                                                                                            handleChange(event);
                                                                                        }}
                                                                                        onBlur={handleBlur}
                                                                                        data={[{ "label": "Yes", "value": "Yes" }, { "label": "No", "value": "No" }]}
                                                                                        optionLabel={"label"}
                                                                                        optionValue={"value"}
                                                                                    />
                                                                                </div>}
                                                                            </div>
                                                                        </div>
                                                                    )) : null
                                                            }
                                                            {<div className="col-sm-12 d-flex justify-content-sm-end">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-sm btn-light w-10"
                                                                    onClick={() => { push({ profession: '', specialties: '', exp: '', working: 'No' }) }} // insert an empty string at a position
                                                                >
                                                                    Add Profession
                                                                </button>
                                                            </div>}
                                                        </>)}
                                                />
                                                <div><h5 className="text-primary"><i className="fas fa-address-book"></i> Contact Info</h5></div>
                                                <FieldArray
                                                    name="contact_devices"
                                                    render={({ handleRemove, push }) => (
                                                        <>
                                                            {
                                                                values.contact_devices && values.contact_devices.length > 0 ?
                                                                    values.contact_devices.map((dev, index) => (
                                                                        <div key={index + 'list'} className="card">
                                                                            <div className="row p-lg-4">
                                                                                {<div className="col-sm-12 d-flex justify-content-end pb-2 ms-3" style={{ marginTop: -10 }}>
                                                                                    {values?.contact_devices?.length !== 1 && <button
                                                                                        type="button"
                                                                                        className="btn btn-sm w-10 me-1 mt-lg-0 mt-3 text-danger pointer"
                                                                                        onClick={() => {
                                                                                            values.contact_devices.splice(index, 1)
                                                                                            specialties.splice(index, 1)
                                                                                            setFieldValue('contact_devices', values.contact_devices)
                                                                                        }}
                                                                                    >
                                                                                        <i className="fas fa-times"></i>
                                                                                    </button>}
                                                                                </div>}

                                                                                <div className="col-sm-6 pb-lg-0 pb-md-0 pb-3">
                                                                                    <div className="did-floating-label-content">
                                                                                        <select className="did-floating-dropdown"
                                                                                            value={dev.type}
                                                                                            required
                                                                                            name={`contact_devices[${index}].type`}
                                                                                            onChange={(event) => {
                                                                                                handleChange(event);
                                                                                            }}
                                                                                        >
                                                                                            <option
                                                                                                value=""
                                                                                            >
                                                                                            </option>
                                                                                            {devices && devices?.map((option, index) => (
                                                                                                <option
                                                                                                    key={index}
                                                                                                    value={option.SystemValueID}
                                                                                                >
                                                                                                    {option.SystemValue}
                                                                                                </option>)
                                                                                            )}
                                                                                        </select>
                                                                                        <label className={dev.type == "" ? "did-floating-label did-floating-label-down did-floating-labe-astrisk" : "did-floating-label did-floating-label-top did-floating-labe-astrisk"}>Contact Device Type</label>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-sm-6 pb-lg-0 pb-md-0 pb-3">
                                                                                    <InputField
                                                                                        name={`contact_devices[${index}].number`}
                                                                                        className={"did-floating-input"}
                                                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                                        type="number"
                                                                                        label={"Number"}
                                                                                        value={dev.number}
                                                                                        required
                                                                                        onChange={(event) => {
                                                                                            handleChange(event);
                                                                                        }}
                                                                                        onBlur={handleBlur}
                                                                                    />
                                                                                    {errors?.contact_devices && errors?.contact_devices?.length != 0 && errors?.contact_devices[index] ? (
                                                                                        <div className="error-message">{errors?.contact_devices[index]?.number}</div>
                                                                                    ) : null}
                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                    )) : null
                                                            }

                                                            {<div className="col-sm-12 d-flex justify-content-sm-end">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-sm btn-light w-10"
                                                                    onClick={() => { push({ type: '', number: '' }) }} // insert an empty string at a position
                                                                >
                                                                    Add Contact Devices
                                                                </button>
                                                            </div>}
                                                        </>)}
                                                />
                                                <div className="col-sm-12 d-flex justify-content-center">
                                                    <CButton size="lg" className="me-2" color="dark" shape="rounded-pill" onClick={() => setCurrentStep(currentStep - 1)} variant="outline">Back</CButton>
                                                    <CButton size="lg" color="success" shape="rounded-pill" variant="outline" onClick={() => _Next(2)}>  Next Section</CButton>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {/* "Availability"  */}
                                    {currentStep === 2 &&
                                        <div className="border-check">
                                            <div className="row g-4 p-lg-5 p-3">
                                                <div className="d-flex justify-content-center pb-2"><span className="small">Specify the job type and preferred shift time that you're interested in.</span></div>
                                                <div className="col-sm-6">
                                                    <Select
                                                        // isDisabled={jobProfile?.status === 1 || jobProfile?.status >= 3}
                                                        closeMenuOnSelect={false}
                                                        name="interested_in"
                                                        getOptionLabel={(option) => option.label}
                                                        getOptionValue={(option) => option.value}
                                                        isMulti
                                                        hideSelectedOptions={false}
                                                        options={global.config.interestedIn}
                                                        styles={colourStyles}
                                                        placeholder=""
                                                        isRequired={true}
                                                        label="Interested In"
                                                        onChange={(event) => { setFieldValue("interested_in", event?.map(({ value }) => value)) }}
                                                        value={global.config.interestedIn.filter(({ value }) => values.interested_in?.includes(value)) || ''}
                                                        components={{ Control, MultiValue, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                    />
                                                    {errors.interested_in ? (
                                                        <div className="error-message">{errors.interested_in}</div>
                                                    ) : null}
                                                </div>

                                                <div className="col-sm-6">
                                                    <Select
                                                        // isDisabled={jobProfile?.status === 1 || jobProfile?.status >= 3}
                                                        closeMenuOnSelect={false}
                                                        name="availability"
                                                        getOptionLabel={(option) => option.label}
                                                        getOptionValue={(option) => option.value}
                                                        isMulti
                                                        isRequired={true}
                                                        hideSelectedOptions={false}
                                                        options={global.config.shiftDay}
                                                        styles={colourStyles}
                                                        placeholder=""
                                                        label="Your Available Shifts?"
                                                        onChange={(event) => { setFieldValue("availability", event?.map(({ value }) => value)) }}
                                                        value={global.config.shiftDay.filter(({ value }) => values.availability?.includes(value)) || ''}
                                                        components={{ Control, MultiValue, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                    />
                                                    {errors.availability ? (
                                                        <div className="error-message">{errors.availability}</div>
                                                    ) : null}
                                                </div>
                                                <div className="col-sm-6">
                                                    <Select
                                                        closeMenuOnSelect={false}
                                                        getOptionLabel={(option) => option.label}
                                                        getOptionValue={(option) => option.value}
                                                        isMulti
                                                        hideSelectedOptions={false}
                                                        options={global.config.shiftWeeks}
                                                        styles={colourStyles}
                                                        placeholder=""
                                                        label="Your Available Weekdays?"
                                                        onChange={(event) => { setFieldValue("available_day", event?.map(({ value }) => value)); }}
                                                        value={global.config.shiftWeeks.filter(({ value }) => values?.available_day?.includes(value)) || ''}
                                                        components={{ Control, MultiValue, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                    />
                                                    {errors.available_day ? (
                                                        <div className="error-message">{errors.available_day}</div>
                                                    ) : null}
                                                </div>
                                                <div className="col-sm-6">
                                                    <InputField
                                                        name={"available_date"}
                                                        className={"did-floating-input"}
                                                        type="date"
                                                        label={"When You Start Working?"}
                                                        value={values.available_date}
                                                        min={initDate.toISOString().split('T')[0]}
                                                        onChange={(event) => { handleChange(event); }}
                                                        onBlur={handleBlur}
                                                    />
                                                </div>
                                                <div className="col-sm-12 d-grid gap-2 d-md-flex justify-content-center">
                                                    <CButton size="lg" className="me-2" color="dark" shape="rounded-pill" onClick={() => setCurrentStep(currentStep - 1)} variant="outline">Back</CButton>

                                                    <CButton size="lg" color="success" shape="rounded-pill" variant="outline" onClick={() => _Next(3)}>  Next Section</CButton>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {/* "Documents"  */}
                                    {currentStep === 3 &&
                                        <div className="border-check">
                                            <div className="row g-4 p-lg-5 p-3">

                                                <>
                                                    <>
                                                        <div className="d-flex justify-content-center pb-2"><span className="small">Please provide the necessary professional information as per our requirements. This includes your profession,speciality and contact details.that are required for the position you are applying for.</span></div>
                                                        <div className="">
                                                            <h5 className="text-primary text-sm h6"><i className="fas fa-file-upload"></i> Documents & Certificates</h5>
                                                        </div>

                                                    </>
                                                    {< FieldArray
                                                        name="req_doc_list"
                                                        render={() => (
                                                            <>
                                                                {values.req_doc_list && values.req_doc_list.length > 0 ?
                                                                    values.req_doc_list.map((doc, index) => (
                                                                        <div key={index + 'list'}>
                                                                            <div className="row">
                                                                                <div className="col-lg-6 pb-lg-0 pb-md-0 pb-3">
                                                                                    <SelectField
                                                                                        id="select-disabled"
                                                                                        label={"Document Type"}
                                                                                        name={`req_doc_list[${index}].doc_id`}
                                                                                        value={doc.ID}
                                                                                        onChange={(event) => { handleChange(event) }}
                                                                                        onBlur={handleBlur}
                                                                                        data={requiredDoc || []}
                                                                                        optionLabel={"Name"}
                                                                                        optionValue={"ID"}
                                                                                        required={true}
                                                                                        disabled
                                                                                    />
                                                                                </div>
                                                                                <div className="col-sm-6 did-floating-label-content">
                                                                                    <input
                                                                                        id={`req_doc_list[${index}].file`}
                                                                                        name={`req_doc_list[${index}].file`}
                                                                                        title="click here to upload"
                                                                                        style={{ display: "flex" }}
                                                                                        accept="image/*,.pdf"
                                                                                        className={doc?.file ? "did-floating-input" : "did-floating-input file-placeholder"}
                                                                                        placeholder="click here to upload"
                                                                                        type="file"
                                                                                        required={true}
                                                                                        onChange={(e) => {
                                                                                            if (e.currentTarget.files[0] && e.currentTarget.files[0].size >= 5 * 1024 * 1024) {
                                                                                                // File size is too large, display an error message
                                                                                                toast.warn("Your file is too large, maximum allowed size is 5MB!")
                                                                                            } else {
                                                                                                setFieldValue(`req_doc_list[${index}].file`, e.currentTarget.files[0])
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    <label className="did-floating-label did-floating-label-top">Attach File*</label>
                                                                                    {errors?.req_doc_list && errors?.req_doc_list?.length !== 0 && errors?.req_doc_list[index] ? (
                                                                                        <div className="error-message">{errors?.req_doc_list[index]?.file}</div>
                                                                                    ) : null}
                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                    )) : null
                                                                }</>)}
                                                    />}
                                                    <FieldArray
                                                        name="doc_list"
                                                        render={({ handleRemove, push }) => (
                                                            <>
                                                                {
                                                                    values.doc_list && values.doc_list.length > 0 ?
                                                                        values.doc_list.map((doc, index) => (
                                                                            <div key={index + 'list'}>
                                                                                <div className="row">
                                                                                    <div className="col-sm-6 pb-lg-0 pb-md-0 pb-3">
                                                                                        <SelectField
                                                                                            label={"Document Type"}
                                                                                            name={`doc_list[${index}].doc_id`}
                                                                                            value={doc.doc_id}
                                                                                            onChange={(event) => {
                                                                                                if (!values.doc_list.find(x => x.doc_id == event.target.value)) {
                                                                                                    handleChange(event);
                                                                                                } else {
                                                                                                    toast.warn("You have Already Selected This Option")
                                                                                                }
                                                                                            }
                                                                                            }
                                                                                            onBlur={handleBlur}
                                                                                            data={(requiredDoc?.filter(x => x.Name?.includes("**"))) || []}
                                                                                            optionLabel={"Name"}
                                                                                            optionValue={"ID"}
                                                                                            required
                                                                                        />
                                                                                        {errors?.doc_list && errors?.doc_list?.length !== 0 && errors?.doc_list[index] ? (
                                                                                            <div className="error-message">{errors?.doc_list[index]?.doc_id}</div>
                                                                                        ) : null}
                                                                                    </div>
                                                                                    <div className="col-sm-5 did-floating-label-content">
                                                                                        <input
                                                                                            id={`doc_list[${index}].file`}
                                                                                            name={`doc_list[${index}].file`}
                                                                                            title="File"
                                                                                            accept="image/*,.pdf"
                                                                                            className={"did-floating-input"}
                                                                                            type="file"
                                                                                            onChange={(e) => {
                                                                                                if (e.currentTarget.files[0] && e.currentTarget.files[0].size >= 5 * 1024 * 1024) {
                                                                                                    // File size is too large, display an error message
                                                                                                    toast.warn("Your file is too large, maximum allowed size is 5MB!")
                                                                                                } else {
                                                                                                    setFieldValue(`doc_list[${index}].file`, e.currentTarget.files[0])
                                                                                                }

                                                                                            }}
                                                                                        />
                                                                                        <label className="did-floating-label did-floating-label-top">Attach File*</label>
                                                                                        {errors?.doc_list && errors?.doc_list?.length !== 0 && errors?.doc_list[index] ? (
                                                                                            <div className="error-message">{errors?.doc_list[index]?.file}</div>
                                                                                        ) : null}
                                                                                    </div>
                                                                                    <div className="col-sm-1 mt-1">
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-sm btn-light me-1"
                                                                                            onClick={() => {
                                                                                                values?.doc_list?.splice(index, 1);
                                                                                                setFieldValue('doc_list', values.doc_list)
                                                                                            }}
                                                                                        >
                                                                                            x
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        )) : null
                                                                }
                                                                {
                                                                    (<div className="col-sm-12 d-flex justify-content-sm-end">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-sm btn-light w-10"
                                                                            onClick={(event) => { push({ doc_id: '', file: '' }) }} // insert an empty string at a position
                                                                        >
                                                                            Add Documents
                                                                        </button>
                                                                    </div>)}</>)}
                                                    />
                                                </>
                                                {
                                                    <div className="col-sm-12 d-grid gap-2 d-md-flex justify-content-center">
                                                        <CButton size="lg" className="me-2" color="dark" shape="rounded-pill" onClick={() => setCurrentStep(currentStep - 1)} variant="outline">Back</CButton>

                                                        <CButton size="lg" color="success" shape="rounded-pill" variant="outline" onClick={() => _Next(4)}>Next Section</CButton>
                                                    </div>}

                                            </div>
                                        </div>}

                                    {/* "Acknowledgments"  */}
                                    {currentStep === 4 &&
                                        <div className="border-check">
                                            <div className="row g-4 p-lg-5 p-3">

                                                <div className="col-sm-12 mb-3">
                                                    <label>The questions below are legally required for job applicants. Don't worry - we'll keep your information secure.</label>
                                                </div>
                                                <div className="col-12 mt-5">
                                                    <MultiStepProgressBarInner currentStep={currentStepInner} setStep={setStepInner} />
                                                </div>
                                                {
                                                    currentStepInner === 0 &&
                                                    <div className="row g-4 p-2">
                                                        <div className="col-sm-12">
                                                            <h5>AFFIRMATIVE ACTION</h5>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <label><b>What does affirmative action mean?</b></label>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <small>Affirmative action is best understood in terms of the goal: equal employment opportunity for everyone. Equal Employment Opportunity is the condition where all personnel decisions such as hiring, promotions, etc., are made without any consideration of race, color, national origin, religion, sex, gender preference, physical or mental disability, medical condition, ancestry, marital status, age, sexual orientation, citizenship, or status as a covered veteran. Affirmative action, therefore, is a vehicle by which we seek to reach the goal of equal employment opportunity. Affirmative Action may take the form of outreach recruitment efforts, educational opportunities for career advancement, and other training and skill enhancement programs for all employees, including women, minorities, persons with disabilities and covered veterans.</small>
                                                        </div>

                                                        <div className="col-sm-12">
                                                            <label><b>What is an affirmative action program?</b></label>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <small>An affirmative action program is a tool designed to ensure equal employment opportunity. It includes those policies, practices and procedures that Staffers LLC will implement to ensure that all qualified applicants and employees are receiving an equal opportunity for recruitment, selection, advancement, training, development and every other term, condition and privilege of employment.</small>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <label><b>Who benefits from the affirmative action policies and programs?</b></label>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <small>All employees benefit from the affirmative action policies and programs as they help to ensure a fair work environment for everyone.</small>
                                                        </div>

                                                        <div className="col-sm-12">
                                                            <label><b>Do I have to provide the information request on my race, gender, disability, and veteran status?</b></label>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <small>Yes. Staffers LLC is a federal contractor and therefore is required to report information about its workforce, including race and sex data, to the federal government. You therefore must provide this information after you are hired. This information is determined by self-identification. That is, you decide which of the five racial categories best describes you.</small>
                                                        </div>

                                                        <div className="col-sm-12">
                                                            <em>This information is used for statistical reporting to the federal government. It has no relation to your performance and/or your future career with Staffers LLC.</em>
                                                        </div>
                                                        <hr />
                                                        <div className="col-sm-12">
                                                            <h5>FEDERAL EQUAL OPPORTUNITY QUESTIONS</h5>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <label><b>Invitation to Self-Identify</b></label>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <small>In order to help us comply with Federal Equal Opportunity record keeping and legal requirements, we encourage you to answer the questions below.</small>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <small>Please note that Staffers LLC adheres to and believes in equal employment opportunity for all applicants and employees without regard to race, color, sex, age, disability, national origin, religion or veteran status. This information is strictly confidential and kept separate from employee files.</small>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <CFormCheck
                                                                onChange={(event) => {
                                                                    setFieldValue("equal_opportunity", event?.target.checked ? 'true' : 'false')
                                                                    setFieldValue("hispanic", "")
                                                                    setFieldValue("gender", "")
                                                                    setFieldValue("race", "")
                                                                    setFieldValue("race_in_addition", "")
                                                                }}
                                                                onBlur={handleBlur}
                                                                checked={values.equal_opportunity == 'true'}
                                                                name="equal_opportunity"
                                                                value={values.equal_opportunity}
                                                                id="equalOpportunity"
                                                                label="I decline to answer these federal equal opportunity questions at this time." />
                                                        </div>
                                                        {values.equal_opportunity == "false" && <div className="col-sm-6">
                                                            <SelectField
                                                                name="gender"
                                                                label={"What is your gender?"}
                                                                value={values.gender}
                                                                onChange={(event) => { handleChange(event) }}
                                                                required={true}
                                                                onBlur={handleBlur}
                                                                data={[{ "label": "Male", "value": "M" }, { "label": "Female", "value": "F" }]}
                                                                optionLabel={"label"}
                                                                optionValue={"value"}
                                                            />
                                                            {errors.gender ? (
                                                                <div className="error-message">{errors.gender}</div>
                                                            ) : null}
                                                        </div>}
                                                        {values.equal_opportunity == "false" && <div className="col-sm-6">
                                                            <SelectField
                                                                name="hispanic"
                                                                label={"Are you Hispanic?"}
                                                                value={values.hispanic}
                                                                required={true}
                                                                onChange={(event) => { handleChange(event) }}
                                                                onBlur={handleBlur}
                                                                data={[{ "label": "Yes", "value": "Yes" }, { "label": "No", "value": "No" }]}
                                                                optionLabel={"label"}
                                                                optionValue={"value"}
                                                            />
                                                            {errors.hispanic ? (
                                                                <div className="error-message">{errors.hispanic}</div>
                                                            ) : null}
                                                        </div>}
                                                        {values.hispanic === 'No' && values.equal_opportunity == "false" && <><div className="col-sm-6">
                                                            <label>What is your race?</label>
                                                            <Select
                                                                label={""}
                                                                options={optionsRace}
                                                                formatOptionLabel={formatOptionLabel}
                                                                styles={colourStyles}
                                                                value={optionsRace.filter(({ value }) => values.race?.includes(value)) || '' || ''}
                                                                onChange={(event) => { setFieldValue("race", event?.value) }
                                                                }
                                                                components={{ Control }}
                                                            />
                                                        </div>
                                                            <div className="col-sm-6">
                                                                <label>In addition to the race above, do you identify as belonging to any other race?</label>
                                                                <Select
                                                                    label={""}
                                                                    options={optionsRaceInAddition}
                                                                    formatOptionLabel={formatOptionLabel}
                                                                    styles={colourStyles}
                                                                    value={optionsRaceInAddition.filter(({ value }) => values.race_in_addition?.includes(value)) || '' || ''}
                                                                    onChange={(event) => { setFieldValue("race_in_addition", event?.value) }
                                                                    }
                                                                    components={{ Control }}
                                                                />
                                                            </div>
                                                        </>}
                                                        <div className="col-sm-12 d-grid gap-2 d-md-flex justify-content-center">
                                                            <CButton size="lg" color="primary" shape="rounded-pill" disabled={(values.equal_opportunity == "true" ? false : values.gender ? (values.hispanic == "No" ? (!values.race_in_addition || !values.race) : false) : true)} onClick={() => setStepInner(1)}>Continue</CButton>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    currentStepInner === 1 &&
                                                    <div className="row g-4 p-2">
                                                        <div className="col-sm-12">
                                                            <h5>PROTECTED VETERAN SELF-IDENTIFICATION</h5>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <h3 className="text-center">APPLICATION FORM</h3>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <small>This employer is a Government contractor subject to the Vietnam Era Veterans' Readjustment Assistance Act of 1974, as amended by the Jobs for Veterans Act of 2002, 38 U.S.C. 4212 (VEVRAA), which requires Government contractors to take affirmative action to employ and advance in employment: (1) disabled veterans; (2) recently separated veterans; (3) active duty wartime or campaign badge veterans; and (4) Armed Forces service medal veterans. These classifications are defined as follows:</small>
                                                        </div>
                                                        <div className="col-sm-12">

                                                            <ul className="list-group list-group-flush">
                                                                <li className="list-group-item"> • A “disabled veteran” is one of the following:
                                                                    <ul className="list-unstyled ms-5">
                                                                        <li>i.	a veteran of the U.S. military, ground, naval or air service who is entitled to compensation (or who but for the receipt of military retired pay would be entitled to compensation) under laws administered by the Secretary of Veterans Affairs; or </li>
                                                                        <li>ii.	a person who was discharged or released from active duty because of a service-connected disability.</li>
                                                                    </ul>
                                                                </li>
                                                                <li className="list-group-item">• A “recently separated veteran” means any veteran during the three-year period beginning on the date of such veteran's discharge or release from active duty in the U.S. military, ground, naval, or air service.</li>
                                                                <li className="list-group-item">• An “active duty wartime or campaign badge veteran” means a veteran who served on active duty in the U.S. military, ground, naval or air service during a war, or in a campaign or expedition for which a campaign badge has been authorized under the laws administered by the Department of Defense.</li>
                                                                <li className="list-group-item">• An “Armed forces service medal veteran” means a veteran who, while serving on active duty in the U.S. military, ground, naval or air service, participated in a United States military operation for which an Armed Forces service medal was awarded pursuant to Executive Order 12985.</li>
                                                            </ul>
                                                        </div>

                                                        <div className="col-sm-12">
                                                            <small>Protected veterans may have additional rights under USERRA—the Uniformed Services Employment and Reemployment Rights Act. In particular, if you were absent from employment in order to perform service in the uniformed service, you may be entitled to be reemployed by your employer in the position you would have obtained with reasonable certainty if not for the absence due to service. For more information, call the U.S. Department of Labor's Veterans Employment and Training Service (VETS), toll-free, at 1-866-4-USA-DOL.</small>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <label>If you believe you belong to any of the categories of protected veterans listed above, please indicate by checking the appropriate box below. As a Government contractor subject to VEVRAA, we request this information in order to measure the effectiveness of the outreach and positive recruitment efforts we undertake pursuant to VEVRAA, and if you are a current employee because we are.</label>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <CFormCheck
                                                                type="radio"
                                                                id="veteranYes"
                                                                name="veteran"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                checked={values.veteran === 'true'}
                                                                value={true}
                                                                label="I identify as one or more of the classifications of protected veteran listed above" />
                                                            <CFormCheck
                                                                type="radio"
                                                                id="veteranNo"
                                                                name="veteran"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                checked={values.veteran === 'false'}
                                                                value={false}
                                                                label="I am not a protected veteran" />
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <small>Submission of this information is voluntary and refusal to provide it will not subject you to any adverse treatment. The information provided will be used only in ways that are not inconsistent with the Vietnam Era Veterans' Readjustment Assistance Act of 1974, as amended.</small>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <small>The information you submit will be kept confidential, except that (i) supervisors and managers may be informed regarding restrictions on the work or duties of disabled veterans, and regarding necessary accommodations; (ii) first aid and safety personnel may be informed, when and to the extent appropriate, if you have a condition that might require emergency treatment; and (iii) Government officials engaged in enforcing laws administered by the Office of Federal Contract Compliance Programs, or enforcing the Americans with Disabilities Act, may be informed.</small>
                                                        </div>
                                                        <div className="col-sm-12 d-grid gap-2 d-md-flex justify-content-center">
                                                            <CButton size="lg" color="primary" shape="rounded-pill" onClick={() => setStepInner(0)}>Back</CButton>
                                                            <CButton size="lg" color="primary" shape="rounded-pill" disabled={!values.veteran} onClick={() => setStepInner(2)}>Continue</CButton>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    currentStepInner === 2 &&
                                                    <div className="row g-4 p-2">
                                                        <div className="col-sm-12">
                                                            <h5>VOLUNTARY SELF-IDENTIFICATION OF DISABILITY</h5>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <label><b>Why are you being asked to complete this form?</b></label>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <small>Because we do business with the government, we must reach out to, hire, and provide equal opportunity to qualified people with disabilities. To help us measure how well we are doing, we are asking you to tell us if you have a disability or if you ever had a disability. Completing this form is voluntary, but we hope that you will choose to fill it out. If you are applying for a job, any answer you give will be kept private and will not be used against you in any way.</small>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <small>If you already work for us, your answer will not be used against you in any way. Because a person may become disabled at any time, we are required to ask all of our employees to update their information every five years. You may voluntarily self-identify as having a disability on this form without fear of any punishment because you did not identify as having a disability earlier.</small>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <label><b>How do I know if I have a disability?</b></label>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <small>You are considered to have a disability if you have a physical or mental impairment or medical condition that substantially limits a major life activity, or if you have a history or record of such an impairment or medical condition.</small>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <small>Disabilities include, but are not limited to:</small>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <ul className="list-group list-group-flush">
                                                                <li className="list-group-item">• Blindness</li>
                                                                <li className="list-group-item">• Deafness</li>
                                                                <li className="list-group-item">• Cancer</li>
                                                                <li className="list-group-item">• Diabetes</li>
                                                                <li className="list-group-item">• Epilepsy</li>
                                                                <li className="list-group-item">• Autism</li>
                                                                <li className="list-group-item">• Cerebral palsy</li>
                                                                <li className="list-group-item">• HIV/AIDS</li>
                                                                <li className="list-group-item">• Schizophrenia</li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <ul className="list-group list-group-flush">
                                                                <li className="list-group-item">• Muscular dystrophy</li>
                                                                <li className="list-group-item">• Bipolar disorder</li>
                                                                <li className="list-group-item">• Major depression</li>
                                                                <li className="list-group-item">• Multiple sclerosis (MS)</li>
                                                                <li className="list-group-item">• Missing limbs or partially missing limbs</li>
                                                                <li className="list-group-item">• Post-traumatic stress disorder (PTSD)</li>
                                                                <li className="list-group-item">• Obsessive compulsive disorder</li>
                                                                <li className="list-group-item">• Impairments requiring the use of a wheelchair</li>
                                                                <li className="list-group-item">• Intellectual disability (previously called mental retardation)</li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <label><b>Please check one of the boxes below:</b></label>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <CFormCheck
                                                                type="radio"
                                                                name="disability"
                                                                id="disabilityYes"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                checked={values.disability === 'Yes'}
                                                                value="Yes"
                                                                label="Yes, I have a disability" />
                                                            <CFormCheck
                                                                type="radio"
                                                                name="disability"
                                                                value="No"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                checked={values.disability === 'No'}
                                                                id="disabilityNo"
                                                                label="No, I don't have a disability" />
                                                            <CFormCheck
                                                                type="radio"
                                                                name="disability"
                                                                value="None"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                checked={values.disability === 'None'}
                                                                id="disabilityNone"
                                                                label="I don't wish to answer" />
                                                        </div>

                                                        <div className="col-sm-12">
                                                            <label><b>Reasonable Accommodation Notice</b></label>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <small>Federal law requires employers to provide reasonable accommodation to qualified individuals with disabilities. Please tell us if you require a reasonable accommodation to apply for a job or to perform your job. Examples of reasonable accommodation include making a change to the application process or work procedures, providing documents in an alternate format, using a sign language interpreter, or using specialized equipment.</small>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <small>* Section 503 of the Rehabilitation Act of 1973, as amended. For more information about this form or the equal employment obligations of Federal contractors, visit the U.S. Department of Labor’s Office of Federal Contract Compliance Programs (OFCCP) website at www.dol.gov/ofccp.</small>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <small>PUBLIC BURDEN STATEMENT: According to the Paperwork Reduction Act of 1995 no persons are required to respond to a collection of information unless such collection displays a valid OMB control number. This survey should take about 5 minutes to complete.</small>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <small>OMB Control Number 1250-0005</small>
                                                        </div>
                                                        <div className="col-sm-12 d-grid gap-2 d-md-flex justify-content-center">
                                                            <CButton size="lg" className="me-2" color="dark" shape="rounded-pill" onClick={() => setCurrentStep(currentStep - 1)} variant="outline">Back</CButton>
                                                            <CButton size="lg" color="primary" disabled={!values.disability} shape="rounded-pill" onClick={() => _Next(5)}>Save & Continue</CButton>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>}

                                    {/* FINISH  */}
                                    {currentStep === 5 &&
                                        <div className="border-check">
                                            <div className="row g-4 p-lg-5 p-3">

                                                <div className="col-sm-12">
                                                    <h5>Staffers collects and uses this info consistent with its Privacy Policy
                                                    </h5>
                                                </div>
                                                <div className="col-sm-6">
                                                    <InputField
                                                        className={"did-floating-input"}
                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                        type="date"
                                                        name="birth_date"
                                                        max={minDate}
                                                        min="1900-01-01"
                                                        label={"Date of birth"}
                                                        value={values.birth_date}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    {errors.dob ? (
                                                        <div className="error-message">{errors.dob}</div>
                                                    ) : null}
                                                </div>
                                                <div className="col-sm-6">
                                                    <InputField
                                                        className={"did-floating-input"}
                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                        type="text"
                                                        name="ssn"
                                                        label={"SSN"}
                                                        value={values.ssn}
                                                        onChange={(event) => {
                                                            const inputSsn = event.target.value;
                                                            const formattedSsn = inputSsn
                                                                .replace(/\D/g, '')
                                                                .replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');
                                                            handleChange({
                                                                target: {
                                                                    name: 'ssn',
                                                                    value: formattedSsn,
                                                                },
                                                            });
                                                        }}
                                                        maxLength={11}
                                                        onBlur={handleBlur}

                                                    />
                                                    {
                                                        values.ssn?.length < 11 ? (
                                                            <div className="error-message">Social Security Number (SSN) to have a minimum of 9 digits</div>
                                                        ) : null
                                                    }
                                                </div>

                                                <div className="col-sm-12">
                                                    <label>Will you now, or in the future for continued employment with Employer, require Employer to sponsor you for U.S. employment authorization, work visa/status, employer-based immigration benefit(s), such as an H-1B, E-3, TN, PERM, I-140, I-485 Supplement J, “green card”, etc.?
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <CFormCheck type="radio"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        checked={values.employment_tnc == 0}
                                                        name="employment_tnc"
                                                        value={0}
                                                        id="employmentTNCNo"
                                                        label="No"
                                                    />
                                                    <CFormCheck type="radio"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        checked={values.employment_tnc == 1}
                                                        name="employment_tnc"
                                                        value={1}
                                                        id="employmentTNCYes"
                                                        label="Yes" />
                                                </div>
                                                <div><h5 className="text-primary"><i className="fas fa-user-check"></i> Application Verification</h5></div>

                                                <div className="col-sm-12">
                                                    <CFormCheck
                                                        onChange={() => {
                                                            swal({
                                                                title: "ACCEPT & SUBMIT APPLICATION",
                                                                text: `Thank you for applying with Staffers LLC. It is important that the information you provide is
                                                                                         accurate and complete so that we can best serve you and our clients. By clicking 
                                                                                         “Accept and Submit Application” below, you certify, acknowledge, and agree to the following:\n
                                                                                         • The information provided on this application is true and accurate to the best of your knowledge. You understand any false or misleading statements provided in connection with your application shall be grounds for disqualifying you for employment or, if you are already employed, immediate dismissal, to the fullest extent permitted by law.\n
                                                                                         • You authorize Staffers LLC and its parent and affiliated companies (collectively Staffers), to verify all statements relating to your application, including by obtaining information from relevant parties such as your past employers and academic institutions. You also authorize Staffers LLC to release your personnel, medical or compliance information to its clients to the extent such information may be relevant to your employment, in response to lawful requests from other parties, including, but not limited to state or federal licensing bodies or, with respect to personnel information only, to prospective third-party employers. You release Staffers LL from all liabilities relating to such inquiries and disclosures.\n
                                                                                         • You understand that any offer of employment will be contingent upon verification of qualifications and authorization to work under applicable law, including the Immigration Reform and Control Act, as well as passing drug and background screening.\n
                                                                                         • You understand that any prospective employment with Staffers LLC would be at-will. This means either Staffers LLC or you could terminate or modify the employment relationship or its terms with or without notice, at any time. This at-will employment status may only be changed through a writing signed by the CEO of Staffers LLC.\n
                                                                                        `,
                                                                className: "w-75",
                                                                buttons: true,
                                                                dangerMode: true,
                                                                buttons: {
                                                                    cancel: 'DECLINE',
                                                                    confirm: { text: 'ACCEPT & SUBMIT', className: 'btn-success' },
                                                                },
                                                            })
                                                                .then((willDelete) => {
                                                                    if (willDelete) {
                                                                        setFieldValue('tnc', 1)
                                                                        // handleSubmit()
                                                                    } else {
                                                                        setFieldValue('tnc', 0)

                                                                    }
                                                                });

                                                        }
                                                        }
                                                        required
                                                        onBlur={handleBlur}
                                                        checked={values.tnc === 1}
                                                        name="tnc"
                                                        value={values.tnc}
                                                        id="tnc"
                                                        label="I have read and agreed to the Terms and Conditions*" />
                                                </div>
                                                {<div className="col-sm-12 d-grid gap-2 d-md-flex justify-content-center">
                                                    <CButton size="lg" className="me-2" color="dark" shape="rounded-pill" onClick={() => setCurrentStep(currentStep - 1)} variant="outline">Back</CButton>

                                                    <CButton size="lg" color="primary" shape="rounded-pill" type="submit">Submit Application</CButton>
                                                </div>}
                                            </div>
                                        </div>}
                                </form>
                            )}

                        </Formik>
                    </div>

                    {/* <!-- main-panel ends --> */}
                </div>
                {/* <!-- page-body-wrapper ends --> */}
            </div>
            {/* </body> */}
            <Spinner isLoading={AdminStore.loading} />
        </React.Fragment >
    );
})
export default AddCaregiver;
const formatOptionLabel = ({ label, info }) => (
    <div>
        <div>{label}</div>
        <div style={{ fontSize: '12px', color: '#999' }}>{info}</div>
    </div>
);