import React from "react";
import { Helmet } from "react-helmet";

const PageHelmet = ({ name, metaData, ldjson }) => {
  return (
    <Helmet>
      <title>{`Staffers LLC | ${name}`}</title>
      <meta name="description" content={metaData} />
      <script type="application/ld+json">{ldjson}</script>
    </Helmet>
  );
};

export default PageHelmet;
