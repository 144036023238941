/* eslint-disable */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react"
import JobItemSkeleton from "../components/skeletons/JobItemSkeleton";
import Multiselect from "multiselect-react-dropdown";
import HomeStore from "@store/HomeStore";
import { useLocation, useNavigate } from 'react-router-dom';
import StaffsStore from "@store/StaffsStore";
import StaffsItem from "../components/staffs/StaffsItem";
import { InputField } from "@elements/index";
import secureLocalStorage from "react-secure-storage";
import { CModal, CModalHeader, CModalTitle } from "@coreui/react";
import { Formik, Field, FieldArray } from "formik";
import StaffSheetComponent from "@elements/StaffBottomSheet/StaffSheetComponent";
import Spinner from "@components/Spinner";
import SelectField from "@elements/select/SelectField";
import UserStore from "@store/UserStore";
import * as Yup from 'yup';
import { toJS } from "mobx";
import Header from "@components/Header";
import DatePicker, { getAllDatesInRange } from "react-multi-date-picker";
import TimeRangePicker from '@wojtekmaj/react-timerange-picker';
import weekends from "react-multi-date-picker/plugins/highlight_weekends";
import { toast } from "react-toastify";
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import Select, { components, ValueContainerProps } from 'react-select';
import { Helmet } from "react-helmet";
const onlyAlphabets = /^[A-Za-z ]+$/;

const _ = require('lodash');
var cityData = require('@assets/city.json');
var states = require('@assets/states.json');
const colourStyles = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? 'blue' : '#75459b',
        borderRadius: 20,
        boxShadow: 'none',
        padding: "2px 10px",
        '&:focus': {
            border: '1px solid blue !important'
        },
        '&:active': {
            border: '1px solid blue !important'
        },
        '&:hover': {
            border: '1px solid blue !important'
        }
    }),
    valueContainer: (provided) => ({
        ...provided,
        fontSize: 12,
    }),
    menu: base => ({
        ...base,
        fontSize: 12,
    }),
    menuList: (base) => ({
        ...base,
        height: "200px",

        "::-webkit-scrollbar": {
            width: "9px"
        },
        "::-webkit-scrollbar-track": {
            background: "white"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#8282C8"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        }
    })

}
const UpdateSchema = Yup.object().shape({
    state: Yup.string().required('State required'),
    city: Yup.string().required('City required'),
    profession: Yup.string().required('Profession required'),
    category: Yup.string().required('Category required'),
});

const StaffsPage = observer(() => {
    const navigate = useNavigate();
    const params = useLocation();
    const [error, setError] = useState('');
    const [regions, setRegions] = useState([]);
    const [specialties, setSpecialties] = useState([]);
    const specialtiesRef = React.createRef();
    const [lastPage, setLastPage] = useState(1);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(15);

    const [staffList, setStaffList] = useState([]);
    const [selectedDegree, setSelectedDegree] = useState(params?.state?.degree);
    const [selectedSpecialties, setSelectedSpecialties] = useState(params?.state?.specialties);
    const [selectedCity, setSelectedCity] = useState(params?.state?.city || []);
    const [selectedState, setSelectedState] = useState(params?.state?.states || []);
    const [selectedRating, setSelectedRating] = useState(params?.state?.rating || null);
    const [name, setName] = useState(params?.state?.name);
    const [traveller, setTraveller] = useState(params?.state?.traveller || 0);

    const [isLoading, setLoad] = useState(false);
    const [isLoadingText, setLoadText] = useState(false);
    const [isAuthenticated, setAuthenticated] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenForm, setIsOpenForm] = useState(false);
    const [staffData, setStaffData] = useState(null);
    const [userData, setUserData] = useState(null);
    const [bookmarkList, setBookmarkList] = useState([]);
    const [multi, setValues] = useState([])
    const [dates, setDates] = useState([])
    const [allDates, setAllDates] = useState([])
    const [durationType, setDurationType] = useState(undefined);
    const [facilityCode, setFacilityCode] = useState(null);
    const [cityList, setCityList] = useState([]);
    const [clearAll, setClearAll] = useState(0);

    const [userState, setUserState] = useState(null);
    const [userCity, setUserCity] = useState(null);
    const [userZipcode, setUserZipCode] = useState(null);
    const [userAddress, setUserAddress] = useState(null);


    const goToPage = (user) => {
        navigate('/howdoesitwork', { state: { type: user } });
    }

    useEffect(() => {
        setLoad(true);
        StaffsStore.getRegionsList();
        let token = secureLocalStorage.getItem("token");
        var userData = secureLocalStorage.getItem("userData");
        setFacilityCode(JSON.parse(userData)?.facility_code);
        setUserState(JSON.parse(userData)?.state);
        setUserCity(JSON.parse(userData)?.city);
        setUserAddress(JSON.parse(userData)?.address);
        setUserZipCode(JSON.parse(userData)?.zipcode);
        setUserData(JSON.parse(userData));
        setAuthenticated(token);
        onSearch()
        navigate("", { replace: true });
        if (token) {
            UserStore.getBookmark();
        }
    }, [])

    useEffect(() => {
        setBookmarkList(toJS(UserStore?.data?.bookmarkList).map(x => parseInt(x.staff_id)))
    }, [UserStore?.data?.bookmarkList]);

    useEffect(() => {
        onSearch()
    }, [clearAll])

    const onChangeState = (value) => {
        setSelectedState(value);
        setCityList(cityData?.filter(x => value?.find(y => y.abbreviation === x.state_id)))
    };
    const onChangeStateRemove = (value) => {
        setSelectedState(value);
        setCityList(cityData?.filter(x => value?.find(y => y.abbreviation === x.state)))
        if (selectedCity && selectedCity?.length != 0) {
            setSelectedCity(selectedCity?.filter(item => value?.find(data => data.abbreviation === item.state)))
        }
    }

    const onChangeCity = (value) => {
        setSelectedCity(value);
    };

    const onChangeCityRemove = (value) => {
        setSelectedCity(value)
    }


    const onChangeRating = (value) => {
        setSelectedRating(value);
    };
    const onChangeDegree = (value) => {
        setSelectedDegree(value);
        specialtyLoad(value);
    };

    const onChangeDegreeRemove = (event) => {
        setSelectedDegree(event);
        setSpecialties(specialties?.filter(item => event?.find(data => data.Id === item.DegreeID)));
        setSelectedSpecialties(selectedSpecialties?.filter(item => event.find(data => data.Id === item.DegreeID)))
    };

    const specialtyLoad = (value) => {
        if (value?.length > 0) {
            value?.map(async (item) => {
                if (!specialties.find(x => x.DegreeID == item.Id)) {
                    await HomeStore.getSpecialtiesListData(item.Id).then((spec) => {
                        if (spec?.length != 0) {
                            setSpecialties([...specialties, ...spec])
                        }
                    })
                }
            })
        }
    }
    const onChangeSpecialties = (value) => {
        setSelectedSpecialties(value);
    };
    const onChangeSpecialtiesRemove = (value) => {
        setSelectedSpecialties(value);
    };


    const onSearch = () => {
        let param = {
            "page": 1,
            "per_page": 15,
            "name": name,
            "profession": selectedDegree ? selectedDegree?.map(x => x.Id) : [],
            "specialty": selectedSpecialties ? selectedSpecialties?.map(x => x.Id) : [],
            "state": selectedState ? selectedState?.map(x => x.abbreviation) : [],
            "rating": selectedRating ? selectedRating[0]?.value : 0,
            "traveller": traveller,
            "city": selectedCity ? selectedCity?.map(x => x.label) : [],
        }
        setLoad(true);
        StaffsStore.getStaffsList(param).then(async (staffData) => {
            setLastPage(staffData?.data?.last_page)
            setStaffList(staffData?.data?.data)
            setLoad(false);
        });
    }

    const onLoadList = () => {
        if (isAuthenticated) {
            let param = {
                "page": page + 1,
                "per_page": pageSize,
                "name": name,
                "profession": selectedDegree ? selectedDegree?.map(x => x.Id) : [],
                "specialty": selectedSpecialties ? selectedSpecialties?.map(x => x.Id) : [],
                "state": selectedState ? selectedState?.map(x => x.abbreviation) : [],
                "rating": selectedRating ? selectedRating[0]?.value : 0,
                "traveller": traveller,
                "city": selectedCity ? selectedCity?.map(x => x.label) : [],
            }
            setLoad(true);
            StaffsStore.getStaffsList(param).then(async (staffData) => {
                setPage(page + 1);
                setLastPage(staffData?.data?.last_page)
                setStaffList([...staffList, ...staffData?.data?.data])
                setLoad(false);
            });
        } else {
            swal({
                title: "Alert!",
                text: "Please login/register to see more results.",
                icon: "info",
                buttons: {
                    login: {
                        text: "Login",
                        value: "login",
                    },
                    register: {
                        text: "Register",
                        value: "register",
                        className: "btn-success"
                    },
                    cancel: true,
                },
            })
                .then((value) => {
                    if (value === "register") {
                        navigate('/register/staff');
                    } else if (value === "login") {
                        navigate('/login/staff');
                    }
                    else {
                        // Handle the OK button click or do nothing
                        console.log("User clicked OK");
                    }
                });
        }
    }

    const handleChangeName = (event) => {
        if (onlyAlphabets?.test(event.target.value) || event.target.value === '') {
            let value = event.target.value;
            setName(value)
            setError('');
        } else {
            setError('Only alphabets are allowed');
        }

    }
    const scrollToEnd = () => {
        onLoadList()
    }

    window.onscroll = function () {
        if (((window.innerHeight - 200) + document.documentElement.scrollTop + 700 > document.documentElement.offsetHeight) && !StaffsStore.loading) {
            if (page <= lastPage) {
                scrollToEnd();
            }
        }
    }
    const onDismiss = () => {
        setIsOpen(false)
    }
    const onOpen = (item) => {
        if (isAuthenticated) {
            setStaffData(item);
            setIsOpen(true)
        }
    }
    const onDismissForm = () => {
        setValues([])
        setDates([])
        setAllDates([])
        setDurationType(null);
        setIsOpenForm(false)
        setIsOpen(true)
    }
    const onOpenForm = () => {
        setIsOpen(false)
        setIsOpenForm(true)
    }

    const onHiringRequest = (values) => {
        var check;
        var finalslot;

        if (values.category !== "TC") {
            finalslot = values.tenure.map((x, index) => {
                return { "date": x.format(), "slot_type": values.alltype[index] ? values.alltype[index] : values.alltype[0], "time": values.alltime[index] ? values.alltime[index] : values.alltime[0], "remark": values?.allcomment ? values?.allcomment[index] || '' : '' }
            })
            check = finalslot.some((obj) => !Object.values(obj).every((val) => val !== undefined));
        } else {
            check = false
        }

        if (check) {
            toast.warn("Please select date & time!")
        } else {
            let param = {
                user_id: values.user_id,
                staff_id: values.staff_id,
                staff_name: values.staff_name,
                profession: values.profession,
                specialty: values.specialty,
                specialty_name: values.specialty_name,
                category: values.category,
                shift_type: values.shift_type,
                slots: JSON.stringify(finalslot),
                zipcode: values.zipcode,
                address: values.address,
                job_type: values.job_type,
                description: values.description,
                city: values.city,
                state: values.state,
                nop: values?.nop,
                hours_per_week: values.hours_per_week,
                no_of_weeks: values.no_of_weeks,
                pay_details: values.pay_detail,
                start_date: values.start_date,
                end_date: values.end_date,
                start_time: values.start_time,
                end_time: values.end_time,
                qualifications: values.qualifications
            }
            UserStore.setStaffingRequest(param, navigationCallBack, navigationCallBackUnallocated)
        }
    }

    const navigationCallBack = () => {
        let emailList;
        let contactList;
        if (userData?.additional_mail) {
            emailList = JSON.parse(userData?.additional_mail)?.map(function (item) {
                return '<strong class="text-primary">&#128231;' + item?.email + '</strong>';
            }).join('<br>');
        }
        if (userData?.additional_contact) {
            contactList = JSON.parse(userData?.additional_contact)?.map(function (item) {
                return '<strong class="text-primary">&#128231;' + item?.country_code + " " + item?.contact + '</strong>';
            }).join('<br>');
        }
        var content = document.createElement('div');
        content.innerHTML = 'Thank you for submitting a staff request through Staffers!<br>We will notify you as soon as your request has been claimed.<br>Your specific request has been sent to all available healthcare professionals.<br> The notification will be sent to:<br><strong class="text-primary">&#128222; ' + userData?.contact + '</strong><br>' + (contactList ? contactList : "") + '<br><strong class="text-primary">&#128231;'
            + userData?.email + '</strong><br>' + (emailList ? emailList : "") + '<br>Thank you for trusting Staffers with your staffing needs!';
        swal({
            title: "Success",
            content: content,
            icon: "success"
        }).then((value) => {
            onDismissForm()
        });
    }
    const navigationCallBackUnallocated = () => {
        onDismissForm()
        onDismiss()
    }

    const clear = () => {
        setClearAll(clearAll + 1)
        setName('')
        setTraveller(0)
        setSelectedState([])
        setSelectedCity([])
        setSelectedDegree([])
        setSelectedRating()
        setSelectedSpecialties([])
        goToResult()
    }
    const goToResult = () => {
        const element = document.getElementById('featured');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };


    return (
        <>
            <Helmet>
                <title>Staffers LLC | Search Caregiver</title>
            </Helmet>
            <Header />
            <div className="container-fluid p-0 mb-5 wow fadeIn" data-wow-delay="0.03s">
                <div id="header-carousel" className="carousel slide carousel-fade" data-bs-ride="carousel">
                    <div className="carousel-inner carousel-radius">
                        <div className="carousel-item active">
                            <img className="w-100" src="assets/img/carousel-1.png" alt="banner" />
                            <div className="carousel-caption">
                                <div className="container">
                                    <div className="row justify-content-start">
                                        <div className="col-lg-8 pt-5">
                                            <p
                                                className="display-6 d-inline-block text-light py-1 px-0 animated slideInDown">
                                                {HomeStore?.data?.dashboardData?.find(x => x.key_name === 'Banner H1')?.value || "COMING TO THE RESCUE"}</p>
                                            <h6 className="display-5 mb-4 animated slideInDown text-light">{HomeStore?.data?.dashboardData?.find(x => x.key_name === 'Banner H2')?.value || "Welcome to Staffers Community!"}</h6>
                                            <small className="mb-4 animated slideInDown text-light">{HomeStore?.data?.dashboardData?.find(x => x.key_name === 'Banner H3')?.value || "Staffers provides a technology platform to connect high quality clinicians with open shifts in their area. Facilities get the help they need to manage flexible, temporary, and contract labor exclusive to the healthcare industry. Our unique marketplace allows facilities to post shifts and enables caregivers to work those shifts easily. Sign up today and start working tomorrow!"}
                                            </small>
                                            <div className="pt-5">
                                                {!isAuthenticated && <a href="/register/employer" className="btn btn-primary-light  py-3 px-5 mb-3 animated slideInDown">Join Now</a>}
                                                &nbsp;
                                                <a onClick={() => goToPage("client")} className="btn bg-transparent border border-light text-light py-3 px-5 mb-3 animated slideInDown">Learn more</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container home-float-search-staffs">
                <div className="shadow border rounded p-4 p-sm-2 wow fadeInUp" style={{ backgroundColor: "#f6e8ff" }}>
                    <div className="row">
                        <div className="col-lg-12 col-12">
                            <div className="row">
                                <div className="col-lg-10 col-sm-12 col-md-12 pt-2 pb-3 ps-4">
                                    <b className="">SEARCH CAREGIVER</b>
                                </div>
                            </div>
                            <div className="row justify-content-center align-items-center">
                                <div className="col-12 col-lg-10">
                                    <div className="row ">
                                        <div className="col-lg-4 col-12">
                                            <div className="h-100 text-center">
                                                <div className="ps-lg-3 ps-0 mb-3">
                                                    <InputField
                                                        className={"did-floating-select dropdown-multi searchBox "}
                                                        type="text"
                                                        name="name"
                                                        value={name}
                                                        label={"Caregiver Name"}
                                                        onChange={handleChangeName}
                                                        classNameLabel="did-floating-label-custom"
                                                    />
                                                    {error && <small className='text-danger'>{error}</small>}

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-12">
                                            <div className="h-100 text-center">
                                                <div className="ps-lg-3 ps-0 mb-3">
                                                    <Multiselect
                                                        placeholder="Select Degree"
                                                        className="dropdown-multi"
                                                        onSelect={onChangeDegree}
                                                        onRemove={onChangeDegreeRemove}
                                                        selectedValues={selectedDegree}
                                                        avoidHighlightFirstOption={true}
                                                        options={HomeStore.data.degreeList}
                                                        showArrow
                                                        displayValue="Name"
                                                        closeOnSelect={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-12">
                                            <div className="h-100 text-center">
                                                <div className="ps-lg-3 ps-0 mb-3">
                                                    <Multiselect
                                                        placeholder="Select Specialties"
                                                        id='one'
                                                        className="dropdown-multi"
                                                        showArrow
                                                        selectedValues={selectedSpecialties}
                                                        onSelect={onChangeSpecialties}
                                                        onRemove={onChangeSpecialtiesRemove}
                                                        options={specialties}
                                                        avoidHighlightFirstOption={true}
                                                        displayValue="Name"
                                                        ref={specialtiesRef}
                                                        closeOnSelect={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 col-12">
                                            <div className="h-100 text-center">
                                                <div className="ps-lg-3 ps-0 mb-3">
                                                    <Multiselect
                                                        placeholder="State"
                                                        className="dropdown-multi"
                                                        onSelect={onChangeState}
                                                        options={states} // Options to display in the dropdown
                                                        selectedValues={selectedState}
                                                        displayValue="name"
                                                        groupBy="group"
                                                        showArrow
                                                        avoidHighlightFirstOption={true}
                                                        onRemove={onChangeStateRemove}
                                                        closeOnSelect={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-12">
                                            <div className="h-100 text-center">
                                                <div className="ps-lg-3 ps-0 mb-3">
                                                    <Multiselect
                                                        placeholder="City"
                                                        className="dropdown-multi"
                                                        avoidHighlightFirstOption={true}
                                                        onSelect={onChangeCity}
                                                        onRemove={onChangeCityRemove}
                                                        options={cityList} // Options to display in the dropdown city
                                                        selectedValues={selectedCity}
                                                        displayValue="label"
                                                        showArrow
                                                        groupBy="group"
                                                        closeOnSelect={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-12">
                                            <div className="h-100 text-center">
                                                <div className="ps-lg-3 ps-0 mb-3">
                                                    <Multiselect
                                                        placeholder="Rating"
                                                        className="dropdown-multi"
                                                        avoidHighlightFirstOption={true}
                                                        singleSelect
                                                        onSelect={onChangeRating}
                                                        options={global.config.ratingOptions} // Options to display in the dropdown
                                                        selectedValues={selectedRating}
                                                        displayValue="label"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-2">
                                    <div className="mb-3">
                                        <button className="btn btn-primary w-100 py-2" name="submit" type="submit" onClick={() => { onSearch(), goToResult() }}>Search</button>
                                    </div>

                                    <div>
                                        <button className="btn btn-primaryLight w-100 py-2" name="submit" type="submit" onClick={() => { clear(), goToResult() }}>Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section id="featured" className="section bg-cyan mt-lg-0 mt-5">
                <div className="container-fluid">
                    <div className="row">
                        {
                            staffList && staffList?.length === 0 &&
                            <>
                                <p className="text-center mt-5 pt-5">No Caregiver Found</p>
                            </>
                        }
                        <div>
                            {(staffList && staffList?.length != 0) && <p className="text-end pt-lg-5 me-5">{staffList && staffList?.length} entries</p>}
                        </div>
                        {
                            isLoading ?
                                (<>
                                    <div className="grid">
                                        {Array(6)
                                            .fill(0)
                                            .map((_, index) => (
                                                <JobItemSkeleton key={index + "staffsList"} />
                                            ))}
                                    </div>
                                </>) : null
                        }
                        {
                            <div>
                                <div className="grid">
                                    {
                                        !isLoading && staffList && staffList?.length > 0 && staffList.map((item, index) => {
                                            return <div key={item?.id + "slist"}><StaffsItem onOpen={onOpen} item={item} degree={HomeStore.data.degreeList} isAuthenticated={isAuthenticated} bookmarkList={bookmarkList} regions={regions} />
                                            </div>
                                        })
                                    }
                                </div>
                                {isLoadingText && <p className="text-center mt-5 me-5">Loading...</p>}

                            </div>
                        }
                    </div>
                    <div>
                        {(staffList && staffList?.length != 0) && <p className="text-end mt-5 me-5">{staffList && staffList?.length} entries</p>}
                    </div>
                </div>
            </section>
            {/* Bottom Sheet */}
            <StaffSheetComponent isOpen={isOpen} onDismiss={onDismiss} onOpenForm={onOpenForm} data={staffData} />
            {/* HIRING POP UP */}

            <CModal visible={isOpenForm} onClose={onDismissForm} backdrop="static">
                <CModalHeader>
                    <CModalTitle>Request to select this caregiver</CModalTitle>
                </CModalHeader>
                {/* <CModalBody> */}
                <Formik
                    initialValues={{
                        user_id: userData?.id, facility_code: facilityCode || '', category: staffData?.category || '',
                        nop: "",
                        hours_per_week: "",
                        no_of_weeks: "",
                        pay_detail: "",
                        start_date: "",
                        end_date: "",
                        start_time: "",
                        end_time: "",
                        qualifications: "",
                        profession: '',
                        specialty: '', specialty_name: '',
                        staff_id: staffData?.staff_id,
                        staff_name: staffData?.first_name, tenure: "", shift_type: "", zipcode: '', job_type: 2, description: "", state: userState || "", city: userCity || "", address: userAddress || "", zipcode: userZipcode || ""
                    }}
                    onSubmit={values => onHiringRequest(values)}
                    validationSchema={UpdateSchema}
                    validateOnChange={false}
                >
                    {
                        ({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched
                        }) =>
                        (
                            <form onSubmit={handleSubmit}>
                                <div className="modal-body p-4">
                                    <div className="row g-2">
                                        <div className="pb-2 col-sm-6">
                                            <InputField
                                                className={"did-floating-input"}
                                                type="text"
                                                name="staff_name"
                                                label={"Name"}
                                                value={staffData?.first_name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                readOnly={true}
                                            />
                                        </div>
                                        <div className="pb-2 col-sm-6">
                                            <InputField
                                                className={"did-floating-input"}
                                                type="text"
                                                label={"Caregiver Location"}
                                                value={`${staffData?.city}, ${staffData?.state_id}`}
                                                readOnly={true}
                                            />
                                        </div>
                                        <div className="pb-2 col-sm-6">
                                            <InputField
                                                className={"did-floating-input"}
                                                type="text"
                                                name="facility_code"
                                                label={"Facility Code"}
                                                value={values.facility_code}
                                                disabled={true}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </div>
                                        <div className="pb-2 col-sm-6">
                                            <Select
                                                onChange={(e) => { setFieldValue("state", e.abbreviation), setFieldValue("city", '') }}
                                                placeholder={''}
                                                label={'State*'}
                                                styles={colourStyles}
                                                value={states.filter(({ abbreviation }) => values?.state?.includes(abbreviation)) || ''}
                                                options={states}
                                                isDisabled
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.abbreviation}
                                                components={{ Control, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                            />
                                            {errors.state && touched.state ? (
                                                <div className="error-message">{errors.state}</div>
                                            ) : null}
                                        </div>
                                        <div className="pb-2 col-sm-6">
                                            <Select
                                                onChange={(e) => setFieldValue("city", e.label)}
                                                placeholder={''}
                                                label={'City*'}
                                                isDisabled
                                                styles={colourStyles}
                                                getOptionLabel={(option) => option.label}
                                                getOptionValue={(option) => option.label}
                                                value={cityData.filter(({ label }) => values?.city === label) || ''}
                                                options={cityData?.filter(ct => ct.state === states?.find(x => x.abbreviation == values?.state)?.name)}
                                                components={{ Control, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                            />
                                            {errors.city && touched.city ? (
                                                <div className="error-message">{errors.city}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="did-floating-label-content">
                                                <select className="did-floating-dropdown"
                                                    name="profession"
                                                    value={values.profession}
                                                    onChange={handleChange}
                                                    onClick={(e) => {
                                                        setFieldValue('profession', e.target.value)
                                                    }}
                                                >
                                                    <option
                                                        value=""
                                                    >
                                                    </option>
                                                    {staffData?.degree_specialities && JSON.parse(staffData?.degree_specialities)?.filter((entry, index, self) => self.findIndex(e => e.profession === entry.profession) === index)?.map((option, index) => (
                                                        <option
                                                            key={index}
                                                            value={option.profession}
                                                        >
                                                            {option.profession_name}
                                                        </option>
                                                    ))}
                                                </select>
                                                <label className={values.profession == "" ? "did-floating-label did-floating-label-down" : "did-floating-label did-floating-label-top"}>Profession*</label>
                                            </div>
                                            {errors.profession && touched.profession ? (
                                                <div className="error-message">{errors.profession}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="did-floating-label-content">
                                                <select className="did-floating-dropdown"
                                                    name="specialty"
                                                    value={values.specialty}
                                                    onChange={handleChange}
                                                    onClick={(e) => {
                                                        const selectedOption = e.target.options[e.target.selectedIndex];
                                                        const specialtyName = selectedOption.getAttribute('data-name');
                                                        setFieldValue('specialty', e.target.value)
                                                        setFieldValue('specialty_name', specialtyName)
                                                    }}
                                                >
                                                    <option
                                                        value=""
                                                    >
                                                    </option>
                                                    {staffData?.degree_specialities && JSON.parse(staffData?.degree_specialities)?.filter(x => x.profession == values.profession)?.map((option, index) => (
                                                        <option
                                                            key={index}
                                                            value={option.specialties}
                                                            data-name={option.specialties_name}
                                                        >
                                                            {option.specialties_name}
                                                        </option>
                                                    ))}
                                                </select>
                                                <label className={values.specialty == "" ? "did-floating-label did-floating-label-down" : "did-floating-label did-floating-label-top"}>Specialty</label>
                                            </div>
                                        </div>

                                        <div className="pb-2 col-sm-6">
                                            <SelectField
                                                name="category"
                                                label={"Category*"}
                                                value={values.category}
                                                onChange={(e) => {
                                                    setFieldValue("category", e.target.value)
                                                    setValues([])
                                                    setDates([])
                                                    setAllDates([])
                                                    setFieldValue("tenure", "")
                                                    setFieldValue("alldate", "")
                                                    setFieldValue("alltime", "")
                                                    setFieldValue("alltype", "")
                                                    setFieldValue("allcomment", "")
                                                }}
                                                onBlur={handleBlur}
                                                data={global.config.shiftCaregory}
                                                optionLabel={"label"}
                                                optionValue={"value"}
                                            />
                                            {errors.category && touched.category ? (
                                                <div className="error-message">{errors.category}</div>
                                            ) : null}
                                        </div>
                                        {values.category === 'TC' &&
                                            <>
                                                <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                    <SelectField
                                                        name="shift_type"
                                                        label={"Order Type"}
                                                        value={values.shift_type}
                                                        onChange={(event) => {
                                                            setFieldValue("shift_type", event.target.value)
                                                            setFieldValue(`start_time`, global.config.shiftTimeRange?.find(x => x.label === event.target.value)?.value?.start)
                                                            setFieldValue(`end_time`, global.config.shiftTimeRange?.find(x => x.label === event.target.value)?.value?.end)
                                                        }}
                                                        onBlur={handleBlur}
                                                        required
                                                        data={global.config.shiftType}
                                                        optionLabel={"name"}
                                                        optionValue={"value"}
                                                    />
                                                    {errors.shift_type && touched.shift_type ? (
                                                        <div className="error-message">{errors.shift_type}</div>
                                                    ) : null}
                                                </div>

                                                <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                    <InputField
                                                        className={"did-floating-input"}
                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                        type="text"
                                                        name="pay_detail"
                                                        label={"Pay Detail"}
                                                        value={values.pay_detail}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        required={true}
                                                    />
                                                    {errors.pay_detail && touched.pay_detail ? (
                                                        <div className="error-message">{errors.pay_detail}</div>
                                                    ) : null}
                                                </div>

                                                <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                    <InputField
                                                        className={"did-floating-input"}
                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                        type="date"
                                                        name="start_date"
                                                        label={"Start Date"}
                                                        value={values.start_date}
                                                        min={new Date().toISOString().split('T')[0]}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        required={true}
                                                    />
                                                    {errors.start_date && touched.start_date ? (
                                                        <div className="error-message">{errors.start_date}</div>
                                                    ) : null}
                                                </div>
                                                <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                    <InputField
                                                        className={"did-floating-input"}
                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                        type="date"
                                                        name="end_date"
                                                        label={"End Date"}
                                                        value={values.end_date}
                                                        min={values.start_date ? values.start_date : new Date().toISOString().split('T')[0] || values.start_date}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        required={true}
                                                    />
                                                    {errors.end_date && touched.end_date ? (
                                                        <div className="error-message">{errors.end_date}</div>
                                                    ) : null}
                                                </div>
                                                <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                    <InputField
                                                        className={"did-floating-input"}
                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                        type="time"
                                                        name="start_time"
                                                        label={"Start Time"}
                                                        value={values.start_time}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        required={true}
                                                    />
                                                    {errors.start_time && touched.start_time ? (
                                                        <div className="error-message">{errors.start_time}</div>
                                                    ) : null}
                                                </div>
                                                <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                    <InputField
                                                        className={"did-floating-input"}
                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                        type="time"
                                                        name="end_time"
                                                        label={"End Time"}
                                                        value={values.end_time}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        required={true}
                                                    />
                                                    {errors.end_time && touched.end_time ? (
                                                        <div className="error-message">{errors.end_time}</div>
                                                    ) : null}
                                                </div>
                                                <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                    <InputField
                                                        className={"did-floating-input"}
                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                        type="number"
                                                        name="hours_per_week"
                                                        label={"Guaranteed Hours Per Week"}
                                                        value={values.ghpw}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        required={true}
                                                        min={1}
                                                    />
                                                    {errors.hours_per_week && touched.hours_per_week ? (
                                                        <div className="error-message">{errors.hours_per_week}</div>
                                                    ) : null}
                                                </div>
                                                <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                    <InputField
                                                        className={"did-floating-input"}
                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                        type="number"
                                                        name="no_of_weeks"
                                                        label={"# of Weeks"}
                                                        value={values.no_of_weeks}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        required={true}
                                                        min={1}
                                                    />
                                                    {errors.no_of_weeks && touched.no_of_weeks ? (
                                                        <div className="error-message">{errors.no_of_weeks}</div>
                                                    ) : null}
                                                </div>
                                                <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                    <InputField
                                                        className={"did-floating-input"}
                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                        type="number"
                                                        name="nop"
                                                        label={"# of Openings"}
                                                        value={values.nop}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        required={true}
                                                        min={1}
                                                    />
                                                    {errors.nop && touched.nop ? (
                                                        <div className="error-message">{errors.nop}</div>
                                                    ) : null}
                                                </div>
                                                <div className="col-sm-6 pb-lg-2 pb-md-2">
                                                    <InputField
                                                        className={"did-floating-input"}
                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                        type="text"
                                                        name="qualifications"
                                                        label={"Qualifications"}
                                                        value={values.qualifications}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        required={true}
                                                    />
                                                    {errors.qualifications && touched.qualifications ? (
                                                        <div className="error-message">{errors.qualifications}</div>
                                                    ) : null}
                                                </div>
                                            </>
                                        }
                                        <div className="pb-2 col-sm-6">
                                            {values.category === 'PD' ? <DatePicker
                                                disabled={values.category === null}
                                                required
                                                calendarPosition="top-left"
                                                placeholder="Select Dates"
                                                format="MM/DD/YYYY"
                                                inputClass="did-floating-dropdown"
                                                multiple
                                                value={multi}
                                                minDate={new Date()}
                                                onChange={(e) => {
                                                    setValues(e)
                                                    setFieldValue('tenure', e)
                                                }}
                                                plugins={[
                                                    weekends()
                                                ]}
                                            /> : values.category === "TR" ?
                                                <DatePicker
                                                    disabled={values.category === null}
                                                    placeholder="Select Start & End Date"
                                                    calendarPosition="top-left"
                                                    required
                                                    format="MM/DD/YYYY"
                                                    inputClass="did-floating-dropdown"
                                                    range
                                                    rangeHover
                                                    value={dates}
                                                    minDate={new Date()}
                                                    onChange={dateObjects => {
                                                        setDates(dateObjects)
                                                        setAllDates(getAllDatesInRange(dateObjects))
                                                        setFieldValue('tenure', getAllDatesInRange(dateObjects))
                                                    }}
                                                    plugins={[
                                                        weekends()
                                                    ]}
                                                /> : ""}
                                        </div>
                                        <div>
                                            {dates?.length > 1 &&
                                                <div>
                                                    <div className="d-flex justify-content-start mt-2 mb-2">Slots:</div>
                                                    <ul className="list-unstyled">
                                                        <FieldArray name="tenure">
                                                            {({ push, remove, form }) => (
                                                                values?.tenure && values?.tenure?.length != 0 && values?.tenure?.map((date, index) =>
                                                                    <div className="card border p-2 mb-2">
                                                                        <li key={index}>
                                                                            <div className="col-4 pt-1 pb-2" >
                                                                                <SelectField
                                                                                    id={"slot-type"}
                                                                                    label={"Shift Type"}
                                                                                    name={`alltype[${index}]`}
                                                                                    onChange={(event) => {
                                                                                        setFieldValue(`alltype[${index}]`, event.target.value)

                                                                                        setFieldValue(`alltime[${index}]`, [global.config.shiftTimeRange?.find(x => x.label === event.target.value)?.value?.start, global.config.shiftTimeRange?.find(x => x.label === event.target.value)?.value?.end])
                                                                                    }}
                                                                                    onBlur={handleBlur}
                                                                                    value={values?.alltype && values?.alltype[index]}
                                                                                    required
                                                                                    data={global.config.shiftType}
                                                                                    optionLabel={"name"}
                                                                                    optionValue={"value"}
                                                                                />
                                                                            </div>
                                                                            <input className="slot" id={`alldate[${index}]`} name={`alldate[${index}]`} value={date?.format()} />
                                                                            <Field id={`alltime[${index}]`} name={`alltime[${index}]`} render={({ field, form }) => <TimeRangePickerField  {...field} {...form} firstTime={values?.alltime?.length > 0 && values?.alltime[0]} index={index} />} />
                                                                            <div className="btn position-absolute top-0 end-0" onClick={() => {
                                                                                setAllDates([
                                                                                    ...allDates.slice(0, index),
                                                                                    ...allDates.slice(index + 1)
                                                                                ]);
                                                                                setDates([
                                                                                    ...allDates.slice(0, index),
                                                                                    ...allDates.slice(index + 1)
                                                                                ]);
                                                                                (values?.alltime?.length !== 0 && values?.alltime?.length >= index) && values?.alltime?.splice(index, 1);
                                                                                (values?.allqty?.length !== 0 && values?.allqty?.length >= index) && values?.allqty?.splice(index, 1);
                                                                                (values?.alltype?.length !== 0 && values?.alltype?.length >= index) && values?.alltype?.splice(index, 1);
                                                                                remove(index)
                                                                            }}>
                                                                                <i className="fas fa-times-circle text-danger" aria-hidden="true"></i>
                                                                            </div>
                                                                            {values?.tenure?.length > 0 &&
                                                                                <div className="btn btn-outline-success ms-2" style={{ padding: " 1px 5px 1px 5px" }}> <i className="far fa-copy pointer" onClick={() => {
                                                                                    if (values?.alltime[index]?.length === 2 && !values?.alltime[index]?.some(item => item === null || item === undefined)) {
                                                                                        values?.tenure?.map((item, ind) => {
                                                                                            setFieldValue(`alltime[${ind}]`, values?.alltime[index]);
                                                                                            if (values?.allqty?.length && (values?.allqty[index] !== null || values?.allqty[index] !== undefined)) {
                                                                                                setFieldValue(`allqty[${ind}]`, values?.allqty[index])
                                                                                            }
                                                                                            if (values?.alltype?.length && (values?.alltype[index] !== null || values?.alltype[index] !== undefined)) {
                                                                                                setFieldValue(`alltype[${ind}]`, values?.alltype[index])
                                                                                            }
                                                                                        })
                                                                                    } else {
                                                                                        toast.warn("Please select start end time")
                                                                                    }
                                                                                }} data-toggle="tooltip" data-placement="right" title="Copy to all"></i></div>}
                                                                            <div className="col-12 mt-2">
                                                                                <InputField
                                                                                    className={"did-floating-input"}
                                                                                    type="text"
                                                                                    name={`allcomment[${index}]`}
                                                                                    onChange={(event) => {
                                                                                        setFieldValue(`allcomment[${index}]`, event.target.value)
                                                                                    }}
                                                                                    label={"Comments For Caregiver"}
                                                                                    value={values?.allcomment && values?.allcomment[index]}
                                                                                    onBlur={handleBlur}
                                                                                />
                                                                            </div>
                                                                        </li>
                                                                    </div>
                                                                )
                                                            )}
                                                        </FieldArray>

                                                    </ul>
                                                </div>
                                            }
                                            {multi?.length > 0 && multi &&
                                                <div>
                                                    <div className="d-flex justify-content-start mt-2 mb-2">Shifts:</div>
                                                    <ul className="list-unstyled">
                                                        <FieldArray name="tenure">
                                                            {({ push, remove, form }) => (
                                                                values?.tenure && values?.tenure?.length != 0 && values?.tenure.map((date, index) =>
                                                                    <div className="card border p-2 mb-2">
                                                                        <li key={index}>
                                                                            <div className="col-4 pt-1 pb-2" >
                                                                                <SelectField
                                                                                    id={"slot-type"}
                                                                                    label={"Shift Type"}
                                                                                    name={`alltype[${index}]`}
                                                                                    onChange={(event) => {
                                                                                        setFieldValue(`alltype[${index}]`, event.target.value)
                                                                                        setFieldValue(`alltime[${index}]`, [global.config.shiftTimeRange?.find(x => x.label === event.target.value)?.value?.start, global.config.shiftTimeRange?.find(x => x.label === event.target.value)?.value?.end])
                                                                                    }}
                                                                                    onBlur={handleBlur}
                                                                                    value={values?.alltype && values?.alltype[index]}
                                                                                    required
                                                                                    data={global.config.shiftType}
                                                                                    optionLabel={"name"}
                                                                                    optionValue={"value"}
                                                                                />
                                                                            </div>
                                                                            <input className="slot" id={`alldate[${index}]`} name={`alldate[${index}]`} value={date?.format()} />
                                                                            <Field id={`alltime[${index}]`} name={`alltime[${index}]`} render={({ field, form }) => <TimeRangePickerField  {...field} {...form} firstTime={values?.alltime?.length > 0 && values?.alltime[0]} index={index} />} />
                                                                            <div className="btn position-absolute top-0 end-0" onClick={() => {
                                                                                setValues([
                                                                                    ...multi.slice(0, index),
                                                                                    ...multi.slice(index + 1)
                                                                                ]);
                                                                                (values?.alltime?.length !== 0 && values?.alltime?.length >= index) && values?.alltime?.splice(index, 1);
                                                                                (values?.allqty?.length !== 0 && values?.allqty?.length >= index) && values?.allqty?.splice(index, 1);
                                                                                (values?.alltype?.length !== 0 && values?.alltype?.length >= index) && values?.alltype?.splice(index, 1);
                                                                                remove(index)
                                                                            }}>
                                                                                <i className="fas fa-times-circle text-danger" aria-hidden="true"></i>
                                                                            </div>
                                                                            {values?.tenure?.length > 1 && <div className="btn btn-outline-success ms-2" style={{ padding: " 1px 5px 1px 5px" }}><i className="far fa-copy pointer" onClick={() => {
                                                                                if (values?.alltime[index]?.length === 2 && !values?.alltime[index]?.some(item => item === null || item === undefined)) {
                                                                                    values?.tenure?.map((item, ind) => {
                                                                                        setFieldValue(`alltime[${ind}]`, values?.alltime[index]);
                                                                                        if (values?.allqty?.length && (values?.allqty[index] !== null || values?.allqty[index] !== undefined)) {
                                                                                            setFieldValue(`allqty[${ind}]`, values?.allqty[index])
                                                                                        }
                                                                                        if (values?.alltype?.length && (values?.alltype[index] !== null || values?.alltype[index] !== undefined)) {
                                                                                            setFieldValue(`alltype[${ind}]`, values?.alltype[index])
                                                                                        }
                                                                                    })
                                                                                } else {
                                                                                    toast.warn("Please select start end time")
                                                                                }
                                                                            }} data-toggle="tooltip" data-placement="right" title="Copy to all"></i></div>}
                                                                            <div className="col-12 mt-2">
                                                                                <InputField
                                                                                    className={"did-floating-input"}
                                                                                    type="text"
                                                                                    name={`allcomment[${index}]`}
                                                                                    onChange={(event) => {
                                                                                        setFieldValue(`allcomment[${index}]`, event.target.value)
                                                                                    }}
                                                                                    label={"Comments For Caregiver"}
                                                                                    value={values?.allcomment && values?.allcomment[index]}
                                                                                    onBlur={handleBlur}
                                                                                />
                                                                            </div>
                                                                        </li>
                                                                    </div>
                                                                )
                                                            )}
                                                        </FieldArray>
                                                    </ul>
                                                </div>
                                            }
                                        </div>

                                        <div className="pb-2 col-sm-12">
                                            <SelectField
                                                name="job_type"
                                                label={"Job Type"}
                                                value={values.job_type}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                data={global.config.jobType}
                                                optionLabel={"name"}
                                                optionValue={"value"}
                                            />
                                        </div>
                                        <div className="form-group col-sm-12">
                                            <label className="pb-2 label">Comments for Staffers Management</label>
                                            <textarea className="form-control"
                                                placeholder=""
                                                name={'description'}
                                                value={values.description}
                                                onChange={handleChange('description')}
                                            >
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-center">
                                    <button type="submit" className="btn btn-primary w-25">Submit</button>
                                </div>
                            </form>
                        )
                    }
                </Formik>
                {/* </CModalBody> */}
            </CModal>
            <Spinner isLoading={HomeStore.loading || UserStore.loading} />
        </>

    );
});
function TimeRangePickerField(props) {
    const hnadleStart = (e) => {
        const startTime = e.target.value;
        if (props?.values?.alltime?.length === 0) {
            props.setFieldValue(props.name, [startTime, undefined]);
        } else {
            props.setFieldValue(props.name, [startTime, props?.values?.alltime[props.index]?.length === 2 ? props?.values?.alltime[props.index][1] : undefined]);
        }
    }
    const hnadleEnd = (e) => {
        const endTime = e.target.value;
        if (props?.values?.alltime?.length === 0) {
            props.setFieldValue(props.name, [undefined, endTime]);
        } else {
            if (props?.values?.alltime[props.index][0] !== endTime) {
                props.setFieldValue(props.name, [props?.values?.alltime[props.index][0], endTime]);
            } else {
                toast.error("The start and finish timings must not be the same!")
            }
        }
    }

    return (
        <>
            <input style={{ lineHeight: 2 }} required type="time" id="start" name="start" value={props?.values?.alltime[props?.index] ? props?.values?.alltime[props?.index][0] : ''} onChange={hnadleStart} />
            <span style={{ padding: 5, paddingBottom: 7, paddingTop: 6, borderLeftWidth: 0, borderRightWidth: 0 }} className="timer-form">To</span>
            <input style={{ lineHeight: 2 }} required type="time" className="timer-border" id="end" name="end" disabled={props?.values?.alltime[props?.index] ? props?.values?.alltime[props?.index][0] ? false : true : true} value={props?.values?.alltime[props?.index]?.length === 2 ? props?.values?.alltime[props.index][1] : ""} onChange={hnadleEnd} />
        </>
    );
}
export const Control = (props) => {
    return (
        <>
            <label className={props.hasValue || props.isFocused ? "did-floating-label did-floating-label-top" : "did-floating-label did-floating-label"} style={{ zIndex: 1 }}>{props?.selectProps?.label}</label>
            <components.Control {...props} />
        </>
    );
};
export default StaffsPage;
