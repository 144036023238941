import React from 'react'
import Header from '@components/PDFHeader';
import moment from 'moment';


export const BonusPayment = (props) => {
  return (
    <div id="form-content">
      <Header />
      <div className=" agreement-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="  text-center">
                STAFFERS HOLDINGS LLC ARBITRATION POLICY AND AGREEMENT
              </h2>
              <h2 className="text-center">CONSIDERATION ACKNOWLEDGEMENT</h2>
              <p>
                In consideration for the mutual promises contained in the
                Staffers Holdings LLC Arbitration Policy Agreement (“Agreement”)
                and a one-time bonus payment of $200.00 (“Bonus Compensation”),
                and for other good and valuable consideration, the receipt and
                sufficiency of which are hereby acknowledged, I
                <input placeholder='Full Name' required style={{borderWidth:"0px 0px 1px 0px"}} value={props.inputValues.full_name} type="text" minLength={2} maxLength={20}    name="full name" onChange={(e) => props.handleInputChange('full_name', e.target.value)} />
                 &nbsp; have agreed to the Agreement, the
                terms and conditions of which are incorporated herein by
                reference. I acknowledge and agree that I would not have been
                entitled to the Bonus Compensation but for my agreement to the
                Agreement. The Bonus Compensation shall be paid out to me on the
                next available paycheck and subject to applicable tax and other
                required withholding.
              </p>
              <div className="bonus-sec">
                <p>
                {props?.signature ? <img src={props?.signature} width={180} height={50} alt="Signature" /> : <div class="badge badge-primary text-wrap w-25 pointer" onClick={props?.onOpenModal}>
                    Click Here To Sign
                  </div>}
                  <span></span>
                  <label>Signature</label>
                </p>
                <p>
                <input type="text" value={moment(new Date()).format("MM-DD-YYYY HH:mm:ss")} disabled/>
                  <span></span>
                  <label>Date</label> 
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
