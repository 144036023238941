const splitTextArray = (textArray) => {
  try {
    const parsedArray = JSON.parse(textArray);

    // Check if the parsedArray is an array
    if (Array.isArray(parsedArray)) {
      return parsedArray.map((text) => {
        // Split each string after double quote
        let segments = text.split('"');

        // Remove empty strings from the array
        segments = segments.filter((segment) => segment.trim() !== "");

        // Trim whitespace from each segment
        segments = segments.map((segment) => segment.trim());

        return segments;
      });
    } else {
      console.error("Input is not a valid JSON array.");
      return null;
    }
  } catch (error) {
    console.error("Error parsing JSON:", error);
    return null;
  }
};

export default splitTextArray;
