import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CFormCheck, CFormSelect } from '@coreui/react-pro';
import React from 'react';
import moment from 'moment';
import './ResponsiveTable.css'
import StaffsStore from '@store/StaffsStore';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
const ResponsiveTable = ({ data, columns, onChangeSort, onPageSizeChange, pushSlot, onPageChange, selectedRows }) => {
  return (
    <>
      <SortComponent onChangeSort={onChangeSort} onPageSizeChange={onPageSizeChange} />
      <span className='d-md-none d-lg-none d-table-cell mt-3 text-center'>Showing <span >{StaffsStore.data.from || "0"}</span> to <span >{StaffsStore.data.to || "0"}</span> of <span >{StaffsStore.data.total}</span> entries</span>
      <table className="table table-hover">
        <thead className="d-none d-lg-block d-md-table-header-group ">
          <tr>
            {columns?.map((column, index) => (
              <th key={column + index} className="d-sm-none d-md-table-cell">
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => {
            return <tr key={index + 'list'}>
              <td className="align-middle d-md-none d-table-cell">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 col-md-8">
                        <div className="row">
                          <div className="col-12">
                            <b><i className='far fa-calendar fs-6'></i></b> <span className="fs-6">{item.slot_date}</span>, &nbsp;
                            <b><i className='far fa-clock fs-6'></i></b> <span className="fs-6"> {moment(item?.slot_start_time, "HH:mm").format("hh:mm A")} To {moment(item?.slot_end_time, "HH:mm").format("hh:mm A")}</span>
                          </div>
                        </div>
                        <div className="row mt-1">
                          <div className="col-md-12">
                            <ul className="list-unstyled mb-0">
                              <li><b>Type:</b> {item?.category === 'TC' ? item?.shift_type : item?.slot_type}</li>
                              <li><b>Category:</b> {item?.category === 'TC' ? "Traveler" : global.config.shiftCaregory?.find(x => x.value === item?.request?.category)?.label}</li>
                              <li><b>Facility:</b> {item?.request?.facility?.organization_name || item?.request?.user?.organization_name}</li>
                              <li><b>Address:</b> {item?.request?.facility?.address}, {item?.request?.facility?.city}, {item?.request?.facility?.state}</li>
                              {item?.remark && <li><b>Comments:</b> {item?.remark}</li>}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="checkbox-container">
                        <input className="checkbox-custom pointer" id="that" type="checkbox" checked={selectedRows?.find(obj => obj.id === item?.id) !== undefined} onChange={(e) => pushSlot(e, item)} />
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td className="d-none d-md-table-cell text-center">
                <input className="checkbox-custom pointer" id="that" type="checkbox" checked={selectedRows?.find(obj => obj.id === item?.id) !== undefined} onChange={(e) => pushSlot(e, item)} />
              </td>
              <td className="d-none d-md-table-cell">{item?.slot_date}</td>
              <td className="d-none d-md-table-cell">{moment(item?.slot_start_time, "HH:mm").format("hh:mm A")} To {moment(item?.slot_end_time, "HH:mm").format("hh:mm A")}<br />
                <small className='text-danger text-truncate' data-bs-toggle="tooltip" data-bs-placement="top" title={item?.remark}>
                  {item?.remark?.substring(0, 35)}...
                </small>
              </td>
              <td className="d-none d-md-table-cell">{item?.request?.facility?.organization_name || item?.request?.user?.organization_name}</td>
              <td className="d-none d-md-table-cell">{item?.category === 'TC' ? item?.shift_type : item?.slot_type}</td>
              <td className="d-none d-md-table-cell"> {item?.category === 'TC' ? "Traveler" : global.config.shiftCaregory?.find(x => x.value === item?.request?.category)?.label}</td>
              <td className="d-none d-md-table-cell">{item?.request?.facility?.address}, {item?.request?.facility?.city}, {item?.request?.facility?.state}</td>
            </tr>
          })}
        </tbody>
      </table>
      {data?.length === 0 && <p className='text-center'>Shift not found. Please try another preferred state or radius area.</p>}
      <div className='text-end mt-3 '>
        <Pagination
          current={StaffsStore.data.page}
          total={StaffsStore.data.total}
          pageSize={StaffsStore.data.pageSize} // Number of items per page
          onChange={onPageChange}
          showLessItems={true}
          prevIcon={<div className=''><i className="fas fa-chevron-left"></i> Previous</div>} // Icon and text for "Previous" button
          nextIcon={<div className=''>Next <i className="fas fa-chevron-right"></i></div>}
        />
      </div>
    </>
  );
};

export default ResponsiveTable;

const SortComponent = ({ onChangeSort, onPageSizeChange }) => {
  return (
    <div className='d-flex justify-content-between'>
      <div>
        <select className="form-control-sm " onChange={onChangeSort} value={StaffsStore.data.sort_by} id="selectSortBy" aria-label="Sort By">
          <option value="">Sort By</option>
          <option value="dateAsc">Date ASC</option>
          <option value="dateDesc">Date DESC</option>
          {/* <option value="facilityAZ">Facility A - Z</option>
          <option value="facilityZA">Facility Z - A</option> */}
          <option value="shiftTypeAZ">Shift Type A - Z</option>
          <option value="shiftTypeZA">Shift Type Z - A</option>
          {/* <option value="categoryAZ">Category A - Z</option>
          <option value="categoryZA">Category Z - A</option> */}
        </select>
      </div>
      <div className='d-flex align-items-center'>
        <span className='pe-3 d-none d-md-table-cell'>Showing <span >{StaffsStore.data.from || "0"}</span> to <span >{StaffsStore.data.to || "0"}</span> of <span >{StaffsStore.data.total}</span> entries</span>
        <select
          className="form-control-sm me-3"
          value={StaffsStore.data.pageSize}
          onChange={onPageSizeChange}
        >
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="150">150</option>
          <option value="200">200</option>
        </select>
      </div>
    </div>
  );
}
